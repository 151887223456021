
export const LocationTypes = {

    Office           : "office",

    SharedWorkspace  : "sharedWorkspace",

    ProductionFacility : "productionFacility",

    DistributionFacility  : "distributionFaciltiy",

    DataCenter       : "dataCenter",

    ServiceCenter    : "serviceCenter",

    Laboratory       : "laboratory",

    BuildingSite       : "buildingSite",

    Shipyard         : "shipyard",

    Workshop         : "workshop",

    Refinery         : "refinery",

    Vessel           : "vessel",
    
    Rig              : "rig",
    
    Airport          : "airport",
    
    Station          : "station",

    Store            : "store",
    
    Hotel            : "hotel",

    Restaurant       : "restaurant",

    Hospital        : "hospital",

    Clinic          : "clinic",

    CareFacility    : "careFacility",

    Stadium          : "stadium"


}

export type LocationType = keyof (typeof LocationTypes);


