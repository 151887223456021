
import { ObservableIF } from "../../common/api/observableIF";
import { Observation } from "../../common/api/observation";
import { Observable } from "../../common/impl/observable";
import { User } from "../../database/impl/documents/user";
import { LoggerFactory } from "../../common/api/loggerFactory";
import { AuthenticationClaims } from "../api/authenticationClaims";
import { AuthenticationServiceIF } from "../api/authenticationServiceIF";
import { AuthenticationNotification } from "../api/authenticationNotification";
import { Monitor } from "../../common/api/monitor";
import { Factory } from "../../common/api/factory";
 
export const log = LoggerFactory.logger("authentication" );

export abstract class AuthenticationService extends Observable implements AuthenticationServiceIF {

    constructor() {

        super();

        //log.traceIn("constructor()");

        try {

            this.onNotifyAuthenticatedUserChanged = this.onNotifyAuthenticatedUserChanged.bind(this);

            //log.traceOut("constructor()");
        } catch (error) {
            log.warn("constructor()", "Error initializing authentication service", error);

            throw new Error( (error as any).message );
        }
    }

    async init() : Promise<void> {

        log.traceInOut("init()" );

        try {


        } catch (error) {
            log.warn("init()", "Error initializing configuration service", error);

            throw new Error( (error as any).message );
        }
    }

    authenticatedUser(): User | undefined {

        try {

            //log.traceInOut( "currentUser()", this._currentUser );
            return this._currentUser;

        } catch (error) {
            log.warn("currentUser()", "Error getting authenticated user", error);

            throw new Error( (error as any).message );
        }
    }

    authenticationClaims(): AuthenticationClaims | undefined {

        try {

            //log.traceInOut( "authenticationClaims()", this._currentUser );
            return this._authenticationClaims;

        } catch (error) {
            log.warn("authenticationClaims()", "Error getting authentication claims", error);

            throw new Error( (error as any).message );
        }
    }

    protected async updateAuthentication(
        currentUser: User | undefined, 
        authenticationClaims: AuthenticationClaims,
        dontNotify?: boolean): Promise<void> {

        log.traceIn("updateAuthentication()",currentUser, dontNotify);

        try {
            this._authenticationClaims = authenticationClaims; 

            if (this._currentUser != null ) {

                await this._currentUser.unsubscribe(this);
            }

            if( currentUser != null && this._authenticationClaims != null && 
                currentUser.authenticationId.value() != null &&
                this._authenticationClaims.authenticationId !== currentUser.authenticationId.value() ) {

                log.warn( "updateAuthentication()", "Authentication ID mismatch" );
            }
            else {
                this._currentUser = currentUser;
            }

            if (this._currentUser == null ) {
                Factory.get().securityService.clearCurrentKeys();
            }
            else {

                await this._currentUser.subscribe({
                    observer: this,
                    onNotify: this.onNotifyAuthenticatedUserChanged,
                } as Monitor);
            }
             

            if (dontNotify != null && dontNotify) {

                log.traceOut("updateAuthentication()", "no notification");
                return;
            }

            await super.notify(
                Observation.Update,
                authenticationClaims.authenticationId,
                {
                    currentUser: this._currentUser,

                    authenticationClaims: this._authenticationClaims

                } as AuthenticationNotification);

            log.traceOut("updateAuthentication()");

        } catch (error) {
            log.warn("updateAuthentication()", "Error updating authentication", error);

            throw new Error( (error as any).message );
        }
    }

    protected async clearAuthentication( dontNotify?: boolean, error? : string ): Promise<void> {

        log.traceIn("clearAuthentication()", 
            "dontNotify: ", dontNotify,
            "error: ", error );

        try {

            if (this._currentUser != null) {
                await this._currentUser.unsubscribe(this);
            }

            delete this._authenticationClaims;
            delete this._currentUser;

            if (dontNotify != null && dontNotify) {

                log.traceOut("clearAuthentication()", "dontNotify");
                return;
            }
            await super.notify(
                Observation.Delete, 
                undefined,                 
                {
                    error: error

                } as AuthenticationNotification );

            log.traceOut("clearAuthentication()");

        } catch (error) {
            log.warn("clearAuthentication()", "Error getting current user", error);

            throw new Error( (error as any).message );
        }
    }


    protected async monitor( newMonitor : Monitor ): Promise<void> {

        log.traceIn("monitor()" );

        try {

            if (newMonitor.onNotify == null) {

                log.traceIn("monitor()", "no notification callback" );
                return;
            }

            if (this._currentUser == null) {

                log.traceIn("monitor()", "no current user" );
                return;
            }

            const authenticationNotification = {} as AuthenticationNotification;

            authenticationNotification.authenticationClaims = this._authenticationClaims;

            const authenticationId = this._currentUser.authenticationId.value()!;

            authenticationNotification.currentUser = this._currentUser;
        
            await newMonitor.onNotify(this,
                Observation.Create,
                authenticationId,
                authenticationNotification );

            log.traceOut("monitor()");

        } catch (error) {
            log.warn("constructor()", "Error getting current user", error);

            throw new Error( (error as any).message );
        }
    }
    
    onNotifyAuthenticatedUserChanged = async (observable: ObservableIF,
        observation: Observation,
        objectId: string | null | undefined,
        object: object | null | undefined) : Promise<void> => {

        try {
            log.traceIn("onNotifyAuthenticatedUserChanged()", Observation[observation], objectId, object);

            if( this._currentUser == null ||
                !Factory.get().databaseService.databaseFactory.equalDatabasePaths( this._currentUser.databasePath(true), objectId! ) ) {

                log.traceOut("onNotifyAuthenticatedUserChanged()", "not for us", {objectId});
                return;

            }

            const user = object as User;

            switch (observation) {

                case Observation.Create:
                case Observation.Update:
                {

                    this._currentUser.copyProperties( user );

                    await super.notify( 
                        Observation.Update,
                        this._currentUser.authenticationId.value(), {            
                            currentUser: this._currentUser,
                            authenticationClaims: this._authenticationClaims 
                        } as AuthenticationNotification
                    );
                    
                    break;
                }
                case Observation.Delete:
                {

                    await this.clearAuthentication(false);
                    break;
                }
                default:
                    throw new Error("Unrecognized observation: " + observation);
            }

            log.traceOut("onNotifyAuthenticatedUserChanged()");

        } catch (error) {
            log.warn("Error receiving database notification", error);

            log.traceOut("onNotifyAuthenticatedUserChanged()", "error");
        }
    }



    protected async release(observationFilter?: Observation[], objectIdsFilter?: string[]): Promise<void> { }

    abstract registerEmailUser( email : string, password : string ) : Promise<any>;

    abstract signInEmailUser( email : string, password : string ) : Promise<any>;

    abstract signIn( credentials : any ) : Promise<any>;

    abstract signOut( delay? : number ): Promise<void>;

    abstract verifyEmail() : Promise<void>;

    abstract verifyPhone( credentials? : any ) : Promise<void>; 

    abstract checkAuthentication( currentUser : User ) : Promise<any | undefined>;

    abstract refreshAuthenticationClaims() : Promise<AuthenticationClaims | undefined>;

    abstract initialized : boolean;

    private _currentUser: User | undefined;

    private _authenticationClaims : AuthenticationClaims | undefined; 

}
