import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom';

import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';

import { Factory } from 'services/common/api/factory';

import { CompaniesView, CompaniesTable, CompaniesCard, CompaniesChart, CompaniesGraph, CompaniesList } from 'ui/views/companyViews';
import { UsersView, UsersTable, UsersCard, UsersChart, UsersGraph, UsersList } from 'ui/views/userViews';
import { ChangesView, ChangesTable, ChangesCard, ChangesChart, ChangesGraph, ChangesList} from 'ui/views/changeViews';
import { HazardsView, HazardsTable, HazardsCard, HazardsChart, HazardsGraph, HazardsList} from 'ui/views/hazardViews';
import { GatheringsView, GatheringsTable, GatheringsCard, GatheringsChart, GatheringsGraph, GatheringsList } from 'ui/views/gatheringViews';
import {  RisksView, RisksTable, RisksCard, RisksChart, RisksGraph, RisksList } from 'ui/views/riskViews';
import { UnitsCard, UnitsChart, UnitsGraph, UnitsList, UnitsTable, UnitsTree, UnitsView } from 'ui/views/unitViews';
import { MeasuresView, MeasuresTable, MeasuresCard, MeasuresChart, MeasuresGraph, MeasuresList } from 'ui/views/measureViews';
import { VaccineRegistrationsView, VaccineRegistrationsTable, VaccineRegistrationsCard, VaccineRegistrationsChart, VaccineRegistrationsGraph, VaccineRegistrationsList } from 'ui/views/vaccineRegistrationViews';
import { TestRegistrationsView, TestRegistrationsList, TestRegistrationsCard, TestRegistrationsChart, TestRegistrationsGraph, TestRegistrationsTable } from 'ui/views/testRegistrationViews';
import { SymptomRegistrationsView, SymptomRegistrationsTable, SymptomRegistrationsCard, SymptomRegistrationsChart, SymptomRegistrationsGraph, SymptomRegistrationsList } from 'ui/views/symptomRegistrationViews';
import { IncidentRegistrationsView, IncidentRegistrationsTable, IncidentRegistrationsCard, IncidentRegistrationsChart, IncidentRegistrationsGraph, IncidentRegistrationsList } from 'ui/views/incidentRegistrationViews';
import { SickLeaveRegistrationsView, SickLeaveRegistrationsTable, SickLeaveRegistrationsCard, SickLeaveRegistrationsChart, SickLeaveRegistrationsGraph, SickLeaveRegistrationsList } from 'ui/views/sickLeaveRegistrationViews';
import { QuarantineRegistrationsView, QuarantineRegistrationsTable, QuarantineRegistrationsCard, QuarantineRegistrationsChart, QuarantineRegistrationsGraph, QuarantineRegistrationsList } from 'ui/views/quarantineRegistrationViews';
import { SubscriptionsView, SubscriptionsTable, SubscriptionsCard, SubscriptionsChart, SubscriptionsGraph, SubscriptionsList } from '../views/subscriptionViews';
import { LocationsView, LocationsTable, LocationsCard, LocationsChart, LocationsGraph, LocationsMap, LocationsList } from 'ui/views/locationViews';
import { ResidencesView, ResidencesTable, ResidencesCard, ResidencesChart, ResidencesGraph, ResidencesMap, ResidencesList } from 'ui/views/residenceViews';
import { RegistrationsView, RegistrationsTable, RegistrationsCard, RegistrationsChart, RegistrationsGraph, RegistrationsList } from 'ui/views/registrationViews ';
import { CategoriesView, CategoriesTable, CategoriesCard, CategoriesChart, CategoriesGraph, CategoriesList } from '../views/categoryViews';
import { TerminalsCard, TerminalsChart, TerminalsGraph, TerminalsList, TerminalsTable, TerminalsView } from '../views/terminalViews';
import { KeysCard, KeysChart, KeysGraph, KeysList, KeysTable, KeysView } from '../views/keyViews';
import { MessagesCard, MessagesChart, MessagesGraph, MessagesList, MessagesTable, MessagesView } from '../views/messageViews';
import { AppContext, AppContextProps } from 'ui/app/appContext';
import { log } from 'ui/app/app';
import { CompanyIF } from 'services/database/api/documents/companyIF'; 
import { HazardIF } from 'healthguard/api/documents/hazardIF'; 
import { GatheringIF } from 'healthguard/api/documents/gatheringIF';
import { RiskIF } from 'healthguard/api/documents/riskIF';
import { MeasureIF } from 'healthguard/api/documents/measureIF';
import { SickLeaveRegistrationIF } from 'healthguard/api/documents/sickLeaveRegistrationIF';
import { SymptomRegistrationIF } from 'healthguard/api/documents/symptomRegistrationIF';
import { TestRegistrationIF } from 'healthguard/api/documents/testRegistrationIF';
import { IncidentRegistrationIF } from 'healthguard/api/documents/incidentRegistrationIF';
import { UnitIF } from 'services/database/api/documents/unitIF';
import { VaccineRegistrationIF } from 'healthguard/api/documents/vaccineRegistrationIF';
import { DatabaseDocumentIF } from 'services/database/api/core/databaseDocumentIF';
import { DocumentNameKey } from 'services/database/api/core/databaseServiceIF';
import { QuarantineRegistrationIF } from 'healthguard/api/documents/quarantineRegistrationIF';
import { LocationIF } from 'services/database/api/documents/locationIF';
import { ResidenceIF } from 'services/database/api/documents/residenceIF';
import { DatabaseIF } from 'services/database/api/core/databaseIF';
import { ChangeIF } from '../../services/database/api/documents/changeIF';
import { CategoryIF } from '../../services/database/api/documents/categoryIF';
import { RegistrationIF } from '../../healthguard/api/documents/registrationIF';
import { AlertDocument, CategoryDocument, ChangeDocument, CompanyDocument, DeviceDocument, KeyDocument, LocationDocument, MessageDocument, ProjectDocument, ResidenceDocument, SubscriptionDocument, TerminalDocument, UnitDocument, UserDocument } from '../../services/database/api/documents';
import { IncidentRegistrationDocument, QuarantineRegistrationDocument, RegistrationDocument, SickLeaveRegistrationDocument, SymptomRegistrationDocument, TestRegistrationDocument, VaccineRegistrationDocument } from '../../healthguard/api/registrationDocuments';
import { ConsentDocument, HazardDocument, GatheringDocument, RiskDocument, HealthguardCategoryDocument, HealthguardCompanyDocument, HealthguardDeviceDocument, HealthguardLocationDocument, HealthguardProjectDocument, HealthguardUnitDocument, HealthguardUserDocument, MeasureDocument, HealthguardAlertDocument } from '../../healthguard/api/healthguardDocuments';
import { SubscriptionIF } from '../../services/database/api/documents/subscriptionIF';
import { DatabaseChartProps } from './databaseChart';
import { CategoriesCollection, Comparator, Comparators, DatabaseFilter, LocationsCollection, ProjectIF, ProjectsCollection, ReferenceHandle, UsersCollection } from '../../services/database';
import { ProjectsView, ProjectsTable, ProjectsCard, ProjectsChart, ProjectsGraph, ProjectsList } from '../views/projectViews';
import { DatabaseGraphProps } from './databaseGraph';
import { AlertsCard, AlertsChart, AlertsGraph, AlertsTable, AlertsMap, AlertsView, AlertsList } from '../views/alertViews';
import { AlertIF } from '../../services/database/api/documents/alertIF';
import { DatabaseCardProps } from './databaseCard';
import { DatabaseTableProps } from './databaseTable';
import { GatheringsCollection, RisksCollection, MeasuresCollection } from '../../healthguard/api/healthguardCollections';
import { ConsentsCard, ConsentsChart, ConsentsGraph, ConsentsList, ConsentsTable, ConsentsView } from '../views/consentViews';
import { ConsentIF } from '../../healthguard/api/documents/consentIF';
import { DevicesCard, DevicesChart, DevicesGraph, DevicesTable, DevicesMap, DevicesView, DevicesList } from '../views/deviceViews';
import { DeviceIF } from '../../services/database/api/documents/deviceIF';
import { DatabaseMapProps } from './databaseMap';
import { DatabaseQuery } from '../../services/database/api/core/databaseQuery';
import { DatabaseTreeProps } from './databaseTree';
import { HomePaths } from '../../services/common/api/homePaths';
import { TerminalIF } from '../../services/database/api/documents/terminalIF';
import { KeyIF } from '../../services/database/api/documents/keyIF';
import { MessageIF } from '../../services/database/api/documents/messageIF';
import { RegistrationsCollection } from '../../healthguard/api/registrationCollections';
import { UserAccess } from '../../services/common/api/userAccess';
import { HealthguardUserIF } from '../../healthguard/api/documents/healthguardUserIF';
import { DatabaseListProps } from './databaseList';

export const databaseView = ( appContext : AppContextProps, database : DatabaseIF<DatabaseDocumentIF> ) : JSX.Element | null => {

  const databaseQuery = {

    databases: updateDatabases( appContext, [database] ),

    databaseFilters: updateDatabaseFilters( appContext )

  } as DatabaseQuery<DatabaseDocumentIF>;

  switch (database.defaultDocumentName()) {

    case CategoryDocument: 
    case HealthguardCategoryDocument: 
    {
      return( <CategoriesView databaseQuery={databaseQuery as DatabaseQuery<CategoryIF>} /> );
    }
    case ChangeDocument: 
    {
      return( <ChangesView databaseQuery={databaseQuery as DatabaseQuery<ChangeIF>} /> );
    }
    case CompanyDocument: 
    case HealthguardCompanyDocument: 
    {
      return( <CompaniesView databaseQuery={databaseQuery as DatabaseQuery<CompanyIF>} /> );
    }
    case ConsentDocument: 
    {
      return( <ConsentsView databaseQuery={databaseQuery as DatabaseQuery<ConsentIF>} /> );
    }
    case AlertDocument: 
    case HealthguardAlertDocument: 
    {
      return( <AlertsView databaseQuery={databaseQuery as DatabaseQuery<AlertIF>} /> );
    }
    case DeviceDocument: 
    case HealthguardDeviceDocument: 
    {
      return( <DevicesView databaseQuery={databaseQuery as DatabaseQuery<DeviceIF>} /> );
    }
    case HazardDocument: 
    {
      return(  <HazardsView databaseQuery={databaseQuery as DatabaseQuery<HazardIF>} /> );
    }
    case GatheringDocument: 
    {
      return( <GatheringsView databaseQuery={databaseQuery as DatabaseQuery<GatheringIF>} /> );
    }
    case RiskDocument: 
    {
      return( <RisksView databaseQuery={databaseQuery as DatabaseQuery<RiskIF>} /> );
    }
    case ResidenceDocument: 
    {
      return(  <ResidencesView databaseQuery={databaseQuery as DatabaseQuery<ResidenceIF>} /> );
    }
    case LocationDocument: 
    case HealthguardLocationDocument: 
    {
      return( <LocationsView databaseQuery={databaseQuery as DatabaseQuery<LocationIF>} /> );
    }
    case KeyDocument: 
    {
      return( <KeysView databaseQuery={databaseQuery as DatabaseQuery<KeyIF>} /> ); 
    }
    case MeasureDocument: 
    {
      return(  <MeasuresView databaseQuery={databaseQuery as DatabaseQuery<MeasureIF>} /> );
    }
    case MessageDocument: 
    {
      return( <MessagesView databaseQuery={databaseQuery as DatabaseQuery<MessageIF>} /> ); 
    }
    case ProjectDocument: 
    case HealthguardProjectDocument:  
    {
      return( <ProjectsView databaseQuery={databaseQuery as DatabaseQuery<ProjectIF>} /> );
    }
    case QuarantineRegistrationDocument: 
    {
      return(  <QuarantineRegistrationsView databaseQuery={databaseQuery as DatabaseQuery<QuarantineRegistrationIF>} /> );
    }
    case SickLeaveRegistrationDocument: 
    {
      return( <SickLeaveRegistrationsView databaseQuery={databaseQuery as DatabaseQuery<SickLeaveRegistrationIF>} />  );
    }
    case SubscriptionDocument: 
    {
      return(  <SubscriptionsView databaseQuery={databaseQuery as DatabaseQuery<SubscriptionIF>} /> );
    }
    case RegistrationDocument: 
    {
      return( <RegistrationsView databaseQuery={databaseQuery as DatabaseQuery<RegistrationIF>} /> );
    }
    case SymptomRegistrationDocument: 
    {
      return( <SymptomRegistrationsView databaseQuery={databaseQuery as DatabaseQuery<SymptomRegistrationIF>} /> );
    }
    case TerminalDocument: 
    {
      return( <TerminalsView databaseQuery={databaseQuery as DatabaseQuery<TerminalIF>} /> ); 
    }
    case TestRegistrationDocument: 
    {
      return( <TestRegistrationsView databaseQuery={databaseQuery as DatabaseQuery<TestRegistrationIF>} /> );
    }
    case IncidentRegistrationDocument: 
    {
      return( <IncidentRegistrationsView databaseQuery={databaseQuery as DatabaseQuery<IncidentRegistrationIF>} /> );
    }
    case UnitDocument: 
    case HealthguardUnitDocument:
    {
      return(  <UnitsView databaseQuery={databaseQuery as DatabaseQuery<UnitIF>} /> );
    }
    case UserDocument: 
    case HealthguardUserDocument:
    {
      return( <UsersView databaseQuery={databaseQuery as DatabaseQuery<HealthguardUserIF>} /> );
    }
    case VaccineRegistrationDocument: 
    {
      return( <VaccineRegistrationsView databaseQuery={databaseQuery as DatabaseQuery<VaccineRegistrationIF>} /> );
    }
    default:
    {
      log.warn( "No database route found for database ", database );
      return null;
    }
  }
}

export const databaseTable = ( appContext : AppContextProps, databaseTableProps : DatabaseTableProps ) : JSX.Element | null => {

  if( databaseTableProps.databaseObserver.defaultDatabase()  == null ) {
    return null;
  }

  databaseTableProps.databaseObserver.setDatabases( 
    updateDatabases( appContext, databaseTableProps.databaseObserver.databases() ) );

  databaseTableProps.databaseObserver.setDatabaseFilters( 
    updateDatabaseFilters( appContext, databaseTableProps.databaseObserver.databaseFilters() ) );

  switch (databaseTableProps.databaseObserver.defaultDocumentName()) {

    case CategoryDocument: 
    case HealthguardCategoryDocument: 
    {
      return( <CategoriesTable {...databaseTableProps} /> );
    }
    case ChangeDocument: 
    {
      return( <ChangesTable {...databaseTableProps} /> );
    }
    case CompanyDocument: 
    case HealthguardCompanyDocument: 
    {
      return( <CompaniesTable {...databaseTableProps} /> );
    }
    case ConsentDocument: 
    {
      return(  <ConsentsTable {...databaseTableProps} /> );
    }
    case AlertDocument: 
    case HealthguardAlertDocument: 
    {
      return( <AlertsTable {...databaseTableProps}  /> );
    }
    case DeviceDocument: 
    case HealthguardDeviceDocument: 
    {
      return( <DevicesTable {...databaseTableProps} /> );
    }
    case HazardDocument: 
    {
      return(  <HazardsTable {...databaseTableProps}  /> );
    }
    case GatheringDocument: 
    {
      return( <GatheringsTable {...databaseTableProps } /> );
    }
    case RiskDocument: 
    {
      return( <RisksTable {...databaseTableProps } /> );
    }
    case ResidenceDocument: 
    {
      return(  <ResidencesTable {...databaseTableProps}  /> );
    }
    case KeyDocument: 
    {
      return( <KeysTable {  ...databaseTableProps} /> ); 
    }
    case LocationDocument: 
    case HealthguardLocationDocument: 
    {
      return( <LocationsTable {...databaseTableProps}  /> );
    }
    case MeasureDocument: 
    {
      return(  <MeasuresTable {...databaseTableProps} /> );
    }
    case MessageDocument: 
    {
      return( <MessagesTable {  ...databaseTableProps} /> ); 
    }
    case ProjectDocument: 
    case HealthguardProjectDocument:
    {
      return(  <ProjectsTable {...databaseTableProps}  /> );
    }
    case QuarantineRegistrationDocument: 
    {
      return(  <QuarantineRegistrationsTable {...databaseTableProps}  /> );
    }
    case SickLeaveRegistrationDocument: 
    {
      return( <SickLeaveRegistrationsTable {...databaseTableProps}  />  );
    }
    case SubscriptionDocument: 
    {
      return(  <SubscriptionsTable {...databaseTableProps}  /> );
    }
    case RegistrationDocument: 
    {
      return( <RegistrationsTable {...databaseTableProps}  /> );
    }
    case SymptomRegistrationDocument: 
    {
      return( <SymptomRegistrationsTable {...databaseTableProps}  /> );
    }
    case TerminalDocument: 
    {
      return( <TerminalsTable {  ...databaseTableProps} /> ); 
    }
    case TestRegistrationDocument: 
    {
      return( <TestRegistrationsTable {...databaseTableProps}  /> );
    }
    case IncidentRegistrationDocument: 
    {
      return( <IncidentRegistrationsTable {...databaseTableProps}  /> );
    }
    case UnitDocument: 
    case HealthguardUnitDocument:
    {
      return(  <UnitsTable {...databaseTableProps}  /> );
    }
    case UserDocument: 
    case HealthguardUserDocument:
    {
      return( <UsersTable {...databaseTableProps}  /> );
    }
    case VaccineRegistrationDocument: 
    {
      return( <VaccineRegistrationsTable {...databaseTableProps}  /> ); 
    }
    default:
    {
      log.warn( "No database route found for database", databaseTableProps.databaseObserver );
      return null;
    }
  }
}

export const databaseList = ( appContext : AppContextProps, databaseListProps : DatabaseListProps ) : JSX.Element | null => {

  if( databaseListProps.databaseObserver.defaultDatabase()  == null ) {
    return null;
  }

  databaseListProps.databaseObserver.setDatabases( 
    updateDatabases( appContext, databaseListProps.databaseObserver.databases() ) );

  databaseListProps.databaseObserver.setDatabaseFilters( 
    updateDatabaseFilters( appContext, databaseListProps.databaseObserver.databaseFilters() ) );

  switch (databaseListProps.databaseObserver.defaultDocumentName()) {

    case CategoryDocument: 
    case HealthguardCategoryDocument: 
    {
      return( <CategoriesList {...databaseListProps} /> );
    }
    case ChangeDocument: 
    {
      return( <ChangesList {...databaseListProps} /> );
    }
    case CompanyDocument: 
    case HealthguardCompanyDocument: 
    {
      return( <CompaniesList {...databaseListProps} /> );
    }
    case ConsentDocument: 
    {
      return(  <ConsentsList {...databaseListProps} /> );
    }
    case AlertDocument: 
    case HealthguardAlertDocument: 
    {
      return( <AlertsList {...databaseListProps}  /> );
    }
    case DeviceDocument: 
    case HealthguardDeviceDocument: 
    {
      return( <DevicesList {...databaseListProps} /> );
    }
    case HazardDocument: 
    {
      return(  <HazardsList {...databaseListProps}  /> );
    }
    case GatheringDocument: 
    {
      return( <GatheringsList {...databaseListProps } /> );
    }
    case RiskDocument: 
    {
      return( <RisksList {...databaseListProps } /> );
    }
    case ResidenceDocument: 
    {
      return(  <ResidencesList {...databaseListProps}  /> );
    }
    case KeyDocument: 
    {
      return( <KeysList {  ...databaseListProps} /> ); 
    }
    case LocationDocument: 
    case HealthguardLocationDocument: 
    {
      return( <LocationsList {...databaseListProps}  /> );
    }
    case MeasureDocument: 
    {
      return(  <MeasuresList {...databaseListProps} /> );
    }
    case MessageDocument: 
    {
      return( <MessagesList {  ...databaseListProps} /> ); 
    }
    case ProjectDocument: 
    case HealthguardProjectDocument:
    {
      return(  <ProjectsList {...databaseListProps}  /> );
    }
    case QuarantineRegistrationDocument: 
    {
      return(  <QuarantineRegistrationsList {...databaseListProps}  /> );
    }
    case SickLeaveRegistrationDocument: 
    {
      return( <SickLeaveRegistrationsList {...databaseListProps}  />  );
    }
    case SubscriptionDocument: 
    {
      return(  <SubscriptionsList {...databaseListProps}  /> );
    }
    case RegistrationDocument: 
    {
      return( <RegistrationsList {...databaseListProps}  /> );
    }
    case SymptomRegistrationDocument: 
    {
      return( <SymptomRegistrationsList {...databaseListProps}  /> );
    }
    case TerminalDocument: 
    {
      return( <TerminalsList {  ...databaseListProps} /> ); 
    }
    case TestRegistrationDocument: 
    {
      return( <TestRegistrationsList {...databaseListProps}  /> );
    }
    case IncidentRegistrationDocument: 
    {
      return( <IncidentRegistrationsList {...databaseListProps}  /> );
    }
    case UnitDocument: 
    case HealthguardUnitDocument:
    {
      return(  <UnitsList {...databaseListProps}  /> );
    }
    case UserDocument: 
    case HealthguardUserDocument:
    {
      return( <UsersList {...databaseListProps}  /> );
    }
    case VaccineRegistrationDocument: 
    {
      return( <VaccineRegistrationsList {...databaseListProps}  /> );
    }
    default:
    {
      log.warn( "No database route found for database observer", databaseListProps.databaseObserver );
      return null;
    }
  }
}

export const databaseCard = ( appContext : AppContextProps, databaseCardProps : DatabaseCardProps ) : JSX.Element | null => {

  if( databaseCardProps.databaseObserver.defaultDatabase()  == null ) {
    return null;
  }

  databaseCardProps.databaseObserver.setDatabases( 
    updateDatabases( appContext, databaseCardProps.databaseObserver.databases() ) );

  databaseCardProps.databaseObserver.setDatabaseFilters( 
    updateDatabaseFilters( appContext, databaseCardProps.databaseObserver.databaseFilters() ) );

  switch (databaseCardProps.databaseObserver.defaultDocumentName()) {

    case CategoryDocument: 
    case HealthguardCategoryDocument: 
    {
      return( <CategoriesCard { ...databaseCardProps} /> );
    }
    case ChangeDocument: 
    {
      return( <ChangesCard { ...databaseCardProps} /> );
    }
    case CompanyDocument: 
    case HealthguardCompanyDocument: 
    {
      return( <CompaniesCard { ...databaseCardProps} /> );
    }
    case ConsentDocument: 
    {
      return(  <ConsentsCard { ...databaseCardProps} /> );
    }
    case AlertDocument: 
    case HealthguardAlertDocument: 
    {
      return( <AlertsCard { ...databaseCardProps}  /> );
    }
    case DeviceDocument: 
    case HealthguardDeviceDocument: 
    {
      return( <DevicesCard { ...databaseCardProps} /> );
    }
    case HazardDocument: 
    {
      return(  <HazardsCard { ...databaseCardProps}  /> );
    }
    case GatheringDocument: 
    {
      return( <GatheringsCard { ...databaseCardProps } /> );
    }
    case RiskDocument: 
    {
      return( <RisksCard { ...databaseCardProps } /> );
    }
    case ResidenceDocument: 
    {
      return(  <ResidencesCard { ...databaseCardProps}  /> );
    }
    case KeyDocument: 
    {
      return( <KeysCard {  ...databaseCardProps} /> );  
    }
    case LocationDocument: 
    case HealthguardLocationDocument: 
    {
      return( <LocationsCard { ...databaseCardProps}  /> );
    }
    case MeasureDocument: 
    {
      return(  <MeasuresCard { ...databaseCardProps} /> );
    }
    case MessageDocument: 
    {
      return( <MessagesCard {  ...databaseCardProps} /> );  
    }
    case ProjectDocument: 
    case HealthguardProjectDocument:
    {
      return(  <ProjectsCard { ...databaseCardProps}  /> );
    }
    case QuarantineRegistrationDocument: 
    {
      return(  <QuarantineRegistrationsCard { ...databaseCardProps}  /> );
    }
    case SickLeaveRegistrationDocument: 
    {
      return( <SickLeaveRegistrationsCard { ...databaseCardProps}  />  );
    }
    case SubscriptionDocument: 
    {
      return(  <SubscriptionsCard { ...databaseCardProps}  /> );
    }
    case RegistrationDocument: 
    {
      return( <RegistrationsCard { ...databaseCardProps}  /> );
    }
    case SymptomRegistrationDocument: 
    {
      return( <SymptomRegistrationsCard { ...databaseCardProps}  /> );
    }
    case TerminalDocument: 
    {
      return( <TerminalsCard {  ...databaseCardProps} /> ); 
    }
    case TestRegistrationDocument: 
    {
      return( <TestRegistrationsCard { ...databaseCardProps}  /> );
    }
    case IncidentRegistrationDocument: 
    {
      return( <IncidentRegistrationsCard { ...databaseCardProps}  /> );
    }
    case UnitDocument: 
    case HealthguardUnitDocument:
    {
      return(  <UnitsCard { ...databaseCardProps}  /> );
    }
    case UserDocument: 
    case HealthguardUserDocument:
    {
      return( <UsersCard { ...databaseCardProps}  /> );
    }
    case VaccineRegistrationDocument: 
    {
      return( <VaccineRegistrationsCard { ...databaseCardProps}  /> );
    }
    default:
    {
      log.warn( "No database route found for database observer", databaseCardProps.databaseObserver );
      return null;
    }
  }
}

export const databaseChart = ( appContext : AppContextProps, databaseChartProps : DatabaseChartProps ) : JSX.Element | null => {

  if( databaseChartProps.databaseObserver.defaultDatabase() == null ) {
    return null;
  }

  databaseChartProps.databaseObserver.setDatabases( 
      updateDatabases( appContext, databaseChartProps.databaseObserver.databases() ) );
  
  databaseChartProps.databaseObserver.setDatabaseFilters( 
      updateDatabaseFilters( appContext, databaseChartProps.databaseObserver.databaseFilters() ) );
  
  switch (databaseChartProps.databaseObserver.defaultDocumentName()) {

    case CategoryDocument: 
    case HealthguardCategoryDocument: 
    {
      return( <CategoriesChart {  ...databaseChartProps} /> );
    }
    case ChangeDocument: 
    {
      return( <ChangesChart {  ...databaseChartProps} /> );
    }
    case CompanyDocument: 
    case HealthguardCompanyDocument: 
    {
      return( <CompaniesChart {  ...databaseChartProps} /> );
    }
    case ConsentDocument: 
    {
      return(  <ConsentsChart {  ...databaseChartProps} /> );
    }
    case AlertDocument: 
    case HealthguardAlertDocument: 
    {
      return( <AlertsChart {  ...databaseChartProps}  /> );
    }
    case DeviceDocument: 
    case HealthguardDeviceDocument: 
    {
      return( <DevicesChart {  ...databaseChartProps} /> );
    }
    case HazardDocument: 
    {
      return(  <HazardsChart {  ...databaseChartProps}  /> );
    }
    case GatheringDocument: 
    {
      return( <GatheringsChart {  ...databaseChartProps } /> );
    }
    case RiskDocument: 
    {
      return( <RisksChart {  ...databaseChartProps } /> );
    }
    case ResidenceDocument: 
    {
      return(  <ResidencesChart {  ...databaseChartProps}  /> );
    }
    case KeyDocument: 
    {
      return( <KeysChart {  ...databaseChartProps} /> ); 
    }
    case LocationDocument: 
    case HealthguardLocationDocument: 
    {
      return( <LocationsChart {  ...databaseChartProps}  /> );
    }
    case MeasureDocument: 
    {
      return(  <MeasuresChart {  ...databaseChartProps} /> );
    }
    case MessageDocument: 
    {
      return( <MessagesChart {  ...databaseChartProps} /> ); 
    }
    case ProjectDocument: 
    case HealthguardProjectDocument:
    {
      return(  <ProjectsChart {  ...databaseChartProps}  /> );
    }
    case QuarantineRegistrationDocument: 
    {
      return(  <QuarantineRegistrationsChart {  ...databaseChartProps}  /> );
    }
    case SickLeaveRegistrationDocument: 
    {
      return( <SickLeaveRegistrationsChart {  ...databaseChartProps}  />  );
    }
    case SubscriptionDocument: 
    {
      return(  <SubscriptionsChart {  ...databaseChartProps}  /> );
    }
    case RegistrationDocument: 
    {
      return( <RegistrationsChart {  ...databaseChartProps}  /> );
    }
    case SymptomRegistrationDocument: 
    {
      return( <SymptomRegistrationsChart {  ...databaseChartProps}  /> );
    }
    case TerminalDocument: 
    {
      return( <TerminalsChart {  ...databaseChartProps} /> ); 
    }
    case TestRegistrationDocument: 
    {
      return( <TestRegistrationsChart {  ...databaseChartProps}  /> );
    }
    case IncidentRegistrationDocument: 
    {
      return( <IncidentRegistrationsChart {  ...databaseChartProps}  /> );
    }
    case UnitDocument: 
    case HealthguardUnitDocument:
    {
      return(  <UnitsChart {  ...databaseChartProps}  /> );
    }
    case UserDocument: 
    case HealthguardUserDocument:
    {
      return( <UsersChart {  ...databaseChartProps}  /> );
    }
    case VaccineRegistrationDocument: 
    {
      return( <VaccineRegistrationsChart {  ...databaseChartProps}  /> );
    }
    default:
    {
      log.warn( "No database route found for database observer", databaseChartProps.databaseObserver );
      return null;
    }
  }
}

export const databaseGraph = ( appContext : AppContextProps, databaseGraphProps : DatabaseGraphProps ) : JSX.Element | null => {

  if( databaseGraphProps.databaseObserver.defaultDatabase()  == null ) {
    return null;
  }

  databaseGraphProps.databaseObserver.setDatabases( 
    updateDatabases( appContext, databaseGraphProps.databaseObserver.databases() ) );
  
  databaseGraphProps.databaseObserver.setDatabaseFilters( 
    updateDatabaseFilters( appContext, databaseGraphProps.databaseObserver.databaseFilters() ) );

  switch (databaseGraphProps.databaseObserver.defaultDocumentName()) {

    case CategoryDocument: 
    case HealthguardCategoryDocument: 
    {
      return( <CategoriesGraph {  ...databaseGraphProps} /> );
    }
    case ChangeDocument: 
    {
      return( <ChangesGraph {  ...databaseGraphProps} /> );
    }
    case CompanyDocument: 
    case HealthguardCompanyDocument: 
    {
      return( <CompaniesGraph {  ...databaseGraphProps} /> );
    }
    case ConsentDocument: 
    {
      return(  <ConsentsGraph {  ...databaseGraphProps} /> );
    }
    case AlertDocument: 
    case HealthguardAlertDocument: 
    {
      return( <AlertsGraph {  ...databaseGraphProps}  /> );
    }
    case DeviceDocument: 
    case HealthguardDeviceDocument: 
    {
      return( <DevicesGraph {  ...databaseGraphProps} /> );
    }
    case HazardDocument: 
    {
      return(  <HazardsGraph {  ...databaseGraphProps}  /> );
    }
    case GatheringDocument: 
    {
      return( <GatheringsGraph {  ...databaseGraphProps } /> );
    }
    case RiskDocument: 
    {
      return( <RisksGraph {  ...databaseGraphProps } /> );
    }
    case ResidenceDocument: 
    {
      return(  <ResidencesGraph {  ...databaseGraphProps}  /> );
    }
    case KeyDocument: 
    {
      return( <KeysGraph {  ...databaseGraphProps} /> );
    }
    case LocationDocument: 
    case HealthguardLocationDocument: 
    {
      return( <LocationsGraph {  ...databaseGraphProps}  /> );
    }
    case MeasureDocument: 
    {
      return(  <MeasuresGraph {  ...databaseGraphProps} /> );
    }
    case MessageDocument: 
    {
      return( <MessagesGraph {  ...databaseGraphProps} /> );
    }
    case ProjectDocument: 
    case HealthguardProjectDocument:
    {
      return(  <ProjectsGraph {  ...databaseGraphProps}  /> );
    }
    case QuarantineRegistrationDocument: 
    {
      return(  <QuarantineRegistrationsGraph {  ...databaseGraphProps}  /> );
    }
    case SickLeaveRegistrationDocument: 
    {
      return( <SickLeaveRegistrationsGraph {  ...databaseGraphProps}  />  );
    }
    case SubscriptionDocument: 
    {
      return(  <SubscriptionsGraph {  ...databaseGraphProps}  /> );
    }
    case RegistrationDocument: 
    {
      return( <RegistrationsGraph {  ...databaseGraphProps}  /> );
    }
    case SymptomRegistrationDocument: 
    {
      return( <SymptomRegistrationsGraph {  ...databaseGraphProps}  /> );
    }
    case TerminalDocument: 
    {
      return( <TerminalsGraph {  ...databaseGraphProps} /> );
    }
    case TestRegistrationDocument: 
    {
      return( <TestRegistrationsGraph {  ...databaseGraphProps}  /> );
    }
    case IncidentRegistrationDocument: 
    {
      return( <IncidentRegistrationsGraph {  ...databaseGraphProps}  /> );
    }
    case UnitDocument: 
    case HealthguardUnitDocument:
    {
      return(  <UnitsGraph {  ...databaseGraphProps}  /> );
    }
    case UserDocument: 
    case HealthguardUserDocument:
    {
      return( <UsersGraph {  ...databaseGraphProps}  /> );
    }
    case VaccineRegistrationDocument: 
    {
      return( <VaccineRegistrationsGraph {  ...databaseGraphProps}  /> );
    }
    default:
    {
      log.warn( "No database route found for database observer", databaseGraphProps.databaseObserver );
      return null;
    }
  }
}

export const databaseMap = ( appContext : AppContextProps, databaseMapProps : DatabaseMapProps ) : JSX.Element | null => {

  if( databaseMapProps.databaseObserver.defaultDatabase()  == null ) {
    return null;
  }

  databaseMapProps.databaseObserver.setDatabases( 
    updateDatabases( appContext, databaseMapProps.databaseObserver.databases() ) );
    
  databaseMapProps.databaseObserver.setDatabaseFilters( 
    updateDatabaseFilters( appContext, databaseMapProps.databaseObserver.databaseFilters() ) );
  
  switch (databaseMapProps.databaseObserver.defaultDocumentName()) {

    case DeviceDocument: 
    case HealthguardDeviceDocument: 
    {
      return( <DevicesMap {  ...databaseMapProps} /> );
    }
    case AlertDocument: 
    case HealthguardAlertDocument: 
    {
      return(  <AlertsMap {  ...databaseMapProps}  /> ); 
    }
    case ResidenceDocument: 
    {
      return(  <ResidencesMap {  ...databaseMapProps}  /> );
    }
    case LocationDocument: 
    case HealthguardLocationDocument: 
    {
      return( <LocationsMap {  ...databaseMapProps}  /> );
    }
    default:
    {
      log.warn( "No database route found for database observer", databaseMapProps.databaseObserver );
      return null;
    }
  }
}

export const databaseTree = ( appContext : AppContextProps, databaseTreeProps : DatabaseTreeProps ) : JSX.Element | null => {

  if( databaseTreeProps.databaseObserver.defaultDatabase()  == null ) {
    return null;
  }

  databaseTreeProps.databaseObserver.setDatabases( 
    updateDatabases( appContext, databaseTreeProps.databaseObserver.databases() ) );
    
  databaseTreeProps.databaseObserver.setDatabaseFilters( 
    updateDatabaseFilters( appContext, databaseTreeProps.databaseObserver.databaseFilters() ) );
  
  switch (databaseTreeProps.databaseObserver.defaultDocumentName()) {

    case UnitDocument: 
    case HealthguardUnitDocument: 
    {
      return( <UnitsTree {  ...databaseTreeProps}  /> ); 
    }
    default:
    {
      log.warn( "No database route found for database observer", databaseTreeProps.databaseObserver );
      return null;
    }
  }
}


export const updateDatabases = ( 
  appContext : AppContextProps, 
  databases?: DatabaseIF<DatabaseDocumentIF>[] ) : DatabaseIF<DatabaseDocumentIF>[] | undefined => {

  if( databases == null ) {
    return undefined;
  }

  let result = databases;

  if( databases.length === 1 ) {

    switch( databases![0].collectionName()) {

      case CategoriesCollection:
      case GatheringsCollection:
      case RisksCollection:
      case LocationsCollection:
      case MeasuresCollection:
      case ProjectsCollection:
  
        result = databases.concat( Factory.get().databaseService.databaseFactory.parentCollections( databases[0] ) );      
        break;
    }
  }

  updateDatabaseUserAccesses( appContext, result );
  return result;
}

export const updateDatabaseFilters = (
  appContext: AppContextProps, 
  databaseFilters?: Map<string,DatabaseFilter> ) : Map<string,DatabaseFilter> | undefined => {

  //log.traceIn( "updateDatabaseFilters()" );

  
  let result : Map<string, DatabaseFilter>;

  if( databaseFilters != null ) {
    result = new Map<string, DatabaseFilter>( [...databaseFilters] );
  }
  else {
    result = new Map<string, DatabaseFilter>();
  }

  let changed = false;

  if( !result.has( UsersCollection ) ) {

   let ownerPropertyFilter;

    switch (appContext.currentHomePath) {

      case HomePaths.AdminHomePath:
      case HomePaths.CompanyHomePath:
      case HomePaths.UnitHomePath:
        break;
  
      case HomePaths.UserHomePath:
      {
        const usersfilter = new Map<string,ReferenceHandle<HealthguardUserIF>>();

        usersfilter.set( 
          appContext.currentUser!.referenceHandle()!.path, 
          appContext.currentUser!.referenceHandle()! as ReferenceHandle<HealthguardUserIF> );
        
        if( appContext.currentMinors != null ) {
          for( const currentMinor of appContext.currentMinors.values() ) {
            usersfilter.set( currentMinor.referenceHandle().path, 
            currentMinor.referenceHandle() as ReferenceHandle<HealthguardUserIF> );
          }
        }

        ownerPropertyFilter = {
          property: UsersCollection,
          comparator: Comparators.IncludesAny as Comparator,
          value: usersfilter
        } as DatabaseFilter  

        break;
      }
      default:
        throw new Error("Unexpected home path in app context");  
    }
  
    if (ownerPropertyFilter != null) {
  
      result.set(ownerPropertyFilter.property, ownerPropertyFilter);

      changed = true;

      //log.debug( "updateDatabaseFilters()", "Updated owner" );
    }
  
  }
 
  if( !changed ) {
    //log.traceOut( "updateDatabaseFilters()", "No change" );
    return databaseFilters;
  }

  if( result.size === 0 ) {
    //log.traceOut( "updateDatabaseFilters()", "Empty" );
    return undefined;
  }
  

  //log.traceOut( "updateDatabaseFilters()", result );
  return result; 

}

export const updateDatabaseUserAccesses = ( 
  appContext : AppContextProps, 
  databases?: DatabaseIF<DatabaseDocumentIF>[] ) : void => {

  if( appContext.currentHomePath !== HomePaths.UserHomePath ) {
    return;
  }

  if( appContext.currentCompany == null ) {
    return;
  }

  if( databases == null ) {
    return;
  }

  for( const database of databases ) {

    if( database.collectionName()!== RegistrationsCollection ) {
      continue;
    }

    if( database.defaultDocumentName() === RegistrationDocument ) {
      continue;
    }

    const registrationUserAccess = appContext.currentCompany.subcollectionUserAccess(   
      database.defaultDocumentName()
    );

    const databaseUserAccess = UserAccess.merge( database.userAccess(), registrationUserAccess );

    database.setUserAccess( databaseUserAccess );
  }
}



const styles = (theme: Theme) => createStyles({
  root: {
  }
});


interface MatchParams {
}

interface DatabaseRouterProps extends WithStyles<typeof styles>, WithTranslation, RouteComponentProps<MatchParams> {
}

interface DatabaseRouterState { // Document View Props

}

class DatabaseRouter extends React.PureComponent<DatabaseRouterProps,DatabaseRouterState> {

  constructor( props: DatabaseRouterProps ) {
    
    super(props);

    this.state = { 
    } as DatabaseRouterState;

    this.redirectToHome = this.redirectToHome.bind(this);
    this.routeToDatabaseView = this.routeToDatabaseView.bind(this);


    //log.traceInOut( "constructor()" );
  }

  redirectToHome() {
    log.traceInOut( "redirectToHome()");

    const appContext = this.context as AppContextProps;

    return( <Redirect to={{ pathname: appContext.currentHomePath }} /> );
  }


  routeToDatabaseView( url: string ) {
    log.traceIn( "routeToDatabaseView()", {url});

    try {  

      const appContext = this.context as AppContextProps;


      const database = 
        Factory.get().databaseService.databaseFactory.databaseFromUrl(url);

      if( database == null ) {
        throw new Error( "Invalid database for path: " + url );
      }

      const databaseViewRoute = databaseView( appContext, database );

      if( databaseViewRoute != null ) {
        return( databaseViewRoute ); 
      }
      else {
        return this.redirectToHome();
      }

    } catch( error ) {
      log.warn( "routeToDatabaseView()", "Routing error", error ); 

      return this.redirectToHome();
    }
  }

  render() {
    log.traceIn( "render()", this.props.location.pathname, this.props.location.search );

    try {
      //const { classes } = this.props;

      let url = this.props.location.pathname;

      const query = new URLSearchParams(this.props.location.search);

      const documentName = query.get( DocumentNameKey ); 

      if( documentName != null ) {
        url += "?" + DocumentNameKey + "=" + documentName;
      }

      if( Factory.get().databaseService.databaseFactory.isUrlDatabase( url ) ) {

        return this.routeToDatabaseView( url );
      }
      else {
        const redirectHome = this.redirectToHome();
        log.traceOut( "render()", "home");
        return redirectHome;
      }
    } catch( error ) {
      log.warn( "render()", "Routing error", error );

      return this.redirectToHome();
    }
  }
}

DatabaseRouter.contextType = AppContext; 

export default withRouter(withTranslation()(withStyles(styles)(DatabaseRouter)));

