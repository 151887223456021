import React from 'react';
import ReactDOMServer from 'react-dom/server';

import {unescape} from 'html-escaper';

import { withTranslation, WithTranslation } from 'react-i18next';
import { CollectionDefinition } from '../../services/database/api/definitions';
import { HealthguardCollectionDefinition } from '../../healthguard/api/healthguardDefinitions';
import { TranslationKey } from '../../services/common/api/translatorIF';


export const translatedCollectionName = ( name : string, my? : boolean  ) => {
  return unescape( ReactDOMServer.renderToString( <ModifiedCollectionName name={name} my={my} />));
}

interface CollectionNameProps extends WithTranslation {
  name : string,
  my?: boolean
}


interface CollectionNameState { 
}

class CollectionName extends React.PureComponent<CollectionNameProps,CollectionNameState> {

  constructor( props: CollectionNameProps ) {
    
    super(props);

    this.state = { 
    } as CollectionNameState;

    //log.traceInOut( "constructor()" );
  }

  
  render() {

    //log.traceInOut( "render()" );

    let result;

    let translationKey = this.props.name + ":" + (!!this.props.my ? TranslationKey.MyCollection : TranslationKey.Collection);  
  
    if( this.props.i18n.exists( translationKey) ) {
  
      result = this.props.t( translationKey );
      //log.traceOut( "translatedCollectionName()", result ); 
      return result;
    }
  
    translationKey = HealthguardCollectionDefinition + ":" + TranslationKey.Values + ":" + this.props.name;
  
    if( this.props.i18n.exists( translationKey) ) {
  
      result = this.props.t( translationKey );
      //log.traceOut( "translatedCollectionName()", result ); 
      return result;
    }
  
    translationKey = CollectionDefinition + ":" + TranslationKey.Values + ":" + this.props.name;
  
    if( this.props.i18n.exists( translationKey) ) {
  
      result = this.props.t( translationKey );
      //log.traceOut( "translatedCollectionName()", result ); 
      return result;
    }
  
    //log.traceOut( "translatedCollectionName()", "not found", documentName ); 
    return null;
  }
}

const ModifiedCollectionName = withTranslation()(CollectionName);

export default ModifiedCollectionName;

