import { TestResult, TestResults } from '../../api/definitions/testResult';
import { IncidentRegistration } from '../documents/incidentRegistration';
import { QuarantineRegistration } from '../documents/quarantineRegistration';
import { SickLeaveRegistration } from '../documents/sickLeaveRegistration';
import { SymptomRegistration } from '../documents/symptomRegistration';
import { TestRegistration } from '../documents/testRegistration';
import { Registration } from '../documents/registration';

import { HealthguardUser } from '../documents/healthguardUser';
import { IncidentManager } from './incidentManager';
import { log } from '../../../services/database/framework/databaseService';
import { IncidentRegistrationDocument, QuarantineRegistrationDocument, SickLeaveRegistrationDocument, SymptomRegistrationDocument, TestRegistrationDocument, VaccineRegistrationDocument } from '../../api/registrationDocuments';
import { VaccineRegistration } from '../documents/vaccineRegistration';
import { ReferenceHandle } from '../../../services/database/api/core/referenceHandle';
import { HealthguardLocation } from '../documents/healthguardLocation';
import { HealthguardCategory } from '../documents/healthguardCategory';
import { HealthguardProject } from '../documents/healthguardProject';
import { Gathering } from '../documents/gathering';


export class RegistrationManager  {

    static getInstance() : RegistrationManager {
        if( RegistrationManager._instance == null ) {
            RegistrationManager._instance = new RegistrationManager();
        }
        return RegistrationManager._instance;
    }

    async handleNewRegistration( createdRegistration: Registration ) {

        try {
            log.traceIn("handleNewRegistration()");

            const user = await createdRegistration.user.document() as HealthguardUser;

            if( user == null ) {
                log.traceOut( "handleNewRegistration()", "New registration has no user");
                return;
            }  
            
            user.measures.referenceHandles().forEach( userMeasure => {
                createdRegistration.measures.setDocument( userMeasure );
            });

            user.locations.referenceHandles().forEach( userLocation => {
                createdRegistration.locations.setDocument( userLocation as ReferenceHandle<HealthguardLocation> );  
            });

            user.categories.referenceHandles().forEach( userCategory => {
                createdRegistration.categories.setDocument( userCategory as ReferenceHandle<HealthguardCategory> );
            });

            user.projects.referenceHandles().forEach( userProject => {
                createdRegistration.projects.setDocument( userProject as ReferenceHandle<HealthguardProject> );
            });

            const userGatherings = await user.gatherings.documents();

            for( const userGathering of userGatherings.values() ) {

                const registrationDate = createdRegistration.startDate.value();

                const trackUsersFromDate = userGathering.trackUsersFrom.value();

                const trackUsersToDate = userGathering.trackUsersTo.value();
                
                if( registrationDate != null && trackUsersFromDate != null && trackUsersToDate != null ) {

                    if (trackUsersFromDate.getTime() <= registrationDate.getTime() &&
                        registrationDate.getTime() <= trackUsersToDate.getTime()) {

                        createdRegistration.gatherings.setDocument( 
                            userGathering.referenceHandle() as ReferenceHandle<Gathering> );
                    }
                }
            } 

            if( createdRegistration.documentName() === IncidentRegistrationDocument) {

                await this.handleNewIncidentRegistration( user,
                    createdRegistration as IncidentRegistration );
            }
            else if( createdRegistration.documentName() === QuarantineRegistrationDocument ) {
                
                await this.handleNewQuarantineRegistration( user,
                    createdRegistration as QuarantineRegistration );
            }
            else if( createdRegistration.documentName() === SickLeaveRegistrationDocument ) {

                await this.handleNewSickLeaveRegistration( user,
                    createdRegistration as SickLeaveRegistration );
            }
            else if( createdRegistration.documentName() === SymptomRegistrationDocument ) {
                
                await this.handleNewSymptomRegistration( user,
                    createdRegistration as SymptomRegistration );
            }
            else if( createdRegistration.documentName() === TestRegistrationDocument ) {

                await this.handleNewTestRegistration( user,
                    createdRegistration as TestRegistration );
            }
            else if( createdRegistration.documentName() === VaccineRegistrationDocument ) {

                await this.handleNewVaccineRegistration( user,
                    createdRegistration as VaccineRegistration );
            }

            if( createdRegistration.isChanged() ) {

                await createdRegistration.update();

                log.debug("handleNewRegistration()", "updated new registration", createdRegistration.referenceHandle().title );
            }
               
            log.traceOut("handleNewRegistration()");

        } catch (error) {
            log.warn("Error handling registration create", error);

            log.traceOut("handleNewRegistration()", "error");
        }
    }


    async handleNewIncidentRegistration( user : HealthguardUser, incidentRegistration : IncidentRegistration ) : Promise<boolean> {

        try {
            log.traceIn("handleNewIncidentRegistration()");

            // Handled by incident manager

            let registrationUpdated = 
                await IncidentManager.getInstance().handleIncidentCreated( user, incidentRegistration );

            log.traceOut("handleNewIncidentRegistration()", "registrationUpdated", registrationUpdated );
            return registrationUpdated;

        } catch (error) {
            log.warn("Error handling new incident registration", error);

            throw new Error( (error as any).message );
        }
    }

    async handleNewQuarantineRegistration( user : HealthguardUser, quarantineRegistration : QuarantineRegistration ) : Promise<boolean>  {

        try {
            log.traceIn("handleNewQuarantineRegistration()");

            let registrationUpdated = false;

            let incident = await IncidentManager.getInstance().latestActiveIncident( user, quarantineRegistration );

            if( incident == null ) {

                log.traceOut("handleNewQuarantineRegistration()", "No active incidents");
                return false;
            }
            else {

                registrationUpdated = IncidentManager.getInstance().syncRegistrationAndIncident( user, quarantineRegistration, incident );
            }

            incident.quarantineRegistrations.setDocument( 
                quarantineRegistration.referenceHandle() as ReferenceHandle<QuarantineRegistration> );

            await incident.update();

            log.traceOut("handleNewQuarantineRegistration()", "registrationUpdated", registrationUpdated );
            return registrationUpdated;

         } catch (error) {
            log.warn("Error handling new quarantine registration", error);

            throw new Error( (error as any).message );
        }
    }

    async handleNewSickLeaveRegistration( user : HealthguardUser, sickLeaveRegistration : SickLeaveRegistration ) : Promise<boolean> {

        try {
            log.traceIn("handleNewSickLeaveRegistration()");

            let registrationUpdated = false;

            let incident = await IncidentManager.getInstance().latestActiveIncident( user, sickLeaveRegistration );

            if( incident == null ) {

                incident = await IncidentManager.getInstance().createIncidentFromRegistration( user, sickLeaveRegistration );

                registrationUpdated = true;
            }
            else {

                registrationUpdated = IncidentManager.getInstance().syncRegistrationAndIncident( user, sickLeaveRegistration, incident );
            }

            incident.sickLeaveRegistrations.setDocument( 
                sickLeaveRegistration.referenceHandle() as ReferenceHandle<SickLeaveRegistration> );

            await incident.update();

            log.traceOut("handleNewSickLeaveRegistration()", "registrationUpdated", registrationUpdated);
            return registrationUpdated;

        } catch (error) {
            log.warn("Error handling new sick leave registration", error);

            throw new Error( (error as any).message );
        }
    }

    async handleNewSymptomRegistration( user : HealthguardUser, symptomRegistration : SymptomRegistration ) : Promise<boolean> {

        try {
            log.traceIn("handleNewSymptomRegistration()");

            let registrationUpdated = false;

            let incident = await IncidentManager.getInstance().latestActiveIncident( user, symptomRegistration );

            if( incident == null ) {
                log.traceOut("handleNewQuarantineRegistration()", "No active incident");
                return false;
            }
            else {

                registrationUpdated = IncidentManager.getInstance().syncRegistrationAndIncident( user, symptomRegistration, incident );
            }


            incident.symptomRegistrations.setDocument( 
                symptomRegistration.referenceHandle() as ReferenceHandle<SymptomRegistration> );

            await incident.update();

            log.traceOut("handleNewSymptomRegistration()", "registrationUpdated", registrationUpdated );
            return registrationUpdated;

        } catch (error) {
            log.warn("Error handling new symptom registration", error);

            throw new Error( (error as any).message );
        }
    }

    async handleNewTestRegistration( user : HealthguardUser, testRegistration : TestRegistration ) : Promise<boolean> {

        try {
            log.traceIn("handleNewTestRegistration()");

            if( testRegistration.startDate.compareTo( user.lastTestDate ) > 0 ) {

                user.lastTestResult.setValue( testRegistration.testResult.value() );

                user.lastTestDate.setValue( testRegistration.startDate.value() );

                await user.update();
            }

            let registrationUpdated = false;

            let incident = await IncidentManager.getInstance().latestActiveIncident( user, testRegistration );

            if( incident == null ) {

                if( testRegistration.testResult.compareValue( TestResults.Positive as TestResult ) !== 0  ) {

                    log.traceOut("handleNewTestRegistration()", "No existing incident for non positive test");
                    return false;
                }

                incident = await IncidentManager.getInstance().createIncidentFromRegistration( user, testRegistration );

                registrationUpdated = true;
            }
            else {

                registrationUpdated = IncidentManager.getInstance().syncRegistrationAndIncident( user, testRegistration, incident );
            }

            incident.testRegistrations.setDocument( 
                testRegistration.referenceHandle() as ReferenceHandle<TestRegistration> );

            await incident.update();

            log.traceOut("handleNewTestRegistration()", "registrationUpdated", registrationUpdated );
            return registrationUpdated;

        } catch (error) {
            log.warn("Error handling new test registration", error);

            throw new Error( (error as any).message );
        }
    }

    async handleNewVaccineRegistration( user : HealthguardUser, vaccineRegistration : VaccineRegistration ) : Promise<boolean> {

        try {
            log.traceIn("handleNewVaccineRegistration()");

            if( vaccineRegistration.installment.compareTo( user.lastVaccineInstallment ) > 0 ) {

                user.lastVaccineInstallment.setValue( vaccineRegistration.installment.value() );

                user.lastVaccineDate.setValue( vaccineRegistration.startDate.value() );

                await user.update();
            }

            log.traceOut("handleNewVaccineRegistration()", "registrationUpdated", false );
            return false;

        } catch (error) {
            log.warn("Error handling new vaccine registration", error);

            throw new Error( (error as any).message );
        }
    }


    private static _instance? : RegistrationManager;

}

