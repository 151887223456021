import { UserIF } from "../../api/documents/userIF";
import { Residence } from "./residence";
import { Location } from "./location";
import { Category } from "./category";
import { log } from "../../framework/databaseService";
import { PropertiesSelector } from "../../api/core/propertiesSelector";
import { CollectionDatabase } from "../core/collectionDatabase";
import { ReferencesProperty } from "../properties/referencesProperty";
import { CollectionProperty } from "../properties/collectionProperty";
import { SubdocumentProperty } from "../properties/subdocumentProperty";
import { TextProperty } from "../properties/textProperty";
import { Settings } from "../subdocuments/settings";
import { AlertsCollection, CategoriesCollection, CompaniesCollection, DevicesCollection, LocationsCollection, ProjectsCollection, ResidencesCollection, UnitsCollection, UsersCollection } from "../../api/collections";
import { Company } from "./company";
import { UserManager } from "../managers/userManager";
import { Sex, Sexes } from "../../api/definitions/sex";
import { DateProperty } from "../properties/dateProperty";
import { DefinitionProperty } from "../properties/definitionProperty";
import { SexDefinition } from "../../api/definitions";
import { TenantProperty } from "../properties/tenantProperty";
import { OwnerProperty } from "../properties/ownerProperty";
import { Unit } from "./unit";
import { CompanyDocument, UnitDocument, UserDocument } from "../../api/documents";
import { Project } from "./project";
import { BooleanProperty } from "../properties/booleanProperty";
import { Device } from "./device";
import { Contactable } from "./contactable";
import { Alert } from "./alert";
import { Factory } from "../../../common/api/factory";
import { CollectionGroupDatabase } from "../core/collectionGroupDatabase";
import { Database } from "../core/database";
import { SymbolicOwnersProperty } from "../properties/symbolicOwnersProperty";
import { ReferenceProperty } from "../properties/referenceProperty";
import { ReferenceHandle } from "../../api/core/referenceHandle";

export abstract class User extends Contactable implements UserIF {

    constructor( userDocumentName : string, usersCollection : CollectionDatabase<User>, documentPath? : string  ) {

        super( userDocumentName, usersCollection, documentPath );

        try {
            this.startDate.editable = false;
            this.endDate.editable = false;

            this.company = new TenantProperty<Company>( this, CompaniesCollection, CompanyDocument );

            const company = this.company.emptyDocument() as Company;

            if( this.id.value() != null || company != null ) { 
                this.company.editable = false;
            }

            this.unit = new OwnerProperty<Unit>( this, UnitsCollection, UnitDocument ); // parent

            this.allowedSymbolicUnits = new ReferencesProperty<Unit>( this, 
                company != null ? 
                    () => [company.units.collectionGroup()] as CollectionGroupDatabase<Unit>[] : undefined );

            this.symbolicUnits = new SymbolicOwnersProperty<Unit>( this, 
                company != null ? 
                    () => [company.units.collectionGroup()] as CollectionGroupDatabase<Unit>[] : undefined,
                "symbolicUsers" ); 

            this.user = new OwnerProperty<User>( this, UsersCollection, UserDocument );

            this.symbolicUser = new SymbolicOwnersProperty<User>( this, 
                company != null ? 
                    () => [company.users.collectionGroup()] as CollectionGroupDatabase<User>[] : undefined,
                "symbolicUsers" ); 
            
            this.allowedSymbolicUser = new ReferenceProperty<User>( this, 
                company != null ? 
                    () => [company.users.collectionGroup()] as CollectionGroupDatabase<User>[] : undefined );

            this.users = new CollectionProperty<User>( this, UsersCollection, false );

            this.symbolicUsers = new ReferencesProperty<User>( this, 
                company != null ? 
                    () => [company.users.collectionGroup()] as CollectionGroupDatabase<User>[] : undefined,
                "symbolicUser" );

            if( !Factory.get().databaseService.currentCompany()?.enableParents.value() ) {
                this.user.hidden = true;
                this.symbolicUser.hidden = true;
                this.users.hidden = true;
                this.symbolicUsers.hidden = true;
            }

            this.email.encrypt = false;
            this.email.required = true;

            this.firstName = new TextProperty( this );
            this.firstName.required = true;

            this.middleName = new TextProperty( this );

            this.lastName = new TextProperty( this );
            this.lastName.required = true;

            this.dateOfBirth = new DateProperty( this );
            this.dateOfBirth.required = true;

            this.sex = new DefinitionProperty<Sex>( this, SexDefinition, Sexes ); 

            this.employeeId = new TextProperty( this ); 

            this.jobTitle = new TextProperty( this );

            this.managing = new ReferencesProperty<Unit>( this, 
                () => [this.parentCollectionGroup( UnitsCollection ) as CollectionGroupDatabase<Unit>],
                "manager" );
            this.managing.editable = false;

            this.assisting = new ReferencesProperty<Unit>( this, 
                () => [this.parentCollectionGroup( UnitsCollection ) as CollectionGroupDatabase<Unit>],
                "assistants" );
            this.assisting.editable = false;

            this.deputing = new ReferencesProperty<Unit>( this, 
                () => [this.parentCollectionGroup( UnitsCollection ) as CollectionGroupDatabase<Unit>],
                "deputies" );
            this.deputing.editable = false;

            this.projectManaging = new ReferencesProperty<Project>( this, 
                () => this.parentDatabases( ProjectsCollection, {nearestIsCollectionGroup: false} ) as Database<Project>[],
                "projectManager" );
            this.projectManaging.editable = false;

            this.projects = new ReferencesProperty<Project>( this, 
                () => this.parentDatabases( ProjectsCollection, {nearestIsCollectionGroup: false} ) as Database<Project>[],
                "users" );

            this.categories = new ReferencesProperty<Category>(this,
                () => this.parentDatabases( CategoriesCollection, {nearestIsCollectionGroup: false} ) as Database<Category>[],
                "users"); 

            this.locations = new ReferencesProperty<Location>( this, 
                () => this.parentDatabases( LocationsCollection, {nearestIsCollectionGroup: false} ) as Database<Location>[],
                "users" );  

            this.residences = new CollectionProperty<Residence>( this, ResidencesCollection, true );

            this.devices = new CollectionProperty<Device>( this, DevicesCollection, true );

            this.reportedAlerts = new ReferencesProperty<Alert>( this, 
                () => this.parentDatabases( AlertsCollection, {nearestIsCollectionGroup: true} ) as Database<Alert>[],
                "reportedBy" );  

            this.settings = new SubdocumentProperty<Settings>( this, new Settings( this, "settings") ); 
            this.settings.trackChanges = false;

            this.authenticationId = new TextProperty( this );
            this.authenticationId.editable = false; 
            this.authenticationId.encrypt = false; 

            this.disabled = new BooleanProperty( this );
            this.disabled.encrypt = false;
            
            //log.traceInOut( "constructor()", UsersCollection ); 

        } catch( error ) {

            log.warn( "constructor()", "Error initializing measure", error );
            
            throw new Error( (error as any).message );
        }
    }

    indexKey() : string {
        return UserDocument; 
    }

    referenceDateProperty() : DateProperty | undefined {
        return this.dateOfBirth;
    }

    changesPropertiesSelector() : PropertiesSelector {

        const documentPropertiesSelector = super.changesPropertiesSelector();

        return {
            excludePropertyKeys: documentPropertiesSelector.excludePropertyKeys!.concat(
                ["authenticationId"]),

            excludePropertyTypes: documentPropertiesSelector.excludePropertyTypes,

            includePropertyKeys: documentPropertiesSelector.includePropertyKeys,

            includePropertyTypes: documentPropertiesSelector.includePropertyTypes,

        } as PropertiesSelector;
    }

    async generateCategories() : Promise<void> {
        await UserManager.getInstance().generateCategories( this );
    }

    attachChild( childReferenceHandle: ReferenceHandle<User> ) : Promise<void> {
        return UserManager.getInstance().attachChild( this, childReferenceHandle );
    }

    updateTitle() : string {
      
        let title = "";
      
        if( this.firstName.value() != null ) {
            title = this.firstName.value()!;
        }
      
        if( this.lastName.value() != null ) {
            
            if( title != null ) {
                title += " " + this.lastName.value()!;
            }
            else {
                title = this.lastName.value()!;
            }
        }
      
        if( title == null && this.title.value() == null ) {
            // default to email
            title = this.email.value()!;
        }

        this.title.setValue( title );
      
        return title;
      }

    async onCreate() : Promise<void> {
        try {
            //log.traceIn( "("+this.collectionDatabase.collectionName()+")", "onCreate()" );

            await super.onCreate();

            const duplicateUser = await UserManager.getInstance().findDuplicateUser( this );

            if( duplicateUser != null ) {
                throw new Error( "duplicateUser" );
            }

            await UserManager.getInstance().handleCreateUser( this );
    
            //log.traceOut( "("+this.collectionDatabase.collectionName()+")", "onCreate()" );
  
        } catch( error ) {
            
            log.warn( "("+this.collectionDatabase.collectionName()+")", "onCreate()", "Error handling created notification", error );

            throw new Error( (error as any).message ); 
        }
    }


    async onUpdate() : Promise<void> {
        try {
            //log.traceIn( "onUpdate()" );

            await super.onUpdate();

            await UserManager.getInstance().handleUpdateUser( this );
    
            //log.traceOut( "onUpdate()" );
  
        } catch( error ) {
            
            log.warn( "onUpdated()", "Error handling updated notification", error );
  
            throw new Error( (error as any).message );
        }    
    }

    async onDelete() : Promise<void> { 

        try {
            //log.traceIn( "onDelete()" );

            await super.onDelete();

            await UserManager.getInstance().handleDeleteUser( this );
    
            //log.traceOut( "onDelete()" );
  
        } catch( error ) {
            
            log.warn( "onDeleted()", "Error handling delete notification", error );
  
            throw new Error( (error as any).message );
        }  
    }

    readonly company: TenantProperty<Company>;

    readonly unit: OwnerProperty<Unit>;

    readonly allowedSymbolicUnits : ReferencesProperty<Unit>;

    readonly symbolicUnits : SymbolicOwnersProperty<Unit>;

    readonly user: OwnerProperty<User>; 

    readonly symbolicUser : SymbolicOwnersProperty<User>;

    readonly allowedSymbolicUser : ReferenceProperty<User>;

    readonly users : CollectionProperty<User>;

    readonly symbolicUsers : ReferencesProperty<User>;

    readonly firstName : TextProperty;

    readonly middleName : TextProperty;

    readonly lastName : TextProperty;

    readonly dateOfBirth : DateProperty;

    readonly sex : DefinitionProperty<Sex>;

    readonly employeeId : TextProperty;

    readonly jobTitle : TextProperty;

    readonly managing : ReferencesProperty<Unit>;

    readonly assisting : ReferencesProperty<Unit>;

    readonly deputing : ReferencesProperty<Unit>;

    readonly projectManaging : ReferencesProperty<Project>;

    readonly projects : ReferencesProperty<Project>;

    readonly categories : ReferencesProperty<Category>;

    readonly locations : ReferencesProperty<Location>;

    readonly residences: CollectionProperty<Residence>;

    readonly devices : CollectionProperty<Device>;

    readonly reportedAlerts : ReferencesProperty<Alert>;

    readonly settings : SubdocumentProperty<Settings>;

    readonly authenticationId: TextProperty;

    readonly disabled : BooleanProperty;

}
