
export const BillingMethods = {

    Free : "free",

    Invoice : "invoice",

    WooCommerce   : "wooCommerce"

}

export type BillingMethod = keyof (typeof BillingMethods);

export const DefaultBillingMethod = BillingMethods.Free as BillingMethod;
