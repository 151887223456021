import * as React from 'react'

import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import { withTranslation, WithTranslation } from 'react-i18next';

import GoogleMapReact, { ChangeEventValue, ClickEventValue, Coords, MapTypeStyle } from 'google-map-react';

import Geocode from "react-geocode";

import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { Factory } from '../../../services/common/api/factory';

import { Geolocation, GeolocationPropertyIF } from 'services/database/api/properties/geolocationPropertyIF';
import { DefinitionPropertyIF } from '../../../services/database/api/properties/definitionPropertyIF';
import { HealthguardDefinition } from '../../../healthguard/api/healthguardDefinitions';

import { country, activeLanguage, countryName } from '../../app/localization';
import { log } from '../../app/app';

import { PropertyDisplayMode } from '../propertyValue';
import PropertyLabel from '../propertyLabel'; 
import MapMarker from '../mapMarker';

import { definitionIcon } from '../definitionIcon';
import { definitionColor } from '../definitionColor';
import { PersistentKeyCenter, PersistentKeyZoom } from '../appFrame';
import { AppContext, AppContextProps } from '../../app/appContext';
 
import { DefaultMapType, MapType, MapTypes } from '../../../services/database/api/definitions/mapType';

import googleMapsConfiguration from "healthguard/data/settings/googleMaps.json"; 

const styles = (theme: Theme) => createStyles({
  root: {
    width: '100%'
  },
  heading: {
    color: theme.palette.text.secondary,
  },
  headingExpanded: {
    marginTop: theme.spacing(-6.25),
    marginLeft: theme.spacing(-1),
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(1),
    position: 'absolute',
    display: 'flex',
    flexDirection: 'row',
    color: theme.palette.text.secondary,
    background: "#FFFFFF",
    fontSize: "small",
    zIndex: 999
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(-3),
    height: theme.spacing(40)
  },
  singleForm: {
    width: '100%',
    marginTop: theme.spacing(-1),
    marginLeft: theme.spacing(-1),
    height: theme.spacing(60)

  },
  formControl: {
    padding: theme.spacing(0.75),
    border: '1px solid #ccc',
    borderRadius: 5
  },
  formLabel: {
    marginTop: theme.spacing(-0.25)
  },
  labelPaper: {
    paddingLeft: theme.spacing(1),
    marginLeft: theme.spacing(-1),
    paddingRight: theme.spacing(1),
    color: theme.palette.secondary.main
  },
  marker: {
    backgroundColor: "#FFFFFF", 
    opacity: 0.93
  }
});


const emptyDefaultZoom = 3;

const nonEmptyDefaultZoom = 14;

const defaultExpanded = true;

const doubleClickWaitPeriod = 1000;
 

export interface GeolocationPropertyValueProps extends WithStyles<typeof styles>, WithTranslation { 

  property : GeolocationPropertyIF, 

  onPropertyChange? : ( property : GeolocationPropertyIF ) => void,

  displayMode : PropertyDisplayMode,

  singleProperty : boolean,

  hideColor?: boolean,

  disabled?: boolean,

  required?: boolean
}

  
interface GeolocationPropertyValueState { 

  open : boolean,

  propertyValue?: Geolocation,

  defaultCenter?: Coords,

  defaultZoom: number,

  expanded: boolean,

  mapType: MapType
}


class GeolocationPropertyValue extends React.PureComponent<GeolocationPropertyValueProps,GeolocationPropertyValueState> {

  constructor( props: GeolocationPropertyValueProps ) {
    
    super(props);

    this.state = { 
      open : false,

      propertyValue: this.props.property.value(),

      expanded: defaultExpanded,

      defaultZoom: emptyDefaultZoom,

      mapType: DefaultMapType

     } as GeolocationPropertyValueState;


    //log.traceInOut( "constructor()" );
  }

  async componentDidMount() {

    log.traceOut("componentDidMount()");

    try {
      const appContext = this.context as AppContextProps;

      let propertyValue = this.props.property.value();

      let defaultCenter;

      let defaultZoom;

      if (propertyValue == null) {

        let addressQuery : string | undefined;

        const defaultCenterPersistentKey =
          appContext.currentHomePath + "." + PersistentKeyCenter;
  
        defaultCenter = Factory.get().persistentState!.property(defaultCenterPersistentKey) as Coords;
  
        const defaultZoomPersistentKey =
          appContext.currentHomePath + "." + PersistentKeyZoom;
  
        defaultZoom = Factory.get().persistentState!.property(defaultZoomPersistentKey) as number;
  
        if( defaultZoom == null ) {
          defaultZoom = emptyDefaultZoom
        }

        const address = this.props.property.addressProperty()?.subdocument();

        addressQuery = "";

        addressQuery +=
          address?.address1.value() != null ? address.address1.value()! : "";

        if (addressQuery!.length > 0) {
          addressQuery += ", ";
        }

        addressQuery += address?.city?.value() != null ? address.city.value()! : "";

        if (addressQuery.length > 0) {
          addressQuery += ", ";
        }

        addressQuery += address?.state?.value() != null ? address.state.value()! : "";

        if (addressQuery.length > 0) {
          addressQuery += ", ";
        }

        addressQuery += address?.zip?.value() != null ? address.zip.value()! : "";

        if (addressQuery.length > 0) {
          addressQuery += ", ";
        }

        const emptyAddress = addressQuery.length === 0;

        if (addressQuery.length > 0) {
          addressQuery += ", ";
        }

        const region = address?.country?.value() != null ? address.country.value()! : country();

        addressQuery += countryName(region);

        log.debug("componentDidMount()", { addressQuery }, { emptyAddress });

        if (!emptyAddress || defaultCenter == null) {

          Geocode.setApiKey(Factory.get().configurationService.config(googleMapsConfiguration, "mapsKey"));

          Geocode.setLanguage(activeLanguage()); 

          if (region != null) {
            Geocode.setRegion(region);
          }

          const lookup = await Geocode.fromAddress(addressQuery);

          log.debug("componentDidMount()", { lookup });

          defaultCenter = {
            lat: lookup.results[0].geometry.location.lat,
            lng: lookup.results[0].geometry.location.lng
          } as Coords;
        }

        if (!emptyAddress) {

          propertyValue = defaultCenter as Geolocation;

          this.props.property.setValue(defaultCenter);

          this.props.property.clearChanges();

          defaultZoom = nonEmptyDefaultZoom;
        }
      }
      else {
        defaultCenter = propertyValue as Coords;

        defaultZoom = nonEmptyDefaultZoom;
      }

      log.debug("componentDidMount()", { defaultCenter }, { defaultZoom });

      this.setState({
        propertyValue: propertyValue,

        defaultCenter: defaultCenter,

        defaultZoom: defaultZoom
      })

      log.traceOut("componentDidMount()");

    } catch (error) {

      log.warn("componentDidMount()", error);

    }
  }



  async componentDidUpdate() {

    if( JSON.stringify( this.props.property.value() ) !== JSON.stringify( this.state.propertyValue ) ) {
      this.setState( {
        propertyValue: this.props.property.value()
      })
    }
  }

  private updateGeolocation = async ( geolocation : Geolocation ) => {

    log.traceIn("updateGeolocation()", {geolocation} );

    try {

      this.props.property.setValue( geolocation );

      if( !this.props.property.isChanged()) {
        return;
      }

      const address = this.props.property.addressProperty()?.subdocument();

      if( address != null ) {
       
        const region = this.props.property.countryProperty()?.value() != null ?
          this.props.property.countryProperty()?.value()! : country();

        const addressLookup = await Geocode.fromLatLng( 
          geolocation.lat + "", 
          geolocation.lng + "",
          Factory.get().configurationService.config(googleMapsConfiguration, "mapsKey"),
          activeLanguage(),
          region,
        )  

        log.debug("updateGeolocation()", {addressLookup} ); 

        if( addressLookup?.status === "OK" && (addressLookup?.results as any[])?.length != null ) {

          address!.address1!.setValue( undefined );
          address!.address2!.setValue( undefined );
          address!.zip!.setValue( undefined );
          address!.city!.setValue( undefined );
          address!.state!.setValue( undefined );
          address!.country!.setValue( undefined );

          for( const result of addressLookup.results ) {

            if( result.types.includes( "street_address") ) {

              address!.address1.setValue( result.address_components[0].long_name + " " + result.address_components[1].long_name );
            }


            if( result.types.includes( "postal_code") ) {

              address!.zip!.setValue( result.address_components[0].long_name );

              address!.city!.setValue( result.address_components[1].long_name );

            }

            if( result.types.includes( "administrative_area_level_1")  ) {

              address!.state!.setValue( result.address_components[0].long_name );
            }

            if( result.types.includes( "country")  ) {

              address!.country!.setValue( result.address_components[0].short_name );
            }
          }
        }
        log.debug("updateGeolocation()", {address} ); 
      }

      this.setState({
        propertyValue: geolocation
      })

      if (this.props.onPropertyChange != null) {
        this.props.onPropertyChange(this.props.property);
      }

      log.traceOut("updateGeolocation()");

    } catch (error) {
      
      log.warn("updateGeolocation()", error);
    }
  }

  render() {
    //log.traceInOut("render()");

    const {classes } = this.props;

    if( this.props.displayMode === PropertyDisplayMode.Cell ) {

      let text : string = "";
  
      return ( <React.Fragment>{ text }</React.Fragment> );
    }

    const handleClick = async (value: ClickEventValue) => {

      log.traceIn("handleClick()", {value} );

      if( value.event.detail > 1 ) {

        if( this._updateGeolocationTimeout != null ) {

          clearTimeout( this._updateGeolocationTimeout );

          delete this._updateGeolocationTimeout;
        }
        log.traceOut("handleClick()", "doubleclick" ); 
        return;
      }

      const geolocation = {
          lat: value.lat,
          lng: value.lng,
          zoom: this.state.propertyValue?.zoom
      } as Geolocation

      this._updateGeolocationTimeout = setTimeout(() => {

        delete this._updateGeolocationTimeout;

        this.updateGeolocation(geolocation);
        
        },
        doubleClickWaitPeriod );

      log.traceOut("handleClick()" );
    }

    const handleChange = async (value: ChangeEventValue) => {

      log.traceIn("handleChange()", value);

      if (this.state.propertyValue == null) {

        log.traceOut("handleChange()", "no current value");
        return;
      }

      if (this.state.propertyValue.zoom === value.zoom) {
        log.traceOut("handleChange()", "no change to zoom");
        return;
      }

      const geolocation = {
        lat: this.state.propertyValue.lat,
        lng: this.state.propertyValue.lng,
        zoom: value.zoom
      } as Geolocation

      await this.updateGeolocation(geolocation)

      log.traceOut("handleChange()");

    }

    const handleMapTypeChange = async (mapType: string ) => {

      log.traceIn("handleMapTypeChange()", mapType);

      this.setState( {
        mapType: mapType as MapType
      })

      log.traceOut("handleMapTypeChange()" );

    }

    const map = () => {

      const Marker = (props : any) => {
        
        const title = this.props.property.titleProperty()?.value()!

        const status = this.props.property.statusProperty()?.value()!

        const iconProperty = this.props.property.typeProperty() as DefinitionPropertyIF<HealthguardDefinition>;

        const colorProperty = this.props.property.levelProperty() as DefinitionPropertyIF<HealthguardDefinition>

        return (
          <MapMarker 
            id={title}
            title={title}
            status={status}
            icon={iconProperty == null ? undefined : 
              definitionIcon(iconProperty.definition as HealthguardDefinition, iconProperty.value())}
            color={colorProperty == null ? undefined : 
              definitionColor(colorProperty.definition as HealthguardDefinition, colorProperty?.value())}
          />
        ) 
      }

      const gogleMapsKey =
        Factory.get().configurationService.config(googleMapsConfiguration, "mapsKey")

      const googleMapsStyles = 
        Factory.get().configurationService.config(googleMapsConfiguration, "styles") as MapTypeStyle[];
  
      const adjustStyles = this.state.mapType === MapTypes.Roadmap || this.state.mapType === MapTypes.Terrain 

      const country = this.props.property.countryProperty()?.value();

      return (
        <>
          {this.state.defaultCenter != null &&
            <GoogleMapReact
              bootstrapURLKeys={{
                key: gogleMapsKey,
                language: activeLanguage(),
                region: country
              }}
              options={{ 
                disableDefaultUI: true,
                draggableCursor: 'crosshair',
                fullscreenControl: false,
                streetViewControl: true,
                streetViewControlOptions: { position: 7 },
                mapTypeControl: true, 
                mapTypeControlOptions: { position: 3 },  
                zoomControl: true,
                zoomControlOptions: { position: 3 },
                styles: adjustStyles ? googleMapsStyles : undefined
              }}
              draggable={true}
              zoom={this.state.propertyValue?.zoom}
              center={this.state.defaultCenter}
              defaultZoom={this.state.defaultZoom}
              onClick={!!this.props.disabled ? undefined : handleClick }
              onChange={!!this.props.disabled ? undefined : handleChange}
              onMapTypeIdChange={handleMapTypeChange}
            >
              {this.state.propertyValue != null && <Marker lat={this.state.propertyValue.lat} lng={this.state.propertyValue.lng} />}
            </GoogleMapReact>
          }
        </>
      );
    }

    if (this.props.singleProperty) {
      return (
        <>
          <div className={classes.singleForm}>
            {map()}
          </div>
        </>
      );
    }
    else {
      return (
        <>
          <div className={classes.root}>
            <Accordion 
              variant="outlined" 
              expanded={this.state.expanded} 
              key={this.props.property.key()} >
              <AccordionSummary onClick={() => this.setState({ expanded: !this.state.expanded })} expandIcon={<ExpandMoreIcon />} >
                {this.state.expanded ?
                  <Typography className={classes.headingExpanded}>
                    <PropertyLabel documentName={this.props.property.parent.documentName()} propertyKey={this.props.property.key()} />{this.props.required && " *"}
                  </Typography>
                  :
                  <Typography className={classes.heading}>
                    <PropertyLabel documentName={this.props.property.parent.documentName()} propertyKey={this.props.property.key()} />{this.props.required && " *"}
                  </Typography>
                }
              </AccordionSummary>
              <AccordionDetails>
                <div className={classes.form}>
                  {map()}
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        </>
      );
    }
  }

  private _updateGeolocationTimeout? : NodeJS.Timeout;
}

GeolocationPropertyValue.contextType = AppContext;

const ModifiedGeolocationPropertyValue = withTranslation()(withStyles(styles)(GeolocationPropertyValue));

export default ModifiedGeolocationPropertyValue;

