
import { log } from "../../../services/database/framework/databaseService";
import { UserManager } from "../../../services/database/impl/managers/userManager";
import { Risk } from "../documents/risk";

export class RiskManager {

    static getInstance() : RiskManager {
        if( RiskManager._instance == null ) {
            RiskManager._instance = new RiskManager();
        }
        return RiskManager._instance;
    }


    async handleCreateRisk( createdRisk: Risk ) {

        try {
            log.traceIn("handleCreateRisk()", createdRisk.title.value() );

            log.traceOut("handleCreateRisk()");

        } catch (error) {
            log.warn("Error handling document create", error);

            log.traceOut("handleCreateRisk()", "error");
        }
    }

    async handleUpdateRisk( updatedRisk: Risk ) {

        try {
            log.traceIn("handleUpdateRisk()", updatedRisk.title.value() );

            log.traceOut("handleUpdateRisk()");

        } catch (error) {
            log.warn("Error handling document update", error);

            log.traceOut("handleUpdateRisk()", "error");
        }
    } 

    async handleDeleteRisk( deletedRisk: Risk ) {

        try {
            log.traceIn("handleDeleteRisk()", deletedRisk.title.value() );

            log.traceOut("handleDeleteRisk()");

        } catch (error) {
            log.warn("Error handling document delete", error);

            log.traceOut("handleDeleteRisk()", "error");
        }
    } 


    async updateRiskUsers( risk: Risk ) {

        try {
            log.traceIn("updateRiskUsers()", risk.title.value() );

            await UserManager.getInstance().updateDocumentUsers( risk );

            log.traceOut("updateRiskUsers()");

        } catch (error) {
            log.warn("Error handling risk activity statuses", error);

            log.traceOut("updateRiskUsers()", "error");
        }
    }


    private static _instance? : RiskManager; 

}

