

import { User } from "./user";
import { CategoryIF } from "../../api/documents/categoryIF";
import { ReferencesProperty } from "../properties/referencesProperty";
import { DatabaseDocument } from "../../framework/databaseDocument";
import { CollectionDatabase } from "../core/collectionDatabase";
import { log } from "../../framework/databaseService";
import { Company } from "./company";
import { TenantProperty } from "../properties/tenantProperty";
import { CompaniesCollection, LocationsCollection, UnitsCollection, UsersCollection } from "../../api/collections";
import { CategoryDocument, CompanyDocument } from "../../api/documents";
import { Unit } from "./unit";
import { Location } from "./location";
import { DefinitionProperty } from "../properties/definitionProperty";
import { CategoryType, CategoryTypes } from "../../api/definitions/categoryType";
import { CategoryTypeDefinition } from "../../api/definitions";
import { BooleanProperty } from "../properties/booleanProperty";
import { Database } from "../core/database";


export class Category extends DatabaseDocument implements CategoryIF {

    constructor( categoryDocumentName : string, categoriesCollection : CollectionDatabase<Category>, documentPath? : string  ) {

        super( categoryDocumentName, categoriesCollection, documentPath );

        try {
            this.title.required = true;

            this.company = new TenantProperty<Company>( this, CompaniesCollection, CompanyDocument );
            this.company.required = true;
            this.company.editable = false;

            this.categoryType = new DefinitionProperty<CategoryType>( this, CategoryTypeDefinition, CategoryTypes );

            this.autoGenerated = new BooleanProperty( this ); 

            this.locations = new ReferencesProperty<Location>( this, 
                () => this.parentDatabases( LocationsCollection, {nearestIsCollectionGroup: true} ) as Database<Location>[],
                "categories" ); 

            this.units = new ReferencesProperty<Unit>( this, 
                () => this.parentDatabases( UnitsCollection, {nearestIsCollectionGroup: true} ) as Database<Unit>[] ); 

            this.users = new ReferencesProperty<User>( this, 
                () => this.parentDatabases( UsersCollection, {nearestIsCollectionGroup: true} ) as Database<User>[],
                "categories" );

        } catch( error ) {

            log.warn( "constructor()", "Error initializing Category", error );
            
            throw new Error( (error as any).message );
        }
    }

    indexKey() : string {
        return CategoryDocument;
    }

    referenceDateProperty()  {
        return undefined;
    }

    readonly company: TenantProperty<Company>;

    readonly categoryType : DefinitionProperty<CategoryType>;

    readonly autoGenerated : BooleanProperty;

    readonly locations : ReferencesProperty<Location>;

    readonly units : ReferencesProperty<Unit>;

    readonly users : ReferencesProperty<User>;


}
