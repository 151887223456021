import React from 'react';
import ReactDOMServer from 'react-dom/server';

import {unescape} from 'html-escaper';

import { log } from "ui/app/app";

import { withTranslation, WithTranslation } from 'react-i18next';
import { TranslationKey } from '../../services/common/api/translatorIF';
import { HealthguardDocumentDefinition } from '../../healthguard/api/healthguardDefinitions';
import { DocumentDefinition } from '../../services/database/api/definitions';


export const newDocumentName = ( name : string ) => {

    //log.traceInOut( "newDocumentName()", {name} );

    return unescape( ReactDOMServer.renderToString( <ModifiedDocumentName name={name} new={true}/>) );

}

export const translatedDocumentName = ( name : string, my? : boolean ) => {

    //log.traceInOut( "translatedDocumentName()", {name}, {my} );

    return unescape( ReactDOMServer.renderToString( <ModifiedDocumentName name={name} my={my} new={false}/>) );

}

interface DocumentNameProps extends WithTranslation {
  name : string, 
  new? : boolean,
  my? : boolean
}


interface DocumentNameState { 
}

class DocumentName extends React.PureComponent<DocumentNameProps,DocumentNameState> {

  constructor( props: DocumentNameProps ) {
    
    super(props);

    this.state = { 
    } as DocumentNameState;

    //log.traceInOut( "constructor()" );
  }

  
  
  render() {

    //log.traceInOut( "render()" );

    let result;

    if (!!this.props.new) {

      //log.traceIn( "newDocumentName()", this.props.collectionName());

      let translationKey = this.props.name + ":" + TranslationKey.New;

      if (this.props.i18n.exists(translationKey)) {
        result = this.props.t(translationKey);
      }
      else {
        log.warn("newDocumentName()", "Not found for: " + this.props.name);

        result = this.props.name;
      }

      // log.traceOut( "newDocumentName()", result ); 
      return result;
    }
    else {

        let translationKey = this.props.name + ":" + (!!this.props.my ? TranslationKey.MyDocument : TranslationKey.Document);
      
        if( this.props.i18n.exists( translationKey) ) {
          result = this.props.t( translationKey );
          //log.traceOut( "translatedDocumentName()", result ); 
          return result;
        }
      
        translationKey = HealthguardDocumentDefinition + ":" + TranslationKey.Values + ":" + this.props.name;
      
        if( this.props.i18n.exists( translationKey) ) {
      
          result = this.props.t( translationKey );
          //log.traceOut( "translatedDocumentName()", result ); 
          return result;
        }
      
        translationKey = DocumentDefinition + ":" + TranslationKey.Values + ":" + this.props.name;
      
        if( this.props.i18n.exists( translationKey) ) {
      
          result = this.props.t( translationKey );
          //log.traceOut( "translatedDocumentName()", result ); 
          return result;
        }
      
       // log.traceOut( "translatedDocumentName()", result ); 
        return null;
      }

  }
}



const ModifiedDocumentName = withTranslation()(DocumentName);

export default ModifiedDocumentName;


