
import { LoggerFactory } from "../../common/api/loggerFactory";

import { DatabaseServiceIF, AuthenticationIdUserProperty, EmailUserProperty, CustomerIdCompanyProperty, ContactEmailCompanyProperty } from "../api/core/databaseServiceIF";
import { Service } from "../../common/impl/service";
import { DatabaseFactory } from "./databaseFactory";
import { DatabaseManager } from "./databaseManager";
import { User } from "../impl/documents/user";
import { CompaniesCollection, UsersCollection } from "../api/collections";
import { Company } from "../impl/documents/company";
import { Factory } from "../../common/api/factory";
import { Monitor, ObservableIF, Observation } from "../../common";
import { Unit } from "../impl/documents/unit";
import { AuthenticationNotification } from "../../authentication/api/authenticationNotification";
import { DatabaseAccess } from "./databaseAccess";

export const log = LoggerFactory.logger( "database");

export class DatabaseService extends Service implements DatabaseServiceIF  {

    constructor( 
        databaseManager : DatabaseManager, 
        databaseFactory : DatabaseFactory,
        databaseAccess : DatabaseAccess ) {

        super();

        //log.traceIn( "constructor()");

        try {
            this.databaseManager = databaseManager;

            this.databaseFactory = databaseFactory;

            this.databaseAccess = databaseAccess; 

            this.onNotifyAuthenticationUpdated = this.onNotifyAuthenticationUpdated.bind(this);
            this.onNotifyCurrentCompanyUpdated = this.onNotifyCurrentCompanyUpdated.bind(this);
            this.onNotifyCurrentUnitUpdated = this.onNotifyCurrentUnitUpdated.bind(this);

            //log.traceOut( "constructor()");

        } catch( error ) {
            log.warn( "constructor()", "Error initializing database service", error );

            throw new Error( (error as any).message );
        }
    }

    async init() : Promise<void> {

        log.traceInOut("init()" );

        try {

            if (Factory.get().authenticationService != null) {
                
                await Factory.get().authenticationService!.subscribe({
                    observer: this,
                    onNotify: this.onNotifyAuthenticationUpdated
                } as Monitor as Monitor
                );
            }

        } catch (error) {
            log.warn("init()", "Error initializing database service", error);

            throw new Error( (error as any).message );
        }
    }

    onNotifyAuthenticationUpdated = async (observable: ObservableIF,
        observation: Observation,
        objectId: string | null | undefined,
        object: object | null | undefined): Promise<void> => {

        try {
            log.traceIn("onNotifyAuthenticationUpdated()", Observation[observation], {objectId});

            const authenticationNotification = object as AuthenticationNotification;

            const currentUser = authenticationNotification?.currentUser as User;

            if( currentUser != null ) {

                if( this._currentUser == null  ) {

                    this._currentUnit = await currentUser.unit.document();

                    if( this._currentUnit != null ) {

                        await this._currentUnit.subscribe( {
                            observer: this,
                            onNotify: this.onNotifyCurrentUnitUpdated
                        } as Monitor as Monitor 
                        );
                    } 

                    this._currentCompany = await currentUser.company.document();

                    if( this._currentCompany != null ) {

                        await this._currentCompany.subscribe( {
                            observer: this,
                            onNotify: this.onNotifyCurrentCompanyUpdated
                        } as Monitor as Monitor 
                        );
                    }
                }
            }
            else  {

                if( this._currentUnit != null ) {
                    await this._currentUnit.unsubscribe( this );
                    delete this._currentUnit;
                }

                if( this._currentCompany != null ) {
                    await this._currentCompany.unsubscribe( this );
                    delete this._currentUnit;

                }
            }

            this._currentUser = currentUser;

            log.traceOut("onNotifyAuthenticationUpdated()");

        } catch (error) {
            log.warn("Error receiving authentication notification", error);

            log.traceOut("onNotifyAuthenticationUpdated()", error);
        }
    }

    onNotifyCurrentUnitUpdated = async (observable: ObservableIF,
        observation: Observation,
        objectId: string | null | undefined,
        object: object | null | undefined): Promise<void> => {

        try {
            log.traceIn("onNotifyCurrentUnitUpdated()", Observation[observation], {objectId});

            const currentUnit = object != null ? object as Unit : undefined;

            if( this._currentUnit != null && currentUnit != null ) {

                this._currentUnit.copyFrom( currentUnit );
            }

            log.traceOut("onNotifyCurrentUnitUpdated()");

        } catch (error) {
            log.warn("Error receiving current unit update", error);

            log.traceOut("onNotifyCurrentUnitUpdated()", error);
        }
    }

    onNotifyCurrentCompanyUpdated = async (observable: ObservableIF,
        observation: Observation,
        objectId: string | null | undefined,
        object: object | null | undefined): Promise<void> => {

        try {
            log.traceIn("onNotifyCurrentCompanyUpdated()", Observation[observation], {objectId});

            const currentCompany = object != null ? object as Company : undefined;

            if( this._currentCompany != null && currentCompany != null ) {

                this._currentCompany.copyFrom( currentCompany );
            }
            log.traceOut("onNotifyCurrentCompanyUpdated()");

        } catch (error) {
            log.warn("Error receiving current company update", error);

            log.traceOut("onNotifyCurrentCompanyUpdated()", error);
        }
    }



    currentUser() : User | undefined {

        return this._currentUser;
    }

    currentUnit() : Unit | undefined {

        return this._currentUnit;
    }

    currentCompany() : Company | undefined {

        return this._currentCompany;
    }

    async companyWithCustomerId( customerId : string ) : Promise<Company | undefined> {

        log.traceIn( "companyWithCustomerId()", customerId );

        const result = await this.databaseFactory.databaseManager.documentWithProperty( 
            this.databaseFactory.collectionGroupDatabaseFromCollectionName( CompaniesCollection ),
            CustomerIdCompanyProperty, customerId ) as Company;

        log.traceOut( "companyWithCustomerId()", result != null ? result.referenceHandle().title: undefined  );
        return result;
    }

    async companyWithContactEmail( contactEmail : string ) : Promise<Company | undefined> {

        log.traceIn( "companyWithContactEmail()", contactEmail );

        const result = await this.databaseFactory.databaseManager.documentWithProperty( 
            this.databaseFactory.collectionGroupDatabaseFromCollectionName( CompaniesCollection ),
            ContactEmailCompanyProperty, 
            contactEmail ) as Company;

        log.traceOut( "companyWithContactEmail()", result != null ? result.referenceHandle().title: undefined );
        return result;
    }

    async userWithAuthenticationId( authenticationId : string ) : Promise<User | undefined> {

        log.traceIn( "userWithAuthenticationId()", authenticationId );

        const result = await this.databaseFactory.databaseManager.documentWithProperty( 
            this.databaseFactory.collectionGroupDatabaseFromCollectionName( UsersCollection ),
            AuthenticationIdUserProperty, authenticationId ) as User;

        log.traceOut( "userWithAuthenticationId()", result != null ? result.referenceHandle().title : undefined  );
        return result;
    }

    async userWithEmail( email : string ) : Promise<User | undefined> {

        log.traceIn( "userWithEmail()", email );

        const result = (await this.databaseFactory.databaseManager.documentWithProperty( 
            this.databaseFactory.collectionGroupDatabaseFromCollectionName( UsersCollection ),
            EmailUserProperty, 
            email )) as User;

        log.traceOut( "userWithEmail()", result != null ? result.referenceHandle().title: undefined  );
        return result;
    }

    readonly databaseFactory : DatabaseFactory;

    readonly databaseManager : DatabaseManager;

    readonly databaseAccess : DatabaseAccess;

    private _currentUser? : User;

    private _currentUnit? : Unit;

    private _currentCompany? : Company;
}
