import { IncidentRegistrationIF } from "../../api/documents/incidentRegistrationIF";
import { Registration } from "./registration";
import { User } from "../../../services/database/impl/documents/user";
import { log } from "../../../services/database/framework/databaseService";
import { CollectionDatabase } from "../../../services/database/impl/core/collectionDatabase";
import { ReferencesProperty } from "../../../services/database/impl/properties/referencesProperty";
import { TextProperty } from "../../../services/database/impl/properties/textProperty";
import { TextsProperty } from "../../../services/database/impl/properties/textsProperty";
import { HealthguardCompany } from "./healthguardCompany";
import { IncidentRegistrationDocument } from "../../api/registrationDocuments";
import { DefinitionProperty } from "../../../services/database/impl/properties/definitionProperty";
import { IllnessStage, IllnessStages } from "../../api/definitions/illnessStage";
import { IllnessStageDefinition } from "../../api/healthguardDefinitions";
import { OptionsSource } from "../../../services/database/impl/core/optionsSource";
import { Hazard } from "./hazard";

export class IncidentRegistration extends Registration implements IncidentRegistrationIF {

    constructor( incidentRegistrationsCollection : CollectionDatabase<IncidentRegistration>, documentPath? : string  ) {

        super( IncidentRegistrationDocument, incidentRegistrationsCollection, documentPath );

        try {
            this.hazard.required = true;

            const company = this.company.emptyDocument() as HealthguardCompany;

            this.illnessStage = new DefinitionProperty<IllnessStage>( this, IllnessStageDefinition, IllnessStages );

            this.hospital = new TextProperty( this );

            this.clinic = new TextProperty( this );

            this.medications = new TextsProperty( this );
            this.medications.setOptionsSource( new OptionsSource<Hazard,TextsProperty>( this.hazard, "medications" )  );

            this.treatments = new TextsProperty( this );
            this.treatments.setOptionsSource( new OptionsSource<Hazard,TextsProperty>( this.hazard, "treatments" )  );

            this.closeContacts = new ReferencesProperty<User>( this, 
                company != null ? 
                    () => [company.users.collectionGroup()] : 
                    undefined );

            this.externalSources = new TextsProperty( this );

            this.internalSources = new ReferencesProperty<IncidentRegistration>( this, 
                company != null ? 
                    () => [company.incidentRegistrations.collectionGroup()] : 
                    undefined, 
                "internalReceivers" );

            this.externalReceivers = new TextsProperty( this );

            this.internalReceivers = new ReferencesProperty<IncidentRegistration>( this, 
                company != null ? 
                    () => [company.incidentRegistrations.collectionGroup()] :  
                    undefined, 
                "internalSources" );

            //log.traceInOut( "constructor()", IncidentRegistrationsCollection );

        } catch( error ) {

            log.warn( "constructor()", "Error initializing incident", error );
            
            throw new Error( (error as any).message );
        }
    }

    readonly illnessStage: DefinitionProperty<IllnessStage>;

    readonly hospital: TextProperty;

    readonly clinic: TextProperty;

    readonly medications : TextsProperty;

    readonly treatments : TextsProperty;

    readonly closeContacts : ReferencesProperty<User>;

    readonly externalSources: TextsProperty;

    readonly internalSources: ReferencesProperty<IncidentRegistration>;

    readonly externalReceivers: TextsProperty;

    readonly internalReceivers: ReferencesProperty<IncidentRegistration>;

 }
