import { log } from "../../framework/databaseService";
import { DatabaseObject } from "../../framework/databaseObject";
import { DatabaseProperty } from "../../framework/databaseProperty";
import { OptionsSource } from "../core/optionsSource";
import { DatabaseDocument } from "../../framework/databaseDocument";
import { DefinitionsProperty } from "./definitionsProperty";
import { PropertyType, PropertyTypes } from "../../api/definitions/propertyType"; 
import { DefinitionPropertyIF } from "../../api/properties/definitionPropertyIF";
import { TextType, TextTypes } from "../../api/definitions/textTypes";
 
export class DefinitionProperty<Definition extends string> 
    extends DatabaseProperty<Definition> implements DefinitionPropertyIF<Definition> {

    constructor( parent : DatabaseObject, 
        definitionName : string, 
        definitions : {},
        defaultValue? : Definition ) {
            
        super( PropertyTypes.Definition as PropertyType, parent ); 

        this.definition = definitionName;

        this.definitions = definitions;

        this._defaultValue = defaultValue;
    }

    setOptionsSource( optionsSource : OptionsSource<DatabaseDocument,DefinitionsProperty<Definition>>) {
        this._optionsSource = optionsSource;
    }  

    value( ignoreDefault? : boolean ) : Definition | undefined {

        this.decryptData();

        if( this._value != null ) {
            return this._value;
        }
        return !!ignoreDefault ? undefined : this._defaultValue;
    }

    setValue( value : Definition | undefined ): void {

        //log.traceIn( "setValue()", value );

        this.decryptData();

        if( value !== this._value ) {

            if( value != null ) {
                if( Object.values( this.definitions ).indexOf( value ) < 0 ) {
                    throw new Error( "Invalid definition " + value );
                }
            }
            this.setLastChange( this._value );

            this._value = value;
            
            delete this.error;
        }
        //log.traceOut( "setValue()", this._value );

    }

    defaultValue() : Definition | undefined { 

        return this._defaultValue;
    }

    setDefaultValue( defaultValue : Definition | undefined ): void { 

        if( defaultValue != null ) {
            if( Object.values( this.definitions ).indexOf( defaultValue ) < 0 ) {
                throw new Error( "Invalid definition " + defaultValue );
            }
        }

        this._defaultValue = defaultValue;
    }

    requiresSelect() : boolean {
        return true;
    }


    async select( filterValue? : boolean ) : Promise<Map<string,Definition> | undefined> { 

        log.traceIn( "select()" );

        const value = this.value();

        const optionsProperty = this._optionsSource != null ? 
            await this._optionsSource.optionsProperty() : 
            undefined;

        if( optionsProperty == null ||
            (!optionsProperty.required && optionsProperty.value() == null ) ) {

            const result = new Map<string,Definition>();

            for( const definition of Object.values( this.definitions ) ) {

                if( !!filterValue && value != null && value === definition as Definition ) { 
                    continue;
                }
                result.set( definition as string, definition as Definition);
            }

            log.traceOut( "select()", "no options source", result );
            return result;    
        }

        log.traceOut( "select()", "from options source", optionsProperty.values());
        return optionsProperty.options();
    }

    fromData( documentData: any): void {

        if( this.isEncryptedData( documentData[this.key()] ) ) {

            this.setEncryptedData( documentData[this.key()] );            
        }
        else {
            const data = documentData[this.key()];
        
            if( data == null ) {

                this._value = undefined;
                return;
            }

            if ( !(typeof data === 'string') ) {

                log.warn( "Unrecognized type for definition", data );
                this._value = undefined;
                return;
            }

            if ( Object.values( this.definitions ).indexOf( data ) < 0 ) {

                log.warn( "Unrecognized value for definition", data );
                this._value = undefined;
                return;

            } 

            this._value = data as Definition;
        }
        
    }

    async toData( documentData: any, force? : boolean ) : Promise<void> {

        if( !!force ) {
            this.decryptData();
        }
        
        if( this.encrypted() && this.encryptedData() != null ) {
            
            documentData[this.key()] = this.encryptedData();
            return;
        }

        let data;
        
        if( this.encrypted() ) {
            data = this.encryptData( this._value )
        }
        else {
            data = this._value;
        }

        if( data != null ) {
            documentData[this.key()] = data;
        }
    }

    compareTo( other : DefinitionProperty<Definition>, translator? : ( value? : string | undefined ) => string ) : number {

        return this.compareValue( other.value(), translator );
    }

    compareValue( value : Definition | undefined, translator? : ( value? : string ) => string | undefined ) : number {

        if( this.value() == null && value == null ) {
            return 0;
        }

        if( this.value() != null && value == null ) {
            return 1;
        }

        if( this.value() == null && value != null ) {
            return -1;
        }

        let thisValue = translator != null ? translator( this.value() as string ) : this.value() as string;
        let otherValue = translator != null ? translator( value as string ) : value as string;

        return thisValue!.localeCompare( otherValue! );
    }

    includes( other : DefinitionProperty<Definition>, matchAny? : boolean, translator? : ( value? : string ) => string | undefined ) : boolean {
        return this.includesValue( other.value(), matchAny, translator );
    }

    includesValue( value : Definition | undefined, matchAny? : boolean, translator? : ( value? : string ) => string | undefined ) : boolean {
        return this.compareValue( value, translator ) === 0;
    }

    readonly textType = TextTypes.Definition as TextType;

    readonly definition : string;

    readonly definitions : {};

    private _value : Definition | undefined;

    private _defaultValue : Definition | undefined;

    private _optionsSource : OptionsSource<DatabaseDocument,DefinitionsProperty<Definition>> | undefined;

}