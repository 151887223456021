
import { DatabaseObject } from "../../framework/databaseObject";
import { log } from "../../framework/databaseService";
import { DatabaseSubdocument } from "../../framework/databaseSubdocument";
import { NotificationSubdocument } from "../../api/subdocuments";
import { NotificationIF } from "../../api/subdocuments/notificationIF";
import { DefinitionProperty } from "../properties/definitionProperty";
import { NotificationStatus } from "../../api/definitions/notificationStatus";
import { NotificationType, NotificationTypes } from "../../api/definitions/notificationType";
import { NotificationTypeDefinition } from "../../api/definitions";
import { TextProperty } from "../properties/textProperty";
import { TextsProperty } from "../properties/textsProperty";
import { AttachmentsProperty } from "../properties/attachmentsProperty";
import { LinksProperty } from "../properties/linksProperty";
import { MediaType, MediaTypes } from "../../../storage/api/mediaType";
import { MessageLink } from "../../../messaging/api/messageLink";
import { MessageBody } from "../../../messaging/api/messageBody";
import { MapProperty } from "../properties/mapProperty";
import { PropertyType, PropertyTypes } from "../../api/definitions/propertyType";
import { DataProperty } from "../properties/dataProperty";
import { NumberProperty } from "../properties/numberProperty";

export class Notification extends DatabaseSubdocument implements NotificationIF {

    constructor( parent : DatabaseObject, key : string ) {

        super( NotificationSubdocument, parent, key );

        try {

            this.notificationType = new DefinitionProperty<NotificationType>( this, NotificationTypeDefinition, NotificationTypes );
            this.notificationType.editable = false;

            this.sender = new TextProperty(this );
            this.sender.editable = false;

            this.receivers = new TextsProperty(this );
            this.receivers.editable = false;

            this.title = new TextProperty(this );
            this.title.editable = false;

            this.subtitle = new TextProperty(this );
            this.subtitle.editable = false;

            this.body = new TextsProperty(this );
            this.body.editable = false;

            this.badge = new NumberProperty(this );
            this.badge.editable = false;

            this.attachments = new AttachmentsProperty( this, MediaTypes.Document as MediaType );
            this.attachments.editable = false;

            this.links = new LinksProperty( this );
            this.links.editable = false;

            this.notificationStatuses = new MapProperty<NotificationStatus>( PropertyTypes.Map as PropertyType, this );
            this.notificationStatuses.editable = false;

            this.errors = new MapProperty<string>( PropertyTypes.Map as PropertyType, this );
            this.errors.editable = false;

            this.data = new DataProperty<any>( PropertyTypes.Data as PropertyType, this );
            this.data.editable = false;

            //log.traceInOut( "constructor()", parent.title, key );

        } catch( error ) {

            log.warn( "constructor()", "Error initializing settings data", error );
            
            throw new Error( (error as any).message );
        }
    }

    messageBody() : MessageBody| undefined {

        const body = this.body.values();

        if( body == null || body.length === 0 ) {
            return undefined;
        }
        return new MessageBody( body );
    }
    
    messageLinks() : MessageLink[] | undefined {

        const links = this.links.value();

        if( links == null ) {
            return undefined;
        }

        let messageLinks : MessageLink[] = [];

        links.forEach( (value, key) => {
            messageLinks.push( new MessageLink( key, value ) );
        })

        return messageLinks;
    }


    readonly notificationType: DefinitionProperty<NotificationType>;

    readonly sender: TextProperty;

    readonly receivers: TextsProperty;

    readonly title: TextProperty;

    readonly subtitle: TextProperty;

    readonly body: TextsProperty; 

    readonly badge: NumberProperty;

    readonly attachments: AttachmentsProperty;

    readonly links: LinksProperty;

    readonly notificationStatuses: MapProperty<NotificationStatus>;

    readonly errors: MapProperty<string>;

    readonly data: DataProperty<any>;

 }
