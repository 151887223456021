
import { DataPropertyIF } from "../../api/properties/dataPropertyIF";
import { DatabaseProperty } from "../../framework/databaseProperty";

export class DataProperty<Data extends Object> extends DatabaseProperty<Data> implements DataPropertyIF<Data> {

    value() : Data | undefined {

        return this.data();
    }

    setValue( data : Data | undefined ) : void {

        this.setData( data );
    }

    data() : Data | undefined { 

        this.decryptData();

        return this._data;
    }

    setData( data : Data | undefined ): void {

        this.decryptData();

        if( this.compareValue( data ) !== 0 ) {

            this.setLastChange( this._data );

            this._data = data;

            delete this.error;
        }
    }

    fromData( documentData: any): void {

        if( this.isEncryptedData( documentData[this.key()] ) ) {

            this.setEncryptedData( documentData[this.key()] );
        }
        else {
            const data = documentData[this.key()];
            
            if( data == null ) {
                this._data = undefined;
            }
            else if (typeof data === 'object' ) {

                this._data = data;
            } 
            else if (typeof data === 'string') {

                try {
                    this._data = JSON.parse( data );
                } catch( error ) {
                    this._data = undefined;
                }
            }
            else {
                this._data = undefined;
            }
        }
    }

    async toData( documentData: any, force? : boolean ) : Promise<void> {

        if( !!force ) {
            this.decryptData();
        }
        
        if( this.encrypted() && this.encryptedData() != null ) {
            
            documentData[this.key()] = this.encryptedData();
            return;
        }
        
        let data;

        if( this.encrypted() ) {
            data = this.encryptData( this._data )
        }
        else {
            data = this._data;
        }

        if( data != null ) {
            documentData[this.key()] = data;
        }
    }

    compareTo( other : DataProperty<Data> ) : number {

        return this.compareValue( other.value() );
    }


    compareValue( otherData : Data | undefined ) : number {

        const data = this.data();

        if( data == null && otherData == null ) {
            return 0;
        }

        if( data != null && otherData == null ) {
            return 1;
        }

        if( data == null && otherData != null ) {
            return -1;
        }

        return JSON.stringify( data ).localeCompare( JSON.stringify( otherData ));
    }

    includes( other : DataProperty<Data> ) : boolean {
        return this.includesValue( other.value() );
    }


    includesValue( value : Data | undefined ) : boolean {
        return this.compareValue( value ) === 0;
    }

    protected _data : Data | undefined;

    protected _defaultData : Data | undefined;


}