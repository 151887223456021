


export const RegistrationCollections = { 

    Registrations                   : "registrations",

    IncidentRegistrations           : "incidentRegistrations",

    QuarantineRegistrations         : "quarantineRegistrations",

    SickLeaveRegistrations          : "sickLeaveRegistrations",

    SymptomRegistrations            : "symptomRegistrations",

    TestRegistrations               : "testRegistrations",

    VaccineRegistrations            : "vaccineRegistrations"
}

export type RegistrationCollectionName = keyof (typeof RegistrationCollections);

export const RegistrationCollectionNames = Object.values( RegistrationCollections );

export const QuarantineRegistrationsCollection = RegistrationCollections.QuarantineRegistrations as RegistrationCollectionName;

export const RegistrationsCollection = RegistrationCollections.Registrations as RegistrationCollectionName;

export const SickLeaveRegistrationsCollection = RegistrationCollections.SickLeaveRegistrations as RegistrationCollectionName;

export const SymptomRegistrationsCollection = RegistrationCollections.SymptomRegistrations as RegistrationCollectionName;

export const TestRegistrationsCollection = RegistrationCollections.TestRegistrations as RegistrationCollectionName;

export const IncidentRegistrationsCollection = RegistrationCollections.IncidentRegistrations as RegistrationCollectionName;

export const VaccineRegistrationsCollection = RegistrationCollections.VaccineRegistrations as RegistrationCollectionName;



