
export const Symptoms = {

    "AchesAndPains" :           "achesAndPains",

    "Bleeding" :                "bleeding",

    "Bruises" :                 "bruises",

    "ChestPain" :               "chestPain",

    "Chills" :                  "chills",

    "Confusion" :               "confusion",

    "Conjunctivitis" :          "conjunctivitis",
    
    "Coughing" :                "coughing",

    "Cramps" :                  "cramps",

    "Diarrhea" :                "diarrhea",

    "DifficultyBreathing" :     "difficultyBreathing",

    "DiscolourationFingers" :   "discolourationFingers",

    "DiscolourationToes" :      "discolourationToes",

    "DryCough" :                "dryCough",    

    "Fatigue" :                 "fatigue",
    
    "Fever" :                   "fever",
 
    "Headache" :                "headache",

    "Hiccups" :                 "hiccups",

    "HighFever" :               "highFever",

    "Hoarseness" :              "hoarseness",

    "Lethargy" :                 "lethargy",

    "LossOfApetite" :           "lossOfApetite",

    "LossOfMovement" :           "lossOfMovement",

    "LossOfSmell" :             "lossOfSmell",

    "LossOfSpeech" :            "lossOfSpeech",

    "LossOfTaste" :             "lossOfTaste",
    
    "RunnyNose" :               "runnyNose",

    "SkinRash" :                "skinRash",

    "Sneezing" :                "sneezing",

    "Tiredness" :               "tiredness",

    "ShortnessOfBreath" :       "shortnessOfBreath",

    "SoreThroat" :              "soreThroat",

    "StomachAche" :             "stomachAche",

    "Nausea" :                  "nausea",

    "Vomiting" :                "vomiting",

    "Aggression" :              "aggression",

    "Depression" :              "depression",
    
    "Anxiety" :                 "anxiety",

    "Disorientation" :          "Disorientation" 
}

export type Symptom = keyof (typeof Symptoms);


