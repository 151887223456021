
import { DatabaseObject } from "../../framework/databaseObject";
import { log } from "../../framework/databaseService";
import { DatabaseSubdocument } from "../../framework/databaseSubdocument";
import { TextProperty } from "../properties/textProperty";
import { AddressSubdocument } from "../../api/subdocuments";
import { AddressIF } from "../../api/subdocuments/addressIF";
import { CountryProperty } from "../properties/countryProperty";


export class Address extends DatabaseSubdocument implements AddressIF {

    constructor( parent : DatabaseObject, key : string ) {

        super( AddressSubdocument, parent, key );

        try {

            this.address1 = new TextProperty( this );

            this.address2 = new TextProperty( this );

            this.city = new TextProperty( this );

            this.zip = new TextProperty( this );

            this.state = new TextProperty( this );

            this.country = new CountryProperty( this );

            //log.traceInOut( "constructor()" );

        } catch( error ) {

            log.warn( "constructor()", "Error initializing address", error );
            
            throw new Error( (error as any).message );
        }
    }

    address1: TextProperty;

    address2: TextProperty;

    city: TextProperty;

    zip: TextProperty;

    state: TextProperty; 

    country: CountryProperty; 
 }
