
import { HealthguardProjectIF } from "../../api/documents/healthguardProjectIF";
import { log } from "../../../services/database/framework/databaseService";
import { CollectionDatabase } from "../../../services/database/impl/core/collectionDatabase";
import { IncidentRegistration } from "./incidentRegistration";
import { QuarantineRegistration } from "./quarantineRegistration";
import { SickLeaveRegistration } from "./sickLeaveRegistration";
import { SymptomRegistration } from "./symptomRegistration";
import { TestRegistration } from "./testRegistration";
import { VaccineRegistration } from "./vaccineRegistration";
import { Measure } from "./measure";
import { Project } from "../../../services/database/impl/documents/project";
import { HealthguardProjectDocument } from "../../api/healthguardDocuments";
import { ReferencesProperty } from "../../../services/database/impl/properties/referencesProperty";
import { HealthguardCompany } from "./healthguardCompany";
import { Risk } from "./risk";
import { Gathering } from "./gathering";
import { IncidentRegistrationsCollection, QuarantineRegistrationsCollection, SickLeaveRegistrationsCollection, SymptomRegistrationsCollection, TestRegistrationsCollection, VaccineRegistrationsCollection } from "../../api/registrationCollections";
import { CollectionGroupDatabase } from "../../../services/database/impl/core/collectionGroupDatabase";

export class HealthguardProject extends Project implements HealthguardProjectIF {

    constructor( projectsCollection : CollectionDatabase<HealthguardProject>, documentPath? : string ) {

        super( HealthguardProjectDocument, projectsCollection, documentPath ); 

        try {
            const company = this.company.emptyDocument() as HealthguardCompany;

            this.risks = new ReferencesProperty<Risk>( this, 
                company != null ? 
                    () => [company.risks.collectionGroup()] : 
                    undefined,
                "projects" );

            this.measures = new ReferencesProperty<Measure>( this, 
                company != null ? 
                    () => [company.measures.collectionGroup()] : 
                    undefined,
                "projects" );

            this.gatherings = new ReferencesProperty<Gathering>( this, 
                company != null ? 
                    () => [company.gatherings.collectionGroup()] : 
                    undefined,
                "projects" );

            this.incidentRegistrations = new ReferencesProperty<IncidentRegistration>( this, 
                () => [this.parentCollectionGroup( IncidentRegistrationsCollection ) as CollectionGroupDatabase<IncidentRegistration>],
                "projects" );
    
            
            this.quarantineRegistrations = new ReferencesProperty<QuarantineRegistration>( this, 
                () => [this.parentCollectionGroup( QuarantineRegistrationsCollection ) as CollectionGroupDatabase<QuarantineRegistration>],
                "projects" );

    
            this.sickLeaveRegistrations = new ReferencesProperty<SickLeaveRegistration>( this, 
                () => [this.parentCollectionGroup( SickLeaveRegistrationsCollection ) as CollectionGroupDatabase<SickLeaveRegistration>],
                "projects" );
    
  
            this.symptomRegistrations = new ReferencesProperty<SymptomRegistration>( this, 
                () => [this.parentCollectionGroup( SymptomRegistrationsCollection ) as CollectionGroupDatabase<SymptomRegistration>],
                "projects" );
    
            this.testRegistrations = new ReferencesProperty<TestRegistration>( this, 
                () => [this.parentCollectionGroup( TestRegistrationsCollection ) as CollectionGroupDatabase<TestRegistration>],
                "projects" );
        
            this.vaccineRegistrations = new ReferencesProperty<VaccineRegistration>( this, 
                () => [this.parentCollectionGroup( VaccineRegistrationsCollection ) as CollectionGroupDatabase<VaccineRegistration>],
                "projects" );
            
            //log.traceInOut( "constructor()", UnitsCollection );

        } catch( error ) {

            log.warn( "constructor()", "Error initializing unit", error );
            
            throw new Error( (error as any).message );
        }
    }

    readonly risks: ReferencesProperty<Risk>;

    readonly measures: ReferencesProperty<Measure>;

    readonly gatherings: ReferencesProperty<Gathering>;

    readonly incidentRegistrations: ReferencesProperty<IncidentRegistration>;

    readonly quarantineRegistrations: ReferencesProperty<QuarantineRegistration>;

    readonly sickLeaveRegistrations: ReferencesProperty<SickLeaveRegistration>;

    readonly symptomRegistrations: ReferencesProperty<SymptomRegistration>;

    readonly testRegistrations: ReferencesProperty<TestRegistration>;

    readonly vaccineRegistrations: ReferencesProperty<VaccineRegistration>;

}


