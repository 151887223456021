
import { DatabaseObject } from "../../framework/databaseObject";
import { BasicProperty } from "./basicProperty";
import { PropertyType, PropertyTypes } from "../../api/definitions/propertyType"; 
import { CountryPropertyIF } from "../../api/properties/countryPropertyIF";


export class CountryProperty extends BasicProperty<string> implements CountryPropertyIF {

    constructor( parent : DatabaseObject, defaultValue? : string ) {
        super( PropertyTypes.Country as PropertyType, parent, defaultValue ); 

    }

    compareTo( other : CountryProperty ) : number {

        return this.compareValue( other.value() );
     }
 
     compareValue( otherValue : string | undefined ) : number {
 
         const value = this.value();
 
         if( value == null && otherValue == null ) { 
             return 0;
         }
 
         if( value != null && otherValue == null ) {
             return 1;
         }
 
         if( value == null && otherValue != null ) {
             return -1;
         }
 
         return this.value()!.toString().localeCompare( otherValue! );
     }
}