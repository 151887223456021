import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import withWidth, { WithWidthProps } from '@material-ui/core/withWidth';
import { QueryClient, QueryClientProvider } from 'react-query';

import { Device } from '@capacitor/device';

import { Redirect, Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import { withCookies, ReactCookieProps } from 'react-cookie';  

import { App as CapacitorApp } from '@capacitor/app';

import { LoggerFactory } from 'services/common/api/loggerFactory';

import { AppContext, AppContextProps } from 'ui/app/appContext';
import AdminHome from '../views/adminHome';
import CompanyHome from '../views/companyHome';
import UnitHome from '../views/unitHome';
import UserHome from '../views/userHome';

import { Factory } from 'services/common/api/factory';
import SignIn from 'ui/views/signIn';
import { ObservableIF } from 'services/common/api/observableIF';
import { Monitor } from 'services/common/api/monitor';
import { Observation } from 'services/common/api/observation';
import Loading from 'ui/components/loading';
import { AuthenticationNotification } from "services/authentication/api/authenticationNotification";
import CookiesPersistentState from './persistentState/cookiesPersistentState';
import { FirebaseClientService } from './firebase/firebaseClientService';

import { Target } from '../../services/common/api/targets';
import { HealthguardCompanyIF } from '../../healthguard/api/documents/healthguardCompanyIF';
import { HealthguardUserIF } from '../../healthguard/api/documents/healthguardUserIF';
import { HealthguardUnitIF } from '../../healthguard/api/documents/healthguardUnitIF';

import VerifyEmail from '../views/verifyEmail';
import VerifyPhone from './firebase/verifyPhone';

import { PersistentKeyActiveCompany, PersistentKeyActiveHazard, PersistentKeyActiveUnit } from '../components/appFrame';
import { ReferenceHandle } from '../../services/database/api/core/referenceHandle';
import { AuthenticationClaims } from '../../services/authentication/api/authenticationClaims';
import { errorDialog } from '../components/simpleDialog';
import { RealmClientService } from './realm/realmClientService';

import { FirebasePlatform, Platform, RealmPlatform } from '../../services/common/api/platforms';
import i18next, { activeLanguage, setActiveLanguage } from './localization';
import theme from './theme';
import { HazardIF } from '../../healthguard/api/documents/hazardIF';
import { TerminalPlatform, TerminalPlatforms } from "../../services/database/api/definitions/terminalPlatform";
import { Language } from '../../services/database/api/definitions/language';
import { HomePath, HomePaths } from '../../services/common/api/homePaths';
import { Display, DisplayOrientation, DisplayOrientations, DisplayPlatform, DisplayType, DisplayTypes } from './display';
import { TerminalIF } from '../../services/database/api/documents/terminalIF';
import { PushIF } from '../../services/messaging/api/push/pushIF';

import applicationConfiguration from "healthguard/data/settings/application.json";
import { MessageIF } from '../../services/database/api/documents/messageIF';
import { Environment } from '../../services/common/api/environment';

const target = process.env.REACT_APP_TARGET as Target;

const platform = process.env.REACT_APP_PLATFORM as Platform;

const applicationService =
  platform === FirebasePlatform ? new FirebaseClientService(target) :
    (platform === RealmPlatform ? new RealmClientService(target) :
      undefined);

Factory.create(
        Environment.Client, 
        target,
        applicationService! );

export const log = LoggerFactory.logger("ui");   

export const desktopBreakpoint = "md";

export const tabletBreakpoint = "sm";

export const mobileBreakpoint = "xs";

export const SigninPath = "/signin";
export const EmailVerificationPath = "/emailVerification";
export const PhoneVerificationPath = "/phoneVerification";


//const PersistentKeyCurrentPath = "currentPath";

const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    width: '100%',
    height: '100%',
    overflow: 'hidden'
  },
  app: {
    display: 'flex',
    height: '100%',
    width: '100%',
    overflow: 'hidden' 
  }
});

interface MatchParams {
}

interface AppProps extends WithWidthProps, WithStyles<typeof styles>, WithTranslation, RouteComponentProps<MatchParams>, ReactCookieProps {
}

interface AppState { // Document View Props

  currentUser?: HealthguardUserIF;

  currentMinors?: Map<string,HealthguardUserIF>;

  currentUnit?: HealthguardUnitIF;

  currentCompany?: HealthguardCompanyIF;

  currentHazard?: HazardIF;

  currentTerminal?: TerminalIF;

  currentLanguage?: Language;

  currentDisplay?: Display;

  authenticationClaims?: AuthenticationClaims;

  error?: string;

  signInAttempts?: number;

}

class App extends React.PureComponent<AppProps, AppState> {

  constructor(props: AppProps) {

    super(props);

    this.windowRef = React.createRef();

    this.state = {

      error: undefined,

      signInAttempts: 0

    } as AppState;

    this.updateDisplay = this.updateDisplay.bind(this);
    this.updateTerminal = this.updateTerminal.bind(this);
    this.handleAuthenticationNotification = this.handleAuthenticationNotification.bind(this);
    this.onNotify = this.onNotify.bind(this);
    this.homePath = this.homePath.bind(this);
    this.updateCurrentUnit = this.updateCurrentUnit.bind(this);
    this.onUpdateActiveUnit = this.onUpdateActiveUnit.bind(this);
    this.updateCurrentCompany = this.updateCurrentCompany.bind(this);
    this.onUpdateActiveCompany = this.onUpdateActiveCompany.bind(this);
    this.updateCurrentHazard = this.updateCurrentHazard.bind(this);
    this.onUpdateActiveHazard = this.onUpdateActiveHazard.bind(this);
    this.onRedirect = this.onRedirect.bind(this);
    this.updateWindowSize = this.updateWindowSize.bind(this);
    this.updateLanguage = this.updateLanguage.bind(this);
    this.updateDisplay = this.updateDisplay.bind(this);
    this.updateTerminal = this.updateTerminal.bind(this);
    this.onReceivePushNotification = this.onReceivePushNotification.bind(this);
    this.onReceivePushNotificationAction = this.onReceivePushNotificationAction.bind(this);

    //log.traceInOut( "constructor()" );
  }

  async componentDidMount() {

    try {
      log.traceIn("componentDidMount()", "Browser language:", i18next.language);

      await Factory.get().init();

      if( applicationService?.persistentState instanceof CookiesPersistentState ) {
          applicationService.persistentState.setCookies( this.props.cookies! );
      }

      const monitor = {
        observer: this,

        onNotify: this.onNotify
      } as Monitor;

      await Factory.get().authenticationService!.subscribe(monitor);

      //if ( Factory.get().authenticationService!.initialized ) {
      //  await this.handleAuthenticationNotification(undefined);
      //} 
      
      await this.updateWindowSize();

      window.addEventListener('resize', this.updateWindowSize);

      // For mobiles
      CapacitorApp.addListener('backButton', ({canGoBack}) => {
        if(!canGoBack){
          CapacitorApp.exitApp();
        } else {
          window.history.back();
        }
      });

      log.traceOut("componentDidMount()");

    } catch (error) {
      log.warn("componentDidMount()", "Error mounting app", error);

      await errorDialog(error);

      log.traceOut("componentDidMount()", error);
    }
  }

  async componentDidUpdate() {
    //log.traceIn("componentDidUpdate()");

    try {

      //log.traceOut("componentDidUpdate()");

      await this.updateDisplay();

    } catch (error) {
      log.warn("componentDidUpdate()", "Error unmounting updating app ", error);
    }
  }

  async componentWillUnmount() {
    //log.traceIn("componentWillUnmount()");

    try {

      if( this.state.currentCompany != null ) {
        await this.state.currentCompany.unsubscribe( this );
      }

      if( this.state.currentUnit != null ) {
        await this.state.currentUnit.unsubscribe( this );
      }

      if( this.state.currentUser != null ) {
        await this.state.currentUser.unsubscribe( this );
      }

      if( this.state.currentHazard != null ) {
        await this.state.currentHazard.unsubscribe( this ); 
      }

      window.removeEventListener('resize', this.updateWindowSize);
      //log.traceOut("componentWillUnmount()");

    } catch (error) {
      log.warn("componentWillUnmount()", "Error unmounting app", error);

      log.traceOut("componentWillUnmount()", "error");
    }
  }

  onNotify = async (observable: ObservableIF,
    observation: Observation,
    objectId?: string,
    object?: any): Promise<void> => {

    try {
      log.traceIn("onNotify()", Observation[observation], objectId, object);

      const authenticationNotification = object as AuthenticationNotification;

      await this.handleAuthenticationNotification(authenticationNotification);

      log.traceOut("onNotify()");

    } catch (error) {
      log.warn("Error receiving database notification", error);

      await errorDialog(error);

      log.traceOut("onNotify()", error);
    }
  }

  private updateDisplay = async ( width?: number, height?: number )  => { 

    log.traceIn("updateDisplay()", this.props.width, {width}, {height} );

    if (this.props.width == null ) {

      log.traceOut("updateDisplay()", "no valid window width");
      return;
    }

    const displayPlatform = (await Device.getInfo()).platform as DisplayPlatform;

    const displayOrientation = width != null && height != null && width > height ? 
        DisplayOrientations.Horizontal as DisplayOrientation: DisplayOrientations.Vertical as DisplayOrientation; 

    let displayType;

    if (+theme.breakpoints.values[this.props.width] <= +theme.breakpoints.values[mobileBreakpoint] && 
        displayOrientation === DisplayOrientations.Vertical ) {

      displayType = DisplayTypes.Mobile as DisplayType;
    }
    else if (+theme.breakpoints.values[this.props.width] <= +theme.breakpoints.values[tabletBreakpoint] &&
      displayOrientation === DisplayOrientations.Horizontal) {

      displayType = DisplayTypes.Mobile as DisplayType;
    }
    else if (+theme.breakpoints.values[this.props.width] <= +theme.breakpoints.values[tabletBreakpoint]) {

      displayType = DisplayTypes.Tablet as DisplayType;
    }
    else {
      displayType = DisplayTypes.Desktop as DisplayType;
    }

    if((width != null && width !== this.state.currentDisplay?.width) ||
      (height != null && height !== this.state.currentDisplay?.width) ||
      displayPlatform !== this.state.currentDisplay?.displayPlatform ||
      displayType !== this.state.currentDisplay?.displayType ||
      displayOrientation !== this.state.currentDisplay?.displayOrientation) {

      const display = {
        width: width,
        height: height,
        displayPlatform: displayPlatform,
        displayType: displayType,
        displayOrientation: displayOrientation
      } as Display;
      
      this.setState({
        currentDisplay: display
      }); 

      log.traceOut("updateDisplay()", "Updated", {display});
    }

    log.traceOut("updateDisplay()", "No update"); 

  }


  handleAuthenticationNotification = async (authenticationNotification?: AuthenticationNotification): Promise<void> => {

    try {
      log.traceIn("handleAuthenticationNotification()", authenticationNotification?.currentUser );

      const currentUser = authenticationNotification != null ? authenticationNotification.currentUser as HealthguardUserIF : undefined;

      const authenticationClaims = authenticationNotification != null ? authenticationNotification.authenticationClaims : undefined;

      const error = authenticationNotification != null ? authenticationNotification.error : undefined;

      if (authenticationNotification == null ||
        authenticationNotification.authenticationClaims == null ||
        authenticationNotification.authenticationClaims.authenticationId == null) {

        let signInAttempts;

        if (error != null) {
          if (this.state.signInAttempts == null) {
            signInAttempts = 0;
          }
          else {
            signInAttempts = this.state.signInAttempts;
          }
          signInAttempts++;
        }
        else {
          signInAttempts = 0;
        }

        this.setState({

          authenticationClaims: undefined,

          currentUser: undefined,

          currentUnit: undefined,

          currentCompany: undefined,

          currentHazard: undefined,

          currentLanguage: undefined,

          error: error,

          signInAttempts: signInAttempts
        });

        await setActiveLanguage( undefined );

        log.traceOut("handleAuthenticationNotification()", "cleared authentication");
        return;
      }

      const currentMinors = await currentUser?.users.collection().documents() as Map<string,HealthguardUserIF>; 

      const currentUnit = await this.updateCurrentUnit(currentUser);

      const currentCompany = await this.updateCurrentCompany(currentUser);

      const currentHazard = await this.initCurrentHazard();  

      this.setState({

        authenticationClaims: authenticationClaims,

        currentUser: currentUser,

        currentMinors: currentMinors,

        currentUnit: currentUnit,

        currentCompany: currentCompany,

        currentHazard: currentHazard,

        error: undefined,

        signInAttempts: 0
      });

      await this.updateLanguage( currentCompany, currentUser );

      await this.updateTerminal( currentUser );

      log.traceOut("handleAuthenticationNotification()", "state:", this.state);

    } catch (error) {
      log.warn("Error receiving database notification", error);

      await errorDialog(error);

      log.traceOut("handleAuthenticationNotification()", error);
    }
  }

  private updateLanguage = async ( currentCompany? : HealthguardCompanyIF, currentUser? : HealthguardUserIF ) => {

    log.traceIn("updateLanguage()");

    try {
      const ignoreDefaults = true;

      let currentLanguage = currentUser?.language.value(ignoreDefaults);

      if (currentLanguage == null) {
        currentLanguage = currentCompany?.language.value(ignoreDefaults);
      }

      if (currentLanguage !== this.state.currentLanguage) {

        this.setState({
          currentLanguage: currentLanguage
        })

        if (currentLanguage !== activeLanguage()) {

          await setActiveLanguage(currentLanguage);

          this.forceUpdate();
        }
      }

      log.traceOut("updateLanguage()", {currentLanguage});

    } catch (error) {

      log.warn( "updateLanguage()", "Error updating language", error);
    }
  }

  private updateTerminal = async ( currentUser? : HealthguardUserIF ) => {

    log.traceIn("updateTerminal()");

    try {
      if( currentUser == null ) {

        log.traceOut("updateTerminal()", "No current user");
        return;
      }

      if( this._deviceId != null ) {

        log.traceOut("updateTerminal()", "Device ID already read");
        return;
      }

      this._deviceId = ""; // empty placeholder to avoid repeat during below wait

      this._deviceId = (await Device.getId()).uuid; 

      log.debug( "updateTerminal()", this._deviceId );

      const deviceInfo = await Device.getInfo();

      log.debug( "updateTerminal()", {deviceInfo} );

      let terminal = await Factory.get().databaseService.databaseManager.documentWithProperty( 
        currentUser.terminals.collection(),
        "deviceId", 
        this._deviceId ) as TerminalIF;

      if( terminal == null ) {

        log.debug( "updateTerminal()", "Terminal not found, creating new terminal with ID: " +  this._deviceId );

        terminal = currentUser.terminals.collection().newDocument();

        terminal.deviceId.setValue(  this._deviceId );
      }

      let title = deviceInfo.name

      if( title == null ) {
        title = currentUser.title.value() + " " + deviceInfo.model;
      }

      terminal.title.setValue( title );

      terminal.terminalPlatform.setValue( deviceInfo.platform as TerminalPlatform );

      terminal.operatingSystem.setValue( deviceInfo.operatingSystem );

      terminal.operatingSystemVersion.setValue( deviceInfo.osVersion );

      terminal.manufacturer.setValue( deviceInfo.manufacturer );

      terminal.model.setValue( deviceInfo.model );

      terminal.emulator.setValue(deviceInfo.isVirtual);

      terminal.lastLogin.setValue( new Date() );

      if( deviceInfo.platform !== TerminalPlatforms.Web ) {

        const pushToken = await Factory.get().messagingService.pushReceiver?.register({

          onReceivePush: this.onReceivePushNotification,
          onPushAction: this.onReceivePushNotificationAction
        });

        terminal.pushToken.setValue( pushToken );
      }

      if( terminal.isNew()) {
        await terminal.create();
      }
      else {
        await terminal.update();
      }

      this.setState({
        currentTerminal: terminal
      });

      log.traceOut("updateTerminal()");

    } catch (error) {

      log.warn( "updateTerminal()", "Error updating current terminal", error);
    }
  }

  private onReceivePushNotification = async ( push: PushIF ) => { 

    log.traceIn("onReceivePushNotification()", push );

    try {

      const messageReferenceHandle = push.data as ReferenceHandle<MessageIF>;

      if( messageReferenceHandle != null ) {

        this.props.history.push(HomePaths.UserHomePath + messageReferenceHandle.path); 
      }

      log.traceOut("onReceivePushNotification()");
    } catch (error) {
      log.warn("Error receiving push notification ", error);
    }
  }

  private onReceivePushNotificationAction = async ( push: PushIF, actionId : string, actionValue? : string ) => { 

    log.traceIn("onReceivePushNotificationAction()", push, actionId, actionValue );

    try {

      const messageReferenceHandle = push.data as ReferenceHandle<MessageIF>;

      if( messageReferenceHandle != null ) {

        this.props.history.push(HomePaths.UserHomePath + messageReferenceHandle.path); 
      }

      log.traceOut("onReceivePushNotificationAction()");
    } catch (error) {
      log.warn("Error receiving push action ", error);
    }
  }

  private onUpdateActiveUnit = async (activeUnit: HealthguardUnitIF | undefined) => {

    log.traceIn("onUpdateActiveUnit()");
    try {

      const persistentActiveUnitPath = 
        Factory.get().persistentState!.property(PersistentKeyActiveUnit) as string; 

      if( Factory.get().databaseService.databaseFactory.equalDatabasePaths( 
          activeUnit?.referenceHandle().path, persistentActiveUnitPath ) ) {

        log.traceIn("onUpdateActiveUnit()", "No change");
        return;
      }

      if (activeUnit != null) {
        Factory.get().persistentState!.setProperty(PersistentKeyActiveUnit, activeUnit.referenceHandle().path);
      }
      else {
        Factory.get().persistentState!.clearProperty(PersistentKeyActiveUnit);
      }

      const currentUnit = await this.updateCurrentUnit(this.state.currentUser);

      this.setState({ currentUnit: currentUnit });

    } catch (error) {
      log.warn("Error updating active unit ", error);

      await errorDialog(error);

      log.traceOut("onUpdateActiveUnit()", error);
    }
  }

  private async updateCurrentUnit(currentUser: HealthguardUserIF | undefined): Promise<HealthguardUnitIF | undefined> {

    log.traceIn("updateCurrentUnit()");

    try {

      const onNotifyCurrentUnit = async (observable: ObservableIF,
        observation: Observation,
        objectId: string | null | undefined,
        object: object | null | undefined): Promise<void> => {

        if (object != null) {

          const unit = object as HealthguardUnitIF;

          this.setState({ currentUnit: unit });
        }
      }

      if (currentUser == null) {
        log.traceOut("currentUnit()", "No current user");

        return undefined;
      }

      const persistentActiveUnitPath = Factory.get().persistentState!.property(PersistentKeyActiveUnit) as string; 

      if (persistentActiveUnitPath != null) {

        if (this.state.currentUnit != null && 
           Factory.get().databaseService.databaseFactory.equalDatabasePaths(
            this.state.currentUnit.databasePath(), persistentActiveUnitPath ) ) {

          log.traceOut("updateCurrentUnit()", "Active unit from state");
          return this.state.currentUnit;
        }

        try {
          const activeUnit =
            await Factory.get().databaseService.databaseFactory.documentFromUrl(
              persistentActiveUnitPath ) as HealthguardUnitIF;

          if (activeUnit == null) {
            throw new Error("Could not retrieve active unit");
          }


          await activeUnit.subscribe({
            observer: this,
            onNotify: onNotifyCurrentUnit
          } as Monitor);

          log.traceOut("updateCurrentUnit()", "New current unit from active unit: ", activeUnit.referenceHandle());
          return activeUnit;

        } catch (error) {
          log.warn("Error reading active unit", error);

          Factory.get().persistentState!.clearProperty(PersistentKeyActiveUnit)
        }
      }

      let unit: HealthguardUnitIF | undefined;

      const managingReferenceHandles = currentUser.managing.referenceHandles();

      if (managingReferenceHandles.size > 0) {

        let managingUnitPath: string;
        managingReferenceHandles.forEach(managingReferenceHandle => {

          if (managingUnitPath == null || managingReferenceHandle.path.length < managingUnitPath.length) { 

            managingUnitPath = managingReferenceHandle.path;
          }
        })

        unit = await currentUser.managing.document(managingUnitPath!) as HealthguardUnitIF;
      }

      if (unit == null) {
        unit = await currentUser.unit.document() as HealthguardUnitIF;
      }

      if (unit != null) {

        if (this.state.currentUnit != null &&
          this.state.currentUnit.databasePath() === unit.databasePath()) {

          log.traceOut("updateCurrentUnit()", "Unit from state");
          return this.state.currentUnit;
        }

        await unit.subscribe({
          observer: this,
          onNotify: onNotifyCurrentUnit
        } as Monitor);

        log.traceOut("updateCurrentUnit()", "New current unit from owner: " + unit.referenceHandle());
        return unit;
      }

      log.traceOut("updateCurrentUnit()", "No current unit for user");
      return undefined;

    } catch (error) {
      log.warn("Error reading current unit", error);

      await errorDialog(error);

      log.traceOut("updateCurrentUnit()", "error");
      return undefined;
    }
  }


  private onUpdateActiveCompany = async (activeCompany: HealthguardCompanyIF | undefined) => {

    log.traceIn("onUpdateActiveCompany()");
    try {

      const persistentActiveCompanyPath = 
        Factory.get().persistentState!.property(PersistentKeyActiveCompany) as string; 

      if( Factory.get().databaseService.databaseFactory.equalDatabasePaths(
          activeCompany?.referenceHandle().path, persistentActiveCompanyPath ) ) { 
        log.traceIn("onUpdateActiveCompany()", "No change");
        return;
      }

      if (activeCompany != null) {
        Factory.get().persistentState!.setProperty(PersistentKeyActiveCompany, activeCompany.referenceHandle().path);
      }
      else {
        Factory.get().persistentState!.clearProperty(PersistentKeyActiveCompany);
      }

      const currentCompany = await this.updateCurrentCompany(this.state.currentUser);

      this.setState({ currentCompany: currentCompany });

    } catch (error) {
      log.warn("Error updatinf active company ", error);

      await errorDialog(error);

      log.traceOut("onUpdateActiveCompany()", error);
    }
  }

  private async updateCurrentCompany(currentUser: HealthguardUserIF | undefined): Promise<HealthguardCompanyIF | undefined> {

    log.traceIn("updateCurrentCompany()", {currentUser});

    try {

      const onNotifyCurrentCompany = async (observable: ObservableIF,
        observation: Observation,
        objectId: string | null | undefined,
        object: object | null | undefined): Promise<void> => {

        if (object != null) {
          const company = object as HealthguardCompanyIF;
          
          this.setState({ currentCompany: company });

          await this.updateLanguage( company, this.state.currentUser );
        }
      }

      if (currentUser == null) {
        log.traceOut("updateCurrentCompany()", "No current user");

        return undefined;
      }

      const persistentActiveCompanyPath = Factory.get().persistentState!.property(PersistentKeyActiveCompany) as string;

      if (persistentActiveCompanyPath != null) {

        if( this.state.currentCompany != null && 
            Factory.get().databaseService.databaseFactory.equalDatabasePaths(
              this.state.currentCompany.databasePath(), persistentActiveCompanyPath ) ) {

          log.traceOut("updateCurrentCompany()", "Active company from state");
          return this.state.currentCompany;
        }

        try {
          const activeCompany = await Factory.get().databaseService.databaseFactory.documentFromUrl(
            persistentActiveCompanyPath ) as HealthguardCompanyIF;

          if (activeCompany == null) {
            throw new Error("Could not retrieve active company");
          }

          await activeCompany.subscribe({
            observer: this,
            onNotify: onNotifyCurrentCompany
          } as Monitor);

          log.traceOut("updateCurrentCompany()", "New current company from active company: ", activeCompany.referenceHandle().title);
          return activeCompany;

        } catch (error) {
          log.warn("Error reading active company", error);

          Factory.get().persistentState!.clearProperty(PersistentKeyActiveCompany)
        }
      }

      const company = await currentUser.company.document() as HealthguardCompanyIF;

      if (company != null) {

        if( this.state.currentCompany != null &&
            Factory.get().databaseService.databaseFactory.equalDatabasePaths(
              this.state.currentCompany.databasePath(), company.databasePath() ) ) {

          log.traceOut("updateCurrentCompany()", "Company from state");
          return this.state.currentCompany;
        }

        await company.subscribe({
            observer: this,
            onNotify: onNotifyCurrentCompany
        } as Monitor); 

        log.traceOut("updateCurrentCompany()", "New current company from owner", {company} );
        return company;
      }

      log.traceOut("updateCurrentCompany()", "No current company for user");
      return undefined;

    } catch (error) {
      log.warn("Error reading current company", error);

      await errorDialog(error);

      log.traceOut("updateCurrentCompany()", "error");
      return undefined;
    }
  }

  private onUpdateActiveHazard = async (activeHazard: HazardIF | undefined) => {

    log.traceIn("onUpdateActiveHazard()");
    try {

      if (activeHazard != null) {
        Factory.get().persistentState!.setProperty(PersistentKeyActiveHazard, activeHazard.referenceHandle());
      }
      else {
        Factory.get().persistentState!.clearProperty(PersistentKeyActiveHazard);
      }

      const currentHazard = await this.updateCurrentHazard(activeHazard);

      this.setState({ currentHazard: currentHazard });

    } catch (error) {
      log.warn("Error updating active health hazard ", error);

      await errorDialog(error);

      log.traceOut("onUpdateActiveHazard()", error);
    }
  }

  private async initCurrentHazard(): Promise<HazardIF | undefined> {

    log.traceIn("initCurrentHazard()");

    try {

      const onNotifyCurrentHazard = async (observable: ObservableIF,
        observation: Observation,
        objectId: string | null | undefined,
        object: object | null | undefined): Promise<void> => {

        if (object != null) {
          this.setState({ currentHazard: object as HazardIF });
        }
      }

      const persistentActiveHazard = 
        Factory.get().persistentState!.property(PersistentKeyActiveHazard) as ReferenceHandle<HazardIF>;

      if (persistentActiveHazard != null) {

        try {
          const activeHazard = await Factory.get().databaseService.databaseFactory.documentFromUrl(
            persistentActiveHazard.path) as HazardIF;

          if (activeHazard == null) {
            throw new Error("Could not retrieve active health hazard");
          }
          
          await activeHazard.subscribe({
            observer: this,
            onNotify: onNotifyCurrentHazard
          } as Monitor);  
          
          log.traceOut("initCurrentHazard()", "New current hazard from active hazard: ", activeHazard.referenceHandle().title);
          return activeHazard;

        } catch (error) {
          log.warn("Error reading active health hazard", error);

          Factory.get().persistentState!.clearProperty(PersistentKeyActiveHazard)
        }
      }

      log.traceOut("initCurrentHazard()", "No current health hazard");
      return undefined;

    } catch (error) {
      log.warn("Error reading current health hazard", error);

      await errorDialog(error);

      log.traceOut("initCurrentHazard()", "error");
      return undefined;
    }
  }

  private async updateCurrentHazard(currentHazard: HazardIF | undefined): Promise<HazardIF | undefined> {

    log.traceIn("updateCurrentHazard()");

    try {

      const onNotifyCurrentHazard = async (observable: ObservableIF,
        observation: Observation,
        objectId: string | null | undefined,
        object: object | null | undefined): Promise<void> => {

        if (object != null) {
          this.setState({ currentHazard: object as HazardIF });
        }
      }

      const persistentActiveHazard = 
        Factory.get().persistentState!.property(PersistentKeyActiveHazard) as ReferenceHandle<HazardIF>;

      if (persistentActiveHazard != null) {

        if (this.state.currentHazard != null && this.state.currentHazard.databasePath() === persistentActiveHazard.path) {

          log.traceOut("updateCurrentHazard()", "Active hazard from state");
          return this.state.currentHazard;
        }

        try {
          const activeHazard = await Factory.get().databaseService.databaseFactory.documentFromUrl(
            persistentActiveHazard.path) as HazardIF;

          if (activeHazard == null) {

            throw new Error("Could not retrieve active health hazard");
          }
          
          await activeHazard.subscribe({
            observer: this,
            onNotify: onNotifyCurrentHazard
          } as Monitor);  
          
          log.traceOut("updateCurrentHazard()", "New current health hazard from active health hazard: ", activeHazard.referenceHandle().title);
          return activeHazard;

        } catch (error) {
          log.warn("Error reading active health hazard", error);

          Factory.get().persistentState!.clearProperty(PersistentKeyActiveHazard)
        }
      }

      log.traceOut("updateCurrentHazard()", "No current health hazard");
      return undefined;

    } catch (error) {
      log.warn("Error reading current health hazard", error);

      await errorDialog(error);

      log.traceOut("updateCurrentHazard()", "error");
      return undefined;
    }
  }

  private onRedirect = async ( path : string ) => {

    log.traceIn("onRedirect()");
    try {

      log.traceIn("onRedirect()");

    } catch (error) {
      log.warn( "onRedirect", "Error updating active unit ", error);

      await errorDialog(error);
    }
  }


  private homePath(): HomePath | undefined {

    log.traceIn("homePath()", this.props.location.pathname);

    if (this.state.currentUser == null) {
      log.traceOut("homePath()", "No current user");
      return undefined;
    }

    if (this.state.authenticationClaims == null) {
      log.traceOut("homePath()", "No authentication claims");
      return undefined;
    }

    const currentPath = this.props.location.pathname;

    if (currentPath.startsWith(HomePaths.UserHomePath)) {
      log.traceOut("homePath()", "No change", HomePaths.UserHomePath);
      return HomePaths.UserHomePath as HomePath;
    }

    if (currentPath.startsWith(HomePaths.UnitHomePath) && this.state.currentUnit != null &&
      (this.state.authenticationClaims.unitsAdmin != null ||
        this.state.authenticationClaims.companiesAdmin != null ||
        !!this.state.authenticationClaims.systemAdmin)) {

      log.traceOut("homePath()", "No change", HomePaths.UnitHomePath);
      return HomePaths.UnitHomePath as HomePath;
    }

    if (currentPath.startsWith(HomePaths.CompanyHomePath) && this.state.currentCompany != null &&
      (!!this.state.authenticationClaims.systemAdmin || this.state.authenticationClaims.companiesAdmin != null)) {

      log.traceOut("homePath()", "No change", HomePaths.CompanyHomePath);
      return HomePaths.CompanyHomePath as HomePath;
    }

    if (currentPath.startsWith(HomePaths.AdminHomePath) &&
      !!this.state.authenticationClaims.systemAdmin) {

      log.traceOut("homePath()", "No change", HomePaths.AdminHomePath);

      return HomePaths.AdminHomePath as HomePath;
    }

    if (this.state.currentUnit != null && 
        (this.state.authenticationClaims.unitsAdmin != null ||
         this.state.authenticationClaims.companiesAdmin != null ) ) {

      log.traceOut("homePath()", "Force to", HomePaths.UnitHomePath);
      return HomePaths.UnitHomePath as HomePath;
    }

    if (this.state.currentCompany != null &&
       this.state.authenticationClaims.companiesAdmin != null) {

      log.traceOut("homePath()", "Force to", HomePaths.CompanyHomePath);
      return HomePaths.CompanyHomePath as HomePath;
    }

    if (!!this.state.authenticationClaims.systemAdmin) {
      log.traceOut("homePath()", "Force to", HomePaths.AdminHomePath);
      return HomePaths.AdminHomePath as HomePath;
    }

    log.traceOut("homePath()", "Empty");
    return HomePaths.UserHomePath as HomePath;

  }


  private updateWindowSize =  async () => {

    log.traceInOut("updateWindowSize()", this.windowRef.current?.clientWidth, this.windowRef.current?.clientHeight);

    const width = this.windowRef.current?.clientWidth != null ? this.windowRef.current.clientWidth : this.state.currentDisplay?.width;

    const height = this.windowRef.current?.clientHeight != null ? this.windowRef.current.clientHeight : this.state.currentDisplay?.height;

    await this.updateDisplay( width, height );

  }

  render() {
    log.traceIn("render()", this.props.location.pathname, this.state);

    const { classes } = this.props;

    if( !Factory.get().isInitialized() ) {
      return <Loading showLogo={true}/>; 
    }

    const signupUrl =
      Factory.get().configurationService.config(applicationConfiguration, "webUrl", activeLanguage()) +
      Factory.get().configurationService.config(applicationConfiguration, "signUpPath", activeLanguage());

    const appContext = {

      authenticationClaims: this.state.authenticationClaims,

      currentDisplay: this.state.currentDisplay,

      currentTerminal: this.state.currentTerminal,

      onUpdateActiveUnit: this.onUpdateActiveUnit,

      onUpdateActiveCompany: this.onUpdateActiveCompany,

      onUpdateActiveHazard: this.onUpdateActiveHazard
      
    } as AppContextProps

    if (this.state.signInAttempts == null) {
      log.traceOut("render()", "loading");
      return (<Loading showLogo={true}/>);
    }

    const sendToSignup = () => {

      log.traceInOut("sendToSignup()");

      window.open(signupUrl);

      return (<Loading showLogo={true}/>);
    }

    const sendToSignin = () => {
      log.traceInOut("sendToSignin()");

      if (this.props.location.pathname !== SigninPath) {
        return (<Redirect to={{ pathname: SigninPath }} />);
      }

      return (
        <AppContext.Provider value={appContext}>
          <SignIn error={this.state.error} signInAttempts={this.state.signInAttempts!} />
        </AppContext.Provider>
      );
    }

    const sendToEmailVerification = () => {
      log.traceInOut("sendToEmailVerification()");

      if (this.props.location.pathname !== EmailVerificationPath) {
        return (<Redirect to={{ pathname: EmailVerificationPath }} />);
      }

      return (
        <AppContext.Provider value={appContext}>
          <VerifyEmail />
        </AppContext.Provider>
      );
    }

    const sendToPhoneVerification = () => {
      log.traceInOut("sendToPhoneVerification()");

      if (this.props.location.pathname !== PhoneVerificationPath) {
        return (<Redirect to={{ pathname: PhoneVerificationPath }} />);
      }

      return (
        <AppContext.Provider value={appContext}>
          <VerifyPhone />
        </AppContext.Provider>
      );
    }

    if (this.state.authenticationClaims == null ||
      this.state.authenticationClaims.authenticationId == null ) { 

      log.traceOut("render()", "No authentication ID, send to sign in");
      return (sendToSignin());
    }

    if (this.state.authenticationClaims.verifyEmail != null) {
      log.traceOut("render()", "Send to verify email");
      return (sendToEmailVerification());
    }

    if (this.state.authenticationClaims.verifyPhone != null) {
      log.traceOut("render()", "Send to verify phone");
      return (sendToPhoneVerification());
    }

    if (this.state.currentUser == null) {

      log.traceOut("render()", "No authenticated user, send to signup");
      return (sendToSignup());
    }

    if (this.state.currentCompany == null &&
      !this.state.authenticationClaims?.systemAdmin) {

      log.traceOut("render()", "No current company, send to signup");
      return (sendToSignup());
    }

    const homePath = this.homePath();

    if (homePath != null) {

      if (!this.props.location.pathname.startsWith(homePath)) {

        log.traceOut("render()", "Redirect to home path", homePath);
        return (<Redirect to={{ pathname: homePath }} />);
      }

      for (const collectionName of Factory.get().databaseService.databaseFactory.collectionNames()) {

        if (this.props.location.pathname.startsWith("/" + collectionName + "/")) {

          const path = homePath + this.props.location.pathname;

          log.traceOut("render()", "Inject home path into database path", path);

          return (<Redirect to={{ pathname: path }} />);
        }
      }
    }

    appContext.currentUser = this.state.currentUser;

    appContext.currentMinors = this.state.currentMinors;

    appContext.currentUnit = this.state.currentUnit;

    appContext.currentCompany = this.state.currentCompany;

    appContext.currentHazard = this.state.currentHazard; 

    appContext.currentHomePath = homePath;

    /*
    if( this.props.cookies != null ) {
      await PersistentState.setProperty( PersistentKeyCurrentPath, this.props.location.pathname );
    }
    */

    log.traceOut("render()", "main");

    const queryClient = new QueryClient();

    return (
      <div className={classes.root} ref={this.windowRef}>
        <CssBaseline />
        <AppContext.Provider value={appContext}>
          <QueryClientProvider client={queryClient}>

            <Switch>
              <Route path={HomePaths.AdminHomePath} component={AdminHome} />

              <Route path={HomePaths.CompanyHomePath} component={CompanyHome} />

              <Route path={HomePaths.UnitHomePath} component={UnitHome} />

              <Route path={HomePaths.UserHomePath} component={UserHome} />
            </Switch>
          </QueryClientProvider>  
        </AppContext.Provider>
      </div>
    );
  }

  private _deviceId? : string;

  private windowRef: React.RefObject<HTMLDivElement>;
}


export default withCookies(withRouter(withTranslation()(withStyles(styles)(withWidth()(App))))); 



