import { DatabaseObject } from "../../framework/databaseObject";
import { log } from "../../framework/databaseService";
import { DatabaseSubdocument } from "../../framework/databaseSubdocument";
import { PrivacyIF } from "../../api/subdocuments/privacyIF";

import { DefinitionProperty } from "../properties/definitionProperty";
import { DefaultIdentification, Identifications, Identification } from "../../api/definitions/identification";
import { IdentificationDefinition, TimeSpanDefinition, UserAccessTypeDefinition } from "../../api/definitions";
import { NumberProperty } from "../properties/numberProperty";
import { TimeSpan, TimeSpans } from "../../api/definitions/timeSpan";
import { PrivacySubdocument } from "../../api/subdocuments";
import { UserAccessType, UserAccessTypes } from "../../api/definitions/userAccessType";
import { DefinitionsProperty } from "../properties/definitionsProperty";
import { BooleanProperty } from "../properties/booleanProperty";

export class Privacy extends DatabaseSubdocument implements PrivacyIF { 

    constructor( parent : DatabaseObject, key : string ) {

        super( PrivacySubdocument, parent, key ); 

        try {

            this.identification = new DefinitionProperty<Identification>( 
                this, 
                IdentificationDefinition, 
                Identifications,
                DefaultIdentification ); 

            this.userAccessTypes = new DefinitionsProperty<UserAccessType>( 
                this, 
                UserAccessTypeDefinition, 
                UserAccessTypes );

            this.deleteAfterTimeSpanUnits = new NumberProperty(this, undefined, 0);

            this.deleteAfterTimeSpan = new DefinitionProperty<TimeSpan>( 
                this, 
                TimeSpanDefinition, 
                TimeSpans );

            this.disabled = new BooleanProperty( this );

            //log.traceInOut( "constructor()", parent.title, key );

        } catch( error ) {

            log.warn( "constructor()", "Error initializing settings data", error );
            
            throw new Error( (error as any).message );
        }
    }

    readonly identification : DefinitionProperty<Identification>;

    readonly userAccessTypes : DefinitionsProperty<UserAccessType>;

    readonly deleteAfterTimeSpanUnits : NumberProperty;

    readonly deleteAfterTimeSpan : DefinitionProperty<TimeSpan>;

    readonly disabled : BooleanProperty;

 }
