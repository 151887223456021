
import { DatabaseObject } from "../../framework/databaseObject";
import { log } from "../../framework/databaseService";
import { DatabaseSubdocument } from "../../framework/databaseSubdocument";
import { SettingsSubdocument } from "../../api/subdocuments";
import { SettingsIF } from "../../api/subdocuments/settingsIF";

export class Settings extends DatabaseSubdocument implements SettingsIF {

    constructor( parent : DatabaseObject, key : string ) {

        super( SettingsSubdocument, parent, key );

        try {

            
            //log.traceInOut( "constructor()", parent.title, key );

        } catch( error ) {

            log.warn( "constructor()", "Error initializing settings data", error );
            
            throw new Error( (error as any).message );
        }
    }


 }
