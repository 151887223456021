import { DatabaseConverter } from "../../framework/databaseConverter";

import { Factory } from "../../../common/api/factory";
import { FirebaseService } from "../../../application/framework/firebase/firebaseService";
import { log } from "../databaseService";

export class FirestoreConverter extends DatabaseConverter {

    toDate( timestamp : any ) : Date | undefined {

        try {
            if( timestamp == null ) {
                return undefined;
            }

            const firebaseService = Factory.get().applicationService as FirebaseService;

            const timestampObject = new firebaseService.firebase.firestore.Timestamp( 
                timestamp.seconds != null ? timestamp.seconds : 
                    timestamp._seconds != null ? timestamp._seconds :
                        0, 
                timestamp.nanoseconds != null ? timestamp.nanoseconds : 
                    timestamp._nanoseconds != null ? timestamp._nanoseconds :
                    0 );

            return timestampObject.toDate();

        } catch( error ) {
            log.warn( "toDate()", "Error converting timestamp:", (error as any).message, {timestamp} )
            return undefined;
        }
    }

    fromDate( date : Date ) : any | undefined {

        try {
            if( date == null || date.getTime() === 0 ) {
                return undefined;
            }

            const firebaseService = Factory.get().applicationService as FirebaseService;

            return firebaseService.firebase.firestore.Timestamp.fromDate( date );

        } catch( error ) {
            log.warn( "fromDate()", "Error converting date: ", (error as any).message, {date} )
            return undefined;
        }
    }    

    toGeolocation( geoPoint : any ) : any | undefined {

        try {
            if( geoPoint == null ) {
                return undefined;
            }
            return { lat: geoPoint.latitude, lon: geoPoint.longitude };

        } catch( error ) {
            log.warn( "toGeolocation()", "Error converting geoPoint:", {geoPoint} )
            return undefined;
        }
    }

    fromGeolocation( geolocation : any ) : any | undefined {

        try {
            if( geolocation == null ) {
                return undefined;
            }

            const firebaseService = Factory.get().applicationService as FirebaseService;

            return firebaseService.firebase.firestore.GeoPoint( geolocation.lat, geolocation.lon );

        } catch( error ) {
            log.warn( "fromGeolocation()", "Error converting geolocation:", {geolocation} )
            return undefined;
        }
    }  
}
