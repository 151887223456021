
import { Factory } from "../../common/api/factory";
import { UserAccess } from "../../common/api/userAccess";
import { CollectionDatabase } from "../../database/impl/core/collectionDatabase";
import { CollectionGroupDatabase } from "../../database/impl/core/collectionGroupDatabase";
import { DatabaseConverter } from "../../database/framework/databaseConverter";
import { DatabaseDocument } from "../../database/framework/databaseDocument";
import { DatabaseManager } from "../../database/framework/databaseManager";
import { ConfigurationManagerIF } from "../api/configurationManagerIF";
import { log } from "./configurationService";
import { ConfigurationConverter } from "../impl/configurationConverter";
import { CollectionGroupPathSuffix } from "../../database/api/core/databaseServiceIF";
import { Observation } from "../../common/api/observation";
import { ReferenceHandle } from "../../database";
import { Database } from "../../database/impl/core/database";


export abstract class ConfigurationManager extends DatabaseManager implements ConfigurationManagerIF {

    constructor() {

        super( { clientEncryption: false } );

        //log.traceIn( "constructor()");

        try {

            //log.traceOut( "constructor()" );
            
        } catch( error ) {

            log.warn( "Error initializing configuration database", error );
            
            throw new Error( (error as any).message );
        }
    }


    async collection( collectionDatabase : CollectionDatabase<DatabaseDocument> ): Promise<Map<string,DatabaseDocument>> {

        log.traceIn( "collection()", collectionDatabase.databasePath() );

        try {
            if( !collectionDatabase.userAccess().allowRead ) {
                throw new Error( "No read access to collection " + collectionDatabase.collectionName() );
            }

            if( !this._documentsLoaded ) {
                this._documentsLoaded = await this.loadDocuments();
            }

            const result = new Map<string,DatabaseDocument>();

            const collectionMap = this._collectionDocuments.get( collectionDatabase.databasePath() );

            if( collectionMap == null ) {
                log.traceOut( "collection()", collectionDatabase.databasePath(), "not found" );
                return result;
            }

            collectionMap.forEach( databaseDocument => {
                result.set( databaseDocument.databasePath( true ), databaseDocument );
            })

            log.traceOut( "collection()", collectionDatabase.databasePath(), collectionMap.size );
            return collectionMap;
            
        } catch( error ) {

            log.warn( "Error reading colllection", error );
            
            throw new Error( (error as any).message );
        }
    }

    async referenceHandles( collectionDatabase : CollectionDatabase<DatabaseDocument> ): Promise<Map<string,ReferenceHandle<DatabaseDocument>>> {

        log.traceIn( "referenceHandles()", collectionDatabase.databasePath() );

        try {
            if( !collectionDatabase.userAccess().allowRead ) {
                throw new Error( "No read access to collection " + collectionDatabase.collectionName() );
            }

            if( !this._documentsLoaded ) {
                this._documentsLoaded = await this.loadDocuments();
            }

            const result = new Map<string,ReferenceHandle<DatabaseDocument>>();

            let collectionMap = this._collectionDocuments.get( collectionDatabase.databasePath() );

            if( collectionMap == null ) {
                log.traceOut( "referenceHandles()", "not found" );
                return result;
            }
            collectionMap.forEach( databaseDocument => {

                const referenceHandle = databaseDocument.referenceHandle();

                result.set( referenceHandle.path, referenceHandle );
            });

            log.traceOut( "referenceHandles()", result.size );
            return result;
            
        } catch( error ) {

            log.warn( "Error reading colllection", error );
            
            throw new Error( (error as any).message );
        }
    } 

    async documents( collectionDatabase : CollectionDatabase<DatabaseDocument>, documentPaths : string[] ) : Promise<Map<string,DatabaseDocument>> {

        log.traceIn( "documents()", collectionDatabase.databasePath(), documentPaths );

        try {
            if( !collectionDatabase.userAccess().allowRead ) {
                throw new Error( "No read access to collection " + collectionDatabase.collectionName() );
            }

            if( !this._documentsLoaded ) {
                this._documentsLoaded = await this.loadDocuments();
            }

            let collectionMap = this._collectionDocuments.get( collectionDatabase.databasePath() );

            if( collectionMap == null ) {
                log.traceOut( "documents()", "not found" );
                return new Map<string,DatabaseDocument>();
            }
            const result = new Map<string,DatabaseDocument>();

            collectionMap.forEach( databaseDocument => {

                const documentPath = databaseDocument.databasePath();

                if( documentPaths.includes( documentPath) ) {

                    result.set( databaseDocument.databasePath(true), databaseDocument );
                }
            });

            log.traceOut( "("+collectionDatabase.collectionName()+")", "documents()", result.size );
            return result;
            
        } catch( error ) {

            log.warn( "Error reading colllection", collectionDatabase.collectionName(), error );
            
            throw new Error( (error as any).message );
        }
    }

    async document( collectionDatabase : CollectionDatabase<DatabaseDocument>, documentPath : string ) : Promise<DatabaseDocument | undefined> {

        //log.traceIn( "document()", collectionDatabase.databasePath(), documentPath );

        try {
            if( !collectionDatabase.userAccess().allowRead ) {
                throw new Error( "No read access to collection " + collectionDatabase.collectionName() );
            }

            if( !this._documentsLoaded ) {
                this._documentsLoaded = await this.loadDocuments();
            }

            let collectionMap = this._collectionDocuments.get( collectionDatabase.databasePath() );

            if( collectionMap == null ) {
                log.warn( "document()", "collection not found" );
                return undefined;
            }

            const configDocument = collectionMap.get( documentPath.split("?")[0] );

            if( configDocument == null ) {
                log.warn( "document()", "document not found", {collectionMap}, {documentPath} );
                return undefined;
            }

            configDocument.setUserAccess( UserAccess.allowReadOnly() )

            //log.traceOut( "("+collectionDatabase.collectionName()+")", "documents()", configDocument.databasePath() );
            return configDocument;
            
        } catch( error ) {

            log.warn( "Error reading colllection", collectionDatabase.collectionName(), error );
            
            throw new Error( (error as any).message );
        }
    }


    async monitorCollection( collectionDatabase : CollectionDatabase<DatabaseDocument> ) : Promise<void> {

        const databaseDocuments = await this.collection( collectionDatabase );

        for( const databaseDocument of databaseDocuments.values() ) {

            await collectionDatabase.onNotify(
                collectionDatabase, 
                Observation.Create, 
                databaseDocument.databasePath(), 
                databaseDocument);

        }
    }

    async releaseCollection( collectionDatabase : CollectionDatabase<DatabaseDocument> ) : Promise<void> {  
    }

    async monitorDocuments( collectionDatabase : CollectionDatabase<DatabaseDocument>, documentPaths : string[] ) : Promise<void> {

        for( const documentPath of documentPaths ) {

            const databaseDocument = await this.document( collectionDatabase, documentPath );

            if( databaseDocument != null ) {
                await collectionDatabase.onNotify(collectionDatabase, Observation.Create, databaseDocument.databasePath(true), databaseDocument);
            }
        }
    }

    async releaseDocuments( collectionDatabase : CollectionDatabase<DatabaseDocument>, documentPaths : string[] ) : Promise<void> {
    }

    async releaseAllDocuments( collectionDatabase : CollectionDatabase<DatabaseDocument> ) : Promise<void> {
    }

    async createDocument( collectionDatabase : CollectionDatabase<DatabaseDocument>, databaseDocument : DatabaseDocument ): Promise<DatabaseDocument> {
        throw new Error( "Configuration database is read only");
    }

    async deleteDocument( collectionDatabase : CollectionDatabase<DatabaseDocument>, databaseDocument : DatabaseDocument ): Promise<void> {
        
        throw new Error( "Configuration database is read only");
    }

    async archiveDocument( collectionDatabase : CollectionDatabase<DatabaseDocument>, databaseDocument : DatabaseDocument ): Promise<void> {
        
        throw new Error( "Configuration database is read only");
    }

    async readDocument( collectionDatabase : CollectionDatabase<DatabaseDocument>, databaseDocument: DatabaseDocument): Promise<boolean> {
        log.traceIn( "readDocument()", databaseDocument.referenceHandle().title );

        try {
            const configDocument = await this.document( collectionDatabase, databaseDocument.databasePath() );

            if( configDocument == null ) {
                log.traceOut( "readDocument()", "document not found" );
                return false;
            }

            databaseDocument.copyFrom( configDocument );

            log.traceOut( "readDocument()" );
            return true;

        } catch( error ) {

            log.warn( "Error reading document", error );
            
            throw new Error( (error as any).message );
        }
    }

    async updateDocument( collectionDatabase : CollectionDatabase<DatabaseDocument>, 
        databaseDocument: DatabaseDocument, 
        force? : boolean ): Promise<void> {
        
        throw new Error( "Configuration database is read only");
    }

    async expiredArchivedDocuments() : Promise<Map<string,DatabaseDocument>> {
        throw new Error( "Configuration database is read only");
    }




    async documentsWithProperties( 
        database : Database<DatabaseDocument>, 
        properties : Map<string,string>) : Promise<Map<string,DatabaseDocument>> {

        log.traceIn( "documentsWithProperty()", {properties});

        try {
            const result = new Map<string,DatabaseDocument>();

            let databaseDocuments;

            if( database instanceof CollectionGroupDatabase ) {
                databaseDocuments = await this.collectionGroup( database );
            }
            else if( database instanceof CollectionDatabase ) { 
                databaseDocuments = await this.collection( database );
            }
            else {
                throw new Error( "Unrecognized database" );
            }

            if( databaseDocuments != null ) {

                databaseDocuments.forEach( ( databaseDocument, documentPath ) => {

                    let match = true;

                    properties.forEach( (value, key) => {

                        const property = databaseDocument.property( key );

                        if( property == null || property.compareValue( value ) !== 0 ) {
                            match = false;
                        }
                    });

                    if( match ) {
                        result.set( databaseDocument.databasePath( true ), databaseDocument );
                    }
                });
            }

            log.traceOut( "documentsWithProperty()");
            return databaseDocuments;

        } catch( error ) {

            log.warn( "Error querying database", database.databasePath(), error );
            
            throw new Error( (error as any).message );
        }
    }




    async collectionGroup( collectionGroupDatabase : CollectionGroupDatabase<DatabaseDocument> ): Promise<Map<string,DatabaseDocument>> {

        log.traceIn( "collectionGroup()", collectionGroupDatabase.databasePath() );

        try {
            if( !collectionGroupDatabase.userAccess().allowRead ) {
                throw new Error( "No read access to collection group " + collectionGroupDatabase.collectionName() );
            }

            if( !this._documentsLoaded ) {
                this._documentsLoaded = await this.loadDocuments();
            }

            const collectionGroupMap = this._collectionGroupDocuments.get( collectionGroupDatabase.collectionName());

            if( collectionGroupMap == null ) {
                log.traceOut( "collectionGroup()", collectionGroupDatabase.collectionName(), "not found" );
                return new Map<string,DatabaseDocument>();
            }

            const result = new Map<string,DatabaseDocument>();

            const collectionGroupDatabasePath = 
                collectionGroupDatabase.databasePath().split(CollectionGroupPathSuffix)[0];

            collectionGroupMap.forEach( (databaseDocument, documentPath) => {

                if( documentPath.startsWith( collectionGroupDatabasePath ) ) {
                    result.set( databaseDocument.databasePath( true ), databaseDocument );
                }
            } );

            log.traceOut( "collection()", collectionGroupDatabase.databasePath(), result.size );
            return result;
            
        } catch( error ) {

            log.warn( "Error reading colllection", collectionGroupDatabase.collectionName(), error );
            
            throw new Error( (error as any).message );
        }    
    }

    async groupReferenceHandles( 
        collectionGroupDatabase : CollectionGroupDatabase<DatabaseDocument> ): Promise<Map<string,ReferenceHandle<DatabaseDocument>>> {
        log.traceIn( "("+collectionGroupDatabase.collectionName()+")", "groupReferenceHandles()");

        log.traceIn( "collectionGroup()", collectionGroupDatabase.databasePath() );

        try {
            if( !collectionGroupDatabase.userAccess().allowRead ) {
                throw new Error( "No read access to collection group " + collectionGroupDatabase.collectionName() );
            }

            if( !this._documentsLoaded ) {
                this._documentsLoaded = await this.loadDocuments();
            }

            const result = new Map<string,ReferenceHandle<DatabaseDocument>>();

            const collectionGroupMap = this._collectionGroupDocuments.get( collectionGroupDatabase.collectionName());

            if( collectionGroupMap == null ) {
                log.traceOut( "collection()", collectionGroupDatabase.collectionName(), "not found" );
                return result;
            }

            const collectionGroupDatabasePath = 
                collectionGroupDatabase.databasePath().split(CollectionGroupPathSuffix)[0];

            collectionGroupMap.forEach( (databaseDocument, documentPath) => {

                if( documentPath.startsWith( collectionGroupDatabasePath ) ) {

                    const referenceHandle = databaseDocument.referenceHandle();

                    result.set( referenceHandle.path, referenceHandle );
                }
            } );

            log.traceOut( "collection()", collectionGroupDatabase.databasePath(), result.size );
            return result;
            
        } catch( error ) {

            log.warn( "Error reading colllection", collectionGroupDatabase.collectionName(), error );
            
            throw new Error( (error as any).message );
        }    
    }     

    async monitorCollectionGroup( collectionGroupDatabase : CollectionGroupDatabase<DatabaseDocument> ) : Promise<void> {

        log.traceIn( "("+collectionGroupDatabase.collectionName()+")", " monitorCollectionGroup()");

        const databaseDocuments = await this.collectionGroup( collectionGroupDatabase );

        for( const databaseDocument of databaseDocuments.values() ) {

            await collectionGroupDatabase.onNotify(collectionGroupDatabase, Observation.Create, databaseDocument.databasePath(), databaseDocument);

        }
    }

    async releaseCollectionGroup( collectionGroupDatabase : CollectionGroupDatabase<DatabaseDocument> ) : Promise<void> {
    }



    protected async loadDocument( data : any ) : Promise<void> {
        log.traceIn( "loadDocument()", {data} );

        try {

            let documentData;

            if (typeof data === 'object' ) {

                documentData = Object.assign( {}, data );
            } 
            else if (typeof data === 'string') {
    
                try {
                    documentData = JSON.parse( data );
                } catch( error ) {
                    documentData = Object.assign( {}, data );
                }
            }
            else {
                throw new Error( "Unrecognized data format")
            }

            if( documentData.path == null ) {
                throw new Error( "Document path missing");
            }

            const databaseDocument = await Factory.get().databaseService.databaseFactory.documentFromData( 
                documentData.path, documentData ) as DatabaseDocument;

            if( databaseDocument == null ) {
                throw new Error( "Could not read document from data: " + documentData );
            }

            await databaseDocument.onRead();

            databaseDocument.setUserAccess( UserAccess.allowReadOnly() );

            const collectionDatabasePath = databaseDocument.collectionDatabase.databasePath();

            let collectionMap = this._collectionDocuments.get( collectionDatabasePath );

            if( collectionMap == null ) {

                collectionMap = new Map<string,DatabaseDocument>();

                this._collectionDocuments.set( collectionDatabasePath, collectionMap );
            }

            collectionMap.set( databaseDocument.databasePath(), databaseDocument ); 

            let collectionGroupMap = this._collectionGroupDocuments.get( databaseDocument.collectionDatabase.collectionName());

            if( collectionGroupMap == null ) {

                collectionGroupMap = new Map<string,DatabaseDocument>();

                this._collectionGroupDocuments.set( databaseDocument.collectionDatabase.collectionName(), collectionGroupMap );
            }

            collectionGroupMap.set( databaseDocument.databasePath(), databaseDocument ); 

            log.traceOut("loadDocument()", databaseDocument.databasePath() );
            
        } catch( error ) {

            log.warn( "Error loading document", error );
            
            throw new Error( (error as any).message );
        }
    }

    collectionGroupReference( collectionGroupDatabase : CollectionGroupDatabase<DatabaseDocument> ) : any {

        const collectionGroupMap = this._collectionGroupDocuments.get( collectionGroupDatabase.databasePath() );

        return collectionGroupMap;
    }


    collectionReference( collectionDatabase : CollectionDatabase<DatabaseDocument> ) : any {

        const collectionMap = this._collectionDocuments.get( collectionDatabase.databasePath() );

        return collectionMap;
    }

    documentReference( databaseDocument : DatabaseDocument ) : any {

        if( databaseDocument.isNew() ) {
            throw new Error( "documentNotCreated" );
        }

        let collectionMap = this._collectionDocuments.get( databaseDocument.collectionDatabase.databasePath() );

        if( collectionMap == null ) {
            throw new Error( "notFound");
        }

        const configDocument = collectionMap.get( databaseDocument.databasePath() );

        if( configDocument == null ) {
            throw new Error( "notFound");
        }

        return configDocument;
    }

    protected abstract loadDocuments() : Promise<boolean>;

    readonly converter : DatabaseConverter = new ConfigurationConverter();

    private readonly _collectionDocuments = new Map<string,Map<string,DatabaseDocument>>();

    private readonly _collectionGroupDocuments = new Map<string,Map<string,DatabaseDocument>>();

    private _documentsLoaded : boolean = false; 

}