import React from 'react';

import DocumentView, { DocumentProps } from '../components/documentView';

import { PropertiesSelector } from 'services/database/api/core/propertiesSelector';

import DatabaseTable, { DatabaseTableProps } from 'ui/components/databaseTable';
import DatabaseList, { DatabaseListProps } from 'ui/components/databaseList';
import DatabaseCard, { DatabaseCardProps } from 'ui/components/databaseCard';
import DatabaseChart, { DatabaseChartProps } from 'ui/components/databaseChart';
import DatabaseGraph, { DatabaseGraphProps } from 'ui/components/databaseGraph';


import DatabaseView from '../components/databaseView';
import { TerminalIF } from 'services/database/api/documents/terminalIF';
import { DatabaseQuery } from '../../services/database/api/core/databaseQuery';
import { DatabaseViewType, DatabaseViewTypes } from '../components/databaseViewMenu';


export const defaultTerminalTableColumns = [
  "title",
  "lastLogin",
  "terminalPlatform",
  "manufacturer",
  "model",
  "operatingSystem",
  "operatingSystemVersion"
];

export const defaultHighlightedTerminalProperty = "terminalPlatform";

export function TerminalsTable(props: DatabaseTableProps ) {

  return (
    <DatabaseTable {...props} featuredProperties={props.featuredProperties != null ? props.featuredProperties : defaultTerminalTableColumns }/>
  );
}

export function TerminalsList(props: DatabaseListProps ) {

  return (
    <DatabaseList {...props} />
  );
}


export function TerminalsCard( props: DatabaseCardProps ) {
  return (
    <DatabaseCard 
      {...props} 
      highlightedPropertyKey={props.highlightedPropertyKey != null ? props.highlightedPropertyKey : defaultHighlightedTerminalProperty}
    />
  );
}

export function TerminalsChart( props: DatabaseChartProps ) {
  return (
    <DatabaseChart 
      {...props} 
    />
  );
}

export function TerminalsGraph( props: DatabaseGraphProps ) {
  return (
    <DatabaseGraph 
      {...props} 
      previousPropertyKey={"user"}
      directed={false}
    />
  );
}

export function TerminalsView(props: { 
  databaseQuery : DatabaseQuery<TerminalIF>,
  title? : string,
  featuredProperties?: string[],
  highlightedPropertyKey?: string }) {

  return ( <DatabaseView 
    databaseQuery={props.databaseQuery}
    title={props.title}
    featuredProperties={props.featuredProperties != null ? props.featuredProperties : defaultTerminalTableColumns }
    highlightedPropertyKey={props.highlightedPropertyKey != null ? props.highlightedPropertyKey : defaultHighlightedTerminalProperty}
    hideAdd={true}
    hideDateFilter={true}
    defaultDatabaseViewType={DatabaseViewTypes.Table as DatabaseViewType}
    /> );
}


export function TerminalView( props : DocumentProps ) {

  const TerminalBasicsFilter = {

    includePropertyKeys : [
      "deviceId",
      "terminalPlatform",
      "manufacturer",
      "model",
      "operatingSystem",
      "operatingSystemVersion",
      "emulator"
    ]
  
  } as PropertiesSelector;
  
  const TerminaStatusFilter = {

    includePropertyKeys : [
      "lastLogin",
      "pushToken"
    ]
  
  } as PropertiesSelector;
  


  
  const defaultTerminalSteps = () : Map<string,PropertiesSelector> => {
  
    let defaultSteps = new Map<string,PropertiesSelector>();
  
    defaultSteps.set( "basics", TerminalBasicsFilter );
    defaultSteps.set( "status", TerminaStatusFilter );
  
    return defaultSteps;
  }
  
  return ( <DocumentView 
    {...props}
    inputTabs={props.inputTabs != null ? props.inputTabs : defaultTerminalSteps()}
  /> );
}



