export const NotificationTypes = {

    Email     : "email",

    SMS       : "sms",

    Push      : "push"
}

export type NotificationType = keyof (typeof NotificationTypes);
