
export const SubscriptionStatuses = {

    Pending       : "pending",
    
    Active        : "active",

    OnHold        : "on-hold",

    Cancelled     : "cancelled",

    Switched      : "switched",

    Expired       : "expired",

    PendingCancel: "pending-cancel"

}

export type SubscriptionStatus = keyof (typeof SubscriptionStatuses);

export const DefaultSubscriptionStatus = SubscriptionStatuses.Active as SubscriptionStatus;
