import React from 'react';

import firebase from 'firebase/compat/app';
import "firebase/compat/auth";

import { withTranslation, WithTranslation } from 'react-i18next';

import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';

import { Avatar, Button, CircularProgress, createStyles, Grid, Link, TextField, Theme, Typography, WithStyles, withStyles } from '@material-ui/core';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import microsoftIcon from 'ui/app/microsoft-icon.png';
import googleIcon from 'ui/app/google-icon.png';

import CloseIcon from '@material-ui/icons/Close'; 
import LoginIcon from 'mdi-material-ui/Login'; 
import EmailIcon from '@material-ui/icons/Email'; 
import CreateIcon from '@material-ui/icons/Create'; 
import PasswordIcon from '@mui/icons-material/Password';
import AppleIcon from '@material-ui/icons/Apple'; 

import { log } from '../app';

import { activeLanguage } from '../localization';
import { Factory } from '../../../services/common/api/factory';
import { errorDialog } from '../../components/simpleDialog'; 
import { AppContext, AppContextProps } from '../appContext';
import { TerminalPlatforms } from "../../../services/database/api/definitions/terminalPlatform";
import { FirebaseError } from '@firebase/app';
import { FirebaseServiceIF } from '../../../services/application/api/firebase/firebaseServiceIF';
import { propertyInputVariant } from '../../components/propertyValue';


const emailBackground = "#db4437";
const googleBackground = "#ffffff"; 
const microsoftBackground = "#2f2f2f";
const appleBackground = "#000000";

const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex'
  },
  form: {
    display: 'flex',
  },
  button: {
    width: '100%',
    height: theme.spacing(6),
    justifyContent: 'flex-start',
    textTransform: 'none'
  },
  avatar: {
    margin: theme.spacing(0),
    padding: theme.spacing(1),
    backgroundColor: 'transparent' 
  },
  text: {
    padding: '0',
    margin: '0',
    width: theme.spacing(40)
  }
});

enum LoginState {
  LoginWithEmailPassword,
  ResetPassword,
  RegisterEmailPassword,
  AwaitRegistration,
  AwaitSignInEmail,
  AwaitSignInGoogle,
  AwaitSignInMicrosoft,
  AwaitSignInApple
}

interface MatchParams {
}

export interface FirebaseAuthViewProps extends WithStyles<typeof styles>, WithTranslation, RouteComponentProps<MatchParams> { 

  onComplete? ( user? : any ) : Promise<void> 
}

interface FirebaseAuthViewState { // Component State

  loginOption? : LoginState,

  email? : string

  password? : string,

  newPassword?: string,

  repeatPassword?: string,

  wait: boolean

}

class FirebaseAuthView extends React.PureComponent<FirebaseAuthViewProps,FirebaseAuthViewState> {

  constructor( props: FirebaseAuthViewProps ) {
    
    super(props);

    this.state = { 
      wait: false,
      retries: 0
    } as FirebaseAuthViewState;

    //log.traceInOut( "constructor()" ); 
  }

  async componentDidMount() {

    log.traceIn("componentDidMount()");

    try {

      log.debug( "componentDidMount()", activeLanguage() );


      log.traceOut("componentDidMount()");

    } catch (error) {
      log.warn("componentDidMount()", "Error mounting", error);

      await errorDialog(error);
    }
  }

  async componentWillUnmount() {

    log.traceIn("componentWillUnmount()");

    try { 


      log.traceOut("componentWillUnmount()");

    } catch (error) {
      log.warn("componentWillUnmount()", "Error unmounting", error);
    }
  }


  
  render() {

    //log.traceInOut( "render()");

    const { classes } = this.props;

    const handleLoginWithGoogle = async () => {

      log.traceIn("handleLoginWithGoogle()", activeLanguage());

      let user : any;

      try {

        const appContext = this.context as AppContextProps;

        await handleSignOut();

        this.setState({ loginOption: LoginState.AwaitSignInGoogle })

        const firebaseService = Factory.get().applicationService as FirebaseServiceIF;

        if( appContext.currentDisplay?.displayPlatform === TerminalPlatforms.Web ) { 

          firebaseService.authenticator().languageCode = activeLanguage();

          const provider = new firebase.auth.GoogleAuthProvider();

          provider.setCustomParameters({
            prompt: 'select_account'
          });

          const result = await firebaseService.authenticator().signInWithPopup(provider);

          user = result.user;
        }
        else if ( appContext.currentDisplay?.displayPlatform === TerminalPlatforms.Android ) {
            
          await FirebaseAuthentication.signOut();

          await FirebaseAuthentication.setLanguageCode({ languageCode: activeLanguage() });

          let result = await FirebaseAuthentication.signInWithGoogle(); 

          if( result.credential?.idToken == null ) { 
            throw new Error( "noUser" );
          }

          const credential = firebase.auth.GoogleAuthProvider.credential(result.credential.idToken);

          if( credential == null ) {
            throw new Error( "noUser" );
          }

          const userCredential = await firebaseService.authenticator().signInWithCredential(credential);

          user = userCredential.user;
        }
        else if ( appContext.currentDisplay?.displayPlatform === TerminalPlatforms.IOS ) {

          let result = await GoogleAuth.signIn(); 

          if( result.authentication.idToken == null ) { 
            throw new Error( "noUser" );
          }

          const credential = firebase.auth.GoogleAuthProvider.credential(result.authentication.idToken);

          if( credential == null ) {
            throw new Error( "noUser" );
          }

          const firebaseService = Factory.get().applicationService as FirebaseServiceIF;

          const userCredential = await firebaseService.authenticator().signInWithCredential(credential);

          user = userCredential.user;
        }
        else {
          throw new Error( "Unrecognized platform: " + appContext.currentDisplay?.displayPlatform )
        }

        await handleSignIn( user );

      } catch (error) {
        log.warn("handleLoginWithGoogle()", "error signing in with google", error);

        switch( (error as any).code ) 
        {
          case "auth/internal-error":
          case "auth/network-request-failed":
          case "auth/the-service-is-currently-unavailable":
            try {

              await handleSignIn( user );

            } catch( repeatError ) {

              await handleError( repeatError );
            }
            break;

          default:
            await handleError( error ); 
            break;
        }       
      }

      log.traceOut("handleLoginWithGoogle()");
    }

    const handleLoginWithMicrosoft = async () => {

      log.traceIn( "handleLoginWithMicrosoft()");

      let user : any;

      try {

        const appContext = this.context as AppContextProps;

        await handleSignOut();

        this.setState({ loginOption: LoginState.AwaitSignInMicrosoft })

        const firebaseService = Factory.get().applicationService as FirebaseServiceIF;

        firebaseService.authenticator().languageCode = activeLanguage();

        const provider = new firebase.auth.OAuthProvider('microsoft.com'); 

        if (appContext.currentDisplay?.displayPlatform === TerminalPlatforms.Web) {

          provider.setCustomParameters({
            prompt: 'select_account',
          });

          const result = await firebaseService.authenticator().signInWithPopup(provider);

          user = result.user;

          await handleSignIn(result.user);
        }
        else {
          await FirebaseAuthentication.setLanguageCode({ languageCode: activeLanguage() });

          this.setState({ loginOption: LoginState.AwaitSignInMicrosoft })

          const result = await FirebaseAuthentication.signInWithMicrosoft({
            customParameters: [{ key: 'prompt', value: 'select_account' }]
          });

          if( result.credential?.idToken == null || result.credential?.nonce == null ) {
            throw new Error( "noUser" );
          }

          const credential = provider.credential({
            idToken: result.credential?.idToken,
            rawNonce: result.credential?.nonce,
          });

          if( credential == null ) {
            throw new Error( "noUser" );
          }

          const userCredential = await firebaseService.authenticator().signInWithCredential(credential);

          user = userCredential.user;
        }

        await handleSignIn( user );

      } catch (error) {
        log.warn( "handleLoginWithMicrosoft()", "error signing in with microsoft", error );

        switch( (error as any).code ) 
        {
          case "auth/internal-error":
          case "auth/network-request-failed":
          case "auth/the-service-is-currently-unavailable":
            try {

              await handleSignIn( user );

            } catch( repeatError ) {

              await handleError( repeatError );
            }
            break;

          default:
            await handleError( error ); 
            break;
        }       
      }

      log.traceOut( "handleLoginWithMicrosoft()");

    }

    const handleLoginWithApple = async () => {

      log.traceIn( "handleLoginWithApple()");

      let user : any;

      try {

        const appContext = this.context as AppContextProps;

        await handleSignOut();

        this.setState({ loginOption: LoginState.AwaitSignInApple })

        const firebaseService = Factory.get().applicationService as FirebaseServiceIF;

        firebaseService.authenticator().languageCode = activeLanguage();

        const provider = new firebase.auth.OAuthProvider('apple.com');

        if (appContext.currentDisplay?.displayPlatform === TerminalPlatforms.Web) {

          provider.setCustomParameters({
            prompt: 'select_account',
          });

          const result = await firebaseService.authenticator().signInWithPopup(provider);

          user = result.user;
        }
        else {

          this.setState({ loginOption: LoginState.AwaitSignInApple })

          const result = await FirebaseAuthentication.signInWithApple({
            customParameters: [{ key: 'prompt', value: 'select_account' }]
          });

          if( result.credential?.idToken == null || result.credential?.nonce == null ) {
            throw new Error( "noUser" );
          }

          const credential = provider.credential({
            idToken: result.credential?.idToken,
            rawNonce: result.credential?.nonce,
          });

          if( credential == null ) {
            throw new Error( "noUser" );
          }

          const userCredential = await firebaseService.authenticator().signInWithCredential(credential);

          user = userCredential.user;
        }

        await handleSignIn( user );

      } catch (error) {
        log.warn( "handleLoginWithApple()", "error signing in with apple", error );

        switch( (error as any).code ) 
        {
          case "auth/internal-error":
          case "auth/network-request-failed":
          case "auth/the-service-is-currently-unavailable":
            try {

              await handleSignIn( user );

            } catch( repeatError ) {

              await handleError( repeatError );
            }
            break;

          default:
            await handleError( error ); 
            break;
        }      
      }

      log.traceOut( "handleLoginWithApple()");

    }

    const handleLoginWithEmailPassword = async () => {

      log.traceIn( "handleLoginWithEmailPassword()");

      await handleSignOut();

      this.setState( { loginOption: LoginState.LoginWithEmailPassword} )

      log.traceOut( "handleLoginWithEmailPassword()");

    }

    const handleRegisterEmailPassword = async () => {

      log.traceInOut( "handleRegisterEmailPassword()");

      await handleSignOut();

      this.setState( { loginOption: LoginState.RegisterEmailPassword} )
    }

    const handleResetPassword = async () => {

      log.traceInOut( "handleResetPassword()");

      await handleSignOut();

      this.setState( { loginOption: LoginState.ResetPassword} )

    }

    const handleEmailPasswordSubmitted = async ( event : any, retry? : boolean ) => {

      log.traceIn( "handleEmailPasswordSubmitted()");

      try {

        event.preventDefault();

        const { email, password } = event.target.elements;

        this.setState( { 
          loginOption: LoginState.AwaitSignInEmail,
          email: email.value,
          password: password.value
        } );

        const firebaseService = Factory.get().applicationService as FirebaseServiceIF;

        const userCredential = await firebaseService.authenticator().signInWithEmailAndPassword( email.value, password.value );

        await handleSignIn( userCredential.user ); 
  
      } catch (error) {
        log.warn("handleEmailPasswordSubmitted()", "Error submitting email and password", error);

        switch( (error as any).code ) 
        {
          case "auth/internal-error":
          case "auth/network-request-failed":
          case "auth/the-service-is-currently-unavailable":
            if( !retry ) {
              await handleEmailPasswordSubmitted( event, true );
            }
            else {
              await handleError( new Error( "signinError" ) );
            }
            break;

          case "auth/user-not-found":
            await errorDialog("unregisteredUser");
            await handleRegisterEmailPassword();
            break;

          default:
            await handleError( error ); 
            break;
        }
      } 
  
      log.traceOut("handleEmailPasswordSubmitted()");
    }

    const handleRegisterEmailPasswordSubmitted = async ( event : any, retry? : boolean ) => {

      log.traceIn( "handleRegisterEmailPasswordSubmitted()", event.target.elements);

      try {

        event.preventDefault();

        const { email, newPassword, repeatPassword } = event.target.elements;

        if( newPassword.value! !== repeatPassword.value! ) {
          throw new Error( "passwordMismatch" );
        }

        this.setState( { 
          loginOption: LoginState.AwaitRegistration,
          email: email.value,
          password: newPassword.value 
        } )

        const firebaseService = Factory.get().applicationService as FirebaseServiceIF;

        const userCredential = await firebaseService.authenticator().createUserWithEmailAndPassword( email.value, newPassword.value );

        await handleSignIn( userCredential.user ); 

        log.traceOut("handleRegisterEmailPasswordSubmitted()");
  
      } catch (error) {
        log.warn("handleRegisterEmailPasswordSubmitted()", "Error registering email and password", (error as any).code );

        switch( (error as any).code ) 
        {
          case "auth/internal-error":
          case "auth/network-request-failed":
          case "auth/the-service-is-currently-unavailable":
            if( !retry ) {
              await handleRegisterEmailPasswordSubmitted( event, true );
            }
            else {
              await handleError( new Error( "signinError" ) );
            }
            break;

          case "auth/email-already-in-use":
            await errorDialog("duplicateUser");
            await handleLoginWithEmailPassword();
            break;

          case "auth/account-exists-with-different-credential":
            await handleError( new Error( "otherProvider" ) );
            break;

          case "auth/invalid-email":
            await handleError( new Error( "invalidEmail" ) );
            break;

          case "auth/weak-password":
            await handleError( new Error( "weakPassword" ) );
            break;

          case "auth/invalid-verification-code":
            await handleError( new Error( "invalidVerificationCode" ) );
            break;

          case "auth/too-many-requests": 
            await handleError( new Error( "tooManyRequests" ) );
            break;

          default:
            await handleError( error ); 
            break;

        }
      } 

    }

    const handleResetPasswordSubmitted = () => {

      log.traceInOut( "handleResetPasswordSubmitted()");
    }

    const handleSignIn = async ( user : any ) => {

      log.traceInOut( "handleSignIn()");

      try {
        if( user == null ) {

          throw new Error( "noUser")
        }

        if (this.props.onComplete != null) {
          await this.props.onComplete( user );
        }

        this.setState({ loginOption: undefined })

        log.traceOut( "handleSignIn()");

      } catch (error) {
        log.warn( "handleSignIn()", "error signing in", error );

        handleError( error );
      }
    }

    const handleSignOut = async () => {

      log.traceIn( "handleSignOut()");

      try {

        const appContext = this.context as AppContextProps;

        if( appContext.currentDisplay?.displayPlatform !== TerminalPlatforms.Web ) { 

          await FirebaseAuthentication.signOut();
        }

        const firebaseService = Factory.get().applicationService as FirebaseServiceIF;

        await firebaseService.authenticator().signOut();
        
        log.traceOut( "handleSignOut()");

      } catch (error) {

        log.warn( "handleSignOut()", "error signing out", error );

      }
    }


    const handleCancel = async () => {

      log.traceIn("handleCancel()");

      try {

        //await handleSignOut();

      } catch (error) {

        log.warn("handleCancel()", "error signing out", error);
      }

      if (this.props.onComplete != null) {
        this.props.onComplete();
      }

      this.setState({
        loginOption: undefined,
        newPassword: undefined,
        repeatPassword: undefined
      })

      log.traceOut( "handleCancel()");
    }

    const handleError = async (error: any) => {

      log.traceIn("handleError()", {error} );

      try {

        if (error.message == null || 
            error.message === "" || 
            error.message.includes("12501") ||
            error.message.includes("canceled") || 
            error.message.includes("cancelled")) { 

          await handleCancel();
          log.traceOut("handleError()", "cancel web message");
          return;
        }

        if (error instanceof FirebaseError) {

          const firebaseError = error as FirebaseError;

          log.debug("handleError()", "firebaseError", firebaseError.code );

          switch (firebaseError.code) {

            case 'auth/popup-closed-by-user':

              await handleCancel();
              log.traceOut("handleError()", "cancel");
              return;

            case 'auth/invalid-email':

              //await handleSignOut();

              await errorDialog("invalidEmail");
              break;
            
            case 'auth/user-not-found': 

              //await handleSignOut();

              await errorDialog("unknownUser");
              break;

            case 'auth/internal-error':
            case 'auth/wrong-password': 

              await errorDialog("signinError");
              break;

            case 'auth/too-many-requests': 

              await errorDialog("signinAttemptsExceeded");
              break;

            case 'auth/the-service-is-currently-unavailable':
              await errorDialog("serviceUnavailable");
              break;

            default:

              //await handleSignOut();

              await errorDialog(firebaseError.message);
              break;
          }
        }
        else if (error.message != null) {

          //await handleSignOut();

          await errorDialog(error.message);
        }
        else {
          //await handleSignOut();

          await errorDialog("signinError");
        }

      } catch (error) {

        log.warn("handleError()", "error signing out", error);
      }

      if (this.props.onComplete != null) {
        this.props.onComplete();
      }

      this.setState({
        loginOption: undefined,
        newPassword: undefined,
        repeatPassword: undefined
      })

      log.traceOut("handleError()");
    }

    const cancelButton = (
        <Button 
          className={classes.button} 
          startIcon={<Avatar className={classes.avatar} ><CloseIcon/></Avatar>} 
          variant="contained" 
          color="secondary" 
          onClick={handleCancel}> 
          {this.props.t("cancel")}
        </Button> 
    )

    const emailPasswordLogin = (
      <>
        <form onSubmit={handleEmailPasswordSubmitted} noValidate>
          <Grid container direction="column" alignItems="stretch" justifyContent="center" spacing={2} className={classes.form}>  
            <Grid item >
              <TextField
                variant={propertyInputVariant}  
                margin="normal"
                required
                fullWidth
                id="email"
                label={this.props.t("email")}
                type="email"
                name="email"
                autoComplete="email"
                autoFocus
                className={classes.text}
                value={this.state.email != null ? this.state.email : ""}
                onChange={( event ) => {event.preventDefault(); this.setState( {email: event.target.value })}}
              />
            </Grid>
            <Grid item>
              <TextField
                variant={propertyInputVariant}
                margin="normal"
                required
                fullWidth
                name="password"
                label={this.props.t("password")}
                type="password"
                id="password"
                autoComplete="current-password"
                className={classes.text}
                value={this.state.password != null ? this.state.password : ""}
                onChange={( event ) => {event.preventDefault(); this.setState( {password: event.target.value })}}
              />
            </Grid>
            <Grid item>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={this.state.email == null || this.state.password == null}
                startIcon={<Avatar className={classes.avatar} ><LoginIcon/></Avatar>} 
                className={classes.button}> 
                {this.props.t("login")}
              </Button>
            </Grid>
            <Grid item>
              {cancelButton}
            </Grid>
            <Grid item>
              <Typography>
                <Link href="#" onClick={handleRegisterEmailPassword}>
                  {this.props.t("noAccount")}
                </Link>
                &nbsp;&nbsp;
                <Link href="#" onClick={handleResetPassword}>
                  {this.props.t("forgotPassword")}
                </Link>
              </Typography>
            </Grid>
          </Grid> 
        </form>
      </>
    )

    const registerEmailPassword = (
      <>
        <form onSubmit={handleRegisterEmailPasswordSubmitted} noValidate>
          <Grid container direction="column" alignItems="stretch" justifyContent="center" spacing={1} className={classes.form}>
            <Grid item >
              <TextField
                variant={propertyInputVariant}
                margin="normal"
                required
                fullWidth
                id="email"
                label={this.props.t("email")}
                type="email"
                name="email"
                autoComplete="email"
                autoFocus
                className={classes.text}
                value={this.state.email != null ? this.state.email : ""}
                onChange={( event ) => {event.preventDefault(); this.setState( {email: event.target.value })}}
              />
            </Grid>
            <Grid item>
              <TextField
                variant={propertyInputVariant}
                margin="normal"
                required
                fullWidth
                name="newPassword"
                label={this.props.t("newPassword")}
                type="password"
                id="newPassword"
                autoComplete="current-password"
                className={classes.text}
                onChange={( event ) => {event.preventDefault(); this.setState( {newPassword: event.target.value })}}
              />
            </Grid>
            <Grid item>
              <TextField
                variant={propertyInputVariant}
                margin="normal"
                required
                fullWidth
                name="repeatPassword"
                label={this.props.t("repeatPassword")}
                type="password"
                id="repeatPassword"
                autoComplete="current-password"
                className={classes.text}
                onChange={( event ) => {event.preventDefault(); this.setState( {repeatPassword: event.target.value })}}
              />
            </Grid>
            <Grid item>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={this.state.email == null || this.state.newPassword == null || this.state.newPassword !== this.state.repeatPassword }
                startIcon={<Avatar className={classes.avatar} ><CreateIcon/></Avatar>} 
                className={classes.button}>
                {this.props.t("register")}
              </Button>
            </Grid>
            <Grid item>
              {cancelButton}
            </Grid>
          </Grid> 
        </form>
      </>
    )

    const resetPassword = (
      <>
        <form onSubmit={handleResetPasswordSubmitted} noValidate>
          <Grid container direction="column" alignItems="stretch" justifyContent="center" spacing={1} className={classes.form}>
            <Grid item >
              <TextField
                variant={propertyInputVariant}
                margin="normal"
                required
                fullWidth
                id="email"
                label={this.props.t("email")}
                name="email"
                autoComplete="email"
                autoFocus
                className={classes.text}
                value={this.state.email}
              />
            </Grid>
            <Grid item>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={this.state.email == null}
                startIcon={<Avatar className={classes.avatar} ><PasswordIcon/></Avatar>} 
                className={classes.button}>
                {this.props.t("resetPassword")}
              </Button>
            </Grid>
            <Grid item>
              {cancelButton}
            </Grid>
          </Grid> 
        </form>
      </>
    )


    const loginOptions = (
      <>
        <Grid container direction="column" alignItems="stretch" justifyContent="center" spacing={2} className={classes.root}>  
          <Grid item>
            <Button 
              startIcon={
                <Avatar className={classes.avatar} >
                  {this.state.loginOption === LoginState.AwaitSignInEmail || this.state.loginOption === LoginState.AwaitRegistration ? 
                    <CircularProgress color="inherit" size={20} /> : 
                    <EmailIcon/>
                  }
                </Avatar>
              }
              className={classes.button} 
              disabled={this.state.loginOption != null}
              variant="contained" 
              fullWidth
              color="primary" 
              style={this.state.loginOption == null ? { backgroundColor: emailBackground} : {}}
              onClick={handleLoginWithEmailPassword} >
              {this.props.t("loginWithEmailPassword")}
            </Button>
          </Grid>
          <Grid item>
            <Button 
              startIcon={ this.state.loginOption === LoginState.AwaitSignInMicrosoft ? 
                  <Avatar className={classes.avatar}><CircularProgress color="inherit" size={20} /></Avatar> :
                  <Avatar className={classes.avatar}  src={microsoftIcon} />
              }
              className={classes.button}  
              disabled={this.state.loginOption != null} 
              variant="contained" 
              fullWidth
              color="primary" 
              style={this.state.loginOption == null ? { backgroundColor: microsoftBackground} : {}}
              onClick={handleLoginWithMicrosoft} >
              {this.props.t("loginWithMicrosoft")}
            </Button>
          </Grid>
          <Grid item>
            <Button 
              startIcon={ this.state.loginOption === LoginState.AwaitSignInGoogle ? 
                <Avatar className={classes.avatar}><CircularProgress color="inherit" size={20} /></Avatar> :
                <Avatar className={classes.avatar}  src={googleIcon} />
              }
              className={classes.button} 
              disabled={this.state.loginOption != null}
              variant="contained" 
              fullWidth
              style={this.state.loginOption == null ? { backgroundColor: googleBackground} : {}}
              onClick={handleLoginWithGoogle} >
              {this.props.t("loginWithGoogle")}
            </Button>
          </Grid>
          <Grid item>
            <Button 
              startIcon={
                <Avatar className={classes.avatar} >
                  {this.state.loginOption === LoginState.AwaitSignInApple ? 
                    <CircularProgress color="inherit" size={20} /> : 
                    <AppleIcon/>
                  }
                </Avatar>
              }
              className={classes.button}  
              disabled={this.state.loginOption != null} 
              variant="contained" 
              fullWidth
              color="primary" 
              style={this.state.loginOption == null ? { backgroundColor: appleBackground} : {}}
              onClick={handleLoginWithApple} >
              {this.props.t("loginWithApple")}
            </Button>
          </Grid>
        </Grid>
      </>
    )

    return (
      <>
        {(this.state.loginOption === LoginState.AwaitSignInEmail ||
          this.state.loginOption === LoginState.AwaitRegistration || 
         this.state.loginOption === LoginState.AwaitSignInGoogle||
         this.state.loginOption === LoginState.AwaitSignInMicrosoft ||
         this.state.loginOption === LoginState.AwaitSignInApple ) && loginOptions} 
        {this.state.loginOption == null && loginOptions }
        {this.state.loginOption === LoginState.LoginWithEmailPassword && emailPasswordLogin }
        {this.state.loginOption === LoginState.RegisterEmailPassword && registerEmailPassword }
        {this.state.loginOption === LoginState.ResetPassword && resetPassword }
      </>
    );
  }


}

FirebaseAuthView.contextType = AppContext;

const ModifiedFirebaseAuthView = withRouter(withTranslation()(withStyles(styles)(FirebaseAuthView)));

export default ModifiedFirebaseAuthView;



