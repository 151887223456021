import React from 'react';

import DocumentView, { DocumentProps } from '../components/documentView';

import { PropertiesSelector } from 'services/database/api/core/propertiesSelector';

import DatabaseTable, { DatabaseTableProps, StartDateProperty } from 'ui/components/databaseTable';
import DatabaseList, { DatabaseListProps } from 'ui/components/databaseList';
import DatabaseCard, { DatabaseCardProps } from 'ui/components/databaseCard';
import DatabaseChart, { DatabaseChartProps } from 'ui/components/databaseChart';
import DatabaseGraph, { DatabaseGraphProps } from 'ui/components/databaseGraph';

import DatabaseView from '../components/databaseView';
import { TestRegistrationIF } from 'healthguard/api/documents/testRegistrationIF';
import { CompanyDocument, UnitDocument, UserDocument } from '../../services/database/api/documents';
import { DatabaseQuery } from '../../services/database/api/core/databaseQuery';
import { HazardDocument } from '../../healthguard/api/healthguardDocuments';
import { MeasuresCollection } from '../../healthguard/api/healthguardCollections';


export const DefaultTestRegistrationTableColumns = [
  StartDateProperty,
  CompanyDocument,
  UnitDocument,
  UserDocument,
  "testResult",
  "testTypes",
  MeasuresCollection
];

export const defaultHighlightedTestRegistrationsProperty = "result";

export function TestRegistrationsTable(props: DatabaseTableProps ) {

  return (
    <DatabaseTable {...props} featuredProperties={props.featuredProperties != null ? props.featuredProperties : DefaultTestRegistrationTableColumns }/>
  );
}

export function TestRegistrationsList(props: DatabaseListProps ) {

  return (
    <DatabaseList {...props} />
  );
}


export function TestRegistrationsCard( props: DatabaseCardProps ) {
  return (
    <DatabaseCard 
      {...props} 
      highlightedPropertyKey={props.highlightedPropertyKey != null ? props.highlightedPropertyKey : defaultHighlightedTestRegistrationsProperty}
    />
  );
} 

export function TestRegistrationsChart( props: DatabaseChartProps ) {
  return (
    <DatabaseChart 
      {...props} 
    />
  );
}


export function TestRegistrationsGraph( props: DatabaseGraphProps ) {
  return (
    <DatabaseGraph 
      {...props} 
      previousPropertyKey={"testResult"}
      nextPropertyKey={"user"}
      directed={false}
    />
  );
}


export function TestRegistrationsView(props: { 
  databaseQuery : DatabaseQuery<TestRegistrationIF>,
  title? : string,
  featuredProperties?: string[],
  highlightedPropertyKey?: string }) {

  return ( 
    <DatabaseView 
    databaseQuery={props.databaseQuery} 
      title={props.title}
      featuredProperties={props.featuredProperties != null ? props.featuredProperties : DefaultTestRegistrationTableColumns }
      highlightedPropertyKey={props.highlightedPropertyKey != null ? props.highlightedPropertyKey : defaultHighlightedTestRegistrationsProperty}
    /> );
}


export function TestRegistrationView( props : DocumentProps ) {
 
  const TestBasicsFilter = {

    includePropertyKeys: [
      UnitDocument,
      UserDocument,
      MeasuresCollection,
      HazardDocument, 
      "startDate",
      "testTypes", 
      "testResult",
      "variant",
      "incidentRegistrations",
      "quarantineRegistrations",
      "sickLeaveRegistrations",
      "attachments"
    ],

  } as PropertiesSelector;

  /*
  const TestRegistrationsFilter = {

    includePropertyKeys: [
      "symptomRegistrations",
      "incidentRegistrations",
      "quarantineRegistrations",
      "sickLeaveRegistrations"
  ]
  } as PropertiesSelector;
  
  
  const TestAssociationsFilter = {

    includePropertyKeys: [
      "measures",
      "locations",
      "categories",
      "projects",
      "gathering",
      "residence"]
  
  } as PropertiesSelector;

  */
  const defaultTestRegistrationSteps = (): Map<string, PropertiesSelector> => {

    let defaultSteps = new Map<string, PropertiesSelector>();

    defaultSteps.set("basics", TestBasicsFilter);
    //defaultSteps.set("registrations", TestRegistrationsFilter);
    //defaultSteps.set("associations", TestAssociationsFilter);

    return defaultSteps;
  }

  return ( <DocumentView 
    {...props}
    inputTabs={props.inputTabs != null ? props.inputTabs : defaultTestRegistrationSteps()}
  /> );
}


