
import { Factory } from "../../../services/common";
import { log } from "../../../services/database/framework/databaseService";
import { UserManager } from "../../../services/database/impl/managers/userManager";
import { maxReadinessLevel } from "../../api/definitions/readinessLevel";
import { MeasureState, MeasureStates } from "../../api/definitions/measureState";
import { HealthguardCompany } from "../documents/healthguardCompany";
import { HealthguardLocation } from "../documents/healthguardLocation";
import { Measure } from "../documents/measure";

export class MeasureManager {

    static getInstance() : MeasureManager {
        if( MeasureManager._instance == null ) {
            MeasureManager._instance = new MeasureManager();
        }
        return MeasureManager._instance;
    }


    async handleCreateMeasure( createdMeasure: Measure ) {

        try {
            log.traceIn("handleCreateMeasure()", createdMeasure.title.value() );

            await this.updateMeasureState( createdMeasure );

            log.traceOut("handleCreateMeasure()");

        } catch (error) {
            log.warn("Error handling document create", error);

            log.traceOut("handleMeasureCreated()", "error");
        }
    }


    async handleUpdateMeasure( updatedMeasure: Measure ) {

        try {
            log.traceIn("handleUpdateMeasure()", updatedMeasure.title.value() );

            if( updatedMeasure.readinessLevels.isChanged() || updatedMeasure.locations.isChanged() ) {
                
                await this.updateMeasureState( updatedMeasure )
            }

            log.traceOut("handleUpdateMeasure()");

        } catch (error) {
            log.warn("Error handling document update", error);

            log.traceOut("handleMeasureUpdated()", "error");
        }
    } 

    async handleDeleteMeasure( deletedMeasure: Measure ) {

        try {
            log.traceIn("handleDeleteMeasure()", deletedMeasure.title.value() );

            log.traceOut("handleDeleteMeasure()");

        } catch (error) {
            log.warn("Error handling document update", error);

            log.traceOut("handleMeasureUpdated()", "error");
        }
    } 


    async updateMeasureUsers( measure: Measure ) {

        try {
            log.traceIn("updateMeasureUsers()", measure.title.value() );

            await UserManager.getInstance().updateDocumentUsers( measure );

            log.traceOut("updateMeasureUsers()");

        } catch (error) {
            log.warn("Error handling measure activity statuses", error);

            log.traceOut("updateMeasureUsers()", "error");
        }
    }

    async updateMeasureState( measure: Measure, updatedCompany? : HealthguardCompany, updatedLocation? : HealthguardLocation ) {

        try {
            log.traceIn("updateMeasureState()",  measure.title.value() );

            const previousMeasureState = measure.measureState.value();

            const company = updatedCompany != null ? 
                updatedCompany :
                await measure.company.document();

            if( company == null ) {
                log.traceOut("updateMeasureState()", "no company");
                return;
            }

            if( measure.measureState.value() === MeasureStates.Terminated &&
                measure.endDate.value() == null ) {

                measure.endDate.setValue( new Date() );
                
                log.traceOut("updateMeasureState()", "terminated");
                return;
            }
            else if( measure.measureState.value() !== MeasureStates.Terminated &&
                measure.endDate.value() != null ) {

                measure.endDate.setValue( undefined );
                
                log.traceOut("updateMeasureState()", "remove end date");
                return;
            }

            const measureReadinessLevels = measure.readinessLevels.value();

            log.debug("updateMeasureState()", { measureReadinessLevels} );

            let measureState : MeasureState;

            if( measureReadinessLevels == null || measureReadinessLevels.length === 0 ) {

                measureState = MeasureStates.Ongoing as MeasureState;
            }
            else {

                let readinessLevel = company.readinessLevel.value();

                log.debug("updateMeasureState()", company.title.value(), {readinessLevel} );
    
                const locations = await measure.locations.documents();
    
                for( const location of locations.values() ) {
    
                    log.debug("updateMeasureState()", location.title.value(), {readinessLevel} );
    
                    if( updatedLocation != null && 
                        Factory.get().databaseService.databaseFactory.equalDatabasePaths(  
                            updatedLocation.databasePath(), location.databasePath() ) ) {
    
                        readinessLevel = maxReadinessLevel( updatedLocation.readinessLevel.value(), readinessLevel );
                    }
                    else {
                        readinessLevel = maxReadinessLevel( location.readinessLevel.value(), readinessLevel );
                    }
                }
    
                if( readinessLevel == null ) {
    
                    measureState = MeasureStates.Inactive as MeasureState;
                }
                else {
                    measureState = measureReadinessLevels.includes( readinessLevel ) ? 
                        MeasureStates.Ongoing as MeasureState : MeasureStates.Inactive as MeasureState;
                }
            }

            log.debug("updateMeasureState()", {measureState} );

            measure.measureState.setValue( measureState );
            measure.measureState.setLastChange( previousMeasureState );
            
            log.traceOut("updateMeasureState()");

        } catch (error) {
            log.warn("Error handling measure activity statuses", error);

            log.traceOut("updateMeasureState()", "error");
        }
    } 

    private static _instance? : MeasureManager; 

}

