import { LoggerIF } from "./loggerIF";
import { LogLevel } from "./logLevel";

import { ConsoleLogger } from "../impl/consoleLogger";

export class LoggerFactory  {

    static logger( name : string, logLevel? : LogLevel ) : LoggerIF {

        let logger = LoggerFactory._loggers.get( name );
        if( logger == null ) {

           logger = new ConsoleLogger( name, logLevel ); 

            LoggerFactory._loggers.set( name, logger );
        }
        return logger;
    }

    private static _loggers : Map<string,LoggerIF> = new Map<string,LoggerIF>();
}