import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { Box, Button, FormControl, IconButton, Input, MenuItem, Select, Tooltip } from "@material-ui/core";

import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import AddIcon from '@material-ui/icons/Add';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import PeopleIcon from '@mui/icons-material/People';
import BusinessIcon from '@mui/icons-material/Business';
import CloseIcon from '@material-ui/icons/Close';

import { log} from "ui/app/app";
import { HomePaths } from '../../services/common/api/homePaths';

import { AppContext, AppContextProps } from "ui/app/appContext";

import Title from "../components/title";
import { translatedCollectionName } from "../components/collectionName";
import { DefaultDashboardDiagramViewType, PersistentKeyDashboardCardCollection, PersistentKeyDashboardDiagramCollection, PersistentKeyDashboardDiagramViewType, PersistentKeyDashboardTableCollection, PersistentKeyHighlightedProperty } from "ui/components/appFrame";
import { Factory } from "../../services/common/api/factory";
import { DatabaseDocumentIF } from "../../services/database/api/core/databaseDocumentIF";
import { DatabaseIF } from "../../services/database/api/core/databaseIF";
import { databaseCard, databaseChart, databaseGraph, databaseTable, databaseMap, databaseTree, databaseList } from "../components/databaseRouter";
import { IncidentRegistrationsCollection, QuarantineRegistrationsCollection, RegistrationsCollection, SickLeaveRegistrationsCollection, SymptomRegistrationsCollection, TestRegistrationsCollection, VaccineRegistrationsCollection } from "../../healthguard/api/registrationCollections";
import { GatheringsCollection, HealthguardCollectionNames, HazardsCollection, MeasuresCollection, RisksCollection } from "../../healthguard/api/healthguardCollections";
import { ReadinessLevel } from "../../healthguard/api/definitions/readinessLevel";
import { errorDialog } from "../components/simpleDialog";
import { Identifications } from "../../services/database/api/definitions/identification"; 
import { AlertsCollection, CategoriesCollection, ChangesCollection, CollectionNames, CompaniesCollection, DevicesCollection, LocationsCollection, ProjectsCollection, SubscriptionsCollection, UnitsCollection, UsersCollection } from "../../services/database/api/collections";
import { DefinitionPropertyIF } from "../../services/database/api/properties/definitionPropertyIF";
import { DatabaseObserverIF } from "../../services/database/api/core/databaseObserverIF";
import { DisplayTypes } from "../app/display";
import DatabaseViewMenu, { DatabaseViewType, DatabaseViewTypes } from "../components/databaseViewMenu";
import { DocumentNameKey } from "../../services/database";
import { activeLanguage } from "../app/localization";
import { addDocument, openDocument, removeDocument } from "../components/documentRouter";
import { HealthguardUnitDocument } from "../../healthguard/api/healthguardDocuments";
import { propertyInputVariant } from "../components/propertyValue";
import theme from "../app/theme";
import { selectRegistration } from "../components/selectDialog"; 
import { PropertyType, PropertyTypes } from "../../services/database/api/definitions/propertyType";

const DefaultDashboardDiagramCollection = RegistrationsCollection; 
const DefaultUserDashboardDiagramCollection = RegistrationsCollection; 
const DefaultAdminDashboardDiagramCollection = UsersCollection;

const DefaultDashboardCardCollection = RegistrationsCollection; 
const DefaultUserDashboardCardCollection = RegistrationsCollection; 
const DefaultAdminDashboardCardCollection = SubscriptionsCollection;

const DefaultDashboardTableCollection = MeasuresCollection;  
const DefaultUserDashboardTableCollection = MeasuresCollection; 
const DefaultAdminDashboardTableCollection = CompaniesCollection;

const defaultDiagramHeight = "32vh"; 

//const minListHeight = "26vh";

const styles = (theme: Theme) => createStyles({
  root: {
    height: "100%",
    paddingTop: theme.spacing(0),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(0.5),
    marginTop: theme.spacing(-1),
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
    marginBottom: theme.spacing(0),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column'
  },
  select: {
    "& .MuiSelect-select:focus": {
      backgroundColor: 'transparent'
    }
  },
  orgIcons: { 
    padding: theme.spacing(0.25)
  },
  button: {
    justifyContent: "flex-start",
    whiteSpace: 'nowrap', 
    minWidth: 'auto'
  },
  diagrams: { 
    paddingTop: theme.spacing(0.5), 
    paddingBottom: theme.spacing(1)  
  },
  diagramPaper: {
    width: "100%",
    height: defaultDiagramHeight,
    paddingTop: theme.spacing(0.5),
    display: 'flex',
    flexDirection: "column",
    overflow: "hidden",
    backgroundColor: theme.palette.grey[100] 
  },
  mapPaper: {
    width: "100%",
    height: defaultDiagramHeight,  
    display: 'flex',
    flexDirection: "column",
    overflow: "hidden"
  },
  cardPaper: {
    width: "100%",
    height: defaultDiagramHeight,
    paddingTop: theme.spacing(0.5),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1), 
    display: 'flex',
    flexDirection: "column",
    overflow: "hidden",
    backgroundColor: theme.palette.grey[100] 
  },
  tablePaper: {
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    flexGrow: 1 
  }, 
  title: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(6),
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(0),
    color: theme.palette.text.secondary,    
    zIndex: 998
  },
  paperHeader: {
    width: "100%", 
    paddingLeft: theme.spacing(1.5),
  },
  diagramButtons: {
    paddingTop: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5)
  },
  cardButtons: {
    paddingTop: theme.spacing(0.5)
  },
  selectCollectionButton: {
    marginTop: theme.spacing(0),
    marginRight: theme.spacing(0)
  },
  dateFilter: {
    marginTop: theme.spacing(0),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(1),
    display: "flex",
    justifyContent: "flex-begin",
    zIndex: 999
  },
  actionButtons: {
    marginLeft: theme.spacing(1.5),
    display: "flex",
    justifyContent: "flex-begin",
    zIndex: 999,
  },  
  graphButtons: {
    paddingRight: theme.spacing(1)
  },  
  dateFilterButton: {
    paddingRight: theme.spacing(1)
  },  
  includeHistoricButton: {
    paddingRight: theme.spacing(1)
  }, 
  dateCollapse: {
    margin: theme.spacing(0),
    padding: theme.spacing(0),
    width: "100%"
  }
});

interface MatchParams {
}

interface DashboardViewProps extends WithStyles<typeof styles>, WithTranslation, RouteComponentProps<MatchParams>  {
}


interface DashboardViewState { // Document View Props

  diagramViewType?: DatabaseViewType,

  diagramCollection: string,

  cardCollection: string,

  tableCollection: string,

  cardHighlightedPropertyKey?: string,

  selectCollectionOpen?: any


}

class DashboardView extends React.PureComponent<DashboardViewProps, DashboardViewState> {

  constructor(props: DashboardViewProps) {

    super(props);

    this.state = {

    } as DashboardViewState;

    this._tableDatabaseObserver = Factory.get().databaseService.databaseFactory.newDatabaseObserver();

    this._cardDatabaseObserver = Factory.get().databaseService.databaseFactory.newDatabaseObserver();

    this._diagramDatabaseObserver = Factory.get().databaseService.databaseFactory.newDatabaseObserver();

    this.diagramCollectionPersistentKey = this.diagramCollectionPersistentKey.bind(this);
    this.handleDiagramCollection = this.handleDiagramCollection.bind(this);
    this.diagramCollection = this.diagramCollection.bind(this);
    this.diagramDatabases = this.diagramDatabases.bind(this);
    this.openDiagramDatabase = this.openDiagramDatabase.bind(this);

    this.diagramViewTypePersistentKey = this.diagramViewTypePersistentKey.bind(this);
    this.handleDiagramViewType = this.handleDiagramViewType.bind(this);
    this.diagramViewType = this.diagramViewType.bind(this);

    this.cardCollectionPersistentKey = this.cardCollectionPersistentKey.bind(this);
    this.handleCardCollection = this.handleCardCollection.bind(this);
    this.cardCollection = this.cardCollection.bind(this);
    this.cardDatabases = this.cardDatabases.bind(this);
    this.openCardDatabase = this.openCardDatabase.bind(this);

    this.cardHighlightedPropertyPersistentKey = this.cardHighlightedPropertyPersistentKey.bind(this);
    this.cardHighlightedPropertyKey = this.cardHighlightedPropertyKey.bind(this);
    this.onUpdateCardHighlightedPropertyKey = this.onUpdateCardHighlightedPropertyKey.bind(this);

    this.tableCollectionPersistentKey = this.tableCollectionPersistentKey.bind(this);
    this.handleTableCollection = this.handleTableCollection.bind(this);
    this.tableCollection = this.tableCollection.bind(this);
    this.tableDatabases = this.tableDatabases.bind(this);
    this.openTableDatabase = this.openTableDatabase.bind(this);
    
    this.databases = this.databases.bind(this); 

    this.readinessLevelProperty = this.readinessLevelProperty.bind( this );
    this.onUpdateReadinessLevel = this.onUpdateReadinessLevel.bind( this );
   
    this.showHealthData = this.showHealthData.bind( this );

    this.documentPath = this.documentPath.bind( this );

    this.allowedDashboardCollections = this.allowedDashboardCollections.bind( this );


    //log.traceInOut("constructor()");
  }


  async componentDidMount() {

    log.traceIn("componentDidMount()");

    try {

      this._tableDatabaseObserver.setDatabaseQuery({
        databases: this.tableDatabases(),
        includeHistoric: true
      });

      this._cardDatabaseObserver.setDatabaseQuery({
        databases: this.cardDatabases(),
        includeHistoric: true
      });

      this._diagramDatabaseObserver.setDatabaseQuery({
        databases: this.diagramDatabases(),
        includeHistoric: true
      });

      this.setState( {

        diagramViewType: this.diagramViewType(),

        diagramCollection: this.diagramCollection(),
      
        cardCollection: this.cardCollection(),
      
        tableCollection: this.tableCollection(),
      
        cardHighlightedPropertyKey: this.cardHighlightedPropertyKey()
      })

      log.traceOut("componentDidMount()");

    } catch (error) {
      log.warn("componentDidMount()", error);

    }
  }

  private diagramCollectionPersistentKey() {
    const appContext = this.context as AppContextProps;

    return appContext.currentHomePath + this.context.currentHomePath!.substring(1) + "." + PersistentKeyDashboardDiagramCollection;
  }

  private async handleDiagramCollection( diagramCollection : string ) {

    log.traceIn("handleDiagramCollection()",);

    /*
    const diagramCollection = await selectDialog( {
      documentNames: [CollectionDefinition,HealthguardCollectionDefinition], 
      options: !!this.showHealthData() ? HealthguardCollectionNames : CollectionNames,
      title: this.props.t("showCollection") + ":",
      value: this.diagramCollection(),
      disableEmptyOption: true,
      translate: true,
      sort: true
    });
    */

    if( diagramCollection != null && diagramCollection !== this.diagramCollection() ) {

      this._diagramDatabaseObserver = Factory.get().databaseService.databaseFactory.newDatabaseObserver({
          databases: this.databases( diagramCollection ),
          includeHistoric: true
      });

      Factory.get().persistentState!.setProperty( this.diagramCollectionPersistentKey(), diagramCollection);

      this.setState({ diagramCollection: diagramCollection });

    }

    log.traceOut("diagramCollection()", diagramCollection);
  }

  private diagramCollection(): string {
    
    //log.traceIn("diagramCollection()");

    const appContext = this.context as AppContextProps;

    if (this.state.diagramCollection != null) {

      //log.traceOut("diagramCollection()", "From state", this.state.diagramCollection);

      return this.state.diagramCollection;
    }

    const persistentDashboardDiagramColllection =
      Factory.get().persistentState!.property( this.diagramCollectionPersistentKey());

    if (persistentDashboardDiagramColllection != null && 
        HealthguardCollectionNames.includes( persistentDashboardDiagramColllection )) {

      //log.traceOut("diagramCollection()", "From persistent app state", persistentDashboardDiagramColllection);
      return persistentDashboardDiagramColllection;
    }

    let defaultDiagramCollection;

    switch( appContext.currentHomePath ) {

      case HomePaths.AdminHomePath:

        defaultDiagramCollection = DefaultAdminDashboardDiagramCollection;
        break;

      case HomePaths.UserHomePath:

        defaultDiagramCollection = DefaultUserDashboardDiagramCollection;
        break;

      default:

        defaultDiagramCollection = DefaultDashboardDiagramCollection;
        break;
    }

    //log.traceOut("diagramCollection()", "From default", {defaultDiagramCollection});
    return defaultDiagramCollection;
  }

  private diagramDatabases(): DatabaseIF<DatabaseDocumentIF>[] {

    return this.databases( this.diagramCollection() );
  }

  private async openDiagramDatabase() {

    log.traceIn("openDiagramDatabase()",);

    try {

      const appContext = this.context as AppContextProps;

      const diagramDatabases = this.diagramDatabases();

      const path = appContext.currentHomePath! + diagramDatabases[0]!.databasePath( true );

      this.props.history.push(path);

      log.traceOut("openDiagramDatabase()", path);

    } catch (error) {
      log.warn("Error opening diagram collection", error);

      await errorDialog( error);

    }
  }


  private diagramViewTypePersistentKey() {
    const appContext = this.context as AppContextProps;

    return appContext.currentHomePath + this.context.currentHomePath!.substring(1) + "." + PersistentKeyDashboardDiagramViewType;
  }

  private async handleDiagramViewType( diagramViewType : DatabaseViewType ) {

    log.traceIn("handleDiagramViewType()", {diagramViewType});

    Factory.get().persistentState!.setProperty( this.diagramViewTypePersistentKey(), diagramViewType);

    this.setState({ diagramViewType: diagramViewType });

    log.traceOut("diagramCollection()", diagramViewType);
  }

  private diagramViewType(): DatabaseViewType {
    
    //log.traceIn("diagramViewType()");

    if (this.state.diagramViewType != null) {

      //log.traceOut("diagramViewType()", "From state", this.state.diagramViewType);

      return this.state.diagramViewType;
    }

    const persistentDashboardDiagramViewType =
      Factory.get().persistentState!.property( this.diagramViewTypePersistentKey());

    if (persistentDashboardDiagramViewType != null ) {

      //log.traceOut("cardCollection()", "From persistent app state", persistentDashboardDiagramViewType);
      return persistentDashboardDiagramViewType as DatabaseViewType;
    }

    //log.traceOut("cardCollection()", "From default", {defaultCardCollection});
    return DefaultDashboardDiagramViewType;
  }

  private cardCollectionPersistentKey() {
    const appContext = this.context as AppContextProps;

    return appContext.currentHomePath + this.context.currentHomePath!.substring(1) + "." + PersistentKeyDashboardCardCollection;
  }

  private async handleCardCollection( cardCollection : string ) {

    log.traceIn("handleCardCollection()",);

    /*
    const cardCollection = await selectDialog( {
      documentNames: [CollectionDefinition,HealthguardCollectionDefinition], 
      options: !!this.showHealthData() ? HealthguardCollectionNames : CollectionNames,
      title: this.props.t("showCollection") + ":",
      value: this.cardCollection(),
      disableEmptyOption: true,
      translate: true,
      sort: true
    });
    */

    if( cardCollection != null && cardCollection !== this.cardCollection() ) {

      this._cardDatabaseObserver = Factory.get().databaseService.databaseFactory.newDatabaseObserver({
          databases: this.databases( cardCollection ),
          includeHistoric: true
      });

      Factory.get().persistentState!.setProperty( this.cardCollectionPersistentKey(), cardCollection);

      this.setState({ cardCollection: cardCollection });

    }

    log.traceOut("diagramCollection()", cardCollection);
  }

  private cardCollection(): string {
    
    //log.traceIn("cardCollection()");

    const appContext = this.context as AppContextProps;

    if (this.state.cardCollection != null) {

      //log.traceOut("cardCollection()", "From state", this.state.cardCollection);

      return this.state.cardCollection;
    }

    const persistentDashboardCardColllection =
      Factory.get().persistentState!.property( this.cardCollectionPersistentKey());

    if (persistentDashboardCardColllection != null && 
        HealthguardCollectionNames.includes( persistentDashboardCardColllection ) ) {

      //log.traceOut("cardCollection()", "From persistent app state", persistentDashboardCardColllection);
      return persistentDashboardCardColllection;
    }

    let defaultCardCollection;

    switch( appContext.currentHomePath ) {

      case HomePaths.AdminHomePath:

        defaultCardCollection = DefaultAdminDashboardCardCollection;
        break;

      case HomePaths.UserHomePath:

        defaultCardCollection = DefaultUserDashboardCardCollection;
        break;

      default:

        defaultCardCollection = DefaultDashboardCardCollection;
        break;
    }

    //log.traceOut("cardCollection()", "From default", {defaultCardCollection});
    return defaultCardCollection;
  }

  private cardDatabases(): DatabaseIF<DatabaseDocumentIF>[] {

    return this.databases( this.cardCollection() );
  }

  private async openCardDatabase() {

    log.traceIn("openCardDatabase()",);

    try {

      const appContext = this.context as AppContextProps;

      const cardDatabases = this.cardDatabases();

      const path = appContext.currentHomePath! + cardDatabases[0]!.databasePath( true );

      this.props.history.push(path);

      log.traceOut("openCardDatabase()", path);

    } catch (error) {
      log.warn("Error opening card database", error);

      await errorDialog( error);

    }
  }



  private tableCollectionPersistentKey() {
    const appContext = this.context as AppContextProps;

    return appContext.currentHomePath + this.context.currentHomePath!.substring(1) + "." + PersistentKeyDashboardTableCollection;
  }

  private async handleTableCollection( tableCollection : string ) {

    log.traceIn("handleTableCollection()",);

    /*
    const tableCollection = await selectDialog( {
      documentNames: [CollectionDefinition,HealthguardCollectionDefinition], 
      options: !!this.showHealthData() ? HealthguardCollectionNames : CollectionNames, 
      title: this.props.t("showCollection") + ":",
      value: this.tableCollection(),
      disableEmptyOption: true,
      translate: true,
      sort: true
    });
    */

    if( tableCollection != null && tableCollection !== this.tableCollection() ) {

      this._tableDatabaseObserver = Factory.get().databaseService.databaseFactory.newDatabaseObserver({
          databases: this.databases( tableCollection ),
          includeHistoric: true
      });

      Factory.get().persistentState!.setProperty( this.tableCollectionPersistentKey(), tableCollection);

      this.setState({ tableCollection: tableCollection });

    }

    log.traceOut("handleTableCollection()", tableCollection);
  }

  private tableCollection(): string {

    //log.traceIn("tableCollection()");

    const appContext = this.context as AppContextProps;

    if (this.state.tableCollection != null) {

      //log.traceOut("tableCollection()", "From state", this.state.tableCollection);

      return this.state.tableCollection;
    }

    const persistentDashboardTableColllection =
      Factory.get().persistentState!.property( this.tableCollectionPersistentKey());

    if (persistentDashboardTableColllection != null && 
        HealthguardCollectionNames.includes( persistentDashboardTableColllection ) ) {

      //log.traceOut("tableCollection()", "From persistent app state", persistentDashboardTableColllection);
      return persistentDashboardTableColllection;
    }

    let defaultTableCollection;

    switch( appContext.currentHomePath ) {

      case HomePaths.AdminHomePath:

        defaultTableCollection = DefaultAdminDashboardTableCollection;
        break;

      case HomePaths.UserHomePath:

        defaultTableCollection = DefaultUserDashboardTableCollection;
        break;

      default:

        defaultTableCollection = DefaultDashboardTableCollection;
        break;
    }

    //log.traceOut("tableCollection()", "From default", {defaultTableCollection});
    return defaultTableCollection;
  }

  private tableDatabases(): DatabaseIF<DatabaseDocumentIF>[] {

    return this.databases( this.tableCollection() );
  }

  private async openTableDatabase() {

    log.traceIn("openTableDatabase()",);

    try {

      const appContext = this.context as AppContextProps;

      const tableDatabases = this.tableDatabases();

      const path = appContext.currentHomePath! + tableDatabases[0]!.databasePath( true );

      this.props.history.push(path);

      log.traceOut("openTableDatabase()", path);

    } catch (error) {
      log.warn("Error opening table database", error);

      await errorDialog( error);

    }
  }



  private databases( collectionName : string ) : DatabaseIF<DatabaseDocumentIF>[] {

    const appContext = this.context as AppContextProps;

    switch (appContext.currentHomePath) {

      case HomePaths.AdminHomePath:
        return [Factory.get().databaseService.databaseFactory.collectionGroupDatabaseFromCollectionName(collectionName)];

      case HomePaths.CompanyHomePath:
        return [Factory.get().databaseService.databaseFactory.collectionGroupDatabaseFromCollectionName(collectionName, appContext.currentCompany!)];

      case HomePaths.UnitHomePath:
        return [Factory.get().databaseService.databaseFactory.collectionGroupDatabaseFromCollectionName(collectionName, appContext.currentUnit!)];

      case HomePaths.UserHomePath:
        return [Factory.get().databaseService.databaseFactory.collectionGroupDatabaseFromCollectionName(collectionName, appContext.currentUser!)];

      default:
        throw new Error("Unexpected home path in app context");
    }
  }

  private readinessLevelProperty()  : DefinitionPropertyIF<ReadinessLevel> | undefined {

    const appContext = this.context as AppContextProps;

    return appContext.currentCompany?.readinessLevel;
  }

  private onUpdateReadinessLevel = async ( readinessLevelProperty: DefinitionPropertyIF<ReadinessLevel> ) => {
      
    const appContext = this.context as AppContextProps;

    switch (appContext.currentHomePath) {

      case HomePaths.AdminHomePath:
      case HomePaths.UserHomePath:
      case HomePaths.UnitHomePath:
        break;

      case HomePaths.CompanyHomePath:
        await appContext.currentCompany!.update()
        break;

      default:
        throw new Error("Unexpected home path in app context");
    } 
  }

  private cardHighlightedPropertyPersistentKey() {

    const appContext = this.context as AppContextProps;

    return appContext.currentHomePath + "." + this.cardDatabases()[0].defaultDocumentName() + "." + PersistentKeyHighlightedProperty ;
  }

  private async onUpdateCardHighlightedPropertyKey ( cardHighlightedPropertyKey? : string ) {

    log.traceIn("onUpdateHighlightedPropertyKey ()", {cardHighlightedPropertyKey});    

    Factory.get().persistentState!.setProperty( this.cardHighlightedPropertyPersistentKey(), cardHighlightedPropertyKey );

    this.setState({ 
      cardHighlightedPropertyKey: cardHighlightedPropertyKey
     });
  }

  private cardHighlightedPropertyKey(): string | undefined {

    //log.traceIn("cardHighlightedPropertyKey ()",);

    if (this.state.cardHighlightedPropertyKey != null) {
      //log.traceOut("highlightedProperty ()", "From state", this.state.cardHighlightedPropertyKey );
      return this.state.cardHighlightedPropertyKey ;
    }

    const persistentCardHighlightedProperty = Factory.get().persistentState!.property(
      this.cardHighlightedPropertyPersistentKey());

    if (persistentCardHighlightedProperty != null) {

      //log.traceOut("cardHighlightedPropertyKey ()", "From persistent app state", persistentCardHighlightedProperty);
      return persistentCardHighlightedProperty;
    }

    //log.traceOut("cardHighlightedPropertyKey ()", "undefined", undefined );
    return undefined;
  }


  private showHealthData = () : boolean => {

    const appContext = this.context as AppContextProps;

    return appContext.currentCompany != null && 
      appContext.currentUser != null && 
      !appContext.authenticationClaims?.systemAdmin;
  }

  private documentPath( documentName : string ) : string {

    log.traceIn("documentPath()", { documentName });

    const appContext = this.context as AppContextProps;

    let database;

    switch (appContext.currentHomePath) {

      case HomePaths.UserHomePath:

        if (appContext.currentCompany!.subcollectionIdentification(documentName) === Identifications.Anonymous) {
          database =
            Factory.get().databaseService.databaseFactory.collectionGroupDatabaseFromDocumentName(
              documentName, appContext.currentUnit != null ? appContext.currentUnit : appContext.currentCompany);
        }
        else {
          database =
            Factory.get().databaseService.databaseFactory.collectionDatabaseFromDocumentName(
              documentName, appContext.currentUser);
        }
        break;

      case HomePaths.UnitHomePath:
        database =
          Factory.get().databaseService.databaseFactory.collectionGroupDatabaseFromDocumentName(
            documentName, appContext.currentUnit);
        break;

      case HomePaths.CompanyHomePath:
        database =
          Factory.get().databaseService.databaseFactory.collectionGroupDatabaseFromDocumentName(
            documentName, appContext.currentCompany);
        break;

      default:
        throw new Error("Unexpected home path");
    }

    const newDocument = database.newDocument()!;

    const path = newDocument.databasePath(true);

    log.traceOut("documentPath()", { path });
    return path;
  }

  allowedDashboardCollections = () : string[] => {

    const appContext = this.context as AppContextProps;

    const allowedDashboardCollections : string[] = [];

    const allCollections = !!this.showHealthData() ? HealthguardCollectionNames : CollectionNames; 

    for( const collection of allCollections ) {

      switch( collection ) {

        case AlertsCollection:
        case CategoriesCollection:
        case DevicesCollection:
        case GatheringsCollection:
        case HazardsCollection:
        case LocationsCollection:
        case ProjectsCollection:
        case RisksCollection:
        case UnitsCollection:
        {
          if( appContext.currentHomePath !== HomePaths.UserHomePath ) {
            allowedDashboardCollections.push( collection );
          }
          break;
        }

        case ChangesCollection:
        {
          break;
        } 

        case CompaniesCollection:
        case SubscriptionsCollection:
        {
          if( appContext.currentHomePath === HomePaths.AdminHomePath ) {
            allowedDashboardCollections.push( collection );
          }
          break;
        }

        case IncidentRegistrationsCollection:
        {
          if( !appContext.currentCompany!.incidentRegistrationsPrivacy.subdocument().disabled.value() ) {
            allowedDashboardCollections.push( collection );
          }
          break;
        }

        case QuarantineRegistrationsCollection:
        {
          if( !appContext.currentCompany!.quarantineRegistrationsPrivacy.subdocument().disabled.value() ) {
            allowedDashboardCollections.push( collection );
          }
          break;
        }

        case SickLeaveRegistrationsCollection:
        {
          if( !appContext.currentCompany!.sickLeaveRegistrationsPrivacy.subdocument().disabled.value() ) {
            allowedDashboardCollections.push( collection );
          }
          break;
        }

        case SymptomRegistrationsCollection:
        {
          if( !appContext.currentCompany!.symptomRegistrationsPrivacy.subdocument().disabled.value() ) {
            allowedDashboardCollections.push( collection );
          }
          break;
        }

        case TestRegistrationsCollection:
        {
          if( !appContext.currentCompany!.testRegistrationsPrivacy.subdocument().disabled.value() ) {
            allowedDashboardCollections.push( collection );
          }
          break;
        }

        case UsersCollection: 
        {
          if( appContext.currentHomePath !== HomePaths.UserHomePath ||
              appContext.authenticationClaims?.childUserIds != null ) {

            allowedDashboardCollections.push( collection );
          }
          break;
        } 

        case VaccineRegistrationsCollection:
        {
          if( !appContext.currentCompany!.vaccineRegistrationsPrivacy.subdocument().disabled.value() ) {
            allowedDashboardCollections.push( collection );
          }
          break;
        }

        default:
          allowedDashboardCollections.push( collection );
          break;
      }

    }

    return allowedDashboardCollections;
  }


  
  render() {

    //log.traceInOut("render()", appContext.currentDisplay );

    const { classes } = this.props;


    const cardCollection= this.cardCollection();

    const cardHighlightedPropertyKey = this.cardHighlightedPropertyKey();

    const tableCollection= this.tableCollection();

    const showTableColors = true;

    /*
    const readinessLevelProperty = this.readinessLevelProperty();

    const readinessLevelHeader = () => {

      const appContext = this.context as AppContextProps;

      let text = this.props.t("companyReadinessLevel");

      switch( appContext.currentHomePath ) {

        case HomePaths.CompanyHomePath:
        {
          if( appContext.currentCompany?.title.value() != null ) {
            text = appContext.currentCompany.title.value()!;
          }
          break;
        }

        case HomePaths.UnitHomePath:
        {
          if( appContext.currentUnit?.title.value() != null ) {
            text = appContext.currentUnit.title.value()!;
          }
          break;
        }
        case HomePaths.UserHomePath:
        {
          if( appContext.currentUser?.title.value() != null ) {
            text = appContext.currentUser.title.value()!;  
          }
          break; 
        }
      }
      return text; 
    }
    */

    const diagramArea = () => {

      const appContext = this.context as AppContextProps;

      const diagramCollection = this.diagramCollection();

      const diagramViewType = this.diagramViewType();

      const highlightedPropertyKey = diagramCollection === cardCollection ? 
        cardHighlightedPropertyKey : undefined;

      const onAddDocument = async (databaseObserver: DatabaseObserverIF<DatabaseDocumentIF>): Promise<void> =>
          await addDocument({
            appContext: appContext,
            history: this.props.history,
            t: this.props.t,
            databaseObserver: databaseObserver
          });

      const onRemoveDocument = async (databaseObserver: DatabaseObserverIF<DatabaseDocumentIF>, databaseDocument: DatabaseDocumentIF) =>
          removeDocument({
            appContext: appContext,
            history: this.props.history,
            t: this.props.t,
            databaseDocument: databaseDocument,
            databaseObserver: databaseObserver
          });
      
      const onOpenDocument = async (databaseObserver: DatabaseObserverIF<DatabaseDocumentIF>, databaseDocument: DatabaseDocumentIF) =>
         openDocument({
            appContext: appContext,
            history: this.props.history,
            t: this.props.t,
            databaseDocument: databaseDocument,
            databaseObserver: databaseObserver
          });

      return(
        <>
          {diagramViewType === DatabaseViewTypes.Tree &&
            databaseTree(appContext, {
              databaseObserver: this._diagramDatabaseObserver,
              onAddDocument: onAddDocument,
              onRemoveDocument: onRemoveDocument,
              onOpenDocument: onOpenDocument
            })
          }
          {diagramViewType === DatabaseViewTypes.Map &&
            databaseMap(appContext, {
              databaseObserver: this._diagramDatabaseObserver,
              highlightedPropertyKey: highlightedPropertyKey,
              enableStreetView: false,
              enableSateliteView: false,
              onAddDocument: onAddDocument,
              onRemoveDocument: onRemoveDocument,
              onOpenDocument: onOpenDocument
            })
          }
          {diagramViewType === DatabaseViewTypes.Graph &&
            databaseGraph(appContext, {
              databaseObserver: this._diagramDatabaseObserver,
              highlightedPropertyKey: highlightedPropertyKey,
              onAddDocument: onAddDocument,
              onRemoveDocument: onRemoveDocument,
              onOpenDocument: onOpenDocument
            })
          }
          {diagramViewType === DatabaseViewTypes.BarChart &&
            databaseChart(appContext, {
              databaseObserver: this._diagramDatabaseObserver,
              hideAdd: true,
              highlightedPropertyKey: highlightedPropertyKey,
              accumulate: false,
              onAddDocument: onAddDocument,
              onRemoveDocument: onRemoveDocument,
              onOpenDocument: onOpenDocument
            })
          }
          {diagramViewType === DatabaseViewTypes.AccumulatedChart &&
            databaseChart(appContext, {
              databaseObserver: this._diagramDatabaseObserver,
              hideAdd: true,
              highlightedPropertyKey: highlightedPropertyKey,
              accumulate: true,
              onAddDocument: onAddDocument,
              onRemoveDocument: onRemoveDocument,
              onOpenDocument: onOpenDocument
            })
          }
          {diagramViewType === DatabaseViewTypes.List &&
            databaseList(appContext, {
              databaseObserver: this._diagramDatabaseObserver,
              hideAdd: true,
              hideRemove: true,
              onAddDocument: onAddDocument,
              onRemoveDocument: onRemoveDocument,
              onOpenDocument: onOpenDocument
            })
          }
        </>
      );
    }

    const selectCollection = ( currentCollectionName : string, 
      onCollectionNameChange : ( collectionName : string ) => Promise<void> ) => {

      const appContext = this.context as AppContextProps;

      const my = appContext.currentHomePath === HomePaths.UserHomePath && 
                    appContext.authenticationClaims?.childUserIds == null;

      const options = this.allowedDashboardCollections(); 

      options.sort( (optionA, optionB) => {
        return translatedCollectionName( optionA, my ).localeCompare( translatedCollectionName( optionB, my ), activeLanguage() );
      }); 

      const currentValue = options.includes( currentCollectionName ) ? currentCollectionName : undefined;

      return (
        <FormControl
        fullWidth
        variant={propertyInputVariant}>
        <Select
          className={classes.select}
          input={
            <Input disableUnderline={true} />
          }
          displayEmpty={true}
          renderValue={(value) =>
            <Title level="h6" >{translatedCollectionName( value as string, my )}</Title> 
          }
          value={currentValue}
          onChange={(event) => onCollectionNameChange( event.target.value as string )}
          open={this.state.selectCollectionOpen === onCollectionNameChange}
          onOpen={() => this.setState({ selectCollectionOpen: onCollectionNameChange })}
          onClose={() => this.setState({ selectCollectionOpen: undefined })}
        >
          {options.map((option) => (
            <MenuItem
              style={currentCollectionName === option ? { display: 'none' } : {}}
              key={option}
              value={option}>
              {translatedCollectionName( option, my )}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      );
    }

    const temporaryUnitAdmin = () : boolean => {

      const appContext = this.context as AppContextProps;
  
      if( appContext.authenticationClaims == null  ) {
        return false;
      }
  
      if( appContext.currentUnit == null ) {
        return false;
      }
  
      if( appContext.authenticationClaims.unitsAdmin == null ) {
        return false;
      }
  
      const managingReferenceHandles = appContext.currentUser?.managing.referenceHandles();
  
      log.debug( "showUnitCloseOption()", managingReferenceHandles, appContext.currentUnit.databasePath() );
  
      if( managingReferenceHandles != null && 
          managingReferenceHandles.size > 0 && 
          Factory.get().databaseService.databaseFactory.equalDatabasePaths( 
            appContext.currentUnit.databasePath(), Array.from( managingReferenceHandles.values() )[0].path) ) {
        
        return false;
      }
  
      return true; 

    }

    const onSelectHomePath = async ( homePath : string ) => {

      log.traceIn("onSelectHomePath()");

      try {

        const appContext = this.context as AppContextProps;

        if( appContext.currentHomePath === HomePaths.UnitHomePath &&
            homePath === HomePaths.UnitHomePath && 
            temporaryUnitAdmin() ) {

          if( appContext.onUpdateActiveUnit != null ) {
            await appContext.onUpdateActiveUnit( undefined );
          }
        }

        this.props.history.push( homePath );

        log.traceOut("onSelectHomePath()", homePath );

      } catch (error) {
        log.warn("Error selecting new home path", error);

        await errorDialog(error);

      }
    }

    const newRegistration = async () => {
    
      log.traceIn("newRegistration()");
      try {

        const appContext = this.context as AppContextProps;
    
        let path = appContext.currentHomePath! as string;

        let newDocument;

        switch( appContext.currentHomePath )
        {
          case HomePaths.CompanyHomePath:

            newDocument = appContext.currentCompany?.registrations.collection().newDocument();
            break;

          case HomePaths.UnitHomePath:

            newDocument = appContext.currentUnit?.registrations.collection().newDocument();
            break;

          case HomePaths.UserHomePath:

            newDocument = appContext.currentUser?.registrations.collection().newDocument();
            break;

          default:
            log.traceIn("newRegistration()", "Unrecognized home path");
            return;
        }
        
        const addDocumentName = await selectRegistration( appContext, this.props.t("newRegistration") + ":")
    
        if( addDocumentName == null ) {
          log.traceIn("newRegistration()", "None selected");
          return;
        }
    
        path += newDocument!.databasePath() + "?" + DocumentNameKey + "=" + addDocumentName;
    
        this.props.history.push(path);
    
        log.traceOut("newRegistration()", path);
        
      } catch( error ) {
        log.warn( "newRegistration()", error );
    
        await errorDialog( error);
      }
    }

    const diagramViewType = this.diagramViewType();

    const geolocationProperties = this._diagramDatabaseObserver.referenceDocument()?.properties({
      includePropertyTypes: [PropertyTypes.Geolocation as PropertyType] } ); 

    const allowMap = geolocationProperties != null && geolocationProperties?.size > 0;

    const allowChart = this._diagramDatabaseObserver.referenceDocument()?.referenceDateProperty() != null;

    const allowTree = this._diagramDatabaseObserver.documentNames() != null &&
      this._diagramDatabaseObserver.defaultDocumentName()! === HealthguardUnitDocument;  

    const databaseViewItems : DatabaseViewType[] = [];

    if( !!allowMap ) {
      databaseViewItems.push( DatabaseViewTypes.Map as DatabaseViewType );
    }

    if( !!allowTree ) {
      databaseViewItems.push( DatabaseViewTypes.Tree as DatabaseViewType );
    } 

    databaseViewItems.push( DatabaseViewTypes.Graph as DatabaseViewType );

    if( !!allowChart) {
      databaseViewItems.push( DatabaseViewTypes.AccumulatedChart as DatabaseViewType );
      databaseViewItems.push( DatabaseViewTypes.BarChart as DatabaseViewType );
    } 

    databaseViewItems.push( DatabaseViewTypes.List as DatabaseViewType );

    return (
      <React.Fragment>
        <CssBaseline />
        <AppContext.Consumer>
          {appContext => (
            <Box className={classes.root}>
              <Grid item container className={classes.diagrams} alignItems="stretch" justifyContent="space-between" spacing={1}>         
                <Grid item container justifyContent="space-between" style={{ flexWrap: "nowrap" }} spacing={2} xs={12} sm={12} md={12}>
                  <Grid item container justifyContent="flex-start" alignItems="center" spacing={0} style={{overflow: 'auto', flexWrap: 'nowrap'}} >
                    {appContext.currentUser != null &&
                        <Grid item>
                          <Button 
                            className={classes.button} 
                            color={appContext.currentHomePath === HomePaths.UserHomePath ? "primary" : "secondary"}
                            startIcon={<PeopleIcon color="inherit" className={classes.orgIcons} />}
                            onClick={() => onSelectHomePath( HomePaths.UserHomePath )}
                          >
                            {appContext.currentUser.title.value()}
                          </Button>
                        </Grid>
                      }
                                            {appContext.currentUnit != null &&
                        <Grid item style={{flexWrap: "nowrap"}}>
                          <Button 
                            className={classes.button} 
                            color={appContext.currentHomePath === HomePaths.UnitHomePath ? "primary" : "secondary"}
                            startIcon={<AccountTreeIcon color="inherit" className={classes.orgIcons}/>}
                            endIcon={temporaryUnitAdmin() ? <CloseIcon /> : undefined}
                            disabled={appContext.authenticationClaims?.unitsAdmin == null}
                            onClick={() => onSelectHomePath( HomePaths.UnitHomePath )}
                          >
                            {appContext.currentUnit.title.value()}
                          </Button>
                        </Grid>
                      }
                      {appContext.currentCompany != null && 
                        <Grid item>
                          <Button 
                            className={classes.button} 
                            color={appContext.currentHomePath === HomePaths.CompanyHomePath ? "primary" : "secondary"}
                            startIcon={<BusinessIcon color="inherit" className={classes.orgIcons}/>}
                            disabled={appContext.authenticationClaims?.companiesAdmin == null}
                            onClick={() => onSelectHomePath( HomePaths.CompanyHomePath )}
                          >
                            {appContext.currentCompany.title.value()}
                          </Button>
                        </Grid>
                      }
                    </Grid>
                  <Grid item container justifyContent="flex-end" spacing={1} xs sm md>
                    {/*<Grid item>
                      <Button 
                        className={classes.button} 
                        size="small"
                        variant="outlined" 
                        color="secondary"  
                        disableElevation>
                        {this.props.t("back")}
                      </Button>
                    </Grid>
                    */} 
                    <Grid item>
                      <Button 
                        className={classes.button} 
                        size="small"
                        variant="contained" 
                        color="primary" 
                        disableElevation
                        startIcon={<AddIcon/>}
                        onClick={() => newRegistration()}
                        > 
                        {this.props.t("newRegistration")} 
                      </Button>
                     </Grid> 
                    </Grid>
                </Grid>              
                <Grid item xs={12} sm={8} md={8}>
                  <Grid container >
                    <Paper className={classes.diagramPaper} elevation={0}>
                      <Grid container className={classes.paperHeader} justifyContent="space-between" style={{flexWrap: "nowrap"}}>
                        <Grid item >
                          <Title level="h6">
                            {selectCollection(this.diagramCollection(), this.handleDiagramCollection )} 
                          </Title>
                        </Grid>
                        <Grid item container className={classes.diagramButtons} spacing={2} justifyContent="flex-end" alignItems="flex-start">
                          <Grid item>
                            <DatabaseViewMenu
                              currentDatabaseViewType={diagramViewType as DatabaseViewType}
                              databaseViewItems={databaseViewItems}
                              useAnchor={true}
                              onDatabaseViewChange={this.handleDiagramViewType}
                            />
                          </Grid>
                          <Grid item className={classes.selectCollectionButton}>
                            <Tooltip title={(<>{this.props.t("viewCollection")}</>)}>
                              <IconButton size="small" onClick={() => this.openDiagramDatabase()}>
                                <MoreHorizIcon />
                              </IconButton> 
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </Grid>
                      {diagramArea()}
                    </Paper>
                  </Grid>
                </Grid>
                {appContext.currentDisplay?.displayType !== DisplayTypes.Mobile &&
                  <Grid item xs={12} sm={4} md={4}>
                    <Grid container >
                      <Paper className={classes.cardPaper} elevation={0}> 
                        <Grid container className={classes.paperHeader} justifyContent="space-between" >
                          <Grid item >
                            <Title level="h6">
                              {selectCollection(this.cardCollection(), this.handleCardCollection )} 
                            </Title>
                          </Grid>
                          <Grid item className={classes.selectCollectionButton} style={{marginTop: theme.spacing(0.5)}}>
                            <Tooltip title={(<>{this.props.t("viewCollection")}</>)}>
                              <IconButton size="small" onClick={() => this.openCardDatabase()}>
                                <MoreHorizIcon />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </Grid>
                        {databaseCard(appContext, {
                          databaseObserver: this._cardDatabaseObserver,
                          highlightedPropertyKey: cardHighlightedPropertyKey,
                          onUpdateHighlightedPropertyKey: this.onUpdateCardHighlightedPropertyKey,
                          hideLegend: true,
                          hideLabels: true 
                        })}
                      </Paper>
                    </Grid>
                  </Grid>
                }
              </Grid>
              <Paper className={classes.tablePaper} elevation={0}>
                <Grid container className={classes.paperHeader} justifyContent="space-between">
                  <Grid item >
                    <Title level="h6">
                      {selectCollection(this.tableCollection(), this.handleTableCollection )} 
                    </Title>
                  </Grid>
                  <Grid item className={classes.selectCollectionButton}>
                    <Tooltip title={(<>{this.props.t("viewCollection")}</>)}>
                      <IconButton size="small" onClick={() => this.openTableDatabase()}>
                        <MoreHorizIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
                {databaseTable( appContext, {
                  databaseObserver: this._tableDatabaseObserver,
                  hideColors: !showTableColors,
                  highlightedPropertyKey: tableCollection === cardCollection ? cardHighlightedPropertyKey : undefined,
                  hideAdd: true
                })
                }
              </Paper>
            </Box>
          )}
        </AppContext.Consumer>
      </React.Fragment >
    );
  }

  private _tableDatabaseObserver : DatabaseObserverIF<DatabaseDocumentIF>;

  private _cardDatabaseObserver : DatabaseObserverIF<DatabaseDocumentIF>;

  private _diagramDatabaseObserver : DatabaseObserverIF<DatabaseDocumentIF>;
}

DashboardView.contextType = AppContext;

export default withRouter(withTranslation()(withStyles(styles)(DashboardView)));


