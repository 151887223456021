import React from 'react';

import DocumentView, { DocumentProps } from '../components/documentView';

import { PropertiesSelector } from 'services/database/api/core/propertiesSelector';

import DatabaseTable, { DatabaseTableProps } from 'ui/components/databaseTable';
import DatabaseList, { DatabaseListProps } from 'ui/components/databaseList';
import DatabaseCard, { DatabaseCardProps } from 'ui/components/databaseCard';
import DatabaseChart, { DatabaseChartProps } from 'ui/components/databaseChart';
import DatabaseGraph, { DatabaseGraphProps } from 'ui/components/databaseGraph';


import DatabaseView from '../components/databaseView';
import { MessageIF } from 'services/database/api/documents/messageIF';
import MessageReaderView from './messageReaderView';
import { DatabaseFilter } from '../../services/database/api/core/databaseFilter';
import { Comparator, Comparators } from '../../services/database/api/definitions/comparator';
import { DatabaseQuery } from '../../services/database/api/core/databaseQuery';
import { errorDialog } from '../components/simpleDialog';
import { DatabaseViewType, DatabaseViewTypes } from '../components/databaseViewMenu';

export const MessageViewDetails = "details";
export const MessageViewReader = "reader";
export const MessageViewComposer = "composer";

export const defaultMessageTableColumns = [
  "startDate",
  "endDate",
  "sender",
  "receivers",
  "title"
];


export const defaultHighlightedMessageProperty = "messageCategory";

const unreadMessagesProperty = "endDate";

export function MessagesTable(props: DatabaseTableProps ) {

  const unreadMessagesFilter = new Map<string,DatabaseFilter>();

  unreadMessagesFilter.set( unreadMessagesProperty, {

    property: unreadMessagesProperty,

    comparator: Comparators.NotExists as Comparator,

  } as DatabaseFilter );
  
  return (
    <DatabaseTable {...props} 
      featuredProperties={props.featuredProperties != null ? props.featuredProperties : defaultMessageTableColumns }
      highlightedRowsFilters={unreadMessagesFilter}
    />
  );
}

export function MessagesList(props: DatabaseListProps ) {

  return (
    <DatabaseList {...props} />
  );
}


export function MessagesCard( props: DatabaseCardProps ) {
  return (
    <DatabaseCard 
      {...props} 
      highlightedPropertyKey={props.highlightedPropertyKey != null ? props.highlightedPropertyKey : defaultHighlightedMessageProperty}
    />
  );
}

export function MessagesChart( props: DatabaseChartProps ) {
  return (
    <DatabaseChart 
      {...props} 
    />
  );
}

export function MessagesGraph( props: DatabaseGraphProps ) {
  return (
    <DatabaseGraph 
      {...props} 
      previousPropertyKey={"user"}
      directed={false}
    />
  );
}

const MessageBasicsFilter = {

  includePropertyKeys : [
    "messageCategory",
    "startDate",
    "endDate",
    "sender",
    "receivers",
    "body",
    "links",
    "attachments"
  ]

} as PropertiesSelector;

const TerminalEmailNotificationFilter = {

  includePropertyKeys: [
    "emailNotification"
  ]

} as PropertiesSelector;

const TerminalSmsNotificationFilter = {

  includePropertyKeys: [
    "smsNotification"
  ]

} as PropertiesSelector;

const TerminalPushNotificationFilter = {

  includePropertyKeys: [
    "pushNotification"
  ]

} as PropertiesSelector;


const defaultMessageSteps = () : Map<string,PropertiesSelector> => {

  let defaultSteps = new Map<string,PropertiesSelector>();

  defaultSteps.set( "message", MessageBasicsFilter );
  defaultSteps.set( "emailNotification", TerminalEmailNotificationFilter );
  defaultSteps.set( "smsNotification", TerminalSmsNotificationFilter );
  defaultSteps.set( "pushNotification", TerminalPushNotificationFilter );

  return defaultSteps;
}


export function MessagesView(props: { 
  databaseQuery : DatabaseQuery<MessageIF>,
  title? : string,
  featuredProperties?: string[],
  highlightedPropertyKey?: string }) {

  return ( <DatabaseView 
    databaseQuery={props.databaseQuery}
    title={props.title}
    featuredProperties={props.featuredProperties != null ? props.featuredProperties : defaultMessageTableColumns }
    highlightedPropertyKey={props.highlightedPropertyKey != null ? props.highlightedPropertyKey : defaultHighlightedMessageProperty}
    defaultDatabaseViewType={DatabaseViewTypes.Table as DatabaseViewType}
    hideAdd={true}
    /> );
}


export function MessageView( props : DocumentProps ) {

  const [databaseDocument, setDatabaseDocument] = React.useState<MessageIF>();

  React.useEffect(() => {

    (async () => {

      try {
        let message = props.databaseDocument as MessageIF;

        if( !message.isNew() && !message.isLoaded() ) { 
          await message.read(); 
        }

        if( message != null && 
            !message.isNew() && 
            message.endDate.value() == null &&
            message.userAccess().allowUpdate ) {

          message.endDate.setValue( new Date() );

          await message.update();
        }

        setDatabaseDocument( message );

      } catch (error) {

        await errorDialog(error);

      }

    })();

  }, [props.databaseDocument]);

  if( databaseDocument == null ) {
    return null;
  }


  if (props.view === MessageViewDetails || props.view === MessageViewComposer) {
    return (
      <DocumentView
        {...props}
        databaseDocument={databaseDocument}
        inputTabs={props.inputTabs != null ? props.inputTabs : defaultMessageSteps()}
      />
    );
  }
  if (props.view === MessageViewReader) {
    return (<MessageReaderView 
      {...props}
      databaseDocument={databaseDocument} />); 
  }

  if( !databaseDocument.isNew() ) {
    return (<MessageReaderView 
      {...props}
      databaseDocument={databaseDocument} />);
  }
  else {
    return (
      <DocumentView
        {...props}
        databaseDocument={databaseDocument}
        inputTabs={props.inputTabs != null ? props.inputTabs : defaultMessageSteps()}
      /> 
    );
  }
}




