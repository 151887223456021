
import { PropertyType, PropertyTypes } from "../../api/definitions/propertyType"; 
import { BooleanPropertyIF } from "../../api/properties/booleanPropertyIF";
import { DatabaseObject } from "../../framework/databaseObject";
import { BasicProperty } from "./basicProperty";

export class BooleanProperty extends BasicProperty<boolean> implements BooleanPropertyIF {

    constructor( parent : DatabaseObject, defaultValue? : boolean ) {
        super( PropertyTypes.Boolean as PropertyType, parent, defaultValue );
    }

   compareTo( other : BooleanProperty ) : number {

        return this.compareValue( other.value() );
    }

    compareValue( otherValue : boolean | undefined ) : number {

        const value = this.value();

        if( value === otherValue ) { 
            return 0;
        }

        return !!value ? 1 : -1;
    }
}