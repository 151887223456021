
import { CollectionDatabase } from "../core/collectionDatabase";
import { DatabaseDocument } from "../../framework/databaseDocument";
import { log } from "../../framework/databaseService";
import { DefinitionProperty } from "../properties/definitionProperty";
import { GeolocationProperty } from "../properties/geolocationProperty";
import { SubdocumentProperty } from "../properties/subdocumentProperty";
import { TextProperty } from "../properties/textProperty";
import { CompaniesCollection, DevicesCollection, UnitsCollection, UsersCollection } from "../../api/collections";
import { ResidenceTypeDefinition } from "../../api/definitions";
import { ResidenceType, ResidenceTypes } from "../../api/definitions/residenceType";
import { ResidenceIF } from "../../api/documents/residenceIF";
import { Address } from "../subdocuments/address";
import { TenantProperty } from "../properties/tenantProperty";
import { User } from "./user";
import { CompanyDocument, ResidenceDocument, UnitDocument, UserDocument } from "../../api/documents";
import { Company } from "./company";
import { Unit } from "./unit";
import { PhoneNumberProperty } from "../properties/phoneNumberProperty";
import { ReferencesProperty } from "../properties/referencesProperty";
import { Device } from "./device";
import { OwnerProperty } from "../properties/ownerProperty";


export class Residence extends DatabaseDocument implements ResidenceIF {

    constructor( residencesCollection : CollectionDatabase<Residence>, documentPath? : string  ) {

        super( ResidenceDocument, residencesCollection, documentPath );

        try {
            this.title.required = true;

            this.company = new TenantProperty<Company>( this, CompaniesCollection, CompanyDocument ); 
            this.company.editable = false;

            this.unit = new OwnerProperty<Unit>( this, UnitsCollection, UnitDocument ); 

            this.user = new OwnerProperty<User>( this, UsersCollection, UserDocument ); 

            this.residenceType = new DefinitionProperty<ResidenceType>( this, ResidenceTypeDefinition, ResidenceTypes );

            const addressSubdocument = new Address( this, "address");
            this.address = new SubdocumentProperty<Address>( this, addressSubdocument );

            this.geolocation = new GeolocationProperty( this );

            this.geolocation.setCountryProperty( addressSubdocument.country );

            this.geolocation.setTitleProperty( this.title );

            this.geolocation.setAddressProperty( this.address );

            this.geolocation.required = true;

            this.emergencyContactName = new TextProperty( this );

            this.emergencyContactNumber = new PhoneNumberProperty( this, addressSubdocument.country );

            this.devices = new ReferencesProperty<Device>( this, 
                () => [this.parentCollection( DevicesCollection ) as CollectionDatabase<Device>] );           
        
            //log.traceInOut( "constructor()", ResidencesCollection );

        } catch( error ) {

            log.warn( "constructor()", "Error initializing residence", error );
            
            throw new Error( (error as any).message );
        }
    }

    indexKey() : string {
        return ResidenceDocument;
    }

    referenceDateProperty()  {
        return undefined;
    }

    readonly company: TenantProperty<Company>;

    readonly unit: OwnerProperty<Unit>;

    readonly user: OwnerProperty<User>;

    readonly residenceType : DefinitionProperty<ResidenceType>;

    readonly address : SubdocumentProperty<Address>;

    readonly geolocation : GeolocationProperty;

    readonly emergencyContactName : TextProperty;

    readonly emergencyContactNumber : PhoneNumberProperty;

    readonly devices : ReferencesProperty<Device>;


}
