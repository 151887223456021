import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next'; 

import DocumentView, { DocumentProps } from '../components/documentView';

import { PropertiesSelector } from 'services/database/api/core/propertiesSelector';

import DatabaseTable, { DatabaseTableProps } from 'ui/components/databaseTable';
import DatabaseList, { DatabaseListProps } from 'ui/components/databaseList';
import DatabaseCard, { DatabaseCardProps } from 'ui/components/databaseCard';
import DatabaseChart, { DatabaseChartProps } from 'ui/components/databaseChart';
import DatabaseGraph, { DatabaseGraphProps } from 'ui/components/databaseGraph';

import DatabaseView  from '../components/databaseView';

import { ConsentIF } from 'healthguard/api/documents/consentIF';
import { CompanyDocument, UnitDocument, UserDocument } from '../../services/database/api/documents';
import { DatabaseQuery } from '../../services/database/api/core/databaseQuery';
import { DatabaseDocumentIF } from '../../services/database/api/core/databaseDocumentIF';
import { log } from '../app/app';


import { AppContext, AppContextProps } from '../app/appContext';
import { TranslationKey } from '../../services/common/api/translatorIF';
import { confirmationDialog, errorDialog } from '../components/simpleDialog';
import { Factory } from '../../services/common/api/factory';
import { ConsentResponses } from '../../healthguard/api/definitions/consentResponse';

import companyMeasuresNotification from "healthguard/data/notifications/companyMeasures.json";
import { HealthguardCompanyIF, HealthguardUserIF, MeasureDocument } from '../../healthguard';
import { DatabaseViewType, DatabaseViewTypes } from '../components/databaseViewMenu';
import { HomePaths } from '../../services/common/api/homePaths';
import { ReferenceHandle } from '../../services/database/api/core/referenceHandle';
import { MessageIF } from '../../services/database/api/documents/messageIF';

export const defaultConsentTableColumns = [ 
  CompanyDocument,
  UnitDocument,
  UserDocument,
  MeasureDocument,
  "consentResponse",
  "startDate",
  "endDate"
];


export const defaultHighlightedConsentsProperty = "consentResponse"; 

const onNotify = async ( appContext : AppContextProps, 
  t : TFunction, 
  databaseDocument : DatabaseDocumentIF ) => {

  log.traceIn("onNotify()", databaseDocument.title.value() );

  try {
    const consent = databaseDocument as ConsentIF;

    const user = await consent.user.document() as HealthguardUserIF;

    const company = await consent.company.document() as HealthguardCompanyIF;

    const parent = !!company.enableParents.value() && user.user.id() != null ?
      (await user.user.document() as HealthguardUserIF)! : undefined;

    let confirmationPrompt = t( TranslationKey.Prompts + ":sendNotification" );

    confirmationPrompt += " " + (parent != null ? parent.title.value() : user.title.value())  + "?"

    const confirmation = await confirmationDialog(confirmationPrompt);

    if (!confirmation) {
      log.traceOut("onNotify()", "Cancel");
      return;
    }

    const message = await Factory.get().messagingService.notificationSender.createConsentNotification( {
      notification: companyMeasuresNotification,
      user: user,
      company: company,
      parent: parent
    });

    consent.notifications.setDocument( message.referenceHandle() as ReferenceHandle<MessageIF> ); 

    await consent.update();

    log.traceIn("onNotify()", "Added message" );

  } catch (error) {
      log.warn("onNotify()", "Error sending notification", error);

      await errorDialog( error );
    }
}

export function ConsentsTable(props: DatabaseTableProps ) {

  const appContext = useContext(AppContext);

  const { t } = useTranslation();

  return (
    <DatabaseTable 
      {...props} 
      featuredProperties={props.featuredProperties != null ? props.featuredProperties : defaultConsentTableColumns }
      actions = {appContext.currentHomePath === HomePaths.UserHomePath ? undefined : [
        { title: t("notify"), onAction: ( databaseDocument ) => onNotify( appContext, t, databaseDocument ) }
      ]}
      />
  );
}

export function ConsentsList(props: DatabaseListProps ) {

  return (
    <DatabaseList {...props} />
  );
}

export function ConsentsCard( props: DatabaseCardProps ) {
  return (
    <DatabaseCard 
      {...props} 
      highlightedPropertyKey={props.highlightedPropertyKey != null ? 
        props.highlightedPropertyKey : 
        defaultHighlightedConsentsProperty}
    />
  );
} 

export function ConsentsChart( props: DatabaseChartProps ) {
  return (
    <DatabaseChart 
      {...props} 
    />
  );
}

export function ConsentsGraph( props: DatabaseGraphProps ) {
  return (
    <DatabaseGraph 
      {...props} 
      previousPropertyKey={"consent"}
      nextPropertyKey={"user"}
      directed={false}
    />
  );
}

export function ConsentsView(props: { 
  databaseQuery : DatabaseQuery<ConsentIF>,
  title? : string,
  featuredProperties?: string[],
  highlightedPropertyKey?: string }) {

  return ( 
    <DatabaseView
     databaseQuery={props.databaseQuery} 
     title={props.title}
     featuredProperties={props.featuredProperties != null ? props.featuredProperties : defaultConsentTableColumns }
     hideAdd={true}       
     highlightedPropertyKey={props.highlightedPropertyKey != null ? 
      props.highlightedPropertyKey : 
      defaultHighlightedConsentsProperty}
      defaultDatabaseViewType={DatabaseViewTypes.Table as DatabaseViewType}
    /> );
}


export function ConsentView( props : DocumentProps) {

  const appContext = useContext(AppContext);

  const { t } = useTranslation();


  const ConsentBasicsFilter = {

    includePropertyKeys: [
      UnitDocument,
      MeasureDocument,
      UserDocument,
      "receiver",
      "consentResponse",
      "lastChangedAt",
      "startDate",
      "endDate",
      "description"
    ]

  } as PropertiesSelector;

  const ConsentNotificationsFilter = {

    includePropertyKeys: [
      "notifications"
    ]

  } as PropertiesSelector;


  const defaultConsentSteps = (): Map<string, PropertiesSelector> => {

    let defaultSteps = new Map<string, PropertiesSelector>();

    defaultSteps.set("basics", ConsentBasicsFilter);
    defaultSteps.set("notifications", ConsentNotificationsFilter);

    return defaultSteps;
  }


  let actions;

  const consent = props.databaseDocument as ConsentIF;

  if( consent != null && appContext.currentHomePath !== HomePaths.UserHomePath ) {

    actions = [{ 
      title: t("notify"), 
  
      onAction: () => onNotify( appContext, t, consent! ),
  
      disabled: consent.consentResponse.value() === ConsentResponses.Accepted
     }]
  }


  return (<DocumentView 
    {...props}
    inputTabs={props.inputTabs != null ? props.inputTabs : defaultConsentSteps()} 
    actions={actions}
  />); 
}

