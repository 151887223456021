import React from 'react';

import DocumentView, { DocumentProps } from '../components/documentView';

import { PropertiesSelector } from 'services/database/api/core/propertiesSelector';

import DatabaseTable, { DatabaseTableProps } from 'ui/components/databaseTable';
import DatabaseList, { DatabaseListProps } from 'ui/components/databaseList';
import DatabaseCard, { DatabaseCardProps } from 'ui/components/databaseCard';
import DatabaseChart, { DatabaseChartProps } from 'ui/components/databaseChart';
import DatabaseGraph, { DatabaseGraphProps } from 'ui/components/databaseGraph';

import DatabaseView from '../components/databaseView';
import { ProjectIF } from 'services/database/api/documents/projectIF';
import { CompanyDocument, UnitDocument } from '../../services/database/api/documents';
import { DatabaseQuery } from '../../services/database/api/core/databaseQuery';
import { DatabaseViewType, DatabaseViewTypes } from '../components/databaseViewMenu';

export const defaultProjectTableColumns = [ 
  CompanyDocument,
  UnitDocument,
  "title",
  "projectManager",
  "startDate",
  "users"];

export const defaultHighlightedProjectsProperty = "unit";

export function ProjectsTable(props: DatabaseTableProps ) {

  return (
    <DatabaseTable 
      {...props} 
      featuredProperties={props.featuredProperties != null ? props.featuredProperties : defaultProjectTableColumns }
      />
  );
}

export function ProjectsList(props: DatabaseListProps ) {

  return (
    <DatabaseList 
      {...props} 
    />
  );
}


export function ProjectsCard( props: DatabaseCardProps ) {
  return (
    <DatabaseCard 
      {...props} 
      highlightedPropertyKey={props.highlightedPropertyKey != null ? 
        props.highlightedPropertyKey : 
        defaultHighlightedProjectsProperty}
    />
  );
} 

export function ProjectsChart( props: DatabaseChartProps ) {
  return (
    <DatabaseChart 
      {...props} 
    />
  );
}

export function ProjectsGraph( props: DatabaseGraphProps ) {
  return (
    <DatabaseGraph 
      {...props} 
      previousPropertyKey={"unit"}
      nextPropertyKey={"users"}
      directed={false}
    />
  );
}


export function ProjectsView(props: {
  databaseQuery: DatabaseQuery<ProjectIF>,
  title?: string,
  featuredProperties?: string[],
  highlightedPropertyKey?: string
}) {

  return (
    <DatabaseView
      databaseQuery={props.databaseQuery}
      title={props.title}
      featuredProperties={props.featuredProperties != null ? props.featuredProperties : defaultProjectTableColumns}
      highlightedPropertyKey={props.highlightedPropertyKey != null ?
        props.highlightedPropertyKey :
        defaultHighlightedProjectsProperty}
      defaultDatabaseViewType={DatabaseViewTypes.Table as DatabaseViewType}
    />
  );
}


export function ProjectView( props : DocumentProps ) {

  const ProjectBasicsFilter = {

    includePropertyKeys : [
      "unit",
      "projectManager",      
      "startDate",
      "endDate",
      "description",
      "attachments",
      "links" ]
    
  } as PropertiesSelector;
  
  const ProjectMembersFilter = {

    includePropertyKeys : [
      "users" ]
    
  } as PropertiesSelector; 
  
  const defaultProjectSteps = () : Map<string,PropertiesSelector> => {
  
    let defaultSteps = new Map<string,PropertiesSelector>();
  
    defaultSteps.set( "basics", ProjectBasicsFilter );
    defaultSteps.set( "users", ProjectMembersFilter );
  
    return defaultSteps;
  }
  
  return ( <DocumentView 
    {...props}
    inputTabs={props.inputTabs != null ? props.inputTabs : defaultProjectSteps()}/> );
}






