import * as React from 'react'


import { createStyles, makeStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import { withTranslation, WithTranslation } from 'react-i18next';

import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Fab, Grid, IconButton, Menu, MenuItem, TableContainer, TablePagination, TableSortLabel } from '@material-ui/core';

import { AppContext, AppContextProps} from 'ui/app/appContext';
import { log } from 'ui/app/app';

import { DatabaseDocumentIF } from 'services/database/api/core/databaseDocumentIF';

import PropertyLabel from './propertyLabel';
import PropertyValue from './propertyValue';
import { errorDialog } from './simpleDialog';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { DatabasePropertyIF } from 'services/database/api/core/databasePropertyIF';
import { PropertyDisplayMode } from './propertyValue';
import { Factory } from 'services/common/api/factory';
import theme from 'ui/app/theme';

import { CompanyDocument, UnitDocument, UserDocument } from '../../services/database/api/documents';
import { HazardDocument } from '../../healthguard/api/healthguardDocuments';

import {DatabaseFilter, PropertiesSelector } from '../../services/database';
import { DatabaseProps, DatabaseState } from './databaseView';
import Loading from './loading';
import { PersistentKeyColumns, PersistentKeyRowsPerPage, PersistentKeySortColumn, PersistentKeySortOrder } from './appFrame';

import { selectProperty } from './selectDialog';
import { translatedCollectionName } from './collectionName';
import { translatedDocumentName } from './documentName';
import PropertyColor from './propertyColor';
import { DatabaseObserverIF } from '../../services/database/api/core/databaseObserverIF';
import { ObservableIF } from '../../services/common/api/observableIF';
import { Observation } from '../../services/common/api/observation';
import { Monitor } from '../../services/common/api/monitor';
import { HomePaths } from '../../services/common/api/homePaths';
import { DisplayTypes } from '../app/display';
import { DatabaseSortOrder } from '../../services/database/api/core/databaseSortOrder';
import { SortOrientation, SortOrientations } from '../../services/database/api/definitions/sortOrientation';
import { PropertyTypes } from '../../services/database/api/definitions/propertyType';


export const DocumentNameProperty = "name";

export const DocumentDescriptionProperty = "description";

export const IdProperty = "id";

export const DescriptionProperty = "description";

export const StartDateProperty = "startDate"; 

export const EndDateProperty = "endDate";

export const LastChangedByProperty = "lastChangedBy";

export const LastChangedAtProperty = "lastChangedAt";

export const ArchivedProperty = "archived"; 

export const ArchivedAtProperty = "archivedAt";  

export const VariantProperty = "variant";

export const DefaultColumns = [StartDateProperty,EndDateProperty,CompanyDocument,UnitDocument,UserDocument,DocumentNameProperty,HazardDocument]; 

export const DefaultSortColumn = StartDateProperty;

export const DefaultSortOrientation = SortOrientations.Descending;

const RowsPerPageOptions = [100, 250, 500, 750, 1000]; 

const DefaultRowsPerPage = 100;

export const columnPropertiesSelector = { 

  excludePropertyTypes: [
    PropertyTypes.Collection,
    PropertyTypes.Subdocument,
    PropertyTypes.Data,
    PropertyTypes.Empty,
    PropertyTypes.Geolocation,
    PropertyTypes.Map   
  ],

  excludePropertyKeys: [
    "id",
    "description",
    "lastChangedBy",
    "lastChangedAt",
    "archived",
    "archivedAt",
    "authenticationId"
]

} as PropertiesSelector


type TableRow = {

  databaseDocument: DatabaseDocumentIF;

  properties: (DatabasePropertyIF<any> | undefined)[];

  highlighted?: boolean
}


const styles = (theme: Theme) => createStyles({
  textLink: {
    color: 'inherit',
    textDecoration: 'inherit'
  },
  table: {
    tableLayout: "auto", 
    width: "auto"
  },
  headerCell: {
    whiteSpace: "nowrap",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(0) 
  },
  tableCell: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)     
  },
  colorMark: {
    width: theme.spacing(1),
    height: theme.spacing(1)
  },
  mobilePagination: {
    borderBottom: 0, 
    overflowY: 'hidden', 
    marginBottom: theme.spacing(0) 
  },
  defaultPagination: {
    borderBottom: 0, 
    overflowY: 'hidden',
    marginBottom: theme.spacing(0)
  },
  columnAction: { 
    margin: theme.spacing(0),
    padding: theme.spacing(0)
  },
  menuItem: {
    justifyContent: 'flex-begin',
  },
  color: {
    width: theme.spacing(2),
    height: theme.spacing(2)
  },
  multiColor: {
    padding: theme.spacing(0.1),
    width: theme.spacing(1.9),
    height: theme.spacing(1.9)
  },
  icon: {
    width: theme.spacing(2),
    minWidth: theme.spacing(2),
    height: theme.spacing(2),
    minHeight: theme.spacing(2),
    marginRight: theme.spacing(1.25),
    fontColor: theme.palette.secondary 
  },  
  addButton: {
    position: 'absolute',
    bottom: theme.spacing(1.5),
    marginLeft: theme.spacing(-0.5), 
    zIndex: 999,
  }
});


interface MatchParams {
}

export interface DatabaseTableProps extends DatabaseProps {

  databaseObserver : DatabaseObserverIF<DatabaseDocumentIF>,

  featuredProperties?: string[],

  highlightedRowsFilters?: Map<string, DatabaseFilter>,

  expandable?: boolean,

  actions? : {

    title: string, 

    onAction: ( databaseDocument : DatabaseDocumentIF ) => Promise<void>,  

    disabled? : boolean
  }[]
}

export interface ReactDatabaseTableProps extends
  DatabaseTableProps,
  WithStyles<typeof styles>,
  WithTranslation,
  RouteComponentProps<MatchParams> 
  {}


interface DatabaseTableState extends DatabaseState { // Component State

  featuredProperties?: string[],

  highlightedRowsFilters?: Map<string, DatabaseFilter>,

  documentName: string,

  hasEndDate : boolean,

  allowUpdate: boolean,

  allowDelete: boolean,

  currentPage: number, 

  rowsPerPage?: number,

  sortColumn?: string,

  sortOrientation?: SortOrientation,

  loading: boolean,

  columns?: string[];

  rows: Map<string, TableRow>,

  filtered: number

}


class DatabaseTable extends React.PureComponent<ReactDatabaseTableProps, DatabaseTableState> {

  constructor(props: ReactDatabaseTableProps) { 

    super(props);

    const userAccess = this.props.databaseObserver.defaultDatabase()!.userAccess();
    
    const allowUpdate = userAccess.allowUpdate;

    const allowDelete = userAccess.allowDelete && this.props.onRemoveDocument != null;  

    const documents = new Map<string, DatabaseDocumentIF>();

    this.state = {

      highlightedPropertyKey: this.props.highlightedPropertyKey,

      featuredProperties: this.props.featuredProperties,

      highlightedRowsFilters: this.props.highlightedRowsFilters,

      documentName: this.props.databaseObserver.defaultDocumentName()!,

      hasEndDate: false,

      allowUpdate: allowUpdate,

      allowDelete: allowDelete,

      currentPage: 0,

      documents: documents,

      loading: true,

      rows: new Map<string, TableRow>(),

      filtered: 0,

      documentMenuAnchors: new Map<string,HTMLElement>()

    } as DatabaseTableState;

    this.onUpdateDatabases = this.onUpdateDatabases.bind(this);
    this.columnsPersistentKey = this.columnsPersistentKey.bind(this);
    this.columns = this.columns.bind(this);
    this.addColumn = this.addColumn.bind(this);
    this.selectColumn = this.selectColumn.bind(this);
    this.removeColumn = this.removeColumn.bind(this);
    this.openDocument = this.openDocument.bind(this); 
    this.removeDocument = this.removeDocument.bind(this);

    this.updateRows = this.updateRows.bind(this);
    this.row = this.row.bind(this);
    this.showProperty = this.showProperty.bind(this);

    this.rowsPerPagePersistentKey = this.rowsPerPagePersistentKey.bind(this);
    this.rowsPerPage = this.rowsPerPage.bind(this);
    this.updateRowsPerPage = this.updateRowsPerPage.bind(this);

    this.sortColumnPersistentKey = this.sortColumnPersistentKey.bind(this);
    this.sortColumn = this.sortColumn.bind(this);
    this.updateSortColumn = this.updateSortColumn.bind(this);

    this.sortOrientationPersistentKey = this.sortOrientationPersistentKey.bind(this);
    this.sortOrientation = this.sortOrientation.bind(this);

    this.classes = makeStyles((theme) => ({
    }));

    //log.traceInOut("constructor()");
  }


  async componentDidMount() {

    try {
      log.traceIn("componentDidMount()" );

      let databaseSortOrders;

      const sortColumn = this.sortColumn();

      if( sortColumn != null ) {

        databaseSortOrders = new Map<string,DatabaseSortOrder>();

        databaseSortOrders.set(
          sortColumn, {
            property: sortColumn,
            orientation: this.sortOrientation()
          } 
        );
      }

      this.props.databaseObserver.setDatabaseSortOrders( databaseSortOrders );

      await this.updateRows();

      await this.props.databaseObserver.subscribe({ 
        observer: this,
        onNotify: this.onUpdateDatabases 
        } as Monitor );

      await this.props.databaseObserver.update();

      log.traceOut("componentDidMount()");

    } catch( error ) {
          
      log.warn( "componentDidMount()", error ); 

      await errorDialog( error);

      log.traceOut( "componentDidMount()", error );
    }
  }
 
  async componentDidUpdate() {

    try {
      //log.traceIn("componentDidUpdate()" );

      await this.props.databaseObserver.update();

      if( this.props.highlightedPropertyKey !== this.state.highlightedPropertyKey ||
        JSON.stringify( this.props.featuredProperties ) !== JSON.stringify( this.state.featuredProperties ) ||
        JSON.stringify( this.props.highlightedRowsFilters ) !== JSON.stringify( this.state.highlightedRowsFilters ) ||
        this.props.databaseObserver.defaultDocumentName() !== this.state.documentName ) {

        this.setState( {
          highlightedPropertyKey: this.props.highlightedPropertyKey,
          featuredProperties: this.props.featuredProperties,
          highlightedRowsFilters: this.props.highlightedRowsFilters,
          documentName: this.props.databaseObserver.defaultDocumentName()!
        })
      } 

      //log.traceOut("componentDidUpdate()" );

    } catch( error ) {
          
      log.warn( "componentDidUpdate()", error );

      await errorDialog( error);

      //log.traceOut( "componentDidUpdate()", error );
    }
  }

  async componentWillUnmount() {
    log.traceIn("componentWillUnmount()");

    try {

      await this.props.databaseObserver.unsubscribe( this );

      log.traceOut("componentWillUnmount()");

    } catch (error) {
      log.warn("componentWillUnmount()", "Error unmounting collection list", error);
    }
  }

  private onUpdateDatabases = async (observable : ObservableIF, observation : Observation ) => {

    //log.traceIn("onUpdateDatabases()");

    try {
      if( observable !== this.props.databaseObserver ) {
        throw new Error("Mismatching database observer");
      }

      await this.updateRows();

      //log.traceOut("onUpdateDatabases()");

    } catch (error) {
      log.warn("onUpdateDatabases()", "Error updating databases", error);

      log.traceOut("onUpdateDatabases()", "error");
    }
  }


  private async updateRows() : Promise<void> {

    //log.traceIn("updateRows()", this.props.databaseObserver.filteredDocuments().size );

    try {

      const filtered = this.props.databaseObserver.count() - this.props.databaseObserver.filteredCount();

      let hasEndDate = false;

      let rows = new Map<string, TableRow>();

      const columns = this.columns();

      for (const documentPair of this.props.databaseObserver.filteredDocuments()!) {

        const path = documentPair[0]!

        let row;

        const databaseDocument: DatabaseDocumentIF = documentPair[1]!;

        if( databaseDocument.endDate.value() != null ) {
          hasEndDate = true;
        }

        row = this.row(databaseDocument!, columns );

        if( row == null ) {
          //log.warn("updateRows()", "Could not read row ", {path});
          continue;          
        }

        rows.set(path, row); 
      }

      this.setState({ 

        loading: false,

        hasEndDate: !!this.state.hasEndDate || hasEndDate,

        rows: rows,

        filtered: filtered,

        columns: columns

      });


      //log.traceOut("updateRows()", sortedRows.size);

    } catch (error) {
      log.warn("Error reading list rows for documents", error);

      throw new Error( (error as any).message );
    }
  }


  protected openDocument = async ( documentPath: string) => {

    log.traceInOut("openDocument()", documentPath);

    try {

      const databaseDocument = this.props.databaseObserver.filteredDocuments().get( documentPath );

      if( databaseDocument == null ) {
        throw new Error( "notFound" )
      }

      if( this.props.onOpenDocument != null ) {

        this.props.onOpenDocument( this.props.databaseObserver, databaseDocument );
      }
      else {
        let to = this.context.currentHomePath + documentPath; 

        this.props.history.push(to);
      }
      log.traceIn("openDocument()", documentPath);

    } catch( error ) {
      
      log.warn( "openDocument()", error );

      await errorDialog( error);
    }
  }


  private removeDocument = async ( documentPath: string) => {

    log.traceIn("removeDocument()", documentPath);

    try {
      const databaseDocument = await Factory.get().databaseService.databaseFactory.documentFromUrl(documentPath);

      if (databaseDocument == null) {
        throw new Error("notFound");
      }

      if( this.props.onRemoveDocument != null ) {

        this.props.onRemoveDocument(  this.props.databaseObserver, databaseDocument );
      }

            
      log.traceOut("removeDocument()" );

    } catch( error ) {
      
      log.warn( "removeDocument()", error );

      await errorDialog( error );
    }
  }

  private columnsPersistentKey() {
    const appContext = this.context as AppContextProps;

    return appContext.currentHomePath + "." + this.state.documentName + "." + PersistentKeyColumns;
  }

  private columns(): string[] {

    //log.traceIn("columns()" );

    let rawColumns;

    const persistentColumns = Factory.get().persistentState!.property(
      this.columnsPersistentKey());

    if (persistentColumns != null) {

      //log.debug("columns ()", "From persistent app state", persistentColumns);
      rawColumns = persistentColumns;
    }
    
    if (rawColumns == null) {

      const propsColumns = this.state.featuredProperties; 

      if (propsColumns != null) {

        //log.debug("columns()", "From featured properties", propsColumns);
        rawColumns = propsColumns;
      }
    }

    if (rawColumns == null) {

      //log.debug("columns()", "From default", DefaultColumns);
      rawColumns = DefaultColumns;
    }

    const columns: string[] = [];

    for (const column of rawColumns) {

      if (!this.showProperty(column)) {
        continue;
      }

      columns.push( column );

      /*
      if( appContext.display?.displayType === DisplayTypes.Mobile && columns.length >= mobileColumnLimit ) {
        break;
      }

      if( appContext.display?.displayType === DeviceTypes.Tablet && columns.length >= tabletColumnLimit ) {
        break;
      }
      */ 
    }

    //log.traceOut("columns ()", {columns});
    return columns;
  }

  protected addColumn = async () => {
    await this.selectColumn();
  }


  protected selectColumn = async (previousColumn?: string ) => {

    log.traceIn("selectColumn()", { column: previousColumn });

    try {
      const columns = this.state.columns;

      if( columns == null ) {
        log.traceOut("selectColumn()", "no columns"); 
        return;
      }

      const propertiesSelector = columnPropertiesSelector;

      if( !this.showProperty( CompanyDocument )) {
        propertiesSelector.excludePropertyKeys!.push( CompanyDocument );
      }

      if( !this.showProperty( UnitDocument )) {
        propertiesSelector.excludePropertyKeys!.push( UnitDocument );
      }

      if( !this.showProperty( UserDocument )) {
        propertiesSelector.excludePropertyKeys!.push( UserDocument );
      }

      if( !this.showProperty( EndDateProperty )) {
        propertiesSelector.excludePropertyKeys!.push( EndDateProperty );
      }

      if( !this.showProperty( DocumentNameProperty )) {
        propertiesSelector.excludePropertyKeys!.push( DocumentNameProperty );
      }

      let index = previousColumn != null ? columns.indexOf( previousColumn )! : columns.length;

      const newColumn = await selectProperty(
        this.props.databaseObserver.referenceDocument()!,
        columnPropertiesSelector,
        this.props.t("showProperty"),
        previousColumn);

      if (newColumn != null) {

        if( index === columns.length ) {
          columns.push( newColumn );
        }
        else {
          columns[index] = newColumn;
        }

        Factory.get().persistentState!.setProperty( this.columnsPersistentKey(), columns );

        this.setState( { columns: columns });

        const sortColumn = this.sortColumn();

        if( previousColumn === sortColumn ) {

          this.props.databaseObserver.setDatabaseSortOrders( undefined );

          await this.props.databaseObserver.update();
        }

        await this.updateRows();
      }
      log.traceOut("selectColumn()", {columns});

    } catch (error) {

      log.warn("selectColumn()", error);

      await errorDialog(error);

      log.traceOut("selectColumn()", error);
    }
  }

  protected removeColumn = async (removeColumn: string ) => {

    log.traceIn("removeColumn()", { column: removeColumn } );

    try {
      const columns = this.state.columns;

      if( columns == null || columns.length === 0 ) {
        log.traceOut("removeColumn()", "no columns"); 
        return;
      }

      const index = columns.indexOf( removeColumn );

      if( index === -1 ) { 
        throw new Error( "Column selector mismatch")
      }

      columns.splice( index, 1 );

      Factory.get().persistentState!.setProperty( this.columnsPersistentKey(), columns );

      this.setState( { columns: columns });

      const sortColumn = this.sortColumn();

      if( removeColumn === sortColumn ) {

        this.props.databaseObserver.setDatabaseSortOrders( undefined );

        await this.props.databaseObserver.update();
      }

      await this.updateRows();
  
      log.traceOut("removeColumn()", {columns});

    } catch (error) {

      log.warn("removeColumn()", error);

      await errorDialog(error);

      log.traceOut("removeColumn()", error);
    }
  }


  private showProperty = ( column: string): boolean => {

    const appContext = this.context as AppContextProps;

    if (column === HazardDocument) {
      return appContext.currentHazard == null;
    }

    if (column === CompanyDocument) {
      return appContext.currentHomePath === HomePaths.AdminHomePath;
    }

    if (column === UnitDocument) {
      return appContext.currentHomePath === HomePaths.CompanyHomePath ||
        appContext.currentHomePath === HomePaths.UnitHomePath;
    }

    if (column === UserDocument) {
      return appContext.currentHomePath !== HomePaths.UserHomePath;
    }

    if (column === EndDateProperty) {
      return !!this.state.hasEndDate || !!this.props.databaseObserver.includeHistoric();
    }

    if (column === DocumentNameProperty ) {
      return !!this.props.multipleDocuments;
    }

    if( this.props.databaseObserver.referenceDocument()?.property( column ) == null ) {
      return false;
    }

    return true;
  }


  private row = (databaseDocument: DatabaseDocumentIF, columns : string[] ): TableRow | undefined => {

    //log.traceIn("row()", this.state.featuredProperties );

    try {

      const row = {

        databaseDocument: databaseDocument,

        properties : [],

      } as TableRow;

      for( const column of columns ) {

        const property = databaseDocument.property( column );

        if( property == null ) {
          log.warn( "row()", "Property not found in document: " + column );
          continue;
        }

        row.properties.push( property );
      }

      if( this.state.highlightedRowsFilters != null ) {

        row.highlighted = databaseDocument.matchFilter( {
          databaseFilters: this.state.highlightedRowsFilters });
      }

      //log.traceOut("row()", row);
      return row;

    } catch (error) {
      log.warn("Error reading list rows for documents", error);

      return undefined;
    }
  }


  private rowsPerPagePersistentKey = (): string => {
   
    const appContext = this.context as AppContextProps;

    return appContext.currentHomePath + "." + this.state.documentName + "." + PersistentKeyRowsPerPage;
  }

  private rowsPerPage = (): number => {

    //log.traceIn("rowsPerPage()");

    if (this.state.rowsPerPage != null) {
      log.traceOut("rowsPerPage()", "From state", this.state.rowsPerPage);
      return this.state.rowsPerPage;
    }

    const persistentRowsPerPage = Factory.get().persistentState!.property( this.rowsPerPagePersistentKey()) as number;

    if (persistentRowsPerPage != null) {

      //log.traceOut("rowsPerPage()", "From persistent app state", persistentRowsPerPage);
      return persistentRowsPerPage;
    }

    //log.traceOut("rowsPerPage()", "From default", DefaultRowsPerPage);
    return DefaultRowsPerPage;
  };

  private updateRowsPerPage = async ( rowsPerPage: number) => {
    log.traceInOut("updateRowsPerPage()", rowsPerPage);

    Factory.get().persistentState!.setProperty( this.rowsPerPagePersistentKey(), rowsPerPage );

    this.setState({ 
      rowsPerPage: rowsPerPage,

      currentPage: 0
     });
  };

  private sortColumnPersistentKey = (): string => {
   
    const appContext = this.context as AppContextProps;

    return appContext.currentHomePath + "." + this.state.documentName + "." + PersistentKeySortColumn;
  }

  private sortColumn = (): string | undefined => {

    //log.traceIn("sortColumn()");

    if (this.state.sortColumn != null) {
      //log.traceOut("sortColumn()", "From state", this.state.sortColumn);
      return this.state.sortColumn;
    }

    const persistentSortColumn = Factory.get().persistentState!.property( this.sortColumnPersistentKey()) as string;

    if (persistentSortColumn != null) {

      //log.traceOut("sortColumn()", "From persistent app state", persistentSortColumn);
      return persistentSortColumn;
    }

    const columns = this.state.columns;

    if( columns == null || columns.length === 0 ) {
      //log.traceOut("sortColumn()", "no columns");
      return undefined;
    } 

    let sortColumn;
  
    if (columns.includes(DefaultSortColumn)) {
      sortColumn = DefaultSortColumn;
    }
    else if (columns.includes(UserDocument)) {
      sortColumn = UserDocument;
    }
    else if (columns.includes(UnitDocument)) {
      sortColumn = UnitDocument;
    }
    else if (columns.includes(CompanyDocument)) {
      sortColumn = CompanyDocument;
    }
    else if (columns.includes(HazardDocument)) {
      sortColumn = HazardDocument;
    }

    //log.traceOut("sortColumn()", "From default", sortColumn);
    return sortColumn;
  };

  private updateSortColumn = async (sortColumn: string) => {

    try {
      log.traceIn("updateSortColumn()", sortColumn);

      let sortOrientation: SortOrientation;

      if (this.sortColumn() === sortColumn ) {

        sortOrientation = this.sortOrientation() === SortOrientations.Ascending ? 
          SortOrientations.Descending as SortOrientation: SortOrientations.Ascending as SortOrientation;
      }
      else {
        sortOrientation = SortOrientations.Ascending as SortOrientation;
      }

      Factory.get().persistentState!.setProperty( this.sortColumnPersistentKey(), sortColumn );

      Factory.get().persistentState!.setProperty( this.sortOrientationPersistentKey(), sortOrientation );

      let databaseSortOrders;

      if( sortColumn != null ) {

        databaseSortOrders = new Map<string,DatabaseSortOrder>();
        databaseSortOrders.set(
          sortColumn, {
            property: sortColumn,
            orientation: this.sortOrientation()
          } 
        );
      }

      this.props.databaseObserver.setDatabaseSortOrders( databaseSortOrders );

      await this.props.databaseObserver.update();

      await this.updateRows();

      log.traceOut("updateSortColumn()");

    } catch( error ) {
      
      log.warn( "updateSortColumn()", error );

      await errorDialog( error);

      log.traceOut( "updateSortColumn()", error );
    }
  }

  private sortOrientationPersistentKey = (): string => {
   
    const appContext = this.context as AppContextProps;

    return appContext.currentHomePath + "." + this.state.documentName + "." + PersistentKeySortOrder;
  }

  private sortOrientation = (): SortOrientation => {

    //log.traceIn("sortOrientation()");

    if (this.state.sortOrientation != null) {
      //log.traceOut("sortOrientation()", "From state", this.state.sortOrientation);
      return this.state.sortOrientation;
    }

    const persistentSortOrder = Factory.get().persistentState!.property( this.sortOrientationPersistentKey()) as SortOrientation;

    if (persistentSortOrder != null) {

      //log.traceOut("sortOrientation()", "From persistent app state", persistentSortOrder);
      return persistentSortOrder;
    }

    //log.traceOut("sortOrientation()", "From default", DefaultSortOrder);
    return DefaultSortOrientation as SortOrientation;
  };


  render(): JSX.Element {

    //log.traceInOut("render()"); 

    const { classes } = this.props;

    const handleChangePage = (event: unknown, newPage: number) => {

      log.traceInOut("handleChangePage()", newPage);

      this.setState({ currentPage: newPage });
    };


    const headerCell = ( column: string, index : number ) => {

      if (!this.showProperty( column)) {
        return null;
      }

      const sortColumn = this.sortColumn();

      return ([
        <TableCell 
          key={column} 
          align="left" 
          className={classes.headerCell}>
          <Grid container direction="column"> 
            <Grid item>   
              <TableSortLabel 
                active={sortColumn === column}
                direction={sortColumn !== column ? undefined : 
                  this.sortOrientation() === SortOrientations.Ascending ? "asc" : "desc"
                }
                onClick={() => this.updateSortColumn(column)}>
                <PropertyLabel documentName={this.state.documentName} propertyKey={column} />
              </TableSortLabel>
              <TableSortLabel
                className={classes.columnAction}
                active={false}
                onClick={() => this.selectColumn( column )} 
                IconComponent={MoreVertIcon}
              />
              <TableSortLabel
                className={classes.columnAction}
                active={false}
                onClick={() => this.removeColumn(column )} 
                IconComponent={CloseIcon}
              />
            </Grid>
          </Grid>
        </TableCell >
      ]);
    }

    const tableCell = ( path : string, databaseProperty?: DatabasePropertyIF<any>, highlighted?: boolean ) => {

      if (databaseProperty == null ) {
        return <TableCell 
          key={path + ".empty"} 
          padding="none" 
          className={classes.tableCell}
          />;
      }

      const propertyKey = databaseProperty.key();

      if (!this.showProperty( propertyKey )) {
        return null;
      }

      return (
        <TableCell
          padding="none" 
          className={classes.tableCell}
          onClick={(event) => { event.preventDefault(); this.openDocument(path) }}
          key={path + "." + propertyKey}>
          <Grid container alignItems="center" style={{ flexWrap: "nowrap" }}>
            {propertyKey === this.state.highlightedPropertyKey &&
              <Grid item className={classes.icon}>
                <PropertyColor databaseProperty={databaseProperty}/>
              </Grid>
            }
            <Grid item style={{ fontWeight: !!highlighted ? "bold" : "normal"}} >
              <PropertyValue
                property={databaseProperty}
                displayMode={PropertyDisplayMode.Cell}
                singleProperty={false}
                hideColor={propertyKey === this.state.highlightedPropertyKey} />
            </Grid>
          </Grid>
        </TableCell>
      );
    }

    const rowsPerPage = this.rowsPerPage()

    const beginDisplayRange: number = this.state.currentPage * rowsPerPage;

    const endDisplayRange: number = this.state.currentPage * rowsPerPage + rowsPerPage;

    const columns = this.state.columns != null ? this.state.columns : [];

    const count = this.state.rows != null ? this.state.rows.size : 0;

    let title = count === 1 ? 
      translatedDocumentName( this.state.documentName )! : 
      translatedCollectionName( this.state.documentName )!;

    if( title == null ) {
      title = "";
    }

    function DocumentMenu( props : { databaseTable : DatabaseTable, path : string } ) {

      const [documentMenuAnchor, setDocumentMenuAnchor] = React.useState<null | HTMLElement>(null);

      const actions = ( path : string ) => {

        if( props.databaseTable.props.actions == null || props.databaseTable.props.actions.length === 0 ) {
          return null;
        }
  
        return(
          props.databaseTable.props.actions.map( action => 
            <MenuItem
              className={classes.menuItem}
              key={path + ".action"}
              onClick={() => {
                setDocumentMenuAnchor( null );
                if( action.onAction != null ) {
                  action.onAction( props.databaseTable.props.databaseObserver.document( path )!);
                }
              }}>
              {props.databaseTable.props.t(action.title)} 
            </MenuItem> 
          )
        )
      }
      
      return (
        <>
          <IconButton 
            color="secondary" 
            onClick={(event) => setDocumentMenuAnchor( event.currentTarget ) }>
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="document-menu"
            anchorEl={documentMenuAnchor}
            keepMounted
            open={documentMenuAnchor != null}
            onClose={() => setDocumentMenuAnchor( null )}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }} >
            <MenuItem
              className={classes.menuItem}
              onClick={( event ) => {
                setDocumentMenuAnchor( null );
                props.databaseTable.openDocument(props.path) ;
              }}
            >
              {props.databaseTable.props.t("show")}
            </MenuItem>
            {actions(props.path)}
            {props.databaseTable.state.allowDelete &&  
              <MenuItem
                className={classes.menuItem} 
                onClick={(event) => {
                  setDocumentMenuAnchor(null);
                  props.databaseTable.removeDocument(props.path)
                }}
              >
                {props.databaseTable.props.t("remove")}
              </MenuItem>
            }
          </Menu>
        </>
      );
    }

    return (
      <React.Fragment>
        {this.state.loading ? <Loading /> :
          <AppContext.Consumer>
            {appContext => (
              <>
                <TableContainer key="databaseListContainer" style={{ height:"100%" }}>  
                  <Table stickyHeader className={this.classes.table} size="small" key="databaseListHeader">
                    <TableHead key="databaseListHeader">
                      <TableRow key="pagination">
                      </TableRow>
                      <TableRow key="databaseListTitle">
                        {columns.map((column, index) => headerCell(column, this.state.highlightedPropertyKey != null ? index - 1 : index))}
                        <TableCell padding="none" key="add" align="right">
                          <TableSortLabel
                            style={{paddingRight: theme.spacing(1.5)}}
                            active={false}
                            onClick={() => this.addColumn()}
                            IconComponent={AddIcon}
                          />
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody key="databaseListBody">
                      {this.state.rows == null ? <TableRow key="noDatabaseTableRows"></TableRow> :
                        [...this.state.rows!].slice(beginDisplayRange, endDisplayRange).map((entry) => {
                          const path: string = entry[0];
                          const properties: (DatabasePropertyIF<any> | undefined)[] = entry[1].properties;
                          const highlighted = entry[1].highlighted;
                          return (
                            <TableRow key={path} hover>
                              {properties.map((property) => tableCell(path, property, highlighted))}
                              <TableCell padding="none" align="right"
                                key={path + ".menu"}>
                                <DocumentMenu databaseTable={this} path={path} />
                              </TableCell>
                            </TableRow>
                          )
                        })
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
                {appContext.currentDisplay?.displayType === DisplayTypes.Mobile || this.state.rows.size <= DefaultRowsPerPage ?
                  <TablePagination 
                    className={classes.mobilePagination}  
                    style={!!this.props.expandable ? { marginRight: theme.spacing(4)} : {}}
                    component="div"
                    rowsPerPageOptions={count <= DefaultRowsPerPage ? [DefaultRowsPerPage] : RowsPerPageOptions}
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={this.state.currentPage}
                    onPageChange={() => { }}
                    nextIconButtonProps={{ style: { display: "none" } }}
                    backIconButtonProps={{ style: { display: "none" } }}
                    onRowsPerPageChange={(event) => this.updateRowsPerPage(+event.target.value)}
                    labelRowsPerPage={this.props.t("show")}
                    labelDisplayedRows={args =>
                      this.state.filtered === 0 || appContext.currentHomePath === HomePaths.UserHomePath ? 
                        (`${args.count} ${title.toLowerCase()}`) :
                        (`${args.count} ${title.toLowerCase()} (${this.state.filtered} ${this.props.t("filtered").toLowerCase()})`)}
                  />
                  :
                  <TablePagination 
                    className={classes.defaultPagination}
                    style={!!this.props.expandable ? { marginRight: theme.spacing(8)} : {}}
                    component="div"
                    rowsPerPageOptions={RowsPerPageOptions}
                    count={this.state.rows != null ? this.state.rows.size : 0}
                    rowsPerPage={rowsPerPage}
                    page={this.state.currentPage}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={(event) => this.updateRowsPerPage(+event.target.value)}
                    labelRowsPerPage={this.props.t("rowsPerPage")}
                    labelDisplayedRows={args =>
                      this.state.filtered === 0 ?
                      (`${args.from}-${args.to === -1 ? args.count : args.to} ${this.props.t("of").toLowerCase()} ${args.count}`) :
                      (`${args.from}-${args.to === -1 ? args.count : args.to} ${this.props.t("of").toLowerCase()} ${args.count} (${this.state.filtered} ${this.props.t("filtered").toLowerCase()})`)}
                  />
                }
                {!this.props.hideAdd && this.props.onAddDocument != null &&
                  <Fab color="secondary"
                    aria-label="add"
                    className={classes.addButton}
                    onClick={() => this.props.onAddDocument!( this.props.databaseObserver )}>
                    <AddIcon />
                  </Fab>
                }
              </>
            )}
          </AppContext.Consumer>
        }
      </React.Fragment >
    );
  }

  private readonly classes: any;

}

DatabaseTable.contextType = AppContext;

const ModifiedDatabaseTable = withRouter(withTranslation()(withStyles(styles)(DatabaseTable)));

export default ModifiedDatabaseTable;












