
export const Identifications = {

    Identified: "identified",

    Anonymous: "anonymous",

    Optional  : "optional"

}

export type Identification = keyof (typeof Identifications);

export const DefaultIdentification = Identifications.Identified as Identification;   

