
import { DatabaseObject } from "../../framework/databaseObject";
import { BasicProperty } from "./basicProperty";
import { PropertyType, PropertyTypes } from "../../api/definitions/propertyType"; 
import { PhoneNumberPropertyIF } from "../../api/properties/phoneNumberPropertyIF";
import { PhoneNumber } from "../../api/core/phoneNumber";
import { CountryProperty } from "./countryProperty";

export const MinE164PhoneNumberLength = 10;
export const MinNationalPhoneNumberLength = 8;


export class PhoneNumberProperty extends BasicProperty<string> implements PhoneNumberPropertyIF {

    constructor( parent : DatabaseObject, countrySource? : CountryProperty, defaultValue? : string ) {
        super( PropertyTypes.PhoneNumber as PropertyType, parent, defaultValue ); 

        this._countrySource = countrySource;
    }

    countrySource() : CountryProperty | undefined {
        return this._countrySource;
    }

    setCountrySource( countrySource? : CountryProperty ) {
        this._countrySource = countrySource;
    }


    defaultCountry() : string | undefined {
        return this._countrySource?.value();
    }

    setValue( value : string | undefined ): void { 

        if( this.compareValue( value ) !== 0 ) {

            this.setLastChange( this._value );

            this._value = value;

            delete this.error;
        }
    }

    cleanValue() : string | undefined {
        return PhoneNumber.cleanPhoneNumber( this.value() );
    }

    validValue( requireE164 : boolean ) : boolean {
        return PhoneNumber.isValidPhoneNumber( this.value(), requireE164 );
    }

    compareTo( other : PhoneNumberProperty ) : number {

       return this.compareValue( other.value() );
    }

    compareValue( other : string | undefined ) : number {

        const thisValue = this.cleanValue();

        const otherValue = PhoneNumber.cleanPhoneNumber( other );

        if( thisValue == null && otherValue == null ) {
            return 0;
        }

        if( thisValue != null && otherValue == null ) {
            return 1;
        }

        if( thisValue == null && otherValue != null ) {
            return -1;
        }

        return thisValue!.localeCompare( otherValue! );
    }

    includes( other : PhoneNumberProperty ) : boolean {
        return this.includesValue( other.value() );
    }

    includesValue( value : string | undefined ) : boolean {

        const thisValue = this.cleanValue();

        const otherValue = PhoneNumber.cleanPhoneNumber( value );

        return thisValue != null && otherValue != null && thisValue.includes( otherValue ); 
    }


    private _countrySource? : CountryProperty; 

}