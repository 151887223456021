
export const Currencies = {

    USD   : "usd",

    EUR   : "eur",

    GBP   : "gbp",

    NOK   : "nok",

    SEK   : "sek",

    DEK   : "dek"

}

export type Currency = keyof (typeof Currencies);
