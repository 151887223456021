import React from 'react';
import ReactDOMServer from 'react-dom/server';

import {unescape} from 'html-escaper';

import { withTranslation, WithTranslation } from 'react-i18next';

import { TranslationKey } from '../../services/common/api/translatorIF';
import { HealthguardDefinition } from '../../healthguard/api/healthguardDefinitions';


export const translatedDefinition = ( definition : HealthguardDefinition, value? : string ) => {
  return unescape( ReactDOMServer.renderToString( <ModifiedDefinitionText definition={definition} value={value} />));
}

interface DefinitionTextProps extends WithTranslation {
  definition : HealthguardDefinition, 
  value? : string
}


interface DefinitionTextState { 
}

class DefinitionText extends React.PureComponent<DefinitionTextProps,DefinitionTextState> {

  constructor( props: DefinitionTextProps ) {
    
    super(props);

    this.state = { 
    } as DefinitionTextState;

    //log.traceInOut( "constructor()" );
  }


  
  render() {

    //log.traceInOut( "render()" );

    let result;

    let translationKey;
  
    if( this.props.value == null ) {
      translationKey = this.props.definition + ":" + TranslationKey.Definition;
  
      if( this.props.i18n.exists( translationKey ) ) {
        result = this.props.t( translationKey );
        //log.traceOut( "definitionText()", translationKey, result );
        return result;
      }

      return null;
    }
  
    translationKey = this.props.definition + ":" + TranslationKey.Values + ":" + this.props.value;
  
    if( this.props.i18n.exists( translationKey ) ) {
      result = this.props.t( translationKey );
      //log.traceOut( "definitionText()", translationKey, result );
      return result;
    }
  
    translationKey = TranslationKey.Definitions + ":" + this.props.value;
  
    if( this.props.i18n.exists( translationKey ) ) {
      result = this.props.t( translationKey );
      //log.traceOut( "definitionText()", translationKey, result );
      return result;
    }
    
    translationKey = this.props.value;
    if( this.props.i18n.exists( translationKey ) ) {
      result = this.props.t( translationKey );
      //log.traceOut( "definitionText()", translationKey, result );
      return result;
    }
  
    //log.traceOut( "definitionText()", translationKey, result );
    return null;

  }
}

const ModifiedDefinitionText = withTranslation()(DefinitionText);

export default ModifiedDefinitionText;


