

import { CollectionDatabase } from "../../impl/core/collectionDatabase";
import { DatabaseDocument } from "../databaseDocument";

import { DatabaseConverter } from "../databaseConverter";
import { FirestoreConverter } from "./firestoreConverter";
import { CollectionGroupDatabase } from "../../impl/core/collectionGroupDatabase";
import { Factory } from "../../../common/api/factory";
import { Archived, DocumentNameKey, IdSuffix, OwnerIds} from "../../api/core/databaseServiceIF";
import { DatabaseManager } from "../databaseManager";
import { log } from "../databaseService";

import { FirebaseService } from "../../../application/framework/firebase/firebaseService";
import { ChangesCollection} from "../../api/collections";
import { ReferenceHandle } from "../../api/core/referenceHandle";
import { ChangeTypeDefinition } from "../../api/definitions";
import { ChangeTypes } from "../../api/definitions/changeType";
import { Change } from "../../impl/documents/change";
import { Database } from "../../impl/core/database";
import { PropertyType, PropertyTypes } from "../../api/definitions/propertyType"; 
import { PropertiesSelector } from "../../api/core/propertiesSelector";
import { TenantProperty } from "../../impl/properties/tenantProperty";
import { User } from "../../impl/documents/user";


export abstract class FirestoreDatabaseManager extends DatabaseManager {

    async collectionGroup( collectionGroupDatabase : CollectionGroupDatabase<DatabaseDocument> ): Promise<Map<string,DatabaseDocument>> {
        
        log.traceIn( "("+collectionGroupDatabase.collectionName()+")", "collectionGroup()");

        try {

            if( !collectionGroupDatabase.userAccess().allowRead ) {
                throw new Error( "permissionDenied" );
            }

            let result = new Map<string,DatabaseDocument>();

            const documentSnapshots = (await this.databaseQuery( collectionGroupDatabase ).get())?.docs as any[];

            if( documentSnapshots != null ) {

                await Promise.all( documentSnapshots.map( async documentSnapshot => { 

                    try {
                        const documentData = documentSnapshot.data();

                        const documentPath = documentData.path;

                        if( documentPath == null ) {
                            log.warn( "Missing path in document: " + documentData );
                        }
                        else {

                            const databaseDocument = 
                                await Factory.get().databaseService.databaseFactory.documentFromData( 
                                    documentPath, documentData ) as DatabaseDocument;

                            if( databaseDocument != null ) {  

                                result.set( documentPath, databaseDocument! );
                            }
                        }
                    } catch (error) {
                        log.warn("Error reading document snapshot", error);
                    }               
                }));
            }

            log.traceOut( "("+collectionGroupDatabase.collectionName()+")", "collectionGroup()", result.size );
            return result; 

        } catch( error ) {

            log.warn( "Error reading collection group", error );

            throw new Error( (error as any).message );
        }
    }

    async groupReferenceHandles( collectionGroupDatabase : CollectionGroupDatabase<DatabaseDocument> ): Promise<Map<string,ReferenceHandle<DatabaseDocument>>> {
        
        log.traceIn( "("+collectionGroupDatabase.collectionName()+")", "referenceHandles()");

        try {
            if( !collectionGroupDatabase.userAccess().allowRead ) {
                throw new Error( "permissionDenied" );
            }

            let result = new Map<string,ReferenceHandle<DatabaseDocument>>();

            const documentSnapshots = (await this.databaseQuery( collectionGroupDatabase ).get())?.docs as any[];

            if( documentSnapshots != null ) {

                await Promise.all( documentSnapshots.map( async documentSnapshot => { 

                    try {
                        const documentData = documentSnapshot.data();

                        const documentPath = documentData.path;

                        if( documentPath == null ) {
                            log.warn( "Missing path in document: " + documentData );
                        }
                        else {

                            const databaseDocument = 
                                await Factory.get().databaseService.databaseFactory.documentFromData( 
                                    documentPath, documentData ) as DatabaseDocument;

                            if( databaseDocument != null ) {  
  
                                result.set( documentPath, databaseDocument!.referenceHandle() );
                            }
                        }
                    } catch (error) {
                        log.warn("Error reading document snapshot", error);
                    }               
                }));
            }

            log.traceOut( "("+collectionGroupDatabase.collectionName()+")", "referenceHandles()", result.size );
            return result; 

        } catch( error ) {

            log.warn( "Error reading reference handles", error );

            throw new Error( (error as any).message );
        }
    }
    
    async collection( collectionDatabase : CollectionDatabase<DatabaseDocument> ): Promise<Map<string,DatabaseDocument>> {
        
        log.traceIn( "("+collectionDatabase.collectionName()+")", "collection()");

        try {

            if( !collectionDatabase.userAccess().allowRead ) {
                throw new Error( "permissionDenied" );
            }

            let result = new Map<string,DatabaseDocument>();

            const collectionDatabasePath = collectionDatabase.databasePath();                    

            const documentSnapshots = (await this.databaseQuery( collectionDatabase ).get())?.docs as any[];

            if( documentSnapshots != null ) {

                await Promise.all( documentSnapshots.map( async documentSnapshot => { 

                    try {
                        const documentData = documentSnapshot.data();

                        const documentPath = collectionDatabasePath + "/" + documentSnapshot.id;

                        if( documentData.path == null ) {
                            log.warn( "Skipping document with no path with ID: " +  documentSnapshot.id );
                        }
                        else if( documentData.path !== documentPath ) {
                            log.warn( "Document stored with invalid path: " +  documentData.path  );
                        }

                        const databaseDocument = 
                            await Factory.get().databaseService.databaseFactory.documentFromData(
                                documentPath, documentSnapshot.data()) as DatabaseDocument;
    
                        if (databaseDocument == null) {
    
                            log.warn("Unable to read document from data: " + documentSnapshot.data());
    
                        } else {

                            result.set(databaseDocument.databasePath(), databaseDocument);
                        }
                    } catch (error) {
                        log.warn("Error reading document snapshot", error);
                    }               
                }));
            }

            log.traceOut( "("+collectionDatabase.collectionName()+")", "collection()", result.size );
            return result; 

        } catch( error ) {

            log.warn( "Error reading collection", error );

            throw new Error( (error as any).message );
        }
    }

    async referenceHandles( collectionDatabase : CollectionDatabase<DatabaseDocument> ): Promise<Map<string,ReferenceHandle<DatabaseDocument>>> {
        
        log.traceIn( "("+collectionDatabase.collectionName()+")", "referenceHandles()");

        try {

            if( !collectionDatabase.userAccess().allowRead ) {
                throw new Error( "permissionDenied" );
            }

            let result = new Map<string,ReferenceHandle<DatabaseDocument>>();

            const collectionDatabasePath = collectionDatabase.databasePath();                    

            const documentSnapshots = (await this.databaseQuery( collectionDatabase ).get())?.docs as any[];

            if( documentSnapshots != null ) {

                await Promise.all( documentSnapshots.map( async documentSnapshot => { 

                    try {
                        const documentData = documentSnapshot.data();

                        const documentPath = collectionDatabasePath + "/" + documentSnapshot.id;

                        if( documentData.path == null ) {
                            log.warn( "Skipping document with no path with ID: " +  documentSnapshot.id );
                        }
                        else if( documentData.path !== documentPath ) {
                            log.warn( "Document stored with invalid path: " +  documentData.path  );
                        }

                        const databaseDocument = 
                            await Factory.get().databaseService.databaseFactory.documentFromData(
                                documentPath, documentSnapshot.data()) as DatabaseDocument;
    
                        if (databaseDocument == null) {
    
                            log.warn("Unable to read document from data: " + documentSnapshot.data());
    
                        } else {

                            result.set(databaseDocument.databasePath(), databaseDocument.referenceHandle() );
                        }
                    } catch (error) {
                        log.warn("Error reading document snapshot", error);
                    }               
                })); 
            }

            log.traceOut( "("+collectionDatabase.collectionName()+")", "referenceHandles()", result.size );
            return result; 

        } catch( error ) {

            log.warn( "Error reading reference handles", error );

            throw new Error( (error as any).message );
        }
    }
 
    async createDocument( collectionDatabase : CollectionDatabase<DatabaseDocument>, databaseDocument : DatabaseDocument ): Promise<DatabaseDocument> {
        log.traceIn( "("+collectionDatabase.collectionName()+")", "createDocument()", databaseDocument.referenceHandle().title);

        try {
            if( !collectionDatabase.userAccess().allowCreate) {
                throw new Error( "permissionDenied" );
            }

            if( !databaseDocument.userAccess().allowCreate) {
                throw new Error( "permissionDenied" );
            }

            if( collectionDatabase.owner() != null ) {

                const ownerData = await this.documentReference( collectionDatabase.owner()! ).get();
   
                if( !ownerData.exists ) {
                    throw new Error( "Owner of collection does not exist: " + collectionDatabase.owner()!.databasePath() )
                }
            }

            if( databaseDocument.id.value() != null ) {
            
                const existingDocumentData = await this.documentReference( databaseDocument ).get();
    
                if( !!existingDocumentData.exists ) {
                    throw new Error( "Document already exists with ID: " + databaseDocument.id.value() )
                }
            }
            else {
                const documentId = await this.collectionReference( collectionDatabase ).doc().id;

                if( documentId == null ) {
                    throw new Error( "Error getting new document ID");
                }

                databaseDocument.id.setValue( documentId );
            }

            const now = new Date();

            if( databaseDocument.startDate.value() == null ) {
                databaseDocument.startDate.setValue( now );
                databaseDocument.startDate.clearChanges();
            }

            databaseDocument.lastChangedAt.setValue( now );

            const currentUser =  Factory.get().databaseService.currentUser();

            databaseDocument.lastChangedBy.setValue( currentUser?.referenceHandle() as ReferenceHandle<User> );

            databaseDocument.archived.setValue( false ); 
            
            let documentData = await databaseDocument.toData();

            //log.debug( "("+collectionDatabase.collectionName()+")", "documentData", documentData );

            await this.documentReference( databaseDocument ).set( documentData );

            delete documentData.path;  // Remove path before propagating

            log.traceOut( "("+collectionDatabase.collectionName()+")", "createDocument()", "resolved:", databaseDocument.referenceHandle().title);
            return databaseDocument ;

        } catch( error ) {

            log.warn( "Error creating document", error );

            throw new Error( (error as any).message );
        };
    }

    async readDocument( collectionDatabase : CollectionDatabase<DatabaseDocument>, databaseDocument: DatabaseDocument): Promise<boolean> {
        
        //log.traceIn( "readDocument()", databaseDocument.referenceHandle().title);

        try {
            if( !databaseDocument.userAccess().allowRead ) {
                throw new Error( "permissionDenied");
            }

            if( databaseDocument.id.value() == null  ) {
                throw new Error( "documentNotCreated" );
            }

            const firestoreDocument = await this.documentReference( databaseDocument ).get();
           
            if( firestoreDocument == null ) {
                log.warn( "readDocument()", "Document could not be read", databaseDocument.referenceHandle().title);
                return false;
            }

            if( !!firestoreDocument.exists ) {
                
                const documentId = firestoreDocument.id;

                const data = firestoreDocument.data();

                databaseDocument.fromData( data );

                databaseDocument.id.setValue( documentId );

                //log.traceOut( "readDocument()", "read:", databaseDocument.referenceHandle().title);
                return true;
            }
            else {
                log.warn( "readDocument()", "Doesn't exists", databaseDocument.referenceHandle().title);
                return false;
            }
        } catch( error ) {

            log.warn( "Error reading document", error );

            throw new Error( (error as any).message );
        }
    }

    async updateDocument( collectionDatabase : CollectionDatabase<DatabaseDocument>, 
        databaseDocument: DatabaseDocument,
        force? : boolean ): Promise<void> {
        
        log.traceIn( "("+collectionDatabase.collectionName()+")", "updateDocument(", databaseDocument.referenceHandle().path + ")", {force} );

        try {

            const userAccess = databaseDocument.userAccess();

            // log.debug( "("+collectionDatabase.collectionName()+")", "updateDocument()", {userAccess} );

            if( !userAccess.allowUpdate ) {
                throw new Error( "permissionDenied" );
            }
            
            if( databaseDocument.id.value() == null || databaseDocument.id.value()!.length === 0 ) {
                throw new Error( "documentNotCreated");
            }

            const existingDocumentData = await this.documentReference( databaseDocument ).get(); 

            if( !existingDocumentData.exists ) {
                throw new Error( "No document found with ID: " + databaseDocument.id.value() )
            }

            databaseDocument.lastChangedAt.setValue( new Date() );

            const currentUser = Factory.get().databaseService.currentUser();

            databaseDocument.lastChangedBy.setValue( currentUser?.referenceHandle() as ReferenceHandle<User> );

            databaseDocument.archived.setValue( false );
            
            let documentData = await databaseDocument.toData( force );

            //log.debug( "("+collectionDatabase.collectionName()+")", "documentData", documentData );

            await this.documentReference( databaseDocument ).set( documentData );

            log.traceOut( "("+collectionDatabase.collectionName()+")", "updateDocument()", databaseDocument.referenceHandle().path );

        } catch( error ) {

            log.warn( "Error updating document", collectionDatabase.collectionName(), error );
            
            throw new Error( (error as any).message );
        }
    }

    async deleteDocument( collectionDatabase : CollectionDatabase<DatabaseDocument>, databaseDocument : DatabaseDocument ): Promise<void> {
        
        log.traceIn( "("+collectionDatabase.collectionName()+")", "deleteDocument()", databaseDocument.referenceHandle().path );

        try {

            if( !collectionDatabase.userAccess().allowDelete) {
                throw new Error( "permissionDenied" );
            }

            if( !databaseDocument.userAccess().allowDelete) {
                throw new Error( "permissionDenied" );
            }

            if( databaseDocument.id.value() == null ) {
                throw new Error( "documentNotCreated"  );
            }

            await this.documentReference( databaseDocument ).delete();

            log.traceOut( "("+collectionDatabase.collectionName()+")", "deleteDocument()" );

        } catch( error ) {

            log.warn( "Error deleting document", collectionDatabase.collectionName(), error );

            throw new Error( (error as any).message );
        }
    }


    async archiveDocument( collectionDatabase : CollectionDatabase<DatabaseDocument>, databaseDocument : DatabaseDocument ): Promise<void> {
        
        log.traceIn( "("+collectionDatabase.collectionName()+")", "archiveDocument()", databaseDocument.referenceHandle().path );

        try {

            if( !collectionDatabase.userAccess().allowDelete) {
                throw new Error( "permissionDenied" );
            }

            if( !databaseDocument.userAccess().allowDelete) {
                throw new Error( "permissionDenied" );
            }

            if( databaseDocument.id.value() == null ) {
                throw new Error( "documentNotCreated"  );
            }

            const existingDocumentData = await this.documentReference( databaseDocument ).get();

            if( !existingDocumentData.exists ) {
                throw new Error( "No document found with ID: " + databaseDocument.id.value() )
            }

            // First store audit data in document (functions will pick them up)
            databaseDocument.lastChangedAt.setValue( new Date() );

            const currentUser = Factory.get().databaseService.currentUser();

            databaseDocument.lastChangedBy.setValue( currentUser?.referenceHandle() as ReferenceHandle<User> );

            databaseDocument.archived.setValue( true );

            databaseDocument.archivedAt.setValue( new Date() );

            let documentData = await databaseDocument.toData();

            //log.debug( "("+collectionDatabase.collectionName()+")", "documentData", documentData );

            await this.documentReference( databaseDocument ).set( documentData );

            log.traceOut( "("+collectionDatabase.collectionName()+")", "archiveDocument()" ); 

        } catch( error ) {

            log.warn( "Error archiving document", collectionDatabase.collectionName(), error );
            
            throw new Error( (error as any).message );
        }
    }


    async documentsWithProperties( 
        database : Database<DatabaseDocument>, 
        properties : Map<string,string> ) : Promise<Map<string,DatabaseDocument>> {

        log.traceIn( "documentsWithProperties()", database.databasePath(), {properties} );

        try {

            const result = new Map<string,DatabaseDocument>();

            let databaseQuery = this.databaseQuery( database );

            properties.forEach( (value, property ) => {

                databaseQuery = databaseQuery.where( 
                    property,
                    "==",
                    value
                );
            });
  
            let databaseDocument : DatabaseDocument | undefined;

            const documentSnapshots = (await databaseQuery.get())?.docs as any[];

            //log.debug( "documentsWithProperties()", {documentSnapshots});

            if( documentSnapshots != null ) {

                await Promise.all( documentSnapshots.map( async documentSnapshot => { 

                    const documentData = documentSnapshot.data();
    
                    databaseDocument = await Factory.get().databaseService.databaseFactory.documentFromData( 
                        documentData.path, documentData ) as DatabaseDocument;

                    result.set( documentData.path, databaseDocument);
    
                })); 
            }

            log.traceOut( "documentsWithProperties()", result.size );
            return result;


        } catch( error ) {

            log.warn( "documentsWithProperties()", "Error querying database", database.databasePath(), error );
            
            throw new Error( "Error querying database "+ database.databasePath() );
        }
    }

    async expiredArchivedDocuments() : Promise<Map<string,DatabaseDocument>> {

        log.traceIn( "expiredArchivedDocuments()" );

        try {

            const result = new Map<string,DatabaseDocument>();

            const firebaseService = Factory.get().applicationService as FirebaseService;

            let changesCollectionGroupQuery = firebaseService.database().collectionGroup( ChangesCollection );

            changesCollectionGroupQuery = changesCollectionGroupQuery.where( "endDate", "<", Date.now() )

            changesCollectionGroupQuery = changesCollectionGroupQuery.where( ChangeTypeDefinition, "==", ChangeTypes.Archived );
  
            const changeSnapshots = await changesCollectionGroupQuery.get().docs as any[];

            if( changeSnapshots != null ) {

                await Promise.all( changeSnapshots.map( async changeSnapshot => { 

                    const changeData = changeSnapshot.data();
    
                    const change = await Factory.get().databaseService.databaseFactory.documentFromData( 
                        changeData.path, changeData ) as Change;

                    if( change == null ) {
                        log.warn( "expiredArchivedDocuments()", "Could not read change at path: " + changeData.path );
                    }
                    else {

                        const archivedDocument = await change.changedDocument.document();

                        if( archivedDocument == null ) {
                            log.warn( "expiredArchivedDocuments()", "Could not read archived document for change: " + changeData.path );
                        }
                        else if( !archivedDocument.archived.value() ) {
                            log.warn( "expiredArchivedDocuments()", "Document is no longer archived, avoid later requeries: " + changeData.path );

                            change.endDate.setValue( undefined ); // Prevenst subsequent query matches
                            
                            await change.update(); 
                        }
                        else {
                            result.set( archivedDocument.referenceHandle().path, archivedDocument );
                        }
                    }
                }));
            }

            log.traceOut( "expiredArchivedDocuments()", result.size );
            return result;


        } catch( error ) {

            log.warn( "expiredArchivedDocuments()", "Error querying archived documents", error );
            
            throw new Error( "Error querying archived documents: " + (error as any).message );
        }
    }

    protected databaseQuery( database : Database<DatabaseDocument> ) : any {

        //log.traceIn( "databaseQuery()", database.databasePath(), database.owner()?.databasePath() );

        try {
            let databaseQuery = database instanceof CollectionGroupDatabase ?
                this.collectionGroupReference( database as CollectionGroupDatabase<DatabaseDocument> ) : 
                this.collectionReference( database as CollectionDatabase<DatabaseDocument> );

            databaseQuery = databaseQuery.where(
                Archived,
                "==",
                false );

            if( database.queryDocumentName() != null && database.documentNames().length > 0 ) {
                databaseQuery = databaseQuery.where( 
                        DocumentNameKey,
                        "in",
                        database.documentNames() );
            }

            
            const referenceDocument = database.newDocument()!;
            
            //log.debug( "databaseQuery()", "read reference document", referenceDocument.databasePath( true ) );

            
            const ownerId = referenceDocument.ownerDocumentId();

            if( ownerId != null && ownerId.length > 0 ) {

                databaseQuery = databaseQuery.where( 
                    OwnerIds,
                    "array-contains",
                    ownerId
                ); 

                //log.debug( "databaseQuery()", "added", database.collectionName(), OwnerIds, "array-contains", ownerId );

            } 
            
            const tenantProperties = referenceDocument.properties( {
                includePropertyTypes: [PropertyTypes.Tenant as PropertyType]
            } as PropertiesSelector ) as Map<string,TenantProperty<DatabaseDocument>>;

            //log.debug( "databaseQuery()", "read parent properties", tenantProperties.size );

            if( tenantProperties.values() != null ) {

                for( const tenantProperty of tenantProperties.values() ) {

                    const tenantIdKey = tenantProperty.key() + IdSuffix;

                    const tenantId = tenantProperty.id();

                    if( tenantId != null && tenantId.length > 0 ) {

                        databaseQuery = databaseQuery.where( 
                            tenantIdKey,
                            "==",
                            tenantId
                        ); 

                        //log.debug( "databaseQuery()", "added", tenantIdKey, "==", tenantId );
                    }
                }
            }

            //log.traceOut( "databaseQuery()");
            return databaseQuery;

        } catch( error ) {

            log.warn( "databaseQuery()", "Error building database query", error );
            
            throw new Error( (error as any).message );
        }
    }

    collectionGroupReference( collectionGroupDatabase : CollectionGroupDatabase<DatabaseDocument> ) : any {

        const firebaseService = Factory.get().applicationService as FirebaseService;

        const collectionGroupReference = 
            firebaseService.database().collectionGroup( collectionGroupDatabase.collectionName());

        return collectionGroupReference;
    }


    collectionReference( collectionDatabase : CollectionDatabase<DatabaseDocument> ) : any {

        const firebaseService = Factory.get().applicationService as FirebaseService;

        const collectionReference = firebaseService.database().collection( collectionDatabase.databasePath() );                

        return collectionReference;
    }

    documentReference( databaseDocument : DatabaseDocument ) : any {

        //log.traceIn( "documentReference()", {databaseDocument});

        if( databaseDocument.isNew() ) {
            throw new Error( "documentNotCreated" );
        }

        const firebaseService = Factory.get().applicationService as FirebaseService;

        const documentReference = firebaseService.database().doc( databaseDocument.databasePath() ); 

        //log.traceOut( "documentReference()", {documentReference});

        return documentReference; 
    }

    readonly converter : DatabaseConverter = new FirestoreConverter();

 }