import React from 'react';

import BusinessIcon from '@material-ui/icons/Business';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import HealingIcon from '@material-ui/icons/Healing';
import PersonIcon from '@material-ui/icons/Person';
import GroupIcon from '@material-ui/icons/Group';
import AssessmentIcon from '@material-ui/icons/Assessment';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ColorizeIcon from '@material-ui/icons/Colorize';
import PolicyIcon from '@material-ui/icons/Policy';
import GrainIcon from '@material-ui/icons/Grain';
import ExposureIcon from '@material-ui/icons/Exposure';
import HotelIcon from '@material-ui/icons/Hotel';
import PaymentIcon from '@material-ui/icons/Payment';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import ChangeHistoryIcon from '@material-ui/icons/ChangeHistory';
import CategoryIcon from '@material-ui/icons/Category';
import PostAddIcon from '@material-ui/icons/PostAdd';
import BeenhereIcon from '@mui/icons-material/Beenhere';
import ReportIcon from '@mui/icons-material/Report';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import CoronavirusIcon from '@mui/icons-material/Coronavirus';
import DevicesIcon from '@mui/icons-material/Devices';
import MessageIcon from '@mui/icons-material/Message';
import KeyIcon from '@mui/icons-material/Key';


import { AlertsCollection, CategoriesCollection, ChangesCollection, CompaniesCollection, UnitsCollection, LocationsCollection, ResidencesCollection, UsersCollection, SubscriptionsCollection, ProjectsCollection, DevicesCollection, TerminalsCollection, MessagesCollection, KeysCollection } from '../../services/database/api/collections';
import { RegistrationsCollection, QuarantineRegistrationsCollection, SickLeaveRegistrationsCollection, SymptomRegistrationsCollection, TestRegistrationsCollection, IncidentRegistrationsCollection, VaccineRegistrationsCollection} from '../../healthguard/api/registrationCollections';
import { HazardsCollection, GatheringsCollection, MeasuresCollection, ConsentsCollection, RisksCollection } from '../../healthguard/api/healthguardCollections';



interface CollectionIconProps {
  collectionName : string,
  adjustSize?: boolean
}


interface CollectionIconState { 
}

class CollectionIcon extends React.PureComponent<CollectionIconProps,CollectionIconState> {

  constructor( props: CollectionIconProps ) {
    
    super(props);

    this.state = { 
    } as CollectionIconState;

    //log.traceInOut( "constructor()" );
  }

  protected collectionIcon() : JSX.Element {

    //log.traceIn( "collectionIcon()", this.props.collectionName );

    const fontSize = !!this.props.adjustSize ? "inherit" : undefined;

    switch( this.props.collectionName ) {
 
      case CategoriesCollection:
        return( <CategoryIcon fontSize={fontSize} /> );

      case ChangesCollection:
        return( <ChangeHistoryIcon fontSize={fontSize} /> );
  
      case CompaniesCollection:
        return( <BusinessIcon fontSize={fontSize} /> );
          
      case ConsentsCollection:
        return( <BeenhereIcon fontSize={fontSize} /> );

      case AlertsCollection: 
        return( <CoronavirusIcon fontSize={fontSize} /> );

      case DevicesCollection: 
        return( <DeviceHubIcon fontSize={fontSize} /> );

      case HazardsCollection:
        return( <LocalHospitalIcon fontSize={fontSize} /> );

      case GatheringsCollection:
        return( <GrainIcon fontSize={fontSize} /> );
                
      case RisksCollection:
        return( <ReportIcon fontSize={fontSize} /> );        

      case UnitsCollection:
        return( <AccountTreeIcon fontSize={fontSize} /> );
     
      case KeysCollection:
        return( <KeyIcon fontSize={fontSize} /> );

      case LocationsCollection:
        return( <LocationOnIcon fontSize={fontSize} /> );

      case MeasuresCollection:
        return( <PolicyIcon fontSize={fontSize} /> );

      case MessagesCollection:
        return( <MessageIcon fontSize={fontSize} /> );       

      case ProjectsCollection:
        return( <GroupIcon fontSize={fontSize} /> );

      case RegistrationsCollection:
        return( <AssessmentIcon fontSize={fontSize} /> );

      case ResidencesCollection:
        return( <HomeWorkIcon fontSize={fontSize} /> );

      case QuarantineRegistrationsCollection:
        return( <AccountBalanceIcon fontSize={fontSize} /> );
  
      case SickLeaveRegistrationsCollection:
        return( <HotelIcon fontSize={fontSize} /> );
    
      case SubscriptionsCollection:
        return( <PaymentIcon fontSize={fontSize} /> );
    
      case SymptomRegistrationsCollection:
        return( <HealingIcon fontSize={fontSize} /> );
 
      case TerminalsCollection:
        return( <DevicesIcon fontSize={fontSize} /> );       

      case TestRegistrationsCollection:
        return( <ExposureIcon fontSize={fontSize} /> );
      
      case IncidentRegistrationsCollection:
        return( <PostAddIcon fontSize={fontSize} /> );
          
      case UsersCollection:
        return( <PersonIcon fontSize={fontSize} /> );

      case VaccineRegistrationsCollection:
        return( <ColorizeIcon fontSize={fontSize} /> );
      
      default:
        throw new Error( "Collection not recognized: " + this.props.collectionName );
    }
  }
  
  render() {

    //log.traceInOut( "render()" );

    return(
      <React.Fragment>
      { this.collectionIcon() }
      </React.Fragment>
    );
  }
}


export default CollectionIcon;

