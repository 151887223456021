import React from 'react';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import { log } from "ui/app/app";

import { withTranslation, WithTranslation } from 'react-i18next';
import { createStyles, Dialog, DialogContent, Theme, WithStyles, withStyles } from '@material-ui/core';

import { DatabaseDocumentIF } from '../../services/database/api/core/databaseDocumentIF';
import { documentView } from './documentRouter';
import { DocumentProps } from './documentView';

export const ButtonKeyOK = "ok";
export const ButtonKeyCancel = "cancel";


const styles = (theme: Theme) => createStyles({
  content: {
    width: "100%",
    height: "90vh",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(0), 
  },
  grid: {
    flexWrap: "nowrap", 
  },
  buttons: {
    display: "flex",
    justifyContent: 'flex-end',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(0)
  }
});

export const documentDialog = ( databaseDocument : DatabaseDocumentIF ): Promise<void> => {

  return new Promise<void>((resolve, reject) => {

    log.traceIn("documentDialog()"); 

    try {

      confirmAlert({
        customUI: ({ onClose }) => {

          const onComplete = () => {

            log.traceIn("onComplete()");

            onClose();

            log.traceOut("onComplete()");
            resolve();
          }

          return (
            <ModifiedDocumentDialog
              databaseDocument={databaseDocument}
              onComplete={onComplete}
              >
            </ModifiedDocumentDialog>
          );
        }
      });

    } catch (error) {

      log.warn("confirm()", error);

      reject();
    }
  });
}


interface DocumentDialogProps extends WithStyles<typeof styles>, WithTranslation {

  databaseDocument: DatabaseDocumentIF,

  onComplete: () => void
}


interface DocumentDialogState {

  open: boolean,

  complete: boolean
}

class DocumentDialog extends React.PureComponent<DocumentDialogProps, DocumentDialogState> {

  constructor(props: DocumentDialogProps) {

    super(props);

    this.state = {

      open: false,

      complete: false,
    } as DocumentDialogState;

    //log.traceInOut( "constructor()" );
  }


  async componentDidMount() {

    log.traceIn("componentDidMount()");

    this.setState({ 

      open: true

    });

    log.traceIn("componentDidMount()");
  }


  render() {

    //log.traceInOut("render()");
 
    const { classes } = this.props; 

    const handleComplete = async ( databaseDocument? : DatabaseDocumentIF ) => {

      log.traceInOut("handleComplete()");

      this.setState({ open: false });

      this.props.onComplete();
    }

    return (
      <>
        <Dialog
          open={this.state.open}
          onClose={() => handleComplete()}
          maxWidth="lg" 
          fullWidth={true}
        >
          <DialogContent className={classes.content}> 
            {documentView( {
              databaseDocument: this.props.databaseDocument,
              onComplete: handleComplete,
              
            } as DocumentProps )}  
          </DialogContent>
        </Dialog>
      </>
    );
  }
}

const ModifiedDocumentDialog = withTranslation()(withStyles(styles)(DocumentDialog));

export default ModifiedDocumentDialog;

