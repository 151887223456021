
import { Factory } from '../../../common/api/factory';
import { AuthenticationService, log } from '../../framework/authenticationService';
import { User } from '../../../database/impl/documents/user';

import { AuthenticationClaims, EmptyVerifyPhoneMarker } from '../../api/authenticationClaims';
import { PhoneNumber } from '../../../database/api/core/phoneNumber';
 
import applicationConfiguration from "../../../../healthguard/data/settings/application.json";

import authenticationConfiguration from "../../../../healthguard/data/settings/authentication.json";

export class FirebaseAuthenticationService extends AuthenticationService {

    constructor( authenticator : any ) {

        super(); 

        //log.traceInOut("constructor()")

        try {

            this._authenticator = authenticator;

        } catch (error) {

            log.warn("constructor()", "Error starting firebase auth", error);

            throw new Error( (error as any).message );
        }
    }


    async init() : Promise<void> {

        log.traceIn("init()")

        try {

            await super.init();

            if( Factory.get().isClient() ) {

                const persistence = Factory.get().configurationService.config( 
                    authenticationConfiguration, "persistence" )

                await this._authenticator.setPersistence( persistence );

                const initialFirebaseUser = this._authenticator.currentUser;

                if( initialFirebaseUser != null ) {

                    await initialFirebaseUser.reload();

                    const initialIdTokenResult = await initialFirebaseUser.getIdTokenResult();

                    if( initialIdTokenResult != null ) {
                            
                        await this.updateAuthenticationData( initialFirebaseUser, initialIdTokenResult.claims );
                    }
                }
                
                
                this._authenticator.onIdTokenChanged( async (firebaseUser: any ) => {

                    log.traceIn("onIdTokenChanged()")

                    try {

                        this.initialized = true;

                        if( firebaseUser == null ) {

                            this._waitingForClaims = false;
                            this._waitingForVerification = false;

                            await this.clearAuthentication( false );

                            log.traceIn("onIdTokenChanged()", "no firebase user");
                            return;
                        }

                        if( this._waitingForClaims ) {
                            log.traceOut("onIdTokenChanged()", "Waiting for claims")
                            return;
                        }

                        if( this._waitingForVerification ) {
                            log.traceOut("onIdTokenChanged()", "Waiting for email verification")
                            return;
                        }

                        let authenticationClaims;

                        if( firebaseUser != null )  {

                            this._waitingForClaims = true;

                            authenticationClaims = await this.waitForClaims();

                            this._waitingForClaims = false;
                        }

                        if( this._authenticator.currentUser == null ) {

                            await this.clearAuthentication( false, "signinError" );

                            await this.signOut();

                            log.traceOut("onIdTokenChanged()", "No longer valid current user")
                            return;
                        }

                        if( authenticationClaims == null ) {

                            await this.clearAuthentication( false, "unknownUser");

                            await this.signOut( authenticationConfiguration.timeouts.firebaseSignoutDelay as number );

                            log.traceOut("onIdTokenChanged()", "No ID token")
                            return;
                        }
                            
                        await this.updateAuthenticationData( firebaseUser, authenticationClaims ); 

                        log.traceOut("onIdTokenChanged()")

                    } catch (error) {

                        log.warn("onIdTokenChanged()", "Error initializing firebase auth", error);

                        await this.clearAuthentication( false, "unknownUser" );

                    }
                }); 
            }
            log.traceOut("init()")

        } catch (error) {

            log.warn("init()", "Error starting firebase auth", error);

            throw new Error( (error as any).message );
        }
    }

    async registerEmailUser( email : string, password : string ): Promise<any> {

        log.traceIn("registerUser()", {email});

        try {
            if( this._authenticator?.currentUser != null ) {
                throw new Error( "User already signed in")
            }

            const credentials = await this._authenticator.createUserWithEmailAndPassword( email, password );

            if( credentials == null || credentials.user == null ) {
                throw new Error( "loginFailed")
            }

            log.traceOut("registerUser()" );
            return credentials.user;

        } catch (error) {

            log.warn("registerUser()", "Error registering email user with firebase", error);

            throw new Error((error as any).message);
        }    
    }

    async signInEmailUser( email : string, password : string ): Promise<any> {

        log.traceIn("signInEmailUser()", {email});

        try {
            if( this._authenticator?.currentUser != null ) {
                throw new Error( "User already signed in")
            }

            const credentials = await this._authenticator.signInWithEmailAndPassword( email, password );

            if( credentials == null || credentials.user == null ) {
                throw new Error( "loginFailed")
            }

            log.traceOut("signInEmailUser()" );
            return credentials.user;

        } catch (error) {

            log.warn("signInEmailUser()", "Error signing in email user with firebase", error);

            throw new Error((error as any).message);
        }    
    }

    async signIn( credentials : any ): Promise<any> {

        throw new Error( "Sign in is handled by Firebase");
    }


    async signOut( delay? : number ): Promise<void> {

        log.traceIn("signOut()" )

        try {

            if( this._authenticator == null || this._authenticator.currentUser == null ) {

                log.traceOut("signOut()", "No authenticated user to sign out" );
                return;
            }

            if( !!delay ) {

                setTimeout( this.signOut, delay );
            }
            else {
                await this._authenticator.signOut();
            }

            log.traceOut("signOut()" )

        } catch (error) {

            log.warn("signOut()", "Error logging out from firebase auth", error);

            throw new Error( (error as any).message );
        }
    }

    async verifyEmail(): Promise<void> {

        log.traceIn("verifyEmail()" )

        try {

            let firebaseUser = this._authenticator.currentUser;

            if( firebaseUser == null ) {

                log.traceOut("signOut()", "No authenticated user to verify" );
                return;
            }

            const continueUrl = Factory.get().configurationService.config( 
                applicationConfiguration, "appUrl"
            )

            await firebaseUser.sendEmailVerification( { url: continueUrl });

            this._waitingForVerification = true;

            const emailVerification = true;

            const notPhoneVerification = false;

            const authenticationClaims = await this.waitForVerification( emailVerification, notPhoneVerification );

            this._waitingForVerification = false;
 
            firebaseUser = this._authenticator.currentUser;

            if( firebaseUser == null || authenticationClaims == null ) {

                await this.clearAuthentication( false );

                await this.signOut();

                log.traceOut("verifyEmail()", "No longer logged in user")
                return;
            }
                
            await this.updateAuthenticationData( firebaseUser, authenticationClaims ); 

            log.traceOut("verifyEmail()" )

        } catch (error) {

            log.warn("verifyEmail()", "Error verifying email", error);

            throw new Error( (error as any).message );
        }
    }

    async verifyPhone( credentials? : any ): Promise<void> {

        log.traceIn("verifyPhone()", {credentials} )

        try {

            let firebaseUser = this._authenticator.currentUser;

            if( firebaseUser == null ) {

                throw new Error( "No authenticated user to verify" );
            }

            if( credentials == null ) {
                throw new Error( "No credentials" );
            }

            await firebaseUser.updatePhoneNumber( credentials ); 

            this._waitingForVerification = true;

            const notEmailVerification = false;

            const phoneVerification = false;

            const authenticationClaims = await this.waitForVerification( notEmailVerification, phoneVerification );

            this._waitingForVerification = false;
 
            firebaseUser = this._authenticator.currentUser;

            if( firebaseUser == null || authenticationClaims == null ) {

                await this.clearAuthentication( false );

                await this.signOut();

                log.traceOut("verifyEmail()", "No longer logged in user")
                return;
            }
                
            await this.updateAuthenticationData( firebaseUser, authenticationClaims ); 

            log.traceOut("verifyPhone()" )

        } catch (error) {

            log.warn("verifyPhone()", "Error verifying phone", error);

            throw new Error( (error as any).message );
        }
    }

    async checkAuthentication( currentUser : User ) : Promise<any | undefined> {

        log.traceIn("checkAuthentication()", currentUser );

        try {
            const firebaseUser = this._authenticator.currentUser;

            if( firebaseUser == null ) {

                await super.clearAuthentication();

                await this._authenticator.signOut();

                log.traceOut("checkAuthentication()", "No firebase user")
                return undefined;
            }

            if( firebaseUser.uid !== currentUser.authenticationId.value() ) {

                await super.clearAuthentication();

                await this._authenticator.signOut();

                log.traceOut("checkAuthentication()", "mismatching authentication IDs")
                return undefined;
            }

            await firebaseUser.reload();

            await firebaseUser.getIdToken( true );

            const idTokenResult = await firebaseUser.getIdTokenResult();

            if( idTokenResult == null || idTokenResult.claims == null ) {

                await super.clearAuthentication();

                await this._authenticator.signOut();

                log.traceOut("checkAuthentication()", "No claims" );
                return undefined;
            }

            log.traceOut("checkAuthentication()", "OK", idTokenResult.claims );
            return idTokenResult.claims;

        } catch (error ) {
            log.warn("checkAuthentication()", "Error reading firebase auth info", error);

            await super.clearAuthentication( false, "signinError" );

            await this._authenticator.signOut();

            log.traceOut("checkAuthentication()", "error" ); 
            return undefined;
        }
    }


    private async updateAuthenticationData( firebaseUser : any, authenticationClaims : AuthenticationClaims ): Promise<void> {

        log.traceIn("updateAuthenticationData()", {firebaseUser}, {authenticationClaims} );

        try {
            
            if( firebaseUser == null ) {

                await super.clearAuthentication();
                
                log.traceOut("updateAuthenticationData()", "Logout")
                return;
            }

            const updatedAuthenticationClaims = this.updateClaims( firebaseUser, authenticationClaims );

            log.debug("updateAuthenticationData()", {updatedAuthenticationClaims} );

            if( updatedAuthenticationClaims.verifyEmail != null || 
                updatedAuthenticationClaims.verifyPhone != null ) {

                await this.updateAuthentication( undefined, updatedAuthenticationClaims );
        
                log.traceOut("updateAuthenticationData()", "email or phone verification required");
                return;
            }

            log.debug("readCurrentUser()", "current status", this.authenticatedUser()?.databasePath() )

            let authenticatedUser = this.authenticatedUser();

            if( authenticatedUser == null ||
                !Factory.get().databaseService.databaseFactory.equalDatabasePaths( authenticationClaims.userPath, authenticatedUser.databasePath() ) ) {

                await this.updateAuthentication( undefined, updatedAuthenticationClaims, true );

                authenticatedUser = await this.readCurrentUser( firebaseUser, updatedAuthenticationClaims );
            }

            if( authenticatedUser == null ) {

                await this.clearAuthentication( false, "unknownUser");
                
                await this.signOut();

                log.traceOut("updateAuthenticationData()", "Firebase user not found in DB")
                return;
            }

            log.debug("updateAuthenticationData()", {authenticatedUser} );

            await this.updateAuthentication( authenticatedUser, updatedAuthenticationClaims );

            log.traceOut("updateAuthenticationData()");

        } catch (error ) {
            log.warn("updateAuthenticationData()", "Error reading firebase auth info", error);

            await super.clearAuthentication( false, "signinError" );

            log.traceOut("updateAuthenticationData()", "error" );
        }
    }


    private async readCurrentUser( firebaseUser : any, authenticationClaims : AuthenticationClaims): Promise<User | undefined> {

        log.traceIn("readCurrentUser()", {authenticationClaims} )

        try {   

            if( authenticationClaims.userPath == null ) {

                log.traceOut("readCurrentUser()", "Path not found" );
                return undefined;
            }

            const user = await Factory.get().databaseService.databaseFactory.documentFromUrl( 
                authenticationClaims.userPath ) as User;

            if( user == null ) {

                throw new Error( "notFound" );
            }

            log.debug("readCurrentUser()", {authenticationClaims}, {user} )

            if( authenticationClaims.userId !== user.id.value() ) {
                throw new Error( "Mismatching user ID" );
            }

            const unitIds = user.unit.ids();

            if( JSON.stringify( authenticationClaims.unitIds != null ? authenticationClaims.unitIds : [] ) !== 
                JSON.stringify( unitIds != null ? unitIds : []) ) {

                log.warn("readCurrentUser()", JSON.stringify( authenticationClaims.unitIds ), JSON.stringify( unitIds ) )

                throw new Error( "Mismatching unit IDs" );
            }

            const companyId = user.company.id();
            if( authenticationClaims.companyId !== companyId ) {
                throw new Error( "Mismatching company ID" );
            } 

            if( firebaseUser.email == null || firebaseUser.email.toLowerCase() !== user.email.value()!.toLowerCase() ) {
                throw new Error( "Email mismatch between firebase user and database user")
            }

            if( firebaseUser.phoneNumber != null ) {

                if( user.phoneNumber.compareValue( firebaseUser.phoneNumber ) !== 0 ) {

                    user.phoneNumber.setValue( firebaseUser.phoneNumber );

                    await user.update();
                }
            }
        
            log.traceOut("readCurrentUser()", user );
            return user;

        } catch (error) {
            log.warn("readCurrentUser()", "Error reading current user", error);

            throw new Error("Error reading current user: " + error);
        }
    }

    async refreshAuthenticationClaims() : Promise<AuthenticationClaims | undefined> {

        log.traceIn("refreshAuthenticationClaims()" );

        try {

            this._waitingForClaims = true;

            const authenticationClaims = await this.waitForClaims();

            this._waitingForClaims = false;

            const firebaseUser = this._authenticator.currentUser;

            await this.updateAuthenticationData( firebaseUser, authenticationClaims ); 

            log.traceOut("refreshAuthenticationClaims()", {authenticationClaims} );
            return authenticationClaims;

        } catch (error) {
            log.warn("readCurrentUser()", "Error reading current user", error);

            throw new Error("Error reading current user: " + error);
        }
    }

    private async waitForClaims( key? : string, value? : string | number | boolean ): Promise<any | undefined> {

        log.traceIn("waitForClaims()" );

        try {
            const claims = await new Promise<any | undefined>( resolve => {

                const startTime = new Date().getTime();
                
                const waitPeriod = Factory.get().configurationService.config( 
                    authenticationConfiguration.timeouts, "firebaseAuthenticationWaitPeriod" ) as number;

                const waitInterval = Factory.get().configurationService.config( 
                    authenticationConfiguration.timeouts, "firebaseAuthenticationWaitInterval" ) as number;
                
                const maxTime = startTime + waitPeriod;

                const checkClaims = async () => {

                    log.traceIn("checkClaims()" );

                    try {
                        if( !this._waitingForClaims ) {
                            resolve( undefined );
                            log.traceOut("checkClaims()", "Not waiting for claims" );
                            return;
                        }

                        const currentTime = new Date().getTime();

                        const firebaseUser = this._authenticator.currentUser;

                        if( firebaseUser == null ) {
                            log.traceOut("checkClaims()", "No firebase user" );
                            resolve( undefined );
                            return;
                        }

                        await firebaseUser.reload();

                        await firebaseUser.getIdToken( true );

                        const idTokenResult = await firebaseUser.getIdTokenResult();

                        if( idTokenResult == null ) {

                            resolve( undefined );

                            log.traceOut("checkClaims()", "No ID token" );
                            return;
                        }
                        
                        const authenticationClaims = idTokenResult.claims;

                        if( authenticationClaims == null ) {

                            resolve( undefined );

                            log.traceOut("checkClaims()", "No claims" );
                            return;
                        }
                        
                        if (authenticationClaims.authenticationId == null ||
                            (key != null && authenticationClaims[key] == null) ||
                            (key != null && value != null && authenticationClaims[key] !== value)) {

                            if( currentTime < maxTime ) {

                                log.traceOut("checkClaims()", "Still no custom claims data, set new timeout", startTime, currentTime, maxTime );
                                setTimeout( checkClaims, waitInterval );
                            }
                            else {
                                log.traceOut("checkClaims()", "No custom claims data after timeout" );
                                resolve( undefined );
                            }
                        }
                        else {

                            const result = key != null ? authenticationClaims[key] : authenticationClaims;
    
                            log.traceOut("checkClaims()", "Custom claims found", result );
                            resolve( result );
                        }

                    } catch( error ) {
                        log.warn( "checkClaims()", "Error checking claims", error );
                        resolve( undefined );
                        return;
                    }
                }
                checkClaims();
            });

            let authenticationClaims : AuthenticationClaims | undefined;

            const firebaseUser = this._authenticator.currentUser;

            if( claims != null ) {
                authenticationClaims = this.updateClaims( firebaseUser, claims as AuthenticationClaims );
            }

            log.traceOut("waitForClaims()", {authenticationClaims} );
            return authenticationClaims;

        } catch (error) {
            log.warn("waitForClaims()", "Error checking custom claims", error);

            return undefined;
        }
    }

    private updateClaims( firebaseUser : any, authenticationClaims : AuthenticationClaims ) : AuthenticationClaims {

        log.traceIn("updateClaims()", {authenticationClaims} );

        try {
            const updatedAuthenticationClaims = {} as AuthenticationClaims;

            Object.assign( updatedAuthenticationClaims, authenticationClaims );


            if( authenticationClaims.verifyEmail == null || firebaseUser.emailVerified ) {
                delete updatedAuthenticationClaims.verifyEmail;
            }

            if( authenticationClaims.verifyPhone == null 
                || 
                (firebaseUser.phoneNumber != null && authenticationClaims.verifyPhone === EmptyVerifyPhoneMarker)
                ||
                PhoneNumber.isValidPhoneNumber( firebaseUser.phoneNumber, true ) ) {

                delete updatedAuthenticationClaims.verifyPhone;
            }

            log.traceOut("updateClaims()", updatedAuthenticationClaims );
            return updatedAuthenticationClaims;

        } catch (error) {
            log.warn("updateClaims()", "Error checking custom claims", error);

            throw new Error( (error as any).message );
        }
    }

    private async waitForVerification( waitForEmail : boolean, waitForPhone : boolean ): Promise<AuthenticationClaims | undefined> {

        log.traceIn("waitForVerification()" );

        try {
            const authenticationClaims = await new Promise<AuthenticationClaims | undefined>( resolve => {

                const startTime = new Date().getTime();

                const waitPeriod = Factory.get().configurationService.config( 
                    authenticationConfiguration.timeouts, "firebaseVerificationWaitPeriod" ) as number;

                const waitInterval = Factory.get().configurationService.config( 
                    authenticationConfiguration.timeouts, "firebaseVerificationWaitInterval" ) as number;

                const maxTime = startTime + waitPeriod;

                const checkVerified = async () => {

                    log.traceIn("checkVerified()" );

                    if( !this._waitingForVerification ) {
                        resolve( undefined );
                        return;
                    }

                    const currentTime = new Date().getTime();

                    const firebaseUser = this._authenticator.currentUser;

                    if( firebaseUser == null ) {
                        log.traceOut("checkVerified()", "No firebase user" );
                        resolve( undefined );
                        return;
                    }

                    await firebaseUser.reload();

                    await firebaseUser.getIdToken( true );

                    const idTokenResult = await firebaseUser.getIdTokenResult();

                    if( idTokenResult == null || idTokenResult.claims == null ) {

                        log.traceOut("checkVerified()", "No ID token" );
                        resolve( undefined );
                    }
                    else {
                        const claims = idTokenResult.claims;

                        log.debug("checkVerified()", {claims} );

                        if( (waitForEmail && claims.verifyEmail != null && !firebaseUser.emailVerified) 
                            || 
                            (waitForPhone && claims.verifyPhone != null && !firebaseUser.phoneVerified) 
                            ) {

                            if( currentTime < maxTime ) {

                                log.traceOut("checkVerified()", "verfication still required, set new timeout", startTime, currentTime, maxTime );
                                setTimeout( checkVerified, waitInterval );
                            }
                            else {
                                log.traceOut("checkVerified()", "No verification update after timeout" );
                                resolve( undefined );
                            }
                        }
                        else {
                            log.traceOut("checkVerified()", "Verification complete");
                            resolve( idTokenResult.claims );
                        }
                }
                }
                checkVerified();
            });

            log.traceOut("waitForVerification()", {authenticationClaims} );
            return authenticationClaims;

        } catch (error) {
            log.warn("waitForVerification()", "Error checking email verification claims", error);

            return undefined;
        }
    }

    initialized : boolean = false;

    private _authenticator : any;

    private _waitingForClaims = false;

    private _waitingForVerification = false;

}