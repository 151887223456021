
export const MeasureOrigins = {

    Public :      "public",

    Company :        "company" 

}

export type MeasureOrigin = keyof (typeof MeasureOrigins);


