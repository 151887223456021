import React from 'react';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import { log } from "ui/app/app";

import { withTranslation, WithTranslation } from 'react-i18next';
import { TranslationKey } from '../../services/common/api/translatorIF';
import { Button, createStyles, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Theme, WithStyles, withStyles } from '@material-ui/core';
import { TFunction } from 'i18next';
import { propertyInputVariant } from './propertyValue';
 
export const ButtonKeyOK = "ok";
export const ButtonKeyCancel = "cancel";

const styles = (theme: Theme) => createStyles({
    content: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(0),
        paddingLeft: theme.spacing(6),
        paddingRight: theme.spacing(6)
    },  
    buttons: {
      display: "flex", 
      justifyContent:'flex-end', 
      paddingRight: theme.spacing(6)
    }
  });

export const textInputDialog = ( 
    t : TFunction, 
    i18n : any, 
    initialValue? : string,
    messageKey? : string, 
    titleKey? : string,
    okKey? : string,
    cancelKey? : string ) : Promise<string | undefined> => {

    return new Promise<string | undefined>( (resolve, reject) => {

        log.traceIn( "confirm()", messageKey, titleKey );

        try {

            confirmAlert({
                customUI: ({ onClose }) => {

                    const onComplete = ( value? : string ) => {

                        log.traceInOut( "onComplete()", value );

                        onClose();

                        resolve( value );
                    }

                    return (
                        <ModifiedInputDialog
                            initialValue={initialValue}
                            titleKey={titleKey}
                            messageKey={messageKey}
                            okKey={okKey}
                            cancelKey={cancelKey}
                            onComplete={onComplete}>
                        </ModifiedInputDialog>
                    );
                }
            });          

        } catch( error ) {

            log.warn( "onComplete()", error );

            reject();
        }
    });
}



interface InputDialogProps extends WithStyles<typeof styles>, WithTranslation {
    initialValue?: string,
    messageKey?: string,
    titleKey?: string,
    okKey?: string,
    cancelKey?: string,
    onComplete: (input?: any) => void
}


interface InputDialogState { 

    open : boolean,

    value?: string,

    okKey: string,

    cancelKey: string
}

class InputDialog extends React.PureComponent<InputDialogProps,InputDialogState> {

  constructor( props: InputDialogProps ) {
    
    super(props);

    this.state = { 

        open : false,

        value: this.props.initialValue,

        okKey: this.props.okKey != null ? this.props.okKey : ButtonKeyOK,

        cancelKey: this.props.cancelKey != null ? this.props.cancelKey : ButtonKeyCancel

    } as InputDialogState;

    this.dialogTranslation = this.dialogTranslation.bind(this);

    //log.traceInOut( "constructor()" );
  }


  async componentDidMount() {

    log.traceIn( "componentDidMount()" );

    this.setState( {
        open : true,
    });

    log.traceIn( "componentDidMount()" );
  }
  
  private dialogTranslation( key : string ) {

    let result;

    const translationKey = TranslationKey.Prompts + ":" + key;

    if( this.props.i18n.exists( translationKey) ) {

      result = this.props.t( translationKey );
    }
    else if( this.props.i18n.exists( key) ) {

        result = this.props.t( key );
    }
    else {
        result = key;
    }
    return result;
  }
  
  render() {

    //log.traceInOut( "render()" );

    const { classes } = this.props;

    const handleClose = ( buttonKey? : string ) => {

        log.traceInOut( "handleClose()", buttonKey );

        this.setState( { open : false } );     
        
        this.props.onComplete( buttonKey === this.state.okKey ? this.state.value : undefined );
    }

    const handleEditChange = ( 
        event: { 
          preventDefault: () => void;
          target : { name : string, value : string }  
        } ) => {
    
        event.preventDefault();

        this.setState( {
            value: event.target.value != null && event.target.value.length > 0 ? event.target.value : undefined
        })
    }

    return (
     <React.Fragment>
        <Dialog 
            open={this.state.open}
            onClose={() => handleClose()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            {this.props.titleKey != null &&
                <DialogTitle id="alert-dialog-title">{this.dialogTranslation( this.props.titleKey )}</DialogTitle> 
            }
            <DialogContent className={classes.content}>
                {this.props.messageKey != null && <DialogContentText id="alert-dialog-message">
                    {this.dialogTranslation( this.props.messageKey )}
                    </DialogContentText>
                }
                <TextField
                    variant={propertyInputVariant}
                    fullWidth
                    value={this.state.value != null ? this.state.value : ""}
                    onChange={handleEditChange}
                />
            </DialogContent>
            <DialogActions className={classes.buttons}>
                <Button key={this.state.okKey} onClick={() => handleClose( this.state.okKey )} color="primary">
                    {this.dialogTranslation( this.state.okKey )}
                </Button>
                <Button key={this.state.cancelKey} onClick={() => handleClose( this.state.cancelKey )} color="primary">
                    {this.dialogTranslation( this.state.cancelKey )}
                </Button>
            </DialogActions>
          </Dialog>
        </React.Fragment>
      );
  }
}


const ModifiedInputDialog = withTranslation()(withStyles(styles)(InputDialog));

export default ModifiedInputDialog;

