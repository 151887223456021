import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import { Box, Button, CardMedia, createStyles, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Theme, WithStyles, withStyles } from '@material-ui/core';

import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import DownloadIcon from '@mui/icons-material/Download';

import { log } from "ui/app/app";

import { TranslationKey } from '../../services/common/api/translatorIF';
import { StorageMedia } from '../../services/storage/api/storageMedia';
import { errorDialog } from './simpleDialog';
import saveAs from 'file-saver';

export const ButtonKeyClose = "close";


const styles = (theme: Theme) => createStyles({
  content: {
    display: "flex",
    padding: theme.spacing(2)
  },
  buttons: {
    display: "flex",
    justifyContent: 'flex-end',
    paddingTop: theme.spacing(0),
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    paddingBottom: theme.spacing(1) 
  },
  media: { 
  }
});


export const mediaDialog = (
  titleKey: string | undefined,
  media: StorageMedia ): Promise<void> => {

  return new Promise<void>((resolve, reject) => {

    log.traceIn("mediaDialog()", {titleKey}, {media}); 

    try {

      confirmAlert({
        customUI: ({ onClose }) => {

          const onComplete = () => {

            onClose();

            log.traceOut("onComplete()");
            resolve();
          }

          return (
            <ModifiedMediaDialog
              titleKey={titleKey}
              onComplete={onComplete}
              media={media}
              >
            </ModifiedMediaDialog>
          );
        }
      });

    } catch (error) {

      log.warn("confirm()", error);

      reject();
    }
  });
}


interface MediaDialogProps extends WithStyles<typeof styles>, WithTranslation {

  titleKey?: string,

  media : StorageMedia,

  onComplete: () => void
}


interface MediaDialogState {

  open: boolean,

  fullScreen: boolean,

  mediaUrl?: string
}

class MediaDialog extends React.PureComponent<MediaDialogProps, MediaDialogState> {

  constructor(props: MediaDialogProps) {

    super(props);

    this.state = {

      fullScreen: false,

      open: false

    } as MediaDialogState;

    this.titleTranslation = this.titleTranslation.bind(this);

    //log.traceInOut( "constructor()" );
  }


  async componentDidMount() {

    log.traceIn("componentDidMount()");

    try {

      const mediaUrl = this.props.media.data != null ?
        URL.createObjectURL(this.props.media.data as Blob) : this.props.media.downloadUrl;

      if( mediaUrl == null ) {
        throw new Error( "couldNotRead")
      }

      this.setState({

        open: true,

        fullScreen: false,

        mediaUrl: mediaUrl

      });

      log.traceOut("componentDidMount()");

    } catch (error) {
      log.warn("componentDidMount()", error);

      await errorDialog(error);
    }
  }

  private titleTranslation( titleKey: string) {

    let result;

    let translationKey = TranslationKey.Prompts + ":" + titleKey;

    if (this.props.i18n.exists(translationKey)) {

      return this.props.t(translationKey);
    }

    if (this.props.i18n.exists(titleKey)) {

      result = this.props.t(titleKey);
    }
    else {
      result = titleKey;
    }
    return result;
  }



  render() {

    //log.traceInOut("render()");

    const { classes } = this.props;

    const handleClose = () => {

      log.traceInOut("handleClose()");

      this.setState({ open: false });

      this.props.onComplete();
    }

    const handleDownload = async () => {

      try {
        //log.traceIn( "handleDownload()" );

        if( this.props.media.downloadUrl == null ) {
          throw new Error( "Nothing to download");
        }

        saveAs( this.props.media.downloadUrl );
      
        //log.traceOut( "handleDownload()" );
  
      } catch( error ) {
        log.warn( "handleDownload()", "Error downloading", error );
  
        await errorDialog( error );
        
        log.traceOut( "handleDownload()", "error" );
      }
    }

    const handleFullScreen = () => {

      log.traceInOut("handleFullScreen()");

      this.setState({ fullScreen: !this.state.fullScreen });
    }

    return (
      <React.Fragment>
        <Dialog
          open={this.state.open}
          onClose={() => handleClose()}
          fullScreen={this.state.fullScreen} 
        >
          {this.props.titleKey == null ? null :
            <DialogTitle>{this.titleTranslation(this.props.titleKey!)}</DialogTitle>
          }
          <DialogContent className={classes.content}>
            <CardMedia
              className={classes.media}
              component="iframe"
              src={this.state.mediaUrl}
            />
          </DialogContent>
          <DialogActions className={classes.buttons}>
            <Button autoFocus onClick={handleClose} color="primary">
              {this.props.t(ButtonKeyClose)}
            </Button>
            <Box style={{ width:"100%", display:"flex" }}/>
            {this.props.media.downloadUrl != null && (!this.props.media.downloadUrl.endsWith(".pdf") || !this.state.fullScreen) &&
              <IconButton onClick={() => handleDownload()}>
                <DownloadIcon />
              </IconButton>
            }
            <IconButton onClick={() => handleFullScreen()}>
              {this.state.fullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
            </IconButton>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

const ModifiedMediaDialog = withTranslation()(withStyles(styles)(MediaDialog));

export default ModifiedMediaDialog;

