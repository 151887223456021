import { Collections } from "../../services/database/api/collections";
import { RegistrationCollections } from "./registrationCollections";



export const HealthguardCollections = {

    ...Collections,

    ...RegistrationCollections,

    Consents                        : "consents",

    Hazards                   : "hazards",

    Gatherings                      : "gatherings",

    Risks                           : "risks",

    Measures                        : "measures"

}

export type HealthguardCollectionName = keyof (typeof HealthguardCollections);

export const HealthguardCollectionNames = Object.values( HealthguardCollections );

export const ConsentsCollection = HealthguardCollections.Consents as HealthguardCollectionName; 

export const HazardsCollection = HealthguardCollections.Hazards as HealthguardCollectionName;

export const GatheringsCollection = HealthguardCollections.Gatherings as HealthguardCollectionName;

export const RisksCollection = HealthguardCollections.Risks as HealthguardCollectionName;

export const MeasuresCollection = HealthguardCollections.Measures as HealthguardCollectionName;



