
export const HealthConditions = {

    Diabetes                : "diabetes",
    
    Cancer                  : "cancer",

    Asthma                  : "asthma",

    HeartDisease            : "heartDisease",

    ChronicKidneyDisease    : "chronicKidneyDisease",

    HighBloodPressure       : "highBloodPressure",

    Other                   : "other"
}

export type HealthCondition = keyof (typeof HealthConditions);


