import { DatabaseDocument } from "../../framework/databaseDocument";

import { DocumentsProperty } from "./documentsProperty";
import { DatabaseObject } from "../../framework/databaseObject";
import { PropertyType, PropertyTypes } from "../../api/definitions/propertyType";
import { Database } from "../core/database";
import { SymbolicOwnersPropertyIF } from "../../api/properties/symbolicOwnersPropertyIF";

export class SymbolicOwnersProperty<DerivedDocument extends DatabaseDocument> 
    extends DocumentsProperty<DerivedDocument> implements SymbolicOwnersPropertyIF<DerivedDocument> {

    constructor( parent : DatabaseObject, 
        onSelectSources? : () => (Database<DerivedDocument> | undefined)[],
        reciprocalKey? : keyof DerivedDocument ) {

        super( PropertyTypes.SymbolicOwners as PropertyType, parent, onSelectSources, reciprocalKey ); 
    } 
}


