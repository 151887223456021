import React from 'react';
import { AuthenticationClaims } from '../../services/authentication/api/authenticationClaims';
import { HomePath } from '../../services/common/api/homePaths';
import { HazardIF } from '../../healthguard/api/documents/hazardIF';
import { HealthguardCompanyIF } from '../../healthguard/api/documents/healthguardCompanyIF';
import { HealthguardUnitIF } from '../../healthguard/api/documents/healthguardUnitIF';
import { HealthguardUserIF } from '../../healthguard/api/documents/healthguardUserIF';
import { Display } from './display';

export interface AppContextProps {

  authenticationClaims?: AuthenticationClaims,

  currentUser?: HealthguardUserIF;

  currentMinors? : Map<string,HealthguardUserIF>, 

  currentUnit?: HealthguardUnitIF;

  onUpdateActiveUnit?: ( activeUnit? : HealthguardUnitIF ) => Promise<void>,

  currentCompany?: HealthguardCompanyIF;

  onUpdateActiveCompany?: ( activeCompany? : HealthguardCompanyIF ) => Promise<void>,

  currentHazard?: HazardIF;

  onUpdateActiveHazard?: ( hazard? : HazardIF ) => Promise<void>,

  currentDisplay?: Display,

  onRedirect?: ( path : string ) => Promise<void>,

  currentHomePath?: HomePath 
}


export const AppContext = React.createContext<AppContextProps>( {

} );

