
import { HealthguardCompanyIF } from "../../api/documents/healthguardCompanyIF";
import { Company } from "../../../services/database/impl/documents/company";
import { Factory } from "../../../services/common/api/factory";
import { log } from "../../../services/database/framework/databaseService";
import { CollectionDatabase } from "../../../services/database/impl/core/collectionDatabase";
import { CollectionGroupDatabase } from "../../../services/database/impl/core/collectionGroupDatabase";
import { ReferencesProperty } from "../../../services/database/impl/properties/referencesProperty";
import { CollectionProperty } from "../../../services/database/impl/properties/collectionProperty";
import {IncidentRegistrationsCollection, QuarantineRegistrationsCollection, RegistrationsCollection, SickLeaveRegistrationsCollection, SymptomRegistrationsCollection, TestRegistrationsCollection, VaccineRegistrationsCollection } from "../../api/registrationCollections";
import { GatheringsCollection, MeasuresCollection, HazardsCollection, ConsentsCollection, RisksCollection } from "../../api/healthguardCollections";
import { Gathering } from "./gathering";
import { IncidentRegistration } from "./incidentRegistration";
import { Measure } from "./measure";
import { QuarantineRegistration } from "./quarantineRegistration";
import { SickLeaveRegistration } from "./sickLeaveRegistration";
import { SymptomRegistration } from "./symptomRegistration";
import { TestRegistration } from "./testRegistration";
import { VaccineRegistration } from "./vaccineRegistration";
import { IncidentRegistrationDocument, QuarantineRegistrationDocument, SickLeaveRegistrationDocument, SymptomRegistrationDocument, TestRegistrationDocument, VaccineRegistrationDocument } from "../../api/registrationDocuments";
import { HealthguardCompanyDocument } from "../../api/healthguardDocuments";
import { SubdocumentProperty } from "../../../services/database/impl/properties/subdocumentProperty";
import { Privacy } from "../../../services/database/impl/subdocuments/privacy";
import { DefaultIdentification, Identification } from "../../../services/database/api/definitions/identification";

import { Hazard } from "./hazard";
import { DefinitionProperty } from "../../../services/database/impl/properties/definitionProperty";
import { ReadinessLevel, ReadinessLevels } from "../../api/definitions/readinessLevel";
import { HealthguardCompanyManager } from "../managers/healthguardCompanyManager";
import { Consent } from "./consent";
import { TextProperty } from "../../../services/database/impl/properties/textProperty";
import { DefaultTextType} from "../../../services/database/api/definitions/textTypes";

import { Risk } from "./risk";
import { HealthguardUser } from "./healthguardUser";
import { UserAccess } from "../../../services/common/api/userAccess";
import { ReadinessLevelDefinition } from "../../../services/database/api/definitions";

import privacyConfiguration from "../../../healthguard/data/settings/privacy.json";
import { Registration } from "./registration";

export class HealthguardCompany extends Company implements HealthguardCompanyIF {

    constructor( companiesCollection : CollectionDatabase<HealthguardCompany>, documentPath? : string ) {

        super( HealthguardCompanyDocument, companiesCollection, documentPath ); 

        try {
            this.hazards = new CollectionProperty<Hazard>( this, HazardsCollection, true ); 

            this.publicHazards = new ReferencesProperty<Hazard>(  this, 
                () => [Factory.get().databaseService.databaseFactory.collectionDatabaseFromCollectionName( HazardsCollection ) as CollectionDatabase<Hazard>]);
                
            this.gatherings = new CollectionProperty<Gathering>( this, GatheringsCollection, true );

            this.risks = new CollectionProperty<Risk>( this, RisksCollection, true );

            this.measures = new CollectionProperty<Measure>( this, MeasuresCollection, true ); 

            this.consents = new CollectionProperty<Consent>( this, ConsentsCollection, true );
            
            this.consentPrompt = new TextProperty( this, DefaultTextType, true ); 
            
            this.privacyPolicy = new TextProperty( this,  DefaultTextType, true,
                Factory.get().configurationService.config( 
                    privacyConfiguration, "consent.privacyPolicy" ) );             

            this.readinessLevel = new DefinitionProperty<ReadinessLevel>(this, ReadinessLevelDefinition, ReadinessLevels);

            this.healthPersonell = new ReferencesProperty<HealthguardUser>(  this, 
                () => [this.users.collectionGroup() as CollectionGroupDatabase<HealthguardUser>]
            );

            this.registrations = new CollectionProperty<Registration>( this, RegistrationsCollection, true );

            this.incidentRegistrations = 
                new CollectionProperty<IncidentRegistration>( this, IncidentRegistrationsCollection, true );
            this.incidentRegistrations.editable = false;

            this.incidentRegistrationsPrivacy = new SubdocumentProperty<Privacy>( 
                this, 
                new Privacy( this, "incidentRegistrationsPrivacy") );

            this.incidentRegistrationsPrivacy.subdocument().disabled.setDefaultValue( 
                Factory.get().configurationService.config( 
                    privacyConfiguration, "incidentRegistrations.defaultDisabled" )
            );

            this.incidentRegistrationsPrivacy.subdocument().userAccessTypes.setDefaultValue( 
                Factory.get().configurationService.config( 
                    privacyConfiguration, "incidentRegistrations.defaultUserAccess" )
            );

            this.incidentRegistrationsPrivacy.subdocument().identification.setDefaultValue( 
                Factory.get().configurationService.config( 
                    privacyConfiguration, "incidentRegistrations.defaultIdentification" )
            );

            this.incidentRegistrationsPrivacy.subdocument().deleteAfterTimeSpan.setDefaultValue( 
                Factory.get().configurationService.config( 
                    privacyConfiguration, "incidentRegistrations.defaultDeleteAfterTimeSpan" )
            );

            this.incidentRegistrationsPrivacy.subdocument().deleteAfterTimeSpanUnits.setDefaultValue( 
                Factory.get().configurationService.config( 
                    privacyConfiguration, "incidentRegistrations.defaultDeleteAfterTimeSpanUnits" )
            );


            this.quarantineRegistrations = new CollectionProperty<QuarantineRegistration>( 
                this, QuarantineRegistrationsCollection, true );
            this.quarantineRegistrations.editable = false;


            this.quarantineRegistrationsPrivacy = new SubdocumentProperty<Privacy>( 
                this, 
                new Privacy( this, "quarantineRegistrationsPrivacy") );

            this.quarantineRegistrationsPrivacy.subdocument().disabled.setDefaultValue( 
                Factory.get().configurationService.config( 
                    privacyConfiguration, "quarantineRegistrations.defaultDisabled" )
            );

            this.quarantineRegistrationsPrivacy.subdocument().userAccessTypes.setDefaultValue( 
                    Factory.get().configurationService.config( 
                        privacyConfiguration, "quarantineRegistrations.defaultUserAccess" )
            );

            this.quarantineRegistrationsPrivacy.subdocument().identification.setDefaultValue( 
                Factory.get().configurationService.config( 
                    privacyConfiguration, "quarantineRegistrations.defaultIdentification" )
            );

            this.quarantineRegistrationsPrivacy.subdocument().deleteAfterTimeSpan.setDefaultValue( 
                Factory.get().configurationService.config( 
                    privacyConfiguration, "quarantineRegistrations.defaultDeleteAfterTimeSpan" )
            );

            this.quarantineRegistrationsPrivacy.subdocument().deleteAfterTimeSpanUnits.setDefaultValue( 
                Factory.get().configurationService.config( 
                    privacyConfiguration, "quarantineRegistrations.defaultDeleteAfterTimeSpanUnits" )
            );

            this.sickLeaveRegistrations = new CollectionProperty<SickLeaveRegistration>( 
                this, SickLeaveRegistrationsCollection, true );
            this.sickLeaveRegistrations.editable = false;

            this.sickLeaveRegistrationsPrivacy = new SubdocumentProperty<Privacy>( 
                this, 
                new Privacy( this, "sickLeaveRegistrationsPrivacy") );

            this.sickLeaveRegistrationsPrivacy.subdocument().disabled.setDefaultValue( 
                Factory.get().configurationService.config( 
                    privacyConfiguration, "sickLeaveRegistrations.defaultDisabled" )
            );
            
            this.sickLeaveRegistrationsPrivacy.subdocument().userAccessTypes.setDefaultValue( 
                Factory.get().configurationService.config( 
                    privacyConfiguration, "sickLeaveRegistrations.defaultUserAccess" )
            );

            this.sickLeaveRegistrationsPrivacy.subdocument().identification.setDefaultValue( 
                Factory.get().configurationService.config( 
                    privacyConfiguration, "sickLeaveRegistrations.defaultIdentification" )
            );

            this.sickLeaveRegistrationsPrivacy.subdocument().deleteAfterTimeSpan.setDefaultValue( 
                Factory.get().configurationService.config( 
                    privacyConfiguration, "sickLeaveRegistrations.defaultDeleteAfterTimeSpan" )
            );

            this.sickLeaveRegistrationsPrivacy.subdocument().deleteAfterTimeSpanUnits.setDefaultValue( 
                Factory.get().configurationService.config( 
                    privacyConfiguration, "sickLeaveRegistrations.defaultDeleteAfterTimeSpanUnits" )
            );

            this.symptomRegistrations = new CollectionProperty<SymptomRegistration>( 
                this, SymptomRegistrationsCollection, true );
            this.symptomRegistrations.editable = false;

            this.symptomRegistrationsPrivacy = new SubdocumentProperty<Privacy>( 
                this, 
                new Privacy( this, "symptomRegistrationsPrivacy") );

            this.symptomRegistrationsPrivacy.subdocument().disabled.setDefaultValue( 
                Factory.get().configurationService.config( 
                    privacyConfiguration, "symptomRegistrations.defaultDisabled" )
            );

            this.symptomRegistrationsPrivacy.subdocument().userAccessTypes.setDefaultValue( 
                Factory.get().configurationService.config( 
                    privacyConfiguration, "symptomRegistrations.defaultUserAccess" )
            );

            this.symptomRegistrationsPrivacy.subdocument().identification.setDefaultValue( 
                Factory.get().configurationService.config( 
                    privacyConfiguration, "symptomRegistrations.defaultIdentification" )
            );

            this.symptomRegistrationsPrivacy.subdocument().deleteAfterTimeSpan.setDefaultValue( 
                Factory.get().configurationService.config( 
                    privacyConfiguration, "symptomRegistrations.defaultDeleteAfterTimeSpan" )
            );

            this.symptomRegistrationsPrivacy.subdocument().deleteAfterTimeSpanUnits.setDefaultValue( 
                Factory.get().configurationService.config( 
                    privacyConfiguration, "symptomRegistrations.defaultDeleteAfterTimeSpanUnits" )
            );


            this.testRegistrations = new CollectionProperty<TestRegistration>( 
                this, TestRegistrationsCollection, true );
            this.testRegistrations.editable = false;

            this.testRegistrationsPrivacy = new SubdocumentProperty<Privacy>( 
                this, 
                new Privacy( this, "testRegistrationsPrivacy") );

            this.testRegistrationsPrivacy.subdocument().disabled.setDefaultValue( 
                Factory.get().configurationService.config( 
                    privacyConfiguration, "testRegistrations.defaultDisabled" )
            );

            this.testRegistrationsPrivacy.subdocument().userAccessTypes.setDefaultValue( 
                Factory.get().configurationService.config( 
                    privacyConfiguration, "testRegistrations.defaultUserAccess" )
            );

            this.testRegistrationsPrivacy.subdocument().identification.setDefaultValue( 
                Factory.get().configurationService.config( 
                    privacyConfiguration, "testRegistrations.defaultIdentification" )
            );

            this.testRegistrationsPrivacy.subdocument().deleteAfterTimeSpan.setDefaultValue( 
                Factory.get().configurationService.config( 
                    privacyConfiguration, "testRegistrations.defaultDeleteAfterTimeSpan" )
            );

            this.testRegistrationsPrivacy.subdocument().deleteAfterTimeSpanUnits.setDefaultValue( 
                Factory.get().configurationService.config( 
                    privacyConfiguration, "testRegistrations.defaultDeleteAfterTimeSpanUnits" )
            );

            this.vaccineRegistrations = new CollectionProperty<VaccineRegistration>( 
                this, VaccineRegistrationsCollection, true );
            this.vaccineRegistrations.editable = false;

            this.vaccineRegistrationsPrivacy = new SubdocumentProperty<Privacy>( 
                this, 
                new Privacy( this, "vaccineRegistrationsPrivacy") );

            this.vaccineRegistrationsPrivacy.subdocument().disabled.setDefaultValue( 
                Factory.get().configurationService.config( 
                    privacyConfiguration, "vaccineRegistrations.defaultDisabled" )
            );
            
            this.vaccineRegistrationsPrivacy.subdocument().userAccessTypes.setDefaultValue( 
                Factory.get().configurationService.config( 
                    privacyConfiguration, "vaccineRegistrations.defaultUserAccess" )
            );

            this.vaccineRegistrationsPrivacy.subdocument().identification.setDefaultValue( 
                Factory.get().configurationService.config( 
                    privacyConfiguration, "vaccineRegistrations.defaultIdentification" )
            );

            this.vaccineRegistrationsPrivacy.subdocument().deleteAfterTimeSpan.setDefaultValue( 
                Factory.get().configurationService.config( 
                    privacyConfiguration, "vaccineRegistrations.defaultDeleteAfterTimeSpan" )
            );

            this.vaccineRegistrationsPrivacy.subdocument().deleteAfterTimeSpanUnits.setDefaultValue( 
                Factory.get().configurationService.config( 
                    privacyConfiguration, "vaccineRegistrations.defaultDeleteAfterTimeSpanUnits" )
            );

            //log.traceInOut( "constructor()", CompaniesCollection );

        } catch( error ) {

            log.warn( "constructor()", "Error initializing company", error );
            
            throw new Error( (error as any).message );
        }
    }

    subcollectionDisabled( subcollectionDocumentName : string ) : boolean {

        return !!this.privacySubdocument( subcollectionDocumentName )?.disabled.value();
    }

   subcollectionIdentification( subcollectionDocumentName : string ) : Identification {

        const identification = this.privacySubdocument( subcollectionDocumentName )?.identification.value();

        return identification != null ? identification as Identification : DefaultIdentification;
    }

    subcollectionUserAccess(subcollectionDocumentName: string): UserAccess {

        const userAccessTypes = this.privacySubdocument( subcollectionDocumentName )?.userAccessTypes.value();

        return userAccessTypes != null ? UserAccess.fromUserAccessTypes( userAccessTypes ) : UserAccess.allowAll();
    }

    privacySubdocument(subcollectionDocumentName: string): Privacy | undefined {

        switch (subcollectionDocumentName) {

            case IncidentRegistrationDocument:

                return this.incidentRegistrationsPrivacy.subdocument();

            case QuarantineRegistrationDocument:

                return this.quarantineRegistrationsPrivacy.subdocument();

            case SickLeaveRegistrationDocument:

                return this.sickLeaveRegistrationsPrivacy.subdocument();

            case SymptomRegistrationDocument:

                return this.symptomRegistrationsPrivacy.subdocument();

            case TestRegistrationDocument:

                return this.testRegistrationsPrivacy.subdocument();

            case VaccineRegistrationDocument:

                return this.vaccineRegistrationsPrivacy.subdocument();
        }

        return undefined;
    }


    async onCreated() : Promise<void> {
        try {
            log.traceIn( "("+this.collectionDatabase.collectionName()+")", "onCreated()" );

            await super.onCreated();

            await HealthguardCompanyManager.getInstance().handleHealthguardCompanyCreated( this );
    
            log.traceOut( "("+this.collectionDatabase.collectionName()+")", "onCreated()" );
  
        } catch( error ) {
            
            log.warn( "("+this.collectionDatabase.collectionName()+")", "onCreated()", "Error handling created notification", error );
  
            throw new Error( (error as any).message );
        }
    }

    async onUpdated() : Promise<void> {
        try {
            //log.traceIn( "onUpdated()" );

            await super.onUpdated();

            await HealthguardCompanyManager.getInstance().handleHealthguardCompanyUpdated( this );
    
            //log.traceOut( "onUpdated()" );
  
        } catch( error ) {
            
            log.warn( "onUpdated()", "Error handling updated notification", error );
  
            throw new Error( (error as any).message );
        }    
    }

    async onDeleted() : Promise<void> {

        try {
            //log.traceIn( "onDeleted()" );

            await super.onDeleted();
    
            //log.traceOut( "onDeleted()" );
  
        } catch( error ) {
            
            log.warn( "onDeleted()", "Error handling updated notification", error );
  
            throw new Error( (error as any).message );
        }  
    }

    readonly healthPersonell : ReferencesProperty<HealthguardUser>;
 
    readonly hazards : CollectionProperty<Hazard>;

    readonly publicHazards : ReferencesProperty<Hazard>;

    readonly gatherings : CollectionProperty<Gathering>;

    readonly risks : CollectionProperty<Risk>;

    readonly measures : CollectionProperty<Measure>;

    readonly consents: CollectionProperty<Consent>;

    readonly consentPrompt : TextProperty;

    readonly privacyPolicy : TextProperty;

    readonly readinessLevel: DefinitionProperty<ReadinessLevel>;
    
    readonly registrations: CollectionProperty<Registration>; 

    readonly incidentRegistrations: CollectionProperty<IncidentRegistration>; 

    readonly incidentRegistrationsPrivacy : SubdocumentProperty<Privacy>;

    readonly quarantineRegistrations: CollectionProperty<QuarantineRegistration>;

    readonly quarantineRegistrationsPrivacy : SubdocumentProperty<Privacy>;

    readonly sickLeaveRegistrations: CollectionProperty<SickLeaveRegistration>;

    readonly sickLeaveRegistrationsPrivacy : SubdocumentProperty<Privacy>;

    readonly symptomRegistrations: CollectionProperty<SymptomRegistration>;

    readonly symptomRegistrationsPrivacy : SubdocumentProperty<Privacy>;

    readonly testRegistrations: CollectionProperty<TestRegistration>;

    readonly testRegistrationsPrivacy : SubdocumentProperty<Privacy>;

    readonly vaccineRegistrations: CollectionProperty<VaccineRegistration>;

    readonly vaccineRegistrationsPrivacy : SubdocumentProperty<Privacy>;

}


