import * as React from 'react'

import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import { withTranslation, WithTranslation } from 'react-i18next';

import { ResponsiveContainer, PieChart, Pie, Cell, PieLabelRenderProps, Tooltip, Legend } from 'recharts';  

import { log } from 'ui/app/app';

import AddIcon from '@material-ui/icons/Add';
import HighlightIcon from '@mui/icons-material/Highlight';

import { RouteComponentProps, withRouter } from 'react-router-dom';

import { Grid, Typography, IconButton, Tooltip as MuiTooltip } from '@material-ui/core'; 

import { dateFormat } from 'ui/app/localization';
import { endOfToday, format, startOfToday } from 'date-fns';
import { AppContext, AppContextProps } from 'ui/app/appContext';
import { errorDialog } from './simpleDialog';
import { Factory } from '../../services/common/api/factory';
import { DatabaseProps, DatabaseState, emptyHighlightedPropertyKey, highlightedPropertiesSelector } from './databaseView';
import { selectProperty } from './selectDialog';
import { DatabaseDocumentIF, ReferenceHandle } from '../../services/database';
import { translatedPropertyValue } from './propertyValue';
import theme from '../app/theme';


import { translatedDefinition } from './definitionText';
import Loading from './loading';
import { translatedPropertyLabel } from './propertyLabel';
import { EmptyColor } from './colorMap';
import { propertyColor } from './propertyColor';
import { PersistentKeyHighlightedProperty } from './appFrame';
import { ToggleButton } from '@mui/material';
import { DatabaseObserverIF } from '../../services/database/api/core/databaseObserverIF';
import { Monitor } from '../../services/common/api/monitor';
import { ObservableIF } from '../../services/common/api/observableIF';
import { Observation } from '../../services/common/api/observation';
import { PropertyTypes } from '../../services/database/api/definitions/propertyType';

const styles = (theme: Theme) => createStyles({
  root: {
    height: "100%",
    width: "100%"
  },
  header: {
    flexWrap: "nowrap"
  },
  card: {
    height: "60%" 
  }
});



type PropertyDataPoint = {

  color: string,

  count: number,

  value: any,
  
  translatedValue: string
}

type CardData = {

  filterMatches: number,

  todayFilterMatches: number,

  highlightedPropertyData: Map<string,PropertyDataPoint>,
}



export interface DatabaseCardProps extends DatabaseProps {

  databaseObserver : DatabaseObserverIF<DatabaseDocumentIF>,

  hideTotal? : boolean,

  hideToday? : boolean,

  hideSelectProperty? : boolean,

  hideLegend? : boolean,

  hideLabels? : boolean,

  onUpdateHighlightedPropertyKey?: ( highlightedPropertyKey? : string ) => Promise<void> 
}

interface MatchParams {
}

export interface ReactDatabaseCardProps extends
  DatabaseCardProps,
  WithStyles<typeof styles>,
  WithTranslation,
  RouteComponentProps<MatchParams> {
}

interface DatabaseCardState extends DatabaseState { // Component State

  loading: boolean,

  cardData: CardData
}

class DatabaseCard extends React.PureComponent<ReactDatabaseCardProps, DatabaseCardState> {

  constructor(props: ReactDatabaseCardProps) {

    super(props);

    this.state = { 

      highlightedPropertyKey: this.props.highlightedPropertyKey, 

      loading: true,

      cardData: { 
        filterMatches: 0, 
        todayFilterMatches: 0,
        highlightedPropertyData: new Map<string,PropertyDataPoint>() } as CardData

    } as DatabaseCardState;

    this.onUpdateDatabases = this.onUpdateDatabases.bind(this);
    this.updateCardData = this.updateCardData.bind(this);

    this.highlightedPropertyPersistentKey = this.highlightedPropertyPersistentKey.bind(this);
    this.handleHighlightedProperty = this.handleHighlightedProperty.bind(this);
    this.highlightedPropertyKey = this.highlightedPropertyKey.bind(this);

    //log.traceInOut( "constructor()" );
  }


  async componentDidMount() {

    try {
      log.traceIn("componentDidMount()" );

      await this.updateCardData( this.highlightedPropertyKey() );

      await this.props.databaseObserver.subscribe({ 
        observer: this,
        onNotify: this.onUpdateDatabases 
        } as Monitor );


      await this.props.databaseObserver.update();

      this.setState( {
        loading: false
      });

      log.traceOut("componentDidMount()");

    } catch( error ) {
          
      log.warn( "componentDidMount()", error ); 

      await errorDialog( error);

      log.traceOut( "componentDidMount()", error );
    }
  }
 
  async componentDidUpdate() {

    try {
      //log.traceIn("componentDidUpdate()" );

      await this.props.databaseObserver.update();

      if( this.props.highlightedPropertyKey !== this.state.highlightedPropertyKey ) {
        
        await this.updateCardData( this.props.highlightedPropertyKey );
      }

      //log.traceOut("componentDidUpdate()" );

    } catch( error ) {
          
      log.warn( "componentDidUpdate()", error );

      await errorDialog( error);

    }
  }

  async componentWillUnmount() {
    log.traceIn("componentWillUnmount()");

    try {

      await this.props.databaseObserver.unsubscribe( this );

      log.traceOut("componentWillUnmount()");

    } catch (error) {
      log.warn("componentWillUnmount()", "Error unmounting collection list", error);

    }
  }

  private onUpdateDatabases = async (observable : ObservableIF, observation : Observation ) => {

    //log.traceIn("onUpdateDatabases()");

    try {

      if( observable !== this.props.databaseObserver ) {
        throw new Error( "Unrecognized database observer")
      }

      await this.updateCardData( this.highlightedPropertyKey() );

      //log.traceOut("onUpdateDatabases()");

    } catch (error) {
      log.warn("onUpdateDatabases()", "Error updating databases", error);

      log.traceOut("onUpdateDatabases()", "error");
    }
  }

  private async updateCardData( highlightedPropertyKey? : string ) { 

    log.traceIn("updateCardData()", {highlightedPropertyKey}, );

    try {

      const cardData = { 
        filterMatches: this.props.databaseObserver.filteredDocuments().size, 
        todayFilterMatches: 0,
        highlightedPropertyData: new Map<string,PropertyDataPoint>() } as CardData;

      for (const databaseDocument of this.props.databaseObserver.filteredDocuments()!.values()!) {

        const startToday = startOfToday();
        const endToday = endOfToday();

        if (databaseDocument.matchFilter( {from: startToday, to: endToday})) {

          cardData.todayFilterMatches++;
        }

        let highlightedProperty = highlightedPropertyKey == null || !!this.props.multipleDocuments ? undefined :
          databaseDocument.property( highlightedPropertyKey );

        if( highlightedProperty == null ) {
          highlightedProperty = databaseDocument.name;
        }

        let allValues : any[] = [];

        if( highlightedProperty.value() == null ) {
          if( !allValues.includes( undefined ) ) {
            allValues.push( undefined );
          }
        }
        else {
          if( Array.isArray( highlightedProperty.value() ) ) {

            (highlightedProperty.value() as any[]).forEach( value => {
              if( !allValues.includes( value ) ) {
                allValues.push( value );
              }
            });
          }
          else {
            if( !allValues.includes( highlightedProperty.value() ) ) {

              allValues.push( highlightedProperty.value() )
            }
          }
        }

        //log.debug("updateCardData()", {allValues});

        for( const value of allValues ) {

          let translatedValue;
            
          if( value == null ) {
            translatedValue = "";
          }
          else if (highlightedProperty.type === PropertyTypes.Definition ||
            highlightedProperty.type === PropertyTypes.Definitions) {

            translatedValue = translatedDefinition( (highlightedProperty as any).definition, value + "" );

          }
          else if( highlightedProperty.type === PropertyTypes.Tenant ||
                  highlightedProperty.type === PropertyTypes.Owner ||
                  highlightedProperty.type === PropertyTypes.Reference ||
                  highlightedProperty.type === PropertyTypes.References ||
                  highlightedProperty.type === PropertyTypes.SymbolicOwners ||
                  highlightedProperty.type === PropertyTypes.SymbolicCollection ) {

            translatedValue = (value as ReferenceHandle<DatabaseDocumentIF>).title!;
          }
          else {
            translatedValue = translatedPropertyValue( highlightedProperty.parent.documentName(), value + "" );
          }

          if( translatedValue == null || translatedValue.length === 0 ) {
            translatedValue = this.props.t("notSet");
          }

          let color = propertyColor( highlightedProperty );

          if( color == null ) {
            color = EmptyColor;
          }

          let propertyDataPoint = cardData.highlightedPropertyData.get( translatedValue );

          if( propertyDataPoint == null ) {

            propertyDataPoint = { 
              count: 0,
              translatedValue: translatedValue,
              value: value,
              color: color
            }
            //log.debug("updateCardData()", "new property data point", {translatedValue});
          }

          propertyDataPoint.count++;

          cardData.highlightedPropertyData.set( translatedValue, propertyDataPoint );

          //log.debug("updateCardData()", {propertyDataPoint});
                    
        }
      }

      this.setState({ 

        highlightedPropertyKey: highlightedPropertyKey,

        cardData: cardData  
      })

      log.traceOut("cardData()", cardData);

    } catch (error) {
      log.warn("Error reading list rows for documents", error);

      throw new Error( (error as any).message );
    }
  }

  private highlightedPropertyPersistentKey() {

    const appContext = this.context as AppContextProps;

    return appContext.currentHomePath + "." + this.props.databaseObserver.defaultDocumentName()! + "." + PersistentKeyHighlightedProperty ;
  }

  private async handleHighlightedProperty () {

    log.traceIn("handleHighlightedProperty ()");    

    const referenceDocument = this.props.databaseObserver.referenceDocument()!;

    let existingHighlightedPropertyKey : string | null | undefined = this.highlightedPropertyKey();

    if (existingHighlightedPropertyKey === undefined ||
      existingHighlightedPropertyKey === emptyHighlightedPropertyKey) {

      existingHighlightedPropertyKey = null;
    }

    const highlightedPropertyKey = await selectProperty( 
      referenceDocument,
      highlightedPropertiesSelector,
      this.props.t("highlightProperty"),
      existingHighlightedPropertyKey );

    if( highlightedPropertyKey === undefined ) {
      log.traceOut("handleHighlightedProperty ()", "cancel");    
      return;
    }

    let newHighlightedPropertyKey = highlightedPropertyKey !== null ? highlightedPropertyKey : emptyHighlightedPropertyKey;

    log.debug("handleHighlightedProperty ()",  this.state.highlightedPropertyKey, {newHighlightedPropertyKey});    

    if( this.props.onUpdateHighlightedPropertyKey != null ) {
      await this.props.onUpdateHighlightedPropertyKey( newHighlightedPropertyKey ); 
    }
    else {

      Factory.get().persistentState!.setProperty(this.highlightedPropertyPersistentKey(), newHighlightedPropertyKey);

      this.setState({ highlightedPropertyKey: newHighlightedPropertyKey});

      await this.updateCardData( newHighlightedPropertyKey );
    }

    log.traceOut("handleHighlightedProperty ()");    
  }

  private highlightedPropertyKey(): string | undefined {

    //log.traceIn("highlightedPropertyKey ()",);

    if (this.state.highlightedPropertyKey != null) {
      //log.traceOut("highlightedProperty ()", "From state", this.state.highlightedPropertyKey );
      return this.state.highlightedPropertyKey;
    }

    const persistentHighlightedProperty = Factory.get().persistentState!.property(
      this.highlightedPropertyPersistentKey());

    if (persistentHighlightedProperty != null ) {

      //log.traceOut("highlightedProperty ()", "From persistent app state", persistentHighlightedProperty);
      return persistentHighlightedProperty;
    }

    //log.traceOut("highlightedPropertyKey ()", "From default", this.props.highlightedPropertyKey );
    return this.props.highlightedPropertyKey ;
  }



  render(): JSX.Element {

    //log.traceInOut("render()");

    const { classes } = this.props;

    const openDatabase = async () => {

      log.traceIn("openDatabase()");

      try {

        const appContext = this.context as AppContextProps;

        const path = appContext.currentHomePath + this.props.databaseObserver.defaultDatabase()!.databasePath( true );

        this.props.history.push(path);

        log.traceOut("openDatabase()", path);

      } catch (error) {
        log.warn("Error opening database", error);

        await errorDialog( error);

        log.traceOut("openDatabase()", "error opening database");
      }
    }

    const dateRangeText = () => {
      if( this.props.databaseObserver.dateRange()?.from == null ) {
        return this.props.t( "total").toLowerCase(); 
      }

      const to = this.props.databaseObserver.dateRange()?.to != null ? this.props.databaseObserver.dateRange()!.to! : new Date();

      const text = 
        format(this.props.databaseObserver.dateRange()!.from!, dateFormat() ) + 
        " " + this.props.t("to") + " " +
        format( to, dateFormat() );

      return text.toLowerCase();
    }

    const pieChartData = Array.from( this.state.cardData.highlightedPropertyData.values() );

    if( pieChartData.length === 0 ) {
      pieChartData.push( { 
        translatedValue: "", 
        value: "",
        count: 1,
        color: EmptyColor
      } ); 
    }
    

    //log.debug( "render()", {pieChartData})

    const renderCustomLabel = ( pieLabelRenderProps : PieLabelRenderProps ) => {

      if( (!this.props.multipleDocuments && this.highlightedPropertyKey() === emptyHighlightedPropertyKey) ||
        pieLabelRenderProps.name == null ||
        pieLabelRenderProps.name.length === 0 ) {
          
        return "";
      }

      //return ( pieLabelRenderProps.name + " (" + Math.round( +pieLabelRenderProps.percent!*100 ) + "%)");
      return (  pieLabelRenderProps.name); 
    };  
    
    const pieChart = (
      <PieChart
        margin={{
          top: theme.spacing(0), 
          right: theme.spacing(0),
          bottom: theme.spacing(0),
          left: theme.spacing(0)
        }} 
        onClick={() => openDatabase()} 
        >   
        {this.state.cardData.highlightedPropertyData.size > 0 && <Tooltip />} 
        {!this.props.hideLegend && <Legend layout="horizontal" verticalAlign="top" align="right"/>}
        <Pie
          data={pieChartData}
          dataKey="count"
          nameKey="translatedValue"
          label={( props ) => !!this.props.hideLabels ? null : renderCustomLabel(props)} 
          labelLine={false}
          isAnimationActive={false}
          cursor="pointer"
        >
          {pieChartData.map((entry, index) => (
            <Cell 
              key={entry.translatedValue + index } 
              fill={entry.color}
            /> 
          ))}
        </Pie>
      </PieChart> 

    )

    const highlightedPropertyKey = this.highlightedPropertyKey();

    const highlightedPropertyLabel = highlightedPropertyKey != null && highlightedPropertyKey !== emptyHighlightedPropertyKey? 
      translatedPropertyLabel( this.props.databaseObserver.defaultDocumentName()!, highlightedPropertyKey ) : 
      undefined;
   
    //log.debug("render()", highlightedPropertyKey );

    return (
      <Grid item container className={classes.root} direction="column" alignItems="stretch" justifyContent="space-between">
        {this.state.loading ? <Loading /> :
          <>
            {(!!this.props.hideTotal || !this.props.hideTotal) &&
              <Grid item container className={classes.header} justifyContent="space-between">
                <Grid item key="total">
                  {!this.props.hideTotal &&
                    <Typography component="p" variant="body1">&nbsp;&nbsp;&nbsp;
                      <><b><big>{this.state.cardData.filterMatches}</big></b><small> - {dateRangeText()}</small></>
                    </Typography>
                  }
                </Grid>
                <Grid item >
                  {!this.props.hideSelectProperty &&
                    <MuiTooltip  title={(<>{this.props.t("highlightProperty")}</>)}>
                      <ToggleButton
                          size="small"
                          style={{ marginLeft: theme.spacing(1) }}
                          value={highlightedPropertyKey != null && highlightedPropertyKey !== emptyHighlightedPropertyKey}
                          selected={highlightedPropertyKey != null && highlightedPropertyKey !== emptyHighlightedPropertyKey}
                          onClick={() => this.handleHighlightedProperty()}>
                          <HighlightIcon />
                        </ToggleButton>
                    </MuiTooltip> 
                  }
                </Grid>
              </Grid>
            }
            <Grid item container className={classes.card}>
              <ResponsiveContainer>
                {pieChart}
              </ResponsiveContainer>
            </Grid>
            <Grid item container justifyContent="center">
              <Grid item>
                <Typography
                  variant="inherit" 
                  color="secondary"
                >
                  &nbsp;{highlightedPropertyLabel && this.state.cardData.highlightedPropertyData.size > 0 ? highlightedPropertyLabel : ""}&nbsp;
                </Typography>
              </Grid>
            </Grid>
            {(!this.props.hideToday || (!this.props.hideAdd && this.props.onAddDocument != null)) &&
              <Grid item container justifyContent="space-between" style={{marginBottom: theme.spacing(-0.5)}}> 
                <Grid item key="today" >
                  {!this.props.hideToday &&
                    <Typography component="p" variant="body1">&nbsp;&nbsp;&nbsp;
                      <><b><big>{this.state.cardData.todayFilterMatches}</big></b><small> - {this.props.t("newToday").toLowerCase()}</small></>
                    </Typography>
                  }
                </Grid>
                {!this.props.hideAdd && this.props.onAddDocument != null &&
                  <Grid item key="add">
                    <IconButton color="primary" onClick={() => this.props.onAddDocument!( this.props.databaseObserver )} size="small"> 
                      <AddIcon />
                    </IconButton>
                  </Grid>
                }
              </Grid>
            }
          </>
        }
      </Grid>
    );
  }

}

DatabaseCard.contextType = AppContext;

const ModifiedDatabaseCard = withRouter(withTranslation()(withStyles(styles)(DatabaseCard)));

export default ModifiedDatabaseCard;

