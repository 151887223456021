
import { convert } from 'html-to-text';

import { log } from '../framework/messagingService';

export const bodyNewlineElement = "p";

export class MessageBody {

    constructor( items : string[] ) {

        if( typeof items === "string" ) {
            this._items = [items];
        }
        else {
            this._items = items;
        }
    }

    items(): string[] {
        return this._items;
    }

    html(): string {

        try {

            if (this._items == null || this._items.length === 0) {

                return "";
            }

            let html = "";

            for( const item of this._items ) {

                if( item.startsWith("<") && item.endsWith(">" )) {

                    html += item;
                }
                else {
                    html += "<" + bodyNewlineElement + ">" + item + "</" + bodyNewlineElement + ">";
                }
            }
            
            return html;

        } catch (error) {

            const items = this._items;

            log.warn("html()", "Error converting body to html", typeof items, {items}, error);

            throw new Error( (error as any).message );
        }
    }

    text(): string {

        try {

            const text = convert(this.html(), {
                wordwrap: false
            });

            return text; 

        } catch (error) {
            log.warn("html()", "Error converting body to text", error);

            throw new Error( (error as any).message );
        }
    }

    private readonly _items : string[];
}
