import React, { useContext } from 'react';

import DocumentView, { DocumentProps } from '../components/documentView';

import { PropertiesSelector } from 'services/database/api/core/propertiesSelector';

import DatabaseTable, { DatabaseTableProps, EndDateProperty, StartDateProperty } from 'ui/components/databaseTable';
import DatabaseList, { DatabaseListProps } from 'ui/components/databaseList';
import DatabaseCard, { DatabaseCardProps } from 'ui/components/databaseCard';
import DatabaseChart, { DatabaseChartProps } from 'ui/components/databaseChart';
import DatabaseGraph, { DatabaseGraphProps } from 'ui/components/databaseGraph';

import DatabaseView from '../components/databaseView';
import { CompanyDocument } from '../../services/database/api/documents';
import { SubscriptionIF } from '../../services/database/api/documents/subscriptionIF';
import { AppContext } from '../app/appContext';
import { Factory } from '../../services/common/api/factory';
import { useHistory } from 'react-router-dom';
import { SubscriptionsCollection } from '../../services/database/api/collections';
import { log } from '../app/app';
import { errorDialog } from '../components/simpleDialog';

import { useTranslation } from 'react-i18next';
import { BillingMethods } from '../../services/database';
import { activeLanguage } from '../app/localization';

import applicationConfiguration from "../../healthguard/data/settings/application.json";
import { DatabaseQuery } from '../../services/database/api/core/databaseQuery';
import { DatabaseViewType, DatabaseViewTypes } from '../components/databaseViewMenu';

export const DefaultSubscriptionTableColumns = [
  StartDateProperty,
  EndDateProperty,
  CompanyDocument,
  "email",
  "subscriptionStatus",
  "licenses",
  "billingMethod",
  "subscriptionId", 
  "accountId"
  ];

export const defaultHighlightedSubscriptionsProperty = "subscriptionStatus";

export function SubscriptionsTable( props: DatabaseTableProps ) {

  return (
    <DatabaseTable {...props} featuredProperties={props.featuredProperties != null ? props.featuredProperties : DefaultSubscriptionTableColumns }/>
  );
}

export function SubscriptionsList( props: DatabaseListProps ) {

  return (
    <DatabaseList {...props} />
  );
}



export function SubscriptionsCard( props: DatabaseCardProps ) {
  return (
    <DatabaseCard 
      {...props} 
      highlightedPropertyKey={props.highlightedPropertyKey != null ? props.highlightedPropertyKey : defaultHighlightedSubscriptionsProperty}
    />
  );
} 

export function SubscriptionsChart( props: DatabaseChartProps ) {
  return (
    <DatabaseChart 
      {...props} 
    />
  );
}



export function SubscriptionsGraph( props: DatabaseGraphProps ) {
  return (
    <DatabaseGraph 
      {...props} 
      previousPropertyKey={"company"}
      nextPropertyKey={"billingMethod"}
      directed={false}
    />
  );
}



export function SubscriptionsView(props: { 
  databaseQuery : DatabaseQuery<SubscriptionIF>,
  title? : string,
  featuredProperties?: string[],
  highlightedPropertyKey?: string }) {

  return ( <DatabaseView 
    databaseQuery={props.databaseQuery} 
    title={props.title}
    featuredProperties={props.featuredProperties != null ? props.featuredProperties : DefaultSubscriptionTableColumns }
    highlightedPropertyKey={props.highlightedPropertyKey != null ? props.highlightedPropertyKey : defaultHighlightedSubscriptionsProperty}
    defaultDatabaseViewType={DatabaseViewTypes.Table as DatabaseViewType} 
    /> );
}



export function SubscriptionView( props : DocumentProps ) {

  const SubscriptionBasicsFilter = {

    includePropertyKeys: [
      CompanyDocument,
      "email",
      "phoneNumber",
      "contactName",
      "accountId",
      "subscriptionId"
      ]

  } as PropertiesSelector;

  const SubscriptionLicenseFilter = {

    includePropertyKeys: [
      "billingMethod",
      "subscriptionStatus",
      "licenses",
      "minLicenses",
      "maxLicenses",
      ]

  } as PropertiesSelector;


  const SubscriptionChargesFilter = {

    includePropertyKeys: [
      "paymentProvider",
      "currency",
      "subscriptionCharge",
      "subscriptionTax"
    ]

  } as PropertiesSelector;

  const SubscriptionDatesFilter = {

    includePropertyKeys: [
      "trialPeriod",
      "trialInterval",      
      "subscriptionPeriod",
      "subscriptionInterval",
      "startDate",
      "endDate",
      "lastPaymentDate",
      "nextPaymentDate"
    ]

  } as PropertiesSelector;


  const SubscriptionAddressFilter = {

    includePropertyKeys: [
      "billingAddress"
    ]

  } as PropertiesSelector;


  const defaultSubscriptionSteps = (): Map<string, PropertiesSelector> => {

    let defaultSteps = new Map<string, PropertiesSelector>();

    defaultSteps.set("basics", SubscriptionBasicsFilter);
    defaultSteps.set("license", SubscriptionLicenseFilter);
    defaultSteps.set("charges", SubscriptionChargesFilter);
    defaultSteps.set("dates", SubscriptionDatesFilter);
    defaultSteps.set("address", SubscriptionAddressFilter);

    return defaultSteps;
  }

  const { t } = useTranslation();

  const appContext = useContext(AppContext);

  const history = useHistory();

  const [subscription, setSubscription] = React.useState<SubscriptionIF>(); 

  React.useEffect( () => { 

    (async () => {

      try {

        if( appContext.currentUser != null && appContext.authenticationClaims!.companiesAdmin != null ) {
  
          let subscription = props.databaseDocument as SubscriptionIF;

          if( subscription == null ) {

            subscription = await Factory.get().databaseService.databaseFactory.documentFromUrl( history.location.pathname ) as SubscriptionIF;
      
            if( subscription.collectionDatabase.collectionName()!== SubscriptionsCollection ) {
              throw new Error( "Unexpected collection name for subscription: " + subscription.collectionDatabase.collectionName());
            }
  
          }
          setSubscription( subscription );
        }
  
        log.traceOut("useEffect" );
  
      } catch( error ) {
        log.warn( "onOpenAccount()", error );
  
        await errorDialog( error);
  
        log.traceOut( "onOpenAccount()", error );
      }

    })();

    log.traceIn("useEffect" );

  },[props.databaseDocument,history.location.pathname,appContext.authenticationClaims,appContext.currentUser] );


  let actions;

  if( subscription != null ) {

    const onOpenAccount = async () => {

    try {
      log.traceIn( "onOpenAccount()", subscription.referenceHandle().title); 

      const webUrl = 
        Factory.get().configurationService.config( applicationConfiguration, "webUrl", activeLanguage());
  
      const companyAccountUrl = webUrl +
        Factory.get().configurationService.config( applicationConfiguration, "companyAccountPath", activeLanguage());
     
      window.open( companyAccountUrl );

        log.traceOut( "onOpenAccount()" );
        
      } catch( error ) {
        log.warn( "onOpenAccount()", error );

        await errorDialog( error);

        log.traceOut( "onOpenAccount()", error );
      }
    }

    if( subscription != null && subscription.id.value() != null && 
        appContext.currentUser != null && 
        subscription.billingMethod.value() === BillingMethods.WooCommerce &&
        subscription.email.compareTo( appContext.currentUser.email ) === 0 ) {

      actions = [
        { title: t("openAccount"), onAction: onOpenAccount }
      ]
    }
  }

  return (subscription == null ? null :
    <DocumentView 
      {...props}
      databaseDocument={subscription}
      inputTabs={props.inputTabs != null ? props.inputTabs : defaultSubscriptionSteps()} 
      actions={props.actions != null ? props.actions : actions} 
    />
  );
}



