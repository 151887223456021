
import { CollectionDatabase } from "../core/collectionDatabase";
import { DatabaseDocument } from "../../framework/databaseDocument";
import { log } from "../../framework/databaseService";
import { DefinitionProperty } from "../properties/definitionProperty";
import { GeolocationProperty } from "../properties/geolocationProperty";
import { SubdocumentProperty } from "../properties/subdocumentProperty";
import { CategoriesCollection, CompaniesCollection, DevicesCollection, ProjectsCollection, UnitsCollection, UsersCollection } from "../../api/collections";
import { LocationTypeDefinition } from "../../api/definitions";
import { LocationType, LocationTypes } from "../../api/definitions/locationType";
import { LocationIF } from "../../api/documents/locationIF";
import { Address } from "../subdocuments/address";
import { TenantProperty } from "../properties/tenantProperty";
import { Company } from "./company";
import { CompanyDocument, LocationDocument, UnitDocument } from "../../api/documents";
import { ReferencesProperty } from "../properties/referencesProperty";
import { User } from "./user";
import { BooleanProperty } from "../properties/booleanProperty";
import { Unit } from "./unit";
import { UserManager } from "../managers/userManager";
import { Project } from "./project";
import { TextsProperty } from "../properties/textsProperty";
import { Device } from "./device";
import { OwnerProperty } from "../properties/ownerProperty";
import { Category } from "./category";
import { Alert } from "./alert";
import { PhoneNumberProperty } from "../properties/phoneNumberProperty";
import { Database } from "../core/database";
import { CollectionGroupDatabase } from "../core/collectionGroupDatabase";


export class Location extends DatabaseDocument implements LocationIF {

    constructor( projectDocumentName : string, locationsCollection : CollectionDatabase<Location>, documentPath? : string  ) {

        super( projectDocumentName, locationsCollection, documentPath );

        try {
            this.title.required = true;

            this.company = new TenantProperty<Company>( this, CompaniesCollection, CompanyDocument );
            this.company.editable = false;

            const company = this.company.emptyDocument() as Company;

            this.unit = new OwnerProperty<Unit>( this, UnitsCollection, UnitDocument );
            
            this.locationType = new DefinitionProperty<LocationType>( this, LocationTypeDefinition, LocationTypes );

            this.premises = new TextsProperty( this );

            const addressSubdocument = new Address( this, "address");
            this.address = new SubdocumentProperty<Address>( this, addressSubdocument );

            this.phoneNumber = new PhoneNumberProperty( this );

            this.geolocation = new GeolocationProperty( this ); 

            this.geolocation.setCountryProperty( addressSubdocument.country );

            this.geolocation.setTypeProperty( this.locationType );

            this.geolocation.setTitleProperty( this.title );

            this.geolocation.setAddressProperty( this.address );

            this.allUsers = new BooleanProperty( this );

            this.categories = new ReferencesProperty<Category>( this, 
                () => this.parentDatabases( CategoriesCollection, {nearestIsCollectionGroup: false} ) as Database<Category>[],
                "locations" );

            this.units = new ReferencesProperty<Unit>( this, 
                () => this.parentDatabases( UnitsCollection, {nearestIsCollectionGroup: true} ) as Database<Unit>[] );

            this.projects = new ReferencesProperty<Project>( this, 
                () => this.parentDatabases( ProjectsCollection, {nearestIsCollectionGroup: true} ) as Database<Project>[],
                "locations" );

            this.users = new ReferencesProperty<User>( this,
                () => [this.parentCollectionGroup( UsersCollection ) as CollectionGroupDatabase<User>],
                "locations" );

            this.devices = new ReferencesProperty<Device>( this, 
                () => this.parentDatabases( DevicesCollection, {nearestIsCollectionGroup: true} ) as Database<Device>[],
                "location" );           
        
            this.alerts = new ReferencesProperty<Alert>( this, 
                company != null ? 
                    () => [company.alerts.collectionGroup()] : 
                    undefined, 
                "location" );    
            
    
            //log.traceInOut( "constructor()", LocationsCollection );

        } catch( error ) {

            log.warn( "constructor()", "Error initializing location", error );
            
            throw new Error( (error as any).message );
        }
    }

    indexKey() : string {
        return LocationDocument;
    }


    async updateUsers() : Promise<void> {

        await UserManager.getInstance().updateDocumentUsers( this );
    } 

    async onCreate() : Promise<void> {

        //log.traceIn( "onCreate()" );

        try {

            await super.onCreate();
    
            //log.traceOut( "onCreate()" );
  
        } catch( error ) {
            
            log.warn( "onCreated()", "Error handling created notification", error );
  
            throw new Error( (error as any).message );
        }
    }

    async onUpdate() : Promise<void> {

        //log.traceIn( "onUpdate()" );

        try {

            await super.onUpdate();
    
            //log.traceOut( "onUpdate()" );
  
        } catch( error ) {
            
            log.warn( "onUpdated()", "Error handling updated notification", error );
  
            throw new Error( (error as any).message );
        }
    }



    readonly company: TenantProperty<Company>;

    readonly unit: OwnerProperty<Unit>;

    readonly locationType : DefinitionProperty<LocationType>;

    readonly premises : TextsProperty;

    readonly address : SubdocumentProperty<Address>;

    readonly phoneNumber : PhoneNumberProperty;

    readonly geolocation : GeolocationProperty;

    readonly allUsers : BooleanProperty;

    readonly categories : ReferencesProperty<Category>;

    readonly units : ReferencesProperty<Unit>;

    readonly projects : ReferencesProperty<Project>;

    readonly users : ReferencesProperty<User>;

    readonly devices : ReferencesProperty<Device>; 

    readonly alerts : ReferencesProperty<Alert>; 

}
