
 
import * as Realm from "realm-web";

import { RealmService } from "../../../services/application/framework/realm/realmService";
import { AuthenticationService } from "../../../services/authentication/framework/authenticationService";
import { RealmAuthenticationService } from "../../../services/authentication/impl/realm/realmAuthenticationService";
import { SubscriptionManager } from "../../../services/billing/framework/subscriptionManager";
import { Target } from "../../../services/common/api/targets";
import { ConfigurationManager } from "../../../services/configuration/framework/configurationManager";
import { DatabaseFactory } from "../../../services/database/framework/databaseFactory";
import { DatabaseManager } from "../../../services/database/framework/databaseManager";
import { HealthguardDatabaseFactory } from "../../../healthguard/framework/healthguardDatabaseFactory";

import { ClientRealmDatabaseManager } from "../../../services/database/framework/realm/clientRealmDatabaseManager";
import { MediaManager } from "../../../services/storage/framework/mediaManager";
import { DatabaseAccess } from "../../../services/database/framework/databaseAccess";
import { HealthguardUserDatabaseAccess } from "../../../healthguard/framework/healthguardUserDatabaseAccess";
import CookiesPersistentState from "../persistentState/cookiesPersistentState";
import { PersistentState } from "../../../services/common/framework/persistentState";
import { EmailSender } from "../../../services/messaging/framework/email/emailSender";
import { SmsSender } from "../../../services/messaging/framework/sms/smsSender";
import { PushSender } from "../../../services/messaging/framework/push/pushSender";
import { PushReceiver } from "../../../services/messaging/framework/push/pushReceiver";
import { CapacitorPushReceiver } from "../capacitor/capacitorPushReceiver";
import { Translator } from "../../../services/common/framework/translator";

import { HealthguardConfigurationManager } from "../../../healthguard/framework/healthguardConfigurationManager";


import { I18nextTranslator } from "../translator/i18nextTranslator";
import { log } from "../app";

import realmConfig from "healthguard/data/settings/realmConfig.json";
import securityConfiguration from "healthguard/data/settings/security.json"; 
import { KeyManager } from "../../../services/security/framework/keyManager";

export class RealmClientService extends RealmService {

    constructor( target : Target ) {

        super( target );

        try {
            //log.traceIn( "constructor()", {target} ) ;

            const clientEncryption = (securityConfiguration as any)[target] != null ?
                (securityConfiguration as any)[target].clientEncryption : securityConfiguration.clientEncryption;

            //const key = process.env.REACT_APP_KEY!;

            const targetRealmConfig = (realmConfig as any)[target];

            this.app = new Realm.App({ id: targetRealmConfig.appId });

            this.authenticationService = new RealmAuthenticationService( this );

            this.configurationManager = new HealthguardConfigurationManager();

            this.databaseManager = new ClientRealmDatabaseManager( { clientEncryption : clientEncryption } );

            this.databaseFactory = new HealthguardDatabaseFactory( this.configurationManager, this.databaseManager );

            this.databaseAccess = new HealthguardUserDatabaseAccess( this.databaseFactory as HealthguardDatabaseFactory );

            this.persistentState = new CookiesPersistentState();

            this.pushReceiver = new CapacitorPushReceiver();  

            this.translator = new I18nextTranslator();
            
            //log.traceOut( "constructor()",  ) ;

        } catch (error) {
            log.warn( "Failed to initialize Realm", error ) ;

            throw new Error( (error as any).message );
        }
    }


    database() : globalThis.Realm.Services.MongoDB {
        return this.app.currentUser!.mongoClient("mongodb-atlas");
    }

    readonly app : Realm.App;

    readonly authenticationService? : AuthenticationService;

    readonly mediaManager? : MediaManager;

    readonly keyManager? : KeyManager; 

    readonly subscriptionManager? : SubscriptionManager;

    readonly configurationManager : ConfigurationManager;

    readonly databaseManager : DatabaseManager;

    readonly databaseFactory: DatabaseFactory;

    readonly databaseAccess : DatabaseAccess;

    readonly emailSender? : EmailSender;

    readonly smsSender? : SmsSender;

    readonly pushSender? : PushSender;

    readonly pushReceiver? : PushReceiver;

    readonly persistentState: PersistentState;

    readonly translator: Translator;

}