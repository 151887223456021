
export const MeasureClassifications = {

    Vaccination :      "vaccination",

    Testing :           "testing",

    AirConditioning:    "airConditioning",

    Disinfection:       "disinfection",

    RemoteWorking :      "remoteWorking",

    SocialDistancing:   "socialDistancing",

    Quarantining:       "quarantining",

    Organizing:         "organizing",

    PhysicalBarriers:   "physicalBarriers"

}

export type MeasureClassification = keyof (typeof MeasureClassifications);


