
export const Severities = {

    Catastrophic :  "catastrophic",

    Critical :      "critical",

    Moderate :      "moderate",

    Minor :         "minor",

    Negligible :    "negligible"
}

export type Severity = keyof (typeof Severities);


 