import React from 'react';
//import { log } from '../app/app';

interface FlagIconProps {
  countryId? : string,
}


interface FlagIconState { 
}

class FlagIcon extends React.PureComponent<FlagIconProps,FlagIconState> {

  constructor( props: FlagIconProps ) {
    
    super(props);

    this.state = { 
    } as FlagIconState;

    //log.traceInOut( "constructor()" );
  }

  protected flagIcon = ( countryId : string ) : JSX.Element => {
    return <FlagIcon countryId={countryId} />
  }

  render() { 

    //log.traceInOut( "render()", this.props.countryId )

    if( this.props.countryId == null ) {
      return null;
    } 

    return (
      <>
        <img
          loading="lazy"
          width="20"
          src={`https://flagcdn.com/w20/${this.props.countryId.toLowerCase()}.png`}
          srcSet={`https://flagcdn.com/w40/${this.props.countryId.toLowerCase()}.png 2x`}
          alt=""
        />
      </>
    );
  }
}


export default FlagIcon;

