import React from 'react';


interface ColorIconProps {
  color : string
}

interface ColorIconState { 
}

export default class ColorIcon extends React.PureComponent<ColorIconProps,ColorIconState> {

  constructor( props: ColorIconProps ) {
    
    super(props);

    this.state = { 
    } as ColorIconState;

    //log.traceInOut( "constructor()" );
  }

  
  render() {

    //log.traceInOut( "render()" );

    return(
      <>
        <div style={{ 
          width: "100%", 
          height: "100%", 
          borderRadius: "50%",
          backgroundColor: this.props.color}} />
      </>
    );
  }
}


