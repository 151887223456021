import React, { useContext } from 'react';

import DocumentView, { DocumentProps } from '../components/documentView';

import { PropertiesSelector } from 'services/database/api/core/propertiesSelector';

import DatabaseTable, { DatabaseTableProps } from 'ui/components/databaseTable';
import DatabaseList, { DatabaseListProps } from 'ui/components/databaseList';
import DatabaseCard, { DatabaseCardProps } from 'ui/components/databaseCard';
import DatabaseChart, { DatabaseChartProps } from 'ui/components/databaseChart';
import DatabaseView from '../components/databaseView';
import DatabaseGraph, { DatabaseGraphProps } from 'ui/components/databaseGraph';

import { DeviceIF } from 'services/database/api/documents/deviceIF';
import { CompanyDocument } from '../../services/database/api/documents';
import DatabaseMap, { DatabaseMapProps } from '../components/databaseMap';
import { AppContext } from '../app/appContext';
import { DatabaseQuery } from '../../services/database/api/core/databaseQuery';
import { HazardDocument } from '../../healthguard/api/healthguardDocuments';
import { ReadinessLevelDefinition } from '../../services/database/api/definitions';
import { AlertsCollection } from '../../services/database/api/collections';
import { DatabaseViewType, DatabaseViewTypes } from '../components/databaseViewMenu';

export const defaultDeviceTableColumns = [
  CompanyDocument,
  "location",
  "premise", 
  "title",
  "deviceType",
  ReadinessLevelDefinition];

export const defaultHighlightedDeviceProperty = ReadinessLevelDefinition;

export function DevicesTable( props: DatabaseTableProps ) {
  return (
    <DatabaseTable {...props} featuredProperties={props.featuredProperties != null ? props.featuredProperties : defaultDeviceTableColumns}/>
  );
}


export function DevicesList( props: DatabaseListProps ) {
  return (
    <DatabaseList {...props} />
  );
}

export function DevicesCard( props: DatabaseCardProps ) {
  return (
    <DatabaseCard 
      {...props} 
      highlightedPropertyKey={props.highlightedPropertyKey != null ? props.highlightedPropertyKey : defaultHighlightedDeviceProperty}
    />
  );
}

export function DevicesChart( props: DatabaseChartProps ) {
  return (
    <DatabaseChart 
      {...props} 
    />
  );
}


export function DevicesGraph( props: DatabaseGraphProps ) {
  return (
    <DatabaseGraph 
      {...props} 
      previousPropertyKey={"location"}
      directed={false}
    />
  );
}

export function DevicesMap( props: DatabaseMapProps ) {

  const appContext = useContext(AppContext);

  return (
    <DatabaseMap 
      {...props} 
      geolocationPropertyKey={"geolocation"}
      statusPropertyKey={"status"}
      colorPropertyKey={ReadinessLevelDefinition} 
      iconPropertyKey={"deviceType"}
      defaultCountry={appContext.currentCompany?.country.value()} 
    />
  );
}


export function DevicesView(props: { 
  databaseQuery : DatabaseQuery<DeviceIF>,
  title? : string,
  featuredProperties?: string[],
  highlightedPropertyKey?: string }) {

  return ( 
    <DatabaseView 
      databaseQuery={props.databaseQuery} 
      title={props.title}
      allowMap={true}
      featuredProperties={props.featuredProperties != null ? props.featuredProperties : defaultDeviceTableColumns}
      highlightedPropertyKey={props.highlightedPropertyKey != null ? props.highlightedPropertyKey : defaultHighlightedDeviceProperty}
      hideDateFilter={true}
      defaultDatabaseViewType={DatabaseViewTypes.Table as DatabaseViewType} 
    /> 
  );
}

export function DeviceView(props : DocumentProps) {

  const DeviceBasicsFilter = {

    includePropertyKeys: [
      "unit",
      "deviceType",
      "hazard", 
      "location",
      "premise",
      "geolocation",
      "attachments",
      "links",
      "description"
    ]

  } as PropertiesSelector;


  const DeviceConnectionFilter = {

    includePropertyKeys: [
      "connection"
    ]

  } as PropertiesSelector;


  const DeviceStatusFilter = {

    includePropertyKeys: [
      ReadinessLevelDefinition,
      HazardDocument,
      "lastAlertSeverity",
      "lastAlertStatus",
      "lastAlertAt"
    ]

  } as PropertiesSelector;

  const DeviceAlertsFilter = {

    includePropertyKeys: [
      AlertsCollection
    ]

  } as PropertiesSelector;

  const defaultDeviceSteps = (): Map<string, PropertiesSelector> => {

    let defaultSteps = new Map<string, PropertiesSelector>();

    defaultSteps.set("basics", DeviceBasicsFilter);
    defaultSteps.set("connection", DeviceConnectionFilter);
    defaultSteps.set("status", DeviceStatusFilter);
    defaultSteps.set(AlertsCollection, DeviceAlertsFilter); 

    return defaultSteps;
  }
  return (<DocumentView 
    {...props}
    inputTabs={props.inputTabs != null ? props.inputTabs : defaultDeviceSteps()} 
    />);
}



