import { Gathering } from '../documents/gathering';

import { log } from '../../../services/database/framework/databaseService';

import { Factory } from '../../../services/common/api/factory';

import gatheringsConfiguration from "../../../healthguard/data/settings/gatherings.json";

const millisecondsInADay = 24*60*60*1000;

export class GatheringManager  {

    static getInstance() : GatheringManager {
        if( GatheringManager._instance == null ) {
            GatheringManager._instance = new GatheringManager();
        }
        return GatheringManager._instance;
    }

    async updateTrackingDates( gathering: Gathering ) : Promise<void> {

        try {
            log.traceIn("updateTrackingDates()");

            const gatheringDate = gathering.startDate.value();

            if( gatheringDate != null ) {

                const trackUsersBeforeDays = Factory.get().configurationService.config( 
                    gatheringsConfiguration, "trackUsersBeforeDays" );

                const trackUsersFromDate = new Date();

                trackUsersFromDate.setTime( gatheringDate.getTime() - (+trackUsersBeforeDays)*millisecondsInADay );

                gathering.trackUsersFrom.setDate( trackUsersFromDate );
    
                const trackUsersAfterDays = Factory.get().configurationService.config( 
                    gatheringsConfiguration, "trackUsersAfterDays" );

                const trackUsersToDate = new Date();

                trackUsersToDate.setTime( gatheringDate.getTime() + (+trackUsersAfterDays)*millisecondsInADay );

                gathering.trackUsersTo.setDate( trackUsersToDate );
            }

            log.traceOut("updateTrackingDates()");

        } catch (error) {
            log.warn("Error updating updateTrackingDates", error);
        }
    }


    private static _instance? : GatheringManager;

}

