
import { Factory } from '../../../common/api/factory';
import { AuthenticationService, log } from '../../framework/authenticationService';
import { User } from '../../../database/impl/documents/user';

import { AuthenticationClaims } from '../../api/authenticationClaims';
import { RealmService } from '../../../application/framework/realm/realmService';

import authenticationConfiguration from "../../../../healthguard/data/settings/authentication.json";

 
export class RealmAuthenticationService extends AuthenticationService {

    constructor( applicationService : RealmService ) {

        super();

        //log.traceInOut("constructor()")

        this._applicationService = applicationService;

        try {

        } catch (error) {

            log.warn("constructor()", "Error constructing realm authenticator", error);

            throw new Error( (error as any).message );
        }
    }


    async init() : Promise<void> {

        log.traceIn("init()")

        try {

            await super.init();

            if( Factory.get().isClient() ) {

                this.initialized = true;

                const initialRealmUser = this._applicationService.app.currentUser;

                if( initialRealmUser != null ) {
                            
                    await this.updateAuthenticatedUser();
                    
                }
                else {
                    await this.clearAuthentication( false );
                }
            }

            log.traceOut("init()")

        } catch (error) {

            log.warn("init()", "Error starting realm authenticator", error);

            throw new Error( (error as any).message );
        }
    }

    async registerEmailUser( email : string, password : string ): Promise<any> {

        log.traceIn("registerEmailUser()", {email});

        try {
            await this._applicationService.app.emailPasswordAuth.registerUser(email, password);

            log.traceOut("registerEmailUser()" );
            return null;

        } catch (error) {

            log.warn("registerEmailUser()", "Error registering user with realm", error);

            throw new Error((error as any).message);
        }    
    }

    async signInEmailUser( email : string, password : string ): Promise<any> {

        log.traceIn("signInEmailUser()", {email});

        try {
            const credentials = this._applicationService.app.emailPassword( email, password );

            const user = await Factory.get().authenticationService!.signIn( credentials );

            log.traceOut("signInEmailUser()" );
            return user;

        } catch (error) {

            log.warn("signInEmailUser()", "Error signining in user with realm", error);

            throw new Error((error as any).message);
        }    
    }

    async signIn( credentials : any ): Promise<User | undefined> {

        log.traceIn("signIn()", {credentials});

        try {
            const realmUser = await this._applicationService.app.logIn(credentials);

            if( this._applicationService.app.currentUser == null ) {
                throw new Error( "Login failed" );
            }
            
            if( realmUser.id !== this._applicationService.app.currentUser!.id) {
                throw new Error( "Unexpected ID for logged in user")
            }

            const authenticatedUser = await this.updateAuthenticatedUser();

            log.traceOut("signIn()" );
            return authenticatedUser;

        } catch (error) {

            log.warn("signIn()", "Error logging out from firebase auth", error);

            throw new Error((error as any).message);
        }    
    }

    async signOut( delay? : number ): Promise<void> {

        log.traceIn("signOut()" )

        try {

            if( this._applicationService.app.currentUser == null ) {

                log.traceOut("signOut()", "No authenticated user to sign out" );
                return;
            }

            if( !!delay ) {

                setTimeout( this.signOut, delay );
            }
            else {
                await this._applicationService.app.currentUser.logOut();
            }

            log.traceOut("signOut()" )

        } catch (error) {

            log.warn("signOut()", "Error logging out from firebase auth", error);

            throw new Error( (error as any).message );
        }
    }

    async verifyEmail(): Promise<void> {

        throw new Error( "Verify email not supported for Realm")
    }

    async verifyPhone( credentials? : any ): Promise<void> {

        throw new Error( "Verify phone not supported for Realm")
    }

    async checkAuthentication( currentUser : User ) : Promise<any | undefined> {

        log.traceIn("checkAuthentication()", currentUser );

        const realmUser = this._applicationService.app.currentUser;

        try {

            if( realmUser == null ) {

                super.clearAuthentication();

                log.traceOut("checkAuthentication()", "No realm user")
                return undefined;
            }

            if( realmUser.id !== currentUser.authenticationId.value() ) {

                super.clearAuthentication();

                await realmUser.logOut();

                log.traceOut("checkAuthentication()", "mismatching authentication IDs")
                return undefined;
            }

            await realmUser.refreshCustomData();

            if( realmUser.customData == null ) {

                super.clearAuthentication();

                await realmUser.logOut();

                log.traceOut("checkAuthentication()", "No custom data" );
                return undefined;
            }

            log.traceOut("checkAuthentication()", "OK", realmUser.customData  );
            return realmUser.customData ;

        } catch (error ) {
            log.warn("checkAuthentication()", "Error reading firebase auth info", error);

            super.clearAuthentication( (error as any).message );

            if( realmUser != null ) {
                await realmUser.logOut();
            }

            log.traceOut("checkAuthentication()", "error" ); 
            return undefined;
        }
    }


    private async updateAuthenticatedUser(): Promise<User|undefined> {

        log.traceIn("updateAuthenticatedUser()" );

        try {
            let realmUser = this._applicationService.app.currentUser;

            if( realmUser == null ) {

                super.clearAuthentication();

                log.traceOut("updateAuthenticatedUser()", "No current user")
                return undefined;
            }

            await realmUser.refreshCustomData();

            realmUser = this._applicationService.app.currentUser;
        
            if( realmUser == null ) {

                super.clearAuthentication();
                
                log.traceOut("updateAuthenticatedUser()", "No current user after custom data refresh")
                return undefined;
            }

            const updatedAuthenticationClaims = this.readCustomData();

            log.debug("updateAuthenticatedUser()", {updatedAuthenticationClaims} );

            if( updatedAuthenticationClaims.verifyEmail != null || 
                updatedAuthenticationClaims.verifyPhone != null ) {

                await this.updateAuthentication( undefined, updatedAuthenticationClaims );
        
                log.traceOut("updateAuthenticatedUser()", "email or phone verification required");
                return undefined;
            }

            const authenticatedUser = await this.readCurrentUser( updatedAuthenticationClaims );

            if( authenticatedUser == null ) {

                await this.clearAuthentication();
                
                this.signOut();

                log.traceOut("updateAuthenticatedUser()", "Realm user not found in DB")
                return undefined;
            }

            log.debug("updateAuthenticatedUser()", {authenticatedUser} );

            await this.updateAuthentication( authenticatedUser, updatedAuthenticationClaims );

            log.traceOut("updateAuthenticatedUser()", authenticatedUser.databasePath() );
            return authenticatedUser;

        } catch (error ) {
            log.warn("updateAuthenticatedUser()", "Error reading firebase auth info", error);

            super.clearAuthentication( (error as any).message );

            await this.signOut();

            log.traceOut("updateAuthenticatedUser()", "error" );

            return undefined;
        }
    }


    private async readCurrentUser( authenticationClaims : AuthenticationClaims): Promise<User | undefined> {

        log.traceIn("readCurrentUser()", {authenticationClaims} )

        try {   

            const realmUser = this._applicationService.app.currentUser!;

            if( authenticationClaims.userPath == null ) {

                log.traceOut("readCurrentUser()", "Path not found" );
                return undefined;
            }

            const user = await Factory.get().databaseService.databaseFactory.documentFromUrl( 
                authenticationClaims.userPath ) as User;

            if( user == null ) {

                log.traceOut("readCurrentUser()", "Not found" );
                return undefined;
            }

            if( authenticationClaims.userId !== user.id.value() ) {
                log.traceOut("readCurrentUser()", "Mismatching user ID" );
                return undefined;
            }

            if( JSON.stringify( authenticationClaims.unitIds ) !== JSON.stringify( user.unit.ids() ) ) {
                log.traceOut("readCurrentUser()", "Mismatching unit ID" );
                return undefined;
            }

            if( authenticationClaims.companyId !== user.company.id() ) {
                log.traceOut("readCurrentUser()", "Mismatching company ID" );
                return undefined;
            } 

            if( realmUser.profile.email == null || user.email.value()!.toLowerCase() !== realmUser.profile.email.toLowerCase() ) {
                throw new Error( "Email mismatch between firebase user and database user")
            }

            /*
            if( realmUser.profile.phoneNumber != null && 
                user.phoneNumber.compareValue( realmUser.profile.phoneNumber ) !== 0 ) {
                throw new Error( "Phone mismatch between firebase user and database user")
            }
            */
        
            log.traceOut("readCurrentUser()", user );
            return user;

        } catch (error) {
            log.warn("readCurrentUser()", "Error reading current user", error);

            throw new Error( (error as any).message );
        }
    }


    async refreshAuthenticationClaims() : Promise<AuthenticationClaims | undefined> {

        log.traceIn("refreshAuthenticationClaims()" );

        try {

            this._waitingForClaims = true;

            const authenticationClaims = await this.waitForCustomData();

            this._waitingForClaims = false;

            await this.updateAuthenticatedUser(); 

            log.traceOut("refreshAuthenticationClaims()", {authenticationClaims} );
            return authenticationClaims;

        } catch (error) {
            log.warn("readCurrentUser()", "Error reading current user", error);

            throw new Error( (error as any).message );
        }
    }

    private async waitForCustomData( key? : string, value? : string | number | boolean ): Promise<any | undefined> {

        log.traceIn("waitForCustomData()" );

        try {
            const customData = await new Promise<any | undefined>( resolve => {

                const startTime = new Date().getTime();
                
                const waitPeriod = Factory.get().configurationService.config( 
                    authenticationConfiguration.timeouts, "firebaseAuthenticationWaitPeriod" ) as number;

                const waitInterval = Factory.get().configurationService.config( 
                    authenticationConfiguration.timeouts, "firebaseAuthenticationWaitInterval" ) as number;
                
                const maxTime = startTime + waitPeriod;

                const checkCustomData = async () => {

                    log.traceIn("checkCustomData()" );

                    if( !this._waitingForClaims ) {
                        resolve( undefined );
                        log.traceOut("checkCustomData()", "Not waiting for claims" );
                        return;
                    }

                    const currentTime = new Date().getTime();

                    const realmUser = this._applicationService.app.currentUser!;

                    if( realmUser == null ) {
                        log.traceOut("checkCustomData()", "No realm user" );
                        resolve( undefined );
                        return;
                    }

                    await realmUser.refreshCustomData();

                    if( realmUser.customData == null ) {

                        resolve( undefined );

                        log.traceOut("checkCustomData()", "No claims" );
                        return;
                    }
                    
                    if (realmUser.customData.authenticationId == null ||
                        (key != null && realmUser.customData[key] == null) ||
                        (key != null && value != null && realmUser.customData[key] !== value)) {

                        if( currentTime < maxTime ) {

                            log.traceOut("checkCustomData()", "Still no custom claims data, set new timeout", startTime, currentTime, maxTime );
                            setTimeout( checkCustomData, waitInterval );
                        }
                        else {
                            log.traceOut("checkCustomData()", "No custom claims data after timeout" );
                            resolve( undefined );
                        }
                    }
                    else {

                        const result = key != null ? realmUser.customData[key] : authenticationClaims;
 
                        log.traceOut("checkCustomData()", "Custom data found", result );
                        resolve( result );
                    }
                }
                checkCustomData();
            });

            let authenticationClaims : AuthenticationClaims | undefined;

            if( customData != null ) {
                authenticationClaims = this.readCustomData();
            }

            log.traceOut("waitForCustomData()", {authenticationClaims} );
            return authenticationClaims;

        } catch (error) {
            log.warn("waitForCustomData()", "Error checking custom claims", error);

            return undefined;
        }
    }

    private readCustomData() : AuthenticationClaims {

        log.traceIn("readCustomData()" );

        try {
            let realmUser = this._applicationService.app.currentUser!;

            const updatedAuthenticationClaims = {} as AuthenticationClaims;

            if( realmUser.customData != null ) {

                Object.assign( updatedAuthenticationClaims, realmUser.customData );

                if( realmUser.customData.verifyEmail == null || realmUser.customData.emailVerified ) {
                    delete updatedAuthenticationClaims.verifyEmail;
                }

                if( realmUser.customData.verifyPhone == null ) {

                    delete updatedAuthenticationClaims.verifyPhone;
                }
            }

            log.traceOut("readCustomData()", updatedAuthenticationClaims );
            return updatedAuthenticationClaims;

        } catch (error) {
            log.warn("readCustomData()", "Error checking custom claims", error);

            throw new Error( (error as any).message );
        }
    }


    /*
    private async waitForVerification( waitForEmail : boolean, waitForPhone : boolean ): Promise<AuthenticationClaims | undefined> {

        log.traceIn("waitForVerification()" );

        try {
            const authenticationClaims = await new Promise<AuthenticationClaims | undefined>( resolve => {

                const startTime = new Date().getTime();

                const waitPeriod = Factory.get().configurationService.config( 
                    authenticationConfiguration.timeouts, "firebaseVerificationWaitPeriod" ) as number;

                const waitInterval = Factory.get().configurationService.config( 
                    authenticationConfiguration.timeouts, "firebaseVerificationWaitInterval" ) as number;

                const maxTime = startTime + waitPeriod;

                const checkVerified = async ( checkEmail : boolean, checkPhone : boolean  ) => {

                    log.traceIn("checkVerified()" );

                    if( !this._waitingForVerification ) {
                        resolve( undefined );
                        return;
                    }

                    const currentTime = new Date().getTime();

                    const realmUser = this._applicationService.app.currentUser!;

                    if( realmUser == null ) {
                        log.traceOut("checkCustomData()", "No realm user" );
                        resolve( undefined );
                        return;
                    }

                    await realmUser.refreshCustomData();

                    if( realmUser.customData == null ) {

                        log.traceOut("checkVerified()", "No ID token" );
                        resolve( undefined );
                    }
                    else if( 
                        (checkEmail && !!realmUser.customData.emailVerificationRequired && !realmUser.customData.emailVerified) 
                        || 
                        (checkPhone && !!realmUser.customData.phoneVerificationRequired && !realmUser.customData.phoneVerified) 
                        ) {

                        if( currentTime < maxTime ) {

                            log.traceOut("checkVerified()", "verfication still required, set new timeout", startTime, currentTime, maxTime );
                            setTimeout( checkVerified, waitInterval );
                        }
                        else {
                            log.traceOut("checkVerified()", "No verification update after timeout" );
                            resolve( undefined );
                        }
                    }
                    else {
                        log.traceOut("checkVerified()", "Verification complete", {email: checkEmail}, {phone: checkPhone} );
                        resolve( realmUser.customData );
                    }
                }
                checkVerified( waitForEmail, waitForPhone );
            });

            log.traceOut("waitForVerification()", {authenticationClaims} );
            return authenticationClaims;

        } catch (error) {
            log.warn("waitForVerification()", "Error checking email verification claims", error);

            return undefined;
        }
    }
    */

    initialized : boolean = false;

    private _waitingForClaims = false;

    //private _waitingForVerification = false;

    private _applicationService : RealmService

}