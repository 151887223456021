
import { ServiceIF } from "../../../common/api/serviceIF";
import { DatabaseFactoryIF } from "./databaseFactoryIF";
import { UserIF } from "../documents/userIF";
import { CompanyIF } from "../documents/companyIF";
import { DatabaseManagerIF } from "./databaseManagerIF";
import { UnitIF } from "../documents/unitIF";
import { DatabaseAccessIF } from "./databaseAccessIF";


export const CollectionGroupPathSuffix = "-group";

export const NewDocumentId = "new";

export const DocumentNameKey = "name";

export const ReadinessLevelKey = "readinessLevel";

export const ReadinessLevelsKey = "readinessLevels";

export const TestResultKey = "result";

export const LocationTypeKey = "locationType";

export const ResidenceTypeKey = "residenceType";

export const Archived = "archived";

export const OwnerIds = "ownerIds";

export const IdSuffix = "Id";
export const IdsSuffix = "Ids";

export const OwnerTitleSuffix = "Title";
export const OwnerTitlesSuffix = "Titles";

export const CustomerIdCompanyProperty = "customerId";
export const ContactEmailCompanyProperty = "contactEmail";

export const AuthenticationIdUserProperty = "authenticationId";
export const EmailUserProperty = "email";

export const AllUsersProperty = "allUsers";
export const SymbolicUsersProperty = "symbolicUsers";


export interface DatabaseServiceIF extends ServiceIF {

    init() : Promise<void>,

    currentUser() : UserIF | undefined;

    currentUnit() : UnitIF | undefined;

    currentCompany() : CompanyIF | undefined;

    companyWithCustomerId( customerId : string ) : Promise<CompanyIF | undefined>,

    companyWithContactEmail( contactEmail : string ) : Promise<CompanyIF | undefined>,

    userWithAuthenticationId( authenticationId : string ) : Promise<UserIF | undefined>,

    userWithEmail( email : string ) : Promise<UserIF | undefined>,

    readonly databaseFactory : DatabaseFactoryIF;

    readonly databaseManager : DatabaseManagerIF;

    readonly databaseAccess : DatabaseAccessIF;


}

