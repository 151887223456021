import { DatabaseDocument } from "../../framework/databaseDocument";
import { Observable } from "../../../common/impl/observable";
import { UserAccess } from "../../../common/api/userAccess";

import { DatabaseIF } from "../../api/core/databaseIF";
import { ReferenceHandle } from "../../api/core/referenceHandle";
import { Monitor } from "../../../common/api/monitor";
import { log } from "../../framework/databaseService";
import { Observation } from "../../../common/api/observation";
import { Factory } from "../../../common/api/factory";


export abstract class Database<DerivedDocument extends DatabaseDocument> 
    extends Observable implements DatabaseIF<DerivedDocument> {

    constructor(
        collectionName: string,
        queryDocumentName: string | undefined,
        documentNames : string[],
        owner? : DatabaseDocument ) {

        super();

        this._collectionName = collectionName;

        this._queryDocumentName = queryDocumentName;

        this._documentNames = documentNames;

        this._owner = owner;

        //log.traceInOut( "("+this.collectionName()+")", "constructor()" );
    }

    owner() : DatabaseDocument | undefined {
        return this._owner;
    }

    collectionName() : string {
        return this._collectionName;
    }

    queryDocumentName() : string | undefined {
        return this._queryDocumentName;
    }

    documentNames() : string[] {
        return this._documentNames;
    }

    defaultDocumentName() : string {
        return this._documentNames[0]!; 
    }

    protected async notifyMonitor( observation : Observation, monitor : Monitor, databaseDocuments : Map<string,DerivedDocument> ) {
    
        log.traceIn("(" + this.collectionName()+ ")", "notifyMonitor()", {observation}, {monitor}, {databaseDocuments} );

        try {
            if( monitor.onNotify != null ) {

                let result;
                if( monitor.objectIdsFilter != null && monitor.objectIdsFilter.length > 0 ) {

                    result = new Map<string,DerivedDocument>();

                    for( const filteredObjectId of monitor.objectIdsFilter ) {

                        log.debug("(" + this.collectionName()+ ")", "notifyMonitor()", {filteredObjectId});

                        const filteredDocument = databaseDocuments.get( this.documentCacheKey( filteredObjectId ) );

                        log.debug("(" + this.collectionName()+ ")", "notifyMonitor()", {filteredDocument});

                        if( filteredDocument != null ) {
                            result.set( filteredObjectId, filteredDocument );
                        }
                    }
                }
                else {
                    result = new Map<string,DerivedDocument>( [...databaseDocuments] );
                }

                if( result.size === 1 ) {

                    const resultDocument = Array.from( result.values() )[0];

                    await monitor.onNotify( this, observation, resultDocument.databasePath( true ), resultDocument  );

                    log.debug("(" + this.collectionName()+ ")", "notifyMonitor()", {resultDocument} );

                }
                else {
                    await monitor.onNotify( this, observation, this.databasePath( true ), result  );

                    log.debug("(" + this.collectionName()+ ")", "notifyMonitor()", {result} );
                }
            } 

            log.traceOut("(" + this.collectionName()+ ")", "notifyMonitors()");

        } catch (error) {

            log.warn("Error notyfying monitors for", this.collectionName(), error);
        }
    }

    protected documentCacheKey( documentPath : string ) {
        return documentPath.split("?")[0];
    }

    userAccess() : UserAccess {

        if( this._userAccess != null ) {
            return this._userAccess;
        }

        return Factory.get().databaseService.databaseAccess.userAccess( this.databasePath( true ) );
    }

    setUserAccess( userAccess : UserAccess ) {
        this._userAccess = userAccess;
    }



    abstract newDocument( documentPath?: string ): DerivedDocument;

    abstract databasePath( includeDocumentName? : boolean ) : string;

    abstract documents(): Promise<Map<string,DerivedDocument>>;

    abstract referenceHandles(): Promise<Map<string,ReferenceHandle<DerivedDocument>>>;

    abstract document( documentPath: string ): Promise<DerivedDocument | undefined>;

    private _userAccess? : UserAccess;

    private readonly _owner? : DatabaseDocument;

    private readonly _collectionName : string;

    private readonly _queryDocumentName: string | undefined;

    private readonly _documentNames : string[];

}