
export const HazardTypes = {

    Chemical :  "chemical",

    Physical :  "physical",

    Biological : "biological",

    Ergonomic :   "ergonomic",

    Psychosocial : "psychosocial" 
}

export type HazardType = keyof (typeof HazardTypes);


