
export const Probabilities = {

    Frequent :      "frequent",

    Probable :      "probable",

    Occassional :   "occassional",

    Remote :        "remote",

    Improbable :    "improbable"

}

export type Probability = keyof (typeof Probabilities); 


