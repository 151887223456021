import React from 'react';

import DocumentView from './documentView';

import { PropertiesSelector } from '../../services/database/api/core/propertiesSelector';
import { CollectionPropertyIF } from '../../services/database/api/properties/collectionPropertyIF';
import { DatabaseDocumentIF } from '../../services/database/api/core/databaseDocumentIF';


export default function SimpleDatabaseView( props: { collectionProperty : CollectionPropertyIF<DatabaseDocumentIF>} ) {

  const steps = new Map<string, PropertiesSelector>();
  
  steps.set(props.collectionProperty.collectionName(), {

    includePropertyKeys: [props.collectionProperty.collectionName()],
  
  } as PropertiesSelector );
  
  return ( <DocumentView inputTabs={steps} databaseDocument={props.collectionProperty.parentDocument()}/> );
}


