import { Definition } from "../../services/database/api/definitions";

export const HealthguardDefinition = {

    ...Definition,

    ConsentResponse   : "consentResponse",

    DeviceType   : "deviceType",

    HealthguardCollection: "healthguardCollection",

    HealthguardDocument: "healthguardDocument",

    HealthguardSubdocument: "healthguardSubdocument",

    HealthCondition : "healthCondition",

    HazardType : "hazardType",

    IllnessStage   : "illnessStage",

    MedicalCertification   : "medicalCertification",

    MeasureClassification  : "measureClassification",

    MeasureState   : "measureState",

    MeasureType   : "measureType",

    MeasureOrigin   : "measureOrigin",

    QuarantineReason: "quarantineReason",

    TestResult      : "testResult",

    TestType      : "testType",

    Symptom         : "symptom",
}

export type HealthguardDefinition = keyof (typeof HealthguardDefinition);

export const HealthguardDefinitions = Object.values( HealthguardDefinition );

export const ConsentResponseDefinition = HealthguardDefinition.ConsentResponse as HealthguardDefinition;

export const DeviceTypeDefinition = HealthguardDefinition.DeviceType as HealthguardDefinition;

export const HealthguardCollectionDefinition = HealthguardDefinition.HealthguardCollection as HealthguardDefinition;

export const HealthguardDocumentDefinition = HealthguardDefinition.HealthguardDocument as HealthguardDefinition;

export const HealthguardSubdocumentDefinition = HealthguardDefinition.HealthguardSubdocument as HealthguardDefinition;

export const HealthConditionDefinition = HealthguardDefinition.HealthCondition as HealthguardDefinition;

export const HazardTypeDefinition = HealthguardDefinition.HazardType as HealthguardDefinition;

export const IllnessStageDefinition = HealthguardDefinition.IllnessStage as HealthguardDefinition;

export const MedicalCertificationDefinition = HealthguardDefinition.MedicalCertification as HealthguardDefinition;

export const MeasureClassificationDefinition = HealthguardDefinition.MeasureClassification as HealthguardDefinition;

export const MeasureStateDefinition = HealthguardDefinition.MeasureState as HealthguardDefinition;

export const MeasureTypeDefinition = HealthguardDefinition.MeasureType as HealthguardDefinition;

export const MeasureOriginDefinition = HealthguardDefinition.MeasureOrigin as HealthguardDefinition;

export const QuarantineReasonDefinition = HealthguardDefinition.QuarantineReason as HealthguardDefinition;

export const TestResultDefinition = HealthguardDefinition.TestResult as HealthguardDefinition;

export const TestTypeDefinition = HealthguardDefinition.TestType as HealthguardDefinition;

export const SymptomDefinition = HealthguardDefinition.Symptom as HealthguardDefinition; 






