import { Factory } from '../../../common/api/factory';
import { ReferenceHandle } from '../../api/core/referenceHandle';
import { log } from '../../framework/databaseService';
import { Unit } from '../documents/unit';
import { User } from '../documents/user';


export class UnitManager  {

    static getInstance() : UnitManager {
        if( UnitManager._instance == null ) {
            UnitManager._instance = new UnitManager();
        }
        return UnitManager._instance;
    }

    async handleCreateUnit( createdOrganizationUnit: Unit ) {

        try {
            log.traceIn("handleCreateUnit()");

            log.traceOut("handleCreateUnit()");

        } catch (error) {
            log.warn("Error auditing document create", error);
        }
    }

    async handleUpdateUnit( updatedOrganizationUnit: Unit ) {

        try {
            log.traceIn("handleUpdateUnit()");

          
            log.traceOut("handleUpdateUnit()");

        } catch (error) {
            log.warn("Error auditing document changes", error);
        }
    }

    async handleDeleteUnit( deletedOrganizationUnit: Unit ) {

        try {
            log.traceIn("handleDeleteUnit()");


            log.traceOut("handleDeleteUnit()"); 

        } catch (error) {
            log.warn("Error auditing document delete", error);
        }
    }


    async attachUser( unit : Unit, userReferenceHandle: ReferenceHandle<User> ) : Promise<void> {

        try {
            log.traceIn("attachUser()", unit.title.value(), userReferenceHandle.title );

            if( !unit.userAccess().write ) {
                throw new Error( "permissionDenied"); 
            }

            const user = await Factory.get().databaseService.databaseFactory.documentFromUrl( 
                userReferenceHandle.path ) as User;

            if( user == null ) {
                throw new Error( "notFound" );
            }

            let allowed = false;

            if( !Factory.get().databaseService.currentCompany()?.restrictSymbolicOwners.value() ) {

                allowed = true;
            }
            else {

                for( const allowedSymbolicUnit of user.allowedSymbolicUnits.referenceHandles().values() ) {

                    if( unit.databasePath().startsWith( allowedSymbolicUnit.path.split("?")[0] ) ) {

                        allowed = true;
                        break;
                    }
                }
            }

            if( !allowed ) {
                throw new Error( "symbolicUnitNotAllowed"); 
            }

            unit.symbolicUsers.setDocument( userReferenceHandle );

            await unit.update();

            log.traceOut("attachUser()");

        } catch (error) {
            log.warn("Error moving user", error);

            throw new Error( (error as any).message );
        }
    }


    private static _instance? : UnitManager;

}

