
import { Company } from "../../../services/database/impl/documents/company";
import { Hazard } from "./hazard";
import { User } from "../../../services/database/impl/documents/user";
import { Residence } from "../../../services/database/impl/documents/residence";
import { Factory } from "../../../services/common/api/factory";
import { RegistrationIF } from "../../api/documents/registrationIF";
import { Gathering } from "./gathering";
import { log } from "../../../services/database/framework/databaseService";
import { CollectionDatabase } from "../../../services/database/impl/core/collectionDatabase";
import { DatabaseDocument } from "../../../services/database/framework/databaseDocument";
import { OptionsSource } from "../../../services/database/impl/core/optionsSource";
import { ReferenceProperty } from "../../../services/database/impl/properties/referenceProperty";
import { ReferencesProperty } from "../../../services/database/impl/properties/referencesProperty";
import { TextProperty } from "../../../services/database/impl/properties/textProperty";
import { TextsProperty } from "../../../services/database/impl/properties/textsProperty";
import { AlertsCollection, CategoriesCollection, CompaniesCollection, LocationsCollection, ProjectsCollection, UnitsCollection, UsersCollection } from "../../../services/database/api/collections";
import { IncidentRegistrationsCollection, QuarantineRegistrationsCollection, RegistrationsCollection, SickLeaveRegistrationsCollection, SymptomRegistrationsCollection, TestRegistrationsCollection, VaccineRegistrationsCollection } from "../../api/registrationCollections";
import { GatheringsCollection, HazardsCollection, MeasuresCollection } from "../../api/healthguardCollections";
import { HealthguardUser } from "./healthguardUser";
import { HealthguardCompany } from "./healthguardCompany";
import { RegistrationManager } from "../managers/registrationManager";
import { Unit } from "../../../services/database/impl/documents/unit";
import { TenantProperty } from "../../../services/database/impl/properties/tenantProperty";
import { OwnerProperty } from "../../../services/database/impl/properties/ownerProperty";
import { IncidentRegistrationDocument, QuarantineRegistrationDocument, RegistrationDocument, SickLeaveRegistrationDocument, SymptomRegistrationDocument, TestRegistrationDocument, VaccineRegistrationDocument } from "../../api/registrationDocuments";
import { HealthguardCompanyDocument, HealthguardUnitDocument, HealthguardUserDocument } from "../../api/healthguardDocuments";
import { HealthguardLocation } from "./healthguardLocation";
import { HealthguardProject } from "./healthguardProject";
import { Measure } from "./measure";
import { HealthguardCategory } from "./healthguardCategory";
import { IncidentRegistration } from "./incidentRegistration";
import { QuarantineRegistration } from "./quarantineRegistration";
import { SickLeaveRegistration } from "./sickLeaveRegistration";
import { SymptomRegistration } from "./symptomRegistration";
import { TestRegistration } from "./testRegistration";
import { VaccineRegistration } from "./vaccineRegistration";
import { Alert } from "../../../services/database/impl/documents/alert";
import { Identifications } from "../../../services/database/api/definitions/identification";
import { Database } from "../../../services/database/impl/core/database";

export class Registration extends DatabaseDocument implements RegistrationIF {

    constructor( registrationDocumentName : string, registrationsCollection : CollectionDatabase<Registration>, documentPath? : string  ) {

        super( registrationDocumentName, registrationsCollection, documentPath );

        try {
            const reciprocalReference = 
                this.reciprocalReferenceFromDocumentName( registrationDocumentName );

            this.startDate.required = true;

            this.company = new TenantProperty<Company>( this, CompaniesCollection, HealthguardCompanyDocument );
            this.company.editable = false;

            const company = this.company.emptyDocument() as HealthguardCompany;
            
            this.unit = new OwnerProperty<Unit>( this, UnitsCollection, HealthguardUnitDocument ); // parent

            this.user = new OwnerProperty<User>( this, UsersCollection, HealthguardUserDocument ); 
            const ownerUser = this.user.emptyDocument() as HealthguardUser;

            const userDisabled = 
                (Factory.get().databaseService.currentCompany() as HealthguardCompany)?.subcollectionDisabled( 
                    registrationDocumentName );

            if( userDisabled ) {

                this.user.editable = false; 
                this.user.hidden = true;  
            } 
            else {
                const userIdentification = 
                    (Factory.get().databaseService.currentCompany() as HealthguardCompany)?.subcollectionIdentification( 
                        registrationDocumentName );

                if( userIdentification != null) {

                    switch (userIdentification) {
            
                        case Identifications.Optional:
                            this.user.editable = true;
                            break;
            
                        case Identifications.Identified:
            
                            this.user.required = true;
                            break;
            
                        case Identifications.Anonymous:
                            this.user.editable = false; 
                            this.user.hidden = true;  
                            break;
                    }
                } 
            }

            this.healthProvider = new ReferenceProperty<Company>( this, 
                () => [Factory.get().databaseService.databaseFactory.collectionDatabaseFromCollectionName(
                    CompaniesCollection
                ) as CollectionDatabase<Company>] );

            this.hazard = new ReferenceProperty<Hazard>( this, 
                    company != null ? 
                        () => [company.hazards.collection(),company.publicHazards.documentsDatabase()] :  
                        () => [Factory.get().databaseService.databaseFactory.collectionDatabaseFromCollectionName( 
                            HazardsCollection ) as CollectionDatabase<Hazard>]  );

            this.variant = new TextProperty( this ); 
            this.variant.setOptionsSource( new OptionsSource<Hazard,TextsProperty>( 
                this.hazard, "variants" )  );

            this.locations = new ReferencesProperty<HealthguardLocation>( this, 
                () => this.parentDatabases( LocationsCollection, {nearestIsCollectionGroup: false} ) as Database<HealthguardLocation>[],
                reciprocalReference as keyof HealthguardLocation );

            this.projects = new ReferencesProperty<HealthguardProject>( this, 
                () => this.parentDatabases( ProjectsCollection, {nearestIsCollectionGroup: false} ) as Database<HealthguardProject>[],
                reciprocalReference as keyof HealthguardProject );            

            this.categories = new ReferencesProperty<HealthguardCategory>( this, 
                () => this.parentDatabases( CategoriesCollection, {nearestIsCollectionGroup: false} ) as Database<HealthguardCategory>[],
                reciprocalReference as keyof HealthguardCategory );

            this.gatherings = new ReferencesProperty<Gathering>( this, 
                () => this.parentDatabases( GatheringsCollection, {nearestIsCollectionGroup: false} ) as Database<Gathering>[],
                reciprocalReference as keyof Gathering );

            this.residence = new ReferenceProperty<Residence>( this, 
                ownerUser != null ? 
                    () => [ownerUser.residences.collection()] : 
                    undefined,
                reciprocalReference as keyof Residence );

            this.measures = new ReferencesProperty<Measure>(this,
                () => this.parentDatabases( MeasuresCollection, {nearestIsCollectionGroup: false} ) as Database<Measure>[],
                reciprocalReference as keyof Measure
            );
            this.measures.required = true;

            this.alerts = new ReferencesProperty<Alert>(this,
                () => this.parentDatabases( AlertsCollection, {nearestIsCollectionGroup: false} ) as Database<Alert>[],
                reciprocalReference as keyof Alert );  

            this.incidentRegistrations = new ReferencesProperty<IncidentRegistration>(this,
                () => [this.parentCollection( IncidentRegistrationsCollection ) as CollectionDatabase<IncidentRegistration>],
                reciprocalReference as keyof Registration );  
            
            this.quarantineRegistrations = new ReferencesProperty<QuarantineRegistration>(this,
                () => [this.parentCollection( QuarantineRegistrationsCollection ) as CollectionDatabase<QuarantineRegistration>],
                reciprocalReference as keyof Registration );  
            
            this.sickLeaveRegistrations = new ReferencesProperty<SickLeaveRegistration>(this,
                () => [this.parentCollection( SickLeaveRegistrationsCollection ) as CollectionDatabase<SickLeaveRegistration>],
                reciprocalReference as keyof Registration );  

            this.symptomRegistrations = new ReferencesProperty<SymptomRegistration>(this,
                () => [this.parentCollection( SymptomRegistrationsCollection ) as CollectionDatabase<SymptomRegistration>],
                reciprocalReference as keyof Registration );  

            this.testRegistrations = new ReferencesProperty<TestRegistration>(this,
                () => [this.parentCollection( TestRegistrationsCollection ) as CollectionDatabase<TestRegistration>],
                reciprocalReference as keyof Registration );  

            this.vaccineRegistrations = new ReferencesProperty<VaccineRegistration>(this,
                () => [this.parentCollection( VaccineRegistrationsCollection ) as CollectionDatabase<VaccineRegistration>],
                reciprocalReference as keyof Registration ); 

            //log.traceInOut( "constructor()", registrationDocumentName );

        } catch( error ) {

            log.warn( "constructor()", "Error initializing registration", error );
            
            throw new Error( (error as any).message );
        }
    }

    indexKey() : string {
        return RegistrationDocument;
    }

    protected reciprocalReferenceFromDocumentName( documentName : string  ) : string  {

        switch (documentName) {

            case RegistrationDocument:
                return RegistrationsCollection;

            case IncidentRegistrationDocument:
                return IncidentRegistrationsCollection;

            case QuarantineRegistrationDocument:
                return QuarantineRegistrationsCollection;

            case SickLeaveRegistrationDocument:
                return SickLeaveRegistrationsCollection;

            case SymptomRegistrationDocument:
                return SymptomRegistrationsCollection;

            case TestRegistrationDocument:
                return TestRegistrationsCollection;

            case VaccineRegistrationDocument:
                return VaccineRegistrationsCollection;

            default:
                throw new Error("Document name not recognized: " + documentName);
        }
        
    }

    async onCreated() : Promise<void> {
        try {
            //log.traceIn( "("+this.collectionDatabase.collectionName()+")", "onCreated()" );

            await super.onCreated();

            await RegistrationManager.getInstance().handleNewRegistration( this );
    
            //log.traceOut( "("+this.collectionDatabase.collectionName()+")", "onCreated()" );
  
        } catch( error ) {
            
            log.warn( "("+this.collectionDatabase.collectionName()+")", "onCreated()", "Error handling created notification", error );
  
            throw new Error( (error as any).message );
        }
    }

    async onUpdated() : Promise<void> {
        try {
            //log.traceIn( "onUpdated()" );

            await super.onUpdated();
    
            //log.traceOut( "onUpdated()" );
  
        } catch( error ) {
            
            log.warn( "onUpdated()", "Error handling updated notification", error );
  
            throw new Error( (error as any).message );
        }    
    }

    async onDeleted() : Promise<void> {

        try {
            //log.traceIn( "onDeleted()" );

            await super.onDeleted();
    
            //log.traceOut( "onDeleted()" );
  
        } catch( error ) {
            
            log.warn( "onDeleted()", "Error handling updated notification", error );
  
            throw new Error( (error as any).message );
        }  
    }

    readonly company: TenantProperty<Company>;

    readonly unit: OwnerProperty<Unit>;

    readonly user: OwnerProperty<User>;

    readonly healthProvider: ReferenceProperty<Company>;

    readonly hazard: ReferenceProperty<Hazard>;

    readonly variant: TextProperty;

    readonly projects: ReferencesProperty<HealthguardProject>;

    readonly locations: ReferencesProperty<HealthguardLocation>;

    readonly categories: ReferencesProperty<HealthguardCategory>;

    readonly gatherings: ReferencesProperty<Gathering>;

    readonly residence: ReferenceProperty<Residence>;

    readonly measures: ReferencesProperty<Measure>;

    readonly alerts: ReferencesProperty<Alert>;

    readonly incidentRegistrations: ReferencesProperty<IncidentRegistration>;

    readonly quarantineRegistrations : ReferencesProperty<QuarantineRegistration>;

    readonly sickLeaveRegistrations: ReferencesProperty<SickLeaveRegistration>;

    readonly symptomRegistrations: ReferencesProperty<SymptomRegistration>;

    readonly testRegistrations: ReferencesProperty<TestRegistration>;

    readonly vaccineRegistrations: ReferencesProperty<VaccineRegistration>;

 }

