import { ApplicationServiceIF } from "../../application/api/applicationServiceIF";
import { AuthenticationServiceIF } from "../../authentication/api/authenticationServiceIF";
import { AuthenticationService } from "../../authentication/framework/authenticationService";
import { SubscriptionManager } from "../../billing";
import { BillingServiceIF } from "../../billing/api/billingServiceIF";
import { BillingService } from "../../billing/impl/billingService";
import { ConfigurationServiceIF } from "../../configuration/api/configurationServiceIF";
import { ConfigurationManager } from "../../configuration/framework/configurationManager";
import { ConfigurationService } from "../../configuration/framework/configurationService";
import { DatabaseServiceIF } from "../../database/api/core/databaseServiceIF";
import { DatabaseAccess } from "../../database/framework/databaseAccess";
import { DatabaseFactory } from "../../database/framework/databaseFactory";
import { DatabaseManager } from "../../database/framework/databaseManager";
import { DatabaseService } from "../../database/framework/databaseService";
import { MessagingServiceIF } from "../../messaging/api/messagingServiceIF";
import { EmailSender } from "../../messaging/framework/email/emailSender";
import { MessagingService } from "../../messaging/framework/messagingService";
import { PushReceiver } from "../../messaging/framework/push/pushReceiver";
import { PushSender } from "../../messaging/framework/push/pushSender";
import { SmsSender } from "../../messaging/framework/sms/smsSender";
import { SecurityServiceIF } from "../../security/api/securityServiceIF";
import { SecurityService } from "../../security/framework/securityService";
import { StorageServiceIF } from "../../storage/api/storageServiceIF";
import { MediaManager } from "../../storage/framework/mediaManager";
import { StorageService } from "../../storage/framework/storageService";
import { Logger } from "../impl/logger";
import { Environment } from "./environment";
import { LoggerFactory } from "./loggerFactory";
import { PersistentStateIF } from "./persistentStateIF";
import { Target } from "./targets";
import { TranslatorIF } from "./translatorIF";

export const log = LoggerFactory.logger( "common" );

export class Factory  {

    public static create( 
        environment : Environment, 
        target : Target, 
        applicationService : ApplicationServiceIF ) : Factory { 

        if( Factory._instance == null ) {

            new Factory( environment, 
                target, 
                applicationService );
        }
        return Factory._instance;
    }

    public static get() : Factory {

        if( Factory._instance == null ) {
            throw new Error("Factory has not been created!");
        }

        return Factory._instance;
    }

    constructor(  environment : Environment, 
        target : Target, 
        applicationService : ApplicationServiceIF ) {

        try {

            Factory._instance = this;

            this.environment = environment;

            this.target = target;

            this.applicationService = applicationService; 

            Logger.init( target );

            log.info( "constructor()", "Logger ready")

            this.configurationService = new ConfigurationService( 
                target, 
                applicationService.configurationManager as ConfigurationManager ) as ConfigurationServiceIF;

            this.databaseService = new DatabaseService( 
                applicationService.databaseManager as DatabaseManager, 
                applicationService.databaseFactory as DatabaseFactory,
                applicationService.databaseAccess as DatabaseAccess );

            this.authenticationService = applicationService.authenticationService as AuthenticationService;

            this.securityService = new SecurityService( target, {
                keyManager: applicationService.keyManager
            });

            if( applicationService.subscriptionManager != null ) {

                this.billingService = new BillingService( 
                    target, 
                    applicationService.subscriptionManager as SubscriptionManager ) as BillingServiceIF;
            }

            if( applicationService.mediaManager != null ) {

                this.storageService = new StorageService( target, applicationService.mediaManager as MediaManager );
            } 

            this.messagingService = new MessagingService( target, 
                applicationService.emailSender as EmailSender,
                applicationService.smsSender as SmsSender,
                applicationService.pushSender as PushSender,
                applicationService.pushReceiver as PushReceiver );  

            this.persistentState = applicationService.persistentState;

            this.translator = applicationService.translator;

            log.traceOut( "constructor()", "OK")

        } catch( error ) {

            log.warn( "constructor()", "Error creating factory", error )

            throw new Error( (error as any).message );
        }
    }

    async init() : Promise<void> {

        try {

            if( this._initialized ) {
                return;
            }

            await Factory._instance.applicationService.init();

            await Factory._instance.configurationService.init();

            await Factory._instance.databaseService.init();
            
            if( Factory._instance.authenticationService != null ) {
                await Factory._instance.authenticationService.init();
            }
            
            if( Factory._instance.billingService != null ) {
                await Factory._instance.billingService.init();
            }

            await Factory._instance.messagingService.init();

            this._initialized = true;

        } catch( error ) {
            throw new Error( (error as any).message );
        }
    }

    isInitialized() : boolean {

        return this._initialized;
    }

    isClient() {
        return this.environment === Environment.Client;
    }

    readonly configurationService : ConfigurationServiceIF;

    readonly environment : Environment;

    readonly target : Target;

    readonly databaseService : DatabaseServiceIF;

    readonly authenticationService? : AuthenticationServiceIF;

    readonly securityService : SecurityServiceIF;

    readonly billingService? : BillingServiceIF;

    readonly storageService? : StorageServiceIF;

    readonly messagingService : MessagingServiceIF;

    readonly persistentState? : PersistentStateIF;

    readonly translator : TranslatorIF;

    readonly applicationService : ApplicationServiceIF;

    private static _instance : Factory;

    private _initialized = false;
}
