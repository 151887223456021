import * as React from 'react'

import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import { withTranslation, WithTranslation } from 'react-i18next';

import { log } from 'ui/app/app';

import { Chip, Grid, IconButton} from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';
import MoreHorizIcon from '@material-ui/icons/MoreHorizRounded'
import CancelIcon from '@material-ui/icons/Cancel'; 

import { translatedPropertyLabel } from '../propertyLabel';
import { PropertyDisplayMode, propertyInputVariant } from '../propertyValue';

import { AppContext } from '../../app/appContext';

import ChipInput, { ChipRendererArgs } from 'material-ui-chip-input';
import { LinksPropertyIF } from '../../../services/database/api/properties/linksPropertyIF ';
import { browserView } from '../browserView';
import { formDialog } from '../formDialog';
import { confirmationDialog } from '../simpleDialog';

const AddLinkTitle = "addLink";
const UpdateLinkTitle = "updateLink";
const LinkTitleLabel = "urlTitle";
const LinkUrlLabel = "url";



const styles = (theme: Theme) => createStyles({
  link: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1.25),
    borderRadius: 4,
    justifyContent: "space-between", 
    width: "80%" 
  },
  chipInput: {
    "& .ChipInput-chipContainer": {
      //minHeight: chipSelectInputMinHeight
    }
  },
  chipButtons: {
    paddingRight: theme.spacing(7),
    paddingBottom: theme.spacing(3.5),
  },
  addLinkButton: {
    marginTop: theme.spacing(0.5), 
    marginLeft: theme.spacing(-6), 
    marginRight: theme.spacing(-6)
  }
});


export interface LinksPropertyValueProps extends WithStyles<typeof styles>, WithTranslation { 

  property : LinksPropertyIF,

  onPropertyChange? : ( property : LinksPropertyIF ) => void,

  displayMode : PropertyDisplayMode,
  
  singleProperty : boolean,

  hideColor?: boolean,

  disabled?: boolean,

  required?: boolean
}

interface LinksPropertyValueState { 

  open : boolean,

  propertyValues?: Map<string,string>,

  loading: boolean

}

class LinksPropertyValue extends React.Component<LinksPropertyValueProps,LinksPropertyValueState> {

  constructor( props: LinksPropertyValueProps ) {
    
    super(props);

    const propertyValues = this.props.property.value();

    this.state = { 

      open : false,

      propertyValues: propertyValues,

      loading: false

     } as LinksPropertyValueState;

    //log.traceInOut( "constructor()" );
  }

  async componentDidMount() {

    try {
      //log.traceIn( "componentDidMount()", this.state );
    
      //log.traceOut( "componentDidMount()", "state:", this.state );

    } catch( error ) {
      log.warn( "componentDidMount()", "Error mounting documents view", error );
      
      log.traceOut( "componentDidMount()", "error" );
    }
  }

  async componentDidUpdate() {

    //log.traceIn( "componentDidUpdate()",  this.props.property.key() );

    const propertyValues = this.props.property.value() as Map<string,string>;

    //log.debug( "componentDidUpdate()",  {propertyValues} );

    const existingPropertyValues = this.state.propertyValues;

    //log.debug( "componentDidUpdate()",  {existingPropertyValues} );

    if(!this.state.open &&
        this.props.property.compareValue(existingPropertyValues) !== 0) {

      this.setState({
        propertyValues: propertyValues
      })
    }
    //log.traceOut( "componentDidUpdate()",  this.props.property.key() );

  }

  render() {
    //log.traceInOut("render()");

    const {classes } = this.props;

    if( !this.props.property.userAccess().allowRead ) {
      return ( null );
    }

    if( this.props.displayMode === PropertyDisplayMode.Cell ) {
      return ( 
        <>{ 
          this.props.property.value() == null ? <>&nbsp;</> : this.props.property.value()}
        </>  
      );  
    }


    const showLink = ( title : string, url: string ) => {

        browserView( title, url );

    }

    const handleAddLink = async () => {
  
      log.traceIn( "handleAddLink()" );

      const linkInputValues = new Map<string,string | undefined>();
      linkInputValues.set( LinkTitleLabel, undefined );
      linkInputValues.set( LinkUrlLabel, undefined );

      const linkReturnValues = await formDialog( 
        this.props.property.parent.documentName(),  
        AddLinkTitle,
        linkInputValues );
  
      if( linkReturnValues == null ) {
        log.traceOut( "handleAddLink()", "No values");
        return;
      }

      log.debug( "handleAddLink()", {linkReturnValues});

      this.props.property.setLink( 
        linkReturnValues.get( LinkTitleLabel )!,
        linkReturnValues.get( LinkUrlLabel )!
        );

      const propertyValues = this.props.property.value();    

      log.debug( "handleAddLink()", {propertyValues});

      this.setState( {
        propertyValues: propertyValues
      })
        
      if( this.props.onPropertyChange != null ) {
        this.props.onPropertyChange( this.props.property );
      }
    
      log.traceOut( "handleAddLink()");
    }

    const handleEditLink = async ( title : string ) => {
  
      log.traceIn( "handleEditLink()", {title} );

      const linkInputValues = new Map<string,string | undefined>();
      linkInputValues.set( LinkTitleLabel, title );
      linkInputValues.set( LinkUrlLabel, this.state.propertyValues?.get( title ) );

      const linkReturnValues = await formDialog( 
        this.props.property.parent.documentName(),  
        UpdateLinkTitle,
        linkInputValues );
  
      if( linkReturnValues == null ) {
        log.traceOut( "handleEditLink()", "No values");
        return;
      }

      log.debug( "handleEditLink()", {linkReturnValues});

      this.props.property.setLink( 
        linkReturnValues.get( LinkTitleLabel )!,
        linkReturnValues.get( LinkUrlLabel )!
        );

      const propertyValues = this.props.property.value();    

      log.debug( "handleEditLink()", {propertyValues});

      this.setState( {
        propertyValues: propertyValues
      })
        
      if( this.props.onPropertyChange != null ) {
        this.props.onPropertyChange( this.props.property );
      }
    
      log.traceOut( "handleEditLink()");
    }

    const handleDeleteLink = async ( key: string, index : number ) => {
  
      log.traceIn( "handleDeleteLink()", key, index );

      const confirmation = await confirmationDialog( "deleteLink");

      if( !confirmation ) {
        log.traceOut( "handleDeleteLink()", "not confirmed"  );
        return;
      }
  
      const propertyValues = this.props.property.value();

      if( propertyValues == null ) {
        log.traceOut( "handleDeleteLink()", "No values");
        return;
      }
    
      if( key != null ) {
        this.props.property.removeLink( key );

        this.setState( {
          propertyValues: propertyValues
        })
        
        if( this.props.onPropertyChange != null ) {
          this.props.onPropertyChange( this.props.property );
        }
      }

      log.traceOut( "handleDeleteLink()");
    }


    const chipRenderer = ( args : ChipRendererArgs, key : string ) => {

      const label = args.value;

      const url = this.state.propertyValues!.get(label);

      return (
        <Chip className={classes.link} 
          key={key}
          label={label}
          onClick={() => { showLink( label, url! ) }}
          onDelete={()=>{}}
          onMouseDown={(event) => { event.stopPropagation() }}
          deleteIcon={(
            <Grid className={classes.chipButtons}>
              <IconButton size="small" onClick={() => handleEditLink( label )}><MoreHorizIcon/></IconButton>
              {!this.props.disabled && <IconButton size="small" onClick={args.handleDelete}><CancelIcon/></IconButton>} 
            </Grid>
          )} 
        />
      )
    }

    const label = translatedPropertyLabel( this.props.property.parent.documentName(), this.props.property.key() );

    return (
      <Grid container alignItems="center"> 
        <ChipInput 
          className={classes.chipInput} 
          disabled={this.props.disabled}
          readOnly={true}
          fullWidth
          variant={propertyInputVariant}
          required={this.props.required}
          InputLabelProps={{ shrink: true }}
          label={label}
          value={this.state.propertyValues != null ? Array.from(this.state.propertyValues.keys()) : [] as string[]}
          onDelete={(value, index) => handleDeleteLink(value, index)}
          chipRenderer={chipRenderer}
        />
        {!this.props.disabled &&
          <IconButton
            className={classes.addLinkButton}
            component="span"
            onClick={() => handleAddLink()}
          >
            <AddIcon />
          </IconButton>
        }
      </Grid>
    );
  }


}

LinksPropertyValue.contextType = AppContext;

const ModifiedLinksPropertyValue = withTranslation()(withStyles(styles)(LinksPropertyValue));

export default ModifiedLinksPropertyValue;











