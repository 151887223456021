import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom';
import * as H from 'history';

import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';

import { Factory } from 'services/common/api/factory';
import { log } from 'ui/app/app';

import { CompanyView } from 'ui/views/companyViews';
import { UserView } from 'ui/views/userViews';
import { ChangeView } from 'ui/views/changeViews';
import { HazardView } from 'ui/views/hazardViews';
import GatheringView  from 'ui/views/gatheringViews';
import { RiskView } from 'ui/views/riskViews';
import UnitView from 'ui/views/unitViews';
import MeasureView from 'ui/views/measureViews';
import { VaccineRegistrationView } from 'ui/views/vaccineRegistrationViews';
import { TestRegistrationView } from 'ui/views/testRegistrationViews';
import { SymptomRegistrationView } from 'ui/views/symptomRegistrationViews';
import { IncidentRegistrationView } from 'ui/views/incidentRegistrationViews';
import { SickLeaveRegistrationView } from 'ui/views/sickLeaveRegistrationViews';
import { QuarantineRegistrationView } from 'ui/views/quarantineRegistrationViews';
import { SubscriptionView } from '../views/subscriptionViews';
import LocationView from 'ui/views/locationViews';
import { ResidenceView } from 'ui/views/residenceViews';
import { RegistrationView } from 'ui/views/registrationViews ';
import { CategoryView } from '../views/categoryViews';
import { TerminalView } from '../views/terminalViews';
import { KeyView } from '../views/keyViews';
import { MessageView } from '../views/messageViews';
import { ProjectView } from '../views/projectViews';
import { AlertView } from '../views/alertViews';
import { ConsentView } from '../views/consentViews';
import { DeviceView } from '../views/deviceViews';

import { AppContext, AppContextProps } from 'ui/app/appContext';

import { DatabaseDocumentIF } from 'services/database/api/core/databaseDocumentIF';
import { DocumentNameKey } from 'services/database/api/core/databaseServiceIF';
import { CollectionPropertyIF } from '../../services/database';
import { DatabaseObserverIF } from '../../services/database/api/core/databaseObserverIF';
import { SymbolicCollectionPropertyIF } from '../../services/database/api/properties/symbolicCollectionPropertyIF';

import { AlertDocument, CategoryDocument, ChangeDocument, CompanyDocument, DeviceDocument, KeyDocument, LocationDocument, MessageDocument, ProjectDocument, ResidenceDocument, SubscriptionDocument, TerminalDocument, UnitDocument, UserDocument } from '../../services/database/api/documents';
import { IncidentRegistrationDocument, QuarantineRegistrationDocument, RegistrationDocument, SickLeaveRegistrationDocument, SymptomRegistrationDocument, TestRegistrationDocument, VaccineRegistrationDocument } from '../../healthguard/api/registrationDocuments';
import { ConsentDocument, HazardDocument, GatheringDocument, RiskDocument, HealthguardCategoryDocument, HealthguardCompanyDocument, HealthguardDeviceDocument, HealthguardLocationDocument, HealthguardProjectDocument, HealthguardUnitDocument, HealthguardUserDocument, MeasureDocument, HealthguardAlertDocument } from '../../healthguard/api/healthguardDocuments';

import { DocumentProps } from './documentView';

import { TranslationKey } from '../../services/common/api/translatorIF';

import { confirmationDialog, errorDialog } from './simpleDialog';
import SimpleDatabaseView from './simpleDatabaseView';
import { PropertyType, PropertyTypes } from '../../services/database/api/definitions/propertyType';


export const DocumentViewKey = "view";


export const addDocument = async ( props: {
  appContext : AppContextProps, 
  t: TFunction,
  history : H.History<unknown>,
  databaseObserver : DatabaseObserverIF<DatabaseDocumentIF> } ) => {

  log.traceIn("addDocument()");
  try {

    let path = props.appContext.currentHomePath! as string;

    let newDocument = props.databaseObserver.databaseQuery().databases![0].newDocument();

    path += newDocument.databasePath(true);

    props.history.push(path);

    log.traceOut("addDocument()", path);
    
  } catch( error ) {
    log.warn( "addDocument()", error );

    await errorDialog( error);
  }
}

export const removeDocument = async ( props: {
  appContext : AppContextProps, 
  history : H.History<unknown>,
  t: TFunction,
  databaseDocument : DatabaseDocumentIF,
  databaseObserver : DatabaseObserverIF<DatabaseDocumentIF> } ) => {

  log.traceIn("removeDocument()", props.databaseDocument.title.value() );

  try {
    if( props.databaseDocument == null) {
      throw new Error("notFound");
    }

    const ownerDocument = props.databaseObserver.defaultDatabase()!.owner();

    if( ownerDocument == null || 
        props.databaseDocument.databasePath( false ).startsWith( ownerDocument.databasePath(false) ) ) {

        log.debug("removeDocument()", "delete", props.databaseDocument.title.value() );

        if( !props.databaseDocument.userAccess().allowDelete ) {
          throw new Error("permissionDenied");
        }

        let confirmationPrompt = props.t(TranslationKey.Prompts + ":deleteDocument");

        confirmationPrompt += " " + props.databaseDocument.title.value() + "?"
  
        if( !(await confirmationDialog(confirmationPrompt) ) ) {
          log.traceOut("removeDocument()", "Cancel");
          return;
        }
  
        const confirmation2 = await confirmationDialog( "deleteDocument2");
  
        if( !confirmation2 ) {
          log.traceOut( "removeDocument()", "Cancel 2" );
          return;
        }
  
        await props.databaseDocument.delete();

        log.traceOut("removeDocument()", "Deleted", props.databaseDocument.title.value() );
        return;
    }
    else {

      log.debug("removeDocument()", "remove symbolic connection", props.databaseDocument.title.value() );

      let confirmationPrompt = props.t(TranslationKey.Prompts + ":removeDocument");

      confirmationPrompt += " " + props.databaseDocument.title.value() + "?"

      if( !(await confirmationDialog(confirmationPrompt) ) ) {
        log.traceOut("removeDocument()", "Cancel");
        return;
      }

      const symbolicOwnersProperties = props.databaseDocument.properties( {

        includePropertyTypes: [PropertyTypes.SymbolicOwners as PropertyType]  

      }) as Map<string,SymbolicCollectionPropertyIF<DatabaseDocumentIF>>;

      for( const symbolicOwnersProperty of symbolicOwnersProperties.values() ) {

        symbolicOwnersProperty.removeDocument( ownerDocument.databasePath() );

        if( symbolicOwnersProperty.isChanged() ) {

          await props.databaseDocument.update();

          log.traceOut("removeDocument()", "Removed",  ownerDocument.title.value() );
          return;
        }
      } 
    } 
    log.traceOut("removeDocument()", "Not found", ownerDocument.title.value() );

  } catch( error ) {
    
    log.warn( "deleteDocument()", error );

    await errorDialog( error);

  }
}

export const openDocument = async ( props: {
  appContext : AppContextProps, 
  history : H.History<unknown>,
  t: TFunction,
  databaseDocument : DatabaseDocumentIF,
  databaseObserver : DatabaseObserverIF<DatabaseDocumentIF> } ) => {

  log.traceIn("openDocument()", props.databaseDocument.title.value());

  try {

    const path = props.appContext.currentHomePath + props.databaseDocument.databasePath( true );

    props.history.push(path);

    log.traceOut("openDocument()" );

  } catch( error ) {
    
    log.warn( "openDocument()", error );

    await errorDialog( error);

  }
}


export const documentView = ( props : DocumentProps ) : JSX.Element | null => {

  if (props.view != null) {

    const collectionProperty = 
      props.databaseDocument.property( props.view ) as CollectionPropertyIF<DatabaseDocumentIF>;

    if( collectionProperty != null ) {
      return (<SimpleDatabaseView collectionProperty={collectionProperty} />);
    }
  }
  
  switch (props.databaseDocument!.documentName() ) {

    case CategoryDocument: 
    case HealthguardCategoryDocument: 
    {
      return( <CategoryView {...props} /> );
    }
    case ChangeDocument: 
    {
      return( <ChangeView {...props}/> );
    }
    case CompanyDocument: 
    case HealthguardCompanyDocument: 
    {
      return( <CompanyView {...props}/> );
    }
    case ConsentDocument: 
    {
      return(  <ConsentView {...props}/> );
    }
    case AlertDocument: 
    case HealthguardAlertDocument: 
    {
      return(  <AlertView {...props}/> );
    }
    case DeviceDocument: 
    case HealthguardDeviceDocument: 
    {
      return( <DeviceView {...props}/> );
    }
    case HazardDocument: 
    {
      return(<HazardView {...props}/>);
    }
    case GatheringDocument: 
    {
      return( <GatheringView {...props}/> );
    }
    case RiskDocument: 
    {
      return( <RiskView {...props}/> );
    }
    case ResidenceDocument: 
    {
      return(  <ResidenceView {...props}/> );
    }
    case KeyDocument: 
    {
      return( <KeyView {...props}/> );
    }
    case LocationDocument: 
    case HealthguardLocationDocument: 
    {
      return(  <LocationView {...props} />); 
    }
    case MeasureDocument: 
    {
      return(  <MeasureView {...props}/> );
    }
    case MessageDocument: 
    {
      return( <MessageView {...props}/> );
    }
    case ProjectDocument: 
    case HealthguardProjectDocument: 
    {
      return(  <ProjectView {...props}/> );
    }
    case QuarantineRegistrationDocument: 
    {
      return( <QuarantineRegistrationView {...props}/> );
    }
    case SickLeaveRegistrationDocument: 
    {
      return(  <SickLeaveRegistrationView {...props}/> );
    }
    case RegistrationDocument: 
    {
      return(  <RegistrationView {...props}/> );
    }
    case SubscriptionDocument: 
    {
      return(  <SubscriptionView {...props}/> );
    }
    case SymptomRegistrationDocument: 
    {
      return( <SymptomRegistrationView {...props}/> );
    }
    case TerminalDocument: 
    {
      return( <TerminalView {...props}/> );
    }
    case TestRegistrationDocument: 
    {
      return(  <TestRegistrationView {...props}/>);
    }
    case IncidentRegistrationDocument: 
    {
      return(  <IncidentRegistrationView {...props}/> );
    }
    case UnitDocument: 
    case HealthguardUnitDocument:
    {
      return( <UnitView {...props}/> );
    }
    case UserDocument: 
    case HealthguardUserDocument:
    {
      return( <UserView {...props}/> );
    }
    case VaccineRegistrationDocument: 
    {
      return(  <VaccineRegistrationView {...props}/> );
    }
    default:
    {
        log.warn( "No database route found document", props.databaseDocument );
        return null;
    }
  }
}


const styles = (theme: Theme) => createStyles({
  root: {
  }
});


interface MatchParams {
}

interface DocumentRouterProps extends WithStyles<typeof styles>, WithTranslation, RouteComponentProps<MatchParams> {
}

interface DocumentRouterState { // Document View Props

}

class DocumentRouter extends React.PureComponent<DocumentRouterProps,DocumentRouterState> {

  constructor( props: DocumentRouterProps ) {
    
    super(props);

    this.state = { 
    } as DocumentRouterState;

    this.redirectToHome = this.redirectToHome.bind(this);
    this.routeToDocumentView = this.routeToDocumentView.bind(this);

    //log.traceInOut( "constructor()" );
  }

  redirectToHome() {
    log.traceInOut( "redirectToHome()");

    const appContext = this.context as AppContextProps;

    return( <Redirect to={{ pathname: appContext.currentHomePath }} /> );
  }

  loadDocument() : DatabaseDocumentIF | undefined {

    try {
      let url = this.props.location.pathname;
  
      const search = new URLSearchParams(this.props.location.search);
  
      if( search != null ) {
        const documentName = search.get( DocumentNameKey );
  
        if( documentName != null ) {
          url += "?" + DocumentNameKey + "=" + documentName;
        }
      }
  
      const databaseDocument = 
        Factory.get().databaseService.databaseFactory.newDocumentFromUrl( url );
  
      if( search != null ) {
        Array.from( search.keys() ).forEach( searchKey => {
  
          if( searchKey !== DocumentNameKey ) {
  
            const searchProperty = databaseDocument!.property( searchKey );
  
            if( searchProperty != null ) {
  
              const jsonSearchValue = search.get( searchKey );
  
              if( jsonSearchValue != null ) {
  
                const searchValue = JSON.parse( jsonSearchValue );
  
                if( searchValue != null ) {
                  searchProperty.setValue( searchValue );
                }
              }
            }
          } 
        })
      }  
  
      return databaseDocument;
  
    } catch (error) {
      log.warn("loadDocument()", "Error loading document ", error);
  
      return undefined;
    }
  }
  


  routeToDocumentView( path: string, name? : string, view? : string ) {
    log.traceIn( "routeToDocumentView()", {path}, {name}, {view});

    try { 
      
      const onComplete = async ( databaseDocument? : DatabaseDocumentIF ) : Promise<void> => {

        log.traceIn( "onComplete()" );
    
        const appContext = this.context as AppContextProps;

        try {
    
          if( databaseDocument == null ) {

            this.props.history.goBack();
          }
          else {
            this.props.history.replace( appContext.currentHomePath + databaseDocument.databasePath(true));
          }

          log.traceOut( "onComplete()" );
    
        } catch (error) {
          log.warn("onComplete()", "Error completing document route", error);
        }
      }

      const databaseDocument = this.loadDocument();

      if( databaseDocument == null ) {
        throw new Error( "Could not load document" );
      }

      const documentRoute = documentView( {
        databaseDocument: databaseDocument,
        view: view,
        onComplete: onComplete
      } as DocumentProps ); 

      if( documentRoute != null ) { 

        return( documentRoute ); 
      }
      else {
        return this.redirectToHome();
      }

    } catch( error ) {
      log.warn( "routeToDocumentView()", "Routing error", error );

      return this.redirectToHome();
    }
  }


  render() {
    log.traceIn( "render()", this.props.location.pathname, this.props.location.search );

    try {
      //const { classes } = this.props;

      const path = this.props.location.pathname;

      const query = new URLSearchParams(this.props.location.search);

      const name = query.get( DocumentNameKey ); 

      const view = query.get( DocumentViewKey ); 

      if( Factory.get().databaseService.databaseFactory.isUrlDocument( path ) ) {

        const documentRoute = this.routeToDocumentView( path, name != null ? name : undefined, view != null ? view : undefined );
        log.traceOut( "render()", "document");
        return documentRoute;
      }
      else {
        const redirectHome = this.redirectToHome();
        log.traceOut( "render()", "home");
        return redirectHome;
      }
    } catch( error ) {
      log.warn( "render()", "Routing error", error );

      return this.redirectToHome();
    }
  }
}

DocumentRouter.contextType = AppContext;

export default withRouter(withTranslation()(withStyles(styles)(DocumentRouter)));

