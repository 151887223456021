import React from 'react';

import { Checkbox, createStyles, FormControlLabel, makeStyles, Theme } from '@material-ui/core';

import PropertyLabel from '../propertyLabel';

import { BooleanPropertyIF } from 'services/database/api/properties/booleanPropertyIF';
import { PropertyDisplayMode } from '../propertyValue';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    labelPlacementStart: {
      marginLeft: 0
   }
  }),
);

export default function BooleanPropertyValue( props : { 
  property : BooleanPropertyIF, 
  onPropertyChange? : ( property : BooleanPropertyIF ) => void,
  displayMode : PropertyDisplayMode,
  singleProperty : boolean,
  hideColor?: boolean,
  disabled?: boolean,
  required? : boolean } ) {

  const classes = useStyles();

  const [propertyValue, setPropertyValue] = React.useState<boolean | undefined>( undefined );

  React.useEffect( () => {

    setPropertyValue( props.property.value() );

  }, [props.property,props.displayMode]); 

  if( props.displayMode === PropertyDisplayMode.Cell ) {

    return ( <>{ !!propertyValue ? "✓" : "" }</>  );
  }

  const handleChange = (event : any) => {

    props.property.setValue(Boolean(event.target.checked));

    setPropertyValue( props.property.value() );
    
    if (props.onPropertyChange != null) {
      props.onPropertyChange(props.property);
    }
  };

  return (
    <>
      <FormControlLabel
        style={{ width: "100%", justifyContent:"flex-start"}}
        control={
          <Checkbox 
            checked={!!propertyValue} 
            onChange={handleChange} 
            required={props.required}
            disabled={props.disabled}
          />
        }
        label={props.displayMode !== PropertyDisplayMode.Filter && 
          <PropertyLabel documentName={props.property.parent.documentName()} propertyKey={props.property.key()} />} 
        labelPlacement="end"
        disabled={props.disabled}
        classes={{
          labelPlacementStart: classes.labelPlacementStart
        }}
      />
    </>
  );

}
  