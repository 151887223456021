import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import { withTranslation, WithTranslation } from 'react-i18next';

import OrgChart, { Animated, ConnectorAlignment, LayoutType, NodeContainerRenderContext, NodeContainerRenderProps, NodeLineRenderContext, NodeLineRenderProps } from "awesome-react-org-chart";

import { Avatar, Box, Card, CardActions, CardContent, CardHeader, Grid, IconButton, Typography } from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import AddIcon from '@material-ui/icons/Add';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

import { AppContext, AppContextProps } from 'ui/app/appContext';
import { log } from 'ui/app/app';

import { ObservableIF } from 'services/common/api/observableIF';
import { Observation } from 'services/common/api/observation';
import { Monitor } from 'services/common/api/monitor';
import { DatabaseDocumentIF } from 'services/database/api/core/databaseDocumentIF';
import {CollectionGroupPathSuffix, DocumentNameKey, NewDocumentId } from 'services/database/api/core/databaseServiceIF';

import PropertyValue, { PropertyDisplayMode } from './propertyValue';
import { DefaultChildrenExpanded, PersistentKeyChildrenExpanded } from './appFrame';
import { ReferenceHandle, referenceHandleReplacer } from '../../services/database/api/core/referenceHandle';
import { errorDialog } from './simpleDialog';
import { Factory } from '../../services/common/api/factory';
import Loading from './loading';
import { DatabaseObserverIF } from '../../services/database/api/core/databaseObserverIF';
import { DatabaseProps, DatabaseState } from './databaseView';
import CollectionIcon from './collectionIcon';
import { colorMap } from './colorMap';
import { DocumentNameProperty } from './databaseTable';
import { activeLanguage } from '../app/localization';
import { PropertyTypes } from '../../services/database/api/definitions/propertyType';


export const TreeLayouts = {
  Linear: LayoutType.LINEAR,
  Smart: LayoutType.SMART,
  Fishbone1: LayoutType.FISHBONE_1,
  Fishbone2: LayoutType.FISHBONE_2,
  Fishbone3: LayoutType.FISHBONE_3,
  Fishbone4: LayoutType.FISHBONE_4,
  SingleColumnRight: LayoutType.SINGLE_COLUMN_RIGHT,
  SingleColumnLeft: LayoutType.SINGLE_COLUMN_LEFT,
  Stackers: LayoutType.STACKERS,
  Assistants: LayoutType.ASSISTANTS
}


export type TreeLayout = keyof (typeof TreeLayouts);

const defaultLayout = TreeLayouts.SingleColumnLeft;   

const headerColorHue = 50;

const styles = (theme: Theme) => createStyles({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    overflow: 'auto',
    paddingLeft: theme.spacing(2)
  },
  tree: {
  },
  node: {
    width: theme.spacing(36),
    height: "100%",
    cursor: "pointer"
  },
  nodeHeader: {
    padding: theme.spacing(1)
  },  
  actionButton: {
    marginTop: theme.spacing(0.1),
    marginRight: theme.spacing(0.1),
    color: theme.palette.secondary.main,
    alignSelf: "center" 
  },
  nodeAvatar: {
    backgroundColor: 'transparent',
    color: theme.palette.secondary.main,
  },
  nodeContent: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(0)
  },
  nodeActions: {
  }
});


interface MatchParams {
}

export interface DatabaseTreeProps extends DatabaseProps {

  databaseObserver : DatabaseObserverIF<DatabaseDocumentIF>,

  parentPropertyKey?: string,

  childrenPropertyKey?: string,

  treeLayout?: TreeLayout,

  maxDepth?: number,

  rootDocument?: DatabaseDocumentIF,

  onAction?: ( databaseDocument : DatabaseDocumentIF ) => Promise<void>, 

  actionIcon?: JSX.Element

}

interface MatchParams {
}

export interface ReactDatabaseTreeProps extends DatabaseTreeProps,
  WithStyles<typeof styles>,
  WithTranslation,
  RouteComponentProps<MatchParams> {
}



interface DatabaseTreeState extends DatabaseState { // Component State

  parentReferences: Map<string, ReferenceHandle<DatabaseDocumentIF>>, // document path is key
  
  allChildrenDocuments: Map<string,  Map<string, DatabaseDocumentIF>>, // document path is key

  rootDocuments:  Map<string, DatabaseDocumentIF>,

  childrenExpanded: Map<string,boolean>,

  loading: boolean,
}


class DatabaseTree extends React.Component<ReactDatabaseTreeProps, DatabaseTreeState> {

  constructor(props: ReactDatabaseTreeProps) {

    super(props);

    const rootDocuments = new Map<string, DatabaseDocumentIF>();

    this.state = {

      highlightedPropertyKey: this.props.highlightedPropertyKey, 

      parentReferences: new Map<string, ReferenceHandle<DatabaseDocumentIF>>(), 

      allChildrenDocuments: new Map<string,  Map<string, DatabaseDocumentIF>>(), 

      rootDocuments: rootDocuments,

      childrenExpanded: new Map<string,boolean>(), 

      loading: true

    } as DatabaseTreeState;

    this.updateDocuments = this.updateDocuments.bind(this);

    this.children = this.children.bind(this);
    this.isAssistant = this.isAssistant.bind(this);
    this.openDocument = this.openDocument.bind(this);
    this.addDocument = this.addDocument.bind(this);

    this.childrenExpandedPersistentKey = this.childrenExpandedPersistentKey.bind(this);
    this.childrenExpanded = this.childrenExpanded.bind(this);
    this.handleExpandChildren = this.handleExpandChildren.bind(this);

    //log.traceInOut( "constructor()" );
  } 

  async componentDidMount() {

    try {
      log.traceIn("componentDidMount()");

      await this.updateDocuments();

      await this.props.databaseObserver.subscribe({ 
        observer: this,
        onNotify: this.onUpdateDatabases 
        } as Monitor );

      await this.props.databaseObserver.update();

      this.setState( {
        loading: false
      });

      log.traceOut("componentDidMount()");

    } catch( error ) {
      log.warn( "componentDidMount()", error );

      await errorDialog( error);

      log.traceOut( "componentDidMount()", error );
    }
  }

  async componentDidUpdate() {

    try {
      //log.traceIn("componentDidUpdate()");

      await this.props.databaseObserver.update();

      if( this.props.highlightedPropertyKey !== this.state.highlightedPropertyKey ) {
        
        this.setState( {
          highlightedPropertyKey: this.props.highlightedPropertyKey
        });
      }

      //log.traceOut("componentDidUpdate()");

    } catch( error ) {
      log.warn( "componentDidUpdate()", error );

      await errorDialog( error);
    }
  }


  async componentWillUnmount() {
    log.traceIn("componentWillUnmount()");

    try {

      await this.props.databaseObserver.unsubscribe( this );

      log.traceOut("componentWillUnmount()");

    } catch (error) {
      log.warn("componentWillUnmount()", "Error unmounting collection list", error);

    }
  }

  private onUpdateDatabases = async (observable : ObservableIF, observation : Observation ) => {

    //log.traceIn("onUpdateDatabases()");

    try {

      if( observable !== this.props.databaseObserver ) {
        throw new Error( "Unrecognized database observer")
      }

      await this.updateDocuments();

      //log.traceOut("onUpdateDatabases()");

    } catch (error) {
      log.warn("onUpdateDatabases()", "Error updating databases", error);

      log.traceOut("onUpdateDatabases()", "error");
    }
  }

  async updateDocuments() {

    log.traceIn("updateDocuments()" ); 

    try {
      const parentReferences = new Map<string, ReferenceHandle<DatabaseDocumentIF>>(); 

      const allChildrenDocuments = new Map<string, Map<string, DatabaseDocumentIF>>();

      let rootDocuments = new Map<string, DatabaseDocumentIF>();

      const parentPropertyFilter =
        this.props.databaseObserver.databaseFilters()?.get( this.props.parentPropertyKey! );

      log.debug("updateDocuments()", {parentPropertyFilter}, this.props.rootDocument ); 

      if( this.props.rootDocument != null ) {

        rootDocuments.set( this.props.rootDocument.databasePath(), this.props.rootDocument );
      }
      else if( parentPropertyFilter != null ) {

        const parentPropertyValue = parentPropertyFilter.value as ReferenceHandle<DatabaseDocumentIF>;

        if( parentPropertyValue != null ) {

          log.debug("updateDocuments()", {parentPropertyValue}  ); 

          const rootDocument = this.props.databaseObserver.document( parentPropertyValue.path ); 
  
          log.debug("updateDocuments()", {rootDocument}  ); 

          if( rootDocument != null ) {
  
            rootDocuments.set( rootDocument.databasePath(), rootDocument );
          }
        }
      }

      const rootDocumentsSet = rootDocuments.size > 0;

      for( const databaseDocument of this.props.databaseObserver.documents().values() ) {
      
        const databaseDocumentPath = databaseDocument.databasePath();

        const parentReferenceHandle = 
          databaseDocument.property(this.props.parentPropertyKey!)?.value() as ReferenceHandle<DatabaseDocumentIF>;

        if (parentReferenceHandle == null) {

          if (!rootDocumentsSet &&
              this.props.databaseObserver.filteredDocuments().has(databaseDocument.referenceHandle().path) ) {

            rootDocuments.set(databaseDocumentPath, databaseDocument);

            //log.debug("readDocuments()", "rootDocument with no parent:", databaseDocumentPath);
          }
        }
        else {
          //selectDatabaselog.debug("readDocuments()", "document with parent:", databaseDocumentPath, parentReferenceHandle.path );

          parentReferences.set( databaseDocumentPath, parentReferenceHandle );

          let childrenDocuments = allChildrenDocuments.get( parentReferenceHandle.path );

          if( childrenDocuments == null ) {

            childrenDocuments = new Map<string, DatabaseDocumentIF>();
          }

          childrenDocuments.set( databaseDocumentPath, databaseDocument );

          childrenDocuments = new Map([...childrenDocuments].sort((childA, childB) => {

            const titleA = childA[1].title.value() != null ? childA[1].title.value()! : "";
            const titleB = childB[1].title.value() != null ? childB[1].title.value()! : ""; 

            return titleA.localeCompare( titleB, activeLanguage() ); 
          })); 

          allChildrenDocuments.set(parentReferenceHandle.path, childrenDocuments );

        }
      } 
      
      rootDocuments = new Map([...rootDocuments].sort((childA, childB) => {
      
        const titleA = childA[1].title.value() != null ? childA[1].title.value()! : "";
        const titleB = childB[1].title.value() != null ? childB[1].title.value()! : "";

        return titleA.localeCompare( titleB, activeLanguage() );
      })); 

      this.setState({ 

        rootDocuments: rootDocuments,

        allChildrenDocuments: allChildrenDocuments,

        parentReferences: parentReferences
      });

      log.traceOut("updateDocuments()");

    } catch( error ) {
      log.warn( "updateDocuments()", error );

      await errorDialog( error);

      log.traceOut( "updateDocuments()", error );
    }
  }

  
  async openDocument( databaseDocument: DatabaseDocumentIF ) {

    log.traceInOut("openDocument()", databaseDocument);

    try {

      let to = this.context.currentHomePath + databaseDocument.databasePath( true );

      this.props.history.push(to);

      log.traceIn("openDocument()", to);

    } catch( error ) {

      log.warn( "openDocument()", error );

      await errorDialog( error);

      log.traceOut( "openDocument()", error );
    }
  }

  async addDocument( parentDocument: DatabaseDocumentIF ) {

    log.traceInOut("addDocument()", parentDocument );

    try {

      log.traceIn("addDocument()");
      
      const appContext = this.context as AppContextProps;

      let url = appContext.currentHomePath! as string;

      const childrenProperty = parentDocument.property(this.props.childrenPropertyKey!)!;

      if( childrenProperty.type === PropertyTypes.Collection ) {

         url += parentDocument.databasePath() + "/" + 
            this.props.databaseObserver.defaultDatabase()!.collectionName()+ "/" +
            NewDocumentId;
      }
      else if( childrenProperty.type === PropertyTypes.References ||
               childrenProperty.type === PropertyTypes.SymbolicCollection ||
               childrenProperty.type === PropertyTypes.SymbolicOwners ) {

        url += this.props.databaseObserver.defaultDatabase()!.databasePath();
  
        if (url.endsWith(CollectionGroupPathSuffix)) {
  
          url = url.split(CollectionGroupPathSuffix)[0];
        }
  
        url += "/" + NewDocumentId;
  
        if( parentDocument != null && parentDocument.id.value() != null ) {
  
          url += "?" + this.props.parentPropertyKey + "=" + JSON.stringify( parentDocument.referenceHandle(), referenceHandleReplacer );
        }
      }
      else {
        throw new Error( "Can't add property type: " + childrenProperty.type );
      }

      const search = new URLSearchParams(this.props.location.search);

      const documentName = search.get( DocumentNameKey );

      if( documentName != null ) {
        url += "?" + DocumentNameKey + "=" + documentName;
      }
      else {
        url += "?" + DocumentNameKey + "=" + parentDocument.documentName();
      }

      this.props.history.push(url);

      log.traceOut("addDocument()", url );

    } catch( error ) {
      
      log.warn( "addDocument()", error );

      await errorDialog( error);

      log.traceOut( "addDocument()", error );
    }
  }

  private childrenExpandedPersistentKey( databaseDocument: DatabaseDocumentIF ): string {
    return this.props.databaseObserver.defaultDatabase()!.defaultDocumentName()! + "." + databaseDocument.id.value() + "." + PersistentKeyChildrenExpanded;
  }

  private childrenExpanded( databaseDocument: DatabaseDocumentIF ): boolean {

    //log.traceIn("childrenExpanded()");

    const databaseDocumentPath = databaseDocument.databasePath();

    let documentChildrenExpanded = this.state.childrenExpanded.get( databaseDocumentPath );

    if( documentChildrenExpanded != null ) {
      //log.traceOut("childrenExpanded()", "From state", documentChildrenExpanded );
      return documentChildrenExpanded;
    }

    documentChildrenExpanded = Factory.get().persistentState!.property( this.childrenExpandedPersistentKey( databaseDocument ) );

    if( documentChildrenExpanded != null ) {
      //log.traceOut("childrenExpanded()", "From persistent state", documentChildrenExpanded );
      return documentChildrenExpanded;
    }

    //log.traceOut("childrenExpanded()", "From default", DefaultChildrenExpanded );
    return DefaultChildrenExpanded;
  };

  handleExpandChildren = async ( databaseDocument: DatabaseDocumentIF ) => {

    log.traceIn("handleExpandChildren()", databaseDocument);

    try {      
      const databaseDocumentPath = databaseDocument.databasePath();

      const childrenExpanded = this.state.childrenExpanded;

      const documentChildrenExpanded = this.childrenExpanded( databaseDocument );

      childrenExpanded.set( databaseDocumentPath, !documentChildrenExpanded );

      Factory.get().persistentState!.setProperty( this.childrenExpandedPersistentKey( databaseDocument ), !documentChildrenExpanded);

      this.setState( {
        childrenExpanded: childrenExpanded
      });
      
      log.traceOut("handleExpandChildren()", childrenExpanded);

    } catch( error ) {
      
      log.warn( "handleExpandChildren()", error );

      await errorDialog( error);
    }
  }

  children( databaseDocument : DatabaseDocumentIF ) : DatabaseDocumentIF[] {
    //log.traceIn("children()");

    try {
      const databaseDocumentPath = databaseDocument.databasePath();

      const documentChildrenExpanded = this.childrenExpanded( databaseDocument );

      if( !documentChildrenExpanded ) {
        //log.traceOut("children()", "not expanded" );
        return [];
      }

      let childrenDocuments = this.state.allChildrenDocuments.get( databaseDocumentPath );

      if( childrenDocuments == null ) {
        childrenDocuments = new Map<string,DatabaseDocumentIF>();
      }

      //log.traceOut("children()", "expanded", childrenDocuments.values() );
      return Array.from( childrenDocuments.values() ); 

    } catch (error) {
      log.warn("children()", "Error opening children", error);

      //log.traceOut("children()", "error"); 
      return [];
    }
  }

  isAssistant( databaseDocument : DatabaseDocumentIF ) : boolean {
    //log.traceIn("isAssistant()", document );

    try {

      const result = false;

      //log.traceOut("isAssistant()", result );
      return result;

    } catch (error) {
      log.warn("isAssistant()", "Error unmounting collection list", error);

      //log.traceOut("isAssistant()", "error");
      return false;
    }
  }

  render(): JSX.Element {

    //log.traceInOut("render()", this.state.rootDocuments );

    const { classes } = this.props;

    const lineHorizontalStyle: React.CSSProperties = {
      borderTop: "2px solid rgba(0,0,0,0.15)",
      transition: "800ms transform, 800ms width, 800ms height",
    };
  
    const lineVerticalStyle: React.CSSProperties = {
      borderLeft: "2px solid rgba(0,0,0,0.15)",
      transition: "800ms transform, 800ms width, 800ms height",
    };
  
    const containerStyle: React.CSSProperties = {
      margin: "20px auto",
      // pointerEvents: "none",
      // transition: "800ms width, 800ms height",
    };
    
    const renderNodeContainer = (
      databaseDocument : DatabaseDocumentIF,
      props: NodeContainerRenderProps<DatabaseDocumentIF>,
      context: NodeContainerRenderContext<DatabaseDocumentIF> ) => ( 
        
        <Animated
          key={props.key}
          node={databaseDocument}
          props={props}
          context={context}
        />
    );
    

    const renderNodeLine = (
      databaseDocument : DatabaseDocumentIF,
      props: NodeLineRenderProps<DatabaseDocumentIF>,
      context: NodeLineRenderContext<DatabaseDocumentIF> ) => (
      <Animated
        key={props.key}
        node={databaseDocument}
        props={props}
        context={context}
        getStyle={() => ({
          [context.direction === "horizontal"
            ? "borderTop"
            : "borderLeft"]: "2px solid rgba(0,0,0,0.15)",
        })}
      />
    );
     
    
    const renderNode = ( databaseDocument : DatabaseDocumentIF ) => {

      //log.traceIn("renderNode()", databaseDocument );

      const databaseDocumentPath = databaseDocument.databasePath();

      let depth = 0;

      let parentReference = this.state.parentReferences.get( databaseDocumentPath );

      while( parentReference != null ) {

        depth++;

        parentReference = this.state.parentReferences.get( parentReference.path );
      }  

      const documentChildrenExpanded = this.childrenExpanded( databaseDocument );

      const mainProperty = this.state.highlightedPropertyKey != null && this.state.highlightedPropertyKey !== DocumentNameProperty ?  
        databaseDocument.property( this.state.highlightedPropertyKey ) : 
        undefined;

      let children = this.state.allChildrenDocuments.get( databaseDocumentPath );

      let hasChildren = children != null && children.size > 0; 

      //log.traceOut("renderNode()", databaseDocument.title.value(), {depth}, {hasChildren}, this.state.highlightedPropertyKey );

      return (
        <>
          <Card
            variant="elevation"
            className={classes.node}
            key={databaseDocumentPath}
            onClick={( event ) => {
              this.openDocument(databaseDocument);
            }} 
            >
            <CardHeader
              className={classes.nodeHeader}
              style={{ backgroundColor: colorMap( this.props.databaseObserver.defaultDatabase()!.collectionName(), depth, headerColorHue )}}
              avatar={
                <Avatar className={classes.nodeAvatar} > 
                  <CollectionIcon collectionName={this.props.databaseObserver.defaultDatabase()!.collectionName()} />
                </Avatar>
              }
              title={<Typography align="left"><b>{databaseDocument.title.value()}</b></Typography>} 
              action={ this.props.onAction == null ? undefined :
                <IconButton 
                  size="small" 
                  key="action" 
                  onClick={(event) => {
                    event.stopPropagation();
                    this.props.onAction!(databaseDocument) 
                  }}>
                  {this.props.actionIcon != null ? this.props.actionIcon : <MoreHorizIcon />}
                </IconButton>
              }
              classes={{ action: classes.actionButton}}
            />
            {mainProperty != null &&
              <CardContent
                className={classes.nodeContent}
              >
                <Grid item>
                  <Typography variant="subtitle1" align="center" color="textSecondary">
                    <PropertyValue property={mainProperty} displayMode={PropertyDisplayMode.Cell} singleProperty={false} />
                  </Typography>
                </Grid>
              </CardContent>
            }
            <CardActions 
              className={classes.nodeActions}
              >
              <Grid item container justifyContent="space-between">
                {this.props.maxDepth != null && depth >= this.props.maxDepth ? null :
                  <Grid item key="addDocument">
                    <IconButton 
                      size="small" 
                      key="add" 
                      onClick={(event) => {
                        event.stopPropagation();
                        this.addDocument(databaseDocument) 
                      }}>
                      <AddIcon />
                    </IconButton>
                  </Grid>
                }
                {!hasChildren ? null :
                  <Grid item key="expandChildren">
                    <IconButton 
                      size="small" 
                      onClick={( event ) => {
                        event.stopPropagation();
                        this.handleExpandChildren(databaseDocument);
                      }} >
                      {documentChildrenExpanded ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                  </Grid>
                }
              </Grid>
            </CardActions>
          </Card>
        </>
      );
    }

    return (
      <>
        <AppContext.Consumer>
          {appContext => (
            <Box className={classes.root}>
              {this.state.loading ? <Loading /> :
                <Grid 
                  item 
                  container 
                  direction="column" 
                  justifyContent="flex-start" 
                  alignItems="flex-start" 
                  spacing={1}
                  style={{flexWrap: "nowrap"}}>   
                  {Array.from( this.state.rootDocuments.values()).map(rootDocument =>
                    <Grid item className={classes.tree} key={"rootDocument" + rootDocument.databasePath()}>
                      <OrgChart<DatabaseDocumentIF>
                        // required
                        root={rootDocument}
                        isValidNode={(databasePath: string) => { return databasePath != null }}
                        keyGetter={(databaseDocument: DatabaseDocumentIF) => { return databaseDocument.databasePath() }}
                        renderNode={renderNode}
                        renderNodeLine={renderNodeLine}
                        renderNodeContainer={renderNodeContainer}
                        childNodesGetter={this.children}
                        lineHorizontalStyle={lineHorizontalStyle} 
                        lineVerticalStyle={lineVerticalStyle}
                        containerStyle={containerStyle}
                        measureStrategy="effect"
                        connectorThickness={2}
                        connectorAlignment={ConnectorAlignment.Center}
                        isAssistantGetter={this.isAssistant}
                        layout={(this.props.treeLayout != null ? this.props.treeLayout : defaultLayout) as LayoutType}
                        debug={false}
                      />
                    </Grid>
                  )}
                </Grid>
              }
            </Box>
          )}
        </AppContext.Consumer> 
      </> 
    );
  }
}

DatabaseTree.contextType = AppContext;

const ModifiedDatabaseTree = withRouter(withTranslation()(withStyles(styles)(DatabaseTree)));

export default ModifiedDatabaseTree;

