
export const MedicalCertifications = {

    MedicalDoctor       : "medicalDoctor",

    RegisteredNurse     : "registeredNurse"

}

export type MedicalCertification = keyof (typeof MedicalCertifications); 


 