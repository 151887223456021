import React from 'react';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import { log } from "ui/app/app";


import { withTranslation, WithTranslation } from 'react-i18next';
import { TranslationKey } from '../../services/common/api/translatorIF';
import { Button, createStyles, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Radio, RadioGroup, Theme, WithStyles, withStyles } from '@material-ui/core';
import { DatabaseDocumentIF } from '../../services/database/api/core/databaseDocumentIF';
import { PropertiesSelector } from '../../services/database/api/core/propertiesSelector';
import { translatedPropertyLabel } from './propertyLabel';
import { Factory } from '../../services/common/api/factory';
import { translatedCollectionName } from './collectionName';
import { AppContextProps } from '../app/appContext';
import { HealthguardDatabaseFactoryIF } from '../../healthguard/api/healthguardDatabaseFactoryIF';
import { RegistrationDocument } from '../../healthguard/api/registrationDocuments';
import { HealthguardDocumentDefinition } from '../../healthguard/api/healthguardDefinitions';
import { translatedDocumentName } from './documentName';
import { activeLanguage } from '../app/localization';

export const ButtonKeyOK = "ok";
export const ButtonKeyCancel = "cancel";

export const EmptyOption = "notSet";


const styles = (theme: Theme) => createStyles({
  content: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(6)
  },
  buttons: {
    display: "flex",
    justifyContent: 'flex-end',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(6)
  }
});

export const selectRegistration = async (
  appContext: AppContextProps,
  title: string | undefined): Promise<string | null | undefined> => {

  log.traceIn("selectRegistration()");

  try {
    const registrationDocumentNames: string[] = [];

    (Factory.get().databaseService.databaseFactory as HealthguardDatabaseFactoryIF).registrationDocumentNames().forEach(
      registrationDocumentName => {

        if (registrationDocumentName !== RegistrationDocument &&
            !appContext.currentCompany?.subcollectionDisabled(registrationDocumentName) ) {

          registrationDocumentNames.push(registrationDocumentName)
        }
      });

    const addDocumentName = await selectDialog( {
      documentNames: [HealthguardDocumentDefinition],
      options: registrationDocumentNames,
      title: title,
      value: undefined,
      disableEmptyOption: true,
      translate: true,
      sort: true
    });

    log.traceOut("selectProperty()", "no property selected");
    return addDocumentName;

  } catch (error) {

    log.warn("selectProperty()", error);
    return undefined;
  }
}

export const selectProperty = async ( 
  sourceDocument : DatabaseDocumentIF, 
  propertiesSelector : PropertiesSelector,
  title: string | undefined,
  currentPropertyKey : string | null | undefined) : Promise<string | null | undefined> => {

  log.traceIn("selectProperty()", {currentLinkPropertyKey: currentPropertyKey});

  try {
    const properties = sourceDocument.properties(propertiesSelector);

    if (properties != null) {

      let propertyLabels = new Map<string, string>();

      properties.forEach(property => {

        const propertyKey = property.key();

        const propertyLabel =
          translatedPropertyLabel(sourceDocument.documentName(), propertyKey);

        propertyLabels.set(propertyLabel, propertyKey);
      })

      const currentPropertyLabel = currentPropertyKey != null ? 
        translatedPropertyLabel(sourceDocument.documentName(), currentPropertyKey) :
        currentPropertyKey; 

      const selectedPropertyLabel = await selectDialog( {
        documentNames: [sourceDocument.documentName()],
        options: Array.from(propertyLabels.keys()),
        title: title,
        value: currentPropertyLabel,
        sort: true 
      });

      if (selectedPropertyLabel === undefined) {
        log.traceOut("selectProperty()", "cancel");
        return undefined;
      }

      if (selectedPropertyLabel === null) {
        log.traceOut("selectProperty()", "empty option");
        return null;
      }

      const selectedPropertyKey = propertyLabels.get(selectedPropertyLabel)!;

      log.traceOut("selectProperty()", {selectedPropertyKey});
      return selectedPropertyKey;
    }
  } catch (error) {

    log.warn("selectProperty()", error);
    return undefined;
  }
}

export const selectDialog = ( params: {
  documentNames : string[], 
  options: string[],
  title: string | undefined,
  value: string | null | undefined,
  disableEmptyOption? : boolean,
  translate? : boolean,
  sort? : boolean } ): Promise<string | null | undefined> => {

  return new Promise<string | null | undefined>((resolve, reject) => {

    log.traceIn("selectDialog()", {params}); 

    try {

      confirmAlert({
        customUI: ({ onClose }) => {

          const onComplete = (optionKey: string | null | undefined) => {

            onClose();

            log.traceOut("confirm()", optionKey);
            resolve(optionKey);
          }

          return (
            <ModifiedSelectDialog
              documentNames={params.documentNames}
              options={params.options}
              title={params.title}
              onComplete={onComplete}
              value={params.value}
              disableEmptyOption={params.disableEmptyOption}
              translate={params.translate}
              sort={params.sort}
              >
            </ModifiedSelectDialog>
          );
        }
      });

    } catch (error) {

      log.warn("confirm()", error);

      reject();
    }
  });
}


interface SelectDialogProps extends WithStyles<typeof styles>, WithTranslation {

  documentNames: string[],

  options: string[],

  title?: string,

  value : string | null | undefined,

  disableEmptyOption?: boolean,

  translate?: boolean,

  sort? : boolean,

  onComplete: (buttonKey: string | null | undefined ) => void
}


interface SelectDialogState {

  open: boolean,

  selectedOption: string | null | undefined
}

class SelectDialog extends React.PureComponent<SelectDialogProps, SelectDialogState> {

  constructor(props: SelectDialogProps) {

    super(props);

    this.state = {
      open: false

    } as SelectDialogState;

    this.titleTranslation = this.titleTranslation.bind(this);
    this.optionTranslation = this.optionTranslation.bind(this);

    //log.traceInOut( "constructor()" );
  }


  async componentDidMount() {

    log.traceIn("componentDidMount()");

    const selectedOption = this.props.value === undefined ? undefined :
      this.props.value === null ? EmptyOption :
        this.props.value;

    this.setState({ 

      open: true,

      selectedOption: selectedOption 
    });

    log.traceIn("componentDidMount()");
  }

  private titleTranslation( title: string) {

    let result;

    let translationKey = TranslationKey.Prompts + ":" + title;

    if (this.props.i18n.exists(translationKey)) {

      return this.props.t(translationKey);
    }

    if (this.props.i18n.exists(title)) {

      result = this.props.t(title);
    }
    else {
      result = title;
    }
    return result;
  }

  private optionTranslation(option: string) {

    //log.traceIn("optionTranslation()", {option});

    let result;

    if( Factory.get().databaseService.databaseFactory.collectionNames().includes( option ) ) {
      result = translatedCollectionName( option );
      return result;
    }

    if( Factory.get().databaseService.databaseFactory.documentNames().includes( option ) ) {
      result = translatedDocumentName( option );
      return result;
    }

    for( const documentName of this.props.documentNames ) {

      const translationKey = documentName + ":" + TranslationKey.Values + ":" + option;

      if (this.props.i18n.exists(translationKey)) {

        result = this.props.t(translationKey);
        return result;
      }
    }

    if (this.props.i18n.exists(option)) {

      result = this.props.t(option);
    }
    else {
      result = option;
    }
    return result;
  }

  render() {

    //log.traceInOut("render()");

    const { classes } = this.props;

    const handleOk = () => {

      log.traceInOut("handleClose()");

      this.setState({ open: false });

      this.props.onComplete( 
        this.state.selectedOption === EmptyOption ?
          null : 
          this.state.selectedOption 
        );
      }

    const handleCancel = () => {

      log.traceInOut("handleCancel()");

      this.setState({ open: false });

      this.props.onComplete(undefined);
    }

    const onSelect = ( selectedOption : string ) => {

      log.traceInOut("onSelect()", {selectedOption});

      if( selectedOption === this.state.selectedOption ) {
        if( !this.props.disableEmptyOption ) {
          this.setState({ selectedOption: null } );
        }
      }
      else if( selectedOption === EmptyOption ) {
        this.setState({ selectedOption: EmptyOption } );
      }
      else {
        this.setState({ selectedOption: selectedOption });
      }
    }

    const title = !!this.props.translate && this.props.title != null? 
      this.titleTranslation(this.props.title) : this.props.title;

    const options = this.props.options;

    if( !!this.props.sort ) {
      options.sort( (optionA, optionB) => {

        if( !!this.props.translate ) {
          return this.optionTranslation(optionA).localeCompare( this.optionTranslation(optionB), activeLanguage() );

        } 
        else {       
          return optionA.localeCompare( optionB, activeLanguage() );
        }
      }); 
    }


    return (
      <React.Fragment>
        <Dialog
          open={this.state.open}
          onClose={() => handleCancel()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description" 
        >
          {this.props.title == null ? null :
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle> 
          }
          <DialogContent className={classes.content}>
            <RadioGroup
              value={this.state.selectedOption != null ? this.state.selectedOption : "" } 
              >
              {!this.props.disableEmptyOption &&
                <FormControlLabel
                  onClick={(event) => { event.preventDefault(); onSelect(EmptyOption) }}
                  value={EmptyOption}
                  key={EmptyOption}
                  control={<Radio />}
                  label={this.optionTranslation( EmptyOption )} />
              }
              {options.map((option) => (
                  <FormControlLabel
                    onClick={(event) => { event.preventDefault(); onSelect(option) }}
                    value={option}
                    key={option}
                    control={<Radio />}
                    label={!!this.props.translate ? this.optionTranslation(option) : option} /> 
              ))} 
            </RadioGroup>
          </DialogContent>
          <DialogActions className={classes.buttons}>
            <Button autoFocus onClick={handleCancel} color="primary">
              {this.optionTranslation(ButtonKeyCancel)}
            </Button>
            <Button onClick={handleOk} color="primary">
              {this.optionTranslation(ButtonKeyOK)}
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

const ModifiedSelectDialog = withTranslation()(withStyles(styles)(SelectDialog));

export default ModifiedSelectDialog;

