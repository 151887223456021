 
export const DisplayTypes = {

  Mobile: "mobile",

  Tablet: "tablet",

  Desktop: "desktop"
}
export type DisplayType = keyof (typeof DisplayTypes);

export const DisplayPlatforms = {

  Web: "web",

  IOS: "ios",

  Android: "android"
}
export type DisplayPlatform = keyof (typeof DisplayPlatforms);

export const DisplayOrientations = {

  Vertical: "vertical",

  Horizontal: "horizontal"
}
export type DisplayOrientation = keyof (typeof DisplayOrientations);


export type Display = {    

    displayType?: DisplayType,

    displayPlatform?: DisplayPlatform,

    displayOrientation?: DisplayOrientation,

    width?: number,

    height?: number

}
  