import React from "react";

const UrlHttpPrefix = "http://";
const UrlHttpsPrefix = "https://";

export const browserView = async ( title: string, url : string ) => {

  const fullUrl = url.startsWith( UrlHttpPrefix ) || url.startsWith( UrlHttpsPrefix ) ? 
  url : 
  UrlHttpPrefix + url; 

  window.open( fullUrl, '_blank');
}

export default function BrowserView( props: {
  title: string,
  url: string } ) {

    React.useEffect( () => {

      browserView( props.title, props.url );
  
    }, [props.title,props.url]); 


  return ( null );
}

