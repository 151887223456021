import { DatabaseDocument } from "../../framework/databaseDocument";
import { DatabaseProperty } from "../../framework/databaseProperty";
import { CollectionDatabase } from "../core/collectionDatabase";
import { log } from "../../framework/databaseService";
import { DatabaseObject } from "../../framework/databaseObject";
import { Factory } from "../../../common/api/factory";
import { PropertyType, PropertyTypes } from "../../api/definitions/propertyType"; 
import { OwnerTitleSuffix, IdSuffix } from "../../api/core/databaseServiceIF";
import { TenantPropertyIF } from "../../api/properties/tenantPropertyIF";
import { ReferenceHandle } from "../..";
import { CollectionGroupDatabase } from "../core/collectionGroupDatabase";
import { Database } from "../core/database";

export class TenantProperty<DerivedDocument extends DatabaseDocument> 
    extends DatabaseProperty<ReferenceHandle<DerivedDocument>> implements TenantPropertyIF<DerivedDocument> {

        constructor( parent : DatabaseObject, collectionName : string, documentName : string ) {
        
        super( PropertyTypes.Tenant as PropertyType, parent ); 
        
        //log.traceIn( "constructor()", parent.title, collectionName );

        try {

            this._collectionName = collectionName;  

            this._documentName = documentName;

           //log.traceOut( "constructor()" );
        } catch( error ) {
            
            log.warn( "constructor()", "Error initializing document owner", error );

            throw new Error( (error as any).message );
        }
    }

    collectionName() : string {
        return this._collectionName;
    }

    documentName() : string {
        return this._documentName;
    }

    value() {
        return this.referenceHandle();
    }

    setValue( value : ReferenceHandle<DerivedDocument> | undefined ) : void {

        //log.traceIn("setValue()", value ); 

        try {

            const referenceHandle = this.referenceHandle();

            if( value?.path == null ) {
                
                const changed = referenceHandle != null;
                this.cleared = true;

                if( changed ) {

                    this.setLastChange( referenceHandle );
                    
                    delete this._selectCollection;
                    delete this._selectCollectionGroup;

                }

                //log.traceOut("setValue()", "empty" );
                return;
            }

            this.cleared = false;

            if( referenceHandle != null && 
                Factory.get().databaseService.databaseFactory.equalDatabasePaths( referenceHandle.path, value.path ) ) {

                if( value.title != null && value.title !== referenceHandle.title ) {

                    //log.debug("setValue()", "updated title" );

                    this._title = value.title;
                }

                this.clearChanges();

                log.traceOut("setValue()", "no change to path" );
                return;
            }

            if( typeof value.path !== "string" ) {
                throw new Error( "Unrecognized path format");
            }
            this._path = value.path;

            if( value.title != null ) {
                this._title = value.title;
            }

            this.setLastChange( referenceHandle );

            delete this.error;

            delete this._selectCollection;
            delete this._selectCollectionGroup;
    
            //log.traceOut("setValue()");

        } catch (error) {

            log.warn("setValue()", "Error setting value", error);

            throw new Error( (error as any).message );
        }
    } 


    id( ignoreCleared? : boolean ) : string | undefined {

        const path = this.path();

        return path == null ? undefined :
            Factory.get().databaseService.databaseFactory.documentId( path );
    }

    path( ignoreCleared? : boolean ) : string | undefined {

        if( this.cleared && !ignoreCleared ) {
            //log.traceOut( "handles()", "cleared" );
            return undefined;
        }
        
        if( this._path == null ) {
            this._path = this.parent.ownerDocumentPath( this._collectionName )!;
        }

        return this._path;
    }

    title() : string | undefined {

        //log.traceIn( "title()" );

        const referenceHandle = this.referenceHandle();

        const title = referenceHandle?.title;

        //log.traceOut( "title()", {title});
        return title; 
    }

    emptyDocument() : DerivedDocument | undefined {

        const path = this.path();

        if( path == null ) {
            return undefined;
        }

        const document = Factory.get().databaseService.databaseFactory.newDocumentFromUrl( path ) as DerivedDocument;

        //document.title.setValue( this.title() );

        return document;
    }

    async document( ignoreCleared? : boolean ): Promise<DerivedDocument | undefined> {

        //log.traceIn( "document()", monitor );

        try {
            const id = this.id( ignoreCleared );

            const path = this.path( ignoreCleared );

            if( path == null || id == null ) {
                //log.traceOut( "document()", undefined );
                return undefined;
            }
    
            if( this._document == null ) {

                const ownerDocument = 
                    await Factory.get().databaseService.databaseFactory.documentFromUrl( path ) as DerivedDocument;

                if( ownerDocument == null ) {

                    delete this._title;

                    delete this._encryptedTitle;

                    delete this._document;

                    //log.traceOut( "("+this.collectionName()+")", "document()", "not found" );
                    return undefined;
                }

                this._title = ownerDocument.title.value();

                delete this._encryptedTitle;

                this._document = ownerDocument;
            }            

            //log.traceOut( "("+this.collectionName()+")", "document()", this._document );
            return this._document!; 

        } catch( error ) {

            log.warn( "document()", "Error reading database objects", error );

            throw new Error( (error as any).message );
        }    
    }

    
    async select( collectionGroup? : boolean ): Promise<Map<string,ReferenceHandle<DerivedDocument>> | undefined> {
 
        log.traceIn( "select()", {collectionGroup});

        let result = new Map<string,ReferenceHandle<DerivedDocument>>();
        try {

            const ignoreCleared = true;

            let thisReferenceHandle = this.referenceHandle( ignoreCleared );

            if( thisReferenceHandle != null ) {

                if( thisReferenceHandle.title == null ) {

                    await this.document( ignoreCleared );

                    thisReferenceHandle = this.referenceHandle( ignoreCleared );
                }

                result.set( thisReferenceHandle!.path, thisReferenceHandle! );
            }

            const database = this.selectDatabase( collectionGroup );

            if( database == null ) {
                log.traceOut( "select()", "No select database" );
                return result;
            }

            const referenceHandles = await database.referenceHandles();

            referenceHandles.forEach( referenceHandle => {
                
                if( thisReferenceHandle == null || 
                    !Factory.get().databaseService.databaseFactory.equalDatabasePaths( 
                        thisReferenceHandle.path, referenceHandle.path )) {

                    result.set( referenceHandle.path, referenceHandle );
                }
            });

            log.traceOut( "select()", "from root" );
            return result;  

        } catch( error ) {

            log.warn( "select()", "Error reading database objects", error );

            throw new Error( (error as any).message );
        }    
    }

    selectDatabase( collectionGroup? : boolean ) : Database<DerivedDocument> | undefined {

        log.traceIn("selectDatabase()", {collectionGroup} );

        try {
            if( !!collectionGroup ) {
                if( this._selectCollectionGroup == null ) {
                    this._selectCollectionGroup = this.parent.parentCollectionGroup( this._collectionName ) as CollectionGroupDatabase<DerivedDocument>;
                }
                return this._selectCollectionGroup;
            }
            else {
                if( this._selectCollection == null ) {
                    this._selectCollection = this.parent.parentCollection( this._collectionName ) as CollectionDatabase<DerivedDocument>;
                }
                return this._selectCollection;
            }
            //log.traceOut("selectDatabase()", "found" );

        } catch (error) {
            log.warn("selectDatabase()", "Error selecting database", error);

            throw new Error( (error as any).message );
        }
    }


    fromData( documentData: any): void {

        // We don't read owner id from data as it is read in constructor. Only written to DB for group query purposes

        let ownerTitle;

        if( this.isEncryptedData( documentData[this.key() + OwnerTitleSuffix] ) ) {   

            this._encryptedTitle = documentData[this.key() + OwnerTitleSuffix];
        }
        else {
            ownerTitle = documentData[this.key() + OwnerTitleSuffix];

            if( this._title == null && ownerTitle != null ) {
                this._title = ownerTitle;
            }
        }
    }

    async toData( documentData: any, force? : boolean ) : Promise<void> {

        if( !!force ) {
            this.referenceHandle();
        }

        if( this.encrypted() && this.encryptedData() != null ) {
            
            documentData[this.key()] = this.encryptedData();
            return;
        }
        
        const id = this.id();

        const key = this.key();

        if( id != null && key != null ) {
            documentData[key + IdSuffix] = id;
        }

        if( this._title != null ) { 

            if( this.encrypted() ) {
                documentData[key + OwnerTitleSuffix] = this.encryptData( this._title )
            }
            else {
                documentData[key + OwnerTitleSuffix] = this._title;
            }
        }
    }

    referenceHandle( ignoreCleared? : boolean ) : ReferenceHandle<DerivedDocument> | undefined {
        //log.traceIn( "referenceHandle()", this, this._path, this._title );

        const path = this.path( ignoreCleared );

        if( path == null ) {
            //log.traceOut( "referenceHandle()", "no path", undefined );
            return undefined;
        }

        if( this._encryptedTitle != null ) {
    
            this._title = Factory.get().securityService.symmetricCipher.decrypt( this._encryptedTitle  );

            delete this._encryptedTitle; 
        }

        const title = this._title;

        const date = this._document?.referenceDateProperty()?.value();

        const referenceHandle = {
            path: path,
            title: title,
            date: date,
            databaseDocument: this._document
        } as ReferenceHandle<DerivedDocument>; 

        //log.traceOut( "referenceHandle()", {referenceHandle} );
        return referenceHandle;
    }


    compareTo( other : TenantProperty<DerivedDocument> ) : number {

        return this.compareValue( other.value() );
    }

    compareValue( otherValue : ReferenceHandle<DerivedDocument> | undefined ) : number {

        const value = this.value();

        if( value?.path == null && otherValue?.path == null ) {
            return 0;
        }

        if( value?.path == null && otherValue?.path != null ) {
            return -1;
        }

        if( value?.path != null && otherValue?.path == null ) { 
            return 1;
        }

        const pathCompare = value!.path.split("?")[0].localeCompare( otherValue!.path.split("?")[0] );

        const titleCompare = value!.title != null && otherValue?.title != null ? 
            value!.title!.localeCompare( otherValue!.title! ) : 0;

        return pathCompare !== 0 && titleCompare !== 0 ?
            titleCompare :
            pathCompare;
    }

    includes( other : TenantProperty<DerivedDocument> ) : boolean {
        return this.includesValue( other.value() );
    }

    includesValue( otherValue : ReferenceHandle<DerivedDocument> | undefined ) : boolean {

        const path = this.path();

        if( path == null && otherValue == null ) {
            return false;
        }

        if( path == null && otherValue != null ) {
            return false;
        }

        if( path != null && otherValue == null ) {
            return false;
        }

        const otherPath = otherValue!.path;

        if( path == null && otherPath == null ) {
            return false;
        }

        if( path == null && otherPath != null ) {
            return false;
        }

        if( path != null && otherPath == null ) {
            return false;
        }

        return path!.split("?")[0].startsWith( otherPath!.split("?")[0] );    
    }

    copyValueFrom( other : TenantProperty<DerivedDocument> ) : void {

        this.cleared = other.cleared;    

        const ignoreCleared = true;

        delete this._selectCollection;
        delete this._selectCollectionGroup;

        const thisPath = this.path( ignoreCleared );

        const otherPath = other.path( ignoreCleared );

        if( otherPath != null && thisPath === otherPath ) {
            
            this._document = other._document; 
        }

        this._title = other._title; 

        this._encryptedTitle = other._encryptedTitle; 

        this.copyStatesFrom( other );
    }

    cleared : boolean = false;

    private readonly _collectionName : string;

    private readonly _documentName : string;

    private _path? : string;

    private _title? : string; 

    private _document : DerivedDocument | undefined | null;

    private _selectCollection? : CollectionDatabase<DerivedDocument>;

    private _selectCollectionGroup? : CollectionGroupDatabase<DerivedDocument>;
    
    private _encryptedTitle? : string;
}
