import React from 'react';
import ReactDOMServer from 'react-dom/server';

import {unescape} from 'html-escaper';

import { withTranslation, WithTranslation } from 'react-i18next';

import { DatabasePropertyIF } from 'services/database/api/core/databasePropertyIF';
import { DefinitionPropertyIF } from 'services/database/api/properties/definitionPropertyIF';
import { DatePropertyIF } from 'services/database/api/properties/datePropertyIF';
import { DataPropertyIF } from 'services/database/api/properties/dataPropertyIF';
import { SubdocumentPropertyIF } from 'services/database/api/properties/subdocumentPropertyIF';
import { ReferencePropertyIF } from 'services/database/api/properties/referencePropertyIF';
import { DocumentsPropertyIF } from 'services/database/api/properties/documentsPropertyIF';
import { MapPropertyIF } from 'services/database/api/properties/mapPropertyIF';
import { TextsPropertyIF } from 'services/database/api/properties/textsPropertyIF';
import { TextPropertyIF } from 'services/database/api/properties/textPropertyIF';
import { NumberPropertyIF } from 'services/database/api/properties/numberPropertyIF';
import { BooleanPropertyIF } from 'services/database/api/properties/booleanPropertyIF';
import { CollectionPropertyIF } from 'services/database/api/properties/collectionPropertyIF';
import { TenantPropertyIF } from 'services/database/api/properties/tenantPropertyIF';
import { OwnerPropertyIF } from 'services/database/api/properties/ownerPropertyIF';
import { PhoneNumberPropertyIF } from '../../services/database/api/properties/phoneNumberPropertyIF';

import OwnerPropertyValue from './properties/ownerPropertyValue';
import CollectionPropertyValue from './properties/collectionPropertyValue';
import DocumentPropertyValue from './properties/documentPropertyValue';
import DocumentsPropertyValue from './properties/documentsPropertyValue';
import TextPropertyValue from './properties/textPropertyValue';
import TextsPropertyValue from './properties/textsPropertyValue';
import DatePropertyValue from './properties/datePropertyValue';
import NumberPropertyValue from './properties/numberPropertyValue';
import BooleanPropertyValue from './properties/booleanPropertyValue';
import PhoneNumberPropertyValue from './properties/phoneNumberPropertyValue';
import SubdocumentPropertyValue from './properties/subdocumentPropertyValue';
import ImagePropertyValue from './properties/imagePropertyValue';
import AttachmentsPropertyValue from './properties/attachmentsPropertyValue';
import LinksPropertyValue from './properties/linksPropertyValue';

import { DefinitionsPropertyIF } from 'services/database/api/properties/definitionsPropertyIF';
import { TranslationKey } from '../../services/common/api/translatorIF';
import { Factory } from '../../services/common/api/factory';
import { HealthguardDatabaseFactoryIF } from '../../healthguard/api/healthguardDatabaseFactoryIF';
import { HealthguardDocumentNames } from '../../healthguard/api/healthguardDocuments';
import { translatedDefinition } from './definitionText';
import { HealthguardDocumentDefinition } from '../../healthguard/api/healthguardDefinitions';
import { ImagePropertyIF } from '../../services/database/api/properties/imagePropertyIF';
import { AttachmentsPropertyIF } from '../../services/database/api/properties/attachmentsPropertyIF';
import { LinksPropertyIF } from '../../services/database/api/properties/linksPropertyIF ';
import GeolocationPropertyValue from './properties/geolocationPropertyValue';
import { GeolocationPropertyIF } from '../../services/database/api/properties/geolocationPropertyIF';
import { CountryPropertyIF } from '../../services/database/api/properties/countryPropertyIF';
import CountryPropertyValue from './properties/countryPropertyValue';
import MapPropertyValue from './properties/mapPropertyValue';
import DataPropertyValue from './properties/dataPropertyValue';
import { PropertyTypes } from '../../services/database/api/definitions/propertyType';


export enum PropertyDisplayMode {
  Form,
  Cell,
  Filter,
  Text,
  Title
}

export const InputVariants = {

  Standard: "standard" as any,

  Outlined: "outlined" as any,

  Filled: "filled" as any
}

export type InputVariant = keyof (typeof InputVariants);

export const propertyInputVariant = InputVariants.Standard;    

//export const chipSelectInputMinHeight = 50; 

export const tagLimit = 4;

export const showTagLimitCount = 5;

export const translatedPropertyValue = ( documentName : string, property? : string ) => {

  //log.traceIn( "translatedPropertyValue()", documentName, propertyValue );

  if( property == null ) {
    return undefined; 
  }

  return unescape( ReactDOMServer.renderToString( 
    <ModifiedPropertyValue 
      displayMode={PropertyDisplayMode.Text}
      documentName={documentName} 
      property={property}
    />
  ));

}


interface PropertyValueProps extends WithTranslation {

  property : DatabasePropertyIF<any> | string,

  documentName? : string,

  onPropertyChange? : ( property : DatabasePropertyIF<any> ) => void,

  displayMode : PropertyDisplayMode,

  singleProperty? : boolean,

  hideColor? : boolean,

  disabled? : boolean,

  required? : boolean
}

interface PropertyValueState { 

  disabled?: boolean,

  required? : boolean
}

class PropertyValue extends React.PureComponent<PropertyValueProps, PropertyValueState> {

  constructor(props: PropertyValueProps) {

    const disabled = typeof props.property === 'string' ? undefined :
      props.displayMode === PropertyDisplayMode.Filter ? undefined :
        !props.property.editable ? true :
          props.property.parentDocument().isNew() && !props.property.userAccess().allowCreate ? true :
            !props.property.parentDocument().isNew() && !props.property.userAccess().allowUpdate ? true :
              props.disabled;
                

    const required = typeof props.property === 'string' ? undefined :
      props.displayMode === PropertyDisplayMode.Filter ? undefined :
        !props.property.editable ? undefined :
          props.property.parentDocument().isNew() && !props.property.userAccess().allowCreate ? undefined :
            !props.property.parentDocument().isNew() && !props.property.userAccess().allowUpdate ? undefined :
              !!props.required ? true :
                props.property.required;


    super(props);

    this.state = {

      property: this.props.property,

      disabled: disabled,

      required: required

    } as PropertyValueState;

    this.renderText = this.renderText.bind(this);
    this.renderProperty = this.renderProperty.bind(this);
    this.onPropertyChange = this.onPropertyChange.bind(this);

    //log.traceInOut( "constructor()" );
  }

  protected onPropertyChange( property : DatabasePropertyIF<any> ) : void {

    //log.traceIn( "onPropertyChange()", property );

    if( this.props.onPropertyChange != null ) {

      this.props.onPropertyChange( property );

    }
    //log.traceOut( "onPropertyChange()" );
  }

  protected renderText() : JSX.Element | null | string {

    //log.traceInOut( "renderProperty()", this.props.property );

    if( this.props.property == null ) {
      //log.traceOut( "translatedPropertyValue()", "no value" );
      return null;
    }
  
    if( !(typeof this.props.property === 'string') ) {
      return this.renderProperty();
    }

    const propertyValue = this.props.property;

    let result; 

    let translationKey;

    if( !isNaN( +propertyValue ) ) {
      //log.traceOut( "translatedPropertyValue()", "number" ); 
      return propertyValue;
    }
  
    if( HealthguardDocumentNames.includes( propertyValue.toString() ) ) {
  
      return translatedDefinition( HealthguardDocumentDefinition, propertyValue );
    }
  
    translationKey = this.props.documentName + ":" + TranslationKey.Keys + ":" + propertyValue.toString();
    if (this.props.i18n.exists(translationKey)) {
  
      result = this.props.t(translationKey);
      //log.traceOut( "translatedPropertyValue()", translationKey, result );
      return result;
    }
  
    translationKey = this.props.documentName + ":" + TranslationKey.Values + ":" + propertyValue.toString();
    if (this.props.i18n.exists(translationKey)) {
  
      result = this.props.t(translationKey);
      //log.traceOut( "translatedPropertyValue()", translationKey, result );
      return result;
    }
  
    const healthguardDatabaseFactory =
      Factory.get().databaseService.databaseFactory as HealthguardDatabaseFactoryIF;
  
  
    if (healthguardDatabaseFactory.registrationDocumentNames().includes(this.props.documentName!)) {
  
      translationKey = TranslationKey.Registration + ":" + TranslationKey.Values + ":" + propertyValue.toString();
  
      if (this.props.i18n.exists(translationKey)) {
  
        result = this.props.t(translationKey);
        //log.traceOut( "translatedPropertyValue()", translationKey, result ); 
        return result;
      }
    }
  
    if (healthguardDatabaseFactory.contactableDocumentNames().includes(this.props.documentName!)) {
  
      translationKey = TranslationKey.Contactable + ":" + TranslationKey.Values + ":" + propertyValue.toString();
  
      if (this.props.i18n.exists(translationKey)) {
  
        result = this.props.t(translationKey);
        //log.traceOut( "translatedPropertyValue()", translationKey, result ); 
        return result;
      }
    }
  
    translationKey = propertyValue;
  
    if( this.props.i18n.exists( translationKey ) ) {
  
      result = this.props.t( translationKey );
      //log.traceOut( "translatedPropertyValue()", translationKey, result );
      return result;
    }
  
    result = propertyValue;
  
    //log.traceOut( "translatedPropertyValue()", "Not found", result );
    return result;
  }

  
  protected renderProperty() : JSX.Element | null | string {

    //log.traceInOut( "renderProperty()", this.props.property );

    if( typeof this.props.property === 'string' ) {
      return this.renderText();
    }
  
    switch( this.props.property!.type ) {

      case PropertyTypes.Tenant:
        return( <OwnerPropertyValue 
          property={ this.props.property as TenantPropertyIF<any> }
          onPropertyChange={this.onPropertyChange}
          displayMode={this.props.displayMode}  
          singleProperty={!!this.props.singleProperty}  
          hideColor={this.props.hideColor}
          disabled={this.state.disabled}
          required={this.state.required}
          /> );

      case PropertyTypes.Owner:
        return( <OwnerPropertyValue 
          property={ this.props.property as OwnerPropertyIF<any> } 
          onPropertyChange={this.onPropertyChange}
          displayMode={this.props.displayMode}  
          singleProperty={!!this.props.singleProperty}    
          hideColor={this.props.hideColor}
          disabled={this.state.disabled}
          required={this.state.required}
        /> );
    
      case PropertyTypes.Collection:
        return( <CollectionPropertyValue 
          property={ this.props.property as CollectionPropertyIF<any> } 
          onPropertyChange={this.onPropertyChange}
          displayMode={this.props.displayMode}  
          singleProperty={!!this.props.singleProperty}    
          hideColor={this.props.hideColor}
          disabled={this.state.disabled}
          required={this.state.required}
          /> );
    
      case PropertyTypes.Reference:
        return( <DocumentPropertyValue 
          property={ this.props.property as ReferencePropertyIF<any> } 
          onPropertyChange={this.onPropertyChange}
          displayMode={this.props.displayMode}
          singleProperty={!!this.props.singleProperty}    
          hideColor={this.props.hideColor}
          disabled={this.state.disabled}
          required={this.state.required}
           />
          );

      case PropertyTypes.References:
      case PropertyTypes.SymbolicOwners:
      case PropertyTypes.SymbolicCollection:
        return( <DocumentsPropertyValue 
          property={ this.props.property as DocumentsPropertyIF<any> } 
          onPropertyChange={this.onPropertyChange} 
          displayMode={this.props.displayMode} 
          singleProperty={!!this.props.singleProperty}    
          hideColor={this.props.hideColor}
          disabled={this.state.disabled}
          required={this.state.required}
          /> );

      case PropertyTypes.Subdocument:
        return( <SubdocumentPropertyValue 
          property={ this.props.property as SubdocumentPropertyIF<any> } 
          onPropertyChange={this.onPropertyChange} 
          displayMode={this.props.displayMode} 
          singleProperty={!!this.props.singleProperty}    
          hideColor={this.props.hideColor}
          disabled={this.state.disabled}
          required={this.state.required}
          /> );

      case PropertyTypes.Data:
        return( <DataPropertyValue 
          property={ this.props.property as DataPropertyIF<any> } 
          onPropertyChange={this.onPropertyChange}
          displayMode={this.props.displayMode}          
          singleProperty={!!this.props.singleProperty}    
          hideColor={this.props.hideColor}
          disabled={this.state.disabled}
          required={this.state.required}
          /> );

      case PropertyTypes.Map:
        return( <MapPropertyValue 
          property={ this.props.property as MapPropertyIF<any> } 
          onPropertyChange={this.onPropertyChange}
          displayMode={this.props.displayMode}  
          singleProperty={!!this.props.singleProperty}    
          hideColor={this.props.hideColor}
          disabled={this.state.disabled}
          required={this.state.required}
          /> );

      case PropertyTypes.Texts:
        return( <TextsPropertyValue 
          property={ this.props.property as TextsPropertyIF } 
          onPropertyChange={this.onPropertyChange}
          displayMode={this.props.displayMode}  
          singleProperty={!!this.props.singleProperty}    
          hideColor={this.props.hideColor}
          disabled={this.state.disabled}
          required={this.state.required} 
          /> );

      case PropertyTypes.Date:
        return( <DatePropertyValue 
          property={ this.props.property as DatePropertyIF }  
          onPropertyChange={this.onPropertyChange}
          displayMode={this.props.displayMode}  
          singleProperty={!!this.props.singleProperty}    
          hideColor={this.props.hideColor}
          disabled={this.state.disabled}
          required={this.state.required}
          /> );

      case PropertyTypes.Definitions:
        return( <TextsPropertyValue 
          property={ this.props.property as DefinitionsPropertyIF<string> } 
          onPropertyChange={this.onPropertyChange}
          displayMode={this.props.displayMode}  
          singleProperty={!!this.props.singleProperty}    
          hideColor={this.props.hideColor}
          disabled={this.state.disabled}
          required={this.state.required}
          /> );

      case PropertyTypes.Definition:
        return( <TextPropertyValue 
          property={ this.props.property as DefinitionPropertyIF<string> } 
          onPropertyChange={this.onPropertyChange}
          displayMode={this.props.displayMode}  
          singleProperty={!!this.props.singleProperty}    
          hideColor={this.props.hideColor}
          disabled={this.state.disabled}
          required={this.state.required}
          /> );

      case PropertyTypes.Text:
        return( <TextPropertyValue 
          property={ this.props.property as TextPropertyIF }  
          onPropertyChange={this.onPropertyChange}
          displayMode={this.props.displayMode} 
          singleProperty={!!this.props.singleProperty}    
          hideColor={this.props.hideColor}
          disabled={this.state.disabled}
          required={this.state.required}
          /> );

      case PropertyTypes.Number:
        return( <NumberPropertyValue 
          property={ this.props.property as NumberPropertyIF }  
          onPropertyChange={this.onPropertyChange}
          displayMode={this.props.displayMode} 
          singleProperty={!!this.props.singleProperty}    
          hideColor={this.props.hideColor}
          disabled={this.state.disabled}
          required={this.state.required}
          /> );
    
      case PropertyTypes.Boolean:
        return( <BooleanPropertyValue 
          property={ this.props.property as BooleanPropertyIF }  
          onPropertyChange={this.onPropertyChange}
          displayMode={this.props.displayMode} 
          singleProperty={!!this.props.singleProperty}    
          hideColor={this.props.hideColor}
          disabled={this.state.disabled}
          required={this.state.required}
          /> );

      case PropertyTypes.PhoneNumber:
        return( <PhoneNumberPropertyValue 
          property={ this.props.property as PhoneNumberPropertyIF }  
          onPropertyChange={this.onPropertyChange}
          displayMode={this.props.displayMode} 
          singleProperty={!!this.props.singleProperty}    
          hideColor={this.props.hideColor}
          disabled={this.state.disabled}
          required={this.state.required}
          /> );


      case PropertyTypes.Image:
        return (<ImagePropertyValue
          property={this.props.property as ImagePropertyIF}
          onPropertyChange={this.onPropertyChange}
          displayMode={this.props.displayMode}
          singleProperty={!!this.props.singleProperty}  
          hideColor={this.props.hideColor}
          disabled={this.state.disabled}
          required={this.state.required}
        />);

      case PropertyTypes.Attachments:
        return (<AttachmentsPropertyValue
          property={this.props.property as AttachmentsPropertyIF}
          onPropertyChange={this.onPropertyChange}
          displayMode={this.props.displayMode}
          singleProperty={!!this.props.singleProperty}  
          hideColor={this.props.hideColor}
          disabled={this.state.disabled}
          required={this.state.required}
        />);

      case PropertyTypes.Links:
        return (<LinksPropertyValue
          property={this.props.property as LinksPropertyIF}
          onPropertyChange={this.onPropertyChange}
          displayMode={this.props.displayMode}
          singleProperty={!!this.props.singleProperty}  
          hideColor={this.props.hideColor}
          disabled={this.state.disabled}
          required={this.state.required}
        />);

      case PropertyTypes.Geolocation:
        return (<GeolocationPropertyValue
          property={this.props.property as GeolocationPropertyIF}
          onPropertyChange={this.onPropertyChange}
          displayMode={this.props.displayMode}
          singleProperty={!!this.props.singleProperty}  
          hideColor={this.props.hideColor}
          disabled={this.state.disabled}
          required={this.state.required}
        />);

      case PropertyTypes.Country:
        return (<CountryPropertyValue
          property={this.props.property as CountryPropertyIF}
          onPropertyChange={this.onPropertyChange}
          displayMode={this.props.displayMode}
          singleProperty={!!this.props.singleProperty}  
          hideColor={this.props.hideColor}
          disabled={this.state.disabled}
          required={this.state.required}
        />);

      case PropertyTypes.Empty:
          return( null );
      }

    //log.traceOut( "propertyValue()", "Not found", result );
    return ( null ); ;
  }

  async componentWillUnmount() {
    //log.traceInOut( "componentWillUnmount()" );

  }

  render() {

    //log.traceInOut( "render()" );

    if( typeof this.props.property === 'string' ) {
      return( this.renderText() );
    }

    if( this.props.property.hidden ) {
      return null;
    }

    return( this.renderProperty() );
  }
}

const ModifiedPropertyValue = withTranslation()(PropertyValue);

export default ModifiedPropertyValue;


