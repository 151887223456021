import React from 'react';

import DatabaseTable, { DatabaseTableProps, VariantProperty } from 'ui/components/databaseTable';
import DatabaseList, { DatabaseListProps } from 'ui/components/databaseList';
import DatabaseCard, { DatabaseCardProps }  from 'ui/components/databaseCard';
import DatabaseChart, { DatabaseChartProps } from 'ui/components/databaseChart';
import DatabaseGraph, { DatabaseGraphProps } from 'ui/components/databaseGraph';
import DocumentView, { DocumentProps }  from 'ui/components/documentView';
import DatabaseView from 'ui/components/databaseView';

import { IncidentRegistrationIF } from 'healthguard/api/documents/incidentRegistrationIF';
import { HazardDocument } from '../../healthguard/api/healthguardDocuments';
import { UnitDocument, UserDocument } from '../../services/database/api/documents';
import { PropertiesSelector } from '../../services/database/api/core/propertiesSelector';
import { DatabaseQuery } from '../../services/database/api/core/databaseQuery';
import { MeasuresCollection } from '../../healthguard/api/healthguardCollections';
import { QuarantineRegistrationsCollection, RegistrationsCollection, SickLeaveRegistrationsCollection, TestRegistrationsCollection } from '../../healthguard/api/registrationCollections';




export const defaultHighlightedIncidentRegistrationsProperty = VariantProperty;

export function IncidentRegistrationsTable(props: DatabaseTableProps ) {

  const defaultIncidentTableColumns = ( referenceDocument : IncidentRegistrationIF ) => [
    referenceDocument.startDate.key(),
    referenceDocument.endDate.key(),
    referenceDocument.company.key(),
    referenceDocument.unit.key(),
    referenceDocument.user.key(),
    referenceDocument.hazard.key(),
    referenceDocument.illnessStage.key()
  ];

  return (
    <DatabaseTable {...props} 
    featuredProperties={props.featuredProperties != null ? 
      props.featuredProperties : 
      defaultIncidentTableColumns( props.databaseObserver.referenceDocument() as IncidentRegistrationIF ) }/>
  );
}

export function IncidentRegistrationsList(props: DatabaseListProps ) {

  return (
    <DatabaseList {...props} />
  );
}


export function IncidentRegistrationsCard( props: DatabaseCardProps ) {
  return (
    <DatabaseCard 
      {...props} 
      highlightedPropertyKey={props.highlightedPropertyKey != null ? 
        props.highlightedPropertyKey : 
        defaultHighlightedIncidentRegistrationsProperty}
    />
  );
}

export function IncidentRegistrationsChart( props: DatabaseChartProps ) {
  return (
    <DatabaseChart 
      {...props} 
    />
  );
}

export function IncidentRegistrationsGraph( props: DatabaseGraphProps ) {
  return (
    <DatabaseGraph 
      {...props} 
      previousPropertyKey={"internalSource"}
      nextPropertyKey={"internalReceivers"}
      directed={true}
    />
  );
}

export function IncidentRegistrationsView(props: {
  databaseQuery : DatabaseQuery<IncidentRegistrationIF>,
  title?: string,
  featuredProperties?: string[],
  highlightedPropertyKey?: string
}) {
  return (
    <DatabaseView
      databaseQuery={props.databaseQuery}
      title={props.title}
      featuredProperties={props.featuredProperties}
      highlightedPropertyKey={props.highlightedPropertyKey != null ? props.highlightedPropertyKey : defaultHighlightedIncidentRegistrationsProperty}
    />);
}

export function IncidentRegistrationView( props : DocumentProps ) {


  const IncidentBasicsFilter = {

    includePropertyKeys: [
      UnitDocument,
      UserDocument,
      MeasuresCollection,
      HazardDocument,
      "variant",
      "startDate",
      "endDate",
      "illnessStage"
    ]

  } as PropertiesSelector;

  
  const IncidentProliferationFilter = {
  
    includePropertyKeys : [
      "locations",
      "closeContacts",
      "externalSources",
      "internalSources",
      "externalReceivers",
      "internalReceivers"] 
  
  } as PropertiesSelector;

  const IncidentRegistrationsFilter = {

    includePropertyKeys: [
      TestRegistrationsCollection,
      QuarantineRegistrationsCollection,
      SickLeaveRegistrationsCollection
  ]
  
  } as PropertiesSelector;
  
  
  /*
  const IncidentAssociationsFilter = {

    includePropertyKeys: [
      "measures",
      "locations",
      "categories",
      "projects",
      "gatherings"]
  
  } as PropertiesSelector;
*/

  const defaultIncidentRegistrationSteps = () : Map<string,PropertiesSelector> => {
  
    let defaultSteps = new Map<string,PropertiesSelector>();
  
    defaultSteps.set( "basics", IncidentBasicsFilter );
    defaultSteps.set( RegistrationsCollection, IncidentRegistrationsFilter ); 
    defaultSteps.set( "proliferation", IncidentProliferationFilter );
    //defaultSteps.set( "associations", IncidentAssociationsFilter );
  
    return defaultSteps;
  }
  
  return ( <DocumentView 
    {...props}
    inputTabs={props.inputTabs != null ? props.inputTabs : defaultIncidentRegistrationSteps()}
  /> );
}



