
import { CollectionDatabase } from "../../../services/database/impl/core/collectionDatabase";
import { DatabaseDocument } from "../../../services/database/framework/databaseDocument";
import { log } from "../../../services/database/framework/databaseService";
import { DefinitionsProperty } from "../../../services/database/impl/properties/definitionsProperty";
import { TextsProperty } from "../../../services/database/impl/properties/textsProperty";
import { Symptom, Symptoms } from "../../api/definitions/symptom";
import { HazardIF } from "../../api/documents/hazardIF";
import { HazardTypeDefinition, SymptomDefinition, TestTypeDefinition } from "../../api/healthguardDefinitions";
import { HealthguardCompanyDocument, HealthguardUnitDocument, HazardDocument } from "../../api/healthguardDocuments";
import { TestType, TestTypes } from "../../api/definitions/testType";
import { HazardType, HazardTypes } from "../../api/definitions/hazardType";
import { DefinitionProperty } from "../../../services/database/impl/properties/definitionProperty";
import { HealthguardCompany } from "./healthguardCompany";
import { TenantProperty } from "../../../services/database/impl/properties/tenantProperty";
import { OwnerProperty } from "../../../services/database/impl/properties/ownerProperty";
import { HealthguardUnit } from "./healthguardUnit";
import { CompaniesCollection, UnitsCollection } from "../../../services/database/api/collections";
 
export class Hazard extends DatabaseDocument implements HazardIF { 

    constructor( hazardsCollection : CollectionDatabase<Hazard>, documentPath? : string  ) {
 
        super( HazardDocument, hazardsCollection, documentPath );

        try {
            this.company = new TenantProperty<HealthguardCompany>( this, CompaniesCollection, HealthguardCompanyDocument );

            this.unit = new OwnerProperty<HealthguardUnit>( this, UnitsCollection, HealthguardUnitDocument );
            
            this.hazardType = new DefinitionProperty<HazardType>( this, HazardTypeDefinition, HazardTypes );

            this.seriousSymptoms = new DefinitionsProperty<Symptom>( this, SymptomDefinition, Symptoms );

            this.commonSymptoms = new DefinitionsProperty<Symptom>( this, SymptomDefinition, Symptoms );

            this.lessCommonSymptoms = new DefinitionsProperty<Symptom>( this, SymptomDefinition, Symptoms );

            this.variants = new TextsProperty( this ); 

            this.testTypes = new DefinitionsProperty<TestType>( this, TestTypeDefinition, TestTypes );

            this.vaccines = new TextsProperty( this );

            this.medications = new TextsProperty( this );

            this.treatments = new TextsProperty( this );

            //log.traceInOut( "constructor()", HazardsCollection );

        } catch( error ) {

            log.warn( "constructor()", "Error initializing hazard", error );
            
            throw new Error( (error as any).message );
        }
    }

    indexKey() : string {
        return HazardDocument;
    }

    referenceDateProperty() {
        return undefined;
    }

    readonly company:               TenantProperty<HealthguardCompany>;

    readonly unit:                   OwnerProperty<HealthguardUnit>;

    readonly hazardType:       DefinitionProperty<HazardType>;

    readonly seriousSymptoms:        DefinitionsProperty<Symptom>;

    readonly commonSymptoms:         DefinitionsProperty<Symptom>;

    readonly lessCommonSymptoms:     DefinitionsProperty<Symptom>;

    readonly variants:               TextsProperty;

    readonly testTypes:              DefinitionsProperty<TestType>;

    readonly vaccines:               TextsProperty;

    readonly medications:            TextsProperty;

    readonly treatments:             TextsProperty; 
 }
