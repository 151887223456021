import React from 'react';
import { log } from "ui/app/app";
import { AppContext, AppContextProps } from 'ui/app/appContext';
import { HomePaths } from '../../services/common/api/homePaths';

import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import CloseIconFilled from '@material-ui/icons/Close';


import { withTranslation, WithTranslation } from 'react-i18next';
import { Fab} from '@material-ui/core';
import { Box, Button, CircularProgress, Tabs, Tab, Grid} from '@mui/material';


import { newDocumentName } from 'ui/components/documentName';
import DatabaseForm from 'ui/components/documentForm';

import { DatabaseDocumentIF } from 'services/database/api/core/databaseDocumentIF';
import { ObservableIF } from 'services/common/api/observableIF';
import { Monitor } from 'services/common/api/monitor';
import { Observation } from 'services/common/api/observation';
import { PropertiesSelector } from 'services/database/api/core/propertiesSelector';

import { Factory } from 'services/common/api/factory';
import { confirmationDialog, errorDialog } from 'ui/components/simpleDialog';
import { PersistentKeyActiveTab } from './appFrame';
import { DocumentNameKey, NewDocumentId } from '../../services/database/api/core/databaseServiceIF';

import { translatedPropertyLabel } from './propertyLabel';
import { defaultDocumentTitle } from './documentTitle';
import { DatabasePropertyIF } from '../../services/database/api/core/databasePropertyIF';
import { OwnerPropertyIF } from '../../services/database/api/properties/ownerPropertyIF';

import { CollectionDatabaseIF } from '../../services/database/api/core/collectionDatabaseIF';
import { TranslationKey } from '../../services/common/api/translatorIF';
import { DisplayTypes } from '../app/display';
import { TenantPropertyIF } from '../../services/database/api/properties/tenantPropertyIF';
import { PropertyTypes } from '../../services/database/api/definitions/propertyType';
import TextPropertyValue from './properties/textPropertyValue';
import { PropertyDisplayMode } from './propertyValue';

const buttonProgressSize = 20;

const styles = (theme: Theme) => createStyles({
  root: {
    height: "100%",
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(0),
    paddingBottom: theme.spacing(2), 
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },  
  content: {
    height: "100%"
  },
  button: {
    marginRight: theme.spacing(1),
  },
  closeButton: {
    position: 'absolute',
    right : theme.spacing(3),
    marginTop: theme.spacing(-1),
    zIndex: 999
  },
  header: {
  },
  info: {
    paddingTop: theme.spacing(10),
  },
  title: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    color: theme.palette.text.secondary,      
    zIndex: 998
  },
  subtitle: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0)
  },
  tabs: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(-1) 
  },
  mobileTabs: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(2),
    marginLeft: theme.spacing(-1),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0)
  },
  formGridNormal: {
    flex: 1,
    display: 'flex', 
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(4), 
    paddingRight: theme.spacing(4)
  },
  formGridMobile: {
    flex: 1,
    display: 'flex', 
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(1), 
    paddingRight: theme.spacing(1)
  },
  actionButtonsNormal: {
    display: 'flex', 
    justifyContent:'flex-end', 
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    margin: theme.spacing(0)
  },
  actionButtonsMobile: {
    display: 'flex', 
    justifyContent:'flex-end', 
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    margin: theme.spacing(0)
  },
  navigationButtons: {
    display: 'flex', 
    justifyContent:'flex-start',
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    margin: theme.spacing(0)
  } 
});



export interface DocumentProps {

  databaseDocument : DatabaseDocumentIF, 

  view?: string,

  title? : string,

  inputTabs? : Map<string,PropertiesSelector>,

  onDocumentChange? : (databaseDocument : DatabaseDocumentIF, changedProperty? : DatabasePropertyIF<any> ) => Promise<void>,

  onComplete? : (databaseDocument? : DatabaseDocumentIF ) => Promise<void>,

  actions? : {

    title: string, 

    onAction: () => Promise<void>, 

    disabled? : boolean
  }[]
} 

interface DocumentViewProps extends DocumentProps, WithStyles<typeof styles>, WithTranslation {

} 

interface DocumentViewState { // Document View Props

  deleted? : boolean,

  databaseDocument : DatabaseDocumentIF, 

  updatedFormDocument? : DatabaseDocumentIF,

  updatedOwnerCollection? : CollectionDatabaseIF<DatabaseDocumentIF>,

  inputTabs? : Map<string,PropertiesSelector>,

  tabs? : Map<string,PropertiesSelector>,

  activeTab? : number,

  changed: boolean,

  complete: boolean,

  errors? : Map<string,Error>,

  allowDelete: boolean,

  saving? : boolean,

  finishing? : boolean,

  deleting? : boolean
}

class DocumentView extends React.Component<DocumentViewProps,DocumentViewState> {

  constructor( props: DocumentViewProps ) {
    
    super(props);

    log.traceIn( "constructor()" );

    this.onComplete = this.onComplete.bind(this);
    this.readDocument = this.readDocument.bind(this);
    this.updateTabs = this.updateTabs.bind(this);

    this.onNotifyDatabase = this.onNotifyDatabase.bind(this);
    this.onDocumentEditChange = this.onDocumentEditChange.bind(this);
    this.handleDocumentOwnerChange = this.handleDocumentOwnerChange.bind(this);
    this.activeTabPersistentKey = this.activeTabPersistentKey.bind(this);
    this.activeTab = this.activeTab.bind(this);
    this.setActiveTab = this.setActiveTab.bind(this);
    this.tabPropertiesFilter = this.tabPropertiesFilter.bind(this);

    this.handleNext = this.handleNext.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleError = this.handleError.bind(this);
    this.handleForceClose = this.handleForceClose.bind(this);

    this.hasErrors = this.hasErrors.bind(this);


    let databaseDocument = this.props.databaseDocument;

    this.state = { 

      databaseDocument: databaseDocument,

      deleted : false,

      changed: false,

      complete: false,

      activeTab: databaseDocument?.id.value() == null ? 0 : undefined,

      allowDelete: false

    } as DocumentViewState;


    //log.traceOut( "constructor()" );
  }

  async componentDidMount() {

    try {
      log.traceIn( "componentDidMount()" );

      const databaseDocument = this.state.databaseDocument;

      if( this.state.databaseDocument == null ) {
        throw new Error( "notFound" );
      }      
      
      if( databaseDocument.id.value() != null) {

        await databaseDocument.subscribe( { 
          observer: this, 
          onNotify: this.onNotifyDatabase } as Monitor ); 
      }
      else {
        this.readDocument( databaseDocument );
      }

      log.traceOut( "componentDidMount()");
    } catch( error ) {
      log.warn( "componentDidMount()", "Error mounting document view", error );

      this.handleError( new Error( "couldNotRead" ) );
      
      log.traceOut( "componentDidMount()", "error" );
    }
  }

  async componentDidUpdate() {

    try {
      log.traceIn( "componentDidUpdate()" );

      if( this.props.databaseDocument != null &&
         this.props.databaseDocument.databasePath() !== this.state.databaseDocument.databasePath() ) {

          const previousDatabaseDocument = this.state.databaseDocument;

          previousDatabaseDocument.unsubscribe( this );
  
          const databaseDocument = this.props.databaseDocument;
  
          if( databaseDocument.id.value() != null) {
  
            await databaseDocument.subscribe( { 
              observer: this, 
              onNotify: this.onNotifyDatabase } as Monitor ); 
          }
          else {
            this.readDocument( databaseDocument );
          }
      }
      else if( (this.props.inputTabs == null && this.state.inputTabs != null) ||
          (this.props.inputTabs != null && this.state.inputTabs == null) ||
          (this.props.inputTabs != null && this.state.inputTabs != null &&
            JSON.stringify( Array.from( this.props.inputTabs!.values() ) ) !== 
            JSON.stringify( Array.from( this.state.inputTabs!.values() ) ) ) ) { 
        
              const tabs = this.updateTabs( this.state.updatedFormDocument );

              let activeTab = this.activeTab();
        
              if( this.state.updatedFormDocument != null && activeTab >= tabs.size ) {
        
                log.debug( "componentDidUpdate()", "reseting active tab" );

                activeTab = 0;
        
                Factory.get().persistentState!.setProperty( this.activeTabPersistentKey(), activeTab );

                this.setState( {
                  activeTab : activeTab
                } );

              }
        
              this.setState( {
          
                  inputTabs : this.props.inputTabs, 
          
                  tabs : tabs.size > 0 ? tabs : undefined          
              });      
      }

      log.traceOut( "componentDidUpdate()" );

    } catch( error ) {
      log.warn( "componentDidUpdate()", "Error mounting document view", error );

      this.handleError( error as Error );
      
    }
  }



  async readDocument( databaseDocument : DatabaseDocumentIF ) {

    try {
      log.traceIn( "readDocument()");

      if( !databaseDocument.isNew() && !databaseDocument.isLoaded() ) { 
        await databaseDocument.read();
      }

      if( databaseDocument.startDate.value() == null ) {
        databaseDocument.startDate.setValue( new Date() );
        databaseDocument.startDate.clearChanges(); 
      }   

      const tabs = this.updateTabs( databaseDocument );

      let activeTab = this.activeTab();

      if( activeTab >= tabs.size ) {

        log.debug( "readDocument()", "reseting active step" );

        activeTab = 0;

        Factory.get().persistentState!.setProperty( this.activeTabPersistentKey(), activeTab );

        this.setState( {
          activeTab : activeTab
        } );
      }

      const allowDelete = databaseDocument!.id.value() != null && 
        databaseDocument!.userAccess().allowDelete; 

      this.setState( {
  
          inputTabs : this.props.inputTabs, 
  
          tabs : tabs.size > 0 ? tabs : undefined,

          databaseDocument : databaseDocument,

          updatedFormDocument: databaseDocument.duplicate(),

          updatedOwnerCollection: undefined,

          allowDelete: allowDelete
        
      });

      log.traceOut( "readDocument()" );

    } catch( error ) {
      log.warn( "readDocument()", "Error mounting document view", error );

      this.handleError( error as Error );
      
      log.traceOut( "readDocument()", "error" );
    }
  }

  updateTabs( databaseDocument? : DatabaseDocumentIF ) : Map<string,PropertiesSelector> {

    log.traceIn( "updateTabs()" );

    const tabs = new Map<string,PropertiesSelector>();

    try {

      if( databaseDocument == null ) {
        log.traceOut( "updateTabs()", "no document" );
        return tabs;
      }

      const inputTabs = this.props.inputTabs;

      if( inputTabs != null ) {

        inputTabs.forEach( (propertiesSelector, tab) => {

          const properties = databaseDocument!.properties( propertiesSelector );
  
          log.debug( "updateTabs()", "found properties:", tab, properties.keys() );
  
          if( properties.size > 0 ) {
            tabs.set( tab, propertiesSelector );
          }
        }) 
      }
    
      log.traceOut( "updateTabs()", {steps: tabs} );
      return tabs;

    } catch( error ) {
      log.warn( "updateTabs()", "Error mounting document view", error );

      this.handleError( error as Error );
      
      return tabs; 
    }
  }


  async componentWillUnmount() {
    log.traceIn( "componentWillUnmount()" );

      try {

        if( this.state.databaseDocument != null && this.state.databaseDocument.id.value() != null ) {
          this.state.databaseDocument.unsubscribe( this );
        }
      
      log.traceOut( "componentWillUnmount()" );

    } catch( error ) {
      log.warn( "componentWillUnmount()", "Error unmounting document view", error );
      
      log.traceOut( "componentWillUnmount()", "error" );
    }
  }

  async onNotifyDatabase( observable: ObservableIF, 
    observation: Observation, 
    objectId : string | null | undefined, 
    object : object  | null | undefined ) : Promise<void> {

    try {
      log.traceIn( "onNotifyDatabase()", Observation[observation], objectId, object );

      const documentPath : string = objectId!;
      const databaseDocument : DatabaseDocumentIF = object as DatabaseDocumentIF;

      if( databaseDocument != null && !documentPath.startsWith( databaseDocument.databasePath() ) ) {
        throw new Error( "Mismatching document paths in notification: " + documentPath );
      }

      switch( observation ) {

        case Observation.Create:
        {
          this.readDocument( databaseDocument );
          break;
        }
        case Observation.Update:
        {
          const existingDatabaseDocument = this.state.databaseDocument!;
  
          existingDatabaseDocument.copyFrom( databaseDocument );
          
          this.setState( { 
            databaseDocument : databaseDocument,

            updatedFormDocument: databaseDocument.duplicate(),

            updatedOwnerCollection: undefined,

            changed: false,

            complete: false
          } );
          
          break;
        }        
        case Observation.Delete:
        {
          if( this.state.updatedOwnerCollection != null ) {
            log.traceOut( "onNotifyDatabase()", "Document is being moved");
            return;
          }
          this.handleError( new Error( "documentDeleted") );
          
          break;
        }
        default: 
          throw new Error( "Unrecognized observation: " + observation );
      }

      if( this.props.onDocumentChange != null ) {

        await this.props.onDocumentChange( databaseDocument );
      }

      log.traceOut( "onNotifyDatabase()");

    } catch( error ) {
      log.warn( "Error receiving database notification", error );

      await errorDialog( error );
      
      log.traceOut( "onNotifyDatabase()", "error" );
    }
  }

  async onDocumentEditChange( databaseProperty : DatabasePropertyIF<any> ) : Promise<void> {

    try {
      log.traceIn( "onDocumentEditChange()" );

      const updatedFormDocument = databaseProperty.parent as DatabaseDocumentIF;

      let updatedOwnerProperty = 
        databaseProperty.type === PropertyTypes.Tenant || databaseProperty.type === PropertyTypes.Owner ?
        databaseProperty : undefined;


      if( updatedOwnerProperty != null ) {

        await this.handleDocumentOwnerChange( updatedFormDocument, updatedOwnerProperty as OwnerPropertyIF<DatabaseDocumentIF> | TenantPropertyIF<DatabaseDocumentIF> );

        log.traceOut( "onDocumentEditChange()", "owner change");
        return;
      }

      if (this.props.onDocumentChange != null) {

        await this.props.onDocumentChange(updatedFormDocument, databaseProperty);
      }

      const changed = updatedFormDocument.isChanged();

      const complete = updatedFormDocument.isComplete();

      if( changed ) {

        this.setState({

          changed: changed,
  
          complete: complete,
  
          updatedFormDocument: updatedFormDocument.duplicate()
        });
      }
    
      log.traceOut( "onDocumentEditChange()");
    } catch( error ) {
      log.warn( "Error receiving document form notification", error );

      await errorDialog( error );

      log.traceOut( "onDocumentEditChange()", "error" );
    }
  }

  async onComplete( databaseDocument? : DatabaseDocumentIF ) : Promise<void> {

    log.traceIn( "onComplete()" );

    try {

      if( this.props.onComplete != null ) {

        await this.props.onComplete( databaseDocument  );

      }
       
      log.traceOut( "onComplete()" );

    } catch (error) {
      log.warn("onComplete()", "Error loading document ", error);
    }
  }


  async handleDocumentOwnerChange( 
    updatedFormDocument : DatabaseDocumentIF, 
    updatedOwnerProperty : OwnerPropertyIF<DatabaseDocumentIF> | TenantPropertyIF<DatabaseDocumentIF> ) : Promise<void> {

    log.traceIn( "handleDocumentOwnerChange()" );

    try {
      log.traceIn("handleDocumentOwnerChange()");

      const appContext = this.context as AppContextProps;

      let updatedOwner;

      if (updatedOwnerProperty.cleared) {

        switch (appContext.currentHomePath) {

          case HomePaths.UserHomePath:

            updatedOwner = appContext.currentUnit != null ? 
              appContext.currentUnit : 
              appContext.currentCompany;
            break;

          case HomePaths.UnitHomePath:

            updatedOwner = appContext.currentUnit;
            break;

          case HomePaths.CompanyHomePath:

            updatedOwner = appContext.currentCompany;
            break;

          case HomePaths.AdminHomePath:

            updatedOwner = undefined;
            break;

          default:
            throw new Error("Unrecognized home path");
        }
        log.debug("handleExistingDocumentOwnerChange()", "cleared", { updatedOwner });
      }
      else {
        updatedOwner = updatedOwnerProperty.emptyDocument(); 

        log.debug("handleExistingDocumentOwnerChange()", { updatedOwner });
      }

      const updatedOwnerCollection =
        Factory.get().databaseService.databaseFactory.collectionDatabaseFromDocumentName(
          updatedFormDocument.documentName(), updatedOwner);

      const documentId = updatedFormDocument.isNew() ?
        NewDocumentId : updatedFormDocument.id.value()!;

      const updatedPath = appContext.currentHomePath! +
        updatedOwnerCollection.databasePath() + "/" + documentId + "?" +
        DocumentNameKey + "=" + updatedFormDocument.documentName();

      log.debug("handleDocumentOwnerChange()", { updatedPath });

      log.debug("handleDocumentOwnerChange()", { updatedOwnerProperty });

      const replacedFormDocument = Factory.get().databaseService.databaseFactory.newDocumentFromUrl(updatedPath)!;

      replacedFormDocument.copyFrom(updatedFormDocument);

      const replacedFormProperty = replacedFormDocument.property(updatedOwnerProperty.key())!;

      replacedFormProperty.copyValueFrom(updatedOwnerProperty);

      replacedFormProperty.copyStatesFrom(updatedOwnerProperty);

      const complete = replacedFormDocument.isComplete();

      this.setState({
        updatedFormDocument: replacedFormDocument,

        updatedOwnerCollection: updatedOwnerCollection,

        changed: true,

        complete: complete

      });

      if (this.props.onDocumentChange != null) {

        await this.props.onDocumentChange( replacedFormDocument, replacedFormProperty );
      }

      this.onComplete( replacedFormDocument );
         
      log.traceOut( "handleDocumentOwnerChange()");
    } catch( error ) {
      log.warn( "Error receiving document form notification", error );

      await errorDialog( error );

      log.traceOut( "handleDocumentOwnerChange()", "error" );
    }
  }


  async handleReset() {
    log.traceIn( "handleReset()" );
    try {

      const formDocument = this.state.databaseDocument!.duplicate();

      this.setState({

        updatedFormDocument: formDocument,

        updatedOwnerCollection: undefined,

        changed: false,

        complete: false,

        errors: undefined,

        saving: false,

        finishing: false,

        deleting: false

      });

      if (this.props.onDocumentChange != null) {

        await this.props.onDocumentChange(formDocument);
      }

      log.traceOut( "handleReset()" );

    } catch( error ) {
      log.warn( "Error resetting document", error );

      await errorDialog( error );

      log.traceOut( "handleReset()", "error saving document" );
    }
 
  }

  async handleSave( finish : boolean ) {
    log.traceIn( "handleSave()", this.state.updatedFormDocument );
    try {

      if( this.state.updatedFormDocument == null ) {
        log.traceOut( "handleSave()", "Nothing to save" );
        return;
      }

      if( this.hasErrors() ) {

        log.traceOut( "handleSave()" );
        return;
      }

      this.setState( finish ? { finishing : true } : { saving: true });

      let databaseDocument;

      if( this.state.updatedFormDocument.isNew() ) { 

        databaseDocument = this.state.updatedFormDocument;

        if( databaseDocument.title.value() == null || databaseDocument.title.value()!.length === 0 ) {
        
          const title = defaultDocumentTitle( databaseDocument);
          
          databaseDocument.title.setValue( title ); 
          
        }

        await databaseDocument.create();

        if( !finish ) {
          this.onComplete( databaseDocument );
        }
      }
      else if ( this.state.updatedOwnerCollection != null ) {

        const confirmation = await confirmationDialog( "moveDocument");

        if( !confirmation ) {
  
          this.handleReset(); 
  
          log.traceOut( "updateExistingDocumentOwner()", "Cancel changed owner" );
          return;
        }

        databaseDocument = this.state.databaseDocument!;

        databaseDocument.copyFrom( this.state.updatedFormDocument );
        
        databaseDocument = await this.state.databaseDocument!.move( this.state.updatedOwnerCollection );
    
        if( !finish ) {
          this.onComplete( databaseDocument );
        }

      }
      else {
        databaseDocument = this.state.databaseDocument!;

        await this.state.updatedFormDocument.update();

        databaseDocument.copyFrom( this.state.updatedFormDocument );
      }

      this.setState( { 

        allowDelete: databaseDocument!.userAccess().allowDelete,

        databaseDocument: databaseDocument,

        updatedFormDocument: databaseDocument.duplicate(), 

        updatedOwnerCollection: undefined,

        changed: false,

        complete: false,

        errors: undefined,

        saving: undefined,

        finishing: undefined
      } );

      if( finish ) {

        if( databaseDocument != null ) {
          databaseDocument.unsubscribe( this );
        }
  
        Factory.get().persistentState!.clearProperty( this.activeTabPersistentKey() );
  
        this.onComplete();
      }

      log.traceOut( "handleSave()" );
    } catch( error ) {
      log.warn( "Error saving document", error );

      this.setState( finish ? { finishing : false } : { saving: false });

      await errorDialog( error );
      
      log.traceOut( "handleSave()", "error saving document" );
    }
  }


  async handleDelete() {
    log.traceIn("handleDelete()");
    try {

      if (this.state.databaseDocument != null && this.state.databaseDocument.id.value() != null) {

        let confirmationPrompt = this.props.t(TranslationKey.Prompts + ":deleteDocument");

        if( this.state.databaseDocument.title.value() != null ) {
          confirmationPrompt += " " + this.state.databaseDocument.title.value() + "?"
        }

        const confirmation = await confirmationDialog(confirmationPrompt);

        if (!confirmation) {
          log.traceOut("handleDelete()", "Cancel 1");
          return;
        }

        const confirmation2 = await confirmationDialog("deleteDocument2");

        if (!confirmation2) {
          log.traceOut("handleDelete()", "Cancel 2");
          return;
        }

        this.state.databaseDocument.unsubscribe(this);

        this.setState({ deleting: true });

        this.state.databaseDocument.delete();

        Factory.get().persistentState!.clearProperty(this.activeTabPersistentKey());

        this.setState({
          deleted: true,

          deleting: undefined,

          updatedFormDocument: undefined,

          updatedOwnerCollection: undefined,

          changed: false,

          complete: false 

        });

        this.onComplete();
      }

      log.traceOut("handleDelete()");
    } catch (error) {
      log.warn("Error deleting document", error);

      await errorDialog(error);

      log.traceOut("handleDelete()", "error saving databaseDocument");
    }
  }


  async handleClose() {
    log.traceIn( "handleClose()" );
    try {

      if( this.state.updatedFormDocument != null && this.state.updatedFormDocument.isChanged() ) { 

        log.debug( "handleClose()", "Unsaved changes" );

        const confirmation = await confirmationDialog( "unsavedChanges" );

        if( !confirmation ) {
          log.traceOut( "handleClose()", "Cancel close with unsaved changes" );
          return;
        }
      }

      Factory.get().persistentState!.clearProperty( this.activeTabPersistentKey() );

      this.onComplete();

      log.traceOut( "handleClose()", "Closing with unsaved changes" );

    } catch( error ) {
      log.warn( "Error closing document", error );
      
      log.traceOut( "handleClose()", "error closing document" );
    }
  }

  async handleForceClose() {
    log.traceIn( "handleClose()" );
    try {

      this.setState( { 
        updatedFormDocument : undefined,

        updatedOwnerCollection: undefined

      } );

      Factory.get().persistentState!.clearProperty( this.activeTabPersistentKey() );

      this.onComplete( this.state.databaseDocument );

      log.traceOut( "handleForceClose()" );
    } catch( error ) {
      log.warn( "Error force closing document", error );
      
      log.traceOut( "handleForceClose()", "error force closing document" );
    }
  }


  hasErrors() : boolean {

    log.traceIn( "hasErrors()");

    try {

      const validatedFormDocument = this.state.updatedFormDocument != null ?
        this.state.updatedFormDocument.duplicate() : this.state.databaseDocument!.duplicate();
      
      const activeTab = this.activeTab();

      const errors = validatedFormDocument.validate( this.tabPropertiesFilter( activeTab ), true ); 

      if( errors != null && errors.size > 0 ) {

        this.setState( { 

          errors: errors,

          updatedFormDocument: validatedFormDocument
        } );

        log.traceOut( "hasErrors()", "errors on edited document", {errors} );
        return true;
      }

      log.traceOut( "hasErrors()", "OK" );
      return false;

    } catch( error ) {
      log.warn( "Error validating document", error );
      
      throw new Error( "Error validating document")
    }
  }

  async handleError( error : Error ) {
    log.traceIn( "handleError()", {error} );

    try {

      await errorDialog( error );

      this.onComplete( this.state.databaseDocument );

      log.traceOut( "handleError()" );
    } catch( error ) {
      log.warn( "Error viewing document", error );
      
      log.traceOut( "handleError()", "error viewing document" );
    }
  }

  private activeTabPersistentKey = (): string => {

    const appContext = this.context as AppContextProps;

    return appContext.currentHomePath + "." + this.state.databaseDocument!.documentName() + "." + PersistentKeyActiveTab; 
  }


  private activeTab = (): number => {

    //log.traceIn( "activeTab()" );

    if (this.state.activeTab != null) {
      //log.traceOut( "activeTab()", "From state", this.state.activeStep );
      return this.state.activeTab;
    }

    const persistentActiveTab = Factory.get().persistentState!.property( this.activeTabPersistentKey()) as number;

    if (persistentActiveTab != null) {

      //log.traceOut( "activeTab()", "From persistent app state", persistentActiveStep );
      return persistentActiveTab;
    }

    //log.traceOut( "activeTab()", "Not set" );
    return 0;
  };

 async setActiveTab( tab : number ) {

    log.traceIn( "setActiveTab()", tab );

    const activeTab = this.activeTab();

    if( tab > activeTab && this.hasErrors() ) {

      log.traceOut( "setActiveTab()" );
      return;
    }

    Factory.get().persistentState!.setProperty( this.activeTabPersistentKey(), tab );

    this.setState( { 

      activeTab : tab
    } )
  }


  handleNext() {
    log.traceInOut( "handleNext()" );

    const activeTab = this.activeTab();

    if( this.state.tabs != null && activeTab! < this.state.tabs.size - 1 ) {

      this.setActiveTab( activeTab + 1 );
    }
  }

  handleBack() {
    log.traceInOut( "handleBack()" );

    const activeTab = this.activeTab();

    if( this.state.tabs != null && activeTab! > 0 ) {
      this.setActiveTab( activeTab - 1 );
    }     
  }


  tabPropertiesFilter( tab : number ) : PropertiesSelector | undefined {

    if( this.state.tabs == null ) {
      return undefined;
    }

    const currentTabTitle = Array.from( this.state.tabs.keys() )[tab];

    return this.state.tabs.get( currentTabTitle! )!;
  }


  render() {
    //log.traceInOut( "render()",  this.state.updatedFormDocument  );

    const { classes } = this.props;

    const formDocument = this.state.updatedFormDocument;

    if( formDocument == null || this.state.tabs == null ) {
      return( <></> );
    }

    const activeTab = this.activeTab();

    const propertiesSelector = this.tabPropertiesFilter( activeTab );

    const properties = formDocument.properties( propertiesSelector )

    if( properties.size === 0  ) {
      return( <></> );
    }

    const canSave = this.state.changed && this.state.complete && !this.state.saving; 
    
    const title = (
      <Box className={classes.title}>
        <TextPropertyValue
          property={formDocument.title}
          onPropertyChange={this.onDocumentEditChange}
          displayMode={PropertyDisplayMode.Title}
          singleProperty={true}
          hideColor={true}
          placeholder={newDocumentName(formDocument.documentName())}
        />
      </Box>
    ); 

    const tabs = (
      <AppContext.Consumer>
        {appContext =>
          this.state.tabs!.size > 1 &&
          <Grid container >
            <Grid item xs={12} sm={12} md={12} >
              <Tabs
                variant={appContext.currentDisplay?.displayType === DisplayTypes.Mobile ? "scrollable" : "scrollable"}
                textColor={this.state.errors != null ? "secondary" : "primary"}
                indicatorColor={this.state.errors != null ? "secondary" : "primary"}
                scrollButtons
                allowScrollButtonsMobile
                value={activeTab}
                onChange={async (event, value) => await this.setActiveTab(value)}
                className={appContext.currentDisplay?.displayType === DisplayTypes.Mobile ? classes.mobileTabs : classes.tabs}
              >
                {Array.from(this.state.tabs!.keys()).map((tabKey, tab) => (
                  <Tab
                    key={tabKey}
                    value={tab}
                    label={translatedPropertyLabel(formDocument.documentName(), tabKey)}
                  />
                ))}
              </Tabs>
            </Grid>
          </Grid>
        }
      </AppContext.Consumer>
    )

    const form = (
      <AppContext.Consumer>
        {appContext => (
          <Box
            overflow="auto"
            flex={1}
            flexDirection="column"
            display="flex">
            <Grid container
              className={appContext.currentDisplay?.displayType === DisplayTypes.Mobile ? classes.formGridMobile : classes.formGridNormal}>
              <Grid style={{ display: "flex", flex: 1 }} item xs={12} sm={12} md={12} lg={12}>
                <Box overflow="auto" flex={1}>
                  <DatabaseForm
                    databaseObject={formDocument}
                    onDatabaseObjectEditChange={this.onDocumentEditChange}
                    propertiesSelector={propertiesSelector}
                  />
                </Box> 
              </Grid>
            </Grid>
          </Box>
        )}
      </AppContext.Consumer>
    )

    /*
    const info = (
      <AppContext.Consumer>
        {appContext => (
          <Box
            className={classes.info} 
            overflow="auto"
            flex={1}
            flexDirection="column"
            display="flex">
            <Grid container
              className={appContext.currentDisplay?.displayType === DisplayTypes.Mobile ? classes.formGridMobile : classes.formGridNormal}>
              <Grid style={{ display: "flex", flex: 1 }} item xs={12} sm={12} md={12} lg={12}>
                <Box overflow="auto" flex={1}>
                  <DocumentInfo
                    databaseDocument={formDocument}
                    onDocumentEditChange={this.onDocumentEditChange}
                    propertiesSelector={propertiesSelector}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}
      </AppContext.Consumer>
    )
    */ 

    const actions = (
      <AppContext.Consumer>
        {appContext => (
          <Grid container justifyContent="space-between" style={{ flexWrap: "nowrap" }}>
            <Grid item container xs sm md spacing={1}
              className={appContext.currentDisplay?.displayType === DisplayTypes.Mobile ? classes.actionButtonsMobile : classes.actionButtonsNormal}
            >
              {canSave ? (
                <Grid item>
                  <Button
                    variant="contained"
                    key={"finish"}
                    disabled={!!this.state.finishing}
                    color="primary"
                    disableElevation
                    onClick={() => this.handleSave(true)}
                    className={classes.button}>
                    {!!this.state.finishing ?
                      <CircularProgress color="primary" size={buttonProgressSize} /> :
                      this.props.t("finish")}
                  </Button>
                </Grid>
              ) : (
                null
              )}
              <Grid item>
                <Button
                  disabled={!canSave}
                  key={"save"}
                  variant="contained"
                  disableElevation
                  color="primary"
                  onClick={() => this.handleSave(false)}
                  className={classes.button}
                >
                  {!!this.state.saving ? <CircularProgress color="primary" size={buttonProgressSize} /> :
                    formDocument.isNew() ? this.props.t("create") :
                      this.props.t("save")}
                </Button>
              </Grid>
              <Grid item>
                <Button disabled={!this.state.changed} key={"reset"} variant="contained" color="primary" onClick={() => this.handleReset()} className={classes.button} >
                  {this.props.t("reset")}
                </Button>
              </Grid>
              {this.state.allowDelete ? (
                <Grid item>
                  <Button variant="contained" disabled={!!this.state.deleting} key={"delete"} color="primary" onClick={() => this.handleDelete()} className={classes.button} >
                    {!!this.state.deleting ?
                      <CircularProgress color="primary" size={buttonProgressSize} /> :
                      this.props.t("delete")}
                  </Button>
                </Grid>
              ) : (
                null
              )}
              {this.props.actions == null ? null : this.props.actions.map((action) =>
                <Grid item>
                  <Button
                    variant="contained"
                    key={action.title}
                    color="primary" onClick={() => action.onAction()}
                    className={classes.button}
                    disabled={!!action.disabled}>
                    {action.title}
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>

        )}
      </AppContext.Consumer>
    )

    return (
      <>
        <CssBaseline />
        <AppContext.Consumer> 
          {appContext => (
            <Grid container className={classes.root}>
              <Grid item container direction="column" justifyContent="flex-start" alignItems="stretch" xs={12} sm={12} md={12} lg={9}> 
                <Box className={classes.content}
                  overflow="auto"
                  flexDirection="column"
                  display="flex"
                  >
                  {title}
                  {tabs}
                  {form}
                  {actions}
                  <Fab aria-label="close" size="small" className={classes.closeButton} onClick={() => this.handleClose()}>
                    <CloseIconFilled />
                  </Fab>
                </Box>
              </Grid>
              {/*
              <Grid item container direction="column" xs={12} sm={12} md={12} lg={3}>
                {info}
              </Grid>
          */} 
            </Grid>
          )}
        </AppContext.Consumer>
      </>
    ); 
  }
}
 
DocumentView.contextType = AppContext;

const ModifiedDocumentView = withTranslation()(withStyles(styles)(DocumentView));

export default ModifiedDocumentView; 

