import * as React from 'react'

import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import { withTranslation, WithTranslation } from 'react-i18next';

import MuiPhoneNumber from "material-ui-phone-number";

import { PhoneNumberPropertyIF } from 'services/database/api/properties/phoneNumberPropertyIF';
import PropertyLabel from '../propertyLabel';
import { PropertyDisplayMode, propertyInputVariant } from '../propertyValue';
import { country } from '../../app/localization';
import { log } from '../../app/app';
import { PhoneNumber } from '../../../services/database/api/core/phoneNumber';
import { CountryPropertyIF } from '../../../services/database/api/properties/countryPropertyIF';

const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
  },
});

const unsupportedCountries = ["AQ","BV","CX","CC","TF","HM","PN","GS","SJ","UM","EH","AX","GG","IM","JE","XK"]

export interface PhoneNumberPropertyValueProps extends WithStyles<typeof styles>, WithTranslation { 

  property : PhoneNumberPropertyIF, 

  onPropertyChange? : ( property : PhoneNumberPropertyIF ) => void, 

  displayMode : PropertyDisplayMode,

  singleProperty: boolean,

  hideColor?: boolean,

  disabled?: boolean,

  required?: boolean

 }

interface PhoneNumberPropertyValueState { 

  open : boolean,

  options? : string[],

  error? : Error
}


class PhoneNumberPropertyValue extends React.Component<PhoneNumberPropertyValueProps,PhoneNumberPropertyValueState> {

  constructor( props: PhoneNumberPropertyValueProps ) {
    
    super(props);

    this.state = { 
      open : false,
     } as PhoneNumberPropertyValueState;

    //log.traceInOut( "constructor()" );
  }

  componentDidUpdate() {

    if( this.props.property.error !== this.state.error ) {

      this.setState( { error : this.props.property.error });
    }
  }

  render() {
    //log.traceInOut("render()");

    if( !this.props.property.userAccess().allowRead ) {
      return ( null );
    }

    if( this.props.displayMode === PropertyDisplayMode.Cell ) {
      return ( 
        <>{ 
          this.props.property.value() == null ? <>&nbsp;</> : this.props.property.value()}
        </>  
      );  
    }

    const handleEditChange = ( value : string ) => {

      log.traceInOut("handleEditChange()", {value});

      let phoneNumber = PhoneNumber.isValidPhoneNumber( value, true ) ? value : undefined;
  
      this.props.property.setValue( phoneNumber );
  
      if( this.props.onPropertyChange != null ) {
        this.props.onPropertyChange( this.props.property );
      }
    }

    const value = this.props.property.value();

    const countryProperty =
      this.props.property.parent.property("country") as CountryPropertyIF;

    let defaultCountry = countryProperty?.value() != null ? countryProperty?.value() : country();

    if( defaultCountry != null && unsupportedCountries.includes( defaultCountry )) {
      defaultCountry = country();
    }

    //log.debug("render()", {defaultCountry});
  
    return (
      <>
        <MuiPhoneNumber 
          defaultCountry={defaultCountry!.toLowerCase()} 
          variant={propertyInputVariant} 
          fullWidth 
          key={this.props.property.key()} 
          error={this.state.error != null }
          required={this.props.required}       
          disabled={this.props.disabled}
          label={<PropertyLabel documentName={this.props.property.parent.documentName()} propertyKey={this.props.property.key()} />} 
          InputLabelProps={{ shrink: true }}
          value={value != null ? value : null}
          onChange={handleEditChange}
        />
      </>
    );
  }
}
const ModifiedPhoneNumberPropertyValue = withTranslation()(withStyles(styles)(PhoneNumberPropertyValue));

export default ModifiedPhoneNumberPropertyValue;











