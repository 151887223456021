import * as React from 'react'

import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import { withTranslation, WithTranslation } from 'react-i18next';

import { Chip, Grid, Typography } from '@material-ui/core';
import ChipInput, { ChipRendererArgs } from 'material-ui-chip-input'


import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { log } from 'ui/app/app';

import { TextsPropertyIF } from 'services/database/api/properties/textsPropertyIF';
import PropertyLabel, { translatedPropertyLabel } from '../propertyLabel';
import { PropertyDisplayMode, propertyInputVariant, translatedPropertyValue } from '../propertyValue';
import { DefinitionsPropertyIF } from '../../../services/database/api/properties/definitionsPropertyIF';
import { translatedDefinition } from '../definitionText';
import { HealthguardDefinition } from '../../../healthguard/api/healthguardDefinitions';
import { DatabaseDocumentIF, DocumentNameKey } from '../../../services/database';
import { InlineLoading } from '../loading';
import { definitionIcon } from '../definitionIcon';
import { definitionColor } from '../definitionColor';
import { documentColor } from '../documentColor';
import ColorIcon from '../colorIcon';
import { PropertyTypes }  from '../../../services/database/api/definitions/propertyType'; 

const MaxIndividualValueCellDisplayLength = 3;

const defaultChipColor = undefined;
const nonDefaultChipColor = undefined;

const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex'
  },  
  select: {
    "& .MuiSelect-select:focus": {
      backgroundColor: 'transparent'
    },
    //minHeight: chipSelectInputMinHeight
  }, 
  loading : {
    //color:theme.palette.grey[500], 
    marginLeft: theme.spacing(-4.5),
    marginRight: theme.spacing(-4.5)
  },
  selectAction : {
    //color:theme.palette.grey[500], 
    marginLeft: theme.spacing(-8),
    marginRight: theme.spacing(-8) 
  },
  inputLabel: {
    marginTop: theme.spacing(-0.5)
  },
  icon: {
    width: theme.spacing(2), 
    height: theme.spacing(2),
    marginRight: theme.spacing(1)
  },
  selectChip: {
    height: '100%', 
    display: 'flex', 
    borderRadius: 16,
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0)
  },
  editChip: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(1.5) 
  },
  value: {
    overflowWrap: 'break-word', 
    whiteSpace: 'normal', 
    textOverflow: 'clip',
    wordWrap: "break-word" 
  }
});

export interface TextsPropertyValueProps extends WithStyles<typeof styles>, WithTranslation { 

  property : TextsPropertyIF | DefinitionsPropertyIF<any>, 

  onPropertyChange? : ( property : TextsPropertyIF ) => void,

  displayMode : PropertyDisplayMode,

  singleProperty: boolean,

  hideColor?: boolean,

  disabled?: boolean,

  required?: boolean
}


interface TextsPropertyValueState { 

  open : boolean,

  options? : string[],

  propertyValues? : string[],

  isDefaultValues : boolean,

  definition?: any,

  loading: boolean

}


class TextsPropertyValue extends React.Component<TextsPropertyValueProps,TextsPropertyValueState> {

  constructor( props: TextsPropertyValueProps ) {
    
    super(props);

    let definition;

    if( this.props.property.type === PropertyTypes.Definitions ) {

      definition = (this.props.property as DefinitionsPropertyIF<any>).definition;
    }

    this.state = { 
      open : false,

      propertyValues: this.props.property.value(),

      isDefaultValues: this.props.property.value( true ) == null,

      definition: definition,

      loading: false

     } as TextsPropertyValueState;

     this.options = this.options.bind(this);
     this.renderValue = this.renderValue.bind( this );
     this.renderCell = this.renderCell.bind( this );
     this.renderEdit = this.renderEdit.bind( this );
     this.renderSelect = this.renderSelect.bind( this );

    //log.traceInOut( "constructor()" );
  }

  componentDidMount() {

    log.traceIn( "componentDidMount()" );

    const propertyValues = this.props.property.value();
    const isDefaultValues = this.props.property.value( true ) == null;

    this.setState( {
      propertyValues: propertyValues,
      isDefaultValues: isDefaultValues
    })

    log.traceOut( "componentDidMount()", {isDefaultValues} );
    
  }

  componentDidUpdate() {

    log.traceIn( "componentDidUpdate()" );

    const propertyValues = this.props.property.value();

    const propertyValuesIgnoreDefault = this.props.property.value( true );

    const isDefaultValues = propertyValuesIgnoreDefault == null;

    if( JSON.stringify( propertyValues ) !== JSON.stringify( this.state.propertyValues ) ||
        isDefaultValues !== this.state.isDefaultValues ) {

      this.setState( {
          propertyValues: propertyValues,
          isDefaultValues: isDefaultValues 
      })
    }

    log.traceOut( "componentDidUpdate()", {propertyValues}, {propertyValuesIgnoreDefault}, {isDefaultValues} );

  }

  async options() {

    try {
      log.traceIn( "options()" );

      const options = await this.props.property.select();

      const result = options != null ? Array.from( options.values() ) : [];

      this.setState( { 
        options : result,
        loading: false
      } );
    
      log.traceOut( "options()", result );
      return result;

    } catch( error ) {
      log.warn( "options()", "Error reading options", error );

      this.setState( { 
        loading: false
      } );  
      
      return [] as string[]; 
    }
  }


  render() {
    //log.traceInOut("render()");

    if( this.props.displayMode === PropertyDisplayMode.Cell ) {
      return this.renderCell();   
    }

    if( this.props.property.requiresSelect() ){
      return this.renderSelect();
    }
    else {
      return this.renderEdit();
    }
  }

  renderValue = ( value : string ) : JSX.Element  => {

    const {classes } = this.props;

    const color = this.state.definition != null ? 
      definitionColor( this.state.definition, value ) : 
        this.props.property.key() === DocumentNameKey ? 
          documentColor( this.props.property.parent as DatabaseDocumentIF ) :
          undefined;

    const icon = this.state.definition != null ? 
      definitionIcon( this.state.definition, value, true ) : 
      undefined;

    return (
      <Grid item container alignItems="center" style={{flexWrap: "nowrap"}} key={value}>
        {!this.props.hideColor && color != null &&
          <Grid item className={classes.icon}>
            <ColorIcon color={color}/>
          </Grid>
        }
        {icon != null &&
          <Grid item className={classes.icon}>
            {icon}
          </Grid>
        }
        <Grid item >
          <Typography variant="inherit" color="inherit" className={classes.value} > 
            {
              this.state.definition != null ?
                translatedDefinition( this.state.definition as HealthguardDefinition, value )
                :
                translatedPropertyValue( this.props.property.parent.documentName(),value)
            } 
          </Typography>
        </Grid>
      </Grid>
    );
  }
  

  renderCell() {
    //log.traceInOut("renderCell()");

    if( this.state.propertyValues == null || this.state.propertyValues.length === 0 ) {
      return "";
    }

    if( this.state.propertyValues.length > MaxIndividualValueCellDisplayLength ) {
      return "(" + this.state.propertyValues.length + ")";
    }

    return (
      <Grid container direction="column" >
        {this.state.propertyValues.map(value => {
          return this.renderValue(value)
        })}
      </Grid>
    );
  }

  renderEdit(): JSX.Element {
    //log.traceInOut("renderEdit()");

    const handleAddChip = ( value: string ) => {

      log.traceIn( "handleAddChip()", value );
  
      let values = this.props.property.value();
  
      if( values == null ) {
        values = [] as string[];
      }
  
      values.push( value );
  
      this.props.property.setValue( values );

      this.setState( {
        propertyValues: values,
        isDefaultValues: false
      })
      
      if( this.props.onPropertyChange != null ) {
        this.props.onPropertyChange( this.props.property );
      }
      
      log.traceOut( "handleAddChip()");
    }
  
    const handleDeleteChip = ( value: string, index : number ) => {
  
      log.traceIn( "handleDeleteChip()", value, index );
  
      let values = this.props.property.value();
  
      values!.splice( index, 1 );
  
      this.props.property.setValue( values );

      this.setState( {
        propertyValues: values,
        isDefaultValues: false
      })
      
      if( this.props.onPropertyChange != null ) {
        this.props.onPropertyChange( this.props.property );
      }
      
      log.traceOut( "handleDeleteChip()");
    }

    const chipRenderer = ( args : ChipRendererArgs, key : string ) => {

      const {classes } = this.props;

      const label = args.value;

      return (
        <Chip 
          className={classes.editChip} 
          key={key}
          label={this.renderValue(label)}
          color={this.state.isDefaultValues ? defaultChipColor : nonDefaultChipColor }
          onDelete={args.handleDelete}
        />
      )
    }
  
    return ( 
      <React.Fragment>
        <ChipInput
          disabled={this.props.disabled}
          readOnly={this.props.disabled}
          fullWidth
          variant={propertyInputVariant}
          InputLabelProps={{ shrink: true }}
          label={<PropertyLabel documentName={this.props.property.parent.documentName()} propertyKey={this.props.property.key()} />} 
          value={this.state.propertyValues != null ? this.state.propertyValues  : [] as string[] }
          onAdd={(value) => handleAddChip(value)}
          onDelete={(value, index) => handleDeleteChip(value, index)}
          chipRenderer={chipRenderer}
        />
      </React.Fragment> );    
  
  }

  renderSelect(): JSX.Element {

    log.traceInOut("renderSelect()", this.state.propertyValues, this.state.isDefaultValues );

    const {classes } = this.props;

    const options = this.state.options != null ?
      this.state.options :
      this.state.propertyValues != null ?
        this.state.propertyValues :
        [];

    const values = this.state.propertyValues != null ? this.state.propertyValues : []; 

    const label = translatedPropertyLabel( this.props.property.parent.documentName(), this.props.property.key() );

    const handleChange = (event: SelectChangeEvent<string[]>) => {

      log.traceInOut( "handleChange()", event.target.value); 

      const values = typeof event.target.value === "string" ? 
        event.target.value.split(",") : 
        event.target.value;

      this.props.property.setValues(values);

      const updatedValues = this.props.property.value(); 

      // To avoid flickering effect
      this.setState({
        propertyValues: updatedValues,
        isDefaultValues: false
      })

      if (this.props.onPropertyChange != null) {
        this.props.onPropertyChange(this.props.property);
      }
    };


    const removeValue = ( value: string ) => {

      log.traceInOut( "removeValue()", {value});

      this.props.property.removeValue( value );

      const updatedValues = this.props.property.values();

      this.setState({
        propertyValues: updatedValues,
        isDefaultValues: false
      })

      log.debug( "removeValue()", {updatedValues});

      if (this.props.onPropertyChange != null) {
        this.props.onPropertyChange(this.props.property);
      }
    }


    const renderValues = (values: string[]) => {

      if (values.length === 0) {
        return values;
      }

      return (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1}}> 
          {values.map((value) => (
            <Chip
              className={classes.selectChip}
              key={value}
              label={this.renderValue(value)}
              color={this.state.isDefaultValues ? defaultChipColor : nonDefaultChipColor }
              deleteIcon={!!this.props.disabled? <Box/> : undefined}
              onDelete={() => {removeValue(value)}}
              onMouseDown={(event) => {event.stopPropagation()}} 
            />
          ))}
        </Box>
      );
    }

    return (
      <Grid item container alignItems="center">
        <FormControl
          fullWidth
          variant={propertyInputVariant}
          disabled={this.props.disabled}
          required={this.props.required}
          error={this.props.property.error != null}
        >
          <InputLabel className={classes.inputLabel} 
            shrink={true}>
            {label}
          </InputLabel>
          <Select<string[]>
            multiple
            className={classes.select}
            input={(propertyInputVariant as string) !== "outlined" ? undefined :
              <OutlinedInput
                notched
                label={<>{label}&nbsp;&nbsp;</>}
              />
            }
            displayEmpty={true}
            renderValue={renderValues}
            value={values}
            label={<PropertyLabel documentName={this.props.property.parent.documentName()} propertyKey={this.props.property.key()} />}
            onChange={handleChange}
            IconComponent={ this.state.loading || !!this.props.disabled ? Box : undefined}
            open={this.state.open}
            onOpen={async () => {
              this.setState({ loading: true }) 
              const options = await this.options();
              if( options.length > values.length ) {
                this.setState({ open: true }); 
              }
            }}
            onClose={() => {
              this.setState({ open: false, loading: false });
            }}
          >
            {options.map((option) => (
              <MenuItem
                selected={values.includes(option)}
                style={this.state.open && values.includes(option) ? { display: 'none' } : {}}
                key={option}
                value={option}
                onClick={() => {
                  this.setState({ open: false });
                }}>
                {this.renderValue(option)}
              </MenuItem>
            ))}
            {!this.state.open && values.length === 0 && <MenuItem value="" style={{ display: 'none' }}/>} 
            {!this.state.loading && this.state.options != null && this.state.options.length === 0 && 
              <MenuItem disabled value="emptyOptions">{this.props.t("emptyOptions")}</MenuItem>}
          </Select>
        </FormControl>
        {this.state.loading &&
          <Box className={classes.selectAction}><InlineLoading /></Box>
        }
      </Grid> 
    );
  }
}

const ModifiedTextsPropertyValue = withTranslation()(withStyles(styles)(TextsPropertyValue));

export default ModifiedTextsPropertyValue;











