


import { ContactableIF } from "../../api/documents/contactableIF";
import { CollectionDatabase } from "../core/collectionDatabase";
import { MessagesCollection, TerminalsCollection } from "../../api/collections";
import { log } from "../../framework/databaseService";
import { DatabaseDocument } from "../../framework/databaseDocument";
import { TextProperty } from "../properties/textProperty";
import { PhoneNumberProperty } from "../properties/phoneNumberProperty";
import { CollectionProperty } from "../properties/collectionProperty";
import { Message } from "./message";
import { TextType, TextTypes } from "../../api/definitions/textTypes";
import { CountryProperty } from "../properties/countryProperty";
import { DefinitionProperty } from "../properties/definitionProperty";
import { Language, Languages } from "../../api/definitions/language";
import { LanguageDefinition } from "../../api/definitions";
import { SubdocumentProperty } from "../properties/subdocumentProperty";
import { Contactability } from "../subdocuments/contactability";
import { Terminal } from "./terminal";

import { Factory } from "../../../common/api/factory";

import { MessageCategories, MessageCategory } from "../../api/definitions/messageCategory";

import contactabilityConfiguration from '../../../../healthguard/data/settings/contactability.json'

const contactabilityDefaultsPostfix = "Defaults";

export abstract class Contactable extends DatabaseDocument implements ContactableIF {

    constructor( contactableDocumentName : string, contactableCollection : CollectionDatabase<Contactable>, documentPath? : string ) {

        super( contactableDocumentName, contactableCollection, documentPath ); 

        try {
            this.country = new CountryProperty( this ); 

            this.language = new DefinitionProperty<Language>( this, LanguageDefinition, Languages );

            this.email = new TextProperty( this, TextTypes.Email as TextType );
            this.email.encrypt = false;

            this.phoneNumber = new PhoneNumberProperty( this, this.country ); 

            this.messages = new CollectionProperty<Message>( this, MessagesCollection, true );
      
            this.terminals = new CollectionProperty<Terminal>( this, TerminalsCollection, true );

            const securityContactability = new Contactability( this, "securityContactability");

            this.securityContactability = new SubdocumentProperty<Contactability>( this, securityContactability );

            this.setContactabilityDefaults( securityContactability )
            
            const serviceContactability = new Contactability( this, "serviceContactability");

            this.serviceContactability = new SubdocumentProperty<Contactability>( this, serviceContactability );

            this.setContactabilityDefaults( serviceContactability )
            
            const databaseContactability = new Contactability( this, "databaseContactability");

            this.databaseContactability = new SubdocumentProperty<Contactability>( this, databaseContactability );

            this.setContactabilityDefaults( databaseContactability )

            const communicationContactability = new Contactability( this, "communicationContactability");

            this.communicationContactability = new SubdocumentProperty<Contactability>( this, communicationContactability );

            this.setContactabilityDefaults( communicationContactability )

            //log.traceInOut( "constructor()", CompaniesCollection );

        } catch( error ) {

            log.warn( "constructor()", "Error initializing contactable", error );
            
            throw new Error( (error as any).message );
        }
    }

    contactability( messageCategory : MessageCategory ) : Contactability {

        switch( messageCategory ) 
        {
            case MessageCategories.Database:

                return this.databaseContactability.subdocument()
            
            case MessageCategories.Communication:

               return this.communicationContactability.subdocument()

            case MessageCategories.Security:

            return this.securityContactability.subdocument()

            case MessageCategories.Service:

                return this.serviceContactability.subdocument()

            default:
                throw new Error( "Unknown message category")
        }
    }


    private setContactabilityDefaults(contactability: Contactability) {

        try {
            const contactabilityDefaults = Factory.get().configurationService.config(
                contactabilityConfiguration, contactability.parentKey + contactabilityDefaultsPostfix);

            contactability.email.setDefaultValue(contactabilityDefaults.email)
            contactability.sms.setDefaultValue(contactabilityDefaults.sms)
            contactability.push.setDefaultValue(contactabilityDefaults.push)

        } catch (error) {

            log.warn("constructor()", "Error setting contactable defaults", error );
        }
    }
 
    readonly country : CountryProperty;

    readonly language : DefinitionProperty<Language>; 
    
    readonly email : TextProperty;
    
    readonly phoneNumber : PhoneNumberProperty;

    readonly messages : CollectionProperty<Message>;

    readonly terminals: CollectionProperty<Terminal>;

    readonly securityContactability: SubdocumentProperty<Contactability>; 

    readonly serviceContactability : SubdocumentProperty<Contactability>;

    readonly databaseContactability : SubdocumentProperty<Contactability>;

    readonly communicationContactability : SubdocumentProperty<Contactability>;

}
