import React, { useContext } from 'react';

import DocumentView, { DocumentProps } from '../components/documentView';

import { PropertiesSelector } from 'services/database/api/core/propertiesSelector';

import DatabaseTable, { DatabaseTableProps, VariantProperty, StartDateProperty } from 'ui/components/databaseTable';
import DatabaseCard, { DatabaseCardProps } from 'ui/components/databaseCard';
import DatabaseChart, { DatabaseChartProps } from 'ui/components/databaseChart';
import DatabaseGraph, { DatabaseGraphProps } from 'ui/components/databaseGraph';

import DatabaseView from '../components/databaseView';
import { AlertIF } from 'services/database/api/documents/alertIF';
import { HazardDocument } from '../../healthguard/api/healthguardDocuments';
import { CompanyDocument, DeviceDocument, LocationDocument } from '../../services/database/api/documents';
import DatabaseMap, { DatabaseMapProps } from '../components/databaseMap';
import { AppContext } from '../app/appContext';
import { DatabaseQuery } from '../../services/database/api/core/databaseQuery';
import { SeverityDefinition } from '../../services/database/api/definitions';
import { GatheringsCollection, MeasuresCollection, RisksCollection } from '../../healthguard';
import DatabaseList, { DatabaseListProps } from '../components/databaseList';


export const DefaultAlertTableColumns = [
  StartDateProperty,
  CompanyDocument,
  LocationDocument,
  "premise",
  DeviceDocument,
  SeverityDefinition,
  HazardDocument
];

export const defaultHighlightedAlertProperty = SeverityDefinition;

export function AlertsTable(props: DatabaseTableProps ) {

  return (
    <DatabaseTable {...props} featuredProperties={props.featuredProperties != null ? props.featuredProperties : DefaultAlertTableColumns }/>
  );
}

export function AlertsList(props: DatabaseListProps ) {

  return (
    <DatabaseList {...props} />
  );
}


export function AlertsCard( props: DatabaseCardProps ) {
  return (
    <DatabaseCard 
      {...props} 
      highlightedPropertyKey={props.highlightedPropertyKey != null ? props.highlightedPropertyKey : defaultHighlightedAlertProperty}
    />
  );
}

export function AlertsChart( props: DatabaseChartProps ) {
  return (
    <DatabaseChart 
      {...props} 
    />
  );
}

export function AlertsGraph( props: DatabaseGraphProps ) {
  return (
    <DatabaseGraph 
      {...props} 
      previousPropertyKey={DeviceDocument}
      nextPropertyKey={SeverityDefinition}
      directed={false}
    />
  );
}

export function AlertsMap( props: DatabaseMapProps ) {

  const appContext = useContext(AppContext);

  return (
    <DatabaseMap 
      {...props} 
      geolocationPropertyKey={"geolocation"}
      statusPropertyKey={"status"}
      colorPropertyKey={SeverityDefinition} 
      defaultCountry={appContext.currentCompany?.country.value()} 
    />
  );
}



export function AlertsView(props: { 
  databaseQuery : DatabaseQuery<AlertIF>,
  title? : string
  featuredProperties?: string[],
  highlightedPropertyKey?: string }) {

  return ( <DatabaseView 
    databaseQuery={props.databaseQuery} 
    title={props.title}
    featuredProperties={props.featuredProperties != null ? props.featuredProperties : DefaultAlertTableColumns }
    highlightedPropertyKey={props.highlightedPropertyKey != null ? props.highlightedPropertyKey : defaultHighlightedAlertProperty}
    allowMap={true} 
  /> );
}


export function AlertView( props : DocumentProps) {

const AlertBasicsFilter = {

  includePropertyKeys: [
    "startDate",
    SeverityDefinition,
    HazardDocument,
    LocationDocument,
    "description",
    "geolocation"
  ],

} as PropertiesSelector;


const AlertSourceFilter = {

  includePropertyKeys: [
    "reportedBy",
    "premise",
    DeviceDocument,
    VariantProperty,
    "attachments",
    "links",
    "deviceStatus"
  ],

} as PropertiesSelector;


const AlertAffectsFilter = {

  includePropertyKeys: [
    RisksCollection,
    MeasuresCollection,
    GatheringsCollection
  ],

} as PropertiesSelector;


const defaultAlertSteps = () : Map<string,PropertiesSelector> => {

  let defaultSteps = new Map<string,PropertiesSelector>();

  defaultSteps.set( "basics", AlertBasicsFilter );
  defaultSteps.set( "source", AlertSourceFilter );
  defaultSteps.set( "affects", AlertAffectsFilter );
  //defaultSteps.set( "registrations", DetectionRegistrationsFilter );


  return defaultSteps;
}

  return ( <DocumentView 
    {...props}
    inputTabs={props.inputTabs != null ? props.inputTabs : defaultAlertSteps()}
    /> );
}


