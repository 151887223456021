import { DatabaseDocument } from "../../framework/databaseDocument";
import { log } from "../../framework/databaseService";
import { DatabaseObject } from "../../framework/databaseObject";
import { PropertyType } from "../../api/definitions/propertyType"; 
import { Factory } from "../../../common/api/factory";
import { UserAccess } from "../../../common/api/userAccess";
import { ReferenceHandle, referenceHandleReplacer } from "../../api/core/referenceHandle";
import { Database } from "../core/database";
import { Monitor } from "../../../common/api/monitor";
import { Observation } from "../../../common/api/observation";
import { ObservableIF } from "../../../common/api/observableIF";
import { DocumentsDatabase } from "../core/documentsDatabase";
import { DocumentsPropertyIF } from "../../api/properties/documentsPropertyIF";
import { DatabaseProperty } from "../../framework/databaseProperty";


export abstract class DocumentsProperty<DerivedDocument extends DatabaseDocument> 
    extends DatabaseProperty<Map<string,ReferenceHandle<DerivedDocument>>> implements DocumentsPropertyIF<DerivedDocument> {
 
    constructor( type : PropertyType, 
        parent : DatabaseObject, 
        onSelectSources? : () => (Database<DerivedDocument> | undefined)[],
        reciprocalKey? : keyof DerivedDocument ) { 

        super( type, parent );

        //log.traceIn( "constructor()", parent.title, reciprocalKey);

        try {
            if( reciprocalKey != null && !(parent instanceof DatabaseDocument) ) {
                throw new Error( "Reciprocal keys can only be used for documents" );
            }

            this._onSelectSources = onSelectSources;

            this.reciprocalKey = reciprocalKey as string;

            this.onNotify = this.onNotify.bind(this);

            //log.traceOut( "constructor()" );

        } catch( error ) {
            
            log.warn( "constructor()", "Error initializing document reference", error );

            throw new Error( (error as any).message );
        }
    }

    count() : number {

        const result = this.handles().size;

        //log.traceInOut( "count()", result );
        return result;
    }

    hasDocument( documentPath : string ) : boolean {

        const result = this.handles().has( documentPath.split("?")[0] ); 

        //log.traceInOut( "hasDocument()", result );
        return result;
    }

    documentsDatabase() : DocumentsDatabase<DerivedDocument> {

        if( this._documentsDatabase == null ) {
            this._documentsDatabase = new DocumentsDatabase( this ); 
        }
        return this._documentsDatabase;
    }

    value() : Map<string,ReferenceHandle<DerivedDocument>> | undefined {
        const values = this.referenceHandles();

        return values.size > 0 ? values : undefined;
    }


    setValue( value : Map<string,ReferenceHandle<DerivedDocument>> | undefined ) : void {
        if( value == null ) {
            this.clearDocuments();
        }
        else {
            this.setDocuments( value );
        }
    }

    referenceHandles() : Map<string,ReferenceHandle<DerivedDocument>> {

        try {
            //log.traceIn( "referenceHandles()" );

            let result = new Map<string,ReferenceHandle<DerivedDocument>>();

            //log.trace( "referenceHandles()", this.handles() );

            this.handles().forEach( handle => {

                const referenceHandle = this.referenceHandle( handle.path )!;

                result.set( referenceHandle.path, referenceHandle );
            })
            
            //log.traceOut( "referenceHandles()", {result} );
            return result;    

        } catch( error ) {
            
            log.warn( "referenceHandles()", "Error reading reference handles", error );

            throw new Error( (error as any).message );
        }
    }

    referenceHandle( documentPath: string ) : ReferenceHandle<DerivedDocument> | undefined {

        try {
            //log.traceIn( "referenceHandle()",{documentPath} );

            const handle = this.handles().get(documentPath.split("?")[0]);

            if (handle == null) {
                //log.traceOut( "referenceHandles()", "not found' );
                return undefined;
            }

            const result = Object.assign( {}, handle );

            //log.traceOut( "referenceHandles()", result );
            return result;   

        } catch( error ) {
            
            log.warn( "referenceHandles()", "Error reading reference handles", error );

            throw new Error( (error as any).message );
        }
    }


    paths() : string[] {

        try {
            //log.traceIn( "documentPaths()" );

            let result : string[] = [];
            
            if( this.handles().size > 0 ) {
                result = Array.from( this.handles().keys() );
            }

            //log.traceOut( "documentPaths()", result );
            return result;   

        } catch( error ) {
            
            log.warn( "documentPaths()", "Error reading database object ids", error );

            throw new Error( (error as any).message );
        }
    }

    title(documentPath: string): string | undefined {

        //log.traceIn( "documentTitle()", documentPath );

        try {
            const referenceHandle = this.referenceHandle( documentPath );

            if( referenceHandle == null ) {
                //log.traceOut( "documentTitle()", undefined );
                return undefined;
            }
    
            //log.traceOut( "documentTitle()", handle.title );
            return referenceHandle.title;

        } catch( error ) {
            log.warn( "documentTitle()", "Error reading database document title", error );

            throw new Error( (error as any).message );
        }
    }
    
    async documents(): Promise<Map<string,DerivedDocument>> {
 
        //log.traceIn( "documents()" );

        let result = new Map<string,DerivedDocument>();
        try {

            for( const handle of this.handles().values() ) {

                let databaseDocument = handle.databaseDocument as DerivedDocument | undefined;

                if ( databaseDocument == null) {

                    databaseDocument = await Factory.get().databaseService.databaseFactory.documentFromUrl( handle.path ) as DerivedDocument;

                    if( databaseDocument != null ) {

                        this.handles().set( 
                            handle.path.split("?")[0], 
                            this.newHandle( 
                                handle.path, 
                                databaseDocument.title.value(), 
                                databaseDocument.referenceDateProperty()?.value(), 
                                databaseDocument 
                            )
                        );
                    }
                    else {
                        this.handles().delete( handle.path.split("?")[0] );
                    }
                }  

                if( databaseDocument != null ) {
                    result.set( handle.path.split("?")[0], databaseDocument );
                }
            }
            
            //log.traceOut( "documents()", result );
            return result;  

        } catch( error ) {

            log.warn( "documents()", "Error reading database objects", error );

            throw new Error( (error as any).message );
        }    
    }

    emptyDocument(documentPath: string): DerivedDocument | undefined {

        //log.traceIn( "newDocument()", documentPath );

        try {

            let handle = this.handles().get( documentPath.split("?")[0] ) ;

            if( handle == null ) {
                //log.traceOut( "newDocument()", undefined );
                return undefined;
            }
        
            const databaseDocument = 
                Factory.get().databaseService.databaseFactory.newDocumentFromUrl( documentPath ) as DerivedDocument;

            if( databaseDocument == null ) {
                this.handles().delete( documentPath.split("?")[0] );
                //log.traceOut( "newDocument()", "not foune", undefined );
                return undefined;
            }

            //databaseDocument.title.setValue( handle.title );

            //log.traceOut( "newDocument()", document );
            return databaseDocument;

        } catch( error ) {
            log.warn( "newDocument()", "Error reading database document", error );

            throw new Error( (error as any).message );
        }
    }

   async document(documentPath: string ): Promise<DerivedDocument | undefined> {

        //log.traceIn( "document()", documentPath );

        try {

            let handle = this.handles().get( documentPath.split("?")[0] ) ;

            if( handle == null ) {
                log.traceOut( "document()", undefined );
                return undefined;
            }

            let databaseDocument;
            
            if( handle.databaseDocument == null ) {

                databaseDocument = await Factory.get().databaseService.databaseFactory.documentFromUrl( documentPath ) as DerivedDocument;

                if( databaseDocument == null ) {

                    this.handles().delete( documentPath.split("?")[0] );
                    throw new Error( "Reference not found with path: " + documentPath );
                }

                handle = this.newHandle( 
                    documentPath, 
                    databaseDocument.title.value() != null ? databaseDocument.title.value() : handle.title, 
                    databaseDocument.referenceDateProperty()?.value() != null ? databaseDocument.referenceDateProperty()!.value() : handle.date, 
                    databaseDocument );
    
                this.handles().set( documentPath.split("?")[0], handle );
            }
            else {
                databaseDocument = handle.databaseDocument as DerivedDocument;
            }
            

            //log.traceOut( "document()", document );
            return handle!.databaseDocument! as DerivedDocument;

        } catch( error ) {
            log.warn( "document()", "Error reading database document", error );

            throw new Error( (error as any).message );
        }
    }

    
    setDocument( referenceHandle : ReferenceHandle<DerivedDocument> ): void {

       log.traceIn( "setDocument()", referenceHandle.title );

        let previouslyAdded = false;

        try {

            const previousReferenceHandle = this.handles().get( referenceHandle.path.split("?")[0] );

            //log.debug( "setDocument()", "previous", previousReferenceHandle?.title );

            previouslyAdded = previousReferenceHandle != null;

            //log.debug( "setDocument()", {previouslyAdded} );

            if( previousReferenceHandle == null || 
                (referenceHandle.title != null && previousReferenceHandle.title == null ) ) {

                this.setLastChange( Object.assign( new Map<string,ReferenceHandle<DerivedDocument>>(), this.value() ) );

                const newHandle = this.newHandle( 
                    referenceHandle.path, 
                    referenceHandle.title, 
                    referenceHandle.date, 
                    referenceHandle.databaseDocument );

                this.handles().set( 
                    referenceHandle.path.split("?")[0], 
                    newHandle );


                delete this.error;

                super.notify( Observation.Create, referenceHandle.path, referenceHandle );

                this.updateDatabaseSubscription();
                
               // log.debug( "setDocument()" );
            }

            log.traceOut( "setDocument()" );

        } catch( error ) {
            log.warn( "setDocument()", "Error adding database object", error );

            if( !previouslyAdded ) {
                this.handles().delete( referenceHandle.path.split("?")[0] );
            }

            throw new Error( (error as any).message );
        }
    }

    setDocuments( referenceHandles : Map<string,ReferenceHandle<DerivedDocument>> ): void {
        
        //log.traceIn( "setDocuments()", referenceHandles );

        try {
           
            if( this.compareValue( referenceHandles ) === 0 ) {
                //log.traceOut( "setDocuments()", "no change" );
                return;
            }
            this.setLastChange( this.value() );

            delete this.error;

            this.handles().clear();

            referenceHandles.forEach( referenceHandle => {

                if( referenceHandle.path != null ) {

                    this.handles().set( 
                        referenceHandle.path.split("?")[0], 
                        this.newHandle( 
                            referenceHandle.path, 
                            referenceHandle.title, 
                            referenceHandle.date )
                    )
                }
            });

            super.notify( Observation.Create, this.parentDocument().databasePath() + "/" + this.key(), referenceHandles );

            this.updateDatabaseSubscription();

            //log.traceOut( "setDocuments()" );

        } catch( error ) {
            log.warn( "setDocuments()", "Error adding database objects", error );

            throw new Error( (error as any).message );
        }
    }

    removeDocument( documentPath: string): boolean {
        log.traceIn( "removeDocument()", documentPath );

        try {

            const previousValue = this.value();

            if( this.handles().delete( documentPath.split("?")[0] ) ) {

                this.setLastChange( previousValue );

                delete this.error;

                super.notify( Observation.Delete, documentPath );

                this.updateDatabaseSubscription();

                log.traceOut( "removeDocument()", "removed" );
                return true;
            }

            log.traceOut( "removeDocument()", "not found" );
            return false;

        } catch( error ) {
            log.warn( "removeDocument()", "Error removing database object", error );

            throw new Error( (error as any).message );
        }
    }

    clearDocuments(): void {

        //log.traceIn( "clearDocuments()" );

        try {
            if( this.handles().size > 0 ) {

                this.setLastChange( this.value() );

                delete this.error;
            }

            this.handles().clear();

            super.notify( Observation.Delete, this.parentDocument().databasePath() + "/" + this.key() );

            this.updateDatabaseSubscription();

            //log.traceOut( "clearDocuments()" );

        } catch( error ) {
            log.warn( "clearDocuments()", "Error adding database object", error );

            throw new Error( (error as any).message );
        }
    }

    newDocument(): DerivedDocument | undefined {

        try {

            const sources = this.sources();

            if( sources == null || sources.length === 0 ) {
                return undefined;
            }

            for( const source of sources ) {

                if( source == null ) {
                    continue;
                }

                return source.newDocument();
            }

            return undefined;

        } catch( error ) {

            log.warn("path()", "Error creating new document on references ", error );

            throw new Error( (error as any).message );
        }  
    }

    userAccess(): UserAccess {

        //log.traceIn( "userAccess()" );

        try {
            const sources = this.sources();

            if( sources == null || sources.length === 0 ) {
                //log.traceOut( "userAccess()", "no sources" );
                return UserAccess.allowNone();
            }

            //log.traceOut( "userAccess()", "defer to parent document" );
            return this.parentDocument().userAccess();

        } catch( error ) {

            log.warn("path()", "Error getting user access on references ", error );

            throw new Error( (error as any).message );
        }  
    }


    collectionName() : string | undefined {

        try {
            const sources = this.sources();

            if( sources == null || sources.length === 0 ) {
                return undefined;
            }

            for( const source of sources ) {

                if( source == null ) {
                    continue;
                }

                return source.collectionName();
            }

            return undefined; 

        } catch( error ) {

            log.warn("path()", "Error reading database path", error );

            throw new Error( (error as any).message );
        } 
    }

    queryDocumentName() : string | undefined {

        try {
            const sources = this.sources();

            if( sources == null || sources.length === 0 ) {
                return undefined;
            }

            for( const source of sources ) {

                if( source == null ) {
                    continue;
                }

                return source.queryDocumentName();
            }

            return undefined;

        } catch( error ) {

            log.warn("path()", "Error reading default document name", error );

            throw new Error( (error as any).message );
        } 
    }

    documentNames() : string[] | undefined {

        try {
            const sources = this.sources();

            if( sources == null || sources.length === 0 ) {
                return undefined;
            }

            for( const source of sources ) {

                if( source == null ) {
                    continue;
                }

                return source.documentNames();

            }

            return undefined;

        } catch( error ) {

            log.warn("path()", "Error reading document name", error );

            throw new Error( (error as any).message );
        } 
    }



    async options(): Promise<Map<string,ReferenceHandle<DerivedDocument>> | undefined> {
 
        log.traceIn( "options()" );

        const options = this.referenceHandles();

        log.traceOut( "options()", options );
        return options.size > 0 ? options : undefined; 
    }


    async select( filterValues? : boolean ): Promise<Map<string,ReferenceHandle<DerivedDocument>> | undefined> {
 
        log.traceIn( "select()", {filterValues} );

        try {
            const sources = this.sources();

            if( sources == null || sources.length === 0 ) {
                log.traceOut( "referenceOptions()", "empty reference" );
                return undefined;
            }

            const result = new Map<string,ReferenceHandle<DerivedDocument>>();

            const handles = this.handles();

            for( const source of sources ) {

                if( source == null ) {
                    continue;
                }

                const referenceHandles = await source.referenceHandles();

                for( const referenceHandle of referenceHandles.values() ) {

                    if( !!filterValues && handles.has( referenceHandle.path.split("?")[0])) {
                        continue;
                    }
                    result.set( referenceHandle.path, referenceHandle )
                }
            }

            log.traceOut( "select()", result );
            return result;  

        } catch( error ) {

            log.warn( "select()", "Error reading database objects", error );

            throw new Error( (error as any).message );
        }    
    }

    sources() : (Database<DerivedDocument> | undefined)[] | undefined {

        if( this._sources !== undefined ) {
            return this._sources == null ? undefined : this._sources;
        }

        const sources = this._onSelectSources != null ? this._onSelectSources() : undefined;

        if( sources != null ) {
            for( const source of sources ) {

                if( source != null && source.userAccess().allowRead ) {
    
                    if( this._sources == null ) {
                        this._sources = [];
                    }
    
                    this._sources.push( source );
                }
            }
        }

        if( sources == null || sources.length === 0 ) {
            this._sources = null;
            return undefined; 
        }
            
        return this._sources;
    }

    compareTo( other : DocumentsProperty<DerivedDocument> ) : number {

        if( this.handles().size > other.handles().size ) {
            return 1;
        }

        if( this.handles().size < other.handles().size ) {
            return -1;
        }

        for( const handle of this.handles() ) {

            const path = handle[0];

            if( !other.handles().has( path.split("?")[0] )  ) {
                return 1;
            }
        }

        return 0;
    }

    compareValue( referenceHandles : Map<string,ReferenceHandle<DerivedDocument>> | undefined  ) : number {

        if( referenceHandles == null ) {
            return 1;
        }

        if( this.handles().size > referenceHandles.size ) {
            return 1;
        }

        if( this.handles().size < referenceHandles.size ) {
            return -1;
        }

        for( const referenceHandle of referenceHandles.values() ) {

            if( referenceHandle.path == null || !this.handles().has( referenceHandle.path.split("?")[0] ) ) {
                return 1;
            }
        }

        return 0;
    }

    includes( other : DocumentsProperty<DerivedDocument>, matchAny? : boolean  ) : boolean {
        return this.includesValue( other.referenceHandles(), matchAny );
    }

    includesValue( referenceHandles : Map<string,ReferenceHandle<DerivedDocument>> | undefined, matchAny? : boolean ) : boolean {

        log.traceIn( "includesValue", this.handles(), {referenceHandles} )

        if( referenceHandles == null || referenceHandles.size === 0 ) {
            log.traceOut( "includesValue", "No input" )
            return false;
        }

        if( this.handles().size < referenceHandles.size ) {

            log.traceOut( "includesValue", "Too big" )
            return false;
        }

        for( const referenceHandle of referenceHandles.values() ) {

            const compare = referenceHandle != null && this.handles().has( referenceHandle.path.split("?")[0] );

            if( compare && !!matchAny ) {
                return true; 
            }

            if( !compare && !matchAny ) {
                return false;
            }
        }

        log.traceOut( "includesValue", "Found" )
        return !matchAny;
    }

    protected async monitor(newMonitor: Monitor): Promise<void> {
        log.traceIn("monitor()");

        try {
            const sources = this.sources();

            if( sources == null || sources.length !== 1 ) {

                throw new Error( "Reference monitoring requires a single database source");
            }  
            
            this._sourceDatabase = sources[0];

            await this.updateDatabaseSubscription();

            if( newMonitor.onNotify != null ) {

                const result = await this.documents();

                await newMonitor.onNotify( this, Observation.Create, this._sourceDatabase!.databasePath(true), result  );
                
            }

            log.traceOut("monitor()"); 

        } catch (error) {

            log.warn("monitor()", "Error monitoring documents", error);
        }
    }

    protected async release(): Promise<void> {

        try {
            //log.traceIn( "release()" );

            if (this._sourceDatabase != null) {

                await this._sourceDatabase.unsubscribe(this);
            }

            //log.traceOut( "release()" );

        } catch (error) {

            log.warn("release()", "Error releasing database objects", error);

        }
    }

    private async updateDatabaseSubscription(): Promise<void> {

        //log.traceIn( "updateDatabaseSubscription()" ); 

        try {

            if( this._sourceDatabase != null && super.isMonitoring() && this.handles().size > 0) {

                let monitor = {
                    observer: this,
                    onNotify: this.onNotify,
                    observationFilter: super.observationFilter(),
                    objectIdsFilter: Array.from(this.handles().keys())
                } as Monitor;

                await this._sourceDatabase.subscribe(monitor);
            }
            else {
                if (this._sourceDatabase != null) {
                    await this._sourceDatabase.unsubscribe(this);
                }
            }

            //log.traceOut( "updateDatabaseSubscription()" );  
        } catch (error) {

            log.warn("release()", "Error releasing database objects", error);
        }
    }

    protected onNotify = async (observable: ObservableIF,
        observation: Observation,
        objectId?: string,
        object?: any): Promise<void> => {

        log.traceIn("onNotify()", observation, objectId, object);

        try {
            switch (observation) {
                case Observation.Create:
                    {
                        if (Factory.get().databaseService.databaseFactory.isUrlDatabase(objectId!)) {

                            const result = object as Map<string, DerivedDocument>;

                            for (const databaseDocument of result.values()) {

                                let handle = this.handles().get(databaseDocument.databasePath(true).split("?")[0]);

                                if (handle != null) {

                                    if (handle.databaseDocument != null) {

                                        handle.databaseDocument.copyFrom(databaseDocument);
                                    }
                                    else {
                                        handle.databaseDocument = databaseDocument;
                                    }
                                }
                            } 
                        }
                        else {
                            const databaseDocument = object as DerivedDocument;

                            const documentPath = databaseDocument.databasePath();

                            let handle = this.handles().get(documentPath.split("?")[0]);

                            if (handle != null) {

                                if (handle.databaseDocument != null) {

                                    handle.databaseDocument.copyFrom(object! as DerivedDocument);
                                }
                                else {
                                    handle.databaseDocument = object! as DerivedDocument;
                                }
                            }
                        }
                        break;
                    }
                case Observation.Update:
                    {
                        const databaseDocument = object as DerivedDocument;

                        const documentPath = databaseDocument.databasePath();

                        let handle = this.handles().get(documentPath.split("?")[0]);

                        if (handle != null) {

                            if (handle.databaseDocument != null) {

                                handle.databaseDocument.copyFrom(object! as DerivedDocument);
                            }
                            else {
                                handle.databaseDocument = object! as DerivedDocument;
                            }
                        }
                        break;
                    }
                case Observation.Delete: {

                    const databaseDocument = object as DerivedDocument;

                    const documentPath = databaseDocument.databasePath();

                    let handle = this.handles().get(documentPath.split("?")[0]);

                    if (handle != null) {

                        this.handles().delete(documentPath.split("?")[0])
                    }
                    else {
                        log.warn("onNotify()",
                            "Ignoring update for document not held by this handle", documentPath)
                    }
                    break;
                }

                default:
                    break;
            }
            await super.notify(observation, objectId, object);

            log.traceOut("onNotify()");

        } catch (error) {

            log.warn("onNotify()", "Error notifying observers", error);
        }
    }

    async toData( documentData: any, force? : boolean ) : Promise<void> { 

        //log.traceIn( "toData()" );

        try {

            if( !!force && this._encryptedReferencesData != null) {
                this.referenceHandles(); // forces decryption
            } 

            if( this.encrypted() && this._encryptedReferencesData != null ) {

                documentData[this.key()] = this._encryptedReferencesData;

                //log.traceOut( "toData()", "from encrypted data" );
                return;
            }

            if( this.handles().size === 0 ) {
                //log.traceOut(  "toData()",  undefined );
                return;
            }

            const referenceMap = {} as any;

            const referenceHandles = this.referenceHandles();
                
            referenceHandles.forEach( referenceHandle => {

                const documentId = 
                    Factory.get().databaseService.databaseFactory.documentId( referenceHandle.path )!;

                const jsonReferenceHandle = JSON.stringify( referenceHandle, referenceHandleReplacer );

                if( this.encrypted() ) {

                    referenceMap[documentId] = this.encryptData( jsonReferenceHandle )!;
                }
                else {

                    referenceMap[documentId] = jsonReferenceHandle;
                }
            });

            if( this.encrypted() ) {

                this._encryptedReferencesData = referenceMap;

                documentData[this.key()] = this._encryptedReferencesData;
            }
            else {
                documentData[this.key()]  = referenceMap;
            }

            //log.traceOut( "toData()", "result" );

        } catch( error ) {
            log.warn( "toData()", "onNotify()", "Error notifying observers", error );

            throw new Error( (error as any).message );
        }
    }

    fromData( documentData: any): void {

        //log.traceIn( "fromData()" );

        try {
            this._handles = new Map<string,ReferenceHandle<DerivedDocument>>();

            const data = documentData[this.key()];

            if( data == null ) {
                //log.traceOut( "fromData()", this.key(), "No data" );
                return;
            }

            let jsonObject : any;

            if (typeof data === 'object' ) {

                jsonObject = data;
            } 
            else if (typeof data === 'string') {

                jsonObject = JSON.parse(data);
            }
            else {
                throw new Error( "Empty reference object: " + data );                  
            }

            if( jsonObject == null ) {
                throw new Error( "Corrupt reference: " + data );
            }

            for( const entry of Object.entries(jsonObject) ) {

                if( typeof entry[0] === "string" && entry[0].startsWith("/") ) { // legacy 2, cannot use path as index for searh queries

                    const path = entry[0];

                    const title = entry[1] as string;

                    this._handles.set( 
                        path,
                        {   
                            path: path, 
                            title : title != null && title.length > 0 ? title : undefined, 
                        } as ReferenceHandle<DerivedDocument> ); 

                    //log.warn( "fromData()", "Old data format!", this.parent.ownerCollection()!.databasePath(), this.key(), {documentData} );

                }
                else {
                            
                    if( this.isEncryptedData( entry[1] ) ) {    

                        this._encryptedReferencesData = jsonObject;
                        break;
                    }
                    else {

                        const databaseReference = typeof entry[0] === "string" ? undefined : entry[0] as any;

                        const json = entry[1] as any;

                        const referenceHandle = JSON.parse( json ) as ReferenceHandle<DerivedDocument>;

                        log.debug("fromData()", {referenceHandle} )

                        if( referenceHandle?.path != null ) {

                            this._handles.set( referenceHandle.path.split("?")[0], { 

                                    path: referenceHandle.path, 

                                    title : referenceHandle.title, 

                                    date : referenceHandle.date != null ? new Date( referenceHandle.date ) : undefined,

                                    databaseReference: databaseReference                

                                } as ReferenceHandle<DerivedDocument> 
                            );
                        }
                        else {
                            //log.warn("fromData()", "could not read:", jsonObject[id] )
                        }
                    }
                }
            }
            
            //log.traceOut( "fromData()", this.key(), this._handles, this._encryptedReferencesData );
             
        } catch( error ) {
            log.warn( "fromData()", "Error reading reference ", error );

            throw new Error( (error as any).message );
        }
    }



    protected handles(): Map<string,ReferenceHandle<DerivedDocument>> {

        //log.traceIn( "handles()" );

        try {

            if (this._encryptedReferencesData != null) {

                this._handles = new Map<string,ReferenceHandle<DerivedDocument>>();

                const jsonObject = this._encryptedReferencesData;

                delete this._encryptedReferencesData;

                for (const entry of Object.entries(jsonObject)) {

                    const databaseReference = typeof entry[0] === "string" ? undefined : entry[0] as any;

                    const encryptedJson = entry[1] as string; 

                    const jsonReferenceHandle = Factory.get().securityService.symmetricCipher.decrypt( encryptedJson );

                    if( jsonReferenceHandle != null ) {
                        const referenceHandle = JSON.parse(jsonReferenceHandle) as ReferenceHandle<DerivedDocument>;

                        if (referenceHandle?.path != null) {
    
                            this._handles.set( referenceHandle.path.split("?")[0], {

                                    path: referenceHandle.path,

                                    title: referenceHandle.title,

                                    date : referenceHandle.date != null ? new Date( referenceHandle.date ) : undefined,

                                    databaseReference: databaseReference

                                } as ReferenceHandle<DerivedDocument>
                            );
                        }
                    }
                }
            }

            //log.traceOut( "handles()");
            return this._handles;

        } catch( error ) {
            log.warn( "handles()", "Error reading handles ", error );

            throw new Error( (error as any).message );
        }
    }


    private newHandle( 
        path : string, 
        title? : string, 
        date? : Date, 
        databaseDocument? : DerivedDocument ) : ReferenceHandle<DerivedDocument> { 

        const databaseHandle = {

            title: title,

            date: date,

            path : path,

            databaseDocument : databaseDocument,

            databaseReference: databaseDocument?.databaseReference()

        } as ReferenceHandle<DerivedDocument>;

        return databaseHandle;
    } 

    readonly reciprocalKey?: string;

    protected readonly _onSelectSources? : () => (Database<DerivedDocument> | undefined)[];

    protected _sources? : (Database<DerivedDocument> | undefined)[] | null;

    private _documentsDatabase? : DocumentsDatabase<DerivedDocument>; 

    private _sourceDatabase : Database<DerivedDocument> | undefined;

    private _encryptedReferencesData? : any; 

    private _handles = new Map<string,ReferenceHandle<DerivedDocument>>();   


}
