
export const RegistrationDocuments = {

    Registration                : "registration",

    IncidentRegistration        : "incidentRegistration",

    QuarantineRegistration      : "quarantineRegistration",

    SickLeaveRegistration       : "sickLeaveRegistration",

    SymptomRegistration         : "symptomRegistration",

    TestRegistration            : "testRegistration",

    VaccineRegistration         : "vaccineRegistration"
}

export type RegistrationDocumentName = keyof (typeof RegistrationDocuments);

export const RegistrationDocumentNames = Object.values( RegistrationDocuments );

export const RegistrationDocument = RegistrationDocuments.Registration as RegistrationDocumentName;

export const IncidentRegistrationDocument = RegistrationDocuments.IncidentRegistration as RegistrationDocumentName;

export const QuarantineRegistrationDocument = RegistrationDocuments.QuarantineRegistration as RegistrationDocumentName;

export const SickLeaveRegistrationDocument = RegistrationDocuments.SickLeaveRegistration as RegistrationDocumentName;

export const SymptomRegistrationDocument = RegistrationDocuments.SymptomRegistration as RegistrationDocumentName;

export const TestRegistrationDocument = RegistrationDocuments.TestRegistration as RegistrationDocumentName;

export const VaccineRegistrationDocument = RegistrationDocuments.VaccineRegistration as RegistrationDocumentName;

