
import { HealthguardDeviceIF } from "../../api/documents/healthguardDeviceIF";
import { log } from "../../../services/database/framework/databaseService";
import { CollectionDatabase } from "../../../services/database/impl/core/collectionDatabase";
import { TestRegistration } from "./testRegistration";
import { Measure } from "./measure";
import { Device } from "../../../services/database/impl/documents/device";
import { HealthguardDeviceDocument } from "../../api/healthguardDocuments";
import { ReferencesProperty } from "../../../services/database/impl/properties/referencesProperty";
import { HealthguardCompany } from "./healthguardCompany";
import { DefinitionProperty } from "../../../services/database/impl/properties/definitionProperty";
import { DeviceTypeDefinition } from "../../api/healthguardDefinitions";
import { DeviceType, DeviceTypes } from "../../api/definitions/deviceType";
import { HealthguardDeviceManager } from "../managers/healthguardDeviceManager";
import { Risk } from "./risk";
import { ReferenceProperty } from "../../../services/database/impl/properties/referenceProperty";
import { TextProperty } from "../../../services/database/impl/properties/textProperty";
import { Hazard } from "./hazard";
import { Factory } from "../../../services/common/api/factory";
import { HazardsCollection } from "../../api/healthguardCollections";
import { OptionsSource } from "../../../services/database/impl/core/optionsSource";
import { TextsProperty } from "../../../services/database/impl/properties/textsProperty";
import { SeverityDefinition } from "../../../services/database/api/definitions";
import { DateProperty } from "../../../services/database/impl/properties/dateProperty";
import { Severities, Severity } from "../../../services/database/api/definitions/severity";
import { DefaultTextType } from "../../../services/database/api/definitions/textTypes";

export class HealthguardDevice extends Device implements HealthguardDeviceIF {

    constructor( categoriesCollection : CollectionDatabase<HealthguardDevice>, documentPath? : string ) {

        super( HealthguardDeviceDocument, categoriesCollection, documentPath ); 

        try {
            const company = this.company.emptyDocument() as HealthguardCompany;

            this.deviceType = new DefinitionProperty<DeviceType>(
                this, 
                DeviceTypeDefinition, 
                DeviceTypes );
            
            this.geolocation.setTypeProperty( this.deviceType ); 

            this.lastAlertAt = new DateProperty( this ); 

            this.lastAlertSeverity = new DefinitionProperty<Severity>(
                this, 
                SeverityDefinition, 
                Severities );
            
            this.lastAlertStatus = new TextProperty( this, DefaultTextType, true );  

            this.geolocation.setLevelProperty( this.lastAlertSeverity ); 

            this.hazard = new ReferenceProperty<Hazard>( this, 
                company != null ? 
                    () => [company.hazards.collection(),company.publicHazards.documentsDatabase()] : 
                    () => [Factory.get().databaseService.databaseFactory.collectionDatabaseFromCollectionName( 
                        HazardsCollection ) as CollectionDatabase<Hazard>]  ); 

            this.variant = new TextProperty( this ); 
            this.variant.setOptionsSource( new OptionsSource<Hazard,TextsProperty>( 
                this.hazard, "variants" )  );

            this.risks = new ReferencesProperty<Risk>( this, 
                company != null ? 
                    () => [company.risks.collectionGroup()] :  
                    undefined,
                "devices" );

            this.measures = new ReferencesProperty<Measure>( this, 
                company != null ? 
                    () => [company.measures.collectionGroup()] : 
                    undefined,
                "devices" );


            this.testRegistrations = new ReferencesProperty<TestRegistration>(this,
                company != null ?
                    () => [company.testRegistrations.collectionGroup()] :
                    undefined,
                "device");

            //log.traceInOut( "constructor()", UnitsCollection );

        } catch( error ) {

            log.warn( "constructor()", "Error initializing unit", error );
            
            throw new Error( (error as any).message );
        }
    }

    async onCreate() : Promise<void> {

        //log.traceIn( "onCreated()" );

        try {

            await super.onCreate();

            await HealthguardDeviceManager.getInstance().handleCreateHealthguardDevice( this );
    
            //log.traceOut( "onCreated()" );
  
        } catch( error ) {
            
            log.warn( "onCreated()", "Error handling created notification", error );
  
            throw new Error( (error as any).message );
        }
    }

    async onUpdate() : Promise<void> {

        //log.traceIn( "onUpdate()" );

        try {

            await super.onUpdate();

            await HealthguardDeviceManager.getInstance().handleUpdateHealthguardDevice( this );
    
            //log.traceOut( "onUpdate()" );
  
        } catch( error ) {
            
            log.warn( "onUpdated()", "Error handling updated notification", error );
  
            throw new Error( (error as any).message );
        }
    }

    async onDelete() : Promise<void> {

        //log.traceIn( "onDelete()" );

        try {

            await super.onDelete();

            await HealthguardDeviceManager.getInstance().handleDeleteHealthguardDevice( this );
    
            //log.traceOut( "onDelete()" );
  
        } catch( error ) {
            
            log.warn( "onDelete()", "Error handling deleted notification", error );
  
            throw new Error( (error as any).message );
        }
    }

    readonly deviceType: DefinitionProperty<DeviceType>;

    readonly lastAlertAt : DateProperty;

    readonly lastAlertSeverity : DefinitionProperty<Severity>;

    readonly lastAlertStatus : TextProperty;

    readonly hazard : ReferenceProperty<Hazard>;

    readonly variant : TextProperty;

    readonly risks: ReferencesProperty<Risk>;

    readonly measures: ReferencesProperty<Measure>;

    readonly testRegistrations: ReferencesProperty<TestRegistration>;


}


