import { Platform, Platforms } from "../../../common/api/platforms";
import { Target } from "../../../common/api/targets";
import { RealmServiceIF } from "../../api/realm/realmServiceIF";
import { ApplicationService } from "../applicationService";

export abstract class RealmService extends ApplicationService implements RealmServiceIF {

    constructor( target : Target ) {
        super( Platforms.Realm as Platform, target );
    }
    
    abstract database() : any;

    abstract readonly app : any;

}