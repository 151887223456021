
import { DatabaseDocumentIF } from "./databaseDocumentIF";

export type ReferenceHandle<DerivedDocumentIF extends DatabaseDocumentIF> = {  
    
    title? : string; 
    
    date? : Date;

    path : string;  

    databaseDocument?: DerivedDocumentIF; 

    databaseReference?: any
}

export const referenceHandleToData = ( referenceHandle? : ReferenceHandle<DatabaseDocumentIF> ) : any | undefined => { 

    if( referenceHandle == null ) {
        return undefined;
    }

    const data = Object.assign( {}, referenceHandle );

    Object.entries( data ).forEach( entry => {
        
        const key = entry[0];
        const replacedValue = referenceHandleReplacer( key, entry[1]);

        if( replacedValue == null ) {
            delete (data as any)[key];
        }
        else {
            (data as any)[key] = replacedValue;
        }
    })

    return data
}

export const referenceHandleReplacer = (key : string, value : any ) : any => {

    if( key === "databaseDocument") {
        return undefined;
    }
    else if( key === "databaseReference") {
        return undefined;
    }
    else {
        return value;
    }

}