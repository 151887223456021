import red from '@mui/material/colors/red';

import { createTheme } from '@material-ui/core/styles'

import type {} from "@material-ui/lab/themeAugmentation";

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: '#556cd6',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    },
  },
  overrides: {
    MuiSelect: {
      select: {
        "&:focus": {
          backgroundColor: "$labelcolor"
        }
      }
    }
  }
});

export default theme;