
export const CategoryTypes = {

    BirthYear      : "birthYear",

    Sex             : "sex"


}

export type CategoryType = keyof (typeof CategoryTypes); 




