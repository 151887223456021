
import { log } from "../../../services/database/framework/databaseService";
import { CollectionDatabase } from "../../../services/database/impl/core/collectionDatabase";
import { IncidentRegistration } from "./incidentRegistration";
import { QuarantineRegistration } from "./quarantineRegistration";
import { SickLeaveRegistration } from "./sickLeaveRegistration";
import { SymptomRegistration } from "./symptomRegistration";
import { TestRegistration } from "./testRegistration";
import { VaccineRegistration } from "./vaccineRegistration";
import { Measure } from "./measure";
import { ReferencesProperty } from "../../../services/database/impl/properties/referencesProperty";
import { HealthguardCompany } from "./healthguardCompany";
import { Location } from "../../../services/database/impl/documents/location";
import { HealthguardLocationIF } from "../../api/documents/healthguardLocationIF";
import { HealthguardLocationDocument } from "../../api/healthguardDocuments";
import { TextProperty } from "../../../services/database/impl/properties/textProperty";
import { PhoneNumberProperty } from "../../../services/database/impl/properties/phoneNumberProperty";
import { Gathering } from "./gathering";
import { DefinitionProperty } from "../../../services/database/impl/properties/definitionProperty";
import { ReadinessLevel, ReadinessLevels } from "../../api/definitions/readinessLevel";
import { Factory } from "../../../services/common/api/factory";
import { HealthguardLocationManager } from "../managers/healthguardLocationManager";
import { Risk } from "./risk";
import { DefaultTextType} from "../../../services/database/api/definitions/textTypes";
import { ReadinessLevelDefinition } from "../../../services/database/api/definitions";

export class HealthguardLocation extends Location implements HealthguardLocationIF { 

    constructor( locationsCollection : CollectionDatabase<HealthguardLocation>, documentPath? : string ) {

        super( HealthguardLocationDocument, locationsCollection, documentPath ); 

        try {
            const company = this.company.emptyDocument() as HealthguardCompany;

            this.emergencyContactName = new TextProperty( this );

            this.emergencyContactNumber = new PhoneNumberProperty( this );

            this.risks = new ReferencesProperty<Risk>( this, 
                company != null ? 
                    () => [company.risks.collectionGroup()] : 
                    undefined, 
                "locations" );

            this.measures = new ReferencesProperty<Measure>( this, 
                company != null ? 
                    () => [company.measures.collectionGroup()] : 
                    undefined, 
                "locations" );

            this.readinessLevel = new DefinitionProperty<ReadinessLevel>(
                this, 
                ReadinessLevelDefinition, 
                ReadinessLevels, 
                (Factory.get().databaseService.currentCompany() as HealthguardCompany)?.readinessLevel.value() );

            this.geolocation.setLevelProperty( this.readinessLevel ); 

            this.status = new TextProperty( this, DefaultTextType, true );   

            this.geolocation.setStatusProperty( this.status ); 

            this.gatherings = new ReferencesProperty<Gathering>( this, 
                company != null ? 
                    () => [company.gatherings.collectionGroup()] : 
                    undefined, 
                "location" );

            this.incidentRegistrations = new ReferencesProperty<IncidentRegistration>( this, 
                company != null ? 
                    () => [company.incidentRegistrations.collectionGroup()] : 
                    undefined, 
                "locations" );    
            
            this.quarantineRegistrations = new ReferencesProperty<QuarantineRegistration>( this, 
                company != null ? 
                    () => [company.quarantineRegistrations.collectionGroup()] : 
                    undefined, 
                "locations" );
    
            this.sickLeaveRegistrations = new ReferencesProperty<SickLeaveRegistration>( this, 
                company != null ? 
                    () => [company.sickLeaveRegistrations.collectionGroup()] : 
                    undefined, 
                "locations" );
    
            this.symptomRegistrations = new ReferencesProperty<SymptomRegistration>( this, 
                company != null ? 
                    () => [company.symptomRegistrations.collectionGroup()] : 
                    undefined, 
                "locations" );
    
            this.testRegistrations = new ReferencesProperty<TestRegistration>( this, 
                company != null ? 
                    () => [company.testRegistrations.collectionGroup()] : 
                    undefined, 
                "locations" );
        
            this.vaccineRegistrations = new ReferencesProperty<VaccineRegistration>( this, 
                company != null ? 
                    () => [company.vaccineRegistrations.collectionGroup()] : 
                    undefined, 
                "locations" );
            
            //log.traceInOut( "constructor()", UnitsCollection );

        } catch( error ) {

            log.warn( "constructor()", "Error initializing unit", error );
            
            throw new Error( (error as any).message );
        }
    }

    async onCreated() : Promise<void> {
        try {
            log.traceIn( "("+this.collectionDatabase.collectionName()+")", "onCreated()" );

            await super.onCreated();

            await HealthguardLocationManager.getInstance().handleHealthguardLocationCreated( this );
    
            log.traceOut( "("+this.collectionDatabase.collectionName()+")", "onCreated()" );
  
        } catch( error ) {
            
            log.warn( "("+this.collectionDatabase.collectionName()+")", "onCreated()", "Error handling created notification", error );
  
            throw new Error( (error as any).message );
        }
    }

    async onUpdated() : Promise<void> {
        try {
            //log.traceIn( "onUpdated()" );

            await super.onUpdated();

            await HealthguardLocationManager.getInstance().handleHealthguardLocationUpdated( this );
    
            //log.traceOut( "onUpdated()" );
  
        } catch( error ) {
            
            log.warn( "onUpdated()", "Error handling updated notification", error );
  
            throw new Error( (error as any).message );
        }    
    }

    async onDeleted() : Promise<void> {

        try {
            //log.traceIn( "onDeleted()" );

            await super.onDeleted();
    
            //log.traceOut( "onDeleted()" );
  
        } catch( error ) {
            
            log.warn( "onDeleted()", "Error handling updated notification", error );
  
            throw new Error( (error as any).message );
        }  
    }

    readonly emergencyContactName : TextProperty;

    readonly emergencyContactNumber : PhoneNumberProperty;

    readonly risks: ReferencesProperty<Risk>;

    readonly measures: ReferencesProperty<Measure>;

    readonly readinessLevel: DefinitionProperty<ReadinessLevel>;

    readonly status : TextProperty;

    readonly gatherings: ReferencesProperty<Gathering>;

    readonly incidentRegistrations: ReferencesProperty<IncidentRegistration>;

    readonly quarantineRegistrations: ReferencesProperty<QuarantineRegistration>;

    readonly sickLeaveRegistrations: ReferencesProperty<SickLeaveRegistration>;

    readonly symptomRegistrations: ReferencesProperty<SymptomRegistration>;

    readonly testRegistrations: ReferencesProperty<TestRegistration>;

    readonly vaccineRegistrations: ReferencesProperty<VaccineRegistration>; 

}


