import { CollectionDatabase } from "../../../services/database/impl/core/collectionDatabase";
import { log } from "../../../services/database/framework/databaseService";
import { SickLeaveRegistrationIF } from "../../api/documents/sickLeaveRegistrationIF";
import { SickLeaveRegistrationDocument } from "../../api/registrationDocuments";
import { Registration } from "./registration";
import { TextProperty } from "../../../services/database/impl/properties/textProperty";

export class SickLeaveRegistration extends Registration implements SickLeaveRegistrationIF {

    constructor( sickLeaveRegistrationsCollection : CollectionDatabase<SickLeaveRegistration>, documentPath? : string  ) {

        super( SickLeaveRegistrationDocument, sickLeaveRegistrationsCollection, documentPath );

        try {
            this.hazard.required = true;

            this.physician = new TextProperty( this )

            //log.traceInOut( "constructor()", SickLeaveRegistrationsCollection );

        } catch( error ) {

            log.warn( "constructor()", "Error initializing sick leaves", error );
            
            throw new Error( (error as any).message );
        }
    }

    readonly physician: TextProperty; 

}
