import { log } from "./databaseService";
import { DatabaseDocument } from "./databaseDocument";
import { DatabaseObject } from "./databaseObject";
import { UserAccess } from "../../common/api/userAccess";
import { DatabasePropertyIF } from "../api/core/databasePropertyIF";
import { PropertyType } from "../api/definitions/propertyType";
import { Observable } from "../../common/impl/observable";
import { Observation } from "../../common/api/observation";
import { Monitor } from "../../common/api/monitor";
import { Factory } from "../../common/api/factory";

export abstract class DatabaseProperty<Value> extends Observable implements DatabasePropertyIF<Value> {

    constructor( type : PropertyType, parent : DatabaseObject ) {
        
        super();

        try {
            //log.traceIn( "constructor()", type, parent ); 
        
            this.type = type;

            this.parent = parent;

            //log.traceOut( "constructor()");

        } catch( error ) { 

            log.warn( "constructor()", "Error initializing property", error );
            
            throw new Error( (error as any).message );
        }
    }

    parentDocument() : DatabaseDocument {

        let parent = this.parent;

        while( parent != null && parent.parent != null ) {

            parent = parent.parent;
        }

        if( parent == null ) {
            throw new Error( "Property has no parent document ");
        }

        return parent as DatabaseDocument; 
    }

    key() : string {

        if( this._key != null ) {
            //log.traceInOut( "key()", this._key );
            return this._key;
        }
        //log.traceIn( "key()");

        const parentProperties = Object.values( this.parent );

        let key : string | undefined;

        Object.keys( this.parent ).forEach( (propertyKey, propertyKeyIndex ) => {

            if( parentProperties[propertyKeyIndex] === this ) {
                key = propertyKey;
            };
        });
        if( key == null ) {
            throw new Error( "Key not found in " + Object.keys( this.parent ) );
        }

        this._key = key!;

        //log.traceOut( "key()", this._key );
        return this._key
    }

    isChanged() : boolean {
        return this._previousValues.length > 0;
    }

    lastChange() : Value | undefined {
        return this._previousValues.length === 0 ? 
            undefined :
            this._previousValues[this._previousValues.length - 1];
    }

    setLastChange( lastChange : Value | undefined ) {
        this._previousValues.push( lastChange );
    }

    undoLastChange() : void {

        if( this._previousValues.length > 0 ) {

            const previousValue = this._previousValues.pop();

            const previousValues = Object.assign( [], this._previousValues );

            this.setValue( previousValue );

            this.clearChanges();
            Object.assign( this._previousValues, previousValues );
        }
    }

    clearChanges() : void {
        this._previousValues.length = 0;
    }

    protected previousValues() : Array<Value | undefined> {
        return Object.assign( [], this._previousValues );
    }

    protected setPreviousValues( previousValues : Array<Value | undefined> ) {
        this._previousValues.length = 0;
        Object.assign( this._previousValues, previousValues );
    }

    userAccess() : UserAccess {
        let access = this.parent.userAccess();
        if( !this.editable ) {
            access = new UserAccess( access.allowList, false, access.allowRead, false, false );
        }
        return access;
    }

    validate() : Error | undefined {
        
        if( this.required && this.value() == null ) {
            const error = new Error( "propertyValueMissing" );
            return error;
        }

        return undefined;
    }

    encrypted() : boolean {

        try {
            //log.traceIn( "encrypted()" );

            const clientEncryption = this.parent.ownerCollection()!.databaseManager.clientEncryption;

            const encrypted = 
                this.encrypt && clientEncryption && this.parent.ownerCollection()!.encrypted

            //log.traceOut( "encrypted()", {encrypted});
            return encrypted;

        } catch( error ) {

            log.warn( "encrypted()", "Error reading encrypted status", error );
            
            throw new Error("Error reading encryption status: " + (error as any).message );
        }
    }

    copyValueFrom( other : DatabaseProperty<Value> ) : void {

        if( this === other ) {
            return;
        }

        if( other._encryptedData != null ) {

            this._encryptedData = other._encryptedData;
        }
        else {
            this._encryptedData = undefined;

            this.setValue( other.value( true ) );
        }
    }

    copyStatesFrom( other : DatabaseProperty<Value>  ) : void {

        if( this === other ) {
            return;
        }

        this.setPreviousValues( other._previousValues );

        this.editable = other.editable;
        this.encrypt = other.encrypt;
        this.required = other.required;
        this.error = other.error;
        this.hidden = other.hidden;
    }


    protected isEncryptedData( data : any ) : boolean {

        return Factory.get().securityService.symmetricCipher.isEncrypted( data );
    }

    protected encryptData( data : any ) : string | undefined {

        try {
            if (this._encryptedData != null) {
                return this._encryptedData;
            }

            return Factory.get().securityService.symmetricCipher.encrypt(data);

        } catch (error) {

            log.warn("monitor()", "Error encrypting data ", error);

            return undefined;
        }
    }

    protected decryptData(): void {

        try {

            if (this._encryptedData == null) {
                return;
            }

            const data = {} as any;

            data[this.key()] = Factory.get().securityService.symmetricCipher.decrypt(this._encryptedData);

            this.fromData(data);

            delete this._encryptedData;

        } catch (error) {

            log.warn("monitor()", "Error decrypting data ", error);

            throw new Error( (error as any).message );

        }

    }

    protected setEncryptedData( encryptedData : any ) {
        this._encryptedData = encryptedData;
    }


    protected encryptedData() {
        return this._encryptedData;
    }

    async onCreate() : Promise<void> {

        try {
            if( this.encrypted() ) {
                await Factory.get().securityService.updateCurrentKeys( this.parentDocument() );
            }

        } catch (error) {
            log.warn("onRead()", "Error updating keys", error);
        }
    }

    async onUpdate() : Promise<void> {

        try {
            if( this.encrypted() ) {
                await Factory.get().securityService.updateCurrentKeys( this.parentDocument() );
            }

        } catch (error) {
            log.warn("onRead()", "Error updating keys", error);
        }
    }

    async onDelete() : Promise<void> {

        try {
            if( this.encrypted() ) {
                await Factory.get().securityService.updateCurrentKeys( this.parentDocument() );
            }

        } catch (error) {
            log.warn("onRead()", "Error updating keys", error);
        }
    }

    async onRead() : Promise<void> {

        try {
            if( this.encrypted() ) {
                await Factory.get().securityService.updateCurrentKeys( this.parentDocument() );
            }

        } catch (error) {
            log.warn("onRead()", "Error updating keys", error);
        }
    }
    
    async onCreated() : Promise<void> {
        this.clearChanges();    
    }
    
    async onUpdated() : Promise<void> {
        this.clearChanges();
    }

    defaultValue( ) : Value | undefined {
        throw new Error( "Override");
    }

    setDefaultValue( defaultValue : Value | undefined) : void {
        throw new Error( "Override");
    }


    protected async monitor( newMonitor : Monitor ) : Promise<void> {
        
        try {
            if (newMonitor.onNotify == null) {
                return;
            }

            await newMonitor.onNotify(this,
                Observation.Create,
                this.key(),
                this.value() );

        } catch( error ) {
            log.warn( "monitor()", "Error monitoring property", error );
            
            throw new Error( (error as any).message );
        }
    }

    protected async release( observationFilter? : Observation[], objectIdsFilter? : string[] ) : Promise<void> {} 

    
    async onDeleted() : Promise<void> {}
    
    abstract value( ignoreDefault? : boolean ) : any | undefined;

    abstract setValue( value : any | undefined) : void;

    abstract compareTo( other : DatabaseProperty<Value>, translator? : ( value? : string ) => string | undefined ) : number;

    abstract compareValue( value : any | undefined, translator? : ( value? : string ) => string | undefined ) : number;

    abstract includes( other : DatabaseProperty<Value>, matchAny? : boolean, translator? : ( value? : string ) => string | undefined ) : boolean;

    abstract includesValue( value : any | undefined, matchAny? : boolean, translator? : ( value? : string ) => string | undefined ) : boolean;

    abstract fromData( documentData : any ) : void;

    abstract toData( documentData : any, force? : boolean ) : Promise<void>;

    readonly type : PropertyType;

    readonly parent : DatabaseObject;

    required : boolean = false;

    encrypt : boolean = true;

    editable : boolean = true;

    trackChanges: boolean = true;

    hidden: boolean = false;

    error? : Error;

    private readonly _previousValues : Array<Value | undefined> = [];

    private _key : string | undefined;

    private _encryptedData? : string;

}
 