
import { log } from "../framework/messagingService";

export class MessageLink {

  constructor( title : string, url : string ) {

    this.title = title;

    this.url = url;
    
  }

  html(): string {

    try {

        let html = "<a href=" + this.url + ">" + this.title + "</a>";

        return html;

    } catch (error) {
        log.warn("html()", "Error converting action to html", error);

        throw new Error( (error as any).message );
      }
}

text(): string {

    try {

        const text = this.title + ": " + this.url;

        return text;

    } catch (error) {
        log.warn("html()", "Error converting action to text", error);

        throw new Error( (error as any).message );
      }
}

  readonly title: string;

  readonly url: string;

}