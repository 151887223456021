


export const Collections = {

    Alerts                          : "alerts",

    Categories                      : "categories",

    Changes                         : "changes",

    Companies                       : "companies",

    Devices                         : "devices",

    Keys                            : "keys",

    Locations                       : "locations",

    Messages                        : "messages", 

    Projects                        : "projects",

    Units                           : "units", 

    Residences                      : "residences",

    Subscriptions                   : "subscriptions",

    Terminals                       : "terminals",

    Users                           : "users",
}

export type CollectionName = keyof (typeof Collections);

export const CollectionNames = Object.values( Collections );

export const AlertsCollection = Collections.Alerts as CollectionName; 

export const CategoriesCollection = Collections.Categories as CollectionName;

export const ChangesCollection = Collections.Changes as CollectionName;

export const CompaniesCollection = Collections.Companies as CollectionName;

export const DevicesCollection = Collections.Devices as CollectionName;

export const KeysCollection = Collections.Keys as CollectionName;

export const LocationsCollection = Collections.Locations as CollectionName;

export const MessagesCollection = Collections.Messages as CollectionName;

export const ProjectsCollection = Collections.Projects as CollectionName;

export const UnitsCollection = Collections.Units as CollectionName;

export const ResidencesCollection = Collections.Residences as CollectionName;

export const SubscriptionsCollection = Collections.Subscriptions as CollectionName;

export const TerminalsCollection = Collections.Terminals as CollectionName;

export const UsersCollection = Collections.Users as CollectionName;



