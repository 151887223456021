
import { HealthguardCategoryIF } from "../../api/documents/healthguardCategoryIF";
import { log } from "../../../services/database/framework/databaseService";
import { CollectionDatabase } from "../../../services/database/impl/core/collectionDatabase";
import { IncidentRegistration } from "./incidentRegistration";
import { QuarantineRegistration } from "./quarantineRegistration";
import { SickLeaveRegistration } from "./sickLeaveRegistration";
import { SymptomRegistration } from "./symptomRegistration";
import { TestRegistration } from "./testRegistration";
import { VaccineRegistration } from "./vaccineRegistration";
import { Measure } from "./measure";
import { Category } from "../../../services/database/impl/documents/category";
import { HealthguardCategoryDocument } from "../../api/healthguardDocuments";
import { ReferencesProperty } from "../../../services/database/impl/properties/referencesProperty";
import { HealthguardCompany } from "./healthguardCompany";
import { Risk } from "./risk";

export class HealthguardCategory extends Category implements HealthguardCategoryIF {

    constructor( categoriesCollection : CollectionDatabase<HealthguardCategory>, documentPath? : string ) {

        super( HealthguardCategoryDocument, categoriesCollection, documentPath ); 

        try {
            const company = this.company.emptyDocument() as HealthguardCompany;

            this.risks = new ReferencesProperty<Risk>( this, 
                company != null ? 
                    () => [company.risks.collectionGroup()] : 
                    undefined,
                "categories" );

            this.measures = new ReferencesProperty<Measure>( this, 
                company != null ? 
                    () => [company.measures.collectionGroup()] : 
                    undefined,
                "categories" );

            this.incidentRegistrations = new ReferencesProperty<IncidentRegistration>( this, 
                company != null ? 
                    () => [company.incidentRegistrations.collectionGroup()] : 
                    undefined, 
                "categories" );
    
            
            this.quarantineRegistrations = new ReferencesProperty<QuarantineRegistration>( this, 
                company != null ? 
                    () => [company.quarantineRegistrations.collectionGroup()] : 
                    undefined, 
                "categories" );

    
            this.sickLeaveRegistrations = new ReferencesProperty<SickLeaveRegistration>( this, 
                company != null ? 
                    () => [company.sickLeaveRegistrations.collectionGroup()] : 
                    undefined, 
                "categories" );
    
  
            this.symptomRegistrations = new ReferencesProperty<SymptomRegistration>( this, 
                company != null ? 
                    () => [company.symptomRegistrations.collectionGroup()] : 
                    undefined, 
                "categories" );
    
            this.testRegistrations = new ReferencesProperty<TestRegistration>( this, 
                company != null ? 
                    () => [company.testRegistrations.collectionGroup()] : 
                    undefined, 
                "categories" );
        
            this.vaccineRegistrations = new ReferencesProperty<VaccineRegistration>( this, 
                company != null ? 
                    () => [company.vaccineRegistrations.collectionGroup()] : 
                    undefined, 
                "categories" );
            
            //log.traceInOut( "constructor()", UnitsCollection );

        } catch( error ) {

            log.warn( "constructor()", "Error initializing unit", error );
            
            throw new Error( (error as any).message );
        }
    }

    readonly risks: ReferencesProperty<Risk>;

    readonly measures: ReferencesProperty<Measure>;

    readonly incidentRegistrations: ReferencesProperty<IncidentRegistration>;

    readonly quarantineRegistrations: ReferencesProperty<QuarantineRegistration>;

    readonly sickLeaveRegistrations: ReferencesProperty<SickLeaveRegistration>;

    readonly symptomRegistrations: ReferencesProperty<SymptomRegistration>;

    readonly testRegistrations: ReferencesProperty<TestRegistration>;

    readonly vaccineRegistrations: ReferencesProperty<VaccineRegistration>;

}


