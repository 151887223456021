import { DatabaseProperty } from "./databaseProperty";
import { UserAccess } from "../../common/api/userAccess";
import { DatabaseObjectIF } from "../api/core/databaseObjectIF";
import { PropertyType, PropertyTypes } from "../api/definitions/propertyType";
import { PropertiesSelector } from "../api/core/propertiesSelector";
import { DatabaseDocument } from "./databaseDocument";
import { CollectionDatabase } from "../impl/core/collectionDatabase";
import { Observable } from "../../common/impl/observable";
import { SubdocumentProperty } from "../impl/properties/subdocumentProperty";
import { DatabaseSubdocument } from "./databaseSubdocument";
import { CollectionGroupDatabase } from "../impl/core/collectionGroupDatabase";
import { CollectionProperty } from "../impl/properties/collectionProperty";
import { ReferenceHandle } from "..";
import { log } from "./databaseService";
import { Database } from "../impl/core/database";


export abstract class DatabaseObject extends Observable implements DatabaseObjectIF {

  constructor( parent? : DatabaseObject ) {

    super(); 

    try {

      this._isLoaded = false;

      this.parent = parent;

      //log.traceInOut( "constructor()" );

    } catch (error) {

      log.warn("constructor()", "Error creating database document", error);

      throw new Error( (error as any).message );
    }
  }


  fromData(data: any): void {
    //log.traceIn("fromData()", data);

    try {

      if( data == null ) {
        //log.traceOut("fromData()", "no data");
        return;
      }

      const properties = Object.values(this);

      if( properties == null ) {
        //log.traceOut("fromData()", "no properties");
        return;
      }

      Object.keys(this).forEach((key, keyIndex) => {

        const property = properties[keyIndex];

        if ( property instanceof DatabaseProperty) {

          (property as DatabaseProperty<any>).fromData(data);
        }
      });

      //log.traceOut("fromData()", this);

    } catch (error) {

      log.warn("fromData()", "Error reading document", error);

      throw new Error( (error as any).message );
    }
  }

  async toData( force? : boolean ): Promise<any> {
    //log.traceIn("toData()", {force})
    try {

      let data: any = {};

      const properties = Object.values(this);

      for( const property of properties ) {

        if (property instanceof DatabaseProperty) {

          const databaseProperty = property as DatabaseProperty<any>;

          await databaseProperty.toData( data, force ); 
        }
      }

      //log.traceOut("toData()", data)
      return data;

    } catch (error) {
      log.warn("toData()", "Error writing database object to data", error);

      throw new Error( (error as any).message );
    }
  }

  async toJson() : Promise<string> {

    //log.traceIn("toJson()");

    try {
      const data = await this.toData( true );

      const json = JSON.stringify( data );

      //log.traceOut("toJson()", json );
      return json;

    } catch (error) {

      log.warn("toData()", "Error writing database object to json string", error);

            throw new Error( (error as any).message );
    }
  }

  fromJson( json : string ) : void {

    //log.traceIn("fromJson()", json );

    try {
      const data = JSON.parse( json );

      this.fromData( data );

      //log.traceOut("fromJson()", json );
    } catch (error) {
      log.warn("toData()", "Error reading database object from json string", error);

            throw new Error( (error as any).message );
    }
  }

  property(key: string): DatabaseProperty<any> | undefined {

    const property = Object(this)[key];

    if( property?.type != null && property instanceof DatabaseProperty) {
      return property as DatabaseProperty<any>;
    }

    return undefined;  
  }

  properties(propertiesSelector?: PropertiesSelector): Map<string, DatabaseProperty<any>> {

    //log.traceIn("properties()", propertiesSelector);

    try {

      let result = new Map<string, DatabaseProperty<any>>();

      if( propertiesSelector?.includePropertyKeys != null ) {

        for( const includePropertyKey of propertiesSelector.includePropertyKeys ) {

          // This ensures order of includePropertyKeys remains intact

          const property = this.property( includePropertyKey );

          if( property != null && !result.has( includePropertyKey ) ) {

            result.set( includePropertyKey, property );
          }
        }
      }

      for( const propertyKey in Object(this) ) {

        if (result.has(propertyKey)) {
          continue;
        }

        const property = this.property( propertyKey );

        if( property == null ) {
          continue;
        }

        if( propertiesSelector?.includePropertyKeys != null &&
            !propertiesSelector.includePropertyKeys.includes(propertyKey)) {

          continue;
        }

        if( propertiesSelector?.excludePropertyKeys != null &&
            propertiesSelector.excludePropertyKeys.includes(propertyKey)) {

          continue;
        }

        if( propertiesSelector?.includePropertyTypes != null &&
            !propertiesSelector.includePropertyTypes.includes(property.type)) {

          continue
        }

        if( propertiesSelector?.excludePropertyTypes != null &&
            propertiesSelector.excludePropertyTypes.includes(property.type)) {

          continue;
        }
        
        result.set(propertyKey, property);
      }

      //log.traceOut("properties()", result);
      return result;

    } catch (error) {
      log.warn("Error reading properties for database object", error);

      throw new Error( (error as any).message );
    }
  }

  copyFrom( other : DatabaseObject) : void {

    //log.traceIn( "copyFrom()", other, includeCollections );

    try {

      this.copyProperties( other );

      this._isLoaded = other._isLoaded

      //log.traceOut( "copyFrom()", this );
      return;

    } catch( error ) {
        
        log.warn( "copyFrom()", "Error copying database object", error );

        throw new Error( (error as any).message );
      }
  }

  copyProperties( other : DatabaseObject, propertiesSelector?: PropertiesSelector ) : void {

    //log.traceIn("copyProperties()");

    try {

      const excludePropertyKeys = [
        "id",
        "archived",
        "archivedAt"];

      const excludePropertyTypes = [
        PropertyTypes.Collection as PropertyType
      ];

      const augmentedPropertiesSelector = propertiesSelector != null ? propertiesSelector : {} as PropertiesSelector;

      augmentedPropertiesSelector.excludePropertyKeys =  augmentedPropertiesSelector.excludePropertyKeys != null ? 
        augmentedPropertiesSelector.excludePropertyKeys.concat(excludePropertyKeys) : excludePropertyKeys;

      augmentedPropertiesSelector.excludePropertyTypes =  augmentedPropertiesSelector.excludePropertyTypes != null ? 
        augmentedPropertiesSelector.excludePropertyTypes.concat(excludePropertyTypes) : excludePropertyTypes;
     

      const properties = this.properties( augmentedPropertiesSelector );

      for( const propertykeyValuePair of properties ) {

        const propertyKey = propertykeyValuePair[0];

        const property = propertykeyValuePair[1];

        const otherProperty = other.property( propertyKey )!;

        //log.debug("copyProperties()", property.key(), property.changed, property.trackChanges );

        if( property.type === PropertyTypes.Subdocument ) {

          const subdocumentProperty = property as SubdocumentProperty<DatabaseSubdocument>;

          const otherSubdocumentProperty = otherProperty as SubdocumentProperty<DatabaseSubdocument>;

          subdocumentProperty.subdocument().copyProperties( otherSubdocumentProperty.subdocument() );

        }  
        else if( property.type === PropertyTypes.Tenant ||
                 property.type === PropertyTypes.Owner ) {

          if( property.compareTo( otherProperty ) === 0 ) { // only copy if equal paths

            property.copyValueFrom( otherProperty );

            property.copyStatesFrom( otherProperty );

          }
        }
        else {
          property.copyValueFrom( otherProperty ); 

          property.copyStatesFrom( otherProperty );
        }
      }

      //log.traceOut("copyProperties()");

    } catch (error) {
      log.warn("Error copying properties for database object", error);

      throw new Error( (error as any).message );
    }
  }


  validate( propertiesSelector?: PropertiesSelector, markMissingProperties? : boolean ): Map<string, Error> {

    //log.traceIn("validate()");

    try {

      let result = new Map<string, Error>();

      const properties = this.properties( propertiesSelector );

      properties.forEach( property => {

        const error = property.validate();

        if( error != null ) {

          if( !!markMissingProperties ) {

            property.error = error;
          }

          result.set( property.key(), error );
        }
        else if( !!markMissingProperties ) {
          delete property.error;
        }
      });

      //log.traceOut("validate()", result);
      return result;

    } catch (error) {
      log.warn("Error validating properties for database object", error);

      throw new Error( (error as any).message );
    }
  }

  isLoaded() : boolean {
    return this._isLoaded;
  }


  isComplete( propertiesSelector?: PropertiesSelector): boolean {

    const validation = this.validate( propertiesSelector, false );

    log.traceInOut( "isComplete()", {validation});

    return validation.size === 0;
  }


  isChanged( propertiesSelector? : PropertiesSelector ) : boolean {

    log.traceIn("isChanged()");

    const changedProperties = this.changedProperties( propertiesSelector );

    const changed = changedProperties.size > 0;

    log.traceOut("isChanged()", changed );
    return changed;
  }


  changedProperties( propertiesSelector? : PropertiesSelector ) : Map<string,DatabaseProperty<any>> {

    //log.traceIn("changedProperties()");

    try {

      let changedProperties = new Map<string,DatabaseProperty<any>>();

      const properties = this.properties( propertiesSelector );

      for( const propertykeyValuePair of properties ) {

        const property = propertykeyValuePair[1];

        //log.debug("changedProperties()", property.key(), property.changed, property.trackChanges );

        if( property.type === PropertyTypes.Subdocument ) {

          const subdocumentProperty = property as SubdocumentProperty<DatabaseSubdocument>;

          subdocumentProperty.subdocument().changedProperties( propertiesSelector ).forEach( 
            ( changedSubdocumentProperty, changedSubdocumentPropertyKey )  => {
            changedProperties.set( subdocumentProperty.key() + "." + changedSubdocumentPropertyKey, changedSubdocumentProperty );
          
            log.debug("changedProperties()", changedSubdocumentPropertyKey );

          })
        }  
        else if( !!property.trackChanges && !!property.isChanged()) {
          changedProperties.set( property.key(), property );

          log.debug("changedProperties()", property.key() );
        }
      }

      //log.traceOut("changedProperties()", Array.from( changedProperties.keys() ) );
      return changedProperties; 

    } catch (error) {
      log.warn("Error checking changed properties for database object", error);

      throw new Error( (error as any).message );
    }
  }

  clearChanged( propertiesSelector?: PropertiesSelector ) : void{

    //log.traceIn("clearChanged()");

    try {

      const properties = this.properties( propertiesSelector );

      properties.forEach( property => {

        if( property.type === PropertyTypes.Subdocument ) {

          const subdocumentProperty = property as SubdocumentProperty<DatabaseSubdocument>;

          subdocumentProperty.subdocument().clearChanged( propertiesSelector ); 
        }

        property.clearChanges();
      });

      //log.traceOut("clearChanged()", result);

    } catch (error) {
      log.warn("Error clearing changed properties for database object", error);

      throw new Error( (error as any).message );
    }
  }

  propertyCount(propertiesSelector?: PropertiesSelector): number {

    return this.properties(propertiesSelector).size;
  }

  changesPropertiesSelector() : PropertiesSelector | undefined {

    // Override as required
    return undefined;
  }

  setUserAccess( userAccess : UserAccess | undefined ) : void {

      //log.traceIn("setUserAccess()");

      try { 
        this._userAccess = userAccess;

        const properties = this.properties();

        properties.forEach( property => {
  
          if( property.type === PropertyTypes.Subdocument ) {
  
              const subdocumentProperty = property as SubdocumentProperty<DatabaseSubdocument>;
  
              subdocumentProperty.subdocument().setUserAccess( userAccess ); 
          }
  
          if( userAccess != null && userAccess.readOnly() ) {

            property.editable = false;
          }

        });

        //log.traceOut("clearChanged()", result);
  
      } catch (error) {
        log.warn("Error clearing changed properties for database object", error);
  
        throw new Error( (error as any).message );
      }
  }

  async onCreate(): Promise<void> {
    try {
      //log.traceIn( "onCreate()" );

      for (const property of this.properties().values()) {

        if (property.type === PropertyTypes.Subdocument) {

          const subdocumentProperty = property as SubdocumentProperty<DatabaseSubdocument>;

          await subdocumentProperty.subdocument().onCreate();
        }
        else {
          await property.onCreate();
        }
      }

      //log.traceOut( "onCreate()" );

    } catch (error) {

      log.warn("onCreate()", "Error handling create notification", error);

      throw new Error( (error as any).message );
    }
  }

  async onUpdate(): Promise<void> {
    try {
      //log.traceIn( "onUpdate()" );

      for (const property of this.properties().values()) {

        if (property.type === PropertyTypes.Subdocument) {

          const subdocumentProperty = property as SubdocumentProperty<DatabaseSubdocument>;

          await subdocumentProperty.subdocument().onUpdate();
        }
        else {
          await property.onUpdate();
        }
      }

      //log.traceOut( "onUpdate()" );

    } catch (error) {

      log.warn("onUpdate()", "Error handling update notification", error);

      throw new Error( (error as any).message );
    }
  }

  async onDelete(): Promise<void> {

    try {
      //log.traceIn( "onDelete()" );

      for (const property of this.properties().values()) {

        if (property.type === PropertyTypes.Subdocument) {

          const subdocumentProperty = property as SubdocumentProperty<DatabaseSubdocument>;

          await subdocumentProperty.subdocument().onDelete();
        }
        else {
          await property.onDelete();
        }
      }

      //log.traceOut( "onDelete()" );

    } catch (error) {

      log.warn("onDelete()", "Error handling delete notification", error);

      throw new Error( (error as any).message );
    }
  }


  async onRead(): Promise<void> {

    try {
      //log.traceIn( "onRead()" );

      for (const property of this.properties().values()) {

        if (property.type === PropertyTypes.Subdocument) {

          const subdocumentProperty = property as SubdocumentProperty<DatabaseSubdocument>;

          await subdocumentProperty.subdocument().onRead();
        }
        else {
          await property.onRead();
        }
      }

      this._isLoaded = true;

      //log.traceOut( "onRead()" );

    } catch (error) {

      log.warn("onRead()", "Error handling read notification", error);

      throw new Error( (error as any).message );
    }
  }

  async onCreated(): Promise<void> {
    try {
      //log.traceIn( "onCreated()" );

      for (const property of this.properties().values()) {

        if (property.type === PropertyTypes.Subdocument) {

          const subdocumentProperty = property as SubdocumentProperty<DatabaseSubdocument>;

          await subdocumentProperty.subdocument().onCreated();
        }
        else {
          await property.onCreated();
        }
      }

      this._isLoaded = true;

      //log.traceOut( "onCreated()" );

    } catch (error) {

      log.warn("onCreated()", "Error handling created notification", error);

      throw new Error( (error as any).message );
    }
  }

  async onUpdated(): Promise<void> {
    try {
      //log.traceIn( "onUpdated()" );

      for (const property of this.properties().values()) {

        if (property.type === PropertyTypes.Subdocument) {

          const subdocumentProperty = property as SubdocumentProperty<DatabaseSubdocument>;

          await subdocumentProperty.subdocument().onUpdated();
        }
        else {
          await property.onUpdated();
        }
      }

      this._isLoaded = true;

      //log.traceOut( "onUpdated()" );

    } catch (error) {

      log.warn("onUpdated()", "Error handling updated notification", error);

      throw new Error( (error as any).message );
    }
  }

  async onDeleted(): Promise<void> {

    try {
      //log.traceIn( "onDeleted()" );

      for (const property of this.properties().values()) {

        if (property.type === PropertyTypes.Subdocument) {

          const subdocumentProperty = property as SubdocumentProperty<DatabaseSubdocument>;

          await subdocumentProperty.subdocument().onDeleted();
        }
        else {
          await property.onDeleted();
        }
      }
      //log.traceOut( "onDeleted()" );

    } catch (error) {

      log.warn("onDeleted()", "Error handling deleted notification", error);

      throw new Error( (error as any).message );
    }
  }

  abstract documentId() : string | undefined;

  abstract documentDatabasePath( includeDocumentName? : boolean ) : string | undefined;

  abstract documentReferenceHandle() : ReferenceHandle<DatabaseDocument> | undefined;

  abstract ownerDocumentId(collectionName?: string): string | undefined;

  abstract ownerDocumentIds(collectionName?: string): string[] | undefined; 

  abstract symbolicOwnerDocumentIds(collectionName?: string): string[] | undefined; 

  abstract ownerDocumentPath(collectionName?: string): string | undefined;

  abstract ownerDocumentPaths(collectionName?: string): string[] | undefined;

  abstract emptyOwnerDocument(collectionName?: string): DatabaseDocument | undefined;

  abstract emptyOwnerDocuments(collectionName?: string): DatabaseDocument[] | undefined;

  abstract ownerDocument(collectionName?: string): Promise<DatabaseDocument | undefined>;

  abstract ownerDocuments(collectionName?: string): Promise<DatabaseDocument[] | undefined>;

  abstract ownerCollection(collectionName?: string): CollectionDatabase<DatabaseDocument> | undefined;

  abstract ownerCollections(collectionName?: string): CollectionDatabase<DatabaseDocument>[] | undefined;

  abstract ownerCollectionGroup(collectionName?: string): CollectionGroupDatabase<DatabaseDocument> | undefined;

  abstract ownerCollectionGroups(collectionName?: string): CollectionGroupDatabase<DatabaseDocument>[] | undefined;

  abstract parentDatabases( collectionName : string, 
    options? : { 
        nearestIsCollectionGroup? : boolean, 
        includeRootCollection? : boolean }   ) : Database<DatabaseDocument>[] | undefined;

  abstract parentCollection(collectionName: string): CollectionDatabase<DatabaseDocument> | undefined;

  abstract parentCollections(collectionName: string): CollectionDatabase<DatabaseDocument>[] | undefined;

  abstract parentCollectionGroup(collectionName: string): CollectionGroupDatabase<DatabaseDocument> | undefined;

  abstract parentCollectionGroups(collectionName: string): CollectionGroupDatabase<DatabaseDocument>[] | undefined;

  abstract parentCollectionProperty(collectionName: string): CollectionProperty<DatabaseDocument> | undefined;

  abstract parentCollectionProperties(collectionName: string): CollectionProperty<DatabaseDocument>[] | undefined;

  abstract indexKey(): string;  

  abstract documentName(): string;   

  abstract userAccess(): UserAccess;

  //abstract backReferenceKey( propertyKey : string ) : string;

  readonly parent? : DatabaseObject;

  protected _userAccess? : UserAccess;

  private _isLoaded : boolean;

}
