
import { PropertyType, PropertyTypes } from "../../api/definitions/propertyType"; 
import { LinksPropertyIF } from "../../api/properties/linksPropertyIF ";
import { DatabaseObject } from "../../framework/databaseObject";
import { MapProperty } from "./mapProperty";

export class LinksProperty extends MapProperty<string> implements LinksPropertyIF{

    constructor( parent : DatabaseObject ) {

        super( PropertyTypes.Links as PropertyType, parent ); 
    }

    setLink( title : string, url : string ): void {
        super.setEntry( title, url );
    }

    removeLink( title : string ): boolean {
        return super.removeEntry( title ); 
    }

}