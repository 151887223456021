import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import { Button, createStyles, Dialog, DialogActions, DialogContent, Grid, Theme, withStyles, WithStyles } from '@material-ui/core';


import { log } from '../app/app';
import { errorDialog } from './simpleDialog';
import { confirmAlert } from 'react-confirm-alert';


import { AppContext } from '../app/appContext';
import { DatabaseDocumentIF } from '../../services/database/api/core/databaseDocumentIF';
import { DocumentsList } from './documentsList';
import { ReferenceHandle } from '../../services/database/api/core/referenceHandle';

export const ButtonKeyConfirm = "confirm"; 
export const ButtonKeyPrivacyPolicy = "privacyPolicy"; 

export const ButtonKeyCancel = "cancel";

const styles = (theme: Theme) => createStyles({
  dialog: {
  },
  content: {
    minHeight: "40vh", 
    maxHeight: "80vh", 
    height: "100%",
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  buttons: {
    display: "flex", 
    justifyContent:'flex-end', 
    paddingRight: theme.spacing(6)
  }
});

export const selectDocumentDialog = ( props: { 

  placeholder? : string,

  collectionName: string,

  handles: Map<string,ReferenceHandle<DatabaseDocumentIF>>, 

  newDocumentHandle?: ReferenceHandle<DatabaseDocumentIF>,

  hideEmptyFilterOptions? : boolean,

  
} ) : Promise<ReferenceHandle<DatabaseDocumentIF> | undefined> => {

  return new Promise<ReferenceHandle<DatabaseDocumentIF> | undefined>((resolve, reject) => {

    log.traceIn("selectDocumentDialog()"); 

    try {

      confirmAlert({ 

        customUI: ({ onClose }) => {

          const onComplete = ( handle : ReferenceHandle<DatabaseDocumentIF> | undefined) => {

            log.traceIn("onComplete()");

            onClose();

            log.traceOut("onComplete()");
            resolve( handle );
          }

          return (
            <ModifiedSelectDocumentDialog 
              placeholder={props.placeholder}
              collectionName={props.collectionName}
              handles={props.handles}
              hideEmptyFilterOptions={props.hideEmptyFilterOptions}
              newDocumentHandle={props.newDocumentHandle} 
              onComplete={onComplete}
              >
            </ModifiedSelectDocumentDialog>
          );
        }
      });
      log.traceOut("selectDocumentDialog()"); 

    } catch (error) {

      log.warn("confirm()", error);

      reject();
    }
  });
}



interface SelectDocumentDialogProps extends WithStyles<typeof styles>, WithTranslation { 

  placeholder? : string,

  collectionName: string,

  handles: Map<string,ReferenceHandle<DatabaseDocumentIF>>,

  newDocumentHandle?: ReferenceHandle<DatabaseDocumentIF>,

  hideEmptyFilterOptions? : boolean,

  onComplete : ( handle : ReferenceHandle<DatabaseDocumentIF> | undefined ) => void
}


interface SelectDocumentDialogState {

  open: boolean
}

class SelectDocumentDialog extends React.Component<SelectDocumentDialogProps, SelectDocumentDialogState> {

  constructor(props: SelectDocumentDialogProps) {

    super(props);

    this.state = {

      open: false

    } as SelectDocumentDialogState;

    //log.traceInOut( "constructor()" );
  }

  async componentDidMount() {

    try {
      log.traceIn("componentDidMount()");

      this.setState({
        open: true
      });

      log.traceOut("componentDidMount()");

    } catch( error ) {
      
      log.warn( "componentDidMount()", error );

      await errorDialog( error);

      log.traceOut( "componentDidMount()", error );
    }
  }

  async componentWillUnmount() {
    log.traceIn("componentWillUnmount()");

    try {

      log.traceOut("componentWillUnmount()");

    } catch (error) {
      log.warn("componentWillUnmount()", "Error unmounting collection list", error);

      log.traceOut("componentWillUnmount()", "error");
    }
  }

  render() {

    //log.traceInOut("render()", "open: ", this.state.open )

    const { classes } = this.props;

    const onSelectHandle = ( handle?: ReferenceHandle<DatabaseDocumentIF> ) => {

      this.props.onComplete(handle);

      this.setState({
        open: false
      });
    }

    const onNewDocument = () => {

      log.traceIn("onNewDocument()" )

      this.props.onComplete( this.props.newDocumentHandle );

      this.setState({
        open: false
      });

      log.traceOut("onNewDocument()" )

    }

    return (
      <>
        <Dialog
          className={classes.dialog}
          fullWidth={true}
          maxWidth="sm" 
          open={this.state.open}
          onClose={!!this.state.open ? () => this.props.onComplete( undefined ) : undefined }  
        >
          <DialogContent className={classes.content}>
            <DocumentsList 
              collectionName={this.props.collectionName}
              handles={this.props.handles}
              allowSelect={false}
              allowAdd={this.props.newDocumentHandle != null}
              allowRemove={false}
              placeholder={this.props.placeholder}
              hideEmptyFilterOptions={this.props.hideEmptyFilterOptions}
              onAddDocument={onNewDocument}
              onSelectHandle={onSelectHandle} 
            />
          </DialogContent>
          <DialogActions className={classes.buttons}>
            <Grid container justifyContent="flex-end" >
              <Grid item>
                <Button autoFocus onClick={() => this.props.onComplete( undefined )} color="primary"> 
                  {this.props.t(ButtonKeyCancel)}
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

SelectDocumentDialog.contextType = AppContext;

const ModifiedSelectDocumentDialog = withTranslation()(withStyles(styles)(SelectDocumentDialog)); 

export default ModifiedSelectDocumentDialog;

