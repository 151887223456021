import { DatabaseObject } from "../../framework/databaseObject";
import { DatabaseProperty } from "../../framework/databaseProperty";
import { PropertyType, PropertyTypes } from "../../api/definitions/propertyType"; 
import { DatePropertyIF } from "../../api/properties/datePropertyIF";
import { DatabaseConverter } from "../../framework/databaseConverter";

export class DateProperty extends DatabaseProperty<Date> implements DatePropertyIF {

    constructor( parent : DatabaseObject, defaultDate? : Date ) {
        super( PropertyTypes.Date as PropertyType, parent ); 

        this._defaultDate = defaultDate;
    } 

    value( ignoreDefault? : boolean ) {
        return this.date( ignoreDefault );
    }

    setValue( date : Date | undefined ) : void {
        this.setDate( date );
    }

    defaultValue() {
        return this.defaultDate();
    }

    setDefaultValue( defaultDate : Date | undefined ) : void {
        this.setDefaultDate( defaultDate ); 
    }

    date( ignoreDefault? : boolean ) : Date | undefined {

        this.decryptData();

        if( this._date != null ) {
            return this._date;
        }
        return !!ignoreDefault ? undefined : this._defaultDate;
    }

    setDate( date : Date | undefined ): void {

        this.decryptData();

        if( this.compareValue( date ) !== 0 ) {

            this.setLastChange( this._date );

            this._date = date;

            delete this.error;
        }
    }

    defaultDate() : Date | undefined {

        return this._defaultDate;
    }

    setDefaultDate( defaultDate : Date | undefined ) : void {
        this._defaultDate = defaultDate; 
    }

    fromData( documentData: any ): void {

        if( this.isEncryptedData( documentData[this.key()] ) ) {

            this.setEncryptedData( documentData[this.key()] );
        }
        else {
            const data = documentData[this.key()];

            if( this._databaseConverter == null ) {

                this._databaseConverter = this.parent.ownerCollection()?.databaseManager.converter;
            }
            
            this._date = this._databaseConverter != null ? 
                this._databaseConverter.toDate( data ) : undefined;

        }
    }

    async toData( documentData: any, force? : boolean ) : Promise<void> {

        if( !!force ) {
            this.decryptData();
        }
        
        if( this.encrypted() && this.encryptedData() != null ) {
            
            documentData[this.key()] = this.encryptedData();
            return;
        }
        
        if( this._databaseConverter == null ) {

            this._databaseConverter = this.parent.ownerCollection()?.databaseManager.converter;
        }

        const convertedDate = this._date != null ? 
            this._databaseConverter!.fromDate( this._date ) : undefined;

        let data;

        if( this.encrypted() ) {
            data = this.encryptData( convertedDate )
        }
        else {
            data = convertedDate;
        }   
        
        if( data != null ) {
            documentData[this.key()] = data;
        }
    }


    compareTo( other : DateProperty ) : number {

       return this.compareValue( other.date() );
    }

    compareValue( date : Date | undefined ) : number {

        try {
            const thisDate = this.date();

            if( thisDate == null && date == null ) {
                return 0;
            }

            if( thisDate != null && date == null ) {
                return 1;
            }

            if( thisDate == null && date != null ) {
                return -1;
            }

            if( thisDate!.getTime() > date!.getTime() ) {
                return 1;
            }

            if( thisDate!.getTime() < date!.getTime() ) {
                return -1;
            }

            return 0;
            
        } catch( error ) {
            return 1;
        }
    }

    includes( other : DateProperty  ) : boolean {
        return this.includesValue( other.value() );
    }

    includesValue( value : Date | undefined ) : boolean {
        return this.compareValue( value ) === 0;
    }

    private _date : Date | undefined;

    private _defaultDate : Date | undefined;

    private _databaseConverter? : DatabaseConverter;

}