
import React from 'react';

import { Button, createStyles, Grid, makeStyles, Theme, Tooltip } from '@material-ui/core';
import { EmptyColor } from './colorMap';

import PlaceIcon from '@mui/icons-material/Place';

export const MarkerTypes = {
  Small: "small",
  Normal: "normal"
}

export type MarkerType = keyof (typeof MarkerTypes);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      opacity: 0.9,
      position: 'absolute',
      top: '50%',
      left: theme.spacing(1.5),
      transform: 'translate(' + -theme.spacing(3) +'px, -50%)' 
    },
    grid: {
    },
    normalMarker: {
      color: theme.palette.text.secondary,
      whiteSpace: 'nowrap',
      textTransform: 'none',
      paddingLeft: theme.spacing(1.25),
      paddingRight: theme.spacing(1) 
    },
    smallMarker: {
      color: theme.palette.text.secondary,
      minWidth: 0,
      paddingLeft: theme.spacing(1.25),
      paddingRight: theme.spacing(0)
    }
  }),
);
export default function MapMarker (props: { 
  id: string,
  title: string,
  status?: string,
  icon?: JSX.Element, 
  color?: string,
  markerType? : MarkerType,
  onClick?: ( id : string ) => void })  {

  const classes = useStyles();

  const markerButton = (
    <Button
      key={props.id}
      className={props.markerType === MarkerTypes.Small ? classes.smallMarker : classes.normalMarker}
      style={{ 
        backgroundColor: props.color == null ? EmptyColor : props.color,
        cursor: !props.onClick != null ? "pointer" : undefined 
      }}   
      size="small"
      startIcon={props.icon != null ? props.icon : <PlaceIcon fontSize="inherit" />}
      onClick={(event) => { 
        if( props.onClick != null ) {
          event.stopPropagation(); 
          props.onClick!(props.id);
        }
      }}
      disableRipple={props.onClick == null} 
    >
      {props.markerType === MarkerTypes.Small ? null : props.title}
    </Button>
  )

  const smallMarker = ( 
    markerButton
  )


  const normalMarker = (
    <Grid container key={props.id} className={classes.grid} direction="column" alignItems="stretch" justifyContent="center">
      <Grid item>
        {markerButton}
      </Grid>
    </Grid>
  )

  return (
    <div className={classes.root}>
      {props.status != null ?
        <Tooltip title={<>{props.status}</>}> 
          {props.markerType === MarkerTypes.Small ? smallMarker : normalMarker}
        </Tooltip>
        :
        props.markerType === MarkerTypes.Small ? smallMarker : normalMarker
      }
    </div>
  );

}

