import { Company } from '../documents/company';
import { User } from '../documents/user';

import { Factory } from '../../../common/api/factory';
import { log } from '../../framework/databaseService';

import { ReferenceHandle } from '../../api/core/referenceHandle';

import applicationConfiguration from "../../../../healthguard/data/settings/application.json";

export class CompanyManager {

    static getInstance() : CompanyManager {
        if( CompanyManager._instance == null ) {
            CompanyManager._instance = new CompanyManager();
        }
        return CompanyManager._instance;
    }

    async availableLicenses( company : Company ) : Promise<number|undefined> { 

        try {
            log.traceIn("availableLicenses()", company.databasePath() );

            const licenses = company.licenses.value();

            if( licenses == null ) {
                log.traceOut("availableLicenses()", "Licenses not set");
                return undefined;
            }

            const usedLicenses = company.usedLicenses.value();
            if( usedLicenses == null ) {
                log.traceOut("availableLicenses()", "Used licenses not set");
                return undefined;
            }

            if( usedLicenses >= licenses ) {
                log.traceOut("availableLicenses()", "No available", {usedLicenses}, {licenses} );
                return 0;
            }

            const availableLicenses = licenses - usedLicenses;

            log.traceOut("availableLicenses()", {availableLicenses} );
            return availableLicenses;

        } catch (error) {
            log.warn("Error auditing document create", error);

            throw new Error( (error as any).message );
        }
    }


    async handleCompanyCreated( createdCompany: Company ) {

        try {
            log.traceIn("handleCompanyCreated()", createdCompany.databasePath() );

            await this.createDefaultAdminUser( createdCompany );

            log.traceOut("handleCompanyCreated()");

        } catch (error) {
            log.warn("Error auditing document create", error);

            log.traceOut("handleCompanyCreated()", "error");
        }
    }

    async handleCompanyUpdated( updatedCompany: Company ) {

        try {
            log.traceIn("handleCompanyUpdated()");

            await this.createDefaultAdminUser( updatedCompany ); 
          
            log.traceOut("handleCompanyUpdated()");

        } catch (error) {
            log.warn("Error auditing document changes", error);

            log.traceOut("handleCompanyUpdated()", "error");
        }
    }

    async handleCompanyDeleted( deletedCompany: Company ) {

        try {
            log.traceIn("handleCompanyDeleted()");


            log.traceOut("handleCompanyDeleted()");

        } catch (error) {
            log.warn("Error auditing document delete", error);

            log.traceOut("handleCompanyDeleted()", "error");
        }
    }

    async createDefaultAdminUser( company : Company ) : Promise<User | undefined> {

        try {
            log.traceIn("createDefaultAdminUser()");

            if( company.admins.count() > 0 ) {
                log.traceIn("createDefaultAdminUser()", "Admin user already exists");
                return undefined;
            }

            const contactEmail = company.email.value();

            if( contactEmail == null ) {
                log.traceIn("createDefaultAdminUser()", "No company contact email");
                return undefined;
            }

            const contactNumber = company.phoneNumber.value();

            const adminUser = company.users.collection().newDocument()!; 

            const adminName = Factory.get().configurationService.config( 
                applicationConfiguration, "adminName" );

            adminUser.firstName.setValue( company.title.value() );

            adminUser.lastName.setValue( adminName);

            adminUser.email.setValue( contactEmail.toLowerCase() );

            adminUser.phoneNumber.setValue( contactNumber );

            await adminUser.create();

            // Two way reference
            company.admins.setDocument( adminUser.referenceHandle() as ReferenceHandle<User> ); 

            await company.update();

            log.traceOut("createDefaultAdminUser()");
            return adminUser;

        } catch (error) {
            log.warn("Error creating admin user", error);

            throw new Error( (error as any).message );
        }
    }



    private static _instance? : CompanyManager;

}

