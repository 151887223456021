
export const MeasureTypes = {

    Environment :  "environment",

    Control :      "control"

}

export type MeasureType = keyof (typeof MeasureTypes);


