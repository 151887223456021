import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import DashboardIcon from '@material-ui/icons/Dashboard';

import HubIcon from '@mui/icons-material/Hub';

import List from '@material-ui/core/List';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';


import CollectionName from '../components/collectionName';
import CollectionIcon from '../components/collectionIcon';
import AppFrame, { DefaultDashboardsOpen, DefaultRegistrationsOpen, PersistentKeyDashboardsOpen, PersistentKeyRegistrationsOpen } from '../components/appFrame';


import { log } from 'ui/app/app';
import { HomePaths } from '../../services/common/api/homePaths';

import { AppContext, AppContextProps } from 'ui/app/appContext';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { Collapse} from '@material-ui/core';
import { Factory } from 'services/common/api/factory';
import { IncidentRegistrationsCollection, QuarantineRegistrationsCollection, RegistrationsCollection, SickLeaveRegistrationsCollection, SymptomRegistrationsCollection, TestRegistrationsCollection, VaccineRegistrationsCollection } from '../../healthguard/api/registrationCollections';
import { ConsentsCollection, GatheringsCollection, RisksCollection, MeasuresCollection } from '../../healthguard/api/healthguardCollections';
import { errorDialog } from '../components/simpleDialog';
import { IncidentRegistrationDocument, QuarantineRegistrationDocument, RegistrationDocument, SickLeaveRegistrationDocument, SymptomRegistrationDocument, TestRegistrationDocument, VaccineRegistrationDocument } from '../../healthguard/api/registrationDocuments';
import { ConsentDocument, HealthguardAlertDocument, HealthguardUserDocument, MeasureDocument } from '../../healthguard/api/healthguardDocuments';
import { AlertsCollection, CategoriesCollection, ChangesCollection, CompaniesCollection, DevicesCollection, LocationsCollection, MessagesCollection, ProjectsCollection, ResidencesCollection, TerminalsCollection, UnitsCollection, UsersCollection } from '../../services/database/api/collections';
import { ChangeDocument, MessageDocument, ResidenceDocument, TerminalDocument } from '../../services/database/api/documents';
import { DocumentNameKey } from '../../services/database/api/core/databaseServiceIF';
import { DatabaseDocumentIF } from '../../services/database/api/core/databaseDocumentIF';
import { UnitViewOverviewParameter } from './unitViews';
import { CompanyViewPublic } from './companyViews';
import { UserViewAssociationsParameter } from './userViews';
import { DatabaseIF } from '../../services/database/api/core/databaseIF';
import { DocumentViewKey } from '../components/documentRouter';

//const PersistentKeyUserDashboard = "userDashboard";

//const PersistentKeyUserDashboardsOpen = PersistentKeyUserDashboard + ".dashboardsOpen";

//const PersistentKeyUserRegistrationsOpen = PersistentKeyUserDashboard + ".registrationsOpen";

const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    height: '100%',
    width: '100%',
    position: 'relative',
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  currentDashboard: {
    color: theme.palette.info.dark,
  }
});


interface MatchParams {
}

interface UserHomeProps extends WithStyles<typeof styles>, WithTranslation, RouteComponentProps<MatchParams> {
}

interface UserHomeState { // Document View Props

  dashboardsOpen? : boolean;

  measuresOpen? : boolean,

  registrationsOpen? : boolean;

  selectedDatabase: DatabaseIF<DatabaseDocumentIF> | undefined | null,

  userView: string | undefined | null

  parent?: boolean

}

class UserHome extends React.PureComponent<UserHomeProps, UserHomeState> {

  constructor(props: UserHomeProps) {

    super(props);

    this.state = {

    } as UserHomeState;

    this.selectDatabase = this.selectDatabase.bind(this);

    this.selectSimpleUserCollectionView = this.selectSimpleUserCollectionView.bind(this);
    this.selectReferencesView = this.selectReferencesView.bind(this);
    this.selectDatabaseView = this.selectDatabaseView.bind(this);
    this.selectCollectionView = this.selectCollectionView.bind(this);
    this.selectCollectionGroupView = this.selectCollectionGroupView.bind(this);

    //log.traceInOut("constructor()");
  }

  async componentDidMount() {

    try {
      log.traceIn( "componentDidMount()");

      const appContext = this.context as AppContextProps;

      const parent = appContext.authenticationClaims?.childUserIds != null &&
        appContext.authenticationClaims.childUserIds.length > 0 

      this.setState( {
        parent: parent
      }); 

      await this.selectDatabase();

      log.traceOut( "componentDidMount()", "state", );

    } catch( error ) {
      log.warn( "componentDidMount()", "Error mounting documents view", error );

      await errorDialog( error);
      
      log.traceOut( "componentDidMount()", error );
    }
  }

  async componentDidUpdate() {

    try {
      //log.traceIn( "componentDidUpdate()");

      await this.selectDatabase();

      //log.traceOut( "componentDidUpdate()", "state", );

    } catch( error ) {
      log.warn( "componentDidUpdate()", "Error mounting documents view", error );

      await errorDialog( error);
      
    }
  }

  async selectDatabase() {

    try {
      log.traceIn( "selectDatabase()");

      let url = this.props.location.pathname;

      if( url.endsWith( HomePaths.UserHomePath )) {

        this.setState( { selectedDatabase: null } );

        log.traceOut( "selectDatabase()", "no selected database");
        return;
      }

      const query = new URLSearchParams(this.props.location.search);

      const documentName = query.get( DocumentNameKey );

      if( documentName != null ) {

        url += "?" + DocumentNameKey + "=" + documentName;
      }

      const selectedDatabase = Factory.get().databaseService.databaseFactory.databaseFromUrl( url );
        
      if( selectedDatabase?.defaultDocumentName() !== this.state.selectedDatabase?.defaultDocumentName() ) {
        this.setState( { selectedDatabase: selectedDatabase } );
      }

      log.traceOut( "selectDatabase()", "state");

    } catch( error ) {
      log.warn( "selectDatabase()", "Error mounting documents view", error );

      await errorDialog( error);
      
      log.traceOut( "selectDatabase()", error );
    }
  }
  private handleAdminDashboardClick = async () => {

    log.traceInOut( "handleAdminDashboardClick()" );

    const appContext = this.context as AppContextProps;

    if( appContext.onUpdateActiveUnit != null ) {
      await appContext.onUpdateActiveUnit( undefined );
    }

    if( appContext.onUpdateActiveCompany != null ) {
      await appContext.onUpdateActiveCompany( undefined );
    }

    this.setState( { selectedDatabase : undefined });

    this.props.history.push( HomePaths.AdminHomePath );

  };

  private handleCompanyDashboardClick = async () => {

    log.traceInOut( "handleCompanyDashboardClick()" );

    const appContext = this.context as AppContextProps;

    if( appContext.onUpdateActiveUnit != null ) {
      await appContext.onUpdateActiveUnit( undefined );
    }

    this.setState( { selectedDatabase : undefined });

    this.props.history.push( HomePaths.CompanyHomePath );

  };

  private handleUnitDashboardClick = () => {

    log.traceInOut( "handleUnitDashboardClick()" );

    this.setState( { selectedDatabase : undefined });

    this.props.history.push( HomePaths.UnitHomePath );

  };

  private handleUserDashboardClick = () => {

    log.traceInOut( "handleUserDashboardClick()" );

    this.setState( { selectedDatabase : undefined });

    this.props.history.push( HomePaths.UserHomePath );
  
  };


  private handleDashboardsClick = () => {

    log.traceInOut( "handleDashboardsClick()" );

    if( this.state.selectedDatabase != null ) {

      this.handleUserDashboardClick();
    }
    else {
      const dashboardsOpen = this.isDashboardsOpen();

      Factory.get().persistentState!.setProperty( PersistentKeyDashboardsOpen, !dashboardsOpen );

      this.setState( {  dashboardsOpen : !dashboardsOpen  });

      this.props.history.push( HomePaths.UserHomePath );

    }
  };


  private isDashboardsOpen = () : boolean  =>  {

    //log.traceIn( "isDashboardsOpen()", appContext );

    if( this.state.dashboardsOpen != null ) {
      //log.traceOut( "isDashboardsOpen()", "From state", this.state.dashboardsOpen );
      return this.state.dashboardsOpen;
    }

    const persistentDashboardsOpen = Factory.get().persistentState!.property( PersistentKeyDashboardsOpen ) as boolean;

    if( persistentDashboardsOpen != null ) {

      //log.traceOut( "isDashboardsOpen()", "From persistent app state", persistentDashboardsOpen );
      return persistentDashboardsOpen;
    }

    //log.traceOut( "isDashboardsOpen()", "From default", DefaultDashboardsOpen );
    return DefaultDashboardsOpen;
  };


   private handleRegistrationsClick = ( event : any ) => {

    log.traceInOut( "handleRegistrationsClick()" );

    if (this.state.selectedDatabase == null || 
        this.state.selectedDatabase.defaultDocumentName() !== RegistrationDocument) {

      this.selectDatabaseView( RegistrationsCollection ); 

      Factory.get().persistentState!.setProperty( PersistentKeyRegistrationsOpen, false );

      this.setState( {  registrationsOpen : false  }); 
    }
    else {
      event.stopPropagation();

      const registrationsOpen = this.isRegistrationsOpen();

      Factory.get().persistentState!.setProperty( PersistentKeyRegistrationsOpen, !registrationsOpen );

      this.setState( {  registrationsOpen : !registrationsOpen  });
    }
  }


  private isRegistrationsOpen = () : boolean  =>  {

    //log.traceIn( "isRegistrationOpen()" );

    if( this.state.registrationsOpen != null ) {
      //log.traceOut( "isRegistrationOpen()", "From state", this.state.registrationsOpen );
      return this.state.registrationsOpen;
    }

    const persistentRegistrationsOpen = Factory.get().persistentState!.property( PersistentKeyRegistrationsOpen ) as boolean;

    if( persistentRegistrationsOpen != null ) {

      //log.traceOut( "isRegistrationOpen()", "From persistent app state", persistentRegistrationsOpen );
      return persistentRegistrationsOpen;
    }

    //log.traceOut( "isRegistrationsOpen()", "From default", DefaultRegistrationsOpen );
    return DefaultRegistrationsOpen;
  };

  private handleUnitClick = () => {

    log.traceInOut("handleUnitClick()");

    const appContext = this.context as AppContextProps;

    const selectedDatabase = appContext.currentUser!.ownerCollection( UnitsCollection );

    this.setState({ 
      selectedDatabase: selectedDatabase,
      userView: null 
    });

    this.props.history.push(HomePaths.UserHomePath + 
      appContext.currentUnit!.databasePath( true ) + "&" + DocumentViewKey + "=" + UnitViewOverviewParameter );
  };

  private handleCompanyClick = () => {

    log.traceInOut("handleCompanyClick()");

    const appContext = this.context as AppContextProps;

    const selectedDatabase = appContext.currentUser!.ownerCollection( CompaniesCollection );

    this.setState({ 
      selectedDatabase: selectedDatabase,
      userView: null 
    });

    this.props.history.push(HomePaths.UserHomePath + 
      appContext.currentCompany!.databasePath( true ) + "&" + DocumentViewKey + "=" + CompanyViewPublic );
  };


  private handleRisksClick = () => {

    log.traceInOut("handleRisksClick()");

    this.selectOwnerCollection( RisksCollection );

  };

  private handleMeasuresClick = () => {

    log.traceInOut( "handleMeasuresClick()" );

    this.selectOwnerCollection( MeasuresCollection );
   }


  private handleProjectsClick = () => {

    log.traceInOut("handleProjectsClick()");

    this.selectOwnerCollection( ProjectsCollection );

  };

  private handleLocationsClick = () => {

    log.traceInOut("handleLocationsClick()");

    this.selectOwnerCollection( LocationsCollection );
  };

  private handleCategoriesClick = () => {

    log.traceInOut("handleCategoriesClick()");

    this.selectOwnerCollection( CategoriesCollection );
  };


  private handleGatheringsClick = () => {

    log.traceInOut("handleGatheringsClick()");

    this.selectOwnerCollection( GatheringsCollection );
  };

  private handleUsersClick = () => {

    log.traceInOut("handleUsersClick()");

    this.selectCollectionView( UsersCollection );
  };


  private handleResidencesClick = () => {

    log.traceInOut("handleResidenesClick()");

    this.selectDatabaseView( ResidencesCollection );
  };

  private handleDevicesClick = () => {

    log.traceInOut("handleDevicesClick()");

    this.selectDatabaseView( DevicesCollection );
  };


  private handleQuarantinesClick = () => {

    log.traceInOut("handleQuarantinesClick()");

    this.selectDatabaseView( QuarantineRegistrationsCollection );

  };


  private handleSickLeavesClick = () => {

    log.traceInOut("handleSickLeavesClick()");

    this.selectDatabaseView( SickLeaveRegistrationsCollection );

  };

  private handleSymptomsClick = () => {

    log.traceInOut("handleSymptomsClick()");

    this.selectDatabaseView( SymptomRegistrationsCollection );
  };

  private handleTestsClick = () => {

    log.traceInOut("handleTestsClick()");

    this.selectDatabaseView( TestRegistrationsCollection );

  };

  private handleIncidentsClick = () => {

    log.traceInOut("handleIncidentsClick()");

    this.selectDatabaseView( IncidentRegistrationsCollection );

  };

  private handleVaccinesClick = () => {

    log.traceInOut("handleVaccinesClick()");

    this.selectDatabaseView( VaccineRegistrationsCollection );

  };


  private handleChangesClick = () => {

    log.traceInOut( "handleChangesClick()" );

    this.selectCollectionGroupView( ChangesCollection );

  }

  private handleAlertsClick = () => {

    log.traceInOut( "handleAlertsClick()" );

    this.selectDatabaseView( AlertsCollection );

  }


  private handleMessagesClick = () => {

    log.traceInOut( "handleTerminalsClick()" );

    this.selectDatabaseView( MessagesCollection );

  }

  private handleTerminalsClick = () => {

    log.traceInOut( "handleTerminalsClick()" );

    this.selectDatabaseView( TerminalsCollection );

  }

  private handleConsentsClick = () => {

    log.traceInOut( "handleConsentsClick()" );

    this.selectDatabaseView( ConsentsCollection );

  }

  private handleUserAssociationsClick = () => {

    log.traceInOut("handleUserAssociationsClick()");

    const appContext = this.context as AppContextProps;

    const selectedDatabase = appContext.currentUser!.ownerCollection( UsersCollection );

    this.setState({ 
      selectedDatabase: selectedDatabase,
      userView:  UserViewAssociationsParameter
    });

    this.props.history.push(HomePaths.UserHomePath +
      appContext.currentUser!.databasePath(true)! + "&" + DocumentViewKey + "=" + UserViewAssociationsParameter);
  };

  private selectSimpleUserCollectionView( collectionName : string ) {

    log.traceIn("selectSimpleCollectionView()", {collectionName} );

    const appContext = this.context as AppContextProps;

    const selectedDatabase = appContext.currentUser!.ownerCollection( collectionName );

    this.setState({ 
      selectedDatabase: selectedDatabase,
      userView: collectionName
     });

    this.props.history.push( HomePaths.UserHomePath +
      appContext.currentUser!.databasePath()! + "?" + DocumentViewKey + "=" + collectionName);

    log.traceOut("selectCollection()"); 
  }

  private selectReferencesView( collectionName : string ) {

    log.traceIn("selectReferencesView()", {collectionName} );

    log.traceOut("selectReferencesView()");
  }


  private selectDatabaseView( collectionName : string ) {

    log.traceIn("selectDatabaseView()", {collectionName} );

    if( this.state.parent ) {
      this.selectCollectionGroupView( collectionName );
    }
    else {
      this.selectCollectionView( collectionName );
    }

    log.traceOut("selectDatabaseView()");
  }

  private selectCollectionView( collectionName : string ) {

    log.traceIn("selectCollectionView()", {collectionName} );

    const appContext = this.context as AppContextProps;

    const selectedDatabase =
      Factory.get().databaseService.databaseFactory.collectionDatabaseFromCollectionName(
        collectionName, appContext.currentUser );

    this.setState({ 
      selectedDatabase: selectedDatabase,
      userView: null 
    });

    this.props.history.push(HomePaths.UserHomePath + selectedDatabase.databasePath(true));

    log.traceOut("selectCollection()");
  }

  private selectCollectionGroupView( collectionName : string ) {

    log.traceIn("selectCollectionGroupView()", {collectionName} );

    const appContext = this.context as AppContextProps;

    const selectedDatabase =
      Factory.get().databaseService.databaseFactory.collectionGroupDatabaseFromCollectionName(
        collectionName, appContext.currentUser );

    this.setState({ 
      selectedDatabase: selectedDatabase,
      userView: null 
    });

    this.props.history.push(HomePaths.UserHomePath + selectedDatabase.databasePath(true));

    log.traceOut("selectCollectionGroup()");
  }

  private selectOwnerCollection( collectionName : string ) {

    log.traceIn("selectOwnerCollection()", {collectionName} );

    const appContext = this.context as AppContextProps;

    const selectedDatabase =
      Factory.get().databaseService.databaseFactory.collectionDatabaseFromCollectionName(
        collectionName, 
        appContext.currentUnit != null && appContext.currentUnit.property( collectionName ) != null ?
          appContext.currentUnit : 
            appContext.currentCompany != null && appContext.currentCompany.property( collectionName ) != null ?
              appContext.currentCompany :
              undefined 
      );

    this.setState({ 
      selectedDatabase: selectedDatabase,
      userView: null 
    });

    this.props.history.push(HomePaths.UserHomePath + selectedDatabase.databasePath(true));

    log.traceOut("selectCollectionGroup()");
  }

  render() {
    //log.traceIn("render()", this.props.location.pathname);

    const { classes } = this.props;


    const selectedCollectionName = this.state.selectedDatabase != null ?
      this.state.selectedDatabase.collectionName(): undefined;

    const documentName = this.state.selectedDatabase != null ?
      this.state.selectedDatabase.defaultDocumentName() : undefined;

    const dashboardItems = (
      <AppContext.Consumer>
        {appContext => (
          <React.Fragment>
            <List>
            <ListItem
              button
              onClick={() => this.handleDashboardsClick()}
              selected={!this.isDashboardsOpen() && selectedCollectionName == null}> 
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText primary={this.props.t('dashboard')} />
              </ListItem>
            </List>
          </React.Fragment>
        )}
      </AppContext.Consumer>
    );


    const myDataItems = (
      <AppContext.Consumer>
        {appContext => (
          <React.Fragment>
            <List>
              <ListSubheader disableSticky>{this.props.t("myData")}</ListSubheader> 
              <ListItem
                button
                onClick={(event) => this.handleRegistrationsClick(event)}
                selected={documentName === RegistrationDocument}>
                <ListItemIcon>
                  <CollectionIcon collectionName={RegistrationsCollection} />
                </ListItemIcon>
                <ListItemText><CollectionName name={RegistrationDocument} my={false} /></ListItemText>
                {this.isRegistrationsOpen() ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={this.isRegistrationsOpen()} timeout="auto" unmountOnExit>
                {registrationItems}
              </Collapse> 
              <ListItem button onClick={() => this.handleAlertsClick()} selected={documentName === HealthguardAlertDocument}>
                <ListItemIcon>
                  <CollectionIcon collectionName={AlertsCollection} />
                </ListItemIcon>
                <ListItemText><CollectionName name={HealthguardAlertDocument} /></ListItemText>
              </ListItem>  
              <ListItem button onClick={()=>this.handleMessagesClick()} selected={selectedCollectionName === MessagesCollection}>
                <ListItemIcon>
                  <CollectionIcon collectionName={MessagesCollection} /> 
                </ListItemIcon>
                <ListItemText><CollectionName name={MessageDocument} my={false}/></ListItemText>
              </ListItem> 
            </List>
          </React.Fragment>
        )}
      </AppContext.Consumer>
    );


    const registrationItems = (
      <AppContext.Consumer>
        {appContext => (
          <React.Fragment>
            <List component="div" disablePadding>
              {!appContext.currentCompany?.vaccineRegistrationsPrivacy.subdocument().disabled.value() &&
                <ListItem
                  className={classes.nested}
                  button
                  onClick={() => this.handleVaccinesClick()}
                  selected={documentName === VaccineRegistrationDocument}>
                  <ListItemIcon>
                    <CollectionIcon collectionName={VaccineRegistrationsCollection} />
                  </ListItemIcon>
                  <ListItemText><CollectionName name={VaccineRegistrationDocument} my={false}/></ListItemText>
                </ListItem>
              }
              {!appContext.currentCompany?.testRegistrationsPrivacy.subdocument().disabled.value() &&
                <ListItem
                  className={classes.nested}
                  button
                  onClick={() => this.handleTestsClick()}
                  selected={documentName === TestRegistrationDocument}>
                  <ListItemIcon>
                    <CollectionIcon collectionName={TestRegistrationsCollection} />
                  </ListItemIcon>
                  <ListItemText><CollectionName name={TestRegistrationDocument} my={false} /></ListItemText>
                </ListItem>
              }
              {!appContext.currentCompany?.incidentRegistrationsPrivacy.subdocument().disabled.value() &&
                <ListItem
                  className={classes.nested}
                  button onClick={() => this.handleIncidentsClick()}
                  selected={documentName === IncidentRegistrationDocument}>
                  <ListItemIcon>
                    <CollectionIcon collectionName={IncidentRegistrationsCollection} />
                  </ListItemIcon>
                  <ListItemText><CollectionName name={IncidentRegistrationDocument} my={false} /></ListItemText>
                </ListItem>
              }
              {!appContext.currentCompany?.sickLeaveRegistrationsPrivacy.subdocument().disabled.value() &&
                <ListItem
                  className={classes.nested}
                  button onClick={() => this.handleSickLeavesClick()}
                  selected={documentName === SickLeaveRegistrationDocument}>
                  <ListItemIcon>
                    <CollectionIcon collectionName={SickLeaveRegistrationsCollection} />
                  </ListItemIcon>
                  <ListItemText><CollectionName name={SickLeaveRegistrationDocument} my={false}/></ListItemText>
                </ListItem>
              }
              {!appContext.currentCompany?.symptomRegistrationsPrivacy.subdocument().disabled.value()  &&
                <ListItem
                  className={classes.nested}
                  button onClick={() => this.handleSymptomsClick()}
                  selected={documentName === SymptomRegistrationDocument}>
                  <ListItemIcon>
                    <CollectionIcon collectionName={SymptomRegistrationsCollection} />
                  </ListItemIcon>
                  <ListItemText><CollectionName name={SymptomRegistrationDocument} my={false}/></ListItemText>
                </ListItem>
              }              
              {!appContext.currentCompany?.quarantineRegistrationsPrivacy.subdocument().disabled.value() &&
                <ListItem
                  className={classes.nested}
                  button onClick={() => this.handleQuarantinesClick()}
                  selected={documentName === QuarantineRegistrationDocument}>
                  <ListItemIcon>
                    <CollectionIcon collectionName={QuarantineRegistrationsCollection} />
                  </ListItemIcon>
                  <ListItemText><CollectionName name={QuarantineRegistrationDocument} my={false}/></ListItemText>
                </ListItem>
              }
            </List>
          </React.Fragment>
        )}
      </AppContext.Consumer>
    );

    const mySettingsItems = (
      <AppContext.Consumer>
        {appContext => (
          <React.Fragment>
            <List>
              <ListSubheader disableSticky>{this.props.t("settings")}</ListSubheader>
              <ListItem button onClick={() => this.handleMeasuresClick()}
                selected={documentName === MeasureDocument}>
                <ListItemIcon>
                  <CollectionIcon collectionName={MeasuresCollection} />
                </ListItemIcon>
                <ListItemText><CollectionName name={MeasureDocument} my={true}/></ListItemText> 
              </ListItem> 
              <ListItem button 
                onClick={() => this.handleUserAssociationsClick()} 
                selected={selectedCollectionName === UsersCollection && this.state.userView === UserViewAssociationsParameter }>
                <ListItemIcon>
                  <HubIcon/>
                </ListItemIcon>
                <ListItemText>{this.props.t("myAssociations")}</ListItemText>
              </ListItem>
              <ListItem button onClick={() => this.handleResidencesClick()} selected={selectedCollectionName === ResidencesCollection}>
                <ListItemIcon>
                  <CollectionIcon collectionName={ResidencesCollection} />
                </ListItemIcon>
                <ListItemText><CollectionName name={ResidenceDocument} my={true} /></ListItemText> 
              </ListItem>
              {this.state.parent &&
                <ListItem button 
                  onClick={() => this.handleUsersClick()} 
                  selected={selectedCollectionName === UsersCollection && this.state.userView === null}>
                  <ListItemIcon>
                    <CollectionIcon collectionName={UsersCollection} />
                  </ListItemIcon>
                  <ListItemText><CollectionName name={HealthguardUserDocument} my={true}/></ListItemText>
                </ListItem>
              }
            </List>
          </React.Fragment>
        )}
      </AppContext.Consumer>
    );


    const auditItems = (
      <AppContext.Consumer>
        {appContext => (
          <div>
            <List>
              <ListSubheader disableSticky>{this.props.t('security')}</ListSubheader>   
              <ListItem button onClick={() => this.handleConsentsClick()}
                selected={documentName === ConsentDocument}>
                <ListItemIcon>
                  <CollectionIcon collectionName={ConsentsCollection} />
                </ListItemIcon>
                <ListItemText><CollectionName name={ConsentDocument} my={false}/></ListItemText> 
              </ListItem>         
              <ListItem button onClick={()=>this.handleTerminalsClick()} selected={selectedCollectionName === TerminalsCollection}>
                <ListItemIcon>
                  <CollectionIcon collectionName={TerminalsCollection} />
                </ListItemIcon>
                <ListItemText><CollectionName name={TerminalDocument} my={false}/></ListItemText>
              </ListItem>
              <ListItem button onClick={()=>this.handleChangesClick()} selected={selectedCollectionName === ChangesCollection}>
                <ListItemIcon>
                  <CollectionIcon collectionName={ChangesCollection} />
                </ListItemIcon>
                <ListItemText><CollectionName name={ChangeDocument} my={false}/></ListItemText>
              </ListItem>
            </List>
          </div>
        )}
      </AppContext.Consumer>
    );

    log.traceOut("renderMain()");

    return (
      <React.Fragment>
        <AppContext.Consumer>
          {appContext => (
            <AppFrame>
              {dashboardItems}
              {myDataItems}
              {mySettingsItems}
              {auditItems}
            </AppFrame>
          )}
        </AppContext.Consumer>
      </React.Fragment>
    );
  }

}

UserHome.contextType = AppContext;

export default withRouter(withTranslation()(withStyles(styles)(UserHome)));

