import { Monitor } from "../../common/api/monitor";
import { Observation } from "../../common/api/observation";
import { Observable } from "../../common/impl/observable";
import { StorageMedia } from "../api/storageMedia";
import { MediaManagerIF } from "../api/mediaManagerIF";

import { log } from "./storageService";

export abstract class MediaManager extends Observable implements MediaManagerIF {

    constructor() {

        super();
        //log.traceIn( "constructor()");

        try {

            //log.traceOut( "constructor()" );
            
        } catch( error ) {

            log.warn( "Error initializing media manager", error );
            
            throw new Error( (error as any).message );
        }
    }

    async init(): Promise<void> {

        //log.traceIn( "constructor()");

        try {

            //log.traceOut( "constructor()" );

        } catch (error) {

            log.warn("Error initializing image manager", error);

            throw new Error( (error as any).message );
        }
    }

    protected async monitor(newMonitor: Monitor, observationFilter?: Observation[], objectIdsFilter?: string[]): Promise<void> {}

    protected async release(observationFilter?: Observation[], objectIdsFilter?: string[]): Promise<void> {}

    abstract upload( media : StorageMedia, monitor? : Monitor ) : Promise<void>;

    abstract downloadUrl( media: StorageMedia ): Promise<string | undefined>;

    abstract download( media : StorageMedia, monitor? : Monitor ) : Promise<void>;

    abstract delete( path : string ) : Promise<void>;

}