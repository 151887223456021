
export const MapTypes = {

    Roadmap           : "roadmap",

    Terrain           : "terrain",

    Satelite          : "satelite",

    Hybrid            : "hybrid"

}

export type MapType = keyof (typeof MapTypes);

export const DefaultMapType = MapTypes.Roadmap as MapType;


