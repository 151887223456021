
export const Languages = {

    English           : "en",

    Norwegian          : "nb"

}

export type Language = keyof (typeof Languages);

