
import { KeyVault } from "../../database/api/definitions/keyVault";
import { KeyFormat } from "../../database/api/definitions/keyFormat";

import { SymmetricKey } from "./symmetricKey";

export const InitialKeyVersion = 1;

export interface KeyManagerIF {

    createSymmetricKey( id: string, value? : string, labels?: { [k: string]: string } ) : Promise<SymmetricKey | undefined>;

    symmetricKey( id: string ) : Promise<SymmetricKey | undefined>; 

    enableSymmetricKey( id: string, versionNumber? : string ) : Promise<void>;

    disableSymmetricKey( id: string, versionNumber? : string ) : Promise<void>;

    destroySymmetricKey( id: string, versionNumber? : string ) : Promise<void>;

    newValue( keyFormat : KeyFormat ) : string;

    readonly keyVault : KeyVault;
}

 