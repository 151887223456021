import React from 'react';
import { CircularProgress, Grid } from '@material-ui/core';
import theme from '../app/theme';
import logoLightBackground from 'ui/app/logo-light.png';  

export default function Loading( props: { showLogo? : boolean } ) {
  return( 
    <div style={{
      width: '100%',
      height: '100%',
      overflow: 'hidden',
      paddingTop: 'env(safe-area-inset-top)',
      paddingLeft: 'env(safe-area-inset-left)',
      paddingRight: 'env(safe-area-inset-right)' 
    }}>
      <Grid 
        container 
        direction="column" 
        alignItems="center" 
        justifyContent={props.showLogo ? "space-between"  : "center"}
        style={{display: 'flex', width: '100%', height: '100%', paddingTop: theme.spacing(6), paddingBottom: theme.spacing(6)}}
        >
        {props.showLogo &&
          <Grid item> 
            <img src={logoLightBackground} alt="logo" style={{ height: theme.spacing(8) }} />   
          </Grid>
          }
        <Grid item><CircularProgress style={{ color: theme.palette.secondary.light }}/></Grid>
        {props.showLogo &&
          <Grid item>&nbsp;</Grid>
        }
      </Grid> 
    </div>

  );
}

export function InlineLoading( props: {} ) {
  return( 
    <CircularProgress size={theme.spacing(2)} color="secondary" />
  );
}

