
export const MeasureStates = {

    Inactive :      "inactive",

    Ongoing :        "ongoing",

    Terminated :    "terminated" 
}

export type MeasureState = keyof (typeof MeasureStates);


