import React from 'react';
import ReactDOMServer from 'react-dom/server';

import {unescape} from 'html-escaper';

import { log } from "ui/app/app";

import { withTranslation, WithTranslation } from 'react-i18next';
import { TranslationKey } from '../../services/common/api/translatorIF';
import { Factory } from 'services/common/api/factory';
import { HealthguardDatabaseFactoryIF } from '../../healthguard/api/healthguardDatabaseFactoryIF';
import { translatedDefinition } from './definitionText';
import { HealthguardCollectionDefinition, HealthguardDefinition, HealthguardDocumentDefinition, HealthguardSubdocumentDefinition } from '../../healthguard/api/healthguardDefinitions';
import { CollectionDefinition, DocumentDefinition, SubdocumentDefinition } from '../../services/database/api/definitions';


export const translatedPropertyLabel = ( documentName : string, propertyKey : string ) => {

  return unescape( ReactDOMServer.renderToString( 
    <ModifiedPropertyLabel documentName={documentName} propertyKey={propertyKey}/>
  ));
}

interface PropertyLabelProps extends WithTranslation {
  documentName : string,
  propertyKey : string
}


interface PropertyLabelState { 
}

class PropertyLabel extends React.PureComponent<PropertyLabelProps,PropertyLabelState> {

  constructor( props: PropertyLabelProps ) {
    
    super(props);

    this.state = { 
    } as PropertyLabelState;

    //log.traceInOut( "constructor()" );
  }


  render() {

    //log.traceIn( "render()", this.props.documentName, this.props.propertyKey );

    let result;

    let translationKey = this.props.documentName + ":" + TranslationKey.Document + ":" + this.props.propertyKey;
    if (this.props.i18n.exists(translationKey)) {

      result = this.props.t(translationKey);
      //log.traceOut( "render()", translationKey, result );
      return result;
    }

    translationKey = this.props.documentName + ":" + TranslationKey.Keys + ":" + this.props.propertyKey;
    if (this.props.i18n.exists(translationKey)) {

      result = this.props.t(translationKey);
      //log.traceOut( "render()", translationKey, result );
      return result;
    }

    const healthguardDatabaseFactory =
      Factory.get().databaseService.databaseFactory as HealthguardDatabaseFactoryIF;

    if (healthguardDatabaseFactory.registrationDocumentNames().includes(this.props.documentName)) {

      translationKey = TranslationKey.Registration + ":" + TranslationKey.Keys + ":" + this.props.propertyKey;

      if (this.props.i18n.exists(translationKey)) {

        result = this.props.t(translationKey);
        //log.traceOut( "render()",  "From registrations", translationKey, result );
        return result;
      }
    }

    if (healthguardDatabaseFactory.contactableDocumentNames().includes(this.props.documentName)) {

      translationKey = TranslationKey.Contactable + ":" + TranslationKey.Keys + ":" + this.props.propertyKey;

      if (this.props.i18n.exists(translationKey)) {

        result = this.props.t(translationKey);
        //log.traceOut("render()", "From contactables", translationKey, result);
        return result;
      }
    }

    translationKey = this.props.propertyKey;

    if (this.props.i18n.exists(translationKey)) {

      result = this.props.t(translationKey);
      //log.traceOut( "render()", "direct translation", translationKey, result );
      return result;
    }

    result = translatedDefinition(this.props.propertyKey as HealthguardDefinition);

    if (result != null && result.length > 0 ) {
      //log.traceOut( "render()", "from collection", {result} );
      return result;
    }

    translationKey = HealthguardCollectionDefinition + ":" + TranslationKey.Values + ":" + this.props.propertyKey; 

    if (this.props.i18n.exists(translationKey)) {

      result = this.props.t(translationKey);
      //log.traceOut( "render()", "from healthguard collection", {result} ); 
      return result;
    }

    translationKey = CollectionDefinition + ":" + TranslationKey.Values + ":" + this.props.propertyKey;

    if (this.props.i18n.exists(translationKey)) {

      result = this.props.t(translationKey);
      //log.traceOut( "render()", "from collection definition", result ); 
      return result;
    }

    translationKey = HealthguardDocumentDefinition + ":" + TranslationKey.Values + ":" + this.props.propertyKey;

    if (this.props.i18n.exists(translationKey)) {

      result = this.props.t(translationKey);
      //log.traceOut( "render()", "from healthguard collection definition", result ); 
      return result;
    }

    translationKey = DocumentDefinition + ":" + TranslationKey.Values + ":" + this.props.propertyKey;

    if (this.props.i18n.exists(translationKey)) {

      result = this.props.t(translationKey);
      //log.traceOut( "render()", "from document definition", result ); 
      return result;
    }

    translationKey = SubdocumentDefinition + ":" + TranslationKey.Values + ":" + this.props.propertyKey;

    if (this.props.i18n.exists(translationKey)) {

      result = this.props.t(translationKey);
      //log.traceOut( "render()", "from subdocument definition", result ); 
      return result;
    }

    translationKey = HealthguardSubdocumentDefinition + ":" + TranslationKey.Values + ":" + this.props.propertyKey;

    if (this.props.i18n.exists(translationKey)) {

      result = this.props.t(translationKey);
      //log.traceOut( "render()", "from heealthguard subdocument definition", result ); 
      return result;
    }

    log.warn("No translation found for " + this.props.documentName + ":" + this.props.propertyKey);

    result = this.props.propertyKey;

    //log.traceOut("render()", "Not found", result);
    return result;
  }
} 

const ModifiedPropertyLabel = withTranslation()(PropertyLabel);

export default ModifiedPropertyLabel;

