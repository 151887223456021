
export const IllnessStages = {

    SymptomFree         : "symptomFree",

    Symptomatic         : "symptomatic",

    Hospitalized         : "hospitalized",

    IntensiveCare       : "intensiveCare",

    Respirator           : "respirator",

    ProlongedIllness    : "prolongedIllness",

    Deceased            : "deceased",

    Recovered           : "recovered"
}

export type IllnessStage = keyof (typeof IllnessStages);



