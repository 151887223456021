export const Subdocuments = {

    Address                 : "address",

    Connection              : "connection",

    Contactability          : "contactability",

    Notification            : "notification",

    Privacy                 : "privacy",

    Settings                : "settings"

}

export type SubdocumentName = keyof (typeof Subdocuments);

export const SubdocumentNames = Object.values( Subdocuments );

export const AddressSubdocument = Subdocuments.Address as SubdocumentName;

export const ConnectionSubdocument = Subdocuments.Connection as SubdocumentName;

export const ContactabilitySubdocument = Subdocuments.Contactability as SubdocumentName; 

export const NotificationSubdocument = Subdocuments.Notification as SubdocumentName; 

export const PrivacySubdocument = Subdocuments.Privacy as SubdocumentName;

export const SettingsSubdocument = Subdocuments.Settings as SubdocumentName;





