
import { ReferenceProperty } from "../properties/referenceProperty";
import { log } from "../../framework/databaseService";
import { DatabaseDocument } from "../../framework/databaseDocument";
import { TenantProperty } from "../properties/tenantProperty";
import { Options } from "../../framework/options";


export class OptionsSource<D extends DatabaseDocument, O extends Options> {

    constructor( property : ReferenceProperty<D> | TenantProperty<D>, optionsPropertyKey : keyof D ) {
        //log.traceInOut( "constructor()", property, optionsPropertyKey );

        this._property = property;

        this._optionsPropertyKey = optionsPropertyKey as string;
    }

    async optionsProperty() : Promise<O | undefined> {

        log.traceIn( "optionsProperty()" );

        try {            
            let databaseDocument;

            if( this._property instanceof TenantProperty ) {

                const ownerProperty = this._property as TenantProperty<D>;

                databaseDocument = await ownerProperty.document() as D;
            }
            else if( this._property instanceof ReferenceProperty ) {

                const referenceProperty = this._property as ReferenceProperty<D>;

                if( referenceProperty.path() == null ) {
                    log.traceOut( "optionsProperty()", "empty reference" );
                    return undefined;
                }

                databaseDocument = await referenceProperty.document() as D;
            }

            if( databaseDocument == null ) {
                log.traceOut( "optionsProperty()", "empty document" );
                return undefined;
            }

            const optionsProperty = databaseDocument[this._optionsPropertyKey as keyof D] as any;

            if( optionsProperty == null ) {
                throw new Error( "invalid options property" );
            }

            log.traceOut( "optionsProperty()", optionsProperty );
            return optionsProperty as O;  

        } catch( error ) {

            log.warn( "optionsProperty()", "Error reading options", error );

            throw new Error( (error as any).message );
        }    
    }

    private readonly _property : ReferenceProperty<D> | TenantProperty<D>;

    private readonly _optionsPropertyKey : string;

}