
import { Factory } from '../../../common/api/factory';
import { DatabaseDocument } from '../../framework/databaseDocument';
import { log } from '../../framework/databaseService';
import { Unit } from '../documents/unit';
import { User } from '../documents/user';
import { BooleanProperty } from '../properties/booleanProperty';
import { ReferenceHandle } from '../../api/core/referenceHandle';
import { TenantProperty } from '../properties/tenantProperty';
import { Company } from '../documents/company';
import { CategoriesCollection, LocationsCollection, ProjectsCollection, UnitsCollection, UsersCollection } from '../../api/collections';
import { AllUsersProperty } from '../../api/core/databaseServiceIF';
import { GatheringsCollection } from '../../../../healthguard/api/healthguardCollections';
import { CompanyDocument, UnitDocument } from '../../api/documents';
import { CollectionProperty } from '../properties/collectionProperty';
import { CategoryTypes } from '../../api/definitions/categoryType';
import { SexDefinition } from '../../api/definitions';
import { TranslationKey } from '../../../common/api/translatorIF';
import { PropertyTypes } from "../../api/definitions/propertyType"; 
import { Database } from '../core/database';
import { DocumentsProperty } from '../properties/documentsProperty';
import { Category } from '../documents/category';
 
export class UserManager  { 

    static getInstance() : UserManager {
        if( UserManager._instance == null ) {
            UserManager._instance = new UserManager();
        }
        return UserManager._instance;
    }

    async handleCreateUser( createdUser: User ) {

        try {
            log.traceIn("handleCreateUser()");

            if( createdUser.title.value() == null ) {

                createdUser.updateTitle();
            }

            log.traceOut("handleCreateUser()");

        } catch (error) {
            log.warn("Error handling create user", error);

            log.traceOut("handleCreateUser()", "error");
        }
    }

    async handleUpdateUser( updatedUser: User ) {

        try {
            log.traceIn("handleUpdateUser()");

            if( updatedUser.firstName.isChanged() || updatedUser.lastName.isChanged() ) {
                
                updatedUser.updateTitle();
            }
           
            log.traceOut("handleUpdateUser()");

        } catch (error) {
            log.warn("Error handling user update", error);

            log.traceOut("handleUpdateUser()", "error");
        }
    }

    async handleDeleteUser( deletedUser: User ) {

        try {
            log.traceIn("handleDeleteUser()");

            log.traceOut("handleDeleteUser()");

        } catch (error) {
            log.warn("Error handling delete user", error);

            log.traceOut("handleDeleteUser()", "error");
        }
    }


    async attachChild( parent : User, childReferenceHandle: ReferenceHandle<User> ) : Promise<void> {

        try {
            log.traceIn("attachChild()", parent.title.value(), childReferenceHandle.title );

            if( !parent.userAccess().write ) {
                throw new Error( "permissionDenied"); 
            }

            const child = await Factory.get().databaseService.databaseFactory.documentFromUrl( 
                childReferenceHandle.path ) as User;

            if( child == null ) {
                throw new Error( "notFound" );
            }

            let allowed = false;

            if( !Factory.get().databaseService.currentCompany()?.restrictSymbolicOwners.value() ) {

                allowed = true;
            }
            else {

                if( Factory.get().databaseService.databaseFactory.equalDatabasePaths( 
                    parent.databasePath(), child!.allowedSymbolicUser.value()?.path ) ) {
                   
                    allowed = true;
                }
            }

            if( !allowed ) {
                throw new Error( "symbolicUserNotAllowed"); 
            }

            parent.symbolicUsers.setDocument( childReferenceHandle );

            await parent.update();

            log.traceOut("attachUser()");

        } catch (error) {
            log.warn("Error attaching user", error);

            throw new Error( (error as any).message );
        }
    }


    async findDuplicateUser( createdUser: User, usersDatabase? : Database<User> ) : Promise<User | undefined> {

        try {
            log.traceIn("findDuplicateUser()");

            const email = createdUser.email.value();

            if( email == null ) {
                log.traceOut("findDuplicateUser()", "no email");
                return undefined;
            }

            let database;

            if (usersDatabase != null) {

                database = usersDatabase;

            }
            else {

                const company = createdUser.company.emptyDocument();

                if (company == null) {

                    database =
                        Factory.get().databaseService.databaseFactory.collectionGroupDatabaseFromCollectionName(UsersCollection);
                }
                else {
                    database = company.users.collectionGroup()!; 
                }
            }

            const duplicateUsers = 
                await Factory.get().databaseService.databaseManager.documentsWithProperty( 
                    database,
                    createdUser.email.key(), 
                    email ) as Map<string,User>;
            
            for( const duplicateUser of duplicateUsers.values() ) {

                if( createdUser.id.value() == null || createdUser.id.compareTo( duplicateUser.id ) !== 0 ) {

                    log.traceOut("findDuplicateUser()", "Found", duplicateUser.referenceHandle().title);
                    return duplicateUser;
                }
            }

            log.traceOut("findDuplicateUser()", "not found");
            return undefined;

        } catch (error) {
            log.warn("Error auditing document delete", error);

            throw new Error( (error as any).message );
        }
    }

    async moveUser( beforeUser: User, afterUnit : Unit | undefined ) : Promise<User> {

        try {
            log.traceIn("moveUser()", {beforeUser}, {afterUnit});

            if( !beforeUser.userAccess().write ) {
                throw new Error( "permissionDenied");
            }

            const beforeUnit = await beforeUser.unit.document();

            if( beforeUnit == null && afterUnit == null ) {
                throw new Error( "Both old unit and new unit are empty for user");
            }

            if( beforeUnit != null && !beforeUnit.userAccess().write ) {
                throw new Error( "Moving user requires write permission for before unit");
            }

            if( afterUnit != null && !afterUnit.userAccess().write ) {
                throw new Error( "Moving user requires write permission for after unit");
            }

            const company = beforeUser.company.emptyDocument();

            if( company == null ) {
                throw new Error( "Can only move user that belongs to a company");
            }

            if( (afterUnit == null || beforeUnit == null) && !company.userAccess().write ) {
                throw new Error( "Moving user requires write permission for company");
            }

            let afterUser;

            if( afterUnit != null ) {
                afterUser = await afterUnit.collectionDatabase.moveDocument( beforeUser, afterUnit.users.collection() ) as User;
            }
            else {
                afterUser = await company.collectionDatabase.moveDocument( beforeUser, company.users.collection() ) as User;
            }

            log.traceOut("moveUser()", {afterUser} );
            return afterUser;

        } catch (error) {
            log.warn("Error moving user", error);

            throw new Error( (error as any).message );
        }
    }

    async updateAllDocumentDatabasesWithUser(databaseDocument: DatabaseDocument, user: User ): Promise<void> {

        return this.updateDocumentDatabasesWithUser( databaseDocument, user, 
            Factory.get().databaseService.databaseFactory.collectionNamesWithUsersReference()
        );
    }

    async updateDocumentDatabasesWithUser(databaseDocument: DatabaseDocument, user: User, collectionNames : string[] ): Promise<void> {

        try {
            log.traceIn("updateDocumentDatabaseWithUser()", databaseDocument.databasePath(), user.databasePath(), collectionNames );

            for( const collectionName of collectionNames ) {

                const collectionProperty = databaseDocument.property( collectionName ) as CollectionProperty<DatabaseDocument>;

                if( collectionProperty != null && collectionProperty.type === PropertyTypes.Collection ) {
    
                    this.updateDatabaseWithUser( 
                        collectionProperty.database(),  
                        user );
                }
            }

            log.traceOut("updateDocumentDatabaseWithUser()");

        } catch (error) {
            log.warn("Error updating database with user", error);

            log.traceOut("updateDatabaseWithUser()", "error");
        }
    }

    async updateDatabaseWithUser(database: Database<DatabaseDocument>, user: User): Promise<void> {

        try {
            log.traceIn("updateDatabaseWithUser()", user.databasePath());

            const documents = await database.documents();

            for( const databaseDocument of documents.values() ) {

                await this.updateDocumentWithUser(databaseDocument, user );

                if( databaseDocument.isChanged() ) {
                    
                    await databaseDocument.update();
                }
            } 

            log.traceOut("updateDatabaseWithUser()");

        } catch (error) {
            log.warn("Error updating database with user", error);

            log.traceOut("updateDatabaseWithUser()", "error");
        }
    }

    async updateDocumentWithUser(databaseDocument: DatabaseDocument, user: User): Promise<void> {

        try {
            log.traceIn("updateDocumentWithUser()", databaseDocument.databasePath(), user.databasePath());

            const usersReferencesProperty =
                databaseDocument.property(UsersCollection) as DocumentsProperty<User>;

            if (usersReferencesProperty == null || 
                (usersReferencesProperty.type !== PropertyTypes.References && 
                    usersReferencesProperty.type !== PropertyTypes.SymbolicCollection ) ) {
                log.traceOut("updateDocumentWithUser()","Document does not have users references property");
                return;
            }

            const allUsersProperty = databaseDocument.property(AllUsersProperty) as BooleanProperty;

            if (allUsersProperty != null && !!allUsersProperty.value()) {

                usersReferencesProperty.setDocument(user.referenceHandle() as ReferenceHandle<User> );
            }
            else {

                const unitsProperty = databaseDocument.property(UnitsCollection) as DocumentsProperty<Unit>;

                if (unitsProperty != null && 
                    (unitsProperty.type === PropertyTypes.References || 
                        unitsProperty.type === PropertyTypes.SymbolicCollection ) ) {

                    for (const unitReference of unitsProperty.referenceHandles().values() ) {

                        if (Factory.get().databaseService.databaseFactory.equalDatabasePaths(
                            unitReference.path, user.unit.value()?.path)) {

                            usersReferencesProperty.setDocument(user.referenceHandle() as ReferenceHandle<User> );
                        }
                    }
                }

                this.updateDocumentUsersReferencesFromCollection( 
                    databaseDocument, 
                    user,
                    Factory.get().databaseService.databaseFactory.collectionNamesWithUsersReference() );

            }
            log.traceOut("updateDocumentWithUser()"); 

        } catch (error) {
            log.warn("Error updating document with user", error);

            log.traceOut("updateDocumentWithUser()", "error");
        }
    }

    async updateDocumentUsersReferencesFromCollection(
        databaseDocument: DatabaseDocument,
        user: User, 
        collectionNames : string[] ): Promise<void> {

        try {
            log.traceIn("updateDocumentWithUserFromCollectionReference()",
                databaseDocument.databasePath(),
                user.databasePath(), 
                collectionNames );

            const usersReferencesProperty =
                databaseDocument.property(UsersCollection) as DocumentsProperty<User>;

            if (usersReferencesProperty == null || 
                (usersReferencesProperty.type !== PropertyTypes.References && 
                    usersReferencesProperty.type !== PropertyTypes.SymbolicCollection ) ) {
                log.traceOut("updateDocumentUsersReferencesFromCollection()", "Document does not have users references property");
                return;
            }

            for( const collectionName of collectionNames ) {

                const collectionProperty = databaseDocument.property(collectionName) as DocumentsProperty<DatabaseDocument>;

                if (collectionProperty != null && 
                    (collectionProperty.type === PropertyTypes.References || 
                        collectionProperty.type === PropertyTypes.SymbolicCollection) ) {
    
                    for (const collectionReference of collectionProperty.referenceHandles().values()) {
    
                        const userCollectionReferencesProperty = user.property( collectionName ) as DocumentsProperty<DatabaseDocument>;
    
                        if( userCollectionReferencesProperty != null &&
                            (collectionProperty.type === PropertyTypes.References || 
                                collectionProperty.type === PropertyTypes.SymbolicCollection )) {
    
                            for (const userCollectionReference of userCollectionReferencesProperty.referenceHandles().values()) {
    
                                if (Factory.get().databaseService.databaseFactory.equalDatabasePaths(
                                    collectionReference.path, userCollectionReference.path)) {
        
                                    usersReferencesProperty.setDocument(user.referenceHandle() as ReferenceHandle<User> );
                                    break;
                                }
                            }
                        }
                    }
                }
            }

            log.traceOut("updateDocumentWithUser()"); 

        } catch (error) {
            log.warn("Error updating document with user", error);

            log.traceOut("updateDocumentWithUser()", "error");
        }
    }

    async updateDocumentUsers(databaseDocument: DatabaseDocument ) : Promise<Map<string, ReferenceHandle<User>>> {

        return this.updateDocumentUsersFromCollectionReferences( databaseDocument, 
            [UnitsCollection,
            CategoriesCollection, 
            LocationsCollection, 
            ProjectsCollection, 
            GatheringsCollection] 
        );
    }

    async updateDocumentUsersFromCollectionReferences(databaseDocument: DatabaseDocument, 
        collectionNames : string[] ) : Promise<Map<string, ReferenceHandle<User>>> {

        try {
            log.traceIn("updateDocumentUsersFromCollectionReferences()", databaseDocument.databasePath());

            let nextDocumentUsers = new Map<string, ReferenceHandle<User>>();

            const usersReferencesProperty =
                databaseDocument.property(UsersCollection) as DocumentsProperty<User>;

            if (usersReferencesProperty == null || 
                (usersReferencesProperty.type !== PropertyTypes.References && 
                    usersReferencesProperty.type !== PropertyTypes.SymbolicCollection )) {
                log.traceOut("Document does not have users references property");
                return nextDocumentUsers;
            }

            const previousDocumentUsers = usersReferencesProperty.referenceHandles();

            const changedProperties = databaseDocument.changedProperties();

            if( changedProperties.has(UsersCollection) && !changedProperties.has(AllUsersProperty) ) { 

                let changedCollection = false;

                for( const collectionName of collectionNames ) {
                    if( changedProperties.has(collectionName) ) {
                        changedCollection = true;
                        break;
                    }
                }

                if( !changedCollection ) {

                    const usersProperty = databaseDocument.property( UsersCollection )! as DocumentsProperty<User>;

                    usersProperty.referenceHandles().forEach( user => { 
                        nextDocumentUsers.set(user.path, user);
                    });
                }

            }
            else {

                const allUsersProperty = databaseDocument.property(AllUsersProperty) as BooleanProperty;

                if (allUsersProperty != null && !!allUsersProperty.value()) {

                    const unitOwnerProperty = databaseDocument.property(UnitDocument)! as TenantProperty<Unit>;

                    if (unitOwnerProperty != null && unitOwnerProperty.value() != null) {

                        const ownerUnit = await unitOwnerProperty.document();

                        if (ownerUnit != null) {

                            const unitUsers = await ownerUnit.users.collectionGroup()!.referenceHandles();

                            for (const unitUser of unitUsers.values()) {

                                nextDocumentUsers.set(unitUser.path, unitUser);
                            }
                        }
                    }
                    else {
                        const companyOwnerProperty = databaseDocument.property(CompanyDocument)! as TenantProperty<Company>;

                        const ownerCompany = await companyOwnerProperty.document();

                        if (ownerCompany != null) {

                            const companyUsers = await ownerCompany.users.collectionGroup()!.referenceHandles();

                            for (const companyUser of companyUsers.values()) {

                                nextDocumentUsers.set(companyUser.path, companyUser);
                            }
                        }
                    }
                }
                else {

                    const unitsProperty = databaseDocument.property(UnitsCollection) as DocumentsProperty<Unit>;

                    if (unitsProperty != null && 
                        (unitsProperty.type === PropertyTypes.References || 
                            unitsProperty.type === PropertyTypes.SymbolicCollection ) ) {
        
                        const units = await unitsProperty.documents();
        
                        for (const unit of units.values()) {
        
                            const unitUsers = await unit.users.collectionGroup()!.referenceHandles();

                            for (const unitUser of unitUsers.values()) {

                                nextDocumentUsers.set(unitUser.path, unitUser);
                            }
                        }
                    }

                    for( const collectionName of collectionNames ) {

                        const documentUsers = await this.getUsersFromCollectionReference( databaseDocument, collectionName );

                        nextDocumentUsers = 
                            new Map<string,ReferenceHandle<User>>( [...nextDocumentUsers, ...documentUsers]);

                    }
                }
            }

            previousDocumentUsers.forEach(previousDocumentUser => {

                if( !nextDocumentUsers.has(previousDocumentUser.path)) {

                    usersReferencesProperty.removeDocument(previousDocumentUser.path);
                }
            });

            nextDocumentUsers.forEach(nextDocumentUser => {

                usersReferencesProperty.setDocument(nextDocumentUser);
            })

            log.traceOut("updateDocumentUsersFromCollectionReferences()", nextDocumentUsers.size );
            return nextDocumentUsers;

        } catch (error) {
            log.warn("Error updating users", error);

            throw new Error( (error as any).message );
        }
    }

    async getUsersFromCollectionReference( databaseDocument : DatabaseDocument, collectionName : string ): Promise<Map<string,ReferenceHandle<User>>> {

        const documentUsers = new Map<string, ReferenceHandle<User>>();

        try {
            log.traceIn("getUsersFromCollectionReference()", {collectionName} );

            const documentsProperty = databaseDocument.property(collectionName) as DocumentsProperty<DatabaseDocument>;

            if (documentsProperty != null && 
                (documentsProperty.type === PropertyTypes.References || 
                    documentsProperty.type === PropertyTypes.SymbolicCollection )) {

                const userReferencesDocuments = await documentsProperty.documents();

                for (const userReferencesDocument of userReferencesDocuments.values()) {

                    const usersProperty = 
                        userReferencesDocument.property( UsersCollection ) as DocumentsProperty<User>;

                    if( usersProperty != null ) {
                        const users = usersProperty.referenceHandles();

                        for (const user of users.values()) {
                            documentUsers.set(user.path, user);
                        }
                    }
                }
            }

        } catch (error) {
            log.warn("Error reading user references", error);
        }


        log.traceOut("getUsersFromCollectionReference()", documentUsers.size );
        return documentUsers;
    }

    async generateCategories( user: User ) : Promise<void> {

        try {
            log.traceIn("generateCategories()", user.title.value() );


            if( user.company.id() == null ) {
                log.traceIn("generateCategories()", "Only for company users" );
                return;
            }

            let company;

            let userCategories;

            let companyCategories;

            if( user.dateOfBirth.isChanged() ) {

                const dateOfBirthTitle = user.dateOfBirth.value() != null ?
                    user.dateOfBirth.value()!.getFullYear().toString() : undefined;

                userCategories = userCategories != null ? userCategories : 
                    await user.categories.documents();

                let dateOfBirthFound = false;
                for( const userCategory of userCategories.values() ) {

                    if( userCategory.categoryType.value() === CategoryTypes.BirthYear &&
                        !!userCategory.autoGenerated.value() ) {

                        if( userCategory.title.value() !== dateOfBirthTitle ) {

                            user.categories.removeDocument( userCategory.referenceHandle().path );
                            break;
                        }
                        else {
                            dateOfBirthFound = true;
                        }
                    }
                }

                if (!dateOfBirthFound && dateOfBirthTitle != null) {

                    company = company != null ? company :
                        await user.company.document();

                    companyCategories = companyCategories != null ? companyCategories :
                        await company!.categories.collection().documents();

                    for (const companyCategory of companyCategories.values()) {

                        if (companyCategory.categoryType.value() === CategoryTypes.BirthYear &&
                            !!companyCategory.autoGenerated.value() &&
                            companyCategory.title.value() === dateOfBirthTitle) {

                            user.categories.setDocument(companyCategory.referenceHandle() as ReferenceHandle<Category> );
                            break;
                        }
                    }
                }
            }

            if( user.sex.isChanged() ) {

                company = company != null ? company :
                    await user.company.document();

                const language = company!.language.value() != null ? company!.language.value() :
                    Factory.get().translator.defaultLanguage();

                const sexTranslations = user.sex.value() == null ? undefined :
                    await Factory.get().translator.loadTranslations( SexDefinition, language );

                const sexTitle = user.sex.value() == null ? undefined :
                    Factory.get().translator.translate( 
                        TranslationKey.Values + "." + user.sex.value()!, 
                        sexTranslations,
                        language);

                userCategories = userCategories != null ? userCategories : 
                    await user.categories.documents();

                let sexFound = false;
                for( const userCategory of userCategories.values() ) {

                    if( userCategory.categoryType.value() === CategoryTypes.Sex &&
                        !!userCategory.autoGenerated.value() ) {

                        if( userCategory.title.value() !== sexTitle ) {

                            user.categories.removeDocument( userCategory.referenceHandle().path );
                            break;
                        }
                        else {
                            sexFound = true;
                        }
                    }
                }

                if (!sexFound && sexTitle != null) {

                    company = company != null ? company :
                        await user.company.document();

                    companyCategories = companyCategories != null ? companyCategories :
                        await company!.categories.collection().documents();

                    for (const companyCategory of companyCategories.values()) {

                        if (companyCategory.categoryType.value() === CategoryTypes.Sex &&
                            !!companyCategory.autoGenerated.value() &&
                            companyCategory.title.value() === sexTitle) {

                            user.categories.setDocument(companyCategory.referenceHandle() as ReferenceHandle<Category> );
                            break;
                        }
                    }
                }
            }

            log.traceOut("generateCategories()" );

        } catch (error) {
            log.warn("Error generating user categories", error );

        }
    }

    private static _instance? : UserManager;

}

