import React from 'react';
import * as Realm from "realm-web";


import { withTranslation, WithTranslation } from 'react-i18next';


import { Button, createStyles, Grid, Link, TextField, Theme, WithStyles, withStyles } from '@material-ui/core';
import { RouteComponentProps, withRouter } from 'react-router-dom';


import CloseIcon from '@material-ui/icons/Close'; 
import EmailIcon from '@material-ui/icons/Email'; 
import CreateIcon from '@material-ui/icons/Create'; 

import GoogleIcon from 'mdi-material-ui/Google'
import LoginIcon from 'mdi-material-ui/Login'; 

import { log } from '../app';

import { errorDialog } from '../../components/simpleDialog';
import { Factory } from '../../../services/common/api/factory';
import Loading from '../../components/loading';
import { propertyInputVariant } from '../../components/propertyValue';

const styles = (theme: Theme) => createStyles({ 
  root: {
    display: 'flex',
  },
  form: {
    display: 'flex',
    width: theme.spacing(50) 
  },
  button: {
    width: '100%' 
  },
  text: {
    padding: '0',
    margin: '0'  
  }
});

enum LoginState {
  LoginWithGoogle,
  LoginWithEmailPassword,
  ResetPassword,
  RegisterEmailPassword,
  AwaitSignIn,
  AwaitRegistration
}

interface MatchParams {
}

export interface RealmAuthViewProps extends WithStyles<typeof styles>, WithTranslation, RouteComponentProps<MatchParams> { 

  onComplete? ( user? : any ) : void 
}

interface RealmAuthViewState { // Component State

  loginOption? : LoginState,

  email? : string

  password? : string

}

class RealmAuthView extends React.PureComponent<RealmAuthViewProps,RealmAuthViewState> {

  constructor( props: RealmAuthViewProps ) {
    
    super(props);

    this.state = { 
    } as RealmAuthViewState;

    //log.traceInOut( "constructor()" );
  }

  
  render() {

    log.traceInOut( "render()");

    const { classes } = this.props;

    /*
    const handleLoginAnonymously = async () => {

      log.traceIn( "handleLoginAnonymously()");

      try {

        const credentials = Realm.Credentials.anonymous();

        this.setState( { loginOption: LoginState.AwaitSignIn} )

        const user = await Factory.get().authenticationService!.signIn( credentials );

        if( this.props.onComplete != null ) {
          this.props.onComplete( user );
        }

        this.setState( { loginOption: undefined } )
  
        //log.traceOut("handleLoginAnonymously()");
  
      } catch (error) {
        log.warn("handleLoginAnonymously()", "Error logging un anonymously", error);
  
        await errorDialog( error);

        if( this.props.onComplete != null ) {
          this.props.onComplete();
        }

        this.setState( { loginOption: undefined } )

        log.traceOut("handleLoginAnonymously()", "error");
      } 
    } */

    const handleLoginWithGoogle = () => {

      log.traceInOut( "handleLoginWithGoogle()");

      this.setState( { loginOption: LoginState.LoginWithGoogle} )
    }

    const handleLoginWithEmailPassword = () => {

      log.traceInOut( "handleLoginWithEmailPassword()");

      this.setState( { loginOption: LoginState.LoginWithEmailPassword} )
    }

    const handleRegisterEmailPassword = () => {

      log.traceInOut( "handleRegisterEmailPassword()");

      this.setState( { loginOption: LoginState.RegisterEmailPassword} )
    }

    const handleResetPassword = () => {

      log.traceInOut( "handleResetPassword()");

      this.setState( { loginOption: LoginState.ResetPassword} )

    }

    const handleEmailPasswordSubmitted = async ( event : any ) => {

      log.traceIn( "handleEmailPasswordSubmitted()");

      try {

        event.preventDefault();

        const { email, password } = event.target.elements;

        const credentials = Realm.Credentials.emailPassword( email.value, password.value );

        this.setState( { 
          loginOption: LoginState.AwaitSignIn,
          email: email.value,
          password: password.value
        } );

        const user = await Factory.get().authenticationService!.signIn( credentials );

        if( this.props.onComplete != null ) {
          this.props.onComplete( user );
        }

        this.setState( { loginOption: undefined } )
  
        log.traceOut("handleEmailPasswordSubmitted()");
  
      } catch (error) {
        log.warn("handleEmailPasswordSubmitted()", "Error submitting email and password", error);
  
        await errorDialog( error);

        if( this.props.onComplete != null ) {
          this.props.onComplete();
        }

        this.setState( { loginOption: undefined } )

        log.traceOut("handleEmailPasswordSubmitted()", "error");
      } 
    }

    const handleRegisterEmailPasswordSubmitted = async ( event : any ) => {

      log.traceIn( "handleRegisterEmailPasswordSubmitted()", event.target.elements);

      try {

        event.preventDefault();

        const { email, newPassword, repeatPassword } = event.target.elements;

        if( newPassword.value! !== repeatPassword.value! ) {
          throw new Error( "passwordMismatch" );
        }

        this.setState( { 
          loginOption: LoginState.AwaitRegistration,
          email: email.value,
          password: newPassword.value 
        } )

        await Factory.get().authenticationService!.registerEmailUser( email.value, newPassword.value );

        const credentials = Realm.Credentials.emailPassword( email.value, newPassword.value );

        this.setState( { 
          loginOption: LoginState.AwaitSignIn,
          email: email.value,
          password: newPassword.value
        } );

        const user = await Factory.get().authenticationService!.signIn( credentials );

        if( this.props.onComplete != null ) {
          this.props.onComplete( user );
        }

        this.setState( { loginOption: undefined } )

        log.traceOut("handleRegisterEmailPasswordSubmitted()");
  
      } catch (error) {
        log.warn("handleRegisterEmailPasswordSubmitted()", "Error registering email and password", error);
  
        await errorDialog( error);

        if( this.props.onComplete != null ) {
          this.props.onComplete(); 
        }

        this.setState( { loginOption: undefined} )

        log.traceOut("handleRegisterEmailPasswordSubmitted()", "error");
      } 

    }

    const handleResetPasswordSubmitted = () => {

      log.traceInOut( "handleResetPasswordSubmitted()");
    }

    const handleCancel = () => {

      log.traceInOut( "handleCancel()");

      this.setState( { loginOption: undefined } )
    }

    const cancelButton = ( cancelText? : string ) => {
      return(
        <Button className={classes.button} startIcon={<CloseIcon />} variant="contained" onClick={handleCancel}>
          {cancelText != null ? cancelText : this.props.t("cancel")}
        </Button> 
    )}

    const googleLogin = (
      <>
        <Grid container direction="column" alignItems="stretch" spacing={2} className={classes.root}> 
          <Grid item>
            {cancelButton()}
          </Grid>
        </Grid>
      </>
    )

    const waiting = (
      <>
        <Grid container direction="column" alignItems="stretch" spacing={2} className={classes.root}> 
          <Grid item>
            <Loading />
          </Grid>
          <Grid item>
            {cancelButton()}
          </Grid>
        </Grid>
      </>
    )

    const emailPasswordLogin = (
      <>
        <form onSubmit={handleEmailPasswordSubmitted} noValidate>
          <Grid container direction="column" alignItems="stretch" spacing={1} className={classes.form}>
            <Grid item >
              <TextField
                variant={propertyInputVariant}
                margin="normal"
                required
                fullWidth
                id="email"
                label={this.props.t("email")}
                type="email"
                name="email"
                autoComplete="email"
                autoFocus
                className={classes.text}
                value={this.state.email}
              />
            </Grid>
            <Grid item>
              <TextField
                variant={propertyInputVariant}
                margin="normal"
                required
                fullWidth
                name="password"
                label={this.props.t("password")}
                type="password"
                id="password"
                autoComplete="current-password"
                className={classes.text}
                value={this.state.password}
              />
            </Grid>
            <Grid item>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                startIcon={<LoginIcon />}
                className={classes.button}>
                {this.props.t("login")}
              </Button>
            </Grid>
            <Grid item>
              {cancelButton()}
            </Grid>
            <Grid item>
              <Link href="#" onClick={handleResetPassword}>
                {this.props.t("forgotPassword")}
              </Link>
            </Grid>
            <Grid item>
              <Link href="#" onClick={handleRegisterEmailPassword}>
                {this.props.t("noAccount")}
              </Link>
            </Grid>
          </Grid> 
        </form>
      </>
    )

    const registerEmailPassword = (
      <>
        <form onSubmit={handleRegisterEmailPasswordSubmitted} noValidate>
          <Grid container direction="column" alignItems="stretch" spacing={1} className={classes.form}>
            <Grid item >
              <TextField
                variant={propertyInputVariant}
                margin="normal"
                required
                fullWidth
                id="email"
                label={this.props.t("email")}
                type="email"
                name="email"
                autoComplete="email"
                autoFocus
                className={classes.text}
                value={this.state.email}
              />
            </Grid>
            <Grid item>
              <TextField
                variant={propertyInputVariant}
                margin="normal"
                required
                fullWidth
                name="newPassword"
                label={this.props.t("newPassword")}
                type="password"
                id="newPassword"
                autoComplete="current-password"
                className={classes.text}
              />
            </Grid>
            <Grid item>
              <TextField
                variant={propertyInputVariant}
                margin="normal"
                required
                fullWidth
                name="repeatPassword"
                label={this.props.t("repeatPassword")}
                type="password"
                id="repeatPassword"
                autoComplete="current-password"
                className={classes.text}
              />
            </Grid>
            <Grid item>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                startIcon={<CreateIcon />}
                className={classes.button}>
                {this.props.t("register")}
              </Button>
            </Grid>
            <Grid item>
              {cancelButton()}
            </Grid>
          </Grid> 
        </form>
      </>
    )

    const resetPassword = (
      <>
        <form onSubmit={handleResetPasswordSubmitted} noValidate>
          <Grid container direction="column" alignItems="stretch" spacing={1} className={classes.form}>
            <Grid item >
              <TextField
                variant={propertyInputVariant}
                margin="normal"
                required
                fullWidth
                id="email"
                label={this.props.t("email")}
                name="email"
                autoComplete="email"
                autoFocus
                className={classes.text}
                value={this.state.email}
              />
            </Grid>
            <Grid item>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.button}>
                {this.props.t("resetPassword")}
              </Button>
            </Grid>
            <Grid item>
              {cancelButton()}
            </Grid>
          </Grid> 
        </form>
      </>
    )

    const promptLoginOption = (
      <>
        <Grid container direction="column" alignItems="stretch" spacing={2} className={classes.root}> 
          <Grid item>
            <Button startIcon={<GoogleIcon />} className={classes.button} variant="contained" onClick={handleLoginWithGoogle} >
              {this.props.t("loginWithGoogle")}
            </Button>
          </Grid>
          <Grid item>
            <Button startIcon={<EmailIcon />} className={classes.button} variant="contained" color="primary" onClick={handleLoginWithEmailPassword} >
              {this.props.t("loginWithEmailPassword")}
            </Button>
          </Grid>
        </Grid>
      </>
    )

    return (
      <>
        {this.state.loginOption == null && promptLoginOption }
        {this.state.loginOption === LoginState.LoginWithGoogle && googleLogin }
        {this.state.loginOption === LoginState.LoginWithEmailPassword && emailPasswordLogin }
        {this.state.loginOption === LoginState.RegisterEmailPassword && registerEmailPassword }
        {this.state.loginOption === LoginState.ResetPassword && resetPassword }
        {this.state.loginOption === LoginState.AwaitRegistration && waiting}
        {this.state.loginOption === LoginState.AwaitSignIn && waiting }
      </>
    );
  }


}

export default withRouter(withTranslation()(withStyles(styles)(RealmAuthView)));


