import * as React from 'react'

import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import { withTranslation, WithTranslation } from 'react-i18next';

import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import CloseIcon from '@material-ui/icons/Close'; 
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import AddIcon from '@material-ui/icons/Add'; 
import SearchIcon from '@material-ui/icons/Search'; 

import { log } from 'ui/app/app';
import { HomePaths } from '../../../services/common/api/homePaths';

import { Box, Grid, IconButton, InputAdornment, ListSubheader, TextField } from '@material-ui/core';
import { TenantPropertyIF } from 'services/database/api/properties/tenantPropertyIF';
import PropertyLabel, { translatedPropertyLabel } from '../propertyLabel';
import { PropertyDisplayMode, propertyInputVariant } from '../propertyValue';
import { DatabaseDocumentIF } from 'services/database/api/core/databaseDocumentIF';

import { ReferenceHandle } from 'services/database/api/core/referenceHandle';
import { NewDocumentId } from '../../../services/database/api/core/databaseServiceIF';
import { AppContext, AppContextProps } from '../../app/appContext';
import { OwnerPropertyIF } from '../../../services/database/api/properties/ownerPropertyIF';
import { DatabasePropertyIF } from '../../../services/database/api/core/databasePropertyIF';
import { InlineLoading } from '../loading';
import { PropertiesSelector } from '../../../services/database/api/core/propertiesSelector';
import { Factory } from '../../../services/common/api/factory';
import { UsersCollection } from '../../../services/database';
import { activeLanguage } from '../../app/localization';
import { documentDialog } from '../documentDialog';
import { errorDialog } from '../simpleDialog';
import { PropertyTypes } from '../../../services/database/api/definitions/propertyType';

const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
  },
  select: {
    "& .MuiSelect-select:focus": {
      backgroundColor: 'transparent'
    }
  },
  addButton : {
    marginTop: theme.spacing(1.5), 
    marginLeft: theme.spacing(-8), 
    marginRight: theme.spacing(-8) 
  },
  selectAction : {
    //color:theme.palette.grey[500], 
    marginLeft: theme.spacing(-4.5),
    marginRight: theme.spacing(-4.5)
  },
  inputLabel: {
    marginTop: theme.spacing(-0.5)
  },
  search: {
    color: theme.palette.secondary.main
  }
});

const MinOptionsForSearch = 10;

const OptionsMaxHeight = "80vh";

const ownerPropertiesSelector = { 

  includePropertyTypes: [PropertyTypes.Tenant,PropertyTypes.Owner]

} as PropertiesSelector;




export interface OwnerPropertyValueProps extends WithStyles<typeof styles>, WithTranslation { 

  property : TenantPropertyIF<DatabaseDocumentIF> | OwnerPropertyIF<DatabaseDocumentIF>, 

  onPropertyChange? : ( property : DatabasePropertyIF<any> ) => void,

  displayMode : PropertyDisplayMode,
  
  singleProperty : boolean,

  hideColor?: boolean,

  disabled?: boolean,

  required?: boolean
 }

interface OwnerPropertyValueState { 

  open: boolean,

  newDocument: boolean,

  options : Map<string,ReferenceHandle<DatabaseDocumentIF>>,

  propertyValue?: ReferenceHandle<DatabaseDocumentIF>,

  loading: boolean,

  search: string,

  enabled: boolean

}


class OwnerPropertyValue extends React.PureComponent<OwnerPropertyValueProps,OwnerPropertyValueState> {

  constructor( props: OwnerPropertyValueProps ) {
    
    super(props);

    const propertyValue = this.props.property.referenceHandle();

    const options = new Map<string,ReferenceHandle<DatabaseDocumentIF>>();

    if( propertyValue != null ) {
      options.set( propertyValue.path, propertyValue );
    } 

    const newDocument = propertyValue?.path.includes("/" + NewDocumentId);

    const parentCollection = 
      this.props.property.parent.parentCollection( this.props.property.collectionName());

    const enabled = !this.props.disabled &&
        (parentCollection == null || parentCollection.userAccess().allowCreate ); 
        

    this.state = { 

      open: false,

      newDocument: newDocument,

      enabled: enabled,

      options: options,

      propertyValue: propertyValue,

      loading: false,

      search: ""

    } as OwnerPropertyValueState;

    this.updateTitle = this.updateTitle.bind( this );
    this.options = this.options.bind( this );

    //log.traceInOut( "constructor()" );
  }

  async componentDidMount() {

    try {
      //log.traceIn( "componentDidMount()", this.state.propertyValue );
      
      if ( this.state.propertyValue != null && 
          (this.state.propertyValue.title == null || this.state.propertyValue.title.length === 0 ) ) {
              
          //log.debug( "componentDidMount()", "no title" ); 
          
          await this.updateTitle();
      }
    
      //log.traceOut( "componentDidMount()");

    } catch( error ) {
      log.warn( "componentDidMount()", "Error mounting documents view", error );
      
      log.traceOut( "componentDidMount()", "error" );
    }
  }

  
  async componentDidUpdate() {

    //log.traceInOut( "componentDidUpdate()" );

    const propertyValue = this.props.property.referenceHandle();

    const existingPropertyValue = this.state.propertyValue;

    //log.debug( "componentDidUpdate()", {propertyValue}, {existingPropertyValue} );

    if (!this.state.open &&
        this.props.property.compareValue(existingPropertyValue) !== 0 ) {

      if ( propertyValue != null && propertyValue.title == null ) {

        await this.updateTitle();
      }
      else {
        const options = new Map<string,ReferenceHandle<DatabaseDocumentIF>>();
      
        if( propertyValue != null ) {
          options.set( propertyValue.path, propertyValue )
        } 
  
        this.setState({
          propertyValue: propertyValue,
  
          options: options
        })
      }
    }
  }
  

  async updateTitle() {

    try {
      //log.traceIn( "updateTitle()" );
      
      const propertyValue = Object.assign( {}, this.props.property.referenceHandle());

      if( propertyValue != null && (propertyValue.title == null || propertyValue.title.length === 0 ) ) {

        //log.debug( "updateTitle()", "no title" ); 

        const owner = await this.props.property.document();

        if( owner != null ) {

          const ownerTitle = owner.title.value();

          //log.debug( "updateTitle()", ownerTitle );

          propertyValue.title = ownerTitle;

          this.props.property.setValue( propertyValue );

          const options = new Map<string,ReferenceHandle<DatabaseDocumentIF>>();

          options.set( propertyValue.path, propertyValue )
    
          this.setState({
    
            propertyValue: propertyValue,
    
            options: options 
          });
        }
      } 
  
    
      //log.traceOut( "updateTitle()" ); 

    } catch( error ) {
      log.warn( "updateTitle()", "Error mounting documents view", error );
      
      //log.traceOut( "updateTitle()", "error" );
    }
  }


  async componentWillUnmount() {
    //log.traceIn( "componentWillUnmount()" );

    try {

      //log.traceOut( "componentWillUnmount()");

    } catch( error ) {
      log.warn( "componentWillUnmount()", "Error unmounting collection list", error );
      
      log.traceOut( "componentWillUnmount()", "error" );
    }
  }

  async options() {

    log.traceIn( "options()", this.props.property.key() );

    let result = new Map<string,ReferenceHandle<DatabaseDocumentIF>>();

    try {

      const appContext = this.context as AppContextProps;

      if( appContext.currentHomePath === HomePaths.UserHomePath ) {

        if( this.props.property.collectionName()=== UsersCollection ) {

          const currentUserReferenceHandle = appContext.currentUser!.referenceHandle();

          result.set( currentUserReferenceHandle.path, currentUserReferenceHandle ); 

          let options = await appContext.currentUser!.users.collection().referenceHandles(); 
  
          result = new Map<string,ReferenceHandle<DatabaseDocumentIF>>( [...result, ...options]);
        }
        else {
          const userProperty = appContext.currentUser!.property( 
            this.props.property.key() ) as (TenantPropertyIF<DatabaseDocumentIF> | OwnerPropertyIF<DatabaseDocumentIF>);
  
          log.debug( "options()", {userProperty} );
  
          if( userProperty != null ) {
  
            const userPropertyOption = userProperty.referenceHandle();
  
            if( userPropertyOption != null ) {
  
              log.debug( "options()", {userPropertyOption} );
  
              result.set( userPropertyOption.path, userPropertyOption );
            }
          }
        }
      }
      else { 

        let allEnabledOwnerFiltersEmpty = true;

        const ownerProperties =
          this.props.property.parent.properties(ownerPropertiesSelector);

        for (const ownerProperty of ownerProperties.values()) {

          if( ownerProperty.editable && ownerProperty.value() != null ) {
            allEnabledOwnerFiltersEmpty = false;
            break;
          }
        } 
        
        const useCollectionGroup = 
          allEnabledOwnerFiltersEmpty ||  
          this.props.property.type === PropertyTypes.Owner;  

        let options = await this.props.property.select( useCollectionGroup );

        if (options != null) {

          result = new Map<string,ReferenceHandle<DatabaseDocumentIF>>( [...result, ...options]);
        }
      }

      result = new Map([...result].sort((optionA, optionB) => {
      
        const titleA = optionA[1].title != null ? optionA[1].title! : "";
        const titleB = optionB[1].title != null ? optionB[1].title! : "";

        return titleA.localeCompare( titleB, activeLanguage() );
      }));  


      this.setState( { 
        options : result,
        loading: false 
      } );
      log.traceOut( "options()" );

    } catch( error ) {
      log.warn( "options()", "Error reading options", error );

      this.setState( { 
        loading: false 
      } );
    }
    
  }


  render() {
    //log.traceInOut("render()");

    const {classes } = this.props;

    if( !!this.props.property.hidden ) {
      return null;
    }

    const appContext = this.context as AppContextProps;

    let title = this.props.property.title();

    if( this.props.displayMode === PropertyDisplayMode.Cell ) {
      return ( <>{ title  } </>  );  
    }

    const empty = this.state.propertyValue == null;

    const canOpenOwner = appContext.currentHomePath !== HomePaths.UserHomePath &&
      this.props.displayMode === PropertyDisplayMode.Form &&
      //this.state.enabled && 
      !empty && 
      !this.state.newDocument;  

    const canClear = !empty && this.state.enabled; 

    const canCreate = empty && 
      this.state.enabled &&
      this.props.displayMode === PropertyDisplayMode.Form &&
      !!this.props.property.selectDatabase()?.userAccess().allowCreate;
    
    const value = !this.state.open && this.state.propertyValue?.path != null ? this.state.propertyValue.path: "";

    //log.debug( "render()", this.state.propertyValue, {empty}, {required}, {canOpenOwner}, {canClear});

    const label = translatedPropertyLabel( this.props.property.parent.documentName(), this.props.property.key());

    const showDocument = async () => {

      log.traceIn( "showDocument()" );

      try {

        const databaseDocument = await this.props.property.document();

        await documentDialog( databaseDocument! );
  
        log.traceOut("showDocument()");
  
      } catch( error ) {
        log.warn( "showDocument()", "Error creating new document", error );

        await errorDialog( error );
        
      }
    }

    const newDocument =  async () => {

      log.traceIn( "newDocument()" );

      try {

        const selectDatabase = this.props.property.selectDatabase();
  
        const newDocument = selectDatabase!.newDocument()!;
  
        await documentDialog( newDocument );
  
        log.traceOut( "newDocument()");
  
      } catch( error ) {
        log.warn( "newDocument()", "Error creating new document", error );

        await errorDialog( error );
      }
    }


    const handleChange = async (event: SelectChangeEvent ) => {

      log.traceIn("handleChange()", event.target.value, event.target.name);

      try {

        const referenceHandle = event.target.value != null ? 
          this.state.options.get( event.target.value ): undefined;

        event.preventDefault();

        if( referenceHandle != null && referenceHandle.path.includes("/" + NewDocumentId)) {

          const appContext = this.context as AppContextProps;

          let path = appContext.currentHomePath! as string;

          path += referenceHandle.path;

          if( appContext.onRedirect != null ) {

              await appContext.onRedirect( path );
          }

          log.traceOut("handleChange()", "changed document");
          return;
        }

        this.props.property.setValue( referenceHandle );
        //this.props.property.changed = true;

        this.setState( {
          propertyValue: referenceHandle,
          open: false
        })

        if (this.props.onPropertyChange != null) {
          this.props.onPropertyChange(this.props.property);
        }

        log.traceOut("handleChange()");

      } catch( error ) {
        log.warn( "handleChange()", "Error changing property", error );

        await errorDialog( error );
      }
    };

    const handleClear = async () => {

      log.traceIn("handleClear()" );

      try {

        this.props.property.setValue( undefined );

        this.setState( {
          propertyValue: undefined,
          open: false
        })

        if (this.props.onPropertyChange != null) {
          this.props.onPropertyChange(this.props.property);
        }

        log.traceOut("handleClear()");

      } catch( error ) {
        log.warn( "handleClear()", "Error clearing property", error );

        await errorDialog( error );
      }
    };

    const renderValue = (path: string ) => {

      const referenceHandle = this.state.options.get( path );

      if( referenceHandle == null ) {
        return "";
      }

      return referenceHandle.title != null ? referenceHandle.title : "";
    }

    const open = () => {

      this.setState({ 
        open: true, 
        loading: true 
      });
      
      this.options(); 

    }

    const close = () => {

      this.setState({ 
        open: false, 
        loading: false 
      });
    }

    return (
      <Grid container alignItems="center">
        <FormControl
          fullWidth
          variant={propertyInputVariant}
          disabled={!this.state.enabled}
          required={this.props.required}
          error={this.props.property.error != null}
        >
          <InputLabel className={classes.inputLabel} 
            shrink={true}
            >
            {label}
          </InputLabel>
          <Select
            className={classes.select}
            input={(propertyInputVariant as string) !== "outlined" ? undefined :
              <OutlinedInput
                notched
                label={<>{label}&nbsp;&nbsp;</>}
              />
            }
            displayEmpty={true} 
            renderValue={renderValue}
            value={value} 
            label={<PropertyLabel documentName={this.props.property.parent.documentName()} propertyKey={this.props.property.key()} />}
            onChange={handleChange}
            open={this.state.open && !this.state.loading}
            IconComponent={ this.state.loading || !this.state.enabled || (!empty && !this.state.open) ? Box : undefined }
            onOpen={() => 
              this.state.enabled && empty ? open() : undefined  
            }
            onClose={() => {
              close() 
            }}
            MenuProps={{ 
              autoFocus: false,
              style: { maxHeight: OptionsMaxHeight }
            }}
          >
            {this.state.open && this.state.options.size >= MinOptionsForSearch &&   
              <ListSubheader 
                disableSticky={true} 
                style={{lineHeight: 0}}
                onKeyDown={(event) => event.stopPropagation()}
                > 
                <TextField
                  variant="standard"
                  fullWidth
                  color="secondary"
                  value={this.state.search}
                  placeholder={this.props.t("search")}
                  onChange={async (event) => {
                    const search = event.target.value;
                    if( search !== this.state.search ) {
                      event.stopPropagation();
                      this.setState({ search: search });
                    }
                  }}
                  InputProps={{
                    className: classes.search,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton disabled size="small">
                          <SearchIcon color="disabled" />
                        </IconButton>
                      </InputAdornment>
                    ),
                    disableUnderline: true, 
                    autoFocus: true
                  }}
                />
              </ListSubheader>
            }
            {Array.from(this.state.options.values()).map((option) => (
              <MenuItem
                selected={this.state.propertyValue != null && 
                  Factory.get().databaseService.databaseFactory.equalDatabasePaths( this.state.propertyValue.path, option.path )}
                style={option.title != null && !option.title.toLowerCase().includes( this.state.search.toLowerCase() )  ? 
                  { display: 'none' } : {} 
                }
                key={option.path}
                value={option.path}>
                {option.title != null ? option.title : ""}
              </MenuItem>
            ))}
            {!this.state.open && this.state.propertyValue == null && <MenuItem value="" style={{ display: 'none' }}/>} 
            {!this.state.loading && this.state.options.size === 0 && 
              <MenuItem disabled value="emptyOptions">{this.props.t("emptyOptions")}</MenuItem>}
          </Select>
        </FormControl>
        {this.state.loading &&
          <Box className={classes.selectAction}><InlineLoading /></Box>
        }
        {!this.state.loading && canCreate &&
          <IconButton className={classes.addButton} size="small" onClick={() => newDocument()}>  
            <AddIcon />
          </IconButton>
        } 
        {!this.state.open && canClear &&
          <IconButton className={classes.selectAction} size="small" onClick={() => handleClear()}>
            <CloseIcon />
          </IconButton>
        }
        {!this.state.loading && canOpenOwner &&
          <IconButton className={classes.selectAction} size="small" onClick={() => showDocument()}>  
            <MoreHorizIcon />
          </IconButton>
        }        
      </Grid>
    );
  }
}

OwnerPropertyValue.contextType = AppContext;

const ModifiedOwnerPropertyValue = withTranslation()(withStyles(styles)(OwnerPropertyValue));

export default ModifiedOwnerPropertyValue;



