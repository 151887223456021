import React, { useContext } from 'react';

import { withTranslation, WithTranslation } from 'react-i18next';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core';

import DocumentView, { DocumentProps } from '../components/documentView';

import { PropertiesSelector } from 'services/database/api/core/propertiesSelector';

import DatabaseTable, { DatabaseTableProps } from 'ui/components/databaseTable';
import DatabaseList, { DatabaseListProps } from 'ui/components/databaseList';
import DatabaseCard, { DatabaseCardProps } from 'ui/components/databaseCard';
import DatabaseChart, { DatabaseChartProps } from 'ui/components/databaseChart';
import DatabaseGraph, { DatabaseGraphProps } from 'ui/components/databaseGraph';

import DatabaseView from '../components/databaseView';
import { LocationIF } from 'services/database/api/documents/locationIF';
import { CompanyDocument } from '../../services/database/api/documents';
import { log } from '../app/app';
import { HomePaths } from '../../services/common/api/homePaths';

import { DatabaseDocumentIF } from '../../services/database/api/core/databaseDocumentIF';
import { AppContext, AppContextProps } from '../app/appContext';
import { errorDialog } from '../components/simpleDialog';
import DatabaseMap, { DatabaseMapProps } from '../components/databaseMap';
import { DatabasePropertyIF } from '../../services/database/api/core/databasePropertyIF';
import { DatabaseQuery } from '../../services/database/api/core/databaseQuery';
import { LocationTypeDefinition, ReadinessLevelDefinition } from '../../services/database/api/definitions';
import { CategoriesCollection, ProjectsCollection, UnitsCollection } from '../../services/database/api/collections';
import { DatabaseViewType, DatabaseViewTypes } from '../components/databaseViewMenu';

export const defaultLocationTableColumns = [
  CompanyDocument,
  "title",
  LocationTypeDefinition,
  "premises",
  "devices"
];

export const defaultHighlightedLocationProperty = ReadinessLevelDefinition;


export function LocationsTable(props: DatabaseTableProps ) {

  return (
    <DatabaseTable {...props} 
      featuredProperties={props.featuredProperties != null ? props.featuredProperties : defaultLocationTableColumns }/>
  );
}

export function LocationsList(props: DatabaseListProps ) {

  return (
    <DatabaseList {...props} />
  );
}


export function LocationsCard( props: DatabaseCardProps ) {

  return (
    <DatabaseCard 
      {...props} 
      highlightedPropertyKey={props.highlightedPropertyKey != null ? props.highlightedPropertyKey : defaultHighlightedLocationProperty}
    />
  );
}

export function LocationsChart( props: DatabaseChartProps ) {

  return (
    <DatabaseChart 
      {...props} 
    />
  );
}

export function LocationsGraph( props: DatabaseGraphProps ) {

  return (
    <DatabaseGraph 
      {...props} 
      previousPropertyKey={"company"}
      nextPropertyKey={"devices"} 
      directed={false}
    />
  );
}

export function LocationsMap( props: DatabaseMapProps ) {

  const appContext = useContext(AppContext);

  return (
    <DatabaseMap 
      {...props} 
      geolocationPropertyKey={"geolocation"}
      statusPropertyKey={"status"}
      colorPropertyKey={ReadinessLevelDefinition} 
      iconPropertyKey={LocationTypeDefinition}
      defaultCountry={appContext.currentCompany?.country.value()}
    />
  );
}

export function LocationsView(props: { 
  databaseQuery : DatabaseQuery<LocationIF>,
  title? : string,
  featuredProperties?: string[],
  highlightedPropertyKey?: string }) {

  return ( <DatabaseView 
    databaseQuery={props.databaseQuery} 
    title={props.title}
    featuredProperties={props.featuredProperties != null ? props.featuredProperties : defaultLocationTableColumns }
    allowMap={true}
    defaultDatabaseViewType={DatabaseViewTypes.Map as DatabaseViewType}  
    highlightedPropertyKey={props.highlightedPropertyKey != null ? props.highlightedPropertyKey : defaultHighlightedLocationProperty}
    hideDateFilter={true}
    /> 
  );
}


const styles = (theme: Theme) => createStyles({});


interface LocationViewProps extends DocumentProps, WithStyles<typeof styles>, WithTranslation {

}

interface LocationViewState { // Document View Props

  databaseDocument: LocationIF,

  steps?: Map<string, PropertiesSelector>,

  showAllUnits: boolean,
}


class LocationView extends React.PureComponent<LocationViewProps,LocationViewState> {

  constructor( props: LocationViewProps ) {
    
    super(props);

    log.traceIn( "constructor()" );

    this.state = { 

      showAllUnits: false,

      databaseDocument: this.props.databaseDocument

    } as LocationViewState;

    this.readLocation = this.readLocation.bind(this);

    //log.traceOut( "constructor()" );
  }

  async componentDidMount() {

    try {
      log.traceIn( "componentDidMount()" );

      await this.readLocation();

      log.traceOut( "componentDidMount()" );
    } catch( error ) {
      log.warn( "componentDidMount()", "Error mounting document view", error );
      
      log.traceOut( "componentDidMount()", "error" );
    }
  }

  async componentDidUpdate() {

    try {
      //log.traceIn( "componentDidUpdate()");

      //log.traceOut( "componentDidUpdate()" );
    } catch( error ) {
      log.warn( "componentDidUpdate()", "Error mounting document view", error );
      
    }
  }

  async readLocation() {

    try {
      log.traceIn("readLocation()" );

      const databaseDocument = this.props.databaseDocument as LocationIF;

      this.setState( {

        databaseDocument: databaseDocument,

        showAllUnits: !databaseDocument.allUsers.value()
      })

      log.traceOut( "readLocation()", "state:", this.state );
      
    } catch( error ) {
      log.warn( "readLocation()", "Error mounting document view", error );

      await errorDialog( error );

      log.traceOut( "readLocation()", "error" );
    }
  }


  render() {

    const LocationBasicsFilter = {

      includePropertyKeys : [
        LocationTypeDefinition,
        "phoneNumber",
        "geolocation",
        "premises",
        "description"
      ],
  
    } as PropertiesSelector;
  
    const LocationStatusFilter = {

      includePropertyKeys : [
        ReadinessLevelDefinition,
        "status"
      ],
  
    } as PropertiesSelector;
  
    const LocationAllocationFilter = {

      includePropertyKeys: [
        CategoriesCollection,
        UnitsCollection, 
        ProjectsCollection,
        "allUsers"
      ]

    } as PropertiesSelector;

    const LocationAllUsersFilter = {

      includePropertyKeys: ["allUsers"]

    } as PropertiesSelector;

    const LocationUsersFilter = {
  
      includePropertyKeys: [
        "users"
      ]
    } as PropertiesSelector;
  
    const LocationAddressFilter = {
  
      includePropertyKeys: [
        "address"
      ]
    } as PropertiesSelector;
  

    const onDocumentChange = async (databaseDocument: DatabaseDocumentIF, 
      changedProperty? : DatabasePropertyIF<any> ): Promise<void> => {

      log.traceIn("onDocumentChange");

      try {

        const location = databaseDocument as LocationIF;

        const showAllUnits = !location.allUsers.value();

        log.debug("onDocumentChange", { showAllUnits }, this.state.showAllUnits);

        if (this.state.showAllUnits !== showAllUnits) {

          this.setState({ 
            databaseDocument: location,
            showAllUnits: showAllUnits
           });
        }

        if( changedProperty == null ) {

          log.traceIn("onDocumentChange", "no changed property");
          return;
        }

        if (LocationAllocationFilter.includePropertyKeys!.includes( changedProperty.key() ) ) {

          await location.updateUsers();
        }

      } catch (error) {
        log.warn("onDocumentChange()", error);

        await errorDialog(error);
      }


      log.traceOut("onDocumentChange");
    }

    const appContext = this.context as AppContextProps;

    let steps;
    
    if( this.props.inputTabs != null ) {
      steps = this.props.inputTabs;

    } 
    else {
      steps = new Map<string, PropertiesSelector>();

      steps.set("basics", LocationBasicsFilter);

      steps.set( "address", LocationAddressFilter);

      steps.set("status", LocationStatusFilter);
  
      if (appContext.currentHomePath !== HomePaths.UserHomePath) {

        if (this.state.showAllUnits) {
          steps.set("allocation", LocationAllocationFilter);
        }
        else {
          steps.set("allocation", LocationAllUsersFilter);
        }

        steps.set("users", LocationUsersFilter);
      }
    }

    //log.debug("render()", {steps} );
  
    return (<DocumentView
      {...this.props}
      databaseDocument={this.state.databaseDocument}
      inputTabs={steps}
      onDocumentChange={onDocumentChange}
    />);
  }
}

LocationView.contextType = AppContext;

const ModifiedLocationView = withTranslation()(withStyles(styles)(LocationView));

export default ModifiedLocationView; 


