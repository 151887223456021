import { DatabaseDocument } from "../../framework/databaseDocument";

import { DocumentsPropertyIF } from "../../api/properties/documentsPropertyIF";

import { DocumentsProperty } from "./documentsProperty";
import { DatabaseObject } from "../../framework/databaseObject";
import { PropertyType, PropertyTypes } from "../../api/definitions/propertyType"; 
import { Database } from "../core/database";

export class ReferencesProperty<DerivedDocument extends DatabaseDocument> 
    extends DocumentsProperty<DerivedDocument> implements DocumentsPropertyIF<DerivedDocument> {

 
    constructor( parent : DatabaseObject, 
        onSelectSources? : () => (Database<DerivedDocument> | undefined)[],
        reciprocalKey? : keyof DerivedDocument ) {

        super( PropertyTypes.References as PropertyType, parent, onSelectSources, reciprocalKey ); 
    }    


}
 