
import { Push } from "../../../services/messaging/framework/push/push";
import { PushReceiver } from "../../../services/messaging/framework/push/pushReceiver";
import { log } from "../../../services/messaging/framework/messagingService";
import { PushNotifications, ActionPerformed, PushNotificationSchema } from "@capacitor/push-notifications";

export class CapacitorPushReceiver extends PushReceiver {

    constructor() {

        super();
        log.traceIn( "constructor()");

        try {

            log.traceOut( "constructor()" );
            
        } catch( error ) {

            log.warn( "Error constructing Firebase push receiver", error );
            
            throw new Error( (error as any).message );
        }
    }

    async init(): Promise<void> {

        log.traceIn( "init()");

        try {
            await super.init();

            //log.traceOut( "init()" );

        } catch (error) {

            log.warn("Error initializing Firebase push receiver", error);

            throw new Error( (error as any).message );
        }
    }


    async register( callbacks?: { 
        onReceivePush? : ( push : Push ) => Promise<void>,
        onPushAction? : ( push : Push, actionId : string, actionValue? : string ) => Promise<void> } ) : Promise<string | undefined> {

        log.traceIn("register()");

        try {

            this._onReceivePush = callbacks?.onReceivePush;

            this._onPushAction = callbacks?.onPushAction;

            const permissionStatus = await PushNotifications.requestPermissions();

            if (permissionStatus.receive === 'denied') {

                log.warn("readPushToken()", "Permission to push denied");
                return undefined;
            }

            this._pushToken = await new Promise<string | undefined>(async (resolve, reject) => {

                PushNotifications.addListener('registration', (token) => {

                    log.debug("readPushToken()", 'registration', token.value);

              resolve( token.value );
    
            });
    
            PushNotifications.addListener('registrationError', ( error )  => {
    
              log.warn("readPushToken()", 'registrationError', error);
    
              reject( error );
            });
    
            log.debug("readPushToken()", "Added registration listener");
    
            await PushNotifications.register();
    
            log.debug("readPushToken()", "Registered for push notifications");
          });

            if (this._pushToken != null) {

                await PushNotifications.addListener('pushNotificationReceived', ( pushNotificationSchema : PushNotificationSchema) => {
                    log.debug("readPushToken()","Push notification received: ", pushNotificationSchema );
                    
                    const push = new Push( {
                        id: pushNotificationSchema.id, 
                        title: pushNotificationSchema.title, 
                        subtitle: pushNotificationSchema.subtitle, 
                        body: pushNotificationSchema.body, 
                        badge: pushNotificationSchema.badge, 
                        group: pushNotificationSchema.group, 
                        data: pushNotificationSchema.data?.payload != null ? JSON.parse( pushNotificationSchema.data.payload ) : undefined
                    });

                    if( this._onReceivePush != null ) {
                        this._onReceivePush( push );
                    }
                });

                await PushNotifications.addListener('pushNotificationActionPerformed', (actionPerformed : ActionPerformed) => {
                    log.debug("readPushToken()", "Push notification action performed", actionPerformed.actionId, actionPerformed.inputValue );

                    const pushNotificationSchema = actionPerformed.notification;

                    const push = new Push( {
                        id: pushNotificationSchema.id, 
                        title: pushNotificationSchema.title, 
                        subtitle: pushNotificationSchema.subtitle, 
                        body: pushNotificationSchema.body, 
                        badge: pushNotificationSchema.badge, 
                        group: pushNotificationSchema.group, 
                        data: pushNotificationSchema.data?.payload != null ? JSON.parse( pushNotificationSchema.data.payload ) : undefined
                    });

                    if( this._onPushAction != null ) {
                        this._onPushAction( push, actionPerformed.actionId, actionPerformed.inputValue );
                    }
                });
            }

          log.traceOut("readPushToken()", this._pushToken );
          return this._pushToken;
    
        } catch (error) {
            log.warn("readPushToken()", "Error reading push token", error);
    
            return undefined;
        }
    }

    private _pushToken? : string;

    private _onReceivePush? : ( push : Push ) => Promise<void>;

    private _onPushAction? : ( push : Push, actionId : string, actionValue? : string ) => Promise<void>;
}