import { LogLevel } from "../api/logLevel";
import { Logger } from "./logger";

export class ConsoleLogger extends Logger {

    error = this.errorEnabled() ?
        console.error.bind(console.error, "(" + LogLevel.Error + ")", "(" + this.name + ")") : Logger.ignore.bind(Logger.ignore,"");

    warn = this.warnEnabled() ?
        console.warn.bind(console.warn, "(" + LogLevel.Warn + ")", "(" + this.name + ")") : Logger.ignore.bind(Logger.ignore,"");

    info = this.infoEnabled() ?
        console.info.bind(console.info, "(" + LogLevel.Info + ")", "(" + this.name + ")") : Logger.ignore.bind(Logger.ignore,"");

    debug = this.debugEnabled() ? 
        console.debug.bind(console.debug, "(" + LogLevel.Debug + ")", "(" + this.name + ")") : Logger.ignore.bind(Logger.ignore,""); 
 
    traceIn = this.traceEnabled() ? 
        console.debug.bind(console.debug, "(" + LogLevel.Trace + ")", "(" + this.name + ")", "--> enter") : Logger.ignore.bind(Logger.ignore,"");
    
    traceOut = this.traceEnabled() ? 
        console.debug.bind(console.debug, "(" + LogLevel.Trace + ")", "(" + this.name + ")", "exit <--") : Logger.ignore.bind(Logger.ignore,"");

    traceInOut = this.traceEnabled() ? 
        console.debug.bind(console.debug, "(" + LogLevel.Trace + ")", "(" + this.name + ")", "enter <--> exit") : Logger.ignore.bind(Logger.ignore,"");
}