
export const TimeSpans = {

    Years    : "years",
    
    Quarters  : "quarters",

    Months    : "months",

    Weeks     : "weeks",

    Days      : "days",

    Hours    : "hours",

    Minutes  : "minutes",

    Seconds  : "seconds"

}

export type TimeSpan = keyof (typeof TimeSpans);

export const DefaultTimeSpan = TimeSpans.Months as TimeSpan;
