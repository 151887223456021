import { PushIF } from "../../api/push/pushIF";

export class Push implements PushIF {

    constructor( content: { 
        token? : string, 
        id? : string, 
        title? : string, 
        subtitle? : string, 
        body? : string, 
        group? : string, 
        badge? : number, 
        priority?: string,
        data? : any } ) {

        this.token = content.token;

        this.id = content.id;

        this.title = content.title;

        this.subtitle = content.subtitle;

        this.body = content.body;

        this.badge = content.badge;

        this.group = content.group;

        this.priority = content.priority;

        this.data = content.data;
    }

    readonly token?: string;

    readonly id?: string;

    readonly title?: string;

    readonly subtitle?: string;

    readonly body?: string;

    readonly badge?: number;

    readonly group? : string;

    readonly priority? : string;

    readonly data? : any;

}
