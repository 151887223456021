
import { FirebaseService } from "../../../application/framework/firebase/firebaseService";
import { Factory } from "../../../common/api/factory";
import { Monitor } from "../../../common/api/monitor";
import { Observation } from "../../../common/api/observation";

import { StorageMedia } from "../../api/storageMedia";
import { MediaManager } from "../../framework/mediaManager";
import { log } from "../../framework/storageService";


export class FirebaseMediaManager extends MediaManager {

    constructor() {

        super();

        //log.traceIn( "constructor()");

        try {

            //log.traceOut( "constructor()" );
            
        } catch( error ) {

            log.warn( "Error initializing media manager", error );
            
            throw new Error( (error as any).message );
        }
    }

    async init(): Promise<void> {

        //log.traceIn( "init()");

        try {

            //log.traceOut( "constructor()" );

        } catch (error) {

            log.warn("Error initializing image manager", error);

            throw new Error( (error as any).message );
        }
    }

    async upload( media: StorageMedia, monitor? : Monitor ): Promise<void> {

        log.traceIn( "upload()", {media} );

        try {

            if( media.data == null ) {
                throw new Error( "Empty data for media: " + {media} )
            }

            if( media.path == null ) {
                throw new Error( "Empty path for media: " + media.path )
            }

            const mediaReference = this.mediaReference( media.path );

            let uploadTask;

            if( media.data instanceof File || 
                media.data instanceof Blob ||
                media.data instanceof Uint8Array ) {

                uploadTask = mediaReference.put( media.data );
            }
            else {

                uploadTask = mediaReference.putString( media.data as string );
            }

            let uploadError;

            this._mediaTasks.set( media.path, uploadTask );

            if( monitor != null ) {

                await super.subscribe( monitor );

                uploadTask.on( "state_changed", async (snapshot : any) => {

                    this.updateMedia( media, snapshot );

                    await super.notify( Observation.Update, media.path, media );
                },
                (error : any )=> {
                    uploadError = error;
                });
            }

            const snapshot = await uploadTask;

            this._mediaTasks.delete( media.path );

            this.updateMedia( media, snapshot );

            if( uploadError != null ) {
                throw uploadError;
            }

            if( monitor != null ) {
                await super.unsubscribe( monitor );
            }

            log.traceOut( "upload()", {snapshot} );

        } catch (error) {

            log.warn("Error uploading media", error);

            throw new Error( (error as any).message );
        }
    }

    async downloadUrl( media: StorageMedia ): Promise<string | undefined> {
        log.traceIn( "downloadUrl()", media.path );

        try {
            const firebaseService = Factory.get().applicationService as FirebaseService;

            const storageRef = firebaseService.storage().ref();

            media.downloadUrl = await storageRef.child( media.path ).getDownloadURL();
                          
            log.traceOut( "downloadUrl()", media.downloadUrl );
            return media.downloadUrl;

        } catch (error) {

            log.warn("Error getting download URL", error);

            throw new Error( (error as any).message );
        }
    }

    async download( media: StorageMedia ): Promise<void> {
        log.traceIn( "download()");

        try {
            const firebaseService = Factory.get().applicationService as FirebaseService;

            const storageRef = firebaseService.storage().ref();

            media.downloadUrl = await storageRef.child( media.path ).getDownloadURL();
            
            // This can be downloaded directly:
            const xmlHttpRequest = new XMLHttpRequest();

            xmlHttpRequest.responseType = 'blob';
            xmlHttpRequest.onload = (event) => {

                media.data = xmlHttpRequest.response;

            };
            xmlHttpRequest.open('GET', media.downloadUrl! );

            xmlHttpRequest.send();
              
            log.traceOut( "download()" );

        } catch (error) {

            log.warn("Error downloading media", error);

            throw new Error( (error as any).message );
        }
    }

    async delete( path: string ): Promise<void> {

        log.traceIn( "delete()", {path} );

        try {

            if( path == null ) {
                throw new Error( "Empty path for media: " + {path} )
            }

            const mediaReference = this.mediaReference( path );

            await mediaReference.delete();

            log.traceOut( "delete()" );

        } catch (error) {

            log.warn("Error deleting media", error);

            throw new Error( (error as any).message );
        }
    }

    async cancelTask( media: StorageMedia ): Promise<void> {
        log.traceIn( "cancelTask()");

        try {

            const mediaTask = this._mediaTasks.get( media.path );

            if( mediaTask == null ) {
                throw new Error( "Ongoing task not found for: " + media.path )
            }

            mediaTask.cancel();

            this._mediaTasks.delete( media.path );

            log.traceOut( "cancelTask()" );

        } catch (error) {

            log.warn("Error canceling media task", error);

            throw new Error( (error as any).message );
        }
    }

    async pauseTask( media: StorageMedia ): Promise<void> {
        
        log.traceIn( "pauseTask()");

        try {

            const mediaTask = this._mediaTasks.get( media.path );

            if( mediaTask == null ) {
                throw new Error( "Ongoing task not found for: " + media.path )
            }

            mediaTask.pause();

            log.traceOut( "pauseTask()" );

        } catch (error) {

            log.warn("Error pausing media task", error);

            throw new Error( (error as any).message );
        }
    }

    async resumeTask( media: StorageMedia ): Promise<void> {
        log.traceIn( "resumeTask()");

        try {

            const mediaTask = this._mediaTasks.get( media.path );

            if( mediaTask == null ) {
                throw new Error( "Ongoing task not found for: " + media.path )
            }

            mediaTask.resumeTask();

            log.traceOut( "resumeTask()" );

        } catch (error) {

            log.warn("Error resuming media task", error);

            throw new Error( (error as any).message );
        }
    }

    private mediaReference( path : string ) : any {

        const firebaseService = Factory.get().applicationService as FirebaseService;

        const rootRef = firebaseService.storage().ref();
      
        const mediaRef = rootRef.child( path );

        return mediaRef;
    } 

    private updateMedia( media : StorageMedia, snapshot : any ) {

        media.bytesTransferred = snapshot.bytesTransferred;

        media.totalBytes = snapshot.totalBytes;

        media.metadata = snapshot.metadata;
    }

    private readonly _mediaTasks = new Map<string,any>();
      
}
