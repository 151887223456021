import { LoggerIF } from "../api/loggerIF";
import { LogLevel } from "../api/logLevel";

import { Target } from "../api/targets";

import loggingConfiguration from "../../../healthguard/data/settings/logging.json";


export abstract class Logger implements LoggerIF {

    static init( target : Target ) {

        Logger._target = target;
    }

    constructor( name : string, logLevel? : LogLevel ) {

        this.name = name;
        
        this._logLevel = logLevel;
    }

    logLevel() : LogLevel {

        if( this._logLevel != null ) {
            return this._logLevel;
        }


        if( Logger._target != null ) {

            const logLevel = (loggingConfiguration as any)[Logger._target]?.logLevel[this.name] != null ?
                (loggingConfiguration as any)[Logger._target].logLevel[this.name] : (loggingConfiguration.logLevel as any)[this.name];
   
   
            if( logLevel != null ) {            
                this._logLevel = logLevel;
                return logLevel;
            }

            const defaultLogLevel = (loggingConfiguration as any)[Logger._target]?.defaultLogLevel != null ?
                (loggingConfiguration as any)[Logger._target].defaultLogLevel : loggingConfiguration.defaultLogLevel;


            if( defaultLogLevel != null ) {            
                this._logLevel = defaultLogLevel;
                return defaultLogLevel;
            }

        }
        
        // This one will always work also before initilized
        return loggingConfiguration.defaultLogLevel as LogLevel;
    }

    errorEnabled() : boolean {
        return [LogLevel.Error,LogLevel.Warn,LogLevel.Info,LogLevel.Debug,LogLevel.Trace].includes( this.logLevel() );
    }

    warnEnabled() : boolean {
        return [LogLevel.Warn,LogLevel.Info,LogLevel.Debug,LogLevel.Trace].includes( this.logLevel() );
    }

    infoEnabled() : boolean {
        return [LogLevel.Info,LogLevel.Debug,LogLevel.Trace].includes( this.logLevel() );
    }

    debugEnabled() : boolean {
        return [LogLevel.Debug,LogLevel.Trace].includes( this.logLevel() );
    }

    traceEnabled() : boolean {
        return [LogLevel.Trace].includes( this.logLevel() );
    }


    abstract error( message?: any, ...optionalParams: any[] ) : void;

    abstract warn( message?: any, ...optionalParams: any[] ) : void;

    abstract info( message?: any, ...optionalParams: any[] ) : void;

    abstract debug( message?: any, ...optionalParams: any[] ) : void;

    abstract traceIn( message?: any, ...optionalParams: any[] ) : void;

    abstract traceOut( message?: any, ...optionalParams: any[] ) : void;

    abstract traceInOut( message?: any, ...optionalParams: any[] ) : void;

    protected static ignore( message?: any, ...optionalParams: any[] ) : void {}

    protected timestamp = () : string => {
        const date = new Date();
        return date.toISOString();
    }

    readonly name : string;

    protected _logLevel? : LogLevel;

    protected static _target : Target;

}