import React, { useContext } from 'react';

import DocumentView, { DocumentProps } from '../components/documentView';

import { PropertiesSelector } from 'services/database/api/core/propertiesSelector';

import DatabaseTable, { DatabaseTableProps } from 'ui/components/databaseTable';
import DatabaseList, { DatabaseListProps } from 'ui/components/databaseList';
import DatabaseCard, { DatabaseCardProps } from 'ui/components/databaseCard';
import DatabaseChart, { DatabaseChartProps } from 'ui/components/databaseChart';
import DatabaseGraph, { DatabaseGraphProps } from 'ui/components/databaseGraph';

import DatabaseView from '../components/databaseView';
import { ResidenceIF } from 'services/database/api/documents/residenceIF';
import { CompanyDocument, UnitDocument, UserDocument } from '../../services/database/api/documents';
import { AppContext } from '../app/appContext';
import DatabaseMap, { DatabaseMapProps } from '../components/databaseMap';
import { DatabaseQuery } from '../../services/database/api/core/databaseQuery';
import { ReadinessLevelDefinition } from '../../services/database/api/definitions';
import { DatabaseViewType, DatabaseViewTypes } from '../components/databaseViewMenu';

export const defaultResidenceTableColumns = [
  CompanyDocument, 
  UnitDocument,
  UserDocument,
  "title",
  "residenceType",
  "emergencyContactName",
  "emergencyContactNumber"];

export const defaultHighlightedResidencesProperty = "residenceType";

export function ResidencesTable(props: DatabaseTableProps ) {

  return (
    <DatabaseTable {...props} featuredProperties={props.featuredProperties != null ? props.featuredProperties : defaultResidenceTableColumns }/>
  );
}


export function ResidencesList(props: DatabaseListProps ) {

  return (
    <DatabaseList {...props} />
  );
}

export function ResidencesCard( props: DatabaseCardProps ) {
  return (
    <DatabaseCard 
      {...props} 
      highlightedPropertyKey={props.highlightedPropertyKey != null ? props.highlightedPropertyKey : defaultHighlightedResidencesProperty}
    />
  );
} 

export function ResidencesChart( props: DatabaseChartProps ) {
  return (
    <DatabaseChart 
      {...props} 
    />
  );
}



export function ResidencesGraph( props: DatabaseGraphProps ) {
  return (
    <DatabaseGraph 
      {...props} 
      previousPropertyKey={"unit"}
      nextPropertyKey={"user"}
      directed={false}
    />
  );
}

export function ResidencesMap( props: DatabaseMapProps ) {

  const appContext = useContext(AppContext);

  return (
    <DatabaseMap 
      {...props} 
      geolocationPropertyKey={"geolocation"}
      colorPropertyKey={ReadinessLevelDefinition}
      iconPropertyKey={"residenceType"}
      defaultCountry={appContext.currentCompany?.country.value()} 
    /> 
  );
}

export function ResidencesView(props: { 
  databaseQuery : DatabaseQuery<ResidenceIF>,
  title? : string,
  featuredProperties?: string[],
  highlightedPropertyKey?: string }) {

  return ( <DatabaseView 
    databaseQuery={props.databaseQuery} 
    title={props.title}
    featuredProperties={props.featuredProperties != null ? props.featuredProperties : defaultResidenceTableColumns }
    allowMap={true}
    highlightedPropertyKey={props.highlightedPropertyKey != null ? props.highlightedPropertyKey : defaultHighlightedResidencesProperty}
    hideDateFilter={true}
    defaultDatabaseViewType={DatabaseViewTypes.Map as DatabaseViewType}  
    /> 
  );
}



export function ResidenceView( props : DocumentProps ) {

  const ResidenceBasicsFilter = {

    includePropertyKeys : [
      "user",
      "residenceType",
      "geolocation",
      "description"],

  } as PropertiesSelector;

  const ResidenceContactInfoFilter = {

    includePropertyKeys : ["emergencyContactName","emergencyContactNumber"],

  } as PropertiesSelector;

  const ResidenceAddressFilter = {

    includePropertyKeys : ["address"],

  } as PropertiesSelector;

  const defaultResidenceSteps = () : Map<string,PropertiesSelector> => {

    let defaultSteps = new Map<string,PropertiesSelector>();

    defaultSteps.set( "basics", ResidenceBasicsFilter );
    defaultSteps.set( "address", ResidenceAddressFilter );
    defaultSteps.set( "contactInfo", ResidenceContactInfoFilter );


    return defaultSteps;
  }

  return ( <DocumentView 
    {...props}
    inputTabs={props.inputTabs != null ? props.inputTabs : defaultResidenceSteps()}
    /> );
}


