
export const Periods = {

    ThisDay       : "thisDay",

    NextDay          : "nextDay",

    NextDays         : "nextDays",

    LastDay          : "lastDay",

    LastDays         : "lastDays",

    ThisWeek       : "thisWeek",

    NextWeek          : "nextWeek",

    NextWeeks         : "nextWeeks",

    LastWeek          : "lastWeek",

    LastWeeks         : "lastWeeks",

    ThisMonth       : "thisMonth",

    NextMonth          : "nextMonth",

    NextMonths         : "nextMonths",

    LastMonth          : "lastMonth",

    LastMonths         : "lastMonths",

    ThisQuarter       : "thisQuarter",

    NextQuarter          : "nextQuarter",

    NextQuarters         : "nextQuarters",

    LastQuarter          : "lastQuarter",

    LastQuarters         : "lastQuarters",

    ThisYear       : "thisYear",

    NextYear          : "nextYear",

    NextYears         : "nextYears",

    LastYear          : "lastYear",

    LastYears         : "lastYears" 

}

export type Period = keyof (typeof Periods);
