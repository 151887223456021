
import { log } from "../../services/database/framework/databaseService";


import { AlertsCollection, CategoriesCollection, ChangesCollection, CompaniesCollection, DevicesCollection, KeysCollection, LocationsCollection, MessagesCollection, ProjectsCollection, SubscriptionsCollection, TerminalsCollection, UnitsCollection, UsersCollection } from "../../services/database/api/collections";
import { RegistrationsCollection  } from "../api/registrationCollections";
import { HazardsCollection, GatheringsCollection, RisksCollection, MeasuresCollection, ConsentsCollection  } from "../api/healthguardCollections";
 
import { User } from "../../services/database/impl/documents/user";

import { DatabaseAccess } from "../../services/database/framework/databaseAccess";
import { HealthguardUserDatabaseAccessIF } from "../api/healthguardUserDatabaseAccessIF";
import { IncidentRegistrationDocument } from "../api/registrationDocuments";
import { Factory } from "../../services/common/api/factory";
import { DatabaseFactory } from "../../services/database/framework/databaseFactory";
import { UserAccess } from "../../services/common/api/userAccess";
import { HealthguardCompany } from "../impl/documents/healthguardCompany";

export class HealthguardUserDatabaseAccess extends DatabaseAccess implements HealthguardUserDatabaseAccessIF {

    constructor( databaseFactory : DatabaseFactory ) {

        super();

        this._databaseFactory = databaseFactory;
        
        //log.traceIn( "constructor()");

        try {
            // Configuration data
  
            //log.traceOut( "constructor()");

        } catch( error ) {
            log.warn( "constructor()", "Error initializing healthguard database access", error );

            throw new Error( (error as any).message );
        }
    }

    userAccess( databasePath : string ) : UserAccess {

        //log.traceIn( "userAccess()", {databasePath});

        try {

            const authenticationClaims = Factory.get().authenticationService!.authenticationClaims();

            if( authenticationClaims == null || 
                authenticationClaims.userPath == null ) {

                //log.traceOut( "userAccess()", "No claims, allow none");
                return UserAccess.allowNone();
            }

            let authenticatedUser = Factory.get().authenticationService?.authenticatedUser() as User;

            if( authenticatedUser == null ) {

                authenticatedUser = 
                    this._databaseFactory.newDocumentFromUrl( authenticationClaims.userPath ) as User;
            }

            if( authenticatedUser == null ||
                !this._databaseFactory.equalDatabasePaths( authenticatedUser.databasePath(), authenticationClaims.userPath ) ) {

                //log.traceOut( "userAccess()", "No authenticated user, allow none");
                return UserAccess.allowNone();
            }

            let defaultAccess = this.defaultAccess( databasePath, authenticatedUser );

            let systemAdminAccess = UserAccess.allowNone();

            if( !!authenticationClaims.systemAdmin ) {

                systemAdminAccess = this.systemAdminAccess( databasePath, authenticatedUser );
            }

            let companyAccess = UserAccess.allowNone();

            if( authenticationClaims.companiesAdmin != null ) {

                companyAccess = this.companyAdminAccess( databasePath, authenticatedUser );
            }
            else {
                companyAccess = this.companyUserAccess( databasePath, authenticatedUser );
            }

            let unitAdminAccess = UserAccess.allowNone();

            if( authenticationClaims.unitsAdmin != null ) { 

                unitAdminAccess = this.unitAdminAccess( databasePath, authenticatedUser );
            }

            let parentUserAccess = UserAccess.allowNone();

            if( authenticationClaims.childUserIds != null && authenticationClaims.childUserIds.length > 0 ) {

                parentUserAccess = this.parentUserAccess( databasePath, authenticatedUser );
            }

            let leafUserAccess = UserAccess.allowNone();

            if( !!authenticationClaims.minor || !authenticationClaims.adult ) { 

                leafUserAccess = this.minorUserAccess( databasePath, authenticatedUser );
            }
            else {

                leafUserAccess = this.regularUserAccess( databasePath, authenticatedUser ); 
            }

            const allowList = defaultAccess.allowList ||
                systemAdminAccess.allowList || 
                companyAccess.allowList ||
                unitAdminAccess.allowList ||
                parentUserAccess.allowList ||
                leafUserAccess.allowList;

            const allowCreate = defaultAccess.allowCreate ||
                systemAdminAccess.allowCreate || 
                companyAccess.allowCreate ||
                unitAdminAccess.allowCreate ||
                parentUserAccess.allowCreate ||
                leafUserAccess.allowCreate;

            const allowRead = defaultAccess.allowRead ||
                systemAdminAccess.allowRead || 
                companyAccess.allowRead ||
                unitAdminAccess.allowRead ||
                parentUserAccess.allowRead ||
                leafUserAccess.allowRead;

            const allowUpdate = defaultAccess.allowUpdate ||
                systemAdminAccess.allowUpdate || 
                companyAccess.allowUpdate ||
                unitAdminAccess.allowUpdate ||
                parentUserAccess.allowUpdate ||
                leafUserAccess.allowUpdate;

            const allowDelete = defaultAccess.allowDelete ||
                systemAdminAccess.allowDelete || 
                companyAccess.allowDelete ||
                unitAdminAccess.allowDelete ||
                parentUserAccess.allowDelete ||
                leafUserAccess.allowDelete;

            const userAccess = new UserAccess( allowList, allowCreate, allowRead, allowUpdate, allowDelete );

            //log.traceOut( "userAccess()", {userAccess} );
            return userAccess;

        } catch( error ) {
            log.warn( "userAccess()", "Error reading user access", error );

            return UserAccess.allowNone();
        }
    }

    private defaultAccess( databasePath : string, authenticatedUser : User ) : UserAccess {

        const collectionName = this._databaseFactory.collectionNameFromUrl( databasePath );

        switch( collectionName ) {

            case HazardsCollection:

                return UserAccess.allowReadOnly();
        }   
        return UserAccess.allowNone();
    }

    private systemAdminAccess( databasePath : string, authenticatedUser : User ) : UserAccess {

        //log.traceInOut( "systemAdminAccess()", "allow all" );

        return UserAccess.allowAll();
    }

    private companyAdminAccess( databasePath : string, authenticatedUser : User ) : UserAccess {

        //log.traceIn( "companyAdminAccess()", {databasePath} );

        const companyPath = authenticatedUser.company.referenceHandle()!.path;
        
        if( this._databaseFactory.equalDatabasePaths( databasePath, companyPath ) ) {

            //log.traceOut( "companyAdminAccess()", "allow update");
            return UserAccess.allowUpdate();
        }

        const collectionName = this._databaseFactory.collectionNameFromUrl( databasePath );

        if( this._databaseFactory.trimmedDatabasePath( databasePath )!.startsWith( 
            this._databaseFactory.trimmedDatabasePath( companyPath )! ) ) {
        
            switch( collectionName ) {

                case ChangesCollection:

                    //log.traceOut( "companyAdminAccess()", "allow create and read ");
                    return new UserAccess( true, true, true, false, false );

                case CompaniesCollection:

                    //log.traceOut( "companyAdminAccess()", "allow create and read ");
                    return new UserAccess( true, false, true, true, false );

                case ConsentsCollection:

                    //log.traceOut( "companyAdminAccess()", "allow list, create, update and read ");
                    return new UserAccess( true, true, true, true, false );

                case KeysCollection:

                    //log.traceOut( "companyAdminAccess()", "allow list, create, update and read ");
                    return new UserAccess( true, true, true, false, false );

                case SubscriptionsCollection:

                    ///log.traceOut( "companyAdminAccess()", "allow read only");
                    return UserAccess.allowReadOnly();

                default: 
                    //log.traceOut( "companyAdminAccess()", "allow all");
                    return UserAccess.allowAll();
            }
        }

        //log.traceOut( "companyAdminAccess()", "allow none");
        return UserAccess.allowNone();
    }

    private companyUserAccess( databasePath : string, authenticatedUser : User ) : UserAccess {

        //log.traceIn( "companyUserAccess()", {databasePath} );

        const collectionName = this._databaseFactory.collectionNameFromUrl( databasePath );

        const documentName = this._databaseFactory.documentNameFromUrl( databasePath );

        const companyReferenceHandle = authenticatedUser.company.referenceHandle();

        if( companyReferenceHandle != null ) {

            const companyPath = companyReferenceHandle.path;

            if( this._databaseFactory.equalDatabasePaths( databasePath, companyPath ) ) {
    
                //log.traceOut( "regularUserAccess()", "allow read");
                return UserAccess.allowReadOnly();
            }

            if( this._databaseFactory.trimmedDatabasePath( databasePath )!.startsWith( 
                this._databaseFactory.trimmedDatabasePath( companyPath )! ) ) {
    
                switch( collectionName ) {
    
                    case SubscriptionsCollection:
    
                        //log.traceOut( "companyUserAccess()", "company", "allow none" );
                        return UserAccess.allowNone();

                    case RegistrationsCollection: {

                        if (!databasePath.includes("/" + UsersCollection + "/")) {
                            // Anonymous registrations

                            //log.traceOut( "companyUserAccess()", "user", "allow all" );
                            return UserAccess.allowAll();
                        }
                        else if (documentName === IncidentRegistrationDocument) {
                            //log.traceOut( "companyUserAccess()", "company", "allow read only" );
                            return UserAccess.allowReadOnly(); 
                        }
                        else {
                            //log.traceOut( "regularUserAccess()", "company", "allow none" );
                            return UserAccess.allowNone();
                        }
                    }
                    case UnitsCollection:
                    case UsersCollection:
                    case HazardsCollection:
                    case DevicesCollection:
                    case AlertsCollection:
                    case CategoriesCollection:
                    case CompaniesCollection:
                    case LocationsCollection:
                    case MeasuresCollection:
                    case ProjectsCollection:
                    case GatheringsCollection:
                    case RisksCollection:
    
                        //log.traceOut( "companyUserAccess()", "company", "allow read only" );
                        return UserAccess.allowReadOnly();    
                }
            }
        }

        //log.traceOut( "companyUserAccess()", "allow nome" );
        return UserAccess.allowNone();
    }


    private unitAdminAccess( databasePath : string, authenticatedUser : User ) : UserAccess {

        //log.traceIn( "unitAdminAccess()", {databasePath}, {authenticatedUser} );

        const currentUnit =  Factory.get().databaseService.currentUnit();

        const collectionName = this._databaseFactory.collectionNameFromUrl( databasePath );

        const unitReferenceHandle = authenticatedUser.unit.referenceHandle();

        const userDocumentPath = this._databaseFactory.documentPathFromUrl( databasePath, UsersCollection );

        if( userDocumentPath != null && currentUnit != null ) { 

            //log.debug( "unitAdminAccess()", {userDocumentPath}, {currentUnit}  );

            if( currentUnit.symbolicUsers.hasDocument( userDocumentPath ) ) {

                switch (collectionName) 
                {
                    case UsersCollection:

                        //log.traceOut( "unitAdminAccess()", "users", "allow list, update and read ");
                        return new UserAccess( true, false, true, true, false ); 

                    default:  
                        //log.traceOut( "unitAdminAccess()", "default", "allow create, update, read");
                        return new UserAccess( true, true, true, true, false ); 
                }
            }
        }

        const managingUnitReferenceHandles = authenticatedUser.managing.referenceHandles();

        //log.debug( "unitAdminAccess()", {unitReferenceHandle}, {managingUnitReferenceHandles} );

        for( const managingUnitReferenceHandle of managingUnitReferenceHandles.values() ) {

            const managingUnitPath = managingUnitReferenceHandle.path;

            if( this._databaseFactory.equalDatabasePaths( databasePath, managingUnitPath ) ) {

                if( unitReferenceHandle != null && 
                    this._databaseFactory.equalDatabasePaths( unitReferenceHandle.path, managingUnitPath ) ) {

                    //log.traceOut( "unitAdminAccess()", "managing own unit, only allow update");
                    return UserAccess.allowUpdate();
                }
                else {
                    //log.traceOut( "unitAdminAccess()", "managing subunit, only allow all");
                    return UserAccess.allowAll();
                }
            }

            if( this._databaseFactory.trimmedDatabasePath( databasePath )!.startsWith( 
                this._databaseFactory.trimmedDatabasePath( managingUnitPath )! ) ) {

                switch (collectionName) {

                    case ChangesCollection:

                        //log.traceOut( "unitAdminAccess()", "user", "allow create and read only" );
                        return new UserAccess(true, true, true, false, false);

                    case ConsentsCollection:

                        //log.traceOut( "unitAdminAccess()", "allow list, create, update and read ");
                        return new UserAccess( true, true, true, true, false );

                    default: 

                        //log.traceOut( "unitAdminAccess()", "from unit", "allow all" );
                        return UserAccess.allowAll(); 
                }
            }
        }

        //log.traceOut( "unitAdminAccess()", "allow none" );
        return UserAccess.allowNone();
    }

    private parentUserAccess( databasePath : string, authenticatedUser : User ) : UserAccess {

        //log.traceIn( "parentUserAccess()", {databasePath} );

        const collectionName = this._databaseFactory.collectionNameFromUrl( databasePath );

        const documentName = this._databaseFactory.documentNameFromUrl( databasePath );

        const userPath = authenticatedUser.databasePath( false );

        if( databasePath.startsWith( userPath ) + "/" + UsersCollection ) {

            switch( collectionName ) {

                case ChangesCollection:

                    //log.traceOut( "regularUserAccess()", "user", "allow everything but delete" );
                    return new UserAccess( true, true, true, false, false );

                case ConsentsCollection:

                    //log.traceOut( "unitAdminAccess()", "allow list, create, update and read ");
                    return new UserAccess( true, true, true, true, false );

                case RegistrationsCollection: 
                {
                    if( documentName == null ) {
                        return UserAccess.allowReadOnly();
                    }

                    const disabled = (Factory.get().databaseService.currentCompany() as HealthguardCompany)?.subcollectionDisabled( 
                        documentName );
            
                    if( !!disabled ) {
                        return UserAccess.allowNone();
                    }
            
                    const userAccess = (Factory.get().databaseService.currentCompany() as HealthguardCompany)?.subcollectionUserAccess( 
                        documentName );
            
                    return userAccess;
                }
                case UsersCollection:

                    //log.traceOut( "regularUserAccess()", "user", "allow update" );
                    return UserAccess.allowUpdate();

                default: 
                    //log.traceOut( "regularUserAccess()", "user", "allow all" );
                    return UserAccess.allowAll();
            } 
        }        

        //log.traceOut( "parentUserAccess()", "allow all" );
        return UserAccess.allowNone();
    }


    private regularUserAccess( databasePath : string, authenticatedUser : User ) : UserAccess {

        //log.traceIn( "regularUserAccess()", {databasePath} );

        const collectionName = this._databaseFactory.collectionNameFromUrl( databasePath );

        const documentName = this._databaseFactory.documentNameFromUrl( databasePath );

        const userPath = authenticatedUser.databasePath( false );

        if( databasePath.startsWith( userPath ) ) {

            switch( collectionName ) {

                case ChangesCollection:

                    //log.traceOut( "regularUserAccess()", "user", "allow everything but delete" );
                    return new UserAccess( true, true, true, false, false );

                case ConsentsCollection:

                    //log.traceOut( "unitAdminAccess()", "allow list, create, update and read ");
                    return new UserAccess( true, true, true, true, false );

                case RegistrationsCollection: 
                {
                    if( documentName == null ) {
                        return UserAccess.allowReadOnly();
                    }

                    const disabled = (Factory.get().databaseService.currentCompany() as HealthguardCompany)?.subcollectionDisabled( 
                        documentName );
            
                    if( !!disabled ) {
                        return UserAccess.allowNone();
                    }
            
                    const userAccess = (Factory.get().databaseService.currentCompany() as HealthguardCompany)?.subcollectionUserAccess( 
                        documentName );
            
                    return userAccess;
                }

                case UsersCollection:

                    //log.traceOut( "regularUserAccess()", "user", "allow update" );
                    return UserAccess.allowUpdate();

                default: 
                    //log.traceOut( "regularUserAccess()", "user", "allow all" );
                    return UserAccess.allowAll();
            }
        }

        //log.traceOut( "regularUserAccess()", "allow nome" );
        return UserAccess.allowNone();
    }

    private minorUserAccess( databasePath : string, authenticatedUser : User ) : UserAccess {

        //log.traceIn( "minorUserAccess()", {databasePath} );

        const userPath = authenticatedUser.databasePath( false );

        const collectionName = this._databaseFactory.collectionNameFromUrl( databasePath );

        const documentName = this._databaseFactory.documentNameFromUrl( databasePath );

        if( databasePath.startsWith( userPath ) ) {

            switch( collectionName ) {

                case RegistrationsCollection: 
                {
                    if( documentName == null ) {
                        return UserAccess.allowReadOnly();
                    }

                    const disabled = (Factory.get().databaseService.currentCompany() as HealthguardCompany)?.subcollectionDisabled( 
                        documentName );
            
                    if( !!disabled ) {
                        return UserAccess.allowNone();
                    }
            
                    const userAccess = (Factory.get().databaseService.currentCompany() as HealthguardCompany)?.subcollectionUserAccess( 
                        documentName );
            
                    return userAccess.allowRead ? UserAccess.allowReadOnly() : UserAccess.allowNone();
                }

                case MessagesCollection: 
                case TerminalsCollection: 
                {
                    return new UserAccess( true, true, true, true, false );
                }
                default: 
                    //log.traceOut( "regularUserAccess()", "user", "allow readonly" );
                    return UserAccess.allowReadOnly();
            }
        }
        //log.traceOut( "minorUserAccess()", "allow nome" );
        return UserAccess.allowNone();
    }


    private readonly _databaseFactory : DatabaseFactory;


}
