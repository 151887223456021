import React from 'react';
import ReactDOMServer from 'react-dom/server';

import {unescape} from 'html-escaper';

import { DatabaseDocumentIF } from '../../services/database/api/core/databaseDocumentIF';
import { WithTranslation, withTranslation } from 'react-i18next';
import { UserIF } from '../../services/database/api/documents/userIF';
import { OwnerPropertyIF, UsersCollection } from '../../services/database';
import { translatedPropertyValue } from './propertyValue';
import { translatedDocumentName } from './documentName';


export const translatedDocumentTitle = ( databaseDocument : DatabaseDocumentIF, propertyKey? : string ) => { 

  //log.traceIn( "translatedDocumentTitle()", this.props.collectionName);

  if( databaseDocument.title.value() != null ) {
    return databaseDocument.title.value()!;
  }
  else {
    return defaultDocumentTitle( databaseDocument, propertyKey );
  }
}

export const userTitle = ( user? : UserIF ) : string => {
  
  if( user == null ) {
    return "";
  }

  let title = "";

  if( user.firstName.value() != null ) {
      title = user.firstName.value()!;
  }

  if( user.lastName.value() != null ) {
      
      if( title != null ) {
          title += " " + user.lastName.value()!;
      }
      else {
          title = user.lastName.value()!;
      }
  }

  if( title == null && user.title.value() == null ) {
      // default to email
      title = user.email.value()!;
  }

  return title;
}

export const defaultDocumentTitle = ( databaseDocument : DatabaseDocumentIF, highlightedProperty? : string ) => {

  return unescape( ReactDOMServer.renderToString( 
    <ModifiedDocumentTitle 
      databaseDocument={databaseDocument} 
      highlightedProperty={highlightedProperty}
    />
  ) );

}

interface DocumentTitleProps extends WithTranslation {
  databaseDocument : DatabaseDocumentIF,
  highlightedProperty? : string
}


interface DocumentTitleState { 
}

class DocumentTitle extends React.PureComponent<DocumentTitleProps,DocumentTitleState> {

  constructor( props: DocumentTitleProps ) {
    
    super(props);

    this.state = { 
    } as DocumentTitleState;

    //log.traceInOut( "constructor()" );
  }
  
  render() {

    //log.traceInOut( "render()" );

    let title = translatedDocumentName(this.props.databaseDocument.documentName());

    if (this.props.databaseDocument.collectionDatabase.collectionName()=== UsersCollection) {
      title = userTitle(this.props.databaseDocument as UserIF);
      return title;
    }

    if (this.props.highlightedProperty != null) {

      const property = this.props.databaseDocument.property(this.props.highlightedProperty);

      if (property != null && property.value() != null) {
        title += " (" + translatedPropertyValue(this.props.databaseDocument.documentName(), property.value() ) + ")";
      }
    }

    const userOwnerProperty = this.props.databaseDocument.property("user") as OwnerPropertyIF<UserIF>;

    //log.debug( "defaultDocumentTitle()", Object.assign( {}, userOwnerProperty ) );

    if (userOwnerProperty != null) {

      const userTitle = userOwnerProperty.title();

      if (userTitle != null) {
        title += ": " + userTitle;
      }
    }

    if (this.props.databaseDocument.startDate.value() != null) {
      title += " - " + this.props.databaseDocument.startDate.value()!.toLocaleDateString();
    }

    //log.traceOut( "defaultDocumentTitle()", {title} );
    return title;
  }
}


const ModifiedDocumentTitle = withTranslation()(DocumentTitle);

export default ModifiedDocumentTitle;


