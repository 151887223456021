
export const Platforms = {

    Firebase             : "firebase",

    Realm                : "realm"
}

export type Platform = keyof (typeof Platforms);

export const FirebasePlatform= Platforms.Firebase as Platform;

export const RealmPlatform = Platforms.Realm as Platform;




