
export const TestTypes = {

    PCR :           "pcr",

    AntiGen :       "antiGen",

    Blood :         "blood",

    Urine :         "urine",

    Breathalyzer :  "breathalyzer",

    BloodPressure : "bloodPressure",

    Temperature : "temperature"

}

export type TestType = keyof (typeof TestTypes);


