

import { Factory } from "../../common/api/factory";
import { LoggerFactory } from "../../common/api/loggerFactory";
import { Monitor } from "../../common/api/monitor";
import { Observation } from "../../common/api/observation";
import { Target } from "../../common/api/targets";
import { Observable } from "../../common/impl/observable";
import { ConfigurationServiceIF } from "../api/configurationServiceIF";
import { ConfigurationManager } from "./configurationManager";

export const log = LoggerFactory.logger("configuration");

export class ConfigurationService extends Observable implements ConfigurationServiceIF {

    constructor( target : Target, configurationManager : ConfigurationManager ) {

        super();

        //log.traceInOut("constructor()", target );

        try {

            this.target = target;

            this.configurationManager = configurationManager;

        } catch (error) {
            log.warn("constructor()", "Error constructing configuration service", error);

            throw new Error( (error as any).message );
        }
    }

    async init() : Promise<void> {

        log.traceInOut("init()" );

        try {


        } catch (error) {
            log.warn("init()", "Error initializing configuration service", error);

            throw new Error( (error as any).message );
        }
    }

    config( configData : any, key? : string, language? : string ) : any {

        try {

            const target = Factory.get().target;

            let data;

            if (typeof configData === 'object' ) {

                data = configData;
            } 
            else if (typeof configData === 'string') {
    
                try {
                    data = JSON.parse( configData );
                } catch( error ) {
                    data = configData;
                }
            }

            let targetData = data[target];

            if( targetData != null ) {

                if( key != null ) {

                    key.split('.').forEach( key => {
                        if( targetData != null ) {
                            targetData = targetData[key]
                        }
                    });
                }

                if( targetData != null ) {

                    if( language != null && targetData[language] != null ) {
                        return targetData[language];
                    }
                    else {
                        return targetData;
                    }
                }
            }

            let genericData = data;
 
            if( key != null ) {
                key.split('.').forEach(key => {
                    if( genericData != null ) {
                        genericData = genericData[key];
                    }
                });
            }

            if( genericData == null ) {
                throw new Error( "Configuration item not found");
            }

            if( language != null && genericData[language] != null ) {
                return genericData[language];
            }
            else {
                return genericData;
            }

        } catch (error) {

            log.warn( "Error reading configuration key: " + key, error );

            throw new Error( (error as any).message );
        }
    }


    protected async monitor( newMonitor : Monitor ): Promise<void> {}

    protected async release(observationFilter?: Observation[], objectIdsFilter?: string[]): Promise<void> {}

    readonly target : Target;

    readonly configurationManager : ConfigurationManager;

}
