export const TextTypes = {

    Definition: "definition",

    Text: "text",

    Email: "email",

    Password: "password",

    Url: "url"
}

export type TextType = keyof (typeof TextTypes);

export const DefaultTextType = TextTypes.Text as TextType;