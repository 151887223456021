import React from 'react';

import DocumentView, { DocumentProps } from '../components/documentView';

import { PropertiesSelector } from 'services/database/api/core/propertiesSelector';

import DatabaseTable, { DatabaseTableProps, StartDateProperty } from 'ui/components/databaseTable';
import DatabaseList, { DatabaseListProps } from 'ui/components/databaseList';
import DatabaseCard, { DatabaseCardProps } from 'ui/components/databaseCard';
import DatabaseChart, { DatabaseChartProps } from 'ui/components/databaseChart';
import DatabaseGraph, { DatabaseGraphProps } from 'ui/components/databaseGraph';

import DatabaseView from '../components/databaseView';
import { VaccineRegistrationIF } from 'healthguard/api/documents/vaccineRegistrationIF';
import { CompanyDocument, UnitDocument, UserDocument } from '../../services/database/api/documents';
import { HazardDocument } from '../../healthguard/api/healthguardDocuments';
import { DatabaseQuery } from '../../services/database/api/core/databaseQuery';
import { MeasuresCollection } from '../../healthguard/api/healthguardCollections';

export const DefaultVaccineRegistrationTableColumns = [
  StartDateProperty,
  CompanyDocument,
  UnitDocument,
  UserDocument,
  HazardDocument,
  "type", 
  "installment"]; 

export const defaultHighlightedVaccineRegistrationsProperty = "type";


export function VaccineRegistrationsTable(props: DatabaseTableProps ) {

  if( props.featuredProperties == null ) {
    props.featuredProperties = DefaultVaccineRegistrationTableColumns;
  }
  return (
    <DatabaseTable {...props} featuredProperties={props.featuredProperties != null ? props.featuredProperties : DefaultVaccineRegistrationTableColumns }/>
  );
}

export function VaccineRegistrationsList(props: DatabaseListProps ) {

  return (
    <DatabaseList {...props} />
  );
}



export function VaccineRegistrationsCard( props: DatabaseCardProps ) {
  return (
    <DatabaseCard 
      {...props} 
      highlightedPropertyKey={props.highlightedPropertyKey != null ? props.highlightedPropertyKey : defaultHighlightedVaccineRegistrationsProperty}
    />
  );
} 

export function VaccineRegistrationsChart( props: DatabaseChartProps ) {
  return (
    <DatabaseChart 
      {...props} 
    />
  );
}


export function VaccineRegistrationsGraph( props: DatabaseGraphProps ) {
  return (
    <DatabaseGraph 
      {...props} 
      previousPropertyKey={"type"}
      nextPropertyKey={"user"}
      directed={false}
    />
  );
}


export function VaccineRegistrationsView(props: { 
  databaseQuery : DatabaseQuery<VaccineRegistrationIF>,
  title? : string,
  featuredProperties?: string[], 
  highlightedPropertyKey?: string }) {

  return ( 
    <DatabaseView 
      databaseQuery={props.databaseQuery} 
      title={props.title}
      featuredProperties={props.featuredProperties != null ? props.featuredProperties : DefaultVaccineRegistrationTableColumns }
      highlightedPropertyKey={props.highlightedPropertyKey != null ? props.highlightedPropertyKey : defaultHighlightedVaccineRegistrationsProperty}
    /> );
}
 

export function VaccineRegistrationView( props : DocumentProps ) {

  const VaccineRegistrationBasicsFilter = {

    includePropertyKeys: [
      UnitDocument,
      UserDocument,
      MeasuresCollection,
      HazardDocument,
      "startDate",
      "type",
      "installment",
      "attachments"
    ]
  
  } as PropertiesSelector;
  
  /*
  const VaccineAssociationsFilter = {

    includePropertyKeys: [
      "measures",
      "locations",
      "categories",
      "projects"]
  
  } as PropertiesSelector;

  */

  const defaultVaccineRegistrationSteps = () : Map<string,PropertiesSelector> => {
  
    let defaultSteps = new Map<string,PropertiesSelector>();
  
    defaultSteps.set( "basics", VaccineRegistrationBasicsFilter );
    //defaultSteps.set( "associations", VaccineAssociationsFilter );
  
    return defaultSteps;
  }
  
  return ( <DocumentView 
    {...props}
    inputTabs={props.inputTabs != null ? props.inputTabs : defaultVaccineRegistrationSteps()}
  /> );
}


