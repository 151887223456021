
export const ResidenceTypes = {
    
    SingleFamilyHome  : "singleFamilyHome",

    Apartment         : "apartment",

    Cabin             : "cabin",

    Hotel            : "hotel",

    ShortTermRental  : "shortTermRental",

    Barracks         : "barracks"
}

export type ResidenceType = keyof (typeof ResidenceTypes);


