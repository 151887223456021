import { DatabaseObject } from "./databaseObject";
import { CollectionDatabase } from "../impl/core/collectionDatabase";
import { Factory } from "../../common/api/factory";
import { Monitor } from "../../common/api/monitor";
import { ObservableIF } from "../../common/api/observableIF";
import { Observation } from "../../common/api/observation";
import { UserAccess } from "../../common/api/userAccess";
import { DatabaseDocumentIF } from "../api/core/databaseDocumentIF";
import { DatabaseFilter } from "../api/core/databaseFilter";
import { Comparators } from "../api/definitions/comparator";
import { DateProperty } from "../impl/properties/dateProperty";
import { TextProperty } from "../impl/properties/textProperty";
import { User } from "../impl/documents/user";
//import { MapProperty } from "../impl/properties/mapProperty";
import { Change } from "../impl/documents/change";
import { CollectionProperty } from "../impl/properties/collectionProperty";
import { PropertiesSelector } from "../api/core/propertiesSelector";
import { PropertyType, PropertyTypes } from "../api/definitions/propertyType";
import { ReferenceProperty } from "../impl/properties/referenceProperty";
import { BooleanProperty } from "../impl/properties/booleanProperty";
import { ChangesCollection } from "../api/collections";
import { DocumentNameKey, NewDocumentId, OwnerIds } from "../api/core/databaseServiceIF";
import { CollectionGroupDatabase } from "../impl/core/collectionGroupDatabase";
import { ReferenceHandle } from "..";
import { LinksProperty } from "../impl/properties/linksProperty";
import { AttachmentsProperty } from "../impl/properties/attachmentsProperty";
import { DefaultTextType} from "../api/definitions/textTypes";
import { MediaType, MediaTypes } from "../../storage/api/mediaType";
import { log } from "./databaseService";
import { Database } from "../impl/core/database";
import { SymbolicOwnersProperty } from "../impl/properties/symbolicOwnersProperty";
import { ImageProperty } from "../impl/properties/imageProperty";


export abstract class DatabaseDocument extends DatabaseObject implements DatabaseDocumentIF {

    constructor( documentName : string,
        collectionDatabase : CollectionDatabase<DatabaseDocument>,
        documentPath? : string ) {

        super();

        try {
            //log.traceIn( "constructor()", collectionDatabase, documentPath );

            this.collectionDatabase = collectionDatabase;

            this.name = new TextProperty( this );
            this.name.setValue( documentName );

            this.name.editable = false; 
            this.name.trackChanges = false;
            this.name.encrypt = false;

            this.id = new TextProperty( this );
            this.id.editable = false;
            this.id.trackChanges = false;
            this.id.encrypt = false;

            this.title = new TextProperty(this);

            this.image = new ImageProperty(this);

            this.startDate = new DateProperty( this );

            this.endDate = new DateProperty( this );  
            
            //this.backReferences = new MapProperty<ReferenceHandle>( this );
            //this.backReferences.editable = false;
            //this.backReferences.trackChanges = false; 

            this.changes = new CollectionProperty<Change>( this, ChangesCollection, false );

            this.lastChangedBy = new ReferenceProperty<User>( this );
            this.lastChangedBy.editable = false;
            this.lastChangedBy.trackChanges = false;

            this.lastChangedAt = new DateProperty( this );
            this.lastChangedAt.editable = false;
            this.lastChangedAt.trackChanges = false;

            this.archived = new BooleanProperty( this );
            this.archived.hidden = true;
            this.archived.trackChanges = false;
            this.archived.encrypt = false;

            this.archivedAt = new DateProperty( this ); 
            this.archivedAt.hidden = true;
            this.archivedAt.trackChanges = false;
            this.archivedAt.encrypt = false;

            this.description = new TextProperty(this, DefaultTextType, true );

            this.attachments = new AttachmentsProperty( this, MediaTypes.Document as MediaType );

            this.links = new LinksProperty( this );

            if( documentPath != null ) {

                const documentId = Factory.get().databaseService.databaseFactory.documentId( documentPath );

                if( documentId == null ) {
                    throw new Error( "Invalid document path: " + documentPath );
                }

                if( !documentId.endsWith( NewDocumentId ) ) {
                    this.id.setValue( documentId );
                    this.id.clearChanges();
                }
            }

            this.onNotifyDocumentChange = this.onNotifyDocumentChange.bind(this);

            //log.traceOut( "constructor()");

        } catch( error ) {
                
            log.warn(  "("+documentName+")", "constructor()", "Error creating database document", error );

            throw new Error( (error as any).message );
        }
    }

    isNew() : boolean {
        return this.id.value() == null;
    }


    async create(): Promise<void> {

        //log.traceIn( "("+this.name.value()!+")", "create()", this.referenceHandle().title );

        try {
            await this.collectionDatabase.createDocument( this );

            //log.traceOut( "("+this.name.value()!+")", "create()", this.referenceHandle().title );

        } catch( error ) {
            
            log.warn( "("+this.name.value()!+")", "create()", "Error creating database object", error );

            throw new Error( (error as any).message );
        }
    }

    async read(): Promise<void> {

        //log.traceIn( "("+this.name.value()!+")", "read()" );

        try {
            if( this.id.value() == null ) {
                throw new Error( "Cannot read document without ID: " + this.databasePath(true));
            }

            const exists = await this.collectionDatabase.readDocument( this );

            if( !exists ) { 

                throw new Error( "Document does not exist with path: " + this.databasePath() );
            }    

            //log.traceOut( "("+this.name.value()!+")", "read()", this.referenceHandle().title );

        } catch( error ) {
            
            log.warn( "("+this.name.value()!+")", "read()", "Error reading database object", error );

            throw new Error( (error as any).message );
        }
    }

    async update( force? : boolean ): Promise<void> {

        //log.traceIn( "("+this.name.value()!+")", "update()", this.referenceHandle().title, {force} );

        try {

            await this.collectionDatabase.updateDocument( this, force );

            //log.traceOut( "("+this.name.value()!+")", "update()", this.referenceHandle().title );

        } catch( error ) {
            
            log.warn( "("+this.name.value()!+")", "update()", "Error updating database object", error );

            throw new Error( (error as any).message );
        }
    }

    async delete(): Promise<void> {

        //log.traceIn( "("+this.name.value()!+")", "delete()", this.referenceHandle().title );

        try {

            await this.collectionDatabase.deleteDocument( this );

            //log.traceOut( "("+this.name.value()!+")", "delete()", this.referenceHandle().title );

        } catch( error ) {
            
            log.warn( "("+this.name.value()!+")", "delete()", "Error deleting database object", error );

            throw new Error( (error as any).message );
        }
    }

    async move( nextCollectionDatabase : CollectionDatabase<DatabaseDocument> ) : Promise<DatabaseDocument> {

        log.traceIn( "move()" );

        try {

            const movedDocument = await this.collectionDatabase.moveDocument( this, nextCollectionDatabase );

            log.traceOut( "move()" );
            return movedDocument;

        } catch( error ) {
            
            log.warn( "("+this.name.value()!+")", "move()", "Error moving database object", error );

            throw new Error( (error as any).message );
        }
    }

    documentId() : string | undefined {
        return this.id.value();
    }

    documentDatabasePath( includeDocumentName? : boolean ) : string | undefined {
        return this.databasePath( includeDocumentName );
    }
  
    documentReferenceHandle() : ReferenceHandle<DatabaseDocument> | undefined {
        return this.referenceHandle();
    }

    databasePath( includeDocumentName? : boolean ) : string {

        //log.traceIn( "("+this.collectionDatabase.documentName+")", "databasePath()", {excludeDocumentName} );

        let path = this.collectionDatabase.databasePath();

        if( this.id.value() != null ) {

            path += "/" + this.id.value();
        }
        else {
            path += "/" + NewDocumentId;               
        }

        if( !!includeDocumentName ) {
            path += "?" + DocumentNameKey + "=" + this.name.value()!;
        }
    
        //log.traceOut( "("+this.collectionDatabase.documentName+")", "databasePath()", path );
        return path;
    }

    databaseReference() : any | undefined {
        
        if( this.isNew() ) {
            return undefined;
        }

        return this.collectionDatabase.databaseManager.documentReference( this );
    }

    referenceHandle() : ReferenceHandle<DatabaseDocument> {

        const referenceHandle = {} as ReferenceHandle<DatabaseDocument>;

        referenceHandle.title = this.title.value();

        referenceHandle.date = this.referenceDateProperty()?.value();

        referenceHandle.path = this.databasePath( true );

        referenceHandle.databaseDocument = this;

        referenceHandle.databaseReference = this.databaseReference();

        return referenceHandle;
    }

    // Override as required
    referenceDateProperty() : DateProperty | undefined {
        return this.startDate;
    }


    ownerDocumentId( collectionName? : string ) : string | undefined {

        //log.traceIn( "ownerDocumentId()", collectionName );

        try {
            if( this.collectionDatabase == null ) {
                //log.traceOut( "ownerDocumentPath()", "no collection" );
                return undefined;
            }

            if( collectionName == null ) {

                const ownerDocumentId = this.collectionDatabase.owner() != null ? 
                    this.collectionDatabase.owner()!.id.value() : undefined;

                //log.traceOut( "ownerDocumentPath()", "no collection name", ownerDocumentPath );
                return ownerDocumentId;
            }

            /*
            if( this.collectionDatabase.collectionName()=== collectionName && this.id.value() != null) {

                const ownerDocumentId = this.id.value();

                //log.traceOut( "ownerDocumentPath()", "this document is the owner", ownerDocumentId );
                return ownerDocumentId;
            }
            */ 

            let result : string | undefined;

            let path = this.collectionDatabase.databasePath();

            const pathElements = path.startsWith("/") ? 
                path.substring(1).split("/") : path.split("/");  // remove leading "/" and split the rest

            for( let i = 0; i < pathElements.length; i++ ) {

                if( pathElements[i] === collectionName ) {

                    if( i + 1 < pathElements.length ) {
                        //log.trace( "ownerDocumentId()", "found", pathElements[i + 1] );
                        result = pathElements[i + 1];  
                    } 
                    else {
                        result = this.id.value();
                    }            
                }
            }

            //log.traceOut( "ownerDocumentId()", "not found" );
            return result;   

        } catch( error ) {
            log.warn( "ownerDocumentId()", "Error reading owner document ID", error );

            throw new Error( (error as any).message );
        }
    }

    ownerDocumentIds( collectionName? : string ) : string[] | undefined {

        //log.traceIn( "ownerDocumentIds()", collectionName );

        try {
            if( this.collectionDatabase == null ) {
                //log.traceOut( "ownerDocumentIds()", "no collection" ); 
                return undefined;
            }

            const result : string[] = [];

            let path = this.collectionDatabase.databasePath();

            const pathElements = path.startsWith("/") ? 
                path.substring(1).split("/") : path.split("/");  // remove leading "/" and split the rest

            for( let i = 0; i < pathElements.length; i++ ) {

                if( collectionName == null && i % 2 === 1 ) { // every uneven path element is a document,

                    //log.traceOut( "ownerDocumentIds()", "found no collectionName", pathElements[i] );
                    result.push( pathElements[i] );                
                }
                else if( i > 0 && pathElements[i-1] === collectionName ) {

                    //log.traceOut( "ownerDocumentIds()", "found collectionName", pathElements[i] );
                    result.push( pathElements[i] );   
                }
            }

            //log.traceOut( "ownerDocumentIds()", result );
            return result;   

        } catch( error ) {
            log.warn( "ownerDocumentId()", "Error owner document IDs", error );

            throw new Error( (error as any).message );
        }
    }

    symbolicOwnerDocumentIds( collectionName? : string ) : string[] | undefined {

       //log.traceIn( "symbolicOwnerDocumentIds()", collectionName );

        try {
            if( this.collectionDatabase == null ) {
                //log.traceOut( "symbolicOwnerDocumentIds()", "no collection" ); 
                return undefined;
            }

            const result : string[] = [];

            const symbolicOwnersProperties = this.properties( {
                includePropertyTypes: [PropertyTypes.SymbolicOwners]
            } as PropertiesSelector ) as Map<string,SymbolicOwnersProperty<DatabaseDocument>>;
    
            //log.debug( "symbolicOwnerDocumentIds()", "read symbolic owner properties", symbolicOwnersProperties.size );
     
            for( const symbolicOwnersProperty of symbolicOwnersProperties.values() ) {

                const symbolicOwnersReferenceHandles = symbolicOwnersProperty.referenceHandles();

                for( const symbolicOwnersReferenceHandle of symbolicOwnersReferenceHandles.values() ) { 

                    const path = symbolicOwnersReferenceHandle.path.split("?")[0];

                    const pathElements = path.startsWith("/") ?
                        path.substring(1).split("/") : path.split("/");  // remove leading "/" and split the rest

                    for (let i = 0; i < pathElements.length; i++) {

                        if (collectionName == null && i % 2 === 1) { // every uneven path element is a document,

                            //log.traceOut( "symbolicOwnerDocumentIds()", "found no collectionName", pathElements[i] );

                            if(!result.includes( pathElements[i] )) {
                                result.push(pathElements[i]);
                            }
                        }
                        else if (i > 0 && pathElements[i - 1] === collectionName) {

                            //log.traceOut( "symbolicOwnerDocumentIds()", "found collectionName", pathElements[i] );
                            if(!result.includes( pathElements[i] )) {
                                result.push(pathElements[i]);
                            }
                        }
                    }
                }
            }

            //log.traceOut( "symbolicOwnerDocumentIds()", result );
            return result;   

        } catch( error ) {
            log.warn( "symbolicOwnerDocumentIds()", "Error owner document IDs", error );

            throw new Error( (error as any).message );
        }
    }

    ownerDocumentPath( collectionName? : string ) : string | undefined {

        //log.traceIn( "ownerDocumentPath()", collectionName );

        try {
            if( this.collectionDatabase == null ) {
                //log.traceOut( "ownerDocumentPath()", "no collection" );
                return undefined;
            }

            if( collectionName == null ) {

                const ownerDocumentPath = this.collectionDatabase.owner() != null ? 
                    this.collectionDatabase.owner()!.databasePath() : undefined;

                //log.traceOut( "ownerDocumentPath()", "no collection name", ownerDocumentPath );
                return ownerDocumentPath;
            }

            /*
            if( this.collectionDatabase.collectionName()=== collectionName && this.id.value() != null ) {

                const ownerDocumentPath = this.databasePath();

                //log.traceOut( "ownerDocumentPath()", "this document is the owner", ownerDocumentPath );
                return ownerDocumentPath;
            }
            */

            let path = this.collectionDatabase.databasePath();

            let ownerDocumentPath = "";

            let result : string | undefined;

            const pathElements = path.startsWith("/") ? 
                path.substring(1).split("/") : path.split("/");  // remove leading "/" and split the rest

            for( let i = 0; i < pathElements.length; i++ ) {

                ownerDocumentPath += "/" + pathElements[i];

                if( i > 0 && pathElements[i-1] === collectionName ) {

                    //log.trace( "ownerDocumentPath()", "found", ownerDocumentPath );
                    result = ownerDocumentPath;                
                }
            }

            //log.traceOut( "ownerDocumentPath()", result );
            return result;   

        } catch( error ) {
            log.warn( "ownerDocumentPath()", "Error owner document path", error );

            throw new Error( (error as any).message );
        }
    }

    ownerDocumentPaths( collectionName? : string ) : string[] | undefined {

        //log.traceIn( "ownerDocumentPaths()", collectionName );

        try {
            if( this.collectionDatabase == null ) {
                //log.traceOut( "ownerDocumentPaths()", "no collection" );
                return undefined;
            }

            const result : string[] = [];

            let path = this.collectionDatabase.databasePath();

            const pathElements = path.startsWith("/") ? 
                path.substring(1).split("/") : path.split("/");  // remove leading "/" and split the rest

            let ownerDocumentPath = "";

            for( let i = 0; i < pathElements.length; i++ ) {

                ownerDocumentPath += "/" + pathElements[i];

                if( collectionName == null && i % 2 === 1 ) { // every uneven path element is a document,

                    //log.traceOut( "ownerDocumentPaths()", "add no collection name", ownerDocumentPath );
                    result.push( ownerDocumentPath );                
                }
                else if( i > 0 && pathElements[i-1] === collectionName ) {

                    //log.traceOut( "ownerDocumentPaths()", "add", ownerDocumentPath );
                    result.push( ownerDocumentPath );          
                }
            }

            if( result.length > 0 ) {
                //log.traceOut( "ownerDocumentPaths()", result );
                return result;  
            }

            //log.traceOut( "ownerDocumentPaths()", "not found" );
            return undefined;   

        } catch( error ) {
            log.warn( "ownerDocumentId()", "Error document paths", error );

            throw new Error( (error as any).message );
        }
    }


    emptyOwnerDocument( collectionName? : string ) : DatabaseDocument | undefined {

        //log.traceIn( "("+this.collectionDatabase+")", "ownerDocument()", collectionName );

        try {

            const path = this.ownerDocumentPath( collectionName );

            if( path == null ) {
                //log.traceOut( "("+this.collectionDatabase.documentName+")", "emptyOwnerDocument()", "not found" );
                return undefined;
            }

            const result = Factory.get().databaseService.databaseFactory.newDocumentFromUrl( path ) as DatabaseDocument;

            //log.traceOut( "("+this.collectionDatabase.documentName+")", "emptyOwnerDocument()", result.referenceHandle().title );
            return result;

        } catch( error ) {
            
            log.warn( "("+this.name.value()!+")", "emptyOwnerDocument()", "Error reading owner document", error );

            throw new Error( (error as any).message );
        }
    }

    emptyOwnerDocuments( collectionName? : string ) : DatabaseDocument[] | undefined {

        //log.traceIn( "("+this.collectionDatabase+")", "emptyOwnerDocuments()", collectionName );

        try {

            const paths = this.ownerDocumentPaths( collectionName );

            if( paths == null ) {
                //log.traceOut( "("+this.collectionDatabase.documentName+")", "emptyOwnerDocuments()", "not found" );
                return undefined;
            }

            const result : DatabaseDocument[] = [];

            for( const path of paths ) {

                const databaseDocument = 
                    Factory.get().databaseService.databaseFactory.newDocumentFromUrl( path ) as DatabaseDocument;

                if( databaseDocument == null ) {
                    throw new Error( "Document in owner path does not exist: " + path );
                }
                result.push( databaseDocument );
            }


            //log.traceOut( "("+this.collectionDatabase.documentName+")", "emptyOwnerDocuments()", result.size() );
            return result;

        } catch( error ) {
            
            log.warn( "("+this.name.value()!+")", "emptyOwnerDocuments()", "Error reading owner documentd", error );

            throw new Error( (error as any).message );
        }
    }

    async ownerDocument( collectionName? : string ) : Promise<DatabaseDocument | undefined> {

        //log.traceIn( "("+this.collectionDatabase+")", "ownerDocument()", collectionName );

        try {
            const path = this.ownerDocumentPath( collectionName );

            if( path == null ) {
                //log.traceOut( "("+this.collectionDatabase.documentName+")", "ownerDocument()", "not found" );
                return undefined;
            }

            const result = await Factory.get().databaseService.databaseFactory.documentFromUrl( path ) as DatabaseDocument;

            //log.traceOut( "("+this.collectionDatabase.documentName+")", "ownerDocument()", result.referenceHandle() );
            return result;

        } catch( error ) {
            
            log.warn( "("+this.name.value()!+")", "ownerDocument()", "Error reading owner document", error );

            throw new Error( (error as any).message );
        }
    }

    async ownerDocuments( collectionName? : string ) : Promise<DatabaseDocument[] | undefined> {

        //log.traceIn( "("+this.collectionDatabase+")", "ownerDocuments()", collectionName );

        try {

            const paths = this.ownerDocumentPaths( collectionName );

            if( paths == null ) {
                //log.traceOut( "("+this.collectionDatabase.documentName+")", "ownerDocuments()", "not found" );
                return undefined;
            }

            const result : DatabaseDocument[] = [];

            for( const path of paths ) {

                const databaseDocument = 
                    await Factory.get().databaseService.databaseFactory.documentFromUrl( path ) as DatabaseDocument;

                if( databaseDocument == null ) {
                    throw new Error( "Document in owner path does not exist: " + path );
                }
                result.push( databaseDocument );

            }

            //log.traceOut( "("+this.collectionDatabase.documentName+")", "ownerDocuments()", result.size() );
            return result;

        } catch( error ) {
            
            log.warn( "("+this.name.value()!+")", "ownerDocuments()", "Error reading owner documentd", error );

            throw new Error( (error as any).message );
        }
    }

    ownerCollection( collectionName? : string ) : CollectionDatabase<DatabaseDocument> | undefined {

        //log.traceIn( "("+this.collectionDatabase+")", "ownerCollection()", {collectionName} );

        try {

            if( collectionName == null ) {
                //log.traceOut( "("+this.collectionDatabase.documentName+")", "ownerCollection()", this.collectionDatabase );
                return this.collectionDatabase;
            }

            const path = this.ownerDocumentPath( collectionName );

            if( path == null ) {

                const result = 
                    Factory.get().databaseService.databaseFactory.collectionDatabaseFromCollectionName( 
                        collectionName ) as CollectionDatabase<DatabaseDocument>;

                //log.traceOut( "("+this.collectionDatabase.documentName+")", "ownerCollection()", "use root collection" );
                return result;
            }
            
            const result = 
                Factory.get().databaseService.databaseFactory.collectionFromUrl( 
                    path ) as CollectionDatabase<DatabaseDocument>;

            //log.traceOut( "("+this.collectionDatabase.documentName+")", "ownerCollection()", result.databasePath() );
            return result;

        } catch( error ) {
            
            log.warn( "("+this.name.value()!+")", "ownerCollection()", "Error reading owner collection", error );

            throw new Error( (error as any).message );
        }
    }

    ownerCollections( collectionName? : string ) : CollectionDatabase<DatabaseDocument>[] | undefined {

        log.traceIn( "ownerCollections()", {collectionName} );

        try {
            if( this.collectionDatabase == null ) {
                //log.traceOut( "ownerCollections()", "no collection" );
                return undefined;
            }

            const result : CollectionDatabase<DatabaseDocument>[] = [];

            let path = this.collectionDatabase.databasePath();

            const pathElements = path.startsWith("/") ? 
                path.substring(1).split("/") : path.split("/");  // remove leading "/" and split the rest

            let collectionPath = "";

            for( let i = 0; i < pathElements.length; i++ ) {

                collectionPath += "/" + pathElements[i];

                if( collectionName == null && i % 2 === 0 ) { // every even path element is a collection,

                    const collectionDatabase = 
                        Factory.get().databaseService.databaseFactory.collectionFromUrl( collectionPath ) as CollectionDatabase<DatabaseDocument>;

                    //log.traceOut( "ownerCollections()", "found no collectionName", pathElements[i] );
                    result.push( collectionDatabase );   
                }
                else if( collectionName === pathElements[i] ) {

                    const collectionDatabase = 
                        Factory.get().databaseService.databaseFactory.collectionFromUrl( collectionPath ) as CollectionDatabase<DatabaseDocument>;

                    //log.traceOut( "ownerCollections()", "found with collectionName", pathElements[i] );
                    result.push( collectionDatabase );                
                }
            }

            //log.traceOut( "ownerCollections()", result.length > 0 ? result.length : undefined );
            return result.length > 0 ? result : undefined;   

        } catch( error ) {
            log.warn( "ownerCollections()", "Error database path", error );

            throw new Error( (error as any).message );
        }
    }

    ownerCollectionGroup( collectionName? : string ) : CollectionGroupDatabase<DatabaseDocument> | undefined {

        //log.traceIn( "("+this.collectionDatabase+")", "ownerCollectionGroup()", {collectionName} );

        try {
            const path = this.ownerDocumentPath( 
                collectionName != null ? collectionName : this.collectionDatabase.collectionName());

            if( path == null ) {

                const result = 
                    Factory.get().databaseService.databaseFactory.collectionGroupDatabaseFromCollectionName( 
                        collectionName != null ? 
                            collectionName : 
                            this.collectionDatabase.collectionName()) as CollectionGroupDatabase<DatabaseDocument>;

                //log.traceOut( "("+this.collectionDatabase.documentName+")", "ownerCollectionGroup()", "use root collection" );
                return result;
            }
            
            const result = 
                Factory.get().databaseService.databaseFactory.collectionGroupFromUrl( 
                    path ) as CollectionGroupDatabase<DatabaseDocument>;

            //log.traceOut( "("+this.collectionDatabase.documentName+")", "ownerCollectionGroup()", result.databasePath() );
            return result;

        } catch( error ) {
            
            log.warn( "("+this.name.value()!+")", "ownerCollectionGroup()", "Error reading owner collection", error );

            throw new Error( (error as any).message );
        }
    }

    ownerCollectionGroups( collectionName? : string ) : CollectionGroupDatabase<DatabaseDocument>[] | undefined {

        log.traceIn( "ownerCollectionGroups()", {collectionName} );

        try {

            const result : CollectionGroupDatabase<DatabaseDocument>[] = [];

            let path = this.collectionDatabase.databasePath();

            const pathElements = path.startsWith("/") ? 
                path.substring(1).split("/") : path.split("/");  // remove leading "/" and split the rest

            let collectionPath = "";

            for( let i = 0; i < pathElements.length; i++ ) {

                collectionPath += "/" + pathElements[i];

                if( collectionName == null && i % 2 === 0 ) { // every even path element is a collection,

                    const collectionGroupDatabase = 
                        Factory.get().databaseService.databaseFactory.collectionGroupFromUrl( 
                            collectionPath ) as CollectionGroupDatabase<DatabaseDocument>;

                    //log.traceOut( "ownerCollectionGroups()", "found no collectionName", pathElements[i] );
                    result.push( collectionGroupDatabase );   
                }
                else if( collectionName === pathElements[i] ) {

                    const collectionGroupDatabase = 
                        Factory.get().databaseService.databaseFactory.collectionGroupFromUrl( 
                            collectionPath ) as CollectionGroupDatabase<DatabaseDocument>;

                    //log.traceOut( "ownerCollectionGroups()", "found with collectionName", pathElements[i] );
                    result.push( collectionGroupDatabase );                
                }
            }

            //log.traceOut( "ownerCollectionGroups()", result.length > 0 ? result.length : undefined );
            return result.length > 0 ? result : undefined;   

        } catch( error ) {
            log.warn( "ownerCollectionGroups()", "Error database path", error );

            throw new Error( (error as any).message );
        }
    }

    parentDatabases( collectionName : string, 
        options? : { 
            nearestIsCollectionGroup? : boolean, 
            includeRootCollection? : boolean }  ) : Database<DatabaseDocument>[] | undefined {

        //log.traceIn( "parentDatabases()", {collectionName}, {nearestAsCollectionGroup} );

        try {

            const result : Database<DatabaseDocument>[] = [];

            let nearestFound = false;

            const parentCollectionProperties = this.parentCollectionProperties( collectionName );

            if (parentCollectionProperties != null && parentCollectionProperties.length > 0) {

                for( const parentCollectionProperty of parentCollectionProperties ) {

                    if( !nearestFound && !!options?.nearestIsCollectionGroup ) {
                        result.push( parentCollectionProperty.collectionGroup()! );
                    }
                    else {
                        result.push( parentCollectionProperty.collection() );
                    }
                    nearestFound = true;
                }
            }

            if( !!options?.includeRootCollection ) {
            
                if( !nearestFound && !!options?.nearestIsCollectionGroup ) {

                    const rootCollectionGroup = 
                        Factory.get().databaseService.databaseFactory.collectionGroupDatabaseFromCollectionName( 
                            collectionName ) as CollectionGroupDatabase<DatabaseDocument>;

                    if( rootCollectionGroup.allowRootCollection ) {
                        result.push( rootCollectionGroup );
                    }
                }
                else {
                    const rootCollection =
                        Factory.get().databaseService.databaseFactory.collectionDatabaseFromCollectionName(
                            collectionName) as CollectionDatabase<DatabaseDocument>;

                    if (rootCollection.allowRootCollection) {
                        result.push(rootCollection);
                    }
                }
            }

            //log.traceOut("parentDatabases()", result.length > 0 ? result : undefined);
            return result.length > 0 ? result : undefined; 

        } catch( error ) {
            log.warn( "parentDatabases()", error );

            throw new Error( (error as any).message );
        }
    }


    parentCollection( collectionName : string ) : CollectionDatabase<DatabaseDocument> | undefined {

        //log.traceIn( "parentCollection()", {collectionName} );

        try {

            const parentCollectionProperties = this.parentCollectionProperties( collectionName );

            if (parentCollectionProperties != null && parentCollectionProperties.length > 0) {
    
                const collection = parentCollectionProperties[0].collection();

                //log.traceOut( "parentCollection()", collectionGroup.databasePath() ); 
                return collection;
            }

            const rootCollection =
                Factory.get().databaseService.databaseFactory.collectionDatabaseFromCollectionName(
                    collectionName) as CollectionDatabase<DatabaseDocument>;

            if (rootCollection.allowRootCollection) {

                //log.traceOut( "parentCollection()", collection.databasePath() ); 
                return rootCollection;
            }      

            //log.traceOut( "parentCollection()", "not found" );
            return undefined

        } catch (error) {

            log.warn("parentCollection()", "Error reading parent collection", error);

            throw new Error( (error as any).message );
        }
    }

    parentCollections( collectionName : string ) : CollectionDatabase<DatabaseDocument>[] | undefined {

       // log.traceIn( "parentCollections()", {collectionName} );

        try {

            const result : CollectionDatabase<DatabaseDocument>[] = [];

            const parentCollectionProperties = this.parentCollectionProperties( collectionName );

            if (parentCollectionProperties != null && parentCollectionProperties.length > 0) {

                for( const parentCollectionProperty of parentCollectionProperties ) {

                    result.push( parentCollectionProperty.collection() );
                }
            }

            const noOwnerCollection = 
                Factory.get().databaseService.databaseFactory.collectionDatabaseFromCollectionName( 
                    collectionName ) as CollectionDatabase<DatabaseDocument>;

            if( noOwnerCollection.allowRootCollection ) {
                result.push( noOwnerCollection );
            }

            //log.traceOut("parentCollections()", result.length > 0 ? result : undefined);
            return result.length > 0 ? result : undefined; 

        } catch( error ) {
            log.warn( "parentCollections()", error );

            throw new Error( (error as any).message );
        }
    }

    parentCollectionGroup( collectionName : string ) : CollectionGroupDatabase<DatabaseDocument> | undefined {

        //log.traceIn( "("+this.collectionDatabase+")", "parentCollectionGroup()", {collectionName} );

        try {

            const parentCollectionProperties = this.parentCollectionProperties( collectionName );

            if (parentCollectionProperties != null && parentCollectionProperties.length > 0) {
    
                const collectionGroup = parentCollectionProperties[0].collectionGroup();

                //log.traceOut( "parentCollectionGroup()", collectionGroup.databasePath() ); 
                return collectionGroup;
            }

            const rootCollectionGroup =
                Factory.get().databaseService.databaseFactory.collectionGroupDatabaseFromCollectionName(
                    collectionName) as CollectionGroupDatabase<DatabaseDocument>;

            if (rootCollectionGroup.allowRootCollection) {

                //log.traceOut( "parentCollectionGroup()", rootCollectionGroup.databasePath() ); 
                return rootCollectionGroup;
            }
            
            //log.traceOut( "parentCollectionGroup()", "not found" );
            return undefined

        } catch (error) {

            log.warn("(" + this.name.value()! + ")", "parentCollection()", "Error reading parent collection", error);

            throw new Error( (error as any).message );
        }
    }

    parentCollectionGroups( collectionName : string ) : CollectionGroupDatabase<DatabaseDocument>[] | undefined {

       // log.traceIn( "parentCollectionGroups()", {collectionName} );

        try {

            const result : CollectionGroupDatabase<DatabaseDocument>[] = [];

            const parentCollectionProperties = this.parentCollectionProperties( collectionName );

            if (parentCollectionProperties != null && parentCollectionProperties.length > 0) {

                for( const parentCollectionProperty of parentCollectionProperties ) {

                    if( !!parentCollectionProperty.allowCollectionGroup() ) {
                        result.push( parentCollectionProperty.collectionGroup()! ); 
                    }
                }
            }

            const rootCollectionGroup = 
                Factory.get().databaseService.databaseFactory.collectionGroupDatabaseFromCollectionName( 
                    collectionName ) as CollectionGroupDatabase<DatabaseDocument>;

            if( rootCollectionGroup.allowRootCollection ) {
                result.push( rootCollectionGroup );
            }

            //log.traceOut("parentCollectionGroups()", result.length > 0 ? result : undefined);
            return result.length > 0 ? result : undefined; 

        } catch( error ) {
            log.warn( "parentCollectionGroups()", error );

            throw new Error( (error as any).message );
        }
    }

    parentCollectionProperty( collectionName : string ) : CollectionProperty<DatabaseDocument> | undefined {

        //log.traceIn( "parentCollectionProperty()", {collectionName} );

        try {

            const ownerDocument = this.emptyOwnerDocument();

            if (ownerDocument != null) {

                const databaseProperty = ownerDocument.property(collectionName);

                if (databaseProperty != null) {

                    if (databaseProperty.type === PropertyTypes.Collection) {

                        log.traceOut( "parentCollectionProperty()", "found in owner:", ownerDocument.databasePath() );
                        return databaseProperty as CollectionProperty<DatabaseDocument>;
                    }
                }
            }

            //log.traceOut( "parentCollectionProperty()", "not found" );
            return undefined

        } catch (error) {

            log.warn( "parentCollectionProperty()", "Error reading parent collection", error);

            throw new Error( (error as any).message );
        }
    }

    parentCollectionProperties( collectionName : string ) : CollectionProperty<DatabaseDocument>[] | undefined {

        //log.traceIn( "parentCollectionProperties()", {collectionName} );

        try {

            const result : CollectionProperty<DatabaseDocument>[] = [];

            const ownerDocuments = this.emptyOwnerDocuments();

            if (ownerDocuments != null && ownerDocuments.length > 0) {

                for (let i = ownerDocuments.length - 1; i >= 0; i--) {

                    const ownerDocument = ownerDocuments[i];

                    const databaseProperty = ownerDocument.property(collectionName);

                    if (databaseProperty != null) {

                        if (databaseProperty.type === PropertyTypes.Collection) {

                            result.push( databaseProperty as CollectionProperty<DatabaseDocument> );
                        }
                    }
                }
            }

            //log.traceOut("parentCollectionProperties()", result.length > 0 ? result : undefined);
            return result.length > 0 ? result : undefined; 

        } catch( error ) {
            log.warn( "parentCollectionProperties()", error );

            throw new Error( (error as any).message );
        }
    }

    documentName() : string {
        return this.name.value()!;
    }

    duplicate() : DatabaseDocument {

        //log.traceIn( "("+this.collectionDatabase.documentName+")", "duplicate()" );

        try {

            let copy = Factory.get().databaseService.databaseFactory.newDocument( 
                this.collectionDatabase, this.databasePath() ) as DatabaseDocument;

            copy.copyProperties( this );  // Ensures deep copy

            //log.traceOut( "("+this.collectionDatabase.documentName+")", "duplicate()", copy );
            return copy;

        } catch( error ) {
            
            log.warn( "("+this.name.value()!+")", "duplicate()", "Error copying database object", error );

            throw new Error( (error as any).message );
        }
    }

    async subscribe( monitor : Monitor ) : Promise<void>
    {
        //log.traceIn( "usubscribe()", monitor );

        try {

            if( this.id.value() == null ) {
                return;
            }

            monitor.objectIdsFilter = [this.referenceHandle().path];

            await super.subscribe( monitor );

            //log.traceOut( "subscribe()", "return", result );

        } catch( error ) {
            log.warn( "subscribe()", "Error adding new monitor", monitor, error );

            throw new Error( (error as any).message );
        }
    }

    protected async monitor( newMonitor : Monitor ): Promise<void> {

        //log.traceIn( "monitor()" );  
        
        try {
            if( this.id.value() == null ) {
                throw new Error("Cannot monitor document without ID" );
            }

            const path = this.referenceHandle().path;

            if( newMonitor.objectIdsFilter != null && 
                ( newMonitor.objectIdsFilter.length !== 1 || newMonitor.objectIdsFilter[0] !== path ) ) {

                throw new Error("Document to monitor not held by this handle: " + newMonitor.objectIdsFilter );
            }
        
            await this.updateDatabaseSubscription();
  
            //log.traceOut( "("+this.collectionDatabase.collectionName()+")", "monitor()" );
  
        } catch( error ) {
            log.warn( "("+this.collectionDatabase.collectionName()+")", "monitor()", "Error monitoring document", error );
  
            throw new Error( (error as any).message );
        }
    }

    private async updateDatabaseSubscription(): Promise<void> {
        log.traceIn("(" + this.collectionDatabase.collectionName()+ ")", "updateDatabaseSubscription()", this);

        try {
            const path = this.databasePath( true );

            if (super.isMonitoring() && path != null && path.length > 0) {

                log.debug("(" + this.collectionDatabase.collectionName()+ ")", "Updating database subscription for: " + path);

                await this.collectionDatabase.subscribe({
                    observer: this,
                    onNotify: this.onNotifyDocumentChange,
                    objectIdsFilter: [path]
                });

            }
            else {
                log.debug("(" + this.collectionDatabase.collectionName()+ ")", "No database subscription for: " + path);

                this.collectionDatabase.unsubscribe(this);
            }

            log.traceOut("(" + this.collectionDatabase.collectionName()+ ")", "updateDatabaseSubscription()", path);

        } catch (error) {
            log.warn("(" + this.collectionDatabase.collectionName()+ ")", "monitor()", "Error subscribing to database", error);

            throw new Error( (error as any).message );
        }

    }
  
  
    protected async release(): Promise<void> {
  
        try {
            //log.traceIn( "("+this.collectionDatabase.collectionName+")", "release()" );
  
            this.collectionDatabase.unsubscribe( this );
  
            //log.traceOut( "("+this.collectionDatabase.collectionName+")", "release()" );
  
        } catch( error ) {
            
            log.warn( "("+this.collectionDatabase.collectionName+")", "release()", "Error releasing database objects", error );
  
            throw new Error( (error as any).message );
        }
    }

    protected onNotifyDocumentChange = async (observable: ObservableIF,
        observation: Observation,
        objectId: string | null | undefined,
        object: object | null | undefined): Promise<void> => {

        log.traceIn( "onNotifyDocumentChange()", this, observation, objectId );
  
        try {

            if( objectId == null || 
                !Factory.get().databaseService.databaseFactory.equalDatabasePaths( objectId, this.databasePath( true ) ) ) {
                    
                log.traceOut( "onNotifyDocumentChange()", "Not for us", {objectId}, this.databasePath( true ) );
                return;
            }

            const databaseDocument = object as DatabaseDocument;
    
            if( databaseDocument != null ) {
                this.copyFrom( databaseDocument );  
            }
  
            await super.notify( observation, objectId, this );
  
            log.traceOut( "onNotifyDocumentChange()" );
  
        } catch( error ) {
            
            log.warn( "onNotifyDocumentChange()", "Error notifying database handle", this, error );
        }
    }


    fromData(data: any): void {
        //log.traceIn("fromData()", data);

        try {

            const id = this.id.value();

            if( data.id != null ) {
                delete data.id;  // Use actual path to evaluate id
            }

            if( data.path != null ) {
                delete data.path;  // Use actual path to evaluate id
            } 

            if( data[OwnerIds] != null ) {
                delete data[OwnerIds];  // We read this from the path
            } 

            super.fromData( data );

            if( this.id.value() == null ) {
                this.id.setValue( id );
            }

            //log.traceOut("fromData()", this);

        } catch (error) {

            log.warn("fromData()", "Error reading document", error);

            throw new Error( (error as any).message );
        }
    }

    async toData( force? : boolean ): Promise<any> {
        log.traceIn("toData()", {force})
        try {

            const data = await super.toData( force );

            data.path = this.databasePath();

            let ownerIds = this.ownerDocumentIds();

            const symbolicOwnerIds = this.symbolicOwnerDocumentIds();

            if( symbolicOwnerIds != null ) {

                for( const symbolicOwnerId of symbolicOwnerIds ) {

                    if( ownerIds == null ) {
                        ownerIds = [];
                    }
                    
                    if( !ownerIds.includes( symbolicOwnerId ) ) {
                        ownerIds.push( symbolicOwnerId );
                    }
                }
            }

            const ownerDocuments = this.emptyOwnerDocuments();

            if( ownerDocuments != null ) {
                for( const ownerDocument of ownerDocuments.values() ) { 


                    if( ownerDocument.properties( { 
                        includePropertyTypes: [PropertyTypes.SymbolicOwners as PropertyType] } ).size > 0 ) {

                        await ownerDocument.read();
                    }

                    const ownerSymbolicOwnerIds = ownerDocument.symbolicOwnerDocumentIds();

                    if( ownerSymbolicOwnerIds != null ) {

                        for( const ownerSymbolicOwnerId of ownerSymbolicOwnerIds ) {

                            if( ownerIds == null ) {
                                ownerIds = [];
                            }
                            
                            if( !ownerIds.includes( ownerSymbolicOwnerId ) ) {
                                ownerIds.push( ownerSymbolicOwnerId );
                            }
                        }
                    }
                }
            }

            if (ownerIds != null && ownerIds.length > 0) {
                data[OwnerIds] = ownerIds;
            }
            else {
                data[OwnerIds] = [];
            }

            delete data.id;  // We don't store ID, only path

            log.traceOut("toData()", data[OwnerIds])
            return data;
        } catch (error) {
            log.warn("toData()", "Error writing database document to data", error);

            throw new Error( (error as any).message );
        }
    }

    matchFilter( params: { 
        from?: Date, 
        to?: Date, 
        matchHistoric? : boolean, 
        databaseFilters?: Map<string, DatabaseFilter>
        }): boolean {

        //log.traceIn("matchFilter()" );

        try {

            if (params.from != null && this.startDate.compareValue(params.from) < 0) {

                //log.traceOut("matchFilter()", "before date range" );
                return false;
            }

            if (params.to != null && this.startDate.compareValue(params.to) > 0) {
                //log.traceOut("matchFilter()", "after date range" );
                return false;
            }

            if ( params.matchHistoric != null && !params.matchHistoric && 
                this.endDate.value() != null && this.endDate.value()!.getTime() <= new Date().getTime() ){
                //log.traceOut("matchFilter()", "matchHistoric" );
                return false;
            }

            if (params.databaseFilters != null) {
                for (const keyValuePair of params.databaseFilters) {

                    const propertyKey = keyValuePair[0];
                    const databaseFilter = keyValuePair[1];

                    const documentProperty = this.property(propertyKey);

                    if (documentProperty != null) {

                        if( documentProperty.type === PropertyTypes.Collection ) { 
                            continue;
                        }

                        if( !!documentProperty.hidden ) {
                            continue;
                        }

                        if( !!databaseFilter.ignoreEmpty ) {

                            const value = documentProperty.value();
                            
                            if( value == null ) {
                                continue;
                            }

                            if( value.size != null && value.size === 0 ) {
                                continue;
                            }

                            if( value.length != null && value.length === 0 ) {
                                continue;
                            }
                        } 

                        //log.trace("matchFilter()", "documentProperty", propertyKey, documentProperty.value());

                        //log.trace("matchFilter()", "databaseFilter", propertyKey, databaseFilter.value );

                        switch (databaseFilter.comparator) {

                            case Comparators.Equal: {

                                const compare = documentProperty.compareValue(databaseFilter.value);

                                if (compare !== 0) {
                                    //log.traceOut("matchFilter()", "failed", databaseFilter.comparator, propertyKey);
                                    return false;
                                }
                                break;
                            }
                            case Comparators.GreaterThan: {

                                const compare = documentProperty.compareValue(databaseFilter.value);

                                if (compare <= 0) {
                                    //log.traceIn("matchFilter()", "failed", databaseFilter.comparator, propertyKey);
                                    return false;
                                }
                                break;
                            }
                            case Comparators.GreaterThanOrEqual: {

                                const compare = documentProperty.compareValue(databaseFilter.value);

                                if (compare < 0) {
                                    //log.traceIn("matchFilter()", "failed", databaseFilter.comparator, propertyKey);
                                    return false;
                                }
                                break;
                            }
                            case Comparators.LessThan: {

                                const compare = documentProperty.compareValue(databaseFilter.value);

                                if (compare >= 0) {
                                    //log.traceIn("matchFilter()", "failed", databaseFilter.comparator, propertyKey);
                                    return false;
                                }
                                break;
                            }
                            case Comparators.LessThanOrEqual: {

                                const compare = documentProperty.compareValue(databaseFilter.value);

                                if (compare >= 0) {
                                    //log.traceIn("matchFilter()", "failed", databaseFilter.comparator, propertyKey);
                                    return false;
                                }
                                break;
                            }
                            case Comparators.NotEqual: {

                                const compare = documentProperty.compareValue(databaseFilter.value);

                                if (compare === 0) {
                                    //log.traceIn("matchFilter()", "failed", databaseFilter.comparator, propertyKey);
                                    return false;
                                }
                                break;
                            }
                            case Comparators.Includes: {

                                if( !documentProperty.includesValue( databaseFilter.value ) ) {
                                    //log.traceIn("matchFilter()", "failed", databaseFilter.comparator, propertyKey);
                                    return false;
                                }

                                break;
                            }
                            case Comparators.IncludesAny: {

                                if( !documentProperty.includesValue( databaseFilter.value, true ) ) {
                                    //log.traceIn("matchFilter()", "failed", databaseFilter.comparator, propertyKey);
                                    return false;
                                }

                                break;
                            }
                            case Comparators.NotIncludes: {

                                if( documentProperty.includesValue( databaseFilter.value ) ) {
                                    //log.traceIn("matchFilter()", "failed", databaseFilter.comparator, propertyKey);
                                    return false;
                                }

                                break;

                            }
                            case Comparators.Exists: {
                    
                                const value = documentProperty.value();

                                if ( value == null ||
                                     (value.length != null && value.length === 0 ) ||
                                     (value.size != null && value.size === 0 ) ) {

                                    //log.traceIn("matchFilter()", "failed", databaseFilter.comparator, propertyKey);
                                    return false;
                                }
                                break;
                            }
                            case Comparators.NotExists:  {
                                
                                const value = documentProperty.value();

                                if (value != null &&
                                    (value.length == null || value.length > 0 ) &&
                                    (value.size == null || value.size > 0 ) ) {

                                    //log.traceIn("matchFilter()", "failed", databaseFilter.comparator, propertyKey);
                                    return false;
                                }
                                break;
                            }
                            default:
                                throw new Error( "Unexpected comparator: " + databaseFilter.comparator );
                        }
                    }
                }
            }

            //log.traceOut("matchFilter()", "passed" );
            return true;

        } catch (error) {
            log.warn("Error filtering document", error);

            throw new Error( (error as any).message );
        }
    }

    compareTo( other : DatabaseDocumentIF | null | undefined ) : number { 

        if( other == null ) {
            return 1;
        }

        const id = this.id.value();
        const otherId = other.id.value();

        if( id != null && otherId != null && id === otherId ) {
            return 0;
        }

        const title = this.title.value();
        const otherTitle = other.title.value();

        if( title == null && otherTitle == null ) {
            
            if( id == null && otherId == null ) {
                return 0;
            }
    
            if( id != null && otherId == null ) {
                return 1;
            }
    
            if( id == null && otherId != null ) {
                return -1;
            }

            return id!.localeCompare( otherId! );
        }

        if( title != null && otherTitle == null ) {
            return 1;
        }

        if( title == null && otherTitle != null ) {
            return -1;
        }

        return title!.localeCompare( otherTitle! );
    }

    
    //backReferenceKey( propertyKey : string ) : string {
    //    return this.documentName() + "." + propertyKey + "." + this.id.value()!;
    //}
    

    changesPropertiesSelector() : PropertiesSelector {

        return {

            //excludePropertyKeys: ["backReferences","lastChangedAt","lastChangedBy"],
            excludePropertyKeys: ["lastChangedAt","lastChangedBy"],

            excludePropertyTypes: [PropertyTypes.Collection]

        } as PropertiesSelector;
    }


    userAccess() : UserAccess {

        if( this._userAccess != null ) {

            return this._userAccess;
        }

        const userAccess = Factory.get().databaseService.databaseAccess.userAccess( this.databasePath( true ) );   

        //log.traceInOut( "userAccess()", {userAccess} )
        return userAccess;
    }



    validate( propertiesSelector?: PropertiesSelector, markMissingProperties? : boolean  ): Map<string, Error> {

    //log.traceIn("validate()");

        try {

        let result = super.validate( propertiesSelector, markMissingProperties );

        if( this.startDate.value() != null && this.endDate.value() != null &&
            (this.startDate.isChanged() || this.startDate.isChanged()) &&
            this.startDate.compareTo( this.endDate ) > 0 ) {

            const error = new Error( "endDateBeforeStartDate" );
            
            this.endDate.error = error;
            
            result.set( this.endDate.key(), error );
        }
        else if( result.get( this.endDate.key() ) == null  ) { 

            delete this.endDate.error;
        }
        


        //log.traceOut("validate()", result);
        return result;

        } catch (error) {
            log.warn("Error validating properties for database object", error);

            throw new Error( (error as any).message );
        }
    }

    readonly collectionDatabase : CollectionDatabase<DatabaseDocument>;

    readonly name : TextProperty;

    readonly id : TextProperty;

    readonly title: TextProperty;

    readonly image: ImageProperty;

    readonly startDate: DateProperty;

    readonly endDate : DateProperty;

    //readonly backReferences : MapProperty<ReferenceHandle<DatabaseDocument>>;

    readonly changes : CollectionProperty<Change>;

    readonly lastChangedBy : ReferenceProperty<User>;

    readonly lastChangedAt : DateProperty;

    readonly archived : BooleanProperty;

    readonly archivedAt : DateProperty;

    readonly description: TextProperty;

    readonly attachments: AttachmentsProperty;

    readonly links: LinksProperty;
  
}
