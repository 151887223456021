
export const PropertyTypes = {
    Tenant:                 "tenant",
    Owner:                  "owner",
    SymbolicOwners:         "symbolicOwners",
    SymbolicCollection:     "symbolicCollection",
    Reference:              "reference",
    References:             "references",
    Empty:                  "empty",
    Collection:             "collection",
    Subdocument:            "subdocument",
    Definitions:            "definitions",
    Definition:             "definition",
    Data:                   "data",
    Map:                    "map",
    Texts:                  "texts",
    Date:                   "date",
    Geolocation:            "geolocation",
    Text:                   "text",
    Number:                 "number",
    Boolean:                "boolean",
    PhoneNumber:            "phoneNumber",
    Country:                "country",
    Attachment:             "attachment",
    Attachments:            "attachments", 
    Image:                  "image",
    Links:                  "links"
}

export type PropertyType = keyof (typeof PropertyTypes);  
