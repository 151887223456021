
export const SortOrientations = {

    Ascending: "ascending",

    Descending: "descending"
}

export type SortOrientation = keyof (typeof SortOrientations);  

