import React from 'react';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import { log } from "ui/app/app";

import { withTranslation, WithTranslation } from 'react-i18next';
import { TranslationKey } from '../../services/common/api/translatorIF';
import { Button, createStyles, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Theme, WithStyles, withStyles } from '@material-ui/core';

import { translatedPropertyLabel } from './propertyLabel';
import { propertyInputVariant } from './propertyValue';

export const ButtonKeyOK = "ok";
export const ButtonKeyCancel = "cancel";


const styles = (theme: Theme) => createStyles({
  content: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(6),
    width: "50%",
    minWidth: theme.spacing( 60 )
  },
  grid: {
    flexWrap: "nowrap", 
  },
  buttons: {
    display: "flex",
    justifyContent: 'flex-end',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(6)
  }
});

export const formDialog = (
  documentName: string,
  title: string | undefined,
  values: Map<string,string | undefined> ): Promise<Map<string,string> | undefined> => {

  return new Promise<Map<string,string> | undefined>((resolve, reject) => {

    log.traceIn("formDialog()", {title}, {values}); 

    try {

      confirmAlert({
        customUI: ({ onClose }) => {

          const onComplete = ( values?: Map<string,string> ) => {

            onClose();

            log.traceOut("confirm()", {values});
            resolve(values);
          }

          return (
            <ModifiedFormDialog
              documentName={documentName}
              title={title}
              values={values}
              onComplete={onComplete}
              >
            </ModifiedFormDialog>
          );
        }
      });

    } catch (error) {

      log.warn("confirm()", error);

      reject();
    }
  });
}


interface FormDialogProps extends WithStyles<typeof styles>, WithTranslation {

  documentName: string,

  title?: string,

  values: Map<string,string | undefined>,

  onComplete: ( result?: Map<string,string> ) => void
}


interface FormDialogState {

  open: boolean,

  complete: boolean,

  values: Map<string,string>
}

class FormDialog extends React.PureComponent<FormDialogProps, FormDialogState> {

  constructor(props: FormDialogProps) {

    super(props);

    const values = new Map<string,string>();

    this.props.values.forEach( (value, title ) => {

      if( value != null ) {
        values.set( title, value );
      }
    })

    this.state = {

      values: values,

      open: false,

      complete: false,
    } as FormDialogState;

    this.titleTranslation = this.titleTranslation.bind(this);

    //log.traceInOut( "constructor()" );
  }


  async componentDidMount() {

    log.traceIn("componentDidMount()");

    this.setState({ 

      open: true

    });

    log.traceIn("componentDidMount()");
  }

  private titleTranslation( titleKey: string) {

    let result;

    let translationKey = TranslationKey.Prompts + ":" + titleKey;

    if (this.props.i18n.exists(translationKey)) {

      return this.props.t(translationKey);
    }

    if (this.props.i18n.exists(titleKey)) {

      result = this.props.t(titleKey);
    }
    else {
      result = titleKey;
    }
    return result;
  }


  render() {

    //log.traceInOut("render()");
 
    const { classes } = this.props; 

    const handleOk = () => {

      log.traceInOut("handleClose()");

      this.setState({ open: false });

      this.props.onComplete( 
        this.state.values.size < this.props.values.size ?
          undefined : 
          this.state.values 
        );
      }

    const handleCancel = () => {

      log.traceInOut("handleCancel()");

      this.setState({ open: false });

      this.props.onComplete(undefined);
    }

    const onEditChange = ( label : string, value : string ) => {

      log.traceInOut("onEditChange()", {label}, {value});

      const values = new Map<string,string>( this.state.values );

      if( value != null && value.length > 0 ) {
        values.set( label, value );
      }
      else {
        values.delete( label );
      }

      const complete = values.size === this.props.values.size;

      this.setState({ 
        values: values,
        complete: complete  
      });
    }

    return (
      <React.Fragment>
        <Dialog
          open={this.state.open}
          onClose={() => handleCancel()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          {this.props.title == null ? null :
            <DialogTitle id="alert-dialog-title">{this.titleTranslation(this.props.title)}</DialogTitle>
          }
          <DialogContent className={classes.content}>
            <Grid container direction="column" alignItems="stretch" spacing={2} className={classes.grid}>
              {Array.from(this.props.values.keys()).map(label => (
                <Grid item key={label}>
                  <TextField
                    variant={propertyInputVariant}
                    fullWidth
                    label={translatedPropertyLabel( this.props.documentName, label ) }
                    InputLabelProps={{ shrink: true }}
                    value={this.state.values.get( label ) != null ? this.state.values.get( label ) : ""}
                    onChange={( event ) => {event.preventDefault(); onEditChange( label, event.target.value)}}
                  />
                </Grid>
              ))}
            </Grid>
          </DialogContent>
          <DialogActions className={classes.buttons}>
            <Button autoFocus onClick={handleCancel} color="primary"> 
              {this.props.t(ButtonKeyCancel)}
            </Button>
            <Button onClick={handleOk} color="primary" disabled={!this.state.complete}>
              {this.props.t(ButtonKeyOK)}
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

const ModifiedFormDialog = withTranslation()(withStyles(styles)(FormDialog));

export default ModifiedFormDialog;

