

import { LoggerFactory } from "../../common/api/loggerFactory";
import { Monitor } from "../../common/api/monitor";
import { Observation } from "../../common/api/observation";
import { Target } from "../../common/api/targets";
import { Observable } from "../../common/impl/observable";
import { StorageServiceIF } from "../api/storageServiceIF";
import { MediaManager } from "./mediaManager";


export const log = LoggerFactory.logger("storage");

export class StorageService extends Observable implements StorageServiceIF {

    constructor( target : Target, mediaManager : MediaManager ) {

        super();

        //log.traceInOut("constructor()", target );

        try {

            this.target = target;

            this.mediaManager = mediaManager;

        } catch (error) {
            log.warn("constructor()", "Error constructing storage service", error);

            throw new Error( (error as any).message );
        }
    }

    async init() : Promise<void> {

        log.traceInOut("init()" );

        try {

            this.mediaManager.init();


        } catch (error) {
            log.warn("init()", "Error initializing storage service", error);

            throw new Error( (error as any).message );
        }
    }


    protected async monitor( newMonitor : Monitor ): Promise<void> {}

    protected async release(observationFilter?: Observation[], objectIdsFilter?: string[]): Promise<void> {}

    readonly target : Target;

    readonly mediaManager : MediaManager; 

}
