
import firebase from 'firebase/compat/app';
import "firebase/compat/firestore";
import "firebase/compat/auth";
import "firebase/compat/storage";

import { initializeApp } from 'firebase/app';
import {initializeAuth, indexedDBLocalPersistence} from 'firebase/auth';


import { FirebaseService } from '../../../services/application/framework/firebase/firebaseService';
import { AuthenticationService } from '../../../services/authentication/framework/authenticationService';
import { FirebaseAuthenticationService } from '../../../services/authentication/impl/firebase/firebaseAuthenticationService';
import { SubscriptionManager } from '../../../services/billing';
import { EmulatorTarget, Target } from '../../../services/common/api/targets';
import { ConfigurationManager } from '../../../services/configuration/framework/configurationManager';
import { DatabaseFactory } from '../../../services/database/framework/databaseFactory';
import { DatabaseManager } from '../../../services/database/framework/databaseManager';
import { ClientFirestoreDatabaseManager } from '../../../services/database/framework/firebase/clientFirestoreDatabaseManager';
import { HealthguardDatabaseFactory } from '../../../healthguard/framework/healthguardDatabaseFactory';

import { MediaManager } from '../../../services/storage/framework/mediaManager';
import { FirebaseMediaManager } from '../../../services/storage/impl/firebase/firebaseMediaManager';
import { DatabaseAccess } from '../../../services/database/framework/databaseAccess';
import { HealthguardUserDatabaseAccess } from '../../../healthguard/framework/healthguardUserDatabaseAccess';
import { PersistentState } from '../../../services/common/framework/persistentState';
import LocalStoragePersistentState from '../persistentState/localStoragePersistentState';
import { Capacitor } from '@capacitor/core';
import { EmailSender } from '../../../services/messaging/framework/email/emailSender';
import { SmsSender } from '../../../services/messaging/framework/sms/smsSender';
import { PushSender } from '../../../services/messaging/framework/push/pushSender';
import { PushReceiver } from '../../../services/messaging/framework/push/pushReceiver';
import { CapacitorPushReceiver } from '../capacitor/capacitorPushReceiver';
import { Translator } from '../../../services/common/framework/translator';


import { HealthguardConfigurationManager } from '../../../healthguard/framework/healthguardConfigurationManager';
import { I18nextTranslator } from '../translator/i18nextTranslator';
import { KeyManager } from '../../../services/security/framework/keyManager';

import firebaseConfig from "healthguard/data/settings/firebaseConfig.json";
import securityConfiguration from "healthguard/data/settings/security.json";

export class FirebaseClientService extends FirebaseService {

    constructor( target : Target ) {

        super( target, firebase );

        try {
            //log.traceIn( "constructor()", process.env.REACT_APP_TARGET )

            if( firebase.apps.length === 0 ) {

                const targetFirebaseConfig = (firebaseConfig as any)[target];

                firebase.initializeApp( targetFirebaseConfig );

                if ( Capacitor.isNativePlatform() ) {
                    const app = initializeApp( targetFirebaseConfig );

                    initializeAuth( app, {
                        persistence: indexedDBLocalPersistence
                    });
                }

                if( target === EmulatorTarget ) {
                    
                    firebase.firestore().useEmulator( targetFirebaseConfig.authDomain, targetFirebaseConfig.firestorePort);
                    
                    firebase.auth().useEmulator( "http://" + targetFirebaseConfig.authDomain + ":" + targetFirebaseConfig.authPort );                
                }

                //log.debug( "constructor()", "initialized", targetFirebaseConfig ) ;
            }

            this.authenticationService = new FirebaseAuthenticationService( firebase.auth() );

            this.mediaManager = new FirebaseMediaManager();

            this.configurationManager = new HealthguardConfigurationManager();

            const clientEncryption = (securityConfiguration as any)[target] != null ?
                (securityConfiguration as any)[target].clientEncryption : securityConfiguration.clientEncryption;

            this.databaseManager = new ClientFirestoreDatabaseManager( { clientEncryption: clientEncryption } );

            this.databaseFactory = new HealthguardDatabaseFactory( this.configurationManager, this.databaseManager );

            this.databaseAccess = new HealthguardUserDatabaseAccess( this.databaseFactory as HealthguardDatabaseFactory );

            this.persistentState = new LocalStoragePersistentState();

            this.pushReceiver = new CapacitorPushReceiver(); 

            this.translator = new I18nextTranslator(); 

            //log.traceOut( "constructor()",  ) ;

        } catch (error) {
            console.log( "Failed to initialize Firebase", error );
            //log.warn( "Failed to initialize Firebase", error ) ;

            throw new Error( (error as any).message );
        }
    }

    database() : any {

        return firebase.firestore();
    }

    authenticator() : any {

        return firebase.auth();
    }

    storage() : any {
        return firebase.storage();
    }

    messaging() : any {
        return firebase.messaging();
    }

    readonly authenticationService : AuthenticationService;

    readonly mediaManager? : MediaManager;

    readonly keyManager? : KeyManager;

    readonly subscriptionManager? : SubscriptionManager;

    readonly configurationManager : ConfigurationManager;

    readonly databaseManager : DatabaseManager;

    readonly databaseFactory: DatabaseFactory;

    readonly databaseAccess: DatabaseAccess;

    readonly emailSender? : EmailSender;

    readonly smsSender? : SmsSender;

    readonly pushSender? : PushSender;

    readonly pushReceiver? : PushReceiver;

    readonly persistentState: PersistentState;

    readonly translator: Translator;
}