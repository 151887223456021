
export const Sexes = {

    Male       : "male",
    
    Female     : "female"

}

export type Sex = keyof (typeof Sexes);
