import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import { withTranslation, WithTranslation } from 'react-i18next';

import { ForceGraph2D } from 'react-force-graph';

import ChangeHistoryIcon from '@mui/icons-material/ChangeHistory';
import ChangeHistoryTwoToneIcon from '@mui/icons-material/ChangeHistoryTwoTone';

import SquareIcon from '@mui/icons-material/Square';
import SquareTwoToneIcon from '@mui/icons-material/SquareTwoTone';

import ForwardOutlinedIcon from '@material-ui/icons/ForwardOutlined';
import ForwardTwoToneIcon from '@material-ui/icons/ForwardTwoTone';

import { AppContext, AppContextProps } from 'ui/app/appContext';
import { log } from 'ui/app/app';

import { DatabaseDocumentIF } from 'services/database/api/core/databaseDocumentIF';

import { errorDialog } from './simpleDialog';
import { DefinitionPropertyIF, DefinitionsPropertyIF, PropertiesSelector, DocumentsPropertyIF } from '../../services/database';
import { Factory } from '../../services/common/api/factory';
import theme from '../app/theme';
import { DatabaseProps, DatabaseState } from './databaseView';
import { documentColor } from './documentColor';
import { selectProperty } from './selectDialog';
import { PersistentKeyPreviousProperty, PersistentKeyNextProperty, PersistentKeyDirected, DefaultDirected } from './appFrame';
import Loading from './loading';
import { Checkbox, Grid, Tooltip } from '@material-ui/core';
import { translatedDefinition } from './definitionText';
import { HealthguardDefinition } from '../../healthguard';
import { translatedPropertyValue } from './propertyValue';
import { definitionColor } from './definitionColor';
import { colorMap } from './colorMap';
import { propertyColor } from './propertyColor';
import { DatabaseObserverIF } from '../../services/database/api/core/databaseObserverIF';
import { ObservableIF } from '../../services/common/api/observableIF';
import { Observation } from '../../services/common/api/observation';
import { Monitor } from '../../services/common/api/monitor';
import { PropertyTypes } from '../../services/database/api/definitions/propertyType';

const excludePropertyKeys = [
  "id",
  "title",
  "description",
  "name",
  "lastChangedBy",
  "archived"];

const excludePropertyTypes = [
  PropertyTypes.Collection,
  PropertyTypes.Date,
  PropertyTypes.Data,
  PropertyTypes.Map,
  PropertyTypes.Empty,
  PropertyTypes.Geolocation,
  PropertyTypes.PhoneNumber,
  PropertyTypes.Subdocument
];

export const previousPropertiesSelector = {

  excludePropertyTypes: excludePropertyTypes.concat( [

  ]),

  excludePropertyKeys: excludePropertyKeys.concat( [

  ])

} as PropertiesSelector

export const nextPropertiesSelector = {

  exlcudePropertyTypes: excludePropertyTypes.concat( [

  ]),

  excludePropertyKeys: excludePropertyKeys.concat( [
    
  ])

} as PropertiesSelector


const styles = (theme: Theme) => createStyles({
  root: {
    width: '100%',
    height: '100%',
    padding: 0,
    margin: 0
  },  
  graph: {
    width: '100%',
    height: '100%',
    padding: 0
  },
  buttons: {
    float: "right",
    top: theme.spacing(4),
    justifyContent: 'space-between',
    width: "30%",
    marginLeft: "-30%"
  },
  linkPropertyButtons: {
    justifyContent: 'flex-end',
    margin: 0,
    padding: 0
  },
  directedButton: {
    justifyContent: 'flex-end',
    margin: 0,
    padding: 0
  },
  checkbox: {
    marginTop: -theme.spacing(0.5)
  }
});

const nodeSize = 10;

const nextNodeSize = 8;

const previousNodeSize = 8;

type GraphNode = {

  id: string,

  name: string,

  databaseDocument? : DatabaseDocumentIF, 

  definition?: HealthguardDefinition,

  color?: string,

  linkPropertyKey? : string
}

type GraphLink = {

  source: string,

  target: string
}

type GraphData = {

  nodes: Map<string,GraphNode>,

  links: Map<string,GraphLink[]>
}

export interface DatabaseGraphProps extends DatabaseProps {

  databaseObserver : DatabaseObserverIF<DatabaseDocumentIF>,

  directed? : boolean,

  previousPropertyKey?: string,

  nextPropertyKey?: string,

  hideSelectDirected? : boolean

  hideSelectNextPropertyKey? : boolean,

  hideSelectPreviousPropertyKey? : boolean

}

interface MatchParams {
}

export interface ReactDatabaseGraphProps extends
  DatabaseGraphProps,
  WithStyles<typeof styles>,
  WithTranslation,
  RouteComponentProps<MatchParams> {
}


interface DatabaseGraphState extends DatabaseState { // Component State

  loading: boolean,

  graphWidth: number,

  graphHeight: number,

  maxZoom?: number,

  directed? : boolean,

  previousPropertyKey: string | undefined | null,

  nextPropertyKey: string | undefined | null,

  graphData: GraphData

}


class DatabaseGraph extends React.PureComponent<ReactDatabaseGraphProps, DatabaseGraphState> {

  constructor(props: ReactDatabaseGraphProps) {

    super(props);

    this.containerRef = React.createRef();

    this.graphRef = React.createRef();

    this.state = {

      loading: true,

      nodes: new Map<string, GraphNode>(),

      links: new Map<string, GraphLink[]>(),

      graphWidth: 0,

      graphHeight: 0,
      
      maxZoom: 1,

      highlightedPropertyKey: this.props.highlightedPropertyKey,

      previousPropertyKey: undefined,

      nextPropertyKey: undefined,

      graphData: { 

        nodes: new Map<string,GraphNode>(),

        links: new Map<string,GraphLink[]>() 

      } as GraphData

    } as DatabaseGraphState;

    this.onUpdateDatabases = this.onUpdateDatabases.bind(this);

    this.updateGraphDataWithNode = this.updateGraphDataWithNode.bind(this);
    this.updateGraphDataWithLinks = this.updateGraphDataWithLinks.bind(this);
    this.updateGraphData = this.updateGraphData.bind(this);

    this.previousPropertyKey = this.previousPropertyKey.bind(this);
    this.previousPropertyPersistentKey = this.previousPropertyPersistentKey.bind(this);
    this.handlePreviousProperty = this.handlePreviousProperty.bind(this);

    this.nextPropertyKey = this.nextPropertyKey.bind(this);
    this.nextPropertyPersistentKey = this.nextPropertyPersistentKey.bind(this);
    this.handleNextProperty = this.handleNextProperty.bind(this);

    this.directed = this.directed.bind(this);
    this.directedPersistentKey = this.directedPersistentKey.bind(this);
    this.handleDirected = this.handleDirected.bind(this);

    this.updateGraphSize = this.updateGraphSize.bind(this);
    this.updateGraphZoom = this.updateGraphZoom.bind(this);

    this.nodeColor = this.nodeColor.bind(this);
    this.nodeObject = this.nodeObject.bind(this);

    this.onOpenDocument = this.onOpenDocument.bind(this);

    //log.traceInOut( "constructor()" );
  } 

  async componentDidMount() {

    try {
      log.traceIn("componentDidMount()" );

      await this.updateGraphData( 
        this.props.highlightedPropertyKey,
        this.previousPropertyKey(), 
        this.nextPropertyKey() );  
        
      await this.props.databaseObserver.subscribe({ 
        observer: this,
        onNotify: this.onUpdateDatabases 
        } as Monitor );

      await this.props.databaseObserver.update();
        
      this.updateGraphSize();

      window.addEventListener('resize', this.updateGraphSize);

      this.setState( {
        loading: false
      });

      log.traceOut("componentDidMount()");

    } catch( error ) {
          
      log.warn( "componentDidMount()", error ); 

      await errorDialog( error);

      log.traceOut( "componentDidMount()", error );
    }
  }
 
  async componentDidUpdate() {

    try {
      //log.traceIn("componentDidUpdate()" );

      await this.props.databaseObserver.update();

      if( this.props.highlightedPropertyKey !== this.state.highlightedPropertyKey ) {

        await this.updateGraphData( 
          this.props.highlightedPropertyKey,
          this.previousPropertyKey(), 
          this.nextPropertyKey() );      
      }

      //log.traceOut("componentDidUpdate()" );

    } catch( error ) {
          
      log.warn( "componentDidUpdate()", error );

      await errorDialog( error);

    }
  }

  async componentWillUnmount() {
    log.traceIn("componentWillUnmount()");

    try {

      await this.props.databaseObserver.unsubscribe( this );

      window.removeEventListener('resize', this.updateGraphSize);

      log.traceOut("componentWillUnmount()");

    } catch (error) {
      log.warn("componentWillUnmount()", "Error unmounting collection list", error);

    }
  }

  private onUpdateDatabases = async (observable : ObservableIF, observation : Observation ) => {

    //log.traceIn("onUpdateDatabases()");

    try {
      if( observable !== this.props.databaseObserver ) {
        throw new Error("Mismatching database observer");
      }

      await this.updateGraphData( 
        this.state.highlightedPropertyKey,
        this.previousPropertyKey(), 
        this.nextPropertyKey() );  

      //log.traceOut("onUpdateDatabases()");

    } catch (error) {
      log.warn("onUpdateDatabases()", "Error updating databases", error);

      log.traceOut("onUpdateDatabases()", "error");
    }
  }

  private async updateGraphData(  
    highlightedPropertyKey : string | undefined,
    previousPropertyKey : string | null | undefined,
    nextPropertyKey : string | null | undefined ) {

    //log.traceIn("updateGraphData()");

    try {

      const graphData = { 

        nodes: new Map<string,GraphNode>(),

        links: new Map<string,GraphLink[]>() 

      } as GraphData;

      for (const databaseDocument of this.props.databaseObserver.filteredDocuments().values()!) {

        await this.updateGraphDataWithNode( 
          graphData, 
          databaseDocument,
          previousPropertyKey,
          nextPropertyKey );
      }

      Factory.get().persistentState!.setProperty( this.previousPropertyPersistentKey(), previousPropertyKey );

      Factory.get().persistentState!.setProperty( this.nextPropertyPersistentKey(), nextPropertyKey);

      this.setState({ 

        highlightedPropertyKey: highlightedPropertyKey,

        previousPropertyKey : previousPropertyKey,

        nextPropertyKey : nextPropertyKey,

        graphData: graphData

       })
      //log.traceOut("updateGraphData()");

    } catch (error) {
      log.warn("Error reading list rows for documents", error);

      throw new Error( (error as any).message );
    }
  }

  async updateGraphDataWithNode( graphData : GraphData, 
    databaseDocument: DatabaseDocumentIF,
    previousPropertyKey : string | null | undefined,
    nextPropertyKey: string | null | undefined ) { 

    //log.traceInOut("updateGraphDataWithNode()", {databaseDocument} );

    try {

      const referenceHandle = databaseDocument.referenceHandle();
  
      const graphNode = { 
        id: referenceHandle.path,
        name: referenceHandle.title != null ? referenceHandle.title : this.props.t(""),
        databaseDocument: databaseDocument
      } as GraphNode;

      graphData.nodes.set( referenceHandle.path, graphNode ); 

      //log.debug("updateGraphDataWithNode()", referenceHandle.path, {graphNode} );

      if( previousPropertyKey != null ) {

        await this.updateGraphDataWithLinks( graphData, 
          databaseDocument, 
          previousPropertyKey, 
          false );
      }

      if( nextPropertyKey != null ) {

        await this.updateGraphDataWithLinks( graphData, 
          databaseDocument, 
          nextPropertyKey, 
          true );
      }

      //log.traceOut("updateGraphDataWithNode()" );

    } catch( error ) {
      
      log.warn( "updateGraphDataWithNode()", error );

    }
  }

  async updateGraphDataWithLinks( graphData : GraphData, 
    nodeDocument: DatabaseDocumentIF,
    linkPropertyKey : string,
    isSource: boolean ) {

    //log.traceInOut("updateGraphDataWithLinks()", {databaseDocument} );

    try {

      const nodeDocumentReference = nodeDocument.referenceHandle();
  
      //log.debug("updateGraphDataWithLinks()", referenceHandle.path, {graphNode} );

      const newLinks : GraphLink[] = [];

      //log.debug("updateGraphDataWithLinks()", "linkReferenceKey", referenceKey );

      if( linkPropertyKey != null ) {

        const linkProperty = nodeDocument.property(linkPropertyKey);

        if (linkProperty != null && linkProperty.value() != null) {

          switch (linkProperty.type) {

            case PropertyTypes.Tenant:
            case PropertyTypes.Owner:
            case PropertyTypes.Reference:
            {
              const linkDocument = await (linkProperty as any).document() as DatabaseDocumentIF;

              let linkDocumentName = linkDocument.documentName();
              
              if( linkDocumentName == null ) {
                linkDocumentName = linkPropertyKey; 
              }

              const linkReferenceHandle = linkDocument.referenceHandle();

              if (this.props.databaseObserver.ignoreDocumentNames() == null || 
                  !this.props.databaseObserver.ignoreDocumentNames()!.includes(linkDocumentName)) {

                if (nodeDocument.documentName() !== linkDocumentName ||
                  this.props.databaseObserver.filteredDocuments().has(linkReferenceHandle.path)) {

                  let graphLink;

                  if (isSource) {
                    graphLink = {
                      source: nodeDocumentReference.path,
                      target: linkReferenceHandle.path
                    } as GraphLink;

                  }
                  else {
                    graphLink = {
                      source: linkReferenceHandle.path,
                      target: nodeDocumentReference.path
                    } as GraphLink;
                  }

                  newLinks.push(graphLink);

                  //log.debug("updateGraphDataWithLinks()", {graphLink} );

                  if (nodeDocument.documentName() !== linkDocumentName) {

                    const linkReferenceGraphNode = {
                      id: linkReferenceHandle.path,
                      name: linkReferenceHandle.title != null ? linkReferenceHandle.title : this.props.t(""),
                      databaseDocument: linkDocument,
                      linkPropertyKey: linkPropertyKey
                    } as GraphNode;

                    graphData.nodes.set(linkReferenceHandle.path, linkReferenceGraphNode);

                    //log.debug("updateGraphDataWithLinks()", {previousReferenceGraphNode} );
                  }
                }
              }

              break;
            }
            case PropertyTypes.References:  
            case PropertyTypes.SymbolicCollection:  
            case PropertyTypes.SymbolicOwners:  
            {
              const linkDocuments = await (linkProperty as DocumentsPropertyIF<DatabaseDocumentIF>).documents();
              
              for (const linkDocument of linkDocuments.values() ) { 

                let linkDocumentName = linkDocument.documentName();

                if( linkDocumentName == null ) {
                  linkDocumentName = linkPropertyKey;
                }

                const linkReferenceHandle = linkDocument.referenceHandle();

                if( this.props.databaseObserver.ignoreDocumentNames() != null && 
                    this.props.databaseObserver.ignoreDocumentNames()!.includes( linkDocumentName ) ) {
                  continue;
                }

                if( nodeDocument.documentName() !== linkDocumentName ||
                    this.props.databaseObserver.filteredDocuments().has( linkReferenceHandle.path )) {

                  let graphLink;

                  if (isSource) {
                    graphLink = {
                      source: nodeDocumentReference.path,         
                      target: linkReferenceHandle.path
                    } as GraphLink;
                  }
                  else {
                    graphLink = {
                      source: linkReferenceHandle.path,
                      target: nodeDocumentReference.path
                    } as GraphLink;
                  }

                  newLinks.push( graphLink );

                  //log.debug("updateGraphDataWithLinks()", {graphLink} );

                  if( nodeDocument.documentName() !== linkDocumentName ) {

                    const linkReferenceGraphNode = { 
                      id: linkReferenceHandle.path,
                      name: linkReferenceHandle.title != null ? linkReferenceHandle.title : this.props.t(""),
                      databaseDocument: linkDocument,
                      linkPropertyKey: linkPropertyKey
                    } as GraphNode;
              
                    graphData.nodes.set( linkReferenceHandle.path, linkReferenceGraphNode ); 
  
                    //log.debug("updateGraphDataWithLinks()", {previousReferenceGraphNode} );
                  }
                }
              }
              break;
            }
            case PropertyTypes.Definitions:
            case PropertyTypes.Texts:
            {
              const values = (linkProperty as any).values() as string[];

              let definition;

              if (values != null) {

                for (const value of values) {

                  const nodeName = linkProperty.key() + "." + value;

                  let graphLink;

                  if (isSource) {
                    graphLink = {
                      source: nodeDocumentReference.path,
                      target: nodeName
                    } as GraphLink;
                  }
                  else {
                    graphLink = {
                      source: nodeName,
                      target: nodeDocumentReference.path
                    } as GraphLink;
                  }

                  newLinks.push(graphLink);

                  let name;

                  if( linkProperty.type === PropertyTypes.Definitions ) {

                    definition = (linkProperty as DefinitionsPropertyIF<string>).definition as HealthguardDefinition;

                    name = translatedDefinition(
                      definition,
                      value);

                    
                  }
                  else {
                    name = value + "";
                  }

                  const referenceNode = {
                    id: nodeName,
                    name: name,
                    definition: definition,
                    linkPropertyKey: linkPropertyKey
                  } as GraphNode;

                  graphData.nodes.set(nodeName, referenceNode);

                  //log.debug("updateGraphDataWithLinks()", {previousReferenceGraphNode} );
                }
              }
              break;
            }
            default:
            {
              let definition;

              const value = linkProperty.value();

              if (value != null) {

                const nodeName = linkProperty.key() + "." + value;

                let graphLink;

                if (isSource) {
                  graphLink = {
                    source: nodeDocumentReference.path,
                    target: nodeName
                  } as GraphLink;
                }
                else {
                  graphLink = {
                    source: nodeName,
                    target: nodeDocumentReference.path
                  } as GraphLink;
                }

                newLinks.push(graphLink);

                let name;

                if( linkProperty.type === PropertyTypes.Definition ) {

                  definition = (linkProperty as DefinitionPropertyIF<string>).definition as HealthguardDefinition;

                  name = translatedDefinition( definition, value)
                }
                else{
                  name = translatedPropertyValue( linkProperty.parent.documentName(), value )
                }

                const referenceNode = {
                  id: nodeName,
                  name: name,
                  definition: definition,
                  linkPropertyKey: linkPropertyKey
                } as GraphNode;

                graphData.nodes.set(nodeName, referenceNode);

                //log.debug("updateGraphDataWithLinks()", {previousReferenceGraphNode} );
                ;
              }
              break;
            }
          }
        }
      }

      const previousLinks = graphData.links.get( nodeDocumentReference.path );

      if( previousLinks == null ) {

        graphData.links.set( nodeDocumentReference.path, newLinks ); 

      }
      else {
        graphData.links.set( nodeDocumentReference.path, previousLinks.concat( newLinks ) ); 
      }
      
      //log.traceOut("updateGraphDataWithLinks()" );

    } catch( error ) {
      
      log.warn( "updateGraphDataWithLinks()", error );

    }
  }

  private previousPropertyPersistentKey() {
    const appContext = this.context as AppContextProps;

    return appContext.currentHomePath + "." + this.props.databaseObserver.defaultDocumentName()! + "." + PersistentKeyPreviousProperty ;
  }

  private async handlePreviousProperty () {

    log.traceIn("handlePreviousProperty ()",);

    const sourceDocument = this.props.databaseObserver.databaseQuery()!.databases![0].newDocument()!; 

    const previousPropertyKey = await selectProperty( 
      sourceDocument,
      previousPropertiesSelector,
      this.props.t("previousProperty"),
      this.previousPropertyKey()
      );
    
    if( previousPropertyKey !== undefined ) {

      await this.updateGraphData( 
        this.state.highlightedPropertyKey,
        previousPropertyKey != null ? previousPropertyKey : undefined, 
        this.nextPropertyKey() );
    }
  }

  private previousPropertyKey (): string | null | undefined {

    //log.traceIn("previousPropertyKey ()",);

    if (this.state.previousPropertyKey !== undefined) {
      //log.traceOut("previousProperty ()", "From state", this.state.previousPropertyKey );
      return this.state.previousPropertyKey ;
    }

    const persistentPreviousProperty = Factory.get().persistentState!.property(
      this.previousPropertyPersistentKey());

    if (persistentPreviousProperty !== undefined) {

      //log.traceOut("previousProperty ()", "From persistent app state", persistentPreviousProperty);
      return persistentPreviousProperty;
    }

    //log.traceOut("previousPropertyKey ()", "From default", this.props.previousPropertyKey );
    return this.props.previousPropertyKey ;
  }


  private nextPropertyPersistentKey() {
    const appContext = this.context as AppContextProps;

    return appContext.currentHomePath + "." + this.props.databaseObserver.defaultDocumentName()! + "." + PersistentKeyNextProperty ;
  }

  private async handleNextProperty () {

    log.traceIn("handleNextProperty ()",);

    const sourceDocument = this.props.databaseObserver.databaseQuery()!.databases![0].newDocument()!; 

    const nextPropertyKey = await selectProperty( 
      sourceDocument,
      nextPropertiesSelector,
      this.props.t("nextProperty"),
      this.nextPropertyKey() );
    
    if( nextPropertyKey !== undefined ) {

      await this.updateGraphData( 
        this.state.highlightedPropertyKey,
        this.previousPropertyKey(), 
        nextPropertyKey );
    }
  }

  private nextPropertyKey (): string | null | undefined {

    //log.traceIn("nextPropertyKey ()",);

    if (this.state.nextPropertyKey !== undefined) {
      //log.traceOut("nextProperty ()", "From state", this.state.nextPropertyKey );
      return this.state.nextPropertyKey ;
    }

    const persistentNextProperty = Factory.get().persistentState!.property(
      this.nextPropertyPersistentKey());

    if (persistentNextProperty !== undefined) {

      //log.traceOut("nextProperty ()", "From persistent app state", persistentNextProperty);
      return persistentNextProperty;
    }

    //log.traceOut("nextPropertyKey ()", "From default", this.props.nextPropertyKey );
    return this.props.nextPropertyKey ;
  }

  private directedPersistentKey = (): string => {
    return this.context.currentHomePath!.substring(1) + "." + PersistentKeyDirected;
  }

  private directed = (): boolean => {

    //log.traceIn("directed()");

    if (this.state.directed != null) {
      //log.traceOut("directed()", "From state", this.state.directed);
      return this.state.directed;
    }

    const persistentDirected = Factory.get().persistentState!.property( this.directedPersistentKey()) as boolean;

    if (persistentDirected != null) {

      //log.traceOut("directed()", "From persistent app state", persistentDirected);
      return persistentDirected;
    } 

    if( this.props.directed != null ) {

      //log.traceOut("directed()", "From property", this.props.directed);
      return this.props.directed;
    }

    //log.traceOut("directed()", "From property", DefaultDirected);
    return DefaultDirected;
  };

  private handleDirected = async () => {
    log.traceInOut("handleDirected()");

    const directed = this.directed();

    Factory.get().persistentState!.setProperty( this.directedPersistentKey(), !directed);

    this.setState({ directed: !directed });
  };


  async onOpenDocument( path: string, databaseDocument? : DatabaseDocumentIF ) {

    log.traceIn("onOpenDocument()", path );

    try {

      if( !path.startsWith("/")) { 

        log.traceOut("onOpenDocument()", "not a document path" );
        return;
      }

      if( this.props.onOpenDocument != null ) {

        if( databaseDocument != null ) {

          this.props.onOpenDocument( this.props.databaseObserver, databaseDocument );

        }
      }


      log.traceOut("onOpenDocument()");

    } catch( error ) {

      log.warn( "onOpenDocument()", error );

      await errorDialog( error);

    }
  }

  private updateGraphSize = () => {

    log.traceInOut("updateGraphSize()", this.containerRef.current?.clientWidth, this.containerRef.current?.clientHeight  );

    const width = this.containerRef.current != null ? this.containerRef.current.clientWidth : this.state.graphWidth;

    const height = this.containerRef.current != null ? this.containerRef.current.clientHeight : this.state.graphHeight;

    if( width !== this.state.graphWidth || height !== this.state.graphHeight ) {
      this.setState( {

        graphWidth: width,
  
        graphHeight: height
      });
    }
  }


  private updateGraphZoom = () => {

    if(  this.state.maxZoom != null ) {

      this.setState( { 
        maxZoom: undefined
      });
    }
  }

  private nodeColor = ( graphNode : GraphNode ) : string => {

    if( graphNode.color != null ) {
      return graphNode.color;
    }

    const propertyKey = graphNode.id.split(".")[0];

    const propertyValue = graphNode.id.split(".")[1];

    if( graphNode.databaseDocument != null ) {
      
      const highlightedProperty = this.state.highlightedPropertyKey == null ? undefined :
        graphNode.databaseDocument.property( this.state.highlightedPropertyKey );

      if( !this.props.multipleDocuments && highlightedProperty != null ) {

        graphNode.color = propertyColor( highlightedProperty );
      }
      else {
        graphNode.color = documentColor(graphNode.databaseDocument); 
      }
    }
    else if( graphNode.definition != null ) { 
      graphNode.color = definitionColor( graphNode.definition, propertyValue  );
    }

    if( graphNode.color == null ) {
      graphNode.color = colorMap( propertyKey, propertyValue  );
    }

    return graphNode.color!; 

  }

  private nodeObject = ( graphNode : any, context : CanvasRenderingContext2D ) => {

    const previousPropertyKey = this.previousPropertyKey();

    const nextPropertyKey = this.nextPropertyKey();

    context.fillStyle = this.nodeColor( graphNode ); 

    if( previousPropertyKey != null && graphNode.linkPropertyKey === previousPropertyKey ) {
      // Previous node is a square
      context.fillRect(graphNode.x - previousNodeSize, graphNode.y - previousNodeSize, previousNodeSize*2, previousNodeSize*2); 
    }
    else if( nextPropertyKey != null && graphNode.linkPropertyKey === nextPropertyKey) {

    // Next node is a square

      context.beginPath(); 
      context.moveTo(graphNode.x, graphNode.y - nextNodeSize); 
      context.lineTo(graphNode.x - nextNodeSize, graphNode.y + nextNodeSize); 
      context.lineTo(graphNode.x + nextNodeSize, graphNode.y + nextNodeSize); 
      context.fill(); 
    }
    else {
      // Regular node is a circle

      context.beginPath();
      context.arc( graphNode.x, graphNode.y, nodeSize, 0, 2 * Math.PI, false); 
      context.fill();
    }
  }


  render(): JSX.Element {

    //log.traceInOut("render()");

    const { classes } = this.props;

    if (this.state.graphData.nodes.size === 0 ) {
      return( <></> ); 
    }

    const allNodes = Array.from( this.state.graphData.nodes.values() );

    let allLinks: GraphLink[] = [];

    for( const links of this.state.graphData.links.values()) {

      allLinks = allLinks.concat( links );
    }

    //log.debug( "render()", this.containerRef.current, this.containerRef.current?.clientWidth, this.containerRef.current?.clientHeight )


    const width = this.containerRef.current != null ? this.containerRef.current.clientWidth : this.state.graphWidth;

    const height = this.containerRef.current != null ? this.containerRef.current.clientHeight : this.state.graphHeight;

    return (
      <>
        {this.state.loading ? <Loading /> :
          <Grid container className={classes.root}>
            <div ref={this.containerRef} className={classes.graph} >
              <ForceGraph2D
                maxZoom={this.state.maxZoom}
                ref={this.graphRef}
                nodeRelSize={nodeSize}
                width={width}
                height={height}
                onNodeClick={(node) => this.onOpenDocument(node.id! + "", (node as GraphNode).databaseDocument )}
                graphData={{
                  nodes: allNodes,
                  links: allLinks
                }}
                linkVisibility={true}
                linkDirectionalArrowLength={this.directed() ? 10 : 0}
                linkDirectionalArrowRelPos={1}
                linkDirectionalArrowColor={theme.palette.secondary.main}
                linkColor={theme.palette.secondary.main}
                nodeCanvasObject={(node, context) => this.nodeObject(node, context)}
                onEngineTick={() => this.updateGraphZoom()}
              />
            </div>
            <Grid className={classes.buttons} item container direction="column">
              <Grid className={classes.linkPropertyButtons} container item>
                <Grid item>
                  {!this.props.hideSelectDirected &&
                    <Tooltip title={(<>{this.props.t("directed")}</>)}>
                      <Checkbox
                        className={classes.checkbox}
                        size="small"
                        icon={<ForwardOutlinedIcon />}
                        checkedIcon={<ForwardTwoToneIcon />}
                        checked={this.directed()}
                        onClick={(event) => this.handleDirected()}
                      />
                    </Tooltip>
                  }
                </Grid>
                <Grid item>
                  {!this.props.hideSelectPreviousPropertyKey &&
                    <Tooltip title={(<>{this.props.t("previousProperty")}</>)}>
                      <Checkbox
                        className={classes.checkbox}
                        size="small"
                        icon={<SquareIcon />}
                        checkedIcon={<SquareTwoToneIcon />}                        
                        checked={this.previousPropertyKey() != null}
                        onClick={(event) => this.handlePreviousProperty()}
                      />
                    </Tooltip>
                  }
                </Grid>
                <Grid item>
                  {!this.props.hideSelectNextPropertyKey &&
                    <Tooltip title={(<>{this.props.t("nextProperty")}</>)}>
                      <Checkbox
                        className={classes.checkbox}
                        size="small"
                        icon={<ChangeHistoryIcon />}
                        checkedIcon={<ChangeHistoryTwoToneIcon />}
                        checked={this.nextPropertyKey() != null}
                        onClick={(event) => this.handleNextProperty()}
                      />
                    </Tooltip>
                  }
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
      </>
    );
  }

  private containerRef: React.RefObject<HTMLDivElement>;

  private graphRef: any;

}

DatabaseGraph.contextType = AppContext;

const ModifiedDatabaseGraph = withRouter(withTranslation()(withStyles(styles)(DatabaseGraph)));

export default ModifiedDatabaseGraph;

