
import { BasicPropertyIF } from "../../api/properties/basicPropertyIF";
import { DatabaseObject } from "../../framework/databaseObject";
import { DatabaseProperty } from "../../framework/databaseProperty";
import { PropertyType } from "../../api/definitions/propertyType"; 


export abstract class BasicProperty<BasicType extends string | number | boolean> 
    extends DatabaseProperty<BasicType> implements BasicPropertyIF<BasicType> {

    constructor( type : PropertyType, parent : DatabaseObject, defaultValue? : BasicType ) {
        super( type, parent );

        this._defaultValue = defaultValue;
    }

    value( ignoreDefault? : boolean ) : BasicType | undefined {

        this.decryptData();

        if( this._value != null ) {
            return this._value;
        }
        return !!ignoreDefault ? undefined : this._defaultValue;
    }

    setValue( value : BasicType | undefined ): void {

        this.decryptData();

        if( this.compareValue( value ) !== 0 ) {

            this.setLastChange( this._value );

            this._value = value;

            delete this.error;
        }
    }


    defaultValue() : BasicType | undefined {

        return this._defaultValue;
    }

    setDefaultValue( defaultValue : BasicType | undefined ): void {
        this._defaultValue = defaultValue;
    }


    fromData( documentData: any): void {

        if( this.isEncryptedData( documentData[this.key()] ) ) {

            this.setEncryptedData( documentData[this.key()] );
        }
        else {
            this._value = documentData[this.key()];
        }
    }


    async toData( documentData: any, force? : boolean ) : Promise<void> {

        if( !!force ) {
            this.decryptData();
        }

        if( this.encrypted() && this.encryptedData() != null ) {
            
            documentData[this.key()] = this.encryptedData();
            return;
        }
        
        let data;

        if( this.encrypted() ) {

            data = this.encryptData( this._value )
        }
        else {
            data = this._value;
        }

        if( data != null ) {
            documentData[this.key()] = data;
        }
    }

    includes( other : BasicProperty<BasicType> ) : boolean {
        return this.includesValue( other.value() );
    }


    includesValue( value : BasicType | undefined ) : boolean {
        return this.compareValue( value ) === 0;
    }

    protected _value : BasicType | undefined;

    protected _defaultValue : BasicType | undefined;


}