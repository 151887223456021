
import { CollectionDatabase } from "../core/collectionDatabase";
import { DatabaseDocument } from "../../framework/databaseDocument";
import { log } from "../../framework/databaseService";
import { DefinitionProperty } from "../properties/definitionProperty";
import { TextProperty } from "../properties/textProperty";
import { CompaniesCollection} from "../../api/collections";
import { SubscriptionIF } from "../../api/documents/subscriptionIF";
import { TenantProperty } from "../properties/tenantProperty";
import { CompanyDocument, SubscriptionDocument} from "../../api/documents";
import { Company } from "./company";
import { BillingMethod, BillingMethods, DefaultBillingMethod } from "../../api/definitions/billingMethod";
import { NumberProperty } from "../properties/numberProperty";
import { DefaultTimeSpan, TimeSpan, TimeSpans } from "../../api/definitions/timeSpan";
import { Currencies, Currency } from "../../api/definitions/currency";
import { PaymentProvider, PaymentProviders } from "../../api/definitions/paymentProvider";
import { DefaultSubscriptionStatus, SubscriptionStatus, SubscriptionStatuses } from "../../api/definitions/subscriptionStatus";
import { BillingMethodDefinition, CurrencyDefinition, PaymentProviderDefinition, SubscriptionStatusDefinition, TimeSpanDefinition } from "../../api/definitions";
import { DateProperty } from "../properties/dateProperty";
import { SubdocumentProperty } from "../properties/subdocumentProperty";
import { Address } from "../subdocuments/address";
import { PhoneNumberProperty } from "../properties/phoneNumberProperty";
import { TextType, TextTypes } from "../../api/definitions/textTypes";


export class Subscription extends DatabaseDocument implements SubscriptionIF {

    constructor( subscriptionsCollection : CollectionDatabase<Subscription>, documentPath? : string  ) {

        super( SubscriptionDocument, subscriptionsCollection, documentPath );

        try {
            this.company = new TenantProperty<Company>( this, CompaniesCollection, CompanyDocument ); 
            this.company.required = true;

            this.accountId = new TextProperty( this );
            this.accountId.encrypt = false;

            this.companyName = new TextProperty( this );

            this.email = new TextProperty( this, TextTypes.Email as TextType );
            this.email.encrypt = false;

            this.contactName = new TextProperty( this );

            const billingAddressSubdocument = new Address( this, "billingAddress");
            this.billingAddress = new SubdocumentProperty<Address>( this, billingAddressSubdocument );

            this.phoneNumber = new PhoneNumberProperty( this, billingAddressSubdocument.country );
            
            this.subscriptionId = new TextProperty( this );
            this.subscriptionId.encrypt = false;

            this.subscriptionStatus = new DefinitionProperty<SubscriptionStatus>( 
                this, 
                SubscriptionStatusDefinition, 
                SubscriptionStatuses,
                DefaultSubscriptionStatus );
            this.subscriptionStatus.required = true;


            this.billingMethod = new DefinitionProperty<BillingMethod>( 
                this, 
                BillingMethodDefinition, 
                BillingMethods,
                DefaultBillingMethod );           
            this.billingMethod.required = true;

            this.paymentProvider = new DefinitionProperty<PaymentProvider>( 
                this, 
                PaymentProviderDefinition, 
                PaymentProviders );

            this.licenses = new NumberProperty( this, undefined, 0 );
            this.licenses.required = true;

            this.minLicenses = new NumberProperty( this, undefined, 0 );

            this.maxLicenses = new NumberProperty( this, undefined, 0 );

            this.currency = new DefinitionProperty<Currency>( 
                this, 
                CurrencyDefinition, 
                Currencies );

            this.trialTax = new NumberProperty( this, undefined, 0 );

            this.trialCharge = new NumberProperty( this, undefined, 0 );

            this.setupCharge = new NumberProperty( this, undefined, 0 );

            this.setupTax = new NumberProperty( this, undefined, 0 );

            this.subscriptionTax = new NumberProperty( this, undefined, 0 );  

            this.subscriptionCharge = new NumberProperty( this, undefined, 0 );

            this.trialInterval = new NumberProperty( this, undefined, 0 );

            this.trialPeriod = new DefinitionProperty<TimeSpan>( 
                this, 
                TimeSpanDefinition, 
                TimeSpans );

            this.subscriptionInterval = new NumberProperty( this, 1, 0 );

            this.subscriptionPeriod = new DefinitionProperty<TimeSpan>( 
                this, 
                TimeSpanDefinition, 
                TimeSpans,
                DefaultTimeSpan );

            this.lastPaymentDate = new DateProperty( this );

            this.nextPaymentDate = new DateProperty( this );

            //log.traceInOut( "constructor()", SubscriptionsCollection );

        } catch( error ) {

            log.warn( "constructor()", "Error initializing subscription", error );
            
            throw new Error( (error as any).message );
        }
    }

    indexKey() : string {
        return SubscriptionDocument;
    }


    async onCreated() : Promise<void> {
        this.handleEditable();
    }

    async onUpdated() : Promise<void> {
        this.handleEditable();
    }

    private handleEditable() {

        const editable = this.billingMethod.value() !== BillingMethods.WooCommerce;

        const properties = this.properties();

        properties.forEach( property => {
            property.editable = editable;
        })
    }

    readonly company: TenantProperty<Company>;

    readonly accountId : TextProperty;

    readonly companyName : TextProperty;

    readonly email : TextProperty;

    readonly contactName : TextProperty;

    readonly phoneNumber : PhoneNumberProperty;

    readonly subscriptionId : TextProperty;

    readonly subscriptionStatus : DefinitionProperty<SubscriptionStatus>;

    readonly billingMethod : DefinitionProperty<BillingMethod>;

    readonly paymentProvider : DefinitionProperty<PaymentProvider>;

    readonly licenses : NumberProperty;

    readonly minLicenses : NumberProperty;

    readonly maxLicenses : NumberProperty;

    readonly currency : DefinitionProperty<Currency>;

    readonly trialTax : NumberProperty;

    readonly trialCharge : NumberProperty;

    readonly setupCharge : NumberProperty;

    readonly setupTax : NumberProperty;

    readonly subscriptionTax : NumberProperty;

    readonly subscriptionCharge : NumberProperty;

    readonly trialInterval : NumberProperty;

    readonly trialPeriod : DefinitionProperty<TimeSpan>;

    readonly subscriptionInterval : NumberProperty;

    readonly subscriptionPeriod : DefinitionProperty<TimeSpan>;

    readonly lastPaymentDate : DateProperty;

    readonly nextPaymentDate : DateProperty;

    readonly billingAddress : SubdocumentProperty<Address>;


}
