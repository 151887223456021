import { DatabaseDocument } from "../../framework/databaseDocument";
import { CollectionDatabase } from "../core/collectionDatabase";
import { log } from "../../framework/databaseService";
import { Factory } from "../../../common/api/factory";
import { PropertyType, PropertyTypes } from "../../api/definitions/propertyType"; 
import { CollectionPropertyIF } from "../../api/properties/collectionPropertyIF";
import { DatabaseProperty } from "../../framework/databaseProperty";
import { CollectionGroupDatabase } from "../core/collectionGroupDatabase";
import { Monitor } from "../../../common/api/monitor";
import { Database } from "../core/database";
import { UserAccess } from "../../../common/api/userAccess";


export class CollectionProperty<DerivedDocument extends DatabaseDocument> 
    extends DatabaseProperty<DerivedDocument> implements CollectionPropertyIF<DerivedDocument> {

    constructor( parent : DatabaseDocument, collectionName : string, allowCollectionGroup? : boolean ) {

        super( PropertyTypes.Collection as PropertyType, parent );

        //log.traceIn( "constructor()", parent.title, collectionName );

        try {
            this._collectionName = collectionName;

            this._allowCollectionGroup = allowCollectionGroup;

            //log.traceOut( "constructor()" ); 

        } catch( error ) {
            
            log.warn( "constructor()", "Error initializing document reference", error );

            throw new Error( (error as any).message );
        }
    }

    collectionName() : string {
        return this._collectionName
    }

    allowCollectionGroup() : boolean | undefined {
        return this._allowCollectionGroup;
    }

    value() {
        return undefined;
    }

    setValue( value : any | undefined ) : void {
        log.warn( "setValue()", "not supported for collection property" );
    }

    database() : Database<DerivedDocument> {

        if( !!this._allowCollectionGroup ) {
            return this.collectionGroup()!;
        }
        else {
            return this.collection(); 
        }
    }

    collection() : CollectionDatabase<DerivedDocument> {
        return Factory.get().databaseService.databaseFactory.collectionDatabaseFromCollectionName( 
            this.collectionName(), this.parent as DatabaseDocument ) as CollectionDatabase<DerivedDocument>;
    }

    collectionGroup() : CollectionGroupDatabase<DerivedDocument> | undefined {

        if( !this._allowCollectionGroup ) {
            return undefined;
        }

        return Factory.get().databaseService.databaseFactory.collectionGroupDatabaseFromCollectionName( 
            this.collectionName(), this.parent as DatabaseDocument ) as CollectionGroupDatabase<DerivedDocument>;
    }

    protected async monitor( newMonitor : Monitor): Promise<void> { 

        throw new Error( "Unsupported" );
    }

    protected async release(): Promise<void> {

        throw new Error( "Unsupported" );
    }


    fromData( documentData: any): void {}

    async toData( documentData: any ) : Promise<void> {}

    compareTo( other : CollectionProperty<DerivedDocument> ) : number {

        throw new Error("Unsupported")
    }

    compareValue( derivedDocument : DerivedDocument | undefined ) : number {

        throw new Error("Unsupported")
    }

    includes( other : CollectionProperty<DerivedDocument>  ) : boolean {
        throw new Error("Unsupported")
    }

    includesValue( derivedDocument : DerivedDocument | undefined ) : boolean {
        throw new Error("Unsupported")
    }

    userAccess() : UserAccess {

        return this.database().userAccess();
    }


    private readonly _collectionName : string;

    private readonly _allowCollectionGroup? : boolean; 

}
