import { CollectionDatabase } from "../core/collectionDatabase";
import { DatabaseDocument } from "../../framework/databaseDocument";
import { log } from "../../framework/databaseService";
import { DefinitionProperty } from "../properties/definitionProperty";
import { TenantProperty } from "../properties/tenantProperty";
import { ReferenceProperty } from "../properties/referenceProperty";
import { TextProperty } from "../properties/textProperty";
import { ChangeType, ChangeTypes } from "../../api/definitions/changeType";
import { ChangesCollection, CompaniesCollection, UnitsCollection, UsersCollection } from "../../api/collections";
import { ChangeTypeDefinition } from "../../api/definitions";
import { ChangeIF } from "../../api/documents/changeIF";
import { User } from "./user";
import { ChangeDocument, CompanyDocument, UnitDocument, UserDocument } from "../../api/documents";
import { MapProperty } from "../properties/mapProperty";
import { OwnerProperty } from "../properties/ownerProperty";
import { Unit } from "./unit";
import { Company } from "./company";
import { PropertyType, PropertyTypes } from "../../api/definitions/propertyType"; 


export class Change extends DatabaseDocument implements ChangeIF {

    constructor( changesCollection : CollectionDatabase<Change>, documentPath? : string  ) {

        super( ChangeDocument, changesCollection, documentPath );

        try {
            this.startDate.editable = false;
            this.startDate.trackChanges = false;
            this.startDate.encrypt = false; // search index 

            this.company = new TenantProperty<Company>( this, CompaniesCollection, CompanyDocument );    

            this.unit = new OwnerProperty<Unit>( this, UnitsCollection, UnitDocument ); 

            this.user = new OwnerProperty<User>( this, UsersCollection, UserDocument ); 

            this.changedDocument = new TenantProperty<DatabaseDocument>( 
                this, 
                ChangesCollection,
                ChangeDocument );
            this.changedDocument.editable = false;
            this.changedDocument.trackChanges = false;

            this.changedDocumentName = new TextProperty( this );
            this.changedDocumentName.editable = false;
            this.changedDocumentName.trackChanges = false;

            this.changedDocumentTitle = new TextProperty( this );
            this.changedDocumentTitle.editable = false;
            this.changedDocumentTitle.trackChanges = false;

            this.changeType = new DefinitionProperty<ChangeType>( this, ChangeTypeDefinition, ChangeTypes );
            this.changeType.editable = false;
            this.changeType.trackChanges = false;
            this.changeType.encrypt = false; // search index 


            this.changedBy = new ReferenceProperty<User>( this );
            this.changedBy.editable = false;
            this.changedBy.trackChanges = false;

            this.changed = new MapProperty<any>( PropertyTypes.Map as PropertyType, this );
            this.changed.editable = false;
            this.changed.trackChanges = false;

            //log.traceInOut( "constructor()", ChangesCollection );

        } catch( error ) {

            log.warn( "constructor()", "Error initializing change", error );
            
            throw new Error( (error as any).message );
        }
    }

    indexKey() : string {
        return ChangeDocument;
    }


    readonly company : TenantProperty<Company>;

    readonly unit : OwnerProperty<Unit>;

    readonly user : OwnerProperty<User>;

    readonly changedDocument : TenantProperty<DatabaseDocument>;

    readonly changedDocumentName: TextProperty;

    readonly changedDocumentTitle: TextProperty;

    readonly changedBy      : ReferenceProperty<User>;

    readonly changeType     : DefinitionProperty<ChangeType>;

    readonly changed    : MapProperty<any>;

 }
