
import { log } from "../../../services/database/framework/databaseService";
import { CollectionDatabase } from "../../../services/database/impl/core/collectionDatabase";

import { TenantProperty } from "../../../services/database/impl/properties/tenantProperty";
import { DatabaseDocument } from "../../../services/database/framework/databaseDocument";
import { ConsentIF } from "../../api/documents/consentIF";
import { HealthguardUser } from "./healthguardUser";
import { Measure } from "./measure";
import { Message } from "../../../services/database/impl/documents/message";

import { ConsentResponse, ConsentResponses } from "../../api/definitions/consentResponse";
import { ReferenceProperty } from "../../../services/database/impl/properties/referenceProperty";
import { DefinitionProperty } from "../../../services/database/impl/properties/definitionProperty";
import { ConsentDocument, HealthguardCompanyDocument, HealthguardUnitDocument, HealthguardUserDocument } from "../../api/healthguardDocuments";
import { MeasuresCollection } from "../../api/healthguardCollections";
import { HealthguardCompany } from "./healthguardCompany";
import { HealthguardUnit } from "./healthguardUnit";
import { OwnerProperty } from "../../../services/database/impl/properties/ownerProperty";
import { CompaniesCollection, MessagesCollection, UnitsCollection, UsersCollection } from "../../../services/database/api/collections";
import { ConsentResponseDefinition } from "../../api/healthguardDefinitions";
import { ReferencesProperty } from "../../../services/database/impl/properties/referencesProperty";
import { CollectionGroupDatabase } from "../../../services/database/impl/core/collectionGroupDatabase";
import { DateProperty } from "../../../services/database/impl/properties/dateProperty";
import { Database } from "../../../services/database/impl/core/database";



export class Consent extends DatabaseDocument implements ConsentIF {


    constructor( consentsCollection : CollectionDatabase<Consent>, documentPath? : string  ) { 

        super( ConsentDocument, consentsCollection, documentPath );

        try {
            this.startDate.editable = false;
            this.endDate.editable = false;

            this.company = new TenantProperty<HealthguardCompany>( this, CompaniesCollection, HealthguardCompanyDocument );
            this.company.editable = false;

            this.unit = new OwnerProperty<HealthguardUnit>( this, UnitsCollection, HealthguardUnitDocument ); // parent
            this.unit.editable = false;

            this.user = new OwnerProperty<HealthguardUser>( this, UsersCollection, HealthguardUserDocument ); 
            this.user.editable = false;

            this.measure = new ReferenceProperty<Measure>(this,
                ()=> this.parentDatabases( MeasuresCollection, {nearestIsCollectionGroup: false} ) as Database<Measure>[],
                "consents"  
            );
            this.measure.editable = false;

            this.receiver = new ReferenceProperty<HealthguardUser>(this,
                ()=> this.parentDatabases( UsersCollection, {nearestIsCollectionGroup: true} ) as Database<HealthguardUser>[]
            );
            this.receiver.editable = false;

            this.consentResponse = new DefinitionProperty<ConsentResponse>( this, 
                ConsentResponseDefinition, 
                ConsentResponses );

            this.notifications = new ReferencesProperty<Message>(this,
                () => [this.parentCollectionGroup( MessagesCollection )! as CollectionGroupDatabase<Message>],
                );

            //log.traceInOut( "constructor()", ConsentsCollection );

        } catch( error ) {

            log.warn( "constructor()", "Error initializing consent", error );
            
            throw new Error( (error as any).message );
        }
    }

    indexKey() : string {
        return ConsentDocument;
    }

    referenceDateProperty() : DateProperty {
        return this.endDate;
    }

    async onUpdate() : Promise<void> {
        try {
            //log.traceIn( "onUpdate()" );

            await super.onUpdate();

            if( this.consentResponse.isChanged()&& this.consentResponse.value() === ConsentResponses.Accepted ) {
                this.endDate.setValue( new Date() );
            }
    
            //log.traceOut( "onUpdate()" );
  
        } catch( error ) {
            
            log.warn( "onUpdated()", "Error handling updated notification", error );
  
            throw new Error( (error as any).message );
        }    
    }
    

    readonly company: TenantProperty<HealthguardCompany>;

    readonly unit: OwnerProperty<HealthguardUnit>;

    readonly user: OwnerProperty<HealthguardUser>;

    readonly measure : ReferenceProperty<Measure>;

    readonly receiver : ReferenceProperty<HealthguardUser>; 

    readonly consentResponse : DefinitionProperty<ConsentResponse>; 

    readonly notifications : ReferencesProperty<Message>; 

}
