

import { LoggerFactory } from "../../common/api/loggerFactory";
import { Monitor } from "../../common/api/monitor";
import { Observation } from "../../common/api/observation";
import { Target } from "../../common/api/targets";
import { Observable } from "../../common/impl/observable";
import { BillingServiceIF } from "../api/billingServiceIF";
import { SubscriptionManager } from "../framework/subscriptionManager";

export const log = LoggerFactory.logger("billing");

export class BillingService extends Observable implements BillingServiceIF {

    constructor( target : Target, subscriptionManager : SubscriptionManager ) {

        super();

        //log.traceInOut("constructor()", target );

        try {

            this.target = target;

            this.subscriptionManager = subscriptionManager;

        } catch (error) {
            log.warn("constructor()", "Error constructing billing service", error);

            throw new Error( (error as any).message );
        }
    }

    async init() : Promise<void> {

        log.traceInOut("init()" );

        try {

            this.subscriptionManager.init();


        } catch (error) {
            log.warn("init()", "Error initializing billing service", error);

            throw new Error( (error as any).message );
        }
    }


    protected async monitor( newMonitor : Monitor ): Promise<void> {}

    protected async release(observationFilter?: Observation[], objectIdsFilter?: string[]): Promise<void> {}

    readonly target : Target;

    readonly subscriptionManager : SubscriptionManager

}
