import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import { Box, Button, Checkbox, createStyles, Dialog, DialogActions, DialogContent, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Theme, Typography, withStyles, WithStyles } from '@material-ui/core';

import SimpleDialog, { ButtonKeyNo, ButtonKeyYes } from 'ui/components/simpleDialog';

import { ConsentIF } from 'healthguard/api/documents/consentIF';
import { log } from '../app/app';
import { errorDialog } from '../components/simpleDialog';
import { confirmAlert } from 'react-confirm-alert';
import { ConsentResponse, ConsentResponses } from '../../healthguard/api/definitions/consentResponse';
import { MeasureStates } from '../../healthguard/api/definitions/measureState';
import { MeasureIF } from '../../healthguard/api/documents/measureIF';
import { Monitor } from '../../services/common/api/monitor';
import { ObservableIF } from '../../services/common/api/observableIF';
import { Observation } from '../../services/common/api/observation';
import { Factory } from '../../services/common/api/factory';
import { HealthguardUserIF } from '../../healthguard/api/documents/healthguardUserIF';
import { HealthguardCompanyIF } from '../../healthguard/api/documents/healthguardCompanyIF';
import { lightBlue } from '@material-ui/core/colors';
import { browserView } from '../components/browserView';
import DefinitionColor from '../components/definitionColor';

import { activeLanguage} from '../app/localization';

import privacyConfiguration from "../../healthguard/data/settings/privacy.json";
import { ReadinessLevelDefinition } from '../../services/database/api/definitions';
import { DatabaseObserverIF } from '../../services/database/api/core/databaseObserverIF';
import { UserIF } from '../../services/database/api/documents/userIF';
import { AppContext, AppContextProps } from '../app/appContext';
import theme from '../app/theme';
import { ReferenceHandle } from '../../services/database/api/core/referenceHandle';

export const ButtonKeyConfirm = "confirm"; 
export const ButtonKeyPrivacyPolicy = "privacyPolicy"; 

type ConsentData = {

  consent: ConsentIF,

  measure: MeasureIF,

  minor?: UserIF

}


const styles = (theme: Theme) => createStyles({
  dialog: {
  },
  content: {
    height: "100%",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  table: {
    tableLayout: "auto"
  },
  grid: {
    marginTop: theme.spacing(6),
    paddingLeft: theme.spacing(2), 
    paddingRight: theme.spacing(1), 
    paddingTop: theme.spacing(2),  
    paddingBottom: theme.spacing(2),  
    flexWrap: "nowrap", 
    backgroundColor: lightBlue[100], 
    borderRadius: 4,
    borderWidth: 2
  },
  consent: {
    paddingRight: theme.spacing(1)
  },
  buttons: {
    display: "flex",
    justifyContent: 'flex-end',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(5)
  },
  definitionIcon: {
    width: theme.spacing(1.5),
    height: theme.spacing(1.5)
  }
});

export const consentsDialog = ( props: {
  user : HealthguardUserIF,
  databaseObserver: DatabaseObserverIF<ConsentIF> } ) : Promise<void> => {

  return new Promise<void>((resolve, reject) => {

    log.traceIn("consentsDialog()"); 

    try {

      confirmAlert({ 
        customUI: ({ onClose }) => {

          const onComplete = () => {

            log.traceIn("onComplete()");

            onClose();

            log.traceOut("onComplete()");
            resolve();
          }

          return (
            <ModifiedConsentsDialog 
              user={props.user}
              databaseObserver={props.databaseObserver}
              onComplete={onComplete}
              >
            </ModifiedConsentsDialog>
          );
        }
      });
      log.traceOut("consentsDialog()"); 

    } catch (error) {

      log.warn("confirm()", error);

      reject();
    }
  });
}



interface ConsentsDialogProps extends WithStyles<typeof styles>, WithTranslation { 

  databaseObserver : DatabaseObserverIF<ConsentIF>,

  user : HealthguardUserIF,
  
  onComplete? : () => void
}


interface ConsentsDialogState {

  company? : HealthguardCompanyIF, 

  open: boolean,

  promptConfirmOpen: boolean

  consented: boolean,

  ongoingActiveMeasureConsents: Map<string,ConsentData>
}

class ConsentsDialog extends React.Component<ConsentsDialogProps, ConsentsDialogState> {

  constructor(props: ConsentsDialogProps) {

    super(props);

    this.state = {

      open: false,

      promptConfirmOpen: false,

      consented: false,

      ongoingActiveMeasureConsents: new Map<string,ConsentData>()

    } as ConsentsDialogState;

    this.onUpdateDatabases = this.onUpdateDatabases.bind(this);
    this.updateConsents = this.updateConsents.bind(this);
    

    //log.traceInOut( "constructor()" );
  }

  async componentDidMount() {

    try {
      log.traceIn("componentDidMount()");

      const company = await this.props.user.company.document() as HealthguardCompanyIF; 

      this.setState( {
        company : company
      }); 

      await this.updateConsents();

      await this.props.databaseObserver.subscribe({ 
        observer: this,
        onNotify: this.onUpdateDatabases 
        } as Monitor );

      await this.props.databaseObserver.update();

      log.traceOut("componentDidMount()");

    } catch( error ) {
      
      log.warn( "componentDidMount()", error );

      await errorDialog( error);

      log.traceOut( "componentDidMount()", error );
    }
  }

  async componentWillUnmount() {
    log.traceIn("componentWillUnmount()");

    try {

      await this.props.databaseObserver.unsubscribe( this );

      log.traceOut("componentWillUnmount()");

    } catch (error) {
      log.warn("componentWillUnmount()", "Error unmounting collection list", error);

      log.traceOut("componentWillUnmount()", "error");
    }
  }

  private onUpdateDatabases = async (observable : ObservableIF, observation : Observation ) => {

    log.traceIn("onUpdateDatabases()");

    try {
      if( observable !== this.props.databaseObserver ) {
        throw new Error("Mismatching database observer");
      }

      await this.updateConsents();

      log.traceOut("onUpdateDatabases()" );

    } catch (error) {
      log.warn("onUpdateDatabases()", "Error updating databases", error);

      log.traceOut("onUpdateDatabases()", "error");
    }
  }


  async updateConsents() {

    try {
      log.traceIn("updateConsents()");

      const appContext = this.context as AppContextProps;

      const ongoingActiveMeasureConsents = new Map<string,ConsentData>();

      for (const consent of this.props.databaseObserver.filteredDocuments().values() ) {

        if (consent.consentResponse.value() === ConsentResponses.Accepted ||
            consent.consentResponse.value() === ConsentResponses.Rejected ||
            consent.consentResponse.value() === ConsentResponses.Withdrawn ) {
          continue;
        } 

        if (consent.consentResponse.value() === ConsentResponses.Unread) {
          consent.consentResponse.setValue( ConsentResponses.Read as ConsentResponse );
          await consent.update();
        } 

        const measure = await consent.measure.document();

        if (measure == null) {
          log.warn("componentDidMount()", "Consent is missing a measure reference", consent.referenceHandle())
          continue;
        }

        if (measure.measureState.value() !== MeasureStates.Ongoing) {
          continue;
        }

        let minor;

        if( consent.user.compareValue( appContext.currentUser?.referenceHandle() as ReferenceHandle<HealthguardUserIF>) !== 0 ) { 
          minor = await consent.user.document();
        }
        
        ongoingActiveMeasureConsents.set(consent.databasePath(),
        {
          consent: consent,

          measure: measure,

          minor: minor

        } as ConsentData );
      }

      const open = ongoingActiveMeasureConsents.size > 0;

      this.setState({

        open: open,

        ongoingActiveMeasureConsents: ongoingActiveMeasureConsents

      });

      if( !open && this.props.onComplete != null ) { 
        this.props.onComplete();
      }

      log.traceOut("updateConsents()");

    } catch (error) {

      log.warn("updateConsents()", error);

      await errorDialog(error);

      log.traceOut("updateConsents()", error);
    }
  }

  render() {

    //log.traceInOut("render()", "open: ", this.state.open )
 
    const { classes } = this.props; 

    const handleConfirm = async () => {

      log.traceIn("handleConfirm()" );

      try {
        this.setState( {
          promptConfirmOpen: false
        })

        for( const ongoingConsent of this.state.ongoingActiveMeasureConsents.values() ) {

          ongoingConsent.consent.consentResponse.setValue( ConsentResponses.Accepted as ConsentResponse );

          ongoingConsent.consent.update();
        }

        this.setState( {
          open: false
        })

        if( this.props.onComplete != null) {

          this.props.onComplete();
        }

        log.traceOut("handleConfirm()");

      } catch (error) {

        log.warn("handleConfirm()", error);

        await errorDialog(error);

      }
    }

    const showPrivacyPolicy = async () => {

      log.traceIn("showPrivacyPolicy()" );

      try {

        const privacyPolicy = this.state.company?.privacyPolicy.value() != null ?
          this.state.company.privacyPolicy.value()! :
          Factory.get().configurationService.config(
            privacyConfiguration, "consent.privacyPolicy", activeLanguage())


        browserView( ButtonKeyPrivacyPolicy, privacyPolicy );

        log.traceOut("showPrivacyPolicy()");

      } catch (error) {

        log.warn("showPrivacyPolicy()", error);

        await errorDialog(error);

      }
    }

    const renderMeasure = (consentData : ConsentData ) => {

      log.traceInOut("renderMeasure()", {consentData}); 

      return (
        <TableRow key={consentData.consent.id.value()}>
          <TableCell padding="none" style={{ width: '1px', whiteSpace: 'nowrap' }}>
            {consentData.measure.readinessLevels.values() != null && consentData.measure.readinessLevels.values()!.map((value: string) => (
              <Box className={classes.definitionIcon}>
                <DefinitionColor
                  key={value}
                  definition={ReadinessLevelDefinition}
                  value={value}
                />
              </Box>
            ))
            }
          </TableCell>
          <TableCell key={consentData.measure.id.value() + "-content"} >
            <Grid container direction="column" style={{ flexWrap: "nowrap" }}>
                <Grid item style={{ paddingBottom: theme.spacing(1)}}> 
                  <Typography variant="subtitle2">
                    <b>
                      {consentData.measure.title.value()} 
                    </b>                   
                    {consentData.minor?.title.value() != null ?
                      " " + this.props.t("for").toLowerCase() + " " + consentData.minor.title.value() 
                      : ""
                    }
                  </Typography>
              </Grid> 
              <Grid>
                <Typography variant="body2" align="justify">
                  <Box style={{whiteSpace: "pre-line"}}>
                    {consentData.measure.description.value()}
                  </Box>
                </Typography>
              </Grid>
            </Grid>
          </TableCell> 
        </TableRow>
      );
    }

    const renderConsent = () => {

      log.traceInOut("renderConsent()"); 

      const consentPrompt = this.state.company?.consentPrompt.value() != null ?
        this.state.company.consentPrompt.value()! :
        Factory.get().configurationService.config( 
                    privacyConfiguration, "consent.defaultPrompt", activeLanguage() )

      return (
        <Grid container className={classes.grid}>
          <Grid item xs={10}>
            {this.state.company != null &&
              <Typography variant="body2" align="justify">
                {consentPrompt} 
              </Typography> 
            }
          </Grid>
          <Grid item container className={classes.consent} direction="column" alignItems="center" justifyContent="center" xs={2}>
            <Grid item>
              <Checkbox
                checked={this.state.consented}
                onChange={(event) => this.setState({ consented: !this.state.consented })}
              />
            </Grid> 
          </Grid>
        </Grid>
      );
    }

    return (
      <>
        <Dialog
          className={classes.dialog}
          fullWidth={true}
          maxWidth="md"
          open={this.state.open}
          onClose={undefined}
        >
          <DialogContent className={classes.content}>
            <TableContainer key="ongoingMeasuresContainer" >
              <Table stickyHeader className={classes.table} >
                {this.state.ongoingActiveMeasureConsents.size > 0 &&
                  <TableHead key="ongoingMeasuresHead">
                    <TableRow key="ongoingMeasuresTitles" >
                      <TableCell key="ongoingMeasureTitle" align="center" colSpan={2}>
                        <Typography>
                          <b>{this.props.t("updatedMeasures")}</b>
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                }
                <TableBody key="inactiveMeasuresBody">
                  {Array.from(this.state.ongoingActiveMeasureConsents.values()).map(consentData => {
                    return renderMeasure(consentData);
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            {renderConsent()}
          </DialogContent>
          <DialogActions className={classes.buttons}>
            <Grid container justifyContent="space-between" >
              <Grid item>
                <Button onClick={() => { showPrivacyPolicy() }}>
                  {this.props.t(ButtonKeyPrivacyPolicy)}
                </Button>
              </Grid>
              <Grid>
                <Button onClick={() => { this.setState({ promptConfirmOpen: true }) }} color="primary" disabled={!this.state.consented}>
                  {this.props.t(ButtonKeyConfirm)}
                </Button>
                {this.state.promptConfirmOpen &&
                  <SimpleDialog
                    textKey="confirmConsents"
                    buttonKeys={[ButtonKeyYes, ButtonKeyNo]}
                    onComplete={(buttonKey?: string) => {
                      buttonKey === ButtonKeyYes ?
                        handleConfirm() :
                        this.setState({ promptConfirmOpen: false })
                    }}
                  />
                }
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

ConsentsDialog.contextType = AppContext;

const ModifiedConsentsDialog = withTranslation()(withStyles(styles)(ConsentsDialog)); 

export default ModifiedConsentsDialog;

