import React, { useContext } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core';

import DashboardIcon from '@material-ui/icons/Dashboard';

import { PropertiesSelector } from 'services/database/api/core/propertiesSelector';
import DocumentView, { DocumentProps } from '../components/documentView';
import DatabaseTable, { DatabaseTableProps } from 'ui/components/databaseTable';
import DatabaseList, { DatabaseListProps } from 'ui/components/databaseList';
import DatabaseCard, { DatabaseCardProps } from 'ui/components/databaseCard';
import DatabaseChart, { DatabaseChartProps } from 'ui/components/databaseChart';
import DatabaseGraph, { DatabaseGraphProps } from 'ui/components/databaseGraph';

import DatabaseTree, { DatabaseTreeProps } from 'ui/components/databaseTree';


import { UnitIF } from 'services/database/api/documents/unitIF';
import { AppContext, AppContextProps } from 'ui/app/appContext';
import { log } from 'ui/app/app'; 
import { HomePaths } from '../../services/common/api/homePaths';


import { Factory } from '../../services/common/api/factory';
import { HealthguardUnitIF } from '../../healthguard/api/documents/healthguardUnitIF';
import { errorDialog } from '../components/simpleDialog';
import { CompanyDocument, UnitDocument } from '../../services/database/api/documents';
import DatabaseView from '../components/databaseView';

import unitsConfiguration from "healthguard/data/settings/units.json";
import { DatabaseQuery } from '../../services/database/api/core/databaseQuery';
import { DatabaseViewType, DatabaseViewTypes } from '../components/databaseViewMenu';
import { DatabaseDocumentIF } from '../../services/database/api/core/databaseDocumentIF';

export const UnitViewOverviewParameter = "overview";

export const defaultUnitTableColumns = [CompanyDocument,"title",UnitDocument,"manager","users"];

export const defaultUnitMainProperty = "manager";

export const defaultHighlightedUnitsProperty = "title";

export function UnitsTable(props: DatabaseTableProps ) {

  return (
    <DatabaseTable {...props} featuredProperties={ props.featuredProperties != null ? props.featuredProperties : defaultUnitTableColumns }/>
  );
}

export function UnitsList(props: DatabaseListProps ) {

  return (
    <DatabaseList {...props} />
  );
}


export function UnitsCard( props: DatabaseCardProps ) {
  return (
    <DatabaseCard 
      {...props} 
      highlightedPropertyKey={props.highlightedPropertyKey != null ? props.highlightedPropertyKey : defaultHighlightedUnitsProperty}
    />
  );
} 

export function UnitsChart( props: DatabaseChartProps ) {
  return (
    <DatabaseChart 
      {...props} 
    />
  );
}

export function UnitsGraph( props: DatabaseGraphProps ) {
  return (
    <DatabaseGraph 
      {...props} 
      previousPropertyKey={"unit"}
      nextPropertyKey={"units"}
      directed={false}
    />
  );
}

export function UnitsTree( props: DatabaseTreeProps ) {

  const appContext = useContext(AppContext);

  const history = useHistory();

  const onOpenUnitDashboard = async ( databaseDocument : DatabaseDocumentIF ) => {
  
    log.traceIn("onOpenUnitDashboard()", databaseDocument);

    if (appContext.onUpdateActiveUnit != null) {
      await appContext.onUpdateActiveUnit( databaseDocument as HealthguardUnitIF );  
    }

    history.push(HomePaths.UnitHomePath);

    log.traceOut("onOpenUnitDashboard()");
  }

  return (
    <DatabaseTree 
      {...props} 
      parentPropertyKey={"unit"}
      childrenPropertyKey={"units"}
      highlightedPropertyKey={props.highlightedPropertyKey != null ? props.highlightedPropertyKey : defaultHighlightedUnitsProperty}
      maxDepth={unitsConfiguration.maxUnitLevels} 
      rootDocument={appContext.currentUnit}
      onAction={appContext.authenticationClaims?.companiesAdmin != null ? onOpenUnitDashboard : undefined}
      actionIcon={<DashboardIcon />}
    />
  );
}

export function UnitsView(props: {
  databaseQuery : DatabaseQuery<UnitIF>,
  title?: string,
  featuredProperties?: string[],
  highlightedPropertyKey?: string
}) {

  return (<DatabaseView
    databaseQuery={props.databaseQuery}
    title={props.title}
    featuredProperties={props.featuredProperties != null ? props.featuredProperties : defaultUnitTableColumns} 
    highlightedPropertyKey={props.highlightedPropertyKey != null ? props.highlightedPropertyKey : defaultHighlightedUnitsProperty}
    allowTree={true}
    defaultDatabaseViewType={DatabaseViewTypes.Tree as DatabaseViewType}   
    hideDateFilter={true}
    />);
}

const UnitInfoFilter = {

  includePropertyKeys : [
  "company",
  "unit",
  "manager",
  "deputies",
  "assistants",
  "links"],

} as PropertiesSelector;
 
const UnitUsersFilter = {

  includePropertyKeys : [
    "users"  ],

} as PropertiesSelector;
 
const UnitSymbolicUsersFilter = {

  includePropertyKeys : [
    "symbolicUsers"  ],

} as PropertiesSelector;
 



const defaultUnitSteps = () : Map<string,PropertiesSelector> => {

  let defaultSteps = new Map<string,PropertiesSelector>();

  defaultSteps.set( "info", UnitInfoFilter );
  defaultSteps.set( "users", UnitUsersFilter );
  defaultSteps.set( "symbolicUsers", UnitSymbolicUsersFilter );

  return defaultSteps;
}


export function UnitOverviewView( props : DocumentProps ) {

  const unitHealthDataSteps = (): Map<string, PropertiesSelector> => {

    let overviewSteps = new Map<string, PropertiesSelector>();
  
    overviewSteps.set("info", UnitInfoFilter );
  
    return overviewSteps;
  }
  
  return ( <DocumentView 
    {...props}
    inputTabs={unitHealthDataSteps()}/> ); 
}


const styles = (theme: Theme) => createStyles({});


interface UnitViewProps extends DocumentProps, WithStyles<typeof styles>, WithTranslation {

  featuredProperties?: string[]

}

interface UnitViewState { // Document View Props

  unit : UnitIF,

  showOpenDashboard?: boolean,

  disableOpenDashboard?: boolean,
}

class UnitView extends React.PureComponent<UnitViewProps,UnitViewState> {

  constructor( props: UnitViewProps ) {
    
    super(props);

    log.traceIn( "constructor()" );

    this.state = { 

      unit: this.props.databaseDocument as UnitIF

    } as UnitViewState;

    this.readUnit = this.readUnit.bind(this);

    //log.traceOut( "constructor()" );
  }

  async componentDidMount() {

    try {
      log.traceIn( "componentDidMount()" );

      await this.readUnit();

      log.traceOut( "componentDidMount()" );
    } catch( error ) {
      log.warn( "componentDidMount()", "Error mounting document view", error );
      
      log.traceOut( "componentDidMount()", "error" );
    }
  }

  async componentDidUpdate() {

    try {
      //log.traceIn( "componentDidUpdate()");

      //log.traceOut( "componentDidUpdate()" );
    } catch( error ) {
      log.warn( "componentDidUpdate()", "Error mounting document view", error );
      
    }
  }

  async readUnit() {

    try {
      log.traceIn("readUnit()", this.state);

      const appContext = this.context as AppContextProps;

      let unit = this.props.databaseDocument as UnitIF;

      let showOpenDashboard = false;
      let disableOpenDashboard = false;

      const unitId = unit.id.value();

      if( unitId == null ||
          appContext.currentUser == null || 
          appContext.authenticationClaims == null  ) {

        showOpenDashboard = false;
      }
      else if( !!appContext.authenticationClaims.systemAdmin ) {

        showOpenDashboard = true;
      }
      else if( appContext.authenticationClaims.companiesAdmin != null ) {

        showOpenDashboard = true;
      }
      else if( appContext.authenticationClaims.unitsAdmin != null &&
                unitId !== appContext.currentUser.unit.id() &&
                (appContext.currentUnit == null || appContext.currentUnit.id.value() !== unitId ) ) {

        showOpenDashboard = true;

        if( appContext.authenticationClaims!.subunitIds != null && 
            appContext.authenticationClaims!.subunitIds.includes( unitId ) ) {

              showOpenDashboard = true;
        }
        else {
         
          const authenticationClaims = 
            await Factory.get().authenticationService!.refreshAuthenticationClaims();

          if( authenticationClaims!.subunitIds == null || 
              !authenticationClaims!.subunitIds.includes( unitId ) ) {

              disableOpenDashboard = true;
          }
        }
      }

      this.setState( {
        unit: unit,

        showOpenDashboard: showOpenDashboard,

        disableOpenDashboard: disableOpenDashboard
      })

      log.traceOut( "readUnit()", "state:", this.state );
      
    } catch( error ) {
      log.warn( "readUnit()", "Error mounting document view", error );

      await errorDialog( error );

      log.traceOut( "readUnit()", "error" );
    }
  }

  render() {

    const appContext = this.context as AppContextProps;

    if (this.props.view === UnitViewOverviewParameter) {
      return (<UnitOverviewView 
        {...this.props}
        databaseDocument={this.state.unit}/>);
    }
  
    let actions;
    
    if ( !!this.state.showOpenDashboard ) { 
  
      const onOpenUnitDashboard = async () => {
  
        log.traceIn("onOpenUnitDashboard()", this.state.unit);
  
        if (appContext.onUpdateActiveUnit != null) {
          await appContext.onUpdateActiveUnit(this.state.unit as HealthguardUnitIF); 
        }

        if (appContext.onRedirect != null) {
          await appContext.onRedirect( HomePaths.UnitHomePath ); 
        }
  
        log.traceOut("onOpenUnitDashboard()");
      }
      actions = [{ 
        title: this.props.t("openDashboard"), 

        onAction: onOpenUnitDashboard,

        disabled: this.state.disableOpenDashboard 
       }]
    }
    return (<DocumentView 
      {...this.props}
      databaseDocument={this.state.unit}
      inputTabs={this.props.inputTabs != null ? this.props.inputTabs : defaultUnitSteps()} 
      actions={this.props.actions != null ? this.props.actions : actions} 
    />);
  }
}
 
UnitView.contextType = AppContext;

const ModifiedUnitView = withTranslation()(withStyles(styles)(UnitView));

export default ModifiedUnitView; 
