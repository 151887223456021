import React from 'react';

import { HealthguardDefinition } from '../../healthguard/api/healthguardDefinitions';

import { LocationTypeDefinition, ProbabilityDefinition, ResidenceTypeDefinition } from '../../services/database/api/definitions';
import { LocationType, LocationTypes } from '../../services/database/api/definitions/locationType';

import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import ApartmentIcon from '@mui/icons-material/Apartment';
import CabinIcon from '@mui/icons-material/Cabin';
import HotelIcon from '@material-ui/icons/Hotel';
import BedroomParentIcon from '@mui/icons-material/BedroomParent';
import RectangleIcon from '@mui/icons-material/Rectangle';
import BusinessIcon from '@material-ui/icons/Business';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import CloudIcon from '@mui/icons-material/Cloud';
import SupportIcon from '@mui/icons-material/Support';
import FactoryIcon from '@mui/icons-material/Factory';
import LocalAirportIcon from '@mui/icons-material/LocalAirport';
import StoreIcon from '@mui/icons-material/Store';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import RoofingIcon from '@mui/icons-material/Roofing';
import StadiumIcon from '@mui/icons-material/Stadium';
import TrainIcon from '@mui/icons-material/Train';
import OilBarrelIcon from '@mui/icons-material/OilBarrel';
import EngineeringIcon from '@mui/icons-material/Engineering';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import DomainAddIcon from '@mui/icons-material/DomainAdd';
import BedroomChildIcon from '@mui/icons-material/BedroomChild';
import BiotechIcon from '@mui/icons-material/Biotech';

import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import DensitySmallIcon from '@mui/icons-material/DensitySmall';
import DensityMediumIcon from '@mui/icons-material/DensityMedium';
import DensityLargeIcon from '@mui/icons-material/DensityLarge';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';

import { ResidenceType, ResidenceTypes } from '../../services/database/api/definitions/residenceType';
import { Probabilities, Probability } from '../../services/database/api/definitions/probability';


export const probabilityIcon = ( probability : Probability, adjustSize? : boolean ) => {

  //log.traceInOut( "residenceTypeIcon()", {residenceType} );

  const fontSize = !!adjustSize ? "inherit" : undefined;

  switch( probability ) {

    case Probabilities.Frequent: 
      return( <FormatAlignJustifyIcon fontSize={fontSize} /> );

    case Probabilities.Probable: 
      return( <DensitySmallIcon fontSize={fontSize} /> );

    case Probabilities.Occassional: 
      return( <DensityMediumIcon fontSize={fontSize} /> );

    case Probabilities.Remote: 
      return( <DensityLargeIcon fontSize={fontSize} /> );

    case Probabilities.Improbable: 
      return( <HorizontalRuleIcon fontSize={fontSize} /> );
    
    default:
      return undefined;
  }
}

export const residenceTypeIcon = ( residenceType : ResidenceType, adjustSize? : boolean )  => {

  //log.traceInOut( "residenceTypeIcon()", {residenceType} );

  const fontSize = !!adjustSize ? "inherit" : undefined;

  switch( residenceType ) {

    case ResidenceTypes.SingleFamilyHome: 
      return( <BusinessIcon fontSize={fontSize} /> );

    case ResidenceTypes.Apartment: 
      return( <ApartmentIcon fontSize={fontSize} /> );

    case ResidenceTypes.Cabin: 
      return( <CabinIcon fontSize={fontSize} /> );

    case ResidenceTypes.Hotel: 
      return( <HotelIcon fontSize={fontSize} /> );

    case ResidenceTypes.ShortTermRental: 
      return( <BedroomParentIcon fontSize={fontSize} /> );
    
    case ResidenceTypes.Barracks: 
      return( <RectangleIcon fontSize={fontSize} /> );
    
    default:
      return undefined;
  }
}

export const locationTypeIcon = ( locationType : LocationType, adjustSize? : boolean )  => {

  //log.traceInOut( "locationType()", {locationType} );

  const fontSize = !!adjustSize ? "inherit" : undefined;

  switch( locationType ) {

    case LocationTypes.Office: 
      return( <BusinessIcon fontSize={fontSize} /> );

    case LocationTypes.SharedWorkspace: 
      return( <BusinessCenterIcon fontSize={fontSize} /> );

    case LocationTypes.ProductionFacility: 
      return( <PrecisionManufacturingIcon fontSize={fontSize} /> );

    case LocationTypes.DistributionFacility: 
      return( <WarehouseIcon fontSize={fontSize} /> );

    case LocationTypes.DataCenter: 
      return( <CloudIcon fontSize={fontSize} /> );

    case LocationTypes.ServiceCenter:
      return( <SupportIcon fontSize={fontSize} /> );

    case LocationTypes.Laboratory:
      return( <BiotechIcon fontSize={fontSize} /> );

    case LocationTypes.BuildingSite:
      return( <RoofingIcon fontSize={fontSize} /> );

    case LocationTypes.Shipyard: 
      return( <FactoryIcon fontSize={fontSize} /> );

    case LocationTypes.Workshop: 
      return( <EngineeringIcon fontSize={fontSize} /> );

    case LocationTypes.Refinery:
      return( <OilBarrelIcon fontSize={fontSize} /> );

    case LocationTypes.Vessel:
      return( <DirectionsBoatIcon fontSize={fontSize} /> );

    case LocationTypes.Rig:
      return( <OilBarrelIcon fontSize={fontSize} /> );

    case LocationTypes.Airport: 
      return( <LocalAirportIcon fontSize={fontSize} /> );
  
    case LocationTypes.Station:
      return( <TrainIcon fontSize={fontSize} /> );

    case LocationTypes.Store:
      return( <StoreIcon fontSize={fontSize} /> );

    case LocationTypes.Hotel: 
      return( <HotelIcon fontSize={fontSize} /> );

    case LocationTypes.Restaurant: 
      return( <RestaurantIcon fontSize={fontSize} /> );

    case LocationTypes.Hospital: 
      return( <DomainAddIcon fontSize={fontSize} /> );

    case LocationTypes.Clinic: 
      return( <LocalHospitalIcon fontSize={fontSize} /> );

    case LocationTypes.CareFacility:
      return( <BedroomChildIcon fontSize={fontSize} /> );

    case LocationTypes.Stadium:
      return( <StadiumIcon fontSize={fontSize} /> );
    
    default:
      return undefined;
  }
}

export const definitionIcon = ( definition : HealthguardDefinition, value? : string, adjustSize? : boolean ) => {

  switch( definition ) {

    case ProbabilityDefinition:
      return probabilityIcon( value as Probability, adjustSize );

    case LocationTypeDefinition:
      return locationTypeIcon( value as LocationType, adjustSize );

    case ResidenceTypeDefinition:
      return residenceTypeIcon( value as ResidenceType, adjustSize );
  
    default:
      return undefined;
  }
}

interface DefinitionIconProps {
  definition : HealthguardDefinition, 
  value? : string,
  adjustSize?: boolean
}


interface DefinitionIconState { 
}

export default class DefinitionIcon extends React.PureComponent<DefinitionIconProps,DefinitionIconState> {

  constructor( props: DefinitionIconProps ) {
    
    super(props);

    this.state = { 
    } as DefinitionIconState;

    //log.traceInOut( "constructor()" );
  }

  
  render() {

    //log.traceInOut( "render()" );

    return(
      <>
        {definitionIcon( this.props.definition, this.props.value, this.props.adjustSize )}
      </>
    );
  }
}


