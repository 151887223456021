import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import countries from 'i18n-iso-countries';

import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import { nb, enUS } from 'date-fns/locale'
import { SubdocumentNames } from '../../services/database/api/subdocuments';
import { HealthguardDefinitions } from '../../healthguard/api/healthguardDefinitions';
import { HealthguardDocumentNames } from '../../healthguard/api/healthguardDocuments';
import { DocumentNames } from '../../services/database/api/documents';
import { Definitions } from '../../services/database/api/definitions';
import { HealthguardSubdocumentNames } from '../../healthguard/api/healthguardSubdocuments';
import { Language, Languages } from '../../services/database/api/definitions/language';
import { Target } from '../../services/common/api/targets';
import { TranslationKey } from '../../services/common/api/translatorIF';

import applicationConfiguration from "../../healthguard/data/settings/application.json";

const target = process.env.REACT_APP_TARGET as Target;

const defaultLanguage = (applicationConfiguration as any)[target] != null ?
  (applicationConfiguration as any)[target].defaultLanguage : applicationConfiguration.defaultLanguage;

export const DefaultCountry = "us";

export const SupportedLanguages = Object.values( Languages );

SupportedLanguages.forEach( language => {
  countries.registerLocale(require( "i18n-iso-countries/langs/" + language + ".json" ));
})

i18n
  // load translation using http -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    //lng : 'nb', // override to test
    load: 'languageOnly',
    fallbackLng: { 
        'default': [defaultLanguage]
    }, 
    debug: false,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }, 

    react: {
      useSuspense: true
    },

    ns: ([] as string[]).concat(
      TranslationKey.Errors,
      TranslationKey.Prompts,
      TranslationKey.Policies, 
      TranslationKey.Registration,
      TranslationKey.Contactable,
      HealthguardDocumentNames, 
      HealthguardSubdocumentNames,
      HealthguardDefinitions, 
      DocumentNames, 
      SubdocumentNames, 
      Definitions)
  });
  export default i18n


  export const Locales = {

    en: {
      countryId: "us",
      locale: enUS,
      dateFormat: "MM/dd/yyyy",
      timeFormat: "hh:mm a",
      dateTimeFormat: "MM/dd/yyyy hh:mm a",
      useAmPm: true,
      yearFormat: "yyyy",
      quarterFormat: "qqq''yy",
      monthFormat: "MMM yy",
      weekFormat: "'week 'wo",
      dayFormat: "MM/dd",
      hourFormat: "hh a",
      minuteFormat: "mm",
      secondFormat: "ss",
    },
  
    nb: {
      countryId: "no",
      locale: nb,
      dateFormat: "dd/MM/yyyy",
      timeFormat: "HH:mm",
      dateTimeFormat: "MM/dd/yyyy HH:mm",
      useAmPm: false,
      yearFormat: "yyyy",
      quarterFormat: "qqq''yy",
      monthFormat: "MMM yy",
      weekFormat: "'uke 'wo",
      dayFormat: "dd/MM",
      hourFormat: "HH",
      minuteFormat: "mm",
      secondFormat: "ss"
    }
  }

  type Locale = keyof typeof Locales;  

  export const activeLanguage = () : Language => { 

    if( i18n.language == null ) {
      return defaultLanguage;
    }

    const language = i18n.language.split("-")[0];

    return SupportedLanguages.includes( language ) ? language as Language : defaultLanguage;
  }

  export const setActiveLanguage = async ( language? : Language ) : Promise<void> => {

    if( language !== activeLanguage() ) {

      await i18n.changeLanguage( language ); 
    }
  }

  export const allCountries = ( language? : Language ) : Map<string,string> => {

    const result = new Map<string,string>();

    Object.entries( countries.getNames( language != null ? language : activeLanguage() ) ).forEach( country => {
      result.set( country[0], country[1] );
    })
    return result;
  }
  export const countryName = ( countryId : string, language? : Language ) => 
    countries.getName( countryId, language != null ? language : activeLanguage() );

  export const country = ( language? : string ) => 
    Locales[(language != null ? language : activeLanguage()) as Locale ].countryId;

  export const locale = ( language? : string ) => 
    Locales[(language != null ? language : activeLanguage()) as Locale ].locale;

  export const dateFormat = ( language? : string ) => 
    Locales[(language != null ? language : activeLanguage()) as Locale ].dateFormat;

  export const timeFormat = ( language? : string ) => 
    Locales[(language != null ? language : activeLanguage()) as Locale ].timeFormat;

  export const dateTimeFormat = ( language? : string ) => 
    Locales[(language != null ? language : activeLanguage()) as Locale ].dateTimeFormat;

  export const useAmPm = ( language? : string ) => 
    Locales[(language != null ? language : activeLanguage()) as Locale ].useAmPm;

  export const yearFormat = ( language? : string ) => 
    Locales[(language != null ? language : activeLanguage()) as Locale ].yearFormat;

  export const quarterFormat = ( language? : string ) => 
    Locales[(language != null ? language : activeLanguage()) as Locale ].quarterFormat;

  export const monthFormat = ( language? : string ) => 
    Locales[(language != null ? language : activeLanguage()) as Locale ].monthFormat;

  export const weekFormat = ( language? : string ) => 
    Locales[(language != null ? language : activeLanguage()) as Locale ].weekFormat;

  export const dayFormat = ( language? : string ) => 
    Locales[(language != null ? language : activeLanguage()) as Locale ].dayFormat;

  export const hourFormat = ( language? : string ) => 
    Locales[(language != null ? language : activeLanguage()) as Locale ].hourFormat;

  export const minuteFormat = ( language? : string ) => 
    Locales[(language != null ? language : activeLanguage()) as Locale ].minuteFormat;

  export const secondFormat = ( language? : string ) => 
    Locales[(language != null ? language : activeLanguage()) as Locale ].secondFormat;



