import React from 'react';

import { TextField, Typography } from '@material-ui/core';

import PropertyLabel from '../propertyLabel';

import { NumberPropertyIF } from 'services/database/api/properties/numberPropertyIF';
import { PropertyDisplayMode, propertyInputVariant } from '../propertyValue';

export default function NumberPropertyValue( props : { 

  property : NumberPropertyIF,

  onPropertyChange? : ( property : NumberPropertyIF ) => void,

  displayMode : PropertyDisplayMode,

  singleProperty : boolean,

  hideColor?: boolean,

  disabled?: boolean,

  required?: boolean } ) {

  const [propertyValue, setPropertyValue] = React.useState<number | undefined>( undefined );

  React.useEffect( () => {

    setPropertyValue( props.property.value() );

  }, [props.property]); 

  if( props.displayMode === PropertyDisplayMode.Cell ) {      
    return ( <Typography>{ propertyValue != null ? propertyValue : <>&nbsp;</> }</Typography> );
  }
  
  const handleChange = ( 
    event: { 
      preventDefault: () => void;
      target : { name : string, value : string }  
    } ) => {

    event.preventDefault();

    props.property.setValue( event.target.value != null ?  +event.target.value : undefined );

    setPropertyValue( props.property.value() );

    if( props.onPropertyChange != null ) {
      props.onPropertyChange( props.property );
    }
  }

  return ( 
    <React.Fragment>
      <TextField variant={propertyInputVariant} 
        fullWidth 
        key={props.property.key()} 
        error={props.property.error != null}
        disabled={props.disabled}
        required={props.required}       
        type="number"
        InputLabelProps={{ shrink: true }}
        label={<PropertyLabel documentName={props.property.parent.documentName()} propertyKey={props.property.key()} />} 
        value={propertyValue != null ? propertyValue : ""}
        onChange={handleChange}
      />
    </React.Fragment> );   
}

  