import * as React from 'react'

import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Box, Collapse, Grid, IconButton } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close'; 

import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


import { AppContext, AppContextProps } from 'ui/app/appContext';
import { log } from 'ui/app/app';
import { HomePaths } from '../../services/common/api/homePaths';

import { DatabaseDocumentIF } from 'services/database/api/core/databaseDocumentIF';
import { DatabaseFilter } from 'services/database/api/core/databaseFilter';
import { PersistentKeyFilterCompany, PersistentKeyFilterComparator, PersistentKeyFilterMatchValue, PersistentKeyFilterPropertyKey, PersistentKeyFilterUnit, PersistentKeyFilterUser } from './appFrame';
import { DatabasePropertyIF } from 'services/database/api/core/databasePropertyIF';
import { PropertiesSelector } from 'services/database/api/core/propertiesSelector';
import { translatedPropertyLabel } from './propertyLabel';
import PropertyValue, { PropertyDisplayMode, propertyInputVariant } from './propertyValue';
import { ReferenceHandle, referenceHandleReplacer } from 'services/database/api/core/referenceHandle';
import { TenantPropertyIF } from 'services/database/api/properties/tenantPropertyIF';
import { UserIF } from 'services/database/api/documents/userIF';
import { CompanyIF } from 'services/database/api/documents/companyIF';
import { Factory } from 'services/common/api/factory';
import { DocumentNameKey, NewDocumentId } from 'services/database/api/core/databaseServiceIF';
import { ArchivedAtProperty, ArchivedProperty, DescriptionProperty, DocumentDescriptionProperty, DocumentNameProperty, EndDateProperty, IdProperty, LastChangedAtProperty, LastChangedByProperty, StartDateProperty } from './databaseTable';
import { translatedDefinition } from './definitionText';
import { Collections, CompaniesCollection, UsersCollection } from '../../services/database/api/collections';
import { Comparator, Comparators, DefaultComparator } from '../../services/database/api/definitions/comparator';
import { ComparatorDefinition } from '../../services/database/api/definitions';
import { CompanyDocument, UnitDocument, UserDocument } from '../../services/database/api/documents';
import { HazardsCollection } from "healthguard/api/healthguardCollections";
import { HazardDocument } from '../../healthguard/api/healthguardDocuments';
import { OwnerPropertyIF } from '../../services/database/api/properties/ownerPropertyIF';
import { UnitIF } from '../../services/database/api/documents/unitIF';
import { errorDialog } from './simpleDialog';
import { ReferencePropertyIF } from '../../services/database/api/properties/referencePropertyIF';
import { HazardIF } from '../../healthguard/api/documents/hazardIF';
import { DatabaseIF } from '../../services/database/api/core/databaseIF';
import { activeLanguage } from '../app/localization';
import { PropertyTypes } from '../../services/database/api/definitions/propertyType';
 
const styles = (theme: Theme) => createStyles({
  root: {
    marginTop: theme.spacing(0),
    marginRight: theme.spacing(0.5),
    display: 'flex', 
    zIndex: 999,
    justifyContent: 'flex-begin',
  },
  select: {
    "& .MuiSelect-select:focus": {
      backgroundColor: 'transparent'
    }
  },
  collapse: {
    width: "100%", 
    margin: theme.spacing(0),
    padding: theme.spacing(0)
  },
  inputLabel: {
    marginTop: theme.spacing(-0.5)
  },
  item: {
    marginBottom: theme.spacing(1)
  },
  clearButton : {
    marginLeft: theme.spacing(-4)
  },
});

const excludePropertyTypes = [ 
  PropertyTypes.Collection,
  PropertyTypes.Subdocument,
  PropertyTypes.Map,
  PropertyTypes.Data,
  PropertyTypes.Empty,
  PropertyTypes.Attachment,
  PropertyTypes.Attachments, 
  PropertyTypes.Links,
  PropertyTypes.Image
];


const excludePropertyKeys = [
  CompanyDocument,
  UnitDocument,
  UserDocument,
  HazardsCollection,
  HazardDocument,
  DocumentNameProperty,
  DocumentDescriptionProperty, 
  IdProperty,
  DescriptionProperty,
  StartDateProperty,
  EndDateProperty,
  LastChangedByProperty,
  LastChangedAtProperty,
  ArchivedAtProperty,
  ArchivedProperty];   

export interface PropertyFilterProps extends WithStyles<typeof styles>, WithTranslation { 

  databases: DatabaseIF<DatabaseDocumentIF>[],

  propertiesSelector? : PropertiesSelector,

  onDatabaseFiltersChange? : ( databaseFilters : Map<string,DatabaseFilter> ) => Promise<void> 
}


interface PropertyFilterState { 

  propertiesSelector : PropertiesSelector,

  filterPropertyKey?: string,

  propertyKeyOpen: boolean,

  propertyKeyOptions: string[],
  
  comparator?: Comparator,

  comparatorOpen: boolean,

  matchValue?: any,

  showCompany?: boolean,

  companyFilter?: ReferenceHandle<CompanyIF>,

  showUnit?: boolean,

  unitFilter?: ReferenceHandle<UnitIF>,

  showUser?: boolean,

  userFilter?: ReferenceHandle<UserIF>,
  
  propertiesSource? : DatabaseDocumentIF

}

class PropertyFilter extends React.PureComponent<PropertyFilterProps,PropertyFilterState> {

  constructor( props: PropertyFilterProps ) {
    
    super(props);

    const propertiesSelector = {

      excludePropertyTypes: excludePropertyTypes,

      excludePropertyKeys: excludePropertyKeys

    } as PropertiesSelector;


    if( this.props.propertiesSelector != null ) {

      propertiesSelector.includePropertyKeys = 
        this.props.propertiesSelector.includePropertyKeys;

        if( this.props.propertiesSelector.excludePropertyKeys != null ) {

          propertiesSelector.excludePropertyKeys!.concat( 
            this.props.propertiesSelector.excludePropertyKeys );
        }
                          
      propertiesSelector.excludePropertyKeys = 
        this.props.propertiesSelector.excludePropertyKeys;
                          
      propertiesSelector.includePropertyTypes = 
        this.props.propertiesSelector.includePropertyTypes;

      if( this.props.propertiesSelector.excludePropertyTypes != null ) {

        propertiesSelector.excludePropertyTypes!.concat( 
          this.props.propertiesSelector.excludePropertyTypes );
      }
    }


    this.state = { 

      propertiesSelector: propertiesSelector,

      propertyKeyOpen: false,

      comparatorOpen: false,


     } as PropertyFilterState;

     this.updateDatabaseFilters = this.updateDatabaseFilters.bind( this );

     this.propertyKeyPersistentKey = this.propertyKeyPersistentKey.bind( this );
     this.handlePropertyKey = this.handlePropertyKey.bind( this );

     this.comparatorPersistentKey = this.comparatorPersistentKey.bind( this );
     this.handleComparator = this.handleComparator.bind( this );

     this.matchValuePersistentKey = this.matchValuePersistentKey.bind( this );
     this.handleMatchValue = this.handleMatchValue.bind( this );

     this.companyFilterPersistentKey = this.companyFilterPersistentKey.bind( this );
     this.updateCompanyFilter = this.updateCompanyFilter.bind( this );

     this.unitFilterPersistentKey = this.unitFilterPersistentKey.bind( this );
     this.updateUnitFilter = this.updateUnitFilter.bind( this );

     this.userFilterPersistentKey = this.userFilterPersistentKey.bind( this );
     this.updateUserFilter = this.updateUserFilter.bind( this );

    //log.traceInOut( "constructor()" );
  }

  async componentDidMount() {

    log.traceIn("componentDidMount()" );

    try {

      await this.updateDatabaseFilters();

      log.traceOut("componentDidMount()",);

    } catch( error ) {
      log.warn( "componentDidMount()", error );

      await errorDialog( error);

      log.traceOut( "componentDidMount()", error );
    }
  }

  async componentDidUpdate() {

    // log.traceIn("componentDidUpdate()" );

    try {

      //await this.updateDatabaseFilters();

      //log.traceOut("componentDidUpdate()",);

    } catch( error ) {
      log.warn( "componentDidUpdate()", error );

      await errorDialog( error);

      //log.traceOut( "componentDidUpdate()", error ); 
    }
  }

    async updateDatabaseFilters() {

    log.traceIn("updateDatabaseFilters()" );

    try {

      const appContext = this.context as AppContextProps;

      let propertiesSource;

      let userFilter;

      const persistentUserFilter = Factory.get().persistentState!.property( this.userFilterPersistentKey() );
      
      if( persistentUserFilter != null ) {
        //log.debug( "updateDatabaseFilters()", "From persistent", {persistentUserFilter} );
        userFilter = JSON.parse( persistentUserFilter );
      }

      let unitFilter;
      const persistentUnitFilter = Factory.get().persistentState!.property( this.unitFilterPersistentKey() );
        
      if( persistentUnitFilter != null ) {

        //log.debug( "updateDatabaseFilters()", "From persistent", {persistentUnitFilter} );
        unitFilter = JSON.parse( persistentUnitFilter ); 
      }

      let companyFilter;

      const persistentCompanyFilter = Factory.get().persistentState!.property( this.companyFilterPersistentKey() );
        
      if( persistentCompanyFilter != null ) {

        companyFilter = JSON.parse( persistentCompanyFilter );
        //log.debug( "updateDatabaseFilters()", "From persistent", {persistentCompanyFilter} );
      }

      if (userFilter?.path != null) {

        let sourceDocumentPath = userFilter.path.split("?")[0] + "/" +
          this.props.databases[0].collectionName()+ "/" + NewDocumentId;
          
        if( this.props.databases[0].queryDocumentName() != null ) {
          sourceDocumentPath += "?" + DocumentNameKey + "=" + this.props.databases[0].queryDocumentName(); 
        }

        propertiesSource =
          Factory.get().databaseService.databaseFactory.newDocumentFromUrl(sourceDocumentPath)!;

      }
      else if (unitFilter?.path != null) {

        let sourceDocumentPath = unitFilter.path.split("?")[0] + "/" +
          this.props.databases[0].collectionName()+ "/" + NewDocumentId;
          
        if( this.props.databases[0].queryDocumentName() != null ) {
          sourceDocumentPath += "?" + DocumentNameKey + "=" + this.props.databases[0].queryDocumentName(); 
        }

        propertiesSource =
          Factory.get().databaseService.databaseFactory.newDocumentFromUrl(sourceDocumentPath)!;
      }
      else if (companyFilter?.path != null) {

        let sourceDocumentPath = companyFilter.path.split("?")[0] + "/" +
          this.props.databases[0].collectionName()+ "/" + NewDocumentId;
        
        if( this.props.databases[0].queryDocumentName() != null ) {
          sourceDocumentPath += "?" + DocumentNameKey + "=" + this.props.databases[0].queryDocumentName(); 
        }

        propertiesSource =
          Factory.get().databaseService.databaseFactory.newDocumentFromUrl(sourceDocumentPath)!;

      }
      else {
        propertiesSource = this.props.databases[0].newDocument()!;
      }

      const properties = propertiesSource.properties(this.state.propertiesSelector);

      if( properties == null ) {
        throw new Error( "Empty properties source" );
      }

      if (appContext.currentHazard != null) {

        const hazard = properties.get(HazardDocument) as ReferencePropertyIF<HazardIF>;

        if (hazard != null) {

          hazard.setValue(appContext.currentHazard.referenceHandle() as ReferenceHandle<HazardIF>);

          properties.delete(HazardDocument);
        }
      }

      let propertyKeyOptions : string[] = [];

      if( propertiesSource != null ) {

        properties.forEach( property => {

          if( !property.hidden ) {
            propertyKeyOptions.push( property.key() );
          }

        })
      }

      //log.debug("updateDatabaseFilters()", "propertyKeyOptions", this.state.propertiesSelector, propertyKeyOptions );

      let showCompanyProperty = false;
      if( propertiesSource.collectionDatabase.collectionName()=== CompaniesCollection ) {
        showCompanyProperty = false; 
      }
      else {
        const companyProperty = propertiesSource != null ? propertiesSource.property(CompanyDocument) : undefined;
        if (companyProperty != null ) {
  
          let isCompanyDatabase = false;
          this.props.databases.forEach( database => {
             if( database.collectionName()=== Collections.Companies ) {
              isCompanyDatabase = true;
             }
          })
  
          showCompanyProperty = !isCompanyDatabase && appContext.currentHomePath === HomePaths.AdminHomePath;

          if( companyFilter == null ) {
            companyProperty.setValue( undefined );
          }
        }
      }
      
      const unitProperty = propertiesSource?.property(UnitDocument);

      let showUnitProperty = unitProperty != null;
      //log.debug( "updateDatabaseFilters()", {unitProperty})

      if (unitProperty != null ) {

        showUnitProperty = 
          (appContext.currentHomePath === HomePaths.CompanyHomePath || appContext.currentHomePath === HomePaths.UnitHomePath); 

          if( unitFilter == null ) {
            unitProperty.setValue( undefined );
          }
      }
      

      let showUserProperty = false;
      if( propertiesSource.collectionDatabase.collectionName()=== UsersCollection ) {
        showUserProperty = false;

        if( !!appContext.currentCompany?.enableParents.value() ) {
          propertyKeyOptions.push( UserDocument );
        }
      }
      else {

        const userProperty = propertiesSource != null ? propertiesSource.property(UserDocument) : undefined;
        if( userProperty != null ) {

          let isUserDatabase = false;
          this.props.databases.forEach( database => {
            if( database.collectionName()=== Collections.Users ) {
              isUserDatabase = true;
            }
          })

          showUserProperty = !isUserDatabase && appContext.currentHomePath !== HomePaths.UserHomePath;

          if( userFilter == null ) {
            userProperty.setValue( undefined );
          }
        }
      }

      let filterPropertyKey = Factory.get().persistentState!.property( 
          this.propertyKeyPersistentKey() ) as string | undefined;

      log.debug( "updateDatabaseFilters()", {filterPropertyKey})

      let comparator = Factory.get().persistentState!.property( this.comparatorPersistentKey() ) as Comparator;
    
      if( comparator == null ) {
        comparator = DefaultComparator;
      }

      log.debug( "updateDatabaseFilters()", {comparator})

      let matchValue;

      if( filterPropertyKey != null ) {

        const filterProperty = propertiesSource.property( filterPropertyKey );

        if( filterProperty != null ) {

          if( filterProperty.type === PropertyTypes.Boolean ) {
            comparator = Comparators.Equal as Comparator;
          }

          const persistentMatchData = Factory.get().persistentState!.property( this.matchValuePersistentKey() );

          if( persistentMatchData != null ) {

            filterProperty.fromData( persistentMatchData );

            matchValue = filterProperty.value();
          }
        }
      }

      propertyKeyOptions.sort( (keyA, keyB) => {

        return translatedPropertyLabel(this.props.databases[0].defaultDocumentName(), keyA )!.localeCompare( 
          translatedPropertyLabel(this.props.databases[0].defaultDocumentName(), keyB ), activeLanguage() ); 
      } );

      log.debug( "updateDatabaseFilters()", {matchValue})

      if (this.state.propertiesSource == null || 
        propertiesSource.databasePath() !== this.state.propertiesSource.databasePath() ||
        JSON.stringify(propertyKeyOptions) !== JSON.stringify(this.state.propertyKeyOptions) ||
        showCompanyProperty !== this.state.showCompany ||
        JSON.stringify( companyFilter ) !== JSON.stringify( this.state.companyFilter ) ||
        showUnitProperty !== this.state.showUnit ||
        JSON.stringify( unitFilter ) !== JSON.stringify( this.state.unitFilter ) ||
        showUserProperty !== this.state.showUser ||
        JSON.stringify( userFilter ) !== JSON.stringify( this.state.userFilter ) ||
        filterPropertyKey !== this.state.filterPropertyKey ||
        comparator !== this.state.comparator ||
        matchValue !== this.state.matchValue
        ) {

        this.setState({
          propertiesSource: propertiesSource,

          propertyKeyOptions: propertyKeyOptions,

          showCompany: showCompanyProperty,

          companyFilter: companyFilter,

          showUnit: showUnitProperty,

          unitFilter: unitFilter,

          showUser: showUserProperty,

          userFilter: userFilter,

          filterPropertyKey: filterPropertyKey, 

          comparator: comparator,
    
          matchValue: matchValue
        })

        if (this.props.onDatabaseFiltersChange != null ) {

          const databaseFilters = new Map<string, DatabaseFilter>();
                         
          if( companyFilter != null ) {
    
            databaseFilters.set( CompanyDocument, {     
            
              property: CompanyDocument,
    
              comparator: Comparators.Equal as Comparator,
          
              value: companyFilter
            } );

            log.debug( "updateDatabaseFilters()", {companyFilter})

          }
          
          if( unitFilter != null ) {
    
            databaseFilters.set( UnitDocument, {     
            
              property: UnitDocument,
    
              comparator: Comparators.Equal as Comparator,
          
              value: unitFilter
            } );

            log.debug( "updateDatabaseFilters()", {unitFilter})

          }
    
          if( userFilter != null ) {
    
            databaseFilters.set( UserDocument, {     
            
              property: UserDocument,
    
              comparator: Comparators.Equal as Comparator,
          
              value: userFilter
            });

            log.debug( "updateDatabaseFilters()", {userFilter})

          }
    
          if( filterPropertyKey != null && 
              (matchValue != null || comparator === Comparators.Exists || comparator === Comparators.NotExists ) ) {
    
            databaseFilters.set(filterPropertyKey, {
    
              property: filterPropertyKey,
    
              comparator: comparator,
    
              value: matchValue 
            }); 

            log.debug( "updateDatabaseFilters()", {filterPropertyKey}, {comparator}, {matchValue})
          }
  
          await this.props.onDatabaseFiltersChange( databaseFilters );
        }
      }

      log.traceOut("updateDatabaseFilters()",);

    } catch( error ) {
      log.warn( "updateDatabaseFilters()", error );

      await errorDialog( error);

      log.traceOut( "updateDatabaseFilters()", error );
    }
  }

  private propertyKeyPersistentKey = () : string => {
    const appContext = this.context as AppContextProps;

    return appContext.currentHomePath + "." + this.props.databases[0].defaultDocumentName() + "." + PersistentKeyFilterPropertyKey;
  }

  private handlePropertyKey = async ( event : any, propertyKey? : string ) => {

    log.traceIn( "handlePropertyKey()", propertyKey );

    try {
      event.preventDefault();

      if( propertyKey === this.state.filterPropertyKey ) {

        log.traceOut( "handlePropertyKey()", "No change");
        return;
      }

      if( propertyKey == null ) {
        
        Factory.get().persistentState!.clearProperty( this.propertyKeyPersistentKey() );
      }
      else {
        Factory.get().persistentState!.setProperty( this.propertyKeyPersistentKey(), propertyKey );
        
        Factory.get().persistentState!.clearProperty( this.comparatorPersistentKey() ); 
      }

      Factory.get().persistentState!.clearProperty( this.matchValuePersistentKey() ); 

      await this.updateDatabaseFilters();

      log.traceOut( "handlePropertyKey()");

    } catch( error ) {
      log.warn( "handlePropertyKey()", error );

      await errorDialog( error);

      log.traceOut( "handlePropertyKey()", error );
    }
  }

  private filterProperty = () : DatabasePropertyIF<any> | undefined =>  {

    //log.traceIn( "filterProperty()" );

    try{

      if( this.state.propertiesSource == null ) {
        //log.traceOut( "filterProperty()", "no properties source" );
        return undefined;
      }

      if( this.state.filterPropertyKey == null ) {
        
        //log.traceIn( "filterProperty()", "no key );
        return undefined;
      }

      const property = this.state.propertiesSource.property( this.state.filterPropertyKey );

      log.traceOut("filterProperty()", property );
      return property;

    } catch (error) {

      log.warn("Error updating property filter", error);

      log.traceOut("filterProperty()", "error reading property");
      return undefined;
    }
  }

  private comparatorPersistentKey = () : string => {
    const appContext = this.context as AppContextProps;

    return appContext.currentHomePath + "." + this.props.databases[0].queryDocumentName() + "." + PersistentKeyFilterComparator;
  }

  private handleComparator = async ( event : any, 
    property : DatabasePropertyIF<any> | undefined, 
    comparator : Comparator ) => {

    log.traceIn( "handleComparator()", comparator );

    try {
      event.preventDefault();

      Factory.get().persistentState!.setProperty( this.comparatorPersistentKey(), comparator );
      
      await this.updateDatabaseFilters();
      
      log.traceOut( "handleComparator()");

    } catch( error ) {
      log.warn( "handleComparator()", error );

      await errorDialog( error);

      log.traceOut( "handleComparator()", error );
    }
  }


  private matchValuePersistentKey = () : string => {
    const appContext = this.context as AppContextProps;

    return appContext.currentHomePath + "." + this.props.databases[0].queryDocumentName() + "." + PersistentKeyFilterMatchValue;
  }

  private handleMatchValue = async ( matchValue : any ) => {

    log.traceIn( "handleMatchValue()", {matchValue} );

    try {

      const filterProperty = this.filterProperty(); 

      if( filterProperty == null ) {

        log.traceIn( "handleMatchValue()", "No filter property" );
        return;
      }

      filterProperty.setValue( matchValue );

      const persistentMatchData = {};
      
      await filterProperty.toData( persistentMatchData, true );

      log.debug( "handleMatchValue()", {persistentMatchData} );

      Factory.get().persistentState!.setProperty(  this.matchValuePersistentKey(), persistentMatchData  );

      await this.updateDatabaseFilters();

      log.traceOut( "handleMatchValue()");

    } catch( error ) {
      log.warn( "handleMatchValue()", error );

      await errorDialog( error);

      log.traceOut( "handleMatchValue()", error );
    }
  }


  private companyFilterPersistentKey = () : string => {
    const appContext = this.context as AppContextProps;

    return appContext.currentHomePath + "." + PersistentKeyFilterCompany;
  }


  private updateCompanyFilter = async ( companyProperty : TenantPropertyIF<CompanyIF> ) => {

    log.traceIn( "updateCompanyFilter()", companyProperty );

    try {

      const companyReferenceHandle = companyProperty.referenceHandle();

      if( companyReferenceHandle == null  ) {

        Factory.get().persistentState!.clearProperty( this.companyFilterPersistentKey() );
      }
      else {
        Factory.get().persistentState!.setProperty( this.companyFilterPersistentKey(),
          JSON.stringify( companyReferenceHandle, referenceHandleReplacer ) );
      }

      Factory.get().persistentState!.clearProperty( this.unitFilterPersistentKey() );
      Factory.get().persistentState!.clearProperty( this.userFilterPersistentKey() );

      await this.updateDatabaseFilters();

      log.traceOut( "updateCompanyFilter()");

    } catch( error ) {
      log.warn( "updateCompanyFilter()", error );

      await errorDialog( error);

      log.traceOut( "updateCompanyFilter()", error );
    }
  }

  private unitFilterPersistentKey = () : string => {
    const appContext = this.context as AppContextProps;

    return appContext.currentHomePath + "." + PersistentKeyFilterUnit;
  }


  private updateUnitFilter = async ( unitProperty : OwnerPropertyIF<UnitIF> ) => {

    log.traceIn( "updateUnitFilter()", unitProperty );

    try {

      const unitsReferenceHandle =  unitProperty.referenceHandle();

      if( unitsReferenceHandle == null ) { 

        Factory.get().persistentState!.clearProperty( this.unitFilterPersistentKey() );
      }
      else {
        Factory.get().persistentState!.setProperty( this.unitFilterPersistentKey(), 
          JSON.stringify( unitsReferenceHandle, referenceHandleReplacer  ) ); 
      }

      Factory.get().persistentState!.clearProperty( this.userFilterPersistentKey() );

      await this.updateDatabaseFilters();

      log.traceOut( "updateUnitFilter()");

    } catch( error ) {
      log.warn( "updateUnitFilter()", error );

      await errorDialog( error);

      log.traceOut( "updateUnitFilter()", error );
    }
  }

  private userFilterPersistentKey = () : string => {
    const appContext = this.context as AppContextProps;

    return appContext.currentHomePath + "." + PersistentKeyFilterUser;
  }

  private updateUserFilter = async ( userProperty : TenantPropertyIF<UserIF> ) => {

    log.traceIn( "updateUserFilter()", userProperty );

    try {
      const userReferenceHandle = userProperty.referenceHandle();

      if(userReferenceHandle == null ) {

        Factory.get().persistentState!.clearProperty( this.userFilterPersistentKey() );
      }
      else {
        Factory.get().persistentState!.setProperty(this.userFilterPersistentKey(), 
          JSON.stringify( userReferenceHandle, referenceHandleReplacer  ));

      }

      await this.updateDatabaseFilters();

      log.traceOut( "updateUserFilter()");

    } catch( error ) {
      log.warn( "updateUserFilter()", error );

      await errorDialog( error);

      log.traceOut( "updateUserFilter()", error );
    }
  }


  render() {
    //log.traceInOut("render()", this.state );

    const { classes } = this.props;

    const renderMatchFilter = () => {

      const selectedProperty = this.filterProperty();

      const filterPropertyLabel = this.props.t("property");

      const propertyKeyOptions = this.state.propertyKeyOptions != null ? 
        this.state.propertyKeyOptions : [];

      return (
        <Grid className={classes.root} container item direction="row" spacing={1} >
          {!!this.state.showCompany &&
            <Grid item className={classes.item} xs sm md >
              <PropertyValue
                property={this.state.propertiesSource!.property(CompanyDocument)!}
                onPropertyChange={(property) => this.updateCompanyFilter(property as TenantPropertyIF<CompanyIF>)}
                displayMode={PropertyDisplayMode.Filter}
                singleProperty={false} />
            </Grid>
          }
          {!!this.state.showUnit &&
            <Grid item className={classes.item} xs sm md >
              <PropertyValue
                property={this.state.propertiesSource!.property(UnitDocument)!}
                onPropertyChange={(property) => this.updateUnitFilter(property as OwnerPropertyIF<UnitIF>)}
                displayMode={PropertyDisplayMode.Filter}
                singleProperty={false} />
            </Grid>
          }
          {!!this.state.showUser &&
            <Grid item className={classes.item} xs sm md >
              <PropertyValue
                property={this.state.propertiesSource!.property(UserDocument)!}
                onPropertyChange={(property) => this.updateUserFilter(property as TenantPropertyIF<UserIF>)}
                displayMode={PropertyDisplayMode.Filter}
                singleProperty={false} />
            </Grid>
          }
          <Grid item className={classes.item} xs sm md >
            <Grid container alignItems="center">
              <FormControl
                fullWidth
                variant={propertyInputVariant}>
                <InputLabel className={classes.inputLabel}
                  shrink={true}>
                  {filterPropertyLabel}
                </InputLabel>
                <Select
                  className={classes.select}
                  input={(propertyInputVariant as string) !== "outlined" ? undefined :
                    <OutlinedInput
                      notched
                      label={<>{filterPropertyLabel}&nbsp;&nbsp;</>}
                    />
                  }
                  IconComponent={this.state.filterPropertyKey != null ? Box : undefined} 
                  displayEmpty={true}
                  renderValue={(value) => value == null || value === "" ? "" :
                    translatedPropertyLabel(this.props.databases[0].defaultDocumentName(), value)
                  }
                  value={this.state.filterPropertyKey != null ? this.state.filterPropertyKey : ""}
                  label={filterPropertyLabel}
                  onChange={(event) => this.handlePropertyKey(event, event.target.value as string)}
                  open={this.state.propertyKeyOpen}
                  onOpen={event => { 
                    this.setState({ propertyKeyOpen: true })
                  }}
                  onClose={() => {
                    this.setState({ propertyKeyOpen: false })
                  }}
                >
                  {propertyKeyOptions.map((option) => (
                    <MenuItem
                      style={this.state.filterPropertyKey === option ? { display: 'none' } : {}}
                      key={option}
                      value={option}>
                      {translatedPropertyLabel(this.props.databases[0].defaultDocumentName(), option)}
                    </MenuItem>
                  ))}
                  {!this.state.propertyKeyOpen && this.state.filterPropertyKey == null && <MenuItem value="" style={{ display: 'none' }} />}
                </Select>
              </FormControl>
              {this.state.filterPropertyKey != null &&
                <IconButton className={classes.clearButton} size="small" onClick={event => this.handlePropertyKey(event, undefined)}>
                  <CloseIcon />
                </IconButton>
              }
            </Grid>
          </Grid>
          {selectedProperty != null && selectedProperty.type !== PropertyTypes.Boolean &&
            <Grid item className={classes.item} xs={2} sm={2} md={1} >
              <FormControl
                fullWidth
                variant={propertyInputVariant}>
                <Select
                  className={classes.select}
                  input={(propertyInputVariant as string) !== "outlined" ? undefined :
                    <OutlinedInput
                      notched
                    />
                  }
                  displayEmpty={true}
                  renderValue={(value) =>
                    translatedDefinition(ComparatorDefinition, value)
                  }
                  value={this.state.comparator != null ? this.state.comparator : DefaultComparator}
                  label={filterPropertyLabel}
                  onChange={(event) => this.handleComparator(event, selectedProperty, event.target.value as Comparator)}
                  open={this.state.comparatorOpen}
                  onOpen={() => this.setState({ comparatorOpen: true })}
                  onClose={() => this.setState({ comparatorOpen: false })}
                >
                  {Object.values(Comparators).map((option) => (
                    <MenuItem
                      style={this.state.filterPropertyKey === option ? { display: 'none' } : {}}
                      key={option}
                      value={option}>
                      {translatedDefinition(ComparatorDefinition, option)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          }
          <Grid item container className={classes.item} xs sm md justifyContent="flex-start" alignItems="center"> 
            <Collapse
              in={selectedProperty != null && this.state.comparator !== Comparators.Exists && this.state.comparator !== Comparators.NotExists}
              timeout="auto" unmountOnExit
              className={classes.collapse}>
              {selectedProperty == null ? null :
                <PropertyValue
                  property={selectedProperty}
                  onPropertyChange={(property) => this.handleMatchValue(property.value())}
                  displayMode={PropertyDisplayMode.Filter}
                  singleProperty={false} />
              }
            </Collapse>
          </Grid>
        </Grid>
      );
    }

    return (
      <React.Fragment>
        <AppContext.Consumer>
          {appContext => (
            <>
              {renderMatchFilter()}
            </>
          )}
        </AppContext.Consumer> 
      </React.Fragment>
    );
  }


}

PropertyFilter.contextType = AppContext;

const ModifiedPropertyFilter = withTranslation()(withStyles(styles)(PropertyFilter));

export default ModifiedPropertyFilter;










