

import { AuthenticationService } from "../../authentication/framework/authenticationService";
import { SubscriptionManager } from "../../billing/framework/subscriptionManager";
import { LoggerFactory } from "../../common/api/loggerFactory";
import { Monitor } from "../../common/api/monitor";
import { Observation } from "../../common/api/observation";
import { Platform } from "../../common/api/platforms";
import { Target } from "../../common/api/targets";
import { PersistentState } from "../../common/framework/persistentState";
import { Translator } from "../../common/framework/translator";
import { Observable } from "../../common/impl/observable";
import { ConfigurationManager } from "../../configuration/framework/configurationManager";
import { DatabaseAccess } from "../../database/framework/databaseAccess";
import { DatabaseFactory } from "../../database/framework/databaseFactory";
import { DatabaseManager } from "../../database/framework/databaseManager";
import { KeyManager } from "../../security/framework/keyManager";
import { EmailSender } from "../../messaging/framework/email/emailSender";
import { PushReceiver } from "../../messaging/framework/push/pushReceiver";
import { PushSender } from "../../messaging/framework/push/pushSender";
import { SmsSender } from "../../messaging/framework/sms/smsSender";
import { MediaManager } from "../../storage/framework/mediaManager";
import { ApplicationServiceIF } from "../api/applicationServiceIF";


export const log = LoggerFactory.logger("application");

export abstract class ApplicationService extends Observable implements ApplicationServiceIF {

    constructor( platform : Platform, target : Target ) {

        super();

        //log.traceInOut("constructor()", target );

        try {

            this.platform = platform;

            this.target = target;

        } catch (error) {
            log.warn("constructor()", "Error constructing application service", error);

            throw new Error( (error as any).message );
        }
    }

    async init() : Promise<void> {

        log.traceIn("init()" );

        try {

            log.traceOut("init()" );

        } catch (error) {
            log.warn("init()", "Error initializing application service", error);

            throw new Error( (error as any).message );
        }
    }

    protected async monitor( newMonitor : Monitor ): Promise<void> {}

    protected async release(observationFilter?: Observation[], objectIdsFilter?: string[]): Promise<void> {}

    readonly platform : Platform;

    readonly target : Target;

    abstract database() : any;

    abstract readonly authenticationService? : AuthenticationService;

    abstract readonly mediaManager? : MediaManager;

    abstract readonly keyManager? : KeyManager;

    abstract readonly subscriptionManager? : SubscriptionManager;

    abstract readonly configurationManager : ConfigurationManager;

    abstract readonly databaseManager : DatabaseManager;

    abstract readonly databaseFactory: DatabaseFactory;

    abstract readonly databaseAccess: DatabaseAccess;

    abstract readonly emailSender? : EmailSender;

    abstract readonly smsSender? : SmsSender;

    abstract readonly pushSender? : PushSender;

    abstract readonly pushReceiver? : PushReceiver;

    abstract readonly persistentState?: PersistentState;

    abstract readonly translator: Translator;


}
