

import { DatabaseManagerIF } from "../api/core/databaseManagerIF";
import { CollectionDatabase } from "../impl/core/collectionDatabase";

import { DatabaseDocument } from "./databaseDocument";

import { DatabaseConverter } from "./databaseConverter";
import { DatabaseProperty } from "./databaseProperty";
import { CollectionGroupDatabase } from "../impl/core/collectionGroupDatabase";

import { Observation } from "../../common/api/observation";
import { Observable } from "../../common/impl/observable";
import { log } from "./databaseService";
import { ReferenceHandle } from "../api/core/referenceHandle";
import { Database } from "../impl/core/database";
import { Monitor } from "../../common/api/monitor";

export abstract class DatabaseManager extends Observable implements DatabaseManagerIF {

    constructor( params: { clientEncryption : boolean } ) {

        super();

        //log.traceIn( "constructor()");

        try {

            this.clientEncryption = params.clientEncryption;

            //log.traceOut( "constructor()");

        } catch( error ) {
            log.warn( "constructor()", "Error initializing database manager", error );

            throw new Error( (error as any).message );
        }
    }


    async documents( collectionDatabase : CollectionDatabase<DatabaseDocument>, documentPaths : string[] ) : Promise<Map<string,DatabaseDocument>> {

        log.traceIn( "("+collectionDatabase.collectionName()+")", "documents()", documentPaths );

        try {
            if( !collectionDatabase.userAccess().allowRead ) {
                throw new Error( "No read access to collection " + collectionDatabase.collectionName() );
            }

            let initialResult = new Map<string,DatabaseDocument>();

            if( documentPaths != null ) {
                documentPaths.forEach( async (documentPath) => { 

                    let databaseDocument : DatabaseDocument = collectionDatabase.newDocument( documentPath );

                    await this.readDocument( collectionDatabase, databaseDocument );

                    initialResult.set( documentPath, databaseDocument );
                } );
            }

            log.traceOut( "("+collectionDatabase.collectionName()+")", "documents()", initialResult );
            return initialResult;
            
        } catch( error ) {

            log.warn( "Error reading documents", collectionDatabase.collectionName(), error );
            
            throw new Error( (error as any).message );
        }
    }


    async addProperty( collectionDatabase : CollectionDatabase<DatabaseDocument>, 
        documentPath : string,
        property : DatabaseProperty<any> ): Promise<void> {

        try {
            log.traceIn( "("+collectionDatabase.collectionName()+")", "addProperty()", );

            let storedDocument = collectionDatabase.newDocument( documentPath );

            await this.readDocument( collectionDatabase, storedDocument );

            let storedDocumentData = await storedDocument.toData();

            await property.toData( storedDocumentData );

            storedDocument.fromData( storedDocumentData );

            await this.updateDocument( collectionDatabase, storedDocument );
    
            log.traceOut( "("+collectionDatabase.collectionName()+")", "addProperty()" );

        } catch( error ) {

            log.warn( "("+collectionDatabase.collectionName()+")", "addProperty()", "Error reading database property", error );

            throw new Error( (error as any).message );
        }
    }

    async readProperty( collectionDatabase : CollectionDatabase<DatabaseDocument>, 
        documentPath : string,
        property : DatabaseProperty<any>): Promise<void> {

        try {
            log.traceIn( "("+collectionDatabase.collectionName()+")", "readProperty()", );

            let storedDocument = collectionDatabase.newDocument( documentPath );

            await this.readDocument( collectionDatabase, storedDocument );

            let storedDocumentData = await storedDocument.toData();

            property.fromData( storedDocumentData[property.key() ] );

            log.traceOut( "("+collectionDatabase.collectionName()+")", "readProperty()" );

        } catch( error ) {

            log.warn( "("+collectionDatabase.collectionName()+")", "addProperty()", "Error reading database property", error );

            throw new Error( (error as any).message );
        }

    }

    async updateProperty( collectionDatabase : CollectionDatabase<DatabaseDocument>, 
        documentPath : string,
        property : DatabaseProperty<any>): Promise<void> {

        try {
            log.traceIn( "("+collectionDatabase.collectionName()+")", "updateProperty()", );

            let storedDocument = collectionDatabase.newDocument( documentPath );

            await this.readDocument( collectionDatabase, storedDocument );

            let storedDocumentData = await storedDocument.toData();

            await property.toData( storedDocumentData );

            storedDocument.fromData( storedDocumentData )

            await this.updateDocument( collectionDatabase, storedDocument );
    
            log.traceOut( "("+collectionDatabase.collectionName()+")", "updateProperty()" );

        } catch( error ) {

            log.warn( "("+collectionDatabase.collectionName()+")", "updateProperty()", "Error updating database property", error );

            throw new Error( (error as any).message );
        }      
    }

    async removeProperty( collectionDatabase : CollectionDatabase<DatabaseDocument>, 
        documentPath : string,
        property : DatabaseProperty<any> ): Promise<void> {

        try {
            log.traceIn( "("+collectionDatabase.collectionName()+")", "updateProperty()", );

            let storedDocument = collectionDatabase.newDocument( documentPath );

            await this.readDocument( collectionDatabase, storedDocument );

            let storedDocumentData = await storedDocument.toData();

            delete storedDocumentData[property.key()];

            storedDocument.fromData( storedDocumentData )

            await this.updateDocument( collectionDatabase, storedDocument );
    
            log.traceOut( "("+collectionDatabase.collectionName()+")", "updateProperty()" );

        } catch( error ) {

            log.warn( "("+collectionDatabase.collectionName()+")", "updateProperty()", "Error updating database property", error );

            throw new Error( (error as any).message );
        }      
    }

    protected async monitor( newMonitor : Monitor): Promise<void> {

        throw new Error( "Database should be monitored via a collection or collection group")
    }

    protected async release(observationFilter?: Observation[], objectIdsFilter?: string[]): Promise<void> {
        throw new Error( "Database should be monitored via a collection or collection group")
    } 

    isMonitoringCollectionGroup( collectionGroupDatabase : CollectionGroupDatabase<DatabaseDocument> ) : boolean {

        log.traceIn( "("+collectionGroupDatabase.collectionName()+")", "isMonitoringCollectionGroup()" );

        const result = this._collectionGroupMonitors.get(collectionGroupDatabase.databasePath( true )) !== undefined;

        log.traceOut( "("+collectionGroupDatabase.collectionName()+")", "isMonitoringCollectionGroup()", result );
        return result;
    }

    isMonitoringCollection( collectionDatabase : CollectionDatabase<DatabaseDocument> ) : boolean {

        //log.traceIn( "("+collectionDatabase.collectionName()+")", "isMonitoringCollection()" );

        const result = (this._collectionMonitors.get(collectionDatabase.databasePath( true )) !== undefined);

        //log.traceOut( "("+collectionDatabase.collectionName()+")", "isMonitoringCollection()", result );
        return result;
    }

    isMonitoringDocument( collectionDatabase : CollectionDatabase<DatabaseDocument>, documentPath : string ) : boolean {

        log.traceIn( "("+collectionDatabase.collectionName()+")", "isMonitoringDocument()", documentPath );

        let result = false;

        const collectionDocumentMonitors = this._documentMonitors.get(collectionDatabase.databasePath());

        if( collectionDocumentMonitors != null ) {

            result = collectionDocumentMonitors.get( documentPath ) != null;
        }

        log.traceOut( "("+collectionDatabase.collectionName()+")", "isMonitoringDocument()", result );
        return result;
    }

    documentWithProperty( database : Database<DatabaseDocument>, 
        key : string, 
        value : string ) : Promise<DatabaseDocument | undefined> {

        const properties = new Map<string,string>();
        
        properties.set( key, value );

        return this.documentWithProperties( database, properties );

    }

    async documentWithProperties( 
        database : Database<DatabaseDocument>, 
        properties : Map<string,string> ) : Promise<DatabaseDocument | undefined> {

        log.traceIn( "documentWithProperties()", {properties} );

        try {
            let databaseDocuments = await this.documentsWithProperties( database, properties);

            if( databaseDocuments == null || databaseDocuments.size === 0 ) {

                log.traceOut( "documentWithProperties()", "Not found");
                return undefined;
            }

            if( databaseDocuments.size > 1 ) {
                throw new Error( "Multiple documents with properties: [" + {properties} + "]");
            }

            log.traceOut( "documentWithProperties()", {properties} );
            return Array.from( databaseDocuments.values()! )![0]!;

        } catch( error ) {

            log.warn( "Error reading document with property", error );
            
            throw new Error( "Error querying database database: " + (error as any).message );
        }
    }

    documentsWithProperty( database : Database<DatabaseDocument>, 
        key : string, 
        value : string ) : Promise<Map<string,DatabaseDocument>> {

        const properties = new Map<string,string>();
        
        properties.set( key, value );

        return this.documentsWithProperties( database, properties ); 
    }
 
    abstract collectionGroup( 
        collectionGroupDatabase : CollectionGroupDatabase<DatabaseDocument> ): Promise<Map<string,DatabaseDocument>>;

    abstract monitorCollectionGroup( 
        collectionGroupDatabase : CollectionGroupDatabase<DatabaseDocument> ): Promise<void>; 

    abstract releaseCollectionGroup( 
        collectionGroupDatabase : CollectionGroupDatabase<DatabaseDocument>): Promise<void>; 
 
    abstract groupReferenceHandles( 
        collectionGroupDatabase : CollectionGroupDatabase<DatabaseDocument> ): Promise<Map<string,ReferenceHandle<DatabaseDocument>>>;

    abstract collectionGroupReference( collectionGroup : CollectionGroupDatabase<DatabaseDocument> ): any;  

    abstract collection( 
        collectionDatabase : CollectionDatabase<DatabaseDocument> ): Promise<Map<string,DatabaseDocument>>;

    abstract monitorCollection( 
        collectionDatabase : CollectionDatabase<DatabaseDocument> ) : Promise<void>;

    abstract releaseCollection( 
        collectionDatabase : CollectionDatabase<DatabaseDocument> ) : Promise<void>;

    abstract monitorDocuments( 
        collectionDatabase : CollectionDatabase<DatabaseDocument>, documentPaths : string[] ) : Promise<void>;

    abstract releaseDocuments( 
        collectionDatabase : CollectionDatabase<DatabaseDocument>, documentPaths : string[] ) : Promise<void>;

    abstract releaseAllDocuments( 
        collectionDatabase : CollectionDatabase<DatabaseDocument> ) : Promise<void>;

    abstract referenceHandles( 
        collectionDatabase : CollectionDatabase<DatabaseDocument> ): Promise<Map<string,ReferenceHandle<DatabaseDocument>>>;

    abstract collectionReference( collection : CollectionDatabase<DatabaseDocument> ): any; 

    abstract createDocument( 
        collectionDatabase : CollectionDatabase<DatabaseDocument>, databaseDocument : DatabaseDocument ): Promise<DatabaseDocument>;

    abstract deleteDocument( 
        collectionDatabase : CollectionDatabase<DatabaseDocument>, databaseDocument : DatabaseDocument ): Promise<void>;

    abstract archiveDocument( 
        collectionDatabase : CollectionDatabase<DatabaseDocument>, databaseDocument : DatabaseDocument ): Promise<void>;

    abstract readDocument( 
        collectionDatabase : CollectionDatabase<DatabaseDocument>, databaseDocument: DatabaseDocument): Promise<boolean>;

    abstract updateDocument( 
        collectionDatabase : CollectionDatabase<DatabaseDocument>, 
        databaseDocument: DatabaseDocument,
        force? : boolean ): Promise<void>;

    abstract documentReference( databaseDocument : DatabaseDocument ): any; // 

    abstract documentsWithProperties( 
        database : Database<DatabaseDocument>, 
        properties : Map<string,string> ) : Promise<Map<string,DatabaseDocument>>;

    abstract expiredArchivedDocuments() : Promise<Map<string,DatabaseDocument>>;
    
    abstract readonly converter : DatabaseConverter;

    protected _collectionGroupMonitors : Map<string,any> = new Map<string,any | null>();

    protected _collectionMonitors : Map<string,any> = new Map<string,any | null>();

    protected _documentMonitors : Map<string,Map<string,any>> = new Map<string,Map<string,any | null>>();

    readonly clientEncryption : boolean;

}
