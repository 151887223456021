import { DatabaseConverter } from "../../framework/databaseConverter";

import { Factory } from "../../../common/api/factory";
import { FirebaseService } from "../../../application/framework/firebase/firebaseService";

export class RealmConverter extends DatabaseConverter {

    toDate( timestamp : any ) : Date | undefined {

        if( timestamp == null || timestamp.seconds === 0 ) {
            return undefined;
        }
        return timestamp.toDate();
    }

    fromDate( date : Date ) : any | undefined {

        if( date == null || date.getTime() === 0 ) {
            return undefined;
        }

        const firebaseService = Factory.get().applicationService as FirebaseService;

        return firebaseService.firebase.firestore.Timestamp.fromDate( date );
    }    

    toGeolocation( geoPoint : any ) : any | undefined {

        if( geoPoint == null ) {
            return undefined;
        }
        return { lat: geoPoint.latitude, lon: geoPoint.longitude }
    }

    fromGeolocation( geolocation : any ) : any | undefined {

        if( geolocation == null ) {
            return undefined;
        }

        const firebaseService = Factory.get().applicationService as FirebaseService;

        return firebaseService.firebase.firestore.GeoPoint( geolocation.lat, geolocation.lon );
    }  
}
