
export const ConnectionStatuses = {

    Disconnected : "disconnected",

    Connecting : "connecting",

    Connected : "connected",

}

export type ConnectionStatus = keyof (typeof ConnectionStatuses);


