
import { Factory } from "../../../common/api/factory";
import { Observation } from "../../../common/api/observation";
import { Database } from "../../impl/core/database";
import { CollectionDatabase } from "../../impl/core/collectionDatabase";
import { CollectionGroupDatabase } from "../../impl/core/collectionGroupDatabase";
import { DatabaseDocument } from "../databaseDocument";
import { log } from "../databaseService";
import { RealmDatabaseManager } from "./realmDatabaseManager";


export class ClientRealmDatabaseManager extends RealmDatabaseManager {

    monitorCollectionGroup( collectionGroupDatabase : CollectionGroupDatabase<DatabaseDocument> ) : Promise<void> {

        return this.monitorDatabase( collectionGroupDatabase );
    }

    releaseCollectionGroup( collectionGroupDatabase : CollectionGroupDatabase<DatabaseDocument> ) : Promise<void> {
        
        return this.releaseDatabase( collectionGroupDatabase );
    }

    monitorCollection( collectionDatabase : CollectionDatabase<DatabaseDocument> ) : Promise<void> {

        return this.monitorDatabase( collectionDatabase );
    }

    releaseCollection( collectionDatabase : CollectionDatabase<DatabaseDocument> ) : Promise<void> {
        
        return this.releaseDatabase( collectionDatabase );
    }

    protected async monitorDatabase( database : Database<DatabaseDocument> ) : Promise<void> {
        log.traceIn( "monitorDatabase()", database.databasePath());

        try {

            if( !database.userAccess().allowRead ) {
                throw new Error( "No read access to database");
            }

            let databaseMonitor;

            if( database instanceof CollectionGroupDatabase ) {

                databaseMonitor = this._collectionGroupMonitors.get( database.databasePath( true ) );
            }
            else if( database instanceof CollectionDatabase ) { 

                databaseMonitor = this._collectionMonitors.get( database.databasePath( true ) );
            }
            else {
                 throw new Error( "Unrecognized database" );
            }

            if( databaseMonitor != null ) {
                log.traceOut( "monitorDatabase()", "Already monitoring database", database.databasePath() );
                return;
            }

            const databaseFilter = this.databaseFilter( database );

            databaseMonitor = this.mongoDBCollection( database ).watch( 
                { "filter" : { databaseFilter } } );

            if( database instanceof CollectionGroupDatabase ) {

                this._collectionGroupMonitors.set( database.databasePath( true ), databaseMonitor );
            }
            else if( database instanceof CollectionDatabase ) { 

                this._collectionMonitors.set( database.databasePath( true ), databaseMonitor );
            }

            for await ( const change of databaseMonitor ) {
                
                const documentPath = change._id;

                let documentData;

                let databaseDocument;

                let observation: Observation;

                switch (change.operationType) {

                    case "insert": {

                        observation = Observation.Create;

                        documentData = change.fullDocument;

                        //log.debug("Inserted document", {documentPath}, {documentData});
                        break;
                    }

                    case "update": 
                    case "replace": {

                        observation = Observation.Update;

                        documentData = change.fullDocument;

                        //log.debug("Updated document", {documentPath}, {documentData});
                        break;
                    }

                    case "delete": {

                        observation = Observation.Delete;

                        log.debug("Deleted document", {documentPath});
                        break;
                    }
                }

                if( documentData != null ) {

                    databaseDocument = 
                        await Factory.get().databaseService.databaseFactory.documentFromData(
                            documentPath, documentData) as DatabaseDocument;

                }

                await database.onNotify(database, observation!, documentPath, databaseDocument);

            }

            log.traceOut( "monitorDatabase()", database.databasePath());

        } catch( error ) {
            log.warn( "("+database.collectionName()+")", "monitorDatabase()", "Error monitoring database", error );
            
            throw new Error( (error as any).message );
        }
    }

    async releaseDatabase( database : Database<DatabaseDocument> ) : Promise<void> {
        
        log.traceIn( "releaseDatabase()", database.databasePath());

        try {
            let databaseMonitor;

            if( database instanceof CollectionGroupDatabase ) {

                databaseMonitor = this._collectionGroupMonitors.get( database.databasePath( true ) );
            }
            else if( database instanceof CollectionDatabase ) { 

                databaseMonitor = this._collectionMonitors.get( database.databasePath( true ) );
            }
            else {
                 throw new Error( "Unrecognized database" );
            }

            if( databaseMonitor == null ) {
                log.traceOut( "releaseDatabase()", "Not monitoring database", database.databasePath() );
                return;
            }

            databaseMonitor.return( undefined );

            this._collectionGroupMonitors.delete( database.databasePath( true ) );

            log.traceOut( "releaseDatabase()", database.databasePath());

        } catch( error ) {

            log.warn( "Error stopping collection group monitoring from firestore for", database.databasePath(), error );
            
            log.traceOut( "releaseDatabase()", database.databasePath(), "error" );
        }
    }

    async monitorDocuments( collectionDatabase : CollectionDatabase<DatabaseDocument>, documentPaths : string[] ) : Promise<void> {

        log.traceIn( "("+collectionDatabase.collectionName()+")", "monitorDocuments()", documentPaths );

        try {
            if( !collectionDatabase.userAccess().allowRead ) {
                throw new Error( "No read access to collection " + collectionDatabase.collectionName() );
            }

            if( documentPaths != null ) {
                for( const documentPath of documentPaths ) {

                    await this.monitorDocument( collectionDatabase, documentPath );

                }
            }

            log.traceOut( "("+collectionDatabase.collectionName()+")", "monitorDocuments()" );
            
        } catch( error ) {

            log.warn( "Error starting document monitoring for", collectionDatabase.collectionName(), error );
            
            throw new Error( (error as any).message );
        }
    }


    async monitorDocument( collectionDatabase : CollectionDatabase<DatabaseDocument>, documentPath : string ) : Promise<void> {

        log.traceIn( "monitorDocument()", collectionDatabase.databasePath(), {documentPath} );

        try {
            if( !collectionDatabase.userAccess().allowRead ) {
                throw new Error( "No read access to collection " + collectionDatabase.collectionName() );
            }

            const documentId = Factory.get().databaseService.databaseFactory.documentId( documentPath );

            if( documentId == null ) {
                throw new Error( "Invalid document path: " + documentPath );
            }


            let collectionDocumentMonitors = this._documentMonitors.get( collectionDatabase.databasePath( true ) );

            if( collectionDocumentMonitors == null ) {

                collectionDocumentMonitors = new Map<string,() => void>();

                this._documentMonitors.set( collectionDatabase.databasePath( true ), collectionDocumentMonitors );
            }

            let documentMonitor = collectionDocumentMonitors.get( documentPath );

            if( documentMonitor != null ) {
                log.traceOut( "monitorDocument()", collectionDatabase.databasePath(), {documentPath}, "Already monitoring" );
                return undefined;
            }

            documentMonitor = this.mongoDBCollection( collectionDatabase ).watch( 
                { "ids" : [documentPath] } );

            collectionDocumentMonitors.set( documentPath, documentMonitor );

            return new Promise<void>( async resolve => {

                let hasInitialResult = false;

                for await ( const change of documentMonitor ) {
                
                    try {
                        const documentPath = change._id;
        
                        let documentData;
        
                        let databaseDocument;
        
                        let observation: Observation;
        
                        switch (change.operationType) {
        
                            case "insert": {
        
                                observation = Observation.Create;
        
                                documentData = change.fullDocument;
        
                                //log.debug("Inserted document", {documentPath}, {documentData});
                                break;
                            }
        
                            case "update": 
                            case "replace": {
        
                                observation = Observation.Update;
        
                                documentData = change.fullDocument;
        
                                //log.debug("Updated document", {documentPath}, {documentData});
                                break;
                            }
        
                            case "delete": {
        
                                observation = Observation.Delete;
        
                                log.debug("Deleted document", {documentPath});
                                break;
                            }
                        }
        
                        if( documentData != null ) {
        
                            databaseDocument = 
                                await Factory.get().databaseService.databaseFactory.documentFromData(
                                    documentPath, documentData) as DatabaseDocument;
        
                        }

                        log.debug( "("+collectionDatabase.collectionName()+")", "monitorDocument()", observation!, documentData );

                        if( !hasInitialResult ) {
                            hasInitialResult = true;
                            resolve();
                        }
                        else {
                            await collectionDatabase.onNotify( collectionDatabase, Observation.Update, documentPath, databaseDocument );
                        }

                    } catch( error ) {
                        log.warn( "("+collectionDatabase.collectionName()+")", "monitorDocument()", "snapshot", error );

                        if( !hasInitialResult ) {
                            hasInitialResult = true;
                            resolve( );
                        }
                        else {
                            await collectionDatabase.onNotify( collectionDatabase, Observation.Delete, documentPath, undefined );
                        }
                    } 
                }     
            });
        } catch( error ) {

            log.warn( "Error stopping documents monitoring  for", collectionDatabase.databasePath(), {documentPath}, error );
            
            log.traceIn( "monitorDocument()", collectionDatabase.databasePath(), {documentPath}, "error" );
            return undefined;
        }
    }
    
    async releaseDocuments( collectionDatabase : CollectionDatabase<DatabaseDocument>, documentPaths : string[] ) : Promise<void> {
       
        log.traceIn( "releaseDocuments()", collectionDatabase.databasePath(), {documentPaths} );

        try {

            const collectionDocumentMonitors = this._documentMonitors.get( collectionDatabase.databasePath( true ) );

            if( collectionDocumentMonitors != null ) {

                for( const documentPath of documentPaths ) {

                    let documentMonitor = collectionDocumentMonitors.get( documentPath );
        
                    if( documentMonitor != null ) {

                        documentMonitor.return( undefined );
            
                        collectionDocumentMonitors.delete( documentPath );
                    }    
                } 
            }

            log.traceOut( "releaseDocuments()", collectionDatabase.databasePath(), {documentPaths} );

        } catch( error ) {

            log.warn( "Error stopping documents monitoring from firestore for", collectionDatabase.collectionName(), error );
            
            log.traceOut( "releaseDocuments()", collectionDatabase.databasePath(), {documentPaths}, "error");
        }

    }

    async releaseAllDocuments( collectionDatabase : CollectionDatabase<DatabaseDocument> ) : Promise<void> {
 
        log.traceIn( "releaseAllDocuments()", collectionDatabase.databasePath());

        try {

            let collectionDocumentMonitors = this._documentMonitors.get( collectionDatabase.databasePath( true ) );

            if( collectionDocumentMonitors == null ) {
                log.traceOut( "releaseAllDocuments()", collectionDatabase.databasePath(), "not found");
                return;
            }

            for( const documentMonitor of collectionDocumentMonitors.values() ) {

                documentMonitor.return( undefined );
            } 

            this._documentMonitors.delete( collectionDatabase.databasePath( true ) );

            log.traceOut( "releaseAllDocuments()", collectionDatabase.databasePath());

        } catch( error ) {

            log.warn( "Error stopping all documents monitoring from firestore for", collectionDatabase.collectionName(), error );
            
            log.traceOut( "releaseAllDocuments()", collectionDatabase.databasePath(), "error");
        }
    }
 }