import React from 'react';

import { pink, orange, blue, grey, red, green, yellow} from '@material-ui/core/colors';
import { ReadinessLevel, ReadinessLevels } from '../../healthguard/api/definitions/readinessLevel';
import { ConsentResponseDefinition, HealthguardDefinition, MeasureStateDefinition, TestResultDefinition } from '../../healthguard/api/healthguardDefinitions';
import { TestResult, TestResults } from '../../healthguard/api/definitions/testResult';
import { DefaultColorHue, EmptyColor } from './colorMap';
import { MeasureState, MeasureStates } from '../../healthguard/api/definitions/measureState';
import { Severities, Severity } from '../../services/database/api/definitions/severity';
import ColorIcon from './colorIcon';
import { ConnectionStatus, ConnectionStatuses } from '../../services/database/api/definitions/connectionStatus';
import { ReadinessLevelDefinition, ConnectionStatusDefinition, SeverityDefinition, KeyStatusDefinition } from '../../services/database/api/definitions';
import { KeyStatus, KeyStatuses } from '../../services/database/api/definitions/keyStatus';
import { ConsentResponse, ConsentResponses } from '../../healthguard/api/definitions/consentResponse';


export const readinessLevelColor = ( readinessLevel? : ReadinessLevel ) : string => { 

  if( readinessLevel == null ) {
    return EmptyColor; 
  }

  switch( readinessLevel ) {

    case ReadinessLevels.Red:
      return red[DefaultColorHue]; 

    case ReadinessLevels.Yellow:
      return yellow[DefaultColorHue];
    
    case ReadinessLevels.Green:
      return green[DefaultColorHue];

    default:
      throw new Error( "Unrecognized readiness level");
  }  
}

export const connectionStatusColor = ( connectionStatus? : ConnectionStatus ) : string => { 

  if( connectionStatus == null ) {
    return EmptyColor; 
  }

  switch( connectionStatus ) {

    case ConnectionStatuses.Connecting:
      return yellow[DefaultColorHue];
    
    case ConnectionStatuses.Connected:
      return green[DefaultColorHue];

    case ConnectionStatuses.Disconnected:
      return red[DefaultColorHue];

    default:
      throw new Error( "Unrecognized alert level");
  }  
}


export const severityColor = ( severity? : Severity ) : string => { 

  if( severity == null ) {
    return EmptyColor; 
  }

  switch( severity ) {

    case Severities.Catastrophic:
      return red[DefaultColorHue];

    case Severities.Critical:
      return pink[DefaultColorHue];

    case Severities.Moderate:
      return orange[DefaultColorHue];
  
    case Severities.Minor:
      return yellow[DefaultColorHue];
  
    case Severities.Negligible:
      return blue[DefaultColorHue];
  
    default:
      throw new Error( "Unrecognized risk severity");
  }  
}


export const measureStateColor = ( measureState? : MeasureState ) : string => { 

  if( measureState == null ) {
    return EmptyColor; 
  }

  switch( measureState ) {

    case MeasureStates.Ongoing:
      return green[DefaultColorHue];

    case MeasureStates.Inactive:
      return blue[DefaultColorHue];

    case MeasureStates.Terminated:
      return grey[DefaultColorHue];
  
    default:
      throw new Error( "Unrecognized test result");
  }  
}


export const testResultColor = ( testResult? : TestResult ) : string => { 

  if( testResult == null ) {
    return EmptyColor; 
  }

  switch( testResult ) {

    case TestResults.Negative:
      return red[DefaultColorHue];

    case TestResults.Positive:
      return green[DefaultColorHue];

    case TestResults.Ambigous: 
      return orange[DefaultColorHue];

    case TestResults.Pending: 
      return EmptyColor;

    default:
      throw new Error( "Unrecognized test result");
  }  
}


export const keyStatusColor = ( keyStatus? : KeyStatus ) : string => { 

  if( keyStatus == null ) {
    return EmptyColor; 
  }

  switch( keyStatus ) {

    case KeyStatuses.Requested:
      return blue[DefaultColorHue];

    case KeyStatuses.Enabled:
      return green[DefaultColorHue];

    case KeyStatuses.Disabled:
      return yellow[DefaultColorHue];
  
    default:
      throw new Error( "Unrecognized test result");
  }  
}


export const consentResponseColor = ( consentResponse? : ConsentResponse ) : string => { 

  if( consentResponse == null ) {
    return EmptyColor; 
  }

  switch( consentResponse ) {

    case ConsentResponses.Unread:
      return grey[DefaultColorHue];

    case ConsentResponses.Read:
      return blue[DefaultColorHue];

    case ConsentResponses.Accepted:
      return green[DefaultColorHue];

    case ConsentResponses.Rejected:
      return red[DefaultColorHue];
  
    case ConsentResponses.Withdrawn:
      return pink[DefaultColorHue];
  
    default:
      throw new Error( "Unrecognized test result");
  }  
}



export const definitionColor = ( definition : HealthguardDefinition, value? : string ) => {

  switch( definition ) {

    case ReadinessLevelDefinition:
      return readinessLevelColor( value as ReadinessLevel );

    case ConnectionStatusDefinition:
      return connectionStatusColor( value as ConnectionStatus );

    case SeverityDefinition:
      return severityColor( value as Severity );  

    case MeasureStateDefinition:
      return measureStateColor( value as MeasureState );

    case TestResultDefinition:
      return testResultColor( value as TestResult );

    case KeyStatusDefinition:
      return keyStatusColor( value as KeyStatus );

    case ConsentResponseDefinition:
      return consentResponseColor( value as ConsentResponse );


    default:
      return undefined;
  }
}

interface DefinitionColorProps {
  definition : HealthguardDefinition, 
  value? : string
}


interface DefinitionColorState { 
}

export default class DefinitionColor extends React.PureComponent<DefinitionColorProps,DefinitionColorState> {

  constructor( props: DefinitionColorProps ) {
    
    super(props);

    this.state = { 
    } as DefinitionColorState;

    //log.traceInOut( "constructor()" );
  }

  
  render() {

    //log.traceInOut( "render()" );

    const color = definitionColor( this.props.definition, this.props.value );

    return(
      <>
        {color && <ColorIcon color={color}/>}
      </>
    );
  }
}


