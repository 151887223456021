import React from 'react';

import { colorMap, EmptyColor } from './colorMap';

import { DatabasePropertyIF } from '../../services/database/api/core/databasePropertyIF';
import { definitionColor } from './definitionColor';
import { HealthguardDefinition } from '../../healthguard/api/healthguardDefinitions';
import { DefinitionPropertyIF } from '../../services/database/api/properties/definitionPropertyIF';
import { ReferenceHandle } from '../../services/database/api/core/referenceHandle';
import { DocumentNameKey } from '../../services/database/api/core/databaseServiceIF';
import { documentColor } from './documentColor';
import { DatabaseDocumentIF } from '../../services/database/api/core/databaseDocumentIF';
import { PropertyTypes } from '../../services/database/api/definitions/propertyType';

export const propertyColor = ( databaseProperty : DatabasePropertyIF<any> ) =>  {

  const key = databaseProperty.key();

  const value = databaseProperty.value(); 

  if( value == null ) {
    return EmptyColor;
  }

  switch( databaseProperty.type ) {  

    case PropertyTypes.Definition: 
    {
      const color = definitionColor( (databaseProperty as DefinitionPropertyIF<any>).definition as HealthguardDefinition, value )
      return color != null ? color : colorMap( (databaseProperty as DefinitionPropertyIF<any>).definition, value );
    }
  
    case PropertyTypes.Tenant:
    case PropertyTypes.Owner:
    case PropertyTypes.Reference:
      return colorMap( databaseProperty.key(), (value as ReferenceHandle<DatabaseDocumentIF>).path.split("?")[0] );

    default:
      if( key === DocumentNameKey ) {
        return documentColor( databaseProperty.parent as DatabaseDocumentIF );
      }
      else {
        return colorMap( key, value );
      }
  }
}

interface PropertyColorProps {
  databaseProperty : DatabasePropertyIF<any> 
}


interface PropertyColorState { 
}

export default class PropertyColor extends React.PureComponent<PropertyColorProps,PropertyColorState> {

  constructor( props: PropertyColorProps ) {
    
    super(props);

    this.state = { 
    } as PropertyColorState;

    //log.traceInOut( "constructor()" );
  }

  
  render() {

    //log.traceInOut( "render()" );

    return(
      <React.Fragment>
        <div style={{ width: "100%", height: "100%", borderRadius: "50%", backgroundColor: propertyColor( this.props.databaseProperty )}} />
      </React.Fragment>
    );
  }
}


