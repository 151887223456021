import * as React from 'react'

import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import { withTranslation, WithTranslation } from 'react-i18next';

import { log } from 'ui/app/app';

import { IconButton, Grid, Box, Typography, InputAdornment, TextField} from '@material-ui/core'; 

import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select'; 

import CloseIcon from '@material-ui/icons/Close'; 
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'; 
import VisibilityIcon from '@material-ui/icons/Visibility'; 

import { TextPropertyIF } from 'services/database/api/properties/textPropertyIF';
import PropertyLabel, { translatedPropertyLabel } from '../propertyLabel';
import { PropertyDisplayMode, propertyInputVariant, translatedPropertyValue } from '../propertyValue';
import { DatabasePropertyIF } from '../../../services/database/api/core/databasePropertyIF';
import { DefinitionPropertyIF } from '../../../services/database/api/properties/definitionPropertyIF';
import { HealthguardDefinition } from '../../../healthguard/api/healthguardDefinitions';
import { translatedDefinition } from '../definitionText';
import { InlineLoading } from '../loading';
import { TextType, TextTypes } from '../../../services/database/api/definitions/textTypes';
import { definitionIcon } from '../definitionIcon';
import { DatabaseDocumentIF, DocumentNameKey } from '../../../services/database';
import { definitionColor } from '../definitionColor';
import { documentColor } from '../documentColor';
import ColorIcon from '../colorIcon';
import { PropertyTypes } from '../../../services/database/api/definitions/propertyType';

const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
  },
  select: {
    "& .MuiSelect-select:focus": {
      backgroundColor: 'transparent'
    }
  },
  loading : {
    //color:theme.palette.grey[500], 
    marginLeft: theme.spacing(-4.5),
    marginRight: theme.spacing(-4.5) 
  },
  clearButton : {
    marginLeft: theme.spacing(-4.5),
    marginRight: theme.spacing(-4.5)
  },
  inputLabel: {
    marginTop: theme.spacing(-0.5)
  },
  icon: {
    width: theme.spacing(2), 
    height: theme.spacing(2),
    marginRight: theme.spacing(1.2)
  }
});

const MinMultilineRows = 1;
const MaxMultilineRows = 10;

const titleFontSize = "133%"

export interface TextPropertyValueProps extends WithStyles<typeof styles>, WithTranslation { 

  property : TextPropertyIF | DefinitionPropertyIF<any>, 

  onPropertyChange? : ( property : DatabasePropertyIF<any> ) => void,

  displayMode : PropertyDisplayMode,

  singleProperty: boolean,

  hideColor?: boolean,

  disabled?: boolean,

  required?: boolean,

  placeholder?: string
 }

interface TextPropertyValueState { 

  open : boolean,

  options? : string[],

  propertyValue?: string,

  propertyDefaultValue?: string,

  definition?: any,

  textType: TextType,

  multiline : boolean,

  loading: boolean,

  showPassword?: boolean
}


class TextPropertyValue extends React.Component<TextPropertyValueProps,TextPropertyValueState> {

  constructor( props: TextPropertyValueProps ) {
    
    super(props);

    let definition;

    let multiline;

    let textType = this.props.property.textType;

    if( this.props.property.type === PropertyTypes.Definition ) {

      definition = (this.props.property as DefinitionPropertyIF<any>).definition;

      multiline = false;
    }
    else if (this.props.property.type === PropertyTypes.Text ) {

      multiline = !!(this.props.property as TextPropertyIF).multiline;
    }


    this.state = { 
      open : false,

      definition: definition,

      textType: textType,

      multiline: !!multiline,

      loading: false

     } as TextPropertyValueState;

     this.options = this.options.bind(this);
     this.renderItem = this.renderItem.bind( this );
     this.renderValue = this.renderValue.bind( this );
     this.renderEdit = this.renderEdit.bind( this );
     this.renderSelect = this.renderSelect.bind( this );

    //log.traceInOut( "constructor()" );
  }

  componentDidMount() {

    this.setState({
      propertyValue: this.props.property.value(true),
      propertyDefaultValue: this.props.property.defaultValue()
    })

  }


  componentDidUpdate() {

    //log.traceIn( "componentDidUpdate()", this.props.property.value( true ), this.state.propertyValue );

    if( this.props.property.value( true ) !== this.state.propertyValue ||
        this.props.property.defaultValue() !== this.state.propertyDefaultValue) {

      this.setState( {
        propertyValue: this.props.property.value( true ),
        propertyDefaultValue: this.props.property.defaultValue()
      })
    }
    //log.traceOut( "componentDidUpdate()" );
  }

  async options() {

    try {
      log.traceIn( "options()" );

      const options = await this.props.property.select();

      this.setState( { 
        options : options != null ? Array.from( options.values() ) : [],
        loading: false
      } );
    
      log.traceOut( "options()", options );

    } catch( error ) {
      log.warn( "options()", "Error reading options", error );

      this.setState( { 
        loading: false
      } );
    }
  }

  renderItem = ( value? : string, defaultValue? : boolean ) => {

    const {classes } = this.props;

    if( value == null || value.length === 0 ) {
      return "";
    }

    const color = this.state.definition != null ? 
      definitionColor( this.state.definition, value ) : 
        this.props.property.key() === DocumentNameKey ? 
          documentColor( this.props.property.parent as DatabaseDocumentIF ) :
          undefined;


    const icon = this.state.definition != null ? 
      definitionIcon( this.state.definition, value, true ) : 
      undefined;

    return (
      <Grid container alignItems="center" style={{flexWrap: "nowrap"}}>
        {icon != null &&
          <Grid item className={classes.icon}>
            {icon}
          </Grid>
        }
        {!this.props.hideColor && color != null &&
          <Grid item className={classes.icon}>
            <ColorIcon color={color}/> 
          </Grid>
        }
        <Grid item >
          <Typography variant="inherit" color={!!defaultValue ? 'textSecondary' : 'textPrimary'}>
            {!!defaultValue && "("}
            {
              this.state.definition != null ?
                translatedDefinition( this.state.definition as HealthguardDefinition, value)
                :
                translatedPropertyValue(this.props.property.parent.documentName(), value)
            }
            {!!defaultValue && ")"}
          </Typography>
        </Grid>
      </Grid>
    );
  }

  renderValue = (value?: string) => {

    if (value == null || value === "") {

      return this.renderItem( this.state.propertyDefaultValue, true );
    }
    
    return this.renderItem( value );
  }


  renderEdit = (): JSX.Element => {
    //log.traceInOut("renderEdit()");

    const handleEditChange = ( event: { 
        preventDefault: () => void;
        target : { name : string, value : string }  
      } ) => {

      log.traceInOut("handleEditChange()" );

      event.preventDefault();
  
      this.props.property.setValue( event.target.value );

      this.setState( {
        propertyValue: this.props.property.value( true )
      }); 
  
      if( this.props.onPropertyChange != null ) {
        this.props.onPropertyChange( this.props.property );
      }
    }

    const variant = this.props.displayMode === PropertyDisplayMode.Title ? 
      undefined : propertyInputVariant;
  
    const multiline = this.state.multiline && 
      !(this.state.textType === TextTypes.Password && !this.state.showPassword);

    const disableUnderline = this.props.displayMode === PropertyDisplayMode.Title;

    return ( 
      <>
        <TextField 
          variant={variant} 
          multiline={multiline}
          minRows={this.state.multiline ? MinMultilineRows : undefined}
          maxRows={this.state.multiline ? MaxMultilineRows : undefined}
          type={this.state.textType === TextTypes.Password && !!this.state.showPassword ? undefined : 
            this.state.textType
          }
          fullWidth 
          key={this.props.property.key()} 
          error={this.props.property.error != null}
          required={this.props.required}       
          disabled={this.props.disabled}
          label={
            this.props.displayMode !== PropertyDisplayMode.Title && <PropertyLabel documentName={this.props.property.parent.documentName()} propertyKey={this.props.property.key()} />
          } 
          InputLabelProps={{ shrink: true }}
          inputProps={this.state.textType === TextTypes.Email ? 
            { style: { textTransform: "lowercase" } } : 
              this.props.displayMode === PropertyDisplayMode.Title ? 
                { style: { fontSize: titleFontSize } } :   
                undefined 
          }
          value={this.state.propertyValue == null ? "" : 
            this.state.propertyValue } 
          placeholder={this.props.placeholder != null ? this.props.placeholder : this.state.propertyDefaultValue} 
          onChange={handleEditChange} 
          InputProps={ this.state.textType !== TextTypes.Password ? {
            disableUnderline: disableUnderline } : { 
            endAdornment:
              <InputAdornment position="end">
                <IconButton
                  onClick={() => this.setState({showPassword: !this.state.showPassword})}
                  onMouseDown={(event) => {event.preventDefault()}}
                  size="small" 
                >
                  {!this.state.showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
              </InputAdornment> 
          }}
        />
      </>  
    );   
  }

  renderSelect = (): JSX.Element => {

    log.traceInOut("renderSelect()", this.props.property.value(), this.state.propertyValue, this.state.propertyDefaultValue ); 

    const {classes } = this.props;

    if (this.state.loading) {
      this.options();
    }
 
    const options = this.state.options != null ?
      this.state.options :
      this.state.propertyValue != null ?
        [this.state.propertyValue] :
        [];

    const label = translatedPropertyLabel( this.props.property.parent.documentName(),this.props.property.key());

    const handleChange = (event: SelectChangeEvent) => {

      this.props.property.setValue(event.target.value as string);

      this.setState({
        propertyValue: this.props.property.value( true )
      })

      if (this.props.onPropertyChange != null) {
        this.props.onPropertyChange(this.props.property);
      }
    };

    const handleClear = () => {

      this.props.property.setValue( undefined );

      this.setState({
        propertyValue: undefined
      })

      if (this.props.onPropertyChange != null) {
        this.props.onPropertyChange(this.props.property);
      }
    };

    const canClear = !this.state.open && !this.props.disabled && this.state.propertyValue != null;

    return (
      <Grid container alignItems="center">
        <FormControl
          fullWidth
          variant={propertyInputVariant} 
          disabled={this.props.disabled}
          required={this.props.required}
          error={this.props.property.error != null} >
          <InputLabel className={classes.inputLabel} shrink={true}>
            {label}
          </InputLabel>
          <Select
            className={classes.select}
            input={(propertyInputVariant as any) !== "outlined" ? undefined : 
              <OutlinedInput
                notched 
                label={<>{label}&nbsp;&nbsp;</>} 
              />
            }
            displayEmpty={true}
            renderValue={this.renderValue}
            value={this.state.propertyValue != null ? this.state.propertyValue : ""}
            placeholder={this.props.placeholder != null ? this.props.placeholder : this.state.propertyDefaultValue} 
            label={label}
            onChange={handleChange}
            open={this.state.open}
            IconComponent={ !!this.state.loading || canClear || !!this.props.disabled ? Box : undefined } 
            onOpen={() => {
              this.setState({ open: true, loading: true });
            }}
            onClose={() => {
              this.setState({ open: false, loading: false });
            }}
          >
            {options.map((option) => (
              <MenuItem
                selected={this.state.propertyValue === option}
                key={option}
                value={option}>
                {this.renderItem( option )}
              </MenuItem>
            ))} 
            {!this.state.open && this.state.propertyValue == null && <MenuItem value="" style={{ display: 'none' }}/>}
            {!this.state.loading && this.state.options != null && this.state.options.length === 0 && 
              <MenuItem disabled value="emptyOptions">{this.props.t("emptyOptions")}</MenuItem>} 
          </Select>
        </FormControl>
        {this.state.loading &&
          <Box className={classes.loading}><InlineLoading /></Box>
        }
        {canClear &&
          <IconButton className={classes.clearButton} size="small" onClick={() => handleClear()}>
            <CloseIcon />
          </IconButton> 
        }
      </Grid>
    );
  }

  render() {
    //log.traceInOut("render()");

    if( this.props.displayMode === PropertyDisplayMode.Cell ) {
      return this.renderValue( this.state.propertyValue );
    }

    if( this.props.property.requiresSelect() ){
      return this.renderSelect();
    }
    else {
      return this.renderEdit();
    }
  }


}

const ModifiedTextPropertyValue = withTranslation()(withStyles(styles)(TextPropertyValue));

export default ModifiedTextPropertyValue;











