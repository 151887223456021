import { DatabaseDocument } from "../../framework/databaseDocument";
import { DatabaseProperty } from "../../framework/databaseProperty";
import { DatabaseObject } from "../../framework/databaseObject";
import { log } from "../../framework/databaseService";
import { PropertyType } from "../../api/definitions/propertyType"; 
import { Factory } from "../../../common/api/factory";
import { UserAccess } from "../../../common/api/userAccess";
import { ReferenceHandle, referenceHandleReplacer } from "../../api/core/referenceHandle";
import { Database } from "../core/database";
import { DocumentPropertyIF } from "../../api/properties/documentPropertyIF";


export abstract class DocumentProperty<DerivedDocument extends DatabaseDocument> 
    extends DatabaseProperty<ReferenceHandle<DerivedDocument>> implements DocumentPropertyIF<DerivedDocument> {

    constructor( type : PropertyType, 
        parent : DatabaseObject, 
        onSelectSources? : () => (Database<DerivedDocument> | undefined)[],
        reciprocalKey? : keyof DerivedDocument ) {
    
        super( type, parent );

        try { 

            if( reciprocalKey != null && !(parent instanceof DatabaseDocument) ) {
                throw new Error( "Reciprocal keys can only be used for documents" );
            }

            this._onSelectSources = onSelectSources;

            this.reciprocalKey = reciprocalKey as string;

           //log.traceOut( "constructor()" );
        } catch( error ) {
            
            log.warn( "constructor()", "Error initializing document reference", error );

            throw new Error( (error as any).message );
        }
    }

    value() {
        return this.referenceHandle();
    }

    setValue( value : ReferenceHandle<DerivedDocument> | undefined ) : void {

        if( this.compareValue( value ) !== 0 ) {

            if( value == null ) {
                this.clearDocument();
            }
            else {
                this.setDocument( value );
            }
        }
    }

    id() : string | undefined {

        const referenceHandle = this.referenceHandle();

        if( referenceHandle == null ) {
            return undefined
        }

        const documentId = 
            Factory.get().databaseService.databaseFactory.documentId( referenceHandle.path );

        return documentId;
    }

    path() : string | undefined {

        return this.referenceHandle()?.path;
    }

    title() : string | undefined {

        return this.referenceHandle()?.title;
    }

    emptyDocument(): DerivedDocument | undefined {

        //log.traceIn( "emptyDocument()" );

        try {
            const referenceHandle = this.referenceHandle();

            if( referenceHandle == null ) {
                //log.traceOut( "emptyDocument()", "no path", undefined);
                return undefined;
            }

            const result = 
                Factory.get().databaseService.databaseFactory.newDocumentFromUrl( referenceHandle.path ) as DerivedDocument;

            if( result == null ) {

                this.clearDocument();

                //log.traceOut( "emptyDocument()", "not found", undefined);
                return undefined;
            }

            //result.title.setValue( this.title() );

            //log.traceOut( "emptyDocument()", result );
            return result; 

        } catch( error ) {

            log.warn( "newDocument()", "Error reading database objects", error );

            throw new Error( (error as any).message );
        }    
    }


    async document(): Promise<DerivedDocument | undefined> { 

        log.traceIn( "document()" );

        try {
            const referenceHandle = this.referenceHandle();

            if( referenceHandle == null ) {
                log.traceOut( "document()", "no path", undefined);
                return undefined;
            }

            let result;

            if( referenceHandle.databaseDocument != null ) {

                result = referenceHandle.databaseDocument;
            }
            else {
                
                const databaseDocument = 
                    await Factory.get().databaseService.databaseFactory.documentFromUrl( referenceHandle.path ) as DerivedDocument;

                if( databaseDocument == null ) {

                    this.clearDocument();

                    throw new Error( "Reference document not found: " + referenceHandle.path );

                }

                this.updateHandle( 
                    databaseDocument.databasePath( true ), 
                    databaseDocument.title.value() != null ? databaseDocument.title.value() : referenceHandle.title, 
                    databaseDocument.referenceDateProperty()?.value() != null ? databaseDocument.referenceDateProperty()?.value() : referenceHandle.date, 
                    databaseDocument );

                result = databaseDocument;
            }


            log.traceOut( "document()", referenceHandle.path );
            return result; 

        } catch( error ) {

            log.warn( "document()", "Error reading database objects", error );

            throw new Error( (error as any).message );
        }    
    }

    setDocument( referenceHandle : ReferenceHandle<DerivedDocument> ): void {

        //log.traceIn( "setDocument()", referenceHandle.title );

        const existingReferenceHandle = this.referenceHandle();

        const previouslyAdded = Factory.get().databaseService.databaseFactory.equalDatabasePaths( 
            referenceHandle.path, existingReferenceHandle?.path );

        const titleChanged = (existingReferenceHandle?.title !== referenceHandle.title);

        const oldDocument = existingReferenceHandle?.databaseDocument;
        
        try {

            this.updateHandle( 
                referenceHandle.path, 
                referenceHandle.title, 
                referenceHandle.date,
                referenceHandle.databaseDocument ); // Do this first or reciprocal check will fail, restore if exception
                                    

            if( !previouslyAdded || titleChanged ) {

                this.setLastChange( existingReferenceHandle );

                delete this.error;
            }

           log.traceOut( "setDocument()" );

        } catch( error ) {
            log.warn( "setDocument()", "Error adding database object", error );

            if( !previouslyAdded || titleChanged ) {
                this.updateHandle( 
                    existingReferenceHandle?.path, 
                    existingReferenceHandle?.title, 
                    existingReferenceHandle?.date,
                    oldDocument );
            }

            throw new Error( (error as any).message );
        }
    }

    
    clearDocument(): void {

        log.traceIn("clearDocument()" );

        try {
            const referenceHandle = this.referenceHandle();

            if( referenceHandle != null ) {

                this.setLastChange( referenceHandle );

                delete this._referenceHandle;
            }

            log.traceOut("clearDocument()" );

        } catch( error ) {
            log.warn( "clearDocument()", "Error adding database object", error );

            throw new Error( (error as any).message );
        }
    }

    newDocument(): DerivedDocument | undefined {

        try {

            const sources = this.sources();

            if( sources == null || sources.length === 0 ) {
                return undefined;
            }

            for( const source of sources ) {

                if( source == null ) {
                    continue;
                }

                if( typeof source === "string" ) {

                }
                else {
                    return source.newDocument();
                }
            }

            return undefined;

        } catch( error ) {

            log.warn("path()", "Error creating new document on reference ", error );

            throw new Error( (error as any).message );
        }  
    }


    async select( filterValue? : boolean ): Promise<Map<string,ReferenceHandle<DerivedDocument>> | undefined> {
 
        log.traceIn( "select()", {filterValue} );

        try {
            const sources = this.sources();

            if( sources == null || sources.length === 0 ) {
                log.traceOut( "referenceOptions()", "empty reference" );
                return undefined;
            }

            const result = new Map<string,ReferenceHandle<DerivedDocument>>();

            const value = this.value();

            for( const source of sources ) {

                if( source == null ) {
                    continue;
                }

                const referenceHandles = await source.referenceHandles();

                for( const referenceHandle of referenceHandles.values() ) {

                    if( !!filterValue && 
                        value?.path != null && 
                        referenceHandle.path != null &&
                        value.path.split("?")[0] === referenceHandle.path.split("?")[0] ) {
                        continue;
                    }
                    result.set( referenceHandle.path, referenceHandle )
                }
            }

            log.traceOut( "select()", result );
            return result;  

        } catch( error ) {

            log.warn( "select()", "Error reading database objects", error );

            throw new Error( (error as any).message );
        }    
    }

    userAccess(): UserAccess {

        //log.traceIn( "userAccess()" );

        try {
            const sources = this.sources();

            if( sources == null || sources.length === 0 ) {
                //log.traceOut( "userAccess()", "no sources" );
                return UserAccess.allowNone();
            }

            //log.traceOut( "userAccess()", "defer to parent document" );
            return this.parentDocument().userAccess();

        } catch( error ) {

            log.warn("path()", "Error getting user access on references ", error );

            throw new Error( (error as any).message );
        }  
    }

    collectionName() : string | undefined {

        try {
            const sources = this.sources();

            if( sources == null || sources.length === 0 ) {
                return undefined;
            }

            for( const source of sources ) {

                if( source == null ) {
                    continue;
                }

                return source.collectionName();
            }

            return undefined;

        } catch( error ) {

            log.warn("path()", "Error reading collection name ", error );

            throw new Error( (error as any).message );
        } 
    }

    queryDocumentName() : string | undefined {

        try {
            const sources = this.sources();

            if( sources == null || sources.length === 0 ) {
                return undefined;
            }

            for( const source of sources ) {

                if( source == null ) {
                    continue;
                }

                return source.queryDocumentName();
            }

            return undefined;

        } catch( error ) {

            log.warn("path()", "Error reading default document name", error );

            throw new Error( (error as any).message );
        } 
    }

    documentNames() : string[] | undefined {

        try {
            const sources = this.sources();

            if( sources == null || sources.length === 0 ) {
                return undefined;
            }

            for( const source of sources ) {

                if( source == null ) {
                    continue;
                }

                return source.documentNames();
            }

            return undefined;

        } catch( error ) {

            log.warn("path()", "Error reading document name", error );

            throw new Error( (error as any).message );
        } 
    }


    async toData( documentData: any, force? : boolean ) : Promise<void> {
        
        //log.traceIn( "toData()", this.key(), this._handle );

        try {
            if( !!force && this._encryptedReferenceData != null) {
                this.referenceHandle(); // forces decryption
            } 
            
            if( this.encrypted() && this._encryptedReferenceData != null ) {

                documentData[this.key()] = this._encryptedReferenceData;

                //log.traceOut( "toData()", "from encrypted data" );
                return;
            }

            if( this._referenceHandle?.path == null ) {
                //log.traceOut( "toData()", undefined );
                return;
            }

            const referenceMap = {} as any;

            const documentId = 
                Factory.get().databaseService.databaseFactory.documentId( this._referenceHandle.path )!;

            const jsonReferenceHandle = JSON.stringify( this._referenceHandle, referenceHandleReplacer );
            
            if( this.encrypted() ) {

                referenceMap[documentId] = this.encryptData( jsonReferenceHandle )!;

                this._encryptedReferenceData = referenceMap;

                documentData[this.key()] = this._encryptedReferenceData;
            }
            else { 
                referenceMap.set( documentId, jsonReferenceHandle );

                documentData[this.key()] = referenceMap;
            }

            //log.traceOut( "toData()", result );

        } catch( error ) {
            log.warn( "toData()", "Error converting reference to json ", error );

            throw new Error( (error as any).message );
        }
    }

    fromData( documentData: any): void {

        //log.traceIn( "fromData()" );

        try {
            delete this._referenceHandle;

            const data = documentData[this.key()];

            if( data == null ) {
                return;
            }

            let jsonObject : any;

            if (typeof data === 'object' ) {

                jsonObject = data;
            } 
            else if (typeof data === 'string') {

                jsonObject = JSON.parse(data);
            }
            else {
                throw new Error( "Empty reference object: " + data );                  
            }

            if( jsonObject == null ) {
                throw new Error( "Corrupt reference: " + data );
            }

            if( jsonObject.path != null ) { // legacy

                this._referenceHandle = {} as ReferenceHandle<DerivedDocument>;

                this._referenceHandle.path = jsonObject.path;
                
                this._referenceHandle.title = jsonObject.title != null ? jsonObject.title : undefined;

            }
            else {

                for( const key of Object.keys(jsonObject) ) {

                    if( key.startsWith("/") ) { // legacy 2, cannot use path as index for searh queries

                        this._referenceHandle = {} as ReferenceHandle<DerivedDocument>;

                        this._referenceHandle.path = key;

                        const title = jsonObject[key];
    
                        this._referenceHandle.title = title != null && title.length > 0 ? title : undefined;

                        //log.warn( "fromData()", "Old data format!", this.parent.ownerCollection()!.databasePath(), this.key(), {documentData} );
                    }
                    else {

                        if( this.isEncryptedData( jsonObject[key] ) ) {    

                            this._encryptedReferenceData = jsonObject;
                        }
                        else {
                            
                            this._referenceHandle = JSON.parse( jsonObject[key] ) as ReferenceHandle<DerivedDocument>;
                        }
                    }
                }
            }

            //log.traceOut( "fromData()", this._handle );
             
        } catch( error ) {
            log.warn( "fromData()", "Error reading reference: " + this.parentDocument().databasePath(), error );

            throw new Error( (error as any).message );
        }
    }

    referenceHandle() : ReferenceHandle<DerivedDocument> | undefined {
        //log.traceIn( "referenceHandle()" );

        try {
            if( this._referenceHandle == null && this._encryptedReferenceData == null ) {
                return undefined;
            }
            
            if (this._encryptedReferenceData != null) {

                delete this._referenceHandle;

                for (const entry of Object.entries(this._encryptedReferenceData)) {

                    const databaseReference = typeof entry[0] === "string" ? undefined : entry[0] as any;

                    const encryptedReferenceHandle = entry[1] as string;

                    const jsonReferenceHandle = Factory.get().securityService.symmetricCipher.decrypt(encryptedReferenceHandle);

                    if( jsonReferenceHandle == null ) {
                        return undefined;
                    }
                    const referenceHandle = JSON.parse(jsonReferenceHandle) as ReferenceHandle<DerivedDocument>;

                    if (referenceHandle != null) {

                        this._referenceHandle = referenceHandle;

                        this._referenceHandle.databaseReference = databaseReference;
                    }
                    break;
                }
                delete this._encryptedReferenceData;
            }

            if (this._referenceHandle?.path == null) {
                //log.traceOut( "referenceHandle()", "no handle or path" );
                return undefined;
            }

            const referenceHandle = Object.assign( {}, this._referenceHandle );

            //log.traceOut( "referenceHandle()", {referenceHandle} );

            return referenceHandle;

        } catch (error) {

            log.warn("referenceHandle()", "Error reading reference ", error);

            return undefined;
        }
    }


    compareTo( other : DocumentProperty<DerivedDocument> ) : number {
        return this.compareValue( other.value() );
    }

    compareValue( otherValue : ReferenceHandle<DerivedDocument> | undefined ) : number {

        const value = this.value();

        if( value?.path == null && otherValue?.path == null ) {
            return 0;
        }

        if( value?.path == null && otherValue?.path != null ) {
            return -1;
        }

        if( value?.path != null && otherValue?.path == null ) { 
            return 1;
        }

        const pathCompare = value!.path.split("?")[0].localeCompare( otherValue!.path.split("?")[0] );

        const titleCompare = value!.title != null && otherValue?.title != null ? 
            value!.title!.localeCompare( otherValue!.title! ) : 0;

        return pathCompare !== 0 && titleCompare !== 0 ?
            titleCompare :
            pathCompare;
    }
 

    includes( other : DocumentProperty<DerivedDocument>  ) : boolean {
        return this.includesValue( other.value() );
    }

    includesValue( value : ReferenceHandle<DerivedDocument> | undefined ) : boolean {
        return this.compareValue( value ) === 0;
    }

    protected updateHandle( 
        databasePath? : string, 
        title? : string, 
        date? : Date,
        databaseDocument? : DerivedDocument ) : void {

        if( databasePath == null ) {

            delete this._referenceHandle;
        }
        else if( databasePath !== this._referenceHandle?.path ) {

            this._referenceHandle = {
                path: databasePath,
                title: title,
                date: date
            } as ReferenceHandle<DerivedDocument>;

            if( databaseDocument != null ) {

                this._referenceHandle.databaseDocument = databaseDocument;

                this._referenceHandle.databaseReference = databaseDocument.databaseReference();

                this._referenceHandle.title = databaseDocument.title.value();
            }
        }
        else {
            if( this._referenceHandle.databaseDocument != null ) {

                if( databaseDocument != null ) { 

                    this._referenceHandle.databaseDocument.copyFrom( databaseDocument );
                }
            }
            else {
                this._referenceHandle.databaseDocument = databaseDocument;
            }

            if( this._referenceHandle.databaseDocument != null ) {

                this._referenceHandle.databaseReference = this._referenceHandle.databaseDocument.databaseReference();

                this._referenceHandle.title = this._referenceHandle.databaseDocument.title.value();
            }    
        }    
    }

    sources() : (Database<DerivedDocument> | undefined)[] | undefined {

        if( this._sources !== undefined ) {
            return this._sources == null ? undefined : this._sources;
        }

        const sources = this._onSelectSources != null ? this._onSelectSources() : undefined;

        if( sources != null ) {
            for( const source of sources ) {

                if( source != null && source.userAccess().allowRead ) {
    
                    if( this._sources == null ) {
                        this._sources = [];
                    }
    
                    this._sources.push( source );
                }
            }
        }

        if( sources == null || sources.length === 0 ) {
            this._sources = null;
            return undefined;
        }
            
        return this._sources;
    }

    readonly reciprocalKey? : string;

    protected readonly _onSelectSources? : () => (Database<DerivedDocument> | undefined)[];

    protected _sources? : (Database<DerivedDocument> | undefined)[] | null;

    protected _referenceHandle? : ReferenceHandle<DerivedDocument>;

    private _encryptedReferenceData? : any;

}
