import { Documents } from "../../services/database/api/documents";
import { RegistrationDocuments } from "./registrationDocuments";



export const HealthguardDocuments = {

    ...Documents,

    ...RegistrationDocuments,

    Consent                     : "consent",

    Hazard                : "hazard",

    Gathering                   : "gathering",

    Risk                        : "risk",

    HealthguardAlert            : "healthguardAlert",

    HealthguardDevice           : "healthguardDevice",

    HealthguardCategory         : "healthguardCategory",

    HealthguardCompany          : "healthguardCompany",

    HealthguardLocation         : "healthguardLocation",

    HealthguardProject          : "healthguardProject",

    HealthguardUnit             : "healthguardUnit",

    HealthguardUser             : "healthguardUser",

    Measure                     : "measure"
}

export type HealthguardDocumentName = keyof (typeof HealthguardDocuments);

export const HealthguardDocumentNames = Object.values( HealthguardDocuments );

export const ConsentDocument = HealthguardDocuments.Consent as HealthguardDocumentName;

export const HazardDocument = HealthguardDocuments.Hazard as HealthguardDocumentName;

export const GatheringDocument = HealthguardDocuments.Gathering as HealthguardDocumentName;

export const RiskDocument = HealthguardDocuments.Risk as HealthguardDocumentName;

export const HealthguardAlertDocument = HealthguardDocuments.HealthguardAlert as HealthguardDocumentName;

export const HealthguardDeviceDocument = HealthguardDocuments.HealthguardDevice as HealthguardDocumentName;

export const HealthguardCategoryDocument = HealthguardDocuments.HealthguardCategory as HealthguardDocumentName;

export const HealthguardCompanyDocument = HealthguardDocuments.HealthguardCompany as HealthguardDocumentName;

export const HealthguardLocationDocument = HealthguardDocuments.HealthguardLocation as HealthguardDocumentName;

export const HealthguardProjectDocument = HealthguardDocuments.HealthguardProject as HealthguardDocumentName; 

export const HealthguardUnitDocument = HealthguardDocuments.HealthguardUnit as HealthguardDocumentName;

export const HealthguardUserDocument = HealthguardDocuments.HealthguardUser as HealthguardDocumentName;

export const MeasureDocument = HealthguardDocuments.Measure as HealthguardDocumentName;



