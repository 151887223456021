import React, { useContext } from 'react';

import DocumentView, { DocumentProps } from '../components/documentView';

import { PropertiesSelector } from 'services/database/api/core/propertiesSelector';

import DatabaseTable, { DatabaseTableProps } from 'ui/components/databaseTable';

import DatabaseCard, { DatabaseCardProps } from 'ui/components/databaseCard';
import DatabaseChart, { DatabaseChartProps } from 'ui/components/databaseChart';
import DatabaseView from '../components/databaseView';
import DatabaseGraph, { DatabaseGraphProps } from 'ui/components/databaseGraph';

import { CategoryIF } from 'services/database/api/documents/categoryIF';
import { CompanyDocument } from '../../services/database/api/documents';
import { AppContext } from '../app/appContext';
import { HomePaths } from '../../services/common/api/homePaths';
import { DatabaseQuery } from '../../services/database/api/core/databaseQuery';
import { DatabaseViewType, DatabaseViewTypes } from '../components/databaseViewMenu';
import DatabaseList, { DatabaseListProps } from '../components/databaseList';

export const defaultCategoryTableColumns = [
  CompanyDocument,
  "title",
  "categoryType",
  "autoGenerated"
];

export const defaultHighlightedCategoryProperty = "title";

export function CategoriesTable( props: DatabaseTableProps ) {
  return (
    <DatabaseTable {...props} featuredProperties={props.featuredProperties != null ? props.featuredProperties : defaultCategoryTableColumns}/>
  );
}

export function CategoriesList( props: DatabaseListProps ) {
  return (
    <DatabaseList {...props} />
  );
}


export function CategoriesCard( props: DatabaseCardProps ) {
  return (
    <DatabaseCard 
      {...props}  
      highlightedPropertyKey={props.highlightedPropertyKey != null ? props.highlightedPropertyKey : defaultHighlightedCategoryProperty}
    />
  );
}

export function CategoriesChart( props: DatabaseChartProps ) {
  return (
    <DatabaseChart 
      {...props} 
    />
  );
}


export function CategoriesGraph( props: DatabaseGraphProps ) { 
  return (
    <DatabaseGraph 
      {...props} 
      nextPropertyKey={"users"}
      directed={false}
    />
  );
}



export function CategoriesView(props: { 
  databaseQuery : DatabaseQuery<CategoryIF>,
  title? : string,
  featuredProperties?: string[],
  highlightedPropertyKey? : string }) {

  return ( 
    <DatabaseView 
      databaseQuery={props.databaseQuery} 
      title={props.title}
      featuredProperties={props.featuredProperties != null ? props.featuredProperties : defaultCategoryTableColumns}
      highlightedPropertyKey={props.highlightedPropertyKey != null ? props.highlightedPropertyKey : defaultHighlightedCategoryProperty}
      hideDateFilter={true}
      defaultDatabaseViewType={DatabaseViewTypes.Table as DatabaseViewType} 
    /> );
}

export function CategoryView( props : DocumentProps ) {

  const appContext = useContext(AppContext);

  const CategoryBasicsFilter = {

    includePropertyKeys: [
      "title",
      "unit",
      "description",
      "categoryType",
      "autoGenerated",
      "attachments",
      "links"
    ]

  } as PropertiesSelector;

  const CategoryUsersFilter = {

    includePropertyKeys: [
      "users"
    ]

  } as PropertiesSelector;


  const defaultCategorySteps = (): Map<string, PropertiesSelector> => {

    let defaultSteps = new Map<string, PropertiesSelector>();

    defaultSteps.set("basics", CategoryBasicsFilter);

    if( appContext.currentHomePath !== HomePaths.UserHomePath ) {
      defaultSteps.set("users", CategoryUsersFilter);
    }

    return defaultSteps;
  }
  return (<DocumentView databaseDocument={props.databaseDocument} 
    inputTabs={props.inputTabs != null ? props.inputTabs : defaultCategorySteps()} />);
}



