import React from 'react';

import DocumentView, { DocumentProps } from '../components/documentView';

import { PropertiesSelector } from 'services/database/api/core/propertiesSelector';

import DatabaseTable, { DatabaseTableProps, StartDateProperty } from 'ui/components/databaseTable';
import DatabaseList, { DatabaseListProps } from 'ui/components/databaseList';
import DatabaseCard, { DatabaseCardProps } from 'ui/components/databaseCard';
import DatabaseChart, { DatabaseChartProps } from 'ui/components/databaseChart';
import DatabaseGraph, { DatabaseGraphProps } from 'ui/components/databaseGraph';

import DatabaseView from '../components/databaseView';
import { SymptomRegistrationIF } from 'healthguard/api/documents/symptomRegistrationIF';
import { CompanyDocument, UnitDocument, UserDocument } from '../../services/database/api/documents';
import { DatabaseQuery } from '../../services/database/api/core/databaseQuery';
import { MeasuresCollection } from '../../healthguard/api/healthguardCollections';


export const DefaultSymptomRegistrationTableColumns =  [
  StartDateProperty,
  CompanyDocument,
  UnitDocument,
  UserDocument,
  "symptoms"]

export const defaultHighlightedSymptomRegistrationsProperty = "symptoms";

export function SymptomRegistrationsTable(props: DatabaseTableProps ) {

  return (
    <DatabaseTable {...props} featuredProperties={props.featuredProperties != null ? props.featuredProperties : DefaultSymptomRegistrationTableColumns }/>
  );
}

export function SymptomRegistrationsList(props: DatabaseListProps ) {

  return (
    <DatabaseList {...props} />
  );
}


export function SymptomRegistrationsCard( props: DatabaseCardProps ) {
  return (
    <DatabaseCard 
      {...props} 
      highlightedPropertyKey={props.highlightedPropertyKey != null ? props.highlightedPropertyKey : defaultHighlightedSymptomRegistrationsProperty}
    />
  );
} 

export function SymptomRegistrationsChart( props: DatabaseChartProps ) {
  return (
    <DatabaseChart 
      {...props} 
    />
  );
}


export function SymptomRegistrationsGraph( props: DatabaseGraphProps ) {
  return (
    <DatabaseGraph 
      {...props} 
      previousPropertyKey={"symptoms"}
      nextPropertyKey={"user"}
      directed={false}
    />
  );
}

export function SymptomRegistrationsView(props: { 
  databaseQuery : DatabaseQuery<SymptomRegistrationIF>,
  title? : string,
  featuredProperties?: string[],
  highlightedPropertyKey?: string }) {

  return ( 
    <DatabaseView 
      databaseQuery={props.databaseQuery} 
      title={props.title}
      featuredProperties={props.featuredProperties != null ? props.featuredProperties : DefaultSymptomRegistrationTableColumns }
      highlightedPropertyKey={props.highlightedPropertyKey != null ? props.highlightedPropertyKey : defaultHighlightedSymptomRegistrationsProperty}
    /> );
}

export function SymptomRegistrationView( props : DocumentProps ) {

  const SymptomRegistrationBasicsFilter = {

    includePropertyKeys: [
      UnitDocument,
      UserDocument,
      MeasuresCollection,
      "startDate",
      "endDate",
      "hazard",
      "symptoms",
      "description"
    ]

  } as PropertiesSelector;


  /*
  const SymptomRegistrationsFilter = {

    includePropertyKeys: [
      "testRegistrations",
      "incidentRegistrations",
      "quarantineRegistrations",
      "sickLeaveRegistrations"
  ]
  } as PropertiesSelector;
  
  
  const SymptomAssociationsFilter = {

    includePropertyKeys: [
      "measures",
      "locations",
      "categories",
      "projects",
      "gathering",
      "residence"]
  
  } as PropertiesSelector;

  */
  const defaultSymptomRegistrationSteps = (): Map<string, PropertiesSelector> => {

    let defaultSteps = new Map<string, PropertiesSelector>();

    defaultSteps.set("basics", SymptomRegistrationBasicsFilter);
    //defaultSteps.set("registrations", SymptomRegistrationsFilter);
    //defaultSteps.set("associations", SymptomAssociationsFilter);

    return defaultSteps;
  }
  return ( <DocumentView 
    {...props}
    inputTabs={props.inputTabs != null ? props.inputTabs : defaultSymptomRegistrationSteps()}
  /> );
}




