import React from 'react';

import DocumentView, { DocumentProps } from '../components/documentView';

import { PropertiesSelector } from 'services/database/api/core/propertiesSelector';

import DatabaseTable, { DatabaseTableProps } from 'ui/components/databaseTable';
import DatabaseList, { DatabaseListProps } from 'ui/components/databaseList';
import DatabaseCard, { DatabaseCardProps } from 'ui/components/databaseCard';
import DatabaseChart, { DatabaseChartProps } from 'ui/components/databaseChart';
import DatabaseGraph, { DatabaseGraphProps } from 'ui/components/databaseGraph';

import DatabaseView from '../components/databaseView';
import { HazardIF } from 'healthguard/api/documents/hazardIF';
import { DatabaseQuery } from '../../services/database/api/core/databaseQuery';
import { DatabaseViewType, DatabaseViewTypes } from '../components/databaseViewMenu';

 
export const defaultHazardTableColumns = ["title","hazardType","commonSymptoms","seriousSymptoms"];

export const defaultHighlightedHazardProperty = "hazardType";

export function HazardsTable(props: DatabaseTableProps ) { 

  return (
    <DatabaseTable  
      {...props} 
      featuredProperties={props.featuredProperties != null ? props.featuredProperties : defaultHazardTableColumns }/>
  );
}

export function HazardsList(props: DatabaseListProps ) {

  return (
    <DatabaseList  {...props} />
  );
}





export function HazardsCard( props: DatabaseCardProps ) {
  return (
    <DatabaseCard 
      {...props} 
      highlightedPropertyKey={props.highlightedPropertyKey != null ? props.highlightedPropertyKey : defaultHighlightedHazardProperty}
    />
  );
}

export function HazardsChart(props: DatabaseChartProps) {
  return (
    <DatabaseChart
      {...props} 
    />
  );
}

export function HazardsGraph( props: DatabaseGraphProps ) {
  return (
    <DatabaseGraph 
      {...props} 
      nextPropertyKey={"variants"}
      directed={false}
    />
  );
}


export function HazardsView(props: { 
  databaseQuery : DatabaseQuery<HazardIF>,
  title? : string,
  featuredProperties?: string[],
  highlightedPropertyKey?: string }) {

  return ( <DatabaseView 
    databaseQuery={props.databaseQuery} 
    title={props.title}
    featuredProperties={props.featuredProperties != null ? props.featuredProperties : defaultHazardTableColumns }
    highlightedPropertyKey={props.highlightedPropertyKey != null ? props.highlightedPropertyKey : defaultHighlightedHazardProperty}
    hideDateFilter={true}
    defaultDatabaseViewType={DatabaseViewTypes.Table as DatabaseViewType}
    /> );
}


export function HazardView( props : DocumentProps ) {

  const HazardBasicsFilter = {

    includePropertyKeys : ["hazardType",
                          "description", 
                           "seriousSymptoms",
                           "commonSymptoms",
                           "lessCommonSymptoms",
                           "variants",
                           "attachments",
                           "links"
                          ],
    
  } as PropertiesSelector; 
  
  
  
  const HazardRemediesFilter = {
  
    includePropertyKeys : ["testTypes","vaccines","medications","treatments"],
    
  } as PropertiesSelector;

  const defaultHazardSteps = () : Map<string,PropertiesSelector> => {
  
    let defaultSteps = new Map<string,PropertiesSelector>();
  
    defaultSteps.set( "basics", HazardBasicsFilter );
    defaultSteps.set( "remedies", HazardRemediesFilter );
  
    return defaultSteps;
  }
  
  return ( <DocumentView 
    {...props}
    inputTabs={props.inputTabs != null ? props.inputTabs : defaultHazardSteps()}/> );
}



