
import { Hazard } from "./hazard";
import { Factory } from "../../../services/common/api/factory";
import { MeasureIF } from "../../api/documents/measureIF";
import { Category } from "../../../services/database/impl/documents/category";
import { log } from "../../../services/database/framework/databaseService";
import { CollectionDatabase } from "../../../services/database/impl/core/collectionDatabase";
import { DatabaseDocument } from "../../../services/database/framework/databaseDocument";
import { NumberProperty } from "../../../services/database/impl/properties/numberProperty";
import { ReferencesProperty } from "../../../services/database/impl/properties/referencesProperty";
import { ConsentsCollection, GatheringsCollection, HazardsCollection, RisksCollection } from "../../api/healthguardCollections";
import { TenantProperty } from "../../../services/database/impl/properties/tenantProperty";
import { AlertsCollection, CategoriesCollection, CompaniesCollection, DevicesCollection, LocationsCollection, ProjectsCollection, UnitsCollection, UsersCollection } from "../../../services/database/api/collections";
import { HealthguardCompanyDocument, HealthguardUnitDocument, MeasureDocument } from "../../api/healthguardDocuments";
import { OwnerProperty } from "../../../services/database/impl/properties/ownerProperty";
import { HealthguardCompany } from "./healthguardCompany";
import { HealthguardUnit } from "./healthguardUnit";
import { HealthguardUser } from "./healthguardUser";
import { HealthguardProject } from "./healthguardProject";
import { BooleanProperty } from "../../../services/database/impl/properties/booleanProperty";
import { HealthguardLocation } from "./healthguardLocation";
import { IncidentRegistration } from "./incidentRegistration";
import { QuarantineRegistration } from "./quarantineRegistration";
import { SickLeaveRegistration } from "./sickLeaveRegistration";
import { SymptomRegistration } from "./symptomRegistration";
import { TestRegistration } from "./testRegistration";
import { VaccineRegistration } from "./vaccineRegistration";
import { HealthguardCategory } from "./healthguardCategory";
import { MeasureState, MeasureStates } from "../../api/definitions/measureState";
import { DefinitionProperty } from "../../../services/database/impl/properties/definitionProperty";
import { MeasureStateDefinition, MeasureOriginDefinition, MeasureClassificationDefinition, MeasureTypeDefinition } from "../../api/healthguardDefinitions";
import { ReadinessLevel, ReadinessLevels } from "../../api/definitions/readinessLevel";
import { DefinitionsProperty } from "../../../services/database/impl/properties/definitionsProperty";
import { Consent } from "./consent";
import { MeasureManager } from "../managers/measureManager";
import { MeasureOrigin, MeasureOrigins } from "../../api/definitions/measureOrigin";
import { MeasureClassification, MeasureClassifications } from "../../api/definitions/measureClassification";
import { MeasureType, MeasureTypes } from "../../api/definitions/measureType";
import { TextProperty } from "../../../services/database/impl/properties/textProperty";
import { DefaultTextType} from "../../../services/database/api/definitions/textTypes";
import { Risk } from "./risk";
import { Gathering } from "./gathering";
import { HealthguardDevice } from "./healthguardDevice";
import { ReferenceProperty } from "../../../services/database/impl/properties/referenceProperty";
import { IncidentRegistrationsCollection, QuarantineRegistrationsCollection, SickLeaveRegistrationsCollection, SymptomRegistrationsCollection, TestRegistrationsCollection, VaccineRegistrationsCollection } from "../../api/registrationCollections";
import { ReadinessLevelDefinition } from "../../../services/database/api/definitions";
import { HealthguardAlert } from "./healthguardAlert";
import { Database } from "../../../services/database/impl/core/database";
import { CollectionGroupDatabase } from "../../../services/database/impl/core/collectionGroupDatabase";


export class Measure extends DatabaseDocument implements MeasureIF {

    constructor( measuresCollection : CollectionDatabase<Measure>, documentPath? : string  ) {

        super( MeasureDocument, measuresCollection, documentPath );

        try {  
            this.title.required = true;
            this.description.required = true;
                      
            this.company = new TenantProperty<HealthguardCompany>( this, CompaniesCollection, HealthguardCompanyDocument );
            this.company.editable = false;

            const ownerCompany = this.company.emptyDocument() as HealthguardCompany;

            this.unit = new OwnerProperty<HealthguardUnit>( this, UnitsCollection, HealthguardUnitDocument );
            
            const ownerUnit = this.unit.emptyDocument() as HealthguardUnit;

            this.responsible = new ReferenceProperty<HealthguardUser>( this, 
                () => this.parentDatabases( UsersCollection, {nearestIsCollectionGroup: ownerUnit == null} ) as Database<HealthguardUser>[],
                "responsibleFor" ); 

            this.responsible.required = true;

            this.hazards = new ReferencesProperty<Hazard>(  
                this, 
                ownerCompany != null ? 
                    () => [ownerCompany.hazards.collection(),ownerCompany.publicHazards.documentsDatabase()] : 
                    () => [Factory.get().databaseService.databaseFactory.collectionDatabaseFromCollectionName( 
                        HazardsCollection ) as CollectionDatabase<Hazard>]
            );
            
            this.hazards.editable = false;

            this.readinessLevels = new DefinitionsProperty<ReadinessLevel>( this, ReadinessLevelDefinition, ReadinessLevels );

            this.measureClassification = new DefinitionProperty<MeasureClassification>( this, 
                MeasureClassificationDefinition, 
                MeasureClassifications ); 

            this.measureState = new DefinitionProperty<MeasureState>( this, 
                MeasureStateDefinition, 
                MeasureStates,
                MeasureStates.Ongoing as MeasureState );
            this.measureState.required = true;

            this.measureOrigin = new DefinitionProperty<MeasureOrigin>( this, 
                MeasureOriginDefinition, 
                MeasureOrigins );

            this.measureType = new DefinitionProperty<MeasureType>( this, 
                MeasureTypeDefinition, 
                MeasureTypes );
            this.measureType.required = true;

            this.purpose = new TextProperty(this, DefaultTextType, true ); 
            this.purpose.required = true;

            this.risk = new ReferenceProperty<Risk>( this, 
                () => this.parentDatabases( RisksCollection, {nearestIsCollectionGroup: false} ) as Database<Risk>[],
                "measures" ); 

            this.risk.required = true;

            this.consents = new ReferencesProperty<Consent>( this, 
                () => [this.parentCollectionGroup( ConsentsCollection ) as CollectionGroupDatabase<Consent>],
                "measure" ); 

            this.alerts = new ReferencesProperty<HealthguardAlert>( this,  
                () => [this.parentCollectionGroup( AlertsCollection ) as CollectionGroupDatabase<HealthguardAlert>],
                "measures" );

            this.incidentRegistrations = new ReferencesProperty<IncidentRegistration>( this, 
                () => [this.parentCollectionGroup( IncidentRegistrationsCollection ) as CollectionGroupDatabase<IncidentRegistration>],
                "measures" );

            this.quarantineRegistrations = new ReferencesProperty<QuarantineRegistration>( this, 
                () => [this.parentCollectionGroup( QuarantineRegistrationsCollection ) as CollectionGroupDatabase<QuarantineRegistration>],
                "measures" );

            this.sickLeaveRegistrations = new ReferencesProperty<SickLeaveRegistration>( this, 
                () => [this.parentCollectionGroup( SickLeaveRegistrationsCollection ) as CollectionGroupDatabase<SickLeaveRegistration>],
                "measures" );

            this.symptomRegistrations = new ReferencesProperty<SymptomRegistration>( this, 
                () => [this.parentCollectionGroup( SymptomRegistrationsCollection ) as CollectionGroupDatabase<SymptomRegistration>],
                "measures" ); 

            this.testRegistrations = new ReferencesProperty<TestRegistration>( this, 
                () => [this.parentCollectionGroup( TestRegistrationsCollection ) as CollectionGroupDatabase<TestRegistration>],
                "measures" );

            this.vaccineRegistrations = new ReferencesProperty<VaccineRegistration>( this, 
                () => [this.parentCollectionGroup( VaccineRegistrationsCollection ) as CollectionGroupDatabase<VaccineRegistration>],
                "measures" );

            this.reducedR = new NumberProperty( this, undefined, 0 );

            this.allUsers = new BooleanProperty( this );

            this.units = new ReferencesProperty<HealthguardUnit>( this, 
                () => [this.parentCollectionGroup( UnitsCollection ) as CollectionGroupDatabase<HealthguardUnit>]
            );

            this.projects = new ReferencesProperty<HealthguardProject>( this, 
                () => [this.parentCollectionGroup( ProjectsCollection ) as CollectionGroupDatabase<HealthguardProject>],
                "measures" ); 

            this.categories = new ReferencesProperty<HealthguardCategory>( this, 
                () => this.parentDatabases( CategoriesCollection, {nearestIsCollectionGroup: false} ) as Database<HealthguardCategory>[],
                "measures" );

            this.gatherings = new ReferencesProperty<Gathering>( this, 
                () => this.parentDatabases( GatheringsCollection, {nearestIsCollectionGroup: true} ) as Database<Gathering>[],
                "measures" );

            this.locations = new ReferencesProperty<HealthguardLocation>( this, 
                () => this.parentDatabases( LocationsCollection, {nearestIsCollectionGroup: true} ) as Database<HealthguardLocation>[],
                "measures" );

            this.devices = new ReferencesProperty<HealthguardDevice>( this, 
                () => this.parentDatabases( DevicesCollection, {nearestIsCollectionGroup: true} ) as Database<HealthguardDevice>[],
                "measures" );

            this.users = new ReferencesProperty<HealthguardUser>( this, 
                () => this.parentDatabases( UsersCollection, {nearestIsCollectionGroup: true} ) as Database<HealthguardUser>[],
                "measures" );

            //log.traceInOut( "constructor()", MeasuresCollection );

        } catch( error ) {

            log.warn( "constructor()", "Error initializing measure", error );
            
            throw new Error( (error as any).message );
        }
    }

    indexKey() : string {
        return MeasureDocument;
    }

    referenceDateProperty() {
        return undefined;
    }

    async updateUsers() : Promise<void> {

        await MeasureManager.getInstance().updateMeasureUsers( this );
    }


    async onCreate() : Promise<void> {

        //log.traceIn( "onCreated()" );

        try {

            await super.onCreate();

            await MeasureManager.getInstance().handleCreateMeasure( this );
    
            //log.traceOut( "onCreated()" );
  
        } catch( error ) {
            
            log.warn( "onCreated()", "Error handling created notification", error );
  
            throw new Error( (error as any).message );
        }
    }

    async onUpdate() : Promise<void> {

        //log.traceIn( "onUpdate()" );

        try {

            await super.onUpdate();

            await MeasureManager.getInstance().handleUpdateMeasure( this );
    
            //log.traceOut( "onUpdate()" );
  
        } catch( error ) {
            
            log.warn( "onUpdated()", "Error handling updated notification", error );
  
            throw new Error( (error as any).message );
        }
    }

    async onDelete() : Promise<void> {

        //log.traceIn( "onDelete()" );

        try {

            await super.onDelete();

            await MeasureManager.getInstance().handleDeleteMeasure( this );
    
            //log.traceOut( "onDelete()" );
  
        } catch( error ) {
            
            log.warn( "onDelete()", "Error handling deleted notification", error );
  
            throw new Error( (error as any).message );
        }
    }

    readonly company: TenantProperty<HealthguardCompany>;

    readonly unit: OwnerProperty<HealthguardUnit>;

    readonly responsible: ReferenceProperty<HealthguardUser>;

    readonly hazards: ReferencesProperty<Hazard>;

    readonly readinessLevels : DefinitionsProperty<ReadinessLevel>;

    readonly measureClassification : DefinitionProperty<MeasureClassification>;

    readonly measureState : DefinitionProperty<MeasureState>;

    readonly measureOrigin : DefinitionProperty<MeasureOrigin>;

    readonly measureType : DefinitionProperty<MeasureType>;

    readonly purpose : TextProperty;

    readonly risk : ReferenceProperty<Risk>;

    readonly consents: ReferencesProperty<Consent>;

    readonly alerts: ReferencesProperty<HealthguardAlert>;

    readonly incidentRegistrations: ReferencesProperty<IncidentRegistration>;

    readonly quarantineRegistrations : ReferencesProperty<QuarantineRegistration>;

    readonly sickLeaveRegistrations: ReferencesProperty<SickLeaveRegistration>;

    readonly symptomRegistrations: ReferencesProperty<SymptomRegistration>;

    readonly testRegistrations: ReferencesProperty<TestRegistration>;

    readonly vaccineRegistrations: ReferencesProperty<VaccineRegistration>; 

    readonly reducedR : NumberProperty;

    readonly allUsers : BooleanProperty;

    readonly units : ReferencesProperty<HealthguardUnit>;

    readonly projects : ReferencesProperty<HealthguardProject>;

    readonly categories : ReferencesProperty<Category>;

    readonly gatherings : ReferencesProperty<Gathering>;

    readonly locations : ReferencesProperty<HealthguardLocation>; 

    readonly devices : ReferencesProperty<HealthguardDevice>; 

    readonly users : ReferencesProperty<HealthguardUser>

}
