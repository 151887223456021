import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { CssBaseline, ThemeProvider } from '@material-ui/core';

import { CookiesProvider } from 'react-cookie';

import { positions, Provider as AlertProvider} from "react-alert";
import AlertMUITemplate from "react-alert-template-mui";

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import App from './app';
import * as serviceWorker from './serviceWorker';

import './localization';
import theme from './theme';
import { BrowserRouter } from 'react-router-dom';
import Loading from 'ui/components/loading';
import { I18nextProvider } from 'react-i18next';
import i18n from './localization';


const alertOptions = {
  position: positions.MIDDLE
} as any;


ReactDOM.render(
  //<React.StrictMode> // causes double rendering of app in development mode
  <CookiesProvider>
    <ThemeProvider theme={theme}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      <Suspense fallback={<Loading showLogo={true} />}>
        <BrowserRouter>
          <I18nextProvider i18n={i18n}>
            <AlertProvider template={AlertMUITemplate} {...alertOptions}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <App />
              </MuiPickersUtilsProvider>
            </AlertProvider>
          </I18nextProvider>
        </BrowserRouter>
      </Suspense>
    </ThemeProvider>
  </CookiesProvider>
  //</React.StrictMode>
  , document.getElementById('root')
);

serviceWorker.unregister();