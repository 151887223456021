
import { Language } from "../../database/api/definitions/language";

export enum TranslationKey {
    New               = "new",
    Collection        = "collection",
    MyCollection      = "myCollection",
    Document          = "document",
    MyDocument        = "myDocument",
    Subdocument       = "subdocument", 
    MySubdocument     = "mySubdocument",
    Definitions       = "definitions",
    Definition        = "definition",
    Keys              = "keys",
    Values            = "values",
    Prompts           = "prompts", 
    Errors            = "errors",
    Policies          = "policies",
    Registration      = "registration",
    Contactable       = "contactable"
  } 
  
export interface TranslatorIF  {

    loadTranslations( namespace : string, language? : Language ) : Promise<any>,

    translate( key? : string, translations? : any, language? : Language ) : string;

    defaultLanguage() : Language;

    setDefaultLanguage( defaultLanguage : Language ) : void,
}

