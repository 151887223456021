
import { GatheringIF } from "../../api/documents/gatheringIF";
import { IncidentRegistration } from "./incidentRegistration";
import { TestRegistration } from "./testRegistration";
import { log } from "../../../services/database/framework/databaseService";
import { CollectionDatabase } from "../../../services/database/impl/core/collectionDatabase";
import { DatabaseDocument } from "../../../services/database/framework/databaseDocument";
import { NumberProperty } from "../../../services/database/impl/properties/numberProperty";
import { ReferenceProperty } from "../../../services/database/impl/properties/referenceProperty";
import { ReferencesProperty } from "../../../services/database/impl/properties/referencesProperty";
import { MeasuresCollection, RisksCollection } from "../../api/healthguardCollections";
import { HealthguardCompany } from "./healthguardCompany";
import { TenantProperty } from "../../../services/database/impl/properties/tenantProperty";
import { AlertsCollection, CategoriesCollection, CompaniesCollection, LocationsCollection, ProjectsCollection, UnitsCollection, UsersCollection } from "../../../services/database/api/collections";
import { GatheringDocument, HealthguardCompanyDocument, HealthguardUnitDocument } from "../../api/healthguardDocuments";
import { HealthguardUser } from "./healthguardUser";
import { CollectionGroupDatabase } from "../../../services/database/impl/core/collectionGroupDatabase";
import { HealthguardUnit } from "./healthguardUnit";
import { OwnerProperty } from "../../../services/database/impl/properties/ownerProperty";
import { HealthguardLocation } from "./healthguardLocation";
import { HealthguardProject } from "./healthguardProject";
import { BooleanProperty } from "../../../services/database/impl/properties/booleanProperty";
import { UserManager } from "../../../services/database/impl/managers/userManager";
import { DateProperty } from "../../../services/database/impl/properties/dateProperty";
import { GatheringManager } from "../managers/gatheringManager";
import { Risk } from "./risk";
import { Measure } from "./measure";
import { IncidentRegistrationsCollection, TestRegistrationsCollection } from "../../api/registrationCollections";
import { HealthguardCategory } from "./healthguardCategory";
import { HealthguardAlert } from "./healthguardAlert";
import { Database } from "../../../services/database/impl/core/database";


export class Gathering extends DatabaseDocument implements GatheringIF {

    constructor( gatheringsCollection : CollectionDatabase<Gathering>, documentPath? : string  ) {

        super( GatheringDocument, gatheringsCollection, documentPath );

        try {
            this.title.required = true;

            this.company = new TenantProperty<HealthguardCompany>( this, CompaniesCollection, HealthguardCompanyDocument );
            this.company.required = true;
            this.company.editable = false;

            this.unit = new OwnerProperty<HealthguardUnit>( this, UnitsCollection, HealthguardUnitDocument );
                        
            this.startDate.required = true;
    
            this.host = new ReferenceProperty<HealthguardUser>( this, 
                () => this.parentDatabases( UsersCollection, {nearestIsCollectionGroup: true} ) as Database<HealthguardUser>[],
                "gatherings" );

            this.location = new ReferenceProperty<HealthguardLocation>( this, 
                () => this.parentDatabases( LocationsCollection, {nearestIsCollectionGroup: false} ) as Database<HealthguardLocation>[],
                "gatherings" );

            this.maxUsers = new NumberProperty( this, undefined, 0 );

            this.trackUsersFrom = new DateProperty( this );

            this.trackUsersTo = new DateProperty( this );

            this.allUsers = new BooleanProperty( this );

            this.units = new ReferencesProperty<HealthguardUnit>( this, 
                () => [this.parentCollectionGroup( UnitsCollection )! as CollectionGroupDatabase<HealthguardUnit>],
                "gatherings",
                );

            this.projects = new ReferencesProperty<HealthguardProject>( this,
                () => [this.parentCollectionGroup( ProjectsCollection ) as CollectionGroupDatabase<HealthguardProject>],
                "gatherings",
            );

            this.categories = new ReferencesProperty<HealthguardCategory>( this, 
                () => [this.parentCollectionGroup( CategoriesCollection ) as CollectionGroupDatabase<HealthguardCategory>]
            );

            this.users = new ReferencesProperty<HealthguardUser>( this, 
                () => [this.parentCollectionGroup( UsersCollection ) as CollectionGroupDatabase<HealthguardUser>], 
                "gatherings" );

            this.risks = new ReferencesProperty<Risk>( this, 
                () => [this.parentCollectionGroup( RisksCollection ) as CollectionGroupDatabase<Risk>], 
                "gatherings" );

            this.measures = new ReferencesProperty<Measure>( this, 
                () => [this.parentCollectionGroup( MeasuresCollection ) as CollectionGroupDatabase<Measure>], 
                "gatherings" );

            this.alerts = new ReferencesProperty<HealthguardAlert>( this, 
                () => [this.parentCollectionGroup( AlertsCollection ) as CollectionGroupDatabase<HealthguardAlert>], 
                "gatherings" );

            this.incidentRegistrations = new ReferencesProperty<IncidentRegistration>( this, 
                () => [this.parentCollectionGroup( IncidentRegistrationsCollection ) as CollectionGroupDatabase<IncidentRegistration>], 
                "gatherings" );

            this.testRegistrations = new ReferencesProperty<TestRegistration>( this, 
                () => [this.parentCollectionGroup( TestRegistrationsCollection ) as CollectionGroupDatabase<TestRegistration>], 
                "gatherings" );

            //log.traceInOut( "constructor()", SpreaderEventsCollection );

        } catch( error ) {

            log.warn( "constructor()", "Error initializing gathering", error );
            
            throw new Error( (error as any).message );
        }
    }

    indexKey() : string {
        return GatheringDocument;
    }

    async updateUsers() : Promise<void> {

        UserManager.getInstance().updateDocumentUsers( this );
    }

    updateTrackingDates() : Promise<void> {

        return GatheringManager.getInstance().updateTrackingDates( this );

    }


    async onCreate() : Promise<void> {

        //log.traceIn( "onCreate()" );

        try {

            await super.onCreate();

            await this.updateTrackingDates();
    
            //log.traceOut( "onCreate()" );
  
        } catch( error ) {
            
            log.warn( "onCreated()", "Error handling created notification", error );
  
            throw new Error( (error as any).message );
        }
    }

    async onUpdate() : Promise<void> {

        //log.traceIn( "onUpdate()" );

        try {

            await super.onUpdate();

            await this.updateTrackingDates();
    
            //log.traceOut( "onUpdate()" );
  
        } catch( error ) {
            
            log.warn( "onUpdated()", "Error handling updated notification", error );
  
            throw new Error( (error as any).message );
        }
    }


    readonly company: TenantProperty<HealthguardCompany>;

    readonly unit: OwnerProperty<HealthguardUnit>;

    readonly host: ReferenceProperty<HealthguardUser>;

    readonly location : ReferenceProperty<HealthguardLocation>;

    readonly allUsers : BooleanProperty;

    readonly units : ReferencesProperty<HealthguardUnit>;

    readonly projects : ReferencesProperty<HealthguardProject>;

    readonly categories : ReferencesProperty<HealthguardCategory>;

    readonly maxUsers : NumberProperty;

    readonly trackUsersFrom: DateProperty;

    readonly trackUsersTo: DateProperty;

    readonly risks: ReferencesProperty<Risk>;

    readonly measures: ReferencesProperty<Measure>;

    readonly users: ReferencesProperty<HealthguardUser>;

    readonly alerts : ReferencesProperty<HealthguardAlert>;

    readonly incidentRegistrations : ReferencesProperty<IncidentRegistration>;

    readonly testRegistrations : ReferencesProperty<TestRegistration>; 
}
