import React from 'react';

import DocumentView, { DocumentProps } from '../components/documentView';

import { PropertiesSelector } from 'services/database/api/core/propertiesSelector';

import DatabaseTable, { DatabaseTableProps } from 'ui/components/databaseTable';
import DatabaseList, { DatabaseListProps } from 'ui/components/databaseList';
import DatabaseCard, { DatabaseCardProps } from 'ui/components/databaseCard';
import DatabaseChart, { DatabaseChartProps } from 'ui/components/databaseChart';
import DatabaseGraph, { DatabaseGraphProps } from 'ui/components/databaseGraph';

import DatabaseView  from '../components/databaseView';

import { CompanyDocument } from '../../services/database/api/documents';
import { DatabaseQuery } from '../../services/database/api/core/databaseQuery';

import { DatabaseViewType, DatabaseViewTypes } from '../components/databaseViewMenu';

import { KeyIF } from '../../services/database/api/documents/keyIF';

export const defaultKeyTableColumns = [ 
  CompanyDocument,
  "version",
  "keyType",
  "keyFormat",
  "keyStatus",
  "startDate",
  "endDate"
];


export const defaultHighlightedKeysProperty = "keyResponse";

export function KeysTable(props: DatabaseTableProps ) {

  return (
    <DatabaseTable 
      {...props} 
      featuredProperties={props.featuredProperties != null ? props.featuredProperties : defaultKeyTableColumns }
      />
  );
}

export function KeysList(props: DatabaseListProps ) {

  return (
    <DatabaseList {...props} />
  );
}

export function KeysCard( props: DatabaseCardProps ) {
  return (
    <DatabaseCard 
      {...props} 
      highlightedPropertyKey={props.highlightedPropertyKey != null ? 
        props.highlightedPropertyKey : 
        defaultHighlightedKeysProperty}
    />
  ); 
} 

export function KeysChart( props: DatabaseChartProps ) {
  return (
    <DatabaseChart 
      {...props} 
    />
  );
}

export function KeysGraph( props: DatabaseGraphProps ) {
  return (
    <DatabaseGraph 
      {...props} 
      previousPropertyKey={"keyVault"}
      nextPropertyKey={"keyType"}
      directed={false}
    />
  );
}

export function KeysView(props: { 
  databaseQuery : DatabaseQuery<KeyIF>,
  title? : string,
  featuredProperties?: string[],
  highlightedPropertyKey?: string }) {

  return ( 
    <DatabaseView
     databaseQuery={props.databaseQuery} 
     title={props.title}
     featuredProperties={props.featuredProperties != null ? props.featuredProperties : defaultKeyTableColumns }
     highlightedPropertyKey={props.highlightedPropertyKey != null ? 
      props.highlightedPropertyKey : 
      defaultHighlightedKeysProperty}
      defaultDatabaseViewType={DatabaseViewTypes.Table as DatabaseViewType}
    /> 
  );
}


export function KeyView( props : DocumentProps ) {

  const KeyBasicsFilter = {

    includePropertyKeys: [
      "keyVault",
      "keyType",
      "version",
      "keyFormat",
      "keyStatus"
    ]

  } as PropertiesSelector;

  const KeyValuesFilter = {

    includePropertyKeys: [
      "publicKey",
      "secretKey"
    ]

  } as PropertiesSelector;



  const defaultKeySteps = (): Map<string, PropertiesSelector> => {

    let defaultSteps = new Map<string, PropertiesSelector>();

    defaultSteps.set("basics", KeyBasicsFilter);
    defaultSteps.set("values", KeyValuesFilter);

    return defaultSteps;
  }


  return (<DocumentView 
    {...props}
    inputTabs={props.inputTabs != null ? props.inputTabs : defaultKeySteps()} 
  />); 
}

