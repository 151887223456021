
import { log } from "../../../services/database/framework/databaseService";
import { HealthguardDevice } from "../documents/healthguardDevice";
import { Geolocation } from "../../../services/database/api/properties/geolocationPropertyIF"
import { Device } from "../../../services/database/impl/documents/device";
import { Severity } from "../../../services/database/api/definitions/severity";
import { HealthguardCompany } from "../documents/healthguardCompany";
import { HealthguardUnit } from "../documents/healthguardUnit";
import { HealthguardAlert } from "../documents/healthguardAlert";
import { ReferenceHandle } from "../../../services/database/api/core/referenceHandle";


export class HealthguardDeviceManager {

    static getInstance() : HealthguardDeviceManager {
        if( HealthguardDeviceManager._instance == null ) {
            HealthguardDeviceManager._instance = new HealthguardDeviceManager();
        }
        return HealthguardDeviceManager._instance;
    }


    async handleCreateHealthguardDevice( createdHealthguardDevice: HealthguardDevice ) {

        try {
            log.traceIn("handleCreateHealthguardDevice()", createdHealthguardDevice.title.value() );

            log.traceOut("handleCreateHealthguardDevice()");

        } catch (error) {
            log.warn("Error handling document create", error);

            log.traceOut("handleHealthguardDeviceCreated()", "error");
        }
    }


    async handleUpdateHealthguardDevice( updatedHealthguardDevice: HealthguardDevice ) {

        try {
            log.traceIn("handleUpdateHealthguardDevice()", updatedHealthguardDevice.title.value() );

            log.traceOut("handleUpdateHealthguardDevice()");

        } catch (error) {
            log.warn("Error handling document update", error);

            log.traceOut("handleHealthguardDeviceUpdated()", "error");
        }
    } 

    async handleDeleteHealthguardDevice( deletedHealthguardDevice: HealthguardDevice ) {

        try {
            log.traceIn("handleDeleteHealthguardDevice()", deletedHealthguardDevice.title.value() );

            log.traceOut("handleDeleteHealthguardDevice()");

        } catch (error) {
            log.warn("Error handling document update", error);

            log.traceOut("handleHealthguardDeviceUpdated()", "error");
        }
    } 


    async updateDeviceGeolocation( device: Device, geolocation? : Geolocation  ) {

        try {
            log.traceIn(" updateDeviceGeolocation()", device.databasePath() );

            const currentValue = device.geolocation.value();

            if( currentValue == null && geolocation != null ) {

                log.debug("updateDeviceGeolocation()", device.databasePath( true ), {geolocation} );

                device.geolocation.setValue( geolocation );
                
                await device.update();
            }
            
            log.traceOut("updateDeviceDefaultGeolocation()");

        } catch (error) {
            log.warn("Error handling device location", error);

            log.traceOut("updateDeviceDefaultGeolocation()", "error");
        }
    } 

    async generateDeviceAlert( healthguardDevice: HealthguardDevice, severity? : Severity ) {

        try {
            log.traceIn("generateDeviceAlert()", healthguardDevice.title.value() );

            const ownerCompany = healthguardDevice.company.emptyDocument() as HealthguardCompany;

            const ownerUnit = healthguardDevice.unit.emptyDocument() as HealthguardUnit;

            const owner = ownerUnit != null ? ownerUnit : ownerCompany;

            const alert = owner.alerts.collection().newDocument() as HealthguardAlert;  

            const title = healthguardDevice.title.value() + " - " + healthguardDevice.startDate.value()!.toLocaleDateString();

            alert.title.setValue( title )

            alert.severity.setValue( severity );
    
            alert.lastChangedBy.setValue( healthguardDevice.lastChangedBy.value() );

            alert.lastChangedAt.setValue( healthguardDevice.lastChangedAt.value() );

            alert.location.setValue( healthguardDevice.location.value() );

            alert.premise.setValue( healthguardDevice.premise.value() );

            alert.hazard.setValue( healthguardDevice.hazard.value() );

            alert.variant.setValue( healthguardDevice.variant.value() );

            alert.deviceStatus.setValue( healthguardDevice.status.value() );

            alert.device.setValue( healthguardDevice.referenceHandle() as ReferenceHandle<Device> ); 

            alert.measures.setValue( healthguardDevice.measures.value() );

            alert.geolocation.setValue( healthguardDevice.geolocation.value() ); 

            await alert.create();

            log.traceOut("generateDeviceAlert()");

        } catch (error) {
            log.warn("Error generating device alert", error);

        }
    }


    private static _instance? : HealthguardDeviceManager; 

}

