
import { DatabaseObject } from "../../framework/databaseObject";
import { log } from "../../framework/databaseService";
import { DatabaseSubdocument } from "../../framework/databaseSubdocument";
import { ContactabilityIF } from "../../api/subdocuments/contactabilityIF";
import { ContactabilitySubdocument } from "../../api/subdocuments";
import { BooleanProperty } from "../properties/booleanProperty";

export class Contactability extends DatabaseSubdocument implements ContactabilityIF {

    constructor( parent : DatabaseObject, key : string ) {

        super( ContactabilitySubdocument, parent, key );

        try {

            this.email = new BooleanProperty( this );

            this.sms = new BooleanProperty( this );

            this.push = new BooleanProperty( this ); 
            
            //log.traceInOut( "constructor()", parent.title, key );

        } catch( error ) {

            log.warn( "constructor()", "Error initializing settings data", error );
            
            throw new Error( (error as any).message );
        }
    }

    readonly email : BooleanProperty;

    readonly sms : BooleanProperty;

    readonly push : BooleanProperty;

 }
