
import { PropertyType, PropertyTypes } from "../../api/definitions/propertyType"; 
import { SubdocumentPropertyIF } from "../../api/properties/subdocumentPropertyIF";
import { DatabaseObject } from "../../framework/databaseObject";
import { DatabaseProperty } from "../../framework/databaseProperty";
import { DatabaseSubdocument } from "../../framework/databaseSubdocument";

export class SubdocumentProperty<DerivedSubdocument extends DatabaseSubdocument> 
    extends DatabaseProperty<DerivedSubdocument> implements SubdocumentPropertyIF<DerivedSubdocument> {

    constructor( parent : DatabaseObject, subdocument : DerivedSubdocument ) { 
        super( PropertyTypes.Subdocument as PropertyType, parent ); 

        this._subdocument = subdocument;
    }

    value() {
        return undefined;
    }

    setValue( value : any | undefined ) : void {
        throw new Error( "Unsupported");
    }

    subdocument() : DerivedSubdocument {
        return this._subdocument;
    }

    fromData( documentData: any): void {

        const data = documentData[this.key()];

        this._subdocument.fromData( data );
    }

    async toData( documentData: any, force? : boolean ) : Promise<void> {
        documentData[this.key()] = await this._subdocument.toData( force );
    }

    compareTo( other : SubdocumentProperty<DerivedSubdocument> ) : number {

        return 0;
    }

    compareValue( value : DerivedSubdocument | undefined ) : number {

        return 0;
    }

    includes( other : SubdocumentProperty<DerivedSubdocument>  ) : boolean {
        return this.includesValue( other.value() );
    }

    includesValue( value : DerivedSubdocument | undefined ) : boolean {
        return this.compareValue( value ) === 0;
    }

    protected readonly _subdocument : DerivedSubdocument;

}