
import { DatabaseObject } from "../../framework/databaseObject";
import { DatabaseDocument } from "../../framework/databaseDocument";
import { BasicProperty } from "./basicProperty";
import { OptionsSource } from "../core/optionsSource";
import { PropertyType, PropertyTypes } from "../../api/definitions/propertyType";
import { TextPropertyIF } from "../../api/properties/textPropertyIF";
import { DefaultTextType, TextType } from "../../api/definitions/textTypes";
import { log } from "../../framework/databaseService";
import { TextsProperty } from "./textsProperty";

export class TextProperty extends BasicProperty<string> implements TextPropertyIF {

    constructor( parent : DatabaseObject, textType? : TextType, multiline? : boolean, defaultValue? : string ) {
        super( PropertyTypes.Text as PropertyType, parent, defaultValue ); 

        this.textType = textType != null ? textType : DefaultTextType;

        this.multiline = multiline; 
    }

    setOptionsSource( optionsSource :  OptionsSource<DatabaseDocument,TextsProperty> ) {
        this._optionsSource = optionsSource;
    }  

    requiresSelect() : boolean {
        return this._optionsSource != null;
    }

    setValue( value : string | undefined ): void {

        if( value != null && typeof value !== "string" ) {
            throw new Error( "Value is not a string: " + value );
        }

        super.setValue( value ); 
    }

    async select( filterValue? : boolean ) : Promise<Map<string,string> | undefined> { 

        log.traceIn( "select()" );
    
        const value = this.value();

        const optionsProperty = this._optionsSource != null ? 
            await this._optionsSource.optionsProperty() : 
            undefined;

        if( optionsProperty == null ) {

            log.traceOut( "select()", "no options source" );
            return undefined;    
        }

        const options = await optionsProperty.options();

        if( options == null ) {

            log.traceOut( "select()", "no options" );
            return undefined;    
        }

        const result = new Map<string,string>();

        for( const option of options.values() ) {

            if( !!filterValue && value != null && value === option ) {
                continue;
            }
            result.set( option, option );
        }

        log.traceOut( "select()", result.size );
        return result.size === 0 ? undefined : result;  
    }


    compareTo( other : TextProperty ) : number {

       return this.compareValue( other.value() );
    }

    compareValue( otherValue : string | undefined ) : number {

        const value = this.value();

        if( value == null && otherValue == null ) {
            return 0;
        }

        if( value != null && otherValue == null ) {
            return 1;
        }

        if( value == null && otherValue != null ) {
            return -1;
        }

        return this.value()!.toString().localeCompare( otherValue! );
    }

    includes( other : TextProperty ) : boolean {
        return this.includesValue( other.value() );
    }

    includesValue( otherValue : string | undefined ) : boolean {

        const value = this.value();

        return value != null && otherValue != null && value.includes( otherValue ); 
    }

    readonly textType : TextType;

    readonly multiline? : boolean;

    private _optionsSource : OptionsSource<DatabaseDocument,TextsProperty> | undefined;
}