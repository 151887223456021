
import { log } from "../../framework/databaseService";
import { Alert } from "../documents/alert";


export class AlertManager {

    static getInstance() : AlertManager {
        if( AlertManager._instance == null ) {
            AlertManager._instance = new AlertManager();
        }
        return AlertManager._instance;
    }


    async handleCreateAlert( createdAlert: Alert ) {

        try {
            log.traceIn("handleCreateAlert()", createdAlert.title.value() );

            await this.updateAlertGeolocation( createdAlert );

            log.traceOut("handleCreateAlert()");

        } catch (error) {
            log.warn("Error handling alert create", error);
        }
    }


    async handleUpdateAlert( updatedAlert: Alert ) {

        try {
            log.traceIn("handleUpdateAlert()", updatedAlert.title.value() );

            await this.updateAlertGeolocation( updatedAlert );

            log.traceOut("handleUpdateAlert()");

        } catch (error) {
            log.warn("Error handling alert update", error);

        }
    } 

    async handleDeleteAlert( deletedAlert: Alert ) {

        try {
            log.traceIn("handleDeleteAlert()", deletedAlert.title.value() );

            log.traceOut("handleDeleteAlert()");

        } catch (error) {
            log.warn("Error handling document update", error);
        }
    } 

    async updateAlertGeolocation( alert: Alert ) {

        try {
            log.traceIn("updateAlertGeolocation()", alert.databasePath() );

            const currentValue = alert.geolocation.value();

            if( currentValue != null ) {
                log.traceOut("updateAlertGeolocation()", "already set", {currentValue});
                return;
            }

            const device = await alert.device.document();

            let geolocation = device?.geolocation.value();

            if( geolocation == null ) {
                const location = await alert.location.document();

                geolocation = location?.geolocation.value();
            }

            log.debug("updateAlertGeolocation()", {geolocation});

            alert.geolocation.setValue( geolocation );
            
            log.traceOut("updateAlertGeolocation()");

        } catch (error) {
            log.warn("Error handling alert location", error);
        }
    } 


    private static _instance? : AlertManager; 

}

