import * as React from 'react'
import { Box, Button, Fab, Grid, IconButton } from '@material-ui/core';
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';

import { withTranslation, WithTranslation } from 'react-i18next';

import CloseIconFilled from '@material-ui/icons/Close';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

import { log } from 'ui/app/app';

import { MessageIF } from '../../services/database/api/documents/messageIF';
import PropertyValue, { PropertyDisplayMode } from '../components/propertyValue';
import { AppContext } from '../app/appContext';
import { MessageBody } from '../../services/messaging/api/messageBody';
import { documentDialog } from '../components/documentDialog';
import { errorDialog } from '../components/simpleDialog';
import { DocumentProps } from '../components/documentView';


const styles = (theme: Theme) => createStyles({
  root: {
    height: "100%",
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  closeButton: {
    position: 'absolute',
    right : theme.spacing(2),
    marginTop: theme.spacing(0.5),
    zIndex: 999
  },
  content : {
    height: "100%",
    flexWrap: "nowrap"
  },
  properties : {
    paddingLeft: theme.spacing(1), 
    paddingRight: theme.spacing(1) 
  },
  body : {
    height: "100%",
    paddingLeft: theme.spacing(4), 
    paddingRight: theme.spacing(4),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    flexGrow: 1 
  }
});

export interface MessageReaderViewProps extends DocumentProps, WithStyles<typeof styles>, WithTranslation { 

}

interface MessageReaderViewState { // Component State
}


class MessageReaderView extends React.Component<MessageReaderViewProps,MessageReaderViewState> {

  constructor( props: MessageReaderViewProps ) {
    
    super(props);

    this.state = { 
    } as MessageReaderViewState;

    //log.traceInOut( "constructor()" );
  }

  async componentDidMount() {

    try {
      log.traceIn( "componentDidMount()" );

      log.traceOut( "componentDidMount()" );
    } catch( error ) {
      log.warn( "componentDidMount()", "Error mounting document view", error );
      
      log.traceOut( "componentDidMount()", "error" );
    }
  }


  render() {

    //log.traceInOut("render()" );

    const { classes } = this.props;

    const handleClose = async () => {
    
      log.traceIn( "handleClose()" );

      try {

        if( this.props.onComplete != null ) {
          this.props.onComplete();
        }

        log.traceOut("handleClose()");

      } catch( error ) {
        log.warn( "handleClose()", "Error creating new document", error );

        await errorDialog( error );
        
      }
    }

    const showDetails = async () => {

      log.traceIn( "showDetails()" );

      try {

        await documentDialog( this.props.databaseDocument! );

        log.traceOut("showDetails()");

      } catch( error ) {
        log.warn( "showDetails()", "Error creating new document", error );

        await errorDialog( error );
        
      }
    } 

    const message = this.props.databaseDocument as MessageIF;

    const subtitle = message.subtitle.value();

    const body = message.body.value() != null ? message.body.value()! : undefined;

    const messageBody = body != null && body.length > 0 ? new MessageBody( body ) : undefined;

    const links = message.links.value();

    return (
      <>
        <AppContext.Consumer>
          {appContext => (
            <Box className={classes.root} >
              <Fab aria-label="close" size="small" className={classes.closeButton} onClick={() => handleClose()}><CloseIconFilled /></Fab>
              <Grid
                className={classes.content}
                container
                direction="column"
                alignItems="stretch"
                spacing={0}>
                <Grid item container className={classes.properties} alignItems="center" spacing={2}>
                  <Grid item xs={4} sm={4} md={4}>
                    <PropertyValue property={message.startDate} displayMode={PropertyDisplayMode.Form} singleProperty={false} />
                  </Grid>
                  <Grid item xs={4} sm={4} md={4}>
                    <PropertyValue property={message.endDate} displayMode={PropertyDisplayMode.Form} singleProperty={false} />
                  </Grid>
                  <Grid item xs={2} sm={2} md={2}>
                    <IconButton  onClick={() => showDetails()}> 
                      <MoreHorizIcon />
                    </IconButton>
                  </Grid>
                  <Grid item xs={12} sm={11} md={10}>
                    <PropertyValue property={message.sender} displayMode={PropertyDisplayMode.Form} singleProperty={false} />
                  </Grid>
                  <Grid item xs={12} sm={11} md={10}>
                    <PropertyValue property={message.receivers} displayMode={PropertyDisplayMode.Form} singleProperty={false} />
                  </Grid>
                  <Grid item xs={12} sm={11} md={10}>
                    <PropertyValue property={message.title} displayMode={PropertyDisplayMode.Form} singleProperty={false} />
                  </Grid>
                  {message.attachments.value() != null &&
                    <Grid item xs={12} sm={11} md={10}>
                      <PropertyValue property={message.attachments} displayMode={PropertyDisplayMode.Form} singleProperty={false} />
                    </Grid>
                  }
                </Grid>
                <Box className={classes.body} overflow="auto" flexDirection="column" display="flex">
                  <Grid container direction="column">
                    <Grid item >
                      {subtitle != null &&
                        <p><strong>{subtitle}</strong></p>
                      }
                    </Grid>
                    {messageBody != null && 
                      <Grid item >
                        <div dangerouslySetInnerHTML={{ __html: messageBody.html() }} />
                      </Grid>
                    }
                    {links != null && links.size > 0 &&
                      <Grid item container direction="column" alignItems="center" spacing={2}>
                        {Array.from(links.entries()).map(link =>
                          <Grid item key={link[0]}>
                            <Button
                              variant="contained"
                              color="secondary"
                              href={link[1]}>
                              {link[0].toUpperCase()}
                            </Button>
                          </Grid>
                        )}
                      </Grid>
                    }
                  </Grid>
                </Box>
              </Grid>
            </Box>
          )}
        </AppContext.Consumer> 
      </>
    );
  }
}

MessageReaderView.contextType = AppContext;

const ModifiedMessageReaderView = withTranslation()(withStyles(styles)(MessageReaderView));

export default ModifiedMessageReaderView;









