import React from 'react';

import { Accordion, AccordionDetails, AccordionSummary, Box, createStyles, makeStyles, Table, TableBody, TableCell, TableContainer, TableRow, Theme, Typography } from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { DataPropertyIF } from 'services/database/api/properties/dataPropertyIF';

import PropertyLabel from '../propertyLabel';
import { PropertyDisplayMode } from '../propertyValue';

const defaultExpanded = true;

const useStyles = makeStyles((theme: Theme) => 
  createStyles({
    root: {
      width: '100%',
      paddingBottom: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    expandedTable: {
      marginTop: theme.spacing(-6) 
    },
    table: {
      tableLayout: "fixed", 
      width: "100%"
    },
    keyCell: {
      width: "25%",
      border: "none"
    },
    separatorCell: {
      width: theme.spacing(2),
      border: "none"
    },   
    valueCell: {
      textOverflow: "ellipsis",
      border: "none"
    },
    singleForm: {
      width: '100%',
      marginTop: theme.spacing(-1),
      marginLeft: theme.spacing(-1)
    },
    heading: {
      color: theme.palette.text.secondary,
    },
    headingExpanded: {
      marginTop: theme.spacing(-6.25),
      marginLeft: theme.spacing(-1),
      paddingTop: theme.spacing(1),
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(1),
      position: 'absolute',
      display: 'flex',
      flexDirection: 'row',
      color: theme.palette.text.secondary,
      background: "#FFFFFF",
      fontSize: "small",
      zIndex: 999
    },
    cellText: {
      wordWrap: "break-word"
    }
  }
  ));

export default function DataPropertyValue( props: {

  property: DataPropertyIF<any>,

  onPropertyChange?: (property: DataPropertyIF<any>) => void,

  displayMode: PropertyDisplayMode,

  singleProperty: boolean,

  hideColor?: boolean,

  disabled?: boolean,

  required? : boolean }) {

  const [expanded, setExpanded] = React.useState(defaultExpanded);

  const classes = useStyles();

  if (props.displayMode === PropertyDisplayMode.Cell) {
    return (
      <React.Fragment>
        { props.property.value() != null ? "..." : <>&nbsp;</>}
      </React.Fragment>
    );
  }


  const dataTable = (
    props.property.value() != null &&
    <TableContainer style={{ height: "100%" }}>
      <Table className={classes.table} size="small" >
        <TableBody >
          {Object.keys( props.property.value()! ).map( key => (
            <TableRow key={props.property.key() + "." + key}>
              <TableCell className={classes.keyCell} key={props.property.key() + "." + key + ".key"}>
                <Typography className={classes.cellText} variant="inherit">
                  {key}
                </Typography>
              </TableCell>
              <TableCell className={classes.separatorCell}>:</TableCell>
              <TableCell className={classes.valueCell} key={props.property.key() + "." + key + ".value"}>
                <Typography className={classes.cellText} variant="inherit">
                  {JSON.stringify( props.property.value()![key] )}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )

  const keys = props.property.value() != null ? Object.keys( props.property.value() ) : undefined;

  if (props.singleProperty) {
    return (
      <>
        <div className={classes.singleForm}>
          <Box overflow="auto" flex={1}>
            {dataTable}
          </Box>
        </div>
      </>
    );
  }
  else {
    return (
      <>
        <div className={classes.root}>
          <Accordion variant="outlined" expanded={expanded} key={props.property.key()} >
            <AccordionSummary onClick={() => setExpanded(!expanded)} expandIcon={<ExpandMoreIcon />} style={expanded ? { margin:'auto'} : {} }>
              {expanded ?
                <Typography className={classes.headingExpanded} variant="inherit"> 
                  <PropertyLabel documentName={props.property.parent.documentName()} propertyKey={props.property.key()} />
                </Typography>
                :
                <Typography className={classes.heading} variant="inherit">
                  <PropertyLabel documentName={props.property.parent.documentName()} propertyKey={props.property.key()} />
                </Typography>
              }
            </AccordionSummary>
            <AccordionDetails>
              <div className={classes.expandedTable}>
                {keys != null && keys.length > 0 ? 
                  dataTable :
                  <Typography className={classes.cellText} variant="inherit">
                    {JSON.stringify( props.property.value() )}
                  </Typography>
                }
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      </>
    );
  }
}

