import React from 'react';

import DocumentView, { DocumentProps } from '../components/documentView';

import { PropertiesSelector } from 'services/database/api/core/propertiesSelector';

import DatabaseTable, { DatabaseTableProps, EndDateProperty, VariantProperty, StartDateProperty } from 'ui/components/databaseTable';
import DatabaseList, { DatabaseListProps } from 'ui/components/databaseList';
import DatabaseCard, { DatabaseCardProps } from 'ui/components/databaseCard';
import DatabaseChart, { DatabaseChartProps } from 'ui/components/databaseChart';
import DatabaseGraph, { DatabaseGraphProps } from 'ui/components/databaseGraph';

import DatabaseView from '../components/databaseView';
import { SickLeaveRegistrationIF } from 'healthguard/api/documents/sickLeaveRegistrationIF';
import { HazardDocument } from '../../healthguard/api/healthguardDocuments';
import { CompanyDocument, UnitDocument, UserDocument } from '../../services/database/api/documents';
import { DatabaseQuery } from '../../services/database/api/core/databaseQuery';
import { MeasuresCollection } from '../../healthguard/api/healthguardCollections';

export const DefaultSickLeaveRegistrationTableColumns = [
  StartDateProperty,
  EndDateProperty,
  CompanyDocument,
  UnitDocument,
  UserDocument,
  HazardDocument,
  VariantProperty
  ];

export const defaultHighlightedSickLeaveRegistrationsProperty = HazardDocument;

export function SickLeaveRegistrationsTable( props: DatabaseTableProps ) {

  return (
    <DatabaseTable {...props} featuredProperties={props.featuredProperties != null ? props.featuredProperties : DefaultSickLeaveRegistrationTableColumns }/>
  );
}

export function SickLeaveRegistrationsList( props: DatabaseListProps ) {

  return (
    <DatabaseList {...props} />
  );
}


export function SickLeaveRegistrationsCard( props: DatabaseCardProps ) {
  return (
    <DatabaseCard 
      {...props} 
      highlightedPropertyKey={props.highlightedPropertyKey != null ? props.highlightedPropertyKey : defaultHighlightedSickLeaveRegistrationsProperty}
    />
  );
} 

export function SickLeaveRegistrationsChart( props: DatabaseChartProps ) {
  return (
    <DatabaseChart 
      {...props} 
    />
  );
}

export function SickLeaveRegistrationsGraph( props: DatabaseGraphProps ) {
  return (
    <DatabaseGraph 
      {...props} 
      nextPropertyKey={"user"}
      directed={true}
    />
  );
}


export function SickLeaveRegistrationsView(props: { 
  databaseQuery : DatabaseQuery<SickLeaveRegistrationIF>,
  title? : string,
  featuredProperties?: string[],
  highlightedPropertyKey?: string }) {

  return ( <DatabaseView 
    databaseQuery={props.databaseQuery} 
    title={props.title}
    featuredProperties={props.featuredProperties != null ? props.featuredProperties : DefaultSickLeaveRegistrationTableColumns }
    highlightedPropertyKey={props.highlightedPropertyKey != null ? props.highlightedPropertyKey : defaultHighlightedSickLeaveRegistrationsProperty}
    /> );
}



export function SickLeaveRegistrationView( props : DocumentProps ) {

  const SickLeaveBasicsFilter = {

    includePropertyKeys: [
      UnitDocument,
      UserDocument,
      MeasuresCollection,
      HazardDocument,
      "variant",
      "physician",
      "startDate",
      "endDate",
      "attachments"
    ]

  } as PropertiesSelector;

  /*
  const SickLeaveRegistrationsFilter = {

    includePropertyKeys: [
      "symptomRegistrations",
      "testRegistrations",
      "incidentRegistrations",
      "quarantineRegistrations"
  ]
  } as PropertiesSelector;
  
  
const SickLeaveAssociationsFilter = {

    includePropertyKeys: [
      "locations",
      "categories",
      "projects",
      "residence"]
  
  } as PropertiesSelector;
*/
  const defaultSickLeaveSteps = (): Map<string, PropertiesSelector> => {

    let defaultSteps = new Map<string, PropertiesSelector>();

    defaultSteps.set("basics", SickLeaveBasicsFilter);
    //defaultSteps.set("registrations", SickLeaveRegistrationsFilter);
    //defaultSteps.set("associations", SickLeaveAssociationsFilter); 

    return defaultSteps;
  }


  return (<DocumentView 
    {...props}
    inputTabs={props.inputTabs != null ? props.inputTabs : defaultSickLeaveSteps()} 
  />);
}



