import { ConfigurationManager } from "../../services/configuration/framework/configurationManager";
import { CollectionDatabase } from "../../services/database/impl/core/collectionDatabase";
import { CollectionGroupDatabase } from "../../services/database/impl/core/collectionGroupDatabase";
import { DatabaseDocument } from "../../services/database/framework/databaseDocument";
import { DatabaseFactory } from "../../services/database/framework/databaseFactory";
import { DatabaseManager } from "../../services/database/framework/databaseManager";
import { log } from "../../services/database/framework/databaseService";

import { HealthguardDatabaseFactoryIF } from "../api/healthguardDatabaseFactoryIF";
import { Change } from "../../services/database/impl/documents/change";
import { Hazard } from "../impl/documents/hazard";
import { Gathering } from "../impl/documents/gathering";
import { Risk } from "../impl/documents/risk";
import { IncidentRegistration } from "../impl/documents/incidentRegistration";
import { Measure } from "../impl/documents/measure";
import { QuarantineRegistration } from "../impl/documents/quarantineRegistration";
import { Registration } from "../impl/documents/registration";
import { Residence } from "../../services/database/impl/documents/residence";
import { SickLeaveRegistration } from "../impl/documents/sickLeaveRegistration";
import { SymptomRegistration } from "../impl/documents/symptomRegistration";
import { TestRegistration } from "../impl/documents/testRegistration";
import { VaccineRegistration } from "../impl/documents/vaccineRegistration";
import { AlertsCollection, CategoriesCollection, ChangesCollection, CompaniesCollection, DevicesCollection, KeysCollection, LocationsCollection, MessagesCollection, ProjectsCollection, ResidencesCollection, SubscriptionsCollection, TerminalsCollection, UnitsCollection, UsersCollection } from "../../services/database/api/collections";
import { RegistrationsCollection, QuarantineRegistrationsCollection, VaccineRegistrationsCollection, TestRegistrationsCollection, SymptomRegistrationsCollection, SickLeaveRegistrationsCollection, IncidentRegistrationsCollection } from "../api/registrationCollections";
import { HazardsCollection, GatheringsCollection, MeasuresCollection, HealthguardCollectionNames, HealthguardCollectionName, ConsentsCollection, RisksCollection } from "../api/healthguardCollections";
import { CategoryDocument, ChangeDocument, LocationDocument, ResidenceDocument, CompanyDocument, UnitDocument, UserDocument, SubscriptionDocument, ProjectDocument, MessageDocument, DeviceDocument, TerminalDocument, AlertDocument, KeyDocument } from "../../services/database/api/documents";
import { IncidentRegistrationDocument, QuarantineRegistrationDocument, RegistrationDocument, SickLeaveRegistrationDocument, SymptomRegistrationDocument, TestRegistrationDocument, VaccineRegistrationDocument } from "../api/registrationDocuments";
import { ConsentDocument, HazardDocument, GatheringDocument, RiskDocument, HealthguardCategoryDocument, HealthguardCompanyDocument, HealthguardDeviceDocument, HealthguardDocumentName, HealthguardDocumentNames, HealthguardLocationDocument, HealthguardProjectDocument, HealthguardUnitDocument, HealthguardUserDocument, MeasureDocument, HealthguardAlertDocument} from "../api/healthguardDocuments";
import { HealthguardUser } from "../impl/documents/healthguardUser";
import { HealthguardCompany } from "../impl/documents/healthguardCompany";
import { HealthguardUnit } from "../impl/documents/healthguardUnit";
import { Subscription } from "../../services/database/impl/documents/subscription";
import { HealthguardProject } from "../impl/documents/healthguardProject";
import { HealthguardLocation } from "../impl/documents/healthguardLocation";
import { Message } from "../../services/database/impl/documents/message";
import { HealthguardCategory } from "../impl/documents/healthguardCategory";
import { Alert } from "../../services/database/impl/documents/alert";
import { Consent } from "../impl/documents/consent";
import { HealthguardDevice } from "../impl/documents/healthguardDevice";
import { Terminal } from "../../services/database/impl/documents/terminal";
import { HealthguardAlert } from "../impl/documents/healthguardAlert";
import { Key } from "../../services/database/impl/documents/key";

const HealthguardRootCollectionNames = [
    HazardsCollection,
    ChangesCollection,
    CompaniesCollection,
    UsersCollection
];

const RegistrationDocumentNames = [
    RegistrationDocument,
    IncidentRegistrationDocument,
    SickLeaveRegistrationDocument,
    VaccineRegistrationDocument,
    SymptomRegistrationDocument,
    TestRegistrationDocument,
    QuarantineRegistrationDocument
];

const ContactableDocumentNames = [
    UserDocument,
    HealthguardUserDocument,
    CompanyDocument,
    HealthguardCompanyDocument
];

const CollectionNamesWithUsersReference = [
    RisksCollection, 
    CategoriesCollection, 
    LocationsCollection, 
    ProjectsCollection, 
    GatheringsCollection
];

export class HealthguardDatabaseFactory extends DatabaseFactory implements HealthguardDatabaseFactoryIF {

    constructor( configurationManager : ConfigurationManager, databaseManager : DatabaseManager ) {

        super( configurationManager, databaseManager );
        
        //log.traceIn( "constructor()");

        try {
            // Configuration data

            this._hazardsCollectionGroup =
                new CollectionGroupDatabase<Hazard>(
                    this.configurationManager, 
                    HazardsCollection, 
                    HazardDocument, 
                    [HazardDocument], 
                    this.rootCollectionNames().includes( HazardsCollection ),
                    this.encryptedCollectionNames().includes( HazardsCollection ));

            this._hazardsCollection = new CollectionDatabase<Hazard>(
                this.configurationManager, 
                HazardsCollection, 
                HazardDocument,
                [HazardDocument],
                this.rootCollectionNames().includes( HazardsCollection ),
                this.encryptedCollectionNames().includes( HazardsCollection ) );

            // Firestore data

            this._categoriesCollectionGroup =
                new CollectionGroupDatabase<HealthguardCategory>(
                    this.databaseManager, 
                    CategoriesCollection, 
                    HealthguardCategoryDocument, 
                    [HealthguardCategoryDocument,CategoryDocument], 
                    this.rootCollectionNames().includes( CategoriesCollection ),
                    this.encryptedCollectionNames().includes( CategoriesCollection ));

            this._categoriesCollection =
                new CollectionDatabase<HealthguardCategory>(
                    this.databaseManager, 
                    CategoriesCollection, 
                    HealthguardCategoryDocument, 
                    [HealthguardCategoryDocument,CategoryDocument], 
                    this.rootCollectionNames().includes( CategoriesCollection ),
                    this.encryptedCollectionNames().includes( CategoriesCollection )
                    );

            this._changesCollectionGroup  = 
                new CollectionGroupDatabase<Change>( 
                    this.databaseManager, 
                    ChangesCollection, 
                    ChangeDocument, 
                    [ChangeDocument], 
                    this.rootCollectionNames().includes( ChangesCollection ),
                    this.encryptedCollectionNames().includes( ChangesCollection ) );

            this._changesCollection  = 
                new CollectionDatabase<Change>( 
                    this.databaseManager, 
                    ChangesCollection, 
                    ChangeDocument,
                    [ChangeDocument],
                    this.rootCollectionNames().includes( ChangesCollection ),
                    this.encryptedCollectionNames().includes( ChangesCollection ) 
                    );
 
            this._companiesCollectionGroup = 
                new CollectionGroupDatabase<HealthguardCompany>( 
                    this.databaseManager, 
                    CompaniesCollection, 
                    HealthguardCompanyDocument,
                    [HealthguardCompanyDocument,CompanyDocument], 
                    this.rootCollectionNames().includes( CompaniesCollection ),
                    this.encryptedCollectionNames().includes( CompaniesCollection ) );

            this._companiesCollection =
                new CollectionDatabase<HealthguardCompany>(
                    this.databaseManager,
                    CompaniesCollection,
                    HealthguardCompanyDocument,
                    [HealthguardCompanyDocument, CompanyDocument],
                    this.rootCollectionNames().includes(CompaniesCollection),
                    this.encryptedCollectionNames().includes(CompaniesCollection));

            this._consentsCollectionGroup =
                new CollectionGroupDatabase<Consent>(
                    this.databaseManager,
                    ConsentsCollection,
                    ConsentDocument,
                    [ConsentDocument],
                    this.rootCollectionNames().includes(ConsentsCollection),
                    this.encryptedCollectionNames().includes(ConsentsCollection));

            this._consentsCollection =
                new CollectionDatabase<Consent>(
                    this.databaseManager,
                    ConsentsCollection,
                    ConsentDocument,
                    [ConsentDocument],
                    this.rootCollectionNames().includes(ConsentsCollection),
                    this.encryptedCollectionNames().includes(ConsentsCollection));

            this._gatheringsCollectionGroup =
                new CollectionGroupDatabase<Gathering>(
                    this.databaseManager, 
                    GatheringsCollection, 
                    GatheringDocument,
                    [GatheringDocument], 
                    this.rootCollectionNames().includes( GatheringsCollection ),
                    this.encryptedCollectionNames().includes( GatheringsCollection ));

            this._gatheringsCollection =
                new CollectionDatabase<Gathering>(
                    this.databaseManager,
                    GatheringsCollection,
                    GatheringDocument,
                    [GatheringDocument],
                    this.rootCollectionNames().includes(GatheringsCollection),
                    this.encryptedCollectionNames().includes(GatheringsCollection));

            this._risksCollectionGroup =
                new CollectionGroupDatabase<Risk>(
                    this.databaseManager, 
                    RisksCollection, 
                    RiskDocument,
                    [RiskDocument], 
                    this.rootCollectionNames().includes( RisksCollection ),
                    this.encryptedCollectionNames().includes( RisksCollection ));

            this._risksCollection =
                new CollectionDatabase<Risk>(
                    this.databaseManager,
                    RisksCollection,
                    RiskDocument,
                    [RiskDocument],
                    this.rootCollectionNames().includes(RisksCollection),
                    this.encryptedCollectionNames().includes(RisksCollection));


            this._alertsCollectionGroup =
                new CollectionGroupDatabase<Alert>(
                    this.databaseManager,
                    AlertsCollection,
                    AlertDocument,
                    [AlertDocument],
                    this.rootCollectionNames().includes(RegistrationsCollection),
                    this.encryptedCollectionNames().includes(RegistrationsCollection));

            this._alertsCollection =
                new CollectionDatabase<Alert>(
                    this.databaseManager,
                    AlertsCollection,
                    AlertDocument,
                    [AlertDocument],
                    this.rootCollectionNames().includes(RegistrationsCollection),
                    this.encryptedCollectionNames().includes(RegistrationsCollection));

            this._devicesCollectionGroup =
                new CollectionGroupDatabase<HealthguardDevice>(
                    this.databaseManager, 
                    DevicesCollection, 
                    HealthguardDeviceDocument, 
                    [HealthguardDeviceDocument,DeviceDocument], 
                    this.rootCollectionNames().includes( DevicesCollection ),
                    this.encryptedCollectionNames().includes( DevicesCollection ));

            this._devicesCollection =
                new CollectionDatabase<HealthguardDevice>(
                    this.databaseManager, 
                    DevicesCollection, 
                    HealthguardDeviceDocument, 
                    [HealthguardDeviceDocument,DeviceDocument], 
                    this.rootCollectionNames().includes( DevicesCollection ),
                    this.encryptedCollectionNames().includes( DevicesCollection )
                    );

            this._incidentRegistrationsCollectionGroup  = 
                new CollectionGroupDatabase<IncidentRegistration>( 
                    this.databaseManager, 
                    RegistrationsCollection, 
                    IncidentRegistrationDocument,
                    [IncidentRegistrationDocument], 
                    this.rootCollectionNames().includes( RegistrationsCollection ),
                    this.encryptedCollectionNames().includes( RegistrationsCollection ));

            this._incidentRegistrationsCollection  = 
                new CollectionDatabase<IncidentRegistration>( 
                    this.databaseManager, 
                    RegistrationsCollection, 
                    IncidentRegistrationDocument,
                    [IncidentRegistrationDocument],
                    this.rootCollectionNames().includes( RegistrationsCollection ),
                    this.encryptedCollectionNames().includes( RegistrationsCollection ));

            this._keysCollectionGroup =
                new CollectionGroupDatabase<Key>(
                    this.databaseManager,
                    KeysCollection,
                    KeyDocument,
                    [KeyDocument],
                    this.rootCollectionNames().includes(KeysCollection),
                    this.encryptedCollectionNames().includes(KeysCollection));

            this._keysCollection =
                new CollectionDatabase<Key>(
                    this.databaseManager,
                    KeysCollection,
                    KeyDocument,
                    [KeyDocument],
                    this.rootCollectionNames().includes(KeysCollection),
                    this.encryptedCollectionNames().includes(KeysCollection));
    
            this._locationsCollectionGroup = 
                new CollectionGroupDatabase<HealthguardLocation>( 
                    this.databaseManager, 
                    LocationsCollection, 
                    HealthguardLocationDocument,
                    [HealthguardLocationDocument,LocationDocument], 
                    this.rootCollectionNames().includes( LocationsCollection ),
                    this.encryptedCollectionNames().includes( LocationsCollection ));

            this._locationsCollection = 
                new CollectionDatabase<HealthguardLocation>( 
                    this.databaseManager, 
                    LocationsCollection,
                    HealthguardLocationDocument,
                    [HealthguardLocationDocument,LocationDocument],
                    this.rootCollectionNames().includes( LocationsCollection ),
                    this.encryptedCollectionNames().includes( LocationsCollection ));
        
            this._measuresCollectionGroup = 
                new CollectionGroupDatabase<Measure>( 
                    this.databaseManager, 
                    MeasuresCollection, 
                    MeasureDocument,
                    [MeasureDocument], 
                    this.rootCollectionNames().includes( MeasuresCollection ),
                    this.encryptedCollectionNames().includes( MeasuresCollection ));

            this._measuresCollection =
                new CollectionDatabase<Measure>(
                    this.databaseManager,
                    MeasuresCollection,
                    MeasureDocument,
                    [MeasureDocument],
                    this.rootCollectionNames().includes(MeasuresCollection),
                    this.encryptedCollectionNames().includes(MeasuresCollection));

            this._messagesCollectionGroup =
                new CollectionGroupDatabase<Message>(
                    this.databaseManager,
                    MessagesCollection,
                    MessageDocument,
                    [MessageDocument],
                    this.rootCollectionNames().includes(MessagesCollection),
                    this.encryptedCollectionNames().includes(MessagesCollection));

            this._messagesCollection =
                new CollectionDatabase<Message>(
                    this.databaseManager,
                    MessagesCollection,
                    MessageDocument,
                    [MessageDocument],
                    this.rootCollectionNames().includes(MessagesCollection),
                    this.encryptedCollectionNames().includes(MessagesCollection));

            this._projectsCollectionGroup =
                new CollectionGroupDatabase<HealthguardProject>(
                    this.databaseManager,
                    ProjectsCollection,
                    HealthguardProjectDocument,
                    [HealthguardProjectDocument,ProjectDocument],
                    this.rootCollectionNames().includes(ProjectsCollection),
                    this.encryptedCollectionNames().includes( ProjectsCollection ));

            this._projectsCollection =
                new CollectionDatabase<HealthguardProject>(
                    this.databaseManager,
                    ProjectsCollection,
                    HealthguardProjectDocument,
                    [HealthguardProjectDocument,ProjectDocument],
                    this.rootCollectionNames().includes(ProjectsCollection),
                    this.encryptedCollectionNames().includes( ProjectsCollection ));

            this._quarantineRegistrationsCollectionGroup  = 
                new CollectionGroupDatabase<QuarantineRegistration>( 
                    this.databaseManager, 
                    RegistrationsCollection, 
                    QuarantineRegistrationDocument,
                    [QuarantineRegistrationDocument], 
                    this.rootCollectionNames().includes( RegistrationsCollection ),
                    this.encryptedCollectionNames().includes( RegistrationsCollection ));

            this._quarantineRegistrationsCollection =
                new CollectionDatabase<QuarantineRegistration>(
                    this.databaseManager,
                    RegistrationsCollection,
                    QuarantineRegistrationDocument,
                    [QuarantineRegistrationDocument],
                    this.rootCollectionNames().includes( RegistrationsCollection ),
                    this.encryptedCollectionNames().includes( RegistrationsCollection ));

            this._registrationsCollectionGroup =
                new CollectionGroupDatabase<Registration>(
                    this.databaseManager,
                    RegistrationsCollection,
                    undefined,
                    [RegistrationDocument], 
                    this.rootCollectionNames().includes( RegistrationsCollection ),
                    this.encryptedCollectionNames().includes( RegistrationsCollection ));

            this._registrationsCollection =
                new CollectionDatabase<Registration>(
                    this.databaseManager,
                    RegistrationsCollection,
                    undefined,
                    [RegistrationDocument],
                    this.rootCollectionNames().includes( RegistrationsCollection ),
                    this.encryptedCollectionNames().includes( RegistrationsCollection ));


            this._residencesCollectionGroup =
                new CollectionGroupDatabase<Residence>( 
                    this.databaseManager, 
                    ResidencesCollection, 
                    ResidenceDocument,
                    [ResidenceDocument], 
                    this.rootCollectionNames().includes( ResidencesCollection ),
                    this.encryptedCollectionNames().includes( ResidencesCollection ));

            this._residencesCollection = 
                new CollectionDatabase<Residence>( 
                    this.databaseManager, 
                    ResidencesCollection,
                    ResidenceDocument,
                    [ResidenceDocument],
                    this.rootCollectionNames().includes( ResidencesCollection ),
                    this.encryptedCollectionNames().includes( ResidencesCollection ));
        
             this._sickLeaveRegistrationsCollectionGroup  = 
                new CollectionGroupDatabase<SickLeaveRegistration>( 
                    this.databaseManager, 
                    RegistrationsCollection, 
                    SickLeaveRegistrationDocument,
                    [SickLeaveRegistrationDocument], 
                    this.rootCollectionNames().includes( RegistrationsCollection ),
                    this.encryptedCollectionNames().includes( RegistrationsCollection ));

            this._sickLeaveRegistrationsCollection  = 
                new CollectionDatabase<SickLeaveRegistration>( 
                    this.databaseManager, 
                    RegistrationsCollection, 
                    SickLeaveRegistrationDocument,
                    [SickLeaveRegistrationDocument],
                    this.rootCollectionNames().includes( RegistrationsCollection ),
                    this.encryptedCollectionNames().includes( RegistrationsCollection ));

            this._subscriptionsCollectionGroup  = 
                new CollectionGroupDatabase<Subscription>( 
                    this.databaseManager, 
                    SubscriptionsCollection, 
                    SubscriptionDocument,
                    [SubscriptionDocument], 
                    this.rootCollectionNames().includes( SubscriptionsCollection ),
                    this.encryptedCollectionNames().includes( SubscriptionsCollection ));

            this._subscriptionsCollection  = 
                new CollectionDatabase<Subscription>( 
                    this.databaseManager, 
                    SubscriptionsCollection, 
                    SubscriptionDocument,
                    [SubscriptionDocument],
                    this.rootCollectionNames().includes( SubscriptionsCollection ),
                    this.encryptedCollectionNames().includes( SubscriptionsCollection ));

            this._symptomRegistrationsCollectionGroup  = 
                new CollectionGroupDatabase<SymptomRegistration>( 
                    this.databaseManager, 
                    RegistrationsCollection, 
                    SymptomRegistrationDocument,
                    [SymptomRegistrationDocument], 
                    this.rootCollectionNames().includes( RegistrationsCollection ),
                    this.encryptedCollectionNames().includes( RegistrationsCollection ));

            this._symptomRegistrationsCollection  = 
                new CollectionDatabase<SymptomRegistration>( 
                    this.databaseManager, 
                    RegistrationsCollection, 
                    SymptomRegistrationDocument,
                    [SymptomRegistrationDocument],
                    this.rootCollectionNames().includes( RegistrationsCollection ),
                    this.encryptedCollectionNames().includes( RegistrationsCollection ));

            this._terminalsCollectionGroup =
                new CollectionGroupDatabase<Terminal>(
                    this.databaseManager,
                    TerminalsCollection,
                    TerminalDocument,
                    [TerminalDocument],
                    this.rootCollectionNames().includes(TerminalsCollection),
                    this.encryptedCollectionNames().includes(TerminalsCollection));

            this._terminalsCollection =
                new CollectionDatabase<Terminal>(
                    this.databaseManager,
                    TerminalsCollection,
                    TerminalDocument,
                    [TerminalDocument],
                    this.rootCollectionNames().includes(TerminalsCollection),
                    this.encryptedCollectionNames().includes(TerminalsCollection));
            
            this._testRegistrationsCollectionGroup  = 
                new CollectionGroupDatabase<TestRegistration>(
                    this.databaseManager, 
                    RegistrationsCollection, 
                    TestRegistrationDocument,
                    [TestRegistrationDocument], 
                    this.rootCollectionNames().includes( RegistrationsCollection ),
                    this.encryptedCollectionNames().includes( RegistrationsCollection ));

            this._testRegistrationsCollection =
                new CollectionDatabase<TestRegistration>(
                    this.databaseManager, 
                    RegistrationsCollection, 
                    TestRegistrationDocument,
                    [TestRegistrationDocument],
                    this.rootCollectionNames().includes( RegistrationsCollection ),
                    this.encryptedCollectionNames().includes( RegistrationsCollection ));

            this._unitsCollectionGroup =
                new CollectionGroupDatabase<HealthguardUnit>(
                    this.databaseManager, 
                    UnitsCollection, 
                    HealthguardUnitDocument,
                    [HealthguardUnitDocument,UnitDocument], 
                    this.rootCollectionNames().includes( UnitsCollection ),
                    this.encryptedCollectionNames().includes( RegistrationsCollection ));

            this._unitsCollection =
                new CollectionDatabase<HealthguardUnit>(
                    this.databaseManager, 
                    UnitsCollection, 
                    HealthguardUnitDocument,
                    [HealthguardUnitDocument,UnitDocument],
                    this.rootCollectionNames().includes( UnitsCollection ),
                    this.encryptedCollectionNames().includes( UnitsCollection ));

            this._usersCollectionGroup =
                new CollectionGroupDatabase<HealthguardUser>( 
                    this.databaseManager, 
                    UsersCollection, 
                    HealthguardUserDocument,
                    [HealthguardUserDocument,UserDocument],
                    this.rootCollectionNames().includes( UsersCollection ),
                    this.encryptedCollectionNames().includes( UsersCollection ) );

            this._usersCollection = 
                new CollectionDatabase<HealthguardUser>( 
                    this.databaseManager, 
                    UsersCollection,
                    HealthguardUserDocument,
                    [HealthguardUserDocument,UserDocument],
                    this.rootCollectionNames().includes( UsersCollection ),
                    this.encryptedCollectionNames().includes( UsersCollection ) );
                
            this._vaccineRegistrationsCollectionGroup = 
                new CollectionGroupDatabase<VaccineRegistration>( 
                    this.databaseManager, 
                    RegistrationsCollection, 
                    VaccineRegistrationDocument,
                    [VaccineRegistrationDocument], 
                    this.rootCollectionNames().includes( RegistrationsCollection ),
                    this.encryptedCollectionNames().includes( RegistrationsCollection ));

            this._vaccineRegistrationsCollection = 
                new CollectionDatabase<VaccineRegistration>( 
                    this.databaseManager, 
                    RegistrationsCollection,
                    VaccineRegistrationDocument,
                    [VaccineRegistrationDocument],
                    this.rootCollectionNames().includes( RegistrationsCollection ),
                    this.encryptedCollectionNames().includes( RegistrationsCollection ) );
                
            //log.traceOut( "constructor()");

        } catch( error ) {
            log.warn( "constructor()", "Error initializing database service", error );

            throw new Error( (error as any).message );
        }
    }


    collectionGroupDatabaseFromCollectionName(  
        collectionName: HealthguardCollectionName, 
        owner?: DatabaseDocument): CollectionGroupDatabase<DatabaseDocument> {

        switch (collectionName) {

            case CategoriesCollection:
                return this.categoriesCollectionGroup(owner);

            case ChangesCollection:
                return this.changesCollectionGroup(owner);

            case CompaniesCollection:
                return this.companiesCollectionGroup(owner);

            case ConsentsCollection:
                return this.consentsCollectionGroup(owner);

            case AlertsCollection:
                return this.alertsCollectionGroup(owner);

            case DevicesCollection:
                return this.devicesCollectionGroup(owner);

            case HazardsCollection:
                return this.hazardsCollectionGroup(owner);

            case GatheringsCollection:
                return this.gatheringsCollectionGroup(owner);

            case RisksCollection:
                return this.risksCollectionGroup(owner);
    
            case IncidentRegistrationsCollection:
                return this.incidentRegistrationsCollectionGroup(owner);

            case KeysCollection:
                return this.keysCollectionGroup(owner);

            case LocationsCollection:
                return this.locationsCollectionGroup(owner);

            case MeasuresCollection:
                return this.measuresCollectionGroup(owner);

            case MessagesCollection:
                return this.messagesCollectionGroup(owner);

            case ProjectsCollection:
                return this.projectsCollectionGroup(owner);

            case UnitsCollection:
                return this.unitsCollectionGroup(owner);

            case QuarantineRegistrationsCollection:
                return this.quarantineRegistrationsCollectionGroup(owner);

            case RegistrationsCollection:
                return this.registrationsCollectionGroup(owner);

            case ResidencesCollection:
                return this.residencesCollectionGroup(owner);

            case SickLeaveRegistrationsCollection:
                return this.sickLeaveRegistrationsCollectionGroup(owner);
                
            case SubscriptionsCollection:
                return this.subscriptionsCollectionGroup(owner);

            case SymptomRegistrationsCollection:
                return this.symptomRegistrationsCollectionGroup(owner);

            case TerminalsCollection:
                return this.terminalsCollectionGroup(owner);
        
            case TestRegistrationsCollection:
                return this.testRegistrationsCollectionGroup(owner);

            case UsersCollection:
                return this.usersCollectionGroup(owner);

            case VaccineRegistrationsCollection:
                return this.vaccineRegistrationsCollectionGroup(owner);

            default:
                throw new Error("Collection not recognized: " + collectionName);
        }
    }

    collectionGroupDatabaseFromDocumentName( 
        documentName : HealthguardDocumentName, 
        owner? : DatabaseDocument ) : CollectionGroupDatabase<DatabaseDocument> {
        
        switch( documentName ) {

            case AlertDocument:
            case HealthguardAlertDocument:
                return this.alertsCollectionGroup( owner ); 

            case CategoryDocument:
            case HealthguardCategoryDocument:
                return this.categoriesCollectionGroup( owner );
        
            case ChangeDocument:
                return this.changesCollectionGroup( owner );

            case CompanyDocument:
            case HealthguardCompanyDocument:
                return this.companiesCollectionGroup( owner );

            case ConsentDocument:
                return this.consentsCollectionGroup( owner );

            case DeviceDocument:
            case HealthguardDeviceDocument:
                return this.devicesCollectionGroup( owner );
            
            case HazardDocument:
                return this.hazardsCollectionGroup( owner );

            case GatheringDocument:
                return this.gatheringsCollectionGroup(owner);

            case RiskDocument:
                return this.risksCollectionGroup(owner);

            case IncidentRegistrationDocument:
                return this.incidentRegistrationsCollectionGroup( owner );
        
            case KeyDocument:
                return this.keysCollectionGroup( owner );
    
            case LocationDocument:
            case HealthguardLocationDocument:

                return this.locationsCollectionGroup( owner );
  
            case MeasureDocument:
                return this.measuresCollectionGroup( owner );

            case MessageDocument:
                return this.messagesCollectionGroup( owner );
    
            case ProjectDocument:
            case HealthguardProjectDocument:
                return this.projectsCollectionGroup( owner );
        
            case UnitDocument:
            case HealthguardUnitDocument:
                return this.unitsCollectionGroup( owner );

             case QuarantineRegistrationDocument:
                return this.quarantineRegistrationsCollectionGroup( owner );
        
            case RegistrationDocument:
                return this.registrationsCollectionGroup( owner );

            case ResidenceDocument:
                return this.residencesCollectionGroup( owner );
    
            case SickLeaveRegistrationDocument:
                return this.sickLeaveRegistrationsCollectionGroup( owner );
    
            case SubscriptionDocument:
                return this.subscriptionsCollectionGroup( owner );
    
            case SymptomRegistrationDocument:
                return this.symptomRegistrationsCollectionGroup( owner );

            case TerminalDocument:
                return this.terminalsCollectionGroup( owner );    
    
            case TestRegistrationDocument:
                return this.testRegistrationsCollectionGroup( owner );
            
            case UserDocument:
            case HealthguardUserDocument:
                return this.usersCollectionGroup( owner );
            
            case VaccineRegistrationDocument:
                return this.vaccineRegistrationsCollectionGroup( owner );
            
            default:
                throw new Error( "Document name not recognized: " + documentName );
        }
    }

    alertsCollectionGroup( owner? : DatabaseDocument ) : CollectionGroupDatabase<Alert> {

        let alertsCollectionGroup;

        if( owner == null ) {
            alertsCollectionGroup = this._alertsCollectionGroup;
        }
        else {
            alertsCollectionGroup = this._alertsSubcollectionGroups.get( owner.databasePath() );

            if( alertsCollectionGroup == null ) {

                alertsCollectionGroup =
                    new CollectionGroupDatabase<Alert>( this.databaseManager, 
                        AlertsCollection, 
                        HealthguardAlertDocument,
                        [HealthguardAlertDocument,AlertDocument],
                        this._alertsCollectionGroup.allowRootCollection, 
                        this._alertsCollectionGroup.encrypted, 
                        owner );

                this._alertsSubcollectionGroups.set( owner.databasePath(),  alertsCollectionGroup );
            }
        }
        return alertsCollectionGroup;
    }


    categoriesCollectionGroup( owner? : DatabaseDocument ) : CollectionGroupDatabase<HealthguardCategory> {

        let categoriesCollectionGroup;

        if( owner == null ) {
            categoriesCollectionGroup = this._categoriesCollectionGroup;
        }
        else {
            categoriesCollectionGroup = this._categoriesSubcollectionGroups.get( owner.databasePath() );

            if( categoriesCollectionGroup == null ) {

                categoriesCollectionGroup =
                    new CollectionGroupDatabase<HealthguardCategory>( this.databaseManager, 
                        CategoriesCollection, 
                        HealthguardCategoryDocument, 
                        [HealthguardCategoryDocument,CategoryDocument], 
                        this._categoriesCollectionGroup.allowRootCollection,
                        this._categoriesCollectionGroup.encrypted,
                        owner );

                this._categoriesSubcollectionGroups.set( owner.databasePath(), categoriesCollectionGroup );
            }
        }
        return categoriesCollectionGroup;
    }

    changesCollectionGroup( owner? : DatabaseDocument ) : CollectionGroupDatabase<Change> {

        let changesCollectionGroup;

        if( owner == null ) {
            changesCollectionGroup = this._changesCollectionGroup;
        }
        else {
            changesCollectionGroup = this._changesSubcollectionGroups.get( owner.databasePath() );

            if( changesCollectionGroup == null ) {

                changesCollectionGroup =
                    new CollectionGroupDatabase<Change>( this.databaseManager, 
                        ChangesCollection, 
                        ChangeDocument,
                        [ChangeDocument],
                        this._changesCollectionGroup.allowRootCollection, 
                        this._changesCollectionGroup.encrypted,
                        owner );

                this._changesSubcollectionGroups.set( owner.databasePath(), changesCollectionGroup );
            }
        }
        return changesCollectionGroup;
    }

    companiesCollectionGroup( owner? : DatabaseDocument ) : CollectionGroupDatabase<HealthguardCompany> {

        let companiesCollectionGroup;

        if( owner == null ) {
            companiesCollectionGroup = this._companiesCollectionGroup;
        }
        else {
            companiesCollectionGroup = this._companiesSubcollectionGroups.get( owner.databasePath() );

            if( companiesCollectionGroup == null ) {

                companiesCollectionGroup =
                    new CollectionGroupDatabase<HealthguardCompany>( this.databaseManager, 
                        CompaniesCollection, 
                        HealthguardCompanyDocument,
                        [HealthguardCompanyDocument,CompanyDocument],
                        this._companiesCollectionGroup.allowRootCollection,  
                        this._companiesCollectionGroup.encrypted,
                        owner );

                this._companiesSubcollectionGroups.set( owner.databasePath(), companiesCollectionGroup );
            }
        }
        return companiesCollectionGroup;
    }

    consentsCollectionGroup( owner? : DatabaseDocument ) : CollectionGroupDatabase<Consent> {

        let consentsCollectionGroup;

        if( owner == null ) {
            consentsCollectionGroup = this._consentsCollectionGroup;
        }
        else {
            consentsCollectionGroup = this._consentsSubcollectionGroups.get( owner.databasePath() );

            if( consentsCollectionGroup == null ) {

                consentsCollectionGroup =
                    new CollectionGroupDatabase<Consent>( this.databaseManager, 
                        ConsentsCollection, 
                        ConsentDocument,
                        [ConsentDocument],
                        this._consentsCollectionGroup.allowRootCollection,
                        this._consentsCollectionGroup.encrypted,   
                        owner );

                this._consentsSubcollectionGroups.set( owner.databasePath(),  consentsCollectionGroup );
            }
        }
        return consentsCollectionGroup;
    }


    devicesCollectionGroup( owner? : DatabaseDocument ) : CollectionGroupDatabase<HealthguardDevice> {

        let devicesCollectionGroup;

        if( owner == null ) {
            devicesCollectionGroup = this._devicesCollectionGroup;
        }
        else {
            devicesCollectionGroup = this._devicesSubcollectionGroups.get( owner.databasePath() );

            if( devicesCollectionGroup == null ) {

                devicesCollectionGroup =
                    new CollectionGroupDatabase<HealthguardDevice>( this.databaseManager, 
                        DevicesCollection, 
                        HealthguardDeviceDocument, 
                        [HealthguardDeviceDocument,DeviceDocument], 
                        this._devicesCollectionGroup.allowRootCollection,
                        this._devicesCollectionGroup.encrypted,
                        owner );

                this._devicesSubcollectionGroups.set( owner.databasePath(), devicesCollectionGroup );
            }
        }
        return devicesCollectionGroup;
    }

    hazardsCollectionGroup( owner? : DatabaseDocument ) : CollectionGroupDatabase<Hazard> {

        let hazardsCollectionGroup;

        if( owner == null ) {
            hazardsCollectionGroup = this._hazardsCollectionGroup;
        }
        else {
            hazardsCollectionGroup = this._hazardsSubcollectionGroups.get( owner.databasePath() );

            if( hazardsCollectionGroup == null ) {

                hazardsCollectionGroup =
                    new CollectionGroupDatabase<Hazard>( this.databaseManager, 
                        HazardsCollection, 
                        HazardDocument,
                        [HazardDocument],
                        this._hazardsCollectionGroup.allowRootCollection, 
                        this._hazardsCollectionGroup.encrypted,
                        owner );

                this._hazardsSubcollectionGroups.set( owner.databasePath(), hazardsCollectionGroup );
            }
        }
        return hazardsCollectionGroup;
    }

    gatheringsCollectionGroup( owner? : DatabaseDocument ) : CollectionGroupDatabase<Gathering> {

        let gatheringsCollectionGroup;

        if( owner == null ) {
            gatheringsCollectionGroup = this._gatheringsCollectionGroup;
        }
        else {
            gatheringsCollectionGroup = this._gatheringsSubcollectionGroups.get( owner.databasePath() );

            if( gatheringsCollectionGroup == null ) {

                gatheringsCollectionGroup =
                    new CollectionGroupDatabase<Gathering>( this.databaseManager, 
                        GatheringsCollection, 
                        GatheringDocument,
                        [GatheringDocument],
                        this._gatheringsCollectionGroup.allowRootCollection,
                        this._gatheringsCollectionGroup.encrypted, 
                        owner );

                this._gatheringsSubcollectionGroups.set( owner.databasePath(), gatheringsCollectionGroup );
            }
        }
        return gatheringsCollectionGroup;
    }


    risksCollectionGroup( owner? : DatabaseDocument ) : CollectionGroupDatabase<Risk> {

        let risksCollectionGroup;

        if( owner == null ) {
            risksCollectionGroup = this._risksCollectionGroup;
        }
        else {
            risksCollectionGroup = this._risksSubcollectionGroups.get( owner.databasePath() );

            if( risksCollectionGroup == null ) {

                risksCollectionGroup =
                    new CollectionGroupDatabase<Risk>( this.databaseManager, 
                        RisksCollection, 
                        RiskDocument,
                        [RiskDocument],
                        this._risksCollectionGroup.allowRootCollection,
                        this._risksCollectionGroup.encrypted, 
                        owner );

                this._risksSubcollectionGroups.set( owner.databasePath(), risksCollectionGroup );
            }
        }
        return risksCollectionGroup;
    }


    incidentRegistrationsCollectionGroup( owner? : DatabaseDocument ) : CollectionGroupDatabase<IncidentRegistration> {

        let incidentRegistrationsCollectionGroup;

        if( owner == null ) {
            incidentRegistrationsCollectionGroup = this._incidentRegistrationsCollectionGroup;
        }
        else {
            incidentRegistrationsCollectionGroup = this._incidentRegistrationsSubcollectionGroups.get( owner.databasePath() );

            if( incidentRegistrationsCollectionGroup == null ) {

                incidentRegistrationsCollectionGroup =
                    new CollectionGroupDatabase<IncidentRegistration>( this.databaseManager, 
                        RegistrationsCollection, 
                        IncidentRegistrationDocument,
                        [IncidentRegistrationDocument],
                        this._incidentRegistrationsCollectionGroup.allowRootCollection,
                        this._incidentRegistrationsCollectionGroup.encrypted,  
                        owner );

                this._incidentRegistrationsSubcollectionGroups.set( owner.databasePath(), incidentRegistrationsCollectionGroup );
            }
        }
        return incidentRegistrationsCollectionGroup;
    }

    keysCollectionGroup( owner? : DatabaseDocument ) : CollectionGroupDatabase<Key> {

        let keysCollectionGroup;

        if( owner == null ) {
            keysCollectionGroup = this._keysCollectionGroup;
        }
        else {
            keysCollectionGroup = this._keysSubcollectionGroups.get( owner.databasePath() );

            if( keysCollectionGroup == null ) {

                keysCollectionGroup =
                    new CollectionGroupDatabase<Key>( this.databaseManager, 
                        KeysCollection, 
                        KeyDocument,
                        [KeyDocument],
                        this._keysCollectionGroup.allowRootCollection,
                        this._keysCollectionGroup.encrypted,   
                        owner );

                this._keysSubcollectionGroups.set( owner.databasePath(),  keysCollectionGroup );
            }
        }
        return keysCollectionGroup;
    }

    locationsCollectionGroup( owner? : DatabaseDocument ) : CollectionGroupDatabase<HealthguardLocation> {

        let locationsCollectionGroup;

        if( owner == null ) {
            locationsCollectionGroup = this._locationsCollectionGroup;
        }
        else {
            locationsCollectionGroup = this._locationsSubcollectionGroups.get( owner.databasePath() );

            if( locationsCollectionGroup == null ) {

                locationsCollectionGroup =
                    new CollectionGroupDatabase<HealthguardLocation>( 
                        this.databaseManager, 
                        LocationsCollection, 
                        HealthguardLocationDocument,
                        [HealthguardLocationDocument,LocationDocument],
                        this._locationsCollectionGroup.allowRootCollection, 
                        this._locationsCollectionGroup.encrypted,  
                        owner );

                this._locationsSubcollectionGroups.set( owner.databasePath(),  locationsCollectionGroup );
            }
        }
        return locationsCollectionGroup;
    }

    measuresCollectionGroup( owner? : DatabaseDocument ) : CollectionGroupDatabase<Measure> {

        let measuresCollectionGroup;

        if( owner == null ) {
            measuresCollectionGroup = this._measuresCollectionGroup;
        }
        else {
            measuresCollectionGroup = this._measuresSubcollectionGroups.get( owner.databasePath() );

            if( measuresCollectionGroup == null ) {

                measuresCollectionGroup =
                    new CollectionGroupDatabase<Measure>( this.databaseManager, 
                        MeasuresCollection, 
                        MeasureDocument,
                        [MeasureDocument],
                        this._measuresCollectionGroup.allowRootCollection,
                        this._measuresCollectionGroup.encrypted,   
                        owner );

                this._measuresSubcollectionGroups.set( owner.databasePath(),  measuresCollectionGroup );
            }
        }
        return measuresCollectionGroup;
    }

    messagesCollectionGroup( owner? : DatabaseDocument ) : CollectionGroupDatabase<Message> {

        let messagesCollectionGroup;

        if( owner == null ) {
            messagesCollectionGroup = this._messagesCollectionGroup;
        }
        else {
            messagesCollectionGroup = this._messagesSubcollectionGroups.get( owner.databasePath() );

            if( messagesCollectionGroup == null ) {

                messagesCollectionGroup =
                    new CollectionGroupDatabase<Message>( this.databaseManager, 
                        MessagesCollection, 
                        MessageDocument,
                        [MessageDocument],
                        this._messagesCollectionGroup.allowRootCollection,
                        this._messagesCollectionGroup.encrypted,   
                        owner );

                this._messagesSubcollectionGroups.set( owner.databasePath(),  messagesCollectionGroup );
            }
        }
        return messagesCollectionGroup;
    }

    projectsCollectionGroup( owner? : DatabaseDocument ) : CollectionGroupDatabase<HealthguardProject> {

        let projectsCollectionGroup;

        if( owner == null ) {
            projectsCollectionGroup = this._projectsCollectionGroup;
        }
        else {
            projectsCollectionGroup = this._projectsSubcollectionGroups.get( owner.databasePath() );

            if( projectsCollectionGroup == null ) {

                projectsCollectionGroup =
                    new CollectionGroupDatabase<HealthguardProject>( this.databaseManager, 
                        ProjectsCollection, 
                        HealthguardProjectDocument,
                        [HealthguardProjectDocument,ProjectDocument],
                        this._projectsCollectionGroup.allowRootCollection, 
                        this._projectsCollectionGroup.encrypted,   
                        owner );

                this._projectsSubcollectionGroups.set( owner.databasePath(),  projectsCollectionGroup ); 
            }
        }
        return projectsCollectionGroup;
    }

 
 
    unitsCollectionGroup( owner? : DatabaseDocument ) : CollectionGroupDatabase<HealthguardUnit> {

        let unitsCollectionGroup;

        if( owner == null ) {
            unitsCollectionGroup = this._unitsCollectionGroup;
        }
        else {
            unitsCollectionGroup = this._unitsSubcollectionGroups.get( owner.databasePath() );

            if( unitsCollectionGroup == null ) {

                unitsCollectionGroup =
                    new CollectionGroupDatabase<HealthguardUnit>( this.databaseManager, 
                        UnitsCollection, 
                        HealthguardUnitDocument,
                        [HealthguardUnitDocument,UnitDocument],
                        this._unitsCollectionGroup.allowRootCollection, 
                        this._unitsCollectionGroup.encrypted,   
                        owner );

                this._unitsSubcollectionGroups.set( owner.databasePath(), unitsCollectionGroup );
            }
        }
        return unitsCollectionGroup;
    }

    quarantineRegistrationsCollectionGroup( owner? : DatabaseDocument ) : CollectionGroupDatabase<QuarantineRegistration> {

        let quarantineRegistrationsCollectionGroup;

        if( owner == null ) {
            quarantineRegistrationsCollectionGroup = this._quarantineRegistrationsCollectionGroup;
        }
        else {
            quarantineRegistrationsCollectionGroup = this._quarantineRegistrationsSubcollectionGroups.get( owner.databasePath() );

            if( quarantineRegistrationsCollectionGroup == null ) {

                quarantineRegistrationsCollectionGroup =
                    new CollectionGroupDatabase<QuarantineRegistration>( this.databaseManager, 
                        RegistrationsCollection, 
                        QuarantineRegistrationDocument,
                        [QuarantineRegistrationDocument],
                        this._quarantineRegistrationsCollectionGroup.allowRootCollection, 
                        this._quarantineRegistrationsCollectionGroup.encrypted,   
                        owner );

                this._quarantineRegistrationsSubcollectionGroups.set( owner.databasePath(),  quarantineRegistrationsCollectionGroup );
            }
        }
        return quarantineRegistrationsCollectionGroup;
    }

    registrationsCollectionGroups( owner? : DatabaseDocument ) : CollectionGroupDatabase<Registration>[] {

        return ([
            this.incidentRegistrationsCollectionGroup( owner ),
            this.quarantineRegistrationsCollectionGroup( owner ),
            this.sickLeaveRegistrationsCollectionGroup( owner ),
            this.symptomRegistrationsCollectionGroup( owner ),
            this.testRegistrationsCollectionGroup( owner ),
            this.vaccineRegistrationsCollectionGroup( owner )
        ]);
    }

    registrationsCollectionGroup( owner? : DatabaseDocument ) : CollectionGroupDatabase<Registration> {

        let registrationsCollectionGroup;

        if( owner == null ) {
            registrationsCollectionGroup = this._registrationsCollectionGroup;
        }
        else {
            registrationsCollectionGroup = this._registrationsSubcollectionGroups.get( owner.databasePath() );

            if( registrationsCollectionGroup == null ) {

                registrationsCollectionGroup =
                    new CollectionGroupDatabase<Registration>( 
                        this.databaseManager, 
                        RegistrationsCollection, 
                        undefined,
                        [RegistrationDocument],
                        this._registrationsCollectionGroup.allowRootCollection, 
                        this._registrationsCollectionGroup.encrypted, 
                        owner );

                this._registrationsSubcollectionGroups.set( owner.databasePath(),  registrationsCollectionGroup );
            }
        }
        return registrationsCollectionGroup;
    }

    residencesCollectionGroup( owner? : DatabaseDocument ) : CollectionGroupDatabase<Residence> {

        let residencesCollectionGroup;

        if( owner == null ) {
            residencesCollectionGroup = this._residencesCollectionGroup;
        }
        else {
            residencesCollectionGroup = this._residencesSubcollectionGroups.get( owner.databasePath() );

            if( residencesCollectionGroup == null ) {

                residencesCollectionGroup =
                    new CollectionGroupDatabase<Residence>( 
                        this.databaseManager, 
                        ResidencesCollection, 
                        ResidenceDocument,
                        [ResidenceDocument],
                        this._residencesCollectionGroup.allowRootCollection, 
                        this._residencesCollectionGroup.encrypted, 
                        owner );

                this._residencesSubcollectionGroups.set( owner.databasePath(),  residencesCollectionGroup );
            }
        }
        return residencesCollectionGroup;
    }

    sickLeaveRegistrationsCollectionGroup( owner? : DatabaseDocument ) : CollectionGroupDatabase<SickLeaveRegistration> {

        let sickLeaveRegistrationsCollectionGroup;

        if( owner == null ) {
            sickLeaveRegistrationsCollectionGroup = this._sickLeaveRegistrationsCollectionGroup;
        }
        else {
            sickLeaveRegistrationsCollectionGroup = this._sickLeaveRegistrationsSubcollectionGroups.get( owner.databasePath() );

            if( sickLeaveRegistrationsCollectionGroup == null ) {

                sickLeaveRegistrationsCollectionGroup =
                    new CollectionGroupDatabase<SickLeaveRegistration>( this.databaseManager, 
                        RegistrationsCollection, 
                        SickLeaveRegistrationDocument,
                        [SickLeaveRegistrationDocument],
                        this._sickLeaveRegistrationsCollectionGroup.allowRootCollection, 
                        this._sickLeaveRegistrationsCollectionGroup.encrypted, 
                        owner );

                this._sickLeaveRegistrationsSubcollectionGroups.set( owner.databasePath(),  sickLeaveRegistrationsCollectionGroup );
            }
        }
        return sickLeaveRegistrationsCollectionGroup;
    }

    subscriptionsCollectionGroup( owner? : DatabaseDocument ) : CollectionGroupDatabase<Subscription> {

        let subscriptionsCollectionGroup;

        if( owner == null ) {
            subscriptionsCollectionGroup = this._subscriptionsCollectionGroup;
        }
        else {
            subscriptionsCollectionGroup = this._subscriptionsSubcollectionGroups.get( owner.databasePath() );

            if( subscriptionsCollectionGroup == null ) {

                subscriptionsCollectionGroup =
                    new CollectionGroupDatabase<Subscription>( this.databaseManager, 
                        SubscriptionsCollection, 
                        SubscriptionDocument,
                        [SubscriptionDocument],
                        this._subscriptionsCollectionGroup.allowRootCollection, 
                        this._subscriptionsCollectionGroup.encrypted, 
                        owner );

                this._subscriptionsSubcollectionGroups.set( owner.databasePath(), subscriptionsCollectionGroup );
            }
        }
        return subscriptionsCollectionGroup;
    }
    
    symptomRegistrationsCollectionGroup( owner? : DatabaseDocument ) : CollectionGroupDatabase<SymptomRegistration> {

        let symptomRegistrationsCollectionGroup;

        if( owner == null ) {
            symptomRegistrationsCollectionGroup = this._symptomRegistrationsCollectionGroup;
        }
        else {
            symptomRegistrationsCollectionGroup = this._symptomRegistrationsSubcollectionGroups.get( owner.databasePath() );

            if( symptomRegistrationsCollectionGroup == null ) {

                symptomRegistrationsCollectionGroup =
                    new CollectionGroupDatabase<SymptomRegistration>( this.databaseManager, 
                        RegistrationsCollection, 
                        SymptomRegistrationDocument,
                        [SymptomRegistrationDocument],
                        this._symptomRegistrationsCollectionGroup.allowRootCollection, 
                        this._symptomRegistrationsCollectionGroup.encrypted, 
                        owner );

                this._symptomRegistrationsSubcollectionGroups.set( owner.databasePath(), symptomRegistrationsCollectionGroup );
            }
        }
        return symptomRegistrationsCollectionGroup;
    }
   
    terminalsCollectionGroup( owner? : DatabaseDocument ) : CollectionGroupDatabase<Terminal> {

        let terminalsCollectionGroup;

        if( owner == null ) {
            terminalsCollectionGroup = this._terminalsCollectionGroup;
        }
        else {
            terminalsCollectionGroup = this._terminalsSubcollectionGroups.get( owner.databasePath() );

            if( terminalsCollectionGroup == null ) {

                terminalsCollectionGroup =
                    new CollectionGroupDatabase<Terminal>( this.databaseManager, 
                        TerminalsCollection, 
                        TerminalDocument,
                        [TerminalDocument],
                        this._terminalsCollectionGroup.allowRootCollection,
                        this._terminalsCollectionGroup.encrypted,   
                        owner );

                this._terminalsSubcollectionGroups.set( owner.databasePath(),  terminalsCollectionGroup );
            }
        }
        return terminalsCollectionGroup;
    }
    testRegistrationsCollectionGroup( owner? : DatabaseDocument ) : CollectionGroupDatabase<TestRegistration> {

        let testsCollectionGroup;

        if( owner == null ) {
            testsCollectionGroup = this._testRegistrationsCollectionGroup;
        }
        else {
            testsCollectionGroup = this._testRegistrationsSubcollectionGroups.get( owner.databasePath() );

            if( testsCollectionGroup == null ) {

                testsCollectionGroup =
                    new CollectionGroupDatabase<TestRegistration>( this.databaseManager, 
                        RegistrationsCollection, 
                        TestRegistrationDocument,
                        [TestRegistrationDocument],
                        this._testRegistrationsCollectionGroup.allowRootCollection, 
                        this._testRegistrationsCollectionGroup.encrypted, 
                        owner );

                this._testRegistrationsSubcollectionGroups.set( owner.databasePath(), testsCollectionGroup );
            }
        }
        return testsCollectionGroup;
    }

    usersCollectionGroup( owner? : DatabaseDocument ) : CollectionGroupDatabase<HealthguardUser> {

        let usersCollectionGroup;

        if( owner == null ) {
            usersCollectionGroup = this._usersCollectionGroup;
        }
        else {
            usersCollectionGroup = this._usersSubcollectionGroups.get( owner.databasePath() );

            if( usersCollectionGroup == null ) {

                usersCollectionGroup =
                    new CollectionGroupDatabase<HealthguardUser>( this.databaseManager, 
                        UsersCollection, 
                        HealthguardUserDocument,
                        [HealthguardUserDocument,UserDocument],
                        this._usersCollectionGroup.allowRootCollection, 
                        this._usersCollectionGroup.encrypted, 
                        owner );

                this._usersSubcollectionGroups.set( owner.databasePath(), usersCollectionGroup );
            }
        }
        return usersCollectionGroup;
    }

    vaccineRegistrationsCollectionGroup( owner? : DatabaseDocument ) : CollectionGroupDatabase<VaccineRegistration> {

        let vaccinesCollectionGroup;

        if( owner == null ) {
            vaccinesCollectionGroup = this._vaccineRegistrationsCollectionGroup;
        }
        else {
            vaccinesCollectionGroup = this._vaccineRegistrationsSubcollectionGroups.get( owner.databasePath() );

            if( vaccinesCollectionGroup == null ) {

                vaccinesCollectionGroup =
                    new CollectionGroupDatabase<VaccineRegistration>( this.databaseManager, 
                        RegistrationsCollection, 
                        VaccineRegistrationDocument,
                        [VaccineRegistrationDocument],
                        this._vaccineRegistrationsCollectionGroup.allowRootCollection, 
                        this._vaccineRegistrationsCollectionGroup.encrypted, 
                        owner );

                this._vaccineRegistrationsSubcollectionGroups.set( owner.databasePath(), vaccinesCollectionGroup );
            }
        }
        return vaccinesCollectionGroup;
    }

    collectionDatabaseFromCollectionName(
        collectionName: HealthguardCollectionName, 
        owner?: DatabaseDocument): CollectionDatabase<DatabaseDocument> {

        switch (collectionName) {

            case AlertsCollection:
                return this.alertsCollection(owner);

            case CategoriesCollection:
                return this.categoriesCollection(owner);

            case ChangesCollection:
                return this.changesCollection(owner);

            case CompaniesCollection:
                return this.companiesCollection(owner);
            
            case ConsentsCollection:
                return this.consentsCollection(owner);

            case DevicesCollection:
                return this.devicesCollection(owner);
    
            case HazardsCollection:
                return this.hazardsCollection(owner);

            case GatheringsCollection:
                return this.gatheringsCollection(owner);

            case RisksCollection:
                return this.risksCollection(owner);
       
            case IncidentRegistrationsCollection:
                return this.incidentRegistrationsCollection(owner);

            case KeysCollection:
                return this.keysCollection(owner);

            case LocationsCollection:
                return this.locationsCollection(owner);

            case MeasuresCollection:
                return this.measuresCollection(owner);

            case MessagesCollection:
                return this.messagesCollection(owner);
    
            case ProjectsCollection:
                return this.projectsCollection(owner);
    
            case UnitsCollection:
                return this.unitsCollection(owner);

            case QuarantineRegistrationsCollection:
                return this.quarantineRegistrationsCollection(owner);

            case RegistrationsCollection:
                return this.registrationsCollection(owner);

            case ResidencesCollection:
                return this.residencesCollection(owner);

            case SickLeaveRegistrationsCollection:
                return this.sickLeaveRegistrationsCollection(owner);

            case SubscriptionsCollection:
                return this.subscriptionsCollection(owner);
   
            case SymptomRegistrationsCollection:
                return this.symptomRegistrationsCollection(owner);

            case TerminalsCollection:
                return this.terminalsCollection(owner);

            case TestRegistrationsCollection:
                return this.testRegistrationsCollection(owner);

            case UsersCollection:
                return this.usersCollection(owner);

            case VaccineRegistrationsCollection:
                return this.vaccineRegistrationsCollection(owner);

            default:
                throw new Error("Collection not recognized: " + collectionName);
        }
    }

    collectionDatabaseFromDocumentName( 
        documentName : HealthguardDocumentName, 
        owner? : DatabaseDocument ) : CollectionDatabase<DatabaseDocument> {
        
        switch( documentName ) {

            case AlertDocument:
            case HealthguardAlertDocument:
                return this.alertsCollection( owner );
    
            case CategoryDocument:
            case HealthguardCategoryDocument:
                return this.categoriesCollection( owner );
        
            case ChangeDocument:
                return this.changesCollection( owner );

            case CompanyDocument:
            case HealthguardCompanyDocument:
                return this.companiesCollection( owner );

            case ConsentDocument:
                return this.consentsCollection( owner );


            case DeviceDocument:
            case HealthguardDeviceDocument:
                return this.devicesCollection( owner );
            
            case HazardDocument:
                return this.hazardsCollection( owner );

            case GatheringDocument:
                return this.gatheringsCollection( owner );

            case RiskDocument:
                return this.risksCollection( owner );

            case IncidentRegistrationDocument:
                return this.incidentRegistrationsCollection( owner );
              
            case KeyDocument:
                return this.keysCollection( owner );
    
            case LocationDocument:
            case HealthguardLocationDocument:
                return this.locationsCollection( owner );
    
            case MeasureDocument:
                return this.measuresCollection( owner );

            case MessageDocument:
                return this.messagesCollection( owner );
   
            case ProjectDocument:
            case HealthguardProjectDocument:
                return this.projectsCollection( owner );

            case UnitDocument:
            case HealthguardUnitDocument:
                return this.unitsCollection( owner );

            case QuarantineRegistrationDocument:
                return this.quarantineRegistrationsCollection( owner );
        
            case RegistrationDocument:
                return this.registrationsCollection( owner );
    
            case ResidenceDocument:
                return this.residencesCollection( owner );
    
            case SickLeaveRegistrationDocument:
                return this.sickLeaveRegistrationsCollection( owner );

            case SubscriptionDocument:
                return this.subscriptionsCollection( owner );
    
            case SymptomRegistrationDocument:
                return this.symptomRegistrationsCollection( owner );
 
            case TerminalDocument:
                return this.terminalsCollection( owner );
       
            case TestRegistrationDocument:
                return this.testRegistrationsCollection( owner );
            
            case UserDocument:
            case HealthguardUserDocument:
                return this.usersCollection( owner );
            
            case VaccineRegistrationDocument:
                return this.vaccineRegistrationsCollection( owner );
            
            default:
                throw new Error( "Document name not recognized: " + documentName );
        }
    }

    alertsCollection( owner? : DatabaseDocument ) : CollectionDatabase<Alert> {

        let alertsCollection; 

        if( owner == null ) {
            alertsCollection = this._alertsCollection;
        }
        else {
            alertsCollection = this._alertsSubcollections.get( owner.databasePath() );

            if( alertsCollection == null ) {

                alertsCollection =
                    new CollectionDatabase<Alert>( 
                        this.databaseManager, 
                        AlertsCollection, 
                        HealthguardAlertDocument,
                        [HealthguardAlertDocument,AlertDocument],
                        this.rootCollectionNames().includes( AlertsCollection ), 
                        this.encryptedCollectionNames().includes( AlertsCollection ),
                        owner );

                this._alertsSubcollections.set( owner.databasePath(),  alertsCollection );
            }
        }
        return alertsCollection;
    }


    categoriesCollection( owner? : DatabaseDocument ) : CollectionDatabase<HealthguardCategory> {

        let categoriesCollection;

        if( owner == null ) {
            categoriesCollection = this._categoriesCollection;
        }
        else {
            categoriesCollection = this._categoriesSubcollections.get( owner.databasePath() );

            if( categoriesCollection == null ) {

                categoriesCollection =
                    new CollectionDatabase<HealthguardCategory>( 
                        this.databaseManager, 
                        CategoriesCollection, 
                        HealthguardCategoryDocument, 
                        [HealthguardCategoryDocument,CategoryDocument], 
                        this.rootCollectionNames().includes( CategoriesCollection ),
                        this.encryptedCollectionNames().includes( CategoriesCollection ), 
                        owner );

                this._categoriesSubcollections.set( owner.databasePath(),  categoriesCollection );
            }
        }
        return categoriesCollection;
    }
    
    changesCollection( owner? : DatabaseDocument ) : CollectionDatabase<Change> {

        let changesCollection;

        if( owner == null ) {
            changesCollection = this._changesCollection;
        }
        else {
            changesCollection = this._changesSubcollections.get( owner.databasePath() );

            if( changesCollection == null ) {

                changesCollection =
                    new CollectionDatabase<Change>( 
                        this.databaseManager, 
                        ChangesCollection, 
                        ChangeDocument,
                        [ChangeDocument], 
                        this.rootCollectionNames().includes( ChangesCollection ),
                        this.encryptedCollectionNames().includes( ChangesCollection ),
                        owner );

                this._changesSubcollections.set( owner.databasePath(), changesCollection );
            }
        }
        return changesCollection;
    }


    companiesCollection( owner? : DatabaseDocument ) : CollectionDatabase<HealthguardCompany> {

        let companiesCollection;

        if( owner == null ) {
            companiesCollection = this._companiesCollection;
        }
        else {
            companiesCollection = this._companiesSubcollections.get( owner.databasePath() );

            if( companiesCollection == null ) {

                companiesCollection =
                    new CollectionDatabase<HealthguardCompany>( 
                        this.databaseManager, 
                        CompaniesCollection, 
                        HealthguardCompanyDocument,
                        [HealthguardCompanyDocument,CompanyDocument],
                        this.rootCollectionNames().includes( CompaniesCollection ),
                        this.encryptedCollectionNames().includes( CompaniesCollection ),
                        owner );

                this._companiesSubcollections.set( owner.databasePath(), companiesCollection );
            }
        }
        return companiesCollection;
    }

    consentsCollection( owner? : DatabaseDocument ) : CollectionDatabase<Consent> {

        let consentsCollection;

        if( owner == null ) {
            consentsCollection = this._consentsCollection;
        }
        else {
            consentsCollection = this._consentsSubcollections.get( owner.databasePath() );

            if( consentsCollection == null ) {

                consentsCollection =
                    new CollectionDatabase<Consent>( 
                        this.databaseManager, 
                        ConsentsCollection, 
                        ConsentDocument,
                        [ConsentDocument], 
                        this.rootCollectionNames().includes( ConsentsCollection ), 
                        this.encryptedCollectionNames().includes( ConsentsCollection ),
                        owner );

                this._consentsSubcollections.set( owner.databasePath(),  consentsCollection );
            }
        }
        return consentsCollection;
    }

    devicesCollection( owner? : DatabaseDocument ) : CollectionDatabase<HealthguardDevice> {

        let devicesCollection;

        if( owner == null ) {
            devicesCollection = this._devicesCollection;
        }
        else {
            devicesCollection = this._devicesSubcollections.get( owner.databasePath() );

            if( devicesCollection == null ) {

                devicesCollection =
                    new CollectionDatabase<HealthguardDevice>( 
                        this.databaseManager, 
                        DevicesCollection, 
                        HealthguardDeviceDocument, 
                        [HealthguardDeviceDocument,DeviceDocument], 
                        this.rootCollectionNames().includes( DevicesCollection ),
                        this.encryptedCollectionNames().includes( DevicesCollection ),
                        owner );

                this._devicesSubcollections.set( owner.databasePath(),  devicesCollection );
            }
        }
        return devicesCollection;
    }
    

    hazardsCollection( owner? : DatabaseDocument ) : CollectionDatabase<Hazard> {

        let hazardsCollection;

        if( owner == null ) {
            hazardsCollection = this._hazardsCollection;
        }
        else {
            hazardsCollection = this._hazardsSubcollections.get( owner.databasePath() );

            if( hazardsCollection == null ) {

                hazardsCollection =
                    new CollectionDatabase<Hazard>( 
                        this.databaseManager, 
                        HazardsCollection, 
                        HazardDocument,
                        [HazardDocument], 
                        this.rootCollectionNames().includes( HazardsCollection ),
                        this.encryptedCollectionNames().includes( HazardsCollection ),
                        owner );

                this._hazardsSubcollections.set( owner.databasePath(), hazardsCollection );
            }
        }
        return hazardsCollection;
    }

    gatheringsCollection( owner? : DatabaseDocument ) : CollectionDatabase<Gathering> {

        let gatheringsCollection;

        if( owner == null ) {
            gatheringsCollection = this._gatheringsCollection;
        }
        else {
            gatheringsCollection = this._gatheringsSubcollections.get( owner.databasePath() );

            if( gatheringsCollection == null ) {

                gatheringsCollection =
                    new CollectionDatabase<Gathering>( 
                        this.databaseManager, 
                        GatheringsCollection, 
                        GatheringDocument,
                        [GatheringDocument], 
                        this.rootCollectionNames().includes( GatheringsCollection ),
                        this.encryptedCollectionNames().includes( GatheringsCollection ),
                        owner );

                this._gatheringsSubcollections.set( owner.databasePath(),  gatheringsCollection );
            }
        }
        return gatheringsCollection;
    }
    
    risksCollection( owner? : DatabaseDocument ) : CollectionDatabase<Risk> {

        let risksCollection;

        if( owner == null ) {
            risksCollection = this._risksCollection;
        }
        else {
            risksCollection = this._risksSubcollections.get( owner.databasePath() );

            if( risksCollection == null ) {

                risksCollection =
                    new CollectionDatabase<Risk>( 
                        this.databaseManager, 
                        RisksCollection, 
                        RiskDocument,
                        [RiskDocument], 
                        this.rootCollectionNames().includes( RisksCollection ),
                        this.encryptedCollectionNames().includes( RisksCollection ),
                        owner );

                this._risksSubcollections.set( owner.databasePath(),  risksCollection );
            }
        }
        return risksCollection;
    }
    


    incidentRegistrationsCollection( owner? : DatabaseDocument ) : CollectionDatabase<IncidentRegistration> {

        let incidentRegistrationsCollection;

        if( owner == null ) {
            incidentRegistrationsCollection = this._incidentRegistrationsCollection;
        }
        else {
            incidentRegistrationsCollection = this._incidentRegistrationsSubcollections.get( owner.databasePath() );

            if( incidentRegistrationsCollection == null ) {

                incidentRegistrationsCollection =
                    new CollectionDatabase<IncidentRegistration>( 
                        this.databaseManager, 
                        RegistrationsCollection, 
                        IncidentRegistrationDocument,
                        [IncidentRegistrationDocument], 
                        this.rootCollectionNames().includes( RegistrationsCollection ), 
                        this.encryptedCollectionNames().includes( RegistrationsCollection ),
                        owner );

                this._incidentRegistrationsSubcollections.set( owner.databasePath(), incidentRegistrationsCollection );
            }
        }
        return incidentRegistrationsCollection;
    }

    keysCollection( owner? : DatabaseDocument ) : CollectionDatabase<Key> {

        let keysCollection;

        if( owner == null ) {
            keysCollection = this._keysCollection;
        }
        else {
            keysCollection = this._keysSubcollections.get( owner.databasePath() );

            if( keysCollection == null ) {

                keysCollection =
                    new CollectionDatabase<Key>( 
                        this.databaseManager, 
                        KeysCollection, 
                        KeyDocument,
                        [KeyDocument], 
                        this.rootCollectionNames().includes( KeysCollection ), 
                        this.encryptedCollectionNames().includes( KeysCollection ),
                        owner );

                this._keysSubcollections.set( owner.databasePath(),  keysCollection );
            }
        }
        return keysCollection;
    }

    locationsCollection( owner? : DatabaseDocument ) : CollectionDatabase<HealthguardLocation> {

        let locationsCollection;

        if( owner == null ) {
            locationsCollection = this._locationsCollection;
        }
        else {
            locationsCollection = this._locationsSubcollections.get( owner.databasePath() );

            if( locationsCollection == null ) {

                locationsCollection =
                    new CollectionDatabase<HealthguardLocation>( 
                        this.databaseManager, 
                        LocationsCollection, 
                        HealthguardLocationDocument,
                        [HealthguardLocationDocument,LocationDocument], 
                        this.rootCollectionNames().includes( LocationsCollection ), 
                        this.encryptedCollectionNames().includes( LocationsCollection ),
                        owner );

                this._locationsSubcollections.set( owner.databasePath(),  locationsCollection );
            }
        }
        return locationsCollection;
    }

    measuresCollection( owner? : DatabaseDocument ) : CollectionDatabase<Measure> {

        let measuresCollection;

        if( owner == null ) {
            measuresCollection = this._measuresCollection;
        }
        else {
            measuresCollection = this._measuresSubcollections.get( owner.databasePath() );

            if( measuresCollection == null ) {

                measuresCollection =
                    new CollectionDatabase<Measure>( 
                        this.databaseManager, 
                        MeasuresCollection, 
                        MeasureDocument,
                        [MeasureDocument], 
                        this.rootCollectionNames().includes( MeasuresCollection ), 
                        this.encryptedCollectionNames().includes( MeasuresCollection ),
                        owner );

                this._measuresSubcollections.set( owner.databasePath(),  measuresCollection );
            }
        }
        return measuresCollection;
    }

    messagesCollection( owner? : DatabaseDocument ) : CollectionDatabase<Message> {

        let messagesCollection;

        if( owner == null ) {
            messagesCollection = this._messagesCollection;
        }
        else {
            messagesCollection = this._messagesSubcollections.get( owner.databasePath() );

            if( messagesCollection == null ) {

                messagesCollection =
                    new CollectionDatabase<Message>( 
                        this.databaseManager, 
                        MessagesCollection, 
                        MessageDocument,
                        [MessageDocument], 
                        this.rootCollectionNames().includes( MessagesCollection ), 
                        this.encryptedCollectionNames().includes( MessagesCollection ),
                        owner );

                this._messagesSubcollections.set( owner.databasePath(),  messagesCollection );
            }
        }
        return messagesCollection;
    }
    projectsCollection( owner? : DatabaseDocument ) : CollectionDatabase<HealthguardProject> {

        let projectsCollection;

        if( owner == null ) {
            projectsCollection = this._projectsCollection;
        }
        else {
            projectsCollection = this._projectsSubcollections.get( owner.databasePath() );

            if( projectsCollection == null ) {

                projectsCollection =
                    new CollectionDatabase<HealthguardProject>( 
                        this.databaseManager, 
                        ProjectsCollection, 
                        HealthguardProjectDocument,
                        [HealthguardProjectDocument,ProjectDocument], 
                        this.rootCollectionNames().includes( ProjectsCollection ), 
                        this.encryptedCollectionNames().includes( ProjectsCollection ),
                        owner );

                this._projectsSubcollections.set( owner.databasePath(),  projectsCollection ); 
            }
        }
        return projectsCollection;
    }

    quarantineRegistrationsCollection( owner? : DatabaseDocument ) : CollectionDatabase<QuarantineRegistration> {

        let quarantineRegistrationsCollection;

        if( owner == null ) {
            quarantineRegistrationsCollection = this._quarantineRegistrationsCollection;
        }
        else {
            quarantineRegistrationsCollection = this._quarantineRegistrationsSubcollections.get( owner.databasePath() );

            if( quarantineRegistrationsCollection == null ) {

                quarantineRegistrationsCollection =
                    new CollectionDatabase<QuarantineRegistration>( 
                        this.databaseManager, 
                        RegistrationsCollection, 
                        QuarantineRegistrationDocument,
                        [QuarantineRegistrationDocument], 
                        this.rootCollectionNames().includes( RegistrationsCollection ), 
                        this.encryptedCollectionNames().includes( RegistrationsCollection ),
                        owner );

                this._quarantineRegistrationsSubcollections.set( owner.databasePath(),  quarantineRegistrationsCollection );
            }
        }
        return quarantineRegistrationsCollection;
    }

    registrationsCollections( owner? : DatabaseDocument ) : CollectionDatabase<Registration>[] {

        return ([
            this.incidentRegistrationsCollection( owner ),
            this.quarantineRegistrationsCollection( owner ),
            this.sickLeaveRegistrationsCollection( owner ),
            this.symptomRegistrationsCollection( owner ),
            this.testRegistrationsCollection( owner ),
            this.vaccineRegistrationsCollection( owner )
        ]);
    }

    registrationsCollection( owner? : DatabaseDocument ) : CollectionDatabase<Registration> {

        let registrationsCollection;

        if( owner == null ) {
            registrationsCollection = this._registrationsCollection;
        }
        else {
            registrationsCollection = this._registrationsSubcollections.get( owner.databasePath() );

            if( registrationsCollection == null ) {

                registrationsCollection =
                    new CollectionDatabase<Registration>( 
                        this.databaseManager, 
                        RegistrationsCollection, 
                        undefined,
                       [RegistrationDocument], 
                        this.rootCollectionNames().includes( RegistrationsCollection ), 
                        this.encryptedCollectionNames().includes( RegistrationsCollection ),
                        owner );

                this._registrationsSubcollections.set( owner.databasePath(), registrationsCollection );
            }
        }
        return registrationsCollection;
    }


    residencesCollection( owner? : DatabaseDocument ) : CollectionDatabase<Residence> {

        let residencesCollection;

        if( owner == null ) {
            residencesCollection = this._residencesCollection;
        }
        else {
            residencesCollection = this._residencesSubcollections.get( owner.databasePath() );

            if( residencesCollection == null ) {

                residencesCollection =
                    new CollectionDatabase<Residence>( 
                        this.databaseManager, 
                        ResidencesCollection, 
                        ResidenceDocument,
                        [ResidenceDocument], 
                        this.rootCollectionNames().includes( ResidencesCollection ), 
                        this.encryptedCollectionNames().includes( ResidencesCollection ),
                        owner );

                this._residencesSubcollections.set( owner.databasePath(),  residencesCollection );
            }
        }
        return residencesCollection;
    }

    sickLeaveRegistrationsCollection( owner? : DatabaseDocument ) : CollectionDatabase<SickLeaveRegistration> {

        let sickLeaveRegistrationsCollection;

        if( owner == null ) {
            sickLeaveRegistrationsCollection = this._sickLeaveRegistrationsCollection;
        }
        else {
            sickLeaveRegistrationsCollection = this._sickLeaveRegistrationsSubcollections.get( owner.databasePath() );

            if( sickLeaveRegistrationsCollection == null ) {

                sickLeaveRegistrationsCollection =
                    new CollectionDatabase<SickLeaveRegistration>( 
                        this.databaseManager, 
                        RegistrationsCollection, 
                        SickLeaveRegistrationDocument,
                        [SickLeaveRegistrationDocument], 
                        this.rootCollectionNames().includes( RegistrationsCollection ),                         
                        this.encryptedCollectionNames().includes( RegistrationsCollection ),
                        owner );

                this._sickLeaveRegistrationsSubcollections.set( owner.databasePath(),  sickLeaveRegistrationsCollection );
            }
        }
        return sickLeaveRegistrationsCollection;
    }

    subscriptionsCollection( owner? : DatabaseDocument ) : CollectionDatabase<Subscription> {

        let subscriptionsCollection;

        if( owner == null ) {
            subscriptionsCollection = this._subscriptionsCollection;
        }
        else {
            subscriptionsCollection = this._subscriptionsSubcollections.get( owner.databasePath() );

            if( subscriptionsCollection == null ) {

                subscriptionsCollection =
                    new CollectionDatabase<Subscription>( 
                        this.databaseManager, 
                        SubscriptionsCollection, 
                        SubscriptionDocument,
                        [SubscriptionDocument], 
                        this.rootCollectionNames().includes( SubscriptionsCollection ),                         
                        this.encryptedCollectionNames().includes( SubscriptionsCollection ),
                        owner );

                this._subscriptionsSubcollections.set( owner.databasePath(), subscriptionsCollection );
            }
        }
        return subscriptionsCollection;
    }


    symptomRegistrationsCollection( owner? : DatabaseDocument ) : CollectionDatabase<SymptomRegistration> {

        let symptomRegistrationsCollection;

        if( owner == null ) {
            symptomRegistrationsCollection = this._symptomRegistrationsCollection;
        }
        else {
            symptomRegistrationsCollection = this._symptomRegistrationsSubcollections.get( owner.databasePath() );

            if( symptomRegistrationsCollection == null ) {

                symptomRegistrationsCollection =
                    new CollectionDatabase<SymptomRegistration>( 
                        this.databaseManager, 
                        RegistrationsCollection, 
                        SymptomRegistrationDocument,
                        [SymptomRegistrationDocument], 
                        this.rootCollectionNames().includes( RegistrationsCollection ), 
                        this.encryptedCollectionNames().includes( RegistrationsCollection ),
                        owner );

                this._symptomRegistrationsSubcollections.set( owner.databasePath(), symptomRegistrationsCollection );
            }
        }
        return symptomRegistrationsCollection;
    }

    terminalsCollection( owner? : DatabaseDocument ) : CollectionDatabase<Terminal> {

        let terminalsCollection;

        if( owner == null ) {
            terminalsCollection = this._terminalsCollection;
        }
        else {
            terminalsCollection = this._terminalsSubcollections.get( owner.databasePath() );

            if( terminalsCollection == null ) {

                terminalsCollection =
                    new CollectionDatabase<Terminal>( 
                        this.databaseManager, 
                        TerminalsCollection, 
                        TerminalDocument,
                        [TerminalDocument], 
                        this.rootCollectionNames().includes( TerminalsCollection ), 
                        this.encryptedCollectionNames().includes( TerminalsCollection ),
                        owner );

                this._terminalsSubcollections.set( owner.databasePath(),  terminalsCollection );
            }
        }
        return terminalsCollection;
    }
        
    testRegistrationsCollection( owner? : DatabaseDocument ) : CollectionDatabase<TestRegistration> {

        let testRegistrationsCollection;

        if( owner == null ) {
            testRegistrationsCollection = this._testRegistrationsCollection;
        }
        else {
            testRegistrationsCollection = this._testRegistrationsSubcollections.get( owner.databasePath() );

            if( testRegistrationsCollection == null ) {

                testRegistrationsCollection =
                    new CollectionDatabase<TestRegistration>( 
                        this.databaseManager, 
                        RegistrationsCollection, 
                        TestRegistrationDocument,
                        [TestRegistrationDocument], 
                        this.rootCollectionNames().includes( RegistrationsCollection ),
                        this.encryptedCollectionNames().includes( RegistrationsCollection ), 
                        owner );

                this._testRegistrationsSubcollections.set( owner.databasePath(), testRegistrationsCollection );
            }
        }
        return testRegistrationsCollection;
    }

    unitsCollection( owner? : DatabaseDocument ) : CollectionDatabase<HealthguardUnit> {

        let unitsCollection;

        if( owner == null ) {
            unitsCollection = this._unitsCollection;
        }
        else {
            unitsCollection = this._unitsSubcollections.get( owner.databasePath() );

            if( unitsCollection == null ) {

                unitsCollection =
                    new CollectionDatabase<HealthguardUnit>( 
                        this.databaseManager, 
                        UnitsCollection, 
                        HealthguardUnitDocument,
                        [HealthguardUnitDocument,UnitDocument], 
                        this.rootCollectionNames().includes( UnitsCollection ), 
                        this.encryptedCollectionNames().includes( UnitsCollection ), 
                        owner );

                this._unitsSubcollections.set( owner.databasePath(), unitsCollection );
            }
        }
        return unitsCollection;
    }

    usersCollection( owner? : DatabaseDocument ) : CollectionDatabase<HealthguardUser> {

        let usersCollection;

        if( owner == null ) {
            usersCollection = this._usersCollection;
        }
        else {
            usersCollection = this._usersSubcollections.get( owner.databasePath() );

            if( usersCollection == null ) {

                usersCollection =
                    new CollectionDatabase<HealthguardUser>( 
                        this.databaseManager, 
                        UsersCollection, 
                        HealthguardUserDocument,
                        [HealthguardUserDocument,UserDocument], 
                        this.rootCollectionNames().includes( UsersCollection ), 
                        this.encryptedCollectionNames().includes( UsersCollection ), 
                        owner );

                this._usersSubcollections.set( owner.databasePath(), usersCollection );
            }
        }
        return usersCollection;
    }

    vaccineRegistrationsCollection( owner? : DatabaseDocument ) : CollectionDatabase<VaccineRegistration> {

        let vaccinesCollection;

        if( owner == null ) {
            vaccinesCollection = this._vaccineRegistrationsCollection;
        }
        else {
            vaccinesCollection = this._vaccineRegistrationsSubcollections.get( owner.databasePath() );

            if( vaccinesCollection == null ) {

                vaccinesCollection =
                    new CollectionDatabase<VaccineRegistration>(
                        this.databaseManager, 
                        RegistrationsCollection, 
                        VaccineRegistrationDocument,
                        [VaccineRegistrationDocument], 
                        this.rootCollectionNames().includes( RegistrationsCollection ), 
                        this.encryptedCollectionNames().includes( RegistrationsCollection ), 
                        owner );

                this._vaccineRegistrationsSubcollections.set( owner.databasePath(), vaccinesCollection );
            }
        }
        return vaccinesCollection;
    }


    newDocument( collectionDatabase : CollectionDatabase<DatabaseDocument>, documentPath? : string ) : DatabaseDocument {

        let documentName = collectionDatabase.defaultDocumentName();

        if( documentPath != null ) {

            const urlDocumentName = this.documentNameFromUrl( documentPath );

            if( urlDocumentName != null ) {
                documentName = urlDocumentName;
            }
        }

        if( documentName == null ) {
            throw new Error( "No document name for database collection");
        }
        
        switch( documentName ) {
 
            case CategoryDocument:
            case HealthguardCategoryDocument:
                return new HealthguardCategory( collectionDatabase as CollectionDatabase<HealthguardCategory>, documentPath );
                    
            case ChangeDocument:
                return new Change( collectionDatabase as CollectionDatabase<Change>, documentPath );
 
            case CompanyDocument:
            case HealthguardCompanyDocument:
                return new HealthguardCompany( collectionDatabase as CollectionDatabase<HealthguardCompany>, documentPath );
    
            case ConsentDocument:
                return new Consent( collectionDatabase as CollectionDatabase<Consent>, documentPath );
                
            case AlertDocument:
            case HealthguardAlertDocument:
                return new HealthguardAlert( collectionDatabase as CollectionDatabase<HealthguardAlert>, documentPath );

            case DeviceDocument:
            case HealthguardDeviceDocument:
                return new HealthguardDevice( collectionDatabase as CollectionDatabase<HealthguardDevice>, documentPath );
                        
            case HazardDocument:
                return new Hazard( collectionDatabase as CollectionDatabase<Hazard>, documentPath );
    
            case IncidentRegistrationDocument:
                return new IncidentRegistration( collectionDatabase as CollectionDatabase<IncidentRegistration>, documentPath );
                 
            case KeyDocument:
                return new Key( collectionDatabase as CollectionDatabase<Key>, documentPath );
                    
            case LocationDocument:
            case HealthguardLocationDocument:
                return new HealthguardLocation( collectionDatabase as CollectionDatabase<HealthguardLocation>, documentPath );
    
            case MeasureDocument:
                return new Measure( collectionDatabase as CollectionDatabase<Measure>, documentPath );

            case MessageDocument:
                return new Message( collectionDatabase as CollectionDatabase<Message>, documentPath );
                    
            case ProjectDocument:
            case HealthguardProjectDocument:
                return new HealthguardProject( collectionDatabase as CollectionDatabase<HealthguardProject>, documentPath );

            case UnitDocument:
            case HealthguardUnitDocument:
                return new HealthguardUnit( collectionDatabase as CollectionDatabase<HealthguardUnit>, documentPath );

            case QuarantineRegistrationDocument:
                return new QuarantineRegistration( collectionDatabase as CollectionDatabase<QuarantineRegistration>, documentPath );
        
            case RegistrationDocument:
                return new Registration( RegistrationDocument, collectionDatabase as CollectionDatabase<Registration>, documentPath );

            case ResidenceDocument:
                return new Residence( collectionDatabase as CollectionDatabase<Residence>, documentPath );
    
            case SickLeaveRegistrationDocument:
                return new SickLeaveRegistration( collectionDatabase as CollectionDatabase<SickLeaveRegistration>, documentPath );
            
            case GatheringDocument:
                return new Gathering( collectionDatabase as CollectionDatabase<Gathering>, documentPath );
                    
            case RiskDocument:
                return new Risk( collectionDatabase as CollectionDatabase<Risk>, documentPath );
                    
            case SubscriptionDocument:
                return new Subscription( collectionDatabase as CollectionDatabase<Subscription>, documentPath );
        
            case SymptomRegistrationDocument:
                return new SymptomRegistration( collectionDatabase as CollectionDatabase<SymptomRegistration>, documentPath );
 
            case TestRegistrationDocument:
                return new TestRegistration( collectionDatabase as CollectionDatabase<TestRegistration>, documentPath );
     
            case TerminalDocument:
                return new Terminal( collectionDatabase as CollectionDatabase<Terminal>, documentPath );
            
            case UserDocument:
            case HealthguardUserDocument:
                return new HealthguardUser( collectionDatabase as CollectionDatabase<HealthguardUser>, documentPath );

            case VaccineRegistrationDocument:
                return new VaccineRegistration( collectionDatabase as CollectionDatabase<VaccineRegistration>, documentPath );

            default:
                throw new Error( "Data type not recognized: " + documentName ); 
        }
    }

    collectionNames() : string[] {
        return HealthguardCollectionNames as string[];
    }

    rootCollectionNames() : string[] {
        return HealthguardRootCollectionNames as string[];
    }

    encryptedCollectionNames() : string[] {
        return HealthguardCollectionNames as string[];
    }

    documentNames() : string[] {
        return HealthguardDocumentNames as string[];
    }

    contactableDocumentNames() : string[] {
        return ContactableDocumentNames as string[];
    }

    collectionNamesWithUsersReference() : string[] {
        return CollectionNamesWithUsersReference as string[];
    }

    registrationDocumentNames() : string[] {
        return RegistrationDocumentNames as string[];
    }

    private readonly _alertsCollectionGroup :  CollectionGroupDatabase<Alert>;
    private readonly _alertsSubcollectionGroups = new Map<string,CollectionGroupDatabase<Alert>>(); 
    private readonly _alertsCollection :  CollectionDatabase<Alert>;
    private readonly _alertsSubcollections = new Map<string,CollectionDatabase<Alert>>();

    private readonly _categoriesCollectionGroup :  CollectionGroupDatabase<HealthguardCategory>;
    private readonly _categoriesSubcollectionGroups = new Map<string,CollectionGroupDatabase<HealthguardCategory>>();
    private readonly _categoriesCollection :  CollectionDatabase<HealthguardCategory>;
    private readonly _categoriesSubcollections = new Map<string,CollectionDatabase<HealthguardCategory>>();

    private readonly _changesCollectionGroup :  CollectionGroupDatabase<Change>;
    private readonly _changesSubcollectionGroups = new Map<string,CollectionGroupDatabase<Change>>();
    private readonly _changesCollection :  CollectionDatabase<Change>;
    private readonly _changesSubcollections = new Map<string,CollectionDatabase<Change>>();

    private readonly _companiesCollectionGroup : CollectionGroupDatabase<HealthguardCompany>;
    private readonly _companiesSubcollectionGroups = new Map<string,CollectionGroupDatabase<HealthguardCompany>>();
    private readonly _companiesCollection : CollectionDatabase<HealthguardCompany>;
    private readonly _companiesSubcollections = new Map<string,CollectionDatabase<HealthguardCompany>>();
    
    private readonly _consentsCollectionGroup :  CollectionGroupDatabase<Consent>;
    private readonly _consentsSubcollectionGroups = new Map<string,CollectionGroupDatabase<Consent>>();
    private readonly _consentsCollection :  CollectionDatabase<Consent>;
    private readonly _consentsSubcollections = new Map<string,CollectionDatabase<Consent>>();

    private readonly _devicesCollectionGroup :  CollectionGroupDatabase<HealthguardDevice>;
    private readonly _devicesSubcollectionGroups = new Map<string,CollectionGroupDatabase<HealthguardDevice>>();
    private readonly _devicesCollection :  CollectionDatabase<HealthguardDevice>;  
    private readonly _devicesSubcollections = new Map<string,CollectionDatabase<HealthguardDevice>>();

    private readonly _hazardsCollectionGroup :  CollectionGroupDatabase<Hazard>;
    private readonly _hazardsSubcollectionGroups = new Map<string,CollectionGroupDatabase<Hazard>>();
    private readonly _hazardsCollection :  CollectionDatabase<Hazard>;
    private readonly _hazardsSubcollections = new Map<string,CollectionDatabase<Hazard>>();

    private readonly _gatheringsCollectionGroup :  CollectionGroupDatabase<Gathering>;
    private readonly _gatheringsSubcollectionGroups = new Map<string,CollectionGroupDatabase<Gathering>>();
    private readonly _gatheringsCollection :  CollectionDatabase<Gathering>;
    private readonly _gatheringsSubcollections = new Map<string,CollectionDatabase<Gathering>>();

    private readonly _risksCollectionGroup :  CollectionGroupDatabase<Risk>;
    private readonly _risksSubcollectionGroups = new Map<string,CollectionGroupDatabase<Risk>>();
    private readonly _risksCollection :  CollectionDatabase<Risk>;
    private readonly _risksSubcollections = new Map<string,CollectionDatabase<Risk>>();

    private readonly _incidentRegistrationsCollectionGroup :  CollectionGroupDatabase<IncidentRegistration>;
    private readonly _incidentRegistrationsSubcollectionGroups = new Map<string,CollectionGroupDatabase<IncidentRegistration>>();
    private readonly _incidentRegistrationsCollection :  CollectionDatabase<IncidentRegistration>;
    private readonly _incidentRegistrationsSubcollections = new Map<string,CollectionDatabase<IncidentRegistration>>();

    private readonly _keysCollectionGroup :  CollectionGroupDatabase<Key>;
    private readonly _keysSubcollectionGroups = new Map<string,CollectionGroupDatabase<Key>>();
    private readonly _keysCollection :  CollectionDatabase<Key>;
    private readonly _keysSubcollections = new Map<string,CollectionDatabase<Key>>();

    private readonly _locationsCollectionGroup :  CollectionGroupDatabase<HealthguardLocation>;
    private readonly _locationsSubcollectionGroups = new Map<string,CollectionGroupDatabase<HealthguardLocation>>();
    private readonly _locationsCollection :  CollectionDatabase<HealthguardLocation>;
    private readonly _locationsSubcollections = new Map<string,CollectionDatabase<HealthguardLocation>>();

    private readonly _measuresCollectionGroup :  CollectionGroupDatabase<Measure>;
    private readonly _measuresSubcollectionGroups = new Map<string,CollectionGroupDatabase<Measure>>();
    private readonly _measuresCollection :  CollectionDatabase<Measure>;
    private readonly _measuresSubcollections = new Map<string,CollectionDatabase<Measure>>();

    private readonly _messagesCollectionGroup :  CollectionGroupDatabase<Message>;
    private readonly _messagesSubcollectionGroups = new Map<string,CollectionGroupDatabase<Message>>();
    private readonly _messagesCollection :  CollectionDatabase<Message>;
    private readonly _messagesSubcollections = new Map<string,CollectionDatabase<Message>>();

    private readonly _projectsCollectionGroup :  CollectionGroupDatabase<HealthguardProject>;
    private readonly _projectsSubcollectionGroups = new Map<string,CollectionGroupDatabase<HealthguardProject>>();
    private readonly _projectsCollection :  CollectionDatabase<HealthguardProject>;
    private readonly _projectsSubcollections = new Map<string,CollectionDatabase<HealthguardProject>>();

    private readonly _unitsCollectionGroup :  CollectionGroupDatabase<HealthguardUnit>;
    private readonly _unitsSubcollectionGroups = new Map<string,CollectionGroupDatabase<HealthguardUnit>>();
    private readonly _unitsCollection :  CollectionDatabase<HealthguardUnit>;  
    private readonly _unitsSubcollections = new Map<string,CollectionDatabase<HealthguardUnit>>();

    private readonly _quarantineRegistrationsCollectionGroup :  CollectionGroupDatabase<QuarantineRegistration>;
    private readonly _quarantineRegistrationsSubcollectionGroups = new Map<string,CollectionGroupDatabase<QuarantineRegistration>>();
    private readonly _quarantineRegistrationsCollection :  CollectionDatabase<QuarantineRegistration>;
    private readonly _quarantineRegistrationsSubcollections = new Map<string,CollectionDatabase<QuarantineRegistration>>();

    private readonly _residencesCollectionGroup :  CollectionGroupDatabase<Residence>;
    private readonly _residencesSubcollectionGroups = new Map<string,CollectionGroupDatabase<Residence>>();
    private readonly _residencesCollection :  CollectionDatabase<Residence>;
    private readonly _residencesSubcollections = new Map<string,CollectionDatabase<Residence>>();

    private readonly _registrationsCollectionGroup :  CollectionGroupDatabase<Registration>;
    private readonly _registrationsSubcollectionGroups = new Map<string,CollectionGroupDatabase<Registration>>();
    private readonly _registrationsCollection :  CollectionDatabase<Registration>;
    private readonly _registrationsSubcollections = new Map<string,CollectionDatabase<Registration>>();

    private readonly _sickLeaveRegistrationsCollectionGroup :  CollectionGroupDatabase<SickLeaveRegistration>;
    private readonly _sickLeaveRegistrationsSubcollectionGroups = new Map<string,CollectionGroupDatabase<SickLeaveRegistration>>();
    private readonly _sickLeaveRegistrationsCollection :  CollectionDatabase<SickLeaveRegistration>;
    private readonly _sickLeaveRegistrationsSubcollections = new Map<string,CollectionDatabase<SickLeaveRegistration>>();

    private readonly _subscriptionsCollectionGroup :  CollectionGroupDatabase<Subscription>;
    private readonly _subscriptionsSubcollectionGroups = new Map<string,CollectionGroupDatabase<Subscription>>();
    private readonly _subscriptionsCollection :  CollectionDatabase<Subscription>;
    private readonly _subscriptionsSubcollections = new Map<string,CollectionDatabase<Subscription>>();

    private readonly _symptomRegistrationsCollectionGroup :  CollectionGroupDatabase<SymptomRegistration>;
    private readonly _symptomRegistrationsSubcollectionGroups = new Map<string,CollectionGroupDatabase<SymptomRegistration>>();
    private readonly _symptomRegistrationsCollection :  CollectionDatabase<SymptomRegistration>;
    private readonly _symptomRegistrationsSubcollections = new Map<string,CollectionDatabase<SymptomRegistration>>();

    private readonly _terminalsCollectionGroup :  CollectionGroupDatabase<Terminal>;
    private readonly _terminalsSubcollectionGroups = new Map<string,CollectionGroupDatabase<Terminal>>();
    private readonly _terminalsCollection :  CollectionDatabase<Terminal>;
    private readonly _terminalsSubcollections = new Map<string,CollectionDatabase<Terminal>>();

    private readonly _testRegistrationsCollectionGroup :  CollectionGroupDatabase<TestRegistration>;
    private readonly _testRegistrationsSubcollectionGroups = new Map<string,CollectionGroupDatabase<TestRegistration>>();
    private readonly _testRegistrationsCollection :  CollectionDatabase<TestRegistration>;
    private readonly _testRegistrationsSubcollections = new Map<string,CollectionDatabase<TestRegistration>>();

    private readonly _usersCollectionGroup :  CollectionGroupDatabase<HealthguardUser>;
    private readonly _usersSubcollectionGroups = new Map<string,CollectionGroupDatabase<HealthguardUser>>();
    private readonly _usersCollection :  CollectionDatabase<HealthguardUser>;
    private readonly _usersSubcollections = new Map<string,CollectionDatabase<HealthguardUser>>();

    private readonly _vaccineRegistrationsCollectionGroup :  CollectionGroupDatabase<VaccineRegistration>;
    private readonly _vaccineRegistrationsSubcollectionGroups = new Map<string,CollectionGroupDatabase<VaccineRegistration>>();
    private readonly _vaccineRegistrationsCollection :  CollectionDatabase<VaccineRegistration>;
    private readonly _vaccineRegistrationsSubcollections = new Map<string,CollectionDatabase<VaccineRegistration>>();

}
