

import { LoggerFactory } from "../../common/api/loggerFactory";
import { Monitor } from "../../common/api/monitor";
import { Observation } from "../../common/api/observation";
import { Target } from "../../common/api/targets";
import { Observable } from "../../common/impl/observable";
import { MessagingServiceIF } from "../api/messagingServiceIF";
import { EmailSender } from "./email/emailSender";

import { NotificationSender } from "./notification/notificationSender";
import { PushReceiver } from "./push/pushReceiver";
import { PushSender } from "./push/pushSender";
import { SmsSender } from "./sms/smsSender";


export const log = LoggerFactory.logger("messaging");

export class MessagingService extends Observable implements MessagingServiceIF {

    constructor( target : Target, 
        emailSender? : EmailSender, 
        smsSender? : SmsSender, 
        pushSender? : PushSender,
        pushReceiver? : PushReceiver ) {

        super();

        //log.traceInOut("constructor()", target );

        try {

            this.target = target;

            this.notificationSender = new NotificationSender();  

            this.emailSender = emailSender;

            this.smsSender = smsSender;

            this.pushSender = pushSender;

            this.pushReceiver = pushReceiver;

        } catch (error) {
            log.warn("constructor()", "Error constructing storage service", error);

            throw new Error( (error as any).message );
        }
    }

    async init() : Promise<void> {

        log.traceInOut("init()" );

        try {

            if( this.emailSender != null ) {
                this.emailSender.init();
            }

            if( this.smsSender != null ) {
                this.smsSender.init();
            }

            if( this.pushSender != null ) {
                this.pushSender.init();
            }

            if( this.pushReceiver != null ) {
                this.pushReceiver.init();
            }
 
        } catch (error) {
            log.warn("init()", "Error initializing storage service", error);

            throw new Error( (error as any).message );
        }
    }

    protected async monitor( newMonitor : Monitor ): Promise<void> {}

    protected async release(observationFilter?: Observation[], objectIdsFilter?: string[]): Promise<void> {}

    readonly target : Target;

    readonly notificationSender : NotificationSender; 

    readonly emailSender? : EmailSender; 

    readonly smsSender? : SmsSender; 

    readonly pushSender? : PushSender; 

    readonly pushReceiver? : PushReceiver; 

}
