import { Registration } from "./registration";
import { Residence } from "../../../services/database/impl/documents/residence";
import { QuarantineRegistrationIF } from "../../api/documents/quarantineRegistrationIF";
import { log } from "../../../services/database/framework/databaseService";
import { CollectionDatabase } from "../../../services/database/impl/core/collectionDatabase";
import { DefinitionProperty } from "../../../services/database/impl/properties/definitionProperty";
import { ReferenceProperty } from "../../../services/database/impl/properties/referenceProperty";
import { QuarantineReason, QuarantineReasons } from "../../api/definitions/quarantineReason";
import { HealthguardUser } from "./healthguardUser";
import { QuarantineReasonDefinition } from "../../api/healthguardDefinitions";
import { QuarantineRegistrationDocument } from "../../api/registrationDocuments";


export class QuarantineRegistration extends Registration implements QuarantineRegistrationIF {

    constructor( quarantineRegistrationsCollection : CollectionDatabase<QuarantineRegistration>, documentPath? : string  ) {

        super( QuarantineRegistrationDocument, quarantineRegistrationsCollection, documentPath );

        try {
            const ownerUser = this.user.emptyDocument() as HealthguardUser;

            this.reason = new DefinitionProperty<QuarantineReason>( this, QuarantineReasonDefinition, QuarantineReasons);
            this.reason.required = true;

            this.residence = new ReferenceProperty<Residence>( this, 
                ownerUser != null ? 
                    () => [ownerUser.residences.collection()] : 
                    undefined );

            //log.traceInOut( "constructor()", QuarantineRegistrationsCollection );

        } catch( error ) {

            log.warn( "constructor()", "Error initializing sick leaves", error );
            
            throw new Error( (error as any).message );
        }
    }

    readonly reason : DefinitionProperty<QuarantineReason>;

    readonly residence: ReferenceProperty<Residence>;

 
}
