import React from 'react';

import { log } from "ui/app/app";

import { withTranslation, WithTranslation } from 'react-i18next';
import { Button, createStyles, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Theme, Typography, WithStyles, withStyles } from '@material-ui/core';
import Loading from './loading';
import { TranslationKey } from '../../services/common/api/translatorIF';
import { alpha } from '@mui/system/colorManipulator';
import theme from '../app/theme';

export const ButtonKeyOK = "ok";
export const ButtonKeyCancel = "cancel";
export const ButtonKeyYes = "yes";
export const ButtonKeyNo = "no";

const styles = (theme: Theme) => createStyles({
    dialog: {
    }, 
    content: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(0),
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(6)
    },  
    buttons: {
      display: "flex", 
      justifyContent:'flex-end', 
      paddingRight: theme.spacing(6)
    }
  });


interface WaitDialogProps extends WithStyles<typeof styles>, WithTranslation {
    
  open? : boolean,

  title? : string,

  waitText? : string,

  cancelText? : string,

  onCancel? : () => void
}


interface WaitDialogState { 
    open : boolean
}

class WaitDialog extends React.PureComponent<WaitDialogProps,WaitDialogState> {

  constructor( props: WaitDialogProps ) {
    
    super(props); 

    this.state = { 

        open : !!this.props.open

    } as WaitDialogState;

    //log.traceInOut( "constructor()" );
  }


  async componentDidUpdate() {

    log.traceIn( "componentDidUpdate()" );

    if( this.props.open !== this.state.open ) {

        this.setState( {
          open : !!this.props.open
        });
    }

    log.traceIn( "componentDidUpdate()" );
  }

  
  render() {

    //log.traceInOut( "render()" );

    const { classes } = this.props;

    const handleClose = () => {

        log.traceInOut( "handleClose()" );

        this.setState( { open : false } );     
    }

    const handleCancel = () => {

        log.traceInOut( "handleCancel()" );

        handleClose();
        
        if( this.props.onCancel != null ) {
            this.props.onCancel();
        }
    }

    return (
      <React.Fragment>
        <Dialog
          fullWidth={true}
          maxWidth="sm" 
          className={classes.dialog} 
          open={this.state.open}
          onClose={() => handleClose()}
          PaperProps={{
            style: {
              backgroundColor: alpha(theme.palette.background.paper, 0.5)
            }
          }}
        >
          {this.props.title != null &&
            <DialogTitle>{this.props.title}</DialogTitle>
          }
          <DialogContent className={classes.content}>
            <Loading /> 
            <DialogContentText align="center" >
              <Typography variant="inherit" color="secondary" >
                {this.props.waitText != null ? this.props.waitText : this.props.t(TranslationKey.Prompts + ":pleaseWait")}
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions className={classes.buttons}>
            <Button onClick={() => handleCancel()} color="primary">
              {this.props.cancelText != null ? this.props.cancelText : this.props.t("cancel")}
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}


const ModifiedWaitDialog = withTranslation()(withStyles(styles)(WaitDialog));

export default ModifiedWaitDialog;

