
import { Hazard } from "./hazard";
import { Factory } from "../../../services/common/api/factory";

import { log } from "../../../services/database/framework/databaseService";
import { CollectionDatabase } from "../../../services/database/impl/core/collectionDatabase";
import { DatabaseDocument } from "../../../services/database/framework/databaseDocument";
import { GatheringsCollection, HazardsCollection, MeasuresCollection } from "../../api/healthguardCollections";
import { TenantProperty } from "../../../services/database/impl/properties/tenantProperty";
import { CategoriesCollection, CompaniesCollection, DevicesCollection, LocationsCollection, ProjectsCollection, UnitsCollection, UsersCollection } from "../../../services/database/api/collections";
import { RiskDocument, HealthguardCompanyDocument, HealthguardUnitDocument } from "../../api/healthguardDocuments";
import { OwnerProperty } from "../../../services/database/impl/properties/ownerProperty";
import { HealthguardCompany } from "./healthguardCompany";
import { HealthguardUnit } from "./healthguardUnit";

import { DefinitionProperty } from "../../../services/database/impl/properties/definitionProperty";

import { RiskIF } from "../../api/documents/riskIF";
import { Severities, Severity } from "../../../services/database/api/definitions/severity";
import { Probabilities, Probability } from "../../../services/database/api/definitions/probability";
import { Measure } from "./measure";
import { ReferencesProperty } from "../../../services/database/impl/properties/referencesProperty";
import { NumberProperty } from "../../../services/database/impl/properties/numberProperty";
import { BooleanProperty } from "../../../services/database/impl/properties/booleanProperty";
import { HealthguardProject } from "./healthguardProject";
import { Category } from "../../../services/database/impl/documents/category";
import { HealthguardLocation } from "./healthguardLocation";
import { HealthguardUser } from "./healthguardUser";
import { HealthguardCategory } from "./healthguardCategory";
import { RiskManager } from "../managers/riskManager";
import { Gathering } from "./gathering";
import { HealthguardDevice } from "./healthguardDevice";
import { ReadinessLevel, ReadinessLevels } from "../../api/definitions/readinessLevel";
import { TextProperty } from "../../../services/database/impl/properties/textProperty";
import { DefaultTextType} from "../../../services/database/api/definitions/textTypes";
import { ReadinessLevelDefinition, ProbabilityDefinition, SeverityDefinition } from "../../../services/database/api/definitions";
import { HealthguardAlert } from "./healthguardAlert";
import { Database } from "../../../services/database/impl/core/database";


export class Risk extends DatabaseDocument implements RiskIF {

    constructor( risksCollection : CollectionDatabase<Risk>, documentPath? : string  ) {

        super( RiskDocument, risksCollection, documentPath );

        try {  
            this.title.required = true;
            this.description.required = true;
                      
            this.company = new TenantProperty<HealthguardCompany>( this, CompaniesCollection, HealthguardCompanyDocument );
            this.company.editable = false;

            const ownerCompany = this.company.emptyDocument() as HealthguardCompany;

            this.unit = new OwnerProperty<HealthguardUnit>( this, UnitsCollection, HealthguardUnitDocument );
            
            this.hazards = new ReferencesProperty<Hazard>(  
                this, 
                ownerCompany != null ? 
                    () => [ownerCompany.hazards.collection(),ownerCompany.publicHazards.documentsDatabase()] : 
                    () => [Factory.get().databaseService.databaseFactory.collectionDatabaseFromCollectionName( 
                        HazardsCollection ) as CollectionDatabase<Hazard>] 
            );

            this.hazards.required = true; 

            this.probability = new DefinitionProperty<Probability>( this, 
                ProbabilityDefinition, 
                Probabilities ); 
            this.probability.required = true;

            this.severity = new DefinitionProperty<Severity>( this, 
                SeverityDefinition, 
                Severities ); 
            this.severity.required = true;

            this.readinessLevel = new DefinitionProperty<ReadinessLevel>(
                this, 
                ReadinessLevelDefinition, 
                ReadinessLevels );

            this.status = new TextProperty( this, DefaultTextType, false );   

            this.alerts = new ReferencesProperty<HealthguardAlert>(this,
                () => this.parentDatabases( MeasuresCollection, {nearestIsCollectionGroup: false} ) as Database<HealthguardAlert>[],
                "risks" ); 

            this.measures = new ReferencesProperty<Measure>(this,
                () => this.parentDatabases( MeasuresCollection, {nearestIsCollectionGroup: false} ) as Database<Measure>[],
                "risk" );

            this.riskScore = new NumberProperty( this );
            this.riskScore.editable = false;

            this.allUsers = new BooleanProperty( this );

            this.units = new ReferencesProperty<HealthguardUnit>( this, 
                () => this.parentDatabases( UnitsCollection, {nearestIsCollectionGroup: true} ) as Database<HealthguardUnit>[] );

            this.projects = new ReferencesProperty<HealthguardProject>( this, 
                () => this.parentDatabases( ProjectsCollection, {nearestIsCollectionGroup: true} ) as Database<HealthguardProject>[],
                "risks" );

            this.categories = new ReferencesProperty<HealthguardCategory>( this, 
                () => this.parentDatabases( CategoriesCollection, {nearestIsCollectionGroup: true} ) as Database<HealthguardCategory>[],
                "risks" );

            this.gatherings = new ReferencesProperty<Gathering>( this, 
                () => this.parentDatabases( GatheringsCollection, {nearestIsCollectionGroup: true} ) as Database<Gathering>[],
                "risks" );

            this.locations = new ReferencesProperty<HealthguardLocation>( this, 
                () => this.parentDatabases( LocationsCollection, {nearestIsCollectionGroup: true} ) as Database<HealthguardLocation>[],
                "risks" );

            this.devices = new ReferencesProperty<HealthguardDevice>( this, 
                () => this.parentDatabases( DevicesCollection, {nearestIsCollectionGroup: true} ) as Database<HealthguardDevice>[],
                "risks" );

            this.users = new ReferencesProperty<HealthguardUser>( this, 
                () => this.parentDatabases( UsersCollection, {nearestIsCollectionGroup: true} ) as Database<HealthguardUser>[],
                "risks" );

            //log.traceInOut( "constructor()", MeasuresCollection );

        } catch( error ) {

            log.warn( "constructor()", "Error initializing measure", error );
            
            throw new Error( (error as any).message );
        }
    }

    indexKey() : string {
        return RiskDocument; 
    }

    referenceDateProperty() {
        return undefined;
    }

    updateRiskScore() : void {

        log.traceIn("updateRiskScore()",  this.title.value(),  this.severity.value(), this.probability.value() );

        if( this.severity.value() == null || this.probability.value() == null ) {

            this.riskScore.setValue( undefined );

            log.traceOut("updateRiskScore()", "Not ready" );
            return;
        }
        let severityScore = 0;

        switch( this.severity.value() ) {

            case Severities.Catastrophic:

                severityScore = 5;
                break;

            case Severities.Critical:

                severityScore = 4;
                break;

            case Severities.Moderate:

                severityScore = 3;
                break;

            case Severities.Minor:

                severityScore = 2;
                break;

            case Severities.Negligible:

                severityScore = 1;
                break;
        }

        let probabilityScore = 0;

        switch( this.probability.value() ) {

            case Probabilities.Frequent:

                probabilityScore = 5;
                break;

            case Probabilities.Probable:

                probabilityScore = 4;
                break;

            case Probabilities.Occassional:

                probabilityScore = 3;
                break;

            case Probabilities.Remote:

                probabilityScore = 2;
                break;

            case Probabilities.Improbable:

                probabilityScore = 1;
                break;

        }

        const riskRiskScore = severityScore * probabilityScore;

        this.riskScore.setValue( riskRiskScore );

        log.traceOut("updateRiskScore()", {riskRiskScore} );

    }


    async updateUsers() : Promise<void> {

        await RiskManager.getInstance().updateRiskUsers( this );
    }

    async onCreate() : Promise<void> {

        //log.traceIn( "onCreated()" );

        try {

            await super.onCreate();

            this.updateRiskScore();

            await RiskManager.getInstance().handleCreateRisk( this );
    
            //log.traceOut( "onCreated()" );
  
        } catch( error ) {
            
            log.warn( "onCreated()", "Error handling created notification", error );
  
            throw new Error( (error as any).message );
        }
    }

    async onUpdate() : Promise<void> {

        //log.traceIn( "onUpdate()" );

        try {

            await super.onUpdate();

            this.updateRiskScore();

            await RiskManager.getInstance().handleUpdateRisk( this );
    
            //log.traceOut( "onUpdate()" );
  
        } catch( error ) {
            
            log.warn( "onUpdated()", "Error handling updated notification", error );
  
            throw new Error( (error as any).message );
        }
    }

    async onDelete() : Promise<void> {

        //log.traceIn( "onDelete()" );

        try {

            await super.onDelete();

            await RiskManager.getInstance().handleDeleteRisk( this );
    
            //log.traceOut( "onDelete()" );
  
        } catch( error ) {
            
            log.warn( "onDelete()", "Error handling deleted notification", error );
  
            throw new Error( (error as any).message );
        }
    }

    readonly company: TenantProperty<HealthguardCompany>;

    readonly unit: OwnerProperty<HealthguardUnit>;

    readonly hazards: ReferencesProperty<Hazard>;

    readonly probability : DefinitionProperty<Probability>;
    
    readonly severity : DefinitionProperty<Severity>;

    readonly riskScore : NumberProperty;

    readonly readinessLevel: DefinitionProperty<ReadinessLevel>;

    readonly status : TextProperty;

    readonly alerts: ReferencesProperty<HealthguardAlert>; 

    readonly measures: ReferencesProperty<Measure>; 

    readonly allUsers : BooleanProperty;

    readonly units : ReferencesProperty<HealthguardUnit>;

    readonly projects : ReferencesProperty<HealthguardProject>;

    readonly categories : ReferencesProperty<Category>;

    readonly gatherings : ReferencesProperty<Gathering>; 

    readonly locations : ReferencesProperty<HealthguardLocation>; 

    readonly devices : ReferencesProperty<HealthguardDevice>; 

    readonly users : ReferencesProperty<HealthguardUser> 
}
