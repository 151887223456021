import { Registration } from "./registration";
import { Hazard } from "./hazard";
import { TestRegistrationIF } from "../../api/documents/testRegistrationIF";
import { log } from "../../../services/database/framework/databaseService";
import { CollectionDatabase } from "../../../services/database/impl/core/collectionDatabase";
import { OptionsSource } from "../../../services/database/impl/core/optionsSource";
import { DefinitionProperty } from "../../../services/database/impl/properties/definitionProperty";
import { TestResult, TestResults } from "../../api/definitions/testResult";
import { TestResultDefinition, TestTypeDefinition } from "../../api/healthguardDefinitions";
import { TestRegistrationDocument } from "../../api/registrationDocuments";
import { ReferenceProperty } from "../../../services/database/impl/properties/referenceProperty";
import { HealthguardDevice } from "./healthguardDevice";
import { TestType, TestTypes } from "../../api/definitions/testType";
import { DefinitionsProperty } from "../../../services/database/impl/properties/definitionsProperty";
import { DevicesCollection } from "../../../services/database/api/collections";
import { Database } from "../../../services/database/impl/core/database";


export class TestRegistration extends Registration implements TestRegistrationIF {

    constructor( testRegistrationsCollection : CollectionDatabase<TestRegistration>, documentPath? : string  ) {

        super( TestRegistrationDocument, testRegistrationsCollection, documentPath );

        try {

            this.testTypes = new DefinitionsProperty<TestType>( this, TestTypeDefinition, TestTypes);
            this.testTypes.setOptionsSource( new OptionsSource<Hazard,DefinitionsProperty<TestType>>( this.hazard, "testTypes" )  );
            this.testTypes.required = true; 

            this.testResult = new DefinitionProperty<TestResult>( this, TestResultDefinition, TestResults);
            this.testResult.required = true;

            this.device = new ReferenceProperty<HealthguardDevice>( this, 
                () => this.parentDatabases( DevicesCollection, {nearestIsCollectionGroup: false} ) as Database<HealthguardDevice>[],
                "testRegistrations" ); 

            //log.traceInOut( "constructor()", TestRegistrationsCollection );

        } catch( error ) {

            log.warn( "constructor()", "Error initializing test", error );
            
            throw new Error( (error as any).message );
        }
    }

    readonly testTypes: DefinitionsProperty<TestType>;

    readonly testResult: DefinitionProperty<TestResult>;

    readonly device: ReferenceProperty<HealthguardDevice>;

}
