import { DatabaseDocument } from "../../framework/databaseDocument";
import { DatabaseObject } from "../../framework/databaseObject";
import { ReferencePropertyIF } from "../../api/properties/referencePropertyIF";
import { PropertyType, PropertyTypes } from "../../api/definitions/propertyType"; 

import { Database } from "../core/database";
import { DocumentProperty } from "./documentProperty";


export class ReferenceProperty<DerivedDocument extends DatabaseDocument> 
    extends DocumentProperty<DerivedDocument> implements ReferencePropertyIF<DerivedDocument> {

    constructor( parent : DatabaseObject, 
        onSelectSources? : () => (Database<DerivedDocument> | undefined)[],
        reciprocalKey? : keyof DerivedDocument ) {

        super( PropertyTypes.Reference as PropertyType, parent, onSelectSources, reciprocalKey ); 
    }  
     
}
