//import { Location } from "node-geocoder";

import { DatabaseObject } from "../../framework/databaseObject";
import { PropertyType, PropertyTypes } from "../../api/definitions/propertyType"; 
import { Geolocation, GeolocationPropertyIF } from "../../api/properties/geolocationPropertyIF";
import { DataProperty } from "./dataProperty";
import { CountryProperty } from "./countryProperty";
import { DefinitionProperty } from "./definitionProperty";
import { TextProperty } from "./textProperty";
import { SubdocumentProperty } from "./subdocumentProperty";
import { Address } from "../subdocuments/address";

export class GeolocationProperty 
    extends DataProperty<Geolocation> implements GeolocationPropertyIF{

    constructor( parent : DatabaseObject ) {
        super( PropertyTypes.Geolocation as PropertyType, parent ); 
    }

    countryProperty() : CountryProperty | undefined {
        return this._countryProperty;
    }

    setCountryProperty( countryProperty? : CountryProperty ) {
        this._countryProperty = countryProperty;
    }

    typeProperty() : DefinitionProperty<any> | undefined {
        return this._typeProperty;
    }

    setTypeProperty( typeProperty? : DefinitionProperty<any>  ) {
        this._typeProperty = typeProperty;
    }

    levelProperty() : DefinitionProperty<any> | undefined {
        return this._levelProperty;
    }

    setLevelProperty( levelProperty? : DefinitionProperty<any>  ) {
        this._levelProperty = levelProperty;
    }

    titleProperty() : TextProperty | undefined {
        return this._titleProperty;
    }

    setTitleProperty( titleProperty? : TextProperty ) {
        this._titleProperty = titleProperty;
    }

    statusProperty() : TextProperty | undefined {
        return this._statusProperty;
    }

    setStatusProperty( statusProperty? : TextProperty ) {
        this._statusProperty = statusProperty;
    }

    addressProperty() : SubdocumentProperty<Address> | undefined {
        return this._addressProperty;
    }

    setAddressProperty( addressProperty? : SubdocumentProperty<Address> ) {
        this._addressProperty = addressProperty;
    }

    geolocation() : Geolocation | undefined {
        return this.value();
    }

    setGeolocation( geolocation : any | undefined ): void {
        this.setValue( geolocation );
    }

    private _countryProperty? : CountryProperty;

    private _typeProperty? : DefinitionProperty<any>;

    private _levelProperty? : DefinitionProperty<any>;

    private _titleProperty? : TextProperty;

    private _statusProperty? : TextProperty;

    private _addressProperty? : SubdocumentProperty<Address>;

}