import React from 'react';
import { useTranslation } from 'react-i18next';

import DocumentView, { DocumentProps } from '../components/documentView';

import { PropertiesSelector } from 'services/database/api/core/propertiesSelector';

import DatabaseTable, { DatabaseTableProps } from 'ui/components/databaseTable';
import DatabaseList, { DatabaseListProps } from 'ui/components/databaseList';
import DatabaseCard, { DatabaseCardProps } from 'ui/components/databaseCard';
import DatabaseChart, { DatabaseChartProps } from 'ui/components/databaseChart';
import DatabaseGraph, { DatabaseGraphProps } from 'ui/components/databaseGraph';
import DatabaseView from '../components/databaseView';

import { CompanyIF } from 'services/database/api/documents/companyIF';

import SimpleDatabaseView from '../components/simpleDatabaseView';
import { HealthguardCompanyIF } from '../../healthguard/api/documents/healthguardCompanyIF';
import { DatabaseDocumentIF } from '../../services/database/api/core/databaseDocumentIF';
import { log } from '../app/app';
import { DatabasePropertyIF } from '../../services/database/api/core/databasePropertyIF';
import { alertDialog, errorDialog } from '../components/simpleDialog';
import { DatabaseQuery } from '../../services/database/api/core/databaseQuery';
import { IncidentRegistrationsCollection, QuarantineRegistrationsCollection, SickLeaveRegistrationsCollection, SymptomRegistrationsCollection, TestRegistrationsCollection, VaccineRegistrationsCollection } from '../../healthguard/api/registrationCollections';
import { ReadinessLevelDefinition } from '../../services/database/api/definitions';
import { DatabaseViewType, DatabaseViewTypes } from '../components/databaseViewMenu';
import { CollectionPropertyIF } from '../../services/database/api/properties/collectionPropertyIF';


export const defaultCompanyTableColumns = [
  "title",
  "email",
  "phoneNumber",
  "customerId",
  "usedLicenses",
  "licenses"
];

export const defaultHighlightedCompanyProperty = "licenses";

export const CompanyViewPrivacy = "privacy";
export const CompanyViewAdministrators = "administrators";
export const CompanyViewCompanyOverview = "company";
export const CompanyViewPublic = "public";


const CompanyBasicsFilter = {

  includePropertyKeys: [
    "language",
    "country",
    "taxId",
    "domain",
    "email",
    "phoneNumber",
    "description",
    "logo"
  ],

} as PropertiesSelector;

const CompanyRiskManagementFilter = {

  includePropertyKeys: [
    ReadinessLevelDefinition,
    "publicHazards" 
  ],

} as PropertiesSelector; 



const CompanyPrivacyFilter = {

  includePropertyKeys: [
    "consentPrompt",
    "privacyPolicy",
    "enableParents",
    "restrictSymbolicOwners",     
  ],

} as PropertiesSelector;

const CompanyIncidentRegistrationsPrivacyFilter = {

  includePropertyKeys: [
    "incidentRegistrationsPrivacy"
  ]

} as PropertiesSelector;


const CompanyVaccineRegistrationsPrivacyFilter = {

  includePropertyKeys: [
    "vaccineRegistrationsPrivacy"
  ],

} as PropertiesSelector;

const CompanyTestRegistrationsPrivacyFilter = {

  includePropertyKeys: [
    "testRegistrationsPrivacy"
  ],

} as PropertiesSelector;

const CompanySymptomRegistrationsPrivacyFilter = {

  includePropertyKeys: [
    "symptomRegistrationsPrivacy"
  ],

} as PropertiesSelector;

const CompanyQuarantineRegistrationsPrivacyFilter = {

  includePropertyKeys: [
    "quarantineRegistrationsPrivacy"
  ],

} as PropertiesSelector;

const CompanySickLeaveRegistrationsPrivacyFilter = {

  includePropertyKeys: [
    "sickLeaveRegistrationsPrivacy"
  ],

} as PropertiesSelector;



const CompanyAccountFilter = {

  includePropertyKeys: [
    "customerId",
    "usedLicenses",
    "licenses",
    "startDate",
    "endDate",
    "disabled"
  ],

} as PropertiesSelector;

const CompanySubscriptionsFilter = {

  includePropertyKeys: [
    "subscriptions"
  ],

} as PropertiesSelector;

const CompanyAddressesFilter = {

  includePropertyKeys: [
    "visitorAddress",
    "billingAddress",
    "shippingAddress",
  ],

} as PropertiesSelector;


const CompanyAdminsFilter = {

  includePropertyKeys: [
    "admins"
  ],

} as PropertiesSelector;


const CompanyUsersFilter = {

  includePropertyKeys: [
    "users"
  ],

} as PropertiesSelector;

const CompanyPublicFilter = {

  includePropertyKeys: [
    "email",
    "phoneNumber",
    "visitorAddress"
  ],

} as PropertiesSelector;



const defaultCompanySteps = (): Map<string, PropertiesSelector> => {

  let defaultSteps = new Map<string, PropertiesSelector>();

  defaultSteps.set("basics", CompanyBasicsFilter);
  defaultSteps.set("license", CompanyAccountFilter);
  defaultSteps.set("subscription", CompanySubscriptionsFilter);
  defaultSteps.set("addresses", CompanyAddressesFilter);
  defaultSteps.set("administrators", CompanyAdminsFilter);
  defaultSteps.set("users", CompanyUsersFilter);

  return defaultSteps;
}
export function CompaniesTable(props: DatabaseTableProps) {

  return (
    <DatabaseTable {...props} featuredProperties={props.featuredProperties != null ? props.featuredProperties : defaultCompanyTableColumns} />
  );
}

export function CompaniesList(props: DatabaseListProps) {

  return (
    <DatabaseList {...props} />
  );
}


export function CompaniesCard(props: DatabaseCardProps) {
  return (
    <DatabaseCard
      {...props}
      highlightedPropertyKey={props.highlightedPropertyKey != null ? props.highlightedPropertyKey : defaultHighlightedCompanyProperty}
    />
  );
}

export function CompaniesChart(props: DatabaseChartProps) {
  return (
    <DatabaseChart
      {...props}
    />
  );
}


export function CompaniesGraph(props: DatabaseGraphProps) {
  return (
    <DatabaseGraph
      {...props}
      previousPropertyKey={"country"}
      directed={false}
    />
  );
}



export function CompaniesView(props: {
  databaseQuery : DatabaseQuery<CompanyIF>,
  title?: string,
  featuredProperties?: string[],
  highlightedPropertyKey?: string
}) {

  return (<DatabaseView
    databaseQuery={props.databaseQuery}
    title={props.title}
    featuredProperties={props.featuredProperties != null ? props.featuredProperties : defaultCompanyTableColumns} 
    highlightedPropertyKey={props.highlightedPropertyKey != null ? props.highlightedPropertyKey : defaultHighlightedCompanyProperty}
    defaultDatabaseViewType={DatabaseViewTypes.Table as DatabaseViewType} 
  />);
}


export function CompanyView(props : DocumentProps) {

  const view = props.view;

  if (view != null) {

    const collectionProperty = 
      props.databaseDocument.property( view ) as CollectionPropertyIF<DatabaseDocumentIF>;

    if( collectionProperty != null ) {
      return (<SimpleDatabaseView collectionProperty={collectionProperty} />);
    }

    if (view === CompanyViewPrivacy) {
      return (<CompanyPrivacyView {...props}/>);
    }

    if (view === CompanyViewCompanyOverview) {
      return (<CompanyOverviewView {...props}/>);
    }

    if (view === CompanyViewPublic) {
      return (<CompanyPublicView {...props}/>);
    }

    if (view === CompanyViewAdministrators) {
      return (<CompanyAdministratorsView {...props}/>);
    }
  }

  return (<DocumentView
    {...props}
    inputTabs={props.inputTabs != null ? props.inputTabs : defaultCompanySteps()}
  />);
}


export function CompanyOverviewView(props : DocumentProps) {

  const [company,setCompany] = React.useState<CompanyIF>();

  React.useEffect( () => {

    setCompany( props.databaseDocument as CompanyIF );

  }, [props.databaseDocument]); 

  const onDocumentChange = async (databaseDocument: DatabaseDocumentIF, 
    changedProperty? : DatabasePropertyIF<any> ): Promise<void> => {

    log.traceIn("onDocumentChange");
  
    try {
  
      const company = databaseDocument as HealthguardCompanyIF;
  
      if( changedProperty == null ) {
        log.traceOut("onDocumentChange", "no changed property");
        return;
      }
  
      if( changedProperty.key() === company.readinessLevel.key() ) {
        
        await alertDialog( "confirmUpdateReadinessLevel");

      }

      setCompany( company );
  
    } catch (error) {
      log.warn("onDocumentEdit()", error);
  
      await errorDialog(error);
    }
  
    log.traceOut("onDocumentEdit");
  }

  const companyOverviewSteps = (): Map<string, PropertiesSelector> => {

    let overviewSteps = new Map<string, PropertiesSelector>();

    overviewSteps.set("basics", CompanyBasicsFilter);
    overviewSteps.set("riskManagement", CompanyRiskManagementFilter);
    overviewSteps.set("license", CompanyAccountFilter);
    overviewSteps.set("addresses", CompanyAddressesFilter);

    return overviewSteps;
  }

  return (
    company == null ? null : 
        <DocumentView
          {...props}
          databaseDocument={company}
          inputTabs={companyOverviewSteps()}
          onDocumentChange={onDocumentChange}
      />
  );
}

export function CompanyPublicView(props : DocumentProps) {

  const companyBasicsSteps = (): Map<string, PropertiesSelector> => {

    let publicSteps = new Map<string, PropertiesSelector>();

    publicSteps.set("basics", CompanyPublicFilter);

    return publicSteps;
  }

  return (<DocumentView 
    {...props}
    inputTabs={companyBasicsSteps()} 
  />);
}


export function CompanyPrivacyView(props : DocumentProps) {

  const { t } = useTranslation();

  const companyPrivacySteps = (): Map<string, PropertiesSelector> => {

    let privacySteps = new Map<string, PropertiesSelector>();

    privacySteps.set("privacy", CompanyPrivacyFilter);
    privacySteps.set(IncidentRegistrationsCollection, CompanyIncidentRegistrationsPrivacyFilter);
    privacySteps.set(VaccineRegistrationsCollection, CompanyVaccineRegistrationsPrivacyFilter);
    privacySteps.set(TestRegistrationsCollection, CompanyTestRegistrationsPrivacyFilter);
    privacySteps.set(SymptomRegistrationsCollection, CompanySymptomRegistrationsPrivacyFilter);
    privacySteps.set(QuarantineRegistrationsCollection, CompanyQuarantineRegistrationsPrivacyFilter);
    privacySteps.set(SickLeaveRegistrationsCollection, CompanySickLeaveRegistrationsPrivacyFilter);


    return privacySteps;
  }

  return (<DocumentView 
    {...props}
    title={t("privacyOptions")} 
    inputTabs={companyPrivacySteps()} />);
}

export function CompanyAdministratorsView(props : DocumentProps) {

  const companyAdministratorSteps = (): Map<string, PropertiesSelector> => {

    let administratorsSteps = new Map<string, PropertiesSelector>();

    administratorsSteps.set("administrators", CompanyAdminsFilter);

    return administratorsSteps;
  }

  return (<DocumentView 
    {...props}
    inputTabs={companyAdministratorSteps()} 
  />);
}




