import { DatabaseDocument } from "../../framework/databaseDocument";
import { DatabaseProperty } from "../../framework/databaseProperty";
import { log } from "../../framework/databaseService";
import { DatabaseObject } from "../../framework/databaseObject";
import { PropertyType, PropertyTypes } from "../../api/definitions/propertyType"; 
import { OwnerPropertyIF } from "../../api/properties/ownerPropertyIF";
import { Factory } from "../../../common/api/factory";
import { IdsSuffix, IdSuffix, OwnerTitlesSuffix } from "../../api/core/databaseServiceIF";
import { CollectionDatabase } from "../core/collectionDatabase";
import { ReferenceHandle } from "../..";
import { CollectionGroupDatabase } from "../core/collectionGroupDatabase";
import { Database } from "../core/database";
import { Observation } from "../../../common/api/observation";


export class OwnerProperty<DerivedDocument extends DatabaseDocument> 
    extends DatabaseProperty<ReferenceHandle<DerivedDocument>> implements OwnerPropertyIF<DerivedDocument> {

        constructor( parent : DatabaseObject, collectionName : string, documentName : string ) {
        
        super( PropertyTypes.Owner as PropertyType, parent );  
        
        //log.traceIn( "constructor()", parent.title, collectionName );

        try {

            this._collectionName = collectionName;

            this._documentName = documentName;

           //log.traceOut( "constructor()" ); 
        } catch( error ) { 
            
            log.warn( "constructor()", "Error initializing document owner", error );

            throw new Error( (error as any).message );
        }
    }

    collectionName() : string {
        return this._collectionName;
    }

    documentName() : string {
        return this._documentName;
    }


    value() : ReferenceHandle<DerivedDocument> | undefined {
        return this.referenceHandle();
    }


    setValue( value : ReferenceHandle<DerivedDocument> | undefined ) : void {

        if( value == null ) {
            this.clearDocuments();
        }
        else {
            this.setDocument( value );
        }
    }

    count() : number {

        const result = this._handles != null ? this._handles.size : 0;

        //log.traceInOut( "count()", result );
        return result;
    }

    hasDocument( documentPath : string ) : boolean {

        const handles = this.handles();

        if( handles == null ) {
            //log.traceInOut( "hasDocument()", "No handles" );
            return false;
        }

        const result = handles.has( documentPath.split("?")[0] );

        //log.traceInOut( "hasDocument()", result );
        return result;
    }

    id() : string | undefined {

        const ids = this.ids();

        if( ids == null || ids.length === 0 ) {
            return undefined;
        }

        return ids[ids.length -1];
    }
 

    ids() : string[] | undefined {

        try {
            //log.traceIn( "ids()" );

            let result : string[] = [];

            const handles = this.handles();

            if( handles == null ) {
                //log.traceOut( "ids()", "No handles" );
                return undefined;
            }

            handles.forEach( handle => {

                if( handle.path == null ) {
                    throw new Error( "Unexpected empty path");
                }

                const documentId = 
                    Factory.get().databaseService.databaseFactory.documentId( handle.path );

                result.push( documentId! );
            })
            
            //log.traceOut( "ids()", result );
            return result;   

        } catch( error ) {
            
            log.warn( "paths()", "Error reading database object ids", error );

            throw new Error( (error as any).message );
        }
    }

    path( ignoreCleared? : boolean ) : string | undefined {

        //log.traceIn( "path()" );

        const paths = this.paths( ignoreCleared );

        if( paths == null || paths.length === 0 ) {
            return undefined;
        }

        const path = paths[paths.length -1];

        //log.traceOut( "path()", path );
        return path;
    }
 

    paths( ignoreCleared? : boolean ) : string[] | undefined {

        try {
            //log.traceIn( "paths()" );

            const handles = this.handles( ignoreCleared );

            if( handles == null ) {
                //log.traceOut( "paths()", "No handles" );
                return undefined;
            }

            let result = Array.from( handles.keys() );

            //log.traceOut( "paths()", result );
            return result;   

        } catch( error ) {
            
            log.warn( "paths()", "Error reading database object ids", error );

            throw new Error( (error as any).message );
        }
    }


    title() : string | undefined {

        try {

        const titles = this.titles();

        if( titles == null || titles.length === 0 ) {
            return undefined;
        }

        const title = titles[titles.length -1];

        return title != null && title.length > 0 ? title : undefined;

        } catch( error ) {
                
            log.warn( "titles()", "Error reading database object ids", error );

            return undefined;
        }
    }

    titles() : string[] | undefined {

        try {
            //log.traceIn( "titles()" );

            const referenceHandles = this.referenceHandles();

            if( referenceHandles == null ) {
                //log.traceOut( "titles()", "No handles" );
                return undefined;
            }

            let result : string[] = [];

            referenceHandles.forEach( referenceHandles => {
                result.push( referenceHandles.title != null ? referenceHandles.title : "" );
            })
            
            //log.traceOut( "titles()", result );
            return result;   

        } catch( error ) {
            
            log.warn( "titles()", "Error reading database object ids", error );

            return undefined;
        }
    }

    referenceHandle( ignoreCleared? : boolean ) : ReferenceHandle<DerivedDocument> | undefined {

        const referenceHandles = this.referenceHandles( ignoreCleared );

        if( referenceHandles == null || referenceHandles.size === 0 ) {
            return undefined;
        }

        let result : ReferenceHandle<DerivedDocument>;

        referenceHandles.forEach( referenceHandle => {

            if( result == null || referenceHandle.path.length > result.path.length ) {
                result = referenceHandle;
            }
        })

        return result!;
    }

    depth() : number | undefined {

        const handles = this.handles();

        if( handles == null || handles.size === 0 ) {
            return undefined;
        }

        return handles.size;
    }

    async documents(): Promise<Map<string,DerivedDocument> | undefined> {
 
        //log.traceIn( "documents()", monitor );

        try {
            const handles = this.handles();

            if( handles == null ) {
                //log.traceOut( "documents()", "No handles" );
                return undefined;
            }

            let result = new Map<string,DerivedDocument>();

            handles.forEach(async ( handle ) => {

                let databaseDocument = handle.databaseDocument as DerivedDocument | undefined;

                if ( databaseDocument == null) {

                    databaseDocument = 
                        await Factory.get().databaseService.databaseFactory.documentFromUrl( handle.path ) as DerivedDocument;

                    if( databaseDocument == null ) {

                        throw new Error( "Owner document does not exist with path: " + handle.path );
                    }

                    handles.set( handle.path.split("?")[0], this.getNewHandle( handle.path, databaseDocument.title.value(), databaseDocument ) );
                }  

                result.set( handle.path, databaseDocument );
                
            });
            delete this._encryptedTitles;
            
            //log.traceOut( "documents()", result );
            return result;  

        } catch( error ) {

            log.warn( "documents()", "Error reading database objects", error );

            throw new Error( (error as any).message );
        }    
    }

    emptyDocument(): DerivedDocument | undefined {

        //log.traceIn( "emptyDocument()", documentPath );

        try {

            const path = this.path();

            if( path == null ) {
                //log.traceOut( "emptyDocument()", "No handles" );
                return undefined;
            }
        
            const databaseDocument = 
                Factory.get().databaseService.databaseFactory.newDocumentFromUrl( path ) as DerivedDocument;
    
            //log.traceOut( "emptyDocument()", document );
            return databaseDocument;

        } catch( error ) {
            log.warn( "emptyDocument()", "Error reading database document", error );

            throw new Error( (error as any).message );
        }
    }

   async document( ignoreCleared? : boolean ): Promise<DerivedDocument | undefined> {

        //log.traceIn( "document()", documentPath );

        try {

            const path = this.path( ignoreCleared );

            if( path == null ) {
                //log.traceOut( "document()", "No handles" );
                return undefined;
            }

            const handles = this.handles( ignoreCleared )!;

            let handle = handles.get( path.split("?")[0] )!;

            let databaseDocument;

            if( handle.databaseDocument == null ) {

                databaseDocument = await Factory.get().databaseService.databaseFactory.documentFromUrl( 
                    path ) as DerivedDocument;

            }
            else {
                databaseDocument = handle.databaseDocument as DerivedDocument;
            }

            if( databaseDocument != null ) {

                handle = this.getNewHandle( path, databaseDocument.title.value(), databaseDocument );

                handles.set( path.split("?")[0], handle );

                delete this._encryptedTitles;
            }

            //log.traceOut( "document()", document );
            return databaseDocument as DerivedDocument;

        } catch( error ) {
            log.warn( "document()", "Error reading database document", error );

            throw new Error( (error as any).message );
        }
    }

    setDocument( referenceHandle : ReferenceHandle<DerivedDocument> ): void {
        log.traceIn( "setDocument()", referenceHandle.title );

        try {

            this.cleared = false;

            const existingHandles = this.handles( true )!;

            const existingHandle = existingHandles != null ? Array.from(existingHandles.values()).pop() : undefined;

            if( existingHandle != null && 
                Factory.get().databaseService.databaseFactory.equalDatabasePaths(
                    existingHandle.path, referenceHandle.path ) ) {
    
                if( referenceHandle.title != null && referenceHandle.title.length > 0 ) {
    
                    const handle = existingHandles.get( referenceHandle.path.split("?")[0] );
    
                    //log.debug( "setDocument()", {handle} );
    
                    if( handle != null ) {
                        handle.title = referenceHandle.title; 
                    }
                }
    
                this.clearChanges();

                super.notify( Observation.Update, referenceHandle.path, referenceHandle );

                log.traceOut( "setDocument()", "same path" );
                return;
            }

            const changed = !Factory.get().databaseService.databaseFactory.equalDatabasePaths(
                existingHandle?.path, referenceHandle.path );
                
            const newHandles = new Map<string,ReferenceHandle<DerivedDocument>>();

            const pathElements = referenceHandle.path.startsWith("/") ? 
                referenceHandle.path.substring(1).split("/") : referenceHandle.path.split("/");  // remove leading "/" and split the rest

            let ownerPath = "";

            for( let i = 0; i < pathElements.length; i++ ) {

                ownerPath += "/" + pathElements[i];

                if( i > 0 && this.collectionName()=== pathElements[i - 1] ) {

                    let title;

                    let databaseDocument;

                    if( Factory.get().databaseService.databaseFactory.equalDatabasePaths( ownerPath, referenceHandle.path ) ) {
                        title = referenceHandle.title;
                    }

                    const ownerHandle = existingHandles?.get( ownerPath.split("?")[0] );

                    if( title == null ) {

                        title = ownerHandle?.title;
                    }

                    databaseDocument = ownerHandle?.databaseDocument != null ? ownerHandle?.databaseDocument : null;

                    newHandles.set( ownerPath.split("?")[0], this.getNewHandle( ownerPath, title, databaseDocument ) );   
                }
            }

            if( changed ) {
                this.setLastChange( existingHandle );
            }

            this._handles = newHandles;

            delete this._selectCollection;
            delete this._selectCollectionGroup;

            log.traceOut( "setDocument()", newHandles.size );

        } catch( error ) {
            log.warn( "setDocuments()", "Error adding database objects", error );

            throw new Error( (error as any).message );
        }
    }


    clearDocuments(): void {

        //log.traceIn( "clearDocuments()", this._handles );

        try {
            delete this._encryptedTitles;

            if( this._handles != null && this._handles.size > 0 ) {

                const existingValue = this.value();

                this.setLastChange( existingValue );
            }
            this.cleared = true;

            delete this.error;

            delete this._selectCollection;
            delete this._selectCollectionGroup;

            //log.traceOut( "clearDocuments()", this._handles );

        } catch( error ) {
            log.warn( "clearDocuments()", "Error adding database object", error );

            throw new Error( (error as any).message );
        }
    }
    
    async options(): Promise<Map<string,ReferenceHandle<DerivedDocument>> | undefined> {
 
        log.traceIn( "options()" );

        const options = this.referenceHandles();

        log.traceOut( "options()", options );
        return options != null && options.size > 0 ? options : undefined;
    }

    async select( collectionGroup? : boolean ): Promise<Map<string,ReferenceHandle<DerivedDocument>> | undefined> {
 
        log.traceIn( "select()", {collectionGroup} );

        let result = new Map<string,ReferenceHandle<DerivedDocument>>();
        try {

            const ignoreCleared = true;
            let thisReferenceHandle = this.referenceHandle( ignoreCleared );

            if( thisReferenceHandle != null ) {

                if( thisReferenceHandle.title == null ) {

                    await this.document( ignoreCleared );

                    thisReferenceHandle = this.referenceHandle( ignoreCleared );
                }

                result.set( thisReferenceHandle!.path, thisReferenceHandle! );
            }

            const database = this.selectDatabase( collectionGroup );

            if( database == null ) {
                log.traceOut( "select()", "No select database" );
                return result;
            }

            const referenceHandles = await database.referenceHandles();

            referenceHandles.forEach( referenceHandle => {

                if( thisReferenceHandle == null || 
                    !Factory.get().databaseService.databaseFactory.equalDatabasePaths( 
                        thisReferenceHandle.path, referenceHandle.path )) {

                    result.set( referenceHandle.path, referenceHandle );
                }
            });

            log.traceOut( "select()", "from root" );
            return result;  

        } catch( error ) {

            log.warn( "select()", "Error reading database objects", error );

            throw new Error( (error as any).message );
        }    
    }

    selectDatabase( collectionGroup? : boolean ) : Database<DerivedDocument> | undefined {

        log.traceIn("selectDatabase()", {collectionGroup} );

        try {
            if( !!collectionGroup ) {
                if( this._selectCollectionGroup == null ) {
                    this._selectCollectionGroup = this.parent.parentCollectionGroup( this._collectionName ) as CollectionGroupDatabase<DerivedDocument>;
                }
                return this._selectCollectionGroup;
            }
            else {
                if( this._selectCollection == null ) {
                    this._selectCollection = this.parent.parentCollection( this._collectionName ) as CollectionDatabase<DerivedDocument>;
                }
                return this._selectCollection;
            }
            //log.traceOut("selectDatabase()", "found" );

        } catch (error) {
            log.warn("selectDatabase()", "Error selecting database", error);

            throw new Error( (error as any).message );
        }
    }


    async toData( documentData: any, force? : boolean ) : Promise<void> {

        //log.traceIn( "toData()" );

        try {

            if( !!force ) {
                this.referenceHandles();
            }

            
            if( this.encrypted() && this.encryptedData() != null ) {
            
                documentData[this.key()] = this.encryptedData();
                return;
            }

            const key = this.key();

            const ids = this.ids();

            if( ids != null && key != null ) {
                documentData[key + IdsSuffix] = ids;
            }
    
            const id = this.id();

            if( id != null && key != null ) {
                documentData[key + IdSuffix] = id;
            }
    
            const titles = this.titles();

            if( titles != null && key != null ) {

                if( this.encrypted() ) {
                    documentData[key + OwnerTitlesSuffix] = this.encryptData( JSON.stringify( titles ) ); 
                }
                else {
                    documentData[key + OwnerTitlesSuffix] = titles;
                }
            }

            //log.traceOut( "toData()", documentData );

        } catch( error ) {
            log.warn( "toData()", "toData()", "Error notifying observers", error );

            throw new Error( (error as any).message );
        }

    }

    fromData( documentData: any): void {

        //log.traceIn( "fromData()" );

        // We don't read owner ids from data as they are read from path. Only written to DB for group query purposes

        let ownerTitles : string[];

        const key = this.key();

        if( this.isEncryptedData( documentData[key + OwnerTitlesSuffix] ) ) {   

            this._encryptedTitles = documentData[key + OwnerTitlesSuffix];
        }
        else {
            ownerTitles = documentData[key + OwnerTitlesSuffix] as string[];
        
            if( ownerTitles == null ) {
                return;
            }

            const handles = this.handles();

            if( handles == null ) {
                return;
            }

            if( ownerTitles.length !== handles.size ) {
                return;
            }

            const handlesArray = Array.from( handles.values() );

            for( let i = 0; i < ownerTitles.length; i++ ) {

                if( ownerTitles[i] !== null && ownerTitles[i].length > 0 ) {
                    handlesArray[i].title = ownerTitles[i];
                }
            }
        }
            
        //log.traceOut( "fromData()", this );
    } 

    referenceHandles( ignoreCleared? : boolean ) : Map<string,ReferenceHandle<DerivedDocument>> | undefined {

        try {
            //log.traceIn( "referenceHandles()" );

            const handles = this.handles( ignoreCleared );

            if( handles == null ) {
                //log.traceOut( "titles()", "No handles" );
                return undefined;
            }
            const result = new Map<string,ReferenceHandle<DerivedDocument>>();

            handles.forEach( handle => {

                result.set( handle.path, {
                    path: handle.path,
                    title: handle.title,
                    date: handle.date,
                    databaseDocument: handle.databaseDocument
                })
            })

            //log.traceOut( "referenceHandles()", result );
            return result;   

        } catch( error ) {
            
            log.warn( "referenceHandles()", "Error reading reference handles", error );

            throw new Error( (error as any).message );
        }
    }

    compareTo( other : OwnerProperty<DerivedDocument> ) : number {

        return this.compareValue( other.value() );

    }

    compareValue( otherValue : ReferenceHandle<DerivedDocument> | undefined ) : number {

        const value = this.value();

        if( value?.path == null && otherValue?.path == null ) {
            return 0;
        }

        if( value?.path == null && otherValue?.path != null ) {
            return -1;
        }

        if( value?.path != null && otherValue?.path == null ) { 
            return 1;
        }

        const pathCompare = value!.path.split("?")[0].localeCompare( otherValue!.path.split("?")[0] );

        const titleCompare = value!.title != null && otherValue?.title != null ? 
            value!.title!.localeCompare( otherValue!.title! ) : 0;

        return pathCompare !== 0 && titleCompare !== 0 ?
            titleCompare :
            pathCompare;
    }

    includes( other : OwnerProperty<DerivedDocument>  ) : boolean {
        return this.includesValue( other.value() );
    }

    includesValue( otherValue : ReferenceHandle<DerivedDocument> | undefined ) : boolean {

        const path = this.path();

        if( path == null && otherValue == null ) {
            return false;
        }

        if( path == null && otherValue != null ) {
            return false;
        }

        if( path != null && otherValue == null ) {
            return false;
        }

        const otherPath = otherValue!.path;

        if( path == null && otherPath == null ) {
            return false;
        }

        if( path == null && otherPath != null ) {
            return false;
        }

        if( path != null && otherPath == null ) {
            return false;
        }

        return path!.split("?")[0].startsWith( otherPath!.split("?")[0] );    

    }

    copyValueFrom( other : OwnerProperty<DerivedDocument> ) : void {

        //log.traceIn( "copyValueFrom()", this._handles, other._handles );

        this.cleared = other.cleared;    

        delete this._selectCollection;
        delete this._selectCollectionGroup;

        const ignoreCleared = true;

        const existingHandles = this.handles( ignoreCleared );

        const otherHandles = other.handles( ignoreCleared );

        if( existingHandles != null && 
            otherHandles != null &&
            existingHandles.size === otherHandles.size ) {

            const existingHandlesArray = Array.from( existingHandles.values() );
            const otherHandlesArray = Array.from( otherHandles.values() );

            for( let i = 0; i < existingHandlesArray.length; i++ ) {

                if( existingHandlesArray[i].title == null ) {

                    existingHandlesArray[i].title = otherHandlesArray[i].title;

                    existingHandlesArray[i].databaseDocument = otherHandlesArray[i].databaseDocument;
                }
            }
        }
        
        //log.traceOut( "copyValueFrom()", this._handles );

    } 

    protected handles( ignoreCleared? : boolean ) : Map<string,ReferenceHandle<DerivedDocument>> | undefined {

        //log.traceIn( "handles()" );

        try {
            if( this.cleared && !ignoreCleared ) {
                //log.traceOut( "handles()", "cleared" );
                return undefined;
            }

            if( this._handles != null ) {
                //log.traceOut( "handles()", "existing", this._handles );
                return this._handles;
            }

            this._handles = new Map<string,ReferenceHandle<DerivedDocument>>();

            const paths = this.parent.ownerDocumentPaths( this._collectionName );

            //log.trace( "handles()", "paths", paths );

            if( paths == null || paths.length === 0 ) {
                //log.traceOut( "handles()", "no owner paths");
                return undefined;
            }

            for( const path of paths ) {

                this._handles.set( path.split("?")[0], this.getNewHandle( path, undefined, null ) );
            }

            if( this._encryptedTitles != null ) {

                const data = {} as any;
        
                try {
                    const jsonTitles = Factory.get().securityService.symmetricCipher.decrypt( this._encryptedTitles  );

                    data[this.key() + OwnerTitlesSuffix] = JSON.parse( jsonTitles );

                    this.fromData( data );

                } catch( error ) {} 

                delete this._encryptedTitles;
            }

            //log.traceOut( "handles()", "built handles", this._handles );
            return this._handles;

        } catch( error ) {
            log.warn( "handles()", "Error getting handles", error );

            throw new Error( (error as any).message );
        }
    }

    protected getNewHandle( path : string, title : string | undefined, databaseDocument : DerivedDocument | null ) : ReferenceHandle<DerivedDocument> { 

        const databaseHandle = {

            path : path,

            databaseDocument : databaseDocument

        } as ReferenceHandle<DerivedDocument>;

        if( title != null  ) {
            databaseHandle.title = title;
        }

        return databaseHandle;
    } 

    cleared : boolean = false;

    private readonly _collectionName : string;

    private readonly _documentName : string;

    private _selectCollection? : CollectionDatabase<DerivedDocument>;

    private _selectCollectionGroup? : CollectionGroupDatabase<DerivedDocument>;
    
    private _handles : Map<string,ReferenceHandle<DerivedDocument>> | undefined;

    private _encryptedTitles? : string;


}
