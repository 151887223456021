
import { CollectionDatabase } from "../../../services/database/impl/core/collectionDatabase";
import { log } from "../../../services/database/framework/databaseService";
import { User } from "../../../services/database/impl/documents/user";
import { DefinitionProperty } from "../../../services/database/impl/properties/definitionProperty";
import { CollectionProperty } from "../../../services/database/impl/properties/collectionProperty";
import { MedicalCertification, MedicalCertifications } from "../../api/definitions/medicalCertification";
import { HealthguardUserIF } from "../../api/documents/healthguardUserIF";
import { IncidentRegistrationsCollection, QuarantineRegistrationsCollection, SickLeaveRegistrationsCollection, SymptomRegistrationsCollection, TestRegistrationsCollection, VaccineRegistrationsCollection, RegistrationsCollection } from "../../api/registrationCollections";
import { ConsentsCollection, GatheringsCollection, MeasuresCollection, RisksCollection } from "../../api/healthguardCollections";
import { MedicalCertificationDefinition, TestResultDefinition } from "../../api/healthguardDefinitions";
import { TestRegistrationDocument, VaccineRegistrationDocument } from "../../api/registrationDocuments";
import { HealthguardUserDocument } from "../../api/healthguardDocuments";
import { IncidentRegistration } from "./incidentRegistration";
import { QuarantineRegistration } from "./quarantineRegistration";
import { SickLeaveRegistration } from "./sickLeaveRegistration";
import { SymptomRegistration } from "./symptomRegistration";
import { TestRegistration } from "./testRegistration";
import { VaccineRegistration } from "./vaccineRegistration";
import { Gathering } from "./gathering";
import { ReferencesProperty } from "../../../services/database/impl/properties/referencesProperty";
import { HealthguardCompany } from "./healthguardCompany";
import { Measure } from "./measure";
import { Registration } from "./registration";
import { TestResult, TestResults } from "../../api/definitions/testResult";
import { DateProperty } from "../../../services/database/impl/properties/dateProperty";
import { NumberProperty } from "../../../services/database/impl/properties/numberProperty";
import { Factory } from "../../../services/common/api/factory";
import { Identifications } from "../../../services/database/api/definitions/identification";
import { Consent } from "./consent";
import { HealthguardUserManager } from "../managers/healthguardUserManager";
import { Risk } from "./risk";
import { ReferenceProperty } from "../../../services/database/impl/properties/referenceProperty";
import { Database } from "../../../services/database/impl/core/database";

export class HealthguardUser extends User implements HealthguardUserIF { 

    constructor( usersCollection : CollectionDatabase<HealthguardUser>, documentPath? : string  ) {

        super( HealthguardUserDocument, usersCollection, documentPath );

        try {
            this.medicalCertification = 
                new DefinitionProperty<MedicalCertification>( this, MedicalCertificationDefinition, MedicalCertifications );
        
            this.registrations =  
                new CollectionProperty<Registration>( this, RegistrationsCollection, true );

            this.incidentRegistrations = 
                new CollectionProperty<IncidentRegistration>( this, IncidentRegistrationsCollection, true );
                
            this.quarantineRegistrations = 
                new CollectionProperty<QuarantineRegistration>( this, QuarantineRegistrationsCollection, true );
        
            this.sickLeaveRegistrations = 
                new CollectionProperty<SickLeaveRegistration>( this, SickLeaveRegistrationsCollection, true );

            this.symptomRegistrations = 
                new CollectionProperty<SymptomRegistration>( this, SymptomRegistrationsCollection, true );

            this.testRegistrations = 
                new CollectionProperty<TestRegistration>( this, TestRegistrationsCollection, true );
        
            this.vaccineRegistrations = 
                new CollectionProperty<VaccineRegistration>( this, VaccineRegistrationsCollection, true );

            this.gatherings = new ReferencesProperty<Gathering>( this, 
                () => this.parentDatabases( GatheringsCollection, {nearestIsCollectionGroup: true} ) as Database<Gathering>[],
                "users" );

            this.risks = new ReferencesProperty<Risk>( this, 
                () => this.parentDatabases( RisksCollection, {nearestIsCollectionGroup: true} ) as Database<Risk>[],
                "users" );

            this.measures = new ReferencesProperty<Measure>( this, 
                () => this.parentDatabases( MeasuresCollection, {nearestIsCollectionGroup: false} ) as Database<Measure>[],
                "users" );

            this.responsibleFor = new ReferencesProperty<Measure>( this, 
                () => this.parentDatabases( MeasuresCollection, {nearestIsCollectionGroup: false} ) as Database<Measure>[],
                "responsible" );

            this.consents = new CollectionProperty<Consent>( this, ConsentsCollection, true );
            this.consents.editable = false; 

            this.lastAcceptedConsent = new ReferenceProperty<Consent>( this, 
                () => [this.consents.collectionGroup()] );

            this.lastAcceptedConsentDate = new DateProperty(this);

            this.lastNotifiedConsent = new ReferenceProperty<Consent>( this, 
                () => [this.consents.collectionGroup()] );

            this.lastNotifiedConsentDate = new DateProperty(this);

            const testDisabled =
                (Factory.get().databaseService.currentCompany() as HealthguardCompany)?.subcollectionDisabled(
                    TestRegistrationDocument );

            const testVisibility =
                (Factory.get().databaseService.currentCompany() as HealthguardCompany)?.subcollectionIdentification(
                    TestRegistrationDocument );

            this.lastTestResult = new DefinitionProperty<TestResult>(this, TestResultDefinition, TestResults);
            this.lastTestResult.hidden = testDisabled || testVisibility === Identifications.Anonymous;

            this.lastTestDate = new DateProperty(this);
            this.lastTestDate.hidden = testDisabled || testVisibility === Identifications.Anonymous;

            const vaccineDisabled =
                (Factory.get().databaseService.currentCompany() as HealthguardCompany)?.subcollectionDisabled(
                    VaccineRegistrationDocument);

            const vaccineVisibility = 
                (Factory.get().databaseService.currentCompany() as HealthguardCompany)?.subcollectionIdentification(
                    VaccineRegistrationDocument);

            this.lastVaccineInstallment = new NumberProperty( this, undefined, 0 );

            this.lastVaccineInstallment.hidden = vaccineVisibility === Identifications.Anonymous || vaccineDisabled;

            this.lastVaccineDate = new DateProperty(this);
            this.lastVaccineDate.hidden = vaccineVisibility === Identifications.Anonymous || vaccineDisabled;

            //log.traceInOut( "constructor()", UsersCollection );

        } catch( error ) {

            log.warn( "constructor()", "Error initializing measure", error );
            
            throw new Error( (error as any).message );
        }
    }

    async onCreate() : Promise<void> {
        try {
            log.traceIn( "("+this.collectionDatabase.collectionName()+")", "onCreate()" );

            await super.onCreate();

            await HealthguardUserManager.getInstance().handleCreateHealthguardUser( this );
    
            log.traceOut( "("+this.collectionDatabase.collectionName()+")", "onCreate()" );
  
        } catch( error ) {
            
            log.warn( "("+this.collectionDatabase.collectionName()+")", "onCreate()", "Error handling create notification", error );
  
            throw new Error( (error as any).message );
        }
    }

    async updateMeasures(): Promise<void> {
        await HealthguardUserManager.getInstance().updateMeasures( this );
    }

    async onUpdate() : Promise<void> {
        try {
            //log.traceIn( "onUpdate()" );

            await super.onUpdate();

            await HealthguardUserManager.getInstance().handleUpdateHealthguardUser( this ); 
    
            //log.traceOut( "onUpdate()" );
  
        } catch( error ) {
            
            log.warn( "onUpdated()", "Error handling update notification", error );
  
            throw new Error( (error as any).message );
        }    
    }

    async onDelete() : Promise<void> {

        try {
            //log.traceIn( "onDelete()" );

            await super.onDelete();

            await HealthguardUserManager.getInstance().handleDeleteHealthguardUser( this );
    
            //log.traceOut( "onDelete()" );
  
        } catch( error ) {
            
            log.warn( "onDelete()", "Error handling delete notification", error );
  
            throw new Error( (error as any).message );
        }  
    }

    readonly medicalCertification : DefinitionProperty<MedicalCertification>;

    readonly registrations: CollectionProperty<Registration>;

    readonly incidentRegistrations: CollectionProperty<IncidentRegistration>;

    readonly quarantineRegistrations: CollectionProperty<QuarantineRegistration>;

    readonly sickLeaveRegistrations: CollectionProperty<SickLeaveRegistration>;

    readonly symptomRegistrations: CollectionProperty<SymptomRegistration>;

    readonly testRegistrations: CollectionProperty<TestRegistration>;

    readonly vaccineRegistrations: CollectionProperty<VaccineRegistration>;

    readonly gatherings : ReferencesProperty<Gathering>;

    readonly risks : ReferencesProperty<Risk>;

    readonly responsibleFor : ReferencesProperty<Measure>;

    readonly measures : ReferencesProperty<Measure>;

    readonly consents: CollectionProperty<Consent>;

    readonly lastAcceptedConsent: ReferenceProperty<Consent>;

    readonly lastAcceptedConsentDate : DateProperty;

    readonly lastNotifiedConsent: ReferenceProperty<Consent>;

    readonly lastNotifiedConsentDate : DateProperty;

    readonly lastTestResult: DefinitionProperty<TestResult>;

    readonly lastTestDate : DateProperty;

    readonly lastVaccineInstallment: NumberProperty;

    readonly lastVaccineDate : DateProperty;

}
