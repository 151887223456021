import { UserAccessType, UserAccessTypes } from "../../database/api/definitions/userAccessType";

export class UserAccess {

    constructor( allowList : boolean, allowCreate : boolean, allowRead : boolean, allowUpdate : boolean, allowDelete : boolean ) {

        this.allowList = allowList;

        this.allowCreate = allowCreate;

        this.allowRead = allowRead;

        this.allowUpdate = allowUpdate;

        this.allowDelete = allowDelete;
    }

    userAccessTypes() : UserAccessType[] { 

        const userAccessTypes : UserAccessType[] = [];

        if( this.allowList ) {
            userAccessTypes.push( UserAccessTypes.List as UserAccessType );
        }

        if( this.allowCreate ) {
            userAccessTypes.push( UserAccessTypes.Create as UserAccessType );
        }

        if( this.allowRead ) {
            userAccessTypes.push( UserAccessTypes.Read as UserAccessType ); 
        }

        if( this.allowUpdate ) {
            userAccessTypes.push( UserAccessTypes.Update as UserAccessType );
        }

        if( this.allowDelete ) {
            userAccessTypes.push( UserAccessTypes.Delete as UserAccessType );
        }
        
        return userAccessTypes;
    }


    all() : boolean {
        return this.allowList && this.allowCreate && this.allowRead && this.allowUpdate && this.allowDelete;
    }

    none() : boolean {
        return !this.allowList && !this.allowCreate && !this.allowRead && !this.allowUpdate && !this.allowDelete;
    }

    read() : boolean {
        return this.allowRead;
    }

    write() : boolean {
        return this.allowCreate && this.allowUpdate && this.allowDelete;
    }

    readOnly() : boolean {
        return !this.allowCreate && this.allowRead && !this.allowUpdate && !this.allowDelete;
    }

    writeOnly() : boolean {
        return this.allowCreate && !this.allowRead && this.allowUpdate && this.allowDelete;
    }

    static allowAll() : UserAccess {
        return new UserAccess( true, true, true, true, true );
    }

    static allowNone() : UserAccess {
        return new UserAccess( false, false, false, false, false );
    }

    static allowReadOnly() : UserAccess {
        return new UserAccess( true, false, true, false, false );
    }

    static allowWriteOnly() : UserAccess {
        return new UserAccess( true, true, false, true, true );
    }

    static allowUpdate() : UserAccess {
        return new UserAccess( true, false, true, true, false );
    }

    static fromUserAccessTypes( userAccessTypes? : UserAccessType[] ) : UserAccess {
        return userAccessTypes == null ? UserAccess.allowNone() :
         new UserAccess( 
            userAccessTypes.includes( UserAccessTypes.List as UserAccessType ), 
            userAccessTypes.includes( UserAccessTypes.Create as UserAccessType ), 
            userAccessTypes.includes( UserAccessTypes.Read as UserAccessType ), 
            userAccessTypes.includes( UserAccessTypes.Update as UserAccessType ), 
            userAccessTypes.includes( UserAccessTypes.Delete as UserAccessType )            
        );
    }

    static merge( userAccess1 : UserAccess, userAccess2 : UserAccess ) : UserAccess {

        return new UserAccess(
            userAccess1.allowList && userAccess2.allowList,
            userAccess1.allowCreate && userAccess2.allowCreate,
            userAccess1.allowRead && userAccess2.allowRead,
            userAccess1.allowUpdate && userAccess2.allowUpdate,
            userAccess1.allowDelete && userAccess2.allowDelete
        );
    }

    readonly allowList : boolean;

    readonly allowCreate : boolean;

    readonly allowRead : boolean;

    readonly allowUpdate : boolean;

    readonly allowDelete : boolean;

}