import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import DashboardIcon from '@material-ui/icons/Dashboard';

import List from '@material-ui/core/List';

import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';


import CollectionName from '../components/collectionName';
import CollectionIcon from '../components/collectionIcon';
import AppFrame, { DefaultDashboardsOpen, PersistentKeyDashboardsOpen } from '../components/appFrame';


import { log } from 'ui/app/app';
import { AppContext, AppContextProps } from 'ui/app/appContext';
import { HomePaths } from '../../services/common/api/homePaths';

import { Factory } from 'services/common/api/factory';
import { Divider } from '@material-ui/core';
import { CompaniesCollection, UsersCollection, SubscriptionsCollection, TerminalsCollection, ChangesCollection, KeysCollection } from '../../services/database/api/collections';
import { MeasuresCollection, HazardsCollection  } from '../../healthguard/api/healthguardCollections';
import { errorDialog } from '../components/simpleDialog';
import { HazardDocument, HealthguardCompanyDocument, HealthguardUserDocument, MeasureDocument} from '../../healthguard/api/healthguardDocuments';
import { ChangeDocument, KeyDocument, SubscriptionDocument, TerminalDocument } from '../../services/database/api/documents';
import { DocumentNameKey } from '../../services/database/api/core/databaseServiceIF';
import { DatabaseDocumentIF } from '../../services/database/api/core/databaseDocumentIF';
import { DatabaseIF } from '../../services/database/api/core/databaseIF';

  


//const PersistentKeyAdminDashboard = "adminDashboard";

//const PersistentKeyAdminDashboardsOpen = PersistentKeyAdminDashboard + ".dashboardsOpen";

//const PersistentKeyAdminRegistrationsOpen = PersistentKeyAdminDashboard + ".registrationsOpen";


const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    height: '100%',
    width: '100%',
    position: 'relative',
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  currentDashboard: {
    color: theme.palette.info.dark,
  },
});


interface MatchParams {
}

interface AdminHomeProps extends WithStyles<typeof styles>, WithTranslation, RouteComponentProps<MatchParams> {
}

interface AdminHomeState { // Document View Props

  dashboardsOpen? : boolean;

  registrationsOpen? : boolean;

  selectedDatabase: DatabaseIF<DatabaseDocumentIF> | undefined,
}

class AdminHome extends React.PureComponent<AdminHomeProps,AdminHomeState> {

  constructor( props: AdminHomeProps ) {
    
    super(props);

    this.state = { 

      selectedDatabase : undefined, 

    } as AdminHomeState;


    //log.traceInOut( "constructor()" );
  }

  async componentDidMount() {

    try {
      log.traceIn( "componentDidMount()");

      await this.selectDatabase();

      log.traceOut( "componentDidMount()", "state", );

    } catch( error ) {
      log.warn( "componentDidMount()", "Error mounting documents view", error );

      await errorDialog( error);
      
      log.traceOut( "componentDidMount()", error );
    }
  }

  async componentDidUpdate() {

    try {
      //log.traceIn( "componentDidUpdate()");

      await this.selectDatabase();

      //log.traceOut( "componentDidUpdate()", "state", );

    } catch( error ) {
      log.warn( "componentDidUpdate()", "Error mounting documents view", error );

      await errorDialog( error);
      
    }
  }


  async selectDatabase() {

    try {
      log.traceIn( "selectDatabase()");

      let url = this.props.location.pathname;

      if( url.endsWith( HomePaths.AdminHomePath )) {

        this.setState( { selectedDatabase: undefined } );

        log.traceOut( "selectDatabase()", "no selected database");
        return;
      }


      const query = new URLSearchParams(this.props.location.search);

      const documentName = query.get( DocumentNameKey );

      if( documentName != null ) {

        url += "?" + DocumentNameKey + "=" + documentName;
      }

      const selectedDatabase = Factory.get().databaseService.databaseFactory.databaseFromUrl( url );

        
      if( selectedDatabase?.defaultDocumentName() !== this.state.selectedDatabase?.defaultDocumentName() ) {
        this.setState( { selectedDatabase: selectedDatabase } );
      }

      log.traceOut( "componentDidMount()", "state");

    } catch( error ) {
      log.warn( "componentDidMount()", "Error mounting documents view", error );

      await errorDialog( error);
      
      log.traceOut( "componentDidMount()", error );
    }
  }

  private handleAdminDashboardClick = async () => {

    log.traceInOut( "handleAdminDashboardClick()" );

    const appContext = this.context as AppContextProps;

    if( appContext.onUpdateActiveUnit != null ) {
      await appContext.onUpdateActiveUnit( undefined );
    }

    if( appContext.onUpdateActiveCompany != null ) {
      await appContext.onUpdateActiveCompany( undefined );
    }

    this.setState( { selectedDatabase : undefined });

    this.props.history.push( HomePaths.AdminHomePath );

  };

  private handleCompanyDashboardClick = async () => {

    log.traceInOut( "handleCompanyDashboardClick()" );

    const appContext = this.context as AppContextProps;

    if( appContext.onUpdateActiveUnit != null ) {
      await appContext.onUpdateActiveUnit( undefined );
    }

    this.setState( { selectedDatabase : undefined });

    this.props.history.push( HomePaths.CompanyHomePath );

  };


  private handleDashboardsClick = async () => {

    log.traceInOut( "handleDashboardsClick()" );

    if( this.state.selectedDatabase != null ) {

      await this.handleAdminDashboardClick();
    }
    else {
      const dashboardsOpen = this.isDashboardsOpen();

      Factory.get().persistentState!.setProperty( PersistentKeyDashboardsOpen, !dashboardsOpen );

      this.setState( {  dashboardsOpen : !dashboardsOpen  });

      this.props.history.push( HomePaths.AdminHomePath );

    }
  };


  private isDashboardsOpen = () : boolean  =>  {

    //log.traceIn( "isDashboardsOpen()", appContext );

    if( this.state.dashboardsOpen != null ) {
      //log.traceOut( "isDashboardsOpen()", "From state", this.state.dashboardsOpen );
      return this.state.dashboardsOpen;
    }

    const persistentDashboardsOpen = Factory.get().persistentState!.property( PersistentKeyDashboardsOpen ) as boolean;

    if( persistentDashboardsOpen != null ) {

      //log.traceOut( "isDashboardsOpen()", "From persistent app state", persistentDashboardsOpen );
      return persistentDashboardsOpen;
    }

    //log.traceOut( "isDashboardsOpen()", "From default", DefaultDashboardsOpen );
    return DefaultDashboardsOpen;
  };


  private handleMeasuresClick = () => {

    log.traceInOut( "handleMeasuresClick()" );

    const selectedDatabase =
      Factory.get().databaseService.databaseFactory.collectionDatabaseFromCollectionName(MeasuresCollection);

    this.setState({ selectedDatabase: selectedDatabase });

    this.props.history.push(HomePaths.AdminHomePath + selectedDatabase.databasePath(true));

  };
  
  private handleHazardsClick = () => {

    log.traceInOut( "handleHazardsClick()" );

    const selectedDatabase =
      Factory.get().databaseService.databaseFactory.collectionDatabaseFromCollectionName(HazardsCollection);

    this.setState({ selectedDatabase: selectedDatabase });

    this.props.history.push(HomePaths.AdminHomePath + selectedDatabase.databasePath(true));

  };


  private handleCompaniesClick = () => {
    log.traceInOut( "handleCompaniesClick()" );

    const selectedDatabase =
      Factory.get().databaseService.databaseFactory.collectionDatabaseFromCollectionName(CompaniesCollection);

    this.setState({ selectedDatabase: selectedDatabase });

    this.props.history.push(HomePaths.AdminHomePath + selectedDatabase.databasePath(true));

    this.props.history.push(HomePaths.AdminHomePath + selectedDatabase.databasePath( true ));

  };


  private handleUsersClick = () => {

    log.traceInOut( "handleUsersClick()" );

    const selectedDatabase =
      Factory.get().databaseService.databaseFactory.collectionGroupDatabaseFromCollectionName(UsersCollection);

    this.setState({ selectedDatabase: selectedDatabase });

    this.props.history.push(HomePaths.AdminHomePath + selectedDatabase.databasePath(true));

  };

  private handleSubscriptionsClick = () => {

    log.traceInOut( "handleSubscriptionsClick()" );

    const selectedDatabase =
      Factory.get().databaseService.databaseFactory.collectionGroupDatabaseFromCollectionName(SubscriptionsCollection);

    this.setState({ selectedDatabase: selectedDatabase });

    this.props.history.push(HomePaths.AdminHomePath + selectedDatabase.databasePath(true));

  };


  private handleKeysClick = () => {

    log.traceInOut( "handleKeysClick()" );

    this.selectAdminCollectionView( KeysCollection );
  }


  private handleChangesClick = () => {

    log.traceInOut( "handleChangesClick()" );

    this.selectAdminCollectionView( ChangesCollection );
  }

  private handleTerminalsClick = () => {

    log.traceInOut( "handleTerminalsClick()" );

    this.selectAdminCollectionView( TerminalsCollection );

  }

  private selectAdminCollectionView( collectionName : string ) {

    log.traceIn("selectAdminCollectionView()", {collectionName} );

    const appContext = this.context as AppContextProps;

    const selectedDatabase =
      Factory.get().databaseService.databaseFactory.collectionDatabaseFromCollectionName(
        collectionName, appContext.currentUser );

    this.setState({ selectedDatabase: selectedDatabase });

    this.props.history.push(HomePaths.AdminHomePath + selectedDatabase.databasePath(true));

    log.traceOut("selectCollection()");
  }




  render() {
    //log.traceIn( "render()", this.props.location.pathname );

    const selectedCollectionName = this.state.selectedDatabase != null ?
      this.state.selectedDatabase.collectionName(): undefined;

    const dashboardItems = (
      <AppContext.Consumer>
        {appContext => (
          <div>
            <List>
              <ListItem
                button
                onClick={() => this.handleAdminDashboardClick()}
                selected={this.state.selectedDatabase == null}>
                <ListItemIcon>
                  <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary={this.props.t("adminDashboard")} />
              </ListItem>
            </List>
          </div>
        )}
      </AppContext.Consumer>
    );


    const healthAdministrationItems = (
      <AppContext.Consumer>
        {appContext => (
          <div>
            <List>
              <Divider />
              <ListSubheader inset disableSticky>{this.props.t('healthAdministration')}</ListSubheader>
              <ListItem button onClick={()=>this.handleHazardsClick()} selected={selectedCollectionName === HazardsCollection}>
                <ListItemIcon>
                  <CollectionIcon collectionName={HazardsCollection} />
                </ListItemIcon>
                <ListItemText><CollectionName name={HazardDocument} /></ListItemText>
              </ListItem>
              <ListItem button onClick={()=>this.handleMeasuresClick()} selected={selectedCollectionName === MeasuresCollection}>
                <ListItemIcon>
                  <CollectionIcon collectionName={MeasuresCollection} />
                </ListItemIcon>
                <ListItemText><CollectionName name={MeasureDocument} /></ListItemText>
              </ListItem>
            </List>
          </div>
        )}
      </AppContext.Consumer>
    );


    const systemAdministrationItems = (
      <AppContext.Consumer>
        {appContext => (
          <div>
            <List>
              <Divider />
              <ListSubheader inset disableSticky>{this.props.t('systemAdministration')}</ListSubheader>
              <ListItem button onClick={()=>this.handleCompaniesClick()} selected={selectedCollectionName === CompaniesCollection}>
                <ListItemIcon>
                  <CollectionIcon collectionName={CompaniesCollection} />
                </ListItemIcon>
                <ListItemText><CollectionName name={HealthguardCompanyDocument} /></ListItemText>
              </ListItem>
              <ListItem button onClick={()=>this.handleSubscriptionsClick()} selected={selectedCollectionName === SubscriptionsCollection}>
                <ListItemIcon>
                  <CollectionIcon collectionName={SubscriptionsCollection} />
                </ListItemIcon>
                <ListItemText><CollectionName name={SubscriptionDocument} /></ListItemText>
              </ListItem>
              <ListItem button onClick={()=>this.handleUsersClick()} selected={selectedCollectionName === UsersCollection}>
                <ListItemIcon>
                  <CollectionIcon collectionName={UsersCollection} />
                </ListItemIcon>
                <ListItemText><CollectionName name={HealthguardUserDocument} /></ListItemText>
              </ListItem>
            </List>
          </div>
        )}
      </AppContext.Consumer>
    );

    const auditItems = (
      <AppContext.Consumer>
        {appContext => (
          <div>
            <List>
              <Divider />
              <ListSubheader inset disableSticky>{this.props.t('audit')}</ListSubheader>
              <ListItem button onClick={()=>this.handleTerminalsClick()} selected={selectedCollectionName === TerminalsCollection}>
                <ListItemIcon>
                  <CollectionIcon collectionName={TerminalsCollection} />
                </ListItemIcon>
                <ListItemText><CollectionName name={TerminalDocument} /></ListItemText>
              </ListItem>
              <ListItem button onClick={()=>this.handleKeysClick()} selected={selectedCollectionName === KeysCollection}>
                <ListItemIcon>
                  <CollectionIcon collectionName={KeysCollection} />
                </ListItemIcon>
                <ListItemText><CollectionName name={KeyDocument} /></ListItemText>
              </ListItem>
              <ListItem button onClick={()=>this.handleChangesClick()} selected={selectedCollectionName === ChangesCollection}>
                <ListItemIcon>
                  <CollectionIcon collectionName={ChangesCollection} />
                </ListItemIcon>
                <ListItemText><CollectionName name={ChangeDocument}/></ListItemText>
              </ListItem>
            </List>
          </div>
        )}
      </AppContext.Consumer>
    );

    log.traceOut( "renderMain()" );

    return (
      <React.Fragment>
        <AppFrame>
          {dashboardItems}
          {systemAdministrationItems}
          {healthAdministrationItems}
          {auditItems}
        </AppFrame>
      </React.Fragment>
    );
  }
}

AdminHome.contextType = AppContext;

export default withRouter(withTranslation()(withStyles(styles)(AdminHome)));

