import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';


import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';

import { Box, Card, Checkbox, Collapse, Fab, Grid, Paper, Tooltip } from '@material-ui/core';

import CssBaseline from '@material-ui/core/CssBaseline';
import FilterListIcon from '@material-ui/icons/FilterList';
import AddIcon from '@material-ui/icons/Add';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import HighlightIcon from '@mui/icons-material/Highlight';

import theme from '../app/theme';

import { log } from "ui/app/app";
import { AppContext, AppContextProps } from 'ui/app/appContext';

import CollectionName from 'ui/components/collectionName';
import DateFilter from 'ui/components/dateFilter';
import { DateRange } from "services/database/api/core/dateRange";
import PropertyFilter from 'ui/components/propertyFilter';
import { addDocument, openDocument, removeDocument } from "ui/components/documentRouter";

import Title from 'ui/components/title';

import { DocumentNameKey } from 'services/database/api/core/databaseServiceIF';
import { DefaultShowAccumulatedChart, DefaultShowBarChart, DefaultFilterOpen, DefaultShowMap, DefaultShowGraph, DefaultIncludeHistoric, PersistentKeyShowAccumulatedChart, PersistentKeyDatabaseViewType, PersistentKeyFilterDateRange, PersistentKeyFilterOpen, PersistentKeyShowMap, PersistentKeyShowGraph, PersistentKeyIncludeHistoric, PersistentKeyShowCard, DefaultShowCard, PersistentKeyHighlightedProperty, PersistentKeyShowTree, DefaultShowTree, PersistentKeyShowBarChart } from './appFrame';

import { ToggleButton } from '@mui/material';

import { Factory } from 'services/common/api/factory';
import { PropertiesSelector } from 'services/database/api/core/propertiesSelector';
import { DatabaseFilter } from 'services/database/api/core/databaseFilter';
import { CompanyDocument, UnitDocument, UserDocument } from '../../services/database/api/documents';
import { errorDialog } from './simpleDialog';
import { databaseCard, databaseChart, databaseGraph, databaseTable, databaseMap, databaseTree, databaseList } from './databaseRouter';

import {  selectProperty } from "ui/components/selectDialog";
import { DatabaseObserverIF } from '../../services/database/api/core/databaseObserverIF';
import { DatabaseQuery } from '../../services/database/api/core/databaseQuery';
import { HomePaths } from '../../services/common/api/homePaths';
import { DisplayTypes } from '../app/display';
import { DatabaseDocumentIF } from '../../services/database/api/core/databaseDocumentIF';
import DatabaseViewMenu, { DatabaseViewType, DatabaseViewTypes } from './databaseViewMenu';
import { PropertyTypes } from '../../services/database/api/definitions/propertyType';

export const emptyHighlightedPropertyKey = DocumentNameKey;

export const highlightedPropertiesSelector = { 

  excludePropertyTypes: [
    PropertyTypes.Collection,
    PropertyTypes.Subdocument,
    PropertyTypes.References,
    PropertyTypes.SymbolicCollection,
    PropertyTypes.SymbolicOwners,
    PropertyTypes.Texts,
    PropertyTypes.Definitions,
    PropertyTypes.Data,
    PropertyTypes.Empty,
    PropertyTypes.Geolocation,
    PropertyTypes.Map,
    PropertyTypes.Date,
    PropertyTypes.Attachment,
    PropertyTypes.Attachments,
    PropertyTypes.Image,
    PropertyTypes.Links
  ],

  excludePropertyKeys: [
    "id",
    "name",
    "title",
    "description",
    "lastChangedBy",
    "archived",
    "archivedAt"
]

} as PropertiesSelector


const defaultDiagramHeight = "28vh";

const minListHeight = "30vh";
 
const styles = (theme: Theme) => createStyles({
  root: {
    height: "100%", 
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column'
  },
  button: {
    marginRight: theme.spacing(1),
  },
  title: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(4),
    marginBottom: theme.spacing(2),
    color: theme.palette.text.secondary,      
    zIndex: 998
  },
  dateCollapse: {
    margin: theme.spacing(0),
    padding: theme.spacing(0),
    width: '100%'
  },
  propertyCollapse: {
    margin: theme.spacing(0),
    padding: theme.spacing(0),
    width: '100%'
  },
  filterButton: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(1),
  },
  filter: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    display: 'flex',
    justifyContent: 'flex-begin',
    zIndex: 999
  },
  diagram: {
    width:"100%", 
    height: defaultDiagramHeight,
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),     
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1.25),
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
    display: 'flex',
    flexDirection: "column",
    overflow: "hidden",
    backgroundColor: theme.palette.grey[100] 
  },
  card: {
    width: "100%",
    height: defaultDiagramHeight,
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),  
    paddingTop: theme.spacing(2),  
    paddingRight: theme.spacing(1),  
    paddingBottom: theme.spacing(1.25), 
    display: 'flex',
    flexDirection: "column",
    overflow: "hidden",
    backgroundColor: theme.palette.grey[100] 
  },
  table: {
    height: "100%",
    minHeight: minListHeight,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0),
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    flexGrow: 1
  }, 
  createButton: {
    position: 'absolute',
    bottom: theme.spacing(1.5),
    marginLeft: theme.spacing(0.5), 
    zIndex: 999,
    opacity: 0.85
  },
  expandButton: {
    marginTop: -theme.spacing(7),
    backgroundColor: 'transparent',
    borderRadius: 1.5 
  },
  expandBottom: {
    marginTop: theme.spacing(-1),
    marginBottom: theme.spacing(1) 
  },
  expanded: {
    height: "100%",
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0), 
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
    paddingTop: theme.spacing(2), 
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
    paddingBottom: theme.spacing(0.75),
    display: "flex",
    overflow: "hidden",
    flexDirection: "column"
  }
});



interface MatchParams {
}

export interface DatabaseProps {

  featuredProperties?: string[],

  highlightedPropertyKey? : string,

  multipleDocuments? : boolean,

  hideAdd? : boolean,

  hideRemove? : boolean, 

  hideColors?: boolean,

  onAddDocument? : ( databaseObserver : DatabaseObserverIF<DatabaseDocumentIF> ) => Promise<void>,

  onRemoveDocument? : ( databaseObserver : DatabaseObserverIF<DatabaseDocumentIF>, 
                        databaseDocument : DatabaseDocumentIF ) => Promise<void>,

  onOpenDocument? : ( databaseObserver : DatabaseObserverIF<DatabaseDocumentIF>, 
                      databaseDocument : DatabaseDocumentIF ) => Promise<void>
}


export interface DatabaseViewProps extends DatabaseProps {

  databaseQuery: DatabaseQuery<DatabaseDocumentIF>,

  title?: string,

  allowMap?: boolean,

  allowTree?: boolean,

  defaultDatabaseViewType?: DatabaseViewType,

  hideDateFilter?: boolean
}

interface ReactDatabaseViewProps extends DatabaseViewProps, WithStyles<typeof styles>, WithTranslation, RouteComponentProps<MatchParams> {
}

export interface DatabaseState { // Component State

  highlightedPropertyKey?: string

}


interface DatabaseViewState extends DatabaseState { // Document View Props

  databaseQuery : DatabaseQuery<DatabaseDocumentIF>,

  fullSizeComponent?: string,

  filterOpen?: boolean,

  showAccumulatedChart?: boolean,

  showBarChart?: boolean,

  showCard?: boolean,

  showMap?: boolean,

  showGraph?: boolean,

  showTree?: boolean,

  width?: number,

  height?: number,

  allowCreate: boolean,

  databaseViewArea?: DatabaseViewType

}

class DatabaseView extends React.PureComponent<ReactDatabaseViewProps, DatabaseViewState> {

  constructor(props: ReactDatabaseViewProps) {

    super(props);

    log.traceInOut( "constructor()" );

    this.containerRef = React.createRef();

    const allowCreate = !this.props.hideAdd && this.props.databaseQuery.databases![0].userAccess().allowCreate; 

    this.state = {

      allowCreate: allowCreate,

      databaseViewTypeMenuAnchor: null,

      databaseQuery: this.props.databaseQuery,

      waitDialogOpen: false

    } as DatabaseViewState;

    this._databaseObserver = 
      Factory.get().databaseService.databaseFactory.newDatabaseObserver( this.props.databaseQuery );

    this.onAddDocument = this.onAddDocument.bind(this);

    this.filterOpenPersistentKey = this.filterOpenPersistentKey.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.filterOpen = this.filterOpen.bind(this);

    this.propertiesSelector = this.propertiesSelector.bind(this);
    this.onDatabaseFiltersChange = this.onDatabaseFiltersChange.bind(this);

    this.filterDateRangePersistentKey = this.filterDateRangePersistentKey.bind(this);
    this.dateRange = this.dateRange.bind(this);
    this.includeHistoric = this.includeHistoric.bind(this);
    this.includeHistoricPersistentKey = this.includeHistoricPersistentKey.bind(this);
    this.databaseFilters = this.databaseFilters.bind(this);
    this.onDateFilterChange = this.onDateFilterChange.bind(this);

    this.showAccumulatedChartPersistentKey = this.showAccumulatedChartPersistentKey.bind(this);
    this.showAccumulatedChart = this.showAccumulatedChart.bind(this);
    this.handleShowAccumulatedChart = this.handleShowAccumulatedChart.bind(this);

    this.showMapPersistentKey = this.showMapPersistentKey.bind(this);
    this.showMap = this.showMap.bind(this);
    this.handleShowMap = this.handleShowMap.bind(this);

    this.showGraphPersistentKey = this.showGraphPersistentKey.bind(this);
    this.showGraph = this.showGraph.bind(this);
    this.handleShowGraph = this.handleShowGraph.bind(this);

    this.databaseViewAreaPersistentKey = this.databaseViewAreaPersistentKey.bind(this);
    this.databaseViewArea = this.databaseViewArea.bind(this);
    this.handleDatabaseViewType = this.handleDatabaseViewType.bind(this);

    this.onDiagramViewChange = this.onDiagramViewChange.bind(this);

    this.highlightedPropertyPersistentKey = this.highlightedPropertyPersistentKey.bind(this);
    this.handleHighlightedProperty = this.handleHighlightedProperty.bind(this);
    this.onUpdateHighlightedPropertyKey = this.onUpdateHighlightedPropertyKey.bind(this);
    this.highlightedPropertyKey = this.highlightedPropertyKey.bind(this);

    this.diagramViewType = this.diagramViewType.bind(this);

    //log.traceInOut("constructor()");
  }

  async componentDidMount() {

    try {
      log.traceIn("componentDidMount()" );

      this._databaseObserver.setDatabases( this.props.databaseQuery.databases );

      this._databaseObserver.setIgnoreDocumentNames( this.props.databaseQuery.ignoreDocumentNames );

      this._databaseObserver.setDatabaseFilters( this.databaseFilters() );

      this._databaseObserver.setDateRange( this.dateRange() );

      this._databaseObserver.setIncludeHistoric( this.includeHistoric() );

      const databaseQuery = this._databaseObserver.databaseQuery();

      this.setState( {
        databaseQuery: databaseQuery
      });

      await this._databaseObserver.update();
      
      log.traceOut("componentDidMount()");

    } catch( error ) {
          
      log.warn( "componentDidMount()", error ); 

      await errorDialog( error);

      log.traceOut( "componentDidMount()", error );
    }
  }
  
  async componentDidUpdate() {

    try {
      //log.traceIn("componentDidUpdate()" );
      
      let changed = false;

      changed = this._databaseObserver.setDatabases( this.props.databaseQuery.databases ) || changed;

      changed = this._databaseObserver.setIgnoreDocumentNames( this.props.databaseQuery.ignoreDocumentNames ) || changed;

      changed = this._databaseObserver.setDatabaseFilters( this.databaseFilters() ) || changed;

      changed = this._databaseObserver.setDateRange( this.dateRange() ) || changed;

      changed = this._databaseObserver.setIncludeHistoric( this.includeHistoric() ) || changed;

      if( changed ) {
        const databaseQuery = this._databaseObserver.databaseQuery();

        this.setState( {
          databaseQuery: databaseQuery
        });
    
        await this._databaseObserver.update()    
      }
      

      //log.traceOut("componentDidUpdate()" ); 
    } catch( error ) {
          
      log.warn( "componentDidUpdate()", error );
      await errorDialog( error);
    }
  }

  async componentWillUnmount() {
    log.traceIn("componentWillUnmount()");

    try {

      await this._databaseObserver.unsubscribe( this );

      log.traceOut("componentWillUnmount()");

    } catch (error) {
      log.warn("componentWillUnmount()", "Error unmounting collection list", error);

    }
  }


  private onAddDocument = async () => {

    log.traceIn("onAddDocument()");
    try {

      const appContext = this.context as AppContextProps;

      let path = appContext.currentHomePath! as string;

      let newDocument = this._databaseObserver.databaseQuery().databases![0].newDocument();

      path += newDocument.databasePath(true);

      this.props.history.push(path);

      log.traceOut("onAddDocument()", path);
      
    } catch( error ) {
      log.warn( "onAddDocument()", error );

      await errorDialog( error);
    }
  }


  private filterOpenPersistentKey = (): string => {
    const appContext = this.context as AppContextProps;

    return appContext.currentHomePath + "." + this._databaseObserver.defaultDocumentName()! + "." + PersistentKeyFilterOpen;
  }


  private handleFilter = async () => {

    log.traceInOut("handleFilter()");

    try {

      const filterOpen = this.filterOpen();

      Factory.get().persistentState!.setProperty( this.filterOpenPersistentKey(), !filterOpen);

      this.setState({ filterOpen: !filterOpen });

    } catch( error ) {
      log.warn( "handleFilter()", error );

      await errorDialog( error);
    }

  };

  private filterOpen = (): boolean => {

    //log.traceIn( "filterOpen()" );

    if (this.state.filterOpen != null) {
      //log.traceOut( "filterOpen()", "From state", this.state.filterOpen );
      return this.state.filterOpen;
    }

    const persistentFilterOpen = Factory.get().persistentState!.property( this.filterOpenPersistentKey()) as boolean;

    if (persistentFilterOpen != null) {

      //log.traceOut( "filterOpen()", "From persistent app state", persistentFilterOpen );
      return persistentFilterOpen;
    }

    //log.traceOut( "filterOpen()", "From default", DefaultChartOpen );

    return DefaultFilterOpen;

  };


  private propertiesSelector = (): PropertiesSelector => {

    const appContext = this.context as AppContextProps;

    const propertyKeyIncludeFilter: string[] = [];

    if( this.props.featuredProperties != null ) {

      this.props.featuredProperties.forEach(column => {

        if (column === CompanyDocument) {
          if (appContext.currentHomePath === HomePaths.AdminHomePath) {
            propertyKeyIncludeFilter.push(column);
          }
        }
        else if (column === UnitDocument) {
          if (appContext.currentHomePath !== HomePaths.UnitHomePath) {
            propertyKeyIncludeFilter.push(column);
          }
        }
        else if (column === UserDocument) {
          if (appContext.currentHomePath !== HomePaths.UserHomePath) {
            propertyKeyIncludeFilter.push(column);
          }
        }
        else {
          propertyKeyIncludeFilter.push(column);
        }
      });
    }

    return { 
      includePropertyKeys: propertyKeyIncludeFilter
    
    } as PropertiesSelector;

  };

  private databaseFilters = () :  Map<string, DatabaseFilter> | undefined => {

    //log.traceIn("databaseFilters()");

    //log.traceOut("databaseFilters()"); 
    return this._databaseObserver.databaseQuery().databaseFilters;
  };



  private onDatabaseFiltersChange = async (databaseFilters: Map<string, DatabaseFilter>) => { 

    log.traceIn("onDatabaseFiltersChange()", JSON.stringify( Array.from( databaseFilters.values() ) ));

    try {

      let mergedDatabaseFilters = new Map<string, DatabaseFilter>([...databaseFilters]);

      if( this.props.databaseQuery.databaseFilters != null ) {

        mergedDatabaseFilters = new Map<string, DatabaseFilter>([...mergedDatabaseFilters, ...this.props.databaseQuery.databaseFilters]);
      }

      const changed = this._databaseObserver.setDatabaseFilters( mergedDatabaseFilters );

      const updated = this._databaseObserver.databaseQuery()?.databaseFilters?.values() 

      log.debug("onDatabaseFiltersChange()", "updated",
        JSON.stringify( updated != null ? Array.from( updated ) : [] ) );  

      if( changed ) {

        this.setState( { 
          databaseQuery: this._databaseObserver.databaseQuery() 
        });
  
        await this._databaseObserver.update(); 
      }

      const result = this._databaseObserver.databaseQuery()?.databaseFilters?.values() 

      log.traceOut("onDatabaseFiltersChange()", 
        JSON.stringify( result != null ? Array.from( result ) : [] ) );  

    } catch (error) {
      log.warn("onDatabaseFiltersChange()", "Error handling database filters change", error);

    }
  }

  private filterDateRangePersistentKey = (): string => {
    const appContext = this.context as AppContextProps;

    return appContext.currentHomePath + "." +  
      this._databaseObserver.defaultDocumentName()! + "." + 
      PersistentKeyFilterDateRange;
  }

  private dateRange = (): DateRange | undefined => {

    //log.traceIn("dateRange()");

    if (this.state.databaseQuery.dateRange != null) {
      //log.traceOut("dateRange()", "From state", this.state.databaseQuery.dateRange);
      return this.state.databaseQuery.dateRange;
    }

    const persistentDateRange = Factory.get().persistentState!.property( this.filterDateRangePersistentKey());

    //log.debug("dateRange()", "persistentDateRange", persistentDateRange);

    if (persistentDateRange != null) {

      const dateRange = {} as DateRange;

      if (persistentDateRange.from != null) {
        dateRange.from = Factory.get().databaseService.databaseManager.converter.toDate(persistentDateRange.from);
      }

      if (persistentDateRange.to != null) {
        dateRange.to = Factory.get().databaseService.databaseManager.converter.toDate(persistentDateRange.to);
      }

      //log.traceOut("dateRange()", "From persistent app state", dateRange);
      return dateRange;
    }

    if( this.props.databaseQuery.dateRange != null ) {
      //log.traceOut( "dateRange()", "From props", this.props.databaseQuery.dateRange );
      return this.props.databaseQuery.dateRange
    }


    //log.traceOut("dateRange()", "From default");
    return {} as DateRange;
  };



  private includeHistoricPersistentKey = (): string => {
    const appContext = this.context as AppContextProps;

    return appContext.currentHomePath + "." +  
      this._databaseObserver.defaultDocumentName()! + "." + 
      PersistentKeyIncludeHistoric;
  }


  private includeHistoric = () : boolean | undefined =>  {

    //log.traceIn( "includeHistoric()" );

    if( this.state.databaseQuery.includeHistoric != null ) {
      //log.traceOut( "includeHistoric()", "From state", !!this.state.databaseQuery.includeHistoric );
      return !!this.state.databaseQuery.includeHistoric;
    }
 
    const persistentIncludeHistoric = Factory.get().persistentState!.property( this.includeHistoricPersistentKey() ) as boolean;

    if( persistentIncludeHistoric != null ) {

      //log.traceOut( "includeHistoric()", "From persistent app state", persistentIncludeHistoric );
      return persistentIncludeHistoric;
    }

    if( this.props.databaseQuery.includeHistoric != null ) {
      //log.traceOut( "includeHistoric()", "From props", this.props.databaseQuery.includeHistoric );
      return this.props.databaseQuery.includeHistoric
    }

    //log.traceOut( "includeHistoric()", "default", DefaultIncludeHistoric );
    return DefaultIncludeHistoric;

  };


  private onDateFilterChange = async (dateRange?: DateRange, includeHistoric? : boolean )  => {

    log.traceIn("onDateFilterChange()", {dateRange}, {includeHistoric} ); 

    try {
      let changed = this._databaseObserver.setDateRange( dateRange );

      changed = this._databaseObserver.setIncludeHistoric( includeHistoric ) || changed;

      if( changed ) {

        this.setState( { 
          databaseQuery: this._databaseObserver.databaseQuery() 
        });
  
        await this._databaseObserver.update(); 
      }
      log.traceOut("onDateFilterChange()", this._databaseObserver.databaseQuery() ); 

    } catch (error) {
      log.warn("onDateFilterChange()", "Error handling data filters change", error);
    }
  }


  private showCardPersistentKey = (): string => {
    const appContext = this.context as AppContextProps;

    return appContext.currentHomePath + "." + this._databaseObserver.defaultDocumentName()! + "." + PersistentKeyShowCard;
  }

  private showCard = (): boolean => {

    //log.traceIn("showCard()");

    if (this.state.showCard != null) {
      //log.traceOut("showCard()", "From state", this.state.showCard);
      return this.state.showCard;
    }

    const persistentShowCard = Factory.get().persistentState!.property( this.showCardPersistentKey()) as boolean;

    if (persistentShowCard != null) {

      //log.traceOut("showCard()", "From persistent app state", persistentShowCard);
      return persistentShowCard;
    }

    //log.traceOut("showCard()", "From defaults");
    return this.props.defaultDatabaseViewType != null ? 
      this.props.defaultDatabaseViewType ===  DatabaseViewTypes.Card : DefaultShowCard;
  };

  private handleShowCard = async () => {
    log.traceInOut("handleShowCard()");

    try {

      Factory.get().persistentState!.setProperty( this.showCardPersistentKey(), true);
      Factory.get().persistentState!.setProperty( this.showMapPersistentKey(), false);
      Factory.get().persistentState!.setProperty( this.showGraphPersistentKey(), false);
      Factory.get().persistentState!.setProperty( this.showTreePersistentKey(), false);

      this.setState({ 
        showCard: true,
        showMap: false,
        showGraph: false,
        showTree: false
      }); 

    } catch (error) {

      log.warn("handleShowCard()", "Error handling show card", error);
    }
  };

  private showTreePersistentKey = (): string => {
    const appContext = this.context as AppContextProps;

    return appContext.currentHomePath + "." + this._databaseObserver.defaultDocumentName()! + "." + PersistentKeyShowTree;
  }

  private showTree = (): boolean => {

    //log.traceIn("showTree()");

    if (this.state.showTree != null) {
      //log.traceOut("showTree()", "From state", this.state.showTree);
      return this.state.showTree;
    }

    const persistentShowTree = Factory.get().persistentState!.property( this.showTreePersistentKey()) as boolean;

    if (persistentShowTree != null) {

      //log.traceOut("showMap()", "From persistent app state", persistentShowTree);
      return persistentShowTree;
    }

    //log.traceOut("showTree()", "From default" );
    return this.props.defaultDatabaseViewType != null ? 
      this.props.defaultDatabaseViewType ===  DatabaseViewTypes.Tree : DefaultShowTree;
  };

  private handleShowTree = async () => {
    log.traceInOut("handleShowTree()");

    try {

      Factory.get().persistentState!.setProperty( this.showTreePersistentKey(), true);
      Factory.get().persistentState!.setProperty( this.showCardPersistentKey(), false);
      Factory.get().persistentState!.setProperty( this.showMapPersistentKey(), false);
      Factory.get().persistentState!.setProperty( this.showGraphPersistentKey(), false);

      this.setState({ 
        showTree: true,
        showCard: false,
        showMap: false,
        showGraph: false 
      }); 

    } catch (error) {

      log.warn("handleShowTree()", "Error handling show tree", error);
    }
  };


  private showMapPersistentKey = (): string => {
    const appContext = this.context as AppContextProps;

    return appContext.currentHomePath + "." + this._databaseObserver.defaultDocumentName()! + "." + PersistentKeyShowMap;
  }

  private showMap = (): boolean => {

    //log.traceIn("showMap()");

    if (this.state.showMap != null) {
      //log.traceOut("showMap()", "From state", this.state.showMap);
      return this.state.showMap;
    }

    const persistentShowMap = Factory.get().persistentState!.property( this.showMapPersistentKey()) as boolean;

    if (persistentShowMap != null) {

      //log.traceOut("showMap()", "From persistent app state", persistentShowMap);
      return persistentShowMap;
    }

    //log.traceOut("showMap()", "From default");
    return this.props.defaultDatabaseViewType != null ? 
      this.props.defaultDatabaseViewType === DatabaseViewTypes.Map : DefaultShowMap;
  };

  private handleShowMap = async () => {

    log.traceInOut("handleShowMap()");

    try {
      Factory.get().persistentState!.setProperty( this.showCardPersistentKey(), false);
      Factory.get().persistentState!.setProperty( this.showMapPersistentKey(), true);
      Factory.get().persistentState!.setProperty( this.showGraphPersistentKey(), false);
      Factory.get().persistentState!.setProperty( this.showTreePersistentKey(), false);

      this.setState({ 
        showCard: false,
        showMap: true,
        showGraph: false,
        showTree: false 
      }); 

    } catch (error) {

      log.warn("handleShowMap()", "Error handling show map", error);
    }
  };


  private showGraphPersistentKey = (): string => {
    const appContext = this.context as AppContextProps;

    return appContext.currentHomePath + "." + this._databaseObserver.defaultDocumentName()! + "." + PersistentKeyShowGraph;
  }

  private showGraph = (): boolean => {

    //log.traceIn("showGraph()");

    if (this.state.showGraph != null) {
      //log.traceOut("showGraph()", "From state", this.state.showGraph);
      return this.state.showGraph;
    }

    const persistentShowGraph = Factory.get().persistentState!.property( this.showGraphPersistentKey()) as boolean;

    if (persistentShowGraph != null) {

      //log.traceOut("showGraph()", "From persistent app state", persistentShowGraph);
      return persistentShowGraph;
    }

    //log.traceOut("showGraph()", "From default", DefaultShowGraph);

    return this.props.defaultDatabaseViewType != null ? 
      this.props.defaultDatabaseViewType === DatabaseViewTypes.Graph : DefaultShowGraph;
  };

  private handleShowGraph = async () => {

    log.traceInOut("handleShowGraph()");

    try {

      Factory.get().persistentState!.setProperty( this.showCardPersistentKey(), false);
      Factory.get().persistentState!.setProperty( this.showMapPersistentKey(), false);
      Factory.get().persistentState!.setProperty( this.showGraphPersistentKey(), true);
      Factory.get().persistentState!.setProperty( this.showTreePersistentKey(), false);

      this.setState({ 
        showCard: false,
        showMap: false,
        showGraph: true,
        showTree: false
      }); 
      
    } catch (error) {

      log.warn("handleShowGraph()", "Error handling show graph", error);
    }
  };

  private showAccumulatedChartPersistentKey = (): string => {
    const appContext = this.context as AppContextProps;

    return appContext.currentHomePath + "." + this._databaseObserver.defaultDocumentName()! + "." + PersistentKeyShowAccumulatedChart;
  }

  private showAccumulatedChart = (): boolean => {

    //log.traceIn("showAccumulatedChart()");

    if (this.state.showAccumulatedChart != null) {
      //log.traceOut("showAccumulatedChart()", "From state", this.state.showAccumulatedChart);
      return this.state.showAccumulatedChart;
    }

    const persistentShowAccumulatedChart = Factory.get().persistentState!.property( this.showAccumulatedChartPersistentKey()) as boolean;

    if (persistentShowAccumulatedChart != null) {

      //log.traceOut("showAccumulatedChart()", "From persistent app state", persistentShowAccumulatedChart);
      return persistentShowAccumulatedChart;
    }

    //log.traceOut("showAccumulatedChart()", "From default", DefaultShowAccumulatedChart);
    return DefaultShowAccumulatedChart;
  };

  private handleShowAccumulatedChart = async () => {

    log.traceInOut("handleShowAccumulatedChart()");

    try {

      Factory.get().persistentState!.setProperty( this.showAccumulatedChartPersistentKey(), true);
      Factory.get().persistentState!.setProperty( this.showBarChartPersistentKey(), false);
      Factory.get().persistentState!.setProperty( this.showCardPersistentKey(), false );
      Factory.get().persistentState!.setProperty( this.showMapPersistentKey(), false );
      Factory.get().persistentState!.setProperty( this.showGraphPersistentKey(), false );
      Factory.get().persistentState!.setProperty( this.showTreePersistentKey(), false );

      this.setState({ 
        showAccumulatedChart: true,
        showBarChart: false,
        showCard: false,
        showMap: false,
        showGraph: false,
        showTree: false
      });

      } catch (error) {

        log.warn("handleShowAccumulatedChart()", "Error handling accumulated chart", error);
      }
  };

  private showBarChartPersistentKey = (): string => {
    const appContext = this.context as AppContextProps;

    return appContext.currentHomePath + "." + this._databaseObserver.defaultDocumentName()! + "." + PersistentKeyShowBarChart;
  }

  private showBarChart = (): boolean => {

    //log.traceIn("showBarChart()");

    if (this.state.showBarChart != null) {
      //log.traceOut("showBarChart()", "From state", this.showBarChart.showBarChart);
      return this.state.showBarChart;
    }

    const persistentShowBarChart = Factory.get().persistentState!.property( this.showBarChartPersistentKey()) as boolean;

    if (persistentShowBarChart != null) {

      //log.traceOut("showBarChart()", "From persistent app state", persistentShowBarChart);
      return persistentShowBarChart;
    }

    //log.traceOut("showBarChart()", "From default", DefaultShowBarChart);
    return DefaultShowBarChart;
  };

  private handleShowBarChart = async () => {

    log.traceInOut("handleShowBarChart()");

    try {

      Factory.get().persistentState!.setProperty( this.showBarChartPersistentKey(), true);
      Factory.get().persistentState!.setProperty( this.showAccumulatedChartPersistentKey(), false);
      Factory.get().persistentState!.setProperty( this.showCardPersistentKey(), false );
      Factory.get().persistentState!.setProperty( this.showMapPersistentKey(), false );
      Factory.get().persistentState!.setProperty( this.showGraphPersistentKey(), false );
      Factory.get().persistentState!.setProperty( this.showTreePersistentKey(), false );

      this.setState({ 
        showBarChart: true,
        showAccumulatedChart: false,
        showCard: false,
        showMap: false,
        showGraph: false,
        showTree: false
      });

      } catch (error) {

        log.warn("handleShowBarChart()", "Error handling bar chart", error);
      }
  };

  private databaseViewAreaPersistentKey = (): string => {
    const appContext = this.context as AppContextProps;

    return appContext.currentHomePath + "." + this._databaseObserver.defaultDocumentName()! + "." + PersistentKeyDatabaseViewType;
  }

  private databaseViewArea = (): DatabaseViewType | undefined => {

    log.traceIn("databaseViewArea()");

    if (this.state.databaseViewArea != null) {
      log.traceOut("databaseViewArea()", "From state", this.state.databaseViewArea);
      return this.state.databaseViewArea;
    }

    const persistentDatabaseViewType = Factory.get().persistentState!.property( this.databaseViewAreaPersistentKey()) as DatabaseViewType;

    if (persistentDatabaseViewType != null) {

      log.traceOut("databaseViewArea()", "From persistent app state", persistentDatabaseViewType);
      return persistentDatabaseViewType;
    }

    log.traceOut("databaseViewArea()", this.props.defaultDatabaseViewType );
    return this.props.defaultDatabaseViewType;
  };

  private handleDatabaseViewType = async ( databaseViewArea : DatabaseViewType ) => {
    
    log.traceIn("handleDatabaseViewType()", {databaseViewArea});

    try {

      const currentDatabaseViewType = this.databaseViewArea();

      log.traceIn("handleDatabaseViewType()", {currentDatabaseViewType});

      if( databaseViewArea !== currentDatabaseViewType ) {

        Factory.get().persistentState!.setProperty( this.databaseViewAreaPersistentKey(), databaseViewArea );

        this.setState({ databaseViewArea: databaseViewArea }); 
      }

      log.traceOut("handleDatabaseViewType()");

    } catch (error) {

      log.warn("handleDatabaseViewType()", "Error handling database view area", error);
    }

  };

  private highlightedPropertyPersistentKey() {

    const appContext = this.context as AppContextProps;

    return appContext.currentHomePath + "." + this._databaseObserver.defaultDocumentName()! + "." + PersistentKeyHighlightedProperty ;
  }

  private async handleHighlightedProperty () {

    log.traceIn("handleHighlightedProperty()");  
    
    try {

      const referenceDocument = this._databaseObserver.referenceDocument()!;

      let existingHighlightedPropertyKey : string | null | undefined = this.highlightedPropertyKey();

      if( existingHighlightedPropertyKey === undefined ||
          existingHighlightedPropertyKey === emptyHighlightedPropertyKey ) {

        existingHighlightedPropertyKey = null;
      }

      const highlightedPropertyKey = await selectProperty( 
        referenceDocument,
        highlightedPropertiesSelector,
        this.props.t("highlightProperty"),
        existingHighlightedPropertyKey );
      
      if( highlightedPropertyKey === undefined ) {
        log.traceOut("handleHighlightedProperty ()", "cancel");    
        return;
      }

      log.debug("handleHighlightedProperty()", {highlightedPropertyKey});     

      this.onUpdateHighlightedPropertyKey( highlightedPropertyKey === null ? undefined : highlightedPropertyKey );   

      log.traceOut("handleHighlightedProperty ()");    

    } catch (error) {

      log.warn("handleHighlightedProperty()", "Error handling highlighted property", error);
    }
  }


  private async onUpdateHighlightedPropertyKey ( highlightedPropertyKey? : string ) {

    log.traceIn("onUpdateHighlightedPropertyKey ()", this.state.highlightedPropertyKey, {highlightedPropertyKey});    

    Factory.get().persistentState!.setProperty( this.highlightedPropertyPersistentKey(), 
      highlightedPropertyKey != null ? highlightedPropertyKey : emptyHighlightedPropertyKey );

    this.setState({  
      highlightedPropertyKey: highlightedPropertyKey != null ? highlightedPropertyKey : emptyHighlightedPropertyKey
      });

    log.traceOut("onUpdateHighlightedPropertyKey ()", {highlightedPropertyKey});    

  }

  private highlightedPropertyKey(): string | undefined {

    //log.traceIn("highlightedPropertyKey ()",);

    if (this.state.highlightedPropertyKey != null) {
      //log.traceOut("highlightedProperty ()", "From state", this.state.highlightedPropertyKey );
      return this.state.highlightedPropertyKey;
    }

    const persistentHighlightedProperty = Factory.get().persistentState!.property(
      this.highlightedPropertyPersistentKey());

    if (persistentHighlightedProperty != null ) {

      //log.traceOut("highlightedProperty ()", "From persistent app state", persistentHighlightedProperty);
      return persistentHighlightedProperty;
    }

    //log.traceOut("highlightedPropertyKey ()", "From default", this.props.highlightedPropertyKey );
    return this.props.highlightedPropertyKey ;
  }


  diagramViewType = () : DatabaseViewType => {

    const appContext = this.context as AppContextProps;

    const showCard = this.showCard();

    const showMap = this.showMap();

    const showGraph = this.showGraph();

    const showTree = this.showTree();

    const showBarChart = this.showBarChart();


    if( appContext.currentDisplay?.displayType === DisplayTypes.Mobile && showCard && !showMap && !showGraph && !showTree ) {
      return DatabaseViewTypes.Card as DatabaseViewType
    }
    else if( !!this.props.allowMap && !showCard && showMap && !showGraph && !showTree ) {
      return DatabaseViewTypes.Map as DatabaseViewType
    }
    else if( !!this.props.allowTree && !showCard && !showMap && !showGraph && showTree ) {
      return DatabaseViewTypes.Tree as DatabaseViewType
    }
    else if( !showCard && !showMap && showGraph && !showTree) {
      return DatabaseViewTypes.Graph as DatabaseViewType
    }
    else if( !showCard && !showMap && showGraph && !showTree) {
      return DatabaseViewTypes.Graph as DatabaseViewType
    }
    else if( showBarChart ) {
      return DatabaseViewTypes.BarChart as DatabaseViewType
    }
    else {
      return DatabaseViewTypes.AccumulatedChart as DatabaseViewType
    }
  }

  onDiagramViewChange = async ( databaseViewType : DatabaseViewType ) : Promise<void> => {

    switch( databaseViewType )
    {
      case DatabaseViewTypes.Map:
      {
        this.handleShowMap();
        break;
      }

      case DatabaseViewTypes.Tree:
      {
        this.handleShowTree();
        break;
      }

      case DatabaseViewTypes.Graph:
      {
        this.handleShowGraph();
        break;
      }

      case DatabaseViewTypes.AccumulatedChart:
      {
        this.handleShowAccumulatedChart();
        break;
      }

      case DatabaseViewTypes.BarChart:
      {
        this.handleShowBarChart();
        break;
      }

      case DatabaseViewTypes.Card:
      {
        this.handleShowCard();
        break;
      }

      default:
      {
        throw new Error( "Unexpected selection: " + databaseViewType );
      }
    }
  }

  render() {

    //log.traceInOut("render()" );

    const { classes } = this.props;

    const appContext = this.context as AppContextProps;

    const expandButton = (databaseViewArea: DatabaseViewType) => (
      <Paper className={classes.expandButton} elevation={0}>
        <Checkbox
          icon={<FullscreenExitIcon />}
          checkedIcon={<FullscreenIcon color="secondary"/>}
          checked={databaseViewArea != null && databaseViewArea !== DatabaseViewTypes.Dashboard}
          onClick={(event) => {event.stopPropagation(); this.handleDatabaseViewType(databaseViewArea)}} 
        />
      </Paper> 
    )

    const databaseArea = (databaseViewType: DatabaseViewType, expanded : boolean ) => {

      const highlightedPropertyKey = this.highlightedPropertyKey();

      const onAddDocument = async (databaseObserver: DatabaseObserverIF<DatabaseDocumentIF>): Promise<void> =>
        this.props.onAddDocument != null ?
          await this.props.onAddDocument(databaseObserver) :
          await addDocument({
            appContext: appContext,
            history: this.props.history,
            t: this.props.t,
            databaseObserver: databaseObserver
          });

      const onRemoveDocument = async (databaseObserver: DatabaseObserverIF<DatabaseDocumentIF>, databaseDocument: DatabaseDocumentIF) =>
        this.props.onRemoveDocument != null ?
          await this.props.onRemoveDocument(databaseObserver, databaseDocument) :
          removeDocument({
            appContext: appContext,
            history: this.props.history,
            t: this.props.t,
            databaseDocument: databaseDocument,
            databaseObserver: databaseObserver
          });
      
      const onOpenDocument = async (databaseObserver: DatabaseObserverIF<DatabaseDocumentIF>, databaseDocument: DatabaseDocumentIF) =>
        this.props.onOpenDocument != null ?
          await this.props.onOpenDocument(databaseObserver, databaseDocument) :
          openDocument({
            appContext: appContext,
            history: this.props.history,
            t: this.props.t,
            databaseDocument: databaseDocument,
            databaseObserver: databaseObserver
          });

      return(
        <>
          {databaseViewType === DatabaseViewTypes.Tree &&
            databaseTree(appContext, {
              databaseObserver: this._databaseObserver,
              highlightedPropertyKey: highlightedPropertyKey,
              onAddDocument: onAddDocument,
              onRemoveDocument: onRemoveDocument,
              onOpenDocument: onOpenDocument
            })
          }
          {databaseViewType === DatabaseViewTypes.Map &&
            databaseMap(appContext, {
              databaseObserver: this._databaseObserver,
              fullScreenControl: expandButton(expanded ? 
                  DatabaseViewTypes.Dashboard as DatabaseViewType: 
                  DatabaseViewTypes.Map as DatabaseViewType),
              highlightedPropertyKey: highlightedPropertyKey,
              enableStreetView: expanded,
              enableSateliteView: expanded,
              onAddDocument: onAddDocument,
              onRemoveDocument: onRemoveDocument,
              onOpenDocument: onOpenDocument
            })
          }
          {databaseViewType === DatabaseViewTypes.Graph &&
            databaseGraph(appContext, {
              databaseObserver: this._databaseObserver,
              highlightedPropertyKey: highlightedPropertyKey,
              onAddDocument: onAddDocument,
              onRemoveDocument: onRemoveDocument,
              onOpenDocument: onOpenDocument
            })
          }
          {databaseViewType === DatabaseViewTypes.BarChart &&
            databaseChart(appContext, {
              databaseObserver: this._databaseObserver,
              hideAdd: true,
              highlightedPropertyKey: highlightedPropertyKey,
              accumulate: false,
              hideLegend: !expanded,
              onAddDocument: onAddDocument,
              onRemoveDocument: onRemoveDocument,
              onOpenDocument: onOpenDocument
            })
          }
          {databaseViewType === DatabaseViewTypes.AccumulatedChart &&
            databaseChart(appContext, {
              databaseObserver: this._databaseObserver,
              hideAdd: true,
              highlightedPropertyKey: highlightedPropertyKey,
              accumulate: true,
              hideLegend: !expanded,
              onAddDocument: onAddDocument,
              onRemoveDocument: onRemoveDocument,
              onOpenDocument: onOpenDocument
            })
          }
          {databaseViewType === DatabaseViewTypes.Card &&
            databaseCard(appContext, {
              databaseObserver: this._databaseObserver,
              hideAdd: true,
              hideSelectProperty: true,
              hideLegend: true,
              hideLabels: !expanded,
              highlightedPropertyKey: highlightedPropertyKey,
              onUpdateHighlightedPropertyKey: this.onUpdateHighlightedPropertyKey,
              onAddDocument: onAddDocument,
              onRemoveDocument: onRemoveDocument,
              onOpenDocument: onOpenDocument
            })
          }
          {databaseViewType === DatabaseViewTypes.Table &&
            databaseTable(appContext, {
              databaseObserver: this._databaseObserver,
              hideAdd: true,
              featuredProperties: this.props.featuredProperties,
              highlightedPropertyKey: highlightedPropertyKey,
              hideColors: false,
              expandable: true,
              onAddDocument: onAddDocument,
              onRemoveDocument: onRemoveDocument,
              onOpenDocument: onOpenDocument
            })
          }
          {databaseViewType === DatabaseViewTypes.List &&
            databaseList(appContext, {
              databaseObserver: this._databaseObserver,
              hideAdd: true,
              hideRemove: true,
              onAddDocument: onAddDocument,
              onRemoveDocument: onRemoveDocument,
              onOpenDocument: onOpenDocument
            })
          }
        </>
      );
    }

    const renderNormal = () => {

      const databaseViewArea = this.databaseViewArea();

      const expanded = databaseViewArea != null && databaseViewArea !== DatabaseViewTypes.Dashboard;

      let diagramArea : DatabaseViewType;

      if( !!this.props.allowMap && this.showMap() ) {
        diagramArea = DatabaseViewTypes.Map as DatabaseViewType;
      }
      else if( !!this.props.allowTree && this.showTree() ) {
        diagramArea = DatabaseViewTypes.Tree as DatabaseViewType;
      }
      else if( this.showGraph() ) {
        diagramArea = DatabaseViewTypes.Graph as DatabaseViewType;
      }
      else if( this.showBarChart() ) {
        diagramArea = DatabaseViewTypes.BarChart as DatabaseViewType;
      }
      else {
        diagramArea = DatabaseViewTypes.AccumulatedChart as DatabaseViewType;
      }

      return (
        <>
          <Grid item container 
            alignItems="stretch" 
            spacing={1} 
            >
            <Grid item xs={12} sm={8} md={8}>
              <Grid container justifyContent="flex-end" alignItems="flex-end">
                <Paper className={classes.diagram} elevation={0}>
                  {databaseArea(diagramArea, expanded)}
                </Paper>
                {diagramArea !== DatabaseViewTypes.Map && expandButton(diagramArea)}
              </Grid>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Grid container justifyContent="flex-end" alignItems="flex-end">
                <Paper className={classes.card} elevation={0}>
                  {databaseArea(DatabaseViewTypes.Card as DatabaseViewType, expanded)}
                </Paper>
                {expandButton(DatabaseViewTypes.Card as DatabaseViewType)}
              </Grid>
            </Grid>
          </Grid>
          <Box className={classes.table} >
            {databaseArea(DatabaseViewTypes.Table as DatabaseViewType, expanded)}
          </Box>
          <Grid container 
            justifyContent="flex-end" 
            alignItems="flex-end" 
            className={classes.expandBottom} 
            >
            {expandButton(DatabaseViewTypes.Table as DatabaseViewType)}
          </Grid>
        </> 
      );
    }

    const renderNormalMobile = () => {

      const databaseViewArea = this.databaseViewArea();

      const expanded = databaseViewArea != null && databaseViewArea !== DatabaseViewTypes.Dashboard;

      let diagramArea : DatabaseViewType;

      if( !!this.props.allowMap && this.showMap() ) {
        diagramArea = DatabaseViewTypes.Map as DatabaseViewType;
      }
      else if( !!this.props.allowTree && this.showTree() ) {
        diagramArea = DatabaseViewTypes.Tree as DatabaseViewType;
      }
      else if( this.showCard() ) {
        diagramArea = DatabaseViewTypes.Card as DatabaseViewType;
      }
      else if( this.showGraph() ) {
        diagramArea = DatabaseViewTypes.Graph as DatabaseViewType;
      }
      else if( this.showBarChart() ) {
        diagramArea = DatabaseViewTypes.BarChart as DatabaseViewType;
      }
      else {
        diagramArea = DatabaseViewTypes.AccumulatedChart as DatabaseViewType;
      }

      return (
        <>
          <Grid container justifyContent="flex-end" alignItems="flex-end" >
            <Paper className={classes.diagram} >
              {databaseArea(diagramArea, expanded)} 
            </Paper>
            {diagramArea !== DatabaseViewTypes.Map && expandButton(diagramArea)}
          </Grid>
          <Box className={classes.table} >
            {databaseArea(DatabaseViewTypes.List as DatabaseViewType, expanded)}
          </Box>
          <Grid container justifyContent="flex-end" alignItems="flex-end" className={classes.expandBottom} >
            {expandButton(DatabaseViewTypes.List as DatabaseViewType)} 
          </Grid>
        </>
      )
    }


    const renderExpanded = () => {

      const databaseViewArea = this.databaseViewArea();

      let paddingTop;
      let paddingBottom;

      switch( databaseViewArea ) {

        case DatabaseViewTypes.AccumulatedChart: 
        case DatabaseViewTypes.BarChart:
          paddingBottom = "30vh"; 
          break;
      
        case DatabaseViewTypes.Card:
          paddingTop = theme.spacing(4);
          paddingBottom = "40vh";
          break;

        case DatabaseViewTypes.Table:
          paddingBottom = theme.spacing(0.75);
          break;

        default:
          paddingBottom = 0;
          paddingTop = 0;
          break;
          
      }

      return (
        <>
          <Box className={classes.expanded}
            style={databaseViewArea === DatabaseViewTypes.List ?
              { flexGrow: 1, overflow: "auto" } :
              { paddingLeft: theme.spacing(0.5), 
                paddingRight: theme.spacing(0.5), 
                paddingTop: paddingTop,
                paddingBottom: paddingBottom
              }
            }>
            {databaseArea(databaseViewArea!, true)}
          </Box>
          {databaseViewArea !== DatabaseViewTypes.Map &&
            <Grid container justifyContent="flex-end" alignItems="flex-end" className={classes.expandBottom}>
              {expandButton(DatabaseViewTypes.Dashboard as DatabaseViewType)}
            </Grid>
          } 
        </>
      )
    }

    const databaseViewArea = this.databaseViewArea();

    const expanded = databaseViewArea != null && databaseViewArea !== DatabaseViewTypes.Dashboard;

    const filterOpen = this.filterOpen();

    const highlightedPropertyKey = this.highlightedPropertyKey();

    const diagramViewType = this.diagramViewType();

    let databaseViewItems : DatabaseViewType[] = [];

    if( !!this.props.allowMap ) {
      databaseViewItems.push( DatabaseViewTypes.Map as DatabaseViewType );
    }

    if( !!this.props.allowTree ) {
      databaseViewItems.push( DatabaseViewTypes.Tree as DatabaseViewType );
    } 

    databaseViewItems = databaseViewItems.concat([ 
      DatabaseViewTypes.Graph as DatabaseViewType,
      DatabaseViewTypes.AccumulatedChart as DatabaseViewType,
      DatabaseViewTypes.BarChart as DatabaseViewType
    ] );

    if( appContext.currentDisplay?.displayType === DisplayTypes.Mobile) {
      databaseViewItems.push( DatabaseViewTypes.Card as DatabaseViewType );
    }

    const onAddDocument = () => this.props.onAddDocument != null ?
      this.props.onAddDocument(this._databaseObserver) :
      addDocument({
        appContext: appContext,
        history: this.props.history,
        t: this.props.t,
        databaseObserver: this._databaseObserver
      });

    return (
      <React.Fragment>
        <CssBaseline />
        <AppContext.Consumer>
          {appContext => (
            <Box className={classes.root} >
              <Grid container direction="column" style={{ width: "100%" }}>
                <Grid item container
                  justifyContent="space-between"
                  spacing={0}
                  alignItems="center"
                  style={{ flexWrap: "nowrap" }}
                >
                  <Grid item container alignItems="center"
                    style={{ flexWrap: "nowrap" }}
                  >
                    <Card className={classes.title} elevation={0} >
                      <Title level="h6">
                        {this.props.title != null ? this.props.t(this.props.title) :
                          <CollectionName
                            name={this._databaseObserver.defaultDocumentName()!}
                            my={appContext.currentHomePath === HomePaths.UserHomePath &&
                              appContext.authenticationClaims?.childUserIds == null}
                          />
                        }
                      </Title>
                    </Card>
                    <Tooltip title={(<>{this.props.t("filterOptions")}</>)}>
                      <ToggleButton
                        size="small"
                        value={filterOpen}
                        selected={filterOpen}
                        onClick={() => this.handleFilter()}>
                        <FilterListIcon />
                      </ToggleButton> 
                    </Tooltip>
                  </Grid>
                  <Grid item container
                    justifyContent="flex-end"
                    xs
                    sm
                    md
                  >
                    {!expanded &&
                      <DatabaseViewMenu
                        currentDatabaseViewType={diagramViewType as DatabaseViewType}
                        databaseViewItems={databaseViewItems}
                        useAnchor={appContext.currentDisplay?.displayType === DisplayTypes.Mobile}
                        onDatabaseViewChange={this.onDiagramViewChange}
                      />
                    }
                  </Grid>
                  <Grid item container
                    justifyContent="flex-end"
                    xs
                    sm
                    md
                  >
                    {!this.props.multipleDocuments &&
                      <Tooltip title={(<>{this.props.t("highlightProperty")}</>)}>
                        <ToggleButton
                          size="small"
                          style={{ marginLeft: theme.spacing(1) }}
                          value={highlightedPropertyKey != null && highlightedPropertyKey !== emptyHighlightedPropertyKey}
                          selected={highlightedPropertyKey != null && highlightedPropertyKey !== emptyHighlightedPropertyKey}
                          onClick={() => this.handleHighlightedProperty()}>
                          <HighlightIcon />
                        </ToggleButton>
                      </Tooltip>
                    }
                  </Grid>
                </Grid>
                <Grid item container
                  direction="column"
                  justifyContent="flex-end"
                  alignItems="stretch"
                  style={filterOpen ? { marginTop: theme.spacing(2) } : {}}
                >
                  {!this.props.hideDateFilter &&
                    <Grid item>
                      <Collapse in={filterOpen} timeout="auto" unmountOnExit className={classes.dateCollapse}>
                        <DateFilter
                          databases={this._databaseObserver.databaseQuery().databases!}
                          onDateFilterChange={this.onDateFilterChange}
                        />
                      </Collapse>
                    </Grid>
                  }
                  <Grid item>
                    <Collapse in={filterOpen} timeout="auto" unmountOnExit className={classes.propertyCollapse}>
                      <PropertyFilter
                        databases={this._databaseObserver.databaseQuery().databases!}
                        onDatabaseFiltersChange={this.onDatabaseFiltersChange}
                      />
                    </Collapse>
                  </Grid>
                </Grid>
              </Grid>
              {expanded ?
                renderExpanded() :
                (appContext.currentDisplay?.displayType !== DisplayTypes.Mobile ?
                  renderNormal() :
                  renderNormalMobile()
                )
              }
              {this.state.allowCreate &&
                <Fab color="secondary" aria-label="add"
                  className={classes.createButton}
                  style={databaseViewArea !== DatabaseViewTypes.Map ? {} :
                    { marginBottom: theme.spacing(2.5), marginLeft: theme.spacing(2) }
                  }
                  onClick={() => onAddDocument()}
                >
                  <AddIcon />
                </Fab>
              }
            </Box>
          )}
        </AppContext.Consumer>
      </React.Fragment >
    );
  }
  private containerRef: React.RefObject<HTMLDivElement>;

  private _databaseObserver : DatabaseObserverIF<DatabaseDocumentIF>;
} 

DatabaseView.contextType = AppContext;

const ModifiedDatabaseView = withRouter(withTranslation()(withStyles(styles)(DatabaseView)));

export default ModifiedDatabaseView;


