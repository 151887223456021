

import { User } from "./user";
import { Location } from "./location";
import { CompanyIF } from "../../api/documents/companyIF";
import { Category } from "./category";
import { CollectionDatabase } from "../core/collectionDatabase";
import { ReferencesProperty } from "../properties/referencesProperty";
import { CollectionProperty } from "../properties/collectionProperty";
import { SubdocumentProperty } from "../properties/subdocumentProperty";
import { Unit } from "./unit";
import { LocationsCollection, UnitsCollection, UsersCollection, CategoriesCollection, SubscriptionsCollection, ProjectsCollection, DevicesCollection, AlertsCollection, KeysCollection } from "../../api/collections";
import { log } from "../../framework/databaseService";
import { CompanyManager } from "../managers/companyManager";
import { TextProperty } from "../properties/textProperty";
import { Address } from "../subdocuments/address"; 
import { Subscription } from "./subscription";
import { NumberProperty } from "../properties/numberProperty";
import { Project } from "./project";
import { Contactable } from "./contactable";
import { BooleanProperty } from "../properties/booleanProperty";
import { Device } from "./device";
import { Alert } from "./alert";
import { CompanyDocument } from "../../api/documents";
import { Key } from "./key";

export abstract class Company extends Contactable implements CompanyIF {

    constructor( companyDocumentName : string, companiesCollection : CollectionDatabase<Company>, documentPath? : string ) {

        super( companyDocumentName, companiesCollection, documentPath ); 

        try {
            this.title.required = true; 

            this.disabled = new BooleanProperty( this );
            this.disabled.encrypt = false;

            this.startDate.editable = false;
            this.endDate.editable = false;

            this.domain = new TextProperty( this );

            this.taxId = new TextProperty( this );

            this.customerId = new TextProperty( this );
            this.customerId.editable = false;
            this.customerId.encrypt = false;

            this.licenses = new NumberProperty( this, undefined, 0 );
            this.licenses.editable = false;

            this.minLicenses = new NumberProperty( this, undefined, 0 );
            this.minLicenses.editable = false;

            this.maxLicenses = new NumberProperty( this, undefined, 0 );
            this.maxLicenses.editable = false;

            this.usedLicenses = new NumberProperty( this, undefined, 0 );
            this.usedLicenses.editable = false;

            this.disabled.editable = false;

            this.subscriptions = new CollectionProperty<Subscription>( this, SubscriptionsCollection, false );

            this.locations = new CollectionProperty<Location>( this, LocationsCollection, true );

            this.units = new CollectionProperty<Unit>( this, UnitsCollection, true );

            this.projects = new CollectionProperty<Project>( this, ProjectsCollection, true );

            this.devices = new CollectionProperty<Device>( this, DevicesCollection, true );

            this.alerts = new CollectionProperty<Alert>( this, AlertsCollection, true );

            this.users = new CollectionProperty<User>( this, UsersCollection, true );

            this.categories = new CollectionProperty<Category>( this, CategoriesCollection, true ); 
            
            this.admins = new ReferencesProperty<User>( this, () => [this.users.collectionGroup()] ); 

            this.visitorAddress = new SubdocumentProperty<Address>( this, new Address( this, "visitorAddress") );

            this.billingAddress = new SubdocumentProperty<Address>( this, new Address( this, "billingAddress") );

            this.shippingAddress = new SubdocumentProperty<Address>( this, new Address( this, "shippingAddress") );
                
            this.enableParents = new BooleanProperty( this );

            this.restrictSymbolicOwners = new BooleanProperty( this );

            this.keys = new CollectionProperty<Key>( this, KeysCollection );

            //log.traceInOut( "constructor()", CompaniesCollection );

        } catch( error ) {

            log.warn( "constructor()", "Error initializing company", error );
            
            throw new Error( (error as any).message );
        }
    }

    indexKey() : string {
        return CompanyDocument;
    }

    async availableLicenses() : Promise<number | undefined> {

        const licenses = await CompanyManager.getInstance().availableLicenses( this );
        return licenses;
    }


    async onCreated() : Promise<void> {
        try {
            log.traceIn( "("+this.collectionDatabase.collectionName()+")", "onCreated()" );

            await super.onCreated();

            await CompanyManager.getInstance().handleCompanyCreated( this );
    
            log.traceOut( "("+this.collectionDatabase.collectionName()+")", "onCreated()" );
  
        } catch( error ) {
            
            log.warn( "("+this.collectionDatabase.collectionName()+")", "onCreated()", "Error handling created notification", error );
  
            throw new Error( (error as any).message );
        }
    }

    async onUpdated() : Promise<void> {
        try {
            //log.traceIn( "onUpdated()" );

            await super.onUpdated();

            await CompanyManager.getInstance().handleCompanyUpdated( this );
    
            //log.traceOut( "onUpdated()" );
  
        } catch( error ) {
            
            log.warn( "onUpdated()", "Error handling updated notification", error );
  
            throw new Error( (error as any).message );
        }    
    }

    async onDeleted() : Promise<void> {

        try {
            //log.traceIn( "onDeleted()" );

            await super.onDeleted();

            await CompanyManager.getInstance().handleCompanyDeleted( this );
    
            //log.traceOut( "onDeleted()" );
  
        } catch( error ) {
            
            log.warn( "onDeleted()", "Error handling updated notification", error );
  
            throw new Error( (error as any).message );
        }  
    }

    readonly disabled : BooleanProperty;

    readonly customerId : TextProperty;

    readonly domain : TextProperty;

    readonly taxId : TextProperty;

    readonly licenses : NumberProperty;

    readonly minLicenses : NumberProperty;

    readonly maxLicenses : NumberProperty; 

    readonly usedLicenses : NumberProperty;
 
    readonly subscriptions : CollectionProperty<Subscription>;

    readonly locations : CollectionProperty<Location>;

    readonly units : CollectionProperty<Unit>;

    readonly projects : CollectionProperty<Project>;

    readonly devices : CollectionProperty<Device>;

    readonly alerts: CollectionProperty<Alert>;

    readonly users : CollectionProperty<User>;

    readonly categories : CollectionProperty<Category>;
    
    readonly admins : ReferencesProperty<User>;

    readonly visitorAddress : SubdocumentProperty<Address>;

    readonly billingAddress : SubdocumentProperty<Address>;

    readonly shippingAddress : SubdocumentProperty<Address>;

    readonly enableParents : BooleanProperty;

    readonly restrictSymbolicOwners : BooleanProperty;

    readonly keys : CollectionProperty<Key>;

}
