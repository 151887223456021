import * as React from 'react'

import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import { withTranslation, WithTranslation } from 'react-i18next';

import { CardMedia, FormControl, Grid, IconButton, Input, InputLabel, Paper } from '@material-ui/core';

import { saveAs } from 'file-saver';

import { StorageMedia } from '../../../services/storage/api/storageMedia';
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";
import CloseIcon from '@material-ui/icons/Close';
import DownloadIcon from '@mui/icons-material/Download';

import { ImagePropertyIF } from 'services/database/api/properties/imagePropertyIF';
import { PropertyDisplayMode, propertyInputVariant } from '../propertyValue';

import { log } from '../../app/app';import { errorDialog } from '../simpleDialog';
import { translatedPropertyLabel } from '../propertyLabel';
import theme from '../../app/theme';

const styles = (theme: Theme) => createStyles({
  formLabel: {
    marginTop: theme.spacing(-0.1)
  },
  labelPaper: {
    paddingLeft: theme.spacing(1),
    marginLeft: theme.spacing(-1),
    paddingRight: theme.spacing(1),
    color: theme.palette.secondary.main
  },
  input: {
    display: "none",
  },
  image: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  uploadButton: {
    color: theme.palette.primary.light
  },
});

export interface ImagePropertyValueProps extends WithStyles<typeof styles>, WithTranslation { 

  property : ImagePropertyIF, 

  onPropertyChange? : ( property : ImagePropertyIF ) => void,

  displayMode : PropertyDisplayMode,

  singleProperty: boolean,

  hideColor?: boolean,

  disabled?: boolean,

  required?: boolean

 }

interface ImagePropertyValueState { 

  open : boolean,

  options? : string[],

  downloadUrl? : string,

  selectedFile? : File,

  error? : Error
}


class ImagePropertyValue extends React.Component<ImagePropertyValueProps,ImagePropertyValueState> {

  constructor( props: ImagePropertyValueProps ) {
    
    super(props);

    this._inputRef = React.createRef();

    const enabled =
      this.props.displayMode === PropertyDisplayMode.Filter ||
      (this.props.property.userAccess().allowUpdate && this.props.property.editable);


    this.state = { 
      open : false,

      enabled: enabled
     } as ImagePropertyValueState;

    //log.traceInOut( "constructor()" );
  }

  async componentDidMount() {

    try {
      //log.traceIn( "componentDidMount()" );

      const value = this.props.property.value() as StorageMedia;

      let downloadUrl;

      if( value != null ) {

        if( value.downloadUrl == null ) {
          
          downloadUrl = await this.props.property.downloadUrl()
        }
        else {
          downloadUrl = value.downloadUrl;
        }

        this.setState( { downloadUrl: downloadUrl } );
      }

    
      //log.traceOut( "componentDidMount()" );

    } catch( error ) {
      log.warn( "componentDidMount()", "Error mounting documents view", error );

      await errorDialog( error );
      
      log.traceOut( "componentDidMount()", "error" );
    }
  }


  async componentDidUpdate() {

    try {
      //log.traceIn( "componentDidUpdate()" );

      if( this.props.property.error !== this.state.error ) {

        this.setState( { error : this.props.property.error });
      }

      const value = this.props.property.value() as StorageMedia;

      if( value == null ) {

        if( this.state.downloadUrl != null ) {
          this.setState( { downloadUrl: undefined } );
        }

        if( this.state.selectedFile != null ) {
          this.setState( { selectedFile: undefined } );
        }

        //log.traceOut( "componentDidUpdate()", "empty image" );
        return;
      }

      if( value != null ) {

        let downloadUrl;

        if( value.downloadUrl == null ) {
          
          downloadUrl = await this.props.property.downloadUrl()
        }
        else {
          downloadUrl = value.downloadUrl;
        }

        if( downloadUrl !== this.state.downloadUrl ) {
          this.setState( { downloadUrl: downloadUrl } );
        }
      }
    
      //log.traceOut( "componentDidUpdate()" );

    } catch( error ) {
      log.warn( "componentDidUpdate()", "Error updating documents view", error );
      
    }

  }

  render() {
    //log.traceInOut("render()");

    const {classes } = this.props;

    if( !this.props.property.userAccess().allowRead ) {
      return ( null );
    }

    if( this.props.displayMode === PropertyDisplayMode.Cell ) {
      return ( 
        <>{ 
          this.props.property.value() == null ? <>&nbsp;</> : this.props.property.value()}
        </>  
      );  
    }

    const handleCapture = ({ target }: any) => {

      const selectedFile =  target.files[0];

      const media = {

        data: selectedFile

      } as StorageMedia;

      this.props.property.setValue( media );

      this.setState( { 
        selectedFile: selectedFile,
        downloadUrl: undefined 
      } )

      if( this.props.onPropertyChange != null ) {
        this.props.onPropertyChange( this.props.property );
      }
    }

    const handleDownload = async () => {

      try {
        //log.traceIn( "handleDownload()" );

        if( this.state.downloadUrl == null ) {
          throw new Error( "Nothing to download");
        }

        saveAs( this.state.downloadUrl);
      
        //log.traceOut( "handleDownload()" );
  
      } catch( error ) {
        log.warn( "handleDownload()", "Error downloading", error );
  
        await errorDialog( error );
        
        log.traceOut( "handleDownload()", "error" );
      }
    }

    const handleRemove = () => {

      this._inputRef.current.value = null;

      this.props.property.setValue( undefined );

      this.setState( { 
        selectedFile: undefined,
        downloadUrl: undefined 
      } )

      if( this.props.onPropertyChange != null ) {
        this.props.onPropertyChange( this.props.property );
      }
    }

    const image = this.state.selectedFile != null ? 
      URL.createObjectURL(this.state.selectedFile) : this.state.downloadUrl;

    const label = this.props.singleProperty ? undefined :  
      translatedPropertyLabel( this.props.property.parent.documentName(), this.props.property.key() );

    return (
      <FormControl
        fullWidth
        variant={propertyInputVariant}
        style={(propertyInputVariant as string) !== "outlined" ? {} : {
          padding: theme.spacing(0.75),
          border: '1px solid #ccc',
          borderRadius: 5
        }}
        disabled={this.props.disabled}
        required={this.props.required}
        error={this.props.property.error != null}
      >
        <InputLabel
          className={classes.formLabel}
          shrink={true}
        >
          <Paper className={classes.labelPaper} elevation={0} >
            {label}
          </Paper>
        </InputLabel>
        {image != null &&
          <CardMedia
            className={classes.image}
            component="img"
            src={image}
          />
        }
        <Grid container alignItems="center" justifyContent="flex-end">
          <Input
            inputRef={this._inputRef}
            id="upload-button"
            inputProps={{
              accept: "image/*"
            }}
            onChange={handleCapture}
            className={classes.input}
            type="file"
          />
          <IconButton
            className={classes.uploadButton}
            color="primary"
            component="span"
            onClick={() => this._inputRef.current!.click()}
          >
            <PhotoCameraIcon />
          </IconButton>
          {this.state.downloadUrl != null &&
            <IconButton
              color="primary"
              onClick={handleDownload}
            >
              <DownloadIcon />
            </IconButton>
          }
          {image != null &&
            <IconButton
              color="primary"
              onClick={handleRemove}
            >
              <CloseIcon />
            </IconButton>
          }
        </Grid>
      </FormControl >
    );
  }

  private _inputRef : any;
}
const ModifiedImagePropertyValue = withTranslation()(withStyles(styles)(ImagePropertyValue));

export default ModifiedImagePropertyValue;











