export const HomePaths = {

    UserHomePath: "/userhome",
  
    UnitHomePath: "/unithome",
  
    CompanyHomePath: "/companyhome",
  
    AdminHomePath: "/adminhome"
  }
  
  export type HomePath = keyof (typeof HomePaths);
  