import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import DashboardIcon from '@material-ui/icons/Dashboard';

import StopIcon from '@mui/icons-material/Stop';


import List from '@material-ui/core/List';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';

import CollectionName from '../components/collectionName';
import CollectionIcon from '../components/collectionIcon';
import AppFrame, { DefaultDashboardsOpen, DefaultRegistrationsOpen, PersistentKeyDashboardsOpen, PersistentKeyRegistrationsOpen } from '../components/appFrame';


import { log } from 'ui/app/app';
import { HomePaths } from '../../services/common/api/homePaths';

import { AppContext, AppContextProps } from 'ui/app/appContext';
import { Collapse} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { Factory } from 'services/common/api/factory';
import { AlertsCollection, CategoriesCollection, ChangesCollection, CompaniesCollection, DevicesCollection, LocationsCollection, ProjectsCollection, UnitsCollection, UsersCollection } from '../../services/database/api/collections';
import { RegistrationsCollection, QuarantineRegistrationsCollection, SickLeaveRegistrationsCollection, SymptomRegistrationsCollection, TestRegistrationsCollection, IncidentRegistrationsCollection, VaccineRegistrationsCollection } from '../../healthguard/api/registrationCollections';
import { HazardsCollection, MeasuresCollection, GatheringsCollection, ConsentsCollection, RisksCollection } from '../../healthguard/api/healthguardCollections';
import { UnitViewOverviewParameter } from './unitViews';
import { errorDialog } from '../components/simpleDialog';
import { ChangeDocument} from '../../services/database/api/documents';
import { DocumentNameKey } from '../../services/database/api/core/databaseServiceIF';
import { DatabaseDocumentIF } from '../../services/database/api/core/databaseDocumentIF';
import { IncidentRegistrationDocument, QuarantineRegistrationDocument, RegistrationDocument, SickLeaveRegistrationDocument, SymptomRegistrationDocument, TestRegistrationDocument, VaccineRegistrationDocument } from '../../healthguard/api/registrationDocuments';
import { ConsentDocument, GatheringDocument, HealthguardCategoryDocument, HealthguardDeviceDocument, HealthguardLocationDocument, HealthguardProjectDocument, MeasureDocument, HealthguardUserDocument, HealthguardAlertDocument } from '../../healthguard/api/healthguardDocuments';
import { CompanyViewPublic } from './companyViews';
import { DatabaseIF } from '../../services/database/api/core/databaseIF';
import { DocumentViewKey } from '../components/documentRouter';


const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    height: '100%',
    width: '100%',
    position: 'relative',
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  currentDashboard: {
    color: theme.palette.info.dark,
  },
});


interface MatchParams {
}

interface UnitHomeProps extends WithStyles<typeof styles>, WithTranslation, RouteComponentProps<MatchParams> {
}

interface UnitHomeState { // Document View Props

  dashboardsOpen? : boolean,

  registrationsOpen? : boolean,

  selectedDatabase: DatabaseIF<DatabaseDocumentIF> | undefined | null

}

class UnitHome extends React.PureComponent<UnitHomeProps, UnitHomeState> {

  constructor(props: UnitHomeProps) {

    super(props);

    this.state = {

    } as UnitHomeState;

    this.showCompanyCloseOption = this.showCompanyCloseOption.bind( this );
    this.showUnitCloseOption = this.showUnitCloseOption.bind( this );


    //log.traceInOut("constructor()");
  }

  async componentDidMount() { 

    try {
      log.traceIn( "componentDidMount()");

      await this.selectDatabase();

      log.traceOut( "componentDidMount()", "state", );

    } catch( error ) {
      log.warn( "componentDidMount()", "Error mounting documents view", error );

      await errorDialog( error);
      
      log.traceOut( "componentDidMount()", error );
    }
  }

  async componentDidUpdate() {

    try {
      //log.traceIn( "componentDidUpdate()");

      await this.selectDatabase();

      //log.traceOut( "componentDidUpdate()", "state", );

    } catch( error ) {
      log.warn( "componentDidUpdate()", "Error mounting documents view", error );

      await errorDialog( error);
      
    }
  }


  async selectDatabase() {

    try {
      log.traceIn( "selectDatabase()");

      let url = this.props.location.pathname;

      if( url.endsWith( HomePaths.UnitHomePath )) {

        this.setState( { selectedDatabase: undefined } );

        log.traceOut( "selectDatabase()", "no selected database");
        return;
      }

      const query = new URLSearchParams(this.props.location.search);

      const documentName = query.get( DocumentNameKey );

      if( documentName != null ) {

        url += "?" + DocumentNameKey + "=" + documentName;
      }

      const selectedDatabase = Factory.get().databaseService.databaseFactory.databaseFromUrl( url );
        
      if( selectedDatabase?.defaultDocumentName() !== this.state.selectedDatabase?.defaultDocumentName() ) {
        this.setState( { selectedDatabase: selectedDatabase } );
      }

      log.traceOut( "selectDatabase()", "state");

    } catch( error ) {
      log.warn( "selectDatabase()", "Error mounting documents view", error );

      await errorDialog( error);
      
      log.traceOut( "selectDatabase()", error );
    }
  }

  async componentWillUnmount() {
    log.traceIn("componentWillUnmount()");

    try {
      log.traceOut("componentWillUnmount()");

      const appContext = this.context as AppContextProps;

      if( !!this.showUnitCloseOption() ) { 

        if( appContext.onUpdateActiveUnit != null ) {
          await appContext.onUpdateActiveUnit( undefined );
        }
      }  

    } catch (error) {
      log.warn("componentWillUnmount()", "Error unmounting collection list", error);

      log.traceOut("componentWillUnmount()", "error");
    }
  }

  private showCompanyCloseOption() : boolean {

    const appContext = this.context as AppContextProps;

    if( appContext.authenticationClaims == null  ) {
        return false;
    }

    return !!appContext.authenticationClaims.systemAdmin;
  }

  private showUnitCloseOption() : boolean {

    const appContext = this.context as AppContextProps;

    if( appContext.authenticationClaims == null  ) {
      return false;
    }

    if( appContext.currentUnit == null ) {
      return false;
    }

    if( appContext.authenticationClaims.unitsAdmin == null ) {
      return true;
    }

    const managingReferenceHandles = appContext.currentUser?.managing.referenceHandles();

    log.debug( "showUnitCloseOption()", managingReferenceHandles, appContext.currentUnit.databasePath() );

    if( managingReferenceHandles != null && 
        managingReferenceHandles.size > 0 && 
        Factory.get().databaseService.databaseFactory.equalDatabasePaths( 
          appContext.currentUnit.databasePath(), Array.from( managingReferenceHandles.values() )[0].path) ) {
      
      return false;
    }

    return true; 

  }

  private handleAdminDashboardClick = async () => {

    log.traceInOut( "handleAdminDashboardClick()" );

    const appContext = this.context as AppContextProps;

    if( appContext.onUpdateActiveUnit != null ) {
      await appContext.onUpdateActiveUnit( undefined );
    }

    if( appContext.onUpdateActiveCompany != null ) {
      await appContext.onUpdateActiveCompany( undefined );
    }

    this.setState( { selectedDatabase : undefined });

    this.props.history.push( HomePaths.AdminHomePath );

  };

  private handleCompanyDashboardClick = async () => {

    log.traceInOut( "handleCompanyDashboardClick()" );

    const appContext = this.context as AppContextProps;

    if( appContext.onUpdateActiveUnit != null ) {
      await appContext.onUpdateActiveUnit( undefined );
    }

    this.setState( { 
      selectedDatabase: undefined,
    } );

    this.props.history.push( HomePaths.CompanyHomePath );

  };

  private handleCompanyDashboardClose = async () => {

    log.traceInOut( "handleCompanyDashboardClose()" );

    const appContext = this.context as AppContextProps;

    if( appContext.onUpdateActiveUnit != null ) {
      await appContext.onUpdateActiveUnit( undefined );
    }

    if( appContext.onUpdateActiveCompany != null ) {
      await appContext.onUpdateActiveCompany( undefined );
    }

    this.props.history.push( HomePaths.AdminHomePath );
  };

  private handleUnitDashboardClick = async () => {

    log.traceInOut( "handleUnitDashboardClick()" );

    this.setState( { 
      selectedDatabase: undefined,
    } );

    this.props.history.push( HomePaths.UnitHomePath );

  };

  private handleUnitDashboardClose = async () => {

    log.traceInOut( "handleUnitDashboardClose()" );

    const appContext = this.context as AppContextProps;

    if( appContext.onUpdateActiveUnit != null ) {
      await appContext.onUpdateActiveUnit( undefined );
    }

    this.props.history.push( HomePaths.CompanyHomePath );
  };

  private handleUserDashboardClick = () => {

    log.traceInOut( "handleUserDashboardClick()" );

    this.setState( { 
      selectedDatabase: null,
    } );

    this.props.history.push( HomePaths.UserHomePath );

  };


  private handleDashboardsClick = async () => {

    log.traceInOut( "handleDashboardsClick()" );

    if( this.state.selectedDatabase != null ) {

      await this.handleUnitDashboardClick();
    }
    else {
      const dashboardsOpen = this.isDashboardsOpen();

      Factory.get().persistentState!.setProperty( PersistentKeyDashboardsOpen, !dashboardsOpen );

      this.setState( {  dashboardsOpen : !dashboardsOpen  });

      this.props.history.push( HomePaths.UnitHomePath );
    }
  };


  private isDashboardsOpen = () : boolean  =>  {

    //log.traceIn( "isDashboardsOpen()" );

    if( this.state.dashboardsOpen != null ) {
      //log.traceOut( "isDashboardsOpen()", "From state", this.state.dashboardsOpen );
      return this.state.dashboardsOpen;
    }

    const persistentDashboardsOpen = Factory.get().persistentState!.property( PersistentKeyDashboardsOpen ) as boolean;

    if( persistentDashboardsOpen != null ) {

      //log.traceOut( "isDashboardsOpen()", "From persistent app state", persistentDashboardsOpen );
      return persistentDashboardsOpen;
    }

    //log.traceOut( "isDashboardsOpen()", "From default", DefaultDashboardsOpen );
    return DefaultDashboardsOpen;
  };

  private handleMeasuresClick = () => {

    log.traceInOut( "handleMeasuresClick()" );

    this.selectCollectionGroup( MeasuresCollection );

  };


  private handleRegistrationsClick = ( event : any ) => {

    log.traceInOut( "handleRegistrationsClick()" );

    if (this.state.selectedDatabase == null ||
      this.state.selectedDatabase.defaultDocumentName() !== RegistrationDocument) {

      this.selectCollectionGroup( RegistrationsCollection );

      Factory.get().persistentState!.setProperty( PersistentKeyRegistrationsOpen, false );

      this.setState( {  registrationsOpen : false  }); 

    }
    else {
      event.stopPropagation();

      const registrationsOpen = this.isRegistrationsOpen();

      Factory.get().persistentState!.setProperty( PersistentKeyRegistrationsOpen, !registrationsOpen );

      this.setState( {  registrationsOpen : !registrationsOpen  });
    }
  };

  private isRegistrationsOpen = () : boolean  =>  {

    //log.traceIn( "isRegistrationOpen()" );

    if( this.state.registrationsOpen != null ) {
      //log.traceOut( "isRegistrationOpen()", "From state", this.state.registrationsOpen );
      return this.state.registrationsOpen;
    }

    const persistentRegistrationsOpen = Factory.get().persistentState!.property( PersistentKeyRegistrationsOpen ) as boolean;

    if( persistentRegistrationsOpen != null ) {

      //log.traceOut( "isRegistrationOpen()", "From persistent app state", persistentRegistrationsOpen );
      return persistentRegistrationsOpen;
    }

    //log.traceOut( "isRegistrationsOpen()", "From default", DefaultRegistrationsOpen );
    return DefaultRegistrationsOpen;
  };


  private handleAlertsClick = () => {

    log.traceInOut( "handleAlertsClick()" );

    this.selectCollectionGroup( AlertsCollection );

  }


  private handleHazardsClick = () => {

    log.traceInOut("handleHazardsClick()");

    this.selectCompanyCollection( HazardsCollection );

  };

  private handleRisksClick = () => {

    log.traceInOut("handleRisksClick()");

    this.selectCollectionGroup( RisksCollection );

  };


  private handleConsentsClick = () => {

    log.traceInOut("handleConsentsClick()");

    this.selectCollectionGroup( ConsentsCollection );

  };

  private handleGatheringsClick = () => {

    log.traceInOut("handleEventsClick()");

    this.selectCollectionGroup( GatheringsCollection );

  };

  private handleDevicesClick = () => {

    log.traceInOut("handleDevicesClick()");

    this.selectCollectionGroup( DevicesCollection );

  };


  private handleQuarantinesClick = () => {

    log.traceInOut("handleQuarantinesClick()");

    this.selectCollectionGroup( QuarantineRegistrationsCollection );

  };

  private handleSickLeavesClick = () => {

    log.traceInOut("handleSickLeavesClick()");

    this.selectCollectionGroup( SickLeaveRegistrationsCollection );

  };

  private handleSymptomsClick = () => {

    log.traceInOut("handleSymptomsClick()");

    this.selectCollectionGroup( SymptomRegistrationsCollection );

  };

  private handleTestsClick = () => {

    log.traceInOut("handleTestsClick()");

    this.selectCollectionGroup( TestRegistrationsCollection );

  };

  private handleIncidentRegistrationsClick = () => {

    log.traceInOut("handleIncidentRegistrationsClick()");

    this.selectCollectionGroup( IncidentRegistrationsCollection );

  };

  private handleVaccinesClick = () => {

    log.traceInOut("handleVaccinesClick()");

    this.selectCollectionGroup( VaccineRegistrationsCollection );

  };

  private handleCompanyClick = () => {

    log.traceInOut("handleCompanyClick()");

    const appContext = this.context as AppContextProps;

    const selectedDatabase = appContext.currentUnit!.ownerCollection( CompaniesCollection );

    this.setState({ selectedDatabase: selectedDatabase });

    this.props.history.push(HomePaths.UnitHomePath + 
      appContext.currentCompany!.databasePath( true ) + "&" + DocumentViewKey + "=" + CompanyViewPublic );
  };


  private handleUnitClick = () => {

    log.traceInOut("handleUnitClick()");

    const appContext = this.context as AppContextProps;

    const selectedDatabase = appContext.currentUnit!.ownerCollection( UnitsCollection );

    this.setState({ selectedDatabase: selectedDatabase });

    this.props.history.push(HomePaths.UnitHomePath + 
      appContext.currentUnit!.databasePath( true ) + "&" + DocumentViewKey + "=" + UnitViewOverviewParameter );
  };

  private handleUnitsClick = () => {

    log.traceInOut("handleUnitsClick()");

    this.selectCollectionGroup( UnitsCollection );

  };

  private handleProjectsClick = () => {

    log.traceInOut("handleProjectsClick()");

    this.selectCollectionGroup( ProjectsCollection );

  };

  private handleLocationsClick = () => {

    log.traceInOut("handleCategoriesClick()");

    this.selectCollectionGroup( LocationsCollection );

  };

  private handleCategoriesClick = () => {

    log.traceInOut("handleCategoriesClick()");

    this.selectCollectionGroup( CategoriesCollection );

  };



  private handleUsersClick = () => {

    log.traceInOut("handleUsersClick()");

    this.selectCollectionGroup( UsersCollection );

  };


  private handleChangesClick = () => {

    log.traceInOut( "handleChangesClick()" );

    this.selectCollectionGroup( ChangesCollection );
  };

  private selectCollection( collectionName : string ) {

    log.traceIn("selectCollection()", {collectionName} );

    const appContext = this.context as AppContextProps;

    const selectedDatabase =
      Factory.get().databaseService.databaseFactory.collectionDatabaseFromCollectionName(
        collectionName, appContext.currentUnit );

    this.setState({ selectedDatabase: selectedDatabase });

    this.props.history.push(HomePaths.UnitHomePath + selectedDatabase.databasePath(true));


    log.traceOut("selectCollection()");
  }

  private selectCollectionGroup( collectionName : string ) {

    log.traceIn("selectCollectionGroup()", {collectionName} );

    const appContext = this.context as AppContextProps;

    const selectedDatabase =
      Factory.get().databaseService.databaseFactory.collectionGroupDatabaseFromCollectionName(
        collectionName, appContext.currentUnit );

    this.setState({ selectedDatabase: selectedDatabase });

    this.props.history.push(HomePaths.UnitHomePath + selectedDatabase.databasePath(true));

    log.traceOut("selectCollectionGroup()", selectedDatabase.databasePath(true));
  }

  private selectCompanyCollection( collectionName : string ) {

    log.traceIn("selectCompanyCollection()", {collectionName} );

    const appContext = this.context as AppContextProps;

    const selectedDatabase =
      Factory.get().databaseService.databaseFactory.collectionDatabaseFromCollectionName(
        collectionName, appContext.currentCompany );

    this.setState({ selectedDatabase: selectedDatabase });

    this.props.history.push(HomePaths.UnitHomePath + selectedDatabase.databasePath(true));

    log.traceOut("selectCollectionGroup()");
  }

  

  private selectRootCollection( collectionName : string ) {

    log.traceIn("selectRootCollection()", {collectionName} );

    const selectedDatabase =
      Factory.get().databaseService.databaseFactory.collectionDatabaseFromCollectionName(
        collectionName );

    this.setState({ selectedDatabase: selectedDatabase });

    this.props.history.push(HomePaths.UnitHomePath + selectedDatabase.databasePath(true)); 

    log.traceOut("selectRootCollection()");
  }



  render() {
    //log.traceIn("render()", this.props.location.pathname);

    const { classes } = this.props;

    const appContext = this.context as AppContextProps;

    const showHealthData = () : boolean => {

      return appContext.currentUnit != null && 
        (appContext.authenticationClaims!.companiesAdmin != null ||
          appContext.authenticationClaims!.unitsAdmin != null ); 
    }

    const selectedCollectionName = this.state.selectedDatabase != null ?
      this.state.selectedDatabase.collectionName(): undefined;

    const documentName = this.state.selectedDatabase != null ?
      this.state.selectedDatabase.defaultDocumentName() : undefined;

    const dashboardItems = (
      <AppContext.Consumer>
        {appContext => (
          <div>
            <List >
              <ListItem
                button
                onClick={() => this.handleDashboardsClick()}
                selected={!this.isDashboardsOpen() && selectedCollectionName == null} >
                <ListItemIcon>
                  <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary={this.props.t('dashboard')} />
                </ListItem>
            </List>
          </div>
        )}
      </AppContext.Consumer>
    );


    const unitDataItems = (
      <AppContext.Consumer>
        {appContext => (
          <div>
            <List>
              <ListSubheader disableSticky>{this.props.t('unitData')}</ListSubheader>
              <ListItem button onClick={() => this.handleMeasuresClick()} selected={documentName === MeasureDocument}>
                <ListItemIcon>
                  <CollectionIcon collectionName={MeasuresCollection} />
                </ListItemIcon>
                <ListItemText><CollectionName name={MeasureDocument} /></ListItemText>
              </ListItem>  
              <ListItem button onClick={() => this.handleAlertsClick()} selected={documentName === HealthguardAlertDocument}>
                <ListItemIcon>
                  <CollectionIcon collectionName={AlertsCollection} />
                </ListItemIcon>
                <ListItemText><CollectionName name={HealthguardAlertDocument} /></ListItemText>
              </ListItem>       
              <ListItem button onClick={( event ) => this.handleRegistrationsClick( event )} selected={documentName === RegistrationDocument}>
                <ListItemIcon>
                  <CollectionIcon collectionName={RegistrationsCollection} />
                </ListItemIcon>
                <ListItemText><CollectionName name={RegistrationDocument} /></ListItemText>
                {this.isRegistrationsOpen() ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={this.isRegistrationsOpen()} timeout="auto" unmountOnExit>
                {registrationItems}
              </Collapse>
              <ListItem button onClick={() => this.handleConsentsClick()}
                selected={documentName === ConsentDocument}>
                <ListItemIcon>
                  <CollectionIcon collectionName={ConsentsCollection} />
                </ListItemIcon>
                <ListItemText><CollectionName name={ConsentDocument} /></ListItemText>
              </ListItem>        
            </List>
          </div>
        )}
      </AppContext.Consumer>
    );

    const registrationItems = (
      <AppContext.Consumer>
        {appContext => (
          <div>
            <List component="div" disablePadding>
              {!appContext.currentCompany!.incidentRegistrationsPrivacy.subdocument().disabled.value() &&
                <ListItem
                  button
                  className={classes.nested}
                  onClick={() => this.handleIncidentRegistrationsClick()}
                  selected={documentName === IncidentRegistrationDocument}>
                  <ListItemIcon>
                    <CollectionIcon collectionName={IncidentRegistrationsCollection} />
                  </ListItemIcon>
                  <ListItemText><CollectionName name={IncidentRegistrationsCollection} /></ListItemText>
                </ListItem>
              }
              {!appContext.currentCompany!.testRegistrationsPrivacy.subdocument().disabled.value() &&
                <ListItem
                  button
                  className={classes.nested}
                  onClick={() => this.handleTestsClick()}
                  selected={documentName === TestRegistrationDocument}>
                  <ListItemIcon>
                    <CollectionIcon collectionName={TestRegistrationsCollection} />
                  </ListItemIcon>
                  <ListItemText><CollectionName name={TestRegistrationsCollection} /></ListItemText>
                </ListItem>
              }
              {!appContext.currentCompany!.sickLeaveRegistrationsPrivacy.subdocument().disabled.value() &&
                <ListItem
                  button
                  className={classes.nested}
                  onClick={() => this.handleSickLeavesClick()}
                  selected={documentName === SickLeaveRegistrationDocument}>
                  <ListItemIcon>
                    <CollectionIcon collectionName={SickLeaveRegistrationsCollection} />
                  </ListItemIcon>
                  <ListItemText><CollectionName name={SickLeaveRegistrationsCollection} /></ListItemText>
                </ListItem>
              }
              {!appContext.currentCompany!.vaccineRegistrationsPrivacy.subdocument().disabled.value() &&
                <ListItem
                  button
                  className={classes.nested}
                  onClick={() => this.handleVaccinesClick()}
                  selected={documentName === VaccineRegistrationDocument}>
                  <ListItemIcon>
                    <CollectionIcon collectionName={VaccineRegistrationsCollection} />
                  </ListItemIcon>
                  <ListItemText><CollectionName name={VaccineRegistrationsCollection} /></ListItemText>
                </ListItem>
              }
              {!appContext.currentCompany!.symptomRegistrationsPrivacy.subdocument().disabled.value()  &&
                <ListItem
                  button
                  className={classes.nested}
                  onClick={() => this.handleSymptomsClick()}
                  selected={documentName === SymptomRegistrationDocument}>
                  <ListItemIcon>
                    <CollectionIcon collectionName={SymptomRegistrationsCollection} />
                  </ListItemIcon>
                  <ListItemText><CollectionName name={SymptomRegistrationsCollection} /></ListItemText>
                </ListItem>
              }
              {!appContext.currentCompany!.quarantineRegistrationsPrivacy.subdocument().disabled.value() &&
                <ListItem
                  button
                  className={classes.nested}
                  onClick={() => this.handleQuarantinesClick()}
                  selected={documentName === QuarantineRegistrationDocument}>
                  <ListItemIcon>
                    <CollectionIcon collectionName={QuarantineRegistrationsCollection} />
                  </ListItemIcon>
                  <ListItemText><CollectionName name={QuarantineRegistrationsCollection} /></ListItemText>
                </ListItem>
              }
            </List> 
          </div>
        )}
      </AppContext.Consumer>
    );


    const organizationItems = (
      <AppContext.Consumer>
        {appContext => (
          <div>
            <List>
              <ListSubheader disableSticky>{this.props.t('organization')}</ListSubheader>
              <ListItem
                button
                onClick={() => this.handleUnitClick()}
                selected={
                  selectedCollectionName === UnitsCollection &&
                  this.props.location.pathname !== HomePaths.UnitHomePath &&
                  this.props.location.pathname.endsWith(appContext.currentUnit!.databasePath())
                }>
                <ListItemIcon>
                  <StopIcon />
                </ListItemIcon>
                <ListItemText>{appContext.currentUnit!.title.value()}</ListItemText>
              </ListItem>
              <ListItem
                button
                onClick={() => this.handleUnitsClick()}
                selected={
                  selectedCollectionName === UnitsCollection &&
                  this.props.location.pathname !== HomePaths.UnitHomePath &&
                  !this.props.location.pathname.endsWith(appContext.currentUnit!.databasePath())
                }>
                <ListItemIcon>
                  <CollectionIcon collectionName={UnitsCollection} />
                </ListItemIcon>
                <ListItemText>{this.props.t('subUnits')}</ListItemText>
              </ListItem>             
              <ListItem button onClick={() => this.handleCategoriesClick()} selected={selectedCollectionName === CategoriesCollection}>
                <ListItemIcon>
                  <CollectionIcon collectionName={CategoriesCollection} />
                </ListItemIcon>
                <ListItemText><CollectionName name={HealthguardCategoryDocument} /></ListItemText>
              </ListItem>
              <ListItem button onClick={() => this.handleLocationsClick()} selected={selectedCollectionName === LocationsCollection}>
                <ListItemIcon>
                  <CollectionIcon collectionName={LocationsCollection}/>
                </ListItemIcon>
                <ListItemText><CollectionName name={HealthguardLocationDocument}/></ListItemText>
              </ListItem> 
              <ListItem button onClick={() => this.handleProjectsClick()} selected={selectedCollectionName === ProjectsCollection}>
                <ListItemIcon>
                  <CollectionIcon collectionName={ProjectsCollection} />
                </ListItemIcon>
                <ListItemText><CollectionName name={HealthguardProjectDocument} /></ListItemText>
              </ListItem>
              <ListItem button onClick={() => this.handleGatheringsClick()} selected={selectedCollectionName === GatheringsCollection}>
                <ListItemIcon>
                  <CollectionIcon collectionName={GatheringsCollection} />
                </ListItemIcon>
                <ListItemText><CollectionName name={GatheringDocument} /></ListItemText>
              </ListItem>  
              <ListItem button onClick={() => this.handleUsersClick()} selected={selectedCollectionName === UsersCollection}>
                <ListItemIcon>
                  <CollectionIcon collectionName={UsersCollection} />
                </ListItemIcon>
                <ListItemText><CollectionName name={HealthguardUserDocument}/></ListItemText>
              </ListItem>
            </List>
          </div>
        )}
      </AppContext.Consumer>
    );

    const configurationItems = (
      <AppContext.Consumer>
        {appContext => (
          <div>
            <List>
              <ListSubheader disableSticky>{this.props.t('configuration')}</ListSubheader>
              <ListItem button onClick={() => this.handleDevicesClick()} selected={selectedCollectionName === DevicesCollection}>
                <ListItemIcon>
                  <CollectionIcon collectionName={DevicesCollection} />
                </ListItemIcon>
                <ListItemText><CollectionName name={HealthguardDeviceDocument}/></ListItemText>
              </ListItem>
            </List>
          </div>
        )}
      </AppContext.Consumer>
    );

    const auditItems = (
      <AppContext.Consumer>
        {appContext => (
          <div>
            <List>
              <ListSubheader disableSticky>{this.props.t('audit')}</ListSubheader>
              <ListItem button onClick={()=>this.handleChangesClick()} selected={selectedCollectionName === ChangesCollection}>
                <ListItemIcon>
                  <CollectionIcon collectionName={ChangesCollection} />
                </ListItemIcon>
                <ListItemText><CollectionName name={ChangeDocument} /></ListItemText>
              </ListItem>
            </List>
          </div>
        )}
      </AppContext.Consumer>
    );

    log.traceOut("renderMain()");

    return (
      <React.Fragment>
        <AppContext.Consumer>
          {appContext => (
            <AppFrame>
              {dashboardItems}
              {showHealthData() && unitDataItems}
              {organizationItems}
              {configurationItems} 
              {auditItems} 
            </AppFrame>
          )}
        </AppContext.Consumer>
      </React.Fragment>
    );
  }
}

UnitHome.contextType = AppContext;

export default withRouter(withTranslation()(withStyles(styles)(UnitHome)));

