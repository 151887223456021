import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import CookieConsent from 'react-cookie-consent';

import Drawer from '@material-ui/core/Drawer';
import Toolbar from '@material-ui/core/Toolbar';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

import AppBar from '@material-ui/core/AppBar';

import NotificationsIcon from '@mui/icons-material/Notifications';

import DatabaseRouter from './databaseRouter';
import DashboardView from 'ui/views/dashboardView';

import logoDarkBackground from 'ui/app/logo-dark.png';
import icon from 'ui/app/icon-dark.png';

import { Factory } from 'services/common/api/factory';


import { Badge, Box, Grid, Menu, MenuItem, Typography } from '@material-ui/core';
import { AppContext, AppContextProps } from 'ui/app/appContext';
import { log, SigninPath, desktopBreakpoint } from 'ui/app/app';
import { lastMonthPeriod } from './dateFilter';
import { UserViewProfileParameter, UserViewSettingsParameter } from 'ui/views/userViews';
import theme from '../app/theme';
import { Periods, Period } from '../../services/database/api/definitions/period';
import { errorDialog } from './simpleDialog';
import CookiesPersistentState from '../app/persistentState/cookiesPersistentState';
import { DisplayTypes } from '../app/display';
import { MessagesCollection } from '../../services/database/api/collections';
import { HomePaths } from '../../services/common/api/homePaths';
import { DatabaseObserverIF } from '../../services/database/api/core/databaseObserverIF';
import { Monitor } from '../../services/common/api/monitor';
import { ObservableIF } from '../../services/common/api/observableIF'; 
import { Observation } from '../../services/common/api/observation';
import { TranslationKey } from '../../services/common/api/translatorIF';
import { HazardIF } from '../../healthguard/api/documents/hazardIF';
import { MessageIF } from '../../services/database/api/documents/messageIF';
import { DatabaseViewType, DatabaseViewTypes } from './databaseViewMenu';
import { ConsentIF } from '../../healthguard/api/documents/consentIF';
import { ConsentsCollection } from '../../healthguard/api/healthguardCollections';
import { CollectionGroupDatabaseIF } from '../../services/database/api/core/collectionGroupDatabaseIF';
import { CollectionDatabaseIF } from '../../services/database/api/core/collectionDatabaseIF';
import ConsentsDialog from '../views/consentsDialog';
import DocumentRouter, { DocumentViewKey } from './documentRouter';

export const PersistentKeyFilterPropertyKey = 'filterPropertyKey';

export const PersistentKeyFilterComparator = 'filterComparator';

export const PersistentKeyFilterMatchValue = 'filterMatchValue';

export const PersistentKeyFilterCompany = 'filterCompany';

export const PersistentKeyFilterUnit = 'filterUnit';

export const PersistentKeyFilterUser = 'filterUser';

export const PersistentKeyFilterHazard = 'filterHazard';

export const PersistentKeyFilterPeriod = 'filterPeriod';
export const DefaultFilterPeriod = Periods.LastMonth as Period;

export const PersistentKeyFilterPeriodUnits = 'filterPeriodUnits';
export const DefaultFilterPeriodUnits = 2;

export const PersistentKeyIncludeHistoric = 'includeHistoric';
export const DefaultIncludeHistoric = true;

export const PersistentKeyFilterDateRange = 'filterDateRange';
export const DefaultFilterDateRange = lastMonthPeriod;

export const PersistentKeyFilterOpen = 'filterOpen';
export const DefaultFilterOpen = false;

export const PersistentKeyMarkerType = 'markerType';

export const PersistentKeyZoom = 'zoom';

export const PersistentKeyCenter = 'center';

export const PersistentKeyRowsPerPage = 'rowsPerPage';

export const PersistentKeySortColumn = 'sortColumn';

export const PersistentKeySortOrder = 'sortOrder';

export const PersistentKeyShowAccumulatedChart = 'showAccumulatedChart';
export const DefaultShowAccumulatedChart = true;

export const PersistentKeyShowBarChart = 'showBarChart';
export const DefaultShowBarChart = false;

export const PersistentKeyShowCard= 'showCard';
export const DefaultShowCard = true;

export const PersistentKeyShowMap = 'showMap';
export const DefaultShowMap = false;

export const PersistentKeyShowTree = 'showTree';
export const DefaultShowTree = false;

export const PersistentKeyShowGraph = 'showGraph';
export const DefaultShowGraph = false;

export const PersistentKeyDatabaseViewType = 'databaseViewArea';

export const PersistentKeyDashboardsOpen = 'dashboardsOpen';
export const DefaultDashboardsOpen = true;

export const PersistentKeyAlertsOpen = 'alertsOpen';
export const DefaultAlertsOpen = false;

export const PersistentKeyRegistrationsOpen = 'registrationsOpen';
export const DefaultRegistrationsOpen = false;

export const PersistentKeyDashboardDiagramCollection = "dashboardDiagramCollection";

export const PersistentKeyDashboardDiagramViewType = "dashboardDiagramViewType";
export const DefaultDashboardDiagramViewType = DatabaseViewTypes.AccumulatedChart as DatabaseViewType;

export const PersistentKeyDashboardCardCollection = "dashboardCardCollection";

export const PersistentKeyDashboardTableCollection = "dashboardTableCollection";

export const PersistentKeyChildrenExpanded = 'childrenExpandedPropertyKey';
export const DefaultChildrenExpanded = true;

export const PersistentKeyHighlightedProperty = "highlightedPropertyPropertyKey";

export const PersistentKeyColumns = "columnsPropertyKey";

export const PersistentKeyPreviousProperty = "previousPropertyPropertyKey";
export const PersistentKeyNextProperty = "nextPropertyPropertyKey";

export const PersistentKeyDirected = "directedPropertyKey";
export const DefaultDirected = false;

export const PersistentKeyActiveTab = 'activeTab';

export const PersistentKeyActiveUnit = 'activeUnit';

export const PersistentKeyActiveCompany = 'activeCompany';

export const PersistentKeyActiveHazard = 'activeHazard';

const DrawerWidth = theme.spacing(32)

const ToolbarBackground = "#0BB2C6";

//const AllHazardsKey = "allHazards"

const styles = (theme: Theme) => createStyles({

  root: {
    display: 'flex',
    height: '100%',
    width: '100%',
    overflow: 'hidden'
  },
  drawer: {
    [theme.breakpoints.up(desktopBreakpoint)]: {
      width: DrawerWidth,
      flexShrink: 0,
    }
  },
  appBar: {
    background: ToolbarBackground,
    /* Uncomment to shift appbar right for non mobile
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${DrawerWidth}px)`,
      marginLeft: DrawerWidth,
    },
    */
    width: "100%",
    zIndex: theme.zIndex.drawer + 1,
    paddingTop: 'env(safe-area-inset-top)',
    paddingLeft: 'env(safe-area-inset-left)',
    paddingRight: 'env(safe-area-inset-right)'
  },
  // necessary for content to be below app bar
  toolbar: {
    ...theme.mixins.toolbar,
    visibility: 'hidden'
  },
  drawerPaper: {
    width: DrawerWidth,
    paddingTop: 'env(safe-area-inset-top)',
    paddingLeft: 'env(safe-area-inset-left)'
  },
  menuItem: {
    justifyContent: 'flex-start',
  },
  title: {
  },
  toolbarSpacer: {
    justifyContent: 'center',
    flexGrow: 1
  },
  /*
  hazardSelect: {
    "&:after": {
      borderBottomColor: theme.palette.common.white
    },
    "& .MuiSvgIcon-root": {
      color: theme.palette.common.white
    },
  },
  hazardSelectInput: {
    color: theme.palette.common.white
  },
  hazardValue: {
    flexWrap: "nowrap"
  },
  */
  logoButton: {
    borderRadius: 0,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(2),
  },
  toolbarButton: {
    padding: theme.spacing(1)
  },
  menuButton: {
    [theme.breakpoints.up(desktopBreakpoint)]: {
      display: 'none',
    },
    padding: theme.spacing(1)
  },
  logo: {
    height: theme.spacing(4)
  },
  icon: {
    height: theme.spacing(4)
  },
  safeAreaContent: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    paddingTop: 'env(safe-area-inset-top)',
    paddingLeft: 'env(safe-area-inset-left)',
    paddingRight: 'env(safe-area-inset-right)' 
  },
  content: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
    paddingBottom: theme.spacing(7),
    width: '100%',
    height: '100%',
    overflow: 'hidden'
  },
  inputLabel: {
    marginTop: theme.spacing(-0.5)
  }
});

interface MatchParams {
}

interface AppFrameProps extends WithStyles<typeof styles>, WithTranslation, RouteComponentProps<MatchParams> {
  children: React.ReactNode
}

interface AppFrameState { // Document View Props

  drawerOpen: boolean;

  userMenuAnchor: null | HTMLElement;

  hazardOptions: Map<string, HazardIF>;

  hazardOpen: boolean,

  hazardsLoading: boolean,

  unreadMessages: number

}

class AppFrame extends React.PureComponent<AppFrameProps, AppFrameState> {

  constructor(props: AppFrameProps) {

    super(props);

    this.state = {

      drawerOpen: false,

      userMenuAnchor: null,

      hazardOptions: new Map<string, HazardIF>(),

      hazardOpen: false,

      hazardsLoading: false,

      unreadMessages: 0

    } as AppFrameState;

    this._consentsDatabaseObserver = 
      Factory.get().databaseService.databaseFactory.newDatabaseObserver() as DatabaseObserverIF<ConsentIF>;

    this._messagesDatabaseObserver = 
      Factory.get().databaseService.databaseFactory.newDatabaseObserver() as DatabaseObserverIF<MessageIF>;

    this.onUpdateMessages = this.onUpdateMessages.bind(this);

    //log.traceInOut("constructor()");
  }

  async componentDidMount() {

    try {
      log.traceIn("componentDidMount()");

      const appContext = this.context as AppContextProps;

      const hazardOptions = new Map<string, HazardIF>();

      if (appContext.currentHazard != null) {
        hazardOptions.set(appContext.currentHazard.referenceHandle().path, appContext.currentHazard);
      }

      if( appContext.currentUser != null ) {

        this._consentsDatabaseObserver = 
          Factory.get().databaseService.databaseFactory.newDatabaseObserver() as DatabaseObserverIF<ConsentIF>;
  
        const consentDatabase = Factory.get().databaseService.databaseFactory.collectionGroupDatabaseFromCollectionName(
          ConsentsCollection, appContext.currentUser ) as CollectionGroupDatabaseIF<ConsentIF>;
  
        this._consentsDatabaseObserver.setDatabaseQuery({
          databases: [consentDatabase]
        });

        this._messagesDatabaseObserver.setDatabaseQuery( {
          databases: [Factory.get().databaseService.databaseFactory.collectionDatabaseFromCollectionName( 
            MessagesCollection, appContext.currentUser ) as CollectionDatabaseIF<MessageIF>],
          includeHistoric: false
        } );
    
        await this._messagesDatabaseObserver.subscribe({ 
          observer: this,
          onNotify: this.onUpdateMessages 
        } as Monitor );
      }

      this.setState({
        hazardOptions: hazardOptions
      })

      log.traceOut("componentDidMount()");

    } catch (error) {

      log.warn("componentDidMount()", error);

      await errorDialog(error);

      log.traceOut("componentDidMount()", error);
    }
  }

  async componentWillUnmount() {
    //log.traceIn("componentWillUnmount()");

    try {

      if( this._messagesDatabaseObserver != null ) {
        await this._messagesDatabaseObserver.unsubscribe( this );
      }

      //log.traceOut("componentWillUnmount()");

    } catch (error) {
      log.warn("componentWillUnmount()", "Error unmounting collection list", error);

      log.traceOut("componentWillUnmount()", "error");
    }
  }


  private onUpdateMessages = async (observable : ObservableIF, observation : Observation ) => {

    //log.traceIn("onUpdateMessages()");

    try {
      if( this._messagesDatabaseObserver == null || observable !== this._messagesDatabaseObserver ) {
        throw new Error("Mismatching database observer");
      }

      const unreadMessages = this._messagesDatabaseObserver.filteredDocuments().size;

      if( unreadMessages !== this.state.unreadMessages ) {
        this.setState( {
          unreadMessages: unreadMessages
        });
      }

      //log.traceOut("onUpdateMessages()");

    } catch (error) {
      log.warn("onUpdateMessages()", "Error updating databases", error);

      log.traceOut("onUpdateMessages()", "error");
    }
  }


  private handleDrawer = (open: boolean) => {
    log.traceInOut("handleDrawer()");

    this.setState({ drawerOpen: open });
  };

  private handleProfileMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {

    log.traceInOut("handleProfileMenuClick()");

    this.setState({ userMenuAnchor: event.currentTarget });
  };

  private handleMessagesMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {

    log.traceInOut("handleMessagesMenuClick()");

    const appContext = this.context as AppContextProps;

    const messagesDatabase =
      Factory.get().databaseService.databaseFactory.collectionDatabaseFromCollectionName(
        MessagesCollection, appContext.currentUser );

    this.props.history.push(HomePaths.UserHomePath + messagesDatabase.databasePath(true));

  };


  private handleProfileMenuClose = () => {

    log.traceInOut("handleProfileMenuClick()");

    this.setState({ userMenuAnchor: null });
  };


  private handleSignOut = async () => {

    log.traceInOut("handleSignOut()");

    this.setState({ userMenuAnchor: null });

    await Factory.get().authenticationService!.signOut();

    this.props.history.push(SigninPath);
  };

  private handleUserProfile = () => {

    log.traceInOut("handleUserProfile()");

    const appContext = this.context as AppContextProps;

    this.setState({ userMenuAnchor: null });

    this.props.history.push(appContext.currentHomePath +
      appContext.currentUser!.databasePath(true)! + "&" + DocumentViewKey + "=" + UserViewProfileParameter);
  };
 
  private handleUserSettings = () => {

    log.traceInOut("handleUserSettings()");

    const appContext = this.context as AppContextProps;

    this.setState({ userMenuAnchor: null });

    this.props.history.push(appContext.currentHomePath +
      appContext.currentUser!.databasePath(true)! + "&" + DocumentViewKey + "=" + UserViewSettingsParameter);
  };
  

  /*
  private hazardOptions = async () => {

    log.traceIn("hazardOptions()");

    const appContext = this.context as AppContextProps;

    let result = new Map<string, HazardIF>();

    try {

      if (appContext.currentCompany == null) {

        this.setState({
          hazardOptions: result,
          hazardsLoading: false
        });

        log.traceOut("hazardOptions()", "no database");
        return;
      }

      const publicHazards = await appContext.currentCompany.publicHazards.documents();

      const hazards = await appContext.currentCompany.hazards.collection().documents();

      result = new Map<string, HazardIF>( [...publicHazards, ...hazards] );


      this.setState({
        hazardOptions: result,
        hazardsLoading: false
      });

      log.traceOut("hazardOptions()");
      return;

    } catch (error) {
      log.warn("hazardOptions()", "Error reading options", error);

      this.setState({
        hazardOptions: result,
        hazardsLoading: false
      });
    }
  }
*/
/*
  private renderHazard = () => {

    const { classes } = this.props;

    const handleChange = async (event: SelectChangeEvent) => {

      log.traceIn("handleChange()", event.target.value, event.target.name);

      const value = event.target.value != null && event.target.value.length > 0 ?
        this.state.hazardOptions.get(event.target.value) : undefined;

      log.traceIn("handleChange()", value);

      event.preventDefault();

      if (appContext.onUpdateActiveHazard != null) {
        await appContext.onUpdateActiveHazard(value);
      }

      log.traceOut("handleChange()");
    }

    const renderHazardValue = (value: string) => {

      const hazard = this.state.hazardOptions.get(value);

      return (
        <Grid container className={classes.hazardValue} >
          <Grid><Typography><b>{hazard != null ? hazard.title.value()! : this.props.t("allHazards")}</b></Typography></Grid>
        </Grid>
      )
    }

    const open = () => {

      this.setState({
        hazardOpen: true,
        hazardsLoading: true
      });

      this.hazardOptions();
    }

    const appContext = this.context as AppContextProps;

    const value: string = appContext.currentHazard != null ? appContext.currentHazard.referenceHandle().path! : "";

    return (
      <FormControl
        variant="standard"
      >
        <Select
          input={
            <Input className={classes.hazardSelectInput}
              disableUnderline={true}
            />
          }
          className={classes.hazardSelect}
          displayEmpty={true}
          renderValue={renderHazardValue}
          value={value}
          IconComponent={this.state.hazardsLoading ? Box : undefined}
          onChange={handleChange}
          open={this.state.hazardOpen && !this.state.hazardsLoading}
          onOpen={() => open()}
          onClose={() => {
            this.setState({ hazardOpen: false, hazardsLoading: false });
          }}
        >
          {appContext.currentHazard != null &&
            <MenuItem key={AllHazardsKey} value={AllHazardsKey}>{this.props.t(AllHazardsKey)}</MenuItem>
          }
          {Array.from(this.state.hazardOptions.keys()).map((path) => (
            <MenuItem
              selected={Factory.get().databaseService.databaseFactory.equalDatabasePaths(appContext.currentHazard?.referenceHandle().path, path)}
              key={path}
              value={path}>
              {this.state.hazardOptions.get(path)!.title.value()}
            </MenuItem>
          ))}
          {!this.state.hazardOpen && appContext.currentHazard == null && <MenuItem value="" style={{ display: 'none' }} />}
          {!this.state.hazardsLoading && this.state.hazardOptions.size === 0 &&
            <MenuItem disabled value="emptyOptions">{this.props.t("emptyOptions")}</MenuItem>}
        </Select>
      </FormControl>
    )
  }
*/

  render() {
    //log.traceInOut("render()", this.props.location.pathname);

    const { classes } = this.props;

    const path = this.props.location.pathname;

    /*
    const showCompanyMenus = (): boolean => {

      const appContext = this.context as AppContextProps;

      return appContext.currentUser != null &&
        appContext.currentCompany != null &&
        appContext.authenticationClaims!.companyAdmin != null;
    }
    */

    /*
    const showSystemMenus = (): boolean => {

      const appContext = this.context as AppContextProps;

      return appContext.currentUser != null &&
        !!appContext.authenticationClaims!.systemAdmin;
    }
    */ 

    const openDashboard = async () => {

      log.traceIn("openDashboard()");

      try {

        const appContext = this.context as AppContextProps;

        const path = appContext.currentHomePath;

        if (path != null) {
          this.props.history.push(path);
        }

        log.traceOut("openDashboard()", path);

      } catch (error) {
        log.warn("Error opening dashboard", error);

        await errorDialog(error);

        log.traceOut("openDashboard()", "error opening dashboard");
      }
    }

    const container = (window !== undefined ? () => window.document.body : undefined);

    const appBar = (
      <React.Fragment>
        <AppContext.Consumer>
          {appContext => (
            <>
              <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                  <Grid container justifyContent="space-between" alignItems="center" style={{flexWrap: "nowrap"}} spacing={0}>
                    <Grid item className={classes.title}> 
                      <IconButton
                        className={classes.menuButton}
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={() => this.handleDrawer(!this.state.drawerOpen)}
                      >
                        <MenuIcon />
                      </IconButton>
                    </Grid>
                    <Grid item style={ appContext.currentDisplay?.displayType !== DisplayTypes.Mobile ? { width: DrawerWidth} : {}}>
                      < IconButton 
                        color="inherit" 
                        className={classes.logoButton} 
                        onClick={() => openDashboard()}>
                      {appContext.currentDisplay?.displayType === DisplayTypes.Mobile ?
                        <img src={icon} alt="logo" className={classes.icon} /> :
                        <img src={logoDarkBackground} alt="logo" className={classes.logo} />
                      }
                      </IconButton>
                    </Grid>
                    {/*
                    <Grid item >
                      {this.renderHazard()} 
                    </Grid>
                    */}
                    <Grid item className={classes.toolbarSpacer} />
                    <Grid item>
                      <IconButton
                        className={classes.toolbarButton}
                        color="inherit"
                        onClick={this.handleMessagesMenuClick}>
                        <Badge badgeContent={this.state.unreadMessages} overlap="rectangular" showZero={false} color="primary">
                          <NotificationsIcon />
                        </Badge>
                      </IconButton>
                    </Grid>
                    <Grid item>
                      {profileMenu}
                    </Grid>
                  </Grid>
                </Toolbar>
              </AppBar>
            </>
          )}
        </AppContext.Consumer>
      </React.Fragment>
    );

    const profileMenu = (
      <>
        <AppContext.Consumer>
          {appContext => (
            <>
              <IconButton 
                className={classes.toolbarButton}
                color="inherit" 
                onClick={this.handleProfileMenuClick}> 
                <AccountCircleIcon />
              </IconButton>
              <Menu
                id="profile-menu"
                anchorEl={this.state.userMenuAnchor}
                keepMounted
                open={this.state.userMenuAnchor != null}
                onClose={this.handleProfileMenuClose}
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }} >
                {appContext.currentUser!.title.value() != null ? <MenuItem className={classes.menuItem} disabled>{appContext.currentUser!.title.value()}</MenuItem> : null}
                <MenuItem className={classes.menuItem} disabled><Typography variant="body2" color="textSecondary" align="right">{appContext.currentUser!.email.value()}</Typography></MenuItem>
                <Divider />
                <MenuItem className={classes.menuItem} onClick={() => this.handleUserSettings()}>{this.props.t("mySettings")}</MenuItem>
                <MenuItem className={classes.menuItem} onClick={() => this.handleUserProfile()}>{this.props.t("myProfile")}</MenuItem>
                <MenuItem className={classes.menuItem} onClick={() => this.handleSignOut()}>{this.props.t("logout")}</MenuItem>
              </Menu>
            </>
          )}
        </AppContext.Consumer>
      </>
    );

    const cookies = Factory.get().persistentState instanceof CookiesPersistentState ?
      (Factory.get().persistentState as CookiesPersistentState).cookies() : undefined;

    return (
      <React.Fragment>
        <AppContext.Consumer>
          {appContext => (
            <div className={classes.root}>
              <CssBaseline />
              {appBar}
              <nav className={classes.drawer}>
                {appContext.currentDisplay?.displayType !== DisplayTypes.Desktop &&
                  <Drawer
                    container={container}
                    variant="temporary"
                    anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                    open={this.state.drawerOpen}
                    onClose={() => this.handleDrawer(false)}
                    onClick={() => this.handleDrawer(false)}
                    classes={{
                      paper: classes.drawerPaper,
                    }}
                    ModalProps={{
                      keepMounted: true, // Better open performance on mobile.
                    }}>
                    <div className={classes.toolbar}>
                    </div>
                    {this.props.children}
                  </Drawer>
                }
                {appContext.currentDisplay?.displayType === DisplayTypes.Desktop &&
                  <Drawer
                    classes={{
                      paper: classes.drawerPaper,
                    }}
                    variant="permanent"
                    open>
                    <div className={classes.toolbar}>
                    </div>
                    {this.props.children}
                  </Drawer>
                }
              </nav>
              <main className={classes.safeAreaContent}>
                <div className={classes.content}>
                  <div className={classes.toolbar} />
                  <Switch>
                    <Route exact path={appContext.currentHomePath!}><DashboardView /></Route>
                    {Factory.get().databaseService.databaseFactory.isUrlDocument( path ) ?
                      <Route path={path}><DocumentRouter /></Route> :
                      <Route path={path}><DatabaseRouter /></Route>
                    }
                  </Switch>
                </div>
              </main>
              {appContext.currentDisplay?.displayType === DisplayTypes.Desktop && cookies != null && 
                <CookieConsent
                  location="bottom"
                  buttonText={this.props.t(TranslationKey.Policies + ":acceptPolicy")}
                  style={{
                    zIndex: theme.zIndex.drawer + 2,
                    background: theme.palette.grey[100],
                    justifyContent: "center"
                  }}
                  buttonStyle={{
                    background: theme.palette.primary.main,
                    color: "white",
                    marginTop: theme.spacing(0),
                    marginBottom: theme.spacing(2),
                    marginLeft: theme.spacing(0),
                    marginRight: theme.spacing(0),
                    paddingTop: theme.spacing(2),
                    paddingBottom: theme.spacing(2),
                    paddingLeft: theme.spacing(4),
                    paddingRight: theme.spacing(4),
                    borderRadius: "4px",
                    fontWeight: "bold",
                    fontSize: "normal"
                  }}
                  contentStyle={{
                    flex: "0 0 100%",
                    textAlign: "center",
                    color: "black"
                  }}>
                  <Box style={{ paddingLeft: "15%", paddingRight: "15%" }}>
                    <p>{this.props.t(TranslationKey.Policies + ":privacyPolicy")}</p>
                    <p>{this.props.t(TranslationKey.Policies + ":cookiePolicy")}</p>
                  </Box>
                </CookieConsent>
              }
              <ConsentsDialog user={appContext.currentUser!} databaseObserver={this._consentsDatabaseObserver}/>
            </div>
          )}
        </AppContext.Consumer>
      </React.Fragment>
    );
  }

  private _messagesDatabaseObserver: DatabaseObserverIF<MessageIF>;

  private _consentsDatabaseObserver : DatabaseObserverIF<ConsentIF>;

}

AppFrame.contextType = AppContext;

export default withRouter(withTranslation()(withStyles(styles)(AppFrame)));

