
export const ConnectionMethods = {

    Http : "http",

    JWT : "jwt",

    OAuth : "oAuth",

}

export type ConnectionMethod = keyof (typeof ConnectionMethods);


