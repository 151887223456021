
import ObjectId from "bson-objectid";

import { CollectionDatabase } from "../../impl/core/collectionDatabase";
import { DatabaseDocument } from "../databaseDocument";

import { DatabaseConverter } from "../databaseConverter";
import { RealmConverter } from "./realmConverter";
import { CollectionGroupDatabase } from "../../impl/core/collectionGroupDatabase";
import { Factory } from "../../../common/api/factory";
import { Archived, DocumentNameKey, OwnerIds } from "../../api/core/databaseServiceIF";
//import { OwnerProperty } from "../properties/ownerProperty";
import { DatabaseManager } from "../databaseManager";
import { log } from "../databaseService";

import { ReferenceHandle } from "../../api/core/referenceHandle";
import { Database } from "../../impl/core/database";
import { User } from "../../impl/documents/user";

import realmConfiguration from "../../../../healthguard/data/settings/realmConfig.json";


export abstract class RealmDatabaseManager extends DatabaseManager {


    collectionGroup( collectionGroupDatabase : CollectionGroupDatabase<DatabaseDocument> ): Promise<Map<string,DatabaseDocument>> {
        
        return this.database( collectionGroupDatabase );
    }

    groupReferenceHandles( collectionGroupDatabase : CollectionGroupDatabase<DatabaseDocument> ): Promise<Map<string,ReferenceHandle<DatabaseDocument>>> {
        
        return this.databaseReferenceHandles( collectionGroupDatabase );
    }
    
    collection( collectionDatabase : CollectionDatabase<DatabaseDocument> ): Promise<Map<string,DatabaseDocument>> {
        
        return this.database( collectionDatabase );
    }

    referenceHandles( collectionDatabase : CollectionDatabase<DatabaseDocument> ): Promise<Map<string,ReferenceHandle<DatabaseDocument>>> {
        
        return this.databaseReferenceHandles( collectionDatabase );
    }
 
    async createDocument( collectionDatabase : CollectionDatabase<DatabaseDocument>, databaseDocument : DatabaseDocument ): Promise<DatabaseDocument> {
        log.traceIn( "("+collectionDatabase.collectionName()+")", "createDocument()", databaseDocument.referenceHandle().title );

        try {
            if( !collectionDatabase.userAccess().allowCreate) {
                throw new Error( "No create access to collection" );
            }

            if( !databaseDocument.userAccess().allowCreate) {
                throw new Error( "No create access to document" );
            }

            if( collectionDatabase.owner() != null ) {

                const ownerData = await this.mongoDBCollection( collectionDatabase.owner()!.collectionDatabase ).findOne( 
                    { "_id": collectionDatabase.owner()!.databasePath() } );

                if( ownerData == null ) {
                    throw new Error( "Owner of collection does not exist: " + collectionDatabase.owner()!.databasePath() )
                }
            }

            let documentId : string;

            if( databaseDocument.id.value() != null ) {

                const existingDocumentData = await this.mongoDBCollection( collectionDatabase ).findOne( 
                    { "_id": databaseDocument.databasePath() } );

                if( existingDocumentData != null ) {
                    throw new Error( "Document already exists with ID: " + databaseDocument.id.value() )
                }
                documentId = databaseDocument.id.value()!;
            }
            else {

                documentId = new ObjectId().toHexString();

                if( documentId == null ) {
                    throw new Error( "Error getting new document ID");
                }

                databaseDocument.id.setValue( documentId );
            }

            const now = new Date();

            if( databaseDocument.startDate.value() == null ) {
                databaseDocument.startDate.setValue( now );
                databaseDocument.startDate.clearChanges();
            }

            databaseDocument.lastChangedAt.setValue( now );

            const currentUser =  Factory.get().databaseService.currentUser();

            databaseDocument.lastChangedBy.setValue( currentUser?.referenceHandle() as ReferenceHandle<User> );

            databaseDocument.archived.setValue( false );
            
            let documentData = await databaseDocument.toData();

            documentData._id = databaseDocument.databasePath();

            //log.debug( "("+collectionDatabase.collectionName()+")", "documentData", documentData );

            await this.mongoDBCollection( collectionDatabase ).insertOne( documentData );

            log.traceOut( "("+collectionDatabase.collectionName()+")", "createDocument()", "resolved:", databaseDocument.referenceHandle().title );
            return databaseDocument ;

        } catch( error ) {

            log.warn( "Error creating document", error );

            throw new Error( (error as any).message );
        };
    }

    async deleteDocument( collectionDatabase : CollectionDatabase<DatabaseDocument>, databaseDocument : DatabaseDocument ): Promise<void> {
        
        log.traceIn( "("+collectionDatabase.collectionName()+")", "deleteDocument()", databaseDocument.referenceHandle().title );

        try {

            if( !collectionDatabase.userAccess().allowDelete) {
                throw new Error( "No delete access to collection" );
            }

            if( !databaseDocument.userAccess().allowDelete) {
                throw new Error( "No delete access to document" );
            }

            if( databaseDocument.id.value() == null ) {
                throw new Error( "Database object has no ID"  );
            }

            await this.mongoDBCollection( collectionDatabase ).deleteOne( 
                { "_id": databaseDocument.databasePath() } );

            log.traceOut( "("+collectionDatabase.collectionName()+")", "deleteDocument()" );

        } catch( error ) {

            log.warn( "Error deleting document", collectionDatabase.collectionName(), error );
        }
    }

    async readDocument( collectionDatabase : CollectionDatabase<DatabaseDocument>, databaseDocument: DatabaseDocument): Promise<boolean> {
        
        //log.traceIn( "readDocument()", databaseDocument.referenceHandle().title);

        try {
            if( !databaseDocument.userAccess().allowRead ) {
                throw new Error( "No read access to document");
            }

            if( databaseDocument.id.value() == null  ) {
                throw new Error( "Database object has no ID" );
            }

            const documentData = await this.mongoDBCollection( collectionDatabase ).findOne( 
                { "_id": databaseDocument.databasePath() } );

            if( documentData == null ) {
                log.warn( "readDocument()", "Document could not be read", databaseDocument.referenceHandle().title );
                return false;
            }

            const documentId = databaseDocument.id.value();

            documentData.path = documentData._id;
            
            delete documentData._id;

            databaseDocument.fromData( documentData );

            databaseDocument.id.setValue( documentId );

            //log.traceOut( "readDocument()", "read:", databaseDocument.referenceHandle().title);
            return true;

        } catch( error ) {

            log.warn( "Error reading document", error );

            throw new Error( (error as any).message );
        }
    }

    async updateDocument( collectionDatabase : CollectionDatabase<DatabaseDocument>, 
        databaseDocument: DatabaseDocument,
        force? : boolean ): Promise<void> {
        
        log.traceIn( "("+collectionDatabase.collectionName()+")", "updateDocument()", databaseDocument.referenceHandle().title, {force} );

        try {

            if( !databaseDocument.userAccess().allowUpdate ) {
                throw new Error( "No update access to document" );
            }
            
            if( databaseDocument.id.value() == null || databaseDocument.id.value()!.length === 0 ) {
                throw new Error( "Database object is missing an ID, use create");
            }

            const documentPath = databaseDocument.databasePath();

            const existingDocumentData = await this.mongoDBCollection( collectionDatabase ).findOne( 
                { "_id": documentPath } );

            if( existingDocumentData == null ) {
                throw new Error( "No document found with ID: " + documentPath )
            }

            databaseDocument.lastChangedAt.setValue( new Date() );

            const currentUser =  Factory.get().databaseService.currentUser();

            databaseDocument.lastChangedBy.setValue( currentUser?.referenceHandle() as ReferenceHandle<User> );

            let documentData = await databaseDocument.toData( force );

            documentData._id = documentPath;

            delete documentData.path;

            //log.debug( "("+collectionDatabase.collectionName()+")", "documentData", documentData );

            await this.mongoDBCollection( collectionDatabase ).updateOne( 
                { "_id": documentPath },
                documentData );

            log.traceOut( "("+collectionDatabase.collectionName()+")", "updateDocument()", databaseDocument.referenceHandle().title);

        } catch( error ) {

            log.warn( "Error updating document", collectionDatabase.collectionName(), error );
            
            throw new Error( (error as any).message );
        }
    }

    async archiveDocument( collectionDatabase : CollectionDatabase<DatabaseDocument>, databaseDocument : DatabaseDocument ): Promise<void> {
        
        log.traceIn( "("+collectionDatabase.collectionName()+")", "archiveDocument()", databaseDocument.referenceHandle().title );

        try {

            if( !collectionDatabase.userAccess().allowDelete) {
                throw new Error( "No delete access to collection" );
            }

            if( !databaseDocument.userAccess().allowDelete) {
                throw new Error( "No delete access to document" );
            }

            if( databaseDocument.id.value() == null ) {
                throw new Error( "Database object has no ID"  );
            }

            const documentPath = databaseDocument.databasePath();

            const existingDocumentData = await this.mongoDBCollection( collectionDatabase ).findOne( 
                { "_id": documentPath } );

            if( existingDocumentData == null ) {
                throw new Error( "No document found with ID: " + documentPath )
            }

            databaseDocument.lastChangedAt.setValue( new Date() );

            const currentUser =  Factory.get().databaseService.currentUser();

            databaseDocument.lastChangedBy.setValue( currentUser?.referenceHandle() as ReferenceHandle<User> );

            databaseDocument.archived.setValue( true );

            databaseDocument.archivedAt.setValue( new Date() );
            
            let documentData = await databaseDocument.toData();

            //log.debug( "("+collectionDatabase.collectionName()+")", "documentData", documentData );

            documentData._id = documentPath;

            await this.mongoDBCollection( collectionDatabase ).updateOne( 
                { "_id": documentPath },
                documentData );

            log.traceOut( "("+collectionDatabase.collectionName()+")", "archiveDocument()" );

        } catch( error ) {

            log.warn( "Error archiving document", collectionDatabase.collectionName(), error );
            
            throw new Error( (error as any).message );
        }
    }


    async documentsWithProperties( 
        database : Database<DatabaseDocument>, 
        properties : Map<string,string> ) : Promise<Map<string,DatabaseDocument>> {

        log.traceIn( "documentsWithProperty()", database.databasePath(), {properties} );

        try {

            const result = new Map<string,DatabaseDocument>();

            const documentSnapshots = await this.mongoDBCollection( database ).find( properties ) as any[];

            //log.debug( "documentsWithProperty()", {documentSnapshots});

            if (documentSnapshots != null ) {

                for( const documentSnapshot of documentSnapshots ) {

                    const documentData = documentSnapshot.data();

                    delete documentData._id;

                    const databaseDocument = await Factory.get().databaseService.databaseFactory.documentFromData(
                        documentData.path, documentData) as DatabaseDocument;

                    result.set(documentData.path, databaseDocument);

                }
            }

            log.traceOut( "documentsWithProperty()", result.size );
            return result;

        } catch( error ) {

            log.warn( "Error reading document", error );

            throw new Error( (error as any).message );
        }
    }


    async expiredArchivedDocuments( expirationDays? : number ) : Promise<Map<string,DatabaseDocument>> {

        throw new Error( "Not implemented");
    }



    protected async databaseReferenceHandles( database : Database<DatabaseDocument> ): Promise<Map<string,ReferenceHandle<DatabaseDocument>>> {
        
        log.traceIn( "("+database.collectionName()+")", "databaseReferenceHandles()");

        try {

            if( !database.userAccess().allowRead ) {
                throw new Error( "No read access to database " + database.collectionName() );
            }

            let result = new Map<string,ReferenceHandle<DatabaseDocument>>();

            const databaseFilter = this.databaseFilter( database );

            const documentSnapshots = await this.mongoDBCollection( database ).find( databaseFilter );

            if( documentSnapshots != null ) {

                for( const documentSnapshot of documentSnapshots ) {

                    try {
                        const documentPath = documentSnapshot.path;

                        if( documentPath == null ) {
                            log.warn( "Missing path in document: " + documentSnapshot );
                        }
                        else {
                            const databaseDocument = 
                                await Factory.get().databaseService.databaseFactory.documentFromData( 
                                    documentPath, documentSnapshot ) as DatabaseDocument;

                            if( databaseDocument != null ) {    
                                result.set( documentPath, databaseDocument!.referenceHandle() );
                            }
                        }
                    } catch (error) {
                        log.warn("Error reading document snapshot", error);
                    }               
                }
            }

            log.traceOut( "("+database.collectionName()+")", "databaseReferenceHandles()", result.size );
            return result; 

        } catch( error ) {

            log.warn( "Error reading database reference handles", error );

            throw new Error( (error as any).message );
        }
    }

    protected async database( database : Database<DatabaseDocument> ): Promise<Map<string,DatabaseDocument>> {
        
        log.traceIn( "("+database.collectionName()+")", "database()");

        try {

            if( !database.userAccess().allowRead ) {
                throw new Error( "No read access to database " + database.collectionName() );
            }

            let result = new Map<string,DatabaseDocument>();

            const databaseFilter = this.databaseFilter( database );

            const documentSnapshots = await this.mongoDBCollection( database ).find( databaseFilter );

            if( documentSnapshots != null ) {

                for( const documentSnapshot of documentSnapshots ) {

                    try {
                        const documentPath = documentSnapshot.path;

                        if( documentPath == null ) {
                            log.warn( "Missing path in document: " + documentSnapshot );
                        }
                        else {
                            const databaseDocument = 
                                await Factory.get().databaseService.databaseFactory.documentFromData( 
                                    documentPath, documentSnapshot ) as DatabaseDocument;

                            if( databaseDocument != null ) {    
                                result.set( documentPath, databaseDocument! );
                            } 
                        }
                    } catch (error) {
                        log.warn("Error reading document snapshot", error);
                    }               
                }
            }

            log.traceOut( "("+database.collectionName()+")", "database()", result.size );
            return result; 

        } catch( error ) {

            log.warn( "Error reading database", error );

            throw new Error( (error as any).message );
        }
    }

    protected databaseFilter( database : Database<DatabaseDocument> ) : any {

        log.traceIn( "collectionGroupFilter()", database.databasePath() );

        const databaseFilter = {

            name: database.databasePath()

        } as any;
      
        databaseFilter[Archived] = false;

        if( database.documentNames() != null && database.documentNames().length > 0 ) {

            databaseFilter[DocumentNameKey] = database.documentNames();
        }

        if( database.owner() != null && database.owner()!.id.value() != null ) {
                
            if( database instanceof CollectionGroupDatabase ) {
                databaseFilter[OwnerIds] = database.owner()!.id.value()!;  // Owner IDs array contains owner
            }
            else if( database instanceof CollectionDatabase ) { 
                databaseFilter[OwnerIds] = { "$last": database.owner()!.id.value()! }; // Owner IDs array last parent element is owner
            }
            else {
                 throw new Error( "Unrecognized database" );
            }
        }
        else {
            databaseFilter[OwnerIds] = null;
        }

        log.traceOut( "collectionGroupFilter()", {collectionGroupFilter: databaseFilter} );
        return databaseFilter;
    }


    protected mongoDBCollection( database : Database<DatabaseDocument> ) : any {

        const mongoDB = Factory.get().applicationService.database();

        const databaseName = Factory.get().configurationService.config( 
            realmConfiguration, "databaseName" );

        const mongoDBCollection = mongoDB.db( databaseName ).collection( database.collectionName());

        return mongoDBCollection;
    }

    collectionGroupReference( collectionGroupDatabase : CollectionGroupDatabase<DatabaseDocument> ) : any {

        return this.mongoDBCollection( collectionGroupDatabase );
    }

    collectionReference( collectionDatabase : CollectionDatabase<DatabaseDocument> ) : any {

        return this.mongoDBCollection( collectionDatabase );
    }

    documentReference( databaseDocument : DatabaseDocument ) : any {

        if( databaseDocument.isNew() ) {
            throw new Error( "documentNotCreated" );
        }

        const mongoDB = Factory.get().applicationService.database();

        return new mongoDB.DBRef( this.mongoDBCollection( databaseDocument.collectionDatabase ), databaseDocument.databasePath() );

    }


    readonly converter : DatabaseConverter = new RealmConverter();

 }