import { KeyIF } from "../../api/documents/keyIF";
import { log } from "../../framework/databaseService";
import { CollectionDatabase } from "../core/collectionDatabase";
import { TextProperty } from "../properties/textProperty";

import { DatabaseDocument } from "../../framework/databaseDocument";
import { Company } from "./company";
import { TenantProperty } from "../properties/tenantProperty";
import { CompaniesCollection} from "../../api/collections";
import { DefinitionProperty } from "../properties/definitionProperty";

import { KeyDocument, CompanyDocument } from "../../api/documents";

import { KeyVault, KeyVaults } from "../../api/definitions/keyVault";
import { KeyStatus, KeyStatuses } from "../../api/definitions/keyStatus";
import { KeyFormat, KeyFormats } from "../../api/definitions/keyFormat";
import { KeyType, KeyTypes } from "../../api/definitions/keyType";
import { KeyFormatDefinition, KeyVaultDefinition, KeyStatusDefinition, KeyTypeDefinition } from "../../api/definitions";
import { DefaultTextType, TextType, TextTypes } from "../../api/definitions/textTypes";
import { NumberProperty } from "../properties/numberProperty";
import { InitialKeyVersion } from "../../../security/api/keyManagerIF";

export class Key extends DatabaseDocument implements KeyIF {  

    constructor( keyCollection : CollectionDatabase<Key>, documentPath? : string  ) {

        super( KeyDocument, keyCollection, documentPath );

        try {
            this.company = new TenantProperty<Company>( this, CompaniesCollection, CompanyDocument );
            this.company.editable = false; 

            this.publicKey = new TextProperty( this, DefaultTextType, true ); 

            this.secretKey = new TextProperty( this, TextTypes.Password as TextType, true ); 

            this.keyVault = new DefinitionProperty<KeyVault>( 
                this, 
                KeyVaultDefinition, 
                KeyVaults );

            this.version = new NumberProperty( this, InitialKeyVersion, InitialKeyVersion );  

            this.keyType = new DefinitionProperty<KeyType>(
                this, 
                KeyTypeDefinition, 
                KeyTypes );

            this.keyFormat = new DefinitionProperty<KeyFormat>(
                this, 
                KeyFormatDefinition, 
                KeyFormats );

            this.keyStatus = new DefinitionProperty<KeyStatus>( 
                this, 
                KeyStatusDefinition, 
                KeyStatuses );


            //log.traceInOut( "constructor()", KeysCollection ); 

        } catch( error ) {

            log.warn( "constructor()", "Error initializing key", error );
            
            throw new Error( (error as any).message );
        }
    }

    indexKey() : string {
        return KeyDocument;
    }

    async onCreate() : Promise<void> {

        //log.traceIn( "onCreate()" );

        try {

            await super.onCreate();
    
            //log.traceOut( "onCreate()" );
  
        } catch( error ) {
            
            log.warn( "onCreate()", "Error handling created notification", error );
  
            throw new Error( (error as any).message );
        }
    }

    async onUpdate() : Promise<void> {

        //log.traceIn( "onUpdate()" );

        try {

            await super.onUpdate();
    
            //log.traceOut( "onUpdate()" );
  
        } catch( error ) {
            
            log.warn( "onUpdated()", "Error handling updated notification", error );
  
            throw new Error( (error as any).message );
        }
    }

    async onDelete() : Promise<void> {

        //log.traceIn( "onDelete()" );

        try {

            await super.onDelete();
    
            //log.traceOut( "onDelete()" );
  
        } catch( error ) {
            
            log.warn( "onDelete()", "Error handling deleted notification", error );
  
            throw new Error( (error as any).message );
        }
    }


    readonly company: TenantProperty<Company>;

    readonly publicKey : TextProperty;

    readonly secretKey : TextProperty;  // Only for temporary storage

    readonly keyVault : DefinitionProperty<KeyVault>;

    readonly version : NumberProperty;

    readonly keyStatus : DefinitionProperty<KeyStatus>;

    readonly keyType : DefinitionProperty<KeyType>;

    readonly keyFormat : DefinitionProperty<KeyFormat>;

}
