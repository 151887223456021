
import { DatabaseObject } from "../../framework/databaseObject";
import { DatabaseProperty } from "../../framework/databaseProperty";
import { OptionsSource } from "../core/optionsSource";
import { DatabaseDocument } from "../../framework/databaseDocument";
import { PropertyType, PropertyTypes } from "../../api/definitions/propertyType"; 
import { TextsPropertyIF } from "../../api/properties/textsPropertyIF";
import { log } from "../../framework/databaseService";

export class TextsProperty extends DatabaseProperty<string[]> implements TextsPropertyIF {

    constructor( parent : DatabaseObject ) {
        super( PropertyTypes.Texts as PropertyType, parent ); 

    }

    setOptionsSource( optionsSource :  OptionsSource<DatabaseDocument,TextsProperty> ) {
        this._optionsSource = optionsSource;
    }  

    value( ignoreDefault? : boolean ) {
        return this.values( ignoreDefault );
    } 

    setValue( values : string[] | undefined ) : void {
        this.setValues( values );
    }

    values( ignoreDefault? : boolean ) : string[] | undefined {

        this.decryptData();

        if( this._values != null ) {
            return this._values;
        }
        return !!ignoreDefault ? undefined : this._defaultValues;
    }

    setValues( values : string[] | undefined ) : void {

        this.decryptData();

        if( this.compareValue( values ) !== 0 ) {

            this.setLastChange( this._values != null ? Object.assign([], this._values) : undefined );

            this._values = values != null ? Object.assign([], values) : undefined;

            delete this.error;
        }
    }

    clearValues(): void {

        const values = this.values();

        if( values != null ) {

            this.setLastChange( this._values != null ? Object.assign([], this._values) : undefined );

            this._values = undefined;

            delete this.error;
        }
    }

    addValue( value : string ): void {

        const values = this.values();

        if( values == null ) {

            this._values = [value];

            this.setLastChange( this._values != null ? Object.assign([], this._values) : undefined );

            delete this.error;
        }
        else if( values.indexOf( value ) === -1 ) {

            this.setLastChange( this._values != null ? Object.assign([], this._values) : undefined );

            values.push( value );

            this._values = Object.assign([], values);

            delete this.error;
        }
    }

    removeValue( value : string ): boolean {

        const values = this.values();

        if( values == null ) {
            return false;
        }

        let index = values.indexOf( value );

        if( index === -1 ) {
            return false;
        }

        this.setLastChange( this._values != null ? Object.assign([], this._values) : undefined );

        values.splice( index, 1 );

        this._values = Object.assign([], values);

        delete this.error;

        return true;
    }

    defaultValue() : string[] | undefined { 

        return this.defaultValues();
    }

    setDefaultValue( defaultValues : string[] | undefined ): void { 

        this.setDefaultValues( defaultValues );
    }

    defaultValues() : string[] | undefined { 

        return this._defaultValues;
    }

    setDefaultValues( defaultValues : string[] | undefined ): void { 

        this._defaultValues = defaultValues != null ? Object.assign([], defaultValues) : undefined; 
    }



    fromData( documentData: any): void {

        if( this.isEncryptedData( documentData[this.key()] ) ) {

            this.setEncryptedData( documentData[this.key()] );
        }
        else {

            this._values = documentData[this.key()] as string[]; 
        }
    }

    async toData( documentData: any, force? : boolean ) : Promise<void> {

        if( !!force ) {
            this.decryptData();
        }
        
        if( this.encrypted() && this.encryptedData() != null ) {
            
            documentData[this.key()] = this.encryptedData();
            return;
        }

        let data;

        if( this.encrypted() ) {
            data = this.encryptData( this._values );
        }
        else {
            data = this._values;
        }

        if( data != null ) {
            documentData[this.key()] = data;
        }
    }

    requiresSelect() : boolean {
        return this._optionsSource != null;
    }

    async options() : Promise<Map<string,string> | undefined> {

        const values = this.values();

        if( values == null ) {
            return undefined;
        }

        const result = new Map<string,string>();

        values.forEach( value => {
            result.set( value, value );
        });

        return result;    
    }

    async select( filterValues? : boolean ) : Promise<Map<string,string> | undefined> { 

        log.traceIn( "select()" );

        const values = this.values();

        const optionsProperty = this._optionsSource != null ? 
            await this._optionsSource.optionsProperty() : 
            undefined;

        if( optionsProperty == null ) {

            log.traceOut( "select()", "no options source" );
            return undefined;    
        }

        const options = await optionsProperty.options();

        if( options == null ) {

            log.traceOut( "select()", "no options" );
            return undefined;    
        }

        const result = new Map<string,string>();

        for( const option of options.values() ) {

            if( !!filterValues && values != null && values.includes( option ) ) {
                continue;
            }
            result.set( option, option );
        }

        log.traceOut( "select()", result.size );
        return result.size === 0 ? undefined : result;  
    }
    
    compareTo( other : TextsProperty ) : number {

        return this.compareValue( other._values );

    }

    compareValue( values : string[] | undefined ) : number {

        if( this._values == null && values == null ) {
            return 0;
        }

        if( this._values != null && values == null ) {
            return 1;
        }

        if( this._values == null && values != null ) {
            return -1;
        }

        if( this._values!.length > values!.length ) {
            return 1;
        }

        if( this._values!.length < values!.length ) {
            return -1;
        }

        for( let i = 0; i < values!.length; i++ ) {

            if( !this._values!.includes( values![i]) ) {
                return 1;
            }
        }

        return 0;
    }

    includes( other : TextsProperty, matchAny? : boolean ) : boolean {
        return this.includesValue( other.value(), matchAny );
    }

    includesValue( otherValues : string[] | undefined, matchAny? : boolean  ) : boolean {

        const thisValues = this.values();

        if( otherValues == null || otherValues.length === 0 ) {
            return false;
        }

        if( thisValues == null || thisValues.length === 0 ) {
            return false;
        }

        if( thisValues != null && otherValues == null ) {
            return false;
        }

        if( thisValues == null && otherValues != null ) {
            return false;
        }

        if( thisValues!.length < otherValues!.length && !matchAny) {
            return false;
        }
        for( let i = 0; i < otherValues!.length; i++ ) {

            const compare = thisValues!.includes( otherValues![i]);

            if( compare && !!matchAny ) {
                return true;
            }

            if( !compare && !matchAny ){
                return false;
            }
        }

        return !matchAny;
    }

    private _optionsSource : OptionsSource<DatabaseDocument,TextsProperty> | undefined;

    protected _values : string[] | undefined;

    protected _defaultValues : string[] | undefined;


}