import * as React from 'react'

import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import { withTranslation, WithTranslation } from 'react-i18next';

import { log } from 'ui/app/app';
import { AppContext, AppContextProps } from 'ui/app/appContext';

import { Chip, CircularProgress, IconButton, TextField } from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add'; 

import { CollectionPropertyIF } from 'services/database/api/properties/collectionPropertyIF';
import { ReferenceHandle } from 'services/database/api/core/referenceHandle';
import { DatabaseDocumentIF } from 'services/database/api/core/databaseDocumentIF';
import { PropertyDisplayMode, propertyInputVariant} from '../propertyValue';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Monitor } from 'services/common/api/monitor';
import { ObservableIF } from 'services/common/api/observableIF';
import { Observation } from 'services/common/api/observation';
import { Factory } from '../../../services/common/api/factory';
import { DocumentsList } from '../documentsList';
import { confirmationDialog, errorDialog } from '../simpleDialog';

import { UsersCollection } from '../../../services/database/api/collections';
import theme from '../../app/theme';

import { NewDocumentId } from '../../../services/database/api/core/databaseServiceIF';
import { TranslationKey } from '../../../services/common/api/translatorIF';
import { UserAccess } from '../../../services/common/api/userAccess';
import { DocumentsPropertyIF } from '../../../services/database/api/properties/documentsPropertyIF';
import { DatabaseIF } from '../../../services/database/api/core/databaseIF';
import { documentDialog } from '../documentDialog';
import { PropertyTypes } from '../../../services/database/api/definitions/propertyType';
import { translatedPropertyLabel } from '../propertyLabel';

const styles = (theme: Theme) => createStyles({ 
  root: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    minHeight: theme.spacing(16),
    maxHeight: theme.spacing(64),
  },
  heading: {
    marginTop: theme.spacing(-4.5),
    marginLeft: theme.spacing(-1),
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(1),
    position: 'absolute',
    display: 'flex',
    flexDirection: 'row',
    color: theme.palette.text.secondary,
    background: "#FFFFFF",
    fontSize: "small"
  },
  addButton: {
    zIndex: 999,
    position: 'absolute',
    marginTop: theme.spacing(-1),
    marginRight: theme.spacing(-4.5),
  },
});

export interface CollectionPropertyValueProps 
  extends WithStyles<typeof styles>, WithTranslation { 

  property : CollectionPropertyIF<DatabaseDocumentIF> | DocumentsPropertyIF<DatabaseDocumentIF>, 

  onPropertyChange? : ( property : CollectionPropertyIF<DatabaseDocumentIF> | DocumentsPropertyIF<DatabaseDocumentIF> ) => void,

  displayMode : PropertyDisplayMode,

  singleProperty: boolean,

  hideColor?: boolean,

  disabled?: boolean,

  required? : boolean 
}

interface CollectionPropertyValueState { // Component State

  handles: Map<string, ReferenceHandle<DatabaseDocumentIF>>,

  open : boolean,

  loading: boolean,

  userAccess: UserAccess,

  collectionName: string,

  database? : DatabaseIF<DatabaseDocumentIF>
}

class CollectionPropertyValue extends React.Component<CollectionPropertyValueProps,CollectionPropertyValueState> {

  constructor( props: CollectionPropertyValueProps ) {
    
    super(props);

    const userAccess = this.props.property.userAccess();

    const collectionName = this.props.property.collectionName();

    let database;

    if( this.props.property.type === PropertyTypes.Collection ) {

        const collectionProperty = this.props.property as CollectionPropertyIF<DatabaseDocumentIF>;
        
        database = !!collectionProperty.allowCollectionGroup() ? 
          collectionProperty.collectionGroup() : 
          collectionProperty.collection();
    }

    this.state = { 

      handles: new Map<string, ReferenceHandle<DatabaseDocumentIF>>(),

      loading: true,

      open: false,

      userAccess: userAccess,

      collectionName: collectionName,

      database: database

     } as CollectionPropertyValueState;

     this.showDocument = this.showDocument.bind(this);
     this.addDocument = this.addDocument.bind(this);
     this.deleteDocument = this.deleteDocument.bind(this);

    //log.traceInOut( "constructor()" );
  }

  async componentDidMount() {

    try {
      log.traceIn( "componentDidMount()");

      if( this.state.database != null  ) {
        const monitor = {
          observer: this,
          onNotify: this.onNotify
        } as Monitor;

        await this.state.database.subscribe( monitor );
      }
    
      log.traceOut( "componentDidMount()");

    } catch( error ) {
      log.warn( "componentDidMount()", "Error mounting documents view", error );
      
      this.setState( {
        loading: false,
        open: false
      });

      await errorDialog(error);

      log.traceOut( "componentDidMount()", "error" );
    }
  }


  async componentWillUnmount() {
    log.traceIn( "componentWillUnmount()" );

    try {

      if( this.state.database != null  ) {

        await this.state.database.unsubscribe( this );
      }
          
      //log.traceOut( "componentWillUnmount()");

    } catch( error ) {
      log.warn( "componentWillUnmount()", "Error unmounting collection list", error );
      
      log.traceOut( "componentWillUnmount()", "error" );
    }
  }

  onNotify = async (observable: ObservableIF,
    observation: Observation,
    objectId: string | null | undefined,
    object: object | null | undefined): Promise<void> => {

    try {
      log.traceIn("onNotify()", Observation[observation], objectId );

      const referenceHandles = this.state.handles;

      switch (observation) {

        case Observation.Create:
        case Observation.Update:
        {         
          if( Factory.get().databaseService.databaseFactory.isUrlDatabase( objectId! ) ) {

            const initialResult = object as Map<string,DatabaseDocumentIF>;

            if( initialResult != null ) {

              for( const databaseDocument of initialResult.values() ) {

                const documentPath = databaseDocument.databasePath( true );

                log.debug("onNotify()", {documentPath}, {databaseDocument} );

                referenceHandles!.set(documentPath, databaseDocument.referenceHandle());
              }
            }
          }
          else {
            const databaseDocument: DatabaseDocumentIF = object as DatabaseDocumentIF;

            const documentPath = databaseDocument.databasePath( true );

            log.debug("onNotify()", {documentPath}, {databaseDocument} );
        
            referenceHandles!.set(documentPath, databaseDocument.referenceHandle());
          }
          this.setState( { loading: false } );
          break;        
        }
        case Observation.Delete:
        {
          const databaseDocument: DatabaseDocumentIF = object as DatabaseDocumentIF;
      
          const documentPath = databaseDocument.databasePath( true );

          log.debug("onNotify()", {documentPath}, {databaseDocument} );

          referenceHandles!.delete(documentPath );
          break;
        }
        default:
          throw new Error("Unrecognized observation: " + observation);
      }

      this.setState({ 
        handles: referenceHandles
      });

      log.traceOut("onNotify()");

    } catch( error ) {
          
      log.warn( "onNotify()", error );

      await errorDialog( error);

      log.traceOut( "onNotify()", error );
    }
  }

  showDocument = async (referenceHandle: ReferenceHandle<DatabaseDocumentIF>) => {

    log.traceIn( "showDocument()" );

    try {

      const databaseDocument = await referenceHandle.databaseDocument?.read();

      await documentDialog( databaseDocument! );

      log.traceOut( "showDocument()");

    } catch( error ) {
      log.warn( "showDocument()", "Error showing new document", error );

      await errorDialog( error );
      
    }
  }

  addDocument = async () => {

    log.traceIn("addDocument()" );

    try {

      const appContext = this.context as AppContextProps;

      if( appContext.currentCompany != null &&
          this.state.collectionName === UsersCollection ) {

        const availableLicenses = await appContext.currentCompany.availableLicenses();

        if( availableLicenses === 0 ) {
          throw new Error( "noMoreLicenses");
        }
      }

      const newDocument = (this.props.property as CollectionPropertyIF<DatabaseDocumentIF>).collection().newDocument()!;

      await documentDialog( newDocument! );

      log.traceOut("addDocument()");

    } catch( error ) {
      
      log.warn( "addDocument()", error );

      await errorDialog( error);

      log.traceOut( "addDocument()", error );
    }
  }



  deleteDocument = async ( referenceHandle : ReferenceHandle<DatabaseDocumentIF> ) => {

    log.traceIn("deleteDocument()", {referenceHandle} );

    try {

      let confirmationPrompt = this.props.t(TranslationKey.Prompts + ":deleteDocument");

      confirmationPrompt += " " + referenceHandle.title + "?"

      let confirmation = await confirmationDialog(confirmationPrompt);

      if( !confirmation ) {
        log.traceOut( "deleteDocument()", "Cancel" );
        return;
      }

      confirmation = await confirmationDialog( "deleteDocument2");

      if( !confirmation ) {
        log.traceOut( "deleteDocument()", "Cancel" );
        return;
      }

      const databaseDocument = Factory.get().databaseService.databaseFactory.newDocumentFromUrl( 
        referenceHandle.path );

      if (databaseDocument == null) {
        throw new Error("Document does not exist with path: " + referenceHandle.path);
      }

      await databaseDocument.delete();
      
      log.traceOut("deleteDocument()");

    } catch( error ) {
      
      log.warn( "deleteDocument()", error );

      await errorDialog( error);

      log.traceOut( "deleteDocument()", error );
    }
  }

  render() {
    log.traceInOut("render()", this.state.handles );

    //const { classes } = this.props;

    if( !this.props.property.userAccess().allowRead ) {
      return ( null );
    }

    if( this.props.displayMode === PropertyDisplayMode.Cell ) {
      return ( <>&nbsp;</> );
    }

    const optionLabel = ( option : ReferenceHandle<DatabaseDocumentIF> ) : string => {

      return option.title != null ? option.title : "";
    }

    const newDocument = this.props.property.parentDocument().databasePath().includes("/" + NewDocumentId); 

    const canCreate = 
      !newDocument &&
      !this.props.disabled &&
      this.state.userAccess.allowCreate;
  
    const canDelete = 
      !this.props.disabled &&
      this.state.userAccess.allowDelete;

    const showList = this.props.singleProperty && this.props.displayMode === PropertyDisplayMode.Form;

    const label = this.props.singleProperty ? undefined :
      translatedPropertyLabel( this.props.property.parent.documentName(), this.props.property.key() );

    const referenceHandles = Array.from( this.state.handles.values());

    const list = (
      <DocumentsList
        collectionName={this.state.collectionName!}
        handles={this.state.handles}
        label={label}
        allowSelect={false}
        allowAdd={canCreate}
        allowRemove={canDelete}
        onAddDocument={this.addDocument}
        onSelectHandle={this.showDocument}
        onRemoveHandle={this.deleteDocument}
        showDate={true}
      />
    );

    const chips = (
      <Autocomplete
        filterSelectedOptions
        disabled={this.props.disabled}
        fullWidth
        multiple
        disableClearable
        open={this.state.open}
        onOpen={() => {
          this.setState({ open: true });
        }}
        onClose={() => {
          this.setState({ open: false });
        }}
        getOptionSelected={(option, value) => Factory.get().databaseService.databaseFactory.equalDatabasePaths(option.path, value.path)}
        getOptionLabel={optionLabel}
        options={referenceHandles}
        loading={this.state.loading}
        loadingText={this.props.t("loading") + " ..."}
        noOptionsText={this.props.t("emptyOptions")}
        value={referenceHandles}
        closeIcon={null}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => {
            return (
              <Chip
                {...getTagProps({ index })}
                variant="default"
                key={option.path}
                label={option.title}
                disabled={!this.state.userAccess.allowDelete || !this.state.userAccess.allowRead}
                onClick={() => this.showDocument(option)}
                onDelete={() => this.deleteDocument(option)}
              />
            )
          })
        }
        renderInput={(params) => (
          <TextField
            {...params}
            error={this.props.property.error != null}
            disabled={this.props.disabled}
            required={this.props.required}
            variant={propertyInputVariant}
            fullWidth
            key={this.props.property.key()}
            label={!this.props.singleProperty && label}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {canCreate ?
                    <IconButton onClick={() => this.addDocument()} >
                      <AddIcon />
                    </IconButton> :
                    <IconButton disabled style={{ height: theme.spacing(6) }} />
                  }
                  {this.state.loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    );

    return( showList ? list : chips );

  }  
}

CollectionPropertyValue.contextType = AppContext;


const ModifiedCollectionPropertyValue = withTranslation()(withStyles(styles)(CollectionPropertyValue));

export default ModifiedCollectionPropertyValue;


