export const MessageCategories = {

    Database       : "database",

    Communication : "communication",

    Security     : "security",

    Service     : "service"
}

export type MessageCategory = keyof (typeof MessageCategories);
