
import { DatabaseConverterIF } from "../api/core/databaseConverterIF";

export abstract class DatabaseConverter implements DatabaseConverterIF {

    abstract toDate( data? : any ) : Date | undefined;

    abstract fromDate( date? : Date ) : any | undefined;

    abstract toGeolocation( data? : any ) : any | undefined;

    abstract fromGeolocation( geolocation? : any ) : any | undefined;
    
}
