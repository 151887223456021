import React, { useContext } from 'react';

import { withTranslation, WithTranslation } from 'react-i18next';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core';

import DocumentView, { DocumentProps } from '../components/documentView';

import { PropertiesSelector } from 'services/database/api/core/propertiesSelector';

import DatabaseTable, { DatabaseTableProps } from 'ui/components/databaseTable';
import DatabaseList, { DatabaseListProps } from 'ui/components/databaseList';
import DatabaseCard, { DatabaseCardProps } from 'ui/components/databaseCard';
import DatabaseChart, { DatabaseChartProps } from 'ui/components/databaseChart';
import DatabaseGraph, { DatabaseGraphProps } from 'ui/components/databaseGraph';

import DatabaseView from '../components/databaseView';
import { MeasureIF } from 'healthguard/api/documents/measureIF';
import { CompanyDocument } from '../../services/database/api/documents';
import { DatabaseDocumentIF } from '../../services/database/api/core/databaseDocumentIF';
import { log } from '../app/app';
import { HomePaths } from '../../services/common/api/homePaths';

import { errorDialog } from '../components/simpleDialog';
import { AppContext, AppContextProps, } from '../app/appContext';
import { DatabaseFilter } from '../../services/database/api/core/databaseFilter';
import { Comparator, Comparators } from '../../services/database/api/definitions/comparator';
import { MeasureStates } from '../../healthguard/api/definitions/measureState';
import { DatabasePropertyIF } from '../../services/database/api/core/databasePropertyIF';
import { DatabaseObserverIF } from '../../services/database/api/core/databaseObserverIF';
import { DatabaseQuery } from '../../services/database/api/core/databaseQuery';
import { GatheringsCollection, HazardsCollection, RisksCollection } from '../../healthguard/api/healthguardCollections';
import { ReadinessLevelDefinition } from '../../services/database/api/definitions';
import { CategoriesCollection, LocationsCollection, ProjectsCollection, UnitsCollection } from '../../services/database/api/collections';
import { RiskDocument } from '../../healthguard/api/healthguardDocuments';
import { DatabaseViewType, DatabaseViewTypes } from '../components/databaseViewMenu';

export const userMeasureTableColumns = [ 
  CompanyDocument,
  "responsible",
  "title",
  "measureState"
];

export const adminMeasureTableColumns = [ 
  CompanyDocument,
  "title",
  "responsible",
  "measureType",
  "measureClassification",
  "measureState",
  ReadinessLevelDefinition,
  CategoriesCollection,
  LocationsCollection, 
  RisksCollection,  
  HazardsCollection
];

export const defaultHighlightedMeasuresProperty = "measureState";


const updateDatabaseObserver = (appContext: AppContextProps, databaseObserver: DatabaseObserverIF<MeasureIF> ) => {

  let databaseFilters = databaseObserver.databaseFilters();

  if (databaseFilters == null) {
    databaseFilters = new Map<string, DatabaseFilter>();
  }
  let measureStateFilter;

  switch (appContext.currentHomePath) {

    case HomePaths.AdminHomePath:
    case HomePaths.CompanyHomePath:
    case HomePaths.UnitHomePath:
      break;


    case HomePaths.UserHomePath:
      measureStateFilter = {
        property: "measureState",
        comparator: Comparators.Equal as Comparator,
        value: MeasureStates.Ongoing
      } as DatabaseFilter
      break;

    default:
      throw new Error("Unexpected home path in app context");
  }

  if (measureStateFilter != null) {
    databaseFilters.set(measureStateFilter.property, measureStateFilter);
  }

  databaseObserver.setDatabaseFilters( databaseFilters );

  return databaseObserver;
}


export function MeasuresTable(props: DatabaseTableProps ) {

  const appContext = useContext(AppContext);

  const [databaseObserver,setDatabaseObserver] = React.useState<DatabaseObserverIF<MeasureIF> | undefined>();

  React.useEffect( () => {

    setDatabaseObserver( updateDatabaseObserver( appContext, props.databaseObserver as DatabaseObserverIF<MeasureIF>) );

  }, [appContext, props.databaseObserver]);

  return (databaseObserver == null ? <></> : 
    <DatabaseTable 
      {...props} 
      databaseObserver={databaseObserver}
      featuredProperties={props.featuredProperties != null ? 
          props.featuredProperties : 
          appContext.currentHomePath === HomePaths.UserHomePath ? 
            userMeasureTableColumns :
            adminMeasureTableColumns } 
      />
  );
}

export function MeasuresList(props: DatabaseListProps ) {

  const appContext = useContext(AppContext);

  const [databaseObserver,setDatabaseObserver] = React.useState<DatabaseObserverIF<MeasureIF> | undefined>();

  React.useEffect( () => {

    setDatabaseObserver( updateDatabaseObserver( appContext, props.databaseObserver as DatabaseObserverIF<MeasureIF>) );

  }, [appContext, props.databaseObserver]);

  return (databaseObserver == null ? <></> : 
    <DatabaseList 
      {...props} 
      databaseObserver={databaseObserver}
      />
  );
}


export function MeasuresCard( props: DatabaseCardProps ) {

  const appContext = useContext(AppContext);

  const [databaseObserver,setDatabaseObserver] = React.useState<DatabaseObserverIF<MeasureIF> | undefined>();

  React.useEffect( () => {

    setDatabaseObserver( updateDatabaseObserver( appContext, props.databaseObserver as DatabaseObserverIF<MeasureIF>) );

  }, [appContext, props.databaseObserver]);

  return (databaseObserver == null ? <></> : 
    <DatabaseCard 
      {...props} 
      databaseObserver={databaseObserver}
      highlightedPropertyKey={props.highlightedPropertyKey != null ? 
        props.highlightedPropertyKey : 
        defaultHighlightedMeasuresProperty}
    />
  );
} 

export function MeasuresChart( props: DatabaseChartProps ) {

  const appContext = useContext(AppContext);

  const [databaseObserver,setDatabaseObserver] = React.useState<DatabaseObserverIF<MeasureIF> | undefined>();

  React.useEffect( () => {

    setDatabaseObserver( updateDatabaseObserver( appContext, props.databaseObserver as DatabaseObserverIF<MeasureIF>) );

  }, [appContext, props.databaseObserver]);

  return (databaseObserver == null ? <></> : 
    <DatabaseChart 
      {...props} 
      databaseObserver={databaseObserver}
    />
  );
}

export function MeasuresGraph( props: DatabaseGraphProps ) {

  const appContext = useContext(AppContext);

  const [databaseObserver,setDatabaseObserver] = React.useState<DatabaseObserverIF<MeasureIF> | undefined>();

  React.useEffect( () => {

    setDatabaseObserver( updateDatabaseObserver( appContext, props.databaseObserver as DatabaseObserverIF<MeasureIF> ) );

  }, [appContext, props.databaseObserver]);

  return (databaseObserver == null ? <></> : 
    <DatabaseGraph 
      {...props} 
      databaseObserver={databaseObserver}
      previousPropertyKey={"measureClassification"}
      nextPropertyKey={"users"}
      directed={false}
    />
  );
}


export function MeasuresView(props: {
  databaseQuery : DatabaseQuery<MeasureIF>,
  title?: string,
  featuredProperties?: string[],
  highlightedPropertyKey?: string
}) {

  const appContext = useContext(AppContext);

  return (
    <DatabaseView
      databaseQuery={props.databaseQuery}
      title={props.title}
      featuredProperties={props.featuredProperties != null ?
        props.featuredProperties :
        appContext.currentHomePath === HomePaths.UserHomePath ?
          userMeasureTableColumns :
          adminMeasureTableColumns}
      highlightedPropertyKey={props.highlightedPropertyKey != null ? 
        props.highlightedPropertyKey : 
        defaultHighlightedMeasuresProperty}
      defaultDatabaseViewType={DatabaseViewTypes.Table as DatabaseViewType}
    />);
}

const styles = (theme: Theme) => createStyles({});


interface MeasureViewProps extends DocumentProps, WithStyles<typeof styles>, WithTranslation {
}

interface MeasureViewState { // Document View Props

  measure: MeasureIF,

  steps?: Map<string, PropertiesSelector>,

  showAllTargets: boolean,
}


class MeasureView extends React.PureComponent<MeasureViewProps,MeasureViewState> {

  constructor( props: MeasureViewProps ) {
    
    super(props);

    log.traceIn( "constructor()" );

    this.state = { 

      showAllTargets: false,

      measure: this.props.databaseDocument

    } as MeasureViewState;

    this.readMeasure = this.readMeasure.bind(this);

    //log.traceOut( "constructor()" );
  }

  async componentDidMount() {

    try {
      log.traceIn( "componentDidMount()" );

      await this.readMeasure();

      log.traceOut( "componentDidMount()" );
    } catch( error ) {
      log.warn( "componentDidMount()", "Error mounting document view", error );
      
      log.traceOut( "componentDidMount()", "error" );
    }
  }

  async componentDidUpdate() {

    try {
      log.traceIn( "componentDidUpdate()");

      log.traceOut( "componentDidUpdate()" );
    } catch( error ) {
      log.warn( "componentDidUpdate()", "Error mounting document view", error );
      
    }
  }

  async readMeasure() {

    try {
      log.traceIn("readMeasure()" );

      let measure = this.props.databaseDocument as MeasureIF;

      this.setState( {

        measure: measure,

        showAllTargets: !measure.allUsers.value()

      })

      log.traceOut( "readMeasure()", "state:", this.state );
      
    } catch( error ) {
      log.warn( "readMeasure()", "Error mounting document view", error );

      await errorDialog( error );

      log.traceOut( "readMeasure()", "error" );
    }
  }


  render() {

    const MeasureBasicsFilter = {

      includePropertyKeys: [
        "unit",
        "responsible",
        RiskDocument,
        "measureType",
        "measureClassification", 
        "readinessLevels",
        "purpose",
        "description",
        "links",
        "attachments", 
      ]

    } as PropertiesSelector;

    const MeasureStatusFilter = {

      includePropertyKeys: [
        "measureState",
        "startDate",
        "endDate"]

    } as PropertiesSelector; 

    const MeasureTargetsFilter = {

      includePropertyKeys: [
        CategoriesCollection,
        UnitsCollection, 
        LocationsCollection,
        ProjectsCollection,
        GatheringsCollection,
        "allUsers", 
      ]

    } as PropertiesSelector;

    const MeasureNoTargetsFilter = {

      includePropertyKeys: ["allUsers"]

    } as PropertiesSelector;

    const MeasureIncludedEmployeesFilter = {

      includePropertyKeys: ["users"]

    } as PropertiesSelector;

    const onDocumentChange = async (databaseDocument: DatabaseDocumentIF, 
      changedProperty? : DatabasePropertyIF<any> ): Promise<void> => {

      log.traceIn("onDocumentEdit");

      try {

        const measure = databaseDocument as MeasureIF;

        const showAllTargets = !measure.allUsers.value();

        log.debug("onDocumentEdit", { showAllTargets }, this.state.showAllTargets);

        if (this.state.showAllTargets !== showAllTargets) {

          this.setState({ 
            measure: measure,
            showAllTargets: showAllTargets
          });
        }

        if( changedProperty == null ) {

          log.traceIn("onDocumentChange", "no changed property");
          return;
        }

        if (MeasureTargetsFilter.includePropertyKeys!.includes( changedProperty.key() )) {

          await measure.updateUsers();
        }

      } catch (error) {
        log.warn("onDocumentEdit()", error);

        await errorDialog(error);
      }

      log.traceOut("onDocumentEdit");
    }

    let steps;

    const appContext = this.context as AppContextProps;
    
    if( this.props.inputTabs != null ) {
      steps = this.props.inputTabs;

    }
    else {
      steps = new Map<string, PropertiesSelector>();

      steps.set("basics", MeasureBasicsFilter);
      steps.set("status", MeasureStatusFilter);

      if (appContext.currentHomePath !== HomePaths.UserHomePath) {

        if (this.state.showAllTargets) {
          steps.set("targets", MeasureTargetsFilter);
        }
        else {
          steps.set("targets", MeasureNoTargetsFilter);
        }

        steps.set("forUsers", MeasureIncludedEmployeesFilter);

      }

    }

    //log.debug("render()", {steps} );
  
    return (<DocumentView
      {...this.props}
      databaseDocument={this.state.measure}
      inputTabs={steps}
      onDocumentChange={onDocumentChange}
    />);
  }
}

MeasureView.contextType = AppContext;

const ModifiedMeasureView = withTranslation()(withStyles(styles)(MeasureView));

export default ModifiedMeasureView; 





