import React from 'react';

import { withTranslation, WithTranslation } from 'react-i18next';

import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';

import { Menu, Tooltip } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';


import DashboardIcon from '@material-ui/icons/Dashboard';
import BarChartIcon from '@material-ui/icons/BarChart';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import ScatterPlotIcon from '@material-ui/icons/ScatterPlot';
import PublicIcon from '@mui/icons-material/Public';
import PieChartIcon from '@mui/icons-material/PieChart';
import ListIcon from '@mui/icons-material/List';
import AccountTreeIcon from '@mui/icons-material/AccountTree'; 
import TableChartIcon from '@mui/icons-material/TableChart';
import { AppContext } from '../app/appContext';
import { log } from '../app/app';

export const DatabaseViewTypes = {
  Dashboard: "dashboard",
  Tree: "tree",
  Map: "map",
  Graph: "graph",
  AccumulatedChart: "accumulatedChart",
  BarChart: "barChart",
  Card: "card",
  List: "list",
  Table: "table"
}
export type DatabaseViewType = keyof (typeof DatabaseViewTypes);

export const databaseViewIcon = ( params: { databaseViewType: DatabaseViewType, adjustSize? : boolean } ) => {

  const fontSize = !!params.adjustSize ? "inherit" : undefined;

  switch (params.databaseViewType) {

    case DatabaseViewTypes.Dashboard:
      return (<DashboardIcon fontSize={fontSize} />);

    case DatabaseViewTypes.Map:
      return (<PublicIcon fontSize={fontSize} />);

    case DatabaseViewTypes.Graph:
      return (<ScatterPlotIcon fontSize={fontSize} />);

    case DatabaseViewTypes.AccumulatedChart:
      return (<ShowChartIcon fontSize={fontSize} />);

    case DatabaseViewTypes.BarChart:
      return ( <BarChartIcon fontSize={fontSize} />);

    case DatabaseViewTypes.Card:
      return (<PieChartIcon fontSize={fontSize} />);

    case DatabaseViewTypes.List:
      return (<ListIcon fontSize={fontSize} />);

    case DatabaseViewTypes.Table:
      return (<TableChartIcon fontSize={fontSize} />);
  
    case DatabaseViewTypes.Tree:
      return (<AccountTreeIcon fontSize={fontSize} />);
  }
}

const styles = (theme: Theme) => createStyles({
});

 

interface DatabaseViewMenuProps extends WithStyles<typeof styles>, WithTranslation {

  useAnchor: boolean,

  databaseViewItems: DatabaseViewType[],

  currentDatabaseViewType: DatabaseViewType,

  onDatabaseViewChange? : ( databaseViewType: DatabaseViewType ) => Promise<void>,
}


interface DatabaseViewMenuState { 

  menuAnchor: null | HTMLElement,

}

class DatabaseViewMenu extends React.PureComponent<DatabaseViewMenuProps,DatabaseViewMenuState> {

  constructor( props: DatabaseViewMenuProps ) {
    
    super(props);

    this.state = { 

      menuAnchor: null

    } as DatabaseViewMenuState;

    //log.traceInOut( "constructor()" );
  }
  
  render() {

    log.traceInOut( "render()", this.props.currentDatabaseViewType, this.props.databaseViewItems );


    const selectDatabaseView = () => {

      return (
        <ToggleButtonGroup
          size="small"
          orientation='horizontal'>
          {this.props.databaseViewItems.map( databaseViewType =>
            <Tooltip title={(<>{this.props.t(databaseViewType)}</>)} key={databaseViewType}>
              <ToggleButton
                size="small"
                value={this.props.currentDatabaseViewType === databaseViewType}
                selected={this.props.currentDatabaseViewType === databaseViewType}
                onClick={() => { 
                  this.setState({ menuAnchor: null }); 
                  if( this.props.onDatabaseViewChange != null ) {

                    this.props.onDatabaseViewChange( databaseViewType );
                  }
                }} 
                >
                {databaseViewIcon({ databaseViewType: databaseViewType })}
              </ToggleButton>
            </Tooltip>
          )}
        </ToggleButtonGroup>
      );
    }

    const databaseViewMenu = () => {

      return(
        <>
        <ToggleButton 
          size="small" 
          value={this.state.menuAnchor != null}
          selected={this.state.menuAnchor != null}
          onClick={(event)=>this.setState({ menuAnchor: event.currentTarget })}> 
          {databaseViewIcon({ databaseViewType: this.props.currentDatabaseViewType })}
        </ToggleButton> 
        <Menu
          id="expanded-area-menu"
          anchorEl={this.state.menuAnchor}
          keepMounted
          open={this.state.menuAnchor != null}
          onClose={()=>this.setState({ menuAnchor: null })}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }} 
          MenuListProps={{ disablePadding : true }}
          > 
            {selectDatabaseView()}
        </Menu>
      </>
      );
    }


    return(
      <React.Fragment>
        {this.props.useAnchor ?  databaseViewMenu() : selectDatabaseView() }
      </React.Fragment>
    );
  }
}


DatabaseViewMenu.contextType = AppContext;

const ModifiedDatabaseViewMenu = withTranslation()(withStyles(styles)(DatabaseViewMenu));

export default ModifiedDatabaseViewMenu;

