import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import * as H from 'history';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

import DocumentView, { DocumentProps } from '../components/documentView'; 

import { PropertiesSelector } from 'services/database/api/core/propertiesSelector';

import DatabaseTable, { DatabaseTableProps } from 'ui/components/databaseTable';
import DatabaseList, { DatabaseListProps } from 'ui/components/databaseList';
import DatabaseCard, { DatabaseCardProps } from 'ui/components/databaseCard';
import DatabaseChart, { DatabaseChartProps } from 'ui/components/databaseChart';
import DatabaseGraph, { DatabaseGraphProps } from 'ui/components/databaseGraph';


import DatabaseView from '../components/databaseView';

import { CompanyDocument, UnitDocument, UserDocument } from '../../services/database/api/documents';
import { AppContext, AppContextProps } from '../app/appContext';
import { TestRegistrationDocument, VaccineRegistrationDocument } from '../../healthguard/api/registrationDocuments';
import { Identifications } from '../../services/database/api/definitions/identification';
import { log } from '../app/app';
import { DatabaseDocumentIF } from '../../services/database/api/core/databaseDocumentIF';
import { Factory } from '../../services/common/api/factory';

import { confirmationDialog, errorDialog } from '../components/simpleDialog';
import { TranslationKey } from '../../services/common/api/translatorIF';

import { DatabaseQuery } from '../../services/database/api/core/databaseQuery';
import { IncidentRegistrationsCollection, QuarantineRegistrationsCollection, RegistrationsCollection, SickLeaveRegistrationsCollection, SymptomRegistrationsCollection, TestRegistrationsCollection, VaccineRegistrationsCollection } from '../../healthguard/api/registrationCollections';
import { DatabasePropertyIF } from '../../services/database/api/core/databasePropertyIF';
import { ConsentsCollection, GatheringsCollection, MeasuresCollection } from '../../healthguard/api/healthguardCollections';
import { CategoriesCollection, LocationsCollection, ProjectsCollection, UnitsCollection, UsersCollection } from '../../services/database/api/collections';

import { HealthguardUserIF } from '../../healthguard/api/documents/healthguardUserIF';
import { DatabaseViewType, DatabaseViewTypes } from '../components/databaseViewMenu';
import {  DisplayTypes } from '../app/display';
import { SymbolicUsersProperty } from '../../services/database/api/core/databaseServiceIF';
import { SymbolicCollectionPropertyIF } from '../../services/database/api/properties/symbolicCollectionPropertyIF';
import { ReferenceHandle } from '../../services/database/api/core/referenceHandle';
import { DatabaseObserverIF } from '../../services/database/api/core/databaseObserverIF';
import { UnitIF } from '../../services/database/api/documents/unitIF';
import { selectDocumentDialog } from '../components/selectDocumentDialog';
import WaitDialog from '../components/waitDialog';
import { Monitor } from '../../services/common/api/monitor';
import { ObservableIF } from '../../services/common/api/observableIF';
import { Observation } from '../../services/common/api/observation';

import userInviteNotification from "healthguard/data/notifications/userInvite.json";
import { PropertyTypes } from '../../services/database/api/definitions/propertyType';

export const UserViewProfileParameter = "profile";
export const UserViewSettingsParameter = "settings";
export const UserViewAssociationsParameter = "associations";


export const defaultUserTableColumns = [
  CompanyDocument,
  "title", 
  UnitDocument,
  "symnbolicUnits",
  UserDocument,
  "categories"
];

const userTableColumns = ( appContext : AppContextProps ) => {
  
  //log.traceIn( "userTableColumns()" )

  const vaccineVisibility = appContext.currentCompany?.subcollectionIdentification( VaccineRegistrationDocument);

  const testVisibility = appContext.currentCompany?.subcollectionIdentification( TestRegistrationDocument);

  let columns = ([] as string[]).concat( defaultUserTableColumns );

  //log.debug( "userTableColumns()",{vaccineVisibility} )

  if( vaccineVisibility == null ||
      vaccineVisibility === Identifications.Identified ||
       vaccineVisibility === Identifications.Optional )  {

        columns = columns.concat([
          //"lastVaccineInstallment",
          //"lastVaccineDate" 
        ])
  }

  //log.debug( "userTableColumns()",{testVisibility} )

  if( testVisibility == null ||
      testVisibility === Identifications.Identified ||
      testVisibility === Identifications.Optional )  {

        columns = columns.concat([
        //"lastTestResult",
        //"lastTestDate"
      ])
  }


  //log.traceOut( "userTableColumns()",{columns} )

  return columns;
}


export const defaultHighlightedUsersProperty = "lastVaccineInstallment";

export const privacySensitiveHighlightedUsersProperty = "locations";

const highlightedUsersProperty = ( appContext : AppContextProps ) => {
  
  log.traceIn( "highlightedUsersProperty()" )

  let property = privacySensitiveHighlightedUsersProperty;

  const vaccineVisibility = appContext.currentCompany?.subcollectionIdentification( VaccineRegistrationDocument);

  log.debug( "highlightedUsersProperty()",{vaccineVisibility} )

  if( vaccineVisibility == null ||
      vaccineVisibility === Identifications.Identified ||
       vaccineVisibility === Identifications.Optional )  {

        property = defaultHighlightedUsersProperty; 
  }

  return property;
}

const inviteUser = async ( 
  appContext : AppContextProps, 
  t : TFunction, 
  databaseDocument : DatabaseDocumentIF ) => {

  log.traceIn("inviteUser()", databaseDocument.title.value() );

  try {
    const user = databaseDocument as HealthguardUserIF;

    let confirmationPrompt = t( TranslationKey.Prompts + ":sendInvite" );

    confirmationPrompt += " " + user.title.value() + "?"

    const confirmation = await confirmationDialog(confirmationPrompt);

    if (!confirmation) {
      log.traceOut("inviteUser()", "Cancel");
      return;
    }

    const unit = await user.unit.document();

    const company = await user.company.document();

    await Factory.get().messagingService.notificationSender.createInviteNotification( {
      notification: userInviteNotification,
      sender: appContext.currentUser!,
      receiver: user,
      unit: unit,
      company: company
    });

    log.traceOut("inviteUser()");

  } catch (error) {
      log.warn("inviteUser()", "Error sending invite", error);

      await errorDialog( error );

  }
}

const addUser = async (  props: {
  appContext : AppContextProps, 
  t : TFunction, 
  history : H.History<unknown>,
  databaseObserver : DatabaseObserverIF<DatabaseDocumentIF>,
  onUpdateWait: ( wait : boolean ) => void } ) => {

  log.traceIn("addUser()");
  try {

    if( props.appContext.currentCompany != null ) {

      const availableLicenses = await props.appContext.currentCompany.availableLicenses(); 

      if( availableLicenses === 0 ) {
        throw new Error( "noMoreLicenses");
      }
    }

    let path = props.appContext.currentHomePath! as string;

    let newDocument = props.databaseObserver.databases()![0].newDocument();

    const parentDocument = await newDocument.ownerDocument();

    const symbolicUsersProperty = 
      parentDocument?.property( SymbolicUsersProperty ) as SymbolicCollectionPropertyIF<HealthguardUserIF>;

    if ( symbolicUsersProperty != null ) {

      const handles = new Map<string,ReferenceHandle<HealthguardUserIF>>();

      const selectHandles = await symbolicUsersProperty.select( true ) as Map<string,ReferenceHandle<HealthguardUserIF>>;

      const databaseDocuments = props.databaseObserver.documents();

      for( const selectHandle of selectHandles.values() ) {

        if( databaseDocuments.has( selectHandle.path ) ) {
          continue;
        }

        handles.set( selectHandle.path, selectHandle );

      }

      const newDocumentHandle = newDocument.referenceHandle();

      const selectedUserHandle = await selectDocumentDialog( {

        placeholder: props.t( TranslationKey.Prompts + ":associateUser"),
        
        collectionName: UsersCollection,

        handles: handles,

        newDocumentHandle: newDocumentHandle,

        hideEmptyFilterOptions: props.appContext.currentCompany?.restrictSymbolicOwners.value() 

      }) as ReferenceHandle<HealthguardUserIF>;

      if( selectedUserHandle == null ) {

        log.traceOut("addUser()", "no user selected");
        return;
      }
      else if( selectedUserHandle.path !== newDocumentHandle.path ) {

        log.debug("addUser()", "existing document");

        let confirmationPrompt = props.t( TranslationKey.Prompts + ":confirmAssociateUser" );

        confirmationPrompt += " " + selectedUserHandle.title + "?"
    
        if( !(await confirmationDialog(confirmationPrompt) ) ) {
            log.traceOut("addUser()", "Cancel");
            return;
        }

        props.onUpdateWait( true );

        switch( symbolicUsersProperty.parentDocument().collectionDatabase.collectionName()) 
        {
          case UnitsCollection:
          {
            const unit = symbolicUsersProperty.parentDocument() as UnitIF;

            await unit.attachUser( selectedUserHandle );

            break;
          }

          case UsersCollection:
          {
            const user = symbolicUsersProperty.parentDocument() as HealthguardUserIF;

            await user.attachChild( selectedUserHandle );
            break;
          }
          
          default: 
          {
            throw new Error( "invalidPath" );
          }
        }

        await props.databaseObserver.subscribe({ 
          observer: selectedUserHandle,
          onNotify: async (observable : ObservableIF, observation : Observation, objectId? : string , object? : any ) => {

            props.onUpdateWait( false );
            
            await props.databaseObserver.unsubscribe( selectedUserHandle );
          }
        } as Monitor );
  
        log.traceOut("addUser()", "Added selected user", selectedUserHandle.title);
        return;
      }
      else {

        log.debug("addUser()", "new document from select document dialog");
  
        path += newDocument.databasePath(true);
      }
    }
    else {

      log.debug("addUser()", "add document");

      path += newDocument.databasePath(true);
    }


    props.history.push(path);

    log.traceOut("addUser()", path);
    
  } catch( error ) {
    log.warn( "addUser()", error );

    props.onUpdateWait( false );

    await errorDialog( error);
  }
}

export function UsersTable(props: DatabaseTableProps ) {

  const [waitDialogOpen, setWaitDialogOpen] = React.useState( false );

  const appContext = useContext(AppContext);

  const { t } = useTranslation();

  const history = useHistory();

  return (
    <>
      <DatabaseTable
        {...props}
        featuredProperties={props.featuredProperties != null ? props.featuredProperties : userTableColumns(appContext)}
        actions={[
          { title: t("invite"), onAction: (databaseDocument) => inviteUser(appContext, t, databaseDocument) }
        ]}
        onAddDocument={( databaseObserver ) => addUser( {
          appContext: appContext,
          t: t,
          history: history,
          databaseObserver: databaseObserver,
          onUpdateWait: setWaitDialogOpen
        })}
      />
      <WaitDialog open={waitDialogOpen} onCancel={() => setWaitDialogOpen( false )} cancelText={"dismiss"}/> 
    </> 
  );
}

export function UsersList(props: DatabaseListProps ) {

  const [waitDialogOpen, setWaitDialogOpen] = React.useState( false );

  const appContext = useContext(AppContext);

  const { t } = useTranslation();

  const history = useHistory();

  return (
    <>
      <DatabaseList
        {...props}
        onAddDocument={(databaseObserver) => addUser({
          appContext: appContext,
          t: t,
          history: history,
          databaseObserver: databaseObserver,
          onUpdateWait: setWaitDialogOpen
        })}
      />
      <WaitDialog open={waitDialogOpen} onCancel={() => setWaitDialogOpen( false )} cancelText={"dismiss"}/> 
    </>
  );
}

export function UsersCard( props: DatabaseCardProps ) {

  const [waitDialogOpen, setWaitDialogOpen] = React.useState( false );

  const appContext = useContext(AppContext);

  const { t } = useTranslation();

  const history = useHistory();

  return (
    <>
      <DatabaseCard 
        {...props} 
        highlightedPropertyKey={props.highlightedPropertyKey != null ? props.highlightedPropertyKey : highlightedUsersProperty( appContext ) }
        onAddDocument={( databaseObserver ) => addUser( {
          appContext: appContext,
          t: t,
          history: history,
          databaseObserver: databaseObserver,
          onUpdateWait: setWaitDialogOpen
        })}
      />
      <WaitDialog open={waitDialogOpen} onCancel={() => setWaitDialogOpen( false )} cancelText={"dismiss"}/> 
    </>
  );
} 

export function UsersChart( props: DatabaseChartProps ) {

  const [waitDialogOpen, setWaitDialogOpen] = React.useState( false );

  const appContext = useContext(AppContext); 

  const { t } = useTranslation();

  const history = useHistory();

  return (
    <>
      <DatabaseChart
        {...props}
        highlightedPropertyKey={props.highlightedPropertyKey != null ? props.highlightedPropertyKey : highlightedUsersProperty(appContext)}
        onAddDocument={(databaseObserver) => addUser({
          appContext: appContext,
          t: t,
          history: history,
          databaseObserver: databaseObserver,
          onUpdateWait: setWaitDialogOpen
        })}
      />
      <WaitDialog open={waitDialogOpen} onCancel={() => setWaitDialogOpen(false)} cancelText={"dismiss"} />
    </>
  );
}


export function UsersGraph(props: DatabaseGraphProps) {

  const [waitDialogOpen, setWaitDialogOpen] = React.useState( false );

  const appContext = useContext(AppContext);

  const { t } = useTranslation();

  const history = useHistory();

  return (
    <>
      <DatabaseGraph
        {...props}
        previousPropertyKey={"company"}
        directed={false}
        onAddDocument={(databaseObserver) => addUser({
          appContext: appContext,
          t: t,
          history: history,
          databaseObserver: databaseObserver,
          onUpdateWait: setWaitDialogOpen
        })}
      />
      <WaitDialog open={waitDialogOpen} onCancel={() => setWaitDialogOpen(false)} cancelText={"dismiss"} />
    </>
  );
}



export function UsersView(props: { 
  databaseQuery : DatabaseQuery<HealthguardUserIF>,
  title? : string,
  featuredProperties?: string[],
  highlightedPropertyKey?: string }) {

  const [waitDialogOpen, setWaitDialogOpen] = React.useState( false );

  const appContext = useContext(AppContext);

  const { t } = useTranslation();

  const history = useHistory();

  return (
    <>
      <DatabaseView
        databaseQuery={props.databaseQuery}
        title={props.title}
        featuredProperties={props.featuredProperties != null ? props.featuredProperties : userTableColumns(appContext)}
        highlightedPropertyKey={props.highlightedPropertyKey != null ? props.highlightedPropertyKey : highlightedUsersProperty(appContext)}
        hideDateFilter={true}
        defaultDatabaseViewType={DatabaseViewTypes.Table as DatabaseViewType}
        onAddDocument={( databaseObserver ) => addUser( {
          appContext: appContext,
          t: t,
          history: history,
          databaseObserver: databaseObserver,
          onUpdateWait: setWaitDialogOpen
        })}
      />
      <WaitDialog open={waitDialogOpen} onCancel={() => setWaitDialogOpen( false )} cancelText={"dismiss"}/> 
    </>
  );
}

const UserBasicsFilter = {

  includePropertyKeys: [
    "firstName",
    "lastName",
    "dateOfBirth",
    "email",
    "phoneNumber",
    "sex",
    "language",
    "country"
  ]

} as PropertiesSelector;


const UserAssociationsFilter = {

  includePropertyKeys: [
    UnitDocument,
    "allowedSymbolicUnits", 
    "symbolicUnits",
    CategoriesCollection,
    LocationsCollection,
    ProjectsCollection,
    GatheringsCollection
  ]

} as PropertiesSelector;

const UserRolesFilter = {

  includePropertyKeys: [
    "employeeId",
    "managing",
    "assisting",
    "deputing",
    "projectManaging",
    "responsibleFor",
    "medicalCertification"
  ]

} as PropertiesSelector;


const UserFamilyFilter = {

  includePropertyKeys: [
    "user",
    "symbolicUser",
    UsersCollection
  ]

} as PropertiesSelector;

const UserMeasuresFilter = {

  includePropertyKeys: [
    MeasuresCollection
  ]

} as PropertiesSelector;

const UserGatheringsFilter = {

  includePropertyKeys: [
    GatheringsCollection
  ]

} as PropertiesSelector;

const UserConsentsFilter = {

  includePropertyKeys: [
    ConsentsCollection
  ]

} as PropertiesSelector;


export function UserView( props : DocumentProps ) {

  const [user,setUser] = React.useState<HealthguardUserIF>();

  React.useEffect( () => { 

    setUser( props.databaseDocument as HealthguardUserIF);

  }, [props.databaseDocument]); 

  const onDocumentChange = async (databaseDocument: DatabaseDocumentIF, 
    changedProperty? : DatabasePropertyIF<any> ): Promise<void> => {

    log.traceIn("onDocumentChange", changedProperty?.key() );
  
    try {
  
      const updatedUser = databaseDocument as HealthguardUserIF;
  
      if( changedProperty == null ) {
        log.traceOut("onDocumentChange", "no changed property");
        return;
      }

      if( updatedUser.id.value() != null && (
            changedProperty.type === PropertyTypes.Tenant ||
            changedProperty.type === PropertyTypes.Owner )) {

        const confirmation = await confirmationDialog( "moveDocument");

        if( !confirmation ) {
  
          changedProperty.undoLastChange();
  
          log.traceOut( "updateExistingDocumentOwner()", "Cancel changed unit" );
        }
      }
      else {
        await updatedUser.generateCategories();

        await updatedUser.updateMeasures();
      }

      setUser( updatedUser );
  
    } catch (error) {
      log.warn("onDocumentEdit()", error);
  
      await errorDialog(error);
    }
  
    log.traceOut("onDocumentEdit");
  }

  if( user == null ) {
    return null;
  }

  const view = props.view;
  
  if( view === UserViewProfileParameter ) {
    return (<UserProfileView {...props} databaseDocument={user} onDocumentChange={onDocumentChange}/>);
  }

  if( view === UserViewSettingsParameter ) {
    return (<UserSettingsView {...props} databaseDocument={user} onDocumentChange={onDocumentChange}/>);
  }

  if( view === UserViewAssociationsParameter ) {
    return (<UserAssociationsView {...props} databaseDocument={user} onDocumentChange={onDocumentChange}/>);
  }  

  return (<UserDefaultView {...props} databaseDocument={user} onDocumentChange={onDocumentChange}/>);

}

export function UserDefaultView( props : DocumentProps ) {

  const appContext = useContext(AppContext);

  const { t } = useTranslation();

  const defaultUserSteps = (): Map<string, PropertiesSelector> => { 

    let defaultSteps = new Map<string, PropertiesSelector>();

    defaultSteps.set("basics", UserBasicsFilter);

    const user = props.databaseDocument as HealthguardUserIF;

    if( user?.databasePath() !== appContext.currentUser?.databasePath() &&
        !!appContext.currentCompany?.enableParents.value() && 
        user?.user.value() == null ) {

      defaultSteps.set("family", UserFamilyFilter);
    }
      
    defaultSteps.set("associations", UserAssociationsFilter); 
    defaultSteps.set("roles", UserRolesFilter); 

    const userRegistrationsFilter = {

      includePropertyKeys: []
    
    } as PropertiesSelector;

    if (appContext.currentCompany != null) {

      if (!appContext.currentCompany!.incidentRegistrationsPrivacy.subdocument().disabled.value() &&
        !!appContext.currentCompany!.incidentRegistrationsPrivacy.subdocument().userAccess().allowRead &&
        appContext.currentCompany!.incidentRegistrationsPrivacy.subdocument().identification.value() !== Identifications.Anonymous) {

        userRegistrationsFilter.includePropertyKeys!.push(IncidentRegistrationsCollection);
      }

      if (!appContext.currentCompany!.sickLeaveRegistrationsPrivacy.subdocument().disabled.value() &&
        !!appContext.currentCompany!.sickLeaveRegistrationsPrivacy.subdocument().userAccess().allowRead &&
        appContext.currentCompany!.sickLeaveRegistrationsPrivacy.subdocument().identification.value() !== Identifications.Anonymous) {

        userRegistrationsFilter.includePropertyKeys!.push(SickLeaveRegistrationsCollection);
      }

      if (!appContext.currentCompany!.vaccineRegistrationsPrivacy.subdocument().disabled.value() &&
        !!appContext.currentCompany!.vaccineRegistrationsPrivacy.subdocument().userAccess().allowRead &&
        appContext.currentCompany!.vaccineRegistrationsPrivacy.subdocument().identification.value() !== Identifications.Anonymous) {

        userRegistrationsFilter.includePropertyKeys!.push(VaccineRegistrationsCollection);
      }

      if (!appContext.currentCompany!.testRegistrationsPrivacy.subdocument().disabled.value() &&
        !!appContext.currentCompany!.testRegistrationsPrivacy.subdocument().userAccess().allowRead &&
        appContext.currentCompany!.testRegistrationsPrivacy.subdocument().identification.value() !== Identifications.Anonymous) {

        userRegistrationsFilter.includePropertyKeys!.push(TestRegistrationsCollection);
      }

      if (!appContext.currentCompany!.symptomRegistrationsPrivacy.subdocument().disabled.value() &&
        !!appContext.currentCompany!.symptomRegistrationsPrivacy.subdocument().userAccess().allowRead &&
        appContext.currentCompany!.symptomRegistrationsPrivacy.subdocument().identification.value() !== Identifications.Anonymous) {

        userRegistrationsFilter.includePropertyKeys!.push(SymptomRegistrationsCollection);
      }

      if (!appContext.currentCompany!.quarantineRegistrationsPrivacy.subdocument().disabled.value() &&
        !!appContext.currentCompany!.quarantineRegistrationsPrivacy.subdocument().userAccess().allowRead &&
        appContext.currentCompany!.quarantineRegistrationsPrivacy.subdocument().identification.value() !== Identifications.Anonymous) {

        userRegistrationsFilter.includePropertyKeys!.push(QuarantineRegistrationsCollection);
      }
    }
    
    defaultSteps.set(MeasuresCollection, UserMeasuresFilter);

    if( !!user?.consents.userAccess().allowRead ) {
      defaultSteps.set(ConsentsCollection, UserConsentsFilter);
    }

    if( !!user?.registrations.userAccess().allowRead ) {
      defaultSteps.set(RegistrationsCollection, userRegistrationsFilter);
    }

    return defaultSteps; 
  }

  let actions;

  const user = props.databaseDocument as HealthguardUserIF;

  if( user != null && appContext.currentDisplay?.displayType !== DisplayTypes.Mobile ) {

    actions = [{ 
      title: t("invite"), 
  
      onAction: () => inviteUser( appContext, t, user! ),
  
      disabled: user.id.value() == null
     }]
  }


  return (<DocumentView 
    {...props}
    databaseDocument={user}
    inputTabs={defaultUserSteps()}
    onDocumentChange={props.onDocumentChange}
    actions={actions} 
    />);

}


export function UserProfileView( props : DocumentProps ) {

  const userProfileSteps = (): Map<string, PropertiesSelector> => {

    let profileSteps = new Map<string, PropertiesSelector>();
  
    profileSteps.set("basics", UserBasicsFilter);

    return profileSteps;
  }
  
  return ( 
    <DocumentView 
      {...props}
      inputTabs={userProfileSteps()} 
      onDocumentChange={props.onDocumentChange}
    /> );
}

const UserSettingsFilter = {

  includePropertyKeys: [
    "databaseContactability",
    "communicationContactability",
    "securityContactability",
    "serviceContactability"
]
} as PropertiesSelector;


export function UserSettingsView( props : DocumentProps ) {

  const userSettingsSteps = (): Map<string, PropertiesSelector> => {

    let settingsSteps = new Map<string, PropertiesSelector>();
  
    settingsSteps.set("settings", UserSettingsFilter);

    return settingsSteps;
  }
  
  return ( 
    <DocumentView 
      {...props}
      inputTabs={userSettingsSteps()} 
      onDocumentChange={props.onDocumentChange}
      /> 
    );
}

const UserOrganizationFilter = {

  includePropertyKeys: [
    UnitDocument,
    "symbolicUnits",
    "managing",
    CategoriesCollection,
    LocationsCollection,
    "responsibleFor"
  ]

} as PropertiesSelector;


const UserProjectsFilter = {

  includePropertyKeys: [
    "projects"
  ]

} as PropertiesSelector;

export function UserAssociationsView( props : DocumentProps) {

  const userProfileSteps = (): Map<string, PropertiesSelector> => {

    let profileSteps = new Map<string, PropertiesSelector>();
  
    profileSteps.set("organization", UserOrganizationFilter);
    profileSteps.set("myProjects", UserProjectsFilter);
    profileSteps.set("myGatherings", UserGatheringsFilter); 

    return profileSteps;
  }
  
  return (
    <DocumentView 
      {...props} 
      inputTabs={userProfileSteps()}
      onDocumentChange={props.onDocumentChange} 
    /> );
}


