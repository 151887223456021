
import { MediaType, MediaTypes } from "../../../storage/api/mediaType";
import { PropertyType, PropertyTypes } from "../../api/definitions/propertyType"; 
import { ImagePropertyIF } from "../../api/properties/imagePropertyIF";
import { DatabaseObject } from "../../framework/databaseObject";
import { AttachmentProperty } from "./attachmentProperty";

export class ImageProperty extends AttachmentProperty implements ImagePropertyIF{

    constructor( parent : DatabaseObject ) {

        super( PropertyTypes.Image as PropertyType, parent, MediaTypes.Image as MediaType );
    }

}