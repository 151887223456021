
export const DeviceTypes = {

    AirFiltration :   "airFiltration",

    AirDetector :   "airDetector",

    WastewaterDetector : "wastewaterDetector",

    Breathalyzer :   "breathalyzer",

    TestReader :   "testReader",

    BodyTemperatureReader : "bodyTemperatureReader"
}

export type DeviceType = keyof (typeof DeviceTypes);


