

import { DeviceIF } from "../../api/documents/deviceIF";
import { DatabaseDocument } from "../../framework/databaseDocument";
import { CollectionDatabase } from "../core/collectionDatabase";
import { log } from "../../framework/databaseService";
import { Company } from "./company";
import { Location } from "./location";
import { TenantProperty } from "../properties/tenantProperty";
import { CompaniesCollection, LocationsCollection, UnitsCollection } from "../../api/collections";
import { CompanyDocument, DeviceDocument, UnitDocument } from "../../api/documents";
import { OwnerProperty } from "../properties/ownerProperty";
import { Unit } from "./unit";
import { ReferenceProperty } from "../properties/referenceProperty";
import { TextProperty } from "../properties/textProperty";
import { OptionsSource } from "../core/optionsSource";
import { TextsProperty } from "../properties/textsProperty";
import { SubdocumentProperty } from "../properties/subdocumentProperty";
import { Connection } from "../subdocuments/connection";
import { GeolocationProperty } from "../properties/geolocationProperty";
import { DefaultTextType } from "../../api/definitions/textTypes";
import { Alert } from "./alert";
import { ReferencesProperty } from "../properties/referencesProperty";
import { Database } from "../core/database";


export class Device extends DatabaseDocument implements DeviceIF {

    constructor( deviceDocumentName : string, categoriesCollection : CollectionDatabase<Device>, documentPath? : string  ) {

        super( deviceDocumentName, categoriesCollection, documentPath ); 

        try {
            this.title.required = true;

            this.company = new TenantProperty<Company>( this, CompaniesCollection, CompanyDocument );
            this.company.required = true;
            this.company.editable = false;

            const company = this.company.emptyDocument();

            this.unit = new OwnerProperty<Unit>( this, UnitsCollection, UnitDocument );
            
            this.location = new ReferenceProperty<Location>( this, 
                () => this.parentDatabases( LocationsCollection, {nearestIsCollectionGroup: false} ) as Database<Location>[],
                "devices" );
            this.location.required = true;

            this.premise = new TextProperty( this )
            this.premise.setOptionsSource( new OptionsSource<Location,TextsProperty>( this.location, "premises" )  );

            this.geolocation = new GeolocationProperty( this ); 

            this.geolocation.setTitleProperty( this.title );

            this.connection = new SubdocumentProperty<Connection>( this, new Connection( this, "connection") );

            this.status = new TextProperty( this, DefaultTextType, true );  

            this.alerts = new ReferencesProperty<Alert>(this,
                company != null ?
                    () => [company.alerts.collectionGroup()] :
                    undefined,
                "device");


        } catch( error ) {

            log.warn( "constructor()", "Error initializing Device", error );
            
            throw new Error( (error as any).message );
        }
    }

    indexKey() : string {
        return DeviceDocument;
    }


    readonly company: TenantProperty<Company>;

    readonly unit: OwnerProperty<Unit>;

    readonly location : ReferenceProperty<Location>;

    readonly premise : TextProperty;

    readonly geolocation : GeolocationProperty;

    readonly connection : SubdocumentProperty<Connection>;

    readonly status : TextProperty;

    readonly alerts: ReferencesProperty<Alert>;

}
