
import { log } from "../../../services/database/framework/databaseService";
import { HealthguardUser } from "../documents/healthguardUser";

import { HealthguardCompany } from "../documents/healthguardCompany";
import { ReferenceHandle } from "../../../services/database/api/core/referenceHandle";
import { Measure } from "../documents/measure";

export class HealthguardUserManager {

    static getInstance() : HealthguardUserManager {
        if( HealthguardUserManager._instance == null ) {
            HealthguardUserManager._instance = new HealthguardUserManager();
        }
        return HealthguardUserManager._instance;
    }


    async handleCreateHealthguardUser( createdUser: HealthguardUser ) {

        try {
            log.traceIn("handleCreateHealthguardUser()", createdUser.databasePath() );

            log.traceOut("handleCreateHealthguardUser()");

        } catch (error) {
            log.warn("Error handling document create", error);

            log.traceOut("handleCreateHealthguardUser()", "error");
        }
    }


    async handleUpdateHealthguardUser( updatedUser: HealthguardUser ) {

        try {
            log.traceIn("handleUpdateHealthguardUser()", updatedUser.databasePath() );

            log.traceOut("handleUpdateHealthguardUser()");

        } catch (error) {
            log.warn("Error handling document update", error);

            log.traceOut("handleUpdateHealthguardUser()", "error");
        }
    } 

    async handleDeleteHealthguardUser( deletedUser: HealthguardUser ) {

        try {
            log.traceIn("handleDeleteHealthguardUser()", deletedUser.databasePath() );

            log.traceOut("handleDeleteHealthguardUser()");

        } catch (error) {
            log.warn("Error handling document delete", error);

            log.traceOut("handleDeleteHealthguardUser()", "error");
        }
    }

    async updateMeasures( user: HealthguardUser, newUser? : boolean ) { 

        try {
            log.traceIn("updateMeasures()", user.databasePath() );

            if( !newUser &&
                !user.unit.isChanged() &&
                !user.locations.isChanged() &&
                !user.categories.isChanged() && 
                !user.gatherings.isChanged() && 
                !user.projects.isChanged() ) {

                log.traceOut("updateMeasures()", "no relevant change" );
                return;
            }
            
            const company = user.company.emptyDocument() as HealthguardCompany;

            if( company == null ) {
                log.traceOut("updateMeasures()", "no company" );
                return;
            }

            const measures = await company.measures.database().documents(); 

            for( const measure of measures.values() ) {

                let hasMeasure = false;

                if( !!measure.allUsers.value() ) {

                    user.measures.setDocument( measure.referenceHandle() as ReferenceHandle<Measure> as ReferenceHandle<Measure> );
                    log.debug("updateMeasures()", "added for all users", measure.title.value() );
                    hasMeasure = true;
                }

                if( !hasMeasure ) {
                    const unit = user.unit.value();

                    if( unit != null && measure.units.referenceHandles().has( unit.path ) ) {
                        user.measures.setDocument( measure.referenceHandle() as ReferenceHandle<Measure> as ReferenceHandle<Measure> );
                        log.debug("updateMeasures()", "added from unit", unit.title, measure.title.value() );
                        hasMeasure = true;
                        continue;
                    }
                }

                if( !hasMeasure ) {

                    for( const location of user.locations.referenceHandles().values() ) { 
                        
                        if( measure.locations.referenceHandles().has( location.path ) ) {
                            user.measures.setDocument( measure.referenceHandle() as ReferenceHandle<Measure> as ReferenceHandle<Measure> );
                            log.debug("updateMeasures()", "added from location", location.title, measure.title.value() );
                            hasMeasure = true;
                            continue;
                        }
                    }
                }

                if( !hasMeasure ) {

                    for( const category of user.categories.referenceHandles().values() ) {
                        
                        if( measure.categories.referenceHandles().has( category.path ) ) {
                            user.measures.setDocument( measure.referenceHandle() as ReferenceHandle<Measure> as ReferenceHandle<Measure> );
                            log.debug("updateMeasures()", "added from category", category.title, measure.title.value() );
                            hasMeasure = true;
                            continue;
                        }
                    }
                }

                if( !hasMeasure ) {

                    for( const project of user.projects.referenceHandles().values() ) {
                        
                        if( measure.projects.referenceHandles().has( project.path ) ) {
                            user.measures.setDocument( measure.referenceHandle() as ReferenceHandle<Measure> as ReferenceHandle<Measure> );
                            log.debug("updateMeasures()", "added from project", project.title, measure.title.value() );
                            hasMeasure = true;
                            continue;
                        }
                    }
                }

                if( !hasMeasure ) {
                    user.measures.removeDocument( measure.referenceHandle().path );
                }
            }

            log.traceOut("updateMeasures()");

        } catch (error) {
            log.warn("Error handling measure activity statuses", error);

            log.traceOut("updateMeasures()", "error");
        }
    } 

    private static _instance? : HealthguardUserManager; 

}

