


export const Definition = {

    UserAccessType : "userAccessType",

    BillingMethod : "billingMethod",

    CategoryType     : "categoryType",

    ChangeType      : "changeType",

    Collection      : "collection",

    Comparator      : "comparator",

    ConnectionMethod : "connectionMethod",

    ConnectionStatus : "connectionStatus",

    Currency        : "currency",

    Document        : "document",

    Identification         : "identification",

    Language        : "language",

    KeyFormat : "keyFormat",

    KeyVault : "keyVault",

    KeyStatus : "keyStatus",

    KeyType : "keyType",

    LocationType    : "locationType",

    MapType    : "mapType",

    MessageCategory   : "messageCategory",

    NotificationStatus  : "notificationStatus",

    NotificationType : "notificationType",

    PaymentProvider : "paymentProvider",

    Period          : "period",

    ReadinessLevel   : "readinessLevel",    

    Probability: "probability",

    ResidenceType   : "residenceType",

    Severity: "severity",

    Sex             : "sex",

    SortOrientation      : "sortOrientation",

    Subdocument        : "subdocument",

    SubscriptionStatus : "subscriptionStatus",

    TerminalPlatform  : "terminalPlatform",

    TextType        : "textType",
    
    TimeSpan        : "timeSpan"

}

export type Definition = keyof (typeof Definition);

export const Definitions = Object.values( Definition );

export const BillingMethodDefinition = Definition.BillingMethod as Definition;

export const CategoryTypeDefinition = Definition.CategoryType as Definition;

export const ChangeTypeDefinition = Definition.ChangeType as Definition;

export const CollectionDefinition = Definition.Collection as Definition;

export const ComparatorDefinition = Definition.Comparator as Definition;

export const ConnectionMethodDefinition = Definition.ConnectionMethod as Definition;

export const ConnectionStatusDefinition = Definition.ConnectionStatus as Definition;

export const CurrencyDefinition = Definition.Currency as Definition;

export const DocumentDefinition = Definition.Document as Definition;

export const IdentificationDefinition = Definition.Identification as Definition;

export const KeyFormatDefinition = Definition.KeyFormat as Definition;

export const KeyVaultDefinition = Definition.KeyVault as Definition;

export const KeyStatusDefinition = Definition.KeyStatus as Definition;

export const KeyTypeDefinition = Definition.KeyType as Definition;

export const LanguageDefinition = Definition.Language as Definition;

export const LocationTypeDefinition = Definition.LocationType as Definition;

export const MapTypeDefinition = Definition.MapType as Definition;

export const MessageCategoryDefinition = Definition.MessageCategory as Definition;

export const NotificationStatusDefinition = Definition.NotificationStatus as Definition; 

export const NotificationTypeDefinition = Definition.NotificationType as Definition;

export const PaymentProviderDefinition = Definition.PaymentProvider as Definition;

export const PeriodDefinition = Definition.Period as Definition;

export const ProbabilityDefinition = Definition.Probability as Definition;

export const ResidenceTypeDefinition = Definition.ResidenceType as Definition;

export const ReadinessLevelDefinition = Definition.ReadinessLevel as Definition;  

export const SeverityDefinition = Definition.Severity as Definition;

export const SexDefinition = Definition.Sex as Definition;

export const SortOrientationDefinition = Definition.SortOrientation as Definition;

export const SubdocumentDefinition = Definition.Subdocument as Definition;

export const SubscriptionStatusDefinition = Definition.SubscriptionStatus as Definition;

export const TerminalPlatformDefinition = Definition.TerminalPlatform as Definition;

export const TextTypeDefinition = Definition.TextType as Definition;

export const TimeSpanDefinition = Definition.TimeSpan as Definition;

export const UserAccessTypeDefinition = Definition.UserAccessType as Definition; 


