
import { HealthguardUnitIF } from "../../api/documents/healthguardUnitIF";
import { Unit } from "../../../services/database/impl/documents/unit";
import { log } from "../../../services/database/framework/databaseService";
import { AlertsCollection } from "../../../services/database/api/collections";
import { CollectionDatabase } from "../../../services/database/impl/core/collectionDatabase";
import { CollectionProperty } from "../../../services/database/impl/properties/collectionProperty";
import { IncidentRegistrationsCollection, QuarantineRegistrationsCollection, RegistrationsCollection, SickLeaveRegistrationsCollection, SymptomRegistrationsCollection, TestRegistrationsCollection, VaccineRegistrationsCollection } from "../../api/registrationCollections";
import { MeasuresCollection, GatheringsCollection, ConsentsCollection, RisksCollection } from "../../api/healthguardCollections";
import { IncidentRegistration } from "./incidentRegistration";
import { QuarantineRegistration } from "./quarantineRegistration";
import { SickLeaveRegistration } from "./sickLeaveRegistration";
import { SymptomRegistration } from "./symptomRegistration";
import { TestRegistration } from "./testRegistration";
import { VaccineRegistration } from "./vaccineRegistration";
import { HealthguardUnitDocument } from "../../api/healthguardDocuments";
import { Measure } from "./measure";
import { Gathering } from "./gathering";
import { Consent } from "./consent";
import { Risk } from "./risk";
import { Alert } from "../../../services/database/impl/documents/alert";
import { Registration } from "./registration";

export class HealthguardUnit extends Unit implements HealthguardUnitIF {

    constructor( unitsCollection : CollectionDatabase<HealthguardUnit>, documentPath? : string ) {

        super( HealthguardUnitDocument, unitsCollection, documentPath ); 

        try {
            this.risks = new CollectionProperty<Risk>( this, RisksCollection, true );

            this.measures = new CollectionProperty<Measure>( this, MeasuresCollection, true );

            this.consents = new CollectionProperty<Consent>( this, ConsentsCollection, true );

            this.gatherings = new CollectionProperty<Gathering>( this, GatheringsCollection, true ); 

            this.alerts = new CollectionProperty<Alert>( this, AlertsCollection, true );

            this.registrations = new CollectionProperty<Registration>( this, RegistrationsCollection, true );

            this.incidentRegistrations = 
                new CollectionProperty<IncidentRegistration>( this, IncidentRegistrationsCollection, true );

            this.quarantineRegistrations = new CollectionProperty<QuarantineRegistration>( this, QuarantineRegistrationsCollection, true );

            this.sickLeaveRegistrations = new CollectionProperty<SickLeaveRegistration>( this, SickLeaveRegistrationsCollection, true );

            this.symptomRegistrations = new CollectionProperty<SymptomRegistration>( this, SymptomRegistrationsCollection, true );

            this.testRegistrations = new CollectionProperty<TestRegistration>( this, TestRegistrationsCollection, true );

            this.vaccineRegistrations = new CollectionProperty<VaccineRegistration>( this, VaccineRegistrationsCollection, true );

            //log.traceInOut( "constructor()", UnitsCollection );

        } catch( error ) {

            log.warn( "constructor()", "Error initializing unit", error );
            
            throw new Error( (error as any).message );
        }
    }

    readonly risks : CollectionProperty<Risk>;

    readonly measures: CollectionProperty<Measure>;

    readonly consents: CollectionProperty<Consent>;

    readonly gatherings: CollectionProperty<Gathering>;

    readonly alerts: CollectionProperty<Alert>;

    readonly registrations: CollectionProperty<Registration>; 

    readonly incidentRegistrations: CollectionProperty<IncidentRegistration>;

    readonly quarantineRegistrations: CollectionProperty<QuarantineRegistration>;

    readonly sickLeaveRegistrations: CollectionProperty<SickLeaveRegistration>;

    readonly symptomRegistrations: CollectionProperty<SymptomRegistration>;

    readonly testRegistrations: CollectionProperty<TestRegistration>;

    readonly vaccineRegistrations: CollectionProperty<VaccineRegistration>;

}


