
export const Comparators = {

    Includes: "includes",

    IncludesAny: "includesAny",

    Equal : "equal",

    NotEqual : "notEqual",

    LessThan : "lessThan",

    LessThanOrEqual : "lessThanOrEqual",

    GreaterThan: "greaterThan",

    GreaterThanOrEqual: "greaterThanOrEqual",

    NotIncludes: "notIncludes",

    Exists: "exists",

    NotExists: 'notExists'

}

export type Comparator = keyof (typeof Comparators);  

export const DefaultComparator = Comparators.Includes as Comparator;
