
export const QuarantineReasons = {

    Test                         : "test",

    Symptoms                     : "symptoms",

    Tracing                      : "tracing",

    Traveling                    : "traveling",

    Order                        : "order"

}

export type QuarantineReason = keyof (typeof QuarantineReasons);


