
export const TestResults = {

    Positive :          "positive",

    Negative :          "negative",

    Ambigous :          "ambigous",

    Pending :           "pending"
}

export type TestResult = keyof (typeof TestResults);


