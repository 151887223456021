import { log } from "../../framework/databaseService";
import { DatabaseObject } from "../../framework/databaseObject";
import { DatabaseProperty } from "../../framework/databaseProperty";
import { DatabaseDocument } from "../../framework/databaseDocument";
import { OptionsSource } from "../core/optionsSource";
import { PropertyType, PropertyTypes } from "../../api/definitions/propertyType"; 
import { DefinitionsPropertyIF } from "../../api/properties/definitionsPropertyIF";

export class DefinitionsProperty<Definition extends string> 
    extends DatabaseProperty<Definition[]> implements DefinitionsPropertyIF<Definition> {

    constructor( parent : DatabaseObject, 
        definitionName : string, 
        definitions : {}  ) {

        super( PropertyTypes.Definitions as PropertyType, parent ); 

        this.definition = definitionName;

        this.definitions = definitions;

    }

    setOptionsSource( optionsSource : OptionsSource<DatabaseDocument,DefinitionsProperty<Definition>>) {
        this._optionsSource = optionsSource;
    } 

    value( ignoreDefault? : boolean ) {
        return this.values( ignoreDefault );
    } 

    setValue( values : Definition[] | undefined ) : void {
        this.setValues( values );
    }

    values( ignoreDefault? : boolean ) : Definition[] | undefined {

        this.decryptData();

        if( this._values != null ) {
            return this._values;
        }
        return !!ignoreDefault ? undefined : this._defaultValues;
    }

    setValues( values : Definition[] | undefined ) : void {

        this.decryptData();

        if( this.compareValue( values ) !== 0 ) {

            this.setLastChange( this._values != null ? Object.assign([], this._values) : undefined );

            this._values = values != null ? Object.assign([], values) : undefined;

            delete this.error;
        }
    }

    clearValues(): void {

        const values = this.values();

        if( values != null ) {

            this.setLastChange( this._values != null ? Object.assign([], this._values) : undefined );

            this._values = undefined;

            delete this.error;
        }
    }

    addValue( value : Definition ): void {

        if( Object.values( this.definitions ).indexOf( value ) < 0 ) {
            throw new Error( "Invalid definition " + value );
        }

        const values = this.values();

        if( values == null ) {

            this._values = [value];

            this.setLastChange( this._values != null ? Object.assign([], this._values) : undefined );

            delete this.error;
        }
        else if( values.indexOf( value ) === -1 ) {

            this.setLastChange( this._values != null ? Object.assign([], this._values) : undefined );

            values.push( value );

            this._values = Object.assign([], values);

            delete this.error;
        }
    }

    removeValue( value : Definition ): boolean {

        if( Object.values( this.definitions ).indexOf( value ) < 0 ) {
            throw new Error( "Invalid definition " + value );
        }

        const values = this.values();

        if( values == null ) {
            return false;
        }

        let index = values.indexOf( value );

        if( index === -1 ) {
            return false;
        }

        this.setLastChange( this._values != null ? Object.assign([], this._values) : undefined );

        values.splice( index, 1 );

        this._values = Object.assign([], values);

        delete this.error;

        return true;
    }

    defaultValue() : Definition[] | undefined { 

        return this.defaultValues();
    }

    setDefaultValue( defaultValues : Definition[] | undefined ): void { 

        this.setDefaultValues( defaultValues );
    }

    defaultValues() : Definition[] | undefined { 

        return this._defaultValues;
    }

    setDefaultValues( defaultValues : Definition[] | undefined ): void { 

        if( defaultValues != null ) {
            for( const defaultValue of defaultValues ) {
                if( Object.values( this.definitions ).indexOf( defaultValue ) < 0 ) {
                    throw new Error( "Invalid definition " + defaultValue );
                }
            }
        }

        this._defaultValues = defaultValues != null ? Object.assign([], defaultValues) : undefined; 
    }


    requiresSelect() : boolean {
        return true;
    }

    async options() : Promise<Map<string,Definition> | undefined> {

        log.traceInOut( "options()" );

        const values = this.values();

        if( values == null ) {
            return undefined;
        }

        const result = new Map<string,Definition>();

        values.forEach( value => {
            result.set( value, value );
        });

        return result;
    }

    async select( filterValues? : boolean ) : Promise<Map<string,Definition> | undefined> { 

        log.traceIn( "select()" );

        const result = new Map<string,Definition>();

        const values = this.values();

        const optionsProperty = this._optionsSource != null ? 
            await this._optionsSource.optionsProperty() : 
            undefined;

        let options;

        if( optionsProperty == null ||
            (!optionsProperty.required && optionsProperty.value() == null ) ) {

            options = Object.values( this.definitions );  
        }
        else {
            options = (await optionsProperty.options())?.values();
        }

        if( options == null ) {
            log.traceOut( "select()", "no options", undefined ); 
            return undefined;
        }

        for( const definition of options ) {

            if( !!filterValues && values != null && values.includes( definition as Definition ) ) {
                continue;
            }
            result.set( definition as string, definition as Definition);
        }

        log.traceOut( "select()", result.size );
        return result.size === 0 ? undefined : result;  
    }

    fromData( documentData: any): void {

        if( this.isEncryptedData( documentData[this.key()] ) ) {

            this.setEncryptedData( documentData[this.key()] );
        }
        else {
            const data = documentData[this.key()];       

            if( data == null ) {
                this._values = undefined;
                return;
            }

            this._values = [];
            
            data.forEach( (value : any) => {
                if ( Object.values( this.definitions ).indexOf( value ) < 0 ) {

                    log.warn( "Unrecognized value for definition", value );
                } 
                else {
                    if( this._values!.indexOf( value as Definition ) === -1 ) {
                        this._values!.push( value as Definition );
                    }
                }
            });
        }
    }

    async toData( documentData: any, force? : boolean ) : Promise<void> {

        if( !!force ) {
            this.decryptData();
        }
        
        if( this.encrypted() && this.encryptedData() != null ) {
            
            documentData[this.key()] = this.encryptedData();
            return;
        }

        let data;

        if( this.encrypted() ) {
            data = this.encryptData( this._values );
        }
        else {
            data = this._values;
        }

        if( data != null ) {
            documentData[this.key()] = data;
        }
    }

    compareTo( other : DefinitionsProperty<Definition>, translator? : ( value? : string ) => string | undefined  ) : number {

        return this.compareValue( other.values(), translator );

    }

    compareValue( otherValues: Definition[] | undefined, translator? : ( value? : string ) => string | undefined ) : number {

        const thisValues = this.values();

        if( thisValues == null && otherValues == null ) {
            return 0;
        }

        if( thisValues != null && otherValues == null ) {
            return 1;
        }

        if( thisValues == null && otherValues != null ) {
            return -1;
        }

        if( thisValues!.length > otherValues!.length ) {
            return 1;
        }

        if( thisValues!.length < otherValues!.length ) {
            return -1;
        }
        
        for( let i = 0; i < thisValues!.length; i++ ) {

            let found = false;

            let compare = 1;

            let thisValue = translator != null ? translator( thisValues![i] as string ) : thisValues![i] as string;

            for( let j = 0; j < otherValues!.length; j++ ) {

                let otherValue = translator != null ? translator( otherValues![j] as string ) : otherValues![j] as string;            
                
                compare = thisValue!.localeCompare( otherValue! );

                if( compare === 0 ) {
                    found = true;
                    break;
                }
            }

            if( !found ) {
                return compare;
            }

        }

        return 0;
    }

    includes( other : DefinitionsProperty<Definition>, matchAny? : boolean, translator? : ( value? : string ) => string | undefined ) : boolean {
        return this.includesValue( other.value(), matchAny, translator );
    }

    includesValue( otherValues : Definition[] | undefined, matchAny? : boolean, translator? : ( value? : string ) => string | undefined ) : boolean {

        const thisValues = this.values();

        if( otherValues == null || otherValues.length === 0 ) {
            return false;
        }

        if( thisValues == null || thisValues.length === 0 ) {
            return false;
        }

        if( thisValues != null && otherValues == null ) {
            return false;
        }

        if( thisValues == null && otherValues != null ) {
            return false;
        }

        if( thisValues!.length < otherValues!.length && !matchAny) {
            return false;
        }


        for( let i = 0; i < otherValues!.length; i++ ) {

            let otherValue = translator != null ? translator( otherValues![i] as string ) : otherValues![i] as string;

            let found = false;
            for( let j = 0; j < thisValues!.length; j++ ) {

                let thisValue = translator != null ? translator( thisValues![j] as string ) : thisValues![j] as string;            
                
                const compare = thisValue!.localeCompare( otherValue! );

                if( compare === 0 ) {
                    if( !!matchAny ) {
                        return true;
                    }
                    found = true;
                    break;
                }
            }
            if( !found && !matchAny ) {
                return false;
            }
        }

        return !matchAny;
    }

    readonly definition : string;

    readonly definitions : {};

    protected _values : Definition[] | undefined;

    private _defaultValues : Definition[] | undefined;

    private _optionsSource : OptionsSource<DatabaseDocument,DefinitionsProperty<Definition>> | undefined;

}