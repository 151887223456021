
import { UserAccess } from "../../common/api/userAccess";
import { DatabaseAccessIF } from "../api/core/databaseAccessIF";

export abstract class DatabaseAccess implements DatabaseAccessIF {


    abstract userAccess( databasePath : string ) : UserAccess;

}
