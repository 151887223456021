export const NotificationStatuses = {

    Pending    : "pending",
    
    Sent       : "sent",

    Received   : "received",

    Read       : "read",

    Error      : "error"  

}

export type NotificationStatus = keyof (typeof NotificationStatuses);
