import React from 'react';

import { red, purple, indigo, blue, grey, cyan, yellow, pink, teal, lightGreen, lightBlue, orange } from '@material-ui/core/colors';
import { IncidentRegistrationDocument, QuarantineRegistrationDocument, SickLeaveRegistrationDocument, SymptomRegistrationDocument, TestRegistrationDocument, VaccineRegistrationDocument } from '../../healthguard/api/registrationDocuments';
import { ConsentDocument, GatheringDocument, RiskDocument, HealthguardCategoryDocument, HealthguardCompanyDocument, HealthguardDeviceDocument, HealthguardLocationDocument, HealthguardProjectDocument, HealthguardUnitDocument, HealthguardUserDocument, MeasureDocument, HealthguardAlertDocument } from '../../healthguard/api/healthguardDocuments';
import { AlertDocument, CategoryDocument, CompanyDocument, DatabaseDocumentIF, DeviceDocument, KeyDocument, LocationDocument, ProjectDocument, UnitDocument, UserDocument } from '../../services/database';
import { DefaultColorHue, DefaultColor } from './colorMap';
import { keyStatusColor, readinessLevelColor, severityColor } from './definitionColor';
import { HealthguardLocationIF } from '../../healthguard/api/documents/healthguardLocationIF';
import { HealthguardCompanyIF } from '../../healthguard/api/documents/healthguardCompanyIF';
import { HealthguardDeviceIF } from '../../healthguard/api/documents/healthguardDeviceIF';
import { AlertIF } from '../../services/database/api/documents/alertIF';
import ColorIcon from './colorIcon';
import { KeyIF } from '../../services/database/api/documents/keyIF';

export const documentColor = (databaseDocument : DatabaseDocumentIF ) =>  {

  switch( databaseDocument.documentName() ) { 

    case AlertDocument:
    case HealthguardAlertDocument:
      return severityColor( (databaseDocument as AlertIF).severity.value() );  

    case QuarantineRegistrationDocument: 
      return grey[DefaultColorHue];

    case SickLeaveRegistrationDocument:  
      return red[DefaultColorHue];
  
    case SymptomRegistrationDocument:
      return orange[DefaultColorHue];
    
    case TestRegistrationDocument:
      return cyan[DefaultColorHue]; 

    case IncidentRegistrationDocument: 
      return pink[DefaultColorHue]; 

    case VaccineRegistrationDocument:
      return blue[DefaultColorHue];

    case KeyDocument:
      return keyStatusColor( (databaseDocument as KeyIF).keyStatus.value() );  
  
    case MeasureDocument:
      return purple[DefaultColorHue];
  
    case GatheringDocument:
      return lightGreen[DefaultColorHue]; 
  
    case RiskDocument:
      return yellow[DefaultColorHue];
    
    case ProjectDocument:
    case HealthguardProjectDocument:
      return cyan[DefaultColorHue];
    
    case CategoryDocument:
    case HealthguardCategoryDocument: 
      return indigo[DefaultColorHue];

    case DeviceDocument:
    case HealthguardDeviceDocument: 
      return severityColor( (databaseDocument as HealthguardDeviceIF).lastAlertSeverity.value() );  
  
    case LocationDocument:
    case HealthguardLocationDocument: 
      return readinessLevelColor( (databaseDocument as HealthguardLocationIF).readinessLevel.value() );  
      
    case UserDocument:
    case HealthguardUserDocument:
      return lightBlue[DefaultColorHue];
        
    case UnitDocument:
    case HealthguardUnitDocument:
      return blue[DefaultColorHue];
          
    case CompanyDocument:
    case HealthguardCompanyDocument:
      return readinessLevelColor( (databaseDocument as HealthguardCompanyIF).readinessLevel.value() );

    case ConsentDocument:
      return teal[DefaultColorHue];
  
    default:
      return DefaultColor[DefaultColorHue];
  }
}


interface DocumentColorProps {
  databaseDocument : DatabaseDocumentIF
}


interface DocumentColorState { 
}

export default class DocumentColor extends React.PureComponent<DocumentColorProps,DocumentColorState> {

  constructor( props: DocumentColorProps ) {
    
    super(props);

    this.state = { 
    } as DocumentColorState;

    //log.traceInOut( "constructor()" );
  }

  
  render() {

    //log.traceInOut( "render()" );

    const color = documentColor( this.props.databaseDocument );
    return(
      <>
        {color && <ColorIcon color={color}/>}
      </>
    );
  }
}


