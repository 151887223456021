
import { MapPropertyIF } from "../../api/properties/mapPropertyIF";
import { DatabaseProperty } from "../../framework/databaseProperty";

export class MapProperty<Data extends Object> extends DatabaseProperty<Map<string,Data>> implements MapPropertyIF<Data> {

    value() : Map<string,Data> | undefined {

        this.decryptData();

        return this._map;
    }

    setValue( value : Map<string,Data> | undefined ): void {

        this.decryptData();

        if( this.compareValue( value ) !== 0 ) {

            this.setLastChange( Object.assign( new Map<string,Data>(), this._map ) );

            this._map = value;

            delete this.error;
        }
    }

    setEntry( key: string, entry: Data ) : void {

        this.decryptData();

        if( this._map == null ) {

            this._map = new Map<string,Data>();
        }

        if( JSON.stringify( this._map.get( key )) !== JSON.stringify( entry ) ) {

            this.setLastChange( Object.assign( new Map<string,Data>(), this._map ) );

            this._map.set( key, entry );

            delete this.error;
        }
    }

    removeEntry( key: string ) : boolean {

        this.decryptData();

        if( this._map == null ) {
            return false;
        }

        const previousValue = Object.assign( new Map<string,Data>(), this._map );

        const result = this._map.delete( key );

        if( result ) {
            this.setLastChange( previousValue );

            delete this.error;
        }

        return result;   
    }

    fromData( documentData: any): void {

        if( this.isEncryptedData( documentData[this.key()] ) ) { 

            this.setEncryptedData( documentData[this.key()] );
        }
        else {

            const map = documentData[this.key()] as any;

            if( map == null ) {
                this._map = undefined;
                return;
            }
    
            this._map = new Map<string,Data>();
    
            Object.keys(map).forEach( key => {
    
                this._map!.set( key, JSON.parse( map[key] ) as Data );
            });
        }
    }

    async toData( documentData: any, force? : boolean ) : Promise<void> {

        if( !!force ) {
            this.decryptData();
        }
        
        if( this.encryptedData() != null ) {
            
            documentData[this.key()] = this.encryptedData();
        }
        else if( this._map != null && this._map.size > 0 ) {

            const map = {} as any;

            this._map.forEach( (value,key) => {

                map[key] = JSON.stringify( value )
            });

            let data;

            if( this.encrypted() ) {
                data = this.encryptData( map )
            }
            else {
                data = map;
            }

            documentData[this.key()] = data;
        }
    }

    compareTo( other : MapProperty<Data> ) : number {

        return this.compareValue( other.value() );
    }

    compareValue( otherMap : Map<string,Data> | undefined ) : number {

        const thisMap = this.value();

        if( thisMap == null && otherMap == null ) {
            return 0;
        }

        if( thisMap != null && otherMap == null ) {
            return 1;
        }

        if( thisMap == null && otherMap != null ) {
            return -1;
        }

        if( thisMap!.size > otherMap!.size ) {
            return 1;
        }

        if( thisMap!.size < otherMap!.size ) {
            return -1;
        }

        for( const keyValuePair of otherMap! ) {

            const key = keyValuePair[0];
            const otherValue = keyValuePair[1];

            const thisValue = thisMap!.get( key );

            if( thisValue == null ) {
                return 1;
            }

            const compare = JSON.stringify( thisValue ).localeCompare( JSON.stringify( otherValue ) )

            if( compare !== 0 ) {
                return compare;
            }
        }

        return 0;
    }

    includes( other : MapProperty<Data>, matchAny? : boolean ) : boolean {
        return this.includesValue( other.value(), matchAny );
    }

    includesValue( map : Map<string,Data> | undefined, matchAny? : boolean ) : boolean {

        const thisMap = this.value();

        if( thisMap == null && map == null ) {
            return false;
        }

        if( thisMap != null && map == null ) {
            return false;
        }

        if( thisMap == null && map != null ) {
            return false;
        }

        if( thisMap!.size < map!.size && !matchAny ) {
            return false;
        }

        for( const keyValuePair of map! ) {

            const key = keyValuePair[0];
            const otherValue = keyValuePair[1];

            const thisValue = thisMap!.get( key );

            if( thisValue == null ) {
                return false;
            }

            const compare = JSON.stringify( thisValue ).localeCompare( JSON.stringify( otherValue ) ) === 0;

            if( compare && !!matchAny ) {
                return true;
            }

            if( !compare && !matchAny ) {
                return false;
            }
        }
        return !matchAny;
    }

    protected _map : Map<string,Data> | undefined;

}