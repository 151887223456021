import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';


export default function Title( props: { 
  align?: "left" | "right" | "center",
  level: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "subtitle1" | "subtitle2",
  children: React.ReactNode
}) {
  return (
      <Typography align={props.align} variant={props.level} >
        {props.children}
      </Typography>
  );
}

Title.propTypes = {
  children: PropTypes.node,
};