import * as React from 'react'

import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import { withTranslation, WithTranslation } from 'react-i18next';

import { log } from 'ui/app/app';

import { Chip, Grid, IconButton, InputAdornment, ListSubheader, TextField, Typography } from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add'; 
import SearchIcon from '@material-ui/icons/Search'; 

import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { DocumentsPropertyIF } from 'services/database/api/properties/documentsPropertyIF';
import { ReferenceHandle } from 'services/database/api/core/referenceHandle';
import { translatedPropertyLabel } from '../propertyLabel';
import { DatabaseDocumentIF } from 'services/database/api/core/databaseDocumentIF';
import { PropertyDisplayMode, propertyInputVariant } from '../propertyValue';

import { AppContext } from '../../app/appContext';
import { NewDocumentId } from '../../../services/database/api/core/databaseServiceIF';

import { DocumentsList } from '../documentsList'; 
import { InlineLoading } from '../loading';
import { activeLanguage } from '../../app/localization';
import { documentDialog } from '../documentDialog';
import { errorDialog } from '../simpleDialog';
import { Factory } from '../../../services/common/api/factory';

const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
  }, 
  select: {
    "& .MuiSelect-select:focus": {
      backgroundColor: 'transparent'
    },
    //minHeight: chipSelectInputMinHeight
  }, 
  loading : {
    //color:theme.palette.grey[500], 
    marginLeft: theme.spacing(-4.5),
    marginRight: theme.spacing(-4.5)
  },
  addButton : {
    marginTop: theme.spacing(1.5), 
    marginLeft: theme.spacing(-8), 
    marginRight: theme.spacing(-8) 
  },
  selectAction : {
    //color:theme.palette.grey[500], 
    marginLeft: theme.spacing(-8),
    marginRight: theme.spacing(-8)
  },
  search: {
    color: theme.palette.secondary.main
  },
  inputLabel: {
    marginTop: theme.spacing(-0.5)
  },
  chipLabel: {
    wordWrap: "break-word" 
  }
});

const NewOwnerText = "newOwner";

const NoOptionsSourceText = "noOptionsSource";

const EmptyOptionsText = "emptyOptions";

const MaxIndividualValueCellDisplayLength = 3;

const MinOptionsForSearch = 10;

const OptionsMaxHeight = "80vh";



export interface DocumentsPropertyValueProps extends WithStyles<typeof styles>, WithTranslation { 

  property : DocumentsPropertyIF<DatabaseDocumentIF>, 

  onPropertyChange? : ( property : DocumentsPropertyIF<DatabaseDocumentIF> ) => void,

  displayMode : PropertyDisplayMode, 
  
  singleProperty : boolean,

  hideColor?: boolean,

  disabled?: boolean,

  required?: boolean
}

interface DocumentsPropertyValueState { 

  open : boolean,

  noOptionsText : string,

  allowCreate?: boolean,

  propertyValues: Map<string,ReferenceHandle<DatabaseDocumentIF>>,

  options : Map<string,ReferenceHandle<DatabaseDocumentIF>>,

  loading: boolean,

  search: string

}

class DocumentsPropertyValue extends React.Component<DocumentsPropertyValueProps,DocumentsPropertyValueState> {

  constructor( props: DocumentsPropertyValueProps ) {
    
    super(props);

    const newDocument = this.props.property.parentDocument().databasePath().includes("/" + NewDocumentId); 

    const allowCreate = 
      !this.props.disabled && 
      !newDocument &&
      !!this.props.property.userAccess().allowCreate &&
      this.props.displayMode === PropertyDisplayMode.Form;

    const propertyValues = this.props.property.referenceHandles();

    let options = new Map<string,ReferenceHandle<DatabaseDocumentIF>>(); 

    if( propertyValues != null ) {

      propertyValues.forEach( referenceHandle => {
        options.set( referenceHandle.path, referenceHandle );
      })
    } 

    this.state = {  

      open : false,

      noOptionsText: EmptyOptionsText,

      allowCreate: allowCreate,

      options: options,

      propertyValues: propertyValues,

      loading: false,

      search: ""

     } as DocumentsPropertyValueState;

     this.options = this.options.bind(this);

    //log.traceInOut( "constructor()" );
  }

  async componentDidMount() {

    try {
      //log.traceIn( "componentDidMount()", this.state );
    
      //log.traceOut( "componentDidMount()", "state:", this.state );

    } catch( error ) {
      log.warn( "componentDidMount()", "Error mounting documents view", error );
      
      log.traceOut( "componentDidMount()", "error" );
    }
  }

  async componentDidUpdate() {

    //log.traceIn( "componentDidUpdate()",  this.props.property.key() );

    const propertyValues = this.props.property.value() as Map<string,ReferenceHandle<DatabaseDocumentIF>>;

    //log.debug( "componentDidUpdate()",  {propertyValues} );

    const existingPropertyValues = this.state.propertyValues;

    //log.debug( "componentDidUpdate()",  {existingPropertyValues} );

    if(!this.state.open &&
        this.props.property.compareValue(existingPropertyValues) !== 0) {

      let options = new Map<string, ReferenceHandle<DatabaseDocumentIF>>();

      if (propertyValues != null) {

        propertyValues.forEach(referenceHandle => {
          options.set(referenceHandle.path, referenceHandle);
        })
      } 

      //log.debug( "componentDidUpdate()",  {options} );

      this.setState({
        propertyValues: propertyValues,
        options: options
      })
    }
    //log.traceOut( "componentDidUpdate()",  this.props.property.key() );

  }

  async options() : Promise<Map<string,ReferenceHandle<DatabaseDocumentIF>>> {

    log.traceIn( "options()" );

    let result = new Map<string,ReferenceHandle<DatabaseDocumentIF>>();

    try {

      const sources = this.props.property.sources();

      if( sources == null || sources.length === 0 ) {

        this.setState( { 
          options : result,
          noOptionsText: NoOptionsSourceText,
          loading: false
        } );
      
        log.traceOut( "options()", "no database" );
        return result;
      }

      const databasePath = this.props.property.parentDocument().databasePath();

      if( databasePath.includes( "/" + NewDocumentId + "/") ) {

        this.setState( { 
          options : result,
          noOptionsText: NewOwnerText,
          loading: false
        } );
      
        log.traceOut( "options()", "new document" );
        return result;
      }

      let options = await this.props.property.select();

      if( options != null ) {
        for( const option of options.values() ) {

          result.set( option.path, option );
        }
      }

      result = new Map([...result].sort((optionA, optionB) => {
      
        const titleA = optionA[1].title != null ? optionA[1].title! : "";
        const titleB = optionB[1].title != null ? optionB[1].title! : "";

        return titleA.localeCompare( titleB, activeLanguage() ); 
      })); 

      this.setState( { 
        options : result,
        noOptionsText: EmptyOptionsText,
        loading: false 
      } );
    
      log.traceOut( "options()" );
      return result;

    } catch( error ) {
      log.warn( "options()", "Error reading options", error );

      this.setState( { 
        options : result,
        loading: false
      } ); 
      return result;
    }
  }
  render() {
    //log.traceInOut("render()" );

    const {classes } = this.props;

    if (this.props.displayMode === PropertyDisplayMode.Cell) {

      let text : string = "";

      if( this.state.propertyValues != null && this.state.propertyValues.size > 0 ) {
  
        if( this.state.propertyValues.size <= MaxIndividualValueCellDisplayLength ) {

          let count = 0;
          for( const value of this.state.propertyValues.values() ) {

            if( count !== 0 ) {
              text += ", ";
            }

            text += value.title;
            count++;
          }
        }
        else {
          text = "(" + this.state.propertyValues.size + ")";
        }
      }
  
      return ( <React.Fragment>{ text }</React.Fragment> );
    }

    const showList = this.props.singleProperty && this.props.displayMode === PropertyDisplayMode.Form;

    const label = translatedPropertyLabel( this.props.property.parent.documentName(), this.props.property.key());

    const referenceHandles = new Map<string,ReferenceHandle<DatabaseDocumentIF>>();

    if( this.state.propertyValues != null ) {
      this.state.propertyValues.forEach( referenceHandle => {
        referenceHandles.set( referenceHandle.path, referenceHandle );
      }) 
    }

    const emptyOptions = (this.state.options.size === 0 || this.state.options.size === referenceHandles.size );

    const showDocument = async (referenceHandle: ReferenceHandle<DatabaseDocumentIF>) => {

      log.traceIn( "showDocument()", {referenceHandle} );

      try {

        const databaseDocument = referenceHandle.databaseDocument != null ? referenceHandle.databaseDocument :
          await Factory.get().databaseService.databaseFactory.documentFromUrl( referenceHandle.path );

        await documentDialog( databaseDocument! );
  
        log.traceOut( "showDocument()");
  
      } catch( error ) {
        log.warn( "showDocument()", "Error showing new document", error );

        await errorDialog( error );
        
      }
    }

    const addDocument = async (referenceHandle: ReferenceHandle<DatabaseDocumentIF>) => {

      log.traceIn("addDocument()", referenceHandle);

      this.props.property.setDocument( referenceHandle );

      const propertyValues = this.props.property.referenceHandles(); 

      this.setState( { 
        propertyValues: propertyValues 
      })

      if (this.props.onPropertyChange != null) {
        this.props.onPropertyChange(this.props.property);
      }

      log.traceOut("addDocument()");
    }


    const removeDocument = async (referenceHandle: ReferenceHandle<DatabaseDocumentIF>) => {

      log.traceIn("removeDocument()", referenceHandle);

      this.props.property.removeDocument(referenceHandle.path);

      const propertyValues = this.props.property.referenceHandles(); 

      this.setState( { 
        propertyValues: propertyValues 
      })

      if (this.props.onPropertyChange != null) {
        this.props.onPropertyChange(this.props.property);
      }

      log.traceOut("removeDocument()");
    }


    const newDocument =  async () => {

      log.traceIn( "newDocument()" );

      try {
  
        const newDocument = this.props.property.newDocument()!;
  
        await documentDialog( newDocument );
  
        log.traceOut( "newDocument()");
  
      } catch( error ) {
        log.warn( "newDocument()", "Error creating new document", error );

        await errorDialog( error );
      }
    }

    

    const handleChange = (event: SelectChangeEvent<string[]>) => {

      log.traceIn( "handleChange()", {event}); 

      const values = typeof event.target.value === "string" ? 
        event.target.value.split(",") : 
        event.target.value;

      if (values != null && values.length > 0 &&
        values[values.length - 1] != null &&
        values[values.length - 1].includes("/" + NewDocumentId)) {

        showDocument(this.state.options.get( values[values.length - 1] )!);

        log.traceOut("handleChange()", "new document");
        return;
      }

      const referenceHandles = new Map<string,ReferenceHandle<DatabaseDocumentIF>>();

      if (values != null) {

        values.forEach(path => {

          referenceHandles.set( path, this.state.options.get( path )! );
        })
      }

      this.props.property.setValue( referenceHandles ); 

      this.setState( { 
        propertyValues: referenceHandles 
      })

      if (this.props.onPropertyChange != null) {
        this.props.onPropertyChange(this.props.property);
      }

      log.traceOut("handleChange()");
    }

    const list = (
      <DocumentsList
        collectionName={this.props.property.collectionName()!}
        handles={referenceHandles}
        onOptions={this.options}
        allowSelect={!this.props.disabled}        
        allowAdd={!this.props.disabled}
        allowRemove={!this.props.disabled}
        onAddDocument={this.state.allowCreate ? newDocument : undefined}
        onSelectHandle={showDocument}
        onAddedHandle={addDocument}
        onRemoveHandle={removeDocument}
        showDate={true}
      />
    );

    const renderValues = (values: string[]) => {

      if (values.length === 0) {
        return values; 
      }

      return (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
          {values.map((value) => {
            const referenceHandle = this.state.options.get( value );
            return( referenceHandle != null && 
              <Chip
                key={value}
                label={
                  <Typography className={classes.chipLabel} variant="inherit"> 
                    {referenceHandle.title != null ? referenceHandle.title : "noTitle"}
                  </Typography>
                }
                onClick={() => { showDocument( referenceHandle ) }}
                deleteIcon={!!this.props.disabled ? <Box/> : undefined}
                onDelete={() => { removeDocument( referenceHandle ) }}
                onMouseDown={(event) => { event.stopPropagation() }}
              />)
          })} 
        </Box>
      );
    }


    const chips = (
      <Grid container alignItems="center">
        <FormControl
          fullWidth
          variant={propertyInputVariant}
          disabled={this.props.disabled}
          required={this.props.required}
          error={this.props.property.error != null}
        >
          <InputLabel className={classes.inputLabel}
            shrink={true}
          >
            {label}
          </InputLabel>
          <Select<string[]>
            multiple
            className={classes.select}
            input={(propertyInputVariant as string) !== "outlined" ? undefined :
              <OutlinedInput
                notched
                label={<>{label}&nbsp;&nbsp;</>}
              />
            }
            displayEmpty={true}
            renderValue={renderValues}
            value={Array.from(referenceHandles.keys())}
            label={label}
            onChange={handleChange}
            IconComponent={this.state.loading ? Box : undefined}
            open={this.state.open && !this.state.loading}
            onOpen={async () => { 
              this.setState({ loading: true }) 
              const options = await this.options();
              if( options.size === 0 || options.size >= referenceHandles.size ) {
                this.setState({ open: true }) 
              }
            }}
            onClose={() => {
              this.setState({ open: false, loading: false }); 
            }}
            MenuProps={{ 
              autoFocus: false,
              style: { maxHeight: OptionsMaxHeight } 
            }}
          >           
            {this.state.open && this.state.options.size - referenceHandles.size >= MinOptionsForSearch &&  
              <ListSubheader 
                disableSticky={true} 
                style={{lineHeight: 0}}
                onKeyDown={(event) => event.stopPropagation()}
                > 
                <TextField
                  variant="standard"
                  fullWidth
                  color="secondary"
                  value={this.state.search}
                  placeholder={this.props.t("search")}
                  onChange={async (event) => {
                    const search = event.target.value;
                    if( search !== this.state.search ) {
                      event.stopPropagation();
                      this.setState({ search: search });
                    }
                  }}
                  InputProps={{
                    className: classes.search,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton disabled size="small">
                          <SearchIcon color="disabled" />
                        </IconButton>
                      </InputAdornment>
                    ),
                    disableUnderline: true, 
                    autoFocus: true
                  }}
                />
              </ListSubheader>
            }
            {Array.from(this.state.options.values()).map((option) => (
              this.state.open && 
                <MenuItem
                  selected={referenceHandles.has(option.path)}
                  style={(referenceHandles.has(option.path) || 
                    (option.title != null && !option.title.toLowerCase().includes( this.state.search.toLowerCase() ) ) ) ? 
                    { display: 'none' } : {} 
                  } 
                  key={option.path}
                  value={option.path}
                  onClick={() => {
                    this.setState({ open: false });
                  }}>
                {option.title != null ? option.title : ""}
                </MenuItem>
            ))}
            {!this.state.open && referenceHandles.size === 0 && <MenuItem value="" style={{ display: 'none' }} />}
            {!this.state.loading && emptyOptions &&
              <MenuItem disabled value="emptyOptions">{this.props.t(this.state.noOptionsText)}</MenuItem>} 
          </Select>
        </FormControl>
        {this.state.loading &&
          <Box className={classes.loading}><InlineLoading /></Box>
        }
        {!this.state.loading && !!this.state.allowCreate &&
          <IconButton className={classes.addButton} size="small" onClick={() => newDocument()}>  
            <AddIcon />
          </IconButton>
        } 
      </Grid>
    )

    return( showList ? list : 
      chips 
    );
  }
}

DocumentsPropertyValue.contextType = AppContext;

const ModifiedDocumentsPropertyValue = withTranslation()(withStyles(styles)(DocumentsPropertyValue));

export default ModifiedDocumentsPropertyValue;


