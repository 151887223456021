import React from 'react';

import { withTranslation, WithTranslation } from 'react-i18next';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core';

import DocumentView, { DocumentProps } from '../components/documentView';

import { PropertiesSelector } from 'services/database/api/core/propertiesSelector';

import DatabaseTable, { DatabaseTableProps, StartDateProperty } from 'ui/components/databaseTable';
import DatabaseList, { DatabaseListProps } from 'ui/components/databaseList';
import DatabaseCard, { DatabaseCardProps } from 'ui/components/databaseCard';
import DatabaseChart, { DatabaseChartProps } from 'ui/components/databaseChart';
import DatabaseGraph, { DatabaseGraphProps } from 'ui/components/databaseGraph';

import DatabaseView from '../components/databaseView';

import { GatheringIF } from 'healthguard/api/documents/gatheringIF';
import { CompanyDocument, UnitDocument } from '../../services/database/api/documents';
import { confirmationDialog, errorDialog } from '../components/simpleDialog';
import { log } from '../app/app';
import { HomePaths } from '../../services/common/api/homePaths';
import { DatabaseDocumentIF } from '../../services/database/api/core/databaseDocumentIF';
import { AppContext, AppContextProps } from '../app/appContext';
import { DatabasePropertyIF } from '../../services/database/api/core/databasePropertyIF';
import { DatabaseQuery } from '../../services/database/api/core/databaseQuery';
import { DatabaseViewType, DatabaseViewTypes } from '../components/databaseViewMenu';

export const defaultGatheringsTableColumns = [
  CompanyDocument,
  UnitDocument,
  "title",
  "location",
  "users",
  "incidentRegistrations"];

export const defaultHighlightedGatheringProperty = "location";


export function GatheringsTable(props: DatabaseTableProps ) {

  return (
    <DatabaseTable 
      {...props} 
      featuredProperties={props.featuredProperties != null ? props.featuredProperties : defaultGatheringsTableColumns}/>
  );
}

export function GatheringsList(props: DatabaseListProps ) {

  return (
    <DatabaseList  {...props}  />
  );
}

export function GatheringsCard( props: DatabaseCardProps ) {
  return (
    <DatabaseCard 
      {...props} 
      highlightedPropertyKey={props.highlightedPropertyKey != null ? props.highlightedPropertyKey : defaultHighlightedGatheringProperty}
    />
  );
}

export function GatheringsChart( props: DatabaseChartProps ) {
  return (
    <DatabaseChart 
      {...props} 
    />
  );
}

export function GatheringsGraph( props: DatabaseGraphProps ) {
  return (
    <DatabaseGraph 
      {...props} 
      previousPropertyKey={"unit"}
      nextPropertyKey={"users"}
      directed={false}
      
    />
  );
}


export function GatheringsView(props: { 
  databaseQuery : DatabaseQuery<GatheringIF>,
  title? : string,
  featuredProperties?: string[],
  highlightedPropertyKey?: string }) {

  return ( 
    <DatabaseView 
      databaseQuery={props.databaseQuery} 
      title={props.title}
      featuredProperties={props.featuredProperties != null ? props.featuredProperties : defaultGatheringsTableColumns}
      highlightedPropertyKey={props.highlightedPropertyKey != null ? props.highlightedPropertyKey : defaultHighlightedGatheringProperty}
      defaultDatabaseViewType={DatabaseViewTypes.Table as DatabaseViewType}
    /> );
}

const styles = (theme: Theme) => createStyles({});


interface GatheringViewProps extends WithStyles<typeof styles>, WithTranslation, DocumentProps {
}

interface GatheringViewState { // Document View Props

  gathering: GatheringIF,

  steps?: Map<string, PropertiesSelector>,

  showAllInvitees: boolean,
}

class GatheringView extends React.PureComponent<GatheringViewProps,GatheringViewState> {

  constructor( props: GatheringViewProps ) {
    
    super(props);

    log.traceIn( "constructor()" );

    this.state = { 

      gathering: this.props.databaseDocument as GatheringIF,

      showAllInvitees: false

    } as GatheringViewState;

    this.readGathering = this.readGathering.bind(this);

    //log.traceOut( "constructor()" );
  }

  async componentDidMount() {

    try {
      log.traceIn( "componentDidMount()" );

      await this.readGathering();

      log.traceOut( "componentDidMount()" );
    } catch( error ) {
      log.warn( "componentDidMount()", "Error mounting document view", error );
      
      log.traceOut( "componentDidMount()", "error" );
    }
  }

  async componentDidUpdate() {

    try {
      //log.traceIn( "componentDidUpdate()");

      //log.traceOut( "componentDidUpdate()" );
    } catch( error ) {
      log.warn( "componentDidUpdate()", "Error mounting document view", error );
      
    }
  }

  async readGathering() {

    try {
      log.traceIn("readGathering()" );

      let gathering = this.props.databaseDocument as GatheringIF;

      this.setState( {

        gathering: gathering,

        showAllInvitees: !gathering.allUsers.value()

      })

      log.traceOut( "readGathering()", "state:", this.state );
      
    } catch( error ) {
      log.warn( "readGathering()", "Error mounting document view", error );

      await errorDialog( error );

      log.traceOut( "readGathering()", "error" );
    }
  }


  render() {

    const GatheringBasicsFilter = {

      includePropertyKeys: [
        "unit",
        "location",
        "startDate",
        "host",
        "masUsers",
        "description",
        "attachments",
        "links"
      ]
    } as PropertiesSelector;
  
    const GatheringTrackingFilter = {

      includePropertyKeys: [
        "trackUsersFrom",
        "trackUsersTo"
      ]
    } as PropertiesSelector;
  
    const GatheringInviteesFilter = {

      includePropertyKeys: [ 
      "units", 
      "projects", 
      "categories",
      "allUsers"]

    } as PropertiesSelector;

    const GatheringNoInviteesFilter = {

      includePropertyKeys: ["allUsers"]

    } as PropertiesSelector;

    const GatheringParticipantsFilter = {

      includePropertyKeys: ["users"]

    } as PropertiesSelector;

    const GatheringRiskAssessmentFilter = {

      includePropertyKeys: ["risks","measures"],
  
    } as PropertiesSelector;
  
    const GatheringIncidentsFilter = {

      includePropertyKeys: ["incidentRegistrations"],
  
    } as PropertiesSelector;
  
    const GatheringTestsFilter = {
  
      includePropertyKeys: ["testRegistrations"],
  
    } as PropertiesSelector;

    const onDocumentChange = async (databaseDocument: DatabaseDocumentIF, 
      changedProperty? : DatabasePropertyIF<any> ): Promise<void> => {

      log.traceIn("onDocumentChange");

      try {

        const gathering = databaseDocument as GatheringIF;

        const showAllInvitees = !gathering.allUsers.value();

        log.debug("onDocumentChange", { showAllInvitees }, this.state.showAllInvitees);

        if (this.state.showAllInvitees !== showAllInvitees) {

          this.setState({ 
            gathering: gathering,
            showAllInvitees: showAllInvitees 
          });
        }

        if( changedProperty == null ) {
          log.traceIn("onDocumentChange", "no changed property");
          return;
        }

        if( GatheringInviteesFilter.includePropertyKeys!.includes( changedProperty.key() )) {

            await gathering.updateUsers();
        }

        if (changedProperty.key() === StartDateProperty ) {

          if (gathering.id.value() == null ||
            await confirmationDialog("updateTrackingDatesDates")) {

            await gathering.updateTrackingDates();
          }
        }


      } catch (error) {
        log.warn("onDocumentChange()", error);

        await errorDialog(error);
      }

      log.traceOut("onDocumentChange");
    }

    const appContext = this.context as AppContextProps;

    let steps;
    
    if( this.props.inputTabs != null ) {
      steps = this.props.inputTabs;

    }
    else {
      steps = new Map<string, PropertiesSelector>();

      steps.set("basics", GatheringBasicsFilter);
  
      if (appContext.currentHomePath !== HomePaths.UserHomePath) {

        if (this.state.showAllInvitees) {
          steps.set("invitees", GatheringInviteesFilter);
        }
        else {
          steps.set("invitees", GatheringNoInviteesFilter);
        }

        steps.set("participants", GatheringParticipantsFilter);

        steps.set("riskAssessment", GatheringRiskAssessmentFilter);

        steps.set("tracking", GatheringTrackingFilter);

        steps.set("tests", GatheringTestsFilter);

        steps.set("incidents", GatheringIncidentsFilter);

      }
    }

    //log.debug("render()", {steps} );
  
    return (<DocumentView
      {...this.props}
      databaseDocument={this.state.gathering}
      inputTabs={steps}
      onDocumentChange={onDocumentChange}
    />); 
  }
}

GatheringView.contextType = AppContext;

const ModifiedGatheringView = withTranslation()(withStyles(styles)(GatheringView));

export default ModifiedGatheringView; 

