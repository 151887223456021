import { Factory } from "../../../services/common/api/factory";
import { log } from "../../../services/database/framework/databaseService";
import { CollectionDatabase } from "../../../services/database/impl/core/collectionDatabase";
import { OptionsSource } from "../../../services/database/impl/core/optionsSource";
import { Alert } from "../../../services/database/impl/documents/alert";
import { ReferenceProperty } from "../../../services/database/impl/properties/referenceProperty";
import { ReferencesProperty } from "../../../services/database/impl/properties/referencesProperty";
import { TextProperty } from "../../../services/database/impl/properties/textProperty";
import { TextsProperty } from "../../../services/database/impl/properties/textsProperty";
import { HealthguardAlertIF } from "../../api/documents/healthguardAlertIF";
import { GatheringsCollection, HazardsCollection, MeasuresCollection, RisksCollection } from "../../api/healthguardCollections";
import { HealthguardAlertDocument } from "../../api/healthguardDocuments";
import { Gathering } from "./gathering";
import { HealthguardCompany } from "./healthguardCompany";
import { Hazard } from "./hazard";
import { Measure } from "./measure";
import { Risk } from "./risk";


export class HealthguardAlert extends Alert implements HealthguardAlertIF {  

    constructor( alertCollection : CollectionDatabase<HealthguardAlert>, documentPath? : string  ) {

        super( HealthguardAlertDocument, alertCollection, documentPath );

        try {
            this.startDate.required = true;
            
            const company = this.company.emptyDocument() as HealthguardCompany;

            this.risks = new ReferencesProperty<Risk>(this,
                () => this.parentCollections( RisksCollection ) as CollectionDatabase<Risk>[],
                "alerts");  

            this.measures = new ReferencesProperty<Measure>(this,
                () => this.parentCollections( MeasuresCollection ) as CollectionDatabase<Measure>[],
                "alerts");  

            this.gatherings = new ReferencesProperty<Gathering>( this, 
                () => this.parentCollections( GatheringsCollection ) as CollectionDatabase<Gathering>[],
                "alerts" ); 

            this.hazard = new ReferenceProperty<Hazard>( this, 
                company != null ? 
                    () => [company.hazards.collection(),company.publicHazards.documentsDatabase()] : 
                    () => [Factory.get().databaseService.databaseFactory.collectionDatabaseFromCollectionName( 
                        HazardsCollection ) as CollectionDatabase<Hazard>]  ); 

            this.variant = new TextProperty( this ); 
            this.variant.setOptionsSource( new OptionsSource<Hazard,TextsProperty>( 
                this.hazard, "variants" )  );

            //log.traceInOut( "constructor()", AlertsCollection ); 

        } catch( error ) {

            log.warn( "constructor()", "Error initializing alert", error );
            
            throw new Error( (error as any).message );
        }
    }

    async onCreate() : Promise<void> {

        //log.traceIn( "onCreate()" );

        try {

            await super.onCreate();
    
            //log.traceOut( "onCreate()" );
  
        } catch( error ) {
            
            log.warn( "onCreate()", "Error handling created notification", error );
  
            throw new Error( (error as any).message );
        }
    }

    async onUpdate() : Promise<void> {

        //log.traceIn( "onUpdate()" );

        try {

            await super.onUpdate();
    
            //log.traceOut( "onUpdate()" );
  
        } catch( error ) {
            
            log.warn( "onUpdated()", "Error handling updated notification", error );
  
            throw new Error( (error as any).message );
        }
    }

    async onDelete() : Promise<void> {

        //log.traceIn( "onDelete()" );

        try {

            await super.onDelete();
    
            //log.traceOut( "onDelete()" );
  
        } catch( error ) {
            
            log.warn( "onDelete()", "Error handling deleted notification", error );
  
            throw new Error( (error as any).message );
        }
    }


    readonly risks : ReferencesProperty<Risk>;

    readonly measures : ReferencesProperty<Measure>;

    readonly gatherings : ReferencesProperty<Gathering>;
    
    readonly hazard : ReferenceProperty<Hazard>;

    readonly variant : TextProperty;

}
