import React from 'react';

import DocumentView, { DocumentProps } from '../components/documentView';

import { PropertiesSelector } from 'services/database/api/core/propertiesSelector';

import DatabaseTable, { DatabaseTableProps } from 'ui/components/databaseTable';
import DatabaseList, { DatabaseListProps } from '../components/databaseList';
import DatabaseCard, { DatabaseCardProps } from 'ui/components/databaseCard';
import DatabaseChart, { DatabaseChartProps } from 'ui/components/databaseChart';
import DatabaseGraph, { DatabaseGraphProps } from 'ui/components/databaseGraph';


import DatabaseView from '../components/databaseView';
import { ChangeIF } from 'services/database/api/documents/changeIF';
import { CompanyDocument, UnitDocument, UserDocument } from '../../services/database/api/documents';
import { DatabaseQuery } from '../../services/database/api/core/databaseQuery';
import { DatabaseViewType, DatabaseViewTypes } from '../components/databaseViewMenu';


export const defaultChangeTableColumns = [
  "startDate",
  "changedBy",
  "changeType",
  CompanyDocument,
  UnitDocument,
  UserDocument,
  "changedDocumentName",
  "changedDocumentTitle"
];

export const defaultHighlightedChangeProperty = "changedDocumentName";

export function ChangesTable(props: DatabaseTableProps ) { 

  return (
    <DatabaseTable {...props} featuredProperties={props.featuredProperties != null ? props.featuredProperties : defaultChangeTableColumns }/>
  );
}

export function ChangesList(props: DatabaseListProps ) {

  return (
    <DatabaseList {...props} />
  );
}


export function ChangesCard( props: DatabaseCardProps ) {
  return (
    <DatabaseCard 
      {...props} 
      highlightedPropertyKey={props.highlightedPropertyKey != null ? props.highlightedPropertyKey : defaultHighlightedChangeProperty}
    />
  );
}

export function ChangesChart( props: DatabaseChartProps ) {
  return (
    <DatabaseChart 
      {...props} 
    />
  );
}

export function ChangesGraph( props: DatabaseGraphProps ) {
  return (
    <DatabaseGraph 
      {...props} 
      previousPropertyKey={"user"}
      directed={false}
    />
  );
}

export function ChangesView(props: { 
  databaseQuery : DatabaseQuery<ChangeIF>,
  title? : string,
  featuredProperties?: string[],
  highlightedPropertyKey?: string }) {

  return ( 
    <DatabaseView 
      databaseQuery={props.databaseQuery}
      title={props.title}
      featuredProperties={props.featuredProperties != null ? props.featuredProperties : defaultChangeTableColumns }
      highlightedPropertyKey={props.highlightedPropertyKey != null ? props.highlightedPropertyKey : defaultHighlightedChangeProperty}
      hideAdd={true}
      defaultDatabaseViewType={DatabaseViewTypes.Table as DatabaseViewType}
      /> 
    );
}


export function ChangeView( props : DocumentProps ) {

  const ChangeBasicsFilter = {

    includePropertyKeys : defaultChangeTableColumns.concat( "beforeValue","afterValue"),
  
  } as PropertiesSelector;
  

  
  const defaultChangeSteps = () : Map<string,PropertiesSelector> => {
  
    let defaultSteps = new Map<string,PropertiesSelector>();
  
    defaultSteps.set( "basics", ChangeBasicsFilter );
  
    return defaultSteps;
  }
  
  return ( <DocumentView 
    {...props}
    inputTabs={props.inputTabs != null ? props.inputTabs : defaultChangeSteps()}/> );
}



