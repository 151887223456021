import { Translator } from "../../../services/common/framework/translator";
import { Language } from "../../../services/database/api/definitions/language";
import { log } from "../app";
import i18next, { activeLanguage, setActiveLanguage } from "../localization";


export class I18nextTranslator extends Translator {

    async loadTranslations( namespace : string ) : Promise<any> {

        log.traceIn("loadTranslations()", {namespace} );

        try { 

            log.traceOut("loadTranslations()", {namespace} );
            return namespace;

        } catch (error) {

            log.warn("Error loading translations for namespace: " + namespace, error);

            throw new Error( (error as any).message );
        }
    }

    translate( key?: string, namespace?: string, language? :  Language ): string {

        try {
            log.traceIn("translate()", {key}, {namespace}, {language})

            if( key == null ) {
                return "";
            }

            const result = i18next.t( 
                (namespace != null ? namespace + ":" : "") + key.replaceAll(".",":"), 
                language != null ? { lng: language } : undefined );

            log.traceOut("translate()", {result})
            return result

        } catch (error) {

            log.warn("translate()", "Error translating: " + key, error);

            return "";
        }
    }
    
    defaultLanguage() : Language {
        return activeLanguage();
    }

    setDefaultLanguage( defaultLanguage : Language ) : void {
        setActiveLanguage( defaultLanguage as Language );
    }

}