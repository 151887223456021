import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import { withTranslation, WithTranslation } from 'react-i18next';

import GoogleMapReact, { ChangeEventValue, ClickEventValue, Coords, MapTypeStyle } from 'google-map-react';
import Geocode from "react-geocode";

import { Checkbox, Paper, Tooltip } from '@mui/material';

import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import AspectRatioTwoToneIcon from '@mui/icons-material/AspectRatioTwoTone';

import { AppContext, AppContextProps } from 'ui/app/appContext';
import { log } from 'ui/app/app';

import { DatabaseDocumentIF } from 'services/database/api/core/databaseDocumentIF';
import { DatabaseObserverIF } from '../../services/database/api/core/databaseObserverIF';

import { errorDialog } from './simpleDialog';
import { Factory } from '../../services/common/api/factory'; 
import { DatabaseProps } from './databaseView';

import { activeLanguage, country, countryName } from '../app/localization';
import { Box } from '@material-ui/core';
import { HealthguardDefinition } from '../../healthguard';

import { Geolocation, GeolocationPropertyIF } from 'services/database/api/properties/geolocationPropertyIF';
import Loading from './loading';

import { PersistentKeyCenter, PersistentKeyMarkerType, PersistentKeyZoom } from './appFrame';
import MapMarker, { MarkerType, MarkerTypes } from './mapMarker';
import theme from '../app/theme';
import ReactDOM from 'react-dom';
import { definitionIcon } from './definitionIcon';
import { definitionColor } from './definitionColor';
import { DefinitionPropertyIF, TextPropertyIF } from '../../services/database';
import { translatedPropertyValue } from './propertyValue';
import { Monitor } from '../../services/common/api/monitor';
import { ObservableIF } from '../../services/common/api/observableIF';
import { Observation } from '../../services/common/api/observation';

import { DefaultMapType, MapType, MapTypes } from '../../services/database/api/definitions/mapType';

import googleMapsConfiguration from "healthguard/data/settings/googleMaps.json";

const emptyDefaultZoom = 3;

const nonEmptyDefaultZoom = 14;

const doubleClickWaitPeriod = 1000;

const defaultMarkerType = MarkerTypes.Normal as MarkerType;

const styles = (theme: Theme) => createStyles({
  root: {
    width: '100%',
    height: '100%',
    paddingLeft: theme.spacing(0), 
    paddingBottom: theme.spacing(0)
  },  
  map: {
    width: '100%',
    height: '100%',
    padding: 0
  },
  marker: {
    opacity: 0.93,
    whiteSpace: 'nowrap'
  },
  avatar: {
    margin: theme.spacing(0),
    padding: theme.spacing(1),
    backgroundColor: 'transparent' 
  },
  markerType: {
    backgroundColor: 'white',
    borderRadius: 0.5,
    marginLeft: theme.spacing(1.25),
    marginTop: theme.spacing(1)
  },
  fullScreenControl: {
    marginRight: theme.spacing(1.25),
    marginTop: theme.spacing(1)
  }
});


type MapNode = {

  path: string,

  title: string,

  geolocation: Geolocation,

  databaseDocument : DatabaseDocumentIF
}


export interface DatabaseMapProps extends DatabaseProps {

  databaseObserver : DatabaseObserverIF<DatabaseDocumentIF>;

  geolocationPropertyKey? : string,

  iconPropertyKey? : string,

  colorPropertyKey? : string,

  statusPropertyKey? : string,

  defaultCountry? : string,

  markerType?: MarkerType,

  enableStreetView?: boolean,

  enableSateliteView?: boolean,

  fullScreenControl?: JSX.Element
}

interface MatchParams {
}

export interface ReactDatabaseMapProps extends
  DatabaseMapProps,
  WithStyles<typeof styles>,
  WithTranslation,
  RouteComponentProps<MatchParams> {
}


interface DatabaseMapState { // Component State

  loading: boolean,

  country: string,

  markerType?: MarkerType, 
  
  center?: Coords,

  zoom?: number,

  mapNodes: Map<string,MapNode>,

  mapType: MapType

}


class DatabaseMap extends React.PureComponent<ReactDatabaseMapProps, DatabaseMapState> {

  constructor(props: ReactDatabaseMapProps) {

    super(props);

    this.state = {

      loading: true,

      country: this.props.defaultCountry != null ? this.props.defaultCountry : country(),

      mapNodes: new Map<string, MapNode>(),

      mapType: DefaultMapType

    } as DatabaseMapState;

    this.onOpenDocument = this.onOpenDocument.bind(this);

    this.onUpdateDatabases = this.onUpdateDatabases.bind(this);
    this.updateNodes = this.updateNodes.bind(this);

    this.markerTypePersistentKey = this.markerTypePersistentKey.bind(this);
    this.markerType = this.markerType.bind(this);
    this.updateMarkerType = this.updateMarkerType.bind(this);
    this.renderMarkerTypeCheckBox = this.renderMarkerTypeCheckBox.bind(this);

    this.centerPersistentKey = this.centerPersistentKey.bind(this);
    this.center = this.center.bind(this);
    this.updateCenter = this.updateCenter.bind(this);

    this.zoomPersistentKey = this.zoomPersistentKey.bind(this);
    this.zoom = this.zoom.bind(this);
    this.updateZoom = this.updateZoom.bind(this);

    //log.traceInOut( "constructor()" );
  } 


  async componentDidMount() {

    try {
      log.traceIn("componentDidMount()" );

      await this.updateNodes();

      await this.props.databaseObserver.subscribe({ 
        observer: this,
        onNotify: this.onUpdateDatabases 
        } as Monitor );

      await this.props.databaseObserver.update();

      this.setState( {
        loading: false
      });
      
      log.traceOut("componentDidMount()");

    } catch( error ) {
          
      log.warn( "componentDidMount()", error ); 

      await errorDialog( error);

      log.traceOut( "componentDidMount()", error );
    }
  }
 
  async componentDidUpdate() {

    try {
      //log.traceIn("componentDidUpdate()" );

      await this.props.databaseObserver.update();

      if (this._markerTypeDiv != null) {

        ReactDOM.render(
          this.renderMarkerTypeCheckBox(),
          this._markerTypeDiv )
      }

      //log.traceOut("componentDidUpdate()" );

    } catch( error ) {
          
      log.warn( "componentDidUpdate()", error );

      await errorDialog( error);
    }
  }

  async componentWillUnmount() {
    log.traceIn("componentWillUnmount()");

    try {

      await this.props.databaseObserver.unsubscribe( this );

      log.traceOut("componentWillUnmount()");

    } catch (error) {
      log.warn("componentWillUnmount()", "Error unmounting collection list", error);

    }
  }

  private onUpdateDatabases = async (observable : ObservableIF, observation : Observation ) => {

    //log.traceIn("onUpdateDatabases()");

    try {
      if( observable !== this.props.databaseObserver ) {
        throw new Error("Mismatching database observer");
      }

      await this.updateNodes();
      

      //log.traceOut("onUpdateDatabases()");

    } catch (error) {
      log.warn("onUpdateDatabases()", "Error updating databases", error);

      log.traceOut("onUpdateDatabases()", "error");
    }
  }

  private updateNodes = async () => {

    //log.traceIn("updateNodes()");

    try {
      
      const appContext = this.context as AppContextProps;

      const mapNodes = new Map<string,MapNode>();

      for( const filteredDocument of this.props.databaseObserver.filteredDocuments().values() ) {

        const geolocationProperty = filteredDocument.property( 
          this.props.geolocationPropertyKey != null ? this.props.geolocationPropertyKey : "geolocation" ) as GeolocationPropertyIF;
        
        const geolocation = geolocationProperty?.value() as Geolocation;

        if( geolocation == null ) {
          log.warn( "onUpdateDatabases()", "Document has no geolocation", {filteredDocument} );
          continue;
        }

        const path = filteredDocument.referenceHandle().path;

        mapNodes.set( path, {

          path: path,

          title: filteredDocument.title.value(),

          geolocation: geolocation,

          databaseDocument: filteredDocument

        } as MapNode );
      }

      let center = this.center();

      log.debug("onUpdateDatabases()", {center});

      if( center == null ) {

        if( mapNodes.size === 1 ) {

          await this.updateCenter( Array.from( mapNodes.values())[0].geolocation );
        }
        else {

          Geocode.setApiKey( Factory.get().configurationService.config(googleMapsConfiguration, "mapsKey") );

          Geocode.setLanguage(activeLanguage());
  
          const region = appContext.currentCompany?.country?.value() != null ? 
            appContext.currentCompany?.country?.value() : 
            country();
  
          Geocode.setRegion(region!);

          let addressQuery = "";

          const address = appContext.currentCompany?.visitorAddress.subdocument();

          addressQuery +=
              address?.address1.value() != null ? address.address1.value()! : "";
  
          if (addressQuery!.length > 0) {
            addressQuery += ", ";
          }
  
          addressQuery += address?.city?.value() != null ? address.city.value()! : "";
  
          if (addressQuery.length > 0) {
            addressQuery += ", ";
          }
  
          addressQuery += address?.state?.value() != null ? address.state.value()! : "";
  
          if (addressQuery.length > 0) {
            addressQuery += ", ";
          }
  
          addressQuery += address?.zip?.value() != null ? address.zip.value()! : "";
  
          if (addressQuery.length > 0) {
            addressQuery += ", ";
          }
    
          if (addressQuery.length > 0) {
            addressQuery += ", ";
          }
  
          addressQuery += countryName( region! ); 
    
          log.debug("onUpdateDatabases()", {addressQuery});

          const lookup = await Geocode.fromAddress(addressQuery!);
  
          log.debug("onUpdateDatabases()", {lookup});
  
          await this.updateCenter( {
            lat: lookup.results[0].geometry.location.lat,
            lng: lookup.results[0].geometry.location.lng
          } as Coords );
        }
      }

      this.setState( {
        mapNodes: mapNodes,
      })

      //log.traceOut("onUpdateDatabases()");

    } catch (error) {
      log.warn("onUpdateDatabases()", "Error updating databases", error);

      log.traceOut("onUpdateDatabases()", "error");
    }
  }
  private markerTypePersistentKey = (): string => {
   
    const appContext = this.context as AppContextProps;

    return appContext.currentHomePath + "." + this.props.databaseObserver.defaultDocumentName()! + "." + PersistentKeyMarkerType;
  }

  private markerType = (): MarkerType => {

    //log.traceIn("markerType()");

    if (this.props.markerType != null) {
      //log.traceOut("markerType()", "From props", this.props.markerType);
      return this.props.markerType;
    }

    if (this.state.markerType != null) {
      //log.traceOut("markerType()", "From state", this.state.markerType);
      return this.state.markerType;
    }

    const persistentMarkerType = Factory.get().persistentState!.property( this.markerTypePersistentKey()) as MarkerType;

    if (persistentMarkerType != null) {
 
      //log.traceOut("markerType()", "From persistent app state", persistentMarkerType);
      return persistentMarkerType;
    }

    //log.traceOut("markerType()", "From default", defaultMarkerType );
    return defaultMarkerType;
  };

  private updateMarkerType = async ( markerType?: MarkerType ) => {

    log.traceIn("updateMarkerType()", markerType);

    const existingMarkerType = this.markerType();

    const newMarkerType = markerType != null ? markerType :
      existingMarkerType === MarkerTypes.Small ? MarkerTypes.Normal : MarkerTypes.Small;

    if( newMarkerType === existingMarkerType ) {
      log.traceOut("updateMarkerType()", "no change");
      return;
    }

    Factory.get().persistentState!.setProperty( this.markerTypePersistentKey(), newMarkerType );

    this.setState({ 
      markerType: newMarkerType as MarkerType
     });

     log.traceOut("updateMarkerType()");

  };

  private renderMarkerTypeCheckBox = () => {

    const { classes } = this.props;

    return (
      <Paper className={classes.markerType} elevation={0}>
        <Tooltip title={(<>{this.props.t("markerType")}</>)}>
          <Checkbox
            icon={<AspectRatioIcon />}
            checkedIcon={<AspectRatioTwoToneIcon />}
            checked={this.markerType() === MarkerTypes.Normal}
            onClick={(event) => {
              event.stopPropagation();
              this.updateMarkerType()
            }}
          />
        </Tooltip>
      </Paper>
    );
  }


  private centerPersistentKey = (): string => {
   
    const appContext = this.context as AppContextProps;

    return appContext.currentHomePath + "." + PersistentKeyCenter;
  }

  private center = (): Coords | undefined => {

    //log.traceIn("center()");

    if (this.state.center != null) {
      //log.traceOut("center()", "From state", this.state.center);
      return this.state.center;
    }

    const persistentCenter = Factory.get().persistentState!.property( this.centerPersistentKey()) as Coords;

    if (persistentCenter != null) {

      //log.traceOut("center()", "From persistent app state", persistentCenter);
      return persistentCenter;
    }

    //log.traceOut("center()", "undefined" );
    return undefined;
  };

  private updateCenter = async ( center: Coords ) => {

    log.traceIn("updateCenter()", center);

    if( JSON.stringify( center ) === JSON.stringify( this.center() ) ) {
      log.traceOut("updateCenter()", "no change");
      return;
    }

    Factory.get().persistentState!.setProperty( this.centerPersistentKey(), center );

    this.setState({ 
      center: center
     });
     log.traceOut("updateCenter()");
  };


  private zoomPersistentKey = (): string => {
   
    const appContext = this.context as AppContextProps;

    return appContext.currentHomePath + "." + PersistentKeyZoom;
  }

  private zoom = (): number => {

    //log.traceIn("zoom()");

    if (this.state.zoom != null) {
      //log.traceOut("zoom()", "From state", this.state.zoom);
      return this.state.zoom;
    }

    const persistentZoom = Factory.get().persistentState!.property( this.zoomPersistentKey()) as number;

    if (persistentZoom != null) {

      //log.traceOut("zoom()", "From persistent app state", persistentZoom);
      return persistentZoom;
    }

    const defaultZoom = this.state.mapNodes.size > 0 ? nonEmptyDefaultZoom : emptyDefaultZoom;

    //log.traceOut("zoom()", "From default", defaultZoom );
    return defaultZoom;
  };

  private updateZoom = async ( zoom: number) => {

    log.traceIn("updateZoom()", zoom);

    if( zoom === this.zoom() ) {
      log.traceOut("updateZoom()", "no change");
      return;
    }

    Factory.get().persistentState!.setProperty( this.zoomPersistentKey(), zoom );

    this.setState({ 
      zoom: zoom
     });

     log.traceOut("updateZoom()");

  };

  async onOpenDocument( path: string ) {

    log.traceIn("onOpenDocument()", path );

    try {

      if( !path.startsWith("/")) {

        log.traceOut("openDocument()", "not a document path" );
        return;
      }

      const databaseDocument = this.props.databaseObserver.document( path );

      if( databaseDocument == null ) {
        throw new Error( "notFound" )
      }
      
      if( this.props.onOpenDocument != null ) {

        this.props.onOpenDocument( this.props.databaseObserver, databaseDocument );
      }
      else {
        let to = this.context.currentHomePath + path; 

        this.props.history.push(to);
      }

      log.traceOut("onOpenDocument()");

    } catch( error ) {

      log.warn( "openDocument()", error );

      await errorDialog( error);
    }
  }

  render(): JSX.Element {

    //log.traceInOut("render()");

    const { classes } = this.props;

    const Marker = (props: { lat: number, lng: number, mapNode: MapNode }) => {

      const statusProperty = (this.props.statusPropertyKey != null ?
        props.mapNode.databaseDocument.property(this.props.statusPropertyKey) :
        undefined) as TextPropertyIF;

      const iconProperty = (this.props.iconPropertyKey != null ?
        props.mapNode.databaseDocument.property(this.props.iconPropertyKey) :
        undefined) as DefinitionPropertyIF<HealthguardDefinition>;

      const colorProperty = (this.props.colorPropertyKey != null ?
        props.mapNode.databaseDocument.property(this.props.colorPropertyKey) :
        undefined) as DefinitionPropertyIF<HealthguardDefinition>;

      return (
        <MapMarker 
          id={props.mapNode.path}
          title={props.mapNode.title} 
          status={statusProperty == null ? undefined : 
            translatedPropertyValue( props.mapNode.databaseDocument.documentName(), statusProperty.value())}
          icon={iconProperty == null ? undefined : definitionIcon(iconProperty.definition as HealthguardDefinition, iconProperty.value())}
          color={colorProperty == null ? undefined : definitionColor(colorProperty.definition as HealthguardDefinition, colorProperty.value())} 
          markerType={this.markerType()} 
          onClick={this.onOpenDocument}
        />
      )
    }


    // Fit map to its bounds after the api is loaded
    const handleGoogleApiLoaded = ( map : any, maps : any ) => {

      log.traceIn("handleGoogleApiLoaded()");

      const center = this.center();

      if( center == null && this.state.mapNodes.size > 1 ) {

        const bounds = new maps.LatLngBounds();

        this.state.mapNodes.forEach( mapNode => {
          bounds.extend(new maps.LatLng(mapNode.geolocation.lat, mapNode.geolocation.lng));
        });

        map.fitBounds(bounds);

        log.debug("handleGoogleApiLoaded()", {bounds});
      }

      if( this.props.markerType == null ) {
        this._markerTypeDiv = document.createElement('div');

        ReactDOM.render( 
          this.renderMarkerTypeCheckBox(),
          this._markerTypeDiv);   
  
        map.controls[maps.ControlPosition.TOP_LEFT].push(this._markerTypeDiv);   
      }

      if( this.props.fullScreenControl != null ) {

        this._controlButtonDiv = document.createElement('div');

        ReactDOM.render( 
          <Box className={classes.fullScreenControl}>
            {this.props.fullScreenControl}
          </Box>, 
          this._controlButtonDiv );   

        map.controls[maps.ControlPosition.RIGHT_BOTTOM].push(this._controlButtonDiv );  
      }

      log.traceOut("handleGoogleApiLoaded()");
    };

    const handleChange = async (value: ChangeEventValue) => {

      log.traceIn("handleChange()", value);

      await this.updateZoom( value.zoom );

      await this.updateCenter( value.center );

      log.traceOut("handleChange()");
    }

    const handleClick = async (value: ClickEventValue) => {

      if( value.event.detail > 1 ) {

        if( this._doubleclickTimeout != null ) {

          clearTimeout( this._doubleclickTimeout );

          delete this._doubleclickTimeout;
        }
        log.traceOut("handleClick()", "doubleclick" ); 
        return;
      }

      this._doubleclickTimeout = setTimeout( () => {

          delete this._doubleclickTimeout;

          const appContext = this.context as AppContextProps;

          const path = appContext.currentHomePath + this.props.databaseObserver.defaultDatabase()!.databasePath( true );
  
          this.props.history.push(path); 

        }, 
        doubleClickWaitPeriod );

      log.traceOut("handleClick()" );
    }

    const handleMapTypeChange = async (mapType: string ) => {

      log.traceIn("handleMapTypeChange()", mapType);

      this.setState( {
        mapType: mapType as MapType
      })

      log.traceOut("handleMapTypeChange()" );

    }

    const gogleMapsKey =
      Factory.get().configurationService.config(googleMapsConfiguration, "mapsKey");

    const googleMapsStyles = 
      Factory.get().configurationService.config(googleMapsConfiguration, "styles") as MapTypeStyle[];

    const adjustStyles = this.state.mapType === MapTypes.Roadmap || this.state.mapType === MapTypes.Terrain 

    const center = this.center();

    const zoom = this.zoom();

    return (
      <>
        <AppContext.Consumer>
          {appContext => (
            <Box className={classes.root}>
              {this.state.loading ? <Loading /> :
                <>
                  {center != null &&
                    <GoogleMapReact
                      bootstrapURLKeys={{
                        key: gogleMapsKey,
                        language: activeLanguage(),
                        region: this.state.country
                      }}
                      options={{
                        disableDefaultUI: true,
                        controlSize: theme.spacing(5),
                        zoomControl: true,
                        zoomControlOptions: { position: 3 },  
                        mapTypeControl: !!this.props.enableSateliteView, 
                        mapTypeControlOptions: { position: 3 },  
                        streetViewControl: !!this.props.enableStreetView, 
                        streetViewControlOptions: { position: 7 },
                        keyboardShortcuts: false,
                        minZoom: 2,
                        draggableCursor: 'pointer',
                        styles: adjustStyles ? googleMapsStyles : undefined
                      }}
                      draggable={true}
                      center={center}
                      zoom={zoom}
                      yesIWantToUseGoogleMapApiInternals
                      onGoogleApiLoaded={({ map, maps }) => handleGoogleApiLoaded( map, maps )}
                      onChange={handleChange}
                      onClick={handleClick}
                      onMapTypeIdChange={handleMapTypeChange}
                    >
                      {Array.from(this.state.mapNodes.values()).map(mapNode => (
                        <Marker 
                          key={mapNode.path} 
                          lat={mapNode.geolocation.lat} 
                          lng={mapNode.geolocation.lng} 
                          mapNode={mapNode} />
                      ))}
                    </GoogleMapReact>
                  }
                </>
              }
            </Box>
          )}
        </AppContext.Consumer>
      </>
    );
  }

  private _doubleclickTimeout? : NodeJS.Timeout;

  private _markerTypeDiv? : HTMLDivElement;

  private _controlButtonDiv? : HTMLDivElement;

}

DatabaseMap.contextType = AppContext;

const ModifiedDatabaseMap = withRouter(withTranslation()(withStyles(styles)(DatabaseMap)));

export default ModifiedDatabaseMap;

