import * as React from 'react'

import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import { withTranslation, WithTranslation } from 'react-i18next';

import { AppContext } from '../../app/appContext';
import { log } from 'ui/app/app';

import { Box, Grid, IconButton, InputAdornment, ListSubheader, TextField } from '@material-ui/core';

import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';


import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import CloseIcon from '@material-ui/icons/Close'; 
import AddIcon from '@material-ui/icons/Add'; 
import SearchIcon from '@material-ui/icons/Search'; 

import { ReferencePropertyIF as DocumentPropertyIF } from 'services/database/api/properties/referencePropertyIF';
import { ReferenceHandle } from 'services/database/api/core/referenceHandle';
import PropertyLabel, { translatedPropertyLabel } from '../propertyLabel';
import { PropertyDisplayMode, propertyInputVariant } from '../propertyValue';
import { DatabaseDocumentIF } from 'services/database/api/core/databaseDocumentIF';

import { NewDocumentId } from '../../../services/database/api/core/databaseServiceIF';
import { InlineLoading } from '../loading';
import { Factory } from '../../../services/common/api/factory';
import { activeLanguage } from '../../app/localization';
import { documentDialog } from '../documentDialog';
import { errorDialog } from '../simpleDialog';

const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
  },
  select: {
    "& .MuiSelect-select:focus": {
      backgroundColor: 'transparent'
    }
  },
  actionButtons: {
    marginRight: theme.spacing(-4)
  },
  loading : {
    //color:theme.palette.grey[500], 
    marginLeft: theme.spacing(-4.5),
    marginRight: theme.spacing(-4.5)
  },
  addButton : {
    marginTop: theme.spacing(1.5), 
    marginLeft: theme.spacing(-8), 
    marginRight: theme.spacing(-8) 
  },
  clearButton : {
    marginLeft: theme.spacing(-4.5), 
    marginRight: theme.spacing(-4.5)
  },
  moreButton : {
    marginLeft: theme.spacing(-4.5),
    marginRight: theme.spacing(-4.5)
  },
  inputLabel: {
    marginTop: theme.spacing(-0.5)
  },
  search: {
    color: theme.palette.secondary.main
  }
});


const NewOwnerText = "newOwner";

const NoOptionsSourceText = "noOptionsSource";

const EmptyOptionsText = "emptyOptions";

const MinOptionsForSearch = 10;

const OptionsMaxHeight = "80vh";

export interface DocumentPropertyValueProps extends WithStyles<typeof styles>, WithTranslation { 

  property : DocumentPropertyIF<DatabaseDocumentIF>, 

  onPropertyChange? : ( property : DocumentPropertyIF<DatabaseDocumentIF> ) => void,

  displayMode : PropertyDisplayMode,

  singleProperty : boolean,

  hideColor?: boolean,

  disabled?: boolean,

  required?: boolean
}

interface DocumentPropertyValueState { 

  open : boolean,

  noOptionsText : string,

  newDocument: boolean,

  propertyValue?: ReferenceHandle<DatabaseDocumentIF>,

  options : Map<string,ReferenceHandle<DatabaseDocumentIF>>

  loading: boolean,

  search : string

}


class DocumentPropertyValue extends React.Component<DocumentPropertyValueProps,DocumentPropertyValueState> {

  constructor( props: DocumentPropertyValueProps ) {
    
    super(props);

    const propertyValue = this.props.property.referenceHandle();

    const options = new Map<string,ReferenceHandle<DatabaseDocumentIF>>();

    const newDocument = this.props.property.parentDocument().databasePath().includes("/" + NewDocumentId); 

    if( propertyValue != null ) {

      options.set( propertyValue.path, propertyValue );
    } 

    this.state = { 

      open : false,

      noOptionsText: EmptyOptionsText,

      newDocument: newDocument,

      propertyValue: propertyValue,

      options: options,

      loading: false,

      search: ""

     } as DocumentPropertyValueState;

     this.options = this.options.bind(this);

    //log.traceInOut( "constructor()" );
  }

  async componentDidMount() {

    try {
      //log.traceIn( "componentDidMount()", this.state );
  
    
      //log.traceOut( "componentDidMount()", "state:", this.state );

    } catch( error ) {
      log.warn( "componentDidMount()", "Error mounting documents view", error );
      
      log.traceOut( "componentDidMount()", "error" );
    }
  }

  async componentDidUpdate() {

    //log.traceInOut( "componentDidUpdate()" );

    const propertyValue = this.props.property.value();

    const existingPropertyValue = this.state.propertyValue;

    //log.debug( "componentDidUpdate()", {propertyValues} );

    if (!this.state.open &&
        this.props.property.compareValue(existingPropertyValue) !== 0 ) {

      const options = new Map<string, ReferenceHandle<DatabaseDocumentIF>>();

      if (propertyValue != null) {
        options.set(propertyValue.path, propertyValue);
      }

      this.setState({
        propertyValue: propertyValue,
        options: options
      })
    }
  }


  async options() : Promise<Map<string,ReferenceHandle<DatabaseDocumentIF>>> {

    log.traceIn( "options()" );

    let result = new Map<string,ReferenceHandle<DatabaseDocumentIF>>();

    try {

      const sources = this.props.property.sources();

      if( sources == null || sources.length === 0 ) {

        this.setState( { 
          options : result,
          noOptionsText: NoOptionsSourceText,
          loading: false
        } );
      
        log.traceOut( "options()", "no database" );
        return result;
      }

      const databasePath = this.props.property.parentDocument().databasePath();

      if( databasePath.includes( "/" + NewDocumentId + "/") ) {

        this.setState( { 
          options : result,
          noOptionsText: NewOwnerText,
          loading: false
        } );
      
        log.traceOut( "options()", "new document" );
        return result;
      }

      let options = await this.props.property.select();

      if( options != null ) {
        options.forEach( option => {
          result.set( option.path, option );
        })
      }

      result = new Map([...result].sort((optionA, optionB) => {
      
        const titleA = optionA[1].title != null ? optionA[1].title! : "";
        const titleB = optionB[1].title != null ? optionB[1].title! : "";

        return titleA.localeCompare( titleB, activeLanguage() );
      })); 


      this.setState( { 
        options : result,
        noOptionsText: EmptyOptionsText,
        loading: false 
      } );
    
      log.traceOut( "options()" );
      return result;

    } catch( error ) {
      log.warn( "options()", "Error reading options", error );

      this.setState( { 
        options : result,
        loading: false
      } ); 
      return result;
    }
  }

  render() {
    //log.traceInOut("render()", this.props.property.error );

    const {classes } = this.props;

    if( this.props.displayMode === PropertyDisplayMode.Cell ) {

      return ( <>{ this.state.propertyValue?.title != null ? this.state.propertyValue.title : <>&nbsp;</>}</> ); 
    }
    

    const showDocument = async () => {

      log.traceIn( "showDocument()" );

      try {
        const databaseDocument = await this.props.property.document();

        if( databaseDocument != null ) {

          await documentDialog( databaseDocument );
        }
  
        log.traceOut( "showDocument()");
  
      } catch( error ) {
        log.warn( "showDocument()", "Error showing new document", error );

        await errorDialog( error );
        
      }
    }


    const newDocument =  async () => {

      log.traceIn( "newDocument()" );

      try {
  
        const newDocument = this.props.property.newDocument()!;
  
        await documentDialog( newDocument );
  
        log.traceOut( "newDocument()");
  
      } catch( error ) {
        log.warn( "newDocument()", "Error creating new document", error );

        await errorDialog( error );
      }
    }
    


    const handleClear = () => {

      log.traceIn("handleClear()" );

      this.props.property.setValue( undefined );

      this.setState( {

        propertyValue: undefined,

        options: new Map<string,ReferenceHandle<DatabaseDocumentIF>>()
      })

      if (this.props.onPropertyChange != null) {
        this.props.onPropertyChange(this.props.property);
      }

      log.traceOut("handleChange()");
    };

  
    const handleChange = (event: SelectChangeEvent ) => {

      log.traceIn("handleChange()", event.target.value, event.target.name);

      const value = event.target.value != null ? 
        this.state.options.get( event.target.value ): undefined;

      log.traceIn( "handleChange()", value );

      event.preventDefault();
 
      if( value == null || value.title == null ) {
        this.props.property.clearDocument();
      }
      else {
        this.props.property.setDocument( value );
      }

      const propertyValue = this.props.property.value();

      this.setState( { 
        propertyValue: propertyValue  
      })

      if( this.props.onPropertyChange != null ) {
        this.props.onPropertyChange( this.props.property );
      }

      log.traceOut("handleChange()");
    }

    const empty = this.state.propertyValue == null;

    const canOpen = this.props.displayMode === PropertyDisplayMode.Form &&
      !this.state.newDocument &&
      this.props.property.path() != null &&
      this.props.property.emptyDocument()!.userAccess().allowRead;

    const canClear = !empty && !this.props.disabled; 

    const canCreate = empty && 
      !this.props.disabled &&
      this.props.displayMode === PropertyDisplayMode.Form &&
      !!this.props.property.userAccess().allowCreate;

    const label = translatedPropertyLabel( this.props.property.parent.documentName(), this.props.property.key() );

    const value = !this.state.open && this.state.propertyValue?.path != null ? this.state.propertyValue.path: "";

    const renderValue = (path: string ) => {

      const referenceHandle = this.state.options.get( path );

      if( referenceHandle == null ) {
        return "";
      }

      return( referenceHandle.title != null ? referenceHandle.title : "");
    }


    return (
      <Grid container alignItems="center">
        <FormControl
          fullWidth
          variant={propertyInputVariant}
          disabled={this.props.disabled}
          required={this.props.required}
          error={this.props.property.error != null}
        >
          <InputLabel className={classes.inputLabel}
            shrink={true}
            >
            {label}
          </InputLabel>
          <Select
            className={classes.select}
            input={(propertyInputVariant as string) !== "outlined" ? undefined :
              <OutlinedInput
                notched
                label={<>{label}&nbsp;&nbsp;</>}
              />
            }
            displayEmpty={true}
            renderValue={renderValue}
            value={value} 
            label={<PropertyLabel documentName={this.props.property.parent.documentName()} propertyKey={this.props.property.key()} />}
            IconComponent={ this.state.loading || canClear || canOpen || !!this.props.disabled ? Box : undefined}
            onChange={handleChange}
            open={this.state.open && !this.state.loading}
            onOpen={async () => { 
              this.setState({ loading: true }) 
              await this.options();
              this.setState({ open: true }) 
            }}            
            onClose={() => {
              this.setState({ open: false, loading: false });
            }}
            MenuProps={{ 
              autoFocus: false,
              style: { maxHeight: OptionsMaxHeight } 
            }}
          >
            {this.state.open && this.state.options.size >= MinOptionsForSearch &&  
              <ListSubheader 
                disableSticky={true} 
                style={{lineHeight: 0}}
                onKeyDown={(event) => event.stopPropagation()}
                > 
                <TextField
                  variant="standard"
                  fullWidth
                  color="secondary"
                  value={this.state.search}
                  placeholder={this.props.t("search")}
                  onChange={async (event) => {
                    const search = event.target.value;
                    if( search !== this.state.search ) {
                      event.stopPropagation();
                      this.setState({ search: search });
                    }
                  }}
                  InputProps={{
                    className: classes.search,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton disabled size="small">
                          <SearchIcon color="disabled" />
                        </IconButton>
                      </InputAdornment>
                    ),
                    disableUnderline: true, 
                    autoFocus: true
                  }}
                />
              </ListSubheader>
            }
            {Array.from(this.state.options.values()).map((option) => (
              <MenuItem
                selected={this.state.propertyValue != null && 
                  Factory.get().databaseService.databaseFactory.equalDatabasePaths( this.state.propertyValue.path, option.path )}
                style={option.title != null && !option.title.toLowerCase().includes( this.state.search.toLowerCase() )  ? 
                  { display: 'none' } : {} 
                } 
                key={option.path}
                value={option.path}>
                {option.title != null ? option.title : ""}
              </MenuItem>
            ))}
            {!this.state.open && this.state.propertyValue == null && <MenuItem value="" style={{ display: 'none' }}/>} 
            {!this.state.loading && this.state.options.size === 0 && 
              <MenuItem disabled value="emptyOptions">{this.props.t(this.state.noOptionsText)}</MenuItem>} 
          </Select>
        </FormControl>
        {this.state.loading &&
          <Box className={classes.loading}><InlineLoading /></Box>
        }
        {!this.state.loading && canCreate &&
          <IconButton className={classes.addButton} size="small" onClick={() => newDocument()}>  
            <AddIcon />
          </IconButton>
        } 
        {!this.state.loading && canClear &&
          <IconButton className={classes.clearButton} size="small" onClick={() => handleClear()}>
            <CloseIcon />
          </IconButton>
        }  
        {!this.state.loading && canOpen &&
          <IconButton className={classes.moreButton} size="small" onClick={() => showDocument()}>  
            <MoreHorizIcon />
          </IconButton>
        }      
      </Grid>
    );
  }
}

DocumentPropertyValue.contextType = AppContext;

const ModifiedDocumentPropertyValue = withTranslation()(withStyles(styles)(DocumentPropertyValue));

export default ModifiedDocumentPropertyValue;











