
export const ReadinessLevels = {

    Red :          "red",

    Yellow :        "yellow",

    Green :          "green" 
}

export type ReadinessLevel = keyof (typeof ReadinessLevels); 

export const maxReadinessLevel = ( readinessLevel1? : ReadinessLevel, readinessLevel2? : ReadinessLevel ) : ReadinessLevel | undefined  => {

    if( readinessLevel1 == null ) {
        return readinessLevel2;
    }

    switch( readinessLevel1 ) {

        case ReadinessLevels.Red:
            return readinessLevel1;

        case ReadinessLevels.Yellow:
            return readinessLevel2 === ReadinessLevels.Red ? 
                readinessLevel2 :
                readinessLevel1;
        
        case ReadinessLevels.Green:
            return readinessLevel2 === ReadinessLevels.Red || readinessLevel2 === ReadinessLevels.Yellow ? 
                readinessLevel2 : 
                readinessLevel1;
        
        default:
            throw new Error( "Unrecognized risk level: " + readinessLevel1 );
    }
}

