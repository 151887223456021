import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { WithTranslation } from 'react-i18next';
import { createStyles, Theme, WithStyles } from '@material-ui/core';

import DocumentView, { DocumentProps } from '../components/documentView';

import { PropertiesSelector } from 'services/database/api/core/propertiesSelector';

import DatabaseTable, { DatabaseTableProps } from 'ui/components/databaseTable';
import DatabaseList, { DatabaseListProps } from 'ui/components/databaseList';
import DatabaseCard, { DatabaseCardProps } from 'ui/components/databaseCard';
import DatabaseChart, { DatabaseChartProps } from 'ui/components/databaseChart';
import DatabaseGraph, { DatabaseGraphProps } from 'ui/components/databaseGraph';

import DatabaseView from '../components/databaseView';

import { RiskIF } from 'healthguard/api/documents/riskIF';
import { CompanyDocument } from '../../services/database/api/documents';

import { DatabaseQuery } from '../../services/database/api/core/databaseQuery';
import { HazardsCollection, MeasuresCollection } from '../../healthguard/api/healthguardCollections';
import { ReadinessLevelDefinition, ProbabilityDefinition, SeverityDefinition } from '../../services/database/api/definitions';
import { DatabaseViewType, DatabaseViewTypes } from '../components/databaseViewMenu';

export const defaultRisksTableColumns = [
  CompanyDocument,
  "title",
  HazardsCollection,
  ReadinessLevelDefinition,
  SeverityDefinition,
  ProbabilityDefinition,
  "riskScore",
  MeasuresCollection
];

export const defaultHighlightedRiskProperty = SeverityDefinition;


export function RisksTable(props: DatabaseTableProps ) {

  return (
    <DatabaseTable 
      {...props} 
      featuredProperties={props.featuredProperties != null ? props.featuredProperties : defaultRisksTableColumns}/>
  );
}


export function RisksList(props: DatabaseListProps ) {

  return (
    <DatabaseList {...props} />
  );
}


export function RisksCard( props: DatabaseCardProps ) {
  return (
    <DatabaseCard 
      {...props} 
      highlightedPropertyKey={props.highlightedPropertyKey != null ? props.highlightedPropertyKey : defaultHighlightedRiskProperty}
    />
  );
}

export function RisksChart( props: DatabaseChartProps ) {
  return (
    <DatabaseChart 
      {...props} 
    />
  );
}

export function RisksGraph( props: DatabaseGraphProps ) {
  return (
    <DatabaseGraph 
      {...props} 
      previousPropertyKey={SeverityDefinition}
      nextPropertyKey={MeasuresCollection}
      directed={true}
    />
  );
}


export function RisksView(props: { 
  databaseQuery : DatabaseQuery<RiskIF>,
  title? : string,
  featuredProperties?: string[],
  highlightedPropertyKey?: string }) {

  return ( 
    <DatabaseView 
      databaseQuery={props.databaseQuery} 
      title={props.title}
      featuredProperties={props.featuredProperties != null ? props.featuredProperties : defaultRisksTableColumns}
      defaultDatabaseViewType={DatabaseViewTypes.Table as DatabaseViewType} 
      highlightedPropertyKey={props.highlightedPropertyKey != null ? props.highlightedPropertyKey : defaultHighlightedRiskProperty}
      hideDateFilter={true}
      /> );
}


const styles = (theme: Theme) => createStyles({});

interface MatchParams {
}

interface RiskViewProps extends WithStyles<typeof styles>, WithTranslation, RouteComponentProps<MatchParams> {
  
  risk?: RiskIF,

  steps?: Map<string, PropertiesSelector>
}

interface RiskViewState { // Document View Props

  risk?: RiskIF,

  path : string,

  steps?: Map<string, PropertiesSelector>,

  showAllExposed: boolean,
}


export function RiskView( props : DocumentProps ) {

  const RiskBasicsFilter = {

    includePropertyKeys: [
      "readinessLevel",
      "status",
      SeverityDefinition,
      ProbabilityDefinition,
      "riskScore",
      "description",
      "links",
      "attachments"]

  } as PropertiesSelector;

  const HazardsFilter = {

    includePropertyKeys: [
      HazardsCollection]

  } as PropertiesSelector;


  const defaultRiskSteps = (): Map<string, PropertiesSelector> => {

    let defaultSteps = new Map<string, PropertiesSelector>();

    defaultSteps.set("basics", RiskBasicsFilter);
    defaultSteps.set(HazardsCollection, HazardsFilter);

    return defaultSteps;  
  }

  return (<DocumentView
    {...props}    
    inputTabs={props.inputTabs != null ? props.inputTabs : defaultRiskSteps()}
  />);
}





