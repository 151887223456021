
import { Factory } from "../../../common/api/factory";
import { Observation } from "../../../common/api/observation";
import { CollectionDatabase } from "../../impl/core/collectionDatabase";
import { CollectionGroupDatabase } from "../../impl/core/collectionGroupDatabase";
import { DatabaseDocument } from "../databaseDocument";
import { log } from "../databaseService";
import { FirestoreDatabaseManager } from "./firestoreDatabaseManager";


export class ClientFirestoreDatabaseManager extends FirestoreDatabaseManager {

    async monitorCollectionGroup( collectionGroupDatabase : CollectionGroupDatabase<DatabaseDocument> ) : Promise<void> {
        
        log.traceIn( "("+collectionGroupDatabase.collectionName()+")", " monitorCollectionGroup()", collectionGroupDatabase.databasePath( true ));

        try {

            if (!collectionGroupDatabase.userAccess().allowRead) {
                throw new Error( "No read access to collection group " + collectionGroupDatabase.collectionName() );
            }

            let collectionGroupMonitor = this._collectionGroupMonitors.get(collectionGroupDatabase.databasePath(true));

            if (collectionGroupMonitor !== undefined) {
                log.traceOut("(" + collectionGroupDatabase.collectionName()+ ")", "monitorCollectionGroup()", "Already monitoring collection group");
                return;
            }

            this._collectionGroupMonitors.set(collectionGroupDatabase.databasePath(true), null); // placeholder

            collectionGroupMonitor = await this.databaseQuery(collectionGroupDatabase).onSnapshot(
                async (snapshot: { docChanges: () => any[]; }) => {

                    log.traceIn("(" + collectionGroupDatabase.collectionName()+ ")", "monitorCollectionGroup()", "onShapshot");

                    const changes = snapshot.docChanges();

                    const result = new Map<string, DatabaseDocument>();

                    let hasNotified = false;

                    let hasDebugged = false;

                    for( const change of changes ) {

                        try {
                            if( !hasDebugged ) { log.debug("onShapshot", "new change", changes.length ) }

                            const documentData = change.doc.data();

                            if( !hasDebugged ) { log.debug("onShapshot", "documentData") }

                            const documentPath = documentData.path;

                            if( !hasDebugged ) { log.debug("onShapshot", "document.path", {documentPath}) }

                            if (documentPath != null) {
                                
                                const databaseDocument =
                                    await Factory.get().databaseService.databaseFactory.documentFromData(
                                        documentPath, documentData) as DatabaseDocument;
                                
                                if( !hasDebugged ) { log.debug("onShapshot", "databaseDocument") }

                                if (databaseDocument == null) {

                                    log.warn("(" + collectionGroupDatabase.collectionName()+ ")", "monitorCollectionGroup()", "could not read document", { documentPath });
                                }
                                else {

                                    let observation: Observation;

                                    if (change.type === 'added') {

                                        observation = Observation.Create; 
                                    }
                                    else if (change.type === 'modified') {

                                        observation = Observation.Update;
                                    }
                                    else if (change.type === 'removed') {

                                        observation = Observation.Delete;
                                    }
                                    else {
                                        throw new Error("Unrecognized change type");
                                    }

                                    result.set(databaseDocument.databasePath(true), databaseDocument);

                                    if( !hasDebugged ) { log.debug("onShapshot", "result.set") }

                                    if (changes.length === 1 || observation !== Observation.Create) {

                                        await collectionGroupDatabase.onNotify(
                                            collectionGroupDatabase,
                                            observation!,
                                            databaseDocument.databasePath(true),
                                            databaseDocument);

                                        hasNotified = true; 
                                    }
                                }
                            }
                            hasDebugged = true;

                        } catch (error) {
                            log.error("(" + collectionGroupDatabase.collectionName()+ ")", "monitorCollectionGroup()", "onShapshot", "Error reading snapshot", error);
                        }

                    }

                    if (!hasNotified) {

                        await collectionGroupDatabase.onNotify(
                            collectionGroupDatabase,
                            Observation.Create,
                            collectionGroupDatabase.databasePath(true),
                            result);
                    }

                    log.traceOut("(" + collectionGroupDatabase.collectionName()+ ")", "monitorCollectionGroup()", "onShapshot", changes.length);

                },
                async ( error : any ) => {
                    log.warn( "("+collectionGroupDatabase.collectionName()+")", "monitorCollectionGroup()", "Error monitoring collection group", error );
                });

            if( this._collectionGroupMonitors.get(collectionGroupDatabase.databasePath(true)) === undefined ) {
                // We have been released while waiting
                collectionGroupMonitor();
            }
            else {
                this._collectionGroupMonitors.set(collectionGroupDatabase.databasePath(true), collectionGroupMonitor!);
            }

            log.traceOut( "("+collectionGroupDatabase.collectionName()+")", "monitorCollectionGroup()", "Added new monitor");

        } catch( error ) {
            log.warn( "("+collectionGroupDatabase.collectionName()+")", "monitorCollectionGroup()", "Error monitoring collection group", error );
            
            throw new Error( "Could not monitor collection group" + collectionGroupDatabase.collectionName());
        }
    }

    async releaseCollectionGroup( collectionGroupDatabase : CollectionGroupDatabase<DatabaseDocument> ) : Promise<void> {
        
        log.traceIn( "("+collectionGroupDatabase.collectionName()+")", "releaseCollectionGroup()");

        try {

            let collectionGroupMonitor = this._collectionGroupMonitors.get( collectionGroupDatabase.databasePath( true ) );

            if( collectionGroupMonitor === undefined ) {
                log.traceOut( "("+collectionGroupDatabase.collectionName()+")", "releaseCollectionGroup()", "Not monitoring collection group" );
                return;
            }

            if( collectionGroupMonitor != null ) {
                collectionGroupMonitor(); // unsubscribes
            }

            this._collectionGroupMonitors.delete( collectionGroupDatabase.databasePath( true ) );

            log.traceOut( "("+collectionGroupDatabase.collectionName()+")", "releaseCollectionGroup()" );

        } catch( error ) {

            log.warn( "Error stopping collection group monitoring from firestore for", collectionGroupDatabase.collectionName(), error );
            
            log.traceOut( "("+collectionGroupDatabase.collectionName()+")", "releaseCollectionGroup()", error );
        }
    }

    async monitorCollection( collectionDatabase : CollectionDatabase<DatabaseDocument> ) : Promise<void> {
        log.traceIn( "("+collectionDatabase.collectionName()+")", "monitorCollection()");

        try {
            if( !collectionDatabase.userAccess().allowRead ) {
                throw new Error( "No read access to collection " + collectionDatabase.collectionName() );
            }

            let collectionMonitor = this._collectionMonitors.get( collectionDatabase.databasePath( true ) );

            if( collectionMonitor !== undefined ) {
                log.traceOut( "("+collectionDatabase.collectionName()+")", "monitorCollection()", "Already monitoring collection" );
                return;
            }

            this._collectionMonitors.set( collectionDatabase.databasePath( true ), null ); // placeholder

            collectionMonitor = await this.databaseQuery(collectionDatabase).onSnapshot( 
                async ( snapshot: { docChanges: () => any[]; } ) => {

                    //log.traceIn("(" + collectionDatabase.collectionName()+ ")", "monitorCollection()", "onShapshot" );

                    const result = new Map<string, DatabaseDocument>();

                    let hasNotified = false;

                    const changes = snapshot.docChanges();

                    for( const change of changes ) {

                        try {
                            const documentPath = collectionDatabase.databasePath() + "/" + change.doc.id;

                            const documentData = change.doc.data();

                            const databaseDocument =
                                await Factory.get().databaseService.databaseFactory.documentFromData(
                                    documentPath, documentData ) as DatabaseDocument;

                            if (databaseDocument == null) {

                                log.warn("(" + collectionDatabase.collectionName()+ ")", "monitorCollection()", "could not read document", { documentPath });
                            }
                            else {
                                let observation: Observation;

                                if (change.type === 'added') {

                                    observation = Observation.Create;
                                }
                                else if (change.type === 'modified') {

                                    observation = Observation.Update;
                                }
                                else if (change.type === 'removed') {

                                    observation = Observation.Delete;
                                }
                                else {
                                    throw new Error("Unrecognized change type");
                                }

                                //log.debug("(" + collectionDatabase.collectionName()+ ")", "monitorCollection()", "onShapshot", "DB change notification");

                                result.set(databaseDocument.databasePath(true), databaseDocument);

                                if (changes.length === 1 || observation !== Observation.Create) {

                                    await collectionDatabase.onNotify(
                                        collectionDatabase,
                                        observation!,
                                        databaseDocument.databasePath( true ),
                                        databaseDocument);

                                    hasNotified = true;
                                }
                            }

                        } catch (error) {
                            log.warn("(" + collectionDatabase.collectionName()+ ")", "monitorCollection()", "onShapshot", "Error reading snapshot", error);
                        }
                    }    

                    if (!hasNotified) {

                        await collectionDatabase.onNotify(
                            collectionDatabase,
                            Observation.Create,
                            collectionDatabase.databasePath(true),
                            result);
                    }

                //log.traceOut("(" + collectionDatabase.collectionName()+ ")", "monitorCollection()", "onShapshot", changes.length );
            },
            async ( error : any ) => {
                log.warn( "("+collectionDatabase.collectionName()+")", "monitorCollection()", "Error monitoring collection", error );
            });
            
            if( this._collectionMonitors.get(collectionDatabase.databasePath(true)) === undefined ) {
                // We have been released while waiting
                collectionMonitor();
            }
            else {
                this._collectionMonitors.set( collectionDatabase.databasePath( true ), collectionMonitor! );
            }
            log.traceOut( "("+collectionDatabase.collectionName()+")", "monitorCollection()");

        } catch( error ) {
            log.warn( "("+collectionDatabase.collectionName()+")", "monitorCollection()", "Error monitoring collection", error );
            
            throw new Error( (error as any).message );
        }
    }


    async releaseCollection( collectionDatabase : CollectionDatabase<DatabaseDocument> ) : Promise<void> {
        
        //log.traceIn( "("+collectionDatabase.collectionName()+")", "releaseCollection()");

        try {

            let collectionMonitor = this._collectionMonitors.get( collectionDatabase.databasePath( true ) );

            if( collectionMonitor === undefined ) {
                //log.traceOut( "("+collectionDatabase.collectionName()+")", "releaseCollection()", "Not monitoring collection" );
                return;
            }

            if( collectionMonitor != null ) {

                collectionMonitor(); // unsubscribes 
            }

            this._collectionMonitors.delete( collectionDatabase.databasePath( true ) );

            //log.traceOut( "("+collectionDatabase.collectionName()+")", "releaseCollection()" );

        } catch( error ) {

            log.warn( "Error stopping collection monitoring from firestore for", collectionDatabase.collectionName(), error );
            
            log.traceOut( "("+collectionDatabase.collectionName()+")", "releaseCollection()", error );
        }
    }

    async monitorDocuments( collectionDatabase : CollectionDatabase<DatabaseDocument>, documentPaths : string[] ) : Promise<void> {

        log.traceIn( "("+collectionDatabase.collectionName()+")", "monitorDocuments()", documentPaths );

        try {
            if( documentPaths != null ) {

                for( const documentPath of documentPaths ) {

                    await this.monitorDocument( collectionDatabase, documentPath );
                }
            }

            log.traceOut( "("+collectionDatabase.collectionName()+")", "monitorDocuments()" );
            
        } catch( error ) {

            log.warn( "Error starting document monitoring for", collectionDatabase.collectionName(), error );
            
            throw new Error( (error as any).message );
        }
    }


    async monitorDocument( collectionDatabase : CollectionDatabase<DatabaseDocument>, documentPath : string ) : Promise<void> {

        log.traceIn( "("+collectionDatabase.collectionName()+")", "monitorDocument()", documentPath );

        try {
            const databaseDocument = collectionDatabase.newDocument( documentPath );

            const documentId = databaseDocument.id.value()!;

            if( documentId == null ) {
                throw new Error( "No valid document ID from path: " + documentPath );
            }

            if( !databaseDocument.userAccess().allowRead ) {
                throw new Error( "No read access to document");
            }

            let collectionDocumentMonitors = this._documentMonitors.get( collectionDatabase.databasePath( true ) );

            if( collectionDocumentMonitors == null ) {

                collectionDocumentMonitors = new Map<string,() => void>();

                this._documentMonitors.set( collectionDatabase.databasePath( true ), collectionDocumentMonitors );
            }

            if( collectionDocumentMonitors.has( documentPath ) ) {

                await databaseDocument.read();

                await collectionDatabase.onNotify( collectionDatabase, Observation.Create, documentPath, databaseDocument );

                log.traceOut( "("+collectionDatabase.collectionName()+") Already monitoring document: " + documentPath );
                return;
            }

            let hasInitialResult = false;

            collectionDocumentMonitors.set( collectionDatabase.databasePath( true ), null ); // placeholder

            const collectionDocumentMonitor = await this.collectionReference( collectionDatabase ).doc( documentId ).onSnapshot( 
                async (documentData: { exists: null; data: () => any; id: string; }) => {

                //log.debug( "("+collectionDatabase.collectionName()+")", "monitorDocument()", "snapshot", documentData );

                try {
                    if( documentData.exists != null && !documentData.exists ) {
                        throw new Error("Document not found: " + documentPath );
                    }

                    const databaseDocument = 
                        await Factory.get().databaseService.databaseFactory.documentFromData( 
                            documentPath, documentData.data() ) as DatabaseDocument;

                    if( databaseDocument == null ) {
                        throw new Error( "Could not read document: " + documentPath );
                    }

                    if( !hasInitialResult ) {

                        await collectionDatabase.onNotify( collectionDatabase, Observation.Create, documentPath, databaseDocument );

                        hasInitialResult = true;
                    }
                    else {
                        await collectionDatabase.onNotify( collectionDatabase, Observation.Update, documentPath, databaseDocument );
                    }
                    
                } catch( error ) {
                    log.warn( "("+collectionDatabase.collectionName()+")", "monitorDocument()", "snapshot", error );

                    await collectionDatabase.onNotify( collectionDatabase, Observation.Delete, documentPath, undefined );
                } 
            },
            async ( error : any ) => {
                log.warn( "("+collectionDatabase.collectionName()+")", "monitorDocument()", "Error monitoring document", error );
            });     
            
            collectionDocumentMonitors.set( collectionDatabase.databasePath( true ), collectionDocumentMonitor ); 

        } catch( error ) {

            log.warn( "Error stopping documents monitoring from firestore for", collectionDatabase.collectionName(), error );
            
            log.traceOut( "("+collectionDatabase.collectionName()+")", "monitorDocument()", undefined );
            return undefined;
        }
    }
    
    async releaseDocuments( collectionDatabase : CollectionDatabase<DatabaseDocument>, documentPaths : string[] ) : Promise<void> {
       
        //log.traceIn( "("+collectionDatabase.collectionName()+")", "releaseDocuments()", documentPaths );

        try {

            const collectionDocumentMonitors = this._documentMonitors.get( collectionDatabase.databasePath( true ) );

            if( collectionDocumentMonitors != null ) {
                documentPaths.forEach( documentPath => {

                    if( collectionDocumentMonitors.has( documentPath )) {

                        collectionDocumentMonitors.get( documentPath )!(); // unsubscribes
            
                        collectionDocumentMonitors.delete( documentPath );
                    }    
                } );
            }

            //log.traceOut( "("+collectionDatabase.collectionName()+")", "releaseDocuments()" );

        } catch( error ) {

            log.warn( "Error stopping documents monitoring from firestore for", collectionDatabase.collectionName(), error );
            
            log.traceOut( "("+collectionDatabase.collectionName()+")", "releaseDocuments()", error );
        }

    }

    async releaseAllDocuments( collectionDatabase : CollectionDatabase<DatabaseDocument> ) : Promise<void> {
 
        //log.traceIn( "("+collectionDatabase.collectionName()+")", "releaseAllDocuments()");

        try {

            let collectionDocumentMonitors = this._documentMonitors.get( collectionDatabase.databasePath( true ) );

            if( collectionDocumentMonitors == null ) {
                //log.traceOut( "("+collectionDatabase.collectionName()+")", "releaseAllDocuments()", "No monitors" );
                return;
            }
            collectionDocumentMonitors.forEach( documentMonitor => {

                documentMonitor();

            } );

            this._documentMonitors.delete( collectionDatabase.databasePath( true ) );

            //log.traceOut( "("+collectionDatabase.collectionName()+")", "releaseAllDocuments()" );

        } catch( error ) {

            log.warn( "Error stopping all documents monitoring from firestore for", collectionDatabase.collectionName(), error );
            
            log.traceOut( "("+collectionDatabase.collectionName()+")", "releaseAllDocuments()", error );
        }
    }
 }