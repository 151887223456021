

import covid19 from "../data/collections/hazards/covid19/covid19.json"; 
//import ebola from "../../data/collections/hazards/ebola/ebola.json";
import influenza from "../data/collections/hazards/influenza/influenza.json";

import { ConfigurationManager } from "../../services/configuration/framework/configurationManager";
import { log } from "../../services/configuration/framework/configurationService";


export class HealthguardConfigurationManager extends ConfigurationManager  {

    async loadDocuments() : Promise<boolean> {
        log.traceIn( "loadDocuments()" ); 

        try {

            await this.loadDocument( covid19 );
            //this.loadDocument( ebola );
            await this.loadDocument( influenza );

            log.traceOut("loadDocuments()" );
            return true;
            
        } catch( error ) {

            log.warn( "Error loading documents", error );
            
            throw new Error( "Error loading documents" );
        }
    }
}