

import { Language } from "../../database/api/definitions/language";
import { log } from "../api/factory";
import { TranslatorIF } from "../api/translatorIF";

const localesDirectory =  __dirname != null && __dirname.length > 0 ?
    __dirname + "/../../..healthguard/data/locales" :
    "../../..healthguard/data/locales"; 

export abstract class Translator implements TranslatorIF {

    async loadTranslations( namespace : string, language? : Language  ) : Promise<any> {

        try {
            const useLanguage = language != null ? language : this.defaultLanguage();

            const translations = await import(
                localesDirectory + "/" + useLanguage + "/" + namespace + ".json");

            if( translations == null ) {
                throw new Error( "Translations not found for language: " + useLanguage )
            }

            return translations;

        } catch (error) {

            log.warn("Error loading translations for namespace: " + namespace, error);

            throw new Error( (error as any).message );
        }

    }

    translate( key? : string, translations? : any, language? : Language ) : string {

        try {

            if( key == null ) {
                return "";
            }

            const useLanguage = language != null ? language : this.defaultLanguage();

            let data;

            if (typeof translations === 'object') {

                data = translations;
            }
            else if (typeof translations === 'string') {

                try {
                    data = JSON.parse(translations);
                } catch (error) {
                    data = translations;
                }
            }

            let translation = data[useLanguage];

            if (translation != null) {

                if (key != null) {

                    key.split('.').forEach(key => {
                        if (translation != null) {
                            translation = translation[key]
                        }
                    });
                }

                if (translation != null && typeof translation === 'string' ) {

                    return translation;
                }
            }

            let defaultTranslation = data;

            if (key != null) {
                key.split('.').forEach(key => {
                    if (defaultTranslation != null) {
                        defaultTranslation = defaultTranslation[key];
                    }
                });
            }

            if( defaultTranslation == null || !(typeof defaultTranslation === 'string')) {
                return "";
            }

            return defaultTranslation;

        } catch (error) {

            log.warn("Error translating: " + key, error);

            return "";
        }
    }
    abstract defaultLanguage() : Language;

    abstract setDefaultLanguage( defaultLanguage : Language ) : void

}