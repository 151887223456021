import { log } from "../../framework/databaseService";
import { DatabaseDocument } from "../../framework/databaseDocument";
import { Factory } from "../../../common/api/factory";
import { Monitor } from "../../../common/api/monitor";
import { ObservableIF } from "../../../common/api/observableIF";
import { Observation } from "../../../common/api/observation";
import { CollectionGroupDatabaseIF } from "../../api/core/collectionGroupDatabaseIF";
import { CollectionGroupPathSuffix, DocumentNameKey, NewDocumentId } from "../../api/core/databaseServiceIF";
import { DatabaseManager } from "../../framework/databaseManager";
import { ReferenceHandle } from "../..";

import databaseConfiguration from "../../../../healthguard/data/settings/database.json";
import { Database } from "./database";

export class CollectionGroupDatabase<DerivedDocument extends DatabaseDocument> 
    extends Database<DerivedDocument> implements CollectionGroupDatabaseIF<DerivedDocument> {

    constructor( databaseManager : DatabaseManager, 
        collectionName : string,
        queryDocumentName: string | undefined,
        documentNames : string[],
        allowRootCollection? : boolean,
        encrypted? : boolean,
        owner? : DatabaseDocument ) { 

        super(collectionName, queryDocumentName, documentNames, owner ); 

        this.databaseManager = databaseManager;

        this.allowRootCollection = !!allowRootCollection;

        this.encrypted = !!encrypted;

        this.onNotify = this.onNotify.bind(this);
        this.timedCollectionGroupRelease = this.timedCollectionGroupRelease.bind(this);

        //log.traceInOut( "("+this.collectionName()+")", "constructor()" );
    }

    databasePath( includeDocumentName? : boolean ) : string {

        let path = "";

        if( this.owner() != null ) {

            path += this.owner()!.databasePath();
        }

        path += "/" + this.collectionName()+ CollectionGroupPathSuffix;

        if( !!includeDocumentName && this.queryDocumentName() != null  ) {
            path += "?" + DocumentNameKey + "=" + this.queryDocumentName();
        }

        return path;
    }

    async documents(): Promise<Map<string,DerivedDocument>> {
        log.traceIn( "("+this.collectionName()+")", "documents()" );

        try {
            let documents : Map<string,DerivedDocument> = new Map<string,DerivedDocument>();

            if( this.databaseManager.isMonitoringCollectionGroup( this ) ) {

                const monitorCache = 
                    CollectionGroupDatabase._monitorCaches.get( this.databasePath( true ) ) as Map<string, DerivedDocument>;               

                log.traceOut( "("+this.collectionName()+")", "documents()", "from cache" );
                return monitorCache!;    
            }
            else {
                documents = await this.databaseManager.collectionGroup( this ) as Map<string,DerivedDocument>;

                log.traceOut( "("+this.collectionName()+")", "documents()", "from lookup" );
                return documents;
            }
        } catch( error ) {
            log.warn( "("+this.collectionName()+")", "documents()", "Error reading database objects", error );

            throw new Error( (error as any).message );
        }
    }

    newDocument(documentPath?: string ): DerivedDocument {

        //log.traceIn( "("+this.collectionName()+")", "newDocument()", documentPath );

        try {

            let databaseDocument;

            if( documentPath != null ) { 
                databaseDocument = 
                    Factory.get().databaseService.databaseFactory.newDocumentFromUrl( documentPath ) as DerivedDocument;
            }
            else {

                let newDocumentPath =  "";

                if( this.owner() != null ) {
        
                    newDocumentPath += this.owner()!.databasePath();
                }
        
                newDocumentPath += "/" + this.collectionName()+ "/" + NewDocumentId;

                if( this.queryDocumentName() != null ) {
                    newDocumentPath += "?" + DocumentNameKey + "=" + this.queryDocumentName();
                }
        
                databaseDocument = 
                    Factory.get().databaseService.databaseFactory.newDocumentFromUrl( newDocumentPath ) as DerivedDocument;
            }

            //log.traceOut( "("+this.collectionName()+")", "newDocument()", document );
            return databaseDocument;
    
        } catch( error ) {

            log.warn( "("+this.collectionName()+")", "newDocument()", "Error reading database object", error );

            throw new Error( (error as any).message );
        }
    }

    async document(documentPath: string ): Promise<DerivedDocument | undefined> {

        //log.traceIn( "document()", documentPath );

        try {

            let databaseDocument = 
                Factory.get().databaseService.databaseFactory.newDocumentFromUrl( documentPath ) as DerivedDocument;

            await this.readDocument( databaseDocument );

            //log.traceOut( "("+this.collectionName()+")", "document()" );
               
            return databaseDocument;

        } catch( error ) {

            log.warn( "document()", "Error reading database object", error );

            throw new Error( (error as any).message );
        }
    }


    async referenceHandles(): Promise<Map<string,ReferenceHandle<DerivedDocument>>> {
        log.traceIn( "("+this.collectionName()+")", "referenceHandles()");

        try {
            let result = new Map<string,ReferenceHandle<DerivedDocument>>();

            if( this.databaseManager.isMonitoringCollectionGroup( this ) ) {

                const monitorCache = 
                    CollectionGroupDatabase._monitorCaches.get( this.databasePath( true ) ) as Map<string, DerivedDocument>;

                if( monitorCache != null ) {

                    monitorCache!.forEach( ( databaseDocument ) => {

                        const referenceHandle = databaseDocument.referenceHandle() as ReferenceHandle<DerivedDocument>;

                        result.set( referenceHandle.path, referenceHandle );
                    });
                }

                log.traceOut( "("+this.collectionName()+")", "referenceHandles()", "from cache", result ); 
                return result;    
            }
            else {
                result = await this.databaseManager.groupReferenceHandles( this ) as Map<string,ReferenceHandle<DerivedDocument>>;

                log.traceOut( "("+this.collectionName()+")", "referenceHandles()", result );

                return result;
            }

        } catch( error ) {

            log.warn( "("+this.collectionName()+")", "referenceHandles()", "Error reading database reference handles", error );

            throw new Error( (error as any).message );
        }
    }


    async readDocument(databaseDocument: DerivedDocument ): Promise<boolean> {
        //log.traceIn( "readDocument()", databaseDocument.databasePath(), monitor );

        try {
            let exists = false;

            const documentPath = databaseDocument.databasePath( true );

            const monitorCache = 
                CollectionGroupDatabase._monitorCaches.get( this.databasePath( true ) ) as Map<string, DerivedDocument>;

            if( monitorCache != null && monitorCache.has( this.documentCacheKey( databaseDocument.databasePath( true ) ) ) ) {

                const cachedDocument = monitorCache.get( this.documentCacheKey( databaseDocument.databasePath( true ) ) );

                if( cachedDocument != null ) { 
                    exists = true;

                    databaseDocument.copyFrom( cachedDocument );

                    //log.debug( "("+this.collectionName()+")", "readDocument()", "read from cache", databaseDocument.referenceHandle().title);
                }
            }
            else {

                const databaseDocument = await Factory.get().databaseService.databaseFactory.documentFromUrl( documentPath );

                if( databaseDocument != null ) {

                    exists = true;

                    databaseDocument.copyFrom( databaseDocument );

                    //log.debug( "("+this.collectionName()+")", "readDocument()", "read from database", databaseDocument.referenceHandle().title);
                }
            }
            

           //log.traceOut( "readDocument()", exists );
            return exists;

        } catch( error ) {

            log.warn( "("+this.collectionName()+")", "readDocument()", "Error reading database object", error );

            throw new Error( (error as any).message );
        }
    }

    onNotify = async (observable : ObservableIF, 
        observation : Observation, 
        objectId? : string, 
        object? : any) : Promise<void> => {
            
        //log.traceIn( "onNotify()", Observation[observation], objectId );

        try {

            const monitorCache = 
                CollectionGroupDatabase._monitorCaches.get( this.databasePath( true ) ) as Map<string, DerivedDocument>;

            if( monitorCache != null ) {

                let result :  Map<string, DerivedDocument>;

                switch( observation ) {

                    case Observation.Create:
                    case Observation.Update:
                    {
                        if( Factory.get().databaseService.databaseFactory.equalDatabasePaths( objectId!, this.databasePath( true ) ) ) {
                            
                            const initialResult = object as Map<string,DerivedDocument>;

                            if( initialResult != null ) {
                                
                                for( const databaseDocument of initialResult.values() ) {

                                    monitorCache.set( this.documentCacheKey( databaseDocument.databasePath( true ) ), databaseDocument );
                                }
                            }
                        }
                        else {
                            const databaseDocument = object as DerivedDocument;
    
                            monitorCache.set( this.documentCacheKey( databaseDocument.databasePath( true ) ), databaseDocument );
                        } 

                        result = monitorCache;

                        break;
                    }
                    case Observation.Delete:
                    {
                        monitorCache!.delete( this.documentCacheKey( objectId! ));

                        result = new Map<string,DerivedDocument>();

                        result.set( objectId!, object as DerivedDocument );

                        break;
                    }
                    default: 
                      throw new Error( "Unrecognized observation: " + observation );                  
                }

                for( const monitor of super.observers().values() ) { 

                    this.notifyMonitor( observation, monitor, result );
                } 
            }


   
            //log.traceOut( "onNotify()" ); 

        } catch( error ) {

            log.warn( "onNotify()", "Error deleting database object", error );

            throw new Error( (error as any).message );
        }
    }

    protected async monitor( newMonitor : Monitor ): Promise<void> {

        //log.traceIn("(" + this.collectionName()+ ")", "monitor()");

        try {
            const releaseTimeout =
                CollectionGroupDatabase._releaseTimeouts.get(this.databasePath(true)) as NodeJS.Timeout;

            if (releaseTimeout != null) {

                clearTimeout(releaseTimeout);

                CollectionGroupDatabase._releaseTimeouts.delete(this.databasePath(true));
            }

            let monitorCache =
                CollectionGroupDatabase._monitorCaches.get( this.databasePath( true ) ) as Map<string, DerivedDocument>;

            if ( !this.databaseManager.isMonitoringCollectionGroup(this) ) {

                monitorCache = new Map<string, DerivedDocument>(); 

                CollectionGroupDatabase._monitorCaches.set( this.databasePath( true ), monitorCache );

                await this.databaseManager.monitorCollectionGroup(this);
                
                //log.debug("(" + this.collectionName()+ ")", "monitor()", "Started monitoring entire collection group");
            }

            if( monitorCache == null ) {
                throw new Error( "Inconsistent cache states with database manager")
            }

            this.notifyMonitor( Observation.Create, newMonitor, monitorCache );
   
            //log.traceOut("(" + this.collectionName()+ ")", "monitor()", "result size", monitorCache.size );
            return;

        } catch (error) {
            log.warn("Error starting monitoring from firestore for", this.collectionName(), error);

            throw new Error( (error as any).message );
        }
    }

    protected async release(): Promise<void> {

        //log.traceIn("(" + this.collectionName()+ ")", "release()");

        try {

            let releaseTimeout =
                CollectionGroupDatabase._releaseTimeouts.get(this.databasePath(true)) as NodeJS.Timeout;

            if (releaseTimeout != null) {
                //log.traceOut("(" + this.collectionName()+ ")", "release()", "Already being released");
                return;
            }

            const cacheReleaseSeconds = +Factory.get().configurationService.config(
                databaseConfiguration, "cacheReleaseSeconds")!;

            if (isNaN(cacheReleaseSeconds)) {
                throw new Error("Invalid cache release timeout: " + cacheReleaseSeconds);
            }

            releaseTimeout = setTimeout(this.timedCollectionGroupRelease, cacheReleaseSeconds * 1000 );

            CollectionGroupDatabase._releaseTimeouts.set(this.databasePath(true), releaseTimeout );

            //log.traceOut("(" + this.collectionName()+ ")", "release()", "Stopped monitoring all ids for all observations");

        } catch (error) {

            log.warn("Error stopping monitoring collection group", this.collectionName(), error);

            throw new Error( (error as any).message );
        }
    }

    private timedCollectionGroupRelease = async (): Promise<void> => {

        //log.traceIn("(" + this.collectionName()+ ")", "timedCollectionGroupRelease()", observationFilter, objectIdsFilter);

        try {

            await this.databaseManager.releaseCollectionGroup(this);

            CollectionGroupDatabase._monitorCaches.delete( this.databasePath( true ) );

            //log.traceOut("(" + this.collectionName()+ ")", "timedCollectionGroupRelease()", "Stopped monitoring all ids for all observations");

        } catch (error) {

            log.warn("Error stopping monitoring from firestore for", this.collectionName(), error);
        }
    }

    readonly databaseManager : DatabaseManager;

    readonly allowRootCollection : boolean;

    readonly encrypted : boolean;

    protected static _monitorCaches = new Map<string,Map<string,DatabaseDocument>>();

    private static _releaseTimeouts = new Map<string,NodeJS.Timeout>();


} 