
import { DatabaseObject } from "../../framework/databaseObject";
import { log } from "../../framework/databaseService";
import { DatabaseSubdocument } from "../../framework/databaseSubdocument";
import { TextProperty } from "../properties/textProperty";
import { ConnectionSubdocument } from "../../api/subdocuments";
import { DefinitionProperty } from "../properties/definitionProperty";
import { ConnectionStatus, ConnectionStatuses } from "../../api/definitions/connectionStatus";
import { TextType, TextTypes } from "../../api/definitions/textTypes";
import { ConnectionIF } from "../../api/subdocuments/connectionIF";
import { ConnectionMethodDefinition, ConnectionStatusDefinition } from "../../api/definitions";
import { ConnectionMethod, ConnectionMethods } from "../../api/definitions/connectionMethod";


export class Connection extends DatabaseSubdocument implements ConnectionIF {

    constructor( parent : DatabaseObject, key : string ) {

        super( ConnectionSubdocument, parent, key ); 

        try {
            this.connectionStatus = new DefinitionProperty<ConnectionStatus>( 
                this, 
                ConnectionStatusDefinition, 
                ConnectionStatuses );

            this.connectionStatus.editable = false;

            this.errorMessage = new TextProperty( this );

            this.errorMessage.editable = false;

            this.connectionMethod = new DefinitionProperty<ConnectionMethod>( 
                this, 
                ConnectionMethodDefinition, 
                ConnectionMethods );

            this.host = new TextProperty( this );

            this.key = new TextProperty( this, TextTypes.Password as TextType ); 

            this.secret = new TextProperty( this, TextTypes.Password as TextType ); 

            //log.traceInOut( "constructor()" );

        } catch( error ) {

            log.warn( "constructor()", "Error initializing address", error );
            
            throw new Error( (error as any).message );
        }
    }
    connectionStatus: DefinitionProperty<ConnectionStatus>;

    errorMessage: TextProperty;

    connectionMethod: DefinitionProperty<ConnectionMethod>;

    host: TextProperty;

    key: TextProperty;

    secret: TextProperty;

 }
