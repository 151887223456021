import React, { useContext } from 'react';

import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

import DocumentView, { DocumentProps } from '../components/documentView';
import DatabaseTable, { StartDateProperty, DocumentNameProperty, DatabaseTableProps } from 'ui/components/databaseTable';
import DatabaseList, { DatabaseListProps }  from 'ui/components/databaseList';
import DatabaseCard, { DatabaseCardProps } from 'ui/components/databaseCard';
import DatabaseChart, { DatabaseChartProps } from 'ui/components/databaseChart';
import DatabaseGraph, { DatabaseGraphProps } from 'ui/components/databaseGraph';

import DatabaseView from '../components/databaseView';
import { RegistrationIF } from 'healthguard/api/documents/registrationIF';
import { AppContext, AppContextProps } from 'ui/app/appContext';
import { PropertiesSelector } from '../../services/database/api/core/propertiesSelector';
import { CompanyDocument, UnitDocument, UserDocument } from '../../services/database/api/documents';
import { IncidentRegistrationDocument, QuarantineRegistrationDocument, SickLeaveRegistrationDocument, SymptomRegistrationDocument, TestRegistrationDocument, VaccineRegistrationDocument } from '../../healthguard/api/registrationDocuments';
import { HazardDocument} from '../../healthguard/api/healthguardDocuments';
import { HealthguardCompanyIF } from '../../healthguard/api/documents/healthguardCompanyIF';
import { DatabaseObserverIF } from '../../services/database/api/core/databaseObserverIF';
import { DatabaseQuery } from '../../services/database/api/core/databaseQuery';
import { log } from '../app/app';
import { DatabaseDocumentIF } from '../../services/database/api/core/databaseDocumentIF';
import { errorDialog } from '../components/simpleDialog';
import { selectRegistration } from '../components/selectDialog';
import { DocumentNameKey } from '../../services/database/api/core/databaseServiceIF';
import { Factory } from '../../services/common/api/factory';
import { documentDialog } from '../components/documentDialog';

export const DefaultRegistrationTableColumns = 
  [StartDateProperty,
    DocumentNameProperty,
    UserDocument,
    UnitDocument,
    CompanyDocument,
    HazardDocument,
    "locations"] as string[];




export const DefaultAssociationsFilter = {

  includePropertyKeys: [
    "measures",
    "locations",
    "categories",
    "projects",
    "gathering",
    "residence"]

} as PropertiesSelector;

export const defaultHighlightedRegistrationsProperty = DocumentNameProperty;


const addRegistration = async (  props: {
  appContext : AppContextProps, 
  t : TFunction, 
  databaseObserver : DatabaseObserverIF<DatabaseDocumentIF> } ) => {

  log.traceIn("addRegistration()");
  try {

    let path = props.appContext.currentHomePath! as string;

    const addDocumentName = await selectRegistration( props.appContext, props.t("addRegistration") + ":")

    if( addDocumentName == null ) {
      log.traceIn("addRegistration()", "None selected");
      return;
    }

    let newDocument = props.databaseObserver.newDocument()!;

    path += newDocument.databasePath() + "?" + DocumentNameKey + "=" + addDocumentName;

    newDocument = Factory.get().databaseService.databaseFactory.newDocument( 
      newDocument.collectionDatabase, path );

    await documentDialog( newDocument );

    log.traceOut("addRegistration()", path);
    
  } catch( error ) {
    log.warn( "addRegistration()", error );

    await errorDialog( error);
  }
}


const updateDatabaseObserver = ( databaseObserver : DatabaseObserverIF<RegistrationIF>, company? : HealthguardCompanyIF ) : DatabaseObserverIF<RegistrationIF> => {

  databaseObserver.setIgnoreDocumentNames( hiddenCompanyDocumentNames( company ) );
  
  return databaseObserver;
  
}


const hiddenCompanyDocumentNames = ( company? : HealthguardCompanyIF ) : string[] | undefined => {

  if( company == null ) {
    return undefined;
  }

  const hiddenDocuments : string[] = [];

  if( !!company?.incidentRegistrationsPrivacy.subdocument().disabled.value() ) {
    hiddenDocuments.push( IncidentRegistrationDocument )
  }
  if( !!company?.quarantineRegistrationsPrivacy.subdocument().disabled.value() ) {
    hiddenDocuments.push( QuarantineRegistrationDocument )
  }
  if( !!company?.sickLeaveRegistrationsPrivacy.subdocument().disabled.value() ) {
    hiddenDocuments.push( SickLeaveRegistrationDocument )
  }
  if( !!company?.symptomRegistrationsPrivacy.subdocument().disabled.value() ) {
    hiddenDocuments.push( SymptomRegistrationDocument )
  }
  if( !!company?.testRegistrationsPrivacy.subdocument().disabled.value() ) {
    hiddenDocuments.push( TestRegistrationDocument )
  }
  if( !!company?.vaccineRegistrationsPrivacy.subdocument().disabled.value() ) {
    hiddenDocuments.push( VaccineRegistrationDocument )
  }
  
  return( hiddenDocuments.length > 0 ? hiddenDocuments : undefined );
  
}


export function RegistrationsTable(props: DatabaseTableProps ) {

  const appContext = useContext(AppContext);

  const { t } = useTranslation();

  const [databaseObserver,setDatabaseObserver] = React.useState<DatabaseObserverIF<RegistrationIF> | undefined>();

  React.useEffect( () => {

    setDatabaseObserver( updateDatabaseObserver( props.databaseObserver as DatabaseObserverIF<RegistrationIF>, appContext.currentCompany) );

  }, [appContext.currentCompany, props.databaseObserver]);

  return (databaseObserver == null ? <></> : 
    <DatabaseTable {...props} 
      featuredProperties={props.featuredProperties != null ? props.featuredProperties : DefaultRegistrationTableColumns} 
      databaseObserver={databaseObserver}
      multipleDocuments={true}
      onAddDocument={( databaseObserver ) => addRegistration( {
        appContext: appContext,
        t: t,
        databaseObserver: databaseObserver
      })}
    />
  );
}

export function RegistrationsList(props: DatabaseListProps ) {

  const appContext = useContext(AppContext);

  const { t } = useTranslation();

  const [databaseObserver,setDatabaseObserver] = React.useState<DatabaseObserverIF<RegistrationIF> | undefined>();

  React.useEffect( () => {

    setDatabaseObserver( updateDatabaseObserver( props.databaseObserver as DatabaseObserverIF<RegistrationIF>, appContext.currentCompany) );

  }, [appContext.currentCompany, props.databaseObserver]);

  return (databaseObserver == null ? <></> : 
    <DatabaseList {...props} 
      databaseObserver={databaseObserver}
      multipleDocuments={true}
      onAddDocument={( databaseObserver ) => addRegistration( {
        appContext: appContext,
        t: t,
        databaseObserver: databaseObserver
      })}
    />
  );
}


export function RegistrationsCard( props: DatabaseCardProps ) {

  const appContext = useContext(AppContext);

  const { t } = useTranslation();

  const [databaseObserver,setDatabaseObserver] = React.useState<DatabaseObserverIF<RegistrationIF> | undefined>();

  React.useEffect( () => {

    setDatabaseObserver( updateDatabaseObserver( props.databaseObserver as DatabaseObserverIF<RegistrationIF>, appContext.currentCompany) );

  }, [appContext.currentCompany, props.databaseObserver]);

  return (databaseObserver == null ? <></> : 
    <DatabaseCard 
      {...props} 
      databaseObserver={databaseObserver}
      highlightedPropertyKey={props.highlightedPropertyKey != null ? props.highlightedPropertyKey : defaultHighlightedRegistrationsProperty}
      multipleDocuments={true}
      onAddDocument={( databaseObserver ) => addRegistration( {
        appContext: appContext,
        t: t,
        databaseObserver: databaseObserver
      })}
    />
  );
} 

export function RegistrationsChart( props: DatabaseChartProps ) {

  const appContext = useContext(AppContext);

  const { t } = useTranslation();

  const [databaseObserver,setDatabaseObserver] = React.useState<DatabaseObserverIF<RegistrationIF> | undefined>();

  React.useEffect( () => {

    setDatabaseObserver( updateDatabaseObserver( props.databaseObserver as DatabaseObserverIF<RegistrationIF>, appContext.currentCompany) );

  }, [appContext.currentCompany, props.databaseObserver]);

  return (databaseObserver == null ? <></> : 
    <DatabaseChart 
      {...props} 
      databaseObserver={databaseObserver}
      multipleDocuments={true}
      onAddDocument={( databaseObserver ) => addRegistration( {
        appContext: appContext,
        t: t,
        databaseObserver: databaseObserver
      })}
    />
  );
}

export function RegistrationsGraph( props: DatabaseGraphProps ) {

  const appContext = useContext(AppContext);

  const { t } = useTranslation();

  const [databaseObserver,setDatabaseObserver] = React.useState<DatabaseObserverIF<RegistrationIF> | undefined>();

  React.useEffect( () => {

    setDatabaseObserver( updateDatabaseObserver( props.databaseObserver as DatabaseObserverIF<RegistrationIF>, appContext.currentCompany) );

  }, [appContext.currentCompany, props.databaseObserver]);

  return (databaseObserver == null ? <></> : 
    <DatabaseGraph
      {...props}
      databaseObserver={databaseObserver}
      multipleDocuments={true}
      previousPropertyKey={HazardDocument}
      nextPropertyKey={"name"}
      directed={false} 
      onAddDocument={( databaseObserver ) => addRegistration( {
        appContext: appContext,
        t: t,
        databaseObserver: databaseObserver
      })}
    />
  );
}


export function RegistrationsView(props: { 
  databaseQuery : DatabaseQuery<RegistrationIF>,
  title? : string,
  featuredProperties?: string[],
  highlightedPropertyKey?: string }) {

  const appContext = useContext(AppContext);

  const { t } = useTranslation();

  const [databaseQuery,setDatabaseQuery] = React.useState<DatabaseQuery<RegistrationIF> | undefined>();

  React.useEffect( () => {

    const modifiedDatabaseQuery = props.databaseQuery;

    modifiedDatabaseQuery.ignoreDocumentNames = hiddenCompanyDocumentNames( appContext.currentCompany );

    setDatabaseQuery( modifiedDatabaseQuery );

  }, [appContext.currentCompany, props.databaseQuery]);


  return ( databaseQuery == null ? <></> : 
    <AppContext.Consumer>
      {appContext => (
        <DatabaseView
          databaseQuery={databaseQuery}
          multipleDocuments={true}
          title={props.title} 
          featuredProperties={props.featuredProperties != null ? props.featuredProperties : DefaultRegistrationTableColumns}
          highlightedPropertyKey={props.highlightedPropertyKey != null ? props.highlightedPropertyKey : defaultHighlightedRegistrationsProperty}
          onAddDocument={( databaseObserver ) => addRegistration( {
            appContext: appContext,
            t: t,
            databaseObserver: databaseObserver
          })}
        />
      )}
    </AppContext.Consumer>
  );
}


export function RegistrationView( props : DocumentProps ) {

  const RegistrationBasicsFilter = {

    includePropertyKeys: [
      UnitDocument,
      UserDocument,
      "startDate",
      "endDate"
    ]

  } as PropertiesSelector;


  const defaultRegistrationSteps = (): Map<string, PropertiesSelector> => {

    let defaultSteps = new Map<string, PropertiesSelector>();

    defaultSteps.set("basics", RegistrationBasicsFilter);
    defaultSteps.set("associations", DefaultAssociationsFilter);

    return defaultSteps;
  } 

  return (<DocumentView 
    {...props}
    inputTabs={props.inputTabs != null ? props.inputTabs : defaultRegistrationSteps()} 
  />);
}





