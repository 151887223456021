
import React from 'react';

import { format } from 'date-fns';

import { IconButton, CircularProgress, List, ListItem, ListItemIcon, ListItemText, TextField, makeStyles, Theme, createStyles, Box, Grid, Typography } from '@material-ui/core';

import Autocomplete, { AutocompleteChangeReason } from '@material-ui/lab/Autocomplete';

import CloseIcon from '@material-ui/icons/Close';

import SearchIcon from '@material-ui/icons/Search'; 
import AddIcon from '@material-ui/icons/Add'; 
import SortIcon from '@material-ui/icons/Sort'; 

import { DatabaseDocumentIF, ReferenceHandle } from '../../services/database';
import { log } from '../app/app';
import CollectionIcon from './collectionIcon';
import theme from '../app/theme';
import { Factory } from '../../services/common/api/factory';
import { useTranslation } from 'react-i18next';
import { selectDialog } from './selectDialog';
import { activeLanguage, dateFormat } from '../app/localization';

export const SortFields = {
  Title : "title",
  Date : "date"
}
export type SortField = keyof (typeof SortFields); 

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%'
    },
    listBox: {
      marginRight: theme.spacing(0),
      paddingBottom: theme.spacing(2), 
      maxHeight: "100%",
      overflow: 'auto' 
    },
    emptyOptions: {
      paddingTop: "15vh",
      display: "flex",
      width:"100%", 
      height: "100%"
    },
    emptyList: {
      paddingTop: "15vh",
      display: "flex",
      width:"100%", 
      height: "100%"
    },
    list: {
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(0)
    },
    listItem: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(0)
    },
    listItemText1: {
    },    
    listItemText2: {
      paddingTop: theme.spacing(0.5),
    }
  }),
);

export function DocumentsList( 
  props: { 
    collectionName: string,
    handles : Map<string,ReferenceHandle<DatabaseDocumentIF>>,   
    placeholder? : string,
    label? : string,
    allowSelect? : boolean,
    allowAdd? : boolean,
    allowRemove? : boolean,
    showDate? : boolean,
    hideEmptyFilterOptions? : boolean, 
    onOptions? : () => Promise<Map<string,ReferenceHandle<DatabaseDocumentIF>>>,
    onAddDocument? : () => void,
    onSelectHandle? : ( handle : ReferenceHandle<DatabaseDocumentIF> ) => void,
    onAddedHandle? : ( handle : ReferenceHandle<DatabaseDocumentIF> ) => void
    onRemoveHandle? : ( handle : ReferenceHandle<DatabaseDocumentIF> ) => void 
  }) {

  const classes = useStyles();

  const { t } = useTranslation();

  const [open, setOpen] = React.useState( false );
  const [loading, setLoading] = React.useState( false );
  const [filter, setFilter] = React.useState( "" );
  const [sortField,setSortField] = React.useState<SortField>( SortFields.Title as SortField );
  const [options, setOptions] = React.useState<Map<string,ReferenceHandle<DatabaseDocumentIF>> | undefined>( undefined );


  React.useEffect( () => {

    (async () => {
 
      if( props.onOptions != null ) {
  
        setLoading( open && options == null );

        if( open && options == null ) {

          const newOptions = await props.onOptions();

          setOptions( newOptions );
        }

      }
       
    })();
    
  }, [open,options,props]); 

  const selectSortColumn = async () => {

    log.traceIn("selectSortColumn()");

    const updatedSortField = await selectDialog( {
      documentNames: [props.collectionName],
      options: Object.values(SortFields),
      title: t("sort") + ":",
      value: sortField,
      disableEmptyOption: true,
      translate: true,
      sort: true 
    });

    if (updatedSortField != null) {
      setSortField(updatedSortField as SortField);
    }

    log.traceOut("selectSortColumn()");
  }


  const onSelectHandle = (handle: ReferenceHandle<DatabaseDocumentIF>) => {

    log.traceIn("onSelectHandle()", handle);

    if( props.onSelectHandle != null ) {
      props.onSelectHandle( handle );
    }

    log.traceOut("onSelectHandle()");
  }

  const onRemoveHandle = (handle: ReferenceHandle<DatabaseDocumentIF>) => {

    log.traceIn("onRemoveHandle()", handle);

    if( props.onRemoveHandle != null ) {
      props.onRemoveHandle( handle );
    }

    log.traceOut("onRemoveHandle()");
  }

  const onInputChange = async ( filter : string ) => {

    log.traceInOut( "onInputChange", {filter});

    setFilter( filter != null ? filter : "" );
  }

  const openRequest = ( event: React.ChangeEvent<{}> )  => {

    log.traceInOut( "openRequest", {event});

    setOpen( !!props.allowSelect && event.type === "mousedown" );
  }

  const filterOptions = ( options: ReferenceHandle<DatabaseDocumentIF>[] ) : ReferenceHandle<DatabaseDocumentIF>[] => {
    
    const filteredOptions = new Map<string,ReferenceHandle<DatabaseDocumentIF>>();

    for( const option of options.values() ) {

      let found = false;

      for( const referenceHandle of props.handles.values() ) {
        if( Factory.get().databaseService.databaseFactory.equalDatabasePaths( option.path, referenceHandle.path ) ) {
          found = true
          break;
        }
      }
      if( !found ) {

        if( filter == null || filter.length === 0 ||
            option.title!.toLowerCase().includes(filter.toLowerCase())) {
          
            filteredOptions.set( option.path, option );
        }
      }
    }
    return Array.from( filteredOptions.values() );
  }

  const onChange = (
    event: React.ChangeEvent<{}>,
    value: ReferenceHandle<DatabaseDocumentIF>[],
    reason: AutocompleteChangeReason) => {

    log.traceIn("change()", {value}, {reason}); 

    event.preventDefault();

    const newHandles = value != null ? value :[] as ReferenceHandle<DatabaseDocumentIF>[];

    for( const newHandle of newHandles ) {

      let foundNew = false;

      for( const existingReferenceHandle of props.handles.values() ) {

        if( Factory.get().databaseService.databaseFactory.equalDatabasePaths( newHandle.path, existingReferenceHandle.path ) ) {
          foundNew = true;
          break;
        }
      }
      if( !foundNew ) {
        if (props.onAddedHandle != null) {
          props.onAddedHandle( newHandle );
        }
      }
    }

    log.traceOut("change()");
  }

  log.debug("props.handles", props.handles );

  let filteredHandles = new Map<string,ReferenceHandle<DatabaseDocumentIF>>();

  if( filter == null || filter.length === 0 ) {

    if( !props.hideEmptyFilterOptions ) {

      filteredHandles = props.handles;
    }
  }
  else {
    for( const handle of props.handles.values() ) {

      if (filter == null || filter.length === 0 || handle.title!.toLowerCase().includes(filter.toLowerCase())) {

        filteredHandles.set( handle.path, handle); 
      }
    }
  } 

  filteredHandles = new Map([...filteredHandles].sort( (aPair, bPair) => { 

    const a = aPair[1];
    const b = bPair[1];

    if( sortField == null || sortField === SortFields.Title ) {
      return( (a?.title != null ? a.title : "").localeCompare( b?.title != null ? b.title : "", activeLanguage() ) );
    }

    if( sortField === SortFields.Date ) {
      return (a?.date != null ? a.date.getTime() : 0) - (b?.date != null ? b.date.getTime() : 0);
    }

    return 0;
  }));

  const renderHandle = (handle: ReferenceHandle<DatabaseDocumentIF>) => (
    <Grid item container alignItems="center" justifyContent="space-between" style={{ flexWrap: "nowrap" }}>
      <Grid item xs sm md>
        <ListItemText className={classes.listItemText1} primary={handle.title} />
      </Grid>
      {!!props.showDate && handle.date != null &&
        <Grid item xs sm md>
          <ListItemText className={classes.listItemText2} secondary={format(handle.date, dateFormat())} />
        </Grid>
      }
    </Grid>
  )

  return (
    <Box className={classes.root}>
      <Autocomplete
        filterSelectedOptions
        filterOptions={filterOptions}
        fullWidth
        multiple
        disableClearable
        open={open}
        onOpen={openRequest}
        onClose={() => {
          setOpen( false );
          setOptions( undefined )
        }}
        onChange={onChange} 
        getOptionSelected={(option, value) => Factory.get().databaseService.databaseFactory.equalDatabasePaths(option.path, value.path)}
        getOptionLabel={( option ) => option.title != null ? option.title : ""}
        inputValue={filter}
        options={options != null ? Array.from( options.values()) : Array.from( filteredHandles.values())}
        loading={loading}
        loadingText={t("loading") + " ..."}
        noOptionsText={t("emptyOptions")}
        value={[] as ReferenceHandle<DatabaseDocumentIF>[]}
        popupIcon={null}
        closeIcon={null}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => { 
            return ( undefined )
          })
        }
        renderInput={(params) => (
          <TextField
            {...params}
            onChange={(event) => onInputChange(event.target.value)}
            variant={"standard"}
            fullWidth
            label={props.label}
            placeholder={props.placeholder}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <>
                  {!!props.showDate && 
                    <IconButton onClick={( event ) => {event.stopPropagation(); selectSortColumn();}} size="small">
                      <SortIcon />
                    </IconButton> 
                  }
                  <IconButton disabled size="small">
                    <SearchIcon color="disabled" />
                  </IconButton> 
                  {params.InputProps.startAdornment}
                </>
              ),
              endAdornment: (
                <>
                  {!!props.allowAdd && props.onAddDocument != null ?
                    <IconButton onClick={( event ) => {event.stopPropagation(); props.onAddDocument!();}} >
                      <AddIcon />
                    </IconButton> :
                    <IconButton disabled style={{ height: theme.spacing(6) }} />
                  }
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
              style: {
                paddingLeft: theme.spacing(1),
                paddingRight: theme.spacing(0)
              },
              disableUnderline: false
            }}
          />
        )}
      />
      {filteredHandles.size === 0 ? 
        <Grid container className={classes.emptyList} justifyContent="center" alignItems="center">
          <Typography color="textSecondary">{t("noMatches")}</Typography>
        </Grid>  
        :
        <Box className={classes.listBox}>
          <List className={classes.list}>
            {Array.from(filteredHandles.values()).map(handle =>
              <ListItem
                button
                className={classes.listItem}
                key={handle.path}
                id={handle.path}
                selected={false}
                onClick={props.onSelectHandle != null ? 
                  ( event ) => { event.stopPropagation(); onSelectHandle(handle); } : 
                  undefined
                }
              >
                <Grid container alignItems="center" justifyContent="space-between" style={{ flexWrap: "nowrap" }}>
                  <ListItemIcon>
                    <CollectionIcon collectionName={props.collectionName} />
                  </ListItemIcon>
                  {renderHandle(handle)}
                  {!!props.allowRemove && props.onRemoveHandle != null &&
                    <Grid item>
                      <IconButton onClick={(event) => { event.stopPropagation(); onRemoveHandle(handle) }}>
                        <CloseIcon />
                      </IconButton>
                    </Grid>
                  }
                </Grid>
              </ListItem>
            )}
          </List>
        </Box>
      }
    </Box>
  );
}

