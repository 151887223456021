import React, { useContext } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { Button, Grid, Typography } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close'; 
import EmailIcon from '@material-ui/icons/Email'; 

import Title from 'ui/components/title';
import { log } from '../app/app';

import { Factory } from '../../services/common/api/factory';
import { useTranslation } from 'react-i18next';
import { errorDialog } from '../components/simpleDialog';
import { AppContext } from '../app/appContext';
import { TranslationKey } from '../../services/common/api/translatorIF';

import logoLightBackground from 'ui/app/logo-light.png';  
import theme from '../app/theme';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: "100%",
    height: '100%'
  },
  title: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(1),
    textAlign: 'center',
  },
  prompt: {
    textAlign: 'center',
    marginBottom: theme.spacing(1)
  },
  button: {
    width: theme.spacing(36)
  }
}));

export default function VerifyEmail( props: {} ) {

  log.traceInOut("VerifyEmail", props );

  const classes = useStyles();

  const { t } = useTranslation();

  const appContext = useContext(AppContext);

  const onSendVerificationEmail = async () => {

    log.traceIn("onSendVerificationEmail" );

    try {

      Factory.get().authenticationService!.verifyEmail();
      
    } catch( error ) {
      log.warn( "onSendVerificationPhone()", "Error sending verification to phone", error );

      await errorDialog( error);

      log.traceOut( "onSendVerificationPhone()", error );
    }
  }

  const onSignout = async () => {

    log.traceIn("onSignout" );

    try {

      await Factory.get().authenticationService!.signOut()

      log.traceOut( "onSignout()" );

    } catch( error ) {
      log.warn( "onSignout()", "Error sending verification to phone", error );

      await errorDialog( error);

      log.traceOut( "onSignout()", error );
    }
  }

  return (
    <>
      <Grid container direction="column" spacing={1} className={classes.root} alignItems="center" >
        <Grid item container className={classes.title} alignItems="center" justifyContent="center">
          <img src={logoLightBackground} alt="logo" style={{height: theme.spacing(8)}} />
        </Grid>
        <Grid item className={classes.title}>
          <Title level="h6">{t(TranslationKey.Prompts + ":welcome")}</Title>
        </Grid>
        <Grid item className={classes.prompt}>
          <Typography color="textSecondary">{t(TranslationKey.Prompts + ":pleaseVerifyEmail") + " " + appContext.authenticationClaims!.verifyEmail }</Typography>
        </Grid>
        <Grid item />
        <Grid item>
          <Button 
            className={classes.button} 
            variant="contained" 
            fullWidth 
            color="primary" 
            onClick={onSendVerificationEmail} 
            startIcon={<EmailIcon />} >
            {t( TranslationKey.Prompts + ":sendEmailVerificationLink")}
          </Button>  
        </Grid>
        <Grid item>
          <Button 
            className={classes.button} 
            variant="contained" 
            fullWidth 
            onClick={onSignout} 
            startIcon={<CloseIcon />}>
            {t("cancel")}
          </Button> 
        </Grid>
        <Grid item />
      </Grid>
    </> 
  );
}