import parse from 'json-templates'

import { log } from '../messagingService';
import { NotificationParameters } from '../../api/notification/notificationParameters';
import { NotificationTemplate } from '../../api/notification/notificationTemplate';
import { NotificationSenderIF } from '../../api/notification/notificationSenderIF';
import { ReferenceHandle } from '../../../database/api/core/referenceHandle';
import { MessageCategories, MessageCategory } from '../../../database/api/definitions/messageCategory';
import { NotificationStatus, NotificationStatuses } from '../../../database/api/definitions/notificationStatus';
import { Message } from '../../../database/impl/documents/message';
import { Notification } from '../../../database/impl/subdocuments/notification';
import { Unit } from '../../../database/impl/documents/unit';
import { Company } from '../../../database/impl/documents/company';
import { Factory } from '../../../common/api/factory';
import { User } from '../../../database/impl/documents/user';
import { Change } from '../../../database/impl/documents/change';

import { HomePaths } from '../../../common/api/homePaths';
import { Contactable } from '../../../database/impl/documents/contactable';
import { NotificationType } from '../../../database/api/definitions/notificationType';

import { ReadinessLevel } from '../../../../healthguard/api/definitions/readinessLevel';
import { ReadinessLevelDefinition } from '../../../database/api/definitions';
import { TranslationKey } from '../../../common/api/translatorIF';
import { MeasuresCollection } from '../../../../healthguard/api/healthguardCollections';

import { DatabaseDocument } from '../../../database/framework/databaseDocument';

import applicationConfiguration from "../../../../healthguard/data/settings/application.json";

import verifyEmailNotification from "../../../../healthguard/data/notifications/verifyEmail.json";

export class NotificationSender implements NotificationSenderIF {

    async createNotification( params: {
        messageCategory : MessageCategory,
        sender? : Contactable,  
        receiver : Contactable, 
        notificationTemplate: NotificationTemplate, 
        notificationParameters?: NotificationParameters } ): Promise<Message> {

        log.traceIn("createNotification()", params.notificationTemplate, params.notificationParameters );

        try {

            const template = parse(params.notificationTemplate);

            for( const parameter of template.parameters ) {

                if( params.notificationParameters![parameter.key] == null && 
                    parameter.defaultValue == null ) {

                    throw new Error( "Missing parameter in template: " + parameter.key )
                }
            }

            const notificationData = template(params.notificationParameters) as any;

            log.debug("createNotification()", { notification: notificationData });

            const receiverReferenceHandle = params.receiver.referenceHandle() as ReferenceHandle<Contactable>; 

            const message = params.receiver.messages.collection().newDocument() as Message; 

            message.messageCategory.setValue( params.messageCategory ); 

            const receivers = new Map<string,ReferenceHandle<Contactable>>();
            
            receivers.set( receiverReferenceHandle.path, receiverReferenceHandle );

            message.receivers.setValue( receivers );

            message.sender.setValue( params.sender?.referenceHandle() as ReferenceHandle<Contactable> );

            message.title.setValue( notificationData.title?.default != null ? notificationData.title.default : notificationData.title );

            message.body.setValue( notificationData.body?.default != null ? notificationData.body.default : notificationData.body );

            const messageLinks = notificationData.links?.default != null ? notificationData.links?.default : notificationData.links;

            if( messageLinks != null ) {

                messageLinks.forEach( ( action : any ) => {
                
                    message.links.setLink( action.title, action.url );
                });
            }
            const contactability = params.receiver.contactability( params.messageCategory );

            const emailNotification = message.emailNotification.subdocument();

            if( !!contactability.email.value() ) {

                this.readNotification( emailNotification, notificationData );
            }

            const smsNotification = message.smsNotification.subdocument();

            if( !!contactability.sms.value() && params.receiver.phoneNumber.cleanValue() != null ) {

                this.readNotification( smsNotification, notificationData );
            }

            const pushNotification = message.pushNotification.subdocument();

            if( !!contactability.push.value() ) {

                this.readNotification( pushNotification, notificationData );
            }          

            await message.create();

            log.traceOut("createNotification()", message.referenceHandle().title);
            return message;

        } catch (error) {
            log.warn("createNotification()", "Error sending message", error);

            throw new Error( (error as any).message );
        }
    }

    private readNotification( 
        notification : Notification,
        notificationData: any ) {

        log.traceIn("readNotification()");

        try {

            const notificationType = notification.notificationType.value()!

            const title = this.readNotificationParameter( notificationType, notificationData, notification.title.key() );
            notification.title.setValue(title);

            log.debug("readNotification()", {notificationType}, {title} );
            
            const subtitle = this.readNotificationParameter( notificationType, notificationData, notification.subtitle.key() );
            notification.subtitle.setValue(subtitle);

            log.debug("readNotification()", {notificationType}, {subtitle} );

            const body = this.readNotificationParameter( notificationType, notificationData, notification.body.key() );
            notification.body.setValue(body);

            log.debug("readNotification()", {notificationType}, {body} );
           
            const links = this.readNotificationParameter( notificationType, notificationData, "links" );

            log.debug("readNotification()", {notificationType}, {links});

            if( links != null ) {

                links.forEach( ( action : any ) => {
                
                    notification.links.setLink( action.title, action.url );
                });
            }

            notification.notificationStatuses.setEntry( "", NotificationStatuses.Pending as NotificationStatus );

            log.traceOut("readNotification()");

        } catch (error) {
            log.warn("readNotification()", "Error sending message", error);

            throw new Error( (error as any).message );
        }
    }

    private readNotificationParameter( 
        notificationType : NotificationType, 
        notificationData : any,         
        parameterKey: string ) : any {

        if (notificationData[parameterKey] == null) {
            return undefined;
        }

        const parameterData = notificationData[parameterKey];

        if( Array.isArray( parameterData )) { 
            return parameterData;
        }
        
        log.debug("readNotificationParameter()", parameterData, parameterData[notificationType] );

        const notificationParameter = parameterData[notificationType] != null ? 
            parameterData[notificationType] :
            parameterData.default != null ?
                parameterData.default :
                parameterData;

        return notificationParameter;
    }

    async createInviteNotification( params: {
        notification : any, 
        sender : User,
        receiver : User, 
        unit? : Unit, 
        company? : Company } ): Promise<Message> {

        log.traceIn( "createInviteNotification()", params.receiver.referenceHandle().title );

        try { 
            const defaultLanguage = Factory.get().configurationService.config( 
                applicationConfiguration, "defaultLanguage" )!;

            const language = params.receiver.language.value() != null ? params.receiver.language.value()! : 
                params.company?.language.value() != null ? params.company.language.value() :
                    defaultLanguage;

            const notificationTemplate = Factory.get().configurationService.config(
                params.notification, undefined, language );
      
            const appUrl = Factory.get().configurationService.config( 
              applicationConfiguration, "appUrl" )!; 
      
            const iosApp = Factory.get().configurationService.config( 
              applicationConfiguration, "iosApp" )!; 
      
            const androidApp = Factory.get().configurationService.config( 
              applicationConfiguration, "androidApp" )!; 
      
      
            const message = await Factory.get().messagingService.notificationSender.createNotification({ 
              messageCategory: MessageCategories.Service as MessageCategory,
              sender: params.sender,
              receiver: params.receiver,
              notificationTemplate: notificationTemplate,
              notificationParameters: {
                receiver: params.receiver.title.value() != null ? params.receiver.title.value()! : "",
                company: params.company?.title.value() != null ? params.company!.title.value()! : "",
                email: params.receiver.email.value() != null ? params.receiver.email.value()! : "",
                appUrl: appUrl,
                iosApp: iosApp,
                androidApp: androidApp
              }
            }) as Message;

            log.traceOut( "createInviteNotification()", "OK" );
            return message;

        } catch (error) {

            log.warn("Error creating invite notification", {error} );

            throw new Error( (error as any).message );
        }
    }


    async createWelcomeNotification( params: {
        notification : any, 
        user : User, 
        unit? : Unit, 
        company? : Company } ): Promise<Message> {

        log.traceIn( "createWelcomeNotification()", params.user.referenceHandle().title  );

        try { 
            const defaultLanguage = Factory.get().configurationService.config( 
                applicationConfiguration, "defaultLanguage" )!;

            const language = params.user.language.value() != null ? params.user.language.value()! : 
                params.company?.language.value() != null ? params.company.language.value() :
                    defaultLanguage;

            const notificationTemplate = Factory.get().configurationService.config(
                  params.notification, undefined, language );
      
            const appUrl = Factory.get().configurationService.config( 
                applicationConfiguration, "appUrl" )!;
            
            const supportUrl = Factory.get().configurationService.config( 
              applicationConfiguration, "supportUrl", language )!;

            const message = await this.createNotification({
                messageCategory: MessageCategories.Service as MessageCategory,
                receiver: params.user,
                notificationTemplate: notificationTemplate,
                notificationParameters: {
                    receiver: params.user.title.value()!,
                    unit: params.unit?.title.value() != null ? params.unit.title.value()! : "",
                    company: params.company?.title.value() != null ? params.company.title.value()! : "",
                    email: params.user.email.value()!,
                    appUrl: appUrl,
                    supportUrl: supportUrl
                } as NotificationParameters
            });

            log.traceOut( "createWelcomeNotification()", "OK" );
            return message;

        } catch (error) {

            log.warn("Error creatimg welcome notification", {error} );

            throw new Error( (error as any).message );
        }
    }

    async createVerifyEmailNotification( params: {
        verifyEmailUrl : string,
        user : User, 
        company? : Company }): Promise<Message> {

        log.traceIn( "createVerifyEmailNotification()", params.user.referenceHandle().title  );

        try { 
            const defaultLanguage = Factory.get().configurationService.config( 
                applicationConfiguration, "defaultLanguage" )!;

            const language = params.user.language.value() != null ? params.user.language.value()! : 
                params.company?.language.value() != null ? params.company.language.value() :
                    defaultLanguage;

            const verifyEmailNotificationTemplate = Factory.get().configurationService.config(
                verifyEmailNotification, undefined, language );
               
            const supportUrl = Factory.get().configurationService.config( 
              applicationConfiguration, "supportUrl", language )!;

            const message = await Factory.get().messagingService.notificationSender.createNotification({
                messageCategory: MessageCategories.Service as MessageCategory,
                receiver: params.user,
                notificationTemplate: verifyEmailNotificationTemplate,
                notificationParameters: {
                    supportUrl: supportUrl,
                    verifyEmailUrl: params.verifyEmailUrl
                } as NotificationParameters
            }) as Message;

            log.traceOut( "createVerifyEmailNotification()", "OK" );
            return message;

        } catch (error) {

            log.warn("Error creating notification", {error} );

            throw new Error( (error as any).message ); 
        }
    }

    async createDatabaseNotification( params: { 
        notification : any, 
        change : Change, 
        user : User, 
        company? : Company }): Promise<Message> {

        log.traceIn( "createDatabaseNotification()", params.user.referenceHandle().title  ); 

        try { 
            const defaultLanguage = Factory.get().configurationService.config( 
                applicationConfiguration, "defaultLanguage" )!;

            const language = params.user.language.value() != null ? params.user.language.value()! : 
            params.company?.language.value() != null ? params.company.language.value() :
                    defaultLanguage;

            const notificationTemplate = Factory.get().configurationService.config(
                params.notification, undefined, language );
    
            const appUrl = Factory.get().configurationService.config( 
                applicationConfiguration, "appUrl" )!;

            const documentUrl = appUrl + HomePaths.UserHomePath + params.change.changedDocument.path();

            const supportUrl = Factory.get().configurationService.config( 
              applicationConfiguration, "supportUrl", language )!;
            
      
            const message = await this.createNotification({
                messageCategory: MessageCategories.Database as MessageCategory,
                receiver: params.user,
                notificationTemplate: notificationTemplate,
                notificationParameters: {
                    receiver: params.user.title.value()!,
                    company: params.company?.title.value() != null ? params.company.title.value()! : "",
                    documentTitle: params.change.changedDocumentTitle.value(),
                    documentUrl: documentUrl,
                    changedBy: params.change.changedBy.value()?.title != null ? params.change.changedBy.value()!.title! : "",
                    supportUrl: supportUrl
                } as NotificationParameters
            });

            log.traceOut( "createDatabaseNotification()", "OK" );
            return message;

        } catch (error) {

            log.warn("Error creating database notification", {error} );

            throw new Error( (error as any).message );
        }
    }

    async createReadinessNotification(params: {
        notification: any,
        readinessLevel : ReadinessLevel | undefined,
        user: User,
        company?: Company
    }): Promise<Message> {

        log.traceIn("createReadinessNotification()", params.user.referenceHandle().title);

        try {
            const appUrl = Factory.get().configurationService.config( 
                applicationConfiguration, "appUrl" )!;

            const defaultLanguage = Factory.get().configurationService.config( 
                applicationConfiguration, "defaultLanguage" )!;

            const language = params.user.language.value() != null ? params.user.language.value()! : 
                params.company?.language.value() != null ? params.company.language.value() :
                    defaultLanguage;

            const readinessLevelTranslations =
                await Factory.get().translator.loadTranslations(ReadinessLevelDefinition, language);

            const translatedReadinessLevel = params.readinessLevel == null ? "" :
                Factory.get().translator.translate(
                TranslationKey.Values + "." + params.readinessLevel,
                readinessLevelTranslations,
                language);

            const measuresDatabase =
                Factory.get().databaseService.databaseFactory.collectionDatabaseFromCollectionName(
                    MeasuresCollection, params.user);

            const measuresUrl = appUrl + HomePaths.UserHomePath + measuresDatabase.databasePath(true);

            const notificationTemplate = Factory.get().configurationService.config(
                params.notification, undefined, language);

            const message = await Factory.get().messagingService.notificationSender.createNotification({
                messageCategory: MessageCategories.Service as MessageCategory,
                receiver: params.user,
                notificationTemplate: notificationTemplate,
                notificationParameters: {
                    readinessLevel: translatedReadinessLevel,
                    receiver: params.user.title.value()!,
                    company: params.company?.title.value() != null ? params.company.title.value()! : "",
                    measuresUrl: measuresUrl
                } as NotificationParameters
            }) as Message;

            log.traceOut("createReadinessNotification()", "OK");
            return message;

        } catch (error) {

            log.warn("Error creating readiness notification", {error} );

            throw new Error( (error as any).message );
        }
    }

    async createConsentNotification(params: {
        notification: any,
        user: User,
        company?: Company,
        parent?: User
    }): Promise<Message> {

        log.traceIn("createConsentNotification()", params.user.referenceHandle().title);

        try {
            const receiver = params.parent != null ? params.parent : params.user;

            const appUrl = Factory.get().configurationService.config( 
                applicationConfiguration, "appUrl" )!;

            const defaultLanguage = Factory.get().configurationService.config( 
                applicationConfiguration, "defaultLanguage" )!;

            const language = params.user.language.value() != null ? params.user.language.value()! : 
                params.company?.language.value() != null ? params.company.language.value() :
                    defaultLanguage;

            const measuresDatabase =
                Factory.get().databaseService.databaseFactory.collectionDatabaseFromCollectionName(
                    MeasuresCollection, receiver );

            const measuresUrl = appUrl + HomePaths.UserHomePath + measuresDatabase.databasePath(true);

            const notificationTemplate = Factory.get().configurationService.config(
                params.notification, undefined, language);

            const message = await Factory.get().messagingService.notificationSender.createNotification({
                messageCategory: MessageCategories.Service as MessageCategory,
                receiver: receiver,
                notificationTemplate: notificationTemplate,
                notificationParameters: {
                    receiver: receiver.title.value()!,
                    company: params.company?.title.value() != null ? params.company.title.value()! : "",
                    measuresUrl: measuresUrl,
                    user: params.user?.title.value() != null ? params.user.title.value()! : ""
                } as NotificationParameters 
            }) as Message;

            log.traceOut("createConsentNotification()", "OK");
            return message;

        } catch (error) {

            log.warn("Error creating consent notification", {error} );

            throw new Error( (error as any).message );
        }
    }

    async createSymbolicOwnerNotification(params: {
        notification: any,
        user: User,
        company?: Company,
        symbolicOwner: DatabaseDocument,
        changedBy? : User,
        parent?: User
    }): Promise<Message> {

        log.traceIn("createSymbolicOwnerNotification()", params.user.referenceHandle().title);

        try {
            const receiver = params.parent != null ? params.parent : params.user;

            const appUrl = Factory.get().configurationService.config( 
                applicationConfiguration, "appUrl" )!;

            const defaultLanguage = Factory.get().configurationService.config( 
                applicationConfiguration, "defaultLanguage" )!;

            const language = params.user.language.value() != null ? params.user.language.value()! : 
                params.company?.language.value() != null ? params.company.language.value() :
                    defaultLanguage;

            const user = params.user.databasePath() === receiver.databasePath() ?
                Factory.get().translator.translate( "youObject" ) :
                    params.user?.title.value() != null ? params.user.title.value()! : 
                    "";
            
            const associationsUrl = appUrl + HomePaths.UserHomePath + params.user.databasePath(true)! + 
                "&view=associations";

            const notificationTemplate = Factory.get().configurationService.config(
                params.notification, undefined, language);

            const changedBy = params.changedBy?.title.value() != null ? params.changedBy!.title.value()! :
                Factory.get().configurationService.config( applicationConfiguration, "adminName" );

            const message = await Factory.get().messagingService.notificationSender.createNotification({
                messageCategory: MessageCategories.Service as MessageCategory,
                receiver: receiver,
                notificationTemplate: notificationTemplate,
                notificationParameters: {
                    receiver: receiver.title.value()!,
                    company: params.company?.title.value() != null ? params.company.title.value()! : "",
                    associationsUrl: associationsUrl,
                    user: user,
                    symbolicOwner: params.symbolicOwner.title.value() != null ? params.symbolicOwner.title.value()! : "",  
                    changedBy : changedBy
                } as NotificationParameters 
            }) as Message;

            log.traceOut("createSymbolicOwnerNotification()", "OK");
            return message;

        } catch (error) {

            log.warn("Error creating symbolic owner notification", {error} );

            throw new Error( (error as any).message );
        }
    }
}


