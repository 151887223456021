
export const ConsentResponses = {

    Unread :        "unread",
    
    Read :          "read",

    Accepted :      "accepted",

    Rejected :      "rejected",
    
    Withdrawn:      "withdrawn"
}

export type ConsentResponse = keyof (typeof ConsentResponses);


