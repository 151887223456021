
export const UserAccessTypes = {

    List :        "list",

    Create :     "create",

    Read :       "read",

    Update :     "update",

    Delete :      "delete"

}

export type UserAccessType = keyof (typeof UserAccessTypes);


