import { log } from "../../framework/databaseService";
import { DatabaseDocument } from "../../framework/databaseDocument";
import { Monitor } from "../../../common/api/monitor";
import { ObservableIF } from "../../../common/api/observableIF";
import { Observation } from "../../../common/api/observation";
import { UserAccess } from "../../../common/api/userAccess";
import { DocumentNameKey } from "../../api/core/databaseServiceIF";
import { ReferenceHandle } from "../..";

import { Database } from "./database";
import { DocumentsDatabaseIF } from "../../api/core/documentsDatabaseIF";
import { DocumentsProperty } from "../properties/documentsProperty";

export class DocumentsDatabase<DerivedDocument extends DatabaseDocument> 
    extends Database<DerivedDocument> implements DocumentsDatabaseIF<DerivedDocument> { 

    constructor( documentsProperty : DocumentsProperty<DerivedDocument>) {
 
        super( documentsProperty.collectionName()!, 
            documentsProperty.queryDocumentName(), 
            documentsProperty.documentNames()!, 
            documentsProperty.parentDocument()! ); 

        this._referencesProperty = documentsProperty;

        this.onNotify = this.onNotify.bind(this);

        //log.traceInOut( "("+this.collectionName()+")", "constructor()" );
    }

    databasePath( includeDocumentName? : boolean ) : string {

        let path = this.owner()!.databasePath( false ) + "/" + this._referencesProperty.key();

        if( !!includeDocumentName && this.queryDocumentName() != null  ) {
            path += "?" + DocumentNameKey + "=" + this.queryDocumentName();
        }

        return path;
    }

    documents(): Promise<Map<string,DerivedDocument>> {
        return this._referencesProperty.documents();
    }

    newDocument(documentPath?: string ): DerivedDocument {

        return this._referencesProperty.newDocument()!;
    }

    document(documentPath: string ): Promise<DerivedDocument | undefined> {

        return this._referencesProperty.document( documentPath );
    }


    async referenceHandles(): Promise<Map<string,ReferenceHandle<DerivedDocument>>> {

        return this._referencesProperty.referenceHandles();
    }

    onNotify = async (observable : ObservableIF, 
        observation : Observation, 
        objectId? : string, 
        object? : any) : Promise<void> => {

        await super.notify( observation, objectId, object );
    }

    protected async monitor( newMonitor : Monitor ): Promise<void> {

        await this._referencesProperty.subscribe( { 
            observer: this,
            onNotify: this.onNotify 
          } as Monitor );
    }

    protected async release(): Promise<void> {
        await this._referencesProperty.unsubscribe( this );
    }

    userAccess() : UserAccess {

        return this._referencesProperty.userAccess(); 
    }

    setUserAccess( userAccess : UserAccess ) {
        log.traceInOut("setUserAccess()", "ignored for documents"); 
    }


    private readonly _referencesProperty : DocumentsProperty<DerivedDocument>

}