

import { MessageIF } from "../../api/documents/messageIF";
import { DatabaseDocument } from "../../framework/databaseDocument";
import { CollectionDatabase } from "../core/collectionDatabase";
import { log } from "../../framework/databaseService";
import { CompanyDocument, MessageDocument, UnitDocument, UserDocument } from "../../api/documents";
import { NotificationType, NotificationTypes } from "../../api/definitions/notificationType";
import { Contactable } from "./contactable";
import { ReferenceProperty } from "../properties/referenceProperty";
import { ReferencesProperty } from "../properties/referencesProperty";
import { SubdocumentProperty } from "../properties/subdocumentProperty";
import { Notification } from "../subdocuments/notification";
import { TextsProperty } from "../properties/textsProperty";
import { MessageCategories, MessageCategory } from "../../api/definitions/messageCategory";
import { DefinitionProperty } from "../properties/definitionProperty";
import { MessageCategoryDefinition } from "../../api/definitions";
import { TextProperty } from "../properties/textProperty";
import { CollectionGroupDatabase } from "../core/collectionGroupDatabase";
import { Company } from "./company";
import { TenantProperty } from "../properties/tenantProperty";
import { OwnerProperty } from "../properties/ownerProperty";
import { Unit } from "./unit";
import { User } from "./user";
import { CompaniesCollection, UnitsCollection, UsersCollection } from "../../api/collections";



export class Message extends DatabaseDocument implements MessageIF { 

    constructor( categoriesCollection : CollectionDatabase<Message>, documentPath? : string  ) {

        super( MessageDocument, categoriesCollection, documentPath );

        try {
            this.company = new TenantProperty<Company>( this, CompaniesCollection, CompanyDocument );
            this.company.editable = false;

            const company = this.company.emptyDocument() as Company;
            
            this.unit = new OwnerProperty<Unit>( this, UnitsCollection, UnitDocument ); // parent
            this.unit.editable = false;

            this.user = new OwnerProperty<User>( this, UsersCollection, UserDocument ); 
            this.user.editable = false;

            this.startDate.editable = false;
            this.endDate.editable = false;
            this.title.editable = this.isNew(); 
            this.links.editable = this.isNew(); 
            this.attachments.editable = this.isNew(); 

            this.messageCategory = new DefinitionProperty<MessageCategory>( this, MessageCategoryDefinition, MessageCategories ); 
            this.messageCategory.editable = this.isNew(); 

            this.sender = new ReferenceProperty<Contactable>( this); 
            this.sender.editable = false; 

            this.receivers = new ReferencesProperty<Contactable>( this,
                company != null ? 
                () => [company.users.collectionGroup()] as CollectionGroupDatabase<Contactable>[] : undefined
            );
            this.receivers.editable = this.isNew(); 

            this.subtitle = new TextProperty( this );
            this.subtitle.editable = this.isNew(); 

            this.body = new TextsProperty( this );
            this.body.editable = this.isNew(); 

            this.original = new ReferenceProperty<Message>( this );
            this.original.editable = false; 

            this.copies = new ReferencesProperty<Message>( this ); 
            this.copies.editable = false; 

            const emailNotification = new Notification( this, "emailNotification");

            emailNotification.notificationType.setValue( NotificationTypes.Email as NotificationType);
            emailNotification.notificationType.clearChanges(); 

            this.emailNotification = new SubdocumentProperty<Notification>( this, emailNotification );

            const smsNotification = new Notification( this, "smsNotification");

            smsNotification.notificationType.setValue( NotificationTypes.SMS as NotificationType);
            smsNotification.notificationType.clearChanges(); 

            this.smsNotification = new SubdocumentProperty<Notification>( this, smsNotification );

            const pushNotification = new Notification( this, "pushNotification");

            pushNotification.notificationType.setValue( NotificationTypes.Push as NotificationType);
            pushNotification.notificationType.clearChanges(); 

            this.pushNotification = new SubdocumentProperty<Notification>( this, pushNotification );

        } catch( error ) {

            log.warn( "constructor()", "Error initializing Message", error );
            
            throw new Error( (error as any).message );
        }
    }

    indexKey() : string {
        return MessageDocument;
    }

    readonly company: TenantProperty<Company>;

    readonly unit: OwnerProperty<Unit>;

    readonly user: OwnerProperty<User>;

    readonly messageCategory : DefinitionProperty<MessageCategory>; 
    
    readonly sender : ReferenceProperty<Contactable>;

    readonly receivers : ReferencesProperty<Contactable>;

    readonly subtitle: TextProperty; 

    readonly body: TextsProperty; 

    readonly original : ReferenceProperty<Message>;

    readonly copies : ReferencesProperty<Message>;

    readonly emailNotification : SubdocumentProperty<Notification>;

    readonly smsNotification : SubdocumentProperty<Notification>;

    readonly pushNotification : SubdocumentProperty<Notification>;

}
