
import { log } from "../../../services/database/framework/databaseService";
import { ReadinessLevel } from "../../api/definitions/readinessLevel";
import { HealthguardLocation } from "../documents/healthguardLocation";
import { HealthguardDeviceManager } from "./healthguardDeviceManager";
import { MeasureManager } from "./measureManager";

export class HealthguardLocationManager {

    static getInstance() : HealthguardLocationManager {
        if( HealthguardLocationManager._instance == null ) {
            HealthguardLocationManager._instance = new HealthguardLocationManager();
        }
        return HealthguardLocationManager._instance;
    }


    async handleHealthguardLocationCreated( createdLocation: HealthguardLocation ) {

        try {
            log.traceIn("handleHealthguardLocationCreated()", createdLocation.databasePath() );

            await this.updateMeasuresState( createdLocation );

            await this.updateDeviceGeolocations( createdLocation );

            log.traceOut("handleHealthguardLocationCreated()");

        } catch (error) {
            log.warn("Error handling document create", error);

            log.traceOut("handleHealthguardLocationCreated()", "error");
        }
    }


    async handleHealthguardLocationUpdated( updatedLocation: HealthguardLocation ) {

        try {
            log.traceIn("handleHealthguardLocationUpdated()", updatedLocation.databasePath() );

            await this.updateMeasuresState( updatedLocation );

            await this.updateDeviceGeolocations( updatedLocation );

            log.traceOut("handleHealthguardLocationUpdated()");

        } catch (error) {
            log.warn("Error handling document update", error);

            log.traceOut("handleHealthguardLocationUpdated()", "error");
        }
    } 

    async updateLocationDefaultReadinessLevel( location: HealthguardLocation, readinessLevel? : ReadinessLevel  ) {

        try {
            log.traceIn("updateLocationDefaultReadinessLevel()", location.databasePath() );

            const defaultValue = location.readinessLevel.defaultValue();

            if( defaultValue !== readinessLevel ) {

                log.debug("updateLocationDefaultReadinessLevel()", location.databasePath( true ), {readinessLevel} );

                location.readinessLevel.setDefaultValue( readinessLevel );
                location.readinessLevel.setLastChange( defaultValue );
                
                await location.update();
            }
            
            log.traceOut("updateLocationDefaultReadinessLevel()");

        } catch (error) {
            log.warn("Error handling company alert level", error);

        }
    } 

    async updateMeasuresState( location: HealthguardLocation ) {

        try {
            log.traceIn("updateMeasuresState()", location.databasePath() );

            const measures = await location.measures.documents();

            for( const measure of measures.values() ) {

                await MeasureManager.getInstance().updateMeasureState( measure, undefined, location );

                await measure.update();
            }

            log.traceOut("updateMeasuresState()");

        } catch (error) {
            log.warn("Error handling measure activity statuses", error);

            log.traceOut("updateMeasuresState()", "error");
        }
    } 

    async updateDeviceGeolocations( location: HealthguardLocation ) {

        try {
            log.traceIn("updateDeviceGeolocations()", location.databasePath() );

            const geolocation = location.geolocation.value();

            const devices = await location.devices.documents();

            for( const device of devices.values() ) {

                await HealthguardDeviceManager.getInstance().updateDeviceGeolocation( device, geolocation );

                await device.update();
            }

            log.traceOut("updateMeasuresState()"); 

        } catch (error) {
            log.warn("Error handling measure activity statuses", error);

            log.traceOut("updateMeasuresState()", "error");
        }
    } 

    private static _instance? : HealthguardLocationManager; 

}

