
import { CollectionDatabase } from "../impl/core/collectionDatabase";
import { DatabaseDocument } from "./databaseDocument";

import { CollectionGroupDatabase } from "../impl/core/collectionGroupDatabase";

import { DatabaseFactoryIF } from "../api/core/databaseFactoryIF";
import { PropertyTypes } from "../api/definitions/propertyType";
import { CollectionProperty } from "../impl/properties/collectionProperty";
import { DatabaseManager } from "./databaseManager";
import { ConfigurationManager } from "../../configuration/framework/configurationManager";
import { log } from "./databaseService";
import { CollectionGroupPathSuffix, DocumentNameKey } from "../api/core/databaseServiceIF";
import { DatabaseObserver } from "../impl/core/databaseObserver";
import { DatabaseQuery } from "../api/core/databaseQuery";
import { Database } from "../impl/core/database";

export abstract class DatabaseFactory implements DatabaseFactoryIF {

    constructor( configurationManager : ConfigurationManager, databaseManager : DatabaseManager ) {

        //log.traceIn( "constructor()");

        try {
            this.configurationManager = configurationManager;

            this.databaseManager = databaseManager;

            //log.traceOut( "constructor()");

        } catch( error ) {
            log.warn( "constructor()", "Error initializing database service", error );

            throw new Error( (error as any).message );
        }
    }

    documentId( documentPath : string ) : string | undefined {

        //log.traceIn( "documentId()", documentPath );

        const pathElements = documentPath.split("/");

        if( pathElements.length < 2 ) {
            //log.traceOut( "documentId()", "not a document path" );
            return undefined;
        }

        let documentId = pathElements[pathElements.length-1];

        if( documentId.includes( "?") ) {
            documentId = documentId.split( "?" )[0];
        }

        //log.traceOut( "documentId()", documentId );
        return documentId;
    }

    collectionReference( documentPath : string ) : any | undefined {

        const collectionDatabase = this.collectionFromUrl( documentPath );

        return collectionDatabase == null ? undefined :
            this.databaseManager.collectionReference( collectionDatabase );
    }

    collectionGroupReference( documentPath : string ) : any | undefined {

        const collectionGroupDatabase = this.collectionGroupFromUrl( documentPath );

        return collectionGroupDatabase == null ? undefined :
            this.databaseManager.collectionGroupReference( collectionGroupDatabase );
    }

    documentReference( documentPath : string ) : any | undefined {

        const databaseDocument = this.newDocumentFromUrl( documentPath );

        return databaseDocument == null || databaseDocument.isNew() ? undefined :
            this.databaseManager.documentReference( databaseDocument );

    }


    isUrlDatabase( url : string  ) : boolean {

        //log.traceIn( "isUrlDatabase()");

        try {
            const urlElements = this.elementsFromUrl( url );

            const result = urlElements != null && urlElements.length > 0 && urlElements.length % 2 === 1;

            //log.traceOut( "isUrlDatabase()", {result});
            return result;

        } catch( error ) {
            log.warn( "isUrlDatabase()", "Error checking URL is connection", error );

            return false;
        }
    }

    isUrlDocument( url : string  ) : boolean {
        //log.traceIn( "isUrlDocument()");

        try {
            const urlElements = this.elementsFromUrl( url );

            const result = urlElements != null && urlElements.length > 0 && urlElements.length % 2 === 0;

            //log.traceOut( "isUrlDocument()", {result});,
            return result;

        } catch( error ) {
            log.warn( "isUrlDocument()", "Error checking URL is document", error );

            return false;
        }
    }

    trimmedDatabasePath( databasePath? : string ) : string | undefined {

        return databasePath?.split("?")[0];
    }

    equalDatabasePaths( databasePath1? : string, databasePath2? : string ) : boolean {
        return this.trimmedDatabasePath( databasePath1 ) === this.trimmedDatabasePath( databasePath2 );
    }

    documentNameFromUrl( url : string  ) : string | undefined {

        //log.traceIn( "documentNameFromUrl()", {url} );

        try {
            if( !url.includes("?") || url.endsWith("?") ) {
                //log.traceOut( "documentNameFromUrl()", "URL has no parameters", {url} );
                return undefined;
            }

            let urlParameters = url.split("?")[1];

            if( !urlParameters.includes( DocumentNameKey + "=" ) ) {
                //log.traceOut( "documentNameFromUrl()", "Document name parameter not found in URL", url );
                return undefined;
            }

            let documentName = urlParameters.split( DocumentNameKey + "=" )[1];

            documentName = documentName.split( "?" )[0];

            documentName = documentName.split( "&" )[0];

            if( documentName != null && documentName.length > 0 ) {
                //log.traceOut( "documentNameFromUrl()", {documentName} );
                return documentName;
            }
            
            //log.traceOut( "documentNameFromUrl()", "empty document name in URL", url );
            return undefined;

        } catch( error ) {
            log.warn( "documentNameFromUrl()", "Error reading document name from path", error );

            throw new Error( (error as any).message );
        }
    }

    collectionNameFromUrl( url : string  ) : string | undefined {

        //log.traceIn( "collectionNameFromUrl()", url );

        try {

            const elements = this.elementsFromUrl( url.split(CollectionGroupPathSuffix)[0] ) 

            for( let i = elements.length-1; i >=0; i-- ) {

                if( this.collectionNames().indexOf( elements[i] ) !== -1 ) {

                    const result = elements[i];

                    //log.traceOut( "collectionNameFromUrl()", result );
                    return result;
                }
            }

            //log.traceOut( "collectionNameFromUrl()", "not found" );
            return undefined;

        } catch( error ) {
            log.warn( "collectionNameFromUrl()", "Error reading collection group from path", error );

            throw new Error( (error as any).message );
        }
    }

    collectionPathFromUrl( url : string, collectionName? : string ) : string | undefined {

        //log.traceIn( "collectionPathFromUrl()", url );

        try {
            let result;

            let path = "";

            const elements = this.elementsFromUrl( url.split(CollectionGroupPathSuffix)[0] ) 

            if( this.isUrlDatabase( url ) ) {

                for( let i = 0; i < elements.length; i++ ) {

                    path += "/" + elements[i];

                    if( collectionName != null && collectionName === elements[i] ) {
                        result = path;
                    }
                }
            }
            else if( this.isUrlDocument( url ) ) {

                for( let i = 0; i < elements.length - 1; i++ ) {

                    path += "/" + elements[i];

                    if( collectionName != null && collectionName === elements[i] ) {
                        result = path;
                    }
                }
            }
            else {
                throw new Error( "Invalid URL: " + url);
            }

            if( collectionName == null ) {
                result = path;
            }

            //log.traceOut( "collectionPathromUrl()", result ); 
            return result;

        } catch( error ) {
            log.warn( "collectionPathFromUrl()", "Error reading collection path from path", error );

            throw new Error( (error as any).message );
        }
    }

    documentPathFromUrl( url : string, collectionName? : string ) : string | undefined { 

        //log.traceIn( "documentPathFromUrl()", url );

        try {
            let result;

            let path = "";

            const elements = this.elementsFromUrl( url.split(CollectionGroupPathSuffix)[0] ) 

            if( this.isUrlDatabase( url ) ) {

                for( let i = 0; i < elements.length - 1; i++ ) {
                    
                    path += "/" + elements[i];

                    if( collectionName != null && collectionName === elements[i] ) {
                        result = path + "/" + elements[i+1];
                    }
                }
            }
            else if( this.isUrlDocument( url ) ) {

                for( let i = 0; i < elements.length; i++ ) {

                    path += "/" + elements[i];

                    if( collectionName != null && collectionName === elements[i-1] ) {
                        result = path;
                    }
                }
            }
            else {
                throw new Error( "Invalid URL: " + url);
            }

            if( collectionName == null ) {
                result = path;
            }

            //log.traceOut( "documentPathFromUrl()", result ); 
            return result;

        } catch( error ) {
            log.warn( "documentPathFromUrl()", "Error reading document path from path", error );

            throw new Error( (error as any).message );
        }
    }

    databaseFromUrl( url : string ) : Database<DatabaseDocument> | undefined {

        log.traceIn( "databaseFromUrl()", {url} );

        const strippedUrl = url.split("?")[0];

        if( strippedUrl.endsWith( CollectionGroupPathSuffix ) ) {
            return this.collectionGroupFromUrl( url );
        }
        else {
            return this.collectionFromUrl( url );
        } 
    }

    collectionGroupFromUrl( url : string ) : CollectionGroupDatabase<DatabaseDocument>  | undefined {

        //log.traceIn( "collectionGroupFromUrl()", {url} );

        try {
            const strippedUrl = url.split("?")[0];

            const documentName = this.documentNameFromUrl( url );
        
            if( !strippedUrl.endsWith( CollectionGroupPathSuffix ) ) {
                throw new Error( "Collection group path must end with " + CollectionGroupPathSuffix );
            }

            const elements = this.elementsFromUrl( strippedUrl.split(CollectionGroupPathSuffix)[0] )  

            let ownerPath = "";

            let collectionName;

            if( elements.length % 2 === 0 && elements.length > 1 ) {

                for( let i = 0; i < elements.length - 2; i++) {

                    ownerPath += "/" + elements[i];
                }

                collectionName = elements[elements.length-2];
            }
            else if( elements.length % 2 === 1 && elements.length > 0 ) {

                for( let i = 0; i < elements.length - 1; i++) {

                    ownerPath += "/" + elements[i];
                }
                collectionName = elements[elements.length-1];
            }
            else {
                log.warn( "collectionGroupFromUrl()", "The path has no collections" );
                return undefined;
            }

            const owner = this.newDocumentFromUrl( ownerPath ); 

            let collectionGroupDatabase;

            if( documentName != null ) {
                collectionGroupDatabase = this.collectionGroupDatabaseFromDocumentName( 
                    documentName, owner ) as CollectionGroupDatabase<DatabaseDocument> ;
            }
            else {
                collectionGroupDatabase = this.collectionGroupDatabaseFromCollectionName( 
                    collectionName, owner ) as CollectionGroupDatabase<DatabaseDocument> ;
            }

            //log.traceOut( "collectionGroupFromUrl()", collectionGroupDatabase!.databasePath() );
            return collectionGroupDatabase!;

        } catch( error ) {
            log.warn( "collectionGroupFromUrl()", "Error reading collection group from path", error );

            throw new Error( (error as any).message );
        }
    }

    collectionFromUrl( url : string ) : CollectionDatabase<DatabaseDocument>  | undefined {

        //log.traceIn( "collectionFromUrl()", {url} );

        try {
            const strippedUrl = url.split("?")[0];

            const documentName = this.documentNameFromUrl( url );

            //log.debug( "collectionFromUrl()", {documentName} );

            const elements = this.elementsFromUrl( strippedUrl )  

            let ownerPath = "";

            let collectionName;

            if( elements.length % 2 === 0 && elements.length > 1 ) {

                for( let i = 0; i < elements.length - 2; i++) {

                    ownerPath += "/" + elements[i];
                }

                collectionName = elements[elements.length-2];
            }
            else if( elements.length % 2 === 1 && elements.length > 0 ) {

                for( let i = 0; i < elements.length - 1; i++) {

                    ownerPath += "/" + elements[i];
                }
                collectionName = elements[elements.length-1];
            }
            else {
                //log.traceOut( "collectionFromUrl()", "The path has no collections" );
                return undefined;   
            }      

            const owner = this.newDocumentFromUrl( ownerPath ); 

            let collectionDatabase;

            if( documentName != null ) {
                collectionDatabase = this.collectionDatabaseFromDocumentName( 
                    documentName, owner ) as CollectionDatabase<DatabaseDocument> ;
            }
            else {
                collectionDatabase = this.collectionDatabaseFromCollectionName( 
                    collectionName, owner ) as CollectionDatabase<DatabaseDocument> ;
            }

            //log.traceOut( "collectionFromUrl()", collectionDatabase!.databasePath( true ) );
            return collectionDatabase!;

        } catch( error ) {
            log.warn( "collectionFromUrl()", "Error reading collection name from path", error );

            throw new Error( (error as any).message );
        }
    }

    collectionInUrl( url : string, collectionName : string ) : CollectionDatabase<DatabaseDocument> | undefined {

        //log.traceIn( "collectionInUrl()", url, collectionName );

        try {
            let collection;

            let collectionParent = this.newDocumentFromUrl( url );;

            while( collectionParent != null ) {

                const collectionProperty = collectionParent.property( collectionName );

                if( collectionProperty != null &&
                    collectionProperty.type === PropertyTypes.Collection ) {

                    collection = 
                        (collectionProperty as CollectionProperty<DatabaseDocument>).collection();

                    //log.traceOut( "collectionInUrl()", "found", collection );
                    return collection;
                }

                collectionParent = collectionParent.collectionDatabase.owner();
            }

            //log.traceOut( "collectionInUrl()", "no collection found" );
            return undefined;

        } catch( error ) {
            log.warn( "collectionInUrl()", "Error reading document from path", error );

            throw new Error( (error as any).message );
        }
    }

    newDocumentFromUrl( url : string ) : DatabaseDocument | undefined {

        //log.traceIn( "newDocumentFromUrl()", url );

        try {
            const elements = this.elementsFromUrl( url )  // remove leading "/" and split the rest

            //log.debug( "newDocumentFromUrl()", "elements" );

            const documentName = this.documentNameFromUrl( url );

            //log.debug( "newDocumentFromUrl()", "documentName" );

            let databaseDocument : DatabaseDocument | undefined;

            let documentPath : string = "";

            for( let i = 0; i < elements.length;) {

                let collectionDatabase = this.collectionDatabaseFromCollectionName( elements[i], databaseDocument );

                //log.debug( "newDocumentFromUrl()", "collectionDatabase" );

                documentPath += "/" + elements[i];

                if( i === elements.length - 1 ) {

                    if( documentName != null ) {
                        collectionDatabase = this.collectionDatabaseFromDocumentName( documentName, databaseDocument );

                        //log.debug( "newDocumentFromUrl()", "collectionDatabase 2" );

                    }

                    databaseDocument = this.newDocument( collectionDatabase ) as DatabaseDocument;

                    //log.debug( "newDocumentFromUrl()", "databaseDocument" );

                    break;
                }
                i++;

                documentPath += "/" + elements[i];

                if( i === elements.length - 1 ) {

                    if( documentName != null ) {
                        collectionDatabase = this.collectionDatabaseFromDocumentName( documentName, databaseDocument );

                        //log.debug( "newDocumentFromUrl()", "collectionDatabase 3" );

                    }
                } 

                databaseDocument = this.newDocument( collectionDatabase, documentPath ) as DatabaseDocument;

                //log.debug( "newDocumentFromUrl()", "databaseDocument 2" );

                i++;
            }

            //log.traceOut( "newDocumentFromUrl()" ); 
            return databaseDocument!;

        } catch( error ) {
            log.warn( "newDocumentFromUrl()", "Error reading document from path", error );

            return undefined;
        }
    }    

    async documentFromUrl( url : string ) : Promise<DatabaseDocument | undefined> {

        //log.traceIn( "documentFromUrl()", url );

        try {

            let databaseDocument = this.newDocumentFromUrl( url );

            if( databaseDocument == null ) {
                log.warn( "documentFromUrl()", "not found", {url} );
                return undefined;
            }

            if( databaseDocument.id.value() != null ) {
                await databaseDocument.read(); 
            }

            //log.traceOut( "documentFromUrl()", databaseDocument != null ? databaseDocument.referenceHandle().title: undefined );
            return databaseDocument!;

        } catch( error ) {
            log.warn( "documentFromUrl()", "Error reading document from path", error );

            throw new Error( (error as any).message );
        }
    }

    async documentFromData( documentPath: string, data: any): Promise<DatabaseDocument | undefined> {

        //log.traceIn("documentFromData()", data);

        try {

            if (documentPath == null) {
                log.warn("documentFromData()", "empty document path");

                //log.traceOut("documentFromData()", undefined);
                return undefined;
            }

            if (data == null) {
                log.warn("documentFromData()", "no document data for path: " + documentPath );

                //log.traceOut("documentFromData()", undefined);
                return undefined;
            }

            const pathElements = documentPath.split("/");

            const documentId = pathElements[pathElements.length-1];

            let url = documentPath;

            if( data.name != null ) {
                url += "?" + DocumentNameKey + "=" + data.name;
            }

            const databaseDocument = this.newDocumentFromUrl( url )! as DatabaseDocument;

            if( databaseDocument == null ) {
                log.warn("documentFromData()", "no document found at path: " + documentPath );

                //log.traceOut("documentFromData()", "not found", undefined);
                return undefined
            }

            databaseDocument.fromData(data);

            databaseDocument.id.setValue(documentId);

            await databaseDocument.onRead(); 

            //log.traceOut("documentFromData()", "OK");
            return databaseDocument;

        } catch (error) {
            log.warn("monitorCollection()", "Error reading document", error);

            throw new Error( (error as any).message );
        }
    }

    protected elementsFromUrl( url : string ) : string[] {

        //log.traceIn( "elementsFromUrl()", url );

        try {
            let elements : string[] = [];

            if( url == null || url.length === 0 ) {
                //log.traceOut( "elementsFromUrl()", "no elements" );
                return elements;
            }

            let cleanUrl = url.split( "?" )[0];

            const urlElements = cleanUrl.startsWith("/") ? 
            cleanUrl.substring(1).split("/") : cleanUrl.split("/");  // remove leading "/" and split the rest

            if( urlElements.length === 0 ) {
                //log.traceOut( "elementsFromUrl()", "empty" );
                return elements;
            }

            let foundCollection = false;

            for( const urlElement of urlElements ) {

                if( !foundCollection ) {

                    if( this.collectionNames().includes( urlElement.split( CollectionGroupPathSuffix )[0] ) ) {

                        foundCollection = true;
                    }
                }

                if( foundCollection ) {
                    elements.push( urlElement ); 
                }
            }

            //log.traceOut( "elementsFromUrl()", elements );
            return elements;
        } catch( error ) {
            log.warn( "elementsFromUrl()", "Error database path from url", error );

            throw new Error( (error as any).message );
        }
    }

    parentCollections(database: Database<DatabaseDocument>): Database<DatabaseDocument>[] {

        let databases: Database<DatabaseDocument>[] = [];

        const ownerCollections = database.owner() != null ?
            database.owner()!.parentCollections(database.collectionName() ) as Database<DatabaseDocument>[] : undefined;

        if (ownerCollections != null) {

            for (const ownerCollection of ownerCollections) {

                if (ownerCollection.userAccess().allowRead) {
                    databases = databases.concat(ownerCollection);
                }
            }
        }

        const rootCollection = this.collectionDatabaseFromCollectionName(database.collectionName());

        if (rootCollection.allowRootCollection && rootCollection.userAccess().allowRead) {
            databases = databases.concat(rootCollection);
        }

        return databases;
    }

    newDatabaseObserver( databaseQuery? : DatabaseQuery<DatabaseDocument> ) : DatabaseObserver<DatabaseDocument> {

        return new DatabaseObserver( databaseQuery ); 
    }

    abstract collectionNames() : string[];

    abstract rootCollectionNames() : string[];

    abstract encryptedCollectionNames() : string[];

    abstract documentNames() : string[];

    abstract contactableDocumentNames() : string[];

    abstract collectionNamesWithUsersReference() : string[];

    abstract collectionGroupDatabaseFromCollectionName( 
        documentName : string, 
        owner? : DatabaseDocument ) : CollectionGroupDatabase<DatabaseDocument>; 

    abstract collectionGroupDatabaseFromDocumentName( 
        documentName : string, 
        owner? : DatabaseDocument ) : CollectionGroupDatabase<DatabaseDocument>;

    abstract collectionDatabaseFromCollectionName( 
        documentName : string, 
        owner? : DatabaseDocument ) : CollectionDatabase<DatabaseDocument>; 

    abstract collectionDatabaseFromDocumentName( 
        documentName : string, 
        owner? : DatabaseDocument ) : CollectionDatabase<DatabaseDocument>;

    abstract newDocument( 
        collectionDatabase : CollectionDatabase<DatabaseDocument>, 
        documentPath? : string ) : DatabaseDocument; 

    readonly configurationManager : ConfigurationManager;

    readonly databaseManager : DatabaseManager;

}
