import React, { useContext } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import { Grid, Link, Typography } from '@material-ui/core';
import MobileFirebaseAuthView from 'ui/app/firebase/firebaseAuthView'; 
import RealmAuthView from 'ui/app/realm/realmAuthView';
import { log } from '../app/app';

import Loading from '../components/loading';
import logoLightBackground from 'ui/app/logo-light.png';  

import { Factory } from '../../services/common/api/factory';
import { FirebasePlatform, RealmPlatform } from '../../services/common/api/platforms';
import SimpleDialog from '../components/simpleDialog';

import { activeLanguage } from '../app/localization';
import { AppContext } from '../app/appContext';
import { TerminalPlatforms } from "../../services/database/api/definitions/terminalPlatform";

import applicationConfiguration from "healthguard/data/settings/application.json";

const useStyles = makeStyles((theme) => ({
  safeAreaContent: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    paddingTop: 'env(safe-area-inset-top)',
    paddingLeft: 'env(safe-area-inset-left)',
    paddingRight: 'env(safe-area-inset-right)' 
  },
  root: {
    display: 'flex',
    width: '100%',
    height: '100%',
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(2),
  },
  logo: {
    height: theme.spacing(8)
  },
  title: {
    width: '100%',
    marginLeft: theme.spacing(0)
  },
  auth: {
    display: 'flex',
    flexDirection: 'column',
  },
  loginConsent: {
    width: "80%"
  },
}));

export default function SignIn( props: { 

  error?: string, 

  signInAttempts: number 
  
  } ) {

  const { t } = useTranslation();

  const appContext = useContext(AppContext);

  const [signInAttempts, setSignInAttempts] = React.useState( props.signInAttempts ); 

  const [signInComplete, setSignInComplete] = React.useState( false ); 

  const webUrl =
    Factory.get().configurationService.config(applicationConfiguration, "webUrl", activeLanguage());

  const privacyPolicyUrl = (webUrl != null ? webUrl : "") +
    Factory.get().configurationService.config(applicationConfiguration, "privacyPolicyPath", activeLanguage());

  const termsOfServiceUrl = (webUrl != null ? webUrl : "") +
    Factory.get().configurationService.config(applicationConfiguration, "termsOfServicePath", activeLanguage());

  const signupUrl =
    Factory.get().configurationService.config(applicationConfiguration, "webUrl", activeLanguage()) +
    Factory.get().configurationService.config(applicationConfiguration, "signUpPath", activeLanguage());

  React.useEffect(() => { 

    log.traceIn("useEffect");

    setSignInAttempts(props.signInAttempts);

    setSignInComplete(false);

    log.traceOut("useEffect"); 


  }, [props.signInAttempts])

  const classes = useStyles();

  const onSignInComplete = async ( user : any ) => {

    log.traceInOut("onSignInComplete", user );

    if( user != null ) {

      setSignInAttempts(0);

      setSignInComplete( true );
    }
  }

  log.traceInOut("SignIn", {signInAttempts}, {signInComplete} );

  const consentPrompt = (
    <Typography variant="caption" color="secondary" align="center">
      {t("loginConsentPrompt")} 
      <a href={termsOfServiceUrl}>{t("loginConsentTermsOfService")}</a>
      {t("loginConsentAnd")}
      <a href={privacyPolicyUrl}>{t("loginConsentPrivacyPolicy")}</a>.
    </Typography> 
  )

  const signupPrompt = (
    <Typography color="secondary">
      {t("notCustomerYet")} <Link target="_blank" href={signupUrl}> {t("orderHere")}</Link>
    </Typography>
  )

  return (
    <>
      {!!signInComplete ? <Loading showLogo={true}/> :
        <div className={classes.safeAreaContent}>
          <Grid container direction="column" className={classes.root} alignItems="center" justifyContent="space-between" >  
            <Grid item container className={classes.title} alignItems="center" justifyContent="center">
              <img src={logoLightBackground} alt="logo" className={classes.logo} />
            </Grid>
            {signInAttempts === 0 ?
              <Grid item className={classes.auth} >
                {Factory.get().applicationService.platform === FirebasePlatform && <MobileFirebaseAuthView onComplete={onSignInComplete}/>}
                {Factory.get().applicationService.platform === RealmPlatform && <RealmAuthView onComplete={onSignInComplete} />}
              </Grid>
              :
              <SimpleDialog
                titleKey="error"
                textKey={props.error != null ? props.error : "signinError"}
                buttonKeys={["ok"]}
                onComplete={() => setSignInAttempts(0)}>
              </SimpleDialog>
            }
            <Grid item>
              {appContext.currentDisplay?.displayPlatform !== TerminalPlatforms.IOS && signupPrompt} 
             </Grid>
            <Grid item container className={classes.loginConsent} justifyContent="center">
              {consentPrompt}
            </Grid>
          </Grid>
        </div>
      }
    </>
  );
}


