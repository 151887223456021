
import { log } from "../messagingService";


import { PushReceiverIF } from "../../api/push/pushReceiverIF";

import { Push } from "./push";

export abstract class PushReceiver implements PushReceiverIF {

    constructor() { 
        //log.traceIn( "constructor()");

        try {

            //log.traceOut( "constructor()" );
            
        } catch( error ) {

            log.warn( "Error initializing push manager", error );
            
            throw new Error( (error as any).message );
        }
    }

    async init(): Promise<void> {

        log.traceIn( "init()");

        try {

            log.traceOut( "init()" );

        } catch (error) {

            log.warn("Error initializing push receiver", error);

            throw new Error( (error as any).message );
        }
    }


    abstract register( callbacks?: { 
        onReceivePush? : ( push : Push ) => Promise<void>,
        onPushAction? : ( push : Push, actionId : string, actionValue? : string ) => Promise<void>
    } ) : Promise<string | undefined>;

}