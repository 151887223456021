import React from 'react';

import { Accordion, AccordionDetails, AccordionSummary, Box, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { SubdocumentPropertyIF } from 'services/database/api/properties/subdocumentPropertyIF';
import { DatabaseSubdocumentIF } from 'services/database/api/core/databaseSubdocumentIF';
import { PropertiesSelector } from 'services/database/api/core/propertiesSelector';

import DatabaseForm from 'ui/components/documentForm';
import PropertyLabel from '../propertyLabel';
import { PropertyDisplayMode } from '../propertyValue';
import { DatabasePropertyIF } from '../../../services/database/api/core/databasePropertyIF';


const subdocumentPropertiesSelector = { 
  excludePropertyKeys: ["id", "title", "description", "user", "unit", "company"] 
} as PropertiesSelector;

const defaultExpanded = true;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      paddingBottom: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    form: {
      width: '100%',
      marginTop: theme.spacing(-3),
      marginBottom: theme.spacing(-3),
    },
    singleForm: {
      width: '100%',
      marginTop: theme.spacing(-1),
      marginLeft: theme.spacing(-1)
    },
    heading: {
      color: theme.palette.text.secondary,
    },
    headingExpanded: {
      marginTop: theme.spacing(-6.25),
      marginLeft: theme.spacing(-1),
      paddingTop: theme.spacing(1),
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(1),
      position: 'absolute',
      display: 'flex',
      flexDirection: 'row',
      color: theme.palette.text.secondary,
      background: "#FFFFFF",
      fontSize: "small",
      zIndex: 999
    }
  }
  ));

export default function SubdocumentPropertyValue(props: {

  property: SubdocumentPropertyIF<DatabaseSubdocumentIF>,

  onPropertyChange?: (property: SubdocumentPropertyIF<DatabaseSubdocumentIF>) => void,

  displayMode: PropertyDisplayMode,

  singleProperty: boolean,

  hideColor?: boolean,

  disabled?: boolean,

  required?: boolean 

  }) {

  const [expanded, setExpanded] = React.useState(defaultExpanded);

  const classes = useStyles();


  if (props.displayMode === PropertyDisplayMode.Cell) {
    return (
      <React.Fragment>
        { props.property.subdocument() != null ? "..." : <>&nbsp;</>}
      </React.Fragment>
    );
  }

  const handleSubdocumentChange = async (databaseProperty: DatabasePropertyIF<any>) => {

    if (props.onPropertyChange != null) {
      props.onPropertyChange(props.property);
    }
  }

  if (props.singleProperty) {
    return (
      <>
        <div className={classes.singleForm}>
          <Box overflow="auto" flex={1}>
            <DatabaseForm databaseObject={props.property.subdocument()}
              onDatabaseObjectEditChange={handleSubdocumentChange}
              propertiesSelector={subdocumentPropertiesSelector} />
          </Box>
        </div>
      </>
    );
  }
  else {
    return (
      <>
        <div className={classes.root}>
          <Accordion variant="outlined" expanded={expanded} key={props.property.key()} >
            <AccordionSummary onClick={() => setExpanded(!expanded)} expandIcon={<ExpandMoreIcon />} >
              {expanded ?
                <Typography className={classes.headingExpanded}>
                  <PropertyLabel documentName={props.property.parent.documentName()} propertyKey={props.property.key()} />
                </Typography>
                :
                <Typography className={classes.heading}>
                  <PropertyLabel documentName={props.property.parent.documentName()} propertyKey={props.property.key()} />
                </Typography>
              }
            </AccordionSummary>
            <AccordionDetails>
              <div className={classes.form}>
                <DatabaseForm databaseObject={props.property.subdocument()}
                  onDatabaseObjectEditChange={handleSubdocumentChange}
                  propertiesSelector={subdocumentPropertiesSelector} />
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      </>
    );
  }

}

