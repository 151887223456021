import React from 'react';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import { log } from "ui/app/app";

import { withTranslation, WithTranslation } from 'react-i18next';
import { TranslationKey } from '../../services/common/api/translatorIF';
import { Button, createStyles, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Theme, WithStyles, withStyles } from '@material-ui/core';

export const ButtonKeyOK = "ok";
export const ButtonKeyCancel = "cancel";
export const ButtonKeyYes = "yes";
export const ButtonKeyNo = "no";

const styles = (theme: Theme) => createStyles({
    content: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(0),
        paddingLeft: theme.spacing(6),
        paddingRight: theme.spacing(6)
    },  
    buttons: {
      display: "flex", 
      justifyContent:'flex-end', 
      paddingRight: theme.spacing(6)
    }
  });

export const confirmationDialog = ( 
    messageKey : string, 
    titleKey? : string,
    buttonKeys? : string[] ) : Promise<boolean> => {

    return new Promise<boolean>( (resolve, reject) => {

        log.traceIn( "confirm()", messageKey, titleKey );

        try {

            confirmAlert({
                customUI: ({ onClose }) => {

                    const onComplete = ( buttonKey? : string ) => {

                        onClose();

                        if( buttonKey === ButtonKeyOK || buttonKey === ButtonKeyYes ) {
                            log.traceOut( "confirm()", true );
                            resolve( true );
                        }
                        else {
                            log.traceOut( "confirm()", false );
                            resolve( false );
                        }
                    }

                    return (
                        <ModifiedSimpleDialog
                            titleKey={titleKey}
                            textKey={messageKey}
                            buttonKeys={ buttonKeys != null ? buttonKeys : [ButtonKeyYes, ButtonKeyNo]}
                            onComplete={onComplete}>
                        </ModifiedSimpleDialog>
                    );
                }
            });          

        } catch( error ) {

            log.warn( "confirm()", error );

            reject();
        }
    });
}

export const alertDialog = ( 
    messageKey : string, 
    titleKey? : string,
    buttonKey? : string ) : Promise<void> => {

    return new Promise<void>( (resolve, reject) => {

        log.traceIn( "confirm()", messageKey, titleKey );

        try {

            confirmAlert({
                customUI: ({ onClose }) => {

                    const onComplete = ( buttonKey? : string ) => {

                        onClose();
                        resolve();
                    }

                    return (
                        <ModifiedSimpleDialog
                            titleKey={titleKey}
                            textKey={messageKey}
                            buttonKeys={ buttonKey != null ? [buttonKey] : [ButtonKeyOK]}
                            onComplete={onComplete}>
                        </ModifiedSimpleDialog>
                    );
                }
            });          

        } catch( error ) {

            log.warn( "confirm()", error );

            reject();
        }
    });
}

export const errorDialog = ( error : any ) : Promise<void> => {

    return new Promise<void>( (resolve, reject) => {

        log.traceIn( "confirm()" );
        try {

            confirmAlert({
                customUI: ({ onClose }) => {

                    const onComplete = ( buttonKey? : string ) => {

                        onClose();
                        resolve();
                    }

                    return (
                        <ModifiedSimpleDialog
                            titleKey="error" 
                            textKey={error instanceof Error ? (error as Error).message : error} 
                            buttonKeys={[ButtonKeyOK]}
                            onComplete={onComplete}>
                        </ModifiedSimpleDialog>
                    );
                }
            });          

        } catch( error ) {

            log.warn( "confirm()", error );

            reject();
        }
    });
}

interface SimpleDialogProps extends WithStyles<typeof styles>, WithTranslation {
  textKey : string,
  titleKey? : string,
  buttonKeys : string[],
  onComplete : ( buttonKey? : string ) => void
}


interface SimpleDialogState { 
    open : boolean
}

class SimpleDialog extends React.PureComponent<SimpleDialogProps,SimpleDialogState> {

  constructor( props: SimpleDialogProps ) {
    
    super(props); 

    this.state = { 
        open : false,
    } as SimpleDialogState;

    this.dialogTranslation = this.dialogTranslation.bind(this);

    //log.traceInOut( "constructor()" );
  }


  async componentDidMount() {

    log.traceIn( "componentDidMount()" );

    this.setState( {
        open : true,
    });

    log.traceIn( "componentDidMount()" );
  }

  
  private dialogTranslation( key : string ) {

    let result;

    log.traceIn( "dialogTranslation()", {key} );


    if( this.props.i18n.exists( key, { ns: TranslationKey.Errors} ) ) {

      result = this.props.t( key, { ns: TranslationKey.Errors} );
    }
    else if( this.props.i18n.exists( key, { ns: TranslationKey.Prompts} ) ) {

        result = this.props.t( key, { ns: TranslationKey.Prompts} );
    }
    else if( this.props.i18n.exists( key) ) { 

        result = this.props.t( key );
    }
    else {
        result = key; 
    }

    log.traceOut( "dialogTranslation()", {result} );
    return result;
  }
  
  render() {

    //log.traceInOut( "render()" );

    const { classes } = this.props;

    const handleClose = ( buttonKey? : string ) => {

        log.traceInOut( "handleClose()", buttonKey );

        this.setState( { open : false } );     
        
        this.props.onComplete( buttonKey );
    }

    return (
     <React.Fragment>
        <Dialog 
            open={this.state.open}
            maxWidth="sm"
            onClose={() => handleClose()} 
          >
            {this.props.titleKey == null ? null :
                <DialogTitle>{this.dialogTranslation( this.props.titleKey! )}</DialogTitle>   
            }
            <DialogContent className={classes.content}>
                <DialogContentText align="justify" > 
                    {this.dialogTranslation( this.props.textKey )}
                </DialogContentText>
            </DialogContent>
            <DialogActions className={classes.buttons}>
            { this.props.buttonKeys.map((buttonKey) =>
                <Button key={buttonKey} onClick={() => handleClose( buttonKey )} color="primary">
                    {this.dialogTranslation( buttonKey )}
                </Button>
            )}
            </DialogActions>
          </Dialog>
        </React.Fragment>
      );
  }
}


const ModifiedSimpleDialog = withTranslation()(withStyles(styles)(SimpleDialog));

export default ModifiedSimpleDialog;

