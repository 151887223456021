import * as React from 'react'


import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import { withTranslation, WithTranslation } from 'react-i18next';

import {format,  add, differenceInYears, differenceInMonths, differenceInDays, differenceInHours, differenceInMinutes, addYears, addQuarters, addMonths} from 'date-fns/esm'

import { AppContext } from 'ui/app/appContext';
import { log } from 'ui/app/app';


import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Label, AreaChart, Area, ResponsiveContainer, XAxis, YAxis, Tooltip, BarChart, Bar, Legend } from 'recharts';
import { locale, dayFormat, hourFormat, minuteFormat, monthFormat, quarterFormat, secondFormat, weekFormat, yearFormat } from 'ui/app/localization';

import theme from 'ui/app/theme';
import { DateRange } from "../../services/database/api/core/dateRange";
import { DefaultFilterDateRange } from './appFrame';
import { addDays, addHours, addMinutes, addSeconds, addWeeks, endOfToday, startOfDay, startOfHour, startOfMinute, startOfMonth, startOfQuarter, startOfSecond, startOfWeek, startOfYear } from 'date-fns';
import { TimeSpan, TimeSpans } from '../../services/database/api/definitions/timeSpan';
import { errorDialog } from './simpleDialog';
import { DatabaseProps, DatabaseState } from './databaseView';
import Loading from './loading';
import { translatedPropertyValue } from './propertyValue';
import { EmptyColor } from './colorMap';
import { ReferenceHandle } from '../../services/database/api/core/referenceHandle';
import { propertyColor } from './propertyColor';
import { translatedDefinition } from './definitionText';
import { DatabaseObserverIF } from '../../services/database/api/core/databaseObserverIF';
import { Monitor } from '../../services/common/api/monitor';
import { ObservableIF } from '../../services/common/api/observableIF';
import { Observation } from '../../services/common/api/observation';
import { DatabaseDocumentIF } from '../../services/database/api/core/databaseDocumentIF';
import { PropertyTypes } from '../../services/database/api/definitions/propertyType';

const styles = (theme: Theme) => createStyles({
  root: { 
    height: "100%",
    marginBottom: theme.spacing(3)
  },
  chart: {
  },
  xAxisLabel: {
    color: theme.palette.text.secondary
  },
  yAxisLabel: {
    color: theme.palette.text.secondary
  }
});

const MaxSteps = 120;

export interface DatabaseChartProps extends DatabaseProps
{ 
  databaseObserver : DatabaseObserverIF<DatabaseDocumentIF>,

  accumulate?: boolean,

  timeSpan? : TimeSpan,

  xLabel? : string,

  yLabel? : string,

  hideLegend?: boolean
}


interface MatchParams {
}

export interface ReactDatabaseChartProps extends 
    DatabaseChartProps,
    WithStyles<typeof styles>, 
    WithTranslation, 
    RouteComponentProps<MatchParams>
{}

type ChartDataPoint = {
  time: string } & {
  [chartKey : string]: number
}

interface DatabaseChartState extends DatabaseState { // Component State

  loading: boolean,

  accumulate?: boolean,

  chartData: Map<string,ChartDataPoint>

}

class DatabaseChart extends React.PureComponent<ReactDatabaseChartProps,DatabaseChartState> {

  constructor( props: ReactDatabaseChartProps ) {
    
    super(props);

    this.state = { 

      accumulate: this.props.accumulate,

      highlightedPropertyKey: this.props.highlightedPropertyKey,

      loading: true,

      chartData: new Map<string,ChartDataPoint>()

    } as DatabaseChartState;

    this.onUpdateDatabases = this.onUpdateDatabases.bind(this);
    this.updateChartData = this.updateChartData.bind(this);
    this.chartRange = this.chartRange.bind(this);
    this.ranges = this.ranges.bind(this);
    this.formatChartUnit = this.formatChartUnit.bind(this);
    this.chartUnit = this.chartUnit.bind(this);

    this._chartKeys = [];
    this._chartColors = new Map<string, string>();
    this._chartLabels = new Map<string, string>();

    //log.traceInOut( "constructor()" );
  }

  async componentDidMount() {

    try {
      log.traceIn("componentDidMount()" );

      await this.updateChartData( this.props.accumulate, this.props.highlightedPropertyKey );

      await this.props.databaseObserver.subscribe({ 
        observer: this,
        onNotify: this.onUpdateDatabases 
        } as Monitor );


      await this.props.databaseObserver.update();

      this.setState( {
        loading: false
      });

      log.traceOut("componentDidMount()");

    } catch( error ) {
          
      log.warn( "componentDidMount()", error ); 

      await errorDialog( error);

      log.traceOut( "componentDidMount()", error );
    }
  }
 
  async componentDidUpdate() {

    try {
      //log.traceIn("componentDidUpdate()" );

      await this.props.databaseObserver.update();

      if( this.props.accumulate !== this.state.accumulate ||
          this.props.highlightedPropertyKey !== this.state.highlightedPropertyKey ) {

        await this.updateChartData( this.props.accumulate, this.props.highlightedPropertyKey );
      }

      //log.traceOut("componentDidUpdate()" );

    } catch( error ) {
          
      log.warn( "componentDidUpdate()", error );

      await errorDialog( error);

    }
  }

  async componentWillUnmount() {
    log.traceIn("componentWillUnmount()");

    try {

      await this.props.databaseObserver.unsubscribe( this );

      log.traceOut("componentWillUnmount()");

    } catch (error) {
      log.warn("componentWillUnmount()", "Error unmounting collection list", error);
    }
  }

  private onUpdateDatabases = async (observable : ObservableIF, observation : Observation ) => {

    //log.traceIn("onUpdateDatabases()");

    try {
      if( observable !== this.props.databaseObserver ) {
        throw new Error("Mismatching database observer");
      }

      await this.updateChartData( this.state.accumulate, this.state.highlightedPropertyKey );

      //log.traceOut("onUpdateDatabases()");

    } catch (error) {
      log.warn("onUpdateDatabases()", "Error updating databases", error);

      log.traceOut("onUpdateDatabases()", "error");
    }
  }

  private async updateChartData( accumulate?: boolean, highlightedPropertyKey? : string ) {

    //log.traceIn("updateChartData()" );

    try {      
      
      const chartData = new Map<string,ChartDataPoint>();

      let accumulatedBeforeStart = {} as ChartDataPoint;

      let chartRange = this.chartRange( this.props.databaseObserver.dateRange() );

      const timeSpan = this.chartUnit( chartRange, this.props.timeSpan );

      const ranges = this.ranges( this.props.databaseObserver.dateRange() );

      ranges!.forEach( time => {
        chartData.set( time, { time: time } as ChartDataPoint );
      })

      this._chartKeys = [];
      this._chartColors = new Map<string,string>();
      this._chartLabels = new Map<string,string>();

      for (const databaseDocument of this.props.databaseObserver.documents().values()) {

        // We filter dates ourselves to accummulate

        if( !databaseDocument.matchFilter( {
            matchHistoric: this.props.databaseObserver.includeHistoric(), 
            databaseFilters: this.props.databaseObserver.databaseFilters() }) ) {
           //log.debug("updateChartData()", "Document property filtered", document );
          continue;
        }

        if( databaseDocument.startDate.date() == null ) {
          log.warn("updateChartData()", "Document has no start date", document );
          continue;
        }

        if( chartRange != null && chartRange.to != null &&
          databaseDocument.startDate.date()!.getTime() > chartRange.to.getTime() ) {

            //log.debug("updateChartData()", "Starts after", document );
            continue;
        }

        if (databaseDocument.endDate.date() != null &&
          chartRange.from != null && databaseDocument.endDate.date()!.getTime() < chartRange.from.getTime()) {

          //log.debug("updateChartData()", "Ends before", document );
          continue;
        }

        let highlightedProperty = !!this.props.multipleDocuments ? databaseDocument.name :
          highlightedPropertyKey != null ?  databaseDocument.property(highlightedPropertyKey) :
          undefined;

        if( highlightedProperty == null ) {
          highlightedProperty = databaseDocument.name;
        }

        let chartKey;
        let chartColor;
        let chartLabel;

        const value = highlightedProperty.value();

        if (value == null) {
          chartKey = "";
        }
        else if (highlightedProperty.type === PropertyTypes.Definition ) {

          chartKey = value + "";
          chartLabel = translatedDefinition((highlightedProperty as any).definition, value + "");

        }
        else if (highlightedProperty.type === PropertyTypes.Tenant ||
          highlightedProperty.type === PropertyTypes.Owner ||
          highlightedProperty.type === PropertyTypes.Reference ) {

          chartKey = (value as ReferenceHandle<DatabaseDocumentIF>).path.split("?")[0];
          chartLabel = (value as ReferenceHandle<DatabaseDocumentIF>).title;
        }
        else if( typeof value === "string" || typeof value === "number" || typeof value === "boolean" ) {
          chartKey = value + "";
          chartLabel = translatedPropertyValue( highlightedProperty.parent.documentName(), value + "" );
        }
        else {
          chartKey = JSON.stringify( value );
          chartLabel = JSON.stringify( value );
        }

        if (chartKey == null) {
          chartKey = "";
        }

        chartColor = propertyColor(highlightedProperty); 

        if (chartColor == null) {
          chartColor = EmptyColor;
        }

        if (!this._chartKeys.includes(chartKey)) {

          this._chartKeys.push(chartKey);

          this._chartColors.set(chartKey, chartColor!);

          this._chartLabels.set(chartKey, chartLabel != null ? chartLabel : this.props.t("notSet")); 

          //log.debug("updateChartData()", {chartKey}, {chartColor}, {chartLabel});

        }

        if( chartLabel != null &&
            chartLabel.length > 0 &&
            (this._chartLabels.get( chartKey ) == null || this._chartLabels.get( chartKey )!.length === 0 ) ) {
              
            this._chartLabels.set( chartKey, chartLabel )
        }
        

        if( chartRange != null && chartRange.to != null &&
          databaseDocument.startDate.date()!.getTime() > chartRange.to.getTime() ) {

            //log.debug("updateChartData()", "Starts after", document );
            continue;
        }

        if (databaseDocument.endDate.date() != null &&
          chartRange.from != null && databaseDocument.endDate.date()!.getTime() < chartRange.from.getTime()) {

          //log.debug("updateChartData()", "Ends before", {databaseDocument} );
          continue;
        }
        
        const startTime = this.formatChartUnit( databaseDocument.startDate.date()!, timeSpan, accumulate );
        //log.debug("updateChartData()", "startTime", startTime );

        const startChartDataPoint = chartData.get( startTime )!;
        //log.debug("updateChartData()", "startChartDataPoint", startChartDataPoint );

        const endTime = databaseDocument.endDate.date() != null ? 
          this.formatChartUnit( databaseDocument.endDate.date()!, timeSpan, accumulate ) : undefined;
        //log.debug("updateChartData()", "endTime", endTime );

        const endChartDataPoint = endTime != null ? chartData.get( endTime )! : undefined;
        //log.debug("updateChartData()", "endChartDataPoint", endChartDataPoint );

        if( chartRange != null && chartRange.from != null &&
            databaseDocument.startDate.date()!.getTime() < chartRange.from.getTime() ) {

          //log.debug("updateChartData()", "starts before", {databaseDocument} );

          if( accumulatedBeforeStart[chartKey] == null ) {
            accumulatedBeforeStart[chartKey] = 0;
          }
          accumulatedBeforeStart[chartKey] = +accumulatedBeforeStart[chartKey] + 1;
        }
        else if( startChartDataPoint != null ) {
          //log.debug("updateChartData()", "starts during", {databaseDocument}  );

          if (startChartDataPoint[chartKey] == null) {
            startChartDataPoint[chartKey] = 0;
          }

          startChartDataPoint[chartKey] = startChartDataPoint[chartKey] + 1;
        }

        if (databaseDocument.endDate.date() != null && chartRange.to != null &&
          databaseDocument.endDate.date()!.getTime() < chartRange.to!.getTime()) {

          //log.debug("updateChartData()", "ends during", {databaseDocument}  );

          if (!!accumulate) {

            if (endChartDataPoint![chartKey] == null) {
              endChartDataPoint![chartKey] = -1;
            }
            else {
              endChartDataPoint![chartKey] = endChartDataPoint![chartKey] - 1;
            }
          }
        }
      }

      //log.debug("updateChartData()", "chartKeys", this._chartKeys );

      if( !!accumulate ) {

        chartData.forEach( chartDataPoint => {

          this._chartKeys!.forEach( chartKey => {

            if( accumulatedBeforeStart[chartKey] == null ) {
              accumulatedBeforeStart[chartKey] = 0;
            }

            if( chartDataPoint[chartKey] == null ) {
              chartDataPoint[chartKey] = 0;
            }

            chartDataPoint[chartKey] += accumulatedBeforeStart[chartKey];
  
            accumulatedBeforeStart[chartKey] = +chartDataPoint[chartKey];
            
            //log.debug("updateChartData()", "accummulated", chartDataPoint.time, chartKey, accumulatedBeforeStart[chartKey] );
          })
        })
      }

      this.setState({ 

        highlightedPropertyKey: highlightedPropertyKey,

        accumulate: accumulate,

        chartData: chartData  
      })
      
      //log.traceOut("chartData()", {chartData});

    } catch (error) {
      log.warn("Error reading list rows for documents", error);

      throw new Error( (error as any).message );
    }
  }


  private chartRange( dateRange? : DateRange ) : DateRange {

    let chartRange;
    if( dateRange != null ) {
      chartRange = Object.assign( {}, dateRange );
    }
    else {
      chartRange = {};
    }

    if( chartRange.from == null ) {
      chartRange.from = this._earliest != null ? this._earliest : DefaultFilterDateRange.from;
    }

    if( chartRange.to == null ) {
      chartRange.to = endOfToday();
    }
    return chartRange;
  }

  private ranges( dateRange? : DateRange ):  string[] {

    //log.traceIn("ranges()");

    try {
      let ranges : string[] = [];

      const chartRange = this.chartRange( dateRange );

      //log.debug("ranges()", "chartRange", chartRange );

      const timeSpan = this.chartUnit( chartRange, this.props.timeSpan );

      //log.debug("ranges()", "timeSpan", timeSpan );

      if( chartRange == null || chartRange.from == null || chartRange.to == null ) {
        //log.traceOut("ranges()", "no dates");
        return ranges;
      }
      
      for( let date = chartRange.from!;;) {

        //log.debug("ranges()", date );

        let currentDate : Date;
        let nextDate : Date;
        let timeFormat : string

        switch( timeSpan ) {

          case TimeSpans.Years:

            currentDate = startOfYear( date );
            timeFormat = yearFormat();
            nextDate = add( date, { years: 1 } as Duration );
            break;

          case TimeSpans.Quarters:
          
            currentDate = startOfQuarter( date );
            timeFormat = quarterFormat();
            nextDate = add( date, { months: 3 } as Duration );
            break;

          case TimeSpans.Months:

            currentDate = startOfMonth( date );
            timeFormat = monthFormat();
            nextDate = add( date, { months: 1 } as Duration );
            break;

          case TimeSpans.Weeks:

            currentDate = startOfWeek( date );
            timeFormat = weekFormat();
            nextDate = add( date, { weeks: 1 } as Duration );
            break;

          case TimeSpans.Days:

            currentDate = startOfDay( date );
            timeFormat = dayFormat();
            nextDate = add( date, { days: 1 } as Duration );
            break;

          case TimeSpans.Hours:

            currentDate = startOfHour( date );
            timeFormat = hourFormat();
            nextDate = add( date, { hours: 1 } as Duration );
            break;

          case TimeSpans.Minutes:

            currentDate = startOfMinute( date );
            timeFormat = minuteFormat();
            nextDate = add( date, { minutes: 1 } as Duration );
            break;
  
          case TimeSpans.Seconds:

            currentDate = startOfSecond( date );
            timeFormat = secondFormat();
            nextDate = add( date, { seconds: 1 } as Duration );
            break;

          default: 
            throw new Error( "Unrecognized chart unit" );
        }

        const time = format( currentDate, timeFormat, { locale: locale() } );

        ranges.push( time );

        if( ranges.length > MaxSteps ) {
          log.warn("Too many steps", ranges);
          throw new Error( "Too many steps, maximum is " + MaxSteps );
        }

        date = nextDate;

        if( currentDate.getTime() > chartRange.to!.getTime() ) {

          //log.traceOut("ranges()", "one more", ranges );
          return ranges;
        }
      }
    } catch (error) {
      log.warn("Error calculating chart ranges", error);

      throw new Error( (error as any).message );
    }
  }

  private formatChartUnit( date : Date, timeSpan : TimeSpan, accumulate? : boolean ) : string {

    const adjust = !!accumulate ? 1 : 0;

    switch( timeSpan ) {
  
      case TimeSpans.Years:
  
        return format( addYears( date, adjust), yearFormat(), {  locale: locale() } );
  
      case TimeSpans.Quarters:
  
        return format( addQuarters( date, adjust), quarterFormat(), {  locale: locale() } );
  
      case TimeSpans.Months:
  
        return format( addMonths( date, adjust), monthFormat(), {  locale: locale() } );
  
      case TimeSpans.Weeks:
  
        return format( addWeeks( date, adjust), weekFormat(), {  locale: locale() } );
  
      case TimeSpans.Days:
  
        return format( addDays( date, adjust), dayFormat(), {  locale: locale() } );
  
      case TimeSpans.Hours:
  
        return format( addHours( date, adjust), hourFormat(), {  locale: locale() } );
  
      case TimeSpans.Minutes:
  
        return format( addMinutes( date, adjust), minuteFormat(), {  locale: locale() } );
  
      case TimeSpans.Seconds:
  
        return format( addSeconds( date, adjust), secondFormat(), {  locale: locale() } );
  
      default: 
        throw new Error( "Unrecognized chart unit: " +  timeSpan );
    }
  }
  
  private chartUnit( chartRange : DateRange, timeSpan? : TimeSpan ) : TimeSpan {
  
    if (timeSpan != null) {
  
      switch (timeSpan) {
  
        case TimeSpans.Years:
  
          return TimeSpans.Months as TimeSpan;
  
        case TimeSpans.Quarters:
  
          return TimeSpans.Weeks as TimeSpan;
  
        case TimeSpans.Months:
  
          return TimeSpans.Days as TimeSpan;
  
        case TimeSpans.Weeks:
  
          return TimeSpans.Weeks as TimeSpan;
  
        case TimeSpans.Days:
  
          return TimeSpans.Hours as TimeSpan;
  
        case TimeSpans.Hours:
  
          return TimeSpans.Minutes as TimeSpan;
  
        case TimeSpans.Minutes:
  
          return TimeSpans.Seconds as TimeSpan;
  
        default:
          throw new Error("Unsupported date unit: " + timeSpan);
      }
    }
  
    if( differenceInYears( chartRange.to!, chartRange.from! ) >= 2 ) {
      return TimeSpans.Quarters as TimeSpan;
    }
    if( differenceInMonths( chartRange.to!, chartRange.from! ) >= 6 ) {
      return TimeSpans.Months as TimeSpan;
    }
    if( differenceInMonths( chartRange.to!, chartRange.from! ) > 2 ) {
      return TimeSpans.Weeks as TimeSpan;
    }
    if( differenceInDays( chartRange.to!, chartRange.from! ) > 2 ) {
      return TimeSpans.Days as TimeSpan;
    }
    if( differenceInHours( chartRange.to!, chartRange.from! ) > 2 ) {
      return TimeSpans.Hours as TimeSpan;
    }
    if( differenceInMinutes( chartRange.to!, chartRange.from! ) > 2 ) {
      return TimeSpans.Minutes as TimeSpan;
    }
  
    return TimeSpans.Seconds as TimeSpan;
  }

  render(): JSX.Element {

    //log.traceInOut("render()");

    const { classes } = this.props;

    const normalChart = (

      <BarChart
        data={Array.from(this.state.chartData.values())}
        className={classes.chart}
        margin={{
          right: theme.spacing(4),
          bottom: theme.spacing(4),
          left: theme.spacing(-3)
        }} >
        <Tooltip />
        <Legend layout="horizontal" verticalAlign="top" align="right"/>
        {this._chartKeys!.map(chartKey => (
          <Bar 
            key={chartKey} 
            dataKey={chartKey}
            name={this._chartLabels.get(chartKey)}
            stackId={1}
            stroke={this._chartColors.get(chartKey) }
            fill={this._chartColors.get(chartKey) } />
        ))}
        <XAxis dataKey="time" stroke={theme.palette.text.secondary}>
          {this.props.xLabel == null ? null :
            <Label angle={0} position="bottom" style={{ textAnchor: 'middle', fill: theme.palette.text.primary }} >
              {this.props.t(this.props.xLabel!)}
            </Label>
          }
        </XAxis>
        <YAxis stroke={theme.palette.text.secondary} allowDecimals={false} >
          {this.props.yLabel == null ? null :
            <Label angle={0} position="left" style={{ textAnchor: 'middle', fill: theme.palette.text.primary }} >
              {this.props.t(this.props.yLabel!)}
            </Label>
          }
        </YAxis>
      </BarChart>
    );

    const accumulatedChart = (
      <AreaChart
        data={Array.from(this.state.chartData.values())}
        className={classes.chart}
        margin={{
          right: theme.spacing(4),
          bottom: theme.spacing(4),
          left: theme.spacing(-3)
        }} >
        <Tooltip />
        {!this.props.hideLegend && <Legend layout="horizontal" verticalAlign="top" align="right"/>}
        {this._chartKeys!.map(chartKey => (
          <Area 
            type="monotone" 
            key={chartKey} 
            dataKey={chartKey} 
            name={this._chartLabels.get(chartKey)}
            stackId={1}
            stroke={this._chartColors.get(chartKey) }
            fill={this._chartColors.get(chartKey) } />
        ))}
        <XAxis dataKey="time" stroke={theme.palette.text.secondary}>
          {this.props.xLabel == null ? null :
            <Label angle={0} position="bottom" style={{ textAnchor: 'middle', fill: theme.palette.text.primary }} >
              {this.props.t(this.props.xLabel!)}
            </Label>
          }
        </XAxis>
        <YAxis stroke={theme.palette.text.secondary} allowDecimals={false} >
          {this.props.yLabel == null ? null :
            <Label angle={0} position="left" style={{ textAnchor: 'middle', fill: theme.palette.text.primary }} >
              {this.props.t(this.props.yLabel!)}
            </Label>
          }
        </YAxis>
      </AreaChart>
    )
    

    return (
      <React.Fragment>
        {this.state.loading ? <Loading /> :
          <AppContext.Consumer>
            {appContext => (
              <ResponsiveContainer>
                {!!this.state.accumulate ? accumulatedChart : normalChart}
              </ResponsiveContainer>
            )}
          </AppContext.Consumer>
        }
      </React.Fragment>
    );
  }


  private _earliest : Date | undefined;

  private _latest : Date | undefined;

  private _chartKeys : string[];

  private _chartColors : Map<string,string>;

  private _chartLabels : Map<string,string>;

}


const ModifiedDatabaseTable = withRouter(withTranslation()(withStyles(styles)(DatabaseChart)));

export default ModifiedDatabaseTable;












