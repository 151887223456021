import { Subdocuments } from "../../services/database/api/subdocuments";


export const HealthguardSubdocuments = {

    ...Subdocuments,
}

export type HealthguardSubdocumentName = keyof (typeof HealthguardSubdocuments);

export const HealthguardSubdocumentNames = Object.values( HealthguardSubdocuments );




