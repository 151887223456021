import { Registration } from "./registration";
import { Hazard } from "./hazard";
import { VaccineRegistrationIF } from "../../api/documents/vaccineRegistrationIF";
import { log } from "../../../services/database/framework/databaseService";
import { CollectionDatabase } from "../../../services/database/impl/core/collectionDatabase";
import { OptionsSource } from "../../../services/database/impl/core/optionsSource";
import { NumberProperty } from "../../../services/database/impl/properties/numberProperty";
import { TextProperty } from "../../../services/database/impl/properties/textProperty";
import { TextsProperty } from "../../../services/database/impl/properties/textsProperty";
import { VaccineRegistrationDocument } from "../../api/registrationDocuments";


export class VaccineRegistration extends Registration implements VaccineRegistrationIF {

    constructor( vaccineRegistrationsCollection : CollectionDatabase<VaccineRegistration>, documentPath? : string  ) {

        super( VaccineRegistrationDocument, vaccineRegistrationsCollection, documentPath );

        try {
            this.hazard.required = true;

            this.type = new TextProperty( this );
            this.type.setOptionsSource( new OptionsSource<Hazard,TextsProperty>( this.hazard, "vaccines" )  );

            this.installment = new NumberProperty( this, 1, 1 );
            this.installment.required = true;

            //log.traceInOut( "constructor()", VaccineRegistrationsCollection );

        } catch( error ) {

            log.warn( "constructor()", "Error initializing test", error );
            
            throw new Error( (error as any).message );
        }
    }

    readonly type: TextProperty;

    readonly installment: NumberProperty; 

 }

