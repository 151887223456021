
import { Factory } from "../../../services/common";
import { log } from "../../../services/database/framework/databaseService";
import { HealthguardCompany } from "../documents/healthguardCompany";
import { HealthguardLocation } from "../documents/healthguardLocation";
import { HealthguardLocationManager } from "./healthguardLocationManager";
import { MeasureManager } from "./measureManager";

export class HealthguardCompanyManager {

    static getInstance() : HealthguardCompanyManager {
        if( HealthguardCompanyManager._instance == null ) {
            HealthguardCompanyManager._instance = new HealthguardCompanyManager();
        }
        return HealthguardCompanyManager._instance;
    }


    async handleHealthguardCompanyCreated( createdCompany: HealthguardCompany ) {

        try {
            log.traceIn("handleHealthguardCompanyCreated()", createdCompany.databasePath() );

            log.traceOut("handleHealthguardCompanyCreated()");

        } catch (error) {
            log.warn("Error handling document create", error);

            log.traceOut("handleHealthguardCompanyCreated()", "error");
        }
    }


    async handleHealthguardCompanyUpdated( updatedCompany: HealthguardCompany ) {

        try {
            log.traceIn("handleHealthguardCompanyUpdated()", updatedCompany.databasePath() );

            if( Factory.get().authenticationService?.authenticationClaims()?.companyId !== updatedCompany.id.value() ) {
                log.traceOut("handleHealthguardCompanyUpdated()", "Not company admin");
                return;
            }

            await this.updateLocationDefaultReadinessLevels( updatedCompany );

            await this.updateMeasuresState( updatedCompany );

            log.traceOut("handleHealthguardCompanyUpdated()");

        } catch (error) {
            log.warn("Error handling document update", error);

            log.traceOut("handleHealthguardCompanyUpdated()", "error");
        }
    } 

    async updateLocationDefaultReadinessLevels( company: HealthguardCompany ) {

        try {
            log.traceIn("updateLocationDefaultReadinessLevels()", company.databasePath() );

            const companyReadinessLevel = company.readinessLevel.value();

            const locations = await company.locations.database().documents() as Map<string,HealthguardLocation>;

            for( const location of locations.values() ) { 

                HealthguardLocationManager.getInstance().updateLocationDefaultReadinessLevel( location, companyReadinessLevel );
            } 

            log.traceOut("updateLocationDefaultReadinessLevels()");

        } catch (error) {
            log.warn("Error handling company readiness levels", error);
        }
    } 

    async updateMeasuresState( company: HealthguardCompany ) {

        try {
            log.traceIn("updateMeasuresState()", company.databasePath() );

            const measures = await company.measures.database().documents();

            for( const measure of measures.values() ) {

                const locations = measure.locations.value();

                if( locations == null || locations.size === 0 ) {

                    // If measure has a location, the measure will be updated from location update.

                    await MeasureManager.getInstance().updateMeasureState( measure, company );

                    await measure.update();
                }
            }

            log.traceOut("updateMeasuresState()");

        } catch (error) {
            log.warn("Error handling measure activity statuses", error);

            log.traceOut("updateMeasuresState()", "error");
        }
    } 

    private static _instance? : HealthguardCompanyManager; 

}

