import { CompaniesCollection, UnitsCollection, UsersCollection } from "../../api/collections";
import { TerminalPlatformDefinition } from "../../api/definitions";
import { TerminalPlatform, TerminalPlatforms } from "../../api/definitions/terminalPlatform";
import { CompanyDocument, TerminalDocument, UnitDocument, UserDocument } from "../../api/documents";
import { TerminalIF } from "../../api/documents/terminalIF";
import { DatabaseDocument } from "../../framework/databaseDocument";
import { log } from "../../framework/databaseService";
import { CollectionDatabase } from "../core/collectionDatabase";
import { BooleanProperty } from "../properties/booleanProperty";
import { DateProperty } from "../properties/dateProperty";
import { DefinitionProperty } from "../properties/definitionProperty";
import { TenantProperty } from "../properties/tenantProperty";
import { OwnerProperty } from "../properties/ownerProperty";
import { TextProperty } from "../properties/textProperty";
import { Company } from "./company";
import { Unit } from "./unit";
import { User } from "./user";

export class Terminal extends DatabaseDocument implements TerminalIF {

    constructor(terminalsCollection: CollectionDatabase<Terminal>, documentPath?: string) {

        super(TerminalDocument, terminalsCollection, documentPath);

        try {

            this.company = new TenantProperty<Company>(this, CompaniesCollection, CompanyDocument);
            this.company.editable = false;

            this.unit = new OwnerProperty<Unit>(this, UnitsCollection, UnitDocument);

            this.user = new OwnerProperty<User>(this, UsersCollection, UserDocument);

            this.deviceId = new TextProperty(this); 
            this.deviceId.editable = false;
            this.deviceId.encrypt = false;

            this.terminalPlatform = new DefinitionProperty<TerminalPlatform>( this, TerminalPlatformDefinition, TerminalPlatforms ); 
            this.terminalPlatform.editable = false;

            this.manufacturer = new TextProperty(this);
            this.manufacturer.editable = false;

            this.model = new TextProperty(this);
            this.model.editable = false;

            this.operatingSystem = new TextProperty(this);
            this.operatingSystem.editable = false;

            this.operatingSystemVersion = new TextProperty(this);
            this.operatingSystemVersion.editable = false;

            this.emulator = new BooleanProperty(this);
            this.emulator.editable = false;

            this.lastLogin = new DateProperty(this);
            this.lastLogin.editable = false;

            this.pushToken = new TextProperty(this); 
            this.pushToken.editable = false;

        } catch (error) {

            log.warn("constructor()", "Error initializing Terminal", error);

            throw new Error( (error as any).message );
        }
    }

    indexKey() : string {
        return TerminalDocument;
    }

    referenceDateProperty()  {
        return undefined;
    }

    readonly company: TenantProperty<Company>;

    readonly unit: OwnerProperty<Unit>;

    readonly user: OwnerProperty<User>;

    readonly deviceId: TextProperty;

    readonly terminalPlatform: DefinitionProperty<TerminalPlatform>;

    readonly manufacturer: TextProperty;

    readonly model: TextProperty;

    readonly operatingSystem: TextProperty;

    readonly operatingSystemVersion: TextProperty;

    readonly emulator: BooleanProperty;

    readonly lastLogin: DateProperty;

    readonly pushToken: TextProperty;


}
