import { AlertIF } from "../../api/documents/alertIF";
import { log } from "../../framework/databaseService";
import { CollectionDatabase } from "../core/collectionDatabase";
import { OptionsSource } from "../core/optionsSource";
import { TextProperty } from "../properties/textProperty";
import { TextsProperty } from "../properties/textsProperty";
import { ReferenceProperty } from "../properties/referenceProperty";
import { DatabaseDocument } from "../../framework/databaseDocument";
import { Unit } from "./unit";
import { Company } from "./company";
import { Location } from "./location";
import { TenantProperty } from "../properties/tenantProperty";
import { OwnerProperty } from "../properties/ownerProperty";
import { CompaniesCollection, DevicesCollection, LocationsCollection, UnitsCollection, UsersCollection } from "../../api/collections";
import { DefinitionProperty } from "../properties/definitionProperty";
import { GeolocationProperty } from "../properties/geolocationProperty";
import { DefaultTextType} from "../../api/definitions/textTypes";
import { SeverityDefinition } from "../../api/definitions";
import { Severities, Severity } from "../../api/definitions/severity";
import { AlertDocument, CompanyDocument, UnitDocument } from "../../api/documents";
import { Device } from "./device";
import { AlertManager } from "../managers/alertManager";
import { User } from "./user";
import { Database } from "../core/database";



export class Alert extends DatabaseDocument implements AlertIF {  

    constructor( alertDocumentName : string, alertCollection : CollectionDatabase<Alert>, documentPath? : string  ) {

        super( alertDocumentName, alertCollection, documentPath );

        try {
            this.startDate.required = true;

            this.company = new TenantProperty<Company>( this, CompaniesCollection, CompanyDocument );
            this.company.editable = false;

            this.unit = new OwnerProperty<Unit>( this, UnitsCollection, UnitDocument ); // parent

            this.reportedBy = new ReferenceProperty<User>( this, 
                () => this.parentDatabases( UsersCollection, {nearestIsCollectionGroup: true} ) as Database<User>[],
                "reportedAlerts" );  

            this.location = new ReferenceProperty<Location>( this, 
                () => this.parentDatabases( LocationsCollection, {nearestIsCollectionGroup: false} ) as Database<Location>[],
                "alerts" );  
                
            this.geolocation = new GeolocationProperty( this ); 

            this.geolocation.setTitleProperty( this.title );

            this.severity = new DefinitionProperty<Severity>(
                this, 
                SeverityDefinition, 
                Severities );
            this.severity.required = true;

            this.device = new ReferenceProperty<Device>( this, 
                () => this.parentDatabases( DevicesCollection, {nearestIsCollectionGroup: true} ) as Database<Device>[],
                "alerts" ); 

            this.deviceStatus = new TextProperty( this, DefaultTextType, true ); 
            
            this.premise = new TextProperty( this )
            this.premise.setOptionsSource( new OptionsSource<Location,TextsProperty>( this.location, "premises" )  );
 
            this.geolocation.setLevelProperty( this.severity );

            this.geolocation.setStatusProperty( this.description );  

            //log.traceInOut( "constructor()", AlertsCollection ); 

        } catch( error ) {

            log.warn( "constructor()", "Error initializing alert", error );
            
            throw new Error( (error as any).message );
        }
    }

    indexKey() : string {
        return AlertDocument;
    }

    async onCreate() : Promise<void> {

        //log.traceIn( "onCreate()" );

        try {

            await super.onCreate();

            await AlertManager.getInstance().handleCreateAlert( this );
    
            //log.traceOut( "onCreate()" );
  
        } catch( error ) {
            
            log.warn( "onCreate()", "Error handling created notification", error );
  
            throw new Error( (error as any).message );
        }
    }

    async onUpdate() : Promise<void> {

        //log.traceIn( "onUpdate()" );

        try {

            await super.onUpdate();

            await AlertManager.getInstance().handleUpdateAlert( this );
    
            //log.traceOut( "onUpdate()" );
  
        } catch( error ) {
            
            log.warn( "onUpdated()", "Error handling updated notification", error );
  
            throw new Error( (error as any).message );
        }
    }

    async onDelete() : Promise<void> {

        //log.traceIn( "onDelete()" );

        try {

            await super.onDelete();

            await AlertManager.getInstance().handleDeleteAlert( this );
    
            //log.traceOut( "onDelete()" );
  
        } catch( error ) {
            
            log.warn( "onDelete()", "Error handling deleted notification", error );
  
            throw new Error( (error as any).message );
        }
    }


    readonly company: TenantProperty<Company>;

    readonly unit: OwnerProperty<Unit>;

    readonly reportedBy: ReferenceProperty<User>;

    readonly location: ReferenceProperty<Location>;

    readonly geolocation : GeolocationProperty;

    readonly premise : TextProperty;

    readonly device: ReferenceProperty<Device>;

    readonly deviceStatus : TextProperty;

    readonly severity: DefinitionProperty<Severity>;
}
