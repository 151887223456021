
import { FirebasePlatform } from "../../../common/api/platforms";
import { Target } from "../../../common/api/targets";
import { FirebaseServiceIF } from "../../api/firebase/firebaseServiceIF";
import { ApplicationService } from "../applicationService";

export abstract class FirebaseService extends ApplicationService implements FirebaseServiceIF {

    constructor( target: Target, firebase: any ) {

        super( FirebasePlatform, target );
        
        this.firebase = firebase;
    }

    abstract database(): any;  

    abstract authenticator() : any; 

    abstract storage() : any; 

    abstract messaging() : any; 

    readonly firebase : any;

}
