import * as React from 'react'

import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import { withTranslation, WithTranslation } from 'react-i18next';

import { Box, Checkbox, Collapse, Grid, IconButton, Tooltip } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import CloseIcon from '@material-ui/icons/Close'; 
import TodayIcon from '@material-ui/icons/Today'; 
import EventIcon from '@material-ui/icons/Event'; 

import AccessTimeTwoToneIcon from "@mui/icons-material/AccessTimeTwoTone";
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';

import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import {startOfYear, 
  endOfYear, 
  endOfMonth, 
  startOfMonth, 
  endOfDay, 
  startOfDay, 
  endOfHour, 
  startOfHour,
  endOfMinute,
  startOfMinute} from 'date-fns/esm'

  import { addDays, 
    addHours, 
    addMinutes, 
    addMonths, 
    addQuarters, 
    addWeeks, 
    addYears, 
    endOfQuarter, 
    endOfWeek, 
    startOfQuarter, 
    startOfWeek, 
    subDays, 
    subHours, 
    subMinutes, 
    subMonths, 
    subQuarters, 
    subWeeks, 
    subYears } from 'date-fns';

import { AppContext, AppContextProps } from 'ui/app/appContext';
import { log } from 'ui/app/app';

import { PropertiesSelector } from 'services/database/api/core/propertiesSelector';
import DateFnsUtils from '@date-io/date-fns';
import { dateFormat, locale } from 'ui/app/localization';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { DefaultFilterPeriod, PersistentKeyFilterPeriod, PersistentKeyFilterDateRange, DefaultFilterDateRange, PersistentKeyFilterPeriodUnits, DefaultFilterPeriodUnits, PersistentKeyIncludeHistoric, DefaultIncludeHistoric } from './appFrame';
import { Factory } from 'services/common/api/factory';
import theme from '../app/theme';
import { translatedDefinition } from './definitionText'; 
import { TimeSpan, TimeSpans } from '../../services/database/api/definitions/timeSpan';
import { PeriodDefinition, TimeSpanDefinition } from '../../services/database/api/definitions';
import { Period, Periods } from '../../services/database/api/definitions/period';
import { errorDialog } from './simpleDialog';
import { DateRange } from '../../services/database/api/core/dateRange';
import { DisplayTypes } from '../app/display';
import { DatabaseIF } from '../../services/database/api/core/databaseIF';
import { DatabaseDocumentIF } from '../../services/database/api/core/databaseDocumentIF';
import { propertyInputVariant } from './propertyValue';

const styles = (theme: Theme) => createStyles({
  root: {
    marginTop: theme.spacing(0),
    marginRight: theme.spacing(0.5),
    display: 'flex', 
    zIndex: 999,
    justifyContent: 'begin'
  },
  select: {
    "& .MuiSelect-select:focus": {
      backgroundColor: 'transparent'
    }
  },
  collapse: {
    margin: theme.spacing(0),
    padding: theme.spacing(0)
  },
  inputLabel: {
    marginTop: theme.spacing(-0.5)
  },
  item: {
    marginBottom: theme.spacing(1)
  },  
  clearButton : {
    marginLeft: theme.spacing(-4)
  },
  dateIcon : {
    marginLeft: theme.spacing(-4),
    color: theme.palette.secondary.main
  },
});

export const nextYearsPeriod = ( years : number ) => ( {
  from: startOfDay( new Date() ),

  to: addYears( new Date(), years ),
} as DateRange )

export const thisYearPeriod = ( {

from: startOfYear( new Date() ),

to: endOfYear( new Date() )
} as DateRange)

export const nextYearPeriod = nextYearsPeriod( 1 );

export const lastYearsPeriod = ( years : number ) => ({

  from: subYears( new Date(), years ),

  to: endOfDay( new Date() )
} as DateRange)

export const lastYearPeriod = lastYearsPeriod( 1 );

export const thisQuarterPeriod = ({

from: startOfQuarter( new Date() ),

to: endOfQuarter( new Date() )
} as DateRange)

export const nextQuartersPeriod = ( quarters : number ) => ({

from: startOfDay( new Date() ),

to: addQuarters( new Date(), quarters ),
} as DateRange )


export const nextQuarterPeriod = nextQuartersPeriod( 1 );

export const lastQuartersPeriod = ( quarters : number ) => ({

  from: subQuarters( new Date(), quarters ),

  to: endOfDay( new Date() )

} as DateRange )

export const lastQuarterPeriod = lastQuartersPeriod( 1 );


export const thisMonthPeriod = ({

from: startOfMonth( new Date() ),

to: endOfMonth( new Date() )
} as DateRange )

export const nextMonthsPeriod = ( months : number ) => ({

  from: startOfDay( new Date() ),

  to: addMonths( new Date(), months )

} as DateRange )

export const nextMonthPeriod = nextMonthsPeriod( 1 );


export const lastMonthsPeriod = ( months : number ) => ({

  from: subMonths( new Date(), months ),

  to: endOfDay( new Date() )

} as DateRange )

export const lastMonthPeriod = lastMonthsPeriod( 1 );


export const thisWeekPeriod = ({

  from: startOfWeek( new Date() ),
  
  to: endOfWeek( new Date() )
  } as DateRange )
  
  export const nextWeeksPeriod = ( months : number ) => ({
  
    from: startOfDay( new Date() ),
  
    to: addWeeks( new Date(), months )
  
  } as DateRange )
  
  export const nextWeekPeriod = nextWeeksPeriod( 1 );
  
  
  export const lastWeeksPeriod = ( months : number ) => ({
  
    from: subWeeks( new Date(), months ),
  
    to: endOfDay( new Date() )
  
  } as DateRange )
  
  export const lastWeekPeriod = lastWeeksPeriod( 1 );
  
  
export const thisDayPeriod = ({

from: startOfDay( new Date() ),

to: endOfDay( new Date() )
} as DateRange )


export const nextDaysPeriod = ( days : number ) => ({

  from: startOfDay( new Date() ),

  to: addDays( new Date(), days )

} as DateRange )

export const nextDayPeriod = nextDaysPeriod( 1 );

export const lastDaysPeriod = ( days : number ) => ({

  from: subDays( new Date(), days ),

  to: endOfDay( new Date() )

} as DateRange )

export const lastDayPeriod = lastDaysPeriod( 1 );
 
export const nextHoursInterval = ( hours : number ) => ({

  from: startOfHour( new Date() ),

  to: addHours( new Date(), hours )
} as DateRange ) 


export const nextHourInterval = nextHoursInterval( 1 );

export const lastHoursInterval = ( hours : number ) => ({

  from: subHours( new Date(), hours ),

  to: endOfHour( new Date() )
} as DateRange )

export const lastHourInterval = lastHoursInterval( 1 );

export const thisHourInterval = ({

from: startOfHour( new Date() ),

to: endOfHour( new Date() )
} as DateRange )

export const nextMinutesInterval = ( minutes : number ) => ({

  from: startOfMinute( new Date() ),

  to: addMinutes( new Date(), minutes )
} as DateRange )

export const nextMinuteInterval = nextMinutesInterval( 1 );

export const LastMinutesInterval = ( minutes : number ) => ({

  from: subMinutes( new Date(), minutes ),

  to: endOfMinute( new Date() )
} as DateRange )

export const lastMinuteInterval = LastMinutesInterval( 1 );

export const thisMinuteInterval = ({

from: startOfMinute( new Date() ),

to: endOfMinute( new Date() )
} as DateRange )

export const periodRange = ( period : Period, units? : number ) : DateRange => {

  switch( period ) {

    case Periods.ThisYear: 
      return thisYearPeriod;

    case Periods.NextYear: 
      return nextYearPeriod;

    case Periods.NextYears: 
      return nextYearsPeriod( units! );

    case Periods.LastYear: 
    return lastYearPeriod;

    case Periods.LastYears: 
      return lastYearsPeriod( units! );

    case Periods.ThisQuarter: 
      return thisQuarterPeriod;

    case Periods.NextQuarter: 
      return nextQuarterPeriod;

    case Periods.NextQuarters: 
      return nextQuartersPeriod( units! );

    case Periods.LastQuarter: 
      return lastQuarterPeriod;

    case Periods.LastQuarters: 
    return lastQuartersPeriod( units! );

    case Periods.ThisMonth: 
      return thisMonthPeriod;

    case Periods.NextMonth: 
      return nextMonthPeriod;

    case Periods.NextMonths: 
      return nextMonthsPeriod( units! );

    case Periods.LastMonth: 
      return lastMonthPeriod;

    case Periods.LastMonths: 
      return lastMonthsPeriod( units! );

    case Periods.ThisWeek: 
        return thisWeekPeriod;

    case Periods.NextWeek:
      return nextWeekPeriod;

    case Periods.NextWeeks:
      return nextWeeksPeriod( units! );

    case Periods.LastWeek:
      return lastWeekPeriod;

    case Periods.LastWeeks:
      return lastWeeksPeriod( units! );

    case Periods.ThisDay:
      return thisDayPeriod;

    case Periods.NextDay:
      return nextDayPeriod;

    case Periods.NextDays:
      return nextDaysPeriod( units! );

    case Periods.LastDay:
      return lastDayPeriod;

    case Periods.LastDays:
      return lastDaysPeriod( units! );

    default:
      return {} as DateRange;
  }
}

export const periodUnitsOptions = ( period : Period ) : number[] => {

  switch( period ) {

    case Periods.NextYears: 
      return [2,3,4,5];

    case Periods.LastYears: 
      return [2,3,4,5];

    case Periods.NextQuarters: 
      return [2,3];

    case Periods.LastQuarters: 
      return [2,3];

    case Periods.NextMonths: 
      return [2,3,4,5,6,7,8,9,10,11,12,24,36,48,60];

    case Periods.LastMonths: 
      return [2,3,4,5,6,7,8,9,10,11,12];

    case Periods.NextWeeks:
      return [2,3,4,5,6,7,8,9,10,11,12];

    case Periods.LastWeeks:
      return [2,3,4,5,6,7,8,9,10,11,12];

    case Periods.NextDays:
      return [2,3,4,5,6,7,10,14,21,30,45,60];

    case Periods.LastDays:
      return [2,3,4,5,6,7,10,14,21,30,45,60];

    default:
      return [];
  }
}

const periodDateUnit = ( period : Period ) : TimeSpan | undefined => {
  if( period == null ) {
    return undefined;
  }
  if( period.endsWith( "Year" ) || period.endsWith( "Years" ) ) {
    return TimeSpans.Years as TimeSpan;
  }
  if( period.endsWith( "Quarter" ) || period.endsWith( "Quarters" ) ) {
    return TimeSpans.Quarters as TimeSpan;
  }
  if( period.endsWith( "Month" ) || period.endsWith( "Months" ) ) {
    return TimeSpans.Months as TimeSpan;
  }
  if( period.endsWith( "Week" ) || period.endsWith( "Weeks" ) ) {
    return TimeSpans.Weeks as TimeSpan;
  }
  if( period.endsWith( "Day" ) || period.endsWith( "Days" ) ) {
    return TimeSpans.Days as TimeSpan;
  }
  throw new Error( "No match for period: " + period );
}

export interface DateFilterProps extends WithStyles<typeof styles>, WithTranslation { 

  databases: DatabaseIF<DatabaseDocumentIF>[],

  propertyOptionsFilter? : PropertiesSelector,

  onDateFilterChange? : ( dateRange?: DateRange, includeHistoric? : boolean ) => void 
}


interface DateFilterState { 

  period?: Period,

  periodUnits? : number,

  dateRange? : DateRange,

  periodOpen: boolean,

  periodUnitsOpen : boolean,

  includeHistoric?: boolean
}

class DateFilter extends React.PureComponent<DateFilterProps,DateFilterState> {

  constructor( props: DateFilterProps ) {
    
    super(props);

    this.state = { 

      periodOpen: false,

      periodUnitsOpen: false,

     } as DateFilterState;

     this.filterPeriodPersistentKey = this.filterPeriodPersistentKey.bind( this );
     this.handleFilterPeriod = this.handleFilterPeriod.bind( this );
     this.filterPeriod = this.filterPeriod.bind( this );

     this.filterPeriodUnitsPersistentKey = this.filterPeriodUnitsPersistentKey.bind( this );
     this.handleFilterPeriodUnits = this.handleFilterPeriodUnits.bind( this );
     this.filterPeriodUnits = this.filterPeriodUnits.bind( this );

     this.filterDateRangePersistentKey = this.filterDateRangePersistentKey.bind( this );
     this.storePersistentDateRange = this.storePersistentDateRange.bind( this );
     this.readPersistentDateRange = this.readPersistentDateRange.bind( this );
     this.filterDateRange = this.filterDateRange.bind( this );
     this.handleFilterFrom = this.handleFilterFrom.bind( this );
     this.handleFilterTo = this.handleFilterTo.bind( this );

     this.includeHistoricPersistentKey = this.includeHistoricPersistentKey.bind( this );
     this.handleIncludeHistoric = this.handleIncludeHistoric.bind( this );
     this.includeHistoric = this.includeHistoric.bind( this );


    //log.traceInOut( "constructor()" );
  }

  async componentDidMount()  {

    log.traceIn( "componentDidMount()", this.context);

    try{ 

      const dateRange = this.filterDateRange();
      const includeHistoric = this.includeHistoric();
  
      if( this.props.onDateFilterChange != null ) {
        this.props.onDateFilterChange( dateRange, includeHistoric );
      }

      log.traceOut("componentDidMount()", );

    } catch( error ) {
      log.warn( "componentDidMount()", error );

      await errorDialog( error);

      log.traceOut( "componentDidMount()", error );
    }
  }

  private filterPeriodPersistentKey = () : string => {
    const appContext = this.context as AppContextProps;

    return appContext.currentHomePath + "." + 
      this.props.databases[0].defaultDocumentName() + "." + 
      PersistentKeyFilterPeriod;
  }


  private handleFilterPeriod = async ( event : any, period? : Period ) => {

    log.traceIn( "handleFilterPeriod()", period );

    try {
      event.preventDefault();

      const dateRange = period != null ? periodRange( period, this.filterPeriodUnits( period ) ) : {};

      Factory.get().persistentState!.setProperty( this.filterPeriodPersistentKey(), period );

      Factory.get().persistentState!.clearProperty( this.filterPeriodUnitsPersistentKey() );

      this.storePersistentDateRange( dateRange );

      this.setState( { period: period, periodUnits: undefined, dateRange: dateRange } );

      const includeHistoric = this.includeHistoric();

      if( this.props.onDateFilterChange != null ) {
        this.props.onDateFilterChange( dateRange, includeHistoric );
      }

      log.traceOut( "handleFilterPeriod()");

    } catch( error ) {
      log.warn( "handleFilterPeriod()", error );

      await errorDialog( error);

      log.traceOut( "handleFilterPeriod()", error );
    }
  }

  private filterPeriod = () : Period | null =>  {

    log.traceIn( "filterPeriod()" );

    if( this.state.period !== undefined ) {
      log.traceOut( "filterPeriod()", "From state", this.state.period );
      return this.state.period;
    }

    const persistentPeriod = Factory.get().persistentState!.property( this.filterPeriodPersistentKey() ) as Period;

    if( persistentPeriod !== undefined ) {

      log.traceOut( "filterPeriod()", "From persistent app state", persistentPeriod );
      return persistentPeriod;
    }

    log.traceOut( "filterPeriod()", "No filter period" );
    return null;
  };

  private filterPeriodUnitsPersistentKey = () : string => {

    const appContext = this.context as AppContextProps;

    return appContext.currentHomePath + "." + 
      this.props.databases[0].defaultDocumentName() + "." + 
      PersistentKeyFilterPeriodUnits;
  }


  private filterPeriodUnits = ( period? : Period ) : number =>  {

    //log.traceIn( "filterPeriodUnits()" );

    const filterPeriod = period != null ? period : this.filterPeriod();

    if( filterPeriod != null ) {

      const filterPeriodUnitOptions = periodUnitsOptions( filterPeriod );
      if( filterPeriodUnitOptions.length === 0 ) {
        //log.traceOut( "filterPeriodUnits()", "From period", 1 );
        return 1;
      }
    }

    if( this.state.periodUnits != null ) {
      //log.traceOut( "filterPeriodUnits()", "From state", this.state.periodUnits );
      return this.state.periodUnits;
    }

    const persistentPeriodUnits = Factory.get().persistentState!.property( this.filterPeriodUnitsPersistentKey() ) as number;

    if( persistentPeriodUnits != null ) {

      //log.traceOut( "filterPeriodUnits()", "From persistent app state", persistentFilterOpen );
      return persistentPeriodUnits;
    }

    //log.traceOut( "filterPeriodUnits()", "From default", DefaultFilterPeriod );
    return DefaultFilterPeriodUnits;
  };

  private filterDateRangePersistentKey = () : string => {

    const appContext = this.context as AppContextProps;

    return appContext.currentHomePath + "." + 
      this.props.databases[0].defaultDocumentName() + "." + 
      PersistentKeyFilterDateRange;
  }

  private storePersistentDateRange = async ( dateRange : DateRange )  => { 

    const persistentDateRange = {} as any;

    if( dateRange.from != null ) {
      persistentDateRange.from = Factory.get().databaseService.databaseManager.converter.fromDate( dateRange.from  );
    }

    if( dateRange.to != null ) {
      persistentDateRange.to = Factory.get().databaseService.databaseManager.converter.fromDate( dateRange.to  )
    }

    Factory.get().persistentState!.setProperty( this.filterDateRangePersistentKey(), persistentDateRange );
  }

  private readPersistentDateRange = () : DateRange  => {

    const dateRange = {} as DateRange;

    const persistentDateRange = Factory.get().persistentState!.property( this.filterDateRangePersistentKey() );

    if( persistentDateRange != null ) {

      if( persistentDateRange.from != null ) {
        dateRange.from = Factory.get().databaseService.databaseManager.converter.toDate(  persistentDateRange.from );
      }

      if( persistentDateRange.to != null ) {
        dateRange.to = Factory.get().databaseService.databaseManager.converter.toDate( persistentDateRange.to );
      }
    }
    return dateRange;
  }

  private filterDateRange = () : DateRange =>  {

    log.traceIn( "filterDateRange()" );

    const filterPeriod = this.filterPeriod();

    if( filterPeriod != null ) {
      const dateRange = periodRange( filterPeriod, this.filterPeriodUnits() );
      log.traceOut( "filterDateRange()", "From period", filterPeriod, dateRange );
      return dateRange;
    }

    if( this.state.dateRange != null ) {
      log.traceOut( "filterDateRange()", "From state", this.state.dateRange );
      return this.state.dateRange;
    }

    const persistentDateRange = this.readPersistentDateRange();

    if( persistentDateRange != null ) {

      log.traceOut( "filterDateRange()", "From persistent app state", persistentDateRange );
      return persistentDateRange;
    }

    log.traceOut( "filterDateRange()", "From default", DefaultFilterPeriod );
    return DefaultFilterDateRange;
  };

  private handleFilterFrom = async ( filterFrom? : MaterialUiPickersDate ) => {
    log.traceInOut("handleFilterFrom", filterFrom );

    try {
      const dateRange = Object.assign( {}, this.filterDateRange() );

      dateRange.from = filterFrom != null ? filterFrom : undefined;

      Factory.get().persistentState!.clearProperty( this.filterPeriodPersistentKey() );

      Factory.get().persistentState!.clearProperty( this.filterPeriodUnitsPersistentKey() );

      this.storePersistentDateRange( dateRange );

      this.setState( { period: undefined, periodUnits: undefined, dateRange: dateRange } );

      const includeHistoric = this.includeHistoric();

      if( this.props.onDateFilterChange != null ) {
        this.props.onDateFilterChange( dateRange, includeHistoric );
      }
    } catch( error ) {
      log.warn( "handleFilterFrom()", error );

      await errorDialog( error);
    }
  }

  private handleFilterTo = async ( filterTo? : MaterialUiPickersDate ) => {
    log.traceInOut("handleFilterDateRangeTo", filterTo );

    try {
      const dateRange = Object.assign( {}, this.filterDateRange() );

      dateRange.to = filterTo != null ? filterTo : undefined;

      Factory.get().persistentState!.clearProperty( this.filterPeriodPersistentKey() );

      Factory.get().persistentState!.clearProperty( this.filterPeriodUnitsPersistentKey() );

      this.storePersistentDateRange( dateRange );

      this.setState( { period: undefined, periodUnits: undefined, dateRange: dateRange } );

      const includeHistoric = this.includeHistoric();

      if( this.props.onDateFilterChange != null ) {
        this.props.onDateFilterChange( dateRange, includeHistoric );
      }
    } catch( error ) {
      log.warn( "handleFilterTo()", error );

      await errorDialog( error);
    }
  }

  private handleFilterPeriodUnits = async ( event : any, value : number | null ) => {

    log.traceIn( "handleFilterPeriodUnits()", value );

    try {
      event.preventDefault();

      const periodUnits = value != null ? value : undefined;

      const filterPeriod = this.filterPeriod();

      const dateRange = periodRange( filterPeriod!, periodUnits );

      Factory.get().persistentState!.setProperty( this.filterPeriodUnitsPersistentKey(), periodUnits );

      this.storePersistentDateRange(  dateRange );

      this.setState( { periodUnits: periodUnits, dateRange: dateRange } );

      const includeHistoric = this.includeHistoric();

      if( this.props.onDateFilterChange != null ) {
        this.props.onDateFilterChange( dateRange, includeHistoric );
      }

      log.traceOut( "handleFilterPeriodUnits()");

    } catch( error ) {
      log.warn( "handleFilterPeriodUnits()", error );

      await errorDialog( error);

      log.traceOut( "handleFilterPeriodUnits()", error );
    }
  }

  private includeHistoricPersistentKey = () : string => {
    
    const appContext = this.context as AppContextProps;

    return appContext.currentHomePath + "." + 
      this.props.databases[0].defaultDocumentName() + "." + 
      PersistentKeyIncludeHistoric;
  }

  private handleIncludeHistoric = async () =>  {

    log.traceIn( "handleIncludeHistoric()" );

    try {
      const includeHistoric = !this.includeHistoric(); 

      Factory.get().persistentState!.setProperty( this.includeHistoricPersistentKey(), includeHistoric ); 

      this.setState( { includeHistoric: includeHistoric } );

      const dateRange = this.filterDateRange();

      if( this.props.onDateFilterChange != null ) {
        this.props.onDateFilterChange( dateRange, includeHistoric );
      }

      log.traceOut( "handleIncludeHistoric()");

    } catch( error ) {
      log.warn( "handleIncludeHistoric()", error );

      await errorDialog( error);

      log.traceOut( "handleIncludeHistoric()", error );
    }
  }


  private includeHistoric = () : boolean =>  {

    log.traceIn( "includeHistoric()" );

    if( this.state.includeHistoric != null ) {
      log.traceOut( "includeHistoric()", "From state", this.state.includeHistoric );
      return this.state.includeHistoric;
    }

    const persistentIncludeHistoric = Factory.get().persistentState!.property( this.includeHistoricPersistentKey() ) as boolean;

    if( persistentIncludeHistoric != null ) {

      log.traceOut( "includeHistoric()", "From persistent app state", persistentIncludeHistoric );
      return persistentIncludeHistoric;
    }

    log.traceOut( "includeHistoric()", "default", DefaultIncludeHistoric );
    return DefaultIncludeHistoric;
  };


  render() {
    //log.traceInOut("render()");

    const { classes } = this.props;

    const filterPeriod = this.filterPeriod();

    const filterDateRange = this.filterDateRange(); 

    const filterPeriodUnits = this.filterPeriodUnits();

    const filterPeriodUnitsLabel = filterPeriod == null ? "" :
        translatedDefinition(TimeSpanDefinition, periodDateUnit(filterPeriod))

    const showPeriodUnits = filterPeriod != null && periodUnitsOptions(filterPeriod!).length > 1;

    const filterPeriodUnitsOptions = filterPeriod == null ? [] : periodUnitsOptions(filterPeriod);

    const filterPeriodLabel = translatedDefinition(PeriodDefinition);

    const filterPeriodOptions = Object.values(Periods);

    return (
      <React.Fragment>
        <AppContext.Consumer>
          {appContext => (
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale()}>
              <Grid className={classes.root} container item direction="row" spacing={1}>
                <Grid item className={classes.item} xs sm md>
                  <Grid container alignItems="center">
                    <FormControl
                      fullWidth
                      variant={propertyInputVariant}>
                      <InputLabel className={classes.inputLabel}
                        shrink={true}>
                        {filterPeriodLabel}
                      </InputLabel>
                      <Select
                        className={classes.select}
                        input={(propertyInputVariant as string) !== "outlined" ? undefined :
                          <OutlinedInput 
                            notched
                            label={<>{filterPeriodLabel}&nbsp;&nbsp;</>}
                          />
                        }
                        IconComponent={filterPeriod != null ? Box : undefined}
                        displayEmpty={true}
                        renderValue={(value) => value == null ? "" :
                          translatedDefinition(PeriodDefinition,value)
                        }
                        value={filterPeriod == null ? "" : filterPeriod}
                        label={filterPeriodLabel}
                        onChange={(event) => this.handleFilterPeriod(event, event.target.value as Period)}
                        open={this.state.periodOpen} 
                        onOpen={event => {
                            this.setState({ periodOpen: true }) 
                        }}
                        onClose={() => {
                          this.setState({ periodOpen: false })
                        }}
                      >
                        {filterPeriodOptions.map((option) => (
                          <MenuItem
                            style={option.startsWith("next") ? { display: 'none' } : {}}
                            selected={filterPeriod === option}
                            key={option}
                            value={option}>
                            {translatedDefinition(PeriodDefinition, option)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {!this.state.periodOpen && filterPeriod != null &&
                      <IconButton className={classes.clearButton} size="small" onClick={(event) => this.handleFilterPeriod(event, undefined)}>
                        <CloseIcon />
                      </IconButton>
                    }
                  </Grid>
                </Grid>
                {showPeriodUnits &&
                  <Grid item className={classes.item} xs sm md>
                    <Collapse
                      in={showPeriodUnits}
                      timeout="auto" unmountOnExit
                      className={classes.collapse}>
                      <FormControl
                        fullWidth
                        variant={propertyInputVariant}>
                        <InputLabel className={classes.inputLabel}
                          shrink={true}>
                          {filterPeriodUnitsLabel}
                        </InputLabel>
                        <Select
                          className={classes.select}
                          input={
                            <OutlinedInput
                              notched
                              label={<>{filterPeriodUnitsLabel}&nbsp;&nbsp;</>}
                            />
                          }
                          displayEmpty={true}
                          renderValue={(value) => value == null ? "" : value}
                          value={filterPeriodUnits}
                          label={filterPeriodUnitsLabel}
                          onChange={(event) => this.handleFilterPeriodUnits(event, +event.target.value)} 
                          open={this.state.periodUnitsOpen}
                          onOpen={() => { this.setState({ periodUnitsOpen: true }); }}
                          onClose={() => { this.setState({ periodUnitsOpen: false }); }}
                        >
                          {filterPeriodUnitsOptions.map((option) => ( 
                            <MenuItem
                              selected={filterPeriodUnits === option}
                              key={option}
                              value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Collapse>
                  </Grid>
                }
                {(filterPeriod == null || appContext.currentDisplay?.displayType !== DisplayTypes.Mobile) &&
                  <Grid item className={classes.item} xs sm md>
                    <Grid container alignItems="center">
                      <DatePicker
                        fullWidth
                        disableToolbar
                        inputVariant={propertyInputVariant} 
                        variant="dialog"
                        autoOk
                        format={dateFormat()}
                        InputLabelProps={{ shrink: true }}
                        label={this.props.t("from")}
                        value={filterDateRange.from != null ? filterDateRange.from : null}
                        onChange={date => this.handleFilterFrom(date)} />
                      {filterDateRange.from != null &&
                        <IconButton className={classes.clearButton} size="small" onClick={(event) => this.handleFilterFrom(undefined)}>
                          <CloseIcon />
                        </IconButton>
                      }
                      {filterDateRange.from == null &&
                        <TodayIcon className={classes.dateIcon} />
                      }
                    </Grid>
                  </Grid>
                }
                {(filterPeriod == null || appContext.currentDisplay?.displayType !== DisplayTypes.Mobile) &&
                  <Grid item className={classes.item} xs sm md>
                    <Grid container alignItems="center">
                      <DatePicker
                        fullWidth
                        disableToolbar
                        inputVariant={propertyInputVariant} 
                        variant="dialog"
                        autoOk
                        format={dateFormat()}
                        InputLabelProps={{ shrink: true }}
                        label={this.props.t("to")}
                        value={filterDateRange.to != null ? filterDateRange.to : null}
                        onChange={date => this.handleFilterTo(date)} />
                      {filterDateRange.to != null &&
                        <IconButton className={classes.clearButton} size="small" onClick={(event) => this.handleFilterTo(undefined)}>
                          <CloseIcon />
                        </IconButton>
                      }
                      {filterDateRange.to == null &&
                        <EventIcon className={classes.dateIcon} />
                      }
                    </Grid>
                  </Grid>
                }
                <Grid item className={classes.item} xs sm md style={{ paddingTop: theme.spacing(1)}}>
                  <Tooltip title={(<>{this.props.t("includeHistoric")}</>)}>
                    <Checkbox
                      icon={<HistoryToggleOffIcon />}
                      checkedIcon={<AccessTimeTwoToneIcon />}
                      checked={this.includeHistoric()}
                      onClick={() => this.handleIncludeHistoric()}
                    />
                  </Tooltip> 
                </Grid>
              </Grid>
            </MuiPickersUtilsProvider>
          )}
        </AppContext.Consumer>
      </React.Fragment>
    );
  }
}

DateFilter.contextType = AppContext;

const ModifiedDateFilter = withTranslation()(withStyles(styles)(DateFilter));

export default ModifiedDateFilter;











