import * as React from 'react'

import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import { withTranslation, WithTranslation } from 'react-i18next';

import { CountryPropertyIF } from 'services/database/api/properties/countryPropertyIF';
import { PropertyDisplayMode, propertyInputVariant } from '../propertyValue';
import { log } from '../../app/app';
import { Autocomplete, Box, TextField } from '@mui/material';
import { allCountries } from '../../app/localization';
import { translatedPropertyLabel } from '../propertyLabel';
import FlagIcon from '../flagIcon';

const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
  },
  label: {
    marginTop: theme.spacing(-0.5),
    zIndex: 999
  },
  flag: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  }
});

export interface CountryPropertyValueProps extends WithStyles<typeof styles>, WithTranslation { 

  property : CountryPropertyIF, 

  onPropertyChange? : ( property : CountryPropertyIF ) => void,

  displayMode : PropertyDisplayMode,

  singleProperty: boolean,

  hideColor?: boolean,

  disabled?: boolean,

  required? : boolean

 }

interface CountryPropertyValueState { 

  open : boolean,

  propertyValue? : string

  error? : Error
}


class CountryPropertyValue extends React.Component<CountryPropertyValueProps,CountryPropertyValueState> {

  constructor( props: CountryPropertyValueProps ) {
    
    super(props);

    this.state = { 
      open : false

     } as CountryPropertyValueState;

    //log.traceInOut( "constructor()" );
  }

  componentDidMount() {

    this.setState( {
      propertyValue: this.props.property.value()
    });

  }

  componentDidUpdate() {

    if( this.props.property.error !== this.state.error ) {
      this.setState( { error : this.props.property.error });
    }
  }

  render() {
    //log.traceInOut("render()", this.state.propertyValue );

    const {classes } = this.props;

    if( !this.props.property.userAccess().allowRead ) {
      return ( null );
    }

    if( this.props.displayMode === PropertyDisplayMode.Cell ) {
      return ( 
        <>{ 
          this.props.property.value() == null ? <>&nbsp;</> : this.props.property.value()}
        </>  
      );  
    }

    const handleChange = ( countryId? : string ) => {

      log.traceInOut("handleChange()", {countryId});

      if( countryId != null && countryId.length > 0 ) {
  
        this.props.property.setValue( countryId );
      }
      else {
        this.props.property.setValue( undefined );
      }

      this.setState( {
        propertyValue: this.props.property.value()
      });
  
      if( this.props.onPropertyChange != null ) {
        this.props.onPropertyChange( this.props.property );
      }
    }

    const value = this.state.propertyValue == null ? "" : this.state.propertyValue;

    const countries = allCountries(); 
    
    const options = Array.from( countries.keys() ); 

    if( !options.includes( value ) ) {
      options.push( value );
    }
    
    const label = translatedPropertyLabel(this.props.property.parent.documentName(), this.props.property.key());

    const renderOption = (option: string) => {

      return (
        <>
          {option == null || option.length === 0 ? null :
            <>
              {!this.props.hideColor && <FlagIcon countryId={option} />} 
              {countries.get(option)} ({option})
            </>
          }
        </>
      )
    }

    const optionLabel = (option: string) => {

      return ( 
        option == null || option.length === 0 ? "" : 
        countries.get( option )! + " (" + option +")" 
      );
    }

    return (
      <Autocomplete
        options={options}
        autoHighlight
        getOptionLabel={(option) => optionLabel(option)}
        onChange={ (event, value ) => handleChange( value != null ? value : undefined )}
        renderOption={(props, option) => (
          <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
            {renderOption(option)}
          </Box>
        )}
        value={value}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            disabled={this.props.disabled} 
            required={this.props.required}
            variant={propertyInputVariant}
            fullWidth
            InputLabelProps={{ shrink: true, className: classes.label }} 
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <>
                  {value != null && value.length > 0 &&
                    <Box className={classes.flag}>
                      <FlagIcon countryId={value} />
                    </Box>
                  }
                  {params.InputProps.startAdornment}
                </>
              ),
              autoComplete: 'new-password', // disable autocomplete and autofill
            }}
          />
        )}
      />

    );
  }
}
const ModifiedCountryPropertyValue = withTranslation()(withStyles(styles)(CountryPropertyValue));

export default ModifiedCountryPropertyValue;

