import { Registration } from "./registration";
import { SymptomRegistrationIF } from "../../api/documents/symptomRegistrationIF";
import { log } from "../../../services/database/framework/databaseService";
import { CollectionDatabase } from "../../../services/database/impl/core/collectionDatabase";
import { DefinitionsProperty } from "../../../services/database/impl/properties/definitionsProperty";
import { HealthCondition, HealthConditions } from "../../api/definitions/healthCondition";
import { Symptom, Symptoms } from "../../api/definitions/symptom";
import { SymptomDefinition, HealthConditionDefinition } from "../../api/healthguardDefinitions";
import { SymptomRegistrationDocument } from "../../api/registrationDocuments";


export class SymptomRegistration extends Registration implements SymptomRegistrationIF {

    constructor( symptomRegistrationsCollection : CollectionDatabase<SymptomRegistration>, documentPath? : string  ) {

        super( SymptomRegistrationDocument, symptomRegistrationsCollection, documentPath );

        try {            
            this.symptoms = new DefinitionsProperty<Symptom>( this, SymptomDefinition, Symptoms );
            this.symptoms.required = true;

            this.preexistingConditions = new DefinitionsProperty<HealthCondition>( this, HealthConditionDefinition, HealthConditions );

            //log.traceInOut( "constructor()", SymptomRegistrationsCollection );

        } catch( error ) {

            log.warn( "constructor()", "Error initializing symptoms", error );
            
            throw new Error( (error as any).message );
        }
    }

    readonly symptoms: DefinitionsProperty<Symptom>;

    readonly preexistingConditions : DefinitionsProperty<HealthCondition>;
}
 