
import firebase from 'firebase/compat/app';
import "firebase/compat/auth";

import React from 'react';

import MuiPhoneNumber from "material-ui-phone-number";


import { withTranslation, WithTranslation } from 'react-i18next';
import { Button, CircularProgress, createStyles, Grid, Theme, WithStyles, withStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close'; 
import SmsIcon from '@material-ui/icons/Sms'; 

import Title from 'ui/components/title';

import { log } from "ui/app/app";

import { country } from '../localization';
import { AppContext, AppContextProps } from '../appContext';

import { Factory } from '../../../services/common/api/factory';
import { textInputDialog } from '../../components/inputDialog';
import { FirebaseServiceIF } from '../../../services/application/api/firebase/firebaseServiceIF';
import { EmptyVerifyPhoneMarker } from '../../../services/authentication/api/authenticationClaims';

import { PhoneNumber } from '../../../services/database/api/core/phoneNumber';
import { TerminalPlatforms } from "../../../services/database/api/definitions/terminalPlatform";
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import { TranslationKey } from '../../../services/common/api/translatorIF';


import logoLightBackground from 'ui/app/logo-light.png';  
import { errorDialog } from '../../components/simpleDialog';
import theme from '../theme';
import { propertyInputVariant } from '../../components/propertyValue';


const styles = (theme: Theme) => createStyles({
  root: {
  },
  prompt: {
    marginTop: theme.spacing(8),
    textAlign: 'center',
  },
  phoneNumber: {
    width: theme.spacing(36)
  },
  button: {
    width: theme.spacing(36)
  },
  logo: {
    marginTop: theme.spacing(8),
    height: theme.spacing(16)
  }

});

interface VerifyPhoneProps extends WithStyles<typeof styles>, WithTranslation {

}


interface VerifyPhoneState {

  phoneNumber? : string,

  waitingForPhoneVerification: boolean

}

class VerifyPhone extends React.PureComponent<VerifyPhoneProps, VerifyPhoneState> {

  constructor(props: VerifyPhoneProps) {

    super(props);

    this.state = {

      waitingForPhoneVerification: false

    } as VerifyPhoneState;

    this.updatePhoneNumber = this.updatePhoneNumber.bind( this )
    this.onSendVerificationPhone = this.onSendVerificationPhone.bind( this )
    this.onSignout = this.onSignout.bind( this )

    //log.traceInOut( "constructor()" );
  }

  async componentDidMount() {

    log.traceIn("componentDidMount()");

    try {

      const appContext = this.context as AppContextProps;

      if( appContext.currentDisplay?.displayPlatform === TerminalPlatforms.Web ) { 

        const appVerifier = new firebase.auth.RecaptchaVerifier(this._recaptcha, { 'size': 'invisible' });

        if (appVerifier == null) {
          throw new Error("Empty app verifier");
        }

        (window as any).recaptchaVerifier = appVerifier;

        (window as any).recaptchaWidgetId = await (window as any).recaptchaVerifier.render();

      }
      await this.updatePhoneNumber();

      log.traceOut("componentDidMount()");

    } catch (error) {
      log.warn("componentDidMount()", "Error mounting", error);

      await errorDialog(error);
    }
  }

  async updatePhoneNumber() {

    log.traceIn("updatePhoneNumber()");

    try {

      const appContext = this.context as AppContextProps;

      if( appContext.authenticationClaims == null || appContext.authenticationClaims.authenticationId == null ) {
        throw new Error( "No authenticatied user");
      }

      const firebaseService = Factory.get().applicationService as FirebaseServiceIF;

      const firebaseUser = firebaseService.authenticator().currentUser;

      if( firebaseUser == null ) {
        throw new Error( "No firebase user");
      }

      if( firebaseUser.uid !== appContext.authenticationClaims.authenticationId ) {
        throw new Error( "Firebase user UID mismatchj");
      }

      let phoneNumber = appContext.authenticationClaims.verifyPhone!;  

      if( phoneNumber === EmptyVerifyPhoneMarker ) {

        phoneNumber = "";
      }

      if( phoneNumber !== this.state.phoneNumber ) {

        this.setState( {
          
          phoneNumber: phoneNumber,

          waitingForPhoneVerification: false
        });
      }
      
    } catch( error  ) {
      log.warn( "updatePhoneNumber()", "Error sending verification to phone", error );

      await errorDialog( error);
    }
    log.traceOut("updatePhoneNumber()");
  }

  async onSendVerificationPhone() {

    log.traceIn("onSendVerificationPhone" );

    try {

      const appContext = this.context as AppContextProps;

      log.debug("onSendVerificationPhone", appContext.authenticationClaims );

      if( !PhoneNumber.isValidPhoneNumber( this.state.phoneNumber, true ) ) {
        throw new Error( "invalidPhoneNumber" );
      }

      let verificationId;

      if( appContext.currentDisplay?.displayPlatform === TerminalPlatforms.Web ) { 

        const authProvider = new firebase.auth.PhoneAuthProvider();

        verificationId = await authProvider.verifyPhoneNumber(
          this.state.phoneNumber!, (window as any).recaptchaVerifier );
      }
      else {

        const result = await FirebaseAuthentication.signInWithPhoneNumber(
          {
            phoneNumber: this.state.phoneNumber!
          }
        );

        verificationId = result?.verificationId;
      }

      if( verificationId == null ) {
        throw new Error( "VerificationId failed");
      }

      const verificationCode = await textInputDialog( 
        this.props.t, 
        this.props.i18n, 
        undefined, 
        TranslationKey.Prompts + ":enterPhoneVerificationCode" );

      if( verificationCode == null ) {

        throw new Error( this.props.t( TranslationKey.Prompts + ":incorrectPhoneVerificationCode" ));
      }

      const credentials = firebase.auth.PhoneAuthProvider.credential(verificationId, verificationCode);

      if( credentials == null ) {
        throw new Error( "No phone credentials returned");
      }

      this.setState( {
        waitingForPhoneVerification: true,
      });

      await Factory.get().authenticationService!.verifyPhone( credentials ); 

      this.setState( {
        waitingForPhoneVerification: false,
      });

      log.traceOut( "onSendVerificationPhone()" );

    } catch( error ) {
      log.warn( "onSendVerificationPhone()", "Error sending verification to phone", error );

      this.setState( {
        waitingForPhoneVerification: false
      });

      switch( (error as any).code ) 
      {
        case "auth/account-exists-with-different-credential":
          await errorDialog( "duplicateUser" );
          break;

        case "auth/invalid-verification-code":
          await errorDialog( "invalidVerificationCode" );
          break;

        case "auth/too-many-requests": 
          await errorDialog( "tooManyRequests" ); 
          break;

        default:
          await errorDialog( error ); 
          break;

      }
      
  

      log.traceOut( "onSendVerificationPhone()", error );

    }
  }

  async onSignout() {

    log.traceIn("onSignout" );

    try {

      const appContext = this.context as AppContextProps;

      if( appContext.currentDisplay?.displayPlatform !== TerminalPlatforms.Web ) { 

        await FirebaseAuthentication.signOut();
      }

      await Factory.get().authenticationService!.signOut()

      this.setState( {
        waitingForPhoneVerification: false
      });

      log.traceOut( "onSignout()" );

    } catch( error ) {
      log.warn( "onSignout()", "Error sending verification to phone", error );

      await errorDialog( error );

      this.setState( {
        waitingForPhoneVerification: false
      });

      log.traceOut( "onSignout()", error );
    }
  }

  render() {

    const { classes } = this.props;

    const handlePhoneNumberChange = ( value : string ) => {

      log.traceInOut("handlePhoneNumberChange()", {value});

      this.setState( {
        phoneNumber: value
      });
    }

    const defaultCountry = country();

    const validPhoneNumber = PhoneNumber.isValidPhoneNumber( this.state.phoneNumber, true );

    log.traceInOut("render()", this.state.phoneNumber, {defaultCountry}, {validPhoneNumber});

    return (
      <>
        <Grid container direction="column" spacing={1}  alignItems="center" className={classes.root}>
          <Grid item className={classes.logo} >
            <img src={logoLightBackground} alt="logo" style={{ width: theme.spacing(48)}} />
          </Grid>
          <Grid item className={classes.prompt}>
            <Title level="h6">{this.props.t(TranslationKey.Prompts + ":pleaseVerifyPhone")}:</Title>
          </Grid>
          <Grid item />
          <Grid item>
            <MuiPhoneNumber 
              defaultCountry={defaultCountry}
              className={classes.phoneNumber} 
              fullWidth
              variant={propertyInputVariant}
              InputLabelProps={{ shrink: true }}
              value={this.state.phoneNumber != null ? this.state.phoneNumber : null}
              onChange={handlePhoneNumberChange}
            />
          </Grid>
          <Grid item />
          <Grid item>
            <Button 
              className={classes.button} 
              variant="contained" 
              color="primary" 
              onClick={this.onSendVerificationPhone} 
              disabled={!validPhoneNumber || this.state.waitingForPhoneVerification}
              startIcon={<SmsIcon />} >
              {this.props.t(TranslationKey.Prompts + ":sendPhoneVerificationLink")}
            </Button>
          </Grid>
          <Grid item>
            <Button 
              className={classes.button} 
              variant="contained" 
              fullWidth 
              onClick={this.onSignout} 
              startIcon={<CloseIcon />}>
              {this.props.t("cancel")}
            </Button> 
          </Grid>
          <Grid item container alignItems="center" justifyContent="center">
            <div ref={(ref)=>this._recaptcha=ref}></div>
          </Grid>
          {this.state.waitingForPhoneVerification && 
            <Grid item container alignItems="center" justifyContent="center">
              <CircularProgress color="secondary" />
            </Grid>
          }
          <Grid item container alignItems="center" justifyContent="center">
          </Grid>
        </Grid>
      </>
    );
  }

  private _recaptcha? : any;
}

VerifyPhone.contextType = AppContext;

const ModifiedVerifyPhone = withTranslation()(withStyles(styles)(VerifyPhone));

export default ModifiedVerifyPhone;


