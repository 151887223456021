import React from 'react';

import DateFnsUtils from '@date-io/date-fns'; 

import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import { log } from "ui/app/app";

import PropertyLabel from '../propertyLabel';

import { DatePropertyIF } from 'services/database/api/properties/datePropertyIF';
import { PropertyDisplayMode, propertyInputVariant } from '../propertyValue';
import { dateFormat, locale, dateTimeFormat, timeFormat } from 'ui/app/localization';
import { format } from 'date-fns';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'; 

const millisecondsPerDay = 24*60*60*1000;

export default function DatePropertyValue( props : { 
  property : DatePropertyIF, 
  onPropertyChange? : ( property : DatePropertyIF ) => void,
  displayMode : PropertyDisplayMode,
  singleProperty : boolean,
  hideColor?: boolean,
  disabled?: boolean,
  required? : boolean  } ) {

  const [propertyValue, setPropertyValue] = React.useState<Date | undefined>( undefined );
 
  React.useEffect( () => {

    setPropertyValue( props.property.value() );

  }, [props.property,props.displayMode]); 

  const lastDay = propertyValue != null && 
    (propertyValue.getDay() === new Date().getDay() ) &&
    (new Date().getTime() - propertyValue.getTime() < millisecondsPerDay);

  const dateText = 
    propertyValue == null ? "" :
      lastDay ?
      format( propertyValue, timeFormat() ) :
      format( propertyValue, dateTimeFormat() );
  
  if( props.displayMode === PropertyDisplayMode.Cell ) {      
    return( <React.Fragment>{dateText}</React.Fragment> );
  }

  const handleChange = ( date: MaterialUiPickersDate | null ) => {

    log.traceIn( "handleChange()", date );

    if( date == null || isNaN( date.getTime() ) ) {
      props.property.setDate( undefined );
    }
    else {
      props.property.setDate( date );
    }

    setPropertyValue( props.property.value() );

    if( props.onPropertyChange != null ) {
      props.onPropertyChange( props.property );
    }
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale()}>
      <KeyboardDatePicker
        disableToolbar
        autoOk={true}
        inputVariant={propertyInputVariant}
        variant="dialog"
        fullWidth
        format={!props.disabled ? dateFormat() : 
          lastDay ? timeFormat() :
          dateTimeFormat()  
        }
        error={props.property.error != null}
        disabled={props.disabled}
        required={props.required}
        label={<PropertyLabel documentName={props.property.parent.documentName()} propertyKey={props.property.key()} />}
        InputLabelProps={{ shrink: true }}
        KeyboardButtonProps={props.disabled ? { disabled: true, style: { display: 'none' }} : {} }
        value={propertyValue != null ? propertyValue : null}
        onChange={handleChange} 
        onAccept={handleChange} 
        />
    </MuiPickersUtilsProvider>
  ); 
}


  
  