import { PropertyType, PropertyTypes } from "../../api/definitions/propertyType"; 
import { NumberPropertyIF } from "../../api/properties/numberPropertyIF";
import { DatabaseObject } from "../../framework/databaseObject";
import { BasicProperty } from "./basicProperty";

export class NumberProperty extends BasicProperty<number> implements NumberPropertyIF {

    constructor( parent : DatabaseObject, defaultValue? : number, minValue? : number, maxValue? : number ) {
        super( PropertyTypes.Number as PropertyType, parent, defaultValue ); 

        this.minValue = minValue;

        this.maxValue = maxValue;
    }

    setValue( value : number | undefined ): void {

        this.decryptData();

        if( this.compareValue( value ) === 0 ) {
            return;
        }

        if( this.minValue != null && value != null && value < this.minValue ) {
            return;
        }

        if( this.maxValue != null && value != null && value > this.maxValue ) {
            return;
        }

        this.setLastChange( this._value );

        this._value = value;

        delete this.error;
    }


    compareTo( other : NumberProperty ) : number {

        return this.compareValue( other.value() );
    }

    compareValue( otherValue : number | undefined ) : number {

        const value = this.value();

        if( value == null && otherValue == null ) {
            return 0;
        }

        if( value != null && otherValue == null ) {
            return 1;
        }

        if( value == null && otherValue != null ) {
            return -1;
        }

        if( value! > otherValue! ) {
            return 1;
        }

        if( value! < otherValue! ) {
            return -1;
        }

        return 0;
    }

    readonly minValue? : number;

    readonly maxValue? : number;

}