import * as React from 'react'

import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';

import { withTranslation, WithTranslation } from 'react-i18next';

import { log } from 'ui/app/app';

import PropertyValue, { PropertyDisplayMode } from './propertyValue';
import { DatabaseObjectIF } from 'services/database/api/core/databaseObjectIF';
import { Box } from '@material-ui/core';
import { DatabasePropertyIF } from 'services/database/api/core/databasePropertyIF';
import { PropertiesSelector } from 'services/database/api/core/propertiesSelector';
import { errorDialog } from './simpleDialog';
import { TextPropertyIF } from '../../services/database';
import Grid from '@mui/material/Grid';
import { PropertyTypes } from '../../services/database/api/definitions/propertyType';


const styles = (theme: Theme) => createStyles({
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  input: {
    padding: theme.spacing(1),
  },
  item : {
    marginBottom: theme.spacing(2.5)
  }
});

export interface DocumentFormProps extends WithStyles<typeof styles>, WithTranslation { 

  databaseObject : DatabaseObjectIF,

  propertiesSelector? : PropertiesSelector,

  onDatabaseObjectEditChange? : ( databaseProperty : DatabasePropertyIF<any> ) => Promise<void>
}

interface DocumentFormState { // Component State
}


class DocumentForm extends React.Component<DocumentFormProps,DocumentFormState> {

  constructor( props: DocumentFormProps ) {
    
    super(props);

    this.state = { 
    } as DocumentFormState;

    this.onPropertyChange = this.onPropertyChange.bind(this);

    //log.traceInOut( "constructor()" );
  }

  protected onPropertyChange = async ( property : DatabasePropertyIF<any> ) => {

    //log.traceIn( "onPropertyChange()", property );

    try {
      if( this.props.onDatabaseObjectEditChange != null ) {

        await this.props.onDatabaseObjectEditChange( property );
      }
      //log.traceOut( "onPropertyChange()" );

    } catch( error ) {
          
      log.warn( "onPropertyChange()", error );

      await errorDialog( error);

      log.traceOut( "onPropertyChange()", error );
    }
  }



  render(): JSX.Element {

    //log.traceInOut("render()", this.props.propertiesSelector );

    const { classes } = this.props;

    const properties = this.props.databaseObject.properties( this.props.propertiesSelector )

    const singleProperty = properties.size === 1;

    const multilineTextProperty = ( property : DatabasePropertyIF<any> ) => {
      return property.type === PropertyTypes.Text && !!(property as TextPropertyIF).multiline
    }

    const fullWidthProperty = ( property : DatabasePropertyIF<any> ) => {

      switch( property.type ) 
      {
        case PropertyTypes.Subdocument:
        case PropertyTypes.Collection:
        case PropertyTypes.SymbolicCollection:
        case PropertyTypes.Geolocation:
        case PropertyTypes.Attachment:
        case PropertyTypes.Attachments: 
        case PropertyTypes.Links:
        case PropertyTypes.Image:

          return true; 

        case PropertyTypes.SymbolicOwners:
        case PropertyTypes.Definitions:
        case PropertyTypes.References:
        case PropertyTypes.Texts:

          return (property as any).value() != null && (property as any).value().size > 0;

        default:
          return false;

      }
    }

    const narrowWidthProperty = ( property : DatabasePropertyIF<any> ) => {

      switch( property.type ) 
      {
        case PropertyTypes.Date:
        case PropertyTypes.Boolean:
        case PropertyTypes.Number:

          return true;

        default:
          return false;

      }
    }

    const xs = ( property : DatabasePropertyIF<any> ) : number => {

      const fullWidth = 12;

      if (singleProperty) {
        return fullWidth;
      }

      if (fullWidthProperty(property)) {
        return fullWidth;
      }

      if (narrowWidthProperty(property)) {
        return 6;
      }

      if (multilineTextProperty(property)) {
        return fullWidth;
      }

      return fullWidth;
    }

    const sm = ( property : DatabasePropertyIF<any> ) : number => {

      const fullWidth = 12;

      if (singleProperty) {
        return fullWidth;
      }

      if (fullWidthProperty(property)) {
        return fullWidth;
      }

      if (narrowWidthProperty(property)) {
        return 6;
      }

      if (multilineTextProperty(property)) {
        return fullWidth;
      }

      return 6;
    }

    const md = ( property : DatabasePropertyIF<any> ) : number => {

      const fullWidth = 12;

      if (singleProperty) {
        return fullWidth;
      }

      if (fullWidthProperty(property)) {
        return fullWidth;
      }

      if (narrowWidthProperty(property)) {
        return 6;
      }

      if (multilineTextProperty(property)) {
        return fullWidth;
      }

      return 6;
    }

    const lg = ( property : DatabasePropertyIF<any> ) : number => {

      const fullWidth = 12;

      if (singleProperty) {
        return fullWidth; 
      }

      if (fullWidthProperty(property)) {
        return fullWidth;
      }

      if (narrowWidthProperty(property)) {
        return 6; 
      }

      if (multilineTextProperty(property)) {
        return fullWidth; 
      }

      return 6;
    }

    const forceSingleLine = ( property : DatabasePropertyIF<any>, index : number ) => {

      if (singleProperty) {
        return true;
      }

      if (fullWidthProperty(property)) {
        return true;
      }

      if (multilineTextProperty(property)) {
        return true; 
      }

      return false;
    }



    return (
      <React.Fragment>
        <Box className={classes.root}>
          <Grid container alignItems="stretch" spacing={0} >     
          {Array.from( properties.values() ).map( (property, index) => 
            property.hidden ? null :
              <>
                {forceSingleLine( property, index ) ? 
                  <Grid item xs={12} sm={12} md={12} lg={12} height={0} key={property.key() + "pre-divider"}/> :
                  <Grid item width={0}/>
                }
                <Grid item className={classes.item} key={property.key()} padding={2}
                  xs={xs( property )} 
                  sm={sm( property )} 
                  md={md( property )} 
                  lg={lg( property )} 
                  >
                  <PropertyValue  
                    property={property} 
                    onPropertyChange={this.onPropertyChange} 
                    displayMode={PropertyDisplayMode.Form}
                    singleProperty={singleProperty}/>
                </Grid>
                {forceSingleLine( property, index ) ? 
                  <Grid item xs={12} sm={12} md={12} lg={12} height={0} key={property.key() + "post-divider"}/> :
                  <Grid item width={0}/>
                }
              </>
          )}
          </Grid>
        </Box>
      </React.Fragment>
    );
  }

}


const ModifiedDatabaseForm = withTranslation()(withStyles(styles)(DocumentForm));

export default ModifiedDatabaseForm;









