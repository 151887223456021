
import { CollectionDatabase } from "../core/collectionDatabase";
import { DatabaseDocument } from "../../framework/databaseDocument";
import { log } from "../../framework/databaseService";
import { CompaniesCollection, LocationsCollection, UnitsCollection, UsersCollection } from "../../api/collections";
import { TenantProperty } from "../properties/tenantProperty";
import { Company } from "./company";
import { CompanyDocument, ProjectDocument, UnitDocument } from "../../api/documents";
import { ReferencesProperty } from "../properties/referencesProperty";
import { User } from "./user";
import { Location } from "./location";
import { ProjectIF } from "../../api/documents/projectIF";
import { OwnerProperty } from "../properties/ownerProperty";
import { ReferenceProperty } from "../properties/referenceProperty";
import { Unit } from "./unit";
import { Database } from "../core/database";

export abstract class Project extends DatabaseDocument implements ProjectIF {

    constructor( projectDocumentName : string, projectsCollection : CollectionDatabase<Project>, documentPath? : string) {

        super( projectDocumentName, projectsCollection, documentPath );

        try {
            this.company = new TenantProperty<Company>( this, CompaniesCollection, CompanyDocument );
            this.company.editable = false;

            this.unit = new OwnerProperty<Unit>( this, UnitsCollection, UnitDocument );

            this.projectManager = new ReferenceProperty<User>( this, 
                () => this.parentDatabases( UsersCollection, {nearestIsCollectionGroup: true} ) as Database<User>[],
                "projectManaging" );

            this.users = new ReferencesProperty<User>( this, 
                () => this.parentDatabases( UsersCollection, {nearestIsCollectionGroup: true} ) as Database<User>[],
                "projects" );

            this.locations = new ReferencesProperty<Location>( this, 
                () => this.parentDatabases( LocationsCollection, {nearestIsCollectionGroup: true} ) as Database<Location>[],
                "projects" );

            //log.traceInOut( "constructor()", LocationsCollection );

        } catch( error ) {

            log.warn( "constructor()", "Error initializing location", error );
            
            throw new Error( (error as any).message );
        }
    }

    indexKey() : string {
        return ProjectDocument;
    }


    readonly company: TenantProperty<Company>;

    readonly unit: OwnerProperty<Unit>;

    readonly projectManager : ReferenceProperty<User>;

    readonly users : ReferencesProperty<User>;

    readonly locations : ReferencesProperty<Location>;

}
