import React from 'react';

import { amber, blue, blueGrey, brown, cyan, deepOrange, deepPurple, green, grey, indigo, lightBlue, lightGreen, lime, orange, pink, purple, red, teal, yellow } from '@material-ui/core/colors';


export const EmptyColor = "#EEE";

export const DefaultColorHue = 400;

export const DefaultColor = lightBlue;

const colors = [
  cyan, 
  lime, 
  pink, 
  blue,
  lightGreen, 
  indigo,   
  orange, 
  blueGrey, 
  green, 
  deepPurple, 
  deepOrange, 
  brown, 
  yellow,
  amber, 
  grey,
  red, 
  teal,  
  purple
];

const colorMaps = new Map<string,Map<string,string>>();

export const colorMap = ( key : string, value? : any, colorHue? : number ) => {

  const hue = colorHue != null ? colorHue : DefaultColorHue;

  if( value == null ) {
    return EmptyColor; 
  }

  let colorMap = colorMaps.get( key );

  if( colorMap == null ) {

    colorMap = new Map<string,string>()

    colorMaps.set( key, colorMap );
  }

  const lookup = JSON.stringify( value );

  let color = colorMap.get( lookup );

  if( color == null ) {

    const colorMapEntry = colorMap.size < colors.length ? 
      colors[colorMap.size] : undefined;

    color = colorMapEntry != null ? 
      (colorMapEntry as any)[hue] : 
      (DefaultColor as any)[hue];

    colorMap.set( lookup, color! );  
  }

  return color;
}

interface ColorMapProps {
  propertyKey : string, 
  value? : any,
  height: number,
  width: number
}


interface ColorMapState { 
}

export default class ColorMap extends React.PureComponent<ColorMapProps,ColorMapState> {

  constructor( props: ColorMapProps ) {
    
    super(props);

    this.state = { 
    } as ColorMapState;

    //log.traceInOut( "constructor()" );
  }

  
  render() {

    //log.traceInOut( "render()" );

    return(
      <React.Fragment>
        <div style={{ width: this.props.width, height: this.props.height, backgroundColor: colorMap( this.props.propertyKey, this.props.value )}} />
      </React.Fragment>
    );
  }
}


