import * as React from 'react'

import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import { withTranslation, WithTranslation } from 'react-i18next';

import { log } from 'ui/app/app';

import { Chip, Grid, IconButton, Input } from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';

import { AttachmentsPropertyIF } from 'services/database/api/properties/attachmentsPropertyIF';
import { translatedPropertyLabel } from '../propertyLabel';
import { PropertyDisplayMode, propertyInputVariant } from '../propertyValue';

import { AppContext } from '../../app/appContext';
import { NewDocumentId } from '../../../services/database/api/core/databaseServiceIF';

import { StorageMedia } from '../../../services/storage/api/storageMedia';
import ChipInput, { ChipRendererArgs } from 'material-ui-chip-input';
import { mediaDialog } from '../mediaDialog';
import { confirmationDialog } from '../simpleDialog';

const styles = (theme: Theme) => createStyles({
  chip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1.25),
  },
  input: {
    display: "none",
  },
  uploadButton: {
    marginLeft: theme.spacing(-6), 
    marginRight: theme.spacing(-6)
  }
});



export interface AttachmentsPropertyValueProps extends WithStyles<typeof styles>, WithTranslation { 

  property : AttachmentsPropertyIF,

  onPropertyChange? : ( property : AttachmentsPropertyIF ) => void,

  displayMode : PropertyDisplayMode,
  
  singleProperty : boolean,

  hideColor?: boolean,

  disabled?: boolean,

  required? : boolean
}

interface AttachmentsPropertyValueState { 

  open : boolean,

  allowCreate?: boolean,

  propertyValues?: Map<string,StorageMedia>,

  loading: boolean

}

class AttachmentsPropertyValue extends React.Component<AttachmentsPropertyValueProps,AttachmentsPropertyValueState> {

  constructor( props: AttachmentsPropertyValueProps ) {
    
    super(props);

    this._inputRef = React.createRef();

    const newDocument = this.props.property.parentDocument().databasePath().includes("/" + NewDocumentId); 

    const allowCreate = 
      this.props.displayMode === PropertyDisplayMode.Form;

    const propertyValues = this.props.property.value();

    this.state = { 

      open : false,

      allowCreate: allowCreate,

      newDocument: newDocument,

      propertyValues: propertyValues,

      loading: false

     } as AttachmentsPropertyValueState;

    //log.traceInOut( "constructor()" );
  }

  async componentDidMount() {

    try {
      //log.traceIn( "componentDidMount()", this.state );
    
      //log.traceOut( "componentDidMount()", "state:", this.state );

    } catch( error ) {
      log.warn( "componentDidMount()", "Error mounting documents view", error );
      
      log.traceOut( "componentDidMount()", "error" );
    }
  }

  async componentDidUpdate() {

    //log.traceIn( "componentDidUpdate()",  this.props.property.key() );

    const propertyValues = this.props.property.value() as Map<string,StorageMedia>;

    //log.debug( "componentDidUpdate()",  {propertyValues} );

    const existingPropertyValues = this.state.propertyValues;

    //log.debug( "componentDidUpdate()",  {existingPropertyValues} );

    if(!this.state.open &&
        this.props.property.compareValue(existingPropertyValues) !== 0) {

      this.setState({
        propertyValues: propertyValues
      })
    }
    //log.traceOut( "componentDidUpdate()",  this.props.property.key() );

  }

  render() {
    //log.traceInOut("render()");

    const {classes } = this.props;

    if( !this.props.property.userAccess().allowRead ) {
      return ( null );
    }

    if( this.props.displayMode === PropertyDisplayMode.Cell ) {
      return ( 
        <>{ 
          this.props.property.value() == null ? <>&nbsp;</> : this.props.property.value()}
        </>  
      );  
    }


    const handleCapture = ({ target }: any) => {

      const selectedFile =  target.files[0];

      const media = {

        data: selectedFile

      } as StorageMedia;

      this.props.property.addAttachment( media );

      const propertyValues = this.props.property.value();

      this.setState( { 
        propertyValues: propertyValues
      } )

      if( this.props.onPropertyChange != null ) {
        this.props.onPropertyChange( this.props.property );
      }
    }

    const showMedia = ( label : string, media: StorageMedia ) => {

      mediaDialog( label, media ); 

    }

    const handleDeleteChip = async ( key: string, index : number ) => {
  
      log.traceIn( "handleDeleteChip()", key, index );
  
      const confirmation = await confirmationDialog( "deleteAttachment");

      if( !confirmation ) {
        log.traceOut( "handleDeleteChip()", "not confirmed" );
        return;
      }

      const propertyValues = this.props.property.value();

      if( propertyValues == null ) {
        log.traceOut( "handleDeleteChip()", "No values");
        return;
      }

      const deleteMedia = propertyValues.get( key );
    
      if( deleteMedia != null ) {
        this.props.property.removeAttachment( deleteMedia );

        this.setState( {
          propertyValues: propertyValues
        })
        
        if( this.props.onPropertyChange != null ) {
          this.props.onPropertyChange( this.props.property );
        }
      }

    
      log.traceOut( "handleDeleteChip()");
    }


    const chipRenderer = ( args : ChipRendererArgs, key : string ) => {

      const media = this.state.propertyValues!.get(args.value);

      let label : string;

      if( (media?.data as any)?.name != null ) {
        label = (media!.data as any).name 
      }
      else if( media?.path != null ) {

        const elements = media.path.split("/");

        label = elements[elements.length-1];
      }
      else {
        label = key;
      }

      return (
        <Chip className={classes.chip} 
          key={args.value}
          label={label}
          onClick={() => { showMedia( label, media! ) }}
          onDelete={args.handleDelete}
          onMouseDown={(event) => { event.stopPropagation() }}
        />
      )
    }

  const label = translatedPropertyLabel( this.props.property.parent.documentName(), this.props.property.key() );

    return (
      <Grid container alignItems="center"> 
        <ChipInput 
          disabled={this.props.disabled}
          readOnly={true}
          fullWidth
          variant={propertyInputVariant}
          required={this.props.required}
          InputLabelProps={{ shrink: true }}
          label={label}
          value={this.state.propertyValues != null ? Array.from(this.state.propertyValues.keys()) : [] as string[]}
          onDelete={(value, index) => handleDeleteChip(value, index)}
          chipRenderer={chipRenderer}
        />
        <Input
          inputRef={this._inputRef}
          id="upload-button"
          inputProps={{
            accept: "image/*,application/pdf",
          }}
          onChange={handleCapture}
          className={classes.input}
          type="file"
        />
        {!this.props.disabled &&
          <IconButton
            className={classes.uploadButton}
            component="span"
            onClick={() => this._inputRef.current!.click()} 
          >
            <AddIcon />
          </IconButton>
        }
      </Grid>
    );
  }

  private _inputRef : any;

}

AttachmentsPropertyValue.contextType = AppContext;

const ModifiedAttachmentsPropertyValue = withTranslation()(withStyles(styles)(AttachmentsPropertyValue));

export default ModifiedAttachmentsPropertyValue;











