


export const Documents = {

    Alert                       : "alert",

    Category                    : "category",

    Change                      : "change",

    Company                     : "company",

    Device                      : "device",

    Key                         : "key",

    Location                    : "location",

    Message                     : "message",

    Project                     : "project",

    Unit                        : "unit",

    Residence                   : "residence",

    Subscription                : "subscription",

    Terminal                    : "terminal",

    User                        : "user",
}

export type DocumentName = keyof (typeof Documents);

export const DocumentNames = Object.values( Documents );

export const AlertDocument = Documents.Alert as DocumentName;

export const CategoryDocument = Documents.Category as DocumentName;

export const ChangeDocument = Documents.Change as DocumentName;

export const CompanyDocument = Documents.Company as DocumentName;

export const DeviceDocument = Documents.Device as DocumentName;

export const KeyDocument = Documents.Key as DocumentName;

export const LocationDocument = Documents.Location as DocumentName; 

export const MessageDocument = Documents.Message as DocumentName;

export const ProjectDocument = Documents.Project as DocumentName;

export const UnitDocument = Documents.Unit as DocumentName;

export const ResidenceDocument = Documents.Residence as DocumentName;

export const SubscriptionDocument = Documents.Subscription as DocumentName; 

export const TerminalDocument = Documents.Terminal as DocumentName;

export const UserDocument = Documents.User as DocumentName;




