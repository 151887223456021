import * as React from 'react'


import { createStyles, makeStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import { withTranslation, WithTranslation } from 'react-i18next';

import { AppContext} from 'ui/app/appContext';
import { log } from 'ui/app/app';

import { DatabaseDocumentIF } from 'services/database/api/core/databaseDocumentIF';

import { errorDialog } from './simpleDialog';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import {ReferenceHandle } from '../../services/database';
import { DatabaseProps, DatabaseState } from './databaseView';
import Loading from './loading';

import { DatabaseObserverIF } from '../../services/database/api/core/databaseObserverIF';
import { ObservableIF } from '../../services/common/api/observableIF';
import { Observation } from '../../services/common/api/observation';
import { Monitor } from '../../services/common/api/monitor';
import { DocumentsList } from './documentsList';
import { UserAccess } from '../../services/common/api/userAccess';
import { Box } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  root: {
    width: '100%',
    height: '100%',
    paddingtop: theme.spacing(0),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
});


interface MatchParams {
}

export interface DatabaseListProps extends DatabaseProps {

  databaseObserver : DatabaseObserverIF<DatabaseDocumentIF>,

  showDate? : boolean
}

export interface ReactDatabaseListProps extends
  DatabaseListProps,
  WithStyles<typeof styles>,
  WithTranslation,
  RouteComponentProps<MatchParams> 
  {}


interface DatabaseListState extends DatabaseState { // Component State

  handles : Map<string,ReferenceHandle<DatabaseDocumentIF>>,

  userAccess : UserAccess,

  loading: boolean
}


class DatabaseList extends React.PureComponent<ReactDatabaseListProps, DatabaseListState> {

  constructor(props: ReactDatabaseListProps) { 

    super(props);

    this.state = {

      handles: new Map<string,ReferenceHandle<DatabaseDocumentIF>>(),

      userAccess: this.props.databaseObserver.defaultDatabase()!.userAccess(),

      loading: true

    } as DatabaseListState;

    this.onUpdateDatabases = this.onUpdateDatabases.bind(this);

    this.documentDetails = this.documentDetails.bind(this);
    this.onRemoveDocument = this.onRemoveDocument.bind(this);

    this.classes = makeStyles((theme) => ({
    }));

    //log.traceInOut("constructor()");
  }


  async componentDidMount() {

    try {
      log.traceIn("componentDidMount()" );

      const filteredReferenceHandles = this.props.databaseObserver.handles();

      this.setState( {
        handles: filteredReferenceHandles
      });

      await this.props.databaseObserver.subscribe({ 
        observer: this,
        onNotify: this.onUpdateDatabases 
        } as Monitor );

      await this.props.databaseObserver.update();

      log.traceOut("componentDidMount()");

    } catch( error ) {
          
      log.warn( "componentDidMount()", error ); 

      await errorDialog( error);

      log.traceOut( "componentDidMount()", error );
    }
  }
 
  async componentDidUpdate() {

    try {
      //log.traceIn("componentDidUpdate()" );

      await this.props.databaseObserver.update();

      //log.traceOut("componentDidUpdate()" );

    } catch( error ) {
          
      log.warn( "componentDidUpdate()", error );

      await errorDialog( error);

      //log.traceOut( "componentDidUpdate()", error );
    }
  }

  async componentWillUnmount() {
    log.traceIn("componentWillUnmount()");

    try {

      await this.props.databaseObserver.unsubscribe( this );

      log.traceOut("componentWillUnmount()");

    } catch (error) {
      log.warn("componentWillUnmount()", "Error unmounting collection list", error);
    }
  }

  private onUpdateDatabases = async (observable : ObservableIF, observation : Observation ) => {

    log.traceIn("onUpdateDatabases()");

    try {
      if( observable !== this.props.databaseObserver ) {
        throw new Error("Mismatching database observer");
      }

      const filteredReferenceHandles = this.props.databaseObserver.handles();

      this.setState( {
        handles: filteredReferenceHandles,
        loading: false
      });

    log.traceOut("onUpdateDatabases()", filteredReferenceHandles.size );

    } catch (error) {
      log.warn("onUpdateDatabases()", "Error updating databases", error);

      log.traceOut("onUpdateDatabases()", "error");
    }
  }

  protected documentDetails = async ( referenceHandle: ReferenceHandle<DatabaseDocumentIF>) => {

    log.traceInOut("documentDetails()", referenceHandle);

    try {
      const databaseDocument = this.props.databaseObserver.document( referenceHandle.path );

      if( databaseDocument != null && this.props.onOpenDocument != null ) {

        this.props.onOpenDocument( this.props.databaseObserver, databaseDocument );
      }
      else {
        let to = this.context.currentHomePath + referenceHandle.path; 

        this.props.history.push(to);
      }

      log.traceIn("documentDetails()", referenceHandle.path );

    } catch( error ) {
      
      log.warn( "documentDetails()", error ); 

      await errorDialog( error);

    }
  }


  private onRemoveDocument = async ( referenceHandle:  ReferenceHandle<DatabaseDocumentIF> ) => {

    log.traceIn("onRemoveDocument()", referenceHandle);

    try {

      if( this.props.onRemoveDocument == null ) {
        log.traceOut("onRemoveDocument()", "No callback");
        return;
      }

      const databaseDocument = this.props.databaseObserver.document( referenceHandle.path );

      if (databaseDocument == null) {
        throw new Error("notFound");
      }

      this.props.onRemoveDocument( this.props.databaseObserver, databaseDocument );

      log.traceOut("onRemoveDocument()");

    } catch( error ) {
      
      log.warn( "onRemoveDocument()", error );

      await errorDialog( error);
     }
  }

  render(): JSX.Element {

    //log.traceInOut("render()"); 

    const { classes } = this.props;

    return (
      <React.Fragment>
        {this.state.loading ? <Loading /> :
          <AppContext.Consumer>
            {appContext => (
              <Box className={classes.root}>
                <DocumentsList
                  collectionName={this.props.databaseObserver.defaultDatabase()!.collectionName()}
                  handles={this.state.handles}
                  allowSelect={false}
                  allowAdd={!this.props.hideAdd && this.state.userAccess.allowCreate && this.props.onAddDocument != null}
                  allowRemove={!this.props.hideRemove && this.state.userAccess.allowDelete && this.props.onRemoveDocument != null}
                  showDate={this.props.showDate}
                  onAddDocument={this.props.onAddDocument == null ? undefined :
                    () => this.props.onAddDocument!( this.props.databaseObserver ) 
                  }
                  onSelectHandle={this.props.onOpenDocument != null ? this.documentDetails : undefined}
                  onRemoveHandle={this.onRemoveDocument}
                />
              </Box>
            )}
          </AppContext.Consumer>
        }
      </React.Fragment >
    );
  }

  private readonly classes: any;

}

DatabaseList.contextType = AppContext;

const ModifiedDatabaseList = withRouter(withTranslation()(withStyles(styles)(DatabaseList)));

export default ModifiedDatabaseList;












