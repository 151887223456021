

import { LoggerFactory } from "../../common/api/loggerFactory";
import { Monitor } from "../../common/api/monitor";
import { Observation } from "../../common/api/observation";
import { Target } from "../../common/api/targets";
import { Observable } from "../../common/impl/observable";
import { DatabaseDocument } from "../../database/framework/databaseDocument";
import { SecurityServiceIF } from "../api/securityServiceIF";
import { KeyManager } from "./keyManager";
import { SymmetricCipher } from "./symmetricCipher";

import { Factory } from "../../common/api/factory";
import { CompaniesCollection } from "../../database/api/collections";

import { Company } from "../../database/impl/documents/company";


import securityConfiguration from "../../../healthguard/data/settings/security.json";
import { Key } from "../../database/impl/documents/key";
import { KeyType, KeyTypes } from "../../database/api/definitions/keyType";
import { KeyFormat, KeyFormats } from "../../database/api/definitions/keyFormat";
import { KeyStatus, KeyStatuses } from "../../database/api/definitions/keyStatus";
import { PropertyTypes } from "../../database/api/definitions/propertyType";
import { PropertiesSelector } from "../../database/api/core/propertiesSelector";
import { CollectionProperty } from "../../database/impl/properties/collectionProperty";

export const log = LoggerFactory.logger("security");


export class SecurityService extends Observable implements SecurityServiceIF {

    constructor( target : Target, params? : { keyManager?: KeyManager } ) {

        super();

        //log.traceInOut("constructor()", target ); 

        try {

            this.target = target;

            this.keyManager = params?.keyManager;

            this.symmetricCipher = new SymmetricCipher();

        } catch (error) {

            log.warn("constructor()", "Error constructing security service", error);

            throw new Error( (error as any).message );
        }
    }

    async init() : Promise<void> {

        log.traceInOut("init()" );

        try {


        } catch (error) {
            log.warn("init()", "Error initializing security service", error);

            throw new Error( (error as any).message );
        }
    }

    async updateCompanyKeys( company : Company ) : Promise<boolean> {

        try {
            log.traceIn("updateCompanyKeys()", company.title.value() );

            let changed = false;

            const keys = await company.keys.collection().documents();

            let companySecret : Key | undefined;

            for( const key of keys.values() ) {

                if( key.keyType.value() === KeyTypes.Symmetric ) {
                    companySecret = key;
                    break;
                }
            }

            if( companySecret == null ) {

                companySecret = company.keys.collection().newDocument();

                companySecret.keyType.setValue( KeyTypes.Symmetric as KeyType );

                companySecret.keyFormat.setValue( KeyFormats.AES256 as KeyFormat );

                companySecret.keyStatus.setValue( KeyStatuses.Requested as KeyStatus );

                companySecret.keyVault.setValue( this.keyManager?.keyVault );

            }

            switch( companySecret.keyStatus.value() )
            {
                case KeyStatuses.Requested:
                {
                    const symmetricKey = await this.keyManager?.createSymmetricKey( 
                        company.id.value()!, 
                        companySecret.secretKey.value(),
                        company.title.value() == null ? undefined : 
                        {
                            "company": company.title.value()!.replace(" ", "-").toLowerCase() 
                        } );
    
                    if( symmetricKey != null ) {
    
                        companySecret.keyStatus.setValue( KeyStatuses.Enabled as KeyStatus );
    
                        const versionNumber = Object.keys( symmetricKey.versions ).pop()!;
    
                        companySecret.version.setValue( +versionNumber );
    
                        if( companySecret.id.value() == null ) {
                            await companySecret.create();
                        }
                        else {
                            await companySecret.update();
                        }

                        changed = true;
                    }
                    break;
                }

                case KeyStatuses.Enabled:
                {
                    const symmetricKey = await this.keyManager?.symmetricKey( company.id.value()! );
    
                    if( symmetricKey != null && symmetricKey.status !== KeyStatuses.Enabled ) {
    
                        this.keyManager!.enableSymmetricKey( company.id.value()! );

                        changed = true;
                    }
                    break;
                }

                case KeyStatuses.Disabled:
                {
                    const symmetricKey = await this.keyManager?.symmetricKey( company.id.value()! );
    
                    if( symmetricKey != null && symmetricKey.status !== KeyStatuses.Disabled ) {
    
                        this.keyManager!.disableSymmetricKey( company.id.value()! );

                        changed = true;
                    }
                    break;
                }
            }

            log.traceOut("updateCompanyKeys()", {changed} );
            return changed;

        } catch (error) {
            log.warn("Error updating company keys", error );

            return false;
        }
    }

    async disableCompanyKeys( company : Company ) : Promise<boolean> {

        try {
            log.traceIn("disableCompanyKeys()", company );

            let changed = false;

            const keys = await company.keys.collection().documents();

            let companySecret : Key | undefined;

            for( const key of keys.values() ) {

                if( key.keyType.value() === KeyTypes.Symmetric ) {
                    companySecret = key;
                    break;
                }
            }

            if( companySecret == null ) {

                log.traceOut("disableCompanyKeys()", "not found" );
                return false;
            }

            switch( companySecret.keyStatus.value() )
            {
                case KeyStatuses.Enabled:
                {
                    const symmetricKey = await this.keyManager?.symmetricKey( company.id.value()! );
       
                    if( symmetricKey != null && symmetricKey.status !== KeyStatuses.Disabled  ) {
    
                        this.keyManager!.disableSymmetricKey( company.id.value()! );
                        changed = true;
                    }
                    break;
                }
            }

            log.traceOut("disableCompanyKeys()" );
            return changed;

        } catch (error) {
            log.warn("Error disabling company keys", error );

            return false;
        }
    }

    clearCurrentKeys() : void {

        //log.traceIn( "clearCurrentKeys()" );

        this.symmetricCipher.setDefaultKey( undefined );

        this.symmetricCipher.setKey( undefined );

        //log.traceOut( "clearCurrentKeys()" );

    }


    async updateCurrentKeys( databaseDocument : DatabaseDocument ) : Promise<void> {
        
        try {
            //log.traceIn( "updateCurrentKeys()" );

            if( this.symmetricCipher.defaultKey() === undefined ) {

                let defaultKey;

                if( Factory.get().isClient() ) {
                    defaultKey = Factory.get().authenticationService?.authenticationClaims()?.defaultKey;
                }
                else {
                    const defaultKeyId = Factory.get().configurationService.config(
                        securityConfiguration, "defaultKeyId")!;
    
                    defaultKey = await this.keyManager?.symmetricKey( defaultKeyId );
                }

                this.symmetricCipher.setDefaultKey( defaultKey == null ? null : defaultKey );

                log.debug( "updateCurrentKeys()", "updated default", defaultKey == null ? null : defaultKey.id) ;
            }

            const companyId = databaseDocument.ownerDocumentId( CompaniesCollection );

            //log.debug( "updateCurrentKeys()", {companyId} );

            if( companyId != null ) {

                const key = this.symmetricCipher.key();

                //log.debug( "updateCurrentKeys()", "key", key?.id );

                if( key === undefined || (key != null && key.id !== companyId ) ) {

                    let key;

                    if( Factory.get().isClient() ) {

                        key = Factory.get().authenticationService?.authenticationClaims()?.key;
                    }
                    else {
                        key = await this.keyManager?.symmetricKey( companyId );
                    }

                    this.symmetricCipher.setKey( key == null ? null : key );

                    log.debug( "updateKeys()", "updated", key == null ? null : key.id ) ;
                }
            }

            //log.traceOut( "updateCurrentKeys()" ) ; 

        } catch (error) {
            log.warn( "updateKeys()", "Failed to update cipher keys", error ) ;

            this.symmetricCipher.setDefaultKey( null );

            this.symmetricCipher.setKey( null );

            throw new Error( (error as any).message );
        }
    }

    async recipher( databaseDocument : DatabaseDocument, recursive? : boolean ) : Promise<void> { 

        try {
            log.traceIn("recipher()", databaseDocument.title.value() );

            await databaseDocument.update( true );

            if( !recursive ) {
                log.traceOut("recipher()", "Done non recursive" );
                return;
            }

            const collectionPropertiesSelector =
                { includePropertyTypes: [PropertyTypes.Collection] } as PropertiesSelector;

            const subcollectionProperties = databaseDocument.properties(collectionPropertiesSelector);

            for( const subcollectionProperty of subcollectionProperties.values() ) {

                const collectionDatabase =
                    (subcollectionProperty as CollectionProperty<DatabaseDocument>).collection();

                // Changes have admin security access and must be handled with admin rights from back end delete monitoring

                const collectionDocuments = await collectionDatabase.documents();

                for( const collectionDocument of collectionDocuments.values() ) {

                    await this.recipher( collectionDocument, recursive );
                }
            }

            log.traceOut("recipher()", "Done recursive" );

        } catch (error) {
            log.warn("Error reciphering document and its collections", error);
        }
    }

    protected async monitor( newMonitor : Monitor ): Promise<void> {}

    protected async release(observationFilter?: Observation[], objectIdsFilter?: string[]): Promise<void> {}

    readonly target : Target;

    readonly keyManager? : KeyManager;

    readonly symmetricCipher : SymmetricCipher;
}
