
import { log } from "../../framework/databaseService";
import { DatabaseDocument } from "../../framework/databaseDocument";
import { ReferenceProperty } from "../properties/referenceProperty";
import { ReferencesProperty } from "../properties/referencesProperty";
import { Company } from "./company";
import { User } from "./user";
import { Location } from "./location";
import { Category } from "./category";
import { CollectionDatabase } from "../core/collectionDatabase";
import { UnitIF } from "../../api/documents/unitIF";
import { CategoriesCollection, CompaniesCollection, DevicesCollection, LocationsCollection, ProjectsCollection, UnitsCollection, UsersCollection } from "../../api/collections";
import { UnitManager } from "../managers/unitManager";
import { TenantProperty } from "../properties/tenantProperty";
import { OwnerProperty } from "../properties/ownerProperty";
import { CollectionProperty } from "../properties/collectionProperty";
import { CompanyDocument, UnitDocument } from "../../api/documents";
import { Project } from "./project";
import { Device } from "./device";
import { CollectionGroupDatabase } from "../core/collectionGroupDatabase";
import { Database } from "../core/database";
import { SymbolicCollectionProperty } from "../properties/symbolicCollectionProperty";
import { ReferenceHandle } from "../../api/core/referenceHandle";

export abstract class Unit extends DatabaseDocument implements UnitIF {
 
    constructor( unitDocumentName : string, unitsCollection : CollectionDatabase<Unit>, documentPath? : string ) {

        super( unitDocumentName, unitsCollection, documentPath );

        try {

            this.startDate.editable = false;
            this.endDate.editable = false;

            this.title.required = true; 

            this.company = new TenantProperty<Company>( this, CompaniesCollection, CompanyDocument );
            this.company.required = true; 
            this.company.editable = false;

            const company = this.company.emptyDocument() as Company;

            this.unit = new OwnerProperty<Unit>( this, UnitsCollection, UnitDocument ); // parent

            if( this.id.value() != null ) {

                this.unit.editable = false;
            }

            this.units = new CollectionProperty<Unit>( 
                this, 
                UnitsCollection,
                true ); // children

            this.projects = new CollectionProperty<Project>(  
                this, 
                ProjectsCollection,
                true );

            this.categories = new CollectionProperty<Category>( 
                this, 
                CategoriesCollection,
                true );

            this.locations = new CollectionProperty<Location>( 
                this, 
                LocationsCollection,
                true ); 

            this.devices = new CollectionProperty<Device>( this, DevicesCollection, true );

            this.users = new CollectionProperty<User>( this, UsersCollection, true );

            this.symbolicUsers = new SymbolicCollectionProperty<User>( this, 
                () => [company.users.collectionGroup()] as CollectionGroupDatabase<User>[],
                "symbolicUnits" );

            this.manager = new ReferenceProperty<User>( this, 
                () => (this.parentDatabases( UsersCollection, {nearestIsCollectionGroup: false} ) as Database<User>[]).concat( this.users.collection() ),
                "managing" );

            this.assistants = new ReferencesProperty<User>( 
                this, 
                () => [this.users.collection()], 
                "assisting" );
        
            this.deputies = new ReferencesProperty<User>( 
                this, 
                () => [this.users.collection()],  
                "deputing"  );

               
            //log.traceInOut( "constructor()", UnitsCollection );

        } catch( error ) {

            log.warn( "constructor()", "Error initializing group", error );
            
            throw new Error( (error as any).message );
        }
    }

    indexKey() : string {
        return UnitDocument;
    }

    referenceDateProperty()  {
        return undefined;
    }

    attachUser( userReferenceHandle: ReferenceHandle<User> ) : Promise<void> {
        return UnitManager.getInstance().attachUser( this, userReferenceHandle );
    }


    async onCreate() : Promise<void> {
        try {
            //log.traceIn( "onCreated()" );

            await super.onCreate();

            await UnitManager.getInstance().handleCreateUnit( this );
    
            //log.traceOut( "onCreate()" );
  
        } catch( error ) {
            
            log.warn( "("+this.collectionDatabase.collectionName()+")", "onCreate()", "Error handling created notification", error );
  
            throw new Error( (error as any).message );
        }
    }

    async onUpdate() : Promise<void> {
        try {
            //log.traceIn( "onUpdate()" );

            await super.onUpdate();

            await UnitManager.getInstance().handleUpdateUnit( this );
    
            //log.traceOut( "onUpdate()" );
  
        } catch( error ) {
            
            log.warn( "onUpdate()", "Error handling updated notification", error );
  
            throw new Error( (error as any).message );
        }    
    }

    async onDelete() : Promise<void> {

        try {
            //log.traceIn( "onDelete()" );

            await super.onDelete();

            await UnitManager.getInstance().handleDeleteUnit( this );
    
            //log.traceOut( "onDelete()" );
  
        } catch( error ) {
            
            log.warn( "onDeleted()", "Error handling updated notification", error );
  
            throw new Error( (error as any).message );
        }  
    }

    readonly company: TenantProperty<Company>;

    readonly unit: OwnerProperty<Unit>; // parent 

    readonly units: CollectionProperty<Unit>; // children

    readonly locations : CollectionProperty<Location>;

    readonly projects : CollectionProperty<Project>;

    readonly categories : CollectionProperty<Category>;

    readonly devices : CollectionProperty<Device>;

    readonly manager: ReferenceProperty<User>;

    readonly assistants: ReferencesProperty<User>;

    readonly deputies: ReferencesProperty<User>;

    readonly users : CollectionProperty<User>;

    readonly symbolicUsers : SymbolicCollectionProperty<User>;

}
