import { Registration } from '../documents/registration';

import { HealthguardUser } from '../documents/healthguardUser';
import { log } from '../../../services/database/framework/databaseService';
import { HealthguardProject } from '../documents/healthguardProject';
import { ReferencesProperty } from '../../../services/database/impl/properties/referencesProperty';
import { HealthguardLocation } from '../documents/healthguardLocation';

import { Factory } from '../../../services/common/api/factory';
import { HealthguardCategory } from '../documents/healthguardCategory';
import { IncidentRegistration } from '../documents/incidentRegistration';
import { IllnessStages } from '../../api/definitions/illnessStage';

import incidentsConfiguration from "../../../healthguard/data/settings/incidents.json";
import { ReferenceHandle } from '../../../services/database/api/core/referenceHandle';
import { QuarantineRegistration } from '../documents/quarantineRegistration';
import { SickLeaveRegistration } from '../documents/sickLeaveRegistration';
import { SymptomRegistration } from '../documents/symptomRegistration';
import { TestRegistration } from '../documents/testRegistration';

const millisecondsInADay = 24*60*60*1000;

export class IncidentManager  {

    static getInstance() : IncidentManager {
        if( IncidentManager._instance == null ) {
            IncidentManager._instance = new IncidentManager();
        }
        return IncidentManager._instance;
    }

    async handleIncidentCreated( user : HealthguardUser, createdIncident: IncidentRegistration ): Promise<boolean> {

        try {
            log.traceIn("handleIncidentCreated()");

            if( user == null ) {
                log.traceOut( "handleIncidentCreated()", "New incident has no user");
                return false;
            }

            let incidentChanged = false;

            createdIncident.user.setValue( user.referenceHandle() as ReferenceHandle<HealthguardUser> );

            const illnessStage = createdIncident.illnessStage.value();

            if( illnessStage != null ) {
                switch( illnessStage ) {

                    case IllnessStages.Recovered:
                    case IllnessStages.Deceased:
    
                        log.debug("handleIncidentCreated()", "Ending incident per patient status: " + illnessStage );
                        createdIncident.endDate.setValue(  createdIncident.startDate.value() );
                        incidentChanged = true;
                        break;
    
                    default:
                        break;
                }
            }

            incidentChanged = await this.addRegistrationsRetroactively( user, createdIncident ) || incidentChanged;

            log.traceOut("handleIncidentCreated()");
            return incidentChanged;

        } catch (error) {
            log.warn("Error handling registration create", error);

            log.traceOut("handleNewRegistration()", "error");
            return false;
        }
    }

    async latestActiveIncident( user : HealthguardUser, registration: Registration ) : Promise<IncidentRegistration | undefined> {

        try {
            log.traceIn("latestActiveIncident()");

            const incidents = await user.incidentRegistrations.collection().documents();

            let latestActiveIncident : IncidentRegistration | undefined;

            for( const incident of incidents.values() ) {

                if( registration.hazard.value() != null &&
                    incident.hazard.compareTo( registration.hazard ) !== 0 ) {
                    
                    continue;
                }               

                if( incident.endDate.value() == null ) {

                    if( latestActiveIncident == null || incident.startDate.compareTo( latestActiveIncident.startDate ) > 0 ) {

                        const activeIncident = await this.checkActiveIncident( user, incident );
                        if(  activeIncident ) {
                            latestActiveIncident = incident;
                        }
                    }
                }              
            }

            log.traceOut("latestActiveIncident()");
            return latestActiveIncident;

        } catch (error) {
            log.warn("Error reading latest active incident", error);

            return undefined;
        }
    }

    async checkActiveIncident( user : HealthguardUser, incident: IncidentRegistration ) : Promise<boolean> {

        try {
            log.traceIn("checkActiveIncident()");

            if( incident.endDate.value() != null ) {
                log.traceOut("checkActiveIncident()", "incident already has end date");
                return false;
            }

            let active = true;

            if( incident.lastChangedAt.value() != null ) {

                const latestActiveDateInMilliseconds = 
                    incident.lastChangedAt.value()!.getTime() + 
                    (incidentsConfiguration.maxInactiveIncidentPeriodInDays as number) * millisecondsInADay;
                
                if( latestActiveDateInMilliseconds < new Date().getTime() ) {

                    const lastActiveDate = new Date();

                    lastActiveDate.setTime( latestActiveDateInMilliseconds );

                    incident.endDate.setValue( lastActiveDate );

                    incident.illnessStage.setValue( undefined );

                    await incident.update();

                    active = false;

                    log.debug("checkActiveIncident()", "closed incident that expired at " + lastActiveDate.toLocaleDateString() );
                }
            }

            log.traceOut("checkActiveIncident()", active );
            return active;

        } catch (error) {
            log.warn("Error checking active incident", error);

            log.traceOut("checkActiveIncident()", "error" );
            return false;
        }
    }

    async createIncidentFromRegistration( user : HealthguardUser, registration: Registration  ) : Promise<IncidentRegistration> { 

        try {
            log.traceIn("createIncidentFromRegistration()");

            const incident = user.incidentRegistrations.collection().newDocument();

            let title = registration.hazard.value() != null && registration.hazard.value()!.title != null ? 
                registration.hazard.value()!.title + ": " : "";

            title += user.title.value() + " - " + registration.startDate.value()!.toLocaleDateString();

            incident.title.setValue( title )

            incident.startDate.setValue( registration.startDate.value() );

            incident.lastChangedBy.setValue( registration.lastChangedBy.value() );

            incident.lastChangedAt.setValue( registration.lastChangedAt.value() );

            incident.hazard.setValue( registration.hazard.value() );

            incident.variant.setValue( registration.variant.value() );

            await incident.create();

            log.traceOut("createIncidentFromRegistration()");
            return incident; 

        } catch (error) {
            log.warn("Error reading latest active incident", error);

            throw new Error( (error as any).message );
        }
    }

    async addRegistrationsRetroactively( user : HealthguardUser, incident: IncidentRegistration ) : Promise<boolean> {

        try {
            log.traceIn("addRegistrationsRetroactively()");

            let incidentUpdated = false;

            const cutoffDate = new Date();

            const addRegistrationsRetroactivelyForDays = Factory.get().configurationService.config( 
                incidentsConfiguration, "addRegistrationsRetroactivelyForDays" );

            cutoffDate.setTime( new Date().getTime() - (+addRegistrationsRetroactivelyForDays)*millisecondsInADay );

            const quarantineRegistrations = await user.quarantineRegistrations.collection().documents();

            for( const quarantineRegistration of quarantineRegistrations.values() ) {
  
                if( (quarantineRegistration.hazard.value() == null || incident.hazard.compareTo( quarantineRegistration.hazard ) === 0) &&
                     quarantineRegistration.startDate.compareValue( cutoffDate ) >= 0 ) {

                    incident.quarantineRegistrations.setDocument( 
                        quarantineRegistration.referenceHandle() as ReferenceHandle<QuarantineRegistration>  );

                    incidentUpdated = incidentUpdated || incident.quarantineRegistrations.isChanged();

                    const registrationUpdated = this.syncRegistrationAndIncident( user, quarantineRegistration, incident );

                    if( registrationUpdated ) {
                        await quarantineRegistration.update();
                    }

                    log.debug("addRegistrationsRetroactively()", "added", quarantineRegistration.referenceHandle() );
                }
            }

            const sickLeaveRegistrations = await user.sickLeaveRegistrations.collection().documents();

            for( const sickLeaveRegistration of sickLeaveRegistrations.values() ) {
  
                if( (sickLeaveRegistration.hazard.value() == null || incident.hazard.compareTo( sickLeaveRegistration.hazard ) === 0) &&
                     sickLeaveRegistration.startDate.compareValue( cutoffDate ) >= 0 ) {

                    incident.sickLeaveRegistrations.setDocument( 
                        sickLeaveRegistration.referenceHandle() as ReferenceHandle<SickLeaveRegistration> );

                    incidentUpdated = incidentUpdated || incident.sickLeaveRegistrations.isChanged();

                    const registrationUpdated = this.syncRegistrationAndIncident( user, sickLeaveRegistration, incident );

                    if( registrationUpdated ) {
                        await sickLeaveRegistration.update();
                    }

                    log.debug("addRegistrationsRetroactively()", "added", sickLeaveRegistration.referenceHandle().title );
                }
            }

            const symptomRegistrations = await user.symptomRegistrations.collection().documents();

            for( const symptomRegistration of symptomRegistrations.values() ) {

                if( (symptomRegistration.hazard.value() == null || incident.hazard.compareTo( symptomRegistration.hazard ) === 0) &&
                     symptomRegistration.startDate.compareValue( cutoffDate ) >= 0 ) {

                    incident.symptomRegistrations.setDocument( 
                        symptomRegistration.referenceHandle() as ReferenceHandle<SymptomRegistration> );

                    incidentUpdated = incidentUpdated || incident.symptomRegistrations.isChanged();

                    const registrationUpdated = this.syncRegistrationAndIncident( user, symptomRegistration, incident );

                    if( registrationUpdated ) {
                        await symptomRegistration.update();
                    }

                    log.debug("addRegistrationsRetroactively()", "added", symptomRegistration.referenceHandle().title );
                }
            }

            const testRegistrations = await user.testRegistrations.collection().documents();

            for( const testRegistration of testRegistrations.values() ) {

                if( (testRegistration.hazard.value() == null || incident.hazard.compareTo( testRegistration.hazard ) === 0 ) &&
                    testRegistration.startDate.compareValue( cutoffDate ) >= 0 ) {

                    incident.testRegistrations.setDocument( 
                        testRegistration.referenceHandle() as ReferenceHandle<TestRegistration> );

                    incidentUpdated = incidentUpdated || incident.testRegistrations.isChanged();

                    const registrationUpdated = this.syncRegistrationAndIncident( user, testRegistration, incident );

                    if( registrationUpdated ) {
                        await testRegistration.update();
                    }

                    log.debug("addRegistrationsRetroactively()", "added", testRegistration.referenceHandle().title );
                }
            }

            log.traceOut("addRegistrationsRetroactively()", {incidentUpdated} );
            return incidentUpdated;

        } catch (error) {
            log.warn("Error adding existing registrations to new incident", error);

            throw new Error( (error as any).message );
        }
    }

    syncRegistrationAndIncident( user : HealthguardUser, registration: Registration, incident : IncidentRegistration ) : boolean {

        try {
            log.traceIn("syncRegistrationAndIncident()", registration.referenceHandle(), registration.referenceHandle().title );

            // Update incident

            if( incident.lastChangedAt.compareTo( registration.startDate ) < 0 ) {

                incident.lastChangedAt.setValue( registration.startDate.value() );

                incident.lastChangedBy.setValue( registration.lastChangedBy.value() );
            }

            if( incident.hazard.value() == null && registration.hazard.value() != null ) {

                incident.hazard.setValue( registration.hazard.value() );
            }

            if( incident.variant.value() == null && registration.variant.value() != null ) {

                incident.variant.setValue( registration.variant.value() );
            }

            if( incident.projects.compareTo( user.projects as ReferencesProperty<HealthguardProject> ) !== 0 ) {

                incident.projects.setValue( user.projects.value() as Map<string,ReferenceHandle<HealthguardProject>> );
            }

            if( incident.locations.compareTo( user.locations as ReferencesProperty<HealthguardLocation> ) !== 0 ) {

                incident.locations.setValue( user.locations.value() as Map<string,ReferenceHandle<HealthguardLocation>> );
            }

            if( incident.categories.compareTo( user.categories as ReferencesProperty<HealthguardCategory>  ) !== 0 ) {

                incident.categories.setValue( user.categories.value() as Map<string,ReferenceHandle<HealthguardCategory>>  );
            }

            // Update registration

            let registrationUpdated = false;

            if( registration.hazard.value() == null && incident.hazard.value() != null ) {

                registration.hazard.setValue( incident.hazard.value() );

                registrationUpdated = true;
            }

            if( registration.variant.value() == null && incident.variant.value() != null ) {

                registration.variant.setValue( incident.variant.value() );

                registrationUpdated = true;
            }


            if( registration.projects.compareTo( user.projects as ReferencesProperty<HealthguardProject> ) !== 0 ) {

                registration.projects.setValue( user.projects.value() as Map<string,ReferenceHandle<HealthguardProject>> );

                registrationUpdated = true;
            }


            if( registration.locations.compareTo( user.locations as ReferencesProperty<HealthguardLocation> ) !== 0 ) {

                registration.locations.setValue( user.locations.value() as Map<string,ReferenceHandle<HealthguardLocation>> );

                registrationUpdated = true;
            }

            if( registration.categories.compareTo( user.categories as ReferencesProperty<HealthguardCategory> ) !== 0 ) {

                registration.categories.setValue( user.categories.value() as Map<string,ReferenceHandle<HealthguardCategory>>);

                registrationUpdated = true;
            }

            log.traceOut("syncRegistrationAndIncident()", registrationUpdated ); 
            return registrationUpdated;

        } catch (error) {
            log.warn("Error syncing registration and incident ", error);

            throw new Error( (error as any).message );
        }
    }


    private static _instance? : IncidentManager;

}

