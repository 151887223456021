import { Monitor } from "../api/monitor";
import { Observation } from "../api/observation";
import { ServiceIF } from "../api/serviceIF";
import { Observable } from "./observable";

export abstract class Service extends Observable implements ServiceIF {

    async init() : Promise<void> {}

    protected async monitor( newMonitor : Monitor ): Promise<void> {}
 
    protected async release(observationFilter?: Observation[], objectIdsFilter?: string[]): Promise<void> {}

}
