
import { SymmetricKey } from "../../security/api/symmetricKey";

export const EmptyVerifyPhoneMarker = "+";

export type AuthenticationClaims = {

    authenticationId?: string,

    verifyEmail?: string,

    verifyPhone?: string,

    userPath?: string,

    parentUserId?: string,

    userId?: string,

    childUserIds? : string[],  

    symbolicUserIds? : string[], 

    unitIds?: string[],

    subunitIds?: string[],

    symbolicUnitIds?: string[],

    companyId?: string,

    systemAdmin?     : boolean,

    companiesAdmin?    : string[],

    unitsAdmin?     : string[],

    projectManager?  : string[],

    adult?            : boolean,

    minor?            : boolean,

    key? : SymmetricKey,

    defaultKey? : SymmetricKey
}

